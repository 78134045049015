import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-wizard-selection-tree]'
})
export class WizardselectiontreeDirective {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
