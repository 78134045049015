import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-polarchart',
    templateUrl: './polarchart.component.html',
    styleUrls: ['./polarchart.component.css']
})
export class PolarchartComponent implements OnInit {

    @Input() data: any;
    @Input() dashboard: boolean;

    @Output('tab') tab = new EventEmitter();
    gotoTab(index: number) {
        this.tab.emit(index);
    }

    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.update();
    };

    options: Highcharts.Options = {
        credits: {
            enabled: false
        },
        chart: {
            polar: true,
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            margin: 0
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        xAxis: {
            tickmarkPlacement: 'on',
            lineWidth: 0,
            labels: {
                enabled: true,
            },
        },
        yAxis: {
            gridLineInterpolation: 'polygon',
            lineWidth: 0,
            labels: {
                enabled: false,
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">Rank : <b>{point.rank}</b><br/>'
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                marker: {
                    symbol: 'circle',
                    lineWidth: 2,
                    fillColor: 'white',
                    lineColor: null,
                }
            },
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: (event) => {
                            let tab_index = this.getTab(event.point.index);
                            this.gotoTab(tab_index);
                        }
                    }
                }
            }
        },
        pane: {
            startAngle: 0,
        },
    };

    radar_max: number;

    /**
     * 
     */
    constructor() {}

    /**
     * 
     */
    ngOnInit(): void {}

    /**
     * 
     */
    private getTab(point_index: number): number {
        switch (point_index) {
            case 0:
            case 1:
                return 1;
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return 2;
            default:
                return 0;
        }
    }

    /**
     *
     */
    private update(): any {

        if (this.data) {

            let categories = [];
            let datas = [];
            this.radar_max = 1;

            this.data.forEach(element => {
                categories.push(element.name);
                let data_length = element.data.length;

                // Inverted values (rank based)
                //datas.push({
                //  y: (data_length + 1) - element.rank,
                //  rank: element.rank
                //});

                // Inverted values (top based)
                datas.push({
                    y: (100 + 1) - element.top,
                    rank: element.rank_letter
                });

                // Update data max
                if (data_length > this.radar_max)
                    this.radar_max = data_length;

            });

            // Update chart & axis 
            this.chart.update({
                xAxis: {
                    categories: categories,
                },
                yAxis: {
                    min: 1,
                    //max: this.radar_max,
                    max: 100
                }
            });

            // Push to chart
            this.chart.addSeries({
                name: 'Yours',
                type: 'area',
                data: datas,
                color: '#93c47d',
                dataLabels: {
                    enabled: false,
                }
            });


            if (this.dashboard != undefined && this.dashboard) {
                // XXX Zoom out ... find a more elegant solution ?
                setTimeout(() => {
                    let radar_polar: any = document.getElementById("t10-polar").lastChild.lastChild
                        .firstChild;
                    radar_polar.style.height = "300px";
                });
            }
        }
    }
}
