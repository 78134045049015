import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-usermgt-root]'
})
export class UsermgtrootDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
