import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import * as Highcharts from 'highcharts';
import {
    FailureGetDailyTimestampResponse, FailureGetInfrastructureVersionsBetweenResponse,
    GetDailyTimestampResponse,
    GetDashboardDataForFilterResponse,
    GetDashboardGraphDataForFilter, GetInfrastructureVersionsBetweenResponse, InfrastructureVersion,
    NetscopeService,
    SuccessGetDailyTimestampResponse,
    SuccessGetDashboardDataForFilterResponse,
    SuccessGetDashboardGraphDataForFilter, SuccessGetInfrastructureVersionsBetweenResponse, TemporalParameter
} from '../../services/netscope.service';
import {
    ClrDatagridSortOrder,
    ClrDatagridStringFilterInterface
} from '@clr/angular';
import {
    LicenseService
} from "@app/services";
import {
    Router
} from "@angular/router";
import {
    environment
} from "@environments/environment";
import {
    FlowDetail
} from '@app/netscope/netscope-flows-datagrid/netscope-flows-datagrid.component';
import {TranslocoService} from "@ngneat/transloco";
import {NetscopeUtils} from "@app/netscope/netscope-utils";


export function tooltipFormatter (tooltip) {
    if (this.point.value === null) {
        return 'Null';
    }
    // If not null, use the default formatter
    let name = this.point.name
    let formattedValue = NetscopeUtils.formatBytes(this.point.y);
    return `${name}: <b>${formattedValue}</b>`
}


export function overallTrafficTooltipFormatter (tooltip) {
    if (this.point.value === null) {
        return 'Null';
    }
    // If not null, use the default formatter
    let formattedValue = NetscopeUtils.formatBytes(this.point.y);
    let result = tooltip.defaultFormatter.call(this, tooltip);
    result[1] = `Volume: <b>${formattedValue}</b>`;
    return result;
}


@Component({
    selector: 'app-netscope-dashboard',
    templateUrl: './netscope-dashboard.component.html',
    styleUrls: ['./netscope-dashboard.component.css']
})
export class NetscopeDashboardComponent implements OnInit, OnDestroy {

    isLoading = false;
    failureMode = false;
    isLoadingGraph = false;
    currentDayTimestamp = undefined;
    temporalParameter: TemporalParameter;
    dailyTimestamps;
    isFirstDay = false;
    isLastDay = false;
    showFlowModal = false;

    infrastructureVersion: InfrastructureVersion;

    selectFlowDetailsModalTitle = "";

    isNetscopeLicenceEnabled = true;

    Highcharts: typeof Highcharts = Highcharts;
    chartCheckInterval;

    visualisationSettings = {
        only_flows_with_src_and_dst_in_the_filter: false
    };

    // Overall network traffic chart's data
    overallTrafficChartOptions: Highcharts.Options = {
        // @ts-ignore
        series: [],
        yAxis: [{
            labels: {
                formatter: function (tooltip) {
                    return NetscopeUtils.formatBytes(this.value);
                }
            },
            title: {
                text: 'Volume',
                style: {}
            },
            min: 0
        }],
        exporting: {
            enabled: false
        },
        tooltip: {
            formatter: overallTrafficTooltipFormatter
        },
        xAxis: {
            type: 'datetime',
            plotLines: [{
                color: '#ff8856',
                dashStyle: 'Dash',
                width: 2,
                value: 0,
                zIndex: 5,
                label: {
                    text: "",
                    style: {
                        color: "#ff8856"
                    }
                }
            }]
        },
        time: {
            useUTC: false
        },
        title: undefined,
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {
                groupPadding: 0,
                pointPadding: 0,
                dataLabels: {
                    enabled: false,
                }
            },
            series: {
                cursor: 'pointer',
                events: {
                    click: (event) => {
                        this.changeDaySelected(event);
                    }
                }
            }
        },
    };
    updateOverallTrafficChartFlag = false;
    overallTrafficChartRef;

    // Origin pie chart
    originPieChartOptions: Highcharts.Options = {
        title: {
            text: undefined
        },
        tooltip: {
            formatter: tooltipFormatter
        },
        plotOptions: {
            pie: {
                size: "60%",
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    crop: false,
                    alignTo: 'connectors'
                },
                point: {
                    events: {
                        click: (e) => {
                            e.preventDefault();
                            this.filterFlowsForModal(e, {
                                "filterOrigin": e.point.name
                            });
                        }
                    }
                }
            }
        },
        series: [{
            data: [{
                name: '',
                y: 0.00,
                sliced: true,
                selected: true
            }, {
                name: '',
                y: 0.00,
            }, ],
            type: 'pie',
            name: 'Classification',
        }],
        credits: {
            enabled: false
        }
    };
    updateOriginPieChartFlag = false;
    originPieChartRef;

    // Protocol pie chart
    protocolPieChartOptions: Highcharts.Options = {
        title: {
            text: undefined
        },
        tooltip: {
            formatter: tooltipFormatter
        },
        plotOptions: {
            pie: {
                size: "60%",
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    crop: false,
                    alignTo: 'connectors'
                },
                point: {
                    events: {
                        click: (e) => {
                            e.preventDefault();
                            this.filterFlowsForModal(e, {
                                "filterProtocol": e.point.name
                            });
                        }
                    }
                }
            }
        },
        series: [{
            data: [{
                name: '',
                y: 0.00,
                sliced: true,
                selected: true
            }, {
                name: '',
                y: 0.00
            }, ],
            type: 'pie',
            name: 'Classification',
        }],
        credits: {
            enabled: false
        }
    };
    updateProtocolPieChartFlag = false;
    protocolPieChartRef;

    // Resource pie chart
    emitterPieChartOptions: Highcharts.Options = {
        title: {
            text: undefined
        },
        tooltip: {
            formatter: tooltipFormatter
        },
        plotOptions: {
            pie: {
                size: "60%",
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    crop: false,
                    alignTo: 'connectors'
                },
                point: {
                    events: {
                        click: (e) => {
                            e.preventDefault();
                            this.filterFlowsForModal(e, {
                                "filterEmitter": e.point.name
                            });
                        }
                    }
                }
            }
        },
        series: [{
            data: [{
                name: '',
                y: 0.00,
                sliced: true,
                selected: true
            }, {
                name: '',
                y: 0.00
            }, ],
            type: 'pie',
            name: 'Classification',
        }],
        credits: {
            enabled: false
        }
    };
    updateEmitterPieChartFlag = false;
    emitterPieChartRef;

    // Details datagrid's data

    descSort = ClrDatagridSortOrder.DESC;
    flowDetails: FlowDetail[] = [];
    outsideFlowDetails: FlowDetail[] = [];
    selectFlowDetails: FlowDetail[] = [];

    amountOfDataProcessed = 0;
    amountOfPacketsProcessed = 0;
    amountOfFlowsProcessed = 0;

    // Field that will be used by the date picker to store a date value
    dateForm;

    constructor(private netscopeService: NetscopeService, public licenceService: LicenseService, private route: Router, public translocoService: TranslocoService) {}

    ngOnInit(): void {
        this.reloadData();

        this.chartCheckInterval = setInterval(() => {
            this.reflowChart(this.originPieChartRef);
            this.reflowChart(this.protocolPieChartRef);
            this.reflowChart(this.emitterPieChartRef);
            this.reflowChart(this.overallTrafficChartRef);
        }, 100);

        this.route.events.subscribe((event) => {
            this.ngOnDestroy();
        });

        this.licenceService.licenseInfo.subscribe(licenceInfo => {
            if (environment.production) {
                // Check second bit to be different from 0
                this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
            } else {
                this.isNetscopeLicenceEnabled = true;
            }
        });
    }

    ngOnDestroy = () => {
        if (this.chartCheckInterval) {
            clearInterval(this.chartCheckInterval);
        }
    }

    filterFlowsForModal = (event, params) => {
        this.selectFlowDetails = this.flowDetails

        let flowIsInsideVcenter = (flow) => {
            return flow.source === undefined || flow.target === undefined || flow.source.type ===
                "unknown_ip" || flow.target.type === "unknown_ip";
        }
        // Filter on origin
        if (params.hasOwnProperty("filterOrigin")) {
            if (params.filterOrigin === "Outside vCenter") {
                this.selectFlowDetailsModalTitle = "Flows with exterior";
                this.selectFlowDetails = this.selectFlowDetails.filter((flow) => flowIsInsideVcenter(flow));
            } else {
                this.selectFlowDetailsModalTitle = "Flows inside infrastructure";
                this.selectFlowDetails = this.selectFlowDetails.filter((flow) => !flowIsInsideVcenter(flow));
            }
        }
        // Filter on emitter
        if (params.hasOwnProperty("filterEmitter")) {
            let emitterName = params.filterEmitter;
            this.selectFlowDetailsModalTitle = `Flows emitted by '${emitterName}'`;
            this.selectFlowDetails = this.selectFlowDetails
                .filter((flow) => flow.source !== undefined)
                .filter((flow) => flow.source.address === emitterName || (flow.source !== undefined && flow.source
                    .name === emitterName));
        }
        // Filter on protocol
        if (params.hasOwnProperty("filterProtocol")) {
            let protocolName = params.filterProtocol;
            this.selectFlowDetailsModalTitle = `Flows using protocol '${protocolName}'`;
            let linkName = `/netscope/flows/protocol-analysis/protocols/${protocolName}`;
            this.route.navigate([linkName]);
        }
        this.showFlowModal = true;
    }

    reflowChart = (chartRef) => {
        if (chartRef !== undefined && chartRef !== null) {
            try {
                chartRef.reflow();
            } catch (e) {
                console.log(e);
                clearInterval(this.chartCheckInterval);
            }
        }
    }

    setPreviousTimestamp = () => {
        let indexOfCurrentTimestamp = this.dailyTimestamps.indexOf(this.currentDayTimestamp);
        indexOfCurrentTimestamp -= 1;
        if (indexOfCurrentTimestamp >= 0) {
            this.currentDayTimestamp = this.dailyTimestamps[indexOfCurrentTimestamp];
            this.reloadData();
        }
    }

    setNextTimestamp = () => {
        let indexOfCurrentTimestamp = this.dailyTimestamps.indexOf(this.currentDayTimestamp);
        indexOfCurrentTimestamp += 1;
        if (indexOfCurrentTimestamp < this.dailyTimestamps.length) {
            this.currentDayTimestamp = this.dailyTimestamps[indexOfCurrentTimestamp];
            this.reloadData();
        }
    }

    changeDaySelected = (event) => {
        this.currentDayTimestamp = event.point.x / 1000.0;
        this.reloadData();
    }

    updateDashedOrangeLine = () => {
        // Fix orange plot-line that shows which day is currently selected
        // @ts-ignore
        let plotLine = this.overallTrafficChartOptions.xAxis.plotLines[0];
        plotLine.value = this.currentDayTimestamp * 1000;
        plotLine.label.text = new Date(this.currentDayTimestamp * 1000).toLocaleDateString("fr");
        this.updateOverallTrafficChartFlag = true;
    }

    reloadData = () => {
        this.isLoading = true;
        this.failureMode = false;

        setTimeout(() => {
            this.updateDashedOrangeLine();
        }, 300);

        this.isLoading = true;
        this.failureMode = false;

        const dailyTimestampsSubscription = this.netscopeService.getDailyTimestamps();

        dailyTimestampsSubscription.subscribe((dailyTimestampsResponse: GetDailyTimestampResponse) => {

            if (dailyTimestampsResponse instanceof FailureGetDailyTimestampResponse) {
                console.error("There was an issue fetching dailyTimestamps");
                this.failureMode = true;
                this.isLoading = false;
                return;
            }

            let dailyTimestamps;
            if (dailyTimestampsResponse instanceof SuccessGetDailyTimestampResponse) {
                dailyTimestamps = dailyTimestampsResponse.dailyTimestamps;
                this.failureMode = false;
            }

            this.dailyTimestamps = dailyTimestamps.map((v) => v.start_time);
            const minDay = Math.min(...this.dailyTimestamps);
            const maxDay = Math.max(...this.dailyTimestamps);
            if (this.currentDayTimestamp === undefined) {
                let maxTimestamp = maxDay;
                this.currentDayTimestamp = maxTimestamp;
                setTimeout(() => {
                    this.updateDashedOrangeLine();
                }, 300);
            }
            this.isFirstDay = this.currentDayTimestamp === minDay;
            this.isLastDay = this.currentDayTimestamp === maxDay;

            // Update temporal parameters
            this.temporalParameter = new TemporalParameter(this.currentDayTimestamp, this.currentDayTimestamp, "daily");

            this.netscopeService.getInfrastructureVersionsBetween(this.currentDayTimestamp, this.currentDayTimestamp, [], [], true).subscribe((infrastructureVersionResponse: GetInfrastructureVersionsBetweenResponse) => {

                if (infrastructureVersionResponse instanceof FailureGetInfrastructureVersionsBetweenResponse) {
                    console.error("There was an issue fetching infrastructureVersions");
                    this.failureMode = true;
                    this.isLoading = false;
                    return;
                }

                if (infrastructureVersionResponse instanceof SuccessGetInfrastructureVersionsBetweenResponse) {
                    this.infrastructureVersion = infrastructureVersionResponse.infrastructureVersionsBetween;
                }

                let vmsUuidToNameIndex = {};
                for (let vm of this.infrastructureVersion.topology.vm_only_topology.vms) {
                    vmsUuidToNameIndex[`${vm.uuid}`] = vm.name;
                }
                let vmsUuids = this.infrastructureVersion.topology.vm_only_topology.vms.map((vm) => vm.uuid).map((uuid) => uuid.split(":")[1]);
                let hostsUuidToNameIndex = {};
                for (let host of this.infrastructureVersion.topology.vm_only_topology.hosts) {
                    hostsUuidToNameIndex[`${host.uuid}`] = host.name;
                }
                const hostsUuids = this.infrastructureVersion.topology.vm_only_topology.hosts.map((vm) => vm.uuid).map((uuid) => uuid.split(":")[1]);

                const dashboardDataObservable = this.netscopeService.getDashboardDataForFilter(vmsUuids, hostsUuids, this.visualisationSettings.only_flows_with_src_and_dst_in_the_filter, this .currentDayTimestamp);

                dashboardDataObservable.subscribe((dashboardDataResponse: GetDashboardDataForFilterResponse) => {

                    let dashboardData;
                    if (dashboardDataResponse instanceof SuccessGetDashboardDataForFilterResponse) {
                        dashboardData = dashboardDataResponse.dashboardDataForFilter;
                    }

                    // Fix emitters_summary
                    for (let emitter of dashboardData["emitters_summary"]) {
                        if (vmsUuidToNameIndex.hasOwnProperty(emitter.src_resource_uuid)) {
                            emitter["src_address"] = vmsUuidToNameIndex[emitter.src_resource_uuid];
                        }
                        if (hostsUuidToNameIndex.hasOwnProperty(emitter.src_resource_uuid)) {
                            emitter["src_address"] = hostsUuidToNameIndex[emitter.src_resource_uuid];
                        }
                    }
                    // Fix flows_summary and outside_flows_summary
                    for (let key of ["flows_summary", "outside_flows_summary"]) {
                        for (let flow of dashboardData[key]) {
                            for (let [ip_address, object, flow_resource_key] of [
                                    [flow.src_address, flow.src_object, "src"],
                                    [flow.dst_address, flow.dst_object, "dst"]
                                ]) {
                                if (object !== undefined) {
                                    object.id = object.uuid;
                                    object.ipaddress = ip_address;
                                    object.address = ip_address;
                                    if (vmsUuidToNameIndex.hasOwnProperty(object.uuid)) {
                                        object.name = vmsUuidToNameIndex[object.uuid];
                                        object.type = "vm";
                                    } else if (hostsUuidToNameIndex.hasOwnProperty(object.uuid)) {
                                        object.name = hostsUuidToNameIndex[object.uuid];
                                        object.type = "host";
                                    } else {
                                        if (object.uuid.indexOf("vim.VirtualMachine:") !== -1) {
                                            object.type = "vm";
                                        }
                                        if (object.uuid.indexOf("vim.HostSystem:") !== -1) {
                                            object.type = "host";
                                        }
                                        if (object.uuid.indexOf("vim.ExternalIpaddress:") !== -1) {
                                            object.name = object.short_uuid;
                                            object.type = "external_ip";
                                            object.is_in_filter = true;
                                        }
                                    }
                                } else {
                                    let address = flow[flow_resource_key + "_address"];
                                    flow[`${flow_resource_key}_object`] = {
                                        uuid: `vim.UnknownIpaddress:${address}`,
                                        type: "unknown_ip",
                                        name: address,
                                        is_in_filter: true
                                    }
                                }
                            }
                            // Ensure compatibility with FlowDetail (common flow datagrid)
                            flow.source = flow.src_object;
                            flow.target = flow.dst_object;
                        }
                    }
                    // Find flows with external Ips in 'outside_flows_summary' and put them in flows_summary
                    let isFlowWithExternalIps = (flowRow) => {
                        for (let objPropertyName of ["src_object", "dst_object"]) {
                            if (flowRow.hasOwnProperty(objPropertyName) && flowRow[
                                    objPropertyName] !== undefined && (flowRow[objPropertyName]
                                    .type ===
                                    "external_ip")) {
                                return true;
                            }
                        }
                        return false;
                    }
                    let externalIpsOutsideFlows = dashboardData["outside_flows_summary"].filter((row) =>
                        isFlowWithExternalIps(row));
                    dashboardData["outside_flows_summary"] = dashboardData["outside_flows_summary"]
                        .filter((row) => !isFlowWithExternalIps(row));
                    dashboardData["flows_summary"].push(...externalIpsOutsideFlows);
                    // Send data to functions that update the UI
                    this.reloadUi(dashboardData);
                    // @ts-ignore
                    this.amountOfDataProcessed = dashboardData.internal_traffic_sum + dashboardData.external_traffic_sum;
                    // @ts-ignore
                    this.amountOfFlowsProcessed = dashboardData.processed_flows_count;
                    // @ts-ignore
                    this.amountOfPacketsProcessed = dashboardData.protocols_summary
                        .map((p) => p.exchanged_packets)
                        .reduce((a, b) => a + b, 0);
                    this.isLoading = false;
                });
            });
        });

        setTimeout(() => {
            this.isLoadingGraph = true;
            const dashboardGraphDataForFilterObservable = this.netscopeService.getDashboardGraphDataForFilter([], [], this.visualisationSettings.only_flows_with_src_and_dst_in_the_filter);
            dashboardGraphDataForFilterObservable.subscribe((dashboardGraphDataForFilterResponse: GetDashboardGraphDataForFilter) => {
                let dashboardGraphDataForFilter;
                if (
                    dashboardGraphDataForFilterResponse instanceof SuccessGetDashboardGraphDataForFilter
                ) {
                    dashboardGraphDataForFilter = dashboardGraphDataForFilterResponse
                        .dashboardGraphDataForFilter;
                }

                this.updateGraphData(dashboardGraphDataForFilter);
                this.isLoadingGraph = false;
            });
        }, 1000);
    }

    reloadUi = (dashboardData) => {
        this.updateInterIntraTrafficPieChart(dashboardData.internal_traffic_sum, dashboardData
            .external_traffic_sum);
        this.updateProtocolPieChart(dashboardData.protocols_summary);
        this.updateEmitterPieChart(dashboardData.emitters_summary);
        this.updateTrafficDetails(dashboardData.flows_summary, dashboardData.outside_flows_summary);
    }

    updateInterIntraTrafficPieChart = (internalTrafficSum, externalTrafficSum) => {
        // @ts-ignore
        this.originPieChartOptions.plotOptions.pie.dataLabels.enabled = true;
        // @ts-ignore
        this.originPieChartOptions.series = [{
            data: [{
                name: 'Inside vCenter',
                y: internalTrafficSum,
                sliced: true,
                selected: true
            }, {
                name: 'Outside vCenter',
                y: externalTrafficSum
            }, ],
            type: 'pie',
            name: 'Classification',
        }];
        this.updateOriginPieChartFlag = true;
    }

    updateProtocolPieChart = (protocolsSummary) => {
        // @ts-ignore
        this.protocolPieChartOptions.plotOptions.pie.dataLabels.enabled = true;
        // @ts-ignore
        this.protocolPieChartOptions.series = [{
            data: protocolsSummary.map((pSummary) => {
                return {
                    name: pSummary.protocol,
                    y: pSummary.exchanged_bytes,
                };
            }),
            type: 'pie',
            name: 'Classification',
        }];
        this.updateProtocolPieChartFlag = true;
    }

    updateEmitterPieChart = (emittersSummary) => {
        // @ts-ignore
        this.emitterPieChartOptions.plotOptions.pie.dataLabels.enabled = true;
        const sortedEmittersSummary = emittersSummary.sort((p1, p2) => p2.exchanged_bytes - p1.exchanged_bytes)
            .slice(0, 20);
        const data = sortedEmittersSummary.map((pSummary) => {
            const label = pSummary.src_address;
            return {
                name: label,
                y: pSummary.exchanged_bytes
            };
        });
        // @ts-ignore
        this.emitterPieChartOptions.series = [{
            data: data,
            type: 'pie',
            name: 'Classification',
        }];
        this.updateEmitterPieChartFlag = true;
    }

    updateTrafficDetails = (flowsSummary, outsideFlowsSummary) => {
        this.flowDetails = flowsSummary;
        this.outsideFlowDetails = outsideFlowsSummary;
    }

    updateGraphData = (graphData) => {
        this.overallTrafficChartOptions.series = [];
        this.overallTrafficChartOptions.series.push({
            type: 'bar',
            yAxis: 0,
            name: `Volume (MiB)`,
            color: 'rgb(84, 167, 240)',
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y} MiB</b>'
            },
            pointInterval: 24 * 3600 * 1000,
            data: graphData.map((d) => [d.time * 1000, d.exchanged_bytes]),
            showInNavigator: true
        });

        // Check if the chart needs scrollbar (more than 3 months of data)
        let scrollbarEnabled = false;
        let xaxisMin, xaxisMax;
        if (graphData.length >= 1) {
            xaxisMin = graphData[0].time;
            xaxisMax = graphData[0].time;
        }
        if (graphData.length >= 2) {
            xaxisMax = graphData[graphData.length - 1].time;
        }
        if (graphData.length > 90) {
            xaxisMin = graphData[graphData.length - 90].time;
            scrollbarEnabled = true;
        }

        // @ts-ignore
        this.overallTrafficChartOptions.xAxis.min = xaxisMin * 1000;
        // @ts-ignore
        this.overallTrafficChartOptions.xAxis.max = xaxisMax * 1000;
        // @ts-ignore
        this.overallTrafficChartOptions.xAxis.scrollbar = {
            enabled: scrollbarEnabled
        };

        // Update plotline to show current selectedDay

        this.updateOverallTrafficChartFlag = true;
    }

    callbackOriginPieChartRef = (ref) => {
        this.originPieChartRef = ref;
    }

    callbackProtocolPieChartRef = (ref) => {
        this.protocolPieChartRef = ref;
    }

    callbackResourcePieChartRef = (ref) => {
        this.emitterPieChartRef = ref;
    }

    callbackOverallTrafficChartRef = (ref) => {
        this.overallTrafficChartRef = ref;
    }

    /**
     * This method export the data table into a CSV file
     */
    exportCSV() {
        let csvHeader =
            "SourceIp, SourceName, DestinationIp, DestinationName, ExchangedBytes, ExchangedPackets, MatchingFlow"
        let csvContent = csvHeader + "\n";

        for (let flowDetail of this.flowDetails) {
            let src_addr = flowDetail.src_address;
            let src_name = (flowDetail.source !== undefined && flowDetail.source.name !== undefined) ? flowDetail
                .source.name : "unknown";
            let dst_addr = flowDetail.dst_address;
            let dst_name = (flowDetail.target !== undefined && flowDetail.target.name !== undefined) ? flowDetail
                .target.name : "unknown";
            let matching_flow = true;
            let lineValue =
                `${src_addr}, ${src_name}, ${dst_addr}, ${dst_name}, ${flowDetail.exchanged_bytes}, ${flowDetail.exchanged_packets}, ${matching_flow}\n`;
            csvContent += lineValue;
        }
        for (let flowDetail of this.outsideFlowDetails) {
            let src_addr = flowDetail.src_address;
            let src_name = (flowDetail.source !== undefined && flowDetail.source.name !== undefined) ? flowDetail
                .source.name : "unknown";
            let dst_addr = flowDetail.dst_address;
            let dst_name = (flowDetail.target !== undefined && flowDetail.target.name !== undefined) ? flowDetail
                .target.name : "unknown";
            let matching_flow = false;
            let lineValue =
                `${src_addr}, ${src_name}, ${dst_addr}, ${dst_name}, ${flowDetail.exchanged_bytes}, ${flowDetail.exchanged_packets}, ${matching_flow}\n`;
            csvContent += lineValue;
        }

        let exportedFilename = 'netscope-dashboard.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    setLanguage = (language: "french" | "english") => {
        let languageMap: Map<string, string> = new Map([
            ["french", "fr"],
            ["english", "en"]
        ]);
        let language_code = languageMap.get(language);
        this.translocoService.setDefaultLang(language_code);
        this.translocoService.setActiveLang(language_code);
        localStorage.setItem("language", language_code);
    }
    protected readonly NetscopeUtils = NetscopeUtils;
}

