<div class="clr-row" *ngIf="!this.message.kubeDetail">
        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                <div class="card" style = "width: 100%;height: 150px;display: block; ">
                        <div class="card-header">
                                <span style="color:grey;font-size:20px">
                                        <cds-icon shape="cluster"></cds-icon>
                                        CLUSTERS
                                </span>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                <div class="card" style="margin-top: 0px;cursor: pointer;" (click)="node_modal=true">
                                                        <div class="card-block">
                                                                <span style="font-weigth:bold;font-size:24px">
                                                                        {{metadata.node}}
                                                                </span>
                                                                <br>
                                                                <span style="float: inline-end;">nodes</span>
                                                                <br>
                                                        </div>
                                                </div>
                                        </div>
                                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                <div class="card" style="margin-top: 0px;cursor: pointer;" (click)="pod_modal=true">
                                                        <div class="card-block">
                                                                <span style="font-weigth:bold;font-size:24px">
                                                                        {{metadata.pod}}
                                                                </span>
                                                                <br>
                                                                <span style="float: inline-end;">pods</span>
                                                                <br>
                                                        </div>
                                                </div>
                                        </div>
                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                <div class="card" style="margin-top: 0px;cursor: pointer;" (click)="container_modal=true">
                                                        <div class="card-block">
                                                                <span style="font-weigth:bold;font-size:24px">
                                                                        {{metadata.runContainer+metadata.offContainer}}
                                                                </span>
                                                                <br>
                                                                <span style="float: inline-end;">current containers</span>
                                                                <br>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
                <div class="card">
                        <div class="card-block">
                                <span style="font-size: 28px">
                                        {{metadata.runContainer}}
                                </span>
                                <span>
                                        running containers
                                </span>
                                <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadContainersDetail()">
                                        more ...
                                </span>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                <div class="card" style = "width: 100%;height: 150px;display: block; ">
                        <div class="card-header">
                                <span style="color:grey;font-size:20px;">
                                        <fa-icon [icon]="['fas', 'leaf']" style="color:grey;font-size:14px;"></fa-icon>
                                        GREEN IT
                                </span>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                <div class="card" style="margin-top: 0px;">
                                                        <div class="card-block">
                                                        <span style="font-weigth:bold;font-size:24px">
                                                                        {{toFixedNumber((metadata.aggWh/1000), 0)}}
                                                                </span>
                                                                <br>
                                                                <span style="float: inline-end;">kWh</span>
                                                                <br>
                                                        </div>
                                                </div>
                                        </div>
                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                <div class="card" style="margin-top: 0px;">
                                                        <div class="card-block">
                                                        <span style="font-weigth:bold;font-size:24px">
                                                                {{toFixedNumber(metadata.aggCo2, 0)}}
                                                                </span>
                                                                <br>
                                                                <span style="float: inline-end;">kg CO2eq</span>
                                                                <br>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
                <div class="card">
                        <div class="card-block">
                                <span style="font-size: 28px">
                                {{metadata.currentMonthContainer}}
                                </span>
                                <span>
                                        containers for the current month
                                </span>
                                <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadCo2Detail()">
                                        more ...
                                </span>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
        <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
            <div class="card">
                    <div class="card-header" style="height: 60px;">
                            <span style="color:grey;font-size:20px;">
                                    <cds-icon shape="container"></cds-icon>
                                    ELASTICITY
                            </span>
                            <clr-tooltip>
                                    <cds-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></cds-icon>
                                    <clr-tooltip-content clrPosition="middle-right" clrSize="lg" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                            <span style="color: #0072a3">Elasticity</span>
                                            <p>
                                                    The objective is to ensure that the number of containers created each day does not increase exponentially.
                                                    <br><br>The graph shows the last 30 days.
                                                    <br>The green bar indicates the number of deleted containers for a given day.
                                                    <br>The blue bar indicates the number of containers created for a given day.
                                                    <br>The red dot indicates the number of containers existing at the end of the day.
                                                    <br>If the red dot drifts to the right, the number of containers increases compared to the previous day. Conversely, if the red dot drifts to the left, the number of containers decreases compared to the previous day.
                                            </p>
                                    </clr-tooltip-content>
                            </clr-tooltip>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                            <highcharts-chart
                                                        [Highcharts] = "highcharts"
                                                        [options] = "chartOptions3"
                                                        [constructorType]="'chart'"
                                                        style = "width: 100%;height: 275px;display: block; " 
                                                        *ngIf="isReady3">
                                            </highcharts-chart>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
                            <span style="font-size: 28px">
                                    {{metadata.addContainer}}
                            </span>
                            <br>
                            <span>
                                    containers created over the current month
                            </span>
                    </div>
            </div>
        </div>
        <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                <div class="card">
                        <div class="card-header" style="height: 60px;">
                                <span style="color:grey;font-size:20px;">
                                        <cds-icon shape="container"></cds-icon>
                                        DYNAMICITY
                                </span>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <highcharts-chart
                                                        [Highcharts] = "highcharts"
                                                        [options] = "chartOptions4"
                                                        [constructorType]="'chart'"
                                                        style = "width: 100%;height: 275px;display: block; " 
                                                        *ngIf="isReady4">
                                                </highcharts-chart>
                                        </div>
                                </div>
                        </div>
                </div>
                <div class="card">
                        <div class="card-block">
                                <span style="font-size: 28px">
                                        {{model.more30Days}}
                                </span>
                                <br>
                                <span>
                                        containers that are over 30 days old
                                </span>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                <div class="card">
                        <div class="card-header" style="height: 60px;">
                                <span style="color:grey;font-size:20px;">
                                        <cds-icon shape="thumbs-up"></cds-icon>
                                        OPPORTUNITIES
                                </span>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <highcharts-chart
                                                        [Highcharts] = "highcharts"
                                                        [options] = "chartOptions"
                                                        [constructorType]="'chart'"
                                                        style = "width: 100%;height: 275px;display: block;" 
                                                        *ngIf="isReady">
					        </highcharts-chart>
                                        </div>
                                </div>
                        </div>
                </div>
                <div class="card">
                        <div class="card-block">
                                <div class="card-block">
                                        <span style="font-size: 28px">
                                        {{metadata.aggIdle}}
                                        </span>
                                        <span>
                                                idle for the current month
                                        </span>
                                        <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadOpportunitiesDetail()">
                                                more ...
                                        </span>
                                </div>
                        </div>
                </div>
        </div>
</div>
<app-container-detail [isreload]="isDataReload" *ngIf="isContainerDetail == true"></app-container-detail>
<app-greenit-detail *ngIf="isGreenitDetail == true"></app-greenit-detail>
<app-opportunities-detail *ngIf="isOpportunitiesDetail == true"></app-opportunities-detail>

<clr-modal [(clrModalOpen)]="node_modal" [clrModalClosable]=true class="overflow-modal">
        <h3 class="modal-title">Nodes list</h3>
        <div class="modal-body">
                <clr-datagrid *ngIf="node_modal">
                <clr-dg-action-bar style="margin-top:0px">
                    <div class="btn-group" style="float:right">
                        <button type="button" class="btn btn-sm btn-link" (click)="exportNode()">
                            Export to CSV
                        </button>
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder" class="name"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'cluster'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Cluster</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'cluster'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Tanzu kubernetes cluster</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'tkgCluster'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Tanzu kubernetes namespace</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'tkgNs'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Create date</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'ip'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">IP</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'arch'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Architecture</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'osimage'" [clrDgSortOrder]="dataOrder" class="osimage"><ng-container *clrDgHideableColumn="{hidden: false}">OsImage</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'cpu'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">CPU</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'ram'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">RAM (GB)</ng-container></clr-dg-column>
                <clr-dg-row *clrDgItems="let node of nodes" [clrDgItem]="node">
                    <clr-dg-cell class="name">{{node.name}}</clr-dg-cell>
                    <clr-dg-cell>{{node.cluster}}</clr-dg-cell>
                    <clr-dg-cell>{{node.tkgCluster}}</clr-dg-cell>
                    <clr-dg-cell>{{node.tkgNs}}</clr-dg-cell>
                    <clr-dg-cell>{{convertTimeToDate(node.creation)}}</clr-dg-cell>
                    <clr-dg-cell>{{node.ip}}</clr-dg-cell>
                    <clr-dg-cell>{{node.arch}}</clr-dg-cell>
                    <clr-dg-cell class="osimage">{{node.osimage}}</clr-dg-cell>
                    <clr-dg-cell>{{node.cpu}}</clr-dg-cell>
                    <clr-dg-cell>{{(node.ram/1048576/1024).toFixed(2)}}</clr-dg-cell>
                </clr-dg-row>              
                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="node_modal = false">Close</button>
        </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="pod_modal" [clrModalClosable]=true class="overflow-modal">
        <h3 class="modal-title">Pods list</h3>
        <div class="modal-body">
                <clr-datagrid *ngIf="pod_modal">
                <clr-dg-action-bar style="margin-top:0px">
                    <div class="btn-group" style="float:right">
                        <button type="button" class="btn btn-sm btn-link" (click)="exportPod()">
                            Export to CSV
                        </button>
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder" class="name"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'node'" [clrDgSortOrder]="dataOrder" class="name"><ng-container *clrDgHideableColumn="{hidden: false}">Node</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'cluster'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Cluster</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'creation'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Create date</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'ip'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">IP</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'namespace'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Namespace</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
                <clr-dg-row *clrDgItems="let pod of pods" [clrDgItem]="pod">
                    <clr-dg-cell class="name">{{pod.name}}</clr-dg-cell>
                    <clr-dg-cell class="name">{{pod.node}}</clr-dg-cell>
                    <clr-dg-cell>{{pod.cluster}}</clr-dg-cell>
                    <clr-dg-cell>{{convertTimeToDate(pod.creation)}}</clr-dg-cell>
                    <clr-dg-cell>{{pod.ip}}</clr-dg-cell>
                    <clr-dg-cell>{{pod.namespace}}</clr-dg-cell>
                    <clr-dg-cell>{{pod.state}}</clr-dg-cell>
                </clr-dg-row>              
                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="pod_modal = false">Close</button>
        </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="container_modal" [clrModalClosable]=true  class="overflow-modal">
        <h3 class="modal-title">Containers list</h3>
        <div class="modal-body">
                <clr-datagrid *ngIf="container_modal">
                <clr-dg-action-bar style="margin-top:0px">
                    <div class="btn-group" style="float:right">
                        <button type="button" class="btn btn-sm btn-link" (click)="exportContainer()">
                            Export to CSV
                        </button>
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'pod'" [clrDgSortOrder]="dataOrder" class="name"><ng-container *clrDgHideableColumn="{hidden: false}">Pod</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'node'" [clrDgSortOrder]="dataOrder" class="name"><ng-container *clrDgHideableColumn="{hidden: false}">Node</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'cluster'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Cluster</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'namespace'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Namespace</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'creation'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Create date</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'image'" [clrDgSortOrder]="dataOrder" class="osimage"><ng-container *clrDgHideableColumn="{hidden: false}">Image</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
                <clr-dg-row *clrDgItems="let container of containers" [clrDgItem]="container">
                    <clr-dg-cell>{{container.name}}</clr-dg-cell>
                    <clr-dg-cell class="name">{{container.pod}}</clr-dg-cell>
                    <clr-dg-cell class="name">{{container.node}}</clr-dg-cell>
                    <clr-dg-cell>{{container.cluster}}</clr-dg-cell>
                    <clr-dg-cell>{{container.namespace}}</clr-dg-cell>
                    <clr-dg-cell>{{convertTimeToDate(container.creation)}}</clr-dg-cell>
                    <clr-dg-cell class="osimage">{{container.image}}</clr-dg-cell>
                    <clr-dg-cell>{{container.state}}</clr-dg-cell>
                </clr-dg-row>              
                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="container_modal = false">Close</button>
        </div>
</clr-modal>