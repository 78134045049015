<div [ngClass]="cssClass">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="tree"></cds-icon>
			&nbsp;CO2 Emissions
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div *ngIf="isReady">
				<app-greenitcf #greenitcf [showStackLabel]=false [target]="target"></app-greenitcf>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
		</div>
	</div>
</div>