import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import * as moment from 'moment';

import {
    AccountService,
    InfrastructureService,
    ShareService
} from '@app/services';

import {
    KpiActivity,
    Message,
    User
} from '@app/model';
import * as $ from 'jquery';


@Component({
    selector: 'app-kpitable',
    templateUrl: './kpitable.component.html',
    styleUrls: ['./kpitable.component.css']
})
export class KpitableComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: any = {};

    dtTrigger: Subject < any > = new Subject();

    message: Message;

    model_kpi: KpiActivity[] = [];

    moment = moment;

    math = Math;

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private infra_svc: InfrastructureService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();
        $("dt-buttons").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.initDtOptions();
    }

    ngAfterViewInit(): void {

        this.dtTrigger.next();
        this.buildTable();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    private buildTable(): void {

        switch (this.message.kpiActivity) {
            case "add":
                this.infra_svc.getKpiAction(this.currentUser.login, this.message.kpiStart, this.message
                    .currentFilter, 'ADD').pipe(first()).subscribe(
                    data => {
                        this.model_kpi = data;
                        if (this.model_kpi)
                            this.reloadTable();
                    }, error => {
                        console.log('no kpi add data');
                    }
                );
                /*this.infra_svc.getKpiAdd(this.currentUser.login, this.message.kpiStart, this.message.kpiEnd, this.message.elementView, this.message.periodView).pipe(first()).subscribe(
				data => {
	  				this.model_kpi = data;
					if(this.model_kpi)
						this.reloadTable();
				}, error => {
					console.log('no kpi add data');
				}
	  		);*/
                break;
            case "del":
                this.infra_svc.getKpiAction(this.currentUser.login, this.message.kpiStart, this.message
                    .currentFilter, 'DEL').pipe(first()).subscribe(
                    data => {
                        this.model_kpi = data;
                        if (this.model_kpi)
                            this.reloadTable();
                    }, error => {
                        console.log('no kpi add data');
                    }
                );
                /*this.infra_svc.getKpiDel(this.currentUser.login, this.message.kpiStart, this.message.kpiEnd, this.message.elementView, this.message.periodView).pipe(first()).subscribe(
				data => {
					this.model_kpi = data;
					if(this.model_kpi)
						this.reloadTable();
				}, error => {
					console.log('no kpi del data');
				}
	  		);*/
                break;
            case "off":
                this.infra_svc.getKpiOff(this.currentUser.login, this.message.kpiStart, this.message.kpiEnd, this
                    .message.elementView).pipe(first()).subscribe(
                    data => {
                        this.model_kpi = data;
                        if (this.model_kpi)
                            this.reloadTable();
                    }, error => {
                        console.log('no kpi off data');
                    }
                );
                break;
            case "suspend":
                this.infra_svc.getKpiSuspend(this.currentUser.login, this.message.kpiStart, this.message.kpiEnd,
                    this.message.elementView).pipe(first()).subscribe(
                    data => {
                        this.model_kpi = data;
                        if (this.model_kpi)
                            this.reloadTable();
                    }, error => {
                        console.log('no kpi suspend data');
                    }
                );
                break;
            default:
                break;
        }
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
                $('div.dt-buttons').appendTo('span.export');
                $("[id*='DataTables_Table_']").css({
                    'padding-top': '5px'
                });
                $("[name*='DataTables_Table_']").css({
                    'padding': '0px'
                });
            },
            language: {
                loadingRecords: "loading .. ",
                zeroRecords: "loading ...",
                search: '',
                searchPlaceholder: " search..."
            },
            dom: '<"top"B><"clear">frtlip',
            buttons: [{
                extend: 'csv',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'copy',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'print',
                className: 'btn btn-link btn-sm'
            }]
        };
    }
}
