import {
    Component,
    HostListener,
    OnInit
} from '@angular/core';
import {
    InstanceType,
    Message,
    User
} from '@app/model';
import {
    AccountService,
    GreenitService,
    ManagementService,
    MonitorService,
    ShareService
} from '@app/services';
import {
    Subscription
} from 'rxjs';

@Component({
    selector: 'app-co2scopetabs',
    templateUrl: './co2scopetabs.component.html',
    styleUrls: ['./co2scopetabs.component.css']
})
export class Co2scopetabsComponent implements OnInit {

    role: User;
    message: Message;

    // For trackdc instances
    vmware_monitor_check: boolean = false;
    aws_monitor_check: boolean = false;
    azure_monitor_check: boolean = false;
    gcp_monitor_check: boolean = false;
    kubernetes_monitor_check: boolean = false;
    scaphandre_monitor_check: boolean = false;
    xclarity_monitor_check: boolean = false;
    openmanage_monitor_check: boolean = false;
    oneview_monitor_check: boolean = false;
    ipmi_monitor_check: boolean = false;

    // For elements type
    vmware_perimeter_check: boolean = false;
    aws_perimeter_check: boolean = false;
    azure_perimeter_check: boolean = false;
    gcp_perimeter_check: boolean = false;
    kubernetes_perimeter_check: boolean = false;
    scaphandre_perimeter_check: boolean = false;
    xclarity_perimeter_check: boolean = false;
    openmanage_perimeter_check: boolean = false;
    oneview_perimeter_check: boolean = false;
    ipmi_perimeter_check: boolean = false;
    storage_perimeter_check: boolean = false;
    network_perimeter_check: boolean = false;

    // For tabs
    tab: InstanceType;
    tab_subscription: Subscription;
    instancetype = InstanceType;
    app_tabs_status: Map < string,
    Boolean > = new Map < string,
    Boolean > ();

    /**
     * 
     */
    constructor(private authentication_svc: AccountService, private message_svc: ShareService,
        private monitor_svc: MonitorService, private management_svc: ManagementService) {}

    /**
     * 
     */
    ngOnInit() {

        this.authentication_svc.user.subscribe(user => this.role = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        // Check trackdc instances
        this.monitor_svc.vmwareCheck.subscribe((details) => (this.vmware_monitor_check = details));
        this.monitor_svc.awsCheck.subscribe((details) => (this.aws_monitor_check = details));
        this.monitor_svc.azureCheck.subscribe((details) => (this.azure_monitor_check = details));
        this.monitor_svc.gcpCheck.subscribe((details) => (this.gcp_monitor_check = details));
        this.monitor_svc.kubernetesCheck.subscribe((details) => (this.kubernetes_monitor_check = details));
        this.monitor_svc.scaphandreCheck.subscribe((details) => (this.scaphandre_monitor_check = details));
        this.monitor_svc.xclarityCheck.subscribe((details) => (this.xclarity_monitor_check = details));
        this.monitor_svc.openmanageCheck.subscribe((details) => (this.openmanage_monitor_check = details));
        this.monitor_svc.oneviewCheck.subscribe((details) => (this.oneview_monitor_check = details));
        this.monitor_svc.ipmiCheck.subscribe((details) => (this.ipmi_monitor_check = details));

        // Check elements type
        this.management_svc.vmwareCheck.subscribe((details) => (this.vmware_perimeter_check = details));
        this.management_svc.awsCheck.subscribe((details) => (this.aws_perimeter_check = details));
        this.management_svc.azureCheck.subscribe((details) => (this.azure_perimeter_check = details));
        this.management_svc.gcpCheck.subscribe((details) => (this.gcp_perimeter_check = details));
        this.management_svc.kubernetesCheck.subscribe((details) => (this.kubernetes_perimeter_check = details));
        this.management_svc.scaphandreCheck.subscribe((details) => (this.scaphandre_perimeter_check = details));
        this.management_svc.xclarityCheck.subscribe((details) => (this.xclarity_perimeter_check = details));
        this.management_svc.openmanageCheck.subscribe((details) => (this.openmanage_perimeter_check = details));
        this.management_svc.oneviewCheck.subscribe((details) => (this.oneview_perimeter_check = details));
        this.management_svc.ipmiCheck.subscribe((details) => (this.ipmi_perimeter_check = details));
        this.management_svc.storageCheck.subscribe((details) => (this.storage_perimeter_check = details));
        this.management_svc.networkCheck.subscribe((details) => (this.network_perimeter_check = details));

        // Update tabs according to menu
        this.monitor_svc.initAppTabs();
        this.monitor_svc.AppTabsStatus.subscribe((status) => {
            this.app_tabs_status = status;
        });

        this.tab_subscription = this.monitor_svc.currentAppTab.subscribe((tab) => {
            this.tab = tab;
        });
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        if (this.tab_subscription != undefined) {
            this.tab_subscription.unsubscribe();
        }
    }

    /**
     * For tabs
     */

    setTab(tab: InstanceType) {
        this.monitor_svc.setAppTab(tab);
    }

    /**
     * tab activation based on menu, existing connector and filter elements
     */
    checkTab(tab: InstanceType): boolean {

        let status: boolean = false;

        switch (tab) {
            case InstanceType.ALL:
                status = Boolean(this.app_tabs_status.get(InstanceType.ALL));
                break;
            case InstanceType.VMWARE:
                status = Boolean(this.app_tabs_status.get(InstanceType.VMWARE)) && this.vmware_monitor_check && this
                    .vmware_perimeter_check;
                break;
            case InstanceType.AWS:
                status = Boolean(this.app_tabs_status.get(InstanceType.AWS)) && this.aws_monitor_check && this
                    .aws_perimeter_check;
                break;
            case InstanceType.AZURE:
                status = Boolean(this.app_tabs_status.get(InstanceType.AZURE)) && this.azure_monitor_check && this
                    .azure_perimeter_check;
                break;
            case InstanceType.GCP:
                status = Boolean(this.app_tabs_status.get(InstanceType.GCP)) && this.gcp_monitor_check && this
                    .gcp_perimeter_check;
                break;
            case InstanceType.KUBERNETES:
                status = Boolean(this.app_tabs_status.get(InstanceType.KUBERNETES)) && this
                    .kubernetes_monitor_check && this.kubernetes_perimeter_check;
                break;
            case InstanceType.XCLARITY:
                status = Boolean(this.app_tabs_status.get(InstanceType.XCLARITY)) && this.xclarity_monitor_check &&
                    this.xclarity_perimeter_check;
                break;
            case InstanceType.SCAPHANDRE:
                status = Boolean(this.app_tabs_status.get(InstanceType.SCAPHANDRE)) && this
                    .scaphandre_monitor_check && this.scaphandre_perimeter_check;
                break;
            case InstanceType.OPENMANAGE:
                status = Boolean(this.app_tabs_status.get(InstanceType.OPENMANAGE)) && this
                    .openmanage_monitor_check && this.openmanage_perimeter_check;
                break;
            case InstanceType.ONEVIEW:
                status = Boolean(this.app_tabs_status.get(InstanceType.ONEVIEW)) && this.oneview_monitor_check &&
                    this.oneview_perimeter_check;
                break;
            case InstanceType.IPMI:
                status = Boolean(this.app_tabs_status.get(InstanceType.IPMI)) && this.ipmi_monitor_check && this
                    .ipmi_perimeter_check;
                break;
            case InstanceType.STORAGE:
                status = Boolean(this.app_tabs_status.get(InstanceType.STORAGE)) && this.storage_perimeter_check;
                break;
            case InstanceType.NETWORK:
                status = Boolean(this.app_tabs_status.get(InstanceType.NETWORK)) && this.network_perimeter_check;
                break;
            default:
                status = true;
                break;
        }

        if (tab == this.tab) {
            if (status == false) {
                //XXX need to change tab
                //setTimeout(()=>this.setTab(InstanceType.ALL),100);
            }
        }

        return status;
    }

    checkActive(tab: InstanceType): boolean {
        if (tab == this.tab)
            return true;
        else
            return false;
    }
}
