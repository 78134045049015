import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    ClrSelectedState,
    ClrWizard
} from "@clr/angular";


export interface WizardActionEvent {

}

export class NewEmptyManualAppgroupEvent implements WizardActionEvent {}

export class MergeAppsGroupsWithTheseAppsGroupsEvent implements WizardActionEvent {
    constructor(public appsGroups) {}
}

export class NewAppGroupFromVMwareTagEvent implements WizardActionEvent {
    constructor(public newAppGroup) {}
}

export class NewAppGroupFromVMwareCategoryEvent implements WizardActionEvent {
    constructor(public newCategory) {}
}


@Component({
    selector: 'app-apps-groups-wizard-tags-selection',
    templateUrl: './apps-groups-wizard-tags-selection.component.html',
    styleUrls: ['./apps-groups-wizard-tags-selection.component.css']
})
export class AppsGroupsWizardTagsSelectionComponent implements OnInit {

    tagsData;

    action_choice = "manual_appgroup";
    selectedTagStep2;
    vmsCandidates = [];
    selectedVms = [];
    selectedTag;
    selectedCategory;

    vmsSelectionMode = "automatic"

    loading = false;

    @ViewChild("wizard") wizard: ClrWizard;

    @Input()
    vms = [];

    @Output()
    newAppGroupFromWizard: EventEmitter < WizardActionEvent > = new EventEmitter();

    _open: boolean = false;

    rootTagSelection = [];
    rootCategorySelection = [];
    appsGroupsFromCsv = [];

    ngOnInit(): void {}

    fileChanged = (e) => {
        let file = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            let importedAppsGroups = [];

            let rawLines: String = fileReader.result as string;
            let lines = rawLines.split("\n").map((_) => _.trim());

            let isHeader = true;
            for (let line of lines) {
                console.log("line: " + line)
                if (!isHeader) {

                    let delimiter;

                    if (line.indexOf(',') !== -1) {
                        delimiter = ',';
                    }

                    if (line.indexOf(';') !== -1) {
                        delimiter = ';';
                    }

                    if (delimiter === undefined) {
                        continue;
                    }


                    let allColumns = line.split(delimiter);

                    if (allColumns.length == 0) {
                        continue;
                    }

                    let appGroupName = allColumns[0];
                    let appGroupVms = [];

                    if (allColumns.length <= 1) {
                        continue;
                    }

                    for (let i = 1; i < allColumns.length; i++) {
                        let vmUuid: String;
                        let requiredVmName: String = allColumns[i];

                        // Find the UUID of the VM matching this VM name
                        let matchingVms = this.vms.filter((vm) => vm.name == requiredVmName);

                        for (let matchingVm of matchingVms) {
                            vmUuid = matchingVm.uuid;
                        }

                        // If an uuid has been found, add it to the app group
                        if (vmUuid !== undefined) {
                            appGroupVms.push({
                                uuid: vmUuid,
                                name: requiredVmName
                            });
                        }
                    }

                    // Add the apps groups
                    importedAppsGroups.push({
                        "name": appGroupName,
                        "vms": appGroupVms
                    });
                }

                isHeader = false;
            }

            this.appsGroupsFromCsv = importedAppsGroups;
            console.log("a => " + fileReader.result);
        }
        fileReader.readAsText(file);

        // Reset file in file input
        // @ts-ignore
        event.target.value = null;
    }


    open() {
        this.wizard.reset();
        this._open = true;
        this.rootTagSelection = [];
        this.rootCategorySelection = [];
        this.selectedTagStep2 = undefined;
        this.vmsCandidates = [];
        this.vmsSelectionMode = "automatic";
        this.action_choice = "manual_appgroup"
        this.appsGroupsFromCsv = [];

        // Populate tags
        Object.entries(this.tagsData.tags).map(([k, v]) => {
            let newCategory = {
                name: k,
                type: "category",
                selectable: false,
                selected: false,
                children: []
            }
            // @ts-ignore
            let tags = v.tags;
            Object.entries(tags).map(([k2, v2]) => {
                // @ts-ignore
                let vms = v2.vms;
                newCategory.children.push({
                    name: k2,
                    treeValue: `${k}.${k2}`,
                    category: k,
                    selectable: true,
                    selected: false,
                    type: "tag",
                    vms: vms
                })
            });
            this.rootTagSelection.push(newCategory);
        });

        // Populate categories
        Object.entries(this.tagsData.tags).map(([k, v]) => {
            let newCategory = {
                name: k,
                type: "category",
                selectable: true,
                selected: false,
                children: [],
                tags: []
            }
            // @ts-ignore
            let tags = v.tags;
            Object.entries(tags).map(([k2, v2]) => {
                // @ts-ignore
                let vms = v2.vms;
                newCategory.tags.push({
                    name: k2,
                    category: k,
                    selectable: true,
                    selected: false,
                    type: "tag",
                    vms: vms
                })
            });
            this.rootCategorySelection.push(newCategory);
        });
    }

    finish() {
        if (this.action_choice === "import_tag") {
            let newAppGroup = {
                name: this.selectedTag.name,
                type: "tag",
                vms: this.selectedVms.map((vm) => {
                    return {
                        name: vm.name,
                        uuid: vm.uuid
                    };
                }),
                metadata: {}
            };
            if (this.vmsSelectionMode === "automatic") {
                newAppGroup.metadata["trackVmWareCategory"] = this.selectedTag.category;
                newAppGroup.metadata["trackVmWareTag"] = this.selectedTag.name;
                newAppGroup.metadata["trackVmWareResourceTypes"] = ["tag"];
            }
            this.newAppGroupFromWizard.emit(new NewAppGroupFromVMwareTagEvent(newAppGroup));
        }
        if (this.action_choice === "import_category") {
            let newCategory = {
                name: this.selectedTagStep2,
                type: "category",
                tags: this.selectedCategory.tags.map((tag) => tag.name),
                metadata: {}
            };
            if (this.vmsSelectionMode === "automatic") {
                newCategory.metadata["trackVmWareCategory"] = this.selectedCategory.name;
            }

            for (let tagObj of this.selectedCategory.tags) {
                let vmShortUiidsOfCurrentTag = tagObj.vms.map((vmLongUiid) => vmLongUiid.split(":")[1]);
                let tagVmsCandidates = this.vms.filter((vm) => vmShortUiidsOfCurrentTag.indexOf(vm.uuid) !== -1);

                let newAppGroup = {
                    name: tagObj.name,
                    type: "tag",
                    vms: tagVmsCandidates.map((vm) => {
                        return {
                            name: vm.name,
                            uuid: vm.uuid
                        };
                    }),
                    metadata: {
                        trackVmWareCategory: newCategory.name,
                        trackVmWareTag: tagObj.name,
                        trackVmWareResourceTypes: ["category"]
                    }
                };
                this.newAppGroupFromWizard.emit(new NewAppGroupFromVMwareTagEvent(newAppGroup));
            }

            this.newAppGroupFromWizard.emit(new NewAppGroupFromVMwareCategoryEvent(newCategory));
        }
        if (this.action_choice === "manual_appgroup") {
            this.newAppGroupFromWizard.emit(new NewEmptyManualAppgroupEvent());
        }
        if (this.action_choice === "import_manual_appsgroups_from_csv") {
            this.newAppGroupFromWizard.emit(new MergeAppsGroupsWithTheseAppsGroupsEvent(this.appsGroupsFromCsv));
        }
    }

    getChildren = (node) => {
        return node.children;
    }

    onTagSelection = (tag) => {
        this.vmsCandidates = this.vms.filter((vm) => tag.vms.indexOf(vm.uuid) !== -1);
        this.selectedVms = this.vmsCandidates;
        this.selectedTag = tag;
    }

    onCategorySelection = (category) => {
        this.selectedCategory = category;
    }
}

