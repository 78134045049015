<clr-tabs>
	<clr-tab>
        	<button clrTabLink (click)="switchResource('cpu')">CPU</button>
		<clr-tab-content *clrIfActive="isCpu">
			<ng-template load-recommendation-resizing-detail-cpu></ng-template>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
        	<button clrTabLink (click)="switchResource('ram')">RAM</button>
		<clr-tab-content *clrIfActive="isRam">
			<ng-template load-recommendation-resizing-detail-ram></ng-template>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
        	<button clrTabLink (click)="switchResource('disk')">DISK</button>
		<clr-tab-content *clrIfActive="isDisk">
			<ng-template load-recommendation-resizing-detail-disk></ng-template>
		</clr-tab-content>
	</clr-tab>
</clr-tabs>
