<div style="margin-bottom:30px;"*ngIf="!storage_view">
	<label style="padding-left:100px;"><b>{{rp_res_use}}</b> {{rp_unit}} Used ({{rp_res_use_percent}}%)</label>
	<label style="float:right;"><b>{{rp_res_free}}</b> {{rp_unit}} Free</label>
	<div class="progress-block" style="margin-bottom:0;">
		<label style="width:100px">Reservation</label>
		<div class="progress success">
			<progress value="{{rp_res_use_percent}}" max="100"></progress>
		</div>
	</div>
	<label style="float:right;"><b>{{rp_res_total}}</b> {{rp_unit}} Total</label>
</div>
<div>
	<label style="padding-left:100px;"><b>{{rp_cons_use}}</b> {{rp_unit}} Used ({{rp_cons_use_percent}}%)</label>
	<label style="float:right;"><b>{{rp_cons_free}}</b> {{rp_unit}} Free</label>
	<div class="progress-block" style="margin-bottom:0;" >
		<label style="width:100px">Utilization</label>
		<div class="progress success">
			<progress value="{{rp_cons_use_percent}}" max="100"></progress>
		</div>
	</div>
	<label style="float:right;"><b>{{rp_cons_total}}</b> {{rp_unit}} Total</label>
</div>