<clr-combobox-container>
    <clr-combobox
          name="formResources"
          class="full-width-input"
          clrMulti="true"
          [(ngModel)]="selectedItems"
          [disabled]="disabled"
          [clrLoading]="loadingResources"
          (focus)="_focus()"
          (clrInputChange)="_clrInputChange($event)"
          (clrOpenChange)="_clrOpenChange($event)"
          (click)="_click(resourceComboBox)"
          (clrSelectionChange)="_clrSelectionChange($event)"
          #resourceComboBox
    >

    <ng-container *clrOptionSelected="let item">
      <ng-container *ngTemplateOutlet="templateSelectedItems; context: {item: item}">
      </ng-container>
    </ng-container>

    <ng-content select="[slot=displaySelectedItems]"></ng-content>

    <clr-options *ngIf="comboboxOptionsAreVisible">
      <clr-option *clrOptionItems="let item of asyncResources$ | async; field: itemsField" [clrValue]="item">
        <ng-container *ngTemplateOutlet="templateDisplayItems; context: {item: item}">
        </ng-container>
      </clr-option>
    </clr-options>
  </clr-combobox>

  <clr-control-helper>
    <ng-container *ngTemplateOutlet="templateDisplayWhenEmpty"></ng-container>
  </clr-control-helper>

  <clr-control-error>
    <ng-container *ngTemplateOutlet="templateDisplayWhenErrors"></ng-container>
  </clr-control-error>
</clr-combobox-container>
