import {
    Component,
    OnInit
} from '@angular/core';

import {
    InstanceType,
    Message
} from '@app/model';

import {
    MonitorService,
    ShareService
} from '@app/services';
import {
    Subscription
} from 'rxjs';


@Component({
    selector: 'app-simulation',
    templateUrl: './simulation.component.html',
    styleUrls: ['./simulation.component.css']
})
export class SimulationComponent implements OnInit {

    message: Message;

    isVmwareReady: boolean = false;
    isAwsReady: boolean = false;
    isAzureReady: boolean = false;
    isGcpReady: boolean = false;

    // For tabs
    tab: string;
    tab_subscription: Subscription;
    instancetype = InstanceType;

    /**
     * 
     */
    constructor(private message_svc: ShareService, private monitor_svc: MonitorService) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        if(this.message.isCO2Scope) {
            this.tab_subscription = this.monitor_svc.currentAppTab.subscribe((tab) => {
                this.tab = tab;
    
                this.isVmwareReady = false;
                this.isAzureReady = false;
                this.isAwsReady = false;
                this.isGcpReady = false;
    
                this.message.cloudProvider = tab;
    
                switch (tab) {
                    case InstanceType.VMWARE:
                        this.isVmwareReady = true;
                        break;
                    case InstanceType.AWS:
                        this.isAwsReady = true;
                        break;
                    case InstanceType.AZURE:
                        this.isAzureReady = true;
                        break;
                    case InstanceType.GCP:
                        this.isGcpReady = true;
                        break;
                    default:
                        break;
                }
            });
        } else {
            this.tab = InstanceType.VMWARE;
            this.isVmwareReady = true;
        }
    }
}
