import {
    Component,
    OnInit
} from '@angular/core';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';


@Component({
    selector: 'app-opportunities-detail',
    templateUrl: './opportunities-detail.component.html',
    styleUrls: ['./opportunities-detail.component.css']
})
export class OpportunitiesDetailComponent implements OnInit {

    dataOrder = ClrDatagridSortOrder.ASC;

    message: Message;

    isDcscope = true;
    isCo2scope = false;

    months: any = [];

    opportunitiesAll: any = [];

    opportunities: any = [];

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        setTimeout(() => this.initOpportunitiesData(), 100);
    }

    private initOpportunitiesData(): void {

        setTimeout(() => this.loadOpportunitiesData(), 500);
    }

    private loadOpportunitiesData(): void {

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_MONTH)
            .subscribe(
                data => {
                    this.months = data;
                    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                        .KUBERNETES_BEHAVIOR).subscribe(
                        data => {
                            this.opportunitiesAll = data;
                            let val: string = '';
                            val = this.months[0].name;
                            for (let obj of this.opportunitiesAll[val.replace(/ /g, "_")]) {
                                let action: string = 'none';
                                if (obj.behavior == "idle")
                                    action = 'turn off or delete the container if not used';

                                obj.action = action;

                                this.opportunities.push(obj);
                            }
                        }
                    );
                }
            );
    }
}
