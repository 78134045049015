import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    Subject
} from 'rxjs';

import {
    AccountService,
    JsonloaderService,
    MeasurementService,
    ShareService
} from '@app/services';

import {
    Counter,
    JSONTarget,
    Message,
    User
} from '@app/model';

import * as moment from 'moment';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import {
    GraphCounterStackComponent
} from '../graph-counter-stack/graph-counter-stack.component';
import {
    CloudgraphcounterstatckDirective
} from '../../../directives/cloudgraphcounterstatck.directive';


@Component({
    selector: 'app-graph-counter',
    templateUrl: './graph-counter.component.html',
    styleUrls: ['./graph-counter.component.css']
})
export class GraphCounterComponent implements OnInit {

    @ViewChild(CloudgraphcounterstatckDirective) addCloudGraphCounterStack: CloudgraphcounterstatckDirective;

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};
    chartOptions2: Highcharts.Options = {};
    chartOptions3: Highcharts.Options = {};
    chartOptions4: Highcharts.Options = {};
    chartOptions5: Highcharts.Options = {};

    combobox1: boolean = false;
    combobox2: boolean = false;
    combobox3: boolean = false;
    combobox4: boolean = false;
    combobox5: boolean = false;

    moment: any = moment;

    message: Message;

    instancesAll: any = [];

    instances: any = [];

    months: any = [];

    instance_selected: any = null;

    start: number = 0;

    end: number = 0;

    granularity: string = 'DAILY';

    cpuUsageMetrics: any = ['avg'];

    creditsMetrics: any = ['usage', 'balance'];

    ramUsageMetrics: any = ['avg'];

    netMetrics: any = ['avgNetIn', 'avgNetOut'];

    diskMetrics: any = ['avgDiskRead', 'avgDiskWrite'];

    isStack: boolean = false;

    isReady: boolean = false;
    isReady2: boolean = false;
    isReady3: boolean = false;
    isReady4: boolean = false;
    isReady5: boolean = false;

    currentUser: User;

    private requests_counter: Counter[] = [];

    private requests_counterstorage: Counter[] = [];


    constructor(
        private account_svc: AccountService,
        public componentFactoryResolver: ComponentFactoryResolver,
        private json_svc: JsonloaderService,
        private measurement_svc: MeasurementService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        if (this.message.cloudProvider == "aws")
            this.creditsMetrics.push('surplusCharged');

        this.start = moment().utc().startOf('month').unix() * 1000;
        this.end = moment().utc().startOf('day').unix() * 1000;
        let first_instance: any = null;

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_INSTANCE).subscribe(
            data => {
                this.instancesAll = data;
                this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this
                    .message.cloudProvider, JSONTarget.CLOUD_MONTH).subscribe(
                    data2 => {
                        this.months = data2;
                        let val: string = '';
                        val = this.months[0].name;
                        for (let obj of this.instancesAll[val.replace(/ /g, "_")]) {
                            if (obj.state != "destroy") {
                                first_instance = obj;
                                obj['fullName'] = obj.name + ' (' + obj.instanceId + ')';
                                this.instances.push(obj);
                            }
                        }

                        this.measurement_svc.getMinTimeHourly().subscribe(
                            data => {
                                this.start = data.minTime;
                                if (first_instance != null) {
                                    this.instance_selected = first_instance;

                                    this.measurement_svc.getInstanceCounters(
                                        first_instance.identifier,
                                        this.start,
                                        this.end,
                                        this.granularity).pipe(first()).subscribe(
                                        data => {
                                            this.requests_counter = this.requests_counter.concat(
                                                data);
                                            this.loadComputeGraphs();
                                        },
                                        error => {
                                            if (error != null)
                                                console.log(error)
                                        }
                                    );
                                    this.measurement_svc.getInstanceStorageCounters(
                                        first_instance.identifier,
                                        this.start,
                                        this.end,
                                        this.granularity).pipe(first()).subscribe(
                                        data => {
                                            this.requests_counterstorage = this
                                                .requests_counterstorage.concat(data);
                                            this.loadStorageGraphs();
                                        },
                                        error => {
                                            if (error != null)
                                                console.log(error)
                                        }
                                    );
                                }
                            },
                            error => {
                                if (error != null)
                                    console.log(error)
                            }
                        );

                    }
                );
            }
        );
    }

    cpu_click() {
        this.combobox1 = true;
    }

    credit_click() {
        this.combobox2 = true;
    }

    ram_click() {
        this.combobox3 = true;
    }

    disk_click() {
        this.combobox5 = true;
    }

    net_click() {
        this.combobox4 = true;
    }
    reloadGraph() {

        this.combobox1 = false;
        this.combobox2 = false;
        this.combobox3 = false;
        this.combobox4 = false;
        this.combobox5 = false;

        this.isReady = false;
        this.isReady2 = false;
        this.isReady3 = false;
        this.isReady4 = false;
        this.isReady5 = false;
        this.requests_counter = [];
        this.requests_counterstorage = [];

        let ins: any;
        if (this.message.cloudProvider == "aws") {
            let id: string = this.instance_selected.instanceId;
            ins = this.filterInstance(id);
        } else if (this.message.cloudProvider == "azure" || this.message.cloudProvider == "gcp") {
            ins = this.filterInstanceByName(this.instance_selected.name);
        }

        if (ins != null) {
            this.measurement_svc.getInstanceCounters(
                ins.identifier,
                this.start,
                this.end,
                this.granularity).pipe(first()).subscribe(
                data => {
                    this.requests_counter = this.requests_counter.concat(data);
                    this.loadComputeGraphs();
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
            this.measurement_svc.getInstanceStorageCounters(
                ins.identifier,
                this.start,
                this.end,
                this.granularity).pipe(first()).subscribe(
                data => {
                    this.requests_counterstorage = this.requests_counterstorage.concat(data);
                    this.loadStorageGraphs();
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    reloadGraphStack() {

        setTimeout(() => this.loadCloudGraphCounterStack(), 100);
    }

    private loadCloudGraphCounterStack(): void {

        if (this.addCloudGraphCounterStack != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                GraphCounterStackComponent);
            const viewContainerRef = this.addCloudGraphCounterStack.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadComputeGraphs() {

        let cpu_series: any = [];
        let credit_series: any = [];
        let ram_series: any = [];
        let net_series: any = [];

        let cpu_data_avg: any = [];
        let cpu_data_min: any = [];
        let cpu_data_max: any = [];

        let cpu_cu_data: any = [];
        let cpu_cb_data: any = [];
        let cpu_cc_data: any = [];

        let ram_data_avg: any = [];
        let ram_data_min: any = [];
        let ram_data_max: any = [];

        let netin_data_avg: any = [];
        let netin_data_min: any = [];
        let netin_data_max: any = [];
        let netout_data_avg: any = [];
        let netout_data_min: any = [];
        let netout_data_max: any = [];

        for (let i = 0; i < this.requests_counter.length; i++) {
            let cpu_usage_avg = [];
            let cpu_usage_min = [];
            let cpu_usage_max = [];
            cpu_usage_avg[0] = this.requests_counter[i].time;
            cpu_usage_min[0] = this.requests_counter[i].time;
            cpu_usage_max[0] = this.requests_counter[i].time;

            let cpu_credits_usage = [];
            let cpu_credits_balance = [];
            let cpu_credits_charged = [];
            cpu_credits_usage[0] = this.requests_counter[i].time;
            cpu_credits_balance[0] = this.requests_counter[i].time;
            cpu_credits_charged[0] = this.requests_counter[i].time;

            let ram_usage_avg = [];
            let ram_usage_min = [];
            let ram_usage_max = [];
            ram_usage_avg[0] = this.requests_counter[i].time;
            ram_usage_min[0] = this.requests_counter[i].time;
            ram_usage_max[0] = this.requests_counter[i].time;

            let net_in_avg = [];
            let net_in_min = [];
            let net_in_max = [];
            net_in_avg[0] = this.requests_counter[i].time;
            net_in_min[0] = this.requests_counter[i].time;
            net_in_max[0] = this.requests_counter[i].time;

            let net_out_avg = [];
            let net_out_min = [];
            let net_out_max = [];
            net_out_avg[0] = this.requests_counter[i].time;
            net_out_min[0] = this.requests_counter[i].time;
            net_out_max[0] = this.requests_counter[i].time;

            let result: any = this.toDict(this.requests_counter[i].dataPoints);
            cpu_usage_avg[1] = result.AVG_INSTANCE_CPU_INTRA;
            cpu_usage_min[1] = result.MIN_INSTANCE_CPU_INTRA;
            cpu_usage_max[1] = result.MAX_INSTANCE_CPU_INTRA;

            cpu_credits_usage[1] = result.CPU_CREDITUSAGE;
            cpu_credits_balance[1] = result.CPU_CREDITBALANCE;
            cpu_credits_charged[1] = result.CPU_SURPLUSCREDITSCHARGED;

            ram_usage_avg[1] = result.AVG_INSTANCE_RAM_INTRA;
            ram_usage_min[1] = result.MIN_INSTANCE_RAM_INTRA;
            ram_usage_max[1] = result.MAX_INSTANCE_RAM_INTRA;

            net_in_avg[1] = result.AVG_INSTANCE_NET_IN;
            net_in_min[1] = result.MIN_INSTANCE_NET_IN;
            net_in_max[1] = result.MAX_INSTANCE_NET_IN;

            net_out_avg[1] = result.AVG_INSTANCE_NET_OUT;
            net_out_min[1] = result.MIN_INSTANCE_NET_OUT;
            net_out_max[1] = result.MAX_INSTANCE_NET_OUT;

            cpu_data_avg.push(cpu_usage_avg);
            cpu_data_min.push(cpu_usage_min);
            cpu_data_max.push(cpu_usage_max);

            cpu_cu_data.push(cpu_credits_usage);
            cpu_cb_data.push(cpu_credits_balance);
            cpu_cc_data.push(cpu_credits_charged);

            ram_data_avg.push(ram_usage_avg);
            ram_data_min.push(ram_usage_min);
            ram_data_max.push(ram_usage_max);

            netin_data_avg.push(net_in_avg);
            netin_data_min.push(net_in_min);
            netin_data_max.push(net_in_max);

            netout_data_avg.push(net_out_avg);
            netout_data_min.push(net_out_min);
            netout_data_max.push(net_out_max);
        }

        if (this.cpuUsageMetrics.find(x => x === "avg")) {
            let cpuUsageAvg: any = {
                type: 'line',
                name: 'average cpu usage',
                data: cpu_data_avg
            };
            cpu_series.push(cpuUsageAvg);
        }

        if (this.cpuUsageMetrics.find(y => y === "min")) {
            let cpuUsageMin: any = {
                type: 'line',
                name: 'min cpu usage',
                data: cpu_data_min
            };
            cpu_series.push(cpuUsageMin);
        }

        if (this.cpuUsageMetrics.find(z => z === "max")) {
            let cpuUsageMax: any = {
                type: 'line',
                name: 'max cpu usage',
                data: cpu_data_max
            };
            cpu_series.push(cpuUsageMax);
        }
        this.loadCpuUsage(cpu_series);

        if (this.creditsMetrics.find(x => x === "usage")) {
            let creditUsage: any = {
                type: 'line',
                name: 'credits usage',
                data: cpu_cu_data
            };
            credit_series.push(creditUsage);
        }
        if (this.creditsMetrics.find(x => x === "balance")) {
            let creditBalance: any = {
                type: 'line',
                name: 'credits balance',
                data: cpu_cb_data
            };
            credit_series.push(creditBalance);
        }
        if (this.message.cloudProvider == "aws") {
            if (this.creditsMetrics.find(x => x === "surplusCharged")) {
                let creditSurplus: any = {
                    type: 'line',
                    name: 'credits surplus charged',
                    data: cpu_cc_data
                };
                credit_series.push(creditSurplus);
            }
        }

        this.loadCredits(credit_series);

        if (this.ramUsageMetrics.find(x => x === "avg")) {
            let ramUsageAvg: any = {
                type: 'line',
                name: 'average ram usage',
                data: ram_data_avg
            };
            ram_series.push(ramUsageAvg);
        }

        if (this.ramUsageMetrics.find(y => y === "min")) {
            let ramUsageMin: any = {
                type: 'line',
                name: 'min ram usage',
                data: ram_data_min
            };
            ram_series.push(ramUsageMin);
        }

        if (this.ramUsageMetrics.find(z => z === "max")) {
            let ramUsageMax: any = {
                type: 'line',
                name: 'max ram usage',
                data: ram_data_max
            };
            ram_series.push(ramUsageMax);
        }
        this.loadRamUsage(ram_series);

        if (this.netMetrics.find(x => x === "avgNetIn")) {
            let netInAvg: any = {
                type: 'line',
                name: 'average network in',
                data: netin_data_avg
            };
            net_series.push(netInAvg);
        }
        if (this.netMetrics.find(x => x === "minNetIn")) {
            let netInMin: any = {
                type: 'line',
                name: 'min network in',
                data: netin_data_min
            };
            net_series.push(netInMin);
        }
        if (this.netMetrics.find(x => x === "maxNetIn")) {
            let netInMax: any = {
                type: 'line',
                name: 'max network in',
                data: netin_data_max
            };
            net_series.push(netInMax);
        }
        if (this.netMetrics.find(x => x === "avgNetOut")) {
            let netOutAvg: any = {
                type: 'line',
                name: 'average network out',
                data: netout_data_avg
            };
            net_series.push(netOutAvg);
        }
        if (this.netMetrics.find(x => x === "minNetOut")) {
            let netOutMin: any = {
                type: 'line',
                name: 'min network out',
                data: netout_data_min
            };
            net_series.push(netOutMin);
        }
        if (this.netMetrics.find(x => x === "maxNetOut")) {
            let netOutMax: any = {
                type: 'line',
                name: 'max network out',
                data: netout_data_max
            };
            net_series.push(netOutMax);
        }
        this.loadNet(net_series);
    }

    private loadStorageGraphs() {

        let disk_series: any = [];

        let diskread_data_avg: any = [];
        let diskread_data_min: any = [];
        let diskread_data_max: any = [];

        let diskwrite_data_avg: any = [];
        let diskwrite_data_min: any = [];
        let diskwrite_data_max: any = [];

        let diskreadsec_data_avg: any = [];
        let diskreadsec_data_min: any = [];
        let diskreadsec_data_max: any = [];

        let diskwritesec_data_avg: any = [];
        let diskwritesec_data_min: any = [];
        let diskwritesec_data_max: any = [];

        for (let i = 0; i < this.requests_counterstorage.length; i++) {
            let disk_read_avg = [];
            let disk_read_min = [];
            let disk_read_max = [];

            let disk_write_avg = [];
            let disk_write_min = [];
            let disk_write_max = [];

            let disk_readsec_avg = [];
            let disk_readsec_min = [];
            let disk_readsec_max = [];

            let disk_writesec_avg = [];
            let disk_writesec_min = [];
            let disk_writesec_max = [];

            disk_read_avg[0] = this.requests_counterstorage[i].time;
            disk_read_min[0] = this.requests_counterstorage[i].time;
            disk_read_max[0] = this.requests_counterstorage[i].time;

            disk_write_avg[0] = this.requests_counterstorage[i].time;
            disk_write_min[0] = this.requests_counterstorage[i].time;
            disk_write_max[0] = this.requests_counterstorage[i].time;

            disk_readsec_avg[0] = this.requests_counterstorage[i].time;
            disk_readsec_min[0] = this.requests_counterstorage[i].time;
            disk_readsec_max[0] = this.requests_counterstorage[i].time;

            disk_writesec_avg[0] = this.requests_counterstorage[i].time;
            disk_writesec_min[0] = this.requests_counterstorage[i].time;
            disk_writesec_max[0] = this.requests_counterstorage[i].time;

            let result: any = this.toDict(this.requests_counterstorage[i].dataPoints);
            disk_read_avg[1] = result.AVG_INSTANCE_STORAGE_DISK_READ;
            disk_read_min[1] = result.MIN_INSTANCE_STORAGE_DISK_READ;
            disk_read_max[1] = result.MAX_INSTANCE_STORAGE_DISK_READ;

            disk_write_avg[1] = result.AVG_INSTANCE_STORAGE_DISK_WRITE;
            disk_write_min[1] = result.MIN_INSTANCE_STORAGE_DISK_WRITE;
            disk_write_max[1] = result.MAX_INSTANCE_STORAGE_DISK_WRITE;

            disk_readsec_avg[1] = result.AVG_INSTANCE_STORAGE_DISK_READSEC;
            disk_readsec_min[1] = result.MIN_INSTANCE_STORAGE_DISK_READSEC;
            disk_readsec_max[1] = result.MAX_INSTANCE_STORAGE_DISK_READSEC;

            disk_writesec_avg[1] = result.AVG_INSTANCE_STORAGE_DISK_WRITESEC;
            disk_writesec_min[1] = result.MIN_INSTANCE_STORAGE_DISK_WRITESEC;
            disk_writesec_max[1] = result.MAX_INSTANCE_STORAGE_DISK_WRITESEC;

            diskread_data_avg.push(disk_read_avg);
            diskread_data_min.push(disk_read_min);
            diskread_data_max.push(disk_read_max);

            diskwrite_data_avg.push(disk_write_avg);
            diskwrite_data_min.push(disk_write_min);
            diskwrite_data_max.push(disk_write_max);

            diskreadsec_data_avg.push(disk_readsec_avg);
            diskreadsec_data_min.push(disk_readsec_min);
            diskreadsec_data_max.push(disk_readsec_max);

            diskwritesec_data_avg.push(disk_writesec_avg);
            diskwritesec_data_min.push(disk_writesec_min);
            diskwritesec_data_max.push(disk_writesec_max);
        }

        if (this.diskMetrics.find(x => x === "avgDiskRead")) {
            let diskReadAvg: any = {
                type: 'line',
                name: 'average disk read',
                data: diskread_data_avg
            };
            disk_series.push(diskReadAvg);
        }
        if (this.diskMetrics.find(x => x === "minDiskRead")) {
            let diskReadMin: any = {
                type: 'line',
                name: 'min disk read',
                data: diskread_data_min
            };
            disk_series.push(diskReadMin);
        }
        if (this.diskMetrics.find(x => x === "maxDiskRead")) {
            let diskReadMax: any = {
                type: 'line',
                name: 'max disk read',
                data: diskread_data_max
            };
            disk_series.push(diskReadMax);
        }
        if (this.diskMetrics.find(x => x === "avgDiskWrite")) {
            let diskWriteAvg: any = {
                type: 'line',
                name: 'average disk write',
                data: diskwrite_data_avg
            };
            disk_series.push(diskWriteAvg);
        }
        if (this.diskMetrics.find(x => x === "minDiskWrite")) {
            let diskWriteMin: any = {
                type: 'line',
                name: 'min disk write',
                data: diskwrite_data_min
            };
            disk_series.push(diskWriteMin);
        }
        if (this.diskMetrics.find(x => x === "maxDiskWrite")) {
            let diskWriteMax: any = {
                type: 'line',
                name: 'max disk write',
                data: diskwrite_data_max
            };
            disk_series.push(diskWriteMax);
        }
        if (this.diskMetrics.find(x => x === "avgDiskReadSec")) {
            let diskReadSecAvg: any = {
                type: 'line',
                name: 'average disk read seconde',
                data: diskreadsec_data_avg
            };
            disk_series.push(diskReadSecAvg);
        }
        if (this.diskMetrics.find(x => x === "minDiskReadSec")) {
            let diskReadSecMin: any = {
                type: 'line',
                name: 'min disk read seconde',
                data: diskreadsec_data_min
            };
            disk_series.push(diskReadSecMin);
        }
        if (this.diskMetrics.find(x => x === "maxDiskReadSec")) {
            let diskReadSecMax: any = {
                type: 'line',
                name: 'max disk read seconde',
                data: diskreadsec_data_max
            };
            disk_series.push(diskReadSecMax);
        }
        if (this.diskMetrics.find(x => x === "avgDiskWriteSec")) {
            let diskWriteSecAvg: any = {
                type: 'line',
                name: 'average disk write seconde',
                data: diskwritesec_data_avg
            };
            disk_series.push(diskWriteSecAvg);
        }
        if (this.diskMetrics.find(x => x === "minDiskWriteSec")) {
            let diskWriteSecMin: any = {
                type: 'line',
                name: 'min disk write seconde',
                data: diskwritesec_data_min
            };
            disk_series.push(diskWriteSecMin);
        }
        if (this.diskMetrics.find(x => x === "maxDiskWriteSec")) {
            let diskWriteSecMax: any = {
                type: 'line',
                name: 'max disk write seconde',
                data: diskwritesec_data_max
            };
            disk_series.push(diskWriteSecMax);
        }
        this.loadDisk(disk_series);
    }

    private loadCpuUsage(data: any) {

        let title: string = '';

        this.chartOptions = {
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        align: 'left',
                    }
                },
                events: {
                    load: function() {
                        const chart = this;
                        chart.showResetZoom();
                    }
                }
            },
            title: {
                text: title
            },
            xAxis: {
                type: 'datetime',
                events: {
                    setExtremes: function(event) {
                        this.start = Math.round(event.min);
                        this.end = Math.round(event.max);
                        if (this.end - this.start <= 86400000)
                            this.granularity = 'HOURLY';
                        else
                            this.granularity = 'DAILY';

                        this.reloadGraph();
                    }.bind(this)
                }
            },
            yAxis: {
                title: {
                    text: '%'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y:.2f}</b><br/>'
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: data
        };
        this.isReady = true;
    }

    private loadRamUsage(data: any) {

        let title: string = '';

        this.chartOptions5 = {
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        align: 'left',
                    }
                },
                events: {
                    load: function() {
                        const chart = this;
                        chart.showResetZoom();
                    }
                }
            },
            title: {
                text: title
            },
            xAxis: {
                type: 'datetime',
                events: {
                    setExtremes: function(event) {
                        this.start = Math.round(event.min);
                        this.end = Math.round(event.max);
                        if (this.end - this.start <= 86400000)
                            this.granularity = 'HOURLY';
                        else
                            this.granularity = 'DAILY';

                        this.reloadGraph();
                    }.bind(this)
                }
            },
            yAxis: {
                title: {
                    text: '%'
                }
            },
            legend: {
                enabled: false
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: data
        };
        this.isReady5 = true;
    }

    private loadCredits(data: any) {

        let title: string = '';
        let titleSize: string = '20px';

        this.chartOptions2 = {
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        align: 'left',
                    }
                },
                events: {
                    load: function() {
                        const chart = this;
                        chart.showResetZoom();
                    }
                }
            },
            title: {
                text: title
            },
            xAxis: {
                type: 'datetime',
                events: {
                    setExtremes: function(event) {
                        this.start = Math.round(event.min);
                        this.end = Math.round(event.max);
                        if (this.end - this.start <= 86400000)
                            this.granularity = 'HOURLY';
                        else
                            this.granularity = 'DAILY';

                        this.reloadGraph();
                    }.bind(this)
                }
            },
            yAxis: {
                title: {
                    text: 'credits'
                }
            },
            legend: {
                enabled: false
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: data
        };
        this.isReady2 = true;
    }

    private loadNet(data: any) {

        let title: string = '';
        let titleSize: string = '20px';

        this.chartOptions3 = {
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        align: 'left',
                    }
                },
                events: {
                    load: function() {
                        const chart = this;
                        chart.showResetZoom();
                    }
                }
            },
            title: {
                text: title
            },
            xAxis: {
                type: 'datetime',
                events: {
                    setExtremes: function(event) {
                        this.start = Math.round(event.min);
                        this.end = Math.round(event.max);
                        if (this.end - this.start <= 86400000)
                            this.granularity = 'HOURLY';
                        else
                            this.granularity = 'DAILY';

                        this.reloadGraph();
                    }.bind(this)
                }
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: false
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: data
        };
        this.isReady3 = true;
    }

    loadDisk(data: any) {

        let title: string = '';
        let titleSize: string = '20px';

        this.chartOptions4 = {
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        align: 'left',
                    }
                },
                events: {
                    load: function() {
                        const chart = this;
                        chart.showResetZoom();
                    }
                }
            },
            title: {
                text: title
            },
            xAxis: {
                type: 'datetime',
                events: {
                    setExtremes: function(event) {
                        this.start = Math.round(event.min);
                        this.end = Math.round(event.max);
                        if (this.end - this.start <= 86400000)
                            this.granularity = 'HOURLY';
                        else
                            this.granularity = 'DAILY';

                        this.reloadGraph();
                    }.bind(this)
                }
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: false
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: data
        };
        this.isReady4 = true;
    }

    private filterInstance(id: string): any {

        return this.instances.find(item => item.instanceId === id);
    }

    private filterInstanceByName(name: string): any {

        return this.instances.find(item => item.name === name);
    }

    private toDict(dataPoints: any): any {

        var result = {};
        dataPoints.forEach(dp => {
            result[dp.metricName] = dp.value
        });

        return result;
    }

    initInstanceList(): void {

        this.instance_selected = null;
    }
}
