<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th>VIRTUAL MACHINE</th>
			<th>ACTION SHOULD BE APPLIED</th>
			<th>GAIN(%)</th>
			<th>BEHAVIOR</th>
			<th>COMMENT</th>
			<th>SERVER</th>
			<th>vCPU</th>
			<th>AVG CONSUMPTION(%)</th>
			<th>AVG READY(%)</th>
			<th>MAX READY(%)</th>
			<th>AVG READY(ms)</th>
			<th>AVG COSTOP(%)</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let reco of data_elements">
			<td>{{reco.name}}</td>
			<td *ngIf="reco.reco_cpu < 0; else addCpu">remove {{math.abs(reco.reco_cpu)}} vcpu</td>
			<ng-template #addCpu>
				<td *ngIf="reco.reco_cpu > 0; else none">add {{reco.reco_cpu}} vcpu</td>
			</ng-template>
			<ng-template #none>
				<td>none</td>
			</ng-template>
			<td>{{reco.cpu_gain}}</td>
			<td>{{reco.behavior}}</td>
			<td>{{reco.comment}}</td>
			<td>{{reco.server}}</td>
			<td>{{reco.vcpu}}</td>
			<td>{{reco.avg_cpu_cons}}</td>
			<td>{{(reco.avg_cpu_rdy/200/reco.vcpu).toFixed(2)}}</td>
			<td>{{(reco.max_cpu_rdy/200/reco.vcpu).toFixed(2)}}</td>
			<td>{{reco.avg_cpu_rdy}}</td>
			<td>{{(reco.avg_cpu_cstp/200/reco.vcpu).toFixed(2)}}</td>
		</tr>
	</tbody>
</table>
<!-- POWERSHELL MODAL -->
<clr-modal [(clrModalOpen)]="message.isModalPowershellCpu" [clrModalSize]="'xl'">
	<h3 class="modal-title">Choose virtual machines</h3>
	<div class="modal-body" style="overflow:auto">
		<div style="width:100%">
			<table datatable [dtOptions]="powershell_dtOptions" [dtTrigger]="dtTrigger2" style="margin-top:10px" class="table table-compact">
		    		<thead>
					<tr>
						<th></th>
						<th>VIRTUAL MACHINE</th>
						<th>ACTION WILL BE APPLIED</th>
						<th>GAIN(%)</th>
						<th>SERVER</th>
						<th>vCPU</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let reco of data_elements">
						<td>
							<div class="clr-checkbox-wrapper" style="display: inline-block;">
								<input type='checkbox' clrCheckbox [value]="reco.uuid" (click)="manageElement($event.target);" />
							</div>
						</td>
						<td>{{reco.name}}</td>
						<td *ngIf="reco.reco_cpu < 0; else addCpu">remove {{math.abs(reco.reco_cpu)}} vcpu</td>
						<ng-template #addCpu>
							<td *ngIf="reco.reco_cpu > 0; else none">add {{reco.reco_cpu}} vcpu</td>
						</ng-template>
						<ng-template #none>
							<td>none</td>
						</ng-template>
						<td>{{reco.cpu_gain}}</td>
						<td>{{reco.server}}</td>
						<td>{{reco.vcpu}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="exportPowershell()">Export</button>
	</div>
</clr-modal>
<!-- INFO MODAL -->
<clr-modal [(clrModalOpen)]="info_modal" [clrModalClosable]=false [clrModalSize]="'sm'">
    <h3 class="modal-title">{{info_header}}</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>{{info_body}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="info_modal = false">Ok</button>
    </div>
</clr-modal>
