<div>
    <div role="tooltip" class="tooltip tooltip-lg tooltip-bottom-right">
        <div class="info-custom">
            <ul>
                <li><b>Embodied energy</b> is the energy consumed throughout the life cycle of a product. It includes all the production stages of the product since the extraction of materials, transformation, manufacture, transport, installation, and maintenance as well as the energy consumed for its recycling. The embodied energy does not include direct energy.</li>
                <li><b>Direct energy</b> is the energy that is consumed by the product during its use.</li>
            </ul>
        </div>
        <span class="tooltip-content info-tooltip-header"><b>Embodied energy :</b> To calculate the embodied energy, DC Scope analyses the information from your infrastructure (automatically updated from your vCenter), the information you provided for other IT equipment as desktops and devices (screens, keyboards, etc.), the average age of your equipment, and CO2eq / kWh emissions.&#10;&#10;
            <br><br><b>Direct energy :</b> DC Scope measures the direct energy of your data center and calculates the equivalent emissions of CO2 for each server.
        </span>
    </div>
</div>

<div class="clr-row">
    <!-- T2 -->
    <div class="clr-col-lg-6 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="tree"></cds-icon>&nbsp;Carbon footprint evolution
                    <button class="btn btn-link" style="position:relative;float:right;" (click)="displayModalEfficiency()">
                        <cds-icon shape="rack-server"></cds-icon>&nbsp;Servers efficiency list
                    </button>
                    <button class="btn btn-link" style="position:relative;float:right;text-transform:none;margin-right:50px;cursor:default;">
                        Annual kg CO2eq : {{formatNumber(child.annual_co2)}}
                    </button>
                </h5>
            </div>
            <div class="card-block">
                <app-greenitcf [target]="'dc.host.co2.total'"></app-greenitcf>
            </div>
        </div>
    </div>
    <!-- T4 : same as T2 -->
    <div class="clr-col-lg-6 clr-col-md-8 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="tree"></cds-icon>&nbsp;kWh evolution

                    <button class="btn btn-link" style="position:relative;float:right;" (click)="displayModalOptimization()">
                        <cds-icon shape="rack-server"></cds-icon>&nbsp;With optimization : {{formatNumber(child.optimization_kwh)}}
                    </button>

                    <button class="btn btn-link" style="position:relative;float:right;text-transform:none;margin-right:50px;cursor:default;">
                        Annual kWh : {{formatNumber(child.annual_kwh)}} <span style="font-style:italic;">&nbsp;({{formatNumber(child.annual_cost) + " " + globalCurrency}})</span>
                    </button>
                </h5>
            </div>
            <div class="card-block">
                <app-greenitcf [show_nbhost]=true [target]="'dc.host.wh.total'"></app-greenitcf>
            </div>
        </div>
    </div>
</div>
<div class="clr-row" style="padding-bottom:20px;">
    <!-- T3 -->
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="line-chart"></cds-icon>&nbsp;Informations about VM
                </h5>
            </div>
            <div class="card-block">
                <app-greenitvm></app-greenitvm>
            </div>
        </div>
    </div>
</div>      

<app-efficiencylist #child [target]="target" [efficiency_data]="efficiency_data" [renewal_server_data]="renewal_data" (update)="initCEE()"></app-efficiencylist>
