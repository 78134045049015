import * as d3 from "d3";
import {
    NetscopeCommonFlowviewComponent
} from "@app/netscope/netscope-common-flowview/netscope-common-flowview.component";

export class ProtocolAnalysisRenderer {

    labelsLevel = [
        'protocols',
        'protocol',
        'source',
        'destination'
    ];

    computeIdsAndLabels = (protocolData) => {
        protocolData.object.svg_id = this.computeSvgId(protocolData);
        protocolData.object.svg_label = this.computeLabel(protocolData);
        for (let child of protocolData.children) {
            this.computeIdsAndLabels(child);
        }
    }

    computeLabel = (data) => {
        // Compute the left part of the text inside circles.
        // By default, it uses the labelsLevel, and if a description is provided from the API response,
        // then it uses the label returned from the API.
        let label = this.labelsLevel[data.depth];
        // @ts-ignore
        if (data.object.type !== undefined) {
            // @ts-ignore
            label = data.object.type;
        }
        // Also, VMs can be source or destination. This information is set in "d.data.role" property.
        // It will override the value computed above.
        // @ts-ignore
        if (data.role !== undefined) {
            // @ts-ignore
            label = data.role;
        }
        return label;
    }

    fixUuidToBeUsedInCirclesId = (uuid) => {
        return uuid
            .replaceAll(":", "_")
            .replaceAll(" ", "__")
            .replaceAll(".", "_");
    }

    computeSvgId = (data) => {
        let prefix = "";
        if (data.object.parent !== null && data.object.parent !== undefined) {
            prefix = this.computeSvgId(data.object.parent);
        }
        let label = this.computeLabel(data);
        let fixedUuid = this.fixUuidToBeUsedInCirclesId(data.object.uuid);
        // @ts-ignore
        return `${prefix}_${label}_${fixedUuid}`;
    }

    setParents = (protocolData) => {
        for (let child of protocolData.children) {
            child.object.parent = protocolData
            this.setParents(child);
        }
    }

    render(graphData, flowComponent: NetscopeCommonFlowviewComponent, zoomedProtocols): void {

        // Compute ids and labels on protocol data
        this.setParents(flowComponent.graphData.protocols);
        this.computeIdsAndLabels(flowComponent.graphData.protocols);

        d3.select('div#divSvg').select('svg').remove();
        flowComponent.svg = d3.select('div#divSvg')
            .append('svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .append('g')
            .attr('id', 'graph_svg');

        const graphSvg = d3.select('g#graph_svg');
        graphSvg.selectAll('*').remove();

        let svgHeight = document.getElementById('divSvg').clientHeight;
        let svgWidth = document.getElementById('divSvg').clientWidth;

        if (svgHeight == 0) {
            svgHeight = flowComponent.lastSvgHeight;
        } else {
            flowComponent.lastSvgHeight = svgHeight;
        }

        if (svgWidth == 0) {
            svgWidth = flowComponent.lastSvgWidth;
        } else {
            flowComponent.lastSvgWidth = svgWidth;
        }

        if (svgHeight == 0) {
            svgHeight = flowComponent.lastSvgHeight;
        } else {
            flowComponent.lastSvgHeight = svgHeight;
        }

        if (svgWidth == 0) {
            svgWidth = flowComponent.lastSvgWidth;
        } else {
            flowComponent.lastSvgWidth = svgWidth;
        }

        const viewCenterX = svgWidth / 2;
        const viewCenterY = svgHeight / 2;

        const margin = 40;
        const diameter = Math.min(svgWidth, svgHeight);

        const color = d3.scaleLinear()
            .domain([0, 5])
            // @ts-ignore
            .range(['hsl(152,80%,80%)', 'hsl(228,30%,40%)'])
            // @ts-ignore
            .interpolate(d3.interpolateHcl);

        const pack = d3.pack()
            .size([svgWidth, svgHeight])
            .padding(2);

        const root = d3.hierarchy(flowComponent.replayData.protocols)
            .sum(function(d) {
                return d.size;
            })
            .sort(function(a, b) {
                return b.value - a.value;
            });

        let focus = root;
        const nodes = pack(root).descendants();
        let view;

        const g = graphSvg.append('g')
            .attr('transform', 'translate(' + diameter / 2 + ',' + diameter / 2 + ')');

        const visuNode = g
            .selectAll('g')
            .data(nodes)
            .enter()
            .append('g')
            .attr('class', 'circleGroup');

        const self = this;

        const circle = visuNode
            .append('circle')
            .attr('class', function(d) {
                return d.parent ? d.children ? 'node' : 'node node--leaf' : 'node node--root';
            })
            // @ts-ignore
            .attr('id', (d) => d.data.object.svg_id)
            .style('fill', function(d) {
                return color(d.depth);
            })
            .style('cursor', 'pointer')
            // @ts-ignore
            .on('click', function(d, i) {
                if (focus !== i) {
                    d.stopPropagation();
                    zoom(i);
                }
            });

        const text = visuNode
            .append('text')
            .attr('id', (d) => {
                // @ts-ignore
                return `${d.data.object.svg_id}_text`;
            })
            .attr('class', 'textLabel')
            .style("text-anchor", "middle")
            .style('display', function(d) {
                return 'none';
            })
            .text((d) => {
                // @ts-ignore
                let label = d.data.object.svg_label;
                // @ts-ignore
                return `${label}: ${d.data.object.name}`;
            })
            .each((d) => {
                // @ts-ignore
                d.circleId = d.data.object.svg_id;
                // @ts-ignore
                d.circle = document.getElementById(d.circleId);
                return d;
            })
            .style('cursor', 'pointer')
            // @ts-ignore
            .on('click', function(d, i) {
                if (focus !== i) {
                    d.stopPropagation();
                    zoom(i);
                }
            });

        const node = g.selectAll('circle,text');

        graphSvg
            .style('background', color(-1));

        let previousData;

        const zoom = (d) => {
            const focus0 = focus;
            focus = d;
            flowComponent.focusLevel = focus.depth + 1;
            flowComponent.focusNode = focus;

            flowComponent.currentSelection = [];
            let next = focus;
            while (next !== undefined && next !== null) {
                flowComponent.currentSelection.unshift(next);
                next = next.parent;
            }
            flowComponent.updatePerProtocolFlows();

            let isMatchingNode = (node) => {
                if (flowComponent.currentSelection.indexOf(node) !== -1) {
                    return true;
                }
                if (flowComponent.focusNode.children !== undefined && flowComponent.focusNode.children
                    .indexOf(node) !== -1) {
                    return true;
                }
                return false;
            };

            let isParentNode = (node) => {
                return flowComponent.currentSelection.indexOf(node) !== -1 && node.children !== undefined &&
                    node.children.length > 0;
            }

            let matchingVisuNode = visuNode
                .filter((d2) => isMatchingNode(d2));

            let nonMatchingVisuNode;
            if (previousData !== undefined) {
                nonMatchingVisuNode = previousData.matchingVisuNode;
            } else {
                nonMatchingVisuNode = visuNode
                    .filter((d2) => !isMatchingNode(d2));
            }

            let parentNodes = visuNode
                .filter((d2) => isParentNode(d2));

            setTimeout(() => {
                const transition = d3.transition()
                    // @ts-ignore
                    .duration(750)
                    .tween('zoom', function(d2) {
                        // @ts-ignore
                        const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2 + margin]);
                        return function(t) {
                            zoomTo(i(t));
                        };
                    });
            }, 50);

            nonMatchingVisuNode
                .selectAll('.textLabel')
                .style('display', (d2) => 'none');

            // Hide other circles
            nonMatchingVisuNode
                .selectAll('circle')
                .style('display', (d2) => 'none');

            // Show circles that are in [0, this.focusLevel] and the circles just below
            matchingVisuNode
                .selectAll('circle')
                .style('display', (d2) => '');

            matchingVisuNode
                .selectAll('.textLabel')
                .style('display', (d2) => 'inline');

            parentNodes
                .selectAll('.textLabel')
                .style('display', (d2) => 'none');

            function updateTextSize(minimumIteration, currentIterationCount = 0, startTime = -1,
                minimumDuration = 2.0) {
                if (startTime == -1) {
                    startTime = Math.floor(new Date().getTime() / 1000.0);
                }
                const text2 = matchingVisuNode
                    .select('.textLabel')
                    .filter((d2) => d2 !== focus.parent)
                    .style("font-size", (d, i) => {
                        // @ts-ignore
                        let newTextWidth = d.circle.r.animVal.value / 5;

                        // @ts-ignore
                        let textId = `${d.circleId}_text`;
                        if (!d.hasOwnProperty(textId)) {
                            // @ts-ignore
                            let textSvgElement = document.getElementById(textId);
                            // @ts-ignore
                            let currentTextWidth = textSvgElement.getComputedTextLength();
                            let currentFontSize = parseFloat(textSvgElement.style['font-size'].replace('px',
                                ''));

                            if (currentTextWidth !== 0 && !isNaN(currentFontSize)) {
                                // @ts-ignore
                                d[textId] = currentFontSize / currentTextWidth;
                            }
                        }

                        if (d.hasOwnProperty(textId)) {
                            // @ts-ignore
                            newTextWidth = (0.90 * 2 * newTextWidth * 5) * d[textId];
                        }
                        // @ts-ignore
                        d.textWidth = newTextWidth;
                        // @ts-ignore
                        return `${d.textWidth}px`;
                    });
                let now = Math.floor(new Date().getTime() / 1000.0);
                if (currentIterationCount < minimumIteration || (now - startTime < minimumDuration)) {
                    setTimeout(() => {
                        updateTextSize(minimumIteration, currentIterationCount + 1, startTime,
                            minimumDuration);
                    }, 1);
                }
            }

            updateTextSize(10);
            setTimeout(() => {
                updateTextSize(10);
            }, 1000);

            previousData = {
                matchingVisuNode: matchingVisuNode,
                nonMatchingVisuNode: nonMatchingVisuNode,
                parentNodes: parentNodes,
            }
        };

        function zoomTo(v) {
            const k = diameter / v[2];
            view = v;
            // @ts-ignore
            node.attr('transform', function(d) {
                // @ts-ignore
                return 'translate(' + (d.x - v[0]) * k + ',' + (d.y - v[1]) * k + ')';
            });
            circle.attr('r', function(d) {
                return d.r * k;
            });
        }

        // @ts-ignore
        zoomTo([root.x, root.y, root.r * 2 + margin]);
        zoom(root);
        flowComponent.currentSelection = [root];
        flowComponent.updatePerProtocolFlows();

        flowComponent.zoomFunction = zoom;

        if (zoomedProtocols !== undefined) {
            circle.filter((d) => {
                // @ts-ignore
                return d.data.object.name === zoomedProtocols;
            }).each((n) => {
                setTimeout(() => {
                    zoom(n);
                }, 300);
            })
        }
    }
}

