<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="heat-map"></cds-icon>
			&nbsp;Current Virtual Machine Behavior
			<span id="t1-nbvm" class="label">{{data_t1.length}}</span>
		</h5>
	</div>
	<div class="card-block" id="dashboard-t1-core">
		<div class="card-text">
			<div [ngClass]="link_class" *ngIf="isReady">
				<highcharts-chart id="t1-map"
								  [Highcharts]="Highcharts"
								  [callbackFunction]="chartCallback"
					[options]="chartOptions"
					style="width: 100%; display: block; height: 275px; overflow: hidden;" *ngIf="isReady">
				</highcharts-chart>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
		</div>
	</div>
</div>