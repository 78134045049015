<div *ngIf="greenit_alert" class="alert alert-info" role="alert">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
            </div>
            <span class="alert-text">If you have just created your filter, data will be available tomorrow</span>
        </div>
    </div>
</div>
<highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;" ></highcharts-chart>
