import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    LicenseComponent
} from '@app/license/license.component';
import {
    LicenseType
} from '@app/license/license.enums';
import {
    InstanceFullName,
    LicenseInfo,
    Message,
    Status
} from '@app/model';
import {
    LicenseService,
    MonitorService,
    ShareService
} from '@app/services';

@Component({
    selector: 'app-alertmenu',
    templateUrl: './alertmenu.component.html',
    styleUrls: ['./alertmenu.component.css']
})
export class AlertmenuComponent implements OnInit {
    @ViewChild("license", {
        static: true
    }) licenseChild: LicenseComponent;

    message: Message;

    //License
    license_info: LicenseInfo;
    modal: LicenseType = LicenseType.MODAL;

    //Status
    instance_global_alert: number;
    instance_details: Array < Status > ;
    db_global_alert: number;
    db_details: Array < Status > ;

    /**
     * 
     */
    constructor(private message_svc: ShareService, private license_svc: LicenseService,
        private monitor_svc: MonitorService) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.monitor_svc.instanceGlobal.subscribe(global => this.instance_global_alert = global);
        this.monitor_svc.instanceDetails.subscribe(details => this.instance_details = details);
        this.monitor_svc.dbGlobal.subscribe(global => this.db_global_alert = global);
        this.monitor_svc.dbDetails.subscribe(details => this.db_details = details);

        this.license_svc.licenseInfo.subscribe(infos => {
            this.license_info = infos
        });
    }

    /**
     * 
     */
    getInstanceFullname(type: string): string {
        return InstanceFullName[type.toUpperCase()];
    }

    /**
     *
     */
    formatDate(time: number, showhour: boolean): string {
        if (!showhour)
            return new Date(time).toLocaleDateString('en-EN');
        else
            return new Date(time).toLocaleString('en-EN');
    }

    /**
     *
     */
    callLicense(): void {
        this.licenseChild.target = LicenseType.MODAL;
        this.licenseChild.displayModalLicense();
    }

}
