import {
    Component,
    OnInit
} from '@angular/core';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';


@Component({
    selector: 'app-greenit-detail',
    templateUrl: './greenit-detail.component.html',
    styleUrls: ['./greenit-detail.component.css']
})
export class GreenitDetailComponent implements OnInit {

    dataOrder = ClrDatagridSortOrder.DESC;

    message: Message;

    isDcscope = true;
    isCo2scope = false;

    months: any = [];

    greenitAll: any = [];

    greenit: any = [];

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        setTimeout(() => this.initGreenitData(), 100);
    }

    private initGreenitData(): void {

        setTimeout(() => this.loadGreenitData(), 500);
    }

    private loadGreenitData(): void {

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_MONTH)
            .subscribe(
                data => {
                    this.months = data;
                    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                        .KUBERNETES_GREENIT).subscribe(
                        data => {
                            this.greenitAll = data;
                            let val: string = '';
                            val = this.months[0].name;
                            for (let obj of this.greenitAll[val.replace(/ /g, "_")]) {
                                this.greenit.push(obj);
                            }
                        }
                    );
                }
            );
    }
}
