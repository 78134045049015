import {
    Component,
    OnInit
} from '@angular/core';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);


@Component({
    selector: 'app-kpi-savingsplan',
    templateUrl: './kpi-savingsplan.component.html',
    styleUrls: ['./kpi-savingsplan.component.css']
})
export class KpiSavingsplanComponent implements OnInit {

    message: Message;

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};

    dataOrder = ClrDatagridSortOrder.ASC;

    kpiSavingsplans: any = [];

    selected_sp: any;

    isReady: boolean = true;

    region: string = '';

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_KPI_SAVINGSPLAN).subscribe(
            data => {
                this.kpiSavingsplans = data;
            }
        );
    }

    convertDate(end: number): any {

        let date = new Date(end).toLocaleDateString("en-US")
        return date;
    }

    spChanged(event: any) {

        let cat: any = [];
        let over: any = [];
        let data: any = [];
        let under: any = [];
        let min: number = 0;

        for (let i in this.kpiSavingsplans) {
            if (this.kpiSavingsplans[i].uuid == event.uuid) {
                for (let j in this.kpiSavingsplans[i].counter) {
                    cat.push(this.kpiSavingsplans[i].counter[j].timeago);
                    if (this.kpiSavingsplans[i].counter[j].usageRate > 100) {
                        over.push(this.kpiSavingsplans[i].counter[j].usageRate);
                        data.push(null);
                        under.push(null);
                    } else if (this.kpiSavingsplans[i].counter[j].usageRate < 90) {
                        over.push(null);
                        data.push(null);
                        under.push(this.kpiSavingsplans[i].counter[j].usageRate);
                        if (min == 0) {
                            min = this.kpiSavingsplans[i].counter[j].usageRate
                        } else {
                            if (this.kpiSavingsplans[i].counter[j].usageRate < min)
                                min = this.kpiSavingsplans[i].counter[j].usageRate
                        }
                    } else {
                        over.push(null);
                        data.push(this.kpiSavingsplans[i].counter[j].usageRate);
                        under.push(null);
                    }
                }
            }
        }
        let series: any = [];
        let serie0: any = {
            type: 'column',
            name: 'usage rate',
            data: over,
            color: '#CD7F32'
        };
        series.push(serie0);
        let serie1: any = {
            type: 'column',
            name: 'usage rate',
            data: data,
            color: '#FFCC00'
        };
        series.push(serie1);
        let serie2: any = {
            type: 'column',
            name: 'usage rate',
            data: under,
            color: '#C0C0C0'
        };
        series.push(serie2);

        this.loadGraph(cat, series, min);
    }

    private loadGraph(cat: any, series: any, min: number): void {

        let title: string = 'usage rate over the current month';
        let titleSize: string = '20px';

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            legend: {
                enabled: false
            },
            xAxis: {
                categories: cat,
                title: {
                    text: 'day of month'
                },
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat('%d', this.value);
                    }
                }
            },
            yAxis: [{
                title: {
                    text: '%',
                },
                min: min
            }],
            chart: {
                plotBorderWidth: null,
                plotShadow: false
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b> %<br/>'
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        text: "download",
                        symbolStroke: "#0072A3",
                        x: -10
                    }
                }
            },
            series: series
        };
        this.isReady = true;
    }
}
