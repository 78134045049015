import {
    Component,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Failure,
    FailureGetHeatmapFlowsCombinationsResponse,
    GetExternalIpsResponse,
    GetHeatmapFlowsCombinationsResponse,
    GetHeatmapFlowsDetailsResponse,
    GetInfrastructureVersionsBetweenResponse,
    GetLastInfrastructureVersionResponse,
    InfrastructureVersion,
    NetscopeService,
    Success,
    SuccessGetExternalIpsResponse,
    SuccessGetHeatmapFlowsCombinationsResponse,
    SuccessGetHeatmapFlowsDetailsResponse,
    SuccessGetLastInfrastructureVersionResponse
} from '../../services/netscope.service';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    of
} from 'rxjs';
import {
    LicenseService
} from "@app/services";
import {
    ClrDatagridSortOrder,
    ClrDatagridStringFilterInterface
} from "@clr/angular";
import {
    environment
} from "@environments/environment";
import {
    HeatmapComboboxComponent
} from "@app/netscope/netscope-heatmap-viewer/heatmap-combobox/heatmap-combobox.component";
import {
    HeatmapHighchartsHeatmapComponent
} from "@app/netscope/netscope-heatmap-viewer/heatmap-highcharts-heatmap/heatmap-highcharts-heatmap.component";
import {TranslocoService} from "@ngneat/transloco";

class SourceFilter implements ClrDatagridStringFilterInterface < any > {
    accepts(item: any, search: string): boolean {
        if (item.source !== undefined && item.source.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
            return true;
        }
        return false;
    }
}

class DestinationFilter implements ClrDatagridStringFilterInterface < any > {
    accepts(item: any, search: string): boolean {
        if (item.target !== undefined && item.target.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
            return true;
        }
        return false;
    }
}


@Component({
    selector: 'app-netscope-heatmap-viewer-clusters',
    templateUrl: './netscope-heatmap-viewer.component.html',
    styleUrls: ['./netscope-heatmap-viewer.component.css']
})
export class NetscopeHeatmapViewerComponent implements OnInit {

    externalIps = undefined;
    isLoading = false;
    heatmapIsLoading = false;
    failureMode = false;

    resourceUuidToObjectsIndex = {};
    // heatmapFlowsCombinations = [];
    heatmapSourceResources = [];
    heatmapLinkedResources = [];
    selectedResources1: any[] = [];
    selectedResources2: any[] = [];
    flowDetails;
    displayedTime = "";
    selectedTimestamp: number = 0;
    selectedTimeResolution = "";
    bidirectionalFlows = "yes";

    combobox1IsDisabled = true;
    combobox2IsDisabled = true;
    combobox2IsLoading = false;

    infrastructureVersionsBetween: InfrastructureVersion[] = [];

    @ViewChild('vm2Combobox') vm2ComboboxRef: HeatmapComboboxComponent;

    @ViewChild('temporalSelectionHeatMap')
    temporalSelectionHeatMap: HeatmapHighchartsHeatmapComponent;

    reloadButtonColorClass = "btn-primary";

    sourceIpFilter = new SourceFilter();
    destinationIpFilter = new DestinationFilter();
    descSort = ClrDatagridSortOrder.DESC;

    isNetscopeLicenceEnabled = true;

    resourceSelectionChanged = new EventEmitter();
    clickOnTimeSlotEmitter = new EventEmitter();
    loadingStateUpdateEmitter = new EventEmitter();

    constructor(private netscopeService: NetscopeService, public licenceService: LicenseService,
                private activatedRouter: ActivatedRoute, private route: Router,
                public translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.combobox1IsDisabled = true;

        this.activatedRouter.queryParams.subscribe((queryParams) => {

            // Check if a start_time parameter has been specified
            let start_time_str_value = queryParams["start_time"];
            let time_resolution_str_value = queryParams["time_resolution"];
            if (start_time_str_value !== undefined && time_resolution_str_value !== undefined) {
                let currentTime = Number.parseFloat(start_time_str_value);
                let timeResolution = time_resolution_str_value;
                let that = this;

                function tryToSetDateOnHeatmap(maxRemainingAttempt = 5) {
                    if (that.temporalSelectionHeatMap !== undefined && that
                        .temporalSelectionHeatMap.heatmapChartRef.series.length > 0) {
                        that.temporalSelectionHeatMap.selectDate(currentTime, timeResolution);
                    } else {
                        if (maxRemainingAttempt > 0) {
                            setTimeout(() => tryToSetDateOnHeatmap(maxRemainingAttempt - 1), 300);
                        }
                    }
                }
                tryToSetDateOnHeatmap();
            }

            const externalIpsObservable = this.netscopeService.getExternalIps();

            externalIpsObservable.subscribe((response: GetExternalIpsResponse) => {
                if (response instanceof SuccessGetExternalIpsResponse) {
                    this.externalIps = response.externalIps.external_ips;
                }
                this.netscopeService.getLastInfrastructureVersion().subscribe((infrastructureVersionResponse: GetLastInfrastructureVersionResponse) => {

                    let infrastructureVersion;

                    if (infrastructureVersionResponse instanceof SuccessGetLastInfrastructureVersionResponse) {
                        infrastructureVersion = infrastructureVersionResponse.lastInfrastructureVersion;
                    } else {
                        this.failureMode = true;
                        this.isLoading = false;
                        return;
                    }

                    this.buildResourceUuidToObjectIndex(infrastructureVersion);
                    this.activatedRouter.params.subscribe((params) => {
                        let resource_uuid_1 = params["resource_uuid_1"];
                        let resource_uuid_2 = params["resource_uuid_2"];

                        if (resource_uuid_1 !== undefined && resource_uuid_2 !== undefined) {
                            this.selectedResources1 = [this.resourceUuidToObjectsIndex[resource_uuid_1]]
                            this.selectedResources2 = [this.resourceUuidToObjectsIndex[resource_uuid_2]]
                        }
                        this.reloadData();
                    });
                });
            });
        })
        this.reloadData();

        window.onresize = () => {
            if (this.externalIps !== undefined) {
                this.reloadUi("*");
            }
        };

        this.licenceService.licenseInfo.subscribe(licenceInfo => {
            if (environment.production) {
                // Check second bit to be different from 0
                this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
            } else {
                this.isNetscopeLicenceEnabled = true;
            }

            if (this.isNetscopeLicenceEnabled) {
                this.reloadData();
            }
        });

        this.route.events.subscribe((event) => {
            this.ngOnDestroy();
        });

        this.clickOnTimeSlotEmitter.subscribe((event) => {
            this.selectedTimestamp = event[0];
            this.selectedTimeResolution = event[2];
            this.loadFlows(event[0], event[2]);
            this.displayedTime = event[1];
        });

        this.loadingStateUpdateEmitter.subscribe((event) => {
            this.heatmapIsLoading = event;
        })
    }

    vm1SelectionChanged = () => {
        this.combobox2IsDisabled = true;
        this.combobox2IsLoading = true;
        this.selectedResources2 = [];

        let selectedResourceUuid = this.selectedResources1[0].uuid

        this.netscopeService.heatmapLinkedResources(selectedResourceUuid).subscribe((response) => {

            if (response instanceof Failure) {
                this.failureMode = true;
                this.isLoading = false;
                return;
            }

            if (response instanceof Success<Array<string>>) {

                this.heatmapLinkedResources = response.element;

                setTimeout(() => {
                    // @ts-ignore
                    this.fetchVms2('', this.vm2ComboboxRef);
                }, 100);
                this.reloadUi('*');
            }

        })
    }

    vm2SelectionChanged = () => {
        // this.displayHeatmap();
        this.resourceSelectionChanged.emit({
            "update": true
        });
    }

    directionChanged = () => {
        this.resourceSelectionChanged.emit({
            "update": true,
            "bidirectional": this.bidirectionalFlows === "yes"
        });
    }

    fetchVms1 = (filter = '', combobox: HeatmapComboboxComponent) => {
        combobox.loadingResources = true;

        let resources = this.heatmapSourceResources
            .map((e) => this.resourceUuidToObjectsIndex[e])
            .filter((o) => o !== undefined)
            .filter((o) => o.uuid.indexOf(filter) !== 1 || o.name.indexOf(filter) !== -1);

        combobox.asyncResources$ = of(resources);
        combobox.loadingResources = false;
        this.flowDetails = undefined;
    }

    fetchVms2 = (filter = 'second_vm', combobox: HeatmapComboboxComponent) => {
        combobox.loadingResources = true;

        let resources = this.heatmapLinkedResources
            .map((e) => this.resourceUuidToObjectsIndex[e])
            .filter((o) => o !== undefined)
            .filter((o) => o.uuid.indexOf(filter) !== 1 || o.name.indexOf(filter) !== -1);

        combobox.asyncResources$ = of(resources);
        combobox.loadingResources = false;
        this.flowDetails = undefined;
        this.combobox2IsDisabled = false;
        this.combobox2IsLoading = false;
    }

    ngOnDestroy = () => {
    }

    reloadData = () => {
        this.isLoading = true;
        this.failureMode = false;

        // const heatmapFlowsCombinationsObservable = this.netscopeService.getHeatmapFlowsCombinations();

        this.netscopeService.getLastInfrastructureVersion().subscribe((infrastructureVersionResponse: GetLastInfrastructureVersionResponse) => {

            let infrastructureVersion;

            if (infrastructureVersionResponse instanceof SuccessGetLastInfrastructureVersionResponse) {
                infrastructureVersion = infrastructureVersionResponse.lastInfrastructureVersion;
            } else {
                this.failureMode = true;
                this.isLoading = false;
                return;
            }

            this.buildResourceUuidToObjectIndex(infrastructureVersion);

            const heatmapSourceResourcesObservable = this.netscopeService.heatmapGetSourceResources();

            heatmapSourceResourcesObservable.subscribe((heatmapSourcesResponse) => {

                if (heatmapSourcesResponse instanceof Failure) {
                    this.failureMode = true;
                    this.isLoading = false;
                    return;
                }

                if (heatmapSourcesResponse instanceof Success<Array<string>>) {
                    this.heatmapSourceResources = heatmapSourcesResponse.element;
                }

                // this.buildResourceUuidToObjectIndex();
                this.isLoading = false;
                this.combobox1IsDisabled = false;
            });

            if (this.selectedResources1.length > 0 && this.selectedResources2.length > 0) {
                this.resourceSelectionChanged.emit({
                    "update": true
                });
            }
        });

    }

    loadFlows = (timestamp, timeResolution) => {
        let srcUuid, dstUuid;
        for (let resource of this.selectedResources1) {
            srcUuid = resource.uuid;
        }
        for (let resource of this.selectedResources2) {
            dstUuid = resource.uuid;
        }

        if (srcUuid === undefined || dstUuid === undefined) {
            console.log(`could not fetch flows: one of src (${srcUuid}) or dst (${dstUuid}) is undefined`);
        } else {
            console.log(`loading flows for src (${srcUuid}) or dst (${dstUuid}) at timestamp ${timestamp}`);
            this.netscopeService.getHeatmapFlowsDetails(srcUuid, dstUuid, timestamp, timeResolution, this.bidirectionalFlows === "yes").subscribe((
                flowDetailsResponse: GetHeatmapFlowsDetailsResponse) => {

                let flowDetails;
                if (flowDetailsResponse instanceof SuccessGetHeatmapFlowsDetailsResponse) {
                    flowDetails = flowDetailsResponse.heatmapFlowsDetails;
                }

                this.flowDetails = flowDetails;
                this.flowDetails.metrics = [];
                // Prepare a list of metrics in flowDetails
                for (let link of this.flowDetails.links) {
                    for (let metric of link.metrics) {
                        // Set resource types
                        metric.source_address.resourceType = this._computeResourceType(metric.source_address
                            .uuid);
                        metric.destination_address.resourceType = this._computeResourceType(metric
                            .destination_address.uuid);

                        // Fix transportProtocols property in ports. They are in the form "17 TCP", we will transform them in the
                        // following form: "TCP"
                        let transportProtocolParts = metric.transport_protocol.split(" ");
                        if (transportProtocolParts.length == 2) {
                            metric.transport_protocol = transportProtocolParts[1];
                        }
                        this.flowDetails.metrics.push(metric);
                    }
                }
            });
        }
    }

    _computeUuidPrefix = (resourceType) => {
        if (resourceType === "vm") {
            return "vim.VirtualMachine";
        }
        if (resourceType === "host") {
            return "vim.HostSystem";
        }
        if (resourceType === "external_ip") {
            return "vim.ExternalIpaddress";
        }
        return "vim.NotRecognizedResource";
    }

    _computeResourceType = (uuid) => {
        if (uuid.indexOf("vim.VirtualMachine") !== -1) {
            return "vm";
        }
        if (uuid.indexOf("vim.HostSystem") !== -1) {
            return "host";
        }
        if (uuid.indexOf("vim.ExternalIpaddress") !== -1) {
            return "external_ip";
        }
        return "not_recognized";
    }

    buildResourceUuidToObjectIndex = (infrastructureVersion : InfrastructureVersion) => {
        // Create index of UUID => resource
        for (let tuple2 of [
                ["vm", infrastructureVersion.topology.vm_only_topology.vms],
                ["host", infrastructureVersion.topology.vm_only_topology.hosts],
                ["external_ip", this.externalIps]
            ]) {
            let resourceType = tuple2[0];
            let resources = tuple2[1];

            if (resources === undefined) {
                continue;
            }

            for (let resource of resources) {
                let longUuid = `${resource.uuid}`;
                let shortUuid = longUuid.split(":")[1];

                if (resourceType === "external_ip") {
                    resource.short_uuid = shortUuid;
                    resource.uuid = longUuid;
                }

                let resourceObject = {
                    "shortUuid": shortUuid,
                    "uuid": longUuid,
                    "name": resource.name,
                    "resourceType": resourceType,
                };
                this.resourceUuidToObjectsIndex[longUuid] = resourceObject;
                this.resourceUuidToObjectsIndex[shortUuid] = resourceObject;
            }
        }
    }

    reloadUi = (category: string) => {}

    /**
     * This method export the data table into a CSV file
     */
    exportCSV() {
        let csvHeader = "Source, Destination, ExchangedBytes, TotalPackets"
        let csvContent = csvHeader + "\n";
        // for (let link of this.filteredLinks) {
        //   let lineValue = `${link.source.name}, ${link.target.name}, ${link.exchanged_bytes}, ${link.exchanged_packets}\n`;
        //   csvContent += lineValue;
        // }

        let exportedFilename = 'netscope-clusters.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    setLanguage = (language: "french" | "english") => {
        let languageMap: Map<string, string> = new Map([
            ["french", "fr"],
            ["english", "en"]
        ]);
        let language_code = languageMap.get(language);
        this.translocoService.setDefaultLang(language_code);
        this.translocoService.setActiveLang(language_code);
        localStorage.setItem("language", language_code);
    }
}

