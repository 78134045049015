import {
    Component,
    EventEmitter,
    Input,
    OnInit
} from '@angular/core';
import {
    ClrDatagridFilterInterface
} from '@clr/angular';
import {
    LabelType
} from "@angular-slider/ngx-slider";


@Component({
    selector: 'column-range-filter',
    templateUrl: './range-filter.component.html',
    styleUrls: ['./range-filter.component.css']
})
export class RangeFilterComponent implements OnInit, ClrDatagridFilterInterface < any > {

    filterIsActive = false;

    @Input()
    fieldName;

    @Input()
    formatType: string = "default";

    @Input()
    items: any[];
    options;

    @Input()
    tableUpdate: EventEmitter < any > ;

    minValue;
    maxValue;
    changes: any = new EventEmitter < any > (false);

    manualRefresh: EventEmitter < void > = new EventEmitter < void > ();

    ngOnInit() {
        this.initFilter();

        // If table data is not dynamic, there is no need to provide an event emitter
        if (this.tableUpdate != undefined) {
            this.tableUpdate.subscribe(items => {
                this.items = items;
                this.initFilter();
                this.manualRefresh.emit();
            });
        }
    }

    initFilter = () => {
        console.log(`call to initFilter(); with field ${this.fieldName}`);

        if (this.items == null) {
            this.items = [];
        }

        this.minValue = Math.min(...this.items.map((i) => i[this.fieldName]));
        this.maxValue = Math.max(...this.items.map((i) => i[this.fieldName]));

        // Prevent a UI glitch that happends min and max have the same values
        if (this.minValue == this.maxValue) {
            this.maxValue += 1;
        }

        // NGX slider seems to truncate integer values. The following code adjusts minValue and maxValue
        // so that all values fit in the [minValue, maxValue] interval.
        this.minValue = Math.floor(this.minValue);
        this.maxValue = Math.ceil(this.maxValue);

        let formatFunction = (value) => value;

        if (this.formatType === "bytes") {
            formatFunction = this.formatBytes;
        }

        this.options = {
            floor: this.minValue,
            ceil: this.maxValue,
            translate: (value: number, label: LabelType): string => {
                switch (label) {
                    case LabelType.Low:
                        return `<b>Min:</b>${formatFunction(value)}`;
                    case LabelType.High:
                        return `<b>Max:</b>${formatFunction(value)}`;
                    default:
                        return `${formatFunction(value)}`;
                }
            },
            animate: false
        };
    }

    formatBytes = (value, units = ["bytes", "KiB", "MiB", "GiB", "TiB"]) => {
        if (value < 1024 || units.length === 1) {
            // Check if the number has decimals (https://stackoverflow.com/questions/2304052/check-if-a-number-has-a-decimal-place-is-a-whole-number)
            if (value % 1 === 0) {
                return `${value} ${units[0]}`;
            } else {
                return `${value.toFixed(2)} ${units[0]}`;
            }
        }

        return this.formatBytes(value / 1024, units.slice(1));
    }

    triggerEvent() {
        this.changes.emit(true);
    }

    accepts(item: any) {
        let itemFieldValue = item[this.fieldName];

        if (this.filterIsActive) {
            return itemFieldValue >= this.minValue && itemFieldValue <= this.maxValue;
        }
        return true;
    }

    isActive(): boolean {
        return this.filterIsActive;
    }
}
