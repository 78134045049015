import { 
  Component, 
  OnInit 
} from '@angular/core';

import {
  first
} from 'rxjs/operators';

import {
  ClrDatagridSortOrder
} from '@clr/angular';

import {
  Message,
  User
} from '@app/model';

import {
  AccountService,
  MeasurementService,
  ShareService
} from '@app/services';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);
import HC_map from 'highcharts/modules/map';
HC_map(Highcharts);
import HC_treemap from 'highcharts/modules/treemap';
HC_treemap(Highcharts);
import HC_export from 'highcharts/modules/exporting';
HC_export(Highcharts);

@Component({
  selector: 'app-statistical',
  templateUrl: './statistical.component.html',
  styleUrls: ['./statistical.component.css']
})
export class StatisticalComponent implements OnInit {

  highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart | null;
  chartOptions: Highcharts.Options = {};

  dataOrder = ClrDatagridSortOrder.DESC;

  isDcscope = true;
  isCo2scope = false;

  message: Message;

  provider: string = '';

  model: any = {
    title: 'Statistics',
    isValidMap: true,
    isValidList: true,
    isValidStat: false,
    mapState: 'loading ...'
  };

  isReady: boolean = false;
  instances: any = [];
  dataMapCo2: any = [];

  constructor(
    private measurement_svc: MeasurementService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.provider = this.message.cloudProvider;

    this.isCo2scope = this.message.isCO2Scope;
    this.isDcscope = this.message.isDCScope;

  }

  loadMapChart(): void {

    this.model.title = 'The instances consumption';
    this.model.isValidMap = false;
    this.model.isValidList = true;
    this.model.isValidStat = true;

    let profile: string = this.message.cloudMessage.profile;
    let isGeneric: boolean = true;
    let uuids: string[] = this.message.cloudMessage.instanceIds;

    let tag: any = {
      tagName: this.message.cloudMessage.tag.tagName,
      tagValue: this.message.cloudMessage.tag.tagValue
    };
        
    if(this.message.cloudMessage.isWithTag)
      isGeneric = false;

    this.dataMapCo2 = [];

    this.measurement_svc.getStatMapCarbonEmission(this.provider, profile, uuids, isGeneric, this.message.cloudMessage.cloudTime, tag).pipe(first()).subscribe(
      (data: any) => {
        if(data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let dataMap : any = {
              name: data[i].name,
              value: Number(data[i].co2.toFixed(2)),
              colorValue: Number(data[i].co2.toFixed(2))
            };
            this.dataMapCo2.push(dataMap);
          }
        }
        this.callMapChart();
      },
      (error: any) => {
          if (error != null)
              console.log(error)
      }
    );
  }

  loadList(): void {

    this.instances = [];

    this.model.title = 'The instances list';
    this.model.mapState = 'loading ...';
    this.model.isValidList = false;
    this.model.isValidMap = true;
    this.model.isValidStat = true;

    let profile: string = this.message.cloudMessage.profile;
    let isGeneric: boolean = true;
    let uuids: string[] = this.message.cloudMessage.instanceIds;

    let tag: any = {
      tagName: this.message.cloudMessage.tag.tagName,
      tagValue: this.message.cloudMessage.tag.tagValue
    };
        
    if(this.message.cloudMessage.isWithTag)
      isGeneric = false;
    
    this.measurement_svc.getStatInstancesCarbonEmission(this.provider, profile, uuids, isGeneric, this.message.cloudMessage.cloudTime, tag).pipe(first()).subscribe(
      (data: any) => {
        if(data.length > 0) {
          for (let i = 0; i < data.length; i++) {
              let subdesc: any = {
                  region: data[i].region,
                  subscription: data[i].subscription,
                  resgroup: data[i].resgroup
              };

              let instance: any = {
                  identifier: data[i].uuid,
                  name: data[i].name,
                  subscriptionDesc: subdesc,
                  it: data[i].it,
                  state: data[i].state,
                  hour: data[i].hour,
                  wh: data[i].wh,
                  co2d: data[i].co2d,
                  co2e: data[i].co2e,
                  co2: data[i].co2
              };
              this.instances.push(instance);
          }
        }
      },
      (error: any) => {
          if (error != null)
              console.log(error)
      }
    );
  }

  loadStat(): void {

    this.instances = [];
    this.dataMapCo2 = [];

    this.model.title = 'Statistics';
    this.model.mapState = 'loading ...';
    this.model.isValidList = true;
    this.model.isValidMap = true;
    this.model.isValidStat = false;
  }

  roundValue(value: number): number {
    return Math.round(value);
  }

  private callMapChart(): void {

    this.isReady = false;

    this.chartOptions = {
      credits: {
          enabled: false
      },
      title: {
        text: ''
      },
      colorAxis: {
        minColor: '#94a651',
        maxColor: '#10394c'
      },
      tooltip: {
        shared: false,
        useHTML: true,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span>&nbsp;<b>{point.value} kgCO2 eq</b><br/>'
      },
      exporting: {
        enabled: false
      },
      series: [{
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        clip: false,
        turboThreshold: 0,
        data: this.dataMapCo2
      }],
    }

    this.model.mapState = '';
    this.isReady = true;
  }
}
