import {
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    getUserCurrency
} from '../../../assets/js/tools.js';


@Component({
    selector: 'app-kpibehavior',
    templateUrl: './kpibehavior.component.html',
    styleUrls: ['./kpibehavior.component.css']
})
export class KpibehaviorComponent implements OnInit {

    highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    isReady: boolean = false;

    message: Message;

    globalCurrency: string = '';

    behavior_data: any = [];

    private currentUser;

    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KPI_BEHAVIOR)
            .subscribe(
                data => {
                    this.behavior_data = data;
                    this.buildCard();
                }
            );
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    private buildCard(): void {

        this.chartOptions = {
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            title: {
                text: 'Monthly VM behavior'
            },
            xAxis: {
                categories: this.behavior_data['month'],
                crosshair: true
            },
            yAxis: {
                title: {
                    text: 'VM behavior'
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f}</b><br/>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: 'column',
                name: 'IDLE',
                data: this.behavior_data['idle']
            }, {
                type: 'column',
                name: 'LAZY',
                data: this.behavior_data['lazy']
            }, {
                type: 'column',
                name: 'NORMAL',
                data: this.behavior_data['normal']
            }, {
                type: 'column',
                name: 'OVERSIZED',
                data: this.behavior_data['oversized']
            }, {
                type: 'column',
                name: 'UNDERSIZED',
                data: this.behavior_data['undersized']
            }, {
                type: 'column',
                name: 'BUSY',
                data: this.behavior_data['busy']
            }]
        };

        this.isReady = true;
    }
}
