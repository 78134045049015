<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="target"></cds-icon>
			&nbsp;Opportunities
		</h5>
	</div>
	<div class="card-block" id="dashboard-t3-core">
		<div class="card-text">
			<div *ngIf="isReady">
				<clr-tabs>
					<clr-tab>
						<button clrTabLink (click)="opport_before()">Now</button>
						<clr-tab-content *clrIfActive="false">
							<div class="clr-row">
								<div class="clr-col-lg-7 clr-col-md-7 clr-col-12">
									<highcharts-chart id="t3containerB"
										[Highcharts]="highcharts"
										[options]="chartOptions"
										[constructorType]="'chart'"
										style="width: 90%; height: 238px; display: block;" *ngIf="isReady">
									</highcharts-chart>
								</div>
								<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
									<form clrForm clrLayout="vertical">
										<div class="clr-input" style="border-bottom:none;">
											<label>{{data_t3[0]}}</label>
										</div>
										<clr-signpost style="visibility:hidden;height:0px"></clr-signpost>
										<clr-control-helper>% of free resources</clr-control-helper>
									</form>
									<form clrForm clrLayout="vertical">
										<div class="clr-input" style="border-bottom:none;">
											<label>{{data_t3[2]}}</label>
										</div>
										<clr-signpost style="visibility:hidden;height:0px"></clr-signpost>
										<clr-control-helper>running servers</clr-control-helper>
									</form>
									<form clrForm clrLayout="vertical">
										<div class="clr-input" style="border-bottom:none;">
											<label>{{formatNumber(watts_before)}}</label>
										</div>
										<clr-control-helper>avg power consumption (W)</clr-control-helper>
									</form>
									<form clrForm clrLayout="vertical">
										<div class="clr-input" style="border-bottom:none;">
											<label>{{formatNumber(data_t3[9])}}</label>
										</div>
										<clr-signpost style="visibility:hidden;height:0px"></clr-signpost>
										<clr-control-helper>carbon emission (kg CO2eq)</clr-control-helper>
									</form>
								</div>
							</div>
						</clr-tab-content>
					</clr-tab>
					<clr-tab>
						<button clrTabLink (click)="opport_after()">Optimized</button>
						<clr-tab-content *clrIfActive="true">
							<div class="clr-row">
								<div class="clr-col-lg-7 clr-col-md-7 clr-col-12">
									<highcharts-chart id="t3containerA"
										[Highcharts]="highcharts"
										[options]="chartOptions"
										[constructorType]="'chart'"
										style="width: 90%; height: 238px; display: block;" *ngIf="isReady">
									</highcharts-chart>
								</div>
								<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
									<form clrForm clrLayout="vertical">
										<div class="clr-input" style="border-bottom:none;">
											<label>{{data_t3[1]}}</label>
										</div>
										<clr-signpost class="grey-signpost" style="height:0px">
											<clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen class="info-signpost">
												<h5><b>Free resources</b></h5>
												<p>It is the percentage of available resources after optimization</p>
												<p style="margin-top:20px"><cds-icon size="24" shape="help-info" solid="true"></cds-icon>Optimization details :</p>
												<p style="margin-top:20px">Removed idle VM: {{data_t3[6]}}</p>
												<p>Resized VM: {{data_t3[7]}}</p>
											</clr-signpost-content>
										</clr-signpost>
										<clr-control-helper>% of free resources</clr-control-helper>
									</form>
									<form clrForm clrLayout="vertical">
										<div class="clr-input" style="border-bottom:none;">
											<label>{{servers_after}}</label>
											<label style="margin-left:5px" [style.color]="servers_after_percent_color">({{servers_after_percent}} %)</label>
										</div>
										<clr-signpost class="grey-signpost" style="height:0px">
											<clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen class="info-signpost">
												<h5><b>Running servers</b></h5>
												<p>This value represents the number of running servers after optimization.</p>
												<p style="margin-top:20px"><fa-icon [icon]="['fas', 'power-off']" style="font-size:1.5em;margin-right:15px"></fa-icon>After optimization, these servers could be used for sparing or turned off: </p>
												<p style="margin-top:20px">{{data_t3[8]}}</p>
											</clr-signpost-content>
										</clr-signpost>
										<clr-control-helper>running servers</clr-control-helper>
									</form>
									<form clrForm clrLayout="vertical" >
										<div class="clr-input" style="border-bottom:none;">
											<label>{{formatNumber(watts_after)}}</label>
											<label style="margin-left:5px" [style.color]="watts_after_percent_color">({{watts_after_percent}} %)</label>
										</div>
										<clr-control-helper>avg power consumption (W)</clr-control-helper>
									</form>
									<form clrForm clrLayout="vertical">
										<div class="clr-input" style="border-bottom:none;">
											<label>{{formatNumber(carbon_after)}}</label>
											<label style="margin-left:5px" [style.color]="carbon_after_percent_color">({{carbon_after_percent}} %)</label>
										</div>
										<clr-signpost class="grey-signpost" style="height:0px">
											<clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen class="info-signpost">
												<h5><b>Carbon emissions</b></h5>
												<p>This value corresponds to all carbon emissions (direct and embodied) from servers that could be decommissioned.</p>
												<p style="margin-top:20px"><fa-icon [icon]="['fas', 'seedling']" style="font-size:1.9em;margin-right:15px"></fa-icon>This carbon impact is avoided if new servers are not purchased !</p>
											</clr-signpost-content>
										</clr-signpost>
										<clr-control-helper>carbon emission (kg CO2eq)</clr-control-helper>
									</form>
								</div>
							</div>
						</clr-tab-content>
					</clr-tab>
				</clr-tabs>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
    	</div>
	</div>
</div>
