import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    JsonloaderService,
    ManagementService,
    ShareService
} from '@app/services';

import {
    COST,
    COST_UPDATE,
    Message,
    User
} from '@app/model';

import * as moment from 'moment';

import {
    CostvmdetailComponent
} from '@app/costmgt/costvmdetail/costvmdetail.component';
import {
    CostvmdetailDirective
} from '@app/directives/costvmdetail.directive';

import {
    CostvmgenericComponent
} from '@app/costmgt/costvmgeneric/costvmgeneric.component';
import {
    CostvmgenericDirective
} from '@app/directives/costvmgeneric.directive';

import {
    CostvmtagComponent
} from '@app/costmgt/costvmtag/costvmtag.component';
import {
    CostvmtagDirective
} from '@app/directives/costvmtag.directive';

import {
    CostserverdetailComponent
} from '@app/costmgt/costserverdetail/costserverdetail.component';
import {
    CostserverdetailDirective
} from '@app/directives/costserverdetail.directive';

import {
    CostservergenericComponent
} from '@app/costmgt/costservergeneric/costservergeneric.component';
import {
    CostservergenericDirective
} from '@app/directives/costservergeneric.directive';

import {
    CoststodetailComponent
} from '@app/costmgt/coststodetail/coststodetail.component';
import {
    CoststodetailDirective
} from '@app/directives/coststodetail.directive';

import {
    CoststogenericComponent
} from '@app/costmgt/coststogeneric/coststogeneric.component';
import {
    CoststogenericDirective
} from '@app/directives/coststogeneric.directive';

import {
    getUserCurrency
} from '../../assets/js/tools.js';

var componentRef: ComponentRef < any > ;


@Component({
    selector: 'app-costmgt',
    templateUrl: './costmgt.component.html',
    styleUrls: ['./costmgt.component.css']
})
export class CostmgtComponent implements AfterViewInit, OnInit {

    @ViewChild(CostvmdetailDirective) addVmDetail: CostvmdetailDirective;
    @ViewChild(CostvmgenericDirective) addVmGeneric: CostvmgenericDirective;
    @ViewChild(CostvmtagDirective) addVmTag: CostvmtagDirective;
    @ViewChild(CostserverdetailDirective) addServerDetail: CostserverdetailDirective;
    @ViewChild(CostservergenericDirective) addServerGeneric: CostservergenericDirective;
    @ViewChild(CoststodetailDirective) addStoDetail: CoststodetailDirective;
    @ViewChild(CoststogenericDirective) addStoGeneric: CoststogenericDirective;

    message: Message;

    globalCurrency: string = '';

    isVmDetail: boolean = false;

    isServerDetail: boolean = false;

    isStoDetail: boolean = false;

    model: any = {
        current_sto: 'generic_sto',
        current_sto_msg: 'generic cost',
        current_sto_info: '',
        current_sto_date: 0,
        current_server: 'generic_server',
        current_server_msg: 'generic cost',
        current_server_info: '',
        current_server_date: 0,
        current_vm: 'generic_vm',
        current_vm_msg: 'generic cost',
        current_vm_info: '',
        current_vm_date: 0
    };

    moment = moment;

    private currentUser: User;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private management_svc: ManagementService,
        private data: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(
            user => {
                this.currentUser = user;
                this.globalCurrency = getUserCurrency(this.currentUser.currency);
            }
        );

        this.data.currentMessage.subscribe(message => this.message = message);
    }

    ngAfterViewInit(): void {
        this.getCosts();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    loadFixed(): void {

        this.message.costType = this.model.current_vm;
        this.loadDetails();
    }

    loadTag(): void {

        this.message.costType = 'tag_vm';
        this.loadDetails();
    }

    loadDetails(): void {

        if (componentRef != undefined)
            componentRef.destroy();

        switch (this.message.costType) {
            case "generic_vm":
                this.model.current_vm_info = 'you can assign the same fixed cost to all virtual machines';
                this.isServerDetail = false;
                this.isStoDetail = false;
                setTimeout(() => this.loadCostVmGeneric(), 100)
                break;
            case "detail_vm":
                this.model.current_vm_info = 'you can assign a fixed cost to each virtual machine';
                this.isServerDetail = false;
                this.isStoDetail = false;
                setTimeout(() => this.loadCostVmDetail(), 100)
                break;
            case "tag_vm":
                this.model.current_vm_info = 'you can assign a tag (cost application) to each virtual machine';
                this.isServerDetail = false;
                this.isStoDetail = false;
                setTimeout(() => this.loadCostVmTag(), 100)
                break;
            case "generic_server":
                this.model.current_server_info = 'you can assign the same cost to all servers';
                this.isStoDetail = false;
                this.isVmDetail = false;
                setTimeout(() => this.loadCostServerGeneric(), 100)
                break;
            case "detail_server":
                this.model.current_server_info = 'you can assign cost to each server';
                this.isStoDetail = false;
                this.isVmDetail = false;
                setTimeout(() => this.loadCostServerDetail(), 100)
                break;
            case "generic_sto":
                this.model.current_sto_info = 'you can assign the same cost to all datastores ';
                this.isServerDetail = false;
                this.isVmDetail = false;
                setTimeout(() => this.loadCostStoGeneric(), 100)
                break;
            case "detail_sto":
                this.model.current_sto_info = 'you can assign a cost to each datastore';
                this.isServerDetail = false;
                this.isVmDetail = false;
                setTimeout(() => this.loadCostStoDetail(), 100)
                break;
            default:
                break;
        }
    }

    closeHide(): void {

        this.isStoDetail = false;
        this.isServerDetail = false;
        this.isVmDetail = false;
        this.message.isCostTool = false;
    }

    updateCost(): void {

        let isRatio: number = 0;
        if (this.message.isWithRatio)
            isRatio = 1;

        switch (this.message.costType) {
            case "detail_vm":
                this.model.current_vm_date = moment().unix() * 1000;
                let vms_cost: any = [];
                if (this.message.costVmDetail.length > 0) {
                    for (var i = 0; i < this.message.costVmDetail.length; i++) {
                        let data_tmp = [];
                        data_tmp[0] = 'root';
                        data_tmp[1] = this.message.costVmDetail[i].uuid;
                        data_tmp[2] = this.message.costVmDetail[i].name;
                        data_tmp[3] = this.message.costVmDetail[i].cost;
                        vms_cost.push(data_tmp);
                    }
                    if (vms_cost.length > 0) {
                        this.management_svc.updateVmCosts(vms_cost).pipe(first()).subscribe(
                            complete => {
                                this.processingCost();
                            },
                            error => {
                                if (error != null)
                                    console.log(error)
                            }
                        );
                    }
                }
                break;
            case "generic_vm":
                this.model.current_vm_date = moment().unix() * 1000;
                const cost_vm: COST = {
                    USER: 'root',
                    CPU_COST: 0,
                    RAM_COST: 0,
                    VM_COST: this.message.costVmGeneric,
                    STO_COST: 0,
                    SERVER_COST: 0,
                    RATIO: 0,
                    ISRATIO: isRatio
                };
                this.management_svc.updateGenericCpuCost(cost_vm).pipe(first()).subscribe(
                    success => {
                        this.processingCost();
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
                break;
            case "tag_vm":
                this.model.current_vm_date = moment().unix() * 1000;
                this.processingCost();
                break;
            case "detail_sto":
                this.model.current_sto_date = moment().unix() * 1000;
                let dss_cost: any = [];
                if (this.message.costStoDetail.length > 0) {
                    for (var i = 0; i < this.message.costStoDetail.length; i++) {
                        let data_tmp = [];
                        data_tmp[0] = 'root';
                        data_tmp[1] = this.message.costStoDetail[i].uuid;
                        data_tmp[2] = this.message.costStoDetail[i].name;
                        data_tmp[3] = this.message.costStoDetail[i].cost;
                        dss_cost.push(data_tmp);
                    }
                    if (dss_cost.length > 0) {
                        this.management_svc.updateStorageCosts(dss_cost).pipe(first()).subscribe(
                            complete => {
                                this.processingCost();
                            },
                            error => {
                                if (error != null)
                                    console.log(error)
                            }
                        );
                    }
                }
                break;
            case "generic_sto":
                this.model.current_sto_date = moment().unix() * 1000;
                const cost_sto: COST = {
                    USER: 'root',
                    CPU_COST: 0,
                    RAM_COST: 0,
                    VM_COST: 0,
                    STO_COST: this.message.costStoGeneric,
                    SERVER_COST: 0,
                    RATIO: 0,
                    ISRATIO: isRatio
                };
                this.management_svc.updateGenericStoCost(cost_sto).pipe(first()).subscribe(
                    success => {
                        this.processingCost();
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
                break;
            case "detail_server":
                this.model.current_server_date = moment().unix() * 1000;
                let srvs_cost: any = [];
                if (this.message.costSrvDetail.length > 0) {
                    for (var i = 0; i < this.message.costSrvDetail.length; i++) {
                        let data_tmp = [];
                        data_tmp[0] = 'root';
                        data_tmp[1] = this.message.costSrvDetail[i].uuid;
                        data_tmp[2] = this.message.costSrvDetail[i].name;
                        data_tmp[3] = this.message.costSrvDetail[i].cost;
                        data_tmp[4] = this.message.costSrvDetail[i].vcpu_cost;
                        data_tmp[5] = this.message.costSrvDetail[i].vram_cost;
                        data_tmp[6] = this.message.costSrvDetail[i].ratio;
                        srvs_cost.push(data_tmp);
                    }
                    if (srvs_cost.length > 0) {
                        this.management_svc.updateServerCosts(srvs_cost).pipe(first()).subscribe(
                            complete => {
                                this.processingCost();
                            },
                            error => {
                                if (error != null)
                                    console.log(error)
                            }
                        );
                    }
                }
                break;
            case "generic_server":
                this.model.current_server_date = moment().unix() * 1000;
                const cost_srv: COST = {
                    USER: 'root',
                    CPU_COST: this.message.costCpuGeneric,
                    RAM_COST: this.message.costRamGeneric,
                    VM_COST: 0,
                    STO_COST: 0,
                    SERVER_COST: this.message.costSrvGeneric,
                    RATIO: this.message.costRatioGeneric,
                    ISRATIO: isRatio
                };
                this.management_svc.updateGenericSrvCost(cost_srv).pipe(first()).subscribe(
                    success => {
                        this.processingCost();
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
                break;
            default:
                break;
        }

        const cost_info: COST_UPDATE = {
            VM: this.model.current_vm_date,
            HOST: this.model.current_server_date,
            STORAGE: this.model.current_sto_date
        };
        this.management_svc.updateCostInfo(cost_info).pipe(first()).subscribe(
            success => {
                this.getCosts();
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
        this.message.isCostRecord = false;
    }

    updateIsWithRatio(): void {

        let isratio: number = 0;
        if (this.message.isWithRatio)
            isratio = 1;

        let isWithRatio: any = {
            ISRATIO: isratio
        };
        this.management_svc.updateIsWithRatio(isWithRatio).pipe(first()).subscribe();
    }

    private getCosts(): void {

        this.management_svc.getCost('root').pipe(first()).subscribe(
            data => {
                if (data.VM_COST == -1) {
                    this.model.current_vm = 'detail_vm';
                    this.model.current_vm_msg = 'individual cost';
                    this.model.current_vm_info = 'you can assign a fixed cost to each virtual machine';
                } else {
                    this.model.current_vm = 'generic_vm';
                    this.model.current_vm_msg = 'generic cost';
                    this.model.current_vm_info = 'you can assign the same fixed cost to all virtual machines';
                    this.message.costVmGeneric = data.VM_COST;
                }
                if (data.SERVER_COST == -1) {
                    this.model.current_server = 'detail_server';
                    this.model.current_server_msg = 'individual cost';
                    this.model.current_server_info = 'you can assign cost to each server';
                } else {
                    this.model.current_server = 'generic_server';
                    this.model.current_server_msg = 'generic cost';
                    this.model.current_server_info = 'you can assign the same cost to all servers';
                    this.message.costSrvGeneric = data.SERVER_COST;
                }
                if (data.STO_COST == -1) {
                    this.model.current_sto = 'detail_sto';
                    this.model.current_sto_msg = 'individual cost';
                    this.model.current_sto_info = 'you can assign a cost to each datastore';
                } else {
                    this.model.current_sto = 'generic_sto';
                    this.model.current_sto_msg = 'generic cost';
                    this.model.current_sto_info = 'you can assign the same cost to all datastores ';
                    this.message.costStoGeneric = data.STO_COST;
                }
                if (data.ISRATIO == 1) {
                    this.message.isWithRatio = true;
                } else {
                    this.message.isWithRatio = false;
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
        this.management_svc.getCostInfo().pipe(first()).subscribe(
            data => {
                this.model.current_sto_date = data.STORAGE;
                this.model.current_server_date = data.HOST;
                this.model.current_vm_date = data.VM;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private processingCost() {

        this.message.genericInfoType = 'info';
        this.message.genericInfo = 'filter ' + this.message.currentFilter + ' processing ...';
        this.message.isNoInfo = false;

        this.json_svc.cleanJson(this.currentUser.login, this.message.currentFilter).subscribe(
            success => {
                this.json_svc.checkJson(this.currentUser.login, this.message.currentFilter).subscribe(
                    complete => {
                        this.message.genericInfo = 'filter ' + this.message.currentFilter +
                            ' is ready to use';
                        this.message.isNoInfo = false;
                        this.json_svc.changeJson(this.currentUser.login, this.message.currentFilter);
                    },
                    error => {
                        if (error != null) {
                            this.message.isNoInfo = true;
                            console.log(error);
                        }
                    }
                );
            },
            error => {
                console.log(error);
            }
        );
    }

    private loadCostVmDetail(): void {

        if (this.addVmDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CostvmdetailComponent);
            const viewContainerRef = this.addVmDetail.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCostVmGeneric(): void {

        if (this.addVmGeneric != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CostvmgenericComponent);
            const viewContainerRef = this.addVmGeneric.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCostVmTag(): void {

        if (this.addVmTag != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CostvmtagComponent);
            const viewContainerRef = this.addVmTag.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCostServerDetail(): void {

        if (this.addServerDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                CostserverdetailComponent);
            const viewContainerRef = this.addServerDetail.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCostServerGeneric(): void {

        if (this.addServerGeneric != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                CostservergenericComponent);
            const viewContainerRef = this.addServerGeneric.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCostStoDetail(): void {

        if (this.addStoDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CoststodetailComponent);
            const viewContainerRef = this.addStoDetail.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCostStoGeneric(): void {

        if (this.addStoGeneric != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CoststogenericComponent);
            const viewContainerRef = this.addStoGeneric.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }
}
