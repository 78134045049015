<table id="element-consover-cpu" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th rowspan="2" style="display:none;"></th>
			<th rowspan="2">from</th>
			<th rowspan="2">to</th>
			<th rowspan="2">name</th >
			<th width="7%" rowspan="2">nb. of values</th>
			<th colspan="5">CPU consumption (%)</th>
			<th colspan="4">CPU Ready (ms)</th>
		</tr>
		<tr>
			<th width="4%">avg</th>
			<th width="4%">min</th>
			<th width="4%">max</th>
			<th width="7%">CPU < 10</th>
			<th width="7%">CPU > 70</th>
			<th width="4%">avg</th>
			<th width="4%">min</th>
			<th width="4%">max</th>
			<th width="4%">%</th>
		</tr>
  	</thead>
	<tbody>
		<tr *ngFor="let element of data_elements">
			<td style="display:none;">{{element.timeStart}}</td>
			<td>{{element.start}}</td>
			<td>{{element.end}}</td>
			<td>{{element.name}}
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph(element)" *ngIf="currentUser.isMenuGod">
					<cds-icon shape="line-chart"></cds-icon>
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callZoom(element)" *ngIf="element.timeEnd-element.timeStart>3600000">
					<cds-icon shape="zoom-in"></cds-icon>
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callZoomOut(element)" *ngIf="zoom_out">
					<cds-icon shape="zoom-out"></cds-icon>
				</button>
			</td>
			<td>{{element.values}}</td>
			<td>{{element.avgcpu}}</td>
			<td>{{element.mincpu}}</td>
			<td>{{element.maxcpu}}</td>
			<td>{{element.cpu10}}</td>
			<td>{{element.cpu70}}</td>
			<td>{{element.avgcpurdy}}</td>
			<td>{{element.mincpurdy}}</td>
			<td>{{element.maxcpurdy}}</td>
			<td>{{element.percpurdy.toFixed(2)}}</td>
		</tr>
  	</tbody>
</table>
