<div class="clr-row" *ngIf="this.message.kubeDetail">
    <div class="card">
        <div class="card-header">
            <span style="color:grey;font-size:26px;">
                <cds-icon shape="thumbs-up"></cds-icon>
                    OPPORTUNITIES
            </span>
            <span style="float: right;" ngClass="addLink" (click)="message.kubeDetail = false">
                <cds-icon shape="times" size="24"></cds-icon>
            </span>
        </div>
        <div class="card-block">
            <div class="clr-row">
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                    <div class="clr-datagrid">
                        <clr-datagrid>
                            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Container</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'behavior'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Behavior</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'cluster'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Cluster</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'action'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Action</ng-container></clr-dg-column>
                            <clr-dg-row *clrDgItems="let data of opportunities" [clrDgItem]="data">
                                <clr-dg-cell>{{data.name}}</clr-dg-cell>
                                <clr-dg-cell>{{data.state}}</clr-dg-cell>
                                <clr-dg-cell>{{data.behavior}}</clr-dg-cell>
                                <clr-dg-cell>{{data.cluster}}</clr-dg-cell>
                                <clr-dg-cell>{{data.action}}</clr-dg-cell>
                            </clr-dg-row>
                            <clr-dg-footer>
                                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                                        entries per page
                                    </clr-dg-page-size>
                                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                </clr-dg-pagination>
                            </clr-dg-footer>
                        </clr-datagrid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>