import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';

import {
    User,
    Lang,
    Lang_FR,
    Lang_EN,
    Lang_ES,
    AWS_REGION,
    AZURE_REGION,
    InstanceFullName,
    Message,
    KUBERNETES_ARCH
} from '@app/model';

import {
    LicenseService,
    MonitorService,
    ShareService
} from '@app/services';
import {
    Mode,
    InstanceType
} from '@app/model';
import {
    ClrDatagridSortOrder,
    ClrLoadingState,
    ClrWizard,
    ClrWizardPage
} from '@clr/angular';
import {
    Router
} from '@angular/router';

import {
    interval,
    Subscription
} from 'rxjs';

import {
    faAws,
    faGoogle,
    faMicrosoft
} from '@fortawesome/free-brands-svg-icons';
import {
    faClone
} from '@fortawesome/free-regular-svg-icons';
import {
    faCubes,
    faDatabase,
    faServer
} from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-monitor',
    templateUrl: './monitor.component.html',
    styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements OnInit {

    @ViewChild('list') monitor_list: ElementRef;
    @ViewChild('info') info: ElementRef;

    @ViewChild("welcome") welcomewizard: ClrWizard;
    @ViewChild("add") addwizard: ClrWizard;
    @ViewChild("migrationpage") migrationpage: ClrWizardPage;
    @ViewChild("editpage") editpage: ClrWizardPage;

    @ViewChild('addstatus') add_div: ElementRef;

    // For progress wait ...
    @Output('progress') progressChange = new EventEmitter < boolean > ();
    showProgress(progress: boolean) {
        this.progressChange.emit(progress);
    }

    isDcscope = true;
    isCo2scope = false;

    message: Message;

    dataOrder = ClrDatagridSortOrder.ASC;

    badges: any;

    show_progress: boolean = false;

    forbidden_names: Array < String > = [];

    // Type
    vmware_type: InstanceType = InstanceType.VMWARE;
    vcloud_type: InstanceType = InstanceType.VCLOUD;
    aws_type: InstanceType = InstanceType.AWS;
    azure_type: InstanceType = InstanceType.AZURE;
    gcp_type: InstanceType = InstanceType.GCP;
    scaph_type: InstanceType = InstanceType.SCAPHANDRE;
    kube_type: InstanceType = InstanceType.KUBERNETES;
    xclarity_type: InstanceType = InstanceType.XCLARITY;
    openmanage_type: InstanceType = InstanceType.OPENMANAGE;
    oneview_type: InstanceType = InstanceType.ONEVIEW;
    ipmi_type: InstanceType = InstanceType.IPMI;
    exagrid_type: InstanceType = InstanceType.EXAGRID;
    ibm_type: InstanceType = InstanceType.IBM;

    // Icons
    aws_icon = faAws;
    azure_icon = faMicrosoft;
    gcp_icon = faGoogle;
    vmware_icon = faClone;
    scaph_icon = faDatabase;
    kube_icon = faCubes;
    xclarity_icon = faServer;
    openmanage_icon = faServer;
    oneview_icon = faServer;
    ipmi_icon = faServer;
    exagrid_icon = faDatabase;
    ibm_icon = faDatabase;

    // Info
    info_modal: boolean;
    info_header: string;
    info_modal_size: string;

    // Unregister
    unregister_modal: boolean;
    unregister_instance: string;

    // Register
    register_wizard: boolean;
    instance_status: number;
    instance_type: InstanceType;
    instance_name: string = "";
    instance_name_disable: boolean = false;
    instance_name_forbidden: boolean = false;
    instance_edit: boolean = false;

    instance_vmware_vcloud: any = {};
    instance_aws: any = {};
    instance_azure: any = {};
    instance_gcp: any = {};
    instance_scaphandre: any = {};
    instance_kubernetes: any = {};
    instance_xclarity: any = {};
    instance_openmanage: any = {};
    instance_oneview: any = {};
    instance_ipmi: any = {};
    instance_exagrid: any = {};
    instance_ibm: any = {};

    // Welcome
    welcome_wizard: boolean;
    welcome_lang: Lang;
    welcome_license_status: string;
    welcome_license_innerhtml: string;
    welcome_mode: Mode;

    // Lang
    lang: any;

    // Mode
    install_mode: Mode;
    migrate_mode: Mode;

    // Migration
    migrate_ip: string;
    migrate_pwd: string;
    migrate_pwd_show: boolean;
    migrate_retention: number;
    migrate_connect: boolean;
    migrate_connect_button_state: ClrLoadingState;
    migrate_connect_button_text: string;
    migrate_status: number;
    migrate_progress_value: number;
    migrate_progress_message: string;
    migrate_progress_class: string;
    migrate_details_subscription: Subscription;

    // List
    instances_list: Array < any > = [];
    instances_list_modal: boolean;

    // Cloud regions
    aws_regions = AWS_REGION;
    azure_regions = AZURE_REGION;

    // Kubernetes architectures
    kubernetes_arch = KUBERNETES_ARCH;

    // For trackdc instances
    scaphandre_check: boolean = false;


    /**
     * 
     */
    constructor(private monitor_svc: MonitorService, private renderer: Renderer2, private license_svc: LicenseService,
        private router: Router, private data: ShareService, private cd: ChangeDetectorRef) {
        this.info_modal = false;
        this.info_modal_size = "l";

        this.instances_list_modal = false;
        this.unregister_modal = false;
        this.register_wizard = false;

        this.lang = Lang_EN;
        this.welcome_license_innerhtml = "";

        this.welcome_mode = Mode.INSTALL;
        this.install_mode = Mode.INSTALL;
        this.migrate_mode = Mode.MIGRATE;

        this.badges = {
            vmware: 0,
            cloud: 0,
            scaph: 0,
            kube: 0,
            xclarity: 0,
            openmanage: 0,
            oneview: 0,
            ipmi: 0,
            exagrid: 0,
            ibm: 0
        };

    }

    /**
     * 
     */
    ngOnInit(): void {
        this.data.currentMessage.subscribe(message => this.message = message);
        this.monitor_svc.scaphandreCheck.subscribe((details) => (this.scaphandre_check = details));

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;
    }

    /**
     * 
     */
    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    /**
     * 
     */
    private initEmptyInstances() {
        this.instance_type = InstanceType.VMWARE;
        this.instance_name = "";

        this.instance_name_disable = false;
        this.instance_name_forbidden = false;
        this.instance_edit = false;

        this.instance_vmware_vcloud = {
            name: "",
            ip: "",
            login: "",
            pwd: ""
        }

        this.instance_aws = {
            name: "",
            //region: "eu-west-3",
            accesskey: "",
            secretaccesskey: "",
            isorg: false,
            orgname: '',
            orgaccesskey: '',
            orgsecretaccesskey: ''
        }

        this.instance_azure = {
            name: "",
            //region: "france-central",
            tenantid: "",
            clientid: "",
            clientsecret: "",
            //subscriptionid: ""
        }

        this.instance_gcp = {
            name: "",
            projectid: "",
            file: "",
            filecontent: "",
        }

        this.instance_kubernetes = {
            name: "",
            ip: "",
            //file: "",
            //filecontent: "",
            arch: "native",
            token: ""
        }

        this.instance_scaphandre = {
            name: "",
            ip: "",
            login: "",
            pwd: ""
        }

        this.instance_xclarity = {
            name: "",
            ip: "",
            login: "",
            pwd: ""
        }

        this.instance_openmanage = {
            name: "",
            ip: "",
            login: "",
            pwd: ""
        }

        this.instance_oneview = {
            name: "",
            ip: "",
            login: "",
            pwd: ""
        }

        this.instance_ipmi = {
            name: "",
            ip: "",
            login: "",
            pwd: ""
        }

        this.instance_exagrid = {
            name: "",
            ip: "",
            login: "",
            pwd: ""
        }

        this.instance_ibm = {
            name: "",
            ip: "",
            login: "",
            pwd: ""
        }
    }

    /**
     *
     */
    getArchive(user: User): void {
        // Show progress
        this.showProgress(true);

        // Get file
        this.monitor_svc.getArchive(user.login).subscribe(
            res => {
                // Show progress
                this.showProgress(false);

                //window.open(window.URL.createObjectURL(res), '_blank');

                let file_type = "application/gzip";

                // Create temp link
                let blob: Blob = new Blob([res], {
                    type: file_type
                });
                let fileName = 'dcscope_status.tar.gz';
                let objectUrl: string = URL.createObjectURL(blob);

                let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);

            },
            error => {
                // Show progress
                this.showProgress(false);

                let error_header = "Sorry ...";

                let error_body: HTMLParagraphElement = this.renderer.createElement('p');
                error_body.innerHTML = "We can not found your archive :(";

                this.initInfoModal(error_header, error_body, 'sm');

            }
        );
    }

    /**
     * 
     */
    getMonitorList(): void {

        this.show_progress = true;

        this.instances_list_modal = true;

        // Get & build list
        this.monitor_svc.getMonitorList().subscribe(
            data => {
                if (data != null)
                    this.instances_list = < any > data;

                // Update badges & forbidden names
                this.updateBadgesAndNames();

                this.show_progress = false;
            },
            error => {
                // No instances
                this.show_progress = false;
            }
        );
    }

    /**
     *  
     */
    initUnregister(instance: string) {
        this.unregister_modal = true;
        this.unregister_instance = instance;
    }

    /**
     *  
     */
    unregisterInstance(): void {
        if (this.unregister_instance != undefined) {
            let instance = this.unregister_instance;

            // Hide list
            this.instances_list_modal = false;

            // Show progress
            this.showProgress(true);

            this.monitor_svc.unregisterInstance(instance).subscribe(
                data => {
                    // Hide progress
                    this.showProgress(false);

                    // Show list
                    this.instances_list_modal = true;

                    // Refresh list of vcenter/esxi
                    this.getMonitorList();

                    // Refresh  status
                    this.monitor_svc.getStatus();
                },
                error => {
                    // Hide progress
                    this.showProgress(false);

                    // Show list
                    this.instances_list_modal = true;

                    // Init error
                    setTimeout(() => {
                        let error_header = "Error";
                        let error_body: HTMLParagraphElement = this.renderer.createElement('p');
                        error_body.innerHTML = "An error occured during <b>" + instance +
                            "</b> unregistration ! ";

                        this.initInfoModal(error_header, error_body, 'sm');
                    });
                }
            );

        }

        this.unregister_instance = undefined;
        this.unregister_modal = false;

    }

    /**
     * 
     */
    initEdit(instance: any) {
        this.instance_edit = true;
        this.instance_name_disable = true;

        this.instance_type = instance.type;
        this.instance_name = instance.name;

        switch (instance.type) {
            case InstanceType.AWS:
                this.instance_aws = {
                    name: "",
                    //region: instance.region,
                    accesskey: instance.accesskey,
                    secretaccesskey: "",
                    isorg: instance.isorg,
                    orgname: instance.orgname,
                    orgaccesskey: instance.orgaccesskey,
                    orgsecretaccesskey: ''
                }
                break;
            case InstanceType.AZURE:
                this.instance_azure = {
                    name: "",
                    //region: instance.region,
                    tenantid: instance.tenantid,
                    clientid: instance.clientid,
                    clientsecret: "",
                    //subscriptionid: instance.subscriptionid,
                }
                break;
            case InstanceType.GCP:
                this.instance_gcp = {
                    name: instance.name,
                    projectid: instance.projectid
                };
                break;
            case InstanceType.VMWARE:
                this.instance_vmware_vcloud = {
                    name: instance.name,
                    ip: instance.ip,
                    login: instance.login,
                    pwd: ""
                };
                break;
            case InstanceType.KUBERNETES:
                this.instance_kubernetes = {
                    name: instance.name,
                    ip: instance.url,
                    arch: instance.arch,
                    token: ""
                };
                break;
            case InstanceType.SCAPHANDRE:
                this.instance_scaphandre = {
                    name: instance.name,
                    ip: instance.ip,
                    login: instance.login,
                    pwd: ""
                };
                break;
            case InstanceType.XCLARITY:
                this.instance_xclarity = {
                    name: instance.name,
                    ip: instance.ip,
                    login: instance.login,
                    pwd: ""
                };
                break;
            case InstanceType.OPENMANAGE:
                this.instance_openmanage = {
                    name: instance.name,
                    ip: instance.ip,
                    login: instance.login,
                    pwd: ""
                };
                break;
            case InstanceType.ONEVIEW:
                this.instance_oneview = {
                    name: instance.name,
                    ip: instance.ip,
                    login: instance.login,
                    pwd: ""
                };
                break;
            case InstanceType.IPMI:
                this.instance_ipmi = {
                    name: instance.name,
                    ip: instance.ip,
                    login: instance.login,
                    pwd: ""
                };
                break;
            case InstanceType.EXAGRID:
                this.instance_exagrid = {
                    name: instance.name,
                    ip: instance.ip,
                    login: instance.login,
                    pwd: ""
                };
                break;
            case InstanceType.IBM:
                this.instance_ibm = {
                    name: instance.name,
                    ip: instance.ip,
                    login: instance.login,
                    pwd: ""
                };
                break;
            default:
                break;
        }

        this.initWizard(false, false, true);
    }

    /**
     * 
     */
    initWizard(welcome: boolean, goto_migration: boolean, edit: boolean): void {

        if (welcome) {
            this.welcome_lang = Lang.FR;
            this.lang = Lang_FR;
            this.welcome_license_status = "false";
            this.welcome_license_innerhtml = this.lang.WELCOME_LICENSE_LICENSE;

            this.welcome_wizard = true;

        } else {
            this.register_wizard = true;
        }

        // Install
        this.instance_status = undefined;

        // Init empty instance data
        if (!edit) {
            this.initEmptyInstances();
        }

        // Migrate
        this.migrate_ip = "";
        this.migrate_pwd = "";
        this.migrate_pwd_show = false;
        this.migrate_connect = false;
        this.migrate_connect_button_state = ClrLoadingState.DEFAULT;
        this.migrate_connect_button_text = "CHECK VM AVAILABILITY";
        this.migrate_retention = 0; // all
        this.migrate_status = undefined;
        this.migrate_progress_value = 0;
        this.migrate_progress_message = "retrieving informations, please wait ...";

        // Go to migration
        if (goto_migration) {
            this.welcome_mode = Mode.MIGRATE;
            setTimeout(() => {
                this.welcomewizard.navService.goTo(this.migrationpage, true);

                // XXX need to manually 'validate' previous pages
                let pages: ClrWizardPage[] = this.welcomewizard.navService.pageCollection.pagesAsArray;
                pages[1].nextStepDisabled = false;
                pages[3].nextStepDisabled = false;

                // Get migration settings
                this.monitor_svc.getSettings().subscribe(
                    data => {
                        data.forEach(element => {
                            switch (element.option) {
                                case "migration.ip":
                                    this.migrate_ip = element.value;
                                    break;
                                case "migration.retention":
                                    this.migrate_retention = Number(element.value);
                                    break;
                            };
                        });
                    }
                );

                // Init migration
                this.initMigration(false);
            });
        }

        // Go to credentials page
        if (edit) {
            setTimeout(() => {
                this.addwizard.navService.goTo(this.editpage, true);

                // XXX need to manually 'validate' previous pages
                let pages: ClrWizardPage[] = this.addwizard.navService.pageCollection.pagesAsArray;
                pages[1].previousStepDisabled = true;
            });
        }
    }

    /**
     * 
     */
    doCancel(from_welcome: boolean): void {
        switch (this.welcome_mode) {
            // Cancel available only before registration or status failed
            case Mode.INSTALL:
                if (this.instance_status == undefined || this.instance_status > 0) {
                    if (from_welcome) {
                        this.welcomewizard.reset();
                    } else {
                        this.addwizard.reset();
                        this.addwizard.close();

                        // Refresh list
                        this.getMonitorList();
                        // Refresh status
                        this.monitor_svc.getStatus();
                    }
                }
                break;
            case Mode.MIGRATE:
                // Cancel available only before migration
                if (this.migrate_status == undefined) {
                    this.welcomewizard.reset();
                }
                break;
        }

    }

    /**
     * 
     */
    doFinish(welcome: boolean): void {
        if (welcome) {
            this.welcomewizard.reset();

            // Generate trial license
            this.license_svc.generateTrial().subscribe(
                data => {
                    // Update license infos
                    this.license_svc.getLicenseInfo();
                },
                error => {
                    // Need to route to license page
                    this.license_svc.removeInfo();
                    this.router.navigate(['/license']);
                }
            );

            switch (this.welcome_mode) {
                // Drop migration database
                case Mode.MIGRATE:
                    this.monitor_svc.dropMigrate();
                    break;
            }

        } else {
            this.addwizard.reset();
            // Refresh list
            this.getMonitorList();
            // Refresh status
            this.monitor_svc.getStatus();
        }
    }

    /**
     * 
     */
    updateLang(): void {
        setTimeout(() => {
            switch (this.welcome_lang) {
                case Lang.FR:
                    this.lang = Lang_FR;
                    break;
                case Lang.EN:
                    this.lang = Lang_EN;
                    break;
                case Lang.ES:
                    this.lang = Lang_ES;
                    break;
            }
            this.welcome_license_innerhtml = this.lang.WELCOME_LICENSE_LICENSE;
        });
    }

    /**
     * 
     */
    checkInstance(): boolean {

        // Check if name already used
        if (!this.instance_edit)
            if (this.forbidden_names.find(x => x == this.instance_name) != undefined)
                this.instance_name_forbidden = true;
            else
                this.instance_name_forbidden = false;
        else
            this.instance_name_forbidden = false;


        let valid: boolean = false;

        switch (this.instance_type) {
            case InstanceType.AWS:
                this.instance_aws.name = this.instance_name;
                valid = this.instance_aws.name != '' && this.instance_aws.accesskey != '' && this.instance_aws
                    .secretaccesskey != '';
                if (this.instance_aws.isorg == "true")
                    valid = valid && this.instance_aws.orgname != '' && this.instance_aws.orgaccesskey != '' && this
                    .instance_aws.orgsecretaccesskey != '';
                break;
            case InstanceType.AZURE:
                this.instance_azure.name = this.instance_name;
                valid = this.instance_azure.name != '' && this.instance_azure.clientid != '' && this.instance_azure
                    .tenantid != '' && this.instance_azure.clientsecret != '';
                break;
            case InstanceType.GCP:
                this.instance_gcp.name = this.instance_name;
                valid = this.instance_gcp.name != '' && this.instance_gcp.projectid != '' && this.instance_gcp
                    .file != '';
                break;
            case InstanceType.VMWARE:
            case InstanceType.VCLOUD:
                this.instance_vmware_vcloud.name = this.instance_name;
                valid = this.instance_vmware_vcloud.name != '' && this.instance_vmware_vcloud.ip != '' && this
                    .instance_vmware_vcloud.login != '' && this.instance_vmware_vcloud.pwd != '';
                break;
            case InstanceType.KUBERNETES:
                this.instance_kubernetes.name = this.instance_name;
                //valid = this.instance_kubernetes.name != '' && this.instance_kubernetes.ip != '' && this.instance_kubernetes.file != '';
                valid = this.instance_kubernetes.name != '' && this.instance_kubernetes.ip != '' && this
                    .instance_kubernetes.arch != '' && this.instance_kubernetes.token != '';
                break;
            case InstanceType.SCAPHANDRE:
                this.instance_scaphandre.name = this.instance_name;
                valid = this.instance_scaphandre.name != '' && this.instance_scaphandre.ip != '' && this
                    .instance_scaphandre.login != '' && this.instance_scaphandre.pwd != '';
                break;
            case InstanceType.XCLARITY:
                this.instance_xclarity.name = this.instance_name;
                valid = this.instance_xclarity.name != '' && this.instance_xclarity.ip != '' && this
                    .instance_xclarity.login != '' && this.instance_xclarity.pwd != '';
                break;
            case InstanceType.OPENMANAGE:
                this.instance_openmanage.name = this.instance_name;
                valid = this.instance_openmanage.name != '' && this.instance_openmanage.ip != '' && this
                    .instance_openmanage.login != '' && this.instance_openmanage.pwd != '';
                break;
            case InstanceType.ONEVIEW:
                this.instance_oneview.name = this.instance_name;
                valid = this.instance_oneview.name != '' && this.instance_oneview.ip != '' && this.instance_oneview
                    .login != '' && this.instance_oneview.pwd != '';
                break;
            case InstanceType.IPMI:
                this.instance_ipmi.name = this.instance_name;
                valid = this.instance_ipmi.name != '' && this.instance_ipmi.ip != '' && this.instance_ipmi
                    .login != '' && this.instance_ipmi.pwd != '';
                break;
            case InstanceType.EXAGRID:
                this.instance_exagrid.name = this.instance_name;
                valid = this.instance_exagrid.name != '' && this.instance_exagrid.ip != '' && this.instance_exagrid
                    .login != '' && this.instance_exagrid.pwd != '';
                break;
            case InstanceType.IBM:
                this.instance_ibm.name = this.instance_name;
                valid = this.instance_ibm.name != '' && this.instance_ibm.ip != '' && this.instance_ibm
                    .login != '' && this.instance_ibm.pwd != '';
                break;
            default:
                break;
        }

        return valid && !this.instance_name_forbidden;
    }

    /**
     * 
     */
    pushInstance(welcome: boolean): void {

        // Init status
        this.instance_status = -1;

        // Init wizard & status element
        let objDiv: HTMLDivElement = this.add_div.nativeElement;
        let currentWizard: ClrWizard = this.addwizard;

        if (welcome) {
            currentWizard = this.welcomewizard;
        }

        // Block step navigation
        currentWizard.disableStepnav = true;

        // Empty old status div
        while (objDiv.hasChildNodes()) {
            objDiv.removeChild(objDiv.lastChild);
        }

        // Show loading
        let span: HTMLSpanElement = this.renderer.createElement('span');
        span.classList.add('spinner');
        this.renderer.appendChild(objDiv, span);

        // Add message
        let waiting_p: HTMLParagraphElement = this.renderer.createElement('p');
        waiting_p.style.marginTop = "60px";
        waiting_p.innerHTML = "<b>" + this.lang.INSTALL_PROGRESS_TEXT6 + " ...</b>";
        this.renderer.appendChild(objDiv, waiting_p);


        // Init data & crypt password (vmware / vcloud / scapandre / xclarity)
        let jsondata: any = "";

        switch (this.instance_type) {
            case InstanceType.AWS:
                jsondata = JSON.parse(JSON.stringify(this.instance_aws)); // create a copy
                break;
            case InstanceType.AZURE:
                jsondata = JSON.parse(JSON.stringify(this.instance_azure)); // create a copy
                break;
            case InstanceType.GCP:
                jsondata = JSON.parse(JSON.stringify(this.instance_gcp)); // create a copy
                break;
            case InstanceType.VMWARE:
                jsondata = JSON.parse(JSON.stringify(this.instance_vmware_vcloud)); // create a copy
                jsondata.pwd = this.monitor_svc.crypt(jsondata.pwd);
                break;
            case InstanceType.VCLOUD:
                jsondata = JSON.parse(JSON.stringify(this.instance_vmware_vcloud)); // create a copy
                jsondata.pwd = this.monitor_svc.cryptBase64(jsondata.login + ':' + jsondata.pwd, false);
                break;
            case InstanceType.KUBERNETES:
                jsondata = JSON.parse(JSON.stringify(this.instance_kubernetes)); // create a copy
                break;
            case InstanceType.SCAPHANDRE:
                jsondata = JSON.parse(JSON.stringify(this.instance_scaphandre)); // create a copy
                jsondata.pwd = this.monitor_svc.crypt(jsondata.pwd);
                break;
            case InstanceType.XCLARITY:
                jsondata = JSON.parse(JSON.stringify(this.instance_xclarity)); // create a copy
                jsondata.pwd = this.monitor_svc.crypt(jsondata.pwd);
                break;
            case InstanceType.OPENMANAGE:
                jsondata = JSON.parse(JSON.stringify(this.instance_openmanage)); // create a copy
                jsondata.pwd = this.monitor_svc.crypt(jsondata.pwd);
                break;
            case InstanceType.ONEVIEW:
                jsondata = JSON.parse(JSON.stringify(this.instance_oneview)); // create a copy
                jsondata.pwd = this.monitor_svc.crypt(jsondata.pwd);
                break;
            case InstanceType.IPMI:
                jsondata = JSON.parse(JSON.stringify(this.instance_ipmi)); // create a copy
                jsondata.pwd = this.monitor_svc.crypt(jsondata.pwd);
                break;
            case InstanceType.EXAGRID:
                jsondata = JSON.parse(JSON.stringify(this.instance_exagrid)); // create a copy
                jsondata.pwd = this.monitor_svc.crypt(jsondata.pwd);
                break;
            case InstanceType.IBM:
                jsondata = JSON.parse(JSON.stringify(this.instance_ibm)); // create a copy
                jsondata.pwd = this.monitor_svc.crypt(jsondata.pwd);
                break;
            default:
                break;
        }
        jsondata.type = this.instance_type;
        jsondata.new = this.instance_edit == false;


        // Push instance
        this.monitor_svc.registerInstance(JSON.parse(JSON.stringify(jsondata))).subscribe(
            data => {

                this.instance_status = 0;

                // Empty old status div
                while (objDiv.hasChildNodes()) {
                    objDiv.removeChild(objDiv.lastChild);
                }

                // Create global span
                span = this.renderer.createElement('span');

                // Add icon
                let ok_div: HTMLDivElement = this.renderer.createElement('div');
                ok_div.style.marginTop = "120px";
                ok_div.innerHTML = "<cds-icon shape='check' style='color:#306b00' size='96'></cds-icon>";
                this.renderer.appendChild(span, ok_div);

                // Add messages
                let ok_p: HTMLParagraphElement = this.renderer.createElement('p');
                ok_p.style.marginTop = "60px";
                ok_p.innerHTML = "<b>" + jsondata.name + " " + this.lang.INSTALL_PROGRESS_TEXT2 + "</b>";
                this.renderer.appendChild(span, ok_p);

                this.renderer.appendChild(objDiv, span);

            },

            error => {

                this.instance_status = 1;

                // restore step navigation
                if (!this.instance_edit)
                    currentWizard.disableStepnav = false;

                // XXX force show error nav
                //

                // Empty old status div
                while (objDiv.hasChildNodes()) {
                    objDiv.removeChild(objDiv.lastChild);
                }

                // Create global span
                span = this.renderer.createElement('span');

                // Add icon
                let error_div: HTMLDivElement = this.renderer.createElement('div');
                error_div.style.marginTop = "80px";
                error_div.innerHTML = "<cds-icon shape='times' style='color:#991700' size='96'></cds-icon>";
                this.renderer.appendChild(span, error_div);

                // Add logs button
                let error_button: HTMLButtonElement = this.renderer.createElement('button');
                error_button.style.marginTop = "20px";
                error_button.classList.add('btn');
                error_button.classList.add('btn-link');
                error_button.innerHTML = this.lang.INSTALL_PROGRESS_TEXT3;
                this.renderer.appendChild(span, error_button);

                // Add messages
                let error_p: HTMLParagraphElement = this.renderer.createElement('p');
                error_p.style.marginTop = "60px";
                error_p.innerHTML = "<b>" + this.lang.INSTALL_PROGRESS_TEXT7 + "</b>";
                this.renderer.appendChild(span, error_p);

                let error_p2: HTMLParagraphElement = this.renderer.createElement('p');
                error_p2.innerHTML = "<b>" + this.lang.INSTALL_PROGRESS_TEXT5 +
                    " <a href='mailto:contact@easyvirt.com'>contact@easyvirt.com</a></b>";
                this.renderer.appendChild(span, error_p2);


                this.renderer.appendChild(objDiv, span);

                // Info modal
                let log_div: HTMLDivElement = this.renderer.createElement('div');
                log_div.innerHTML = error.error.message.replaceAll('\n', '<br>');

                this.renderer.listen(error_button, "click", () => this.initInfoModal("Logs", log_div, 'l'));

            }
        );

    }

    /**
     * 
     */
    testMigrate(): void {
        this.migrate_connect_button_state = ClrLoadingState.LOADING;

        // Push migration test
        this.monitor_svc.migrateTest(this.migrate_ip, this.migrate_pwd).subscribe(
            data => {
                this.migrate_connect = true;
                this.migrate_connect_button_state = ClrLoadingState.SUCCESS;
            },
            error => {
                this.migrate_connect_button_state = ClrLoadingState.DEFAULT;
                switch (error.error.code) {
                    case 3:
                    case 2:
                        this.migrate_pwd_show = true;
                        break;
                    case 1:
                        this.migrate_pwd_show = false;
                        break;
                }
            }
        );
    }

    /**
     * 
     */
    pushMigrate(): void {
        // Init migration
        this.initMigration(true);

        // Push migration
        this.monitor_svc.migrate(this.migrate_ip, this.migrate_pwd, this.migrate_retention).subscribe(
            data => {
                // Waiting for status update
                setTimeout(() => {
                    this.getMigrateDetails();
                }, 10000);

                // Timer does the job
            },
            error => {
                // Script exec error

                this.stopMigration(1);

                this.migrate_progress_value = 100;
                this.migrate_progress_message = "Error during migration script execution !";

            }
        );
    }

    /**
     * 
     */
    initMigration(from_wizard: boolean) {

        // Init status
        this.migrate_status = -1;

        // Block step navigation
        this.welcomewizard.disableStepnav = true;

        // Init progress default values
        this.migrate_progress_value = 0;
        this.migrate_progress_message = "retrieving informations, please wait ...";
        this.migrate_progress_class = "progress-static labeled";

        // timer for update progress value & message
        this.initMigrateDetailsTimer();

        // Get migration details
        if (!from_wizard) {
            this.getMigrateDetails();
        }

    }

    /**
     * 
     */
    private stopMigration(code: number) {

        this.migrate_status = Number(code);

        // Stop migrate timer
        this.stopMigrateDetailsTimer();

        if (code == 0) {

            // Success
            this.migrate_progress_class = "progress-static labeled success";

        } else if (code > 0) {

            // Error
            this.migrate_progress_class = "progress-static labeled danger";

            // unable step 4 validation
            //let pages: ClrWizardPage[] = this.welcomewizard.navService.pageCollection.pagesAsArray;
            //pages[3].nextStepDisabled = true;

        }
    }

    /**
     * 
     */
    private getMigrateDetails(): void {
        this.monitor_svc.getMigrateDetails().subscribe(
            data => {
                let info: any = data;

                if (info.step != undefined)
                    this.migrate_progress_value = Number(info.step);
                if (info.detail != undefined)
                    this.migrate_progress_message = info.detail;

                // Check code
                if (info.code != undefined && info.code != -1) {
                    this.stopMigration(info.code);
                }

            }
        );
    }

    /**
     * 
     */
    private initMigrateDetailsTimer(): void {
        let session_interval = interval(60000);
        this.migrate_details_subscription = session_interval.subscribe(val => this.getMigrateDetails());
    }

    /**
     * 
     */
    private stopMigrateDetailsTimer(): void {
        if (this.migrate_details_subscription != undefined)
            this.migrate_details_subscription.unsubscribe();
    }

    /**
     * 
     */
    checkName(target: any): void {
        target.value = target.value.toLowerCase();

        //Remove accent
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        for (var i = 0; i < accent.length; i++) {
            target.value = target.value.replace(accent[i], noaccent[i]);
        }

        //Remove specs chars
        target.value = target.value.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:.;?, ]/g, "_");
    }

    /**
     * 
     */
    removeSpaces(target: any): void {
        target.value = target.value.replace(/ /g, "_");
    }

    /**
     * 
     */
    findVmwareInstances(): Array < any > {
        return this.instances_list.filter(e => e.type == InstanceType.VMWARE || e.type == InstanceType.VCLOUD);
    }

    /**
     * 
     */
    findCloudInstances(): Array < any > {
        return this.instances_list.filter(e => e.type == InstanceType.AWS || e.type == InstanceType.AZURE || e
            .type == InstanceType.GCP);
    }

    /**
     * 
     */
    findInstances(itype: InstanceType): Array < any > {
        return this.instances_list.filter(e => e.type == itype);
    }

    /**
     * 
     */
    private updateBadgesAndNames(): void {
        this.badges.cloud = this.findCloudInstances().length;
        this.badges.vmware = this.findVmwareInstances().length;
        this.badges.kube = this.findInstances(this.kube_type).length;
        this.badges.scaph = this.findInstances(this.scaph_type).length;
        this.badges.xclarity = this.findInstances(this.xclarity_type).length;
        this.badges.openmanage = this.findInstances(this.openmanage_type).length;
        this.badges.oneview = this.findInstances(this.oneview_type).length;
        this.badges.ipmi = this.findInstances(this.ipmi_type).length;
        this.badges.exagrid = this.findInstances(this.exagrid_type).length;
        this.badges.ibm = this.findInstances(this.ibm_type).length;

        this.forbidden_names = this.instances_list.map(x => x.name);
    }

    /**
     * 
     */
    getInstanceFullname(type: string): string {
        return InstanceFullName[type.toUpperCase()];
    }

    /**
     * 
     */
    getRegionFullname(type: string, region: string) {
        let regions: Array < any > ;

        switch (type) {
            case InstanceType.AWS:
                regions = this.aws_regions;
                break;
            case InstanceType.AZURE:
                regions = this.azure_regions;
        }
        let entry = regions.find(x => x.value == region);
        if (entry != undefined)
            return entry.display;
        else
            return region;
    }

    /**
     * 
     */
    private initInfoModal(header: string, body: any, size: string) {

        let objDiv: HTMLDivElement = this.info.nativeElement;

        // Empty old info div
        while (objDiv.hasChildNodes()) {
            objDiv.removeChild(objDiv.lastChild);
        }

        this.info_modal = true;
        this.info_modal_size = size;

        this.info_header = header;
        this.renderer.appendChild(objDiv, body);

    }

    /**
     * 
     */
    pushGcpConfigFile(e: {
        target: {
            files: any[];
        };
    }) {
        let file = e.target.files[0];
        this.instance_gcp.file = file.name;

        let fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onload = (e) => {
            this.instance_gcp.filecontent = btoa( < string > fileReader.result);
        }
    }

}
