<div class="clr-row">
    <div [ngClass]="model.configuratorClass" *ngIf="model.isProfileCard">
        <div class="card">
            <div class="card-header">
                <div class="clr-row">
                    <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
                        <div class="clr-control-container">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" 
                                        [(ngModel)]="model.profile"
                                        (ngModelChange)="loadGenericProfile()">
                                    <option id="congigurator-co2-profil" *ngFor="let profile of profiles">{{profile}}</option>
                                </select>
                            </div>
                            <span class="clr-subtext">select a profile</span>
                        </div>
                    </div>
                    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                        <div class="clr-control-container">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" 
                                        [(ngModel)]="model.tagName"
                                        (ngModelChange)="loadProfileForTagName()">
                                    <option id="congigurator-co2-tagname" *ngFor="let tagName of tagNames">{{tagName}}</option>
                                </select>
                            </div>
                            <span class="clr-subtext">select a tag name</span>
                        </div>
                    </div>
                    <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
                        <div class="clr-control-container" *ngIf="tagValues.length > 0">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" 
                                        [(ngModel)]="model.tagValue"
                                        (ngModelChange)="loadProfileForTagValue()">
                                    <option id="congigurator-co2-tagvalue" *ngFor="let tagValue of tagValues">{{tagValue}}</option>
                                </select>
                            </div>
                            <span class="clr-subtext">select a tag value</span>
                        </div>
                    </div>
                    <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
                        <button class="btn btn-sm" style="float: right;" (click)="resetZoom()" *ngIf="model.isResetZoom">RESET ZOOM</button>
                    </div>
                    <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
                        <cds-icon title="bar chart" shape="bar-chart" size="sm" ngClass="addLink" style="float: right;" (click)="reloadGraph()" *ngIf="model.chartType=='line'"></cds-icon>
                        <cds-icon title="line chart" shape="line-chart" size="sm" ngClass="addLink" style="float: right;" (click)="reloadGraph()" *ngIf="model.chartType=='column'"></cds-icon>
                        <cds-icon id="congigurator-co2-tagpie" title="tag distribution" shape="pie-chart" size="sm" ngClass="addLink" style="float: right;margin-right: 10px;" (click)="loadPieChart()" *ngIf="model.isValidPie"></cds-icon>
                        <cds-icon id="congigurator-co2-tagmap" title="time distribution" shape="heat-map" size="sm" ngClass="addLink" style="float: right;margin-right: 10px;" (click)="loadMapChart()" *ngIf="model.isValidMap"></cds-icon>
                    </div>
                </div>
            </div>
            <div class="card-block">
                
                <highcharts-chart
                    [Highcharts] = "highcharts"
                    [options] = "chartOptions"
                    [callbackFunction]="chartCallback"
                    [constructorType]="'chart'"
                    style = "width: 100%;height: 330px;display: block;" 
                    *ngIf="isReady">
                </highcharts-chart>
            </div>
        </div>
    </div>
    <div [ngClass]="model.pieTagClass" *ngIf="model.isPieClick">
        <div class="card">
            <div class="card-header" style="text-align: center;">
                <span style="color:grey;font-size:16px;float: left;">
                    Distribution by tag name: {{model.tagName}}&nbsp;&nbsp;
                    <fa-icon [icon]="['fas', 'leaf']" style="color:grey;font-size:14px;"></fa-icon>&nbsp;&nbsp;
                    <span style="color:black;font-size:20;">
                            <b>{{model.aggregateTotalPie.toFixed(2)}} kgCO2 eq</b>
                    </span>
                </span>
                <span style="float: right;" ngClass="addLink" (click)="model.isProfileCard = true; model.isPieClick = false; isReady2 = false; isReady4 = false">
                    <cds-icon shape="times" size="24"></cds-icon>
                </span>
                <cds-icon shape="heat-map" size="sm" ngClass="addLink" style="float: right;margin-right: 50px;" (click)="loadMapFromPieChart()" *ngIf="isReady2"></cds-icon>
                <cds-icon shape="pie-chart" size="sm" ngClass="addLink" style="float: right;margin-right: 50px;" (click)="isReady4 = false;isReady2 = true" *ngIf="isReady4"></cds-icon>
                <br>
            </div>
            <div class="card-block">
                <div class="clr-row">
                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                        <highcharts-chart
                            [Highcharts] = "highcharts"
                            [options] = "chartOptions2"
                            [constructorType]="'chart'"
                            style = "width: 100%;height: 275px;display: block;" 
                            *ngIf="isReady2">
                        </highcharts-chart>
                    </div>
                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                        <highcharts-chart
                            [Highcharts] = "highcharts"
                            [options] = "chartOptions4"
                            [constructorType]="'chart'"
                            style = "width: 100%;height: 275px;display: block;" 
                            *ngIf="isReady4">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <span style="color: grey; font-weight: bold;">
                        {{model.profileMsg}}
                </span>
            </div>
        </div>
    </div>
    <div [ngClass]="model.mapTagClass" *ngIf="isReady3">
        <div class="card">
            <div class="card-header" style="color:grey;font-size:14px;font-weight: bold;text-align: left;">
                <span>over {{message.cloudMessage.profileDate}}</span>
                <span style="float: right;" ngClass="addLink" (click)="model.isProfileCard = true; isReady3 = false;">
                    <cds-icon shape="times" size="24"></cds-icon>
                </span>
            </div>
            <div class="card-block">
                <div class="clr-row">
                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                        <highcharts-chart
                            [Highcharts] = "highcharts"
                            [options] = "chartOptions3"
                            [constructorType]="'chart'"
                            style = "width: 100%;height: 350px;display: block;" 
                            *ngIf="isReady3">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>