<clr-wizard #wizard [(clrWizardOpen)]="_open">
    <clr-wizard-title>Apps groups management wizard</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'" (click)="finish()">Finish</clr-wizard-button>

    <clr-wizard-page>
        <ng-template clrPageTitle>Select an action</ng-template>
        <ng-template clrPageNavTitle>Select an action</ng-template>

        Please choose one of the options below:

        <div class="clr-form-control" style="margin-top: 15px;">
            <div class="clr-control-container">
                <div class="clr-radio-wrapper">
                    <input type="radio" id="vertical-radio1" [(ngModel)]="action_choice" name="radio-full" value="manual_appgroup" class="clr-radio" />
                    <label for="vertical-radio1" class="clr-control-label">Create an app group manually</label>
                    <input type="radio" id="vertical-radio2" [(ngModel)]="action_choice" name="radio-full" value="import_manual_appsgroups_from_csv" class="clr-radio" />
                    <label for="vertical-radio2" class="clr-control-label">Import manual apps groups from a CSV file</label>
                    <input type="radio" id="vertical-radio3" [(ngModel)]="action_choice" name="radio-full" value="import_tag" class="clr-radio" />
                    <label for="vertical-radio3" class="clr-control-label">Import a Tag as a new app group</label>
                    <input type="radio" id="vertical-radio4" [(ngModel)]="action_choice" name="radio-full" value="import_category" class="clr-radio" />
                    <label for="vertical-radio4" class="clr-control-label">Import a Category as a several new apps groups</label>
                </div>
            </div>
        </div>

    </clr-wizard-page>

    <clr-wizard-page *ngIf="action_choice === 'import_manual_appsgroups_from_csv'">
        <ng-template clrPageTitle>Import from CSV file</ng-template>
        <ng-template clrPageNavTitle>Import from CSV file</ng-template>

        <div class="clr-row">
            <div class="clr-col">
                <div class="card">
                    <div class="card-header">
                        <span class="badge badge-blue">1</span> Pick upload a CSV file
                    </div>
                    <div class="card-block">
                        <input type='file' (change)="fileChanged($event)">
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col">
                <div class="card">
                    <div class="card-header">
                        <span class="badge badge-blue">2</span> Review the apps-groups
                    </div>
                    <div class="card-block">
                        <div class="card" *ngFor="let appGroup of this.appsGroupsFromCsv">
                            <div class="card-header">
                                {{ appGroup.name }}
                            </div>
                            <div class="card-block">
                                <span *ngFor="let vm of appGroup.vms" class="label label-light-blue clickable">
                                    <span class="badge" style="margin: 0 5px 0 0;">vm</span>
                                    {{ vm.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </clr-wizard-page>

    <clr-wizard-page *ngIf="action_choice === 'import_tag'">
        <ng-template clrPageTitle>Tag selection</ng-template>
        <ng-template clrPageNavTitle>Tag selection</ng-template>

        <div class="clr-row">
            <div class="clr-col">
                <div class="card">
                    <div class="card-header">
                        <span class="badge badge-blue">1</span> Pick a tag below
                    </div>
                    <div class="card-block">
                        <clr-tree>
                            <clr-tree-node *clrRecursiveFor="let file of rootTagSelection; getChildren: getChildren"
                                           [clrExpanded]="true">
                                <clr-radio-wrapper *ngIf="file.selectable">
                                    <input type="radio" clrRadio name="options" required value="{{file.treeValue}}" [(ngModel)]="selectedTagStep2" (change)="onTagSelection(file)"/>
                                    <label><cds-icon shape="tag"></cds-icon>{{file.name}}</label>
                                </clr-radio-wrapper>
                                <label *ngIf="!file.selectable"><cds-icon shape="tags"></cds-icon> {{file.name}}</label>
                            </clr-tree-node>
                        </clr-tree>
                    </div>
                </div>
            </div>
        </div>
    </clr-wizard-page>

    <clr-wizard-page *ngIf="action_choice === 'import_category'">
        <ng-template clrPageTitle>Category selection</ng-template>
        <ng-template clrPageNavTitle>Category selection</ng-template>

        <div class="clr-row">
            <div class="clr-col">
                <div class="card">
                    <div class="card-header">
                        <span class="badge badge-blue">1</span> Pick a category below
                    </div>
                    <div class="card-block">
                        <clr-tree>
                            <clr-tree-node *clrRecursiveFor="let file of rootCategorySelection; getChildren: getChildren"
                                           [clrExpanded]="true">
                                <clr-radio-wrapper *ngIf="file.selectable">
                                    <input type="radio" clrRadio name="options" required value="{{file.name}}" [(ngModel)]="selectedTagStep2" (change)="onCategorySelection(file)"/>
                                    <label><cds-icon shape="tag"></cds-icon>{{file.name}}</label>
                                </clr-radio-wrapper>
                                <label *ngIf="!file.selectable"><cds-icon shape="tags"></cds-icon> {{file.name}}</label>
                            </clr-tree-node>
                        </clr-tree>
                    </div>
                </div>
            </div>
        </div>
    </clr-wizard-page>
</clr-wizard>
