<div class="clr-row">
        <div class="card">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				Instance spot
			</span>
			<button class="btn btn-link" style="position:relative;float:right;" (click)="exportSpots()">
				Export
			</button>
                </div>
                <div class="card-block">
			<div class="datagrid-limit-height">
				<clr-datagrid>
					<clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'iter'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Autoscaling</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'computeCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute cost ($)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'spotCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Spot Cost ($)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'computeCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Estimated compute cost year($)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'spotCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Estimated spot cost year ($)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'gain'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Gain (%)</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let spot of spots" [clrDgItem]="spot">
						<clr-dg-cell>{{spot.id}}</clr-dg-cell>
						<clr-dg-cell>{{spot.name}}</clr-dg-cell>
						<clr-dg-cell>{{spot.iter}}</clr-dg-cell>
						<clr-dg-cell>{{spot.computeCost.toFixed(2)}}</clr-dg-cell>
						<clr-dg-cell>{{spot.spotCost.toFixed(2)}}</clr-dg-cell>
						<clr-dg-cell>{{spot.computeCostYear}}</clr-dg-cell>
						<clr-dg-cell>{{spot.spotCostYear}}</clr-dg-cell>
						<clr-dg-cell>{{spot.gain}}</clr-dg-cell>
					</clr-dg-row>
					<clr-dg-footer>
                				<clr-dg-pagination #pagination [clrDgPageSize]="10">
                    					<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
								    entries per page
							</clr-dg-page-size>
                    					{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                				</clr-dg-pagination>
            				</clr-dg-footer>
				</clr-datagrid>
			</div>
                </div>
        </div>
</div>