<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="table"></cds-icon>
					&nbsp;Server list 
				</h5>
				<span class="export"></span>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  					<thead>
    						<tr>
      							<th>NAME</th>
      							<th>IDENTIFIER</th>
      							<th>OS</th>
      							<th>MODEL</th>
      							<th>CPU MODEL</th>
      							<th>CPU#</th>
      							<th>CORES#</th>
      							<th>THREADS#</th>
      							<th>CPU FREQ (GHz)</th>
								<th>RAM CAP (GB)</th>
      							<!-- <th>POWER MIN (Watt)</th>
      							<th>POWER MAX (Watt)</th> -->
						</tr>
  					</thead>
  					<tbody>
    						<tr *ngFor="let host of data_hosts.data" ngClass="addLink" (click)="callroute(host)">
							<td>{{host[0]}}</td>
							<td>{{host[1]}}</td>
							<td>{{host[2]}}</td>
							<td>{{host[3]}}</td>
							<td>{{host[4]}}</td>
							<td>{{host[5]}}</td>
							<td>{{host[6]}}</td>
							<td>{{host[7]}}</td>
							<td>{{math.round(host[8]/1000)}}</td>
							<td>{{math.round(host[9]/1024)}}</td>
							<!-- <td>{{host[10]}}</td>
							<td>{{host[11]}}</td> -->
						</tr>
  					</tbody>
  				</table>
			</div>
		</div>
	</div>
</div>