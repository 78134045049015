import {
    Component,
    EventEmitter,
    Input,
    OnInit
} from '@angular/core';

@Component({
    selector: 'turned-off-no-match-filter',
    templateUrl: './turned-off-no-match-filter.component.html',
    styleUrls: ['./turned-off-no-match-filter.component.css']
})
export class TurnedOffNoMatchFilterComponent implements OnInit {

    filterIsActive = false;

    @Input()
    label;

    @Input()
    fieldName;

    @Input()
    items: any[];
    options;

    @Input()
    tableUpdate: EventEmitter < any > ;

    allValues = [];
    static displayTurnedOff = true;
    static displayNoMatch = true;

    changes: any = new EventEmitter < any > (false);

    manualRefresh: EventEmitter < void > = new EventEmitter < void > ();

    ngOnInit() {
        this.initFilter();

        // If table data is not dynamic, there is no need to provide an event emitter
        if (this.tableUpdate != undefined) {
            this.tableUpdate.subscribe(items => {
                this.items = items;
                this.initFilter();
                this.manualRefresh.emit();
            });
        }
    }

    initFilter = () => {
        console.log(`call to initFilter(); with field ${this.fieldName}`);
        let allValues = [];
        this.options = {};

        if (this.items !== undefined) {
            allValues = [...new Set(this.items.map((item) => item[this.fieldName]).filter((value) => value !=
                null))];
            for (let value of allValues) {
                this.options[value] = true;
            }
        }

        this.allValues = allValues;
    }

    triggerEvent() {
        this.changes.emit(true);
    }

    accepts(item: any) {
        let itemFieldValue = item[this.fieldName];

        if (Object.keys(this.options).indexOf(itemFieldValue) != -1) {
            return this.options[itemFieldValue]
        }

        if (itemFieldValue == null && item.state == 0) {
            return TurnedOffNoMatchFilterComponent.displayTurnedOff;
        }

        if (itemFieldValue == null && item.state != 0) {
            return TurnedOffNoMatchFilterComponent.displayNoMatch;
        }

        return true;
    }

    selectAll() {
        for (let key of Object.keys(this.options)) {
            this.options[key] = true;
        }
        TurnedOffNoMatchFilterComponent.displayNoMatch = true;
        TurnedOffNoMatchFilterComponent.displayTurnedOff = true;
    }

    unselectAll() {
        for (let key of Object.keys(this.options)) {
            this.options[key] = false;
        }
        TurnedOffNoMatchFilterComponent.displayNoMatch = false;
        TurnedOffNoMatchFilterComponent.displayTurnedOff = false;
    }

    isActive(): boolean {
        if (!TurnedOffNoMatchFilterComponent.displayTurnedOff) {
            return true;
        }
        if (!TurnedOffNoMatchFilterComponent.displayNoMatch) {
            return true;
        }
        if (this.options != undefined) {
            for (let key of Object.keys(this.options)) {
                if (!this.options[key]) {
                    return true;
                }
            }
        }
        return false;
    }

    get displayTurnedOff() {
        return TurnedOffNoMatchFilterComponent.displayTurnedOff;
    }

    set displayTurnedOff(value) {
        TurnedOffNoMatchFilterComponent.displayTurnedOff = value;
    }

    get displayNoMatch() {
        return TurnedOffNoMatchFilterComponent.displayNoMatch;
    }

    set displayNoMatch(value) {
        TurnedOffNoMatchFilterComponent.displayNoMatch = value;
    }
}
