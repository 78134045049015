import {
    Component,
    HostListener,
    OnInit
} from '@angular/core';

import {
    first
} from 'rxjs/operators';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    Message,
    User
} from '@app/model';

import {
    AccountService,
    MeasurementService,
    ShareService
} from '@app/services';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';


@Component({
    selector: 'app-instances-co2',
    templateUrl: './instances-co2.component.html',
    styleUrls: ['./instances-co2.component.css']
})
export class InstancesCo2Component implements OnInit {

    isDcscope = true;
    isCo2scope = false;

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};

    dataOrder = ClrDatagridSortOrder.DESC;
    dataOrder2 = ClrDatagridSortOrder.DESC;

    isSelected: boolean = false;

    message: Message;

    instancesAll: any = [];

    instances: any = [];

    co2InstancesYear: any = [];

    months: any = [];

    model: any = {
        isModel: false,
        selectedMonth: '',
        profileMsg: 'over the current month'
    };

    graphClass = 'clr-col-lg-2 clr-col-md-2 clr-col-12';
    tabClass: string = 'clr-col-lg-10 clr-col-md-10 clr-col-12';
    isYearly: boolean = false;
    isCo2Year: boolean = false;
    isVmYear: boolean = false;

    selected_instance: any;

    isReady: boolean = false;
    isGraph: boolean = true;

    provider: string = '';

    currentUser: User;

    @HostListener('document:click', ['$event.target'])
    DocumentClick(event: any) {
        if(event.id == "congigurator-co2-profil" || event.id == "congigurator-co2-tagvalue" || event.id == "congigurator-co2-tagname") {
            setTimeout(() => this.buildInstanceList(), 500);
        } else if (event.point != undefined) {
            if(event.point.id == "congigurator-co2-profil-time")
                setTimeout(() => this.buildInstanceList(), 500);
        }
    }


    constructor(
        private account_svc: AccountService,
        private measurement_svc: MeasurementService,
        private message_svc: ShareService) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            (user: User) => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.provider = this.message.cloudProvider;

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;

        setTimeout(() => this.buildInstanceList(), 500);
    }

    exportInstances() {

        let res = ["ID", "Name", "Account", "Region", "Type", "State", "Running Hours", "Direct power (Wh)",
            "Direct CO2 emission (Kg CO2eq)", "Embodied emission (Kg CO2eq)"
        ].join(',') + '\n';
        res += Object.values < any > (this.instances).map(instance => [instance.identifier, instance.name, instance
            .subscription, instance.region, instance.instanceType,
            instance.state, instance.runH, instance.direct_power, instance.direct_co2, instance.grey_co2
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'overview_greenit.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    private buildInstanceList(): void {

        this.instances = [];

        let profile: string = this.message.cloudMessage.profile;
        let isGeneric: boolean = true;
        let uuids: string[] = this.message.cloudMessage.instanceIds;

        let tag: any = {
            tagName: this.message.cloudMessage.tag.tagName,
            tagValue: this.message.cloudMessage.tag.tagValue
        };
        
        if(this.message.cloudMessage.isWithTag)
            isGeneric = false;

        this.measurement_svc.getInstancesCarbonEmission(this.provider, profile, uuids, isGeneric, this.message.cloudMessage.cloudTime, tag).pipe(first()).subscribe(
            (data: any) => {
              if(data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let subdesc: any = {
                        region: data[i].region,
                        subscription: data[i].subscription,
                        resgroup: data[i].resgroup
                    };

                    let instance: any = {
                        identifier: data[i].uuid,
                        name: data[i].name,
                        subscriptionDesc: subdesc,
                        instanceType: data[i].it,
                        state: data[i].state,
                        runH: data[i].runh,
                        direct_power: data[i].wh,
                        direct_co2: data[i].co2_d,
                        grey_co2: data[i].co2_e
                    };

                    //ADD TAG
                    if(this.message.cloudMessage.isWithTag) {
                        for(let vTag of this.message.cloudMessage.tag.vmsTag) {
                            if(vTag.identifier == data[i].uuid) {
                                instance.tagName = vTag.tagName;
                                instance.tagValue = vTag.tagValue;
                            }
                        }
                    }

                    this.instances.push(instance);
                }
              }
            },
            (error: any) => {
                if (error != null)
                    console.log(error)
            }
        );

        // BUILD TIME RANGE
        switch(profile) {
            case "daily":
                let startDay = moment(this.message.cloudMessage.profileDate).subtract(1, 'days').unix()*1000;
                let day_str: string = moment.unix(startDay/1000).format('dddd, MMMM Do YYYY');
                this.model.profileMsg = 'over the ' + day_str;
                break;
            case "monthly":
                this.model.profileMsg = 'over ' + this.message.cloudMessage.profileDate;
                break;
            case "weekly":
                let start = this.message.cloudMessage.cloudTime.startProfile;
                let end = this.message.cloudMessage.cloudTime.endProfile;
                let week = moment(this.message.cloudMessage.profileDate).subtract(1, 'weeks').weeks();
                this.model.profileMsg = 'over the week ' + week + ' [ ' + moment.unix(start).format('dddd, MMMM Do')
                    + ' to ' + moment.unix(end).format('dddd, MMMM Do YYYY') + ' [';
                break;
            case "yearly":
                let startYear = moment(this.message.cloudMessage.profileDate).unix()*1000;
                let year_str: string = moment.unix(startYear/1000).format('YYYY');
                this.model.profileMsg = 'over the year ' + year_str;
                break;
            default:
                break;
        }
    }
}
