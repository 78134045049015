import {
    Component,
    OnInit
} from '@angular/core';
import {
    AccountService
} from '@app/services';
import {
    ADSettings,
    LdapProperty,
    LdapType
} from '@app/model';

@Component({
    selector: 'app-ldap',
    templateUrl: './ldap.component.html',
    styleUrls: ['./ldap.component.css']
})
export class LdapComponent implements OnInit {

    type = LdapType;
    property = LdapProperty;

    // For settings
    private db_settings: ADSettings[] = [];
    private settings_changed: any;
    settings_modal: boolean = false;
    save_button: boolean = false;

    ldap_type = this.type.AD;
    ldap_hostname: string = "";
    ldap_groupdn: string = "";
    ldap_basedn: string = "";
    ldap_domain: string = "";
    ldap_port: number = 636;
    //openldap
    ldap_userdn: string = "";
    ldap_binddn: string = "";
    ldap_bindpwd: string = "";
    ldap_userobjclass: string = "";


    /**
     * 
     */
    constructor(private authentication_svc: AccountService, ) {
        // initialization
        this.save_button = false;
        this.settings_changed = {};
    }

    /**
     * 
     */
    ngOnInit(): void {
        this.settings_modal = true;

        // GET SETTINGS
        this.authentication_svc.getADSettings().subscribe(
            data => {
                this.updateDataSettings(data);
            });
    }

    /**
     * 
     */
    addSettings(element: HTMLInputElement): void {

        switch (element.id) {
            case LdapProperty.HOSTNAME:
                if (this.ldap_hostname != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_hostname
                    });
                }
                break;
            case LdapProperty.GROUPDN:
                if (this.ldap_groupdn != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_groupdn
                    });
                }
                break;
            case LdapProperty.BASEDN:
                if (this.ldap_basedn != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_basedn
                    });
                }
                break;
            case LdapProperty.DOMAIN:
                if (this.ldap_domain != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_domain
                    });
                }
                break;
            case LdapProperty.PORT:
                if (this.ldap_port != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_port
                    });
                }
                break;
            case LdapProperty.USERDN:
                if (this.ldap_userdn != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_userdn
                    });
                }
                break;
            case LdapProperty.BINDDN:
                if (this.ldap_binddn != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_binddn
                    });
                }
                break;
            case LdapProperty.BINDPWD:
                if (this.ldap_bindpwd != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_bindpwd
                    });
                }
                break;
            case LdapProperty.USEROBJCLASS:
                if (this.ldap_userobjclass != null) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.ldap_userobjclass
                    });
                }
                break;
            default:
                break;
        }

        // Update save button status
        this.save_button = true;
    }

    /**
     * 
     */
    saveSettings(): void {
        this.settings_modal = false;

        // Push ldap type & port
        Object.assign(this.settings_changed, {
            [LdapProperty.TYPE]: this.ldap_type
        });
        Object.assign(this.settings_changed, {
            [LdapProperty.PORT]: this.ldap_port
        });

        ////////////////
        // SAVE SETTINGS
        ////////////////

        let json: any = JSON.parse(JSON.stringify(this.settings_changed));

        if (json["ldap.bindpwd"]) {
            json["ldap.bindpwd"] = this.authentication_svc.crypt(json["ldap.bindpwd"]);
        }

        // Save settings
        this.authentication_svc.updateADSettings(json).subscribe(
            success => {
                // Update settings
                this.authentication_svc.getADSettings().subscribe(
                    data => {
                        this.updateDataSettings(data);
                    },
                    error => {
                        console.log(error);
                    }
                );
            },
            error => {
                console.log(error);
            }
        );

        this.save_button = false;
        this.settings_changed = {};
    }

    /**
     * 
     */
    private updateDataSettings(data: any) {
        this.db_settings = data;

        for (let i in this.db_settings) {
            if (this.db_settings[i] != undefined) {

                switch (this.db_settings[i].option) {
                    case LdapProperty.TYPE:
                        let type_tmp = this.db_settings[i].value;
                        let key = Object.keys(LdapType)[Object.values(LdapType).indexOf(type_tmp as LdapType)];
                        this.ldap_type = LdapType[key];
                        break;
                    case LdapProperty.HOSTNAME:
                        this.ldap_hostname = this.db_settings[i].value;
                        break;
                    case LdapProperty.GROUPDN:
                        this.ldap_groupdn = this.db_settings[i].value;
                        break;
                    case LdapProperty.BASEDN:
                        this.ldap_basedn = this.db_settings[i].value;
                        break;
                    case LdapProperty.DOMAIN:
                        this.ldap_domain = this.db_settings[i].value;
                        break;
                    case LdapProperty.PORT:
                        this.ldap_port = Number(this.db_settings[i].value);
                        break;
                    case LdapProperty.USERDN:
                        this.ldap_userdn = this.db_settings[i].value;
                        break;
                    case LdapProperty.BINDDN:
                        this.ldap_binddn = this.db_settings[i].value;
                        break;
                    case LdapProperty.BINDPWD:
                        this.ldap_bindpwd = this.authentication_svc.decrypt(this.db_settings[i].value);
                        break;
                    case LdapProperty.USEROBJCLASS:
                        this.ldap_userobjclass = this.db_settings[i].value;
                        break;
                    default:
                        break;
                }

            }
        }
    }

}
