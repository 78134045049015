import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    ClrLoadingState
} from "@clr/angular";
import {
    JsonloaderService,
    LicenseService,
    ShareService
} from "@app/services";
import {
    faAws,
    faGoogle,
    faMicrosoft
} from "@fortawesome/free-brands-svg-icons";
import {
    merge,
    Subject
} from 'rxjs';
import {
    concatAll,
    toArray
} from 'rxjs/operators';
import {
    environment
} from "@environments/environment";
import * as Highcharts from 'highcharts';
import {
    Chart
} from 'highcharts';

import {
    CloudPricingService
} from "@app/services/cloud-pricing.service";
import {
    CloudCarbonService
} from '@app/services/cloud-carbon.service';
import {
    CloudimpactService
} from '@app/services/cloudimpact.service';
import {
    JSONTarget
} from "@app/model";


@Component({
    selector: 'app-cloudimpact',
    templateUrl: './cloudimpact.component.html',
    styleUrls: ['./cloudimpact.component.css']
})
export class CloudimpactComponent implements OnInit, OnDestroy {
    // Display parameters
    isCloudPricingLicenceEnabled = true;
    informationModalOpened = false;
    dateLastUpdatePrices = "checking";
    dateLastUpdateEmissions = "checking";
    isLoading = false;
    pricesFetchState: ClrLoadingState = ClrLoadingState.DEFAULT;

    // Cloud carbon parameters
    isRecoEnabled = false;

    // Filter europeans regions
    isOnlyEuropeansEnabled = false;
    europeansPatterns = [
        RegExp("france"),
        RegExp("eu"),
        RegExp("europe"),
        RegExp("united-kingdom"),
        RegExp("FRA|LON|GRA|SBG|WAW"),
        RegExp("myregion"),
        RegExp("Europe"),
        RegExp("Paris"),
        RegExp("London"),
        RegExp("EU"),
        RegExp("France"),
    ];

    // Cloud pricing parameters
    period = "year";
    billingType = "od";
    currencyOption = "USD";
    currencySymbol = "$";
    exchangeRates: any;

    // Icons for page display
    awsIcon = faAws;
    azureIcon = faMicrosoft;
    gcpIcon = faGoogle;

    // Providers variables initialization
    providers = ["aws", "az", "gcp", "myprovider"];
    noCarbonProviders = ["ovh" /*, "myprovider"*/ ];

    providersRegions = {
        "aws": [],
        "az": [],
        "gcp": [],
        "ovh": [],
        "myprovider": []
    };

    // VMs variables initialization
    listedVms = {
        "aws": [],
        "az": [],
        "gcp": [],
        "ovh": [],
        "myprovider": [],
    };

    // Miscellaneous
    infoUpdate: any;
    mapOS: any;
    progress = 0;


    // Highchart
    Highcharts: typeof Highcharts = Highcharts;
    updateFlag = false;
    private priceEmissionsChart: Chart;
    private priceChart: Chart;

    priceEmissionsChartReflowInterval;
    priceChartReflowInterval;


    onPremiseData = [{
        "x": 0,
        "y": 0,
        "region": "",
        "providerTag": "",
        "regionTag": "",
    }];
    awsData = [];
    azData = [];
    gcpData = [];
    ovhData = [];
    myproviderData = [];

    dashboardSeries = [];
    pricechartSeries = [];

    displayLoading = "";
    greenItReco;


    constructor(private cloudimpactService: CloudimpactService, private jsonLoaderService: JsonloaderService,
        public licenceService: LicenseService, private cloudCarbonService: CloudCarbonService,
        private cloudPricingService: CloudPricingService, public shareService: ShareService) {
        // Ensure that parameters that may have been set previously are kept
        this.shareService.currentMessage.subscribe((msg) => {
            this.isRecoEnabled = msg.cloudPricing.applyRecommendations;

            let user = msg.elementViewUser;
            let filter = msg.currentFilter;

            this.jsonLoaderService.getData(user, filter, JSONTarget.GREENIT_RECO).subscribe(
                data => {
                    this.greenItReco = data;
                }
            );

        });

        // Ensure that filter changes are taken into account
        if (this.jsonLoaderService.json !== undefined && this.jsonLoaderService.json.vmComputeStorageReco !==
            null) {
            this.reloadUi();
        } else {
            this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
                this.reloadUi();
            });
        }

        // Check Licence
        this.licenceService.licenseInfo.subscribe(licenceInfo => {
            if (environment.production) {
                // Check first bit to be different than 0
                this.isCloudPricingLicenceEnabled = (licenceInfo.moduleslicense & (1 << 0)) !== 0;
            } else {
                this.isCloudPricingLicenceEnabled = true;
            }
        });
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        for (let interval of [this.priceEmissionsChartReflowInterval, this.priceChartReflowInterval]) {
            if (interval !== undefined) {
                clearInterval(interval)
            }
        }
    }

    chartCallbackPriceEmissionsChart = (chartRef) => {
        this.priceEmissionsChart = chartRef;
        this.priceEmissionsChartReflowInterval = setInterval(() => {
            this.priceEmissionsChart.reflow();
        }, 1000);
    }

    chartCallbackPriceChart = (chartRef) => {
        this.priceChart = chartRef;
        this.priceChartReflowInterval = setInterval(() => {
            this.priceChart.reflow();
        }, 1000);
    }

    // Ensure that parameters that may have been set previously are kept
    keepCloudPricingParameters() {
        this.shareService.currentMessage.subscribe((msg) => {
            msg.cloudPricing.applyRecommendations = this.isRecoEnabled;
        });
    }

    updatePricesAndEmissionsUpdateDate = () => {
        // Fetch the date of the last price update
        this.cloudPricingService.fetchLastUpdateDetails().subscribe(lastUpdateDetailsData => {
            // @ts-ignore
            if (lastUpdateDetailsData.date !== undefined) {
                // @ts-ignore
                this.dateLastUpdatePrices = lastUpdateDetailsData.date;
            } else {
                this.dateLastUpdatePrices = "unknown";
            }
        });
        // Fetch the date of the last price update
        this.cloudCarbonService.fetchLastUpdateDetails().subscribe(lastUpdateDetailsData => {
            // @ts-ignore
            if (lastUpdateDetailsData.date !== undefined) {
                // @ts-ignore
                this.dateLastUpdateEmissions = lastUpdateDetailsData.date;
            } else {
                this.dateLastUpdateEmissions = "unknown";
            }
        });
    }

    // Reloads
    reloadUi(): void {
        this.updateChartOptions();
        this.isLoading = true;
        this.progress = 0;
        this.displayLoading = "";

        // Update dates of prices and emissions
        this.updatePricesAndEmissionsUpdateDate();

        // Reload fresh data
        this.jsonLoaderService.currentJsonSubject.subscribe(json => {
            let providersCloudPricingData = {};
            let providersCarbonData = {};

            let subject = new Subject();
            let loadedCounter = 0;
            subject.subscribe(data => {
                loadedCounter += 1;
                if (loadedCounter === 3) {
                    this.reloadData(json, providersCarbonData, providersCloudPricingData);
                }
            });

            // Fetch cloud pricing data
            let observableList = this.providers.map((providerName) => this.cloudPricingService
                .fetchProviderDetails(providerName));
            let singleObservable = merge(observableList).pipe(concatAll(), toArray());
            singleObservable.subscribe((data) => {
                for (let i = 0; i < data.length; i++) {
                    let correspondingProviderName = this.providers[i];
                    providersCloudPricingData[correspondingProviderName] = data[i]
                }
                subject.next(1);
            });

            // Fetch cloud pricing data for non-cloud carbon providers
            let observableList1 = this.noCarbonProviders.map((providerName) => this.cloudPricingService
                .fetchProviderDetails(providerName));
            let singleObservable1 = merge(observableList1).pipe(concatAll(), toArray());
            singleObservable1.subscribe((data) => {
                for (let i = 0; i < data.length; i++) {
                    let correspondingProviderName = this.noCarbonProviders[i];
                    providersCloudPricingData[correspondingProviderName] = data[i]
                }
                subject.next(1);
            });

            // Fetch cloud carbon data
            let observableList2 = this.providers.map((providerName) => this.cloudCarbonService
                .fetchProviderDetails(providerName));
            let singleObservable2 = merge(observableList2).pipe(concatAll(), toArray());
            singleObservable2.subscribe((data) => {
                for (let i = 0; i < data.length; i++) {
                    let correspondingProviderName = this.providers[i];
                    providersCarbonData[correspondingProviderName] = data[i]
                }
                subject.next(1);
            });
        });
    }

    reloadData(json, cloudCarbonData, cloudPricingData): void {
        this.progress = 0;
        this.isLoading = true;
        this.keepCloudPricingParameters();
        this.clearData();

        this.cloudPricingService.fetchExchangeRates().subscribe((exchangeRates) => {
            // this.exchangeRates = json.priceExchangeRates;
            this.exchangeRates = exchangeRates;

            let selectedComputeSample = this.isRecoEnabled ? json.vmComputeStorageReco : json
                .vmComputeStorage;

            // First update date of "onpremise" provider. Unlike other providers, its data is based on date used for the
            // T6 graph in the dashboard page.
            let exchangeRate = json.priceExchangeRates.currency[this.currencyOption].rate;
            // The variable 'exchangeRate' is a ratio used to transform costs from dollars to euros. Regarding 'onpremise
            // costs', they are specified in euros in the json files, thus we need to do the conversion in the opposite
            // direction:
            //   - if selected currency is "EUR": as costs is already in euros, ratio is 1.0
            //   - if selected currency is "USD": as costs is already in euros, ratio is 1.0 / (ratio_USD_to_EUR)
            let onPremiseExchangeRate = this.currencyOption === "EUR" ? 1.0 : 1.0 / json.priceExchangeRates
                .currency["EUR"].rate;

            // On premise
            let onPremiseEmissions = 0;
            for (let server of json.greenitPower)
                onPremiseEmissions += Number(server.CO2) + Number(server.CO2G);

            let onPremiseCost = json.t6[0].allocated * onPremiseExchangeRate;

            if (this.isRecoEnabled) {
                // @ts-ignore
                const co2Reduction: number = Object.values(this.greenItReco).reduce((a: number, b: number) => a + b);
                onPremiseEmissions = onPremiseEmissions - co2Reduction;
                const costReduction: number = json.t6[0].wasted * onPremiseExchangeRate;
                onPremiseCost = onPremiseCost - costReduction;
            }

            let onPremiseX = onPremiseEmissions;
            let onPremiseY = onPremiseCost;

            onPremiseX = this.period === "year" ? onPremiseX : onPremiseX / 12;
            onPremiseY = this.period === "year" ? onPremiseY : onPremiseY / 12;

            this.onPremiseData = [{
                "x": onPremiseX,
                "y": onPremiseY,
                "region": "Your infrastructure",
                "providerTag": "",
                "regionTag": "",
            }];

            //@ts-ignore
            this.dashboardOptions.xAxis.plotBands[0].from = this.onPremiseData[0].x;
            //@ts-ignore
            this.dashboardOptions.yAxis.plotBands[0].from = this.onPremiseData[0].y;

            let providerAndRegions = [];

            for (let providerName of [...this.providers, ...this.noCarbonProviders]) {
                let providerData = cloudPricingData[providerName];
                if (providerData === undefined || providerData['regions'] === undefined) {
                    continue;
                }

                let isProviderWithoutCarbon = this.noCarbonProviders.indexOf(providerName) !== -1;

                // The following block ensure that cloudPricingData have been correctly loaded. They may be empty in case of a
                // race conditions occuring when the jsons files are loaded.
                if (cloudPricingData[providerName] === undefined || cloudPricingData[providerName][
                        'regions'
                    ] === undefined) {
                    console.error(
                        `There was no regions associated with ${providerName} : it may be caused by a race condition, I am skipping this call to reloadData!`
                    );
                    return;
                }

                if (!isProviderWithoutCarbon) {
                    // Creates a region list with the regions present in both cloud and carbon pricing
                    let cpRegions = Object.keys(cloudPricingData[providerName]['regions']);
                    let ccRegions = Object.keys(cloudCarbonData[providerName]);
                    for (let ccRegion of ccRegions) {
                        for (let cpRegion of cpRegions) {
                            if (ccRegion === cpRegion && this.providersRegions[providerName].indexOf(
                                    ccRegion) === -1) {
                                this.providersRegions[providerName].push(ccRegion);
                            }
                        }
                    }
                } else {
                    this.providersRegions[providerName] = Object.keys(cloudPricingData[providerName][
                        'regions'
                    ]);
                }

                let mapOS = cloudPricingData[providerName]["mapOs"] || {
                    "Microsoft_Windows": "win",
                    "Debian": "linux",
                    "Ubuntu": "linux",
                    "SUSE": "sles",
                    "Red_Hat": "rhel",
                    "CentOS": "linux"
                };
                this.infoUpdate = json.priceUpdateInfo;

                for (let region of this.providersRegions[providerName]) {
                    let keepThisRegions = true;
                    if (this.isOnlyEuropeansEnabled) {
                        keepThisRegions = false;
                        for (let pattern of this.europeansPatterns) {
                            if (pattern.test(region)) {
                                keepThisRegions = true;
                                continue;
                            }
                        }
                    }

                    if (keepThisRegions) {
                        providerAndRegions.push({
                            "providerName": providerName,
                            "hasCarbon": !isProviderWithoutCarbon,
                            "regions": providerData["regions"],
                            "region": providerData["regions"][region],
                            "regionName": region,
                            "mapOS": mapOS,
                        });
                    }

                }
            }

            let processOneProviderRegion = (remainingProviderAndRegions, updateTimer) => {

                if (remainingProviderAndRegions.length == 0) {
                    this.displayLoading = "complete";
                    this.isLoading = false;
                    clearInterval(updateTimer);
                    this.handleUpdate();
                    return;
                }

                const [providerData, ...restRemainingProviderAndRegions] = remainingProviderAndRegions;

                let providerName = providerData["providerName"];
                let region = providerData["regionName"];
                let isProviderWithCarbon = providerData["hasCarbon"];
                let mapOS = providerData["mapOS"];

                this.displayLoading = providerName + " - " + region;

                this.infoUpdate = json.priceUpdateInfo;


                let data;

                if (isProviderWithCarbon) {
                    data = this.cloudimpactService.computeDashboard(selectedComputeSample, json
                        .vmConsover, cloudCarbonData[providerName], cloudPricingData[providerName],
                        region, this.period, this.billingType, mapOS, this.exchangeRates, this
                        .currencyOption);
                } else {
                    data = this.cloudimpactService.computeDashboard(selectedComputeSample, json
                        .vmConsover, null, cloudPricingData[providerName], region, this.period, this
                        .billingType, mapOS, this.exchangeRates, this.currencyOption);
                }

                let results = {
                    "x": data.emissions,
                    "y": data.costs,

                    "region": cloudPricingData[providerName]['regions'][region],
                    "providerTag": providerName,
                    "regionTag": region,
                };

                this.progress++;
                this.pushData(providerName, results);

                if (this.progress % 10 === 0) {
                    setTimeout(() => {
                        processOneProviderRegion(restRemainingProviderAndRegions, updateTimer);
                    })
                } else {
                    processOneProviderRegion(restRemainingProviderAndRegions, updateTimer);
                }
            };

            let updateTimer = setInterval(() => {
                this.handleUpdate();
            }, 300);

            processOneProviderRegion(providerAndRegions, updateTimer);
        });
    }

    keepCloudImpactParameters() {
        // Ensure that parameters that may have been set previously are kept
        this.shareService.currentMessage.subscribe((msg) => {
            msg.cloudPricing.currency = this.currencyOption;
            this.currencySymbol = this.currencyOption == "USD" ? "$" : "€";
            msg.cloudPricing.period = this.period;
            msg.cloudPricing.billingType = this.billingType;
            msg.cloudPricing.applyRecommendations = this.isRecoEnabled;
            msg.cloudPricing.period = this.period;
            msg.cloudPricing.applyRecommendations = this.isRecoEnabled;
        });
    }

    pushData(providerName: string, data: Object) {
        switch (providerName) {
            case "aws":
                this.awsData.push(data);
                break;
            case "az":
                this.azData.push(data);
                break;
            case "gcp":
                this.gcpData.push(data);
                break;
            case "ovh":
                this.ovhData.push(data);
                break;
            case "myprovider":
                this.myproviderData.push(data);
                break;
        }
    }

    clearData() {
        this.awsData.splice(0);
        this.azData.splice(0);
        this.gcpData.splice(0);
        this.ovhData.splice(0);
        this.myproviderData.splice(0);
    }

    askToFetchNewData = () => {
        this.pricesFetchState = ClrLoadingState.LOADING;
        this.cloudPricingService.askToFetchNewPrices().subscribe((result) => {
            this.cloudCarbonService.askToFetchNewEmissionsData().subscribe((result) => {
                setTimeout(() => {
                    this.pricesFetchState = ClrLoadingState.DEFAULT;
                    this.reloadUi();
                }, 500);
            });
        });
    }


    // Highchart
    dashboardOptions: Highcharts.Options;
    pricechartOptions: Highcharts.Options;

    updateChartOptions() {
        let that = this;
        this.dashboardOptions = {
            chart: {
                type: 'scatter',
                zoomType: 'xy',
            },
            credits: {
                enabled: false,
            },
            title: {
                text: 'Cloud Pricing - Cloud Carbon visualization',
            },
            xAxis: {
                title: {
                    //enabled: true,
                    text: 'Yearly Carbon Emissions (kg CO2)',
                },
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                plotBands: [{
                    color: 'rgba(255, 0, 0, 0.1)',
                    from: 0,
                    to: 999999999,
                    label: {
                        text: 'More polluting than your own infrastructure',
                        align: 'left',
                    }
                }],
            },
            yAxis: {
                title: {
                    text: `Yearly total costs (${this.currencyOption})`,
                },
                plotBands: [{
                    color: 'rgba(255, 0, 0, 0.1)',
                    from: 0,
                    to: 999999999,
                    label: {
                        text: 'More expensive than your own infrastructure',
                    }
                }],
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                x: 100,
                y: 70,
                floating: true,
                backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                borderWidth: 1,
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                            },
                        },
                    },
                    tooltip: {
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: `{point.region}<br/>-------------------------<br/>Cost: {point.y:.0f} ${this.currencySymbol}/${this.period}<br/>Emissions: {point.x:.2f} kgCO2/${this.period}`,
                    },
                },
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function() {
                                //@ts-ignore
                                if (this.providerTag) {
                                    //@ts-ignore
                                    location.href = `/cloud/${this.providerTag}/${this.regionTag}?currency=${that.currencyOption}&period=${that.period}&billingType=${that.billingType}&isRecoEnabled=${that.isRecoEnabled}`;
                                }
                            }
                        }
                    }
                }
            },
            series: this.dashboardSeries,
        };

        // Highchart
        this.pricechartOptions = {
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false,
            },
            title: {
                text: 'Cloud pricing'
            },
            xAxis: {
                labels: {
                    enabled: false,
                },
            },
            yAxis: {
                title: {
                    text: `Yearly total costs (${this.currencyOption})`,
                },
            },
            legend: {
                enabled: false
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<b>{point.provider}</b><br>{point.region}<br/>-------------------------<br/>Cost: ${point.y:.0f}' +
                    ` ${this.currencySymbol}/${this.period}<br/>`,
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function() {
                                //@ts-ignore
                                location.href = `/cloud/${this.providerTag}/${this.regionTag}?currency=${that.currencyOption}&period=${that.period}&billingType=${that.billingType}&isRecoEnabled=${that.isRecoEnabled}`;
                            }
                        }
                    }
                }
            },
            series: this.pricechartSeries,
        }
    }

    handleUpdate = () => {
        let onPremiseDataCopy = JSON.parse(JSON.stringify(this.onPremiseData));
        let awsDataCopy = JSON.parse(JSON.stringify(this.awsData));
        let azDataCopy = JSON.parse(JSON.stringify(this.azData));
        let gcpDataCopy = JSON.parse(JSON.stringify(this.gcpData));
        let ovhDataCopy = JSON.parse(JSON.stringify(this.ovhData));
        let myproviderDataCopy = JSON.parse(JSON.stringify(this.myproviderData));

        let dashboardSerie = [{
            name: 'On premise',
            color: 'rgba(52, 216, 47, 1)',
            marker: {
                radius: 18
            },
            dataLabels: {
                enabled: true,
                format: 'On premise',
                style: {
                    color: 'crimson'
                }
            },
            // @ts-ignore
            data: onPremiseDataCopy,
        }, {
            name: 'AWS',
            color: 'rgba(234, 232, 34, 0.8)',
            marker: {
                radius: 10
            },
            dataLabels: {
                enabled: true,
                format: '{series.name} - {point.region}',
            },
            // @ts-ignore
            data: awsDataCopy,
        }, {
            name: 'Azure',
            color: 'rgba(66, 80, 220, 0.8)',
            marker: {
                radius: 10
            },
            dataLabels: {
                enabled: true,
                format: '{series.name} - {point.region}',
            },
            // @ts-ignore
            data: azDataCopy,
        }, {
            name: 'Google Cloud',
            color: 'rgba(216, 47, 47, 0.8)',
            marker: {
                radius: 10
            },
            dataLabels: {
                enabled: true,
                format: '{series.name} - {point.region}',
            },
            // @ts-ignore
            data: gcpDataCopy,
        }, {
            name: 'My provider',
            color: '#D98026FF',
            marker: {
                radius: 10
            },
            dataLabels: {
                enabled: true,
                format: '{series.name} - {point.region}',
            },
            // @ts-ignore
            data: myproviderDataCopy,
        }, ];

        let onPremiseData = onPremiseDataCopy.map((elt) => Object({
            "color": "rgba(52, 216, 47, 1)",
            "y": elt.y,
            "provider": "On premise",
            "region": elt.region,
            "providerTag": elt.providerTag,
            "regionTag": elt.regionTag
        }));
        let awsData = awsDataCopy.map((elt) => Object({
            "color": "rgba(234, 232, 34, 0.8)",
            "y": elt.y,
            "provider": "AWS",
            "region": elt.region,
            "providerTag": elt.providerTag,
            "regionTag": elt.regionTag
        }));
        let azData = azDataCopy.map((elt) => Object({
            "color": "rgba(66, 80, 220, 0.8)",
            "y": elt.y,
            "provider": "Azure",
            "region": elt.region,
            "providerTag": elt.providerTag,
            "regionTag": elt.regionTag
        }));
        let gcpData = gcpDataCopy.map((elt) => Object({
            "color": "rgba(216, 47, 47, 0.8)",
            "y": elt.y,
            "provider": "GCP",
            "region": elt.region,
            "providerTag": elt.providerTag,
            "regionTag": elt.regionTag
        }));
        let ovhData = ovhDataCopy.map((elt) => Object({
            "color": "rgba(60, 50, 50, 0.8)",
            "y": elt.y,
            "provider": "OVH",
            "region": elt.region,
            "providerTag": elt.providerTag,
            "regionTag": elt.regionTag
        }));
        let myproviderData = myproviderDataCopy.map((elt) => Object({
            "color": "#D98026FF",
            "y": elt.y,
            "provider": "My Provider",
            "region": elt.region,
            "providerTag": elt.providerTag,
            "regionTag": elt.regionTag
        }));

        let allData = onPremiseData
            .concat(awsData)
            .concat(azData)
            .concat(gcpData)
            .concat(ovhData)
            .concat(myproviderData)
            .sort((a, b) => (a.y > b.y) ? 1 : -1);

        let pricechartSerie = [{
            name: 'On premise',
            color: 'rgba(52, 216, 47, 1)',
            marker: {
                radius: 18
            },
            dataLabels: {
                enabled: true,
                format: '{point.provider} - {point.region}',
                style: {
                    color: '{point.color}'
                }
            },
            // @ts-ignore
            data: allData,
        }, ];

        this.dashboardSeries.splice(0);
        this.dashboardSeries.push(...dashboardSerie);

        this.pricechartSeries.splice(0);
        this.pricechartSeries.push(...pricechartSerie);

        this.updateFlag = true;
    };
}

