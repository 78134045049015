<div>
    <span>
        <span class="cloud-header">Cloud impact</span>
        <span>
            <button class="btn btn-link btn-icon cloud-header-information-icon" (click)="informationModalOpened = true">
                <cds-icon shape="info-circle" size="24"></cds-icon>
            </button>
        </span>

        <span style="float: right;">
            <span href="..." class="label label-blue clickable">
                prices version
                <span class="badge">{{ dateLastUpdatePrices }}</span>
            </span>
            <span href="..." class="label label-blue clickable">
                emissions version
                <span class="badge">{{ dateLastUpdateEmissions }}</span>
            </span>
            <button [clrLoading]="fetchState" type="button" class="btn btn-icon btn-primary" aria-label="reload"
                (click)="askToFetchNewData()">
                <cds-icon shape="sync"></cds-icon> Reload
            </button>
        </span>
    </span>
</div>

<span>
    <fa-icon [icon]="awsIcon" *ngIf="providerName=='aws'"></fa-icon>
    <fa-icon [icon]="azureIcon" *ngIf="providerName=='az'"></fa-icon>
    <fa-icon [icon]="gcpIcon" *ngIf="providerName=='gcp'"></fa-icon>
    <span class="oui-icon oui-icon-ovh" *ngIf="providerName=='ovh'"></span>
    <fa-icon [icon]="myproviderIcon" *ngIf="providerName=='myprovider'"></fa-icon>

    <span class="cloud-provider-name" style="margin-left: 10px; margin-right: 15px;">Comparing {{ providerName |
        uppercase
        }} - {{ regionName | uppercase }} to On Premise</span>
    <a class="btn btn-sm" (click)="displayChangeRegionModal()">
        <cds-icon shape="map-marker"></cds-icon>
        Change region
    </a>
    <a class="btn btn-sm" clrVerticalNavLink routerLink="/my_cloud_pricing/edit" routerLinkActive="active"
        style="margin-left: 5px;" *ngIf="providerName == 'myprovider'">
        <cds-icon shape="note"></cds-icon>
        Edit
    </a>
    <span class="spinner spinner-sm" *ngIf="isLoading && myProviderShouldBeConfigured() === false">Loading...</span>
</span>

<div class="alert alert-warning" role="alert" *ngIf="myProviderShouldBeConfigured()">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
            </div>
            <span class="alert-text">It seems that your "my-cloud-pricing" configuration is incomplete:
                <ul *ngIf="numberOfVmTypes === 0 || numberOfStorageTypes === 0">
                    <li *ngIf="numberOfVmTypes === 0">{{numberOfVmTypes}} types of VM have been detected.</li>
                    <li *ngIf="numberOfStorageTypes === 0">{{numberOfStorageTypes}} types of storage devices have been
                        detected.</li>
                </ul>
            </span>
            <div class="alert-actions">
                <a class="btn alert-action btn-sm" style="text-decoration: none;" clrVerticalNavLink
                    routerLink="/my_cloud_pricing/edit" routerLinkActive="active">Edit "my-cloud-pricing"
                    configuration</a>
            </div>
        </div>
    </div>
    <button type="button" class="close" aria-label="Close">
        <cds-icon aria-hidden="true" shape="close"></cds-icon>
    </button>
</div>

<div class="clr-row">
    <div class="clr-col-4">
        <div class="clr-row">
            <div class="clr-col-3">
                <div class="clr-form-control">
                    <label for="currencySelector" class="clr-control-label">Currency</label>
                    <div class="clr-select-wrapper">
                        <select class="full-width-input clr-select" id="currencySelector" name="options"
                            [(ngModel)]="currencyOption" (change)="keepCloudImpactParameters();reloadUi()">
                            <option value="USD">USD</option>
                            <option value="EUR">Euros</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="clr-col-3">
                <div class="clr-form-control">
                    <label for="periodSelector" class="clr-control-label">Period</label>
                    <div class="clr-select-wrapper">
                        <select class="full-width-input clr-select" id="periodSelector" name="options"
                            [(ngModel)]="period" (change)="keepCloudImpactParameters();reloadUi()">
                            <option value="year">Year</option>
                            <option value="month">Month</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="clr-col-6">
                <div class="clr-form-control">
                    <label for="billingTypeSelector" class="clr-control-label">Billing Type</label>
                    <div class="clr-select-wrapper">
                        <select class="full-width-input clr-select" id="billingTypeSelector" [(ngModel)]="billingType"
                            (change)="keepCloudImpactParameters();reloadUi()">
                            <option value="od">On Demand</option>
                            <option value="1yr">Reservation (1 year)</option>
                            <option value="3yr">Reservation (3 years)</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col">
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Compute Costs
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    {{ providerCosts.compute | number:'1.0-0' }} {{ currencySymbol }} / {{ period }}
                                </div>
                                <div class="clr-col" *ngIf="economy.computeCost > 0">
                                    <div class="label label-success" style="float: right; height: 20px;">
                                        <span>- {{ economy.computeCost | number:'1.0-0' }} {{ currencySymbol }} / {{
                                            period
                                            }}</span>
                                        <span class="badge badge-success">{{ 100 * economy.computeCost /
                                            onPremiseCost.compute |
                                            number:'1.0-0'}}% economy</span>
                                    </div>
                                </div>
                                <div class="clr-col" *ngIf="economy.computeCost < 0">
                                    <div class="label label-danger" style="float: right; height: 20px;">
                                        <span>+ {{ -1 * economy.computeCost | number:'1.0-0' }} {{ currencySymbol }} /
                                            {{ period
                                            }}</span>
                                        <span class="badge badge-danger">{{ -100 * economy.computeCost /
                                            onPremiseCost.compute |
                                            number:'1.0-0'}}% rise</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Storage Costs
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    {{ providerCosts.storage | number:'1.0-0' }} {{ currencySymbol }} / {{ period }}
                                </div>
                                <div class="clr-col" *ngIf="economy.storageCost > 0">
                                    <div class="label label-success" style="float: right; height: 20px;">
                                        <span>- {{ economy.storageCost | number:'1.0-0' }} {{ currencySymbol }} / {{
                                            period
                                            }}</span>
                                        <span class="badge badge-success">{{ 100 * economy.storageCost /
                                            onPremiseCost.storage |
                                            number:'1.0-0'}}% economy</span>
                                    </div>
                                </div>
                                <div class="clr-col" *ngIf="economy.storageCost < 0">
                                    <div class="label label-danger" style="float: right; height: 20px;">
                                        <span>+ {{ -1 * economy.storageCost | number:'1.0-0' }} {{ currencySymbol }} /
                                            {{ period
                                            }}</span>
                                        <span class="badge badge-danger">{{ -100 * economy.storageCost /
                                            onPremiseCost.storage |
                                            number:'1.0-0'}}% rise</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Total Costs
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    {{ providerCosts.total | number:'1.0-0' }} {{ currencySymbol }} / {{ period }}
                                </div>
                                <div class="clr-col" *ngIf="economy.totalCost > 0">
                                    <div class="label label-success" style="float: right; height: 20px;">
                                        <span>- {{ economy.totalCost | number:'1.0-0' }} {{ currencySymbol }} / {{
                                            period
                                            }}</span>
                                        <span class="badge badge-success">{{ 100 * economy.totalCost /
                                            onPremiseCost.total |
                                            number:'1.0-0'}}% economy</span>
                                    </div>
                                </div>
                                <div class="clr-col" *ngIf="economy.totalCost < 0">
                                    <div class="label label-danger" style="float: right; height: 20px;">
                                        <span>+ {{ -1 * economy.totalCost | number:'1.0-0' }} {{ currencySymbol }} / {{
                                            period
                                            }}</span>
                                        <span class="badge badge-danger">{{ -100 * economy.totalCost /
                                            onPremiseCost.total |
                                            number:'1.0-0'}}% rise</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <button class="btn btn-primary csv-button" (click)="exportPricingCSV()">Export pricing data
                        (CSV)</button>
                </div>
            </div>
        </div>
    </div>


    <div class="clr-col-4">
        <div class="clr-row">
            <div class="clr-form-control">
                <label for="billingTypeSelector" class="clr-control-label">Apply recommendations</label>
                <input class="full-width-input" type="checkbox" clrCheckbox [(ngModel)]="isRecoEnabled"
                    (change)="keepCloudImpactParameters();reloadUi()" />
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col" *ngIf="!providersWithoutCarbon.includes(providerName)">
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Direct Emissions
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    {{ providerEmissions.computeEmissions | number:'1.0-0' }} kg CO2 / {{ period }}
                                </div>
                                <div class="clr-col" *ngIf="economy.computeEmissions > 0">
                                    <div class="label label-success" style="float: right; height: 20px;">
                                        <span>- {{ economy.computeEmissions | number:'1.0-0' }} kg C02 / {{ period
                                            }}</span>
                                        <span class="badge badge-success">{{ 100 * economy.computeEmissions /
                                            onPremiseEmissions.computeEmissions |
                                            number:'1.0-0'}}% economy</span>
                                    </div>
                                </div>
                                <div class="clr-col" *ngIf="economy.computeEmissions < 0">
                                    <div class="label label-danger" style="float: right; height: 20px;">
                                        <span>+ {{ -1 * economy.computeEmissions | number:'1.0-0' }} kg C02 / {{ period
                                            }}</span>
                                        <span class="badge badge-danger">{{ -100 * economy.computeEmissions /
                                            onPremiseEmissions.computeEmissions |
                                            number:'1.0-0'}}% rise</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Embodied Emissions
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    {{ providerEmissions.greyEmissions | number:'1.0-0' }} kg CO2 / {{ period }}
                                </div>
                                <div class="clr-col" *ngIf="economy.greyEmissions > 0">
                                    <div class="label label-success" style="float: right; height: 20px;">
                                        <span>- {{ economy.greyEmissions | number:'1.0-0' }} kg C02 / {{ period
                                            }}</span>
                                        <span class="badge badge-success">{{ 100 * economy.greyEmissions /
                                            onPremiseEmissions.greyEmissions |
                                            number:'1.0-0'}}% economy</span>
                                    </div>
                                </div>
                                <div class="clr-col" *ngIf="economy.greyEmissions < 0">
                                    <div class="label label-danger" style="float: right; height: 20px;">
                                        <span>+ {{ -1 * economy.greyEmissions | number:'1.0-0' }} kg C02 / {{ period
                                            }}</span>
                                        <span class="badge badge-danger">{{ -100 * economy.greyEmissions /
                                            onPremiseEmissions.greyEmissions |
                                            number:'1.0-0'}}% rise</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Total Emissions
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    {{ providerEmissions.totalEmissions | number:'1.0-0' }} kg CO2 / {{ period }}
                                </div>
                                <div class="clr-col" *ngIf="economy.totalEmissions > 0">
                                    <div class="label label-success" style="float: right; height: 20px;">
                                        <span>- {{ economy.totalEmissions | number:'1.0-0' }} kg C02 / {{ period
                                            }}</span>
                                        <span class="badge badge-success">{{ 100 * economy.totalEmissions /
                                            onPremiseEmissions.totalEmissions |
                                            number:'1.0-0'}}% economy</span>
                                    </div>
                                </div>
                                <div class="clr-col" *ngIf="economy.totalEmissions < 0">
                                    <div class="label label-danger" style="float: right; height: 20px;">
                                        <span>+ {{ -1 * economy.totalEmissions | number:'1.0-0' }} kg C02 / {{ period
                                            }}</span>
                                        <span class="badge badge-danger">{{ -100 * economy.totalEmissions /
                                            onPremiseEmissions.totalEmissions |
                                            number:'1.0-0'}}% rise</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <button class="btn btn-primary csv-button" (click)="exportCarbonCSV()">Export carbon data
                        (CSV)</button>
                </div>
            </div>
            <div class="clr-col" *ngIf="providersWithoutCarbon.includes(providerName)">
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Direct Emissions
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                    This feature is not avaliable for provider {{providerName | uppercase}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Embodied Emissions
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                    This feature is not avaliable for provider {{providerName | uppercase}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <div class="card">
                        <div class="card-header">
                            Total Emissions
                            <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col">
                                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                    This feature is not avaliable for provider {{providerName | uppercase}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-row-4">
                    <button class="btn btn-warning csv-button" style="float: right;">Cannot export carbon data</button>
                </div>
            </div>
        </div>
    </div>


    <div class="clr-col-4 graph">
        <div class="clr-row-6">
            <div class="card">
                <div class="card-header card-ajust">
                    <h5>
                        VMs visualization
                        <clr-radio-container clrInline class="cloud-graph-selector">
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value="OS"
                                    [(ngModel)]="chartOption" (change)="keepCloudImpactParameters();reloadUi()" />
                                <label>OS</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value="Cost"
                                    [(ngModel)]="chartOption" (change)="keepCloudImpactParameters();reloadUi()" />
                                <label>Cost</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper *ngIf="!providersWithoutCarbon.includes(providerName)">
                                <input type="radio" clrRadio name="options" required value="Emissions"
                                    [(ngModel)]="chartOption" (change)="keepCloudImpactParameters();reloadUi()" />
                                <label>Emissions</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>
                        <span class="spinner spinner-inline" *ngIf="isLoading">Loading...</span>
                    </h5>
                </div>
                <div class="card-block" id="dashboard-computeTypeChart-core">
                    <div class="card-text">
                        <highcharts-chart [Highcharts]="Highcharts" [callbackFunction]="chartCallbackComputeChart"
                            [options]="computeChartOptions" [(update)]="updateComputeChartFlag" [oneToOne]="true"
                            style="width: 100%; height: 381px; display: block;" *ngIf="computeChartOptions !== undefined"></highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="clr-row" style="margin-top: 15px;">
    <div class="clr-col-10" style="margin-bottom: 5px;">
        <h4 (click)="handleClickToActivateDebugOfCosts()">Details for each VM:</h4>
    </div>
</div>



<clr-tabs>
    <clr-tab>
        <button clrTabLink id="link1">Infrastructure Costs</button>
        <clr-tab-content id="content1" *clrIfActive>
            <div class="datagrid-limit-height" style="margin-top: 0px;"
                *ngIf="myProviderShouldBeConfigured() === false">
                <clr-datagrid [clrDgLoading]="isLoading" class="vmDataGrid">
                    <clr-dg-column [clrDgField]="'name'">VM Name</clr-dg-column>
                    <clr-dg-column [clrDgField]="'compute'">
                        <ng-container>
                            Compute
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="computeFilter">
                            <turned-off-no-match-filter [fieldName]="'compute'" [items]="providerCosts.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" [label]="'Displayable VM types'" #computeFilter>
                            </turned-off-no-match-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'storage'">
                        <ng-container>
                            Storage
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="storageFilter">
                            <turned-off-no-match-filter [fieldName]="'storage'" [items]="providerCosts.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" [label]="'Displayable storage types'"
                                #storageFilter>
                            </turned-off-no-match-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'operatingSystem'">
                        <ng-container>
                            OS
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="operatingSystemFilter">
                            <turned-off-no-match-filter [fieldName]="'operatingSystem'"
                                [items]="providerCosts.listedVMs" [tableUpdate]="shouldUpdateFilters"
                                [label]="'Displayable operating systems'" #operatingSystemFilter>
                            </turned-off-no-match-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'computeCost'">
                        <ng-container *clrDgHideableColumn="{hidden: false}">
                            Compute Cost
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="computeCostFilter">
                            <column-range-filter [fieldName]="'computeCost'" [items]="providerCosts.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" #computeCostFilter>
                            </column-range-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'storageCost'">
                        <ng-container *clrDgHideableColumn="{hidden: false}">
                            Storage Cost
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="storageCostFilter">
                            <column-range-filter [fieldName]="'storageCost'" [items]="providerCosts.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" #storageCostFilter>
                            </column-range-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgSortBy]="totalCostsComparator">
                        <ng-container *clrDgHideableColumn="{hidden: false}">
                            Total
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="totalCostFilter">
                            <column-range-filter [fieldName]="'totalCost'" [items]="providerCosts.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" #totalCostFilter>
                            </column-range-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgSortBy]="'savings.compute'">Compute Savings
                    </clr-dg-column>
                    <clr-dg-column [clrDgSortBy]="'savings.storage'">Storage Savings
                    </clr-dg-column>
                    <clr-dg-column [clrDgSortBy]="totalCostsSavingComparator">Total Savings
                    </clr-dg-column>

                    <clr-dg-row *clrDgItems="let vm of providerCosts.listedVMs" [clrDgItem]="vm">
                        <clr-dg-cell>{{ vm.name }}</clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute !== null">{{ vm.compute }}</clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute === null && vm.state == 0"><span class="label label-info">turned
                                off</span>
                        </clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute === null && vm.state != 0"><span class="label label-danger">no
                                match</span>
                        </clr-dg-cell>
                        <clr-dg-cell>{{ vm.storage }}</clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute !== null">{{ vm.operatingSystem }}</clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute === null && vm.state == 0"><span class="label label-info">turned
                                off</span>
                        </clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute === null && vm.state != 0"><span class="label label-danger">no
                                match</span>
                        </clr-dg-cell>
                        <clr-dg-cell>{{ vm.computeCost | number:'1.0-2'}} {{ currencySymbol }} / {{ period }}
                        </clr-dg-cell>
                        <clr-dg-cell>{{ vm.storageCost | number:'1.0-2'}} {{ currencySymbol }} / {{ period }}
                        </clr-dg-cell>
                        <clr-dg-cell>{{ vm.computeCost + vm.storageCost | number:'1.0-2'}} {{ currencySymbol }} / {{
                            period }}
                        </clr-dg-cell>
                        <clr-dg-cell>{{ vm.savings.compute | number:'1.0-2'}} {{ currencySymbol }}
                            / {{ period
                            }}</clr-dg-cell>
                        <clr-dg-cell>{{ vm.savings.storage | number:'1.0-2'}} {{ currencySymbol }}
                            / {{ period
                            }}</clr-dg-cell>
                        <clr-dg-cell>{{ vm.savings.total | number:'1.0-2'}} {{ currencySymbol }} /
                            {{ period
                            }}</clr-dg-cell>
                    </clr-dg-row>

                    <ng-container ngProjectAs="clr-dg-detail" *ngIf="debug">
                        <clr-dg-detail *clrIfDetail="let detail">
                            <clr-dg-detail-header>{{detail.name}}</clr-dg-detail-header>
                            <clr-dg-detail-body>
                                <b *ngIf="detail.storageDebug" [innerHTML]="detail.storageDebug.title"></b>
                                <ul *ngIf="detail.storageDebug">
                                    <li *ngFor="let explanation of detail.storageDebug.explanations"
                                        [innerHTML]="explanation"></li>
                                </ul>
                                <b *ngIf="detail.computeDebug" [innerHTML]="detail.computeDebug.title"></b>
                                <ul *ngIf="detail.computeDebug">
                                    <li *ngFor="let explanation of detail.computeDebug.explanations"
                                        [innerHTML]="explanation"></li>
                                </ul>
                                <b *ngIf="detail.computeDebug && detail.storageDebug">Total price: {{detail.computeDebug.monthlyPrice +
                                    detail.storageDebug.monthlyPrice | number:'1.0-2'}} $ per month (<i>{{12 *
                                        (detail.computeDebug.monthlyPrice + detail.storageDebug.monthlyPrice) |
                                        number:'1.0-2'}} $
                                        per year</i>)</b>
                                <b *ngIf="!detail.computeDebug && detail.storageDebug">Total price: {{detail.storageDebug.monthlyPrice |
                                    number:'1.0-2'}} $
                                    per month (<i>{{12 * (detail.storageDebug.monthlyPrice) | number:'1.0-2'}} $ per
                                        year</i>)</b>
                            </clr-dg-detail-body>
                        </clr-dg-detail>
                    </ng-container>

                    <clr-dg-footer>
                        <clr-dg-pagination #pagination [clrDgPageSize]="20">
                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">VMs per page</clr-dg-page-size>
                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
                            VMs
                        </clr-dg-pagination>
                    </clr-dg-footer>
                </clr-datagrid>
            </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>Infrastructure Emissions</button>
        <clr-tab-content *clrIfActive="true">
            <div class="datagrid-limit-height" style="margin-top: 0px;"
                *ngIf="myProviderShouldBeConfigured() === false">
                <clr-datagrid [clrDgLoading]="isLoading" class="vmDataGrid">
                    <clr-dg-placeholder>
                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                        This feature is not avaliable for provider {{providerName | uppercase}}
                    </clr-dg-placeholder>
                    <clr-dg-column [clrDgField]="'name'">VM Name</clr-dg-column>
                    <clr-dg-column [clrDgField]="'compute'">
                        <ng-container>
                            Compute
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="computeFilter">
                            <turned-off-no-match-filter [fieldName]="'compute'" [items]="providerEmissions.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" [label]="'Displayable VM types'" #computeFilter>
                            </turned-off-no-match-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'operatingSystem'">
                        <ng-container>
                            OS
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="operatingSystemFilter">
                            <turned-off-no-match-filter [fieldName]="'operatingSystem'"
                                [items]="providerEmissions.listedVMs" [tableUpdate]="shouldUpdateFilters"
                                [label]="'Displayable operating systems'" #operatingSystemFilter>
                            </turned-off-no-match-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'computeEmissions'">
                        <ng-container *clrDgHideableColumn="{hidden: false}">
                            Direct Emissions
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="computeEmissionsFilter">
                            <column-range-filter [fieldName]="'computeEmissions'" [items]="providerEmissions.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" #computeEmissionsFilter>
                            </column-range-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'greyEmissions'">
                        <ng-container *clrDgHideableColumn="{hidden: false}">
                            Embodied Emissions
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="greyEmissionsFilter">
                            <column-range-filter [fieldName]="'greyEmissions'" [items]="providerEmissions.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" #greyEmissionsFilter>
                            </column-range-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgSortBy]="totalEmissionsComparator" [clrDgField]="'totalEmissions'">
                        <ng-container>
                            Total Emissions
                        </ng-container>
                        <clr-dg-filter [clrDgFilter]="totalEmissionsFilter">
                            <column-range-filter [fieldName]="'totalEmissions'" [items]="providerEmissions.listedVMs"
                                [tableUpdate]="shouldUpdateFilters" #totalEmissionsFilter>
                            </column-range-filter>
                        </clr-dg-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgSortBy]="totalEmissionsSavingComparator">Emissions
                        Savings
                    </clr-dg-column>

                    <clr-dg-row *clrDgItems="let vm of providerEmissions.listedVMs" [clrDgItem]="vm">
                        <clr-dg-cell>{{ vm.name }}</clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute !== undefined">{{ vm.compute }}</clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute === undefined"><span class="label label-info">turned
                                off</span>
                        </clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute !== undefined">{{ vm.operatingSystem }}</clr-dg-cell>
                        <clr-dg-cell *ngIf="vm.compute === undefined"><span class="label label-info">turned
                                off</span>
                        </clr-dg-cell>
                        <clr-dg-cell>{{ vm.computeEmissions | number:'1.0-2'}} kg CO2 / {{ period }}</clr-dg-cell>
                        <clr-dg-cell>{{ vm.greyEmissions | number:'1.0-2'}} kg CO2 / {{ period }}</clr-dg-cell>
                        <clr-dg-cell>{{ vm.totalEmissions | number:'1.0-2'}} kg CO2 / {{ period }}</clr-dg-cell>
                        <clr-dg-cell>{{ vm.savings | number:'1.0-2'}} kg CO2 / {{ period }}
                        </clr-dg-cell>
                    </clr-dg-row>

                    <ng-container ngProjectAs="clr-dg-detail" *ngIf="debug">
                        <clr-dg-detail *clrIfDetail="let detail">
                            <clr-dg-detail-header>{{detail.name}}</clr-dg-detail-header>
                            <clr-dg-detail-body>
                                <b *ngIf="detail.debug" [innerHTML]="detail.debug.title"></b>
                                <ul *ngIf="detail.debug">
                                    <li *ngFor="let explanation of detail.debug.explanations" [innerHTML]="explanation">
                                    </li>
                                </ul>
                            </clr-dg-detail-body>
                        </clr-dg-detail>
                    </ng-container>

                    <clr-dg-footer>
                        <clr-dg-pagination #pagination [clrDgPageSize]="20">
                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">VMs per page</clr-dg-page-size>
                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
                            VMs
                        </clr-dg-pagination>
                    </clr-dg-footer>
                </clr-datagrid>
            </div>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>


<div *ngIf="providerEmissions.faultyVMs.length > 0">
    <h3>Errors with {{ providerEmissions.faultyVMs.length }} VMs</h3>
    <div class="alert alert-warning alert-sm" role="alert" *ngFor="let vm of providerEmissions.faultyVMs">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                </div>
                <div class="alert-text">
                    Problem with {{ vm.name }}
                    <i>{{ vm.comment }}</i>
                </div>
            </div>
        </div>
        <button type="button" class="close" aria-label="Close">
            <cds-icon aria-hidden="true" shape="close"></cds-icon>
        </button>
    </div>
</div>

<div *ngIf="providerCosts.faultyVMs.length > 0">
    <h3>Errors with {{ providerCosts.faultyVMs.length }} VMs</h3>
    <div class="alert alert-warning alert-sm" role="alert" *ngFor="let vm of providerCosts.faultyVMs">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                </div>
                <div class="alert-text">
                    Problem with {{ vm.name }}
                    <i>{{ vm.comment }}</i>
                </div>
            </div>
        </div>
        <button type="button" class="close" aria-label="Close">
            <cds-icon aria-hidden="true" shape="close"></cds-icon>
        </button>
    </div>
</div>

<clr-modal [(clrModalOpen)]="informationModalOpened" [clrModalStaticBackdrop]="false">
    <h2 class="modal-title">Cloud Pricing Calculation</h2>
    <div class="modal-body">
        <h3 *ngIf="providerName == 'myprovider'">
            <cds-icon shape="warning-standard" size="18"></cds-icon>
            These prices do not take into account costs incurred of
            :
        </h3>
        <h3 *ngIf="providerName != 'myprovider'">
            <cds-icon shape="warning-standard" size="18"></cds-icon>
            These estimates should not be used as official quotes, since they do not take into account costs
            incurred of
            :
        </h3>
        <ul>
            <li>Any <b>Network</b> traffic</li>
            <li>Supplementary <b>disk I/O</b> transactions</li>
            <li><b>Backup</b> and <b>snapshots</b> storage</li>
            <li>Prediction on future compute and storage needs*</li>
            <li><b>Personnel</b> cost</li>
        </ul>

        <p class="p8">* It is very likely that the needs of storage capacity will increase with time.</p>

        <h3>
            <cds-icon shape="flask" size="18"></cds-icon>
            Methodology
        </h3>

        <p>For each existing VM in DC Scope, we match compute and storage resources as follows :</p>

        <ul>
            <li><b>Compute:</b> the cheapest offered compute/instance type that meets the VM requirements in terms
                of
                number
                of <b>VCPUS</b> and <b>allocated RAM</b></li>
            <li><b>Storage:</b> the storage costs are calculated on a per-GB basis** over the <b>storage
                    provisioned</b>.
                We
                match the cheapest offered storage type that meets the VM requirements in terms of <b>capacity, IOPS
                    and
                    throughput.</b></li>
        </ul>
        <p class="p8" *ngIf="providerName != 'myprovider'">** On Microsoft Azure, managed virtual disks are
            provisioned
            with fixed pre-defined sizes.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="informationModalOpened = false">
            Close
        </button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="changeRegionModalOpened" [clrModalStaticBackdrop]="false">
    <h2 class="modal-title">Select a new region</h2>
    <div class="modal-body">
        <form class="clr-form clr-form-compact">
            <div class="clr-form-control">
                <label for="select-provider" class="clr-control-label">Provider</label>
                <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select id="select-provider" name="availableSelectedProvider" class="clr-select" [(ngModel)]="availableSelectedProvider" (change)="selectedRegionHasChanged()">
                            <option value="{{provider.key}}" *ngFor="let provider of availableProviders | keyvalue">{{provider.value}}</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                </div>
            </div>
            <div class="clr-form-control">
                <label for="select-region" class="clr-control-label">Region</label>
                <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select id="select-region" name="availableSelectedRegion" class="clr-select" [(ngModel)]="availableSelectedRegion" [disabled]="regionSelectionIsDisabled">
                            <option value="{{region.key}}" *ngFor="let region of availableRegions | keyvalue">{{region.value}}</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <a class="btn btn-primary" routerLink="/cloud/{{availableSelectedProvider}}/{{availableSelectedRegion}}" (click)="changeRegionModalOpened = false" routerLinkActive="active" *ngIf="!regionSelectionIsDisabled">
            Change region
        </a>
        <a class="btn btn-primary" disabled *ngIf="regionSelectionIsDisabled">
            Change region
        </a>
        <button type="button" class="btn" (click)="changeRegionModalOpened = false">
            Cancel
        </button>
    </div>
</clr-modal>
