import {
    Directive,
    ViewContainerRef
} from '@angular/core';


@Directive({
    selector: '[cloud-trouble-detail]'
})
export class CloudtroubleDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
