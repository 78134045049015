<div class="clr-row">
    <div class="clr-col-12">
        <div style="float: right">
            <div class="btn-group btn-sm">
                <div class="radio btn" (click)="selectMetric('cpu')">
                    <input type="radio" name="btn-group-demo-radios" id="btn-demo-radio-1"
                           [checked]="selectedMetric=='cpu'">
                    <label for="btn-demo-radio-1">Cpu</label>
                </div>
                <div class="radio btn" (click)="selectMetric('ram')">
                    <input type="radio" name="btn-group-demo-radios" id="btn-demo-radio-2"
                           [checked]="selectedMetric=='ram'">
                    <label for="btn-demo-radio-2">Ram</label>
                </div>
                <div class="radio btn" (click)="selectMetric('power')">
                    <input type="radio" name="btn-group-demo-radios" id="btn-demo-radio-4"
                           [checked]="selectedMetric=='power'">
                    <label for="btn-demo-radio-4">Power</label>
                </div>
            </div>
            <div class="btn-group btn-sm">
                <div class="radio btn" (click)="selectMetric('storage')">
                    <input type="radio" name="btn-group-demo-radios" id="btn-demo-radio-3"
                           [checked]="selectedMetric=='storage'">
                    <label for="btn-demo-radio-3">Storage</label>
                </div>
            </div>
        </div>

        <div clr-col-9>
            Current selection:
            <span *ngFor="let pathPart of displayedPath; let first = first; let last=last;">
                <span *ngIf="!first">/ </span>
                <a *ngIf="!last" (click)="changeSelectionFromTopMenu(pathPart)">
                    <span class="badge badge-1 navigation_link">{{ pathPart }}</span>
                </a>
                <a *ngIf="last" (click)="changeSelectionFromTopMenu(pathPart)">
                    <span class="badge badge-blue navigation_link">{{ pathPart }}</span></a>
            </span>
        </div>
    </div>
</div>

<div id="divSvg" class="card" style="margin-top: 10px; width: 100%; height: 70%; min-height: 400px;"></div>


<span>
    <h5 style="display: inline; margin-right: 10px;">VMs matching the selection</h5>
    <span *ngFor="let pathPart of displayedPath; let first = first; let last=last;">
        <span *ngIf="!first">/ </span>
        <a *ngIf="!last" (click)="changeSelectionFromTopMenu(pathPart)">
            <span class="badge badge-1 navigation_link">{{ pathPart }}</span>
        </a>
        <a *ngIf="last" (click)="changeSelectionFromTopMenu(pathPart)">
            <span class="badge badge-blue navigation_link">{{ pathPart }}</span></a>
    </span>
</span>

<div class="datagrid-limit-height">
    <clr-datagrid [clrDgLoading]="loading" class="datastoreDataGrid">
        <clr-dg-column [clrDgField]="'name'">VM</clr-dg-column>
        <clr-dg-column [clrDgField]="'server'" *ngIf="selectedMetric != 'storage'">server</clr-dg-column>
        <clr-dg-column [clrDgField]="'cluster'" *ngIf="selectedMetric != 'storage'">cluster</clr-dg-column>
        <clr-dg-column [clrDgField]="'datacenter'" *ngIf="selectedMetric != 'storage'">datacenter</clr-dg-column>
        <clr-dg-column [clrDgField]="'datastore'" *ngIf="selectedMetric == 'storage'">datastore</clr-dg-column>

        <clr-dg-column [clrDgField]="'cpu'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                CPU
            </ng-container>
            <clr-dg-filter [clrDgFilter]="cpuFilter">
                <column-range-filter [fieldName]="'cpu'" [items]="listedVms" [tableUpdate]="secondTableShouldChange"
                                     #cpuFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'ram'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                RAM
            </ng-container>
            <clr-dg-filter [clrDgFilter]="ramFilter">
                <column-range-filter [fieldName]="'ram'" [items]="listedVms" [tableUpdate]="secondTableShouldChange"
                                     #ramFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'storage'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Storage
            </ng-container>
            <clr-dg-filter [clrDgFilter]="storageFilter">
                <column-range-filter [fieldName]="'storage'" [items]="listedVms" [tableUpdate]="secondTableShouldChange"
                                     #storageFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'power'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Power
            </ng-container>
            <clr-dg-filter [clrDgFilter]="powerFilter">
                <column-range-filter [fieldName]="'power'" [items]="listedVms" [tableUpdate]="secondTableShouldChange"
                                     #powerFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'server'">Actions</clr-dg-column>

        <clr-dg-row *clrDgItems="let vm of listedVms" [clrDgItem]="vm">
            <clr-dg-cell>{{ vm.name }}</clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric != 'storage'">
                <a (click)="changeSelection(vm.server_path)">
                    <span class="badge badge-blue navigation_link">{{ vm.server }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric != 'storage'">
                <a (click)="changeSelection(vm.cluster_path)">
                    <span class="badge badge-blue navigation_link">{{ vm.cluster }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric != 'storage'">
                <a (click)="changeSelection(vm.datacenter_path)">
                    <span class="badge badge-blue navigation_link">{{ vm.datacenter }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric == 'storage'">
                <a (click)="changeSelection(vm.datastore_path)">
                    <span class="badge badge-blue navigation_link">{{ vm.datastore }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell>{{ vm.cpu }} %</clr-dg-cell>
            <clr-dg-cell>{{ vm.ram }} Go</clr-dg-cell>
            <clr-dg-cell>{{ vm.storage }} Go</clr-dg-cell>
            <clr-dg-cell>{{ vm.power }} Watt(s)</clr-dg-cell><clr-dg-cell><a class="btn btn-info-outline btn-sm" routerLink="/god/resource/{{vm.uuid}}" routerLinkActive="active">Graphs</a></clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">VMs per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} VMs
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>
