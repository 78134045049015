import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    ClrWizard
} from "@clr/angular";

import * as moment from 'moment';

import * as uuid from 'uuid';

import {
    AccountService,
    AuditService
} from '@app/services';

import {
    Alert,
    Notification,
    NotifInfo,
    NotifAlert,
    User
} from '@app/model';


@Component({
    selector: 'app-alertingnotification',
    templateUrl: './alertingnotification.component.html',
    styleUrls: ['./alertingnotification.component.css']
})
export class AlertingnotificationComponent implements OnInit {

    @ViewChild("wizardNotification") wizardnv: ClrWizard;

    selected_notification: Notification;

    notifications: Notification[] = [];

    isRdy: boolean = false;

    info_model: any;

    now: any;

    notif_state: string = 'ENABLED';

    enable_notif: boolean = true;

    isEdit: boolean = false;

    edit_model: any;

    isUpdate: boolean = false;

    wizard_model: any;

    alerts: Alert[] = [];

    update_state: string = 'error';

    update_result: string = 'changes have not been applied.';

    update_states: any = {
        snmp: false,
        email: false,
        cond: false,
        type: false
    };

    isModalEmail: boolean = false;

    isModalAlert: boolean = false;

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private audit_svc: AuditService) {}

    ngOnInit(): void {

        this.now = moment().format('MMMM Do YYYY');

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.wizard_model = {
            error: false,
            errorName: '',
            name: '',
            snmp: false,
            email: false,
            add_email: '',
            emails: [],
            alert_type_rt: true,
            alert_active: true,
            alerts: []
        };

        this.info_model = {
            email: 'enable',
            email_nb: 0,
            snmp: 'enable',
            alert_condition: true,
            alert_type: true,
            alert_nb: 0
        };

        this.edit_model = {
            snmp: false,
            email: false,
            add_email: '',
            emails: [],
            alert_type_rt: true,
            alert_active: true,
            alerts_type: [],
            alerts: []
        };
    }

    ngAfterViewInit(): void {

        this.getNotifications();
    }

    switchNotification(val: string): void {

        this.isEdit = false;
        if (this.notifications.length > 0 && val != "")
            this.loadNotification(this.getNotification(val));
    }

    editNotification(): void {

        this.getAlerts();

        this.audit_svc.getNotifEmail(this.selected_notification.uuid).pipe(first()).subscribe(
            data => {
                this.edit_model.emails = data;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );

        this.audit_svc.getNotifAlert(this.selected_notification.uuid).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        this.edit_model.alerts.push(this.getAlertFromName(data[i].alert_name));
                    }
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );

        if (this.selected_notification.snmp)
            this.edit_model.snmp = true;

        if (this.selected_notification.email)
            this.edit_model.email = true;

        if (this.selected_notification.alert_cond)
            this.edit_model.alert_condition = true;
        else
            this.edit_model.alert_condition = false;

        if (this.selected_notification.type == "rt")
            this.edit_model.alert_type_rt = true;
        else
            this.edit_model.alert_type_rt = false;

        this.isEdit = true;
    }

    getEditEmailList(): void {

        this.isUpdate = true;
    }

    getEditAlertList(): void {

        this.isUpdate = true;
    }

    removeNotification(isReloadList: boolean): void {

        this.audit_svc.delNotification(this.selected_notification.uuid).pipe(first()).subscribe(
            success => {
                if (isReloadList)
                    this.getNotifications();
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    updateNotification(): void {

        this.removeNotification(false);
        let record_notifs: Notification[] = [];
        let notif_type = 'ft';
        if (this.edit_model.alert_type_rt)
            notif_type = 'rt';

        if (this.edit_model.emails.length > 0) {
            for (var i = 0; i < this.edit_model.emails.length; i++) {
                if (this.edit_model.alerts.length > 0) {
                    for (var j = 0; j < this.edit_model.alerts.length; j++) {
                        let record_notif: Notification = {
                            uuid: this.selected_notification.uuid,
                            name: this.selected_notification.name,
                            user: this.currentUser.login,
                            email_add: this.edit_model.emails[i].email_add,
                            alert_name: this.edit_model.alerts[j].notified_name,
                            email: this.edit_model.email,
                            snmp: this.edit_model.snmp,
                            alert_cond: this.edit_model.alert_active,
                            state: this.selected_notification.state,
                            type: notif_type,
                            timeago: moment().unix() * 1000
                        };
                        record_notifs.push(record_notif);
                    }
                }
            }
        } else {
            if (this.edit_model.alerts.length > 0) {
                for (var j = 0; j < this.edit_model.alerts.length; j++) {
                    let record_notif: Notification = {
                        uuid: this.selected_notification.uuid,
                        name: this.selected_notification.name,
                        user: this.currentUser.login,
                        email_add: '',
                        alert_name: this.edit_model.alerts[j].notified_name,
                        email: this.edit_model.email,
                        snmp: this.edit_model.snmp,
                        alert_cond: this.edit_model.alert_active,
                        state: this.selected_notification.state,
                        type: notif_type,
                        timeago: moment().unix() * 1000
                    };
                    record_notifs.push(record_notif);
                }
            }
        }

        if (record_notifs.length > 0) {
            for (var i = 0; i < record_notifs.length; i++) {
                this.audit_svc.updateNotification(record_notifs[i]).pipe(first()).subscribe(
                    sucess => {
                        this.update_state = 'success';
                        this.update_result = 'changes have been applied.';

                        let current_notif: Notification = this.getNotifFromUuid(this.selected_notification
                            .uuid);
                        current_notif.snmp = this.edit_model.snmp;
                        current_notif.email = this.edit_model.email;
                        current_notif.alert_cond = this.edit_model.alert_active;
                        current_notif.type = notif_type;
                        this.loadNotification(current_notif);
                        this.audit_svc.discoverSnmp(this.currentUser.login).subscribe(
                            error => {
                                console.log(error);
                            }
                        );
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
            }
        }
        this.isUpdate = false;
    }

    enableUpdateSnmp(val: boolean): void {

        if (this.selected_notification.snmp != val) {
            this.isUpdate = true;
            this.update_states.snmp = true;
        } else {
            this.update_states.snmp = false;
            if (this.update_states.email == false && this.update_states.cond == false && this.update_states.type ==
                false)
                this.isUpdate = false;
        }
    }

    enableUpdateEmail(val: boolean): void {

        if (this.selected_notification.email != val) {
            this.isUpdate = true;
            this.update_states.email = true;
        } else {
            this.update_states.email = false;
            if (this.update_states.snmp == false && this.update_states.cond == false && this.update_states.type ==
                false)
                this.isUpdate = false;
        }
    }

    enableUpdateCondition(val: boolean): void {

        if (this.selected_notification.alert_cond != val) {
            this.isUpdate = true;
            this.update_states.cond = true;
        } else {
            this.update_states.cond = false;
            if (this.update_states.snmp == false && this.update_states.email == false && this.update_states.type ==
                false)
                this.isUpdate = false;
        }
    }

    enableUpdateType(val: boolean): void {

        let test: boolean = true;
        if (this.selected_notification.type == "ft")
            test = false;

        if (test != val) {
            this.isUpdate = true;
            this.update_states.type = true;
        } else {
            this.update_states.type = false;
            if (this.update_states.snmp == false && this.update_states.email == false && this.update_states.cond ==
                false)
                this.isUpdate = false;
        }
    }

    initWizard(): void {

        this.wizardnv.reset();
        this.wizard_model.error = false;
        this.wizard_model.errorName = '',
            this.wizard_model.name = '';
        this.wizard_model.snmp = false;
        this.wizard_model.email = false;
        this.wizard_model.add_email = '';
        this.wizard_model.emails = [];
        this.wizard_model.alert_type_rt = true;
        this.wizard_model.alert_active = true;
        this.getAlerts();
    }

    goBack(): void {}

    doReset(): void {

        this.initWizard();
        this.wizardnv.reset();
    }

    doFinish(): void {

        let record_notifs: Notification[] = [];

        const notif_uuid = uuid.v4();
        let notif_type = 'ft';
        if (this.wizard_model.alert_type_rt)
            notif_type = 'rt';

        if (this.wizard_model.emails.length > 0) {
            for (var i = 0; i < this.wizard_model.emails.length; i++) {
                if (this.wizard_model.alerts.length > 0) {
                    for (var j = 0; j < this.wizard_model.alerts.length; j++) {
                        let record_notif: Notification = {
                            uuid: notif_uuid,
                            name: this.wizard_model.name,
                            user: this.currentUser.login,
                            email_add: this.wizard_model.emails[i],
                            alert_name: this.wizard_model.alerts[j].notified_name,
                            email: this.wizard_model.email,
                            snmp: this.wizard_model.snmp,
                            alert_cond: this.wizard_model.alert_active,
                            state: 'enable',
                            type: notif_type,
                            timeago: moment().unix() * 1000
                        };
                        record_notifs.push(record_notif);
                    }
                }
            }
        } else {
            if (this.wizard_model.alerts.length > 0) {
                for (var j = 0; j < this.wizard_model.alerts.length; j++) {
                    let record_notif: Notification = {
                        uuid: notif_uuid,
                        name: this.wizard_model.name,
                        user: this.currentUser.login,
                        email_add: '',
                        alert_name: this.wizard_model.alerts[j].notified_name,
                        email: this.wizard_model.email,
                        snmp: this.wizard_model.snmp,
                        alert_cond: this.wizard_model.alert_active,
                        state: 'enable',
                        type: notif_type,
                        timeago: moment().unix() * 1000
                    };
                    record_notifs.push(record_notif);
                }
            }
        }

        for (var i = 0; i < record_notifs.length; i++) {
            this.audit_svc.addNotification(record_notifs[i]).pipe(first()).subscribe(
                success => {
                    this.getNotifications();
                    this.audit_svc.discoverSnmp(this.currentUser.login).subscribe(
                        error => {
                            console.log(error);
                        }
                    );
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    filterList(name: string): boolean {

        let isInFilter: boolean = false;
        for (var i in this.notifications) {
            if (this.notifications[i].name == name) {
                isInFilter = true;
                break;
            }
        }

        return isInFilter;
    }

    filterAlertByType(isWizard: boolean): Alert[] {

        let test: boolean = this.wizard_model.alert_type_rt;
        if (!isWizard)
            test = this.edit_model.alert_type_rt;

        let alerts_type: Alert[] = [];
        for (var i = 0; i < this.alerts.length; i++) {
            if (test) {
                if (this.alerts[i].granularity == "min")
                    alerts_type.push(this.alerts[i]);
            } else {
                if (this.alerts[i].granularity == "day")
                    alerts_type.push(this.alerts[i]);
            }
        }

        return alerts_type;
    }

    checkName(val: string): void {

        if (this.filterList(val)) {
            this.wizard_model.error = true;
            this.wizard_model.errorName = 'to ' + val + ' (alert already exists)';
        } else if (val.length == 0) {
            this.wizard_model.error = true;
            this.wizard_model.errorName = 'empty';
        } else if (val.length > 0) {
            this.wizard_model.error = false;
        }
    }

    addEmail(isWizard: boolean): void {

        if (isWizard)
            this.wizard_model.emails.push(this.wizard_model.add_email);
        else
            this.edit_model.emails.push({
                email_add: this.edit_model.add_email
            });
    }

    removeEmail(email: string, isWizard: boolean): void {

        if (isWizard)
            this.wizard_model.emails = this.wizard_model.emails.filter(e => e !== email);
        else
            this.edit_model.emails = this.edit_model.emails.filter(e => e !== email);
    }

    formatEditDate(date: any): any {
        return moment(date).format('MMMM Do YYYY');
    }

    updateState(val: boolean): void {

        let state: string = '';
        if (val) {
            this.notif_state = 'ENABLED';
            state = 'enable';
        } else {
            this.notif_state = 'DISABLED';
            state = 'disable';
        }

        this.audit_svc.updateNotifState(this.selected_notification.uuid, state).pipe(first()).subscribe(
            success => {
                let current_notif: Notification = this.getNotifFromUuid(this.selected_notification.uuid);
                current_notif.state = state;
                this.audit_svc.discoverSnmp(this.currentUser.login).subscribe(
                    error => {
                        console.log(error);
                    }
                );
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private getNotifications(): void {

        this.notifications = [];
        this.audit_svc.getNotificationList(this.currentUser.login).pipe(first()).subscribe(
            data => {
                this.notifications = data;
                if (this.notifications.length > 0)
                    this.loadNotification(this.notifications[0]);
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private loadNotification(notification: Notification): void {

        this.selected_notification = notification;

        this.audit_svc.getNotifInfo(notification.uuid).pipe(first()).subscribe(
            data => {
                this.info_model.alert_nb = data.alert_nb;
                this.info_model.email_nb = data.email_nb;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );

        if (notification.email)
            this.info_model.email = 'enable';
        else
            this.info_model.email = 'disable';
        if (notification.snmp)
            this.info_model.snmp = 'enable';
        else
            this.info_model.snmp = 'disable';

        if (notification.alert_cond)
            this.info_model.alert_condition = 'all';
        else
            this.info_model.alert_condition = 'one';

        if (notification.type == "rt")
            this.info_model.alert_type = 'real time';
        else
            this.info_model.alert_type = 'filter';

        if (notification.state == "enable") {
            this.enable_notif = true;
            this.notif_state = 'ENABLED';
        } else {
            this.enable_notif = false;
            this.notif_state = 'DISABLED';
        }

        this.isRdy = true;
        setTimeout(() => $('#notification-view').val(notification.name), 100);
    }

    private getNotifFromUuid(uuid: string): Notification {
        return this.notifications.find(notification => notification.uuid === uuid);
    }

    private getNotification(name: string): Notification {
        return this.notifications.find(notification => notification.name === name);
    }

    private getAlerts(): void {

        this.audit_svc.getAlertList(this.currentUser.login).pipe(first()).subscribe(
            data => {
                this.alerts = data;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private getAlertFromName(name: string): Alert {
        return this.alerts.find(alert => alert.notified_name === name);
    }
}
