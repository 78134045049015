import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
    Input
} from '@angular/core';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    ResizingDetailComponent
} from '../aws/resizing-detail/resizing-detail.component';
import {
    CloudresizingDirective
} from '../../directives/cloudresizing.directive';

import {
    BehaviorDetailComponent
} from '../aws/behavior-detail/behavior-detail.component';
import {
    CloudbehaviorDirective
} from '../../directives/cloudbehavior.directive';

import {
    SavingsplanDetailComponent
} from '../aws/savingsplan-detail/savingsplan-detail.component';
import {
    CloudsavingsplanDirective
} from '../../directives/cloudsavingsplan.directive';

import {
    OpportunitiesSpotComponent
} from '../aws/opportunities-spot/opportunities-spot.component';
import {
    CloudoppspotDirective
} from '../../directives/cloudoppspot.directive';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faLeaf
} from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-opportunities-cloud',
    templateUrl: './opportunities-cloud.component.html',
    styleUrls: ['./opportunities-cloud.component.css']
})
export class OpportunitiesCloudComponent implements AfterViewChecked, OnChanges, OnInit {

    @Input() isreload: boolean = false;

    isDcscope = true;
    isCo2scope = false;

    @ViewChild(CloudresizingDirective) addCloudResizingDetail: CloudresizingDirective;
    @ViewChild(CloudbehaviorDirective) addCloudBehaviorDetail: CloudbehaviorDirective;
    @ViewChild(CloudsavingsplanDirective) addCloudSavingsplanDetail: CloudsavingsplanDirective;
    @ViewChild(CloudoppspotDirective) addCloudOpportunitiesSpot: CloudoppspotDirective;

    number = Number;

    metadata: any = [];

    message: Message;

    detail_str: string = "cost";

    isSettings: boolean = false;

    resizing_class: string = 'progress top success';
    resizing_progress: number = 0;

    behavior_class: string = 'progress top success';
    behavior_progress: number = 0;

    savingsplan_class: string = 'progress top success';
    savings_progress: number = 0;

    spot_class: string = 'progress top success';
    spot_progress: number = 0;

    model: any = {
        oppResMeta: '',
        oppBehMeta: '',
        oppSpMeta: '',
        oppSpotMeta: '',
        oppInfo: '',
        oppResGreenMeta: '',
        oppBehGreenMeta: ''
    };

    isOppYear: boolean = false;

    viewContainerRef: any;

    isDataReload: boolean = false;

    currentUser: User;


    constructor(private account_svc: AccountService, public componentFactoryResolver: ComponentFactoryResolver,
        private json_svc: JsonloaderService, private message_svc: ShareService, private cd: ChangeDetectorRef,
        private library: FaIconLibrary) {

        library.addIcons(faLeaf);
    }

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        setTimeout(() => this.initCloudData(), 100);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['isreload'].currentValue)
            this.initCloudData();

    }

    ngAfterViewChecked(): void {

        this.cd.detectChanges();
    }

    loadResizingDetail(): void {
        this.detail_str = "resizing";
        setTimeout(() => this.loadCloudResizingDetail(), 100);
    }

    loadBehaviorDetail(): void {
        this.detail_str = "behavior";
        setTimeout(() => this.loadCloudBehaviorDetail(), 100);
    }

    loadSavingsPlansDetail(): void {
        this.detail_str = "savingsplan";
        setTimeout(() => this.loadCloudSavingsPlansDetail(), 100);
    }

    loadOpportunitiesSpot(): void {
        this.detail_str = "oppspot";
        setTimeout(() => this.loadCloudOpportunitiesSpot(), 100);
    }

    loadMetaSaving(): void {

        if (this.isOppYear) {
            this.model.oppResMeta = this.metadata.resizingCostSaveYear;
            this.model.oppBehMeta = this.metadata.behaviorCostSaveYear;
            this.model.oppSpMeta = this.metadata.savingsplanSaveYear;
            this.model.oppSpotMeta = this.metadata.spotCostSaveYear;
            this.model.oppInfo = 'estimated savings over one year';
            this.model.oppResGreenMeta = this.metadata.resizingCo2SaveYear;
            this.model.oppBehGreenMeta = this.metadata.idleCo2SavingYear;
        } else {
            this.model.oppResMeta = this.metadata.resizingCostSave;
            this.model.oppBehMeta = this.metadata.behaviorCostSave;
            this.model.oppSpMeta = this.metadata.savingsplanSave;
            this.model.oppSpotMeta = this.metadata.spotCostSave;
            this.model.oppInfo = 'current month savings';
            this.model.oppResGreenMeta = this.metadata.resizingCo2Save;
            this.model.oppBehGreenMeta = this.metadata.idleCo2Saving;
        }
    }

    loadResizingSimu(): void {
        this.detail_str = "resizing-simu";
    }

    private initCloudData(): void {

        setTimeout(() => this.loadRegionData(), 500);
    }

    private loadRegionData(): void {

        let cloud_instance: string = this.message.cloudProvider;

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + cloud_instance,
            JSONTarget.CLOUD_METADATA).subscribe(
            data => {
                this.metadata = data;
                if (this.isOppYear) {
                    this.model.oppResMeta = this.metadata.resizingCostSaveYear;
                    this.model.oppBehMeta = this.metadata.behaviorCostSaveYear;
                    this.model.oppSpMeta = this.metadata.savingsplanSaveYear;
                    this.model.oppSpotMeta = this.metadata.spotCostSaveYear;
                    this.model.oppInfo = 'estimated savings over one year';
                    this.model.oppResGreenMeta = this.metadata.resizingCo2SaveYear;
                    this.model.oppBehGreenMeta = this.metadata.idleCo2SavingYear;
                } else {
                    this.model.oppResMeta = this.metadata.resizingCostSave;
                    this.model.oppBehMeta = this.metadata.behaviorCostSave;
                    this.model.oppSpMeta = this.metadata.savingsplanSave;
                    this.model.oppSpotMeta = this.metadata.spotCostSave;
                    this.model.oppInfo = 'current month savings';
                    this.model.oppResGreenMeta = this.metadata.resizingCo2Save;
                    this.model.oppBehGreenMeta = this.metadata.idleCo2Saving;
                }
                if (this.metadata.kpiCreditsAggMax > 0)
                    this.model.kpiAggUsagePercent = (this.metadata.kpiCreditAggUsage * 100 / this.metadata
                        .kpiCreditsAggMax).toFixed(0);

                let total_instance: number = this.metadata.runInstance + this.metadata.offInstance;
                if (total_instance > 0) {
                    this.resizing_progress = this.metadata.resizingInstance * 100 / total_instance;
                    if (this.resizing_progress > 50)
                        this.resizing_class = 'progress top danger';

                    this.behavior_progress = this.metadata.behaviorInstance * 100 / total_instance;
                    if (this.behavior_progress > 50)
                        this.behavior_class = 'progress top danger';

                    this.spot_progress = this.metadata.spotInstance * 100 / total_instance;
                    if (this.spot_progress > 50)
                        this.spot_class = 'progress top danger';
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    private loadCloudResizingDetail(): void {

        if (this.addCloudResizingDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ResizingDetailComponent);
            const viewContainerRef = this.addCloudResizingDetail.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCloudBehaviorDetail(): void {

        if (this.addCloudBehaviorDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BehaviorDetailComponent);
            const viewContainerRef = this.addCloudBehaviorDetail.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCloudSavingsPlansDetail(): void {

        if (this.addCloudSavingsplanDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                SavingsplanDetailComponent);
            const viewContainerRef = this.addCloudSavingsplanDetail.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCloudOpportunitiesSpot(): void {

        if (this.addCloudOpportunitiesSpot != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                OpportunitiesSpotComponent);
            const viewContainerRef = this.addCloudOpportunitiesSpot.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }
}
