import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-t6]'
})
export class T6Directive {
    constructor(public viewContainerRef6: ViewContainerRef) {}
}
