import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    ManagementService,
    ShareService
} from '@app/services';

import {
    Message
} from '@app/model';


@Component({
    selector: 'app-account-management',
    templateUrl: './account-management.component.html',
    styleUrls: ['./account-management.component.css']
})
export class AccountManagementComponent implements OnInit {

    @Input()
    targetInfrastructure: string = 'aws';
    getTargetInfrastructure() {
        return this.targetInfrastructure;
    }

    @Output("loadRegion") loadRegion: EventEmitter < any > = new EventEmitter();

    @Output("loadCloud") loadCloud: EventEmitter < any > = new EventEmitter();

    message: Message;

    isSettings: boolean = false;

    model: any = {
        accountSelected: ''
    };

    allAccounts: any = [];

    accounts: any = [];


    constructor(
        private mgt_svc: ManagementService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.mgt_svc.getRegions(this.getTargetInfrastructure()).pipe(first()).subscribe(
            data2 => {
                if (data2.length > 0) {
                    this.allAccounts = data2;

                    // BUILD ACCOUNT LIST
                    let account_check: string[] = [];
                    for (let account of this.allAccounts) {
                        if (!account_check.includes(account.INSTANCE_NAME)) {
                            account_check.push(account.INSTANCE_NAME);
                            this.accounts.push(account);
                        }
                    }

                    // INIT MODEL
                    this.message.cloudAccountId = this.allAccounts[0].INSTANCE_ID;
                    this.message.cloudAccount = this.allAccounts[0].INSTANCE_NAME;
                    this.model.accountSelected = this.allAccounts[0].INSTANCE_NAME;
                }
            }
        );
    }

    initLoadAccount(): void {

        let account: any = this.filterAccountByFullName(this.model.accountSelected);
        if (account != undefined) {
            this.message.cloudAccountId = account.INSTANCE_ID;
            this.message.cloudAccount = account.INSTANCE_NAME;
        }
    }

    reloadAccount(): void {

        this.loadRegion.emit();

        this.loadCloud.emit();
    }

    private filterAccountByFullName(name: string): any {

        return this.accounts.find((account: {
            INSTANCE_NAME: string;
        }) => account.INSTANCE_NAME === name);
    }
}
