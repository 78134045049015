<div class="card" >
    <div class="card-header card-ajust">
        <h5><cds-icon shape="slider"></cds-icon>&nbsp;{{title}}</h5>
    </div>
    <div class="card-block" style="height:420px">
        <div style="float:left;width:100%">
            <div style="margin:5px;padding:10px;text-align:center;float:left;width:20%;"></div>
            <div class="capablocktitle">
                <b class="customtitle">{{ram_label}}</b><br>
            </div>
            <div class="capablocktitle">
                <b class="customtitle">{{cpu_label}}</b><br>
            </div>
            <div class="capablocktitle">
                <b class="customtitle">{{disk_label}}</b><br>
            </div>
        </div>

        <div style="float:left;width:100%">
            <p style="margin:0;font-size:13px;"><b><cds-icon shape="heat-map"></cds-icon>&nbsp;Resource data</b></p>
        </div>

        <div style="float:left;width:100%;margin-top:10px">
            <div style="margin:5px;padding:10px;text-align:center;float:left;width:20%;">
                <div class="customcontainer"><b class="customlegend">USED</b></div>
                <div class="customcontainer"><b class="customlegend">FREE</b></div>
                <div class="customcontainer"><b class="customlegend">TOTAL</b></div>
            </div>
            <div role="tooltip" class="tooltip tooltip-lg tooltip-top-right capablock" style="border-color: grey;">
                <div class="customcontainer"><div><b>{{formatNumber(ram_used)}} {{ram_unit}}</b></div><div class="customright">({{ram_used_percent}} %)</div></div>
                <div class="customcontainer" [style.color]="ram_free < 0 ? 'red' : 'black'"><div><b>{{formatNumber(ram_free)}} {{ram_unit}}</b></div><div class="customright">({{ram_free_percent}} %)</div></div>
                <div class="customcontainer"><div><b>{{formatNumber(ram_total)}} {{ram_unit}}</b></div><div class="customright">({{ram_total_percent}} %)</div></div>
                <span class="tooltip-content info-tooltip">{{ram_legend}}</span>
            </div>
            <div role="tooltip" class="tooltip tooltip-lg tooltip-top-right capablock" style="border-color: grey;">
                <div class="customcontainer"><div><b>{{formatNumber(cpu_used)}} {{cpu_unit == "" ? '&nbsp;&nbsp;' : cpu_unit}}</b></div><div class="customright">({{cpu_used_percent}} %)</div></div>
                <div class="customcontainer" [style.color]="cpu_free < 0 ? 'red' : 'black'"><div><b>{{formatNumber(cpu_free)}} {{cpu_unit == "" ? '&nbsp;&nbsp;' : cpu_unit}}</b></div><div class="customright">({{cpu_free_percent}} %)</div></div>
                <div class="customcontainer"><div><b>{{formatNumber(cpu_total)}} {{cpu_unit == "" ? '&nbsp;&nbsp;' : cpu_unit}}</b></div><div class="customright">({{cpu_total_percent}} %)</div></div>
                <span class="tooltip-content info-tooltip">{{cpu_legend}}</span>
            </div>
            <div role="tooltip" class="tooltip tooltip-lg tooltip-top-right capablock" style="border-color: grey;">
                <div class="customcontainer"><div><b>{{formatNumber(disk_used)}} {{disk_unit}}</b></div><div class="customright">({{disk_used_percent}} %)</div></div>
                <div class="customcontainer" [style.color]="disk_free < 0 ? 'red' : 'black'"><div><b>{{formatNumber(disk_free)}} {{disk_unit}}</b></div><div class="customright">({{disk_free_percent}} %)</div></div>
                <div class="customcontainer"><div><b>{{formatNumber(disk_total)}} {{disk_unit}}</b></div><div class="customright">({{disk_total_percent}} %)</div></div>
                <span class="tooltip-content info-tooltip">{{disk_legend}}</span>
            </div>
        </div>

        <div style="float:left;width:100%;margin-top:10px">
            <p style="margin:0;font-size:13px;"><b><cds-icon shape="line-chart"></cds-icon>&nbsp;Saturations based on yours trends</b></p>
        </div>

        <div style="float:left;width:100%;margin-top:10px">
            <div style="margin:5px;text-align:center;float:left;width:20%;">
                <div class="customcontainer"><b class="customlegend">SATURATION</b></div>
            </div>
            <div class="capablock" [style.color]="ram_satdate_color">   
                <b>{{ram_satdate_format}}</b><br>
            </div>
            <div class="capablock" [style.color]="cpu_satdate_color">
                <b>{{cpu_satdate_format}}</b><br>
            </div>
            <div class="capablock" [style.color]="disk_satdate_color">
                <b>{{disk_satdate_format}}</b><br>
            </div>
        </div>

        <div style="float:left;width:100%;margin-top:10px">
            <div style="margin:5px;text-align:center;float:left;width:20%;">
                 <div class="customcontainer"><b class="customlegend"><cds-icon shape="slider"></cds-icon>&nbsp;SIMULATION</b></div>
            </div>
            <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left capablock" [style.color]="ram_simudate_color">
                <b >{{ram_simudate_format}}</b><br>
                <!--<span *ngIf="ram_simudate_format == 'KO'" class="tooltip-content ko-tooltip">FREE : {{ram_simu_free}} {{ram_simu_unit}}</span>-->
            </div>
            <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left capablock" [style.color]="cpu_simudate_color">
                <b>{{cpu_simudate_format}}</b><br>
                <!--<span *ngIf="cpu_simudate_format == 'KO'" class="tooltip-content ko-tooltip">FREE : {{cpu_simu_free}} {{cpu_simu_unit}}</span>-->
            </div>
            <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left capablock" [style.color]="disk_simudate_color">
                <b>{{disk_simudate_format}}</b><br>
                <!--<span *ngIf="disk_simudate_format == 'KO'" class="tooltip-content ko-tooltip">FREE : {{disk_simu_free}} {{disk_simu_unit}}</span>-->
            </div>
        </div>

    </div> 
</div>