<clr-vertical-nav style="height:100%;" [clrVerticalNavCollapsible]="true" class="nav-trigger--bottom">
    <a clrVerticalNavLink routerLink="./dashboard" routerLinkActive="active">
        {{menu.OVERVIEW}}
    </a>
    <div class="nav-divider" *ngIf="checkSection(section.MEASURE)"></div>
    <clr-vertical-nav-group [hidden]="!checkSection(section.MEASURE)" routerLinkActive="active">
        Measure
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <a [hidden]="!checkMenu(menu.CO2_EVOLUTION)" clrVerticalNavLink routerLink="./greenit/evolution" routerLinkActive="active">
                <cds-icon shape="tree"></cds-icon>
                {{menu.CO2_EVOLUTION}}
            </a>
            <a [hidden]="!checkMenu(menu.POWER_GRAPH)" clrVerticalNavLink routerLink="./greenit/power" routerLinkActive="active">
                <cds-icon shape="bolt"></cds-icon>
                {{menu.POWER_GRAPH}}
            </a>
            <a [hidden]="!checkMenu(menu.GOD)" clrVerticalNavLink routerLink="./god" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="line-chart" style="margin-right:10px"></cds-icon>
                {{menu.GOD}}
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
    <div class="nav-divider" *ngIf="checkSection(section.ANALYSE)"></div>
     <clr-vertical-nav-group [hidden]="!checkSection(section.ANALYSE)" routerLinkActive="active">
            Analyse
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <a [hidden]="!checkMenu(menu.POWER_DIST)" clrVerticalNavLink routerLink="../dcviews/treemap" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="grid-view" style="margin-right:10px"></cds-icon>
                {{menu.POWER_DIST}}
            </a>
            <a [hidden]="!checkMenu(menu.POWER_USAGE)" clrVerticalNavLink routerLink="./elementsynthesis" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="calendar" style="margin-right:10px"></cds-icon>
                {{menu.POWER_USAGE}}
            </a>
            <a [hidden]="!checkMenu(menu.REPORT)" clrVerticalNavLink routerLink="./reporting" routerLinkActive="active" *ngIf="role.isMenuReport">
                <cds-icon clrVerticalNavIcon shape="library" style="margin-right:10px"></cds-icon>
                {{menu.REPORT}}
             </a>
           <a [hidden]="!checkMenu(menu.GREEN_INDEX)" clrVerticalNavLink routerLink="./greenindex" routerLinkActive="active" *ngIf="role.isMenuVirtindex">
				<cds-icon clrVerticalNavIcon shape="bell-curve" style="margin-right:10px"></cds-icon>
				{{menu.GREEN_INDEX}}&nbsp;<ng-template [ngTemplateOutlet]="greenindexstatus"></ng-template>
			</a>
            <a [hidden]="!checkMenu(menu.COST)" clrVerticalNavLink routerLink="./dashboard-t6" routerLinkActive="active">
				<cds-icon clrVerticalNavIcon shape="dollar" style="margin-right:10px"></cds-icon>
				{{menu.COST}}
			</a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
    <div class="nav-divider" *ngIf="checkSection(section.REDUCE)"></div>
    <clr-vertical-nav-group [hidden]="!checkSection(section.REDUCE)" routerLinkActive="active">
        Reduce
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <a [hidden]="!checkMenu(menu.OPPORTUNITIES)" clrVerticalNavLink routerLink="./opportunities" routerLinkActive="active" *ngIf="role.isMenuReco">
                <cds-icon clrVerticalNavIcon shape="thumbs-up" style="margin-right:10px"></cds-icon>
                {{menu.OPPORTUNITIES}}
            </a>
            <a [hidden]="!checkMenu(menu.GREENIT_ANALYSIS)" clrVerticalNavLink routerLink="./greenit/analysis" routerLinkActive="active">
				<cds-icon clrVerticalNavIcon shape="table"></cds-icon>
				{{menu.GREENIT_ANALYSIS}}
			</a>
            <a [hidden]="!checkMenu(menu.CAPACITY_PLAN)" clrVerticalNavLink routerLink="./capacity" routerLinkActive="active" *ngIf="role.isMenuCapa">
                <cds-icon clrVerticalNavIcon shape="slider" style="margin-right:10px"></cds-icon>
                {{menu.CAPACITY_PLAN}}
            </a>
            <a [hidden]="!checkMenu(menu.SIMULATION)" clrVerticalNavLink routerLink="./simulation" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="deploy" style="margin-right:10px"></cds-icon>
                {{menu.SIMULATION}}
            </a>
            <a [hidden]="!checkMenu(menu.CLOUD_COMPARATOR)" clrVerticalNavLink routerLink="./cloudcomparator" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="deploy" style="margin-right:10px"></cds-icon>
                {{menu.CLOUD_COMPARATOR}}
            </a>
            <clr-vertical-nav-group [hidden]="!checkMenu(menu.CLOUD_IMPACT)" routerLinkActive="active" style="margin-left: 20px;">
                {{menu.CLOUD_IMPACT}}
                <clr-vertical-nav-group-children *clrIfExpanded="true">
                    <a clrVerticalNavLink routerLink="./cloudimpact" routerLinkActive="active" *ngIf="role.isMenuReco">
                        <cds-icon clrVerticalNavIcon shape="network-globe" style="margin-right:10px"></cds-icon>
                        All regions
                    </a>
                    <a clrVerticalNavLink routerLink="./cloud/myprovider/myregion" routerLinkActive="active">
                        <fa-icon [icon]="myproviderIcon" ></fa-icon>
                        My Cloud Impact
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
</clr-vertical-nav>

<!-- GREEN-INDEX-->
<ng-template #greenindexstatus >
	<cds-icon *ngIf="!greenindex_status" size="20" style="color:var(--clr-alert-warning-icon-color, #454545);" shape="exclamation-circle"></cds-icon>
</ng-template>
