import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    OnInit,
    Output
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    CsvGreenItDevice,
    GreenItDevice,
    GreenitIndicators,
    GreenitMinmax,
    GreenitSettings,
    Message,
    User
} from '@app/model';
import {
    AccountService,
    GreenitService,
    JsonloaderService,
    ShareService,
    ManagementService,
    UploadService
} from '@app/services';
import {
    DefaultValues,
    GreenitTarget,
    HOST_DIRECT_RATIO,
    NETWORK_DIRECT_RATIO,
    RENEWAL_DEFAULT_NAME,
    STORAGE_DIRECT_RATIO,
    TreeTarget
} from '../greenit.enums';
import {
    UploadType
} from '../../upload/upload.enums';
import {
    v4 as uuidv4
} from 'uuid';
import Papa, {
    ParseResult
} from "papaparse";
import {
    getUserCurrency
} from '../../../assets/js/tools';
import {
    ClrDatagridSortOrder
} from '@clr/angular';

import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-greenitsettings',
    templateUrl: './greenitsettings.component.html',
    styleUrls: ['./greenitsettings.component.css']
})
export class GreenitsettingsComponent implements OnInit {

    @Output('reload') reloadChange = new EventEmitter < boolean > ();
    pushReload() {
        this.reloadChange.emit(true);
    }

    dataOrder = ClrDatagridSortOrder.ASC;

    math = Math;
    number = Number;

    message: Message;

    isDcscope = true;
    isCo2scope = false;
    productname: String = "DC Scope";

    currentUser: User;
    globalCurrency: string;

    // PIE CHART
    Highcharts: typeof Highcharts = Highcharts;

    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;

        this.chart.showLoading();
    };

    options: Highcharts.Options = {
        chart: {
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: '',
            //text: 'Division of power consumption by IT',
            style: {
                color: 'grey',
                fontSize: '15px',
                marginTop: -100
            }
        },
        legend: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                size: '65%',
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    distance: '-35%'
                }
            }
        },
    }

    //FOR SETTINGS
    private db_settings: GreenitSettings[];
    private settings_changed: any;
    private optimization_run: boolean;

    settings_modal: boolean;

    save_button: boolean;
    indicator_save: boolean;
    minmax_save: boolean;
    settings_save: boolean;
    extrapolation_save: boolean;
    indicator_network_save: boolean;
    indicator_storage_save: boolean;

    extrapolation_alert: boolean;

    last_update: number;

    /*indicators*/
    average_host_year: number;
    average_network_year: number;
    average_storage_year: number;
    average_pue: number;
    average_co2: number;
    average_cost: number;

    settings_host_other_number: number;
    settings_host_other_sockets: number;
    settings_host_other_memory: number;
    settings_host_other_power: number;
    settings_switch_number: number;
    settings_switch_power: number;
    settings_storage_number: number;
    settings_storage_power: number;

    /*minmax*/
    private db_serversminmax: GreenitMinmax[];
    private db_kubenodesminmax: any;
    minmax_data: any;
    show_minmax_details: boolean;

    /*servers indicators*/
    private db_serversindicators: GreenitIndicators[];
    indicators_data: GreenitIndicators[];
    show_indicators_details: boolean;

    /*network indicators*/
    private db_networkindicators: GreenitIndicators[];
    indicators_network_data: GreenitIndicators[];

    /*storage indicators*/
    private db_storageindicators: GreenitIndicators[];
    indicators_storage_data: GreenitIndicators[];

    /*desktop*/
    settings_printer_age: number;
    settings_printer_home_number: number;
    settings_printer_pro_number: number;
    settings_laptop_age: number;
    settings_laptop_13_number: number;
    settings_laptop_15_number: number;
    settings_laptop_17_number: number;
    settings_laptop_charger_number: number;
    settings_desktop_age: number;
    settings_desktop_number: number;
    settings_keyboard_number: number;
    settings_mouse_number: number;
    settings_screen_17_number: number;
    settings_screen_22_number: number;
    settings_screen_27_number: number;
    settings_desktop_router_number: number;
    settings_desktop_network_other_number: number;
    settings_tablet_number: number;
    settings_smartphone_number: number;

    /*optimization*/
    optimization_hosts_uuid: any;
    settings_optimization_host: any;

    /*network & storage */
    settings_network_direct_ratio: number;
    settings_storage_direct_ratio: number;
    settings_host_direct_ratio: number;

    /*renewal server*/
    renewal_modal: boolean;
    renewal_save: boolean = false;
    renewal_data: any = {};

    renewal_serverram: number = 0; // GB
    renewal_serversocket: number = 0;
    renewal_serverthread: number = 0;
    renewal_serverembodied: number = 0;
    renewal_serverkWh: number = 0;


    // FOR TOOLS
    indicatortree: TreeTarget;
    networkindicatortree: TreeTarget;
    storageindicatortree: TreeTarget;
    minmaxtree: TreeTarget;

    // FOR HISTORY
    history_modal: boolean;
    last_history: number;
    history_disabled: boolean;

    // FOR STATIC DEVICES
    settings_tab: string = '';
    isLoading: boolean = true;

    // FOR NETWORK DEVICES
    network_params: any = {
        entry: 'manual'
    }
    network_device: any;
    network_devices: GreenItDevice[] = [];

    // FOR STORAGE DEVICES
    storage_params: any = {
        entry: 'manual'
    }
    storage_device: any;
    storage_devices: GreenItDevice[] = [];

    // FOR UPLOAD
    report: UploadType;
    updates: UploadType;
    netdevice: UploadType;
    stodevice: UploadType;

    // FOR COMMON NODES
    cnodes_params: any = {
        entry: 'list',
        infra: [],
        nodes_1: [],
        nodes_2: [],
        isReady: false,
        mas: []
    }

    cnodes_model: any = {
        id1: '',
        name1: '',
        type1: 'esx',
        matype1: '',
        id2: '',
        name2: '',
        type2: '',
        matype2: '',
        error: false,
        errorMsg: ''
    }

    /**
     *
     */
    constructor(private authentication_svc: AccountService, private json_svc: JsonloaderService,
        private message_svc: ShareService, private greenit_svc: GreenitService, private management_svc:
        ManagementService, private upload_svc: UploadService, private changeDetectorRef: ChangeDetectorRef) {

        this.optimization_hosts_uuid = [];

        // FOR SETTINGS 
        this.settings_modal = false;

        this.save_button = false;
        this.indicator_save = undefined;
        this.minmax_save = undefined;
        this.settings_save = undefined;
        this.extrapolation_save = undefined;
        this.indicator_network_save = undefined;
        this.indicator_storage_save = undefined;
        this.renewal_save = undefined;

        this.extrapolation_alert = false;

        this.show_minmax_details = true;
        this.show_indicators_details = true;

        this.db_settings = [];
        this.db_serversminmax = [];
        this.db_kubenodesminmax = [];
        this.db_serversindicators = [];
        this.db_networkindicators = [];
        this.db_storageindicators = [];

        this.last_update = 0;

        // FOR HISTORY
        this.history_modal = false;
        this.last_history = 0;
        this.history_disabled = false;

        // FOR TOOLS
        this.indicatortree = TreeTarget.INDICATOR;
        this.networkindicatortree = TreeTarget.NETWORK_INDICATOR;
        this.storageindicatortree = TreeTarget.STORAGE_INDICATOR;
        this.minmaxtree = TreeTarget.MINMAX;

        //FOR UPLOAD
        this.report = UploadType.REPORT;
        this.updates = UploadType.UPDATES;
        this.netdevice = UploadType.NETDEVICE;
        this.stodevice = UploadType.STODEVICE;
    }

    /**
     * 
     */
    @HostListener('click', ['$event.target']) onClick(e: {
        id: string;
    }) {
        if (e.id == "upload-close") {
            this.loadDeviceFromFile();
        }
    }

    /**
     * 
     */
    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;

        if (this.isCo2scope)
            this.productname = "CO2 Scope";

        // Common Nodes
        //this.loadNames(true)
    }

    /**
     * Display settings modal
     */
    async displayModalSettings(): Promise < void > {

        this.settings_modal = true;

        // First initialization
        this.save_button = false;
        this.indicator_save = undefined;
        this.minmax_save = undefined;
        this.settings_save = undefined;
        this.indicator_network_save = undefined;
        this.indicator_storage_save = undefined;
        this.renewal_save = undefined;

        this.settings_changed = {};
        this.optimization_run = false;

        // Empty hosts array
        this.optimization_hosts_uuid = [];

        // Init hosts array ...
        let hostSynthesis_json: JSON = this.json_svc.json.hostSynthesis;
        for (let i in hostSynthesis_json) {
            this.optimization_hosts_uuid[i] = [];
            this.optimization_hosts_uuid[i][0] = hostSynthesis_json[i].uuid;
            this.optimization_hosts_uuid[i][1] = hostSynthesis_json[i].name;
        }
        //XXX Inject renewal_server for optimization
        let optimization_size = this.optimization_hosts_uuid.length;
        this.optimization_hosts_uuid[optimization_size] = [];
        this.optimization_hosts_uuid[optimization_size][0] = RENEWAL_DEFAULT_NAME;
        this.optimization_hosts_uuid[optimization_size][1] = RENEWAL_DEFAULT_NAME;

        // ... and sort it by name
        this.optimization_hosts_uuid.sort(function(a, b) {
            return (b[1].toUpperCase()) < (a[1].toUpperCase()) ? 1 : -1;
        });

        // GET SETTINGS (async)
        try {
            let settings_subscribe = await this.greenit_svc.getGreenitSettings().toPromise();
            this.updateDataSettings(settings_subscribe);
        } catch {
            // Nothing, already on console
        }

        // GET DC HOST MINMAX (async)
        let dc_minmax_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this.message
            .currentFilter, GreenitTarget.DC_HOST_MINMAX).toPromise();
        this.db_serversminmax = < GreenitMinmax[] > dc_minmax_subscribe;

        // GET KUBE NODE MINMAX (async)
        let kube_minmax_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this.message
            .currentFilter, GreenitTarget.KUBE_NODE_MINMAX).toPromise();
        this.db_kubenodesminmax = < any[] > kube_minmax_subscribe;

        // GET INDICATORS (async)
        let indicators_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this.message
            .currentFilter, GreenitTarget.SERVERS_SETTINGS).toPromise();
        this.db_serversindicators = < GreenitIndicators[] > indicators_subscribe;

        // GET NETWORK INDICATORS (async)
        try {
            let network_indicators_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this
                .message
                .currentFilter, GreenitTarget.NETWORK_SETTINGS).toPromise();
            this.db_networkindicators = < GreenitIndicators[] > network_indicators_subscribe;
        } catch {
            // Nothing, already on console
        }

        // GET STORAGE INDICATORS (async)
        try {
            let storage_indicators_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this
                .message
                .currentFilter, GreenitTarget.STORAGE_SETTINGS).toPromise();
            this.db_storageindicators = < GreenitIndicators[] > storage_indicators_subscribe;
        } catch {
            // Nothing, already on console
        }

        // GET NETWORK DEVICES
        this.initNetworkDevice(true);

        // GET STORAGE DEVICES
        this.initStorageDevice(true);

        // INIT SETTINGS
        this.initSettings();

        // Update modal width for datagrids
        /*setTimeout(() => {
            // Update modal width
            let modal: HTMLCollectionOf < Element > = document.getElementsByClassName('modal-xl');
            if (modal.length > 0)
                (modal[0] as HTMLElement).style.width = "70rem";
        }, 100);*/

    }

    /**
     * 
     */
    private initSettings(): void {

        //XXX default average
        this.average_host_year = new Date().getFullYear();
        this.average_network_year = new Date().getFullYear();
        this.average_storage_year = new Date().getFullYear();
        this.average_pue = DefaultValues.DC_PUE;
        this.average_co2 = DefaultValues.DC_DIRECT_CO2;
        this.average_cost = DefaultValues.DC_DIRECT_COST;

        //XXX default settings

        //
        // DC
        //

        this.settings_host_other_number = 0;
        this.settings_host_other_sockets = 0;
        this.settings_host_other_memory = 0;
        this.settings_host_other_power = 0;
        this.settings_switch_number = 0;
        this.settings_switch_power = 0;
        this.settings_storage_number = 0;
        this.settings_storage_power = 0;

        //
        // DESKTOP
        //
        this.settings_printer_age = 4;
        this.settings_printer_home_number = 0;
        this.settings_printer_pro_number = 0;
        this.settings_laptop_age = 4;
        this.settings_laptop_13_number = 0;
        this.settings_laptop_15_number = 0;
        this.settings_laptop_17_number = 0;
        this.settings_laptop_charger_number = 0;
        this.settings_desktop_age = 4;
        this.settings_desktop_number = 0;
        this.settings_keyboard_number = 0;
        this.settings_mouse_number = 0;
        this.settings_screen_17_number = 0;
        this.settings_screen_22_number = 0;
        this.settings_screen_27_number = 0;
        this.settings_desktop_router_number = 0;
        this.settings_desktop_network_other_number = 0;
        this.settings_tablet_number = 0;
        this.settings_smartphone_number = 0;

        //
        // OPTIMIZATION
        //
        this.settings_optimization_host = "";

        //
        // NETWORK & STORAGE
        //
        this.settings_network_direct_ratio = DefaultValues.NETWORK_EXTRAPOLATION_RATIO;
        this.settings_storage_direct_ratio = DefaultValues.STORAGE_EXTRAPOLATION_RATIO;
        this.settings_host_direct_ratio = DefaultValues.HOST_EXTRAPOLATION_RATIO;

        // Get settings
        for (let i in this.db_settings) {
            if (this.db_settings[i] != undefined) {
                switch (this.db_settings[i].option) {
                    //
                    // DC
                    //
                    case "static.host.other.number":
                        this.settings_host_other_number = Number(this.db_settings[i].value);
                        break;
                    case "static.host.other.sockets":
                        this.settings_host_other_sockets = Number(this.db_settings[i].value);
                        break;
                    case "static.host.other.memory":
                        this.settings_host_other_memory = Number(this.db_settings[i].value);
                        break;
                    case "static.host.other.power":
                        this.settings_host_other_power = Number(this.db_settings[i].value);
                        break;
                        //
                        // DESKTOP
                        //
                    case "desktop.printer.age":
                        this.settings_printer_age = Number(this.db_settings[i].value);
                        break;
                    case "desktop.printer.home.number":
                        this.settings_printer_home_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.printer.pro.number":
                        this.settings_printer_pro_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.laptop.age":
                        this.settings_laptop_age = Number(this.db_settings[i].value);
                        break;
                    case "desktop.laptop.13.number":
                        this.settings_laptop_13_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.laptop.15.number":
                        this.settings_laptop_15_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.laptop.17.number":
                        this.settings_laptop_17_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.laptop.charger.number":
                        this.settings_laptop_charger_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.desktop.age":
                        this.settings_desktop_age = Number(this.db_settings[i].value);
                        break;
                    case "desktop.desktop.number":
                        this.settings_desktop_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.keyboard.number":
                        this.settings_keyboard_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.mouse.number":
                        this.settings_mouse_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.screen.17.number":
                        this.settings_screen_17_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.screen.22.number":
                        this.settings_screen_22_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.screen.27.number":
                        this.settings_screen_27_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.router.number":
                        this.settings_desktop_router_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.network.other.number":
                        this.settings_desktop_network_other_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.tablet.number":
                        this.settings_tablet_number = Number(this.db_settings[i].value);
                        break;
                    case "desktop.smartphone.number":
                        this.settings_smartphone_number = Number(this.db_settings[i].value);
                        break;
                        //
                        // NETWORK & STORAGE
                        //
                    case "network.direct.ratio":
                        this.settings_network_direct_ratio = Number(this.db_settings[i].value);
                        break;
                    case "storage.direct.ratio":
                        this.settings_storage_direct_ratio = Number(this.db_settings[i].value);
                        break;
                    case "host.direct.ratio":
                        this.settings_host_direct_ratio = Number(this.db_settings[i].value);
                        break;
                        //
                        // OPTIMIZATION
                        //
                    case "optimization.host":
                        let settings_uuid = this.db_settings[i].value;
                        // Get host name from uuid
                        for (let i in this.optimization_hosts_uuid) {
                            if (this.optimization_hosts_uuid[i][0] == settings_uuid) {
                                this.settings_optimization_host = this.optimization_hosts_uuid[i][1];
                                break;
                            }
                        }
                        break;
                        //
                        // RENEWAL SERVER
                        //
                    case "renewal.ram":
                        this.renewal_serverram = Number(this.db_settings[i].value);
                        break;
                    case "renewal.socket":
                        this.renewal_serversocket = Number(this.db_settings[i].value);
                        break;
                    case "renewal.thread":
                        this.renewal_serverthread = Number(this.db_settings[i].value);
                        break;
                    case "renewal.embodied":
                        this.renewal_serverembodied = Number(this.db_settings[i].value);
                        break;
                    case "renewal.kwh":
                        this.renewal_serverkWh = Number(this.db_settings[i].value);
                        break;
                }
            }
        }

        // Get min max
        this.minmax_data = [];
        this.db_serversminmax.forEach(val => this.minmax_data.push(Object.assign({}, val))); // create a copy
        this.db_kubenodesminmax.forEach(val => this.minmax_data.push(Object.assign({}, val)));

        // Get servers settings
        this.indicators_data = [];
        this.db_serversindicators.forEach(val => this.indicators_data.push(Object.assign({},
            val))); // create a copy

        // Get network settings
        this.indicators_network_data = [];
        this.db_networkindicators.forEach(val => this.indicators_network_data.push(Object.assign({},
            val))); // create a copy

        // Get storage settings
        this.indicators_storage_data = [];
        this.db_storageindicators.forEach(val => this.indicators_storage_data.push(Object.assign({},
            val))); // create a copy

        // Default indicators are now based from filter
        if (this.indicators_data.length > 0) {
            let avgYear = this.indicators_data.map(res => res.year).reduce((a, b) => a + b) / this.indicators_data
                .length;
            let avgPue = this.indicators_data.map(res => res.pue).reduce((a, b) => a + b) / this.indicators_data
                .length;
            let avgCo2 = this.indicators_data.map(res => res.co2).reduce((a, b) => a + b) / this.indicators_data
                .length;
            let avgCost = this.indicators_data.map(res => res.cost).reduce((a, b) => a + b) / this.indicators_data
                .length;

            this.average_host_year = Number(avgYear.toFixed(0));
            this.average_pue = Number(avgPue.toFixed(3));
            this.average_co2 = Number(avgCo2.toFixed(3));
            this.average_cost = Number(avgCost.toFixed(3));
        }

        if (this.indicators_network_data.length > 0) {
            let avgNetworkYear = this.indicators_network_data.map(res => res.year).reduce((a, b) => a + b) / this
                .indicators_network_data
                .length;

            this.average_network_year = Number(avgNetworkYear.toFixed(0));
        }

        if (this.indicators_storage_data.length > 0) {
            let avgStorageYear = this.indicators_storage_data.map(res => res.year).reduce((a, b) => a + b) / this
                .indicators_storage_data
                .length;

            this.average_storage_year = Number(avgStorageYear.toFixed(0));
        }
    }

    /**
     * 
     */
    setExtrapolationTab(): void {
        setTimeout(() => {
            this.displayPie();
        }, 100);
    }

    /**
     * 
     */
    private displayPie(): void {

        if (this.chart.series[0] != undefined)
            this.chart.series[0].remove(true);

        let datas = [];

        let tmp_data: any = {
            name: 'storage',
            y: this.settings_storage_direct_ratio,
        };
        datas.push(tmp_data);

        tmp_data = {
            name: 'network',
            y: this.settings_network_direct_ratio,
        };
        datas.push(tmp_data);

        tmp_data = {
            name: 'hosts',
            y: this.settings_host_direct_ratio,
            color: '#476888'
        };
        datas.push(tmp_data);

        // Add serie
        this.chart.addSeries({
            type: 'pie',
            data: datas,
            tooltip: {
                pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
            },
        });

        // Hide chart loading
        this.chart.hideLoading();

        // Add title (center)
        let newX = this.chart.plotWidth / 2 + this.chart.plotLeft;
        let newY = this.chart.plotHeight / 2 + this.chart.plotTop;

        this.chart.renderer.text('Division of energy consumption by IT', newX, newY / 3)
            .attr({
                zIndex: 5,
                align: 'center'
            })
            .css({
                color: 'grey',
                fontSize: '13px',
                fontWeight: 'bold',
            })
            .add();

        // reflow
        this.chart.reflow();
    }

    /**
     * Auto fill some informations
     */
    autoSettings(element: HTMLElement): void {
        let objInput: HTMLInputElement = null;

        switch (element.id) {
            case "desktop.desktop.number":
                // Update keyboards
                objInput = < HTMLInputElement > document.getElementById("desktop.keyboard.number");
                this.settings_keyboard_number = this.settings_desktop_number;
                this.addSettings(objInput);
                // Update mouses
                objInput = < HTMLInputElement > document.getElementById("desktop.mouse.number");
                this.settings_mouse_number = this.settings_desktop_number;
                this.addSettings(objInput);
                break;
            case "desktop.laptop.13.number":
            case "desktop.laptop.15.number":
            case "desktop.laptop.17.number":
                // Update chargers      
                objInput = < HTMLInputElement > document.getElementById("desktop.laptop.charger.number");
                this.settings_laptop_charger_number = Number(this.settings_laptop_13_number + this
                    .settings_laptop_15_number + this.settings_laptop_17_number);
                this.addSettings(objInput);
                break;
        }

    }

    /**
     * 
     */
    addSettings(element: HTMLInputElement): void {

        this.settings_save = true;

        switch (element.id) {
            //
            // DC
            //
            case "static.host.other.number":
                if (this.settings_host_other_number != null && this.settings_host_other_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_host_other_number
                    });
                } else {
                    this.settings_host_other_number = 0;
                }
                break;
            case "static.host.other.sockets":
                if (this.settings_host_other_sockets != null && this.settings_host_other_sockets >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_host_other_sockets
                    });
                } else {
                    this.settings_host_other_sockets = 0;
                }
                break;
            case "static.host.other.memory":
                if (this.settings_host_other_memory != null && this.settings_host_other_memory >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_host_other_memory
                    });
                } else {
                    this.settings_host_other_memory = 0;
                }
                break;
            case "static.host.other.power":
                if (this.settings_host_other_power != null && this.settings_host_other_power >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_host_other_power
                    });
                } else {
                    this.settings_host_other_power = 0;
                }
                break;
                //
                // DESKTOP
                //
            case "desktop.printer.age":
                if (this.settings_printer_age != null && this.settings_printer_age >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_printer_age
                    });
                } else {
                    this.settings_printer_age = 0;
                }
                break;
            case "desktop.printer.home.number":
                if (this.settings_printer_home_number != null && this.settings_printer_home_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_printer_home_number
                    });
                } else {
                    this.settings_printer_home_number = 0;
                }
                break;
            case "desktop.printer.pro.number":
                if (this.settings_printer_pro_number != null && this.settings_printer_pro_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_printer_pro_number
                    });
                } else {
                    this.settings_printer_pro_number = 0;
                }
                break;
            case "desktop.laptop.age":
                if (this.settings_laptop_age != null && this.settings_laptop_age >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_laptop_age
                    });
                } else {
                    this.settings_laptop_age = 0;
                }
                break;
            case "desktop.laptop.13.number":
                if (this.settings_laptop_13_number != null && this.settings_laptop_13_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_laptop_13_number
                    });
                } else {
                    this.settings_laptop_13_number = 0;
                }
                break;
            case "desktop.laptop.15.number":
                if (this.settings_laptop_15_number != null && this.settings_laptop_15_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_laptop_15_number
                    });
                } else {
                    this.settings_laptop_15_number = 0;
                }
                break;
            case "desktop.laptop.17.number":
                if (this.settings_laptop_17_number != null && this.settings_laptop_17_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_laptop_17_number
                    });
                } else {
                    this.settings_laptop_17_number = 0;
                }
                break;
            case "desktop.laptop.charger.number":
                if (this.settings_laptop_charger_number && this.settings_laptop_charger_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_laptop_charger_number
                    });
                } else {
                    this.settings_laptop_charger_number = 0;
                }
                break;
            case "desktop.desktop.age":
                if (this.settings_desktop_age != null && this.settings_desktop_age >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_desktop_age
                    });
                } else {
                    this.settings_desktop_age = 0;
                }
                break;
            case "desktop.desktop.number":
                if (this.settings_desktop_number != null && this.settings_desktop_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_desktop_number
                    });
                } else {
                    this.settings_desktop_number = 0;
                }
                break;
            case "desktop.keyboard.number":
                if (this.settings_keyboard_number != null && this.settings_keyboard_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_keyboard_number
                    });
                } else {
                    this.settings_keyboard_number = 0;
                }
                break;
            case "desktop.mouse.number":
                if (this.settings_mouse_number != null && this.settings_mouse_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_mouse_number
                    });
                } else {
                    this.settings_mouse_number = 0;
                }
                break;
            case "desktop.screen.17.number":
                if (this.settings_screen_17_number != null && this.settings_screen_17_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_screen_17_number
                    });
                } else {
                    this.settings_screen_17_number = 0;
                }
                break;
            case "desktop.screen.22.number":
                if (this.settings_screen_22_number != null && this.settings_screen_22_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_screen_22_number
                    });
                } else {
                    this.settings_screen_22_number = 0;
                }
                break;
            case "desktop.screen.27.number":
                if (this.settings_screen_27_number != null && this.settings_screen_27_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_screen_27_number
                    });
                } else {
                    this.settings_screen_27_number = 0;
                }
                break;
            case "desktop.router.number":
                if (this.settings_desktop_router_number != null && this.settings_desktop_router_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_desktop_router_number
                    });
                } else {
                    this.settings_desktop_router_number = 0;
                }
                break;
            case "desktop.network.other.number":
                if (this.settings_desktop_network_other_number != null && this
                    .settings_desktop_network_other_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_desktop_network_other_number
                    });
                } else {
                    this.settings_desktop_network_other_number = 0;
                }
                break;
            case "desktop.tablet.number":
                if (this.settings_tablet_number != null && this.settings_tablet_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_tablet_number
                    });
                } else {
                    this.settings_tablet_number = 0;
                }
                break;
            case "desktop.smartphone.number":
                if (this.settings_smartphone_number != null && this.settings_smartphone_number >= 0) {
                    Object.assign(this.settings_changed, {
                        [element.id]: this.settings_smartphone_number
                    });
                } else {
                    this.settings_smartphone_number = 0;
                }
                break;
                //
                // OPTIMIZATION
                //
            case "optimization.host":
                this.optimization_run = true;
                let settings_optimization_host_uuid: string = "";
                // Get host uuid from name
                for (let i in this.optimization_hosts_uuid) {
                    if (this.optimization_hosts_uuid[i][1] == this.settings_optimization_host) {
                        settings_optimization_host_uuid = this.optimization_hosts_uuid[i][0];
                        break;
                    }
                }
                Object.assign(this.settings_changed, {
                    [element.id]: settings_optimization_host_uuid
                });
                break;
                //
                // RENEWAL SERVER
                //
            case "renewal.ram":
                Object.assign(this.settings_changed, {
                    [element.id]: this.renewal_serverram
                });
                break;
            case "renewal.socket":
                Object.assign(this.settings_changed, {
                    [element.id]: this.renewal_serversocket
                });
                break;
            case "renewal.thread":
                Object.assign(this.settings_changed, {
                    [element.id]: this.renewal_serverthread
                });
                break;
            case "renewal.embodied":
                Object.assign(this.settings_changed, {
                    [element.id]: this.renewal_serverembodied
                });
                break;
            case "renewal.kwh":
                Object.assign(this.settings_changed, {
                    [element.id]: this.renewal_serverkWh
                });
                break;
        }

        // Update save button status
        this.updateSaveStatus();
    }

    /**
     * 
     */
    addNetworkStorageSettings(target: string): void {

        // Force to update view
        this.changeDetectorRef.detectChanges();

        this.extrapolation_alert = false;
        this.extrapolation_save = true;

        switch (target) {
            case "network.direct.ratio":
                if (this.settings_network_direct_ratio == null || this.settings_network_direct_ratio < 0 || this
                    .settings_network_direct_ratio > 100) {
                    this.settings_network_direct_ratio = 0;
                }
                break;
            case "storage.direct.ratio":
                if (this.settings_storage_direct_ratio == null || this.settings_storage_direct_ratio < 0 || this
                    .settings_storage_direct_ratio > 100) {
                    this.settings_storage_direct_ratio = 0;
                }
                break;

        }

        // Check values
        this.settings_host_direct_ratio = 100 - (this.settings_network_direct_ratio + this
            .settings_storage_direct_ratio);

        if (this.settings_host_direct_ratio > 0) {

            // Push settings
            Object.assign(this.settings_changed, {
                [NETWORK_DIRECT_RATIO]: this.settings_network_direct_ratio
            });
            Object.assign(this.settings_changed, {
                [STORAGE_DIRECT_RATIO]: this.settings_storage_direct_ratio
            });
            Object.assign(this.settings_changed, {
                [HOST_DIRECT_RATIO]: this.settings_host_direct_ratio
            });

            // Update pie
            this.displayPie();

        } else {
            this.extrapolation_alert = true;
            this.extrapolation_save = false;
        }

        // Update save button status
        this.updateSaveStatus();
    }

    /**
     *
     */
    checkServerIndicator(element: HTMLInputElement, device: any): void {
        this.checkIndicator(element, device, this.indicators_data, "server");
    }

    /**
     *
     */
    checkNetworkIndicator(element: HTMLInputElement, device: any): void {
        this.checkIndicator(element, device, this.indicators_network_data, "network");
    }

    /**
     *
     */
    checkStorageIndicator(element: HTMLInputElement, device: any): void {
        this.checkIndicator(element, device, this.indicators_network_data, "storage");
    }

    /**
     * 
     */
    private checkIndicator(element: HTMLInputElement, device: any, indicators: any, indicator_type: string): void {
        let valid_data: boolean = false;

        // Check value
        switch (element.id) {
            case "indicator.year":
            case "indicator.amortization":
                element.value = element.value.replace('.', '').replace(',', ''); // FIX replace decimal symbol
            case "indicator.co2":
            case "indicator.cost":
            case "indicator.embodied":
                if (element.valueAsNumber >= 0)
                    valid_data = true;
                break;
            default:
                if (element.valueAsNumber > 0)
                    valid_data = true;
                break;
        }

        if (valid_data) {
            // Device last update
            device.timeago = new Date().getTime();
        } else {
            element.valueAsNumber = 0;
        }

        // Check for all servers if data are valid
        this.checkIndicators(indicators, indicator_type);

        // Update save button status
        this.updateSaveStatus();

    }

    /**
     * 
     */
    private checkIndicators(data: any, indicator_type: string): void {
        let valid_data: boolean = true;

        for (let device of data) {
            if (device.year <= 0 || device.amortization < 0 || device.embodied < 0 || device.co2 < 0 || device
                .cost < 0 ||
                device.pue <= 0) {
                valid_data = false;
                break;
            }
        }

        // Update indicator save status
        switch (indicator_type) {
            case "server":
                this.indicator_save = valid_data;
                break;
            case "network":
                this.indicator_network_save = valid_data;
                break;
            case "storage":
                this.indicator_storage_save = valid_data;
                break;
            default:
                break;
        }
    }

    /**
     * 
     */
    applyServersIndicators(data: any) {
        this.indicator_save = true;
        this.applyIndicators(data, this.indicators_data, "server");
    }

    /**
     * 
     */
    applyNetworkIndicators(data: any) {
        this.indicator_network_save = true;
        this.applyIndicators(data, this.indicators_network_data, "network");
    }

    /**
     * 
     */
    applyStorageIndicators(data: any) {
        this.indicator_storage_save = true;
        this.applyIndicators(data, this.indicators_storage_data, "storage");
    }

    /**
     * 
     */
    private applyIndicators(data: any, indicators: any, indicator_type: string) {
        // update indicators settings
        for (let i in data) {
            if (data[i] != undefined) {
                let device: GreenitIndicators = indicators.find(sv => sv.identifier === data[i]
                    .identifier);
                if (device != undefined) {
                    if (data[i].year != undefined)
                        device.year = data[i].year;
                    if (data[i].amortization != undefined)
                        device.amortization = data[i].amortization;
                    if (data[i].embodied != undefined)
                        device.embodied = data[i].embodied;
                    if (data[i].co2 != undefined)
                        device.co2 = data[i].co2;
                    if (data[i].cost != undefined)
                        device.cost = data[i].cost;
                    if (data[i].pue != undefined)
                        device.pue = data[i].pue;
                    device.timeago = data[i].timeago;
                }
            }
        }

        // Check indicators for all
        this.checkIndicators(indicators, indicator_type);

        // Show details
        this.showIndicatorsDetails(true);

        // Update save button status
        this.updateSaveStatus();
    }

    /**
     * 
     */
    checkMinMaxSettings(element: HTMLInputElement): void {

        // Check min or max value
        if (element.valueAsNumber >= 0) {
            this.minmax_save = true;
        } else {
            element.valueAsNumber = 0;
            this.minmax_save = false;
        }

        // Check min max for all
        this.checkMinMax();

        // Update save button status
        this.updateSaveStatus();
    }

    /**
     * 
     */
    private checkMinMax(): void {
        for (let host of this.minmax_data) {
            if (host.min > host.max) {
                this.minmax_save = false;
                break;
            }
        }
    }

    /**
     * 
     */
    applyMinMax(data: any) {
        this.minmax_save = true;

        // update servers settings
        for (let i in data) {
            if (data[i] != undefined) {
                let server: GreenitMinmax = this.minmax_data.find(sv => sv.identifier === data[i].identifier);
                if (server != undefined) {
                    if (data[i].min != undefined)
                        server.min = data[i].min;
                    if (data[i].max != undefined)
                        server.max = data[i].max;
                }
            }
        }

        // Check min max for all
        this.checkMinMax();

        // Show details
        this.showMinMaxDetails(true);

        // Update save button status
        this.updateSaveStatus();
    }

    /**
     *
     */
    checkRenewalSettings(element: HTMLInputElement): void {
        let valid_data: boolean = false;

        // Check value
        switch (element.id) {
            case "renewal.ram":
                this.renewal_serverram = Number(element.value.replace('.', '').replace(',',
                    '')); // FIX replace decimal symbol
                break;
            case "renewal.socket":
                this.renewal_serversocket = Number(element.value.replace('.', '').replace(',',
                    '')); // FIX replace decimal symbol
                break;
            case "renewal.thread":
                this.renewal_serverthread = Number(element.value.replace('.', '').replace(',',
                    '')); // FIX replace decimal symbol
                break;
            default:
                break;
        }

        if (element.valueAsNumber >= 0)
            valid_data = true;

        if (!valid_data) {
            element.valueAsNumber = 0;
        } else {
            this.addSettings(element);

            // If RENEWAL_DEFAULT_NAME is choosen as optimisation host
            if (this.settings_optimization_host == RENEWAL_DEFAULT_NAME) {
                this.optimization_run = true;
            }

        }

        // Update save button status
        this.renewal_save = valid_data;

        // Update save button status
        this.updateSaveStatus();
    }

    /**
     * 
     */
    showIndicatorsDetails(show: boolean): void {
        this.show_indicators_details = show;
        if (!show)
            this.save_button = false;
    }

    /**
     * 
     */
    showMinMaxDetails(show: boolean): void {
        this.show_minmax_details = show;
    }

    /**
     * 
     */
    private updateSaveStatus(): void {
        let tmp_indicator = (this.indicator_save == true || this.indicator_save == undefined);
        let tmp_network_indicator = (this.indicator_network_save == true || this.indicator_network_save ==
            undefined);
        let tmp_storage_indicator = (this.indicator_storage_save == true || this.indicator_storage_save ==
            undefined);
        let tmp_minmax = (this.minmax_save == true || this.minmax_save == undefined);
        let tmp_settings = (this.settings_save == true || this.settings_save == undefined);
        let tmp_extrapolation = (this.extrapolation_save == true || this.extrapolation_save == undefined);
        let tmp_renewal = (this.renewal_save == true || this.renewal_save == undefined);
        this.save_button = (tmp_indicator && tmp_network_indicator && tmp_storage_indicator && tmp_minmax &&
            tmp_settings && tmp_extrapolation && tmp_renewal);
    }

    /**
     * 
     */
    saveSettings(): void {
        this.settings_modal = false;

        let settings_end: boolean = false;
        let minmax_end: boolean = false;
        let indicators_end: boolean = false;
        let indicators_network_end: boolean = false;
        let indicators_storage_end: boolean = false;

        // Show progress
        this.message.waiting = true;

        // Update date
        this.last_update = new Date().getTime();

        ////////////////
        // SAVE SETTINGS
        ////////////////
        if (Object.keys(this.settings_changed).length !== 0) {

            let json: JSON = JSON.parse(JSON.stringify(this.settings_changed));

            // Save settings
            this.greenit_svc.updateGreenitSettings(json).subscribe(
                success => {
                    // Update settings
                    this.greenit_svc.getGreenitSettings().subscribe(
                        data => {
                            this.updateDataSettings(data);

                            //Call optimization if needed
                            if (this.optimization_run) {
                                this.greenit_svc.runGreenit(this.currentUser.login, this.message
                                    .currentFilter).subscribe(
                                    success => {
                                        settings_end = true;
                                        this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                            indicators_network_end, indicators_storage_end);
                                    },
                                    error => {
                                        console.log(error);
                                        settings_end = true;
                                        this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                            indicators_network_end, indicators_storage_end);
                                    }
                                );
                            } else {
                                settings_end = true;
                                this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                    indicators_network_end, indicators_storage_end);
                            }

                        },
                        error => {
                            console.log(error);
                            settings_end = true;
                            this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                indicators_network_end, indicators_storage_end);
                        }
                    );
                },
                error => {
                    console.log(error);
                    settings_end = true;
                    this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                        indicators_storage_end);
                }
            );
        } else {
            settings_end = true;
            this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                indicators_storage_end);
        }

        ////////////////
        // SAVE MINMAX
        ////////////////
        if (this.minmax_save) {
            let json: JSON = JSON.parse(JSON.stringify(this.minmax_data));

            // Save min max
            this.greenit_svc.updateServersMinMax(json).subscribe(
                async success => {

                        // GET DC HOST MINMAX (async)
                        let dc_minmax_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login,
                            this.message.currentFilter, GreenitTarget.DC_HOST_MINMAX).toPromise();
                        this.db_serversminmax = < GreenitMinmax[] > dc_minmax_subscribe;

                        // GET KUBE NODE MINMAX (async)
                        let kube_minmax_subscribe = await this.greenit_svc.getGreenitData(this.currentUser
                            .login, this.message.currentFilter, GreenitTarget.KUBE_NODE_MINMAX).toPromise();
                        this.db_kubenodesminmax = < any[] > kube_minmax_subscribe;

                        minmax_end = true;
                        this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                            indicators_storage_end);
                    },
                    error => {
                        console.log(error);
                        minmax_end = true;
                        this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                            indicators_storage_end);
                    }
            );

        } else {
            minmax_end = true;
            this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                indicators_storage_end);
        }

        /////////////////////////////
        // SAVE INDICATORS (SERVERS)
        /////////////////////////////
        if (this.indicator_save) {
            let json: JSON = JSON.parse(JSON.stringify(this.indicators_data));

            // Save settings
            this.greenit_svc.updateGreenitIndicatorsServers(json).subscribe(
                success => {
                    // Update settings data
                    this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter,
                        GreenitTarget.SERVERS_SETTINGS).subscribe(
                        data => {
                            this.db_serversindicators = < GreenitIndicators[] > data;
                            indicators_end = true;
                            this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                indicators_network_end, indicators_storage_end);
                        },
                        error => {
                            console.log(error);
                            indicators_end = true;
                            this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                indicators_network_end, indicators_storage_end);
                        }
                    );
                },
                error => {
                    console.log(error);
                    indicators_end = true;
                    this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                        indicators_storage_end);
                }
            );

        } else {
            indicators_end = true;
            this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                indicators_storage_end);
        }

        /////////////////////////////
        // SAVE INDICATORS (NETWORK)
        /////////////////////////////
        if (this.indicator_network_save) {

            let json: JSON = JSON.parse(JSON.stringify(this.indicators_network_data));

            // Save settings
            this.greenit_svc.updateGreenitIndicatorsNetwork(json).subscribe(
                success => {
                    // Update settings data
                    this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter,
                        GreenitTarget.NETWORK_SETTINGS).subscribe(
                        data => {
                            this.db_networkindicators = < GreenitIndicators[] > data;
                            indicators_network_end = true;
                            this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                indicators_network_end, indicators_storage_end);
                        },
                        error => {
                            console.log(error);
                            indicators_network_end = true;
                            this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                indicators_network_end, indicators_storage_end);
                        }
                    );
                },
                error => {
                    console.log(error);
                    indicators_network_end = true;
                    this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                        indicators_storage_end);
                }
            );

        } else {
            indicators_network_end = true;
            this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                indicators_storage_end);
        }


        /////////////////////////////
        // SAVE INDICATORS (STORAGE)
        /////////////////////////////
        if (this.indicator_storage_save) {

            let json: JSON = JSON.parse(JSON.stringify(this.indicators_storage_data));

            // Save settings
            this.greenit_svc.updateGreenitIndicatorsStorage(json).subscribe(
                success => {
                    // Update settings data
                    this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter,
                        GreenitTarget.STORAGE_SETTINGS).subscribe(
                        data => {
                            this.db_storageindicators = < GreenitIndicators[] > data;
                            indicators_storage_end = true;
                            this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                indicators_network_end, indicators_storage_end);
                        },
                        error => {
                            console.log(error);
                            indicators_storage_end = true;
                            this.checkSaveWaiting(settings_end, minmax_end, indicators_end,
                                indicators_network_end, indicators_storage_end);
                        }
                    );
                },
                error => {
                    console.log(error);
                    indicators_storage_end = true;
                    this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                        indicators_storage_end);
                }
            );

        } else {
            indicators_storage_end = true;
            this.checkSaveWaiting(settings_end, minmax_end, indicators_end, indicators_network_end,
                indicators_storage_end);
        }
    }

    /**
     *
     */
    private checkSaveWaiting(settings_end: boolean, minmax_end: boolean, indicators_end: boolean,
        indicators_network_end: boolean, indicators_storage_end: boolean): void {
        this.message.waiting = !(settings_end && minmax_end && indicators_end && indicators_network_end &&
            indicators_storage_end);

        // Need to reload filter in order to reload optimisation & renewal data
        if (!this.message.waiting && this.optimization_run) {
            // Notify the parent to reload the filter
            this.pushReload();
        }
    }

    /**
     *
     */
    private updateDataSettings(data: any) {
        this.db_settings = data;

        // Update date
        let update_entry = this.db_settings.filter((e: {
            option: string;
        }) => e.option === "settings.update");
        if (update_entry[0])
            this.last_update = Number(update_entry[0].value);

        // Update history date
        let history_entry = this.db_settings.filter((e: {
            option: string;
        }) => e.option === "settings.history");
        if (history_entry[0])
            this.last_history = Number(history_entry[0].value);

        // Update history button
        let history_purge_entry = this.db_settings.filter((e: {
            option: string;
        }) => e.option === "settings.purge");
        if (history_purge_entry[0])
            this.history_disabled = Number(history_purge_entry[0].value) == 1;
    }

    /**
     * 
     */
    formatDate(time: number): string {
        if (time == 0)
            return "---";
        else
            return new Date(time).toLocaleDateString('en-EN');
    }

    /**
     * 
     */
    confirmClear(): void {
        this.history_modal = false;

        // Update date
        this.last_history = new Date().getTime();

        // Disable button
        this.history_disabled = true;

        this.greenit_svc.clearHistory().subscribe(
            data => {
                // TODO
            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );
    }

    /**
     * 
     */
    private exportIndicators(data: any, filename: string): void {
        let res = ["NAME", "DATE OF PURCHASE", "LIFESPAN (year)", "EMBODIED CARBON (kg CO2eq)",
            "ENERGY FACTOR EMISSION (kg CO2eq/kWh)", "ENERGY COST (per kWh)", "PUE"
        ].join(',') + '\n';
        res += Object.values < any > (data).map(device => [device.name, device.year, device.amortization,
            device.embodied, device.co2, device.cost, device.pue
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });

        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    /**
     * 
     */
    exportServersIndicators(): void {
        this.exportIndicators(this.indicators_data, "servers_indicators.csv");
    }

    /**
     * 
     */
    exportNetworkIndicators(): void {
        this.exportIndicators(this.indicators_network_data, "network_indicators.csv");
    }

    /**
     * 
     */
    exportStorageIndicators(): void {
        this.exportIndicators(this.indicators_storage_data, "storage_indicators.csv");
    }

    /**
     * 
     */
    exportMinMax(): void {
        let res = ["NAME", "MODEL", "CPU MODEL", "CPU #", "RAM CAPACITY (MB)", "POWER MIN (W)", "POWER MAX (W)"]
            .join(',') + '\n';
        res += Object.values < any > (this.minmax_data).map(host => [host.name, host.model, host.cpumodel, host
            .sockets, host.ramcap, host.min, host.max
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'servers_minmax.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    // FOR NETWORK DEVICES
    addNetworkDevice(): void {

        let connectorId: string = uuidv4();

        let devices: GreenItDevice[] = [];

        for (let i = 0; i < this.network_device.count; i++) {
            let net_device: GreenItDevice = {
                identifier: uuidv4(),
                name: this.network_device.name,
                model: this.network_device.model,
                manufacturer: this.network_device.manufacturer,
                type: this.network_device.type,
                power: this.network_device.power,
                metaType: 'network',
                connectorId: connectorId
            };
            this.network_devices.push(net_device);
            devices.push(net_device);
        }

        if (devices.length > 0) {
            this.management_svc.addNetworkDevice(devices).subscribe(
                () => {
                    this.initNetworkDevice(false);
                },
                (error: null) => {
                    if (error != null)
                        console.log(error);
                }
            );
        }
    }

    /**
     * 
     */
    validNewDevice(isNetwork: boolean): void {

        if (isNetwork) {
            if (this.network_device.name != "" && this.network_device.count > 0 && this.network_device.power > 0)
                this.network_device.isAdd = false
            else
                this.network_device.isAdd = true
        } else {
            if (this.storage_device.name != "" && this.storage_device.count > 0 && this.storage_device.power > 0)
                this.storage_device.isAdd = false
            else
                this.storage_device.isAdd = true
        }
    }

    /**
     * 
     */
    updateNetworkDevice(detail: any): void {

        let net_device: GreenItDevice = {
            identifier: detail.identifier,
            name: detail.name,
            model: detail.model,
            manufacturer: detail.manufacturer,
            type: detail.type,
            power: detail.power,
            metaType: 'network',
            connectorId: null
        };

        this.management_svc.updateGreenItDevice(net_device).pipe(first()).subscribe(
            (error: null) => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    /**
     * 
     */
    deleteNetworkDevice(detail: any): void {

        this.network_devices = this.network_devices.filter((item: {
            identifier: any;
        }) => item.identifier !== detail.identifier);

        let device: any = {
            identifier: detail.identifier
        };
        this.management_svc.deleteGreenItDevice(device).pipe(first()).subscribe(
            (error: null) => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    /**
     * 
     */
    private initNetworkDevice(isInit: boolean): void {
        this.network_device = {
            name: '',
            type: 'switch',
            count: 1,
            power: 0,
            isAdd: true,
            error: false,
            errorName: ''
        };

        if (isInit) {
            this.management_svc.getGreenItDevice('network').pipe(first()).subscribe(
                (data: any) => {
                    if (data.length > 0) {
                        for (let i in data) {
                            let net_device: GreenItDevice = {
                                identifier: data[i].identifier,
                                name: data[i].name,
                                model: data[i].model,
                                manufacturer: data[i].manufacturer,
                                type: data[i].type,
                                power: data[i].power,
                                metaType: 'network',
                                connectorId: null
                            };
                            this.network_devices.push(net_device);
                        }
                    }
                    this.isLoading = false;
                },
                (error: null) => {
                    this.isLoading = false;
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    // FOR STORAGE DEVICES
    addStorageDevice(): void {

        let connectorId: string = uuidv4();

        let devices: GreenItDevice[] = [];

        for (let i = 0; i < this.storage_device.count; i++) {
            let sto_device: GreenItDevice = {
                identifier: uuidv4(),
                name: this.storage_device.name,
                model: this.storage_device.model,
                manufacturer: this.storage_device.manufacturer,
                type: this.storage_device.type,
                power: this.storage_device.power,
                metaType: 'storage',
                connectorId: connectorId
            };
            this.storage_devices.push(sto_device);
            devices.push(sto_device);
        }

        if (devices.length > 0) {
            this.management_svc.addStorageDevice(devices).subscribe(
                () => {
                    this.initStorageDevice(false);
                },
                (error: null) => {
                    if (error != null)
                        console.log(error);
                }
            );
        }
    }

    /**
     * 
     */
    updateStorageDevice(detail: any): void {

        let sto_device: GreenItDevice = {
            identifier: detail.identifier,
            name: detail.name,
            model: detail.model,
            manufacturer: detail.manufacturer,
            type: detail.type,
            power: detail.power,
            metaType: 'storage',
            connectorId: null
        };

        this.management_svc.updateGreenItDevice(sto_device).pipe(first()).subscribe(
            (error: null) => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    /**
     * 
     */
    deleteStorageDevice(detail: any): void {

        this.storage_devices = this.storage_devices.filter((item: {
            identifier: any;
        }) => item.identifier !== detail.identifier);

        let device: any = {
            identifier: detail.identifier
        };
        this.management_svc.deleteGreenItDevice(device).pipe(first()).subscribe(
            (error: null) => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    /**
     * 
     */
    private initStorageDevice(isInit: boolean): void {
        this.storage_device = {
            name: '',
            type: 'san',
            count: 1,
            power: 0,
            isAdd: true,
            error: false,
            errorName: ''
        };

        if (isInit) {
            this.management_svc.getGreenItDevice('storage').pipe(first()).subscribe(
                (data: any) => {
                    if (data.length > 0) {
                        for (let i in data) {
                            let sto_device: GreenItDevice = {
                                identifier: data[i].identifier,
                                name: data[i].name,
                                model: data[i].model,
                                manufacturer: data[i].manufacturer,
                                type: data[i].type,
                                power: data[i].power,
                                metaType: 'storage',
                                connectorId: null
                            };
                            this.storage_devices.push(sto_device);
                        }
                    }
                    this.isLoading = false;
                },
                (error: null) => {
                    this.isLoading = false;
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    /**
     * 
     */
    private loadDeviceFromFile() {

        let metaType: string = 'network';
        if (this.settings_tab == "storage")
            metaType = 'storage';

        let connectorId: string = uuidv4();

        this.upload_svc.getDeviceFile().subscribe(
            (data: any) => {
                Papa.parse(data, {
                    header: true,
                    skipEmptyLines: true,
                    delimiter: ",",
                    complete: (results: ParseResult < CsvGreenItDevice > ) => {
                        let devices: GreenItDevice[] = [];
                        let res: any = results.data;
                        for (let i in res) {
                            let greenit_device: GreenItDevice = {
                                identifier: uuidv4(),
                                name: res[i].name,
                                model: res[i].model,
                                manufacturer: res[i].manufacturer,
                                type: res[i].type,
                                power: res[i].power,
                                metaType: metaType,
                                connectorId: connectorId
                            };
                            if (metaType == "network")
                                this.network_devices.push(greenit_device);
                            else
                                this.storage_devices.push(greenit_device);

                            devices.push(greenit_device);
                        }
                        // SAVE IN BD
                        if (devices.length > 0) {
                            if (metaType == "network") {
                                this.management_svc.addNetworkDevice(devices).subscribe(
                                    () => {
                                        this.isLoading = false;
                                        this.deleteDeviceFile();
                                    },
                                    (error: null) => {
                                        this.isLoading = false;
                                        if (error != null)
                                            console.log(error);
                                    }
                                );
                            } else {
                                this.management_svc.addStorageDevice(devices).subscribe(
                                    () => {
                                        this.isLoading = false;
                                        this.deleteDeviceFile();
                                    },
                                    (error: null) => {
                                        this.isLoading = false;
                                        if (error != null)
                                            console.log(error);
                                    }
                                );
                            }

                        }
                    }
                });
            },
            (error: any) => {
                this.isLoading = false;
            }
        );
    }

    /**
     * 
     */
    private deleteDeviceFile() {
        this.upload_svc.deleteDeviceFile().subscribe(
            (error: null) => {
                if (error != null)
                    console.log(error);
            }
        );
    }

    // FOR COMMON NODES
    initCommonNode(): void {
        let esx_i: any = {
            value: 'esx',
            desc: 'VMware ESX server'
        };
        this.cnodes_params.infra.push(esx_i);

        let vm_i: any = {
            value: 'vm',
            desc: 'VMware Virtual machine'
        };
        this.cnodes_params.infra.push(vm_i);

        let ome_i: any = {
            value: 'openmanage',
            desc: 'Openmanage server'
        };
        this.cnodes_params.infra.push(ome_i);

        let one_i: any = {
            value: 'oneview',
            desc: 'Oneview server'
        };
        this.cnodes_params.infra.push(one_i);

        let xclarity_i: any = {
            value: 'xclarity',
            desc: 'Xclarity server'
        };
        this.cnodes_params.infra.push(xclarity_i);

        let ipmi_i: any = {
            value: 'ipmi',
            desc: 'IPMI server'
        };
        this.cnodes_params.infra.push(ipmi_i);

        let kube_i: any = {
            value: 'kubernetes',
            desc: 'Kubernetes node'
        };
        this.cnodes_params.infra.push(kube_i);

        this.loadNodeList();
    }

    /**
     * 
     */
    loadNames(isNode1: boolean): void {
        let currentType: string = '';
        if (isNode1) {
            this.cnodes_params.nodes_1 = [];
            currentType = this.cnodes_model.type1;
        } else {
            this.cnodes_params.nodes_2 = [];
            currentType = this.cnodes_model.type2;
        }

        let nodeType: string = '';
        let query: string = '';
        switch (currentType) {
            case "esx":
                query = 'getesxservers';
                nodeType = 'SERVER';
                break;
            case "vm":
                query = 'getvms';
                nodeType = 'VM';
                break;
            case "xclarity":
                query = 'getxclaritynodes';
                nodeType = 'XCLARITY_NODE';
                break;
            case "openmanage":
                query = 'getopenmanagenodes';
                nodeType = 'OPENMANAGE_NODE';
                break;
            case "oneview":
                query = 'getoneviewnodes';
                nodeType = 'ONEVIEW_NODE';
                break;
            case "ipmi":
                query = 'getipminodes';
                nodeType = 'IPMI_NODE';
                break;
            case "kubernetes":
                query = 'getkubernetesnodes';
                nodeType = 'KUBE_NODE';
                break;
            default:
                break;
        }

        this.management_svc.getNodes("root", "all", query).pipe(first()).subscribe(
            (data: any) => {
                if (data.length > 0) {
                    for (let i in data) {
                        let node: any = {
                            name: data[i].name,
                            resourceType: nodeType,
                            uuid: data[i].uuid
                        };
                        if (isNode1)
                            this.cnodes_params.nodes_1.push(node);
                        else
                            this.cnodes_params.nodes_2.push(node);
                    }

                    if (isNode1) {
                        if (this.cnodes_params.nodes_1.length > 0) {
                            this.cnodes_model.name1 = this.cnodes_params.nodes_1[this.cnodes_params.nodes_1
                                .length - 1].name;
                            this.cnodes_model.id1 = this.getNode(isNode1, this.cnodes_model.name1).uuid;
                            this.cnodes_model.matype1 = this.getNode(isNode1, this.cnodes_model.name1)
                                .resourceType;
                        }
                    } else {
                        if (this.cnodes_params.nodes_2.length > 0) {
                            this.cnodes_model.name2 = this.cnodes_params.nodes_2[this.cnodes_params.nodes_2
                                .length - 1].name;
                            this.cnodes_model.id2 = this.getNode(isNode1, this.cnodes_model.name2).uuid;
                            this.cnodes_model.matype2 = this.getNode(isNode1, this.cnodes_model.name2)
                                .resourceType;
                        }
                    }

                    // CHECK VALID SELECTION
                    this.checkValidMa();
                }
            },
            (error: any) => {
                this.cnodes_params.isReady = false;
            }
        );
    }

    /**
     * 
     */
    updateModel(isNode1: boolean): void {
        if (isNode1) {
            if (this.cnodes_params.nodes_1.length > 0) {
                this.cnodes_model.id1 = this.getNode(isNode1, this.cnodes_model.name1).uuid;
                this.cnodes_model.matype1 = this.getNode(isNode1, this.cnodes_model.name1).resourceType;
            }
        } else {
            if (this.cnodes_params.nodes_2.length > 0) {
                this.cnodes_model.id2 = this.getNode(isNode1, this.cnodes_model.name2).uuid;
                this.cnodes_model.matype2 = this.getNode(isNode1, this.cnodes_model.name2).resourceType;
            }
        }

        // CHECK VALID SELECTION
        this.checkValidMa();

    }

    /**
     * 
     */
    updateCommonNode(): void {
        this.management_svc.addMachineAssociation(this.cnodes_model).subscribe(
            () => {
                this.cnodes_params.isReady = false;
            },
            (error: null) => {
                this.cnodes_params.isReady = false;
                this.cnodes_model.error = true;
                this.cnodes_model.errorMsg = 'The node association already recorded ...';
                if (error != null)
                    console.log(error);
            }
        );
    }

    /**
     * 
     */
    loadNodeList(): void {
        this.cnodes_model.id1 = '';
        this.cnodes_model.id2 = '';
        this.cnodes_model.name1 = '';
        this.cnodes_model.name2 = '';
        this.cnodes_model.type1 = 'esx';
        this.cnodes_model.type2 = '';
        this.cnodes_model.matype1 = '';
        this.cnodes_model.matype2 = '';
        this.cnodes_model.error = false;
        this.cnodes_model.errorMsg = '';

        this.management_svc.getMachineAssociation().subscribe(
            (data: any) => {
                this.cnodes_params.mas = data;
            },
            (error: null) => {
                this.cnodes_params.mas = [];
                if (error != null)
                    console.log(error);
            }
        );
    }

    /**
     * 
     */
    deleteCommonNode(ma: any): void {
        this.management_svc.deleteMachineAssociation(ma).subscribe(
            () => {
                this.loadNodeList();
            },
            (error: null) => {
                if (error != null)
                    console.log(error);
            }
        );
    }

    /**
     * 
     */
    private checkValidMa(): void {
        if (this.cnodes_params.nodes_2.length > 0) {
            if (this.cnodes_model.name2 != "") {
                if (this.cnodes_model.id1 != this.cnodes_model.id2) {
                    this.cnodes_params.isReady = true;
                    this.cnodes_model.error = false;
                    this.cnodes_model.errorMsg = '';
                } else {
                    this.cnodes_params.isReady = false;
                    this.cnodes_model.error = true;
                    this.cnodes_model.errorMsg = 'Node1 and Node2 must be different.';
                }
            } else {
                this.cnodes_params.isReady = false;
            }
        } else {
            this.cnodes_params.isReady = false;
        }
    }

    /**
     * 
     */
    private getNode(isNode1: boolean, name: string): any {
        if (isNode1)
            return this.cnodes_params.nodes_1.find((node: {
                name: string;uuid: string,
                resourceType: string
            }) => node.name === name);
        else
            return this.cnodes_params.nodes_2.find((node: {
                name: string;uuid: string,
                resourceType: string
            }) => node.name === name);
    }

}
