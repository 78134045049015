import {
    Component,
    ChangeDetectorRef,
    OnInit
} from '@angular/core';
import {
    Router
} from '@angular/router';

import {
    AccountService,
    JsonloaderService,
    SettingsService,
    ShareService
} from '@app/services';

import {
    Json,
    Message
} from '@app/model';
import * as $ from 'jquery';

@Component({
    selector: 'app-t4',
    templateUrl: './t4.component.html',
    styleUrls: ['./t4.component.css']
})
export class T4Component implements OnInit {

    jsonLoader: Json;

    data_t4: any = [];

    message: Message;

    isDashboardView: boolean = false;

    cpu_class: string = 'progress success';
    cpu_prov_class: string = 'badge-info';
    cpu_cons: number = 0;
    cpu_used: number = 0;
    cpu_free: number = 0;
    cpu_total: number = 0;
    cpu_commit: number = 0;
    cpu_commitper: number = 0;

    ram_class: string = 'progress success';
    ram_prov_class: string = 'badge-info';
    ram_cons: number = 0;
    ram_used: number = 0;
    ram_free: number = 0;
    ram_total: number = 0;
    ram_commit: number = 0;
    ram_commitper: number = 0;
    ram_unit: string = '';

    sto_class: string = 'progress success';
    sto_prov_class: string = 'badge-info';
    sto_cons: number = 0;
    sto_used: number = 0;
    sto_free: number = 0;
    sto_total: number = 0;
    sto_commit: number = 0;
    sto_commitper: number = 0;
    sto_unit: string = '';

    isReady: boolean = false;

    private currentUser;


    constructor(
        private json_svc: JsonloaderService,
        private router: Router,
        private authentication_svc: AccountService,
        private management_svc: SettingsService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.json_svc.currentJson.subscribe(
            json => {
                this.jsonLoader = json;
                if (this.jsonLoader.t4 != null) {
                    this.data_t4 = this.jsonLoader.t4;
                } else {
                    this.data_t4 = [];
                }
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        if (this.router.url != "/clusterviews/synthesis") {
            if (Object.keys(this.data_t4).length > 0)
                this.buildDataDatacenter();
            this.isDashboardView = true;
        } else {
            if (this.message.clusterSynth != null)
                this.buildDataCluster();
        }
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    resizeCard(): void {
        $('#dashboard-t4-core').slideToggle();
    }

    closeCard(): void {

        if (this.router.url != "/clusterviews/synthesis") {
            this.management_svc.updateSettings(this.currentUser.login, 'isT4', false).subscribe(
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
            $('#dashboard-t4').hide();
        } else {
            $('#cluster-t4').hide();
        }
    }

    private buildCard(entity): void {

        this.cpu_cons = +entity.avgcpu;
        this.cpu_used = +entity.avgcpu_ghz;
        this.cpu_free = +entity.free_ghz;
        this.cpu_total = +entity.cap_ghz;
        this.cpu_commitper = +entity.cpuprov;
        this.cpu_commit = +entity.cpuprov_ghz;
        if (this.cpu_commitper >= 70)
            this.cpu_prov_class = 'badge-warning';
        if (this.cpu_commitper >= 90)
            this.cpu_prov_class = 'badge-danger';

        this.ram_cons = +entity.avgram;
        this.ram_used = +entity.avgram_xo;
        this.ram_free = +entity.freeram_xo;
        this.ram_total = +entity.capram_xo;
        this.ram_commitper = +entity.ramprov;
        this.ram_commit = +entity.ramprov_xo;
        this.ram_unit = entity.ram_unit;
        if (this.ram_commitper >= 70)
            this.ram_prov_class = 'badge-warning';
        if (this.ram_commitper >= 90)
            this.ram_prov_class = 'badge-danger';

        this.sto_cons = +entity.avgsto;
        this.sto_used = +entity.avgsto_xo;
        this.sto_free = +entity.freesto_xo;
        this.sto_total = +entity.capsto_xo;
        this.sto_commitper = +entity.stoprov;
        this.sto_commit = +entity.stoprov_xo;
        this.sto_unit = entity.sto_unit;
        if (this.sto_commitper >= 70)
            this.sto_prov_class = 'badge-warning';
        if (this.sto_commitper >= 90)
            this.sto_prov_class = 'badge-danger';

        this.isReady = true;
    }

    private buildDataCluster(): void {

        var mincpu = 0,
            avgcpu = 0,
            maxcpu = 0,
            avgcpu_ghz = 0,
            maxcpu_ghz = 0,
            cap_ghz = 0,
            free_ghz = 0;
        var minram = 0,
            avgram = 0,
            maxram = 0,
            avgram_xo = 0,
            maxram_xo = 0,
            capram_xo = 0,
            freeram_xo = 0;
        var minsto = 0,
            avgsto = 0,
            maxsto = 0,
            avgsto_xo = 0,
            maxsto_xo = 0,
            capsto_xo = 0,
            freesto_xo = 0;
        var stoprov = 0,
            stoprov_dsp = 0,
            stoprov_xo = 0;
        var ramprov = 0,
            ramprov_dsp = 0,
            ramprov_xo = 0;
        var cpuprov = 0,
            cpuprov_dsp = 0,
            cpuprov_ghz = 0;
        var sto_unit = 'GB',
            ram_unit = 'GB',
            stoprov_unit = 'GB',
            ramprov_unit = 'GB';
        // CPU
        cap_ghz = Math.round(this.message.clusterSynth.cpucapghz / 1000);
        avgcpu_ghz = Math.round(this.message.clusterSynth.cpuConsAvg / 1000);
        maxcpu_ghz = Math.round(this.message.clusterSynth.cpuConsMax / 1000);
        free_ghz = cap_ghz - avgcpu_ghz;
        cpuprov = Math.round(this.message.clusterSynth.cpuprov_ghz * 100 / this.message.clusterSynth.cpucapghz);
        cpuprov_ghz = Math.round(this.message.clusterSynth.cpuprov_ghz / 1000);
        avgcpu = Math.round(this.message.clusterSynth.cpuConsAvg * 100 / this.message.clusterSynth.cpucapghz);
        maxcpu = Math.round(this.message.clusterSynth.cpuConsMax * 100 / this.message.clusterSynth.cpucapghz);
        // RAM
        ramprov = Math.round(this.message.clusterSynth.rams * 100 / this.message.clusterSynth.ramcap);
        avgram_xo = Math.round(this.message.clusterSynth.ramConsAvg / 1024);
        maxram_xo = Math.round(this.message.clusterSynth.ramConsMax / 1024);
        capram_xo = Math.round(this.message.clusterSynth.ramcap / 1024);
        freeram_xo = capram_xo - avgram_xo;
        ramprov_xo = Math.round(this.message.clusterSynth.rams / 1024);
        if (avgram_xo > 1024) {
            ram_unit = 'TB';
            avgram_xo = Math.round(avgram_xo / 1024);
            maxram_xo = Math.round(maxram_xo / 1024);
            capram_xo = Math.round(capram_xo / 1024);
            freeram_xo = capram_xo - avgram_xo;
            if (ramprov_xo > 10240) {
                ramprov_unit = 'TB';
                ramprov_xo = Math.round(ramprov_xo / 1024);
            }
        }
        avgram = Math.round(this.message.clusterSynth.ramConsAvg * 100 / this.message.clusterSynth.ramcap)
        maxram = Math.round(this.message.clusterSynth.ramConsMax * 100 / this.message.clusterSynth.ramcap)
        // STORAGE
        stoprov = Math.round(this.message.clusterSynth.sto_prov * 1024 * 100 / this.message.clusterSynth.stocap);
        capsto_xo = Math.round(this.message.clusterSynth.stocap / 1024);
        avgsto_xo = Math.round(this.message.clusterSynth.stoConsAvg / 1024);
        maxsto_xo = Math.round(this.message.clusterSynth.stoConsMax / 1024);
        freesto_xo = capsto_xo - avgsto_xo;
        // stoprov in GB
        stoprov_xo = this.message.clusterSynth.sto_prov;
        if (avgsto_xo > 1024) {
            sto_unit = 'TB';
            avgsto_xo = Math.round(avgsto_xo / 1024);
            maxsto_xo = Math.round(maxsto_xo / 1024);
            capsto_xo = Math.round(capsto_xo / 1024);
            freesto_xo = capsto_xo - avgsto_xo;
            if (stoprov_xo > 10240) {
                stoprov_unit = 'TB';
                stoprov_xo = Math.round(stoprov_xo / 1024);
            }
        }
        avgsto = Math.round(this.message.clusterSynth.stoConsAvg * 100 / this.message.clusterSynth.stocap);
        maxsto = Math.round(this.message.clusterSynth.stoConsMax * 100 / this.message.clusterSynth.stocap);

        var capacity_reservation_start = 0;
        var cluster = {
            locate: 't4-graph',
            type: 'HOST',
            height: 220,
            x: 60,
            //title: 'Cluster resources',
            avgcpu_ghz: avgcpu_ghz,
            avgcpu: avgcpu,
            avgram_xo: avgram_xo,
            avgram: avgram,
            free_ghz: free_ghz,
            cap_ghz: cap_ghz,
            freeram_xo: freeram_xo,
            ram_unit: ram_unit,
            capram_xo: capram_xo,
            freesto_xo: freesto_xo,
            sto_unit: sto_unit,
            capsto_xo: capsto_xo,
            avgsto_xo: avgsto_xo,
            avgsto: avgsto,
            maxcpu: maxcpu,
            maxram: maxram,
            maxsto: maxsto,
            cpuprov_ghz: cpuprov_ghz,
            stoprov_xo: stoprov_xo,
            stoprov_unit: stoprov_unit,
            ramprov_xo: ramprov_xo,
            ramprov_unit: ramprov_unit,
            cpuprov: cpuprov,
            cpuprov_dsp: cpuprov_dsp,
            ramprov: ramprov,
            ramprov_dsp: ramprov_dsp,
            stoprov: stoprov,
            stoprov_dsp: stoprov_dsp,
            reservation: capacity_reservation_start
        }

        this.buildCard(cluster);
    }

    private buildDataDatacenter(): void {

        var mincpu = 0,
            avgcpu = 0,
            maxcpu = 0,
            avgcpu_ghz = 0,
            maxcpu_ghz = 0,
            cap_ghz = 0,
            free_ghz = 0;
        var minram = 0,
            avgram = 0,
            maxram = 0,
            avgram_xo = 0,
            maxram_xo = 0,
            capram_xo = 0,
            freeram_xo = 0;
        var minsto = 0,
            avgsto = 0,
            maxsto = 0,
            avgsto_xo = 0,
            maxsto_xo = 0,
            capsto_xo = 0,
            freesto_xo = 0;
        var stoprov = 0,
            stoprov_dsp = 0,
            stoprov_xo = 0;
        var ramprov = 0,
            ramprov_dsp = 0,
            ramprov_xo = 0;
        var cpuprov = 0,
            cpuprov_dsp = 0,
            cpuprov_ghz = 0;
        var sto_unit = 'GB',
            ram_unit = 'GB',
            stoprov_unit = 'GB',
            ramprov_unit = 'GB';
        var capacity_reservation_start = 100;
        for (var i in this.data_t4) {
            if (this.data_t4[i][2] < 0) {
                this.data_t4[i][2] = 0;
            }
            switch (this.data_t4[i][0]) {
                case "reservation":
                    var res = this.data_t4[i][1];
                    if (res < 0) {
                        res = 0;
                    }
                    capacity_reservation_start = 100 - res;
                    break
                case "dc-cpu":
                    mincpu = Math.round(this.data_t4[i][2] * 100 / this.data_t4[i][1]);
                    maxcpu = Math.round(this.data_t4[i][3] * 100 / this.data_t4[i][1]);
                    avgcpu = Math.round(this.data_t4[i][4] * 100 / this.data_t4[i][1]);
                    cpuprov = Math.round(this.data_t4[i][5] * 100 / this.data_t4[i][1]);
                    avgcpu_ghz = Math.round(this.data_t4[i][4] / 1000);
                    maxcpu_ghz = Math.round(this.data_t4[i][3] / 1000);
                    cap_ghz = Math.round(this.data_t4[i][1] / 1000);
                    cpuprov_ghz = Math.round(this.data_t4[i][5] / 1000);
                    free_ghz = cap_ghz - avgcpu_ghz;
                    break;
                case "dc-ram":
                    minram = Math.round(this.data_t4[i][2] * 100 / this.data_t4[i][1]);
                    maxram = Math.round(this.data_t4[i][3] * 100 / this.data_t4[i][1]);
                    avgram = Math.round(this.data_t4[i][4] * 100 / this.data_t4[i][1]);
                    ramprov = Math.round(this.data_t4[i][5] * 100 / this.data_t4[i][1]);
                    avgram_xo = Math.round(this.data_t4[i][4] / 1024);
                    maxram_xo = Math.round(this.data_t4[i][3] / 1024);
                    capram_xo = Math.round(this.data_t4[i][1] / 1024);
                    ramprov_xo = Math.round(this.data_t4[i][5] / 1024);
                    freeram_xo = capram_xo - avgram_xo;
                    if (avgram_xo > 1024) {
                        ram_unit = 'TB';
                        avgram_xo = Math.round(avgram_xo / 1024);
                        maxram_xo = Math.round(maxram_xo / 1024);
                        capram_xo = Math.round(capram_xo / 1024);
                        freeram_xo = capram_xo - avgram_xo;
                        if (ramprov_xo > 1024) {
                            ramprov_unit = 'TB';
                            ramprov_xo = Math.round(ramprov_xo / 1024);
                        }
                    }
                    break;
                case "dc-sto":
                    minsto = Math.round(this.data_t4[i][2] * 100 / this.data_t4[i][1]);
                    maxsto = Math.round(this.data_t4[i][3] * 100 / this.data_t4[i][1]);
                    avgsto = Math.round(this.data_t4[i][4] * 100 / this.data_t4[i][1]);
                    stoprov = Math.round(this.data_t4[i][5] * 100 / this.data_t4[i][1]);
                    avgsto_xo = Math.round(this.data_t4[i][4] / 1024);
                    maxsto_xo = Math.round(this.data_t4[i][3] / 1024);
                    capsto_xo = Math.round(this.data_t4[i][1] / 1024);
                    stoprov_xo = Math.round(this.data_t4[i][5] / 1024);
                    freesto_xo = capsto_xo - avgsto_xo;
                    if (avgsto_xo > 1024) {
                        sto_unit = 'TB';
                        avgsto_xo = Math.round(avgsto_xo / 1024);
                        maxsto_xo = Math.round(maxsto_xo / 1024);
                        capsto_xo = Math.round(capsto_xo / 1024);
                        if (stoprov_xo > 1024) {
                            stoprov_unit = 'TB';
                            stoprov_xo = Math.round(stoprov_xo / 1024);
                        }
                        freesto_xo = capsto_xo - avgsto_xo;
                    }
                    break;
                default:
                    break;
            }
        }
        var server = {
            locate: 't4-graph',
            type: 'HOST',
            height: 220,
            x: 60,
            //title: 'Datacenter resources',
            avgcpu_ghz: avgcpu_ghz,
            avgcpu: avgcpu,
            avgram_xo: avgram_xo,
            avgram: avgram,
            free_ghz: free_ghz,
            cap_ghz: cap_ghz,
            freeram_xo: freeram_xo,
            ram_unit: ram_unit,
            capram_xo: capram_xo,
            freesto_xo: freesto_xo,
            sto_unit: sto_unit,
            capsto_xo: capsto_xo,
            avgsto_xo: avgsto_xo,
            avgsto: avgsto,
            maxcpu: maxcpu,
            maxram: maxram,
            maxsto: maxsto,
            cpuprov_ghz: cpuprov_ghz,
            stoprov_xo: stoprov_xo,
            stoprov_unit: stoprov_unit,
            ramprov_xo: ramprov_xo,
            ramprov_unit: ramprov_unit,
            cpuprov: cpuprov,
            cpuprov_dsp: cpuprov_dsp,
            ramprov: ramprov,
            ramprov_dsp: ramprov_dsp,
            stoprov: stoprov,
            stoprov_dsp: stoprov_dsp,
            reservation: capacity_reservation_start
        }

        this.buildCard(server);
    }
}
