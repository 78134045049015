import {
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    DataService,
    GreenitService,
    ShareService
} from '@app/services';

import {
    FilterMgt,
    Message
} from '@app/model';

import * as Highcharts from 'highcharts';

import heatmap from 'highcharts/modules/heatmap';
heatmap(Highcharts);

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';


@Component({
    selector: 'app-yearlycal',
    templateUrl: './yearlycal.component.html',
    styleUrls: ['./yearlycal.component.css']
})
export class YearlycalComponent implements OnInit {

    message: Message;

    yearly_range_date: string = '';

    isNoPrevData: boolean = false;

    isNoNextData: boolean = true;

    isMinMaxValue: boolean = true;

    maxEndTime: number = 0;

    data_cache: any;

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    isReady: boolean = false;


    constructor(
        private counter_svc: DataService,
        private greenit_svc: GreenitService,
        private message_svc: ShareService) {}

    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    ngAfterViewInit(): void {

        this.maxEndTime = this.message.maxTimeFilter / 1000;
        this.callYearlyApi();
    }

    previous(): void {

        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const lastDayPreviousYear = moment.unix(yearLastDay).subtract(1, 'year').endOf('year');
        // CONVERT STRING TO NUMBER
        var y: number = +(lastDayPreviousYear.valueOf() / 1000).toFixed();
        this.maxEndTime = y;
        this.callYearlyApi();
        this.isNoNextData = false;
    }

    next(): void {

        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const lastDayNextYear = moment.unix(yearLastDay).add(1, 'year').endOf('year');
        var y: number = +(lastDayNextYear.valueOf() / 1000).toFixed();
        this.maxEndTime = y;
        if (this.maxEndTime < (this.message.maxTimeFilter / 1000)) {
            this.callYearlyApi();
        } else if (this.maxEndTime = (this.message.maxTimeFilter / 1000)) {
            this.callYearlyApi();
            this.isNoNextData = true;
        } else {
            this.isNoNextData = true;
        }
        this.isNoPrevData = false;
    }

    updateYearlyOptions(event): void {

        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const firstDay = moment.unix(yearLastDay).startOf('year');
        if (this.data_cache.length > 0) {
            if (event.target.id == "yearly-radio-100")
                this.loadCalendar(this.data_cache, false, firstDay);
            else
                this.loadCalendar(this.data_cache, true, firstDay);
        }
    }

    private callYearlyApi(): void {

        this.message.waiting = true;
        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const lastDay = moment.unix(yearLastDay).add(1, 'seconds');
        const firstDay = moment.unix(yearLastDay).startOf('year');
        let start: number = 0;
        let end: number = 0;

        if (firstDay.valueOf() < this.message.minTimeFilter) {
            start = this.message.minTimeFilter / 1000;
            this.isNoPrevData = true;
        } else {
            start = firstDay.valueOf() / 1000
        }

        if (lastDay.valueOf() > this.message.maxTimeFilter)
            end = this.message.maxTimeFilter / 1000;
        else
            end = lastDay.valueOf() / 1000;

        if (start > 0 && end > 0) {
            if (this.message.powerUsageEnv == "vmware") {
                let filter: FilterMgt = this.getFilter(this.message.currentFilter);
                let wd: boolean = false;
                if (filter.work_days == 1)
                    wd = true;

                if (this.message.calcons_counter == "threshold") {
                    let th: number = +this.message.calcons_rollup;
                    this.counter_svc.getDataThreshold(
                        this.message.currentUuid,
                        th,
                        start,
                        end,
                        this.message.currentType.toLowerCase()
                    ).pipe(first()).subscribe(
                        data => {
                            this.setRangeDate();
                            this.data_cache = data;
                            this.loadCalendar(data, false, firstDay);
                            this.message.waiting = false;
                        },
                        error => {
                            console.log(error);
                            this.message.waiting = false;
                        }
                    );
                } else {
                    this.counter_svc.getData(
                        this.message.currentUuid,
                        start,
                        end,
                        this.message.calcons_counter,
                        this.message.calcons_rollup,
                        this.message.currentType,
                        wd).pipe(first()).subscribe(
                        data => {
                            this.data_cache = data;
                            this.setRangeDate();
                            this.loadCalendar(data, this.isMinMaxValue, firstDay);
                            this.message.waiting = false;
                        },
                        error => {
                            console.log(error);
                            this.message.waiting = false;
                        }
                    );
                }
            } else {
                let elements: any = [];
                let element: any = {
                    uuid: this.message.currentUuid,
                    type: this.message.currentType
                };
                elements.push(element);

                let powerInfo: any = {
                    start: firstDay.valueOf(),
                    end: lastDay.valueOf(),
                    granularity: 'HOURLY',
                    data: elements
                };

                let query: any = '';
                switch (this.message.powerUsageEnv) {
                    case "kubernetes":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpowerkubenode');
                        break;
                    case "xclarity":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpowerxclarity');
                        break;
                    case "openmanage":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpoweropenmanagenode');
                        break;
                    case "oneview":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpoweroneviewnode');
                        break;
                    case "ipmi":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpoweripminode');
                        break;
                    case "aws":
                    case "azure":
                    case "gcp":
                        powerInfo.account = this.message.powerUsageEnv;
                        let uuids: string[] = [];
                        uuids.push(this.message.currentUuid);
                        powerInfo.data = uuids;
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpowerregion');
                        break;
                    default:
                        break;
                }

                if (query != "") {
                    query.subscribe(
                        data => {
                            let values: any = [];
                            for (let i in data) {
                                let val: any = {
                                    time: data[i].TIMEAGO / 1000,
                                    avg_power_float: data[i].POWER
                                };
                                values.push(val);
                            }
                            this.setRangeDate();
                            this.data_cache = values;
                            this.loadCalendar(values, this.isMinMaxValue, firstDay);
                            this.message.waiting = false;
                        },
                        error => {
                            this.message.waiting = false;
                            if (error != null)
                                console.log(error);
                        }
                    );
                }
            }
        }
    }

    private setRangeDate(): void {

        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const lastDay = moment.unix(yearLastDay).add(0, 'seconds')
        const firstDay = moment.unix(yearLastDay).startOf('year')

        let dateFormatPattern1 = 'MMMM Do'
        let dateFormatPattern2 = dateFormatPattern1 + ' YYYY'
        if (firstDay.year() != lastDay.year()) {
            dateFormatPattern1 += ' YYYY'
        }
        this.yearly_range_date = firstDay.format(dateFormatPattern1) + ' - ' + lastDay.format(dateFormatPattern2);
    }

    private loadCalendar(data, isMaxVal, startOfYear): void {

        var maxVal = 100;
        var v = {}
        var tmp = 0;
        var endFilter = this.maxEndTime;

        let cpucap: number = 0;
        if (this.message.currentType == "VM")
            cpucap = this.message.vmSynth.vcpu;
        else if (this.message.currentType == "SERVER")
            cpucap = this.message.hostSynth.cpucap;
        else if (this.message.currentType == "CLUSTER")
            cpucap = this.message.clusterSynth.cpucap;

        let unit: string = '%';
        data.forEach(measurement => {
            let timestamp = parseInt(measurement.time);
            if (!v[timestamp]) {
                v[timestamp] = []
            }
            switch (this.message.calcons_counter) {
                case "cpu_usage":
                    switch (this.message.calcons_rollup) {
                        case "avg":
                            v[timestamp].push(measurement.avg_cpu_usage / 10);
                            break;
                        case "max":
                            v[timestamp].push(measurement.max_cpu_usage / 10);
                            break;
                        case "min":
                            v[timestamp].push(measurement.min_cpu_usage / 10);
                            break;
                        default:
                            break;
                    }
                    break;
                case "ram_usage":
                    switch (this.message.calcons_rollup) {
                        case "avg":
                            v[timestamp].push(measurement.avg_ram_usage / 10);
                            break;
                        case "max":
                            v[timestamp].push(measurement.max_ram_usage / 10);
                            break;
                        case "min":
                            v[timestamp].push(measurement.min_ram_usage / 10);
                            break;
                        default:
                            break;
                    }
                    break;
                case "cpu_ready":
                    switch (this.message.calcons_rollup) {
                        case "avg":
                            if (cpucap > 0)
                                v[timestamp].push(measurement.avg_cpu_ready / 200 / cpucap);
                            break;
                        case "max":
                            if (cpucap > 0)
                                v[timestamp].push(measurement.max_cpu_ready / 200 / cpucap);
                            break;
                        case "min":
                            if (cpucap > 0)
                                v[timestamp].push(measurement.min_cpu_ready / 200 / cpucap);
                            break;
                        default:
                            break;
                    }
                    break;
                case "power_float":
                    unit = 'W';
                    switch (this.message.calcons_rollup) {
                        case "avg":
                            v[timestamp].push(measurement.avg_power_float);
                            break;
                        default:
                            break;
                    }
                    break;
                case "threshold":
                    unit = 'pts';
                    v[timestamp].push(measurement.pts);
                    maxVal = Math.max(maxVal, measurement.pts);
                    break;
                default:
                    break;
            }
            if (isMaxVal) {
                switch (this.message.calcons_counter) {
                    case "cpu_usage":
                        switch (this.message.calcons_rollup) {
                            case "avg":
                                tmp = Math.max(tmp, measurement.avg_cpu_usage);
                                break;
                            case "max":
                                tmp = Math.max(tmp, measurement.max_cpu_usage);
                                break;
                            case "min":
                                tmp = Math.max(tmp, measurement.min_cpu_usage);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "ram_usage":
                        switch (this.message.calcons_rollup) {
                            case "avg":
                                tmp = Math.max(tmp, measurement.avg_ram_usage);
                                break;
                            case "max":
                                tmp = Math.max(tmp, measurement.max_ram_usage);
                                break;
                            case "min":
                                tmp = Math.max(tmp, measurement.min_ram_usage);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "cpu_ready":
                        switch (this.message.calcons_rollup) {
                            case "avg":
                                tmp = Math.max(tmp, measurement.avg_cpu_ready);
                                break;
                            case "max":
                                tmp = Math.max(tmp, measurement.max_cpu_ready);
                                break;
                            case "min":
                                tmp = Math.max(tmp, measurement.min_cpu_ready);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "power_float":
                        unit = 'W';
                        switch (this.message.calcons_rollup) {
                            case "avg":
                                tmp = Math.max(tmp, measurement.avg_power_float);
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
        });
        if (isMaxVal) {
            if (this.message.calcons_counter == "cpu_ready") {
                if (cpucap > 0)
                    maxVal = Math.round(tmp / 200 / cpucap);
            } else if (this.message.calcons_counter == "power_float") {
                maxVal = Math.round(tmp);
            } else {
                maxVal = Math.round(tmp / 10);
            }
        }

        let datas = [];
        Object.keys(v).forEach(k => {
            let avg = v[k].reduce((a, b) => a + b, 0) / v[k].length
            var y: number = +(k);
            let d = moment.unix(y)
            let hour = d.hour()
            let yearDay = d.dayOfYear()
            var time_y = startOfYear.valueOf() + (86400000 * (yearDay - 1))
            if (hour == 0) {
                hour = 24;
                time_y = startOfYear.valueOf() + (86400000 * (yearDay - 2))
            }
            datas.push([hour, time_y, avg])
        });

        let metric_str = this.message.calcons_counter;
        if (this.message.calcons_counter == "power_float")
            metric_str = 'power';

        this.chartOptions = {
            chart: {
                type: 'heatmap',
                marginTop: 40,
                marginBottom: 10,
                plotBorderWidth: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            xAxis: {
                title: {
                    text: 'hours of the day',
                    align: 'middle'
                },
                opposite: true,
                labels: {
                    formatter: function() {
                        return '<span class="h5">' + this.value + 'h</span>'
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'days',
                },
                type: 'datetime',
                labels: {
                    formatter: function() {
                        let dateStr;
                        let date = moment.unix(this.value / 1000);
                        if (this.isFirst || this.isLast || date.date() == 1)
                            dateStr = date.format("MMM D");
                        else
                            dateStr = date.format("D");

                        return dateStr;
                    }
                },
                tickInterval: 24 * 60 * 60 * 1000,
                minPadding: 0,
                maxPadding: 0,
                startOnTick: false,
                endOnTick: false,
                reversed: true,
            },
            colorAxis: {
                min: 0,
                max: maxVal,
                gridLineWidth: 1,
                gridLineColor: 'white',
                stops: [
                    [0, '#3060cf'],
                    [0.5, '#fffbbc'],
                    [0.9, '#c4463a']
                ]
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 25,
                symbolHeight: 280
            },
            tooltip: {
                formatter: function() {
                    let text;
                    if (this.point.value) {
                        text = moment(this.point.y).format('MMMM DD') + ' [' + (this.point.x - 1) + '-' +
                            this.point.x + 'h] - ' +
                            metric_str + ': ' + this.point.value.toLocaleString("fr-FR", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) + unit;
                    }
                    return text
                }
            },
            series: [{
                type: 'heatmap',
                turboThreshold: 8800,
                borderWidth: 0.1,
                borderColor: 'black',
                data: datas,
                rowsize: 24 * 36e5,
                dataLabels: {
                    enabled: false,
                    color: '#000000'
                }
            }]
        };
        this.isReady = true;
    }

    private getFilter(name: string): FilterMgt {
        return this.message.filterList.find(filter => filter.name === name);
    }
}
