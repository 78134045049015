import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-kpi-capacity]'
})
export class KpicapacityDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
