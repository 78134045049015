import {
    Component,
    EventEmitter,
    HostListener,
    OnInit,
    Output
} from '@angular/core';
import {
    Message,
    User,
    InstanceType
} from '@app/model';
import {
    CO2ScopeMenu,
    CO2ScopeSection
} from '@app/menus/co2scopemenu/co2scopemenu.enum';
import {
    AccountService,
    GreenitService,
    MonitorService,
    ShareService
} from '@app/services';
import {
    VirtindexService
} from '@app/services/virtindex.service';

import {
    faHouseUser
} from "@fortawesome/free-solid-svg-icons";
import {
    Subscription
} from 'rxjs';
import {
    NavigationStart,
    Router
} from '@angular/router';

@Component({
    selector: 'app-co2scopemenu',
    templateUrl: './co2scopemenu.component.html',
    styleUrls: ['./co2scopemenu.component.css']
})
export class Co2scopemenuComponent implements OnInit {

    @Output('showtabs') tabsChange = new EventEmitter < boolean > ();
    pushShowTabs(showtabs: boolean) {
        this.tabsChange.emit(showtabs);
    }

    role: User;
    message: Message;

    // Cloud Pricing
    myproviderIcon = faHouseUser;

    // Green-Index
    greenindex_status: boolean = false;

    // Section/Menu activation
    section = CO2ScopeSection;
    menu = CO2ScopeMenu;
    app_menu_status: Map < string,
        Boolean > = new Map < string,
        Boolean > ();

    // For tabs
    tab: InstanceType;
    tab_subscription: Subscription;

    // Network & Storage check (extrapolation or not)
    network_data_check: boolean = false;
    storage_data_check: boolean = false;

    /**
     * 
     */
    constructor(private authentication_svc: AccountService, private message_svc: ShareService,
        private virtindex_svc: VirtindexService, private monitor_svc: MonitorService,
        private router: Router, private greenit_svc: GreenitService) {}

    /**
     * 
     */
    async ngOnInit(): Promise < void > {
        this.authentication_svc.user.subscribe(user => this.role = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.virtindex_svc.virtindexStatus.subscribe(status => this.greenindex_status = status);

        // Update network tab (according to extrapolation or not)
        this.greenit_svc.networkDataCheck.subscribe((details) => {
            this.network_data_check = details;
            // Update service to show network tab (according to extrapolation or not)
            this.monitor_svc.show_network_tab = this.network_data_check;

        });

        // storage tab (according to extrapolation or not)
        this.greenit_svc.storageDataCheck.subscribe((details) => {
            this.storage_data_check = details;
            // Update service to show storage tab (according to extrapolation or not)
            this.monitor_svc.show_storage_tab = this.storage_data_check;
        });

        // Subscription : update menu according to tab
        this.tab_subscription = this.monitor_svc.currentAppTab.subscribe((tab) => {
            this.tab = tab;
        });

        // Push menu for tabs updates
        this.router.events.subscribe(route => {

            //if (route instanceof NavigationEnd) {

            if (route instanceof NavigationStart) {

                let showtabs: boolean = true;

                let url = route.url;

                switch (url) {
                    case "/dashboard":
                        this.pushMenu(this.menu.OVERVIEW);
                        break;
                    case "/greenit/evolution":
                        this.pushMenu(this.menu.CO2_EVOLUTION);
                        break;
                    case "/greenit/power":
                        this.pushMenu(this.menu.POWER_GRAPH);
                        break;
                    case "/god":
                        this.pushMenu(this.menu.GOD);
                        break;
                    case "/dcviews/treemap":
                        this.pushMenu(this.menu.POWER_DIST);
                        break;
                    case "/elementsynthesis":
                        this.pushMenu(this.menu.POWER_USAGE);
                        break;
                    case "/reporting":
                        this.pushMenu(this.menu.REPORT);
                        break;
                    case "/greenindex":
                        this.pushMenu(this.menu.GREEN_INDEX);
                        break;
                    case "/dashboard-t6":
                        this.pushMenu(this.menu.COST);
                        break;
                    case "/opportunities":
                        this.pushMenu(this.menu.OPPORTUNITIES);
                        break;
                    case "/greenit/analysis":
                        this.pushMenu(this.menu.GREENIT_ANALYSIS);
                        break;
                    case "/capacity":
                        this.pushMenu(this.menu.CAPACITY_PLAN);
                        break;
                    case "/simulation":
                        this.pushMenu(this.menu.SIMULATION);
                        break;
                    case "/cloudcomparator":
                        this.pushMenu(this.menu.CLOUD_COMPARATOR);
                        break;
                    case "/cloudimpact":
                    case "/cloud/myprovider/myregion":
                        this.pushMenu(this.menu.CLOUD_IMPACT);
                        break;
                    case "/cost":
                    case "/user":
                    case "/periodview":
                    case "/selectionview":
                    case "/filter":
                        // Hidden tabs
                        showtabs = false;
                        break;
                    default:
                        //ONLY VMWARE
                        this.pushMenu(null);
                        break;
                }

                //Push show tabs status to app
                this.pushShowTabs(showtabs);
            }
        });
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.tab_subscription != undefined) {
            this.tab_subscription.unsubscribe();
        }
    }

    checkSection(section: CO2ScopeSection): boolean {
        switch (section) {
            case CO2ScopeSection.MEASURE:
                return this.checkMenu(CO2ScopeMenu.CO2_EVOLUTION) || this.checkMenu(CO2ScopeMenu.POWER_GRAPH) ||
                    this.checkMenu(CO2ScopeMenu.GOD);
            case CO2ScopeSection.ANALYSE:
                return this.checkMenu(CO2ScopeMenu.POWER_DIST) || this.checkMenu(CO2ScopeMenu.POWER_USAGE) || this
                    .checkMenu(CO2ScopeMenu.REPORT) || this.checkMenu(CO2ScopeMenu.GREEN_INDEX) || this.checkMenu(
                        CO2ScopeMenu.COST);
            case CO2ScopeSection.REDUCE:
                return this.checkMenu(CO2ScopeMenu.OPPORTUNITIES) || this.checkMenu(CO2ScopeMenu
                        .GREENIT_ANALYSIS) || this.checkMenu(CO2ScopeMenu.CAPACITY_PLAN) ||
                    this.checkMenu(CO2ScopeMenu.SIMULATION) || this.checkMenu(CO2ScopeMenu.CLOUD_COMPARATOR) ||
                    this.checkMenu(CO2ScopeMenu.CLOUD_IMPACT);
            default:
                return true;
        }
    }

    checkMenu(menu: CO2ScopeMenu): boolean {
        switch (menu) {
            // ALL
            case CO2ScopeMenu.OVERVIEW:
                return true;
                // ONLY VMWARE
            case CO2ScopeMenu.GOD:
            case CO2ScopeMenu.POWER_DIST:
            case CO2ScopeMenu.GREEN_INDEX:
            case CO2ScopeMenu.GREENIT_ANALYSIS:
            case CO2ScopeMenu.CAPACITY_PLAN:
            case CO2ScopeMenu.SIMULATION:
            case CO2ScopeMenu.CLOUD_IMPACT:
            case CO2ScopeMenu.COST:
                switch (this.tab) {
                    case InstanceType.VMWARE:
                        return true;
                    default:
                        return false;
                }
                // ONLY VMWARE/AWS/AZURE/GCP
            case CO2ScopeMenu.OPPORTUNITIES:
                switch (this.tab) {
                    case InstanceType.VMWARE:
                    case InstanceType.AWS:
                    case InstanceType.AZURE:
                    case InstanceType.GCP:
                        return true;
                    default:
                        return false;
                }
                // ONLY AWS/AZURE/GCP
            case CO2ScopeMenu.CLOUD_COMPARATOR:
                switch (this.tab) {
                    case InstanceType.AWS:
                    case InstanceType.AZURE:
                    case InstanceType.GCP:
                        return true;
                    default:
                        return false;
                }
                // ALL EXCEPT ALL/SCAPHANDRE/NETWORK/STORAGE
            case CO2ScopeMenu.POWER_USAGE:
                switch (this.tab) {
                    case InstanceType.ALL:
                    case InstanceType.SCAPHANDRE:
                    case InstanceType.STORAGE:
                    case InstanceType.NETWORK:
                        return false;
                    default:
                        return true;
                }
                // ALL EXCEPT ALL/STORAGE(extrapolation)/NETWORK
            case CO2ScopeMenu.POWER_GRAPH:
                switch (this.tab) {
                    case InstanceType.ALL:
                    case InstanceType.NETWORK:
                        return false;
                    case InstanceType.STORAGE:
                        return this.storage_data_check;
                    default:
                        return true;
                }
                // ALL EXCEPT ALL/STORAGE(extrapolation)/NETWORK(extrapolation)
            case CO2ScopeMenu.CO2_EVOLUTION:
                switch (this.tab) {
                    case InstanceType.ALL:
                        return false;
                    case InstanceType.STORAGE:
                        return this.storage_data_check;
                    case InstanceType.NETWORK:
                        return this.network_data_check;
                    default:
                        return true;
                }
                // ONLY VMWARE/SCAPHANDRE/XCLARITY/OPENMANAGE/ONEVIEW/IPMI/STORAGE/NETWORK
            case CO2ScopeMenu.REPORT:
                switch (this.tab) {
                    case InstanceType.VMWARE:
                    case InstanceType.SCAPHANDRE:
                    case InstanceType.XCLARITY:
                    case InstanceType.OPENMANAGE:
                    case InstanceType.ONEVIEW:
                    case InstanceType.IPMI:
                        return true;
                    case InstanceType.STORAGE:
                        return this.storage_data_check;
                    case InstanceType.NETWORK:
                        return this.network_data_check;
                    default:
                        return false;
                }
            default:
                return true;
        }
    }

    pushMenu(menu: CO2ScopeMenu) {
        this.monitor_svc.updateAppTabStatus(menu);
    }
}
