<div *ngIf="message.isNotLoginPage">

	<div *ngIf="isCo2scope">
		<app-allinclusive *ngIf="tab==instanceType.ALL"></app-allinclusive>
		<app-vmware *ngIf="tab==instanceType.VMWARE"></app-vmware>
 		<div *ngIf="tab==instanceType.AWS">
			<app-dashboard-cloud #awsdashboard [isreload]="isDataReload" *ngIf="isReady == true"></app-dashboard-cloud>
			<div *ngIf="!this.message.isValidAws">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<div><strong class="center">No data available !</strong></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="tab==instanceType.AZURE">
			<app-dashboard-cloud #azuredashboard [isreload]="isDataReload" *ngIf="isReady == true"></app-dashboard-cloud>
			<div *ngIf="!this.message.isValidAzure">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<div><strong class="center">No data available !</strong></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="tab==instanceType.GCP">
			<app-dashboard-cloud #gcpdashboard [isreload]="isDataReload" *ngIf="isReady == true"></app-dashboard-cloud>
			<div *ngIf="!this.message.isValidGcp">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<div><strong class="center">No data available !</strong></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="tab==instanceType.KUBERNETES">
			<app-dashboard-container [isreload]="isDataReload" *ngIf="isReady == true"></app-dashboard-container>
			<div *ngIf="!this.message.isValidKubernetes">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<div><strong class="center">No data available !</strong></div>
							</div>
					</div>
				</div>
			</div>
		</div>
		<app-scaphandre *ngIf="tab==instanceType.SCAPHANDRE"></app-scaphandre>
		<app-xclarity *ngIf="tab==instanceType.XCLARITY"></app-xclarity>
		<app-openmanage *ngIf="tab==instanceType.OPENMANAGE"></app-openmanage>
		<app-oneview *ngIf="tab==instanceType.ONEVIEW"></app-oneview>
		<app-ipmi *ngIf="tab==instanceType.IPMI"></app-ipmi>
		<app-storage *ngIf="tab==instanceType.STORAGE"></app-storage>
		<app-network *ngIf="tab==instanceType.NETWORK"></app-network>
	</div>

	<app-vmware *ngIf="isDcscope"></app-vmware>
</div>
