<div class="clr-row">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="clr-row">
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
                <div class="card">
                    <div class="card-block">
                        <span class="label" style="background-color:#0072a3;border-color:#0072a3;width:100%;height:100px;font-size:13px;color:white;font-weight:700;display:block;">
                            <div>&nbsp;</div>
                            <cds-icon shape="tree"></cds-icon>&nbsp;
                            CURRENT CO2 emission
                            <div>&nbsp;</div>
                            <br>
                            <label style="font-size:40px;color:white;text-align:center;font-weight:700;display: block">
                                {{formatBigNumber(model.totalCo2.toFixed(0))}} Kg CO2 eq
                            </label>
                        </span>
                    </div>
                </div>
            </div>
            <div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
                <div class="card">
                    <div class="card-block">
                        <span [ngClass]="model.simulateClass">
                            <div class="clr-row">
                                <div class="clr-col-lg-8 clr-col-md-8 clr-col-8">
                                    <div>&nbsp;</div>
                                    <cds-icon shape="tree"></cds-icon>&nbsp;
                                    CO2 emission after SIMULATION
                                    <div>&nbsp;</div>
                                    <br>
                                    <label style="font-size:40px;color:white;text-align:center;font-weight:700;display: block">
                                        {{formatBigNumber(model.simulateCo2.toFixed(0))}} Kg CO2 eq
                                    </label>
                                </div>
                                <div class="clr-col-lg-4 clr-col-md-4 clr-col-4">
                                    <span *ngIf="model.templates.length > 0">
                                        <br>
                                        <label style="font-size:18px;color:white;text-align:left;font-weight:700;display: block">
                                            The new project's share
                                        </label>
                                        <br><br>
                                        <label style="font-size:20px;color:white;text-align:left;font-weight:700;display: block">
                                            {{formatBigNumber(model.simulateCo2.toFixed(0)-model.totalCo2.toFixed(0))}} Kg CO2 eq
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header">
                <cds-icon shape="tree"></cds-icon>&nbsp;
                SIMULATION SETTINGS
            </div>
            <div class="card-block">
                <div class="clr-row">
                    <div class="clr-col-lg-9 clr-col-md-9 clr-col-9">
                        <span class="label label-info">
                            Add and/or delete Virtual Machines to your project
                        </span>
                        <div class="datagrid-limit-height">
                            <clr-datagrid>
                                <clr-dg-column [clrDgField]="'NAME'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'CO2'" [clrDgSortBy]="co2Comparator"><ng-container *clrDgHideableColumn="{hidden: false}">Direct CO2 (Kg CO2 eq)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'CO2G'" [clrDgSortBy]="co2gComparator"><ng-container *clrDgHideableColumn="{hidden: false}">Embodied CO2 (Kg CO2 eq)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'CO2NET'" [clrDgSortBy]="co2Comparator"><ng-container *clrDgHideableColumn="{hidden: false}">Direct NETWORK CO2 (Kg CO2 eq)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'CO2STO'" [clrDgSortBy]="co2Comparator"><ng-container *clrDgHideableColumn="{hidden: false}">Direct STORAGE CO2 (Kg CO2 eq)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'CO2ALL'" [clrDgSortBy]="co2Comparator"><ng-container *clrDgHideableColumn="{hidden: false}">Total Direct CO2 (Kg CO2 eq)</ng-container></clr-dg-column>
                                <clr-dg-row *clrDgItems="let vm of model.vms" [clrDgItem]="vm" clickable>
                                    <clr-dg-action-overflow>
                                        <button class="action-item" (click)="onAdd(vm)">Add</button>
                                        <button class="action-item" (click)="onDelete(vm)">Delete</button>
                                    </clr-dg-action-overflow>
                                    <clr-dg-cell>{{vm.NAME}}</clr-dg-cell>
                                    <clr-dg-cell>{{convertToNumber(vm.CO2)}}</clr-dg-cell>
                                    <clr-dg-cell>{{convertToNumber(vm.CO2G)}}</clr-dg-cell>
                                    <clr-dg-cell>{{convertToNumber(vm.CO2NET).toFixed(2)}}</clr-dg-cell>
                                    <clr-dg-cell>{{convertToNumber(vm.CO2STO).toFixed(2)}}</clr-dg-cell>
                                    <clr-dg-cell>{{convertToNumber(vm.CO2ALL).toFixed(2)}}</clr-dg-cell>
                                </clr-dg-row>
                                <clr-dg-footer>
                                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                                            entries per page
                                        </clr-dg-page-size>
                                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                    </clr-dg-pagination>
                                </clr-dg-footer>
                            </clr-datagrid>
                        </div>
                    </div>
                    <div class="clr-col-lg-3 clr-col-md-3 clr-col-3">
                        <span class="label label-info">
                            Template settings
                        </span>
                        <div class="datagrid-limit-height">
                            <clr-datagrid>
                                <clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Template</ng-container></clr-dg-column>
                                <clr-dg-column><ng-container *clrDgHideableColumn="{hidden: false}">Quantity</ng-container></clr-dg-column>
                                <clr-dg-row *clrDgItems="let template of model.templates" [clrDgItem]="template" clickable>
                                    <clr-dg-cell>
                                        <span style="color: #3c8500" *ngIf="template.quantity > 0; else disRed">
                                            <cds-icon shape="plus" style="width:50px" role="none"></cds-icon>
                                            {{template.name}}
                                        </span>
                                        <ng-template #disRed>
                                            <span style="color: #c21d00">
                                                <cds-icon shape="minus" style="width:50px" role="none"></cds-icon>
                                                {{template.name}}
                                                </span>
                                        </ng-template>
                                    </clr-dg-cell>
                                    <clr-dg-cell>
                                        <input 
                                            type="number"
                                            value="{{template.quantity}}"
                                            [(ngModel)]="template.quantity"
                                            style="width:50px"
                                            (ngModelChange)="buildSimulateCo2()"
                                            *ngIf="template.quantity > 0; else disNoInput">
                                        <ng-template #disNoInput>
                                            <span style="width:50px">n/a</span>
                                        </ng-template>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<cds-icon shape="trash" class="addLink" (click)="removeElement(template)"></cds-icon>&nbsp;
                                    </clr-dg-cell>
                                </clr-dg-row>
                            </clr-datagrid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>