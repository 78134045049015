<div class="clr-row">
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <clr-toggle-container clrInline>
                    <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="isOppYear" value="isOppYear" [(ngModel)]="isOppYear" (ngModelChange)="loadMetaSaving()" />
                            <label>
                                    one year estimate
                            </label>
                    </clr-toggle-wrapper>
                    <clr-control-helper>display savings</clr-control-helper>
            </clr-toggle-container>
    </div>
    <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
            <span style="float: right;margin-left:100px;margin-top: 20px;" class="label label-info">
                    {{model.oppInfo}}
            </span>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <div class="card">
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-6">
                                            <br>
                                            <span style="font-weigth:bold;font-size:24px" id="spec-cloud-provider-aws-nbresizing">
                                                    {{metadata.resizingInstance}}
                                            </span>
                                            <br>
                                            <span style="font-size:20px">
                                                    resizing
                                            </span>
                                            <br><br>
                                            <span style="float: left;color: #057ff5;" ngClass="addLink" (click)="loadResizingDetail()" id="spec-cloud-provider-aws-moreresizing">
                                                    more ...
                                            </span>
                                    </div>
                                    <div class="clr-col-6 text-right">
                                            <br>
                                            <span style="font-weigth:bold;font-size:20px" id="spec-cloud-provider-aws-savingresizing">
                                                    $ {{model.oppResMeta}}
                                            </span>
                                            <br>
                                            <span style="font-size:20px">
                                                <fa-icon [icon]="['fas', 'leaf']" style="font-size:0.75em;"></fa-icon>
                                                {{number(model.oppResGreenMeta).toFixed(2)}} <span style="font-size:16px">kg CO2eq</span>
                                            </span>
                                            <br>
                                            <span style="font-size:16px">
                                                    savings
                                            </span>
                                            <br>
                                            <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadResizingSimu()">
                                                simulation
                                            </span>
                                    </div>
                            </div>
                            <div [ngClass]="resizing_class">
                                    <progress value="{{resizing_progress}}" max="100"></progress>
                            </div>
                    </div>
            </div>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <div class="card">
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-6">
                                            <br>
                                            <span style="font-weigth:bold;font-size:24px" id="spec-cloud-provider-aws-nbbehavior">
                                                    {{metadata.behaviorInstance}}
                                            </span>
                                            <br>
                                            <span style="font-size:20px">
                                                    turn off
                                            </span>
                                            <br><br>
                                            <span style="float: left;color: #057ff5;" ngClass="addLink" (click)="loadBehaviorDetail()">
                                                more ...
                                            </span>
                                    </div>
                                    <div class="clr-col-6 text-right">
                                            <br>
                                            <span style="font-weigth:bold;font-size:20px" id="spec-cloud-provider-aws-savingbehavior">
                                                    $ {{model.oppBehMeta}}
                                            </span>
                                            <br>
                                            <span style="font-size:20px">
                                                <fa-icon [icon]="['fas', 'leaf']" style="font-size:0.75em;"></fa-icon>
                                                {{number(model.oppBehGreenMeta).toFixed(2)}} <span style="font-size:16px">kg CO2eq</span>
                                            </span>
                                            <br>
                                            <span style="font-size:16px">
                                                    savings
                                            </span>
                                    </div>
                            </div>
                            <div [ngClass]="behavior_class">
                                    <progress value="{{behavior_progress}}" max="100"></progress>
                            </div>
                    </div>
            </div>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <div class="card">
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-7">
                                            <br>
                                            <span style="font-weigth:bold;font-size:24px" id="spec-cloud-provider-aws-nbsp">
                                                    {{metadata.savingsplanFamily}}
                                            </span>
                                            <br>
                                            <span style="font-size:20px">
                                                    savings plan
                                            </span>
                                            <br><br>
                                            <span style="float: left;color: #057ff5;" ngClass="addLink" (click)="loadSavingsPlansDetail()">
                                                    more ...
                                            </span>
                                    </div>
                                    <div class="clr-col-5 text-right">
                                            <br>
                                            <span style="font-weigth:bold;font-size:20px" id="spec-cloud-provider-aws-savingsp">
                                                    $ {{model.oppSpMeta}}
                                            </span>
                                            <br>
                                            <span style="font-size:16px">
                                                    savings
                                            </span>
                                    </div>
                            </div>
                            <div [ngClass]="savingsplan_class">
                                    <progress value="{{savings_progress}}" max="100"></progress>
                            </div>
                    </div>
            </div>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <div class="card">
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-7">
                                            <br>
                                            <span style="font-weigth:bold;font-size:24px">
                                                    {{metadata.spotInstance}}
                                            </span>
                                            <br>
                                            <span style="font-size:20px">
                                                    spot
                                            </span>
                                            <br><br>
                                            <span style="float: left;color: #057ff5;" ngClass="addLink" (click)="loadOpportunitiesSpot()">
                                                    more ...
                                            </span>
                                    </div>
                                    <div class="clr-col-5 text-right">
                                            <br>
                                            <span style="font-weigth:bold;font-size:20px">
                                                    $ {{model.oppSpotMeta}}
                                            </span>
                                            <br>
                                            <span style="font-size:16px">
                                                    savings
                                            </span>
                                    </div>
                            </div>
                            <div [ngClass]="spot_class">
                                    <progress value="{{spot_progress}}" max="100"></progress>
                            </div>
                    </div>
            </div>
    </div>
</div>
<div class="card-block">
    <ng-template cloud-resizing-detail *ngIf="detail_str == 'resizing'"></ng-template>
    <ng-template cloud-behavior-detail *ngIf="detail_str == 'behavior'"></ng-template>
    <ng-template cloud-savingsplan-detail *ngIf="detail_str == 'savingsplan'"></ng-template>
    <ng-template cloud-opportunities-spot *ngIf="detail_str == 'oppspot'"></ng-template>
    <app-resizing-simulator *ngIf="detail_str == 'resizing-simu'"></app-resizing-simulator>
</div>