<div style="width: 300px;">
    <clr-toggle-container>
        <label>Filter tables values that are in range:</label>
        <clr-toggle-wrapper>
            <input type="checkbox" clrToggle name="options" value="option1" [(ngModel)]="filterIsActive"
                   (change)="triggerEvent()"/>
            <label>{{ filterIsActive ? "Filter is active" : "Filter is inactive" }}</label>
        </clr-toggle-wrapper>
    </clr-toggle-container>

    <ngx-slider
            [(value)]="minValue"
            [(highValue)]="maxValue"
            [options]="options"
            (mouseup)="triggerEvent()"
            (mousemove)="triggerEvent()"
            [manualRefresh]="manualRefresh"
    >
    </ngx-slider>
</div>
