<table id="ts-card-list" class="table">
    <thead>
        <tr>
            <th class="left">Resource</th>
	    <th class="left">Description</th>
            <th>warning pts</th>
            <th>critical pts</th>
        </tr>
    </thead>
    <tbody>
        <tr class="addLink" (click)="callGraph('tmcaoh')">
            <td class="left">CPU</td>
            <td class="left">Too much CPU activity on server</td>
	    <td>
		    <span class="badge badge-orange" style="background-color:#f3a10b;">{{ts_selected.pts_crdy_ora}}</span>
	    </td>
	    <td>
		    <span class="badge badge-danger" style="background-color:#ff0000;">{{ts_selected.pts_crdy_red}}</span>
	    </td>
        </tr>
        <tr class="addLink" (click)="callGraph('tmvovm')">
            <td class="left">CPU</td>
	    <td class="left">Too much vCPU on VM</td>
	    <td>
		    <span class="badge badge-orange" style="background-color:#f3a10b;">{{ts_selected.pts_cstp_ora}}</span>
	    </td>
	    <td>
		    <span class="badge badge-danger" style="background-color:#ff0000;">{{ts_selected.pts_cstp_red}}</span>
	    </td>
        </tr>
        <tr class="addLink" (click)="callGraph('vmo')">
            <td class="left">CPU</td>
            <td class="left">Virtual Machine Overloaded</td>
	    <td>
		    <span class="badge badge-orange" style="background-color:#f3a10b;">{{ts_selected.pts_crsr_ora}}</span>
	    </td>
	    <td>
		    <span class="badge badge-danger" style="background-color:#ff0000;">{{ts_selected.pts_crsr_red}}</span>
	    </td>
        </tr>
        <tr class="addLink" (click)="callGraph('diskcmd')">
            <td class="left">DISK</td>
            <td class="left">Controls Failed</td>
	    <td>
		    <span class="badge badge-orange" style="background-color:#f3a10b;">{{ts_selected.pts_dcmd_ora}}</span>
	    </td>
	    <td>
		    <span class="badge badge-danger" style="background-color:#ff0000;">{{ts_selected.pts_dcmd_red}}</span>
	    </td>
        </tr>
        <tr class="addLink" (click)="callGraph('disklat')">
            <td class="left">DISK</td>
            <td class="left">Total Latency</td>
	    <td>
		    <span class="badge badge-orange" style="background-color:#f3a10b;">{{ts_selected.pts_dlat_ora}}</span>
	    </td>
	    <td>
		    <span class="badge badge-danger" style="background-color:#ff0000;">{{ts_selected.pts_dlat_red}}</span>
	    </td>
        </tr>
        <tr class="addLink" (click)="callGraph('net')">
            <td class="left">NET</td>
            <td class="left">Lost Packets</td>
	    <td>
		    <span class="badge badge-orange" style="background-color:#f3a10b;">{{ts_selected.pts_net_ora}}</span>
	    </td>
	    <td>
		    <span class="badge badge-danger" style="background-color:#ff0000;">{{ts_selected.pts_net_red}}</span>
	    </td>
        </tr>
        <tr class="addLink" (click)="callGraph('ram')">
            <td class="left">RAM</td>
            <td class="left">Virtual Machine Overloaded</td>
	    <td>
		    <span class="badge badge-orange" style="background-color:#f3a10b;">{{ts_selected.pts_ram_ora}}</span>
	    </td>
	    <td>
		    <span class="badge badge-danger" style="background-color:#ff0000;">{{ts_selected.pts_ram_red}}</span>
	    </td>
        </tr>
    </tbody>
</table>
