import {
    Component,
    OnInit
} from '@angular/core';
import {
    Router
} from '@angular/router';

import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    AuditService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    Alert,
    FilterElement,
    Json,
    Message,
    TriggeredAlert,
    User
} from '@app/model';

import {
    alertConvApiToIhm
} from '../../../assets/js/tools.js';

import {
    ClrDatagridSortOrder
} from '@clr/angular';


@Component({
    selector: 'app-alertingtablestate',
    templateUrl: './alertingtablestate.component.html',
    styleUrls: ['./alertingtablestate.component.css']
})
export class AlertingtablestateComponent implements OnInit {

    message: Message;

    jsonLoader: Json;

    data_items: any;

    filters: FilterElement[] = [];

    alerts: Alert[] = [];

    triggered_alerts: TriggeredAlert[] = [];

    triggered_state: string = '';

    dataOrderDesc = ClrDatagridSortOrder.DESC;

    private currentUser: User;


    constructor(
        private router: Router,
        private authentication_svc: AccountService,
        private message_svc: ShareService,
        private audit_svc: AuditService,
        private json_svc: JsonloaderService) {}


    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
        this.data_items = this.jsonLoader.dcInfraMin;
    }

    ngAfterViewInit(): void {

        this.getFilters();
        this.getAlerts();
    }

    getInfraFromUuid(uuid: string): any {

        if (this.message.isAlertRt)
            return this.data_items.find(item => item.uuid === uuid);
        else
            return this.filters.find(item => item.name === uuid);
    }

    gotoGraph(alert: any): void {

        this.message.currentUuid = alert.uuid;
        this.message.isFromAlert = true;
        let cur_alert: Alert = this.getAlertFromUuid(alert.uuid_alert);
        if (cur_alert != undefined) {
            if (cur_alert.granularity == "min") {
                this.router.navigate([`/god/resource/${this.message.currentUuid}`]);
            } else {
                switch (cur_alert.counter) {
                    case "critical_behavior":
                    case "busy_behavior":
                        this.router.navigate([`/troubleshooting`]);
                        break;
                    case "zombie_behavior":
                    case "idle_behavior":
                        this.router.navigate([`/recommendation`]);
                        break;
                    case "snap_nb":
                        this.router.navigate([`/storage-overview-snapshot`]);
                        break;
                    case "cpu_remain":
                    case "ram_remain":
                    case "disk_remain":
                    case "vcpu_sat":
                    case "vram_sat":
                    case "vdisk_sat":
                    case "vcpu_allocated":
                    case "ram_allocated":
                    case "disk_allocated":
                    case "disk_goremain":
                    case "ram_goremain":
                    case "cpu_ghzremain":
                    case "cpu_sat":
                    case "disk_sat":
                    case "ram_sat":
                        this.router.navigate([`/capacity`]);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    formatDef(uuid: string): string {

        let new_val: string = '';

        let cur_alert: Alert = this.getAlertFromUuid(uuid);
        let cur_converted_alert = alertConvApiToIhm(cur_alert);

        switch (this.message.alertTriggeredState) {
            case "rt-critical":
            case "ft-critical":
                this.triggered_state = "critical";
                break;
            case "rt-warning":
            case "ft-warning":
                this.triggered_state = "warning";
                break;
            default:
                break;
        }

        if (this.triggered_state == "critical")
            new_val = String(cur_converted_alert.th2) + cur_converted_alert.unit;
        else
            new_val = String(cur_converted_alert.th1) + cur_converted_alert.unit;

        return cur_alert.definition.replace('%STATE%', new_val);
    }

    private getAlerts(): void {

        this.audit_svc.getAlertList(this.currentUser.login).pipe(first()).subscribe(
            data => {
                this.alerts = data;
                if (this.alerts.length > 0)
                    this.getAlertDetail();
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private getAlertDetail(): void {

        if (this.message.alertTriggeredState != "") {
            let state: string = '';
            let granularity: string = '';
            switch (this.message.alertTriggeredState) {
                case "rt-critical":
                    state = 'critical';
                    granularity = 'min';
                    break;
                case "rt-warning":
                    state = 'warning';
                    granularity = 'min';
                    break;
                case "ft-critical":
                    state = 'critical';
                    granularity = 'day';
                    break;
                case "ft-warning":
                    state = 'warning';
                    granularity = 'day';
                    break;
                default:
                    break;
            }
            this.audit_svc.getAlertTriggered(this.currentUser.login, state, granularity, this.message.elementView)
                .pipe(first()).subscribe(
                    data => {
                        this.triggered_alerts = data;
                        for (let i in this.triggered_alerts) {
                            switch (this.triggered_alerts[i].counter) {
                                case "cpuusage":
                                case "ramusage":
                                case "cpuready":
                                case "cpucostop":
                                case "ramactive":
                                case "ramconsumed":
                                case "ramswapped":
                                    this.triggered_alerts[i].action_icon = "line-chart";
                                    this.triggered_alerts[i].action_text = "Graph on demand";
                                    break;
                                case "critical_behavior":
                                case "busy_behavior":
                                    this.triggered_alerts[i].action_icon = "exclamation-circle";
                                    this.triggered_alerts[i].action_text = "Troubleshooting";
                                    break;
                                case "zombie_behavior":
                                case "idle_behavior":
                                    this.triggered_alerts[i].action_icon = "thumbs-up";
                                    this.triggered_alerts[i].action_text = "Recommendation";
                                    break;
                                case "snap_nb":
                                case "snap_date":
                                case "snap_size":
                                    this.triggered_alerts[i].action_icon = "process-on-vm";
                                    this.triggered_alerts[i].action_text = "Snapshot";
                                    break;
                                case "cpu_remain":
                                case "ram_remain":
                                case "disk_remain":
                                case "vcpu_sat":
                                case "vram_sat":
                                case "vdisk_sat":
                                case "vcpu_allocated":
                                case "ram_allocated":
                                case "disk_allocated":
                                case "disk_goremain":
                                case "ram_goremain":
                                case "cpu_ghzremain":
                                case "cpu_sat":
                                case "disk_sat":
                                case "ram_sat":
                                    this.triggered_alerts[i].action_icon = "slider";
                                    this.triggered_alerts[i].action_text = "Capacity planning";
                                    break;
                                default:
                                    this.triggered_alerts[i].action_text = "none";
                                    break;
                            }
                        }
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
        }
    }

    private getAlertFromUuid(uuid: string): Alert {
        return this.alerts.find(alert => alert.uuid === uuid);
    }

    private getFilters(): void {

        for (var i = 0; i < this.message.filterList.length; i++) {
            let filter: FilterElement = {
                uuid: this.message.filterList[i].name,
                name: this.message.filterList[i].name,
                type: 'filter'
            };
            this.filters.push(filter);
        }
    }
}
