import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-recommendation-consolidation-server-list]'
})
export class RecommendationconsolidationserverlistDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
