<div class="clr-row">
        <div class="card" *ngIf="!isSelected">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				Instance resizing
			</span>
			<span *ngIf="!selected_resize" style="margin-left:100px;" class="label label-info">
				Pick an instance to view its resizing solutions
			</span>
			<button class="btn btn-link" style="position:relative;float:right;" (click)="exportResizing()">
				Export
			</button>
                </div>
                <div class="card-block">
			<div class="datagrid-limit-height">
				<clr-datagrid [(clrDgSingleSelected)]="selected_res" (clrDgSingleSelectedChange)="resChanged($event)">
					<clr-dg-column [clrDgField]="'instanceId'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'mode'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Burstable mode</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'bestGain'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Gain (%)</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let resize of resizes" [clrDgItem]="resize">
						<clr-dg-cell>{{resize.instanceId}}</clr-dg-cell>
						<clr-dg-cell>{{resize.name}}</clr-dg-cell>
						<clr-dg-cell>{{resize.mode}}</clr-dg-cell>
						<clr-dg-cell>{{resize.bestGain}}</clr-dg-cell>
					</clr-dg-row>
					<clr-dg-footer>
                				<clr-dg-pagination #pagination [clrDgPageSize]="10">
                    					<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
								    entries per page
							</clr-dg-page-size>
                    					{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                				</clr-dg-pagination>
            				</clr-dg-footer>
				</clr-datagrid>
			</div>
                </div>
        </div>
	<div class="card" *ngIf="isSelected">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				Instance resizing solutions for {{selected_resize.name}}
			</span>
			<span style="float: right;" ngClass="addLink" (click)="isSelected = false">
				<cds-icon shape="times"></cds-icon>
			</span>
                </div>
                <div class="card-block">
			<div class="datagrid-limit-height">
				<span style="color:grey;font-size:18px;">
					current estimation
				</span>
				<clr-datagrid>
					<clr-dg-column>ID</clr-dg-column>
					<clr-dg-column>Name</clr-dg-column>
					<clr-dg-column>Type</clr-dg-column>
					<clr-dg-column>Family</clr-dg-column>
					<clr-dg-column>vCPU</clr-dg-column>
					<clr-dg-column>vRAM (MB)</clr-dg-column>
					<clr-dg-column>Billing</clr-dg-column>
					<clr-dg-column *ngIf="selected_resize.mode == 'exit'; else wosurplus">
						Estimate over the month ($ with surplus)
					</clr-dg-column>
					<ng-template #wosurplus>
						<clr-dg-column>Estimate over the month</clr-dg-column>
					</ng-template>
					<clr-dg-column *ngIf="selected_resize.mode == 'exit'; else wosurplusy">
						Estimate over the year ($ with surplus)
					</clr-dg-column>
					<ng-template #wosurplusy>
						<clr-dg-column>Estimate over the year ($)</clr-dg-column>
					</ng-template>
					<clr-dg-column>Best gain (%)</clr-dg-column>
					<clr-dg-row>
						<clr-dg-cell>{{selected_resize.instanceId}}</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.name}}</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.instanceType}}</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.family}}</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.vcpu}}</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.vram}}</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.billing}}</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.computeCostEstimatedMonth+
							selected_resize.surplusCostMonth}}
							 = {{selected_resize.computeCostEstimatedMonth}}
							 + ({{selected_resize.surplusCostMonth}})
						</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.computeCostEstimatedYear+
							selected_resize.surplusCostYear}}</clr-dg-cell>
						<clr-dg-cell>{{selected_resize.bestGain}}</clr-dg-cell>
					</clr-dg-row>	
				</clr-datagrid>
				<br><br>
				<span style="color:grey;font-size:18px;">
					resizing solutions
				</span>
				<clr-datagrid>
					<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'family'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Family</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'vcpu'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">vCPU</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'vram'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">vRAM (MB)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'billing'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Billing</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'computeCostEstimatedMonth'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Estimate over the month ($)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'computeCostEstimatedYear'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Estimate over the year ($)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'gain'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Gain (%)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'co2'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Kg CO2eq</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let solution of selected_resize.itSolution" [clrDgItem]="solution">
						<clr-dg-cell>{{solution.name}}</clr-dg-cell>
						<clr-dg-cell>{{solution.family}}</clr-dg-cell>
						<clr-dg-cell>{{solution.vcpu}}</clr-dg-cell>
						<clr-dg-cell>{{solution.vram}}</clr-dg-cell>
						<clr-dg-cell>{{solution.billing}}</clr-dg-cell>
						<clr-dg-cell>{{solution.computeCostEstimatedMonth}}</clr-dg-cell>
						<clr-dg-cell>{{solution.computeCostEstimatedYear}}</clr-dg-cell>
						<clr-dg-cell>{{solution.gain}}</clr-dg-cell>
						<clr-dg-cell>{{solution.co2}}</clr-dg-cell>
					</clr-dg-row>
				</clr-datagrid>
			</div>
		</div>
        </div>
</div>