import {
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    InfrastructureService,
    JsonloaderService,
    ShareService
} from '@app/services';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);


@Component({
    selector: 'app-instances-detail',
    templateUrl: './instances-detail.component.html',
    styleUrls: ['./instances-detail.component.css']
})
export class InstancesDetailComponent implements OnInit {

    message: Message;

    graphClass: string = 'clr-col-lg-2 clr-col-md-2 clr-col-12';

    tabClass: string = 'clr-col-lg-10 clr-col-md-10 clr-col-12';

    instancesAll: any = [];

    instances: any = [];

    evolution: any = [];

    dataOrder = ClrDatagridSortOrder.ASC;

    selected_instance: any;

    selected_ins: any;

    isSelected: boolean = false;

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};

    isReady: boolean = false;

    isGraph: boolean = true;

    isEvolutionYear: boolean = false;

    months: any = [];

    model: any = {
        isModel: false,
        selectedMonth: ''
    };

    med: any = null;

    provider: string = '';

    isMultiDisk: boolean = false;

    selected_volume: any;

    volumes: any = [];

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private infra_svc: InfrastructureService,
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.provider = this.message.cloudProvider;

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_INSTANCE).subscribe(
            data => {
                this.instancesAll = data;
                this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this
                    .message.cloudProvider, JSONTarget.CLOUD_MONTH).subscribe(
                    data2 => {
                        this.months = data2;
                        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter,
                                'cloud_' + this.message.cloudProvider, JSONTarget.CLOUD_OVERVIEW_EVOLUTION)
                            .subscribe(
                                data3 => {
                                    this.evolution = data3;
                                    let iter: number = 0;
                                    iter = this.evolution.length;
                                    if (iter > 0)
                                        this.med = this.evolution[iter - 1].med;

                                    let val: string = '';
                                    val = this.months[0].name;
                                    this.model.selectedMonth = val;

                                    for (let obj of this.instancesAll[val.replace(/ /g, "_")]) {
                                        this.instances.push(obj);
                                    }

                                    this.loadGraph();
                                }
                            );
                    }
                );
            }
        );
    }

    private loadGraph(): void {

        let title: string = '';
        let titleSize: string = '20px';

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            chart: {
                plotBorderWidth: null,
                plotShadow: false
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: [{
                type: 'pie',
                name: 'Instances',
                data: [{
                    name: 'off',
                    y: this.med.nbOff,
                    color: '#616a6b'
                }, {
                    name: 'running',
                    y: this.med.nbRunning,
                    color: '#abd65c',
                    sliced: true,
                    selected: true
                }, {
                    name: 'deleted',
                    y: this.med.nbDestroy,
                    color: '#dc7633'
                }, {
                    name: 'created',
                    y: this.med.nbCreate,
                    color: '#546e7a'
                }]
            }]
        };
        this.isReady = true;
    }

    reloadGraph() {

        this.isReady = false;
        if (this.isEvolutionYear) {
            this.graphClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
            setTimeout(() => this.loadGraph2(), 100);
        } else {
            this.graphClass = 'clr-col-lg-2 clr-col-md-2 clr-col-12';
            setTimeout(() => this.loadGraph(), 100);
        }
    }

    loadMonth(event: any) {

        this.model.isModel = false;
        this.instances = [];
        let val: any = event.replace(/ /g, "_");
        for (let obj of this.instancesAll[val]) {
            this.instances.push(obj);
        }

        for (let obj of this.evolution) {
            if (obj.month == this.model.selectedMonth) {
                this.med = obj.med;
            }
        }
        this.loadGraph();
    }

    updateList(): void {

        let val: any = this.model.selectedMonth.replace(/ /g, "_");
        this.instances = [];
        if (this.model.isModel) {
            for (let obj of this.instancesAll[val]) {
                if (obj.state != "destroy")
                    this.instances.push(obj);
            }
        } else {
            for (let obj of this.instancesAll[val]) {
                this.instances.push(obj);
            }
        }
    }

    insChanged(event: any): void {

        if (event != null) {
            this.selected_instance = this.selected_ins;
            this.isSelected = true;
        }
    }

    closeGraph(): void {

        this.tabClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
        this.isReady = false;
        this.isGraph = false;
    }

    exportInstances() {

        let res = ["ID", "Name", "Account", "Region", "Creation date", "Type", "State", "vCPU", "vRAM (MB)"].join(',') +
            '\n';
        res += Object.values < any > (this.instances).map(instance => [instance.instanceId, instance.name, instance
            .subscription, instance.region, instance.creation, instance.instanceType,
            instance.state, instance.vcpu, instance.vram
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'overview_instances.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    private loadGraph2(): void {

        let cat: any = [];
        let create: any = [];
        let destroy: any = [];
        let run: any = [];
        let off: any = [];
        for (let i in this.evolution) {
            cat.push(this.evolution[i].month);
            let create_pt: any = {
                name: 'created',
                y: this.evolution[i].med.nbCreate
            };
            create.push(create_pt);
            let destroy_pt: any = {
                name: 'deleted',
                y: this.evolution[i].med.nbDestroy
            };
            destroy.push(destroy_pt);
            let run_pt: any = {
                name: 'running',
                y: this.evolution[i].med.nbRunning
            };
            run.push(run_pt);
            let off_pt: any = {
                name: 'off',
                y: this.evolution[i].med.nbOff
            };
            off.push(off_pt);
        }

        let series: any = [];
        let serie0: any = {
            type: 'column',
            name: 'created',
            data: create,
            color: '#546e7a'
        };
        series.push(serie0);
        let serie1: any = {
            type: 'column',
            name: 'deleted',
            data: destroy,
            color: '#dc7633'
        };
        series.push(serie1);
        let serie2: any = {
            type: 'column',
            name: 'running',
            data: run,
            color: '#abd65c'
        };
        series.push(serie2);

        let serie3: any = {
            type: 'column',
            name: 'off',
            data: off,
            color: '#616a6b'
        };
        series.push(serie3);

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: '',
                style: {
                    color: 'grey',
                    fontSize: '20px'
                }
            },
            chart: {
                type: 'column'
            },
            xAxis: {
                categories: cat
            },
            yAxis: {
                visible: false,
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                }
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        text: "download",
                        symbolStroke: "#0072A3",
                        x: -10
                    }
                }
            },
            series: series
        };
        this.isReady = true;
    }

    loadDisksDetail() {

        this.isMultiDisk = true;
        console.log(this.selected_instance);
        this.infra_svc.getIdisk(this.selected_instance.identifier).pipe(first()).subscribe(
            data => {
                this.volumes = data;
            }, error => {
                console.log('no idisk data');
            }
        );
    }

    convertTime(time: number): String {

        return new Date(time).toLocaleDateString("en-US");
    }

    convertVol(volId: String): String {

        if (this.message.cloudProvider == "aws")
            return volId.split("-")[5] + " -" + volId.split("-")[6];
        else
            return volId.split("-")[5];
    }
}
