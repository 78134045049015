<div class="card addScroll" style="overflow:hidden;">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="bell-curve"></cds-icon>
			&nbsp;Green Index
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div class="clr-row" *ngIf="greenindex_status && !isNoData" style="position:relative;z-index:1;">
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-6"></div>
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
					<div class="btn-group btn-outline-primary btn-sm radio" style="float:right;margin-right:0;">
						<div class="radio btn">
							<input type="radio" name="btn-group-t10" id="btn-radio-1" [value]=0 (ngModelChange)="radioAction()" [(ngModel)]="radio_choice">
							<label for="btn-radio-1">Rank</label>
						</div>
						<div class="radio btn">
							<input type="radio" name="btn-group-t10" id="btn-radio-2" [value]=1 (ngModelChange)="radioAction()" [(ngModel)]="radio_choice">
							<label for="btn-radio-2">Radar</label>
						</div>
					</div>
				</div>
			</div>

			<app-gaugechart *ngIf="greenindex_status && isRank" [data]="carbon_radar[9]" style="position:relative;top:-24px"></app-gaugechart>
			<app-polarchart *ngIf="greenindex_status && isRadar" [data]="carbon_radar" [dashboard]=true id="t10-polar" style="position:relative;top:-24px"></app-polarchart>
			
			<div *ngIf="isNoData">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
			<div *ngIf="!greenindex_status">
				<clr-alert [clrAlertType]="'warning'">
					<clr-alert-item>
						<span class="alert-text">
							Green-Index is disabled. Go to <button style="vertical-align:unset;margin:0" class="btn btn-sm btn-link" (click)="callRoute()">Green-Index</button>&nbsp;menu to participate and access data.
						</span>
					</clr-alert-item>
				</clr-alert>
				<div class="info-custom">
					<p><cds-icon size="24" shape="help-info"></cds-icon>
						<b>Green-Index is a collaborative feature that aims to evaluate and compare some data around Green IT.</b>
						<br><br>
						This feature allows you to observe the data of other users and compare yourself to others.
						<br>
						<i>Examples : is my average energy consumption high ? Do I have a good VM per server ratio ? ...</i>
					</p>
				</div>
			</div>
		</div>
	</div>
</div>