import {
    Injectable
} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {
    Observable
} from 'rxjs';

import {
    environment
} from '@environments/environment';
import {
    AccountService
} from '@app/services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AccountService) {}

    intercept(req: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
        const currentUser = this.authenticationService.currentUserValue;
        if (!req.headers.has('Content-Type')) {
            //req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Authorization': '12'
                }
            });
        }

        //req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        req = req.clone({
            setHeaders: {
                'Accept': 'application/json',
                'Authorization': '12'
            }
        });

        return next.handle(req);
    }
}
