<div class="card addScroll">
    <div class="card-header card-ajust">
        <h5>
            <cds-icon shape="applications"></cds-icon>
            &nbsp;Top-K processes over the last 24 hours
            <span class="label" *ngIf="data_process.length>0">{{data_process.length}}</span>
        </h5>
    </div>
    <div class="card-block">
        <div class="card-text">
            <div *ngIf="isReady">
                <!-- <div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
						<cds-icon class="is-solid" shape="server"></cds-icon>
						&nbsp;<span class="label" style="background:#476888;color:white;border-color:#476888;font-size:0.53rem">More energy-consuming process</span>
						<br>
						<label class="clr-control-label" style="padding-top:10px;margin-left:25px;word-wrap:anywhere;">{{worst_process.NAME}}&nbsp;({{worst_process.WH}}&nbsp;Wh)</label>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
						<cds-icon class="is-solid" shape="server"></cds-icon>
						&nbsp;<span class="label" style="background:white;color:black;font-size:0.53rem">Less energy-consuming process</span>
						<br>
						<label class="clr-control-label" style="padding-top:10px;margin-left:25px;word-wrap:anywhere;">{{best_process.NAME}}&nbsp;({{best_process.WH}}&nbsp;Wh)</label>
					</div>
				</div> -->
                <highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;height:280px;display:block;"></highcharts-chart>
            </div>
            <div *ngIf="!isReady">
                <strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
            </div> 
        </div>
    </div>
</div>
