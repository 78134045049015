import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    map
} from 'rxjs/operators';

import {
    environment
} from '@environments/environment';

import {
    DefaultValues
} from '@app/greenit/greenit.enums';
import {
    JSONTarget
} from '@app/model';
import {
    JsonloaderService
} from '@app/services/jsonloader.service';
import {
    DATA_ORDER,
    Tab,
    VirtindexDesc,
    VirtindexName,
    VirtindexProperty
} from '@app/virtIndex/virtIndex.enums';
import {
    BehaviorSubject,
    Observable
} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VirtindexService {

    virtindex_settings: any = [];
    virtindex_data: any = {};
    virtindex_status: boolean = false;

    private virtindexSettingsSubject: BehaviorSubject < any > = new BehaviorSubject < any > (this.virtindex_settings);
    virtindexSettings: Observable < any > = this.virtindexSettingsSubject.asObservable();

    private virtindexDataSubject: BehaviorSubject < any > = new BehaviorSubject < any > (this.virtindex_data);
    virtindexData: Observable < any > = this.virtindexDataSubject.asObservable();

    private virtindexStatusSubject: BehaviorSubject < any > = new BehaviorSubject < any > (this.virtindex_status);
    virtindexStatus: Observable < any > = this.virtindexStatusSubject.asObservable();

    constructor(private http: HttpClient, private json_svc: JsonloaderService) {}

    getVirtindexSettings(): void {
        this.http.get(`${environment.apiUrl}/` + 'management/getvirtindexsettings').pipe(map(settings => {
            this.virtindexSettingsSubject.next(settings);
            // Get settings
            for (let i in settings) {
                if (settings[i] != undefined) {
                    switch (settings[i].option) {
                        case 'status':
                            let greenindex_status = String(settings[i].value) == "true";
                            this.virtindexStatusSubject.next(greenindex_status);
                            break;
                        default:
                            break;
                    }
                }
            }
        })).subscribe(data => {}, error => {});
    }

    updateVirtindexSettings(settings: JSON) {
        this.http.post(`${environment.apiUrl}/` + 'management/updatevirtindexsettings', settings).subscribe(data => {
            // Get settings from server
            this.getVirtindexSettings()
        });
    }

    // For client infos
    private easyvirt_host: string = 'https://www.easyvirt.com/virtindex.php';

    private index_filter: string = "all_last30D";
    private index_user: string = "root";

    private customer_json: any;

    private rank_letters: Array < string > = ["A", "B", "C", "D", "E", "F", "G"];
    private rank_indice: number = 14.29;

    show_alert: boolean = undefined;
    alert_msg: string = "An error occurred while retrieving data ...";

    private all_data: any = {};

    /**
     *
     */
    getVirtindexData(): void {

        this.show_alert = undefined;
        this.all_data = {};

        // Get data from server
        this.json_svc.getData(this.index_user, this.index_filter, JSONTarget.VIRT_INDEX).subscribe(
            data => {
                this.customer_json = data;

                if (data) {
                    this.http.post(this.easyvirt_host, btoa(JSON.stringify(this.customer_json))).subscribe(
                        data => {
                            let server_data = data;

                            // Initialize objects from real data
                            this.buildInfra(server_data);
                            this.buildGreenit(server_data);
                            this.buildBehavior(server_data);

                            // Init alert
                            this.show_alert = false;

                            // Emit data
                            this.virtindexDataSubject.next(this.all_data);

                        },
                        error => {
                            this.pushError("Can not retrieve data from server !");
                        }
                    );
                } else {
                    this.pushError("No data to send to server !");
                }
            },
            error => {
                this.pushError("No data to send to server !");
            }
        );
    }

    /**
     *
     */
    private pushError(msg: string) {
        // Init alert
        this.show_alert = true;
        this.alert_msg = msg;

        // Emit changes
        this.virtindexDataSubject.next(this.all_data);
    }

    /**
     *
     */
    private compute(server_data: any, property: VirtindexProperty): any {

        let you = this.customer_json[property];

        // Convert data to number & sort it
        let data: Array < number > = server_data[property].map((x: any) => Number(x));
        data = data.sort((a, b) => (a - b));

        // Looking for name & desc
        let key = Object.keys(VirtindexProperty)[Object.values(VirtindexProperty).indexOf(property)];
        let name: VirtindexName = VirtindexName[key];
        let desc: VirtindexDesc = VirtindexDesc[key];


        let decimal = 2;
        let default_value = 0;
        let data_order = DATA_ORDER.ASC;

        let behavior_property = false;

        // For some properties, max is the best
        switch (property) {
            case VirtindexProperty.VM_CO2T:
            case VirtindexProperty.VM_CO2G:
            case VirtindexProperty.SERVER_CO2G:
            case VirtindexProperty.SERVER_KWH:
                decimal = 0;
                break;
            case VirtindexProperty.SERVER_CO2_COEFF:
                default_value = DefaultValues.DC_DIRECT_CO2;
                decimal = 4;
                break;
            case VirtindexProperty.SERVER_AGE:
                default_value = DefaultValues.HOST_AGE;
                data_order = DATA_ORDER.DESC;
                break;
            case VirtindexProperty.SERVER_PUE:
                default_value = DefaultValues.DC_PUE;
                break;
            case VirtindexProperty.VCPU_PERLOGICALCORE:
            case VirtindexProperty.VM_PERSERVER:
            case VirtindexProperty.VM_PERSOCKET:
            case VirtindexProperty.RAM_ALLOC_PERCENT:
            case VirtindexProperty.RAM_USE_PERCENT:
            case VirtindexProperty.CPU_USE_PERCENT:
                data_order = DATA_ORDER.DESC;
                break;
            case VirtindexProperty.IDLE:
            case VirtindexProperty.OVERSIZED:
            case VirtindexProperty.UNDERSIZED:
                behavior_property = true;
                decimal = 0;
                break;
            case VirtindexProperty.IDLE_EVOL:
            case VirtindexProperty.OVERSIZED_EVOL:
            case VirtindexProperty.UNDERSIZED_EVOL:
                behavior_property = true;
                decimal = 2;
                break;
            default:
                break;
        }

        // Sort data
        if (data_order == DATA_ORDER.DESC)
            data = data.sort((a, b) => (b - a));


        let alert_default = false;

        // Alert if default value detected (if not behavior)
        if (you == default_value && !behavior_property) {
            alert_default = true;
        }

        // Remove default values from data
        //let data_filtered = data.filter(num => num != default_value);
        let data_filtered = data

        // Get min,max,median
        let min = 0;
        let max = 0;
        let median = 0;

        if (data_filtered.length > 0) {
            min = Math.min(...data_filtered);
            max = Math.max(...data_filtered);
            median = this.median(data_filtered);
        }

        // Looking for rank & top
        let top = 100;
        let rank = data.length;

        // Ranking only if not default value (or behavior)
        if (data_filtered.length > 0) {
            let index = data_filtered.findIndex((element: number) => element == you);


            // FIX rounded value for VirtindexProperty.VM_CO2T
            if (index == -1 && property == VirtindexProperty.VM_CO2T) {

                let you_round = Number((Number(you) - 0.01).toFixed(2));
                index = data_filtered.findIndex((element: number) => element == you_round);

                if (index != -1)
                    you = you_round;

                else {
                    you_round = Number((Number(you) + 0.01).toFixed(2));
                    index = data_filtered.findIndex((element: number) => element == you_round);

                    if (index != -1)
                        you = you_round;
                }
            }

            if ((index != -1) && (you != default_value || behavior_property)) {
                rank = index + 1;

                switch (you) {
                    case min:
                        top = 1;
                        if (data_order == DATA_ORDER.DESC)
                            top = 100;
                        break;
                    case median:
                        top = 50;
                        break;
                    case max:
                        top = 100;
                        if (data_order == DATA_ORDER.DESC)
                            top = 1;
                        break;
                    default:
                        top = Number(((rank * 100) / data_filtered.length).toFixed(2));
                        break;
                }

            }
        }

        // Define top color
        let color = 'green';
        if (top > 75)
            color = 'red'
        else if (top > 25)
            color = 'orange';

        // Define rank letter
        let rank_letter = this.rank_letters[this.rank_letters.length - 1];
        let rank_value = Math.floor(top / this.rank_indice);

        if ((rank_value >= 0) && (rank_value <= this.rank_letters.length - 1))
            rank_letter = this.rank_letters[rank_value];

        return {
            name: name,
            desc: desc,
            top: top,
            rank: rank,
            rank_letter: rank_letter,
            min: Number(min.toFixed(decimal)),
            median: Number(median.toFixed(decimal)),
            max: Number(max.toFixed(decimal)),
            you: Number((this.customer_json[property]).toFixed(decimal)),
            alert_default: alert_default,
            color: color,
            data: data
        };

    }

    /**
     *
     */
    private median(values: Array < number > ): number {
        if (values.length == 0)
            return 0;
        else if (values.length == 1)
            return values[0];
        else if (values.length == 2)
            return (values[0] + values[1]) / 2;
        else
            return values[Math.floor(values.length / 2)];
    }

    /**
     *
     */
    private buildInfra(server_data: any): void {

        let vcpu_core = this.compute(server_data, VirtindexProperty.VCPU_PERLOGICALCORE);
        vcpu_core.tab = Tab.INFRA;

        let vm_server = this.compute(server_data, VirtindexProperty.VM_PERSERVER);
        vm_server.tab = Tab.INFRA;

        let vm_socket = this.compute(server_data, VirtindexProperty.VM_PERSOCKET);
        vm_socket.tab = Tab.INFRA;

        let ram_alloc_percent = this.compute(server_data, VirtindexProperty.RAM_ALLOC_PERCENT);
        ram_alloc_percent.tab = Tab.INFRA;

        let ram_use_percent = this.compute(server_data, VirtindexProperty.RAM_USE_PERCENT);
        ram_use_percent.tab = Tab.INFRA;

        let cpu_use_percent = this.compute(server_data, VirtindexProperty.CPU_USE_PERCENT);
        cpu_use_percent.tab = Tab.INFRA;

        // Push to infrastructure data
        this.all_data.vcpu = vcpu_core;
        this.all_data.server = vm_server;
        this.all_data.socket = vm_socket;
        this.all_data.ramallocpercent = ram_alloc_percent;
        this.all_data.ramusepercent = ram_use_percent;
        this.all_data.cpuusepercent = cpu_use_percent;

    }

    /**
     *
     */
    private buildGreenit(server_data: any): void {

        let watts_vm = this.compute(server_data, VirtindexProperty.VM_WATT);
        watts_vm.tab = Tab.GREENIT;

        let kwh_server = this.compute(server_data, VirtindexProperty.SERVER_KWH);
        kwh_server.tab = Tab.GREENIT;

        let carbon_grey_server = this.compute(server_data, VirtindexProperty.SERVER_CO2G);
        carbon_grey_server.tab = Tab.GREENIT;

        let carbon_grey_vm = this.compute(server_data, VirtindexProperty.VM_CO2G);
        carbon_grey_vm.tab = Tab.GREENIT;

        let carbon_total_vm = this.compute(server_data, VirtindexProperty.VM_CO2T);
        carbon_total_vm.tab = Tab.GREENIT;

        let age_server = this.compute(server_data, VirtindexProperty.SERVER_AGE);
        age_server.tab = Tab.GREENIT;

        let pue_server = this.compute(server_data, VirtindexProperty.SERVER_PUE);
        pue_server.tab = Tab.GREENIT;

        let coeffco2_server = this.compute(server_data, VirtindexProperty.SERVER_CO2_COEFF);
        coeffco2_server.tab = Tab.GREENIT;

        // Push to greenit data
        this.all_data.ageserver = age_server;
        this.all_data.pueserver = pue_server;
        this.all_data.coeffco2server = coeffco2_server;
        this.all_data.wattsvm = watts_vm;
        this.all_data.co2gvm = carbon_grey_vm;
        this.all_data.co2tvm = carbon_total_vm;
        this.all_data.co2gserver = carbon_grey_server;
        this.all_data.kwhserver = kwh_server;

    }

    /**
     *
     */
    private buildBehavior(server_data: any): void {

        let idle = this.compute(server_data, VirtindexProperty.IDLE);
        idle.tab = Tab.BEHAVIOR;
        idle.min_color = '#e06666';
        idle.avg_color = '#ea9999';

        let over = this.compute(server_data, VirtindexProperty.OVERSIZED);
        over.tab = Tab.BEHAVIOR;
        over.min_color = '#ffd966';
        over.avg_color = '#ffe599';

        let under = this.compute(server_data, VirtindexProperty.UNDERSIZED);
        under.tab = Tab.BEHAVIOR;
        under.min_color = '#999999';
        under.avg_color = '#bcbcbc';

        let idle_evol = this.compute(server_data, VirtindexProperty.IDLE_EVOL);
        idle_evol.tab = Tab.BEHAVIOR;
        idle_evol.min_color = '#e06666';
        idle_evol.avg_color = '#ea9999';

        let over_evol = this.compute(server_data, VirtindexProperty.OVERSIZED_EVOL);
        over_evol.tab = Tab.BEHAVIOR;
        over_evol.min_color = '#ffd966';
        over_evol.avg_color = '#ffe599';

        let under_evol = this.compute(server_data, VirtindexProperty.UNDERSIZED_EVOL);
        under_evol.tab = Tab.BEHAVIOR;
        under_evol.min_color = '#999999';
        under_evol.avg_color = '#bcbcbc';

        // Push to behavior data
        this.all_data.idle = idle;
        this.all_data.over = over;
        this.all_data.under = under;
        this.all_data.idle_evol = idle_evol;
        this.all_data.over_evol = over_evol;
        this.all_data.under_evol = under_evol;

    }
}
