<ng-template #templateSelectedResources let-item="item">
  <span [attr.class]="item?.resourceType == 'host' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ item?.resourceType }}</span>
  {{ item?.name }}
</ng-template>

<ng-template #templateDisplayResources let-item='item'>
  <span class="badge" [attr.class]="item?.resourceType == 'host' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ item?.resourceType }}</span>
  {{ item.name }}
</ng-template>

<ng-template #templateDisplayResourcesWhenEmpty>
  <clr-control-helper slot="displayWhenEmpty">Type a resource name</clr-control-helper>
</ng-template>

<ng-template #templateDisplayResourcesWhenErrors>
  <clr-control-error slot="displayWhenError">There was an error</clr-control-error>
</ng-template>

<ng-template #templateSelectedCounters let-item="item">
  <cds-icon shape="line-chart" role="img" aria-label="welcome home" size="12"></cds-icon>
  {{ item?.label }}
  <span class="badge" *ngFor="let ressourceType of item.description.appliesTo" [attr.class]="ressourceType == 'HOST' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ ressourceType | lowercase}}</span>
</ng-template>

<ng-template #templateDisplayCounters let-item='item'>
  {{ item.label }}
  <span class="badge" *ngFor="let ressourceType of item.description.appliesTo" [attr.class]="ressourceType == 'HOST' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ ressourceType | lowercase}}</span>
</ng-template>

<ng-template #templateDisplayCountersWhenEmpty>
  <clr-control-helper slot="displayWhenEmpty">Type a counter name</clr-control-helper>
</ng-template>

<ng-template #templateDisplayCountersWhenErrors>
  <clr-control-error slot="displayWhenError">There was an error</clr-control-error>
</ng-template>

<div class="card">
  <div class="card-header">

    <div class="clr-row">

      <div class="clr-col-10">
        Selected resources
      </div>

      <div class="clr-col-2">
        <clr-toggle-container style="float: right; margin-top: 0px;">
          <clr-toggle-wrapper>
            <input type="checkbox" clrToggle value="option1" name="options" [(ngModel)]="graphMergedOption" (change)="graphModeChanged()"/>
            <label>Merged graphs</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
      </div>

    </div>
  </div>

  <div class="card-block">
    <app-graph-on-demand-combobox
      [(selectedItems)]="selectedResources"
      [itemsGenerator]="fetchResources"
      (onChange)="reloadUi('*')"
      [templateSelectedItems]="templateSelectedResources"
      [templateDisplayItems]="templateDisplayResources"
      [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
      [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
      [itemsField]="'name'"
      #resourceCombobox
    >
    </app-graph-on-demand-combobox>
  </div>
</div>

<div class="card" [hidden]="!graphMergedOption">
  <div class="card-header">
    <app-graph-on-demand-combobox
            [(selectedItems)]="selectedCountersAll"
            [itemsGenerator]="fetchCountersAll"
            (onChange)="syncAllComboboxes(false, 'all');reloadUi('*')"
            [templateSelectedItems]="templateSelectedCounters"
            [templateDisplayItems]="templateDisplayCounters"
            [templateDisplayWhenEmpty]="templateDisplayCountersWhenEmpty"
            [templateDisplayWhenErrors]="templateDisplayCountersWhenErrors"
            [itemsField]="'label'"
    >
    </app-graph-on-demand-combobox>
  </div>
  <div class="card-block">
    <app-graph-on-demand-graph id="graphAll"
                               [fetchNavigatorData]="fetchNavigatorDataAll"
                               [fetchGraphData]="fetchDataAll"
                               [callBackWhenCreated]="chartCallbackAll"
                               [selectedResources]="selectedResources"
                               [selectedCounters]="selectedCountersAll"
                               [onSetExtremesChange]="onSetExtremesChange"
                               [thisGraphCategory]="'all'"
                               #allGraph>
    </app-graph-on-demand-graph>
  </div>
</div>

<div class="clr-row" [hidden]="graphMergedOption">
  <div class="clr-col-lg-6 clr-col-12">
    <div class="card">
      <div class="card-header">
        <app-graph-on-demand-combobox
          [(selectedItems)]="selectedCountersRam"
          [itemsGenerator]="fetchCountersRam"
          (onChange)="syncAllComboboxes(false, 'ram');reloadUi('ram')"
          [templateSelectedItems]="templateSelectedCounters"
          [templateDisplayItems]="templateDisplayCounters"
          [templateDisplayWhenEmpty]="templateDisplayCountersWhenEmpty"
          [templateDisplayWhenErrors]="templateDisplayCountersWhenErrors"
          [itemsField]="'label'">
        </app-graph-on-demand-combobox>
      </div>
      <div class="card-block">
        <app-graph-on-demand-graph id="graphRam"
                                   [fetchNavigatorData]="fetchNavigatorDataRam"
                                   [fetchGraphData]="fetchDataRam"
                                   [callBackWhenCreated]="chartCallbackRam"
                                   [selectedResources]="selectedResources"
                                   [selectedCounters]="selectedCountersRam"
                                   [onSetExtremesChange]="onSetExtremesChange"
                                   [thisGraphCategory]="'ram'"
                                   #ramGraph>
        </app-graph-on-demand-graph>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-12">
    <div class="card">
      <div class="card-header">

        <app-graph-on-demand-combobox
                [(selectedItems)]="selectedCountersCpu"
                [itemsGenerator]="fetchCountersCpu"
                (onChange)="syncAllComboboxes(false, 'cpu');reloadUi('cpu')"
                [templateSelectedItems]="templateSelectedCounters"
                [templateDisplayItems]="templateDisplayCounters"
                [templateDisplayWhenEmpty]="templateDisplayCountersWhenEmpty"
                [templateDisplayWhenErrors]="templateDisplayCountersWhenErrors"
                [itemsField]="'label'"
        >
        </app-graph-on-demand-combobox>

      </div>
      <div class="card-block">
        <app-graph-on-demand-graph id="graphCpu"
                                   [fetchNavigatorData]="fetchNavigatorDataCpu"
                                   [fetchGraphData]="fetchDataCpu"
                                   [callBackWhenCreated]="chartCallbackCpu"
                                   [selectedResources]="selectedResources"
                                   [selectedCounters]="selectedCountersCpu"
                                   [onSetExtremesChange]="onSetExtremesChange"
                                   [thisGraphCategory]="'cpu'"
                                   #cpuGraph
        >
        </app-graph-on-demand-graph>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-12">
    <div class="card">
      <div class="card-header">
        <app-graph-on-demand-combobox
          [(selectedItems)]="selectedCountersDisk"
          [itemsGenerator]="fetchCountersDisk"
          (onChange)="syncAllComboboxes(false, 'disk');reloadUi('disk')"
          [templateSelectedItems]="templateSelectedCounters"
          [templateDisplayItems]="templateDisplayCounters"
          [templateDisplayWhenEmpty]="templateDisplayCountersWhenEmpty"
          [templateDisplayWhenErrors]="templateDisplayCountersWhenErrors"
          [itemsField]="'label'"
        >
        </app-graph-on-demand-combobox>
      </div>
      <div class="card-block">
        <app-graph-on-demand-graph id="graphDisk"
                                   [fetchNavigatorData]="fetchNavigatorDataDisk"
                                   [fetchGraphData]="fetchDataDisk"
                                   [callBackWhenCreated]="chartCallbackDisk"
                                   [selectedResources]="selectedResources"
                                   [selectedCounters]="selectedCountersDisk"
                                   [onSetExtremesChange]="onSetExtremesChange"
                                   [thisGraphCategory]="'disk'"
                                   #diskGraph
        >
        </app-graph-on-demand-graph>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-12">
    <div class="card">
      <div class="card-header">
        <app-graph-on-demand-combobox
          [(selectedItems)]="selectedCountersNetwork"
          [itemsGenerator]="fetchCountersNetwork"
          (onChange)="syncAllComboboxes(false, 'network');reloadUi('network')"
          [templateSelectedItems]="templateSelectedCounters"
          [templateDisplayItems]="templateDisplayCounters"
          [templateDisplayWhenEmpty]="templateDisplayCountersWhenEmpty"
          [templateDisplayWhenErrors]="templateDisplayCountersWhenErrors"
          [itemsField]="'label'"
        >
        </app-graph-on-demand-combobox>
      </div>
      <div class="card-block">
        <app-graph-on-demand-graph id="graphNetwork"
                                   [fetchNavigatorData]="fetchNavigatorDataNetwork"
                                   [fetchGraphData]="fetchDataNetwork"
                                   [callBackWhenCreated]="chartCallbackNetwork"
                                   [selectedResources]="selectedResources"
                                   [selectedCounters]="selectedCountersNetwork"
                                   [onSetExtremesChange]="onSetExtremesChange"
                                   [thisGraphCategory]="'network'"
                                   #networkGraph
      >
      </app-graph-on-demand-graph>
      </div>
    </div>
  </div>

</div>

