<div class="clr-row">
    <div style="width:50%;float:left">
        <app-upload #uploadmodal [target]="report"></app-upload>
    </div>
    <div style="width:50%;float:right">
        <app-reportcard (info)="displayInfo('Error', generation_error, $event)" (update)="updateHistory()" [target]="custom"  [data]="objects_subject.asObservable()"></app-reportcard>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-lg-3 clr-col-md-6 clr-col-12" *ngIf="showManage()">
        <app-reportcard (info)="displayInfo('Error', generation_error, $event)" (update)="updateHistory()" [target]="management" [data]="objects_subject.asObservable()"></app-reportcard>
    </div>
    <div class="clr-col-lg-3 clr-col-md-6 clr-col-12" *ngIf="showOps()">
        <app-reportcard (info)="displayInfo('Error', generation_error, $event)" (update)="updateHistory()" [target]="operations" [data]="objects_subject.asObservable()"></app-reportcard>
    </div>
    <div class="clr-col-lg-3 clr-col-md-6 clr-col-12" *ngIf="showReco()">
        <app-reportcard (info)="displayInfo('Error', generation_error, $event)" (update)="updateHistory()" [target]="recommendations" [data]="objects_subject.asObservable()"></app-reportcard>
    </div>
    <div class="clr-col-lg-3 clr-col-md-6 clr-col-12" *ngIf="showGreenit()">
        <app-reportcard (info)="displayInfo('Error', generation_error, $event)" (update)="updateHistory()" [target]="greenit" [data]="objects_subject.asObservable()"></app-reportcard>
    </div>
    <div><app-reportcard hidden #editcard (update)="updateHistory()" [target]="edit" [data]="objects_subject.asObservable()"></app-reportcard></div>
</div>

<div class="clr-row" style="margin-top:30px">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <button (click)="previousYear()" class="btn btn-link" [disabled]="noprevious_year">
            <cds-icon shape="angle" style="transform:rotate(-90deg)"></cds-icon>
        </button>
        &nbsp;&nbsp;
        <span>{{current_year}}</span>
        &nbsp;&nbsp;
        <button (click)="nextYear()" class="btn btn-link" [disabled]="nonext_year">
            <cds-icon shape="angle" style="transform:rotate(90deg)"></cds-icon>
        </button>
    </div>
</div>

<clr-tabs>
	<clr-tab>
        <button clrTabLink (click)="updateDatatable(1)" [disabled]="badges.jan == 0">January&nbsp;<span style="width:17px" [hidden]="badges.jan == 0" class="badge badge-blue">{{badges.jan}}</span></button>
        <clr-tab-content *clrIfActive="badges.jan_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(2)" [disabled]="badges.feb == 0">February&nbsp;<span style="width:17px" [hidden]="badges.feb == 0" class="badge badge-blue">{{badges.feb}}</span></button>
        <clr-tab-content *clrIfActive="badges.feb_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(3)" [disabled]="badges.mar == 0">March&nbsp;<span style="width:17px" [hidden]="badges.mar == 0" class="badge badge-blue">{{badges.mar}}</span></button>
        <clr-tab-content *clrIfActive="badges.mar_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(4)" [disabled]="badges.apr == 0">April&nbsp;<span style="width:17px" [hidden]="badges.apr == 0" class="badge badge-blue">{{badges.apr}}</span></button>
        <clr-tab-content *clrIfActive="badges.apr_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(5)" [disabled]="badges.may == 0">May&nbsp;<span style="width:17px" [hidden]="badges.may == 0" class="badge badge-blue">{{badges.may}}</span></button>
        <clr-tab-content *clrIfActive="badges.may_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(6)" [disabled]="badges.jun == 0">June&nbsp;<span style="width:17px" [hidden]="badges.jun == 0" class="badge badge-blue">{{badges.jun}}</span></button>
        <clr-tab-content *clrIfActive="badges.jun_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(7)" [disabled]="badges.jul == 0">July&nbsp;<span style="width:17px" [hidden]="badges.jul == 0" class="badge badge-blue">{{badges.jul}}</span></button>
        <clr-tab-content *clrIfActive="badges.jul_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(8)" [disabled]="badges.aug == 0">August&nbsp;<span style="width:17px" [hidden]="badges.aug == 0" class="badge badge-blue">{{badges.aug}}</span></button>
        <clr-tab-content *clrIfActive="badges.aug_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(9)" [disabled]="badges.sep == 0">September&nbsp;<span style="width:17px" [hidden]="badges.sep == 0" class="badge badge-blue">{{badges.sep}}</span></button>
        <clr-tab-content *clrIfActive="badges.sep_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(10)" [disabled]="badges.oct == 0">October&nbsp;<span style="width:17px" [hidden]="badges.oct == 0" class="badge badge-blue">{{badges.oct}}</span></button>
        <clr-tab-content *clrIfActive="badges.oct_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(11)" [disabled]="badges.nov == 0">November&nbsp;<span style="width:17px" [hidden]="badges.nov == 0" class="badge badge-blue">{{badges.nov}}</span></button>
        <clr-tab-content *clrIfActive="badges.nov_tab"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="updateDatatable(12)" [disabled]="badges.dec == 0">December&nbsp;<span style="width:17px" [hidden]="badges.dec == 0" class="badge badge-blue">{{badges.dec}}</span></button>
        <clr-tab-content *clrIfActive="badges.dec_tab"></clr-tab-content>
    </clr-tab>
</clr-tabs>

<div style="margin-top:10px;overflow:auto;">
    <clr-datagrid>
        <clr-dg-action-bar style="margin-top:0px">
            <div class="btn-group" style="float:right">
                <!-- <button type="button" class="btn btn-sm btn-link" (click)="exportIndicators()">
                    Export to CSV
                </button> -->
            </div>
        </clr-dg-action-bar>
        <clr-dg-column [clrDgField]="'time'" [clrDgSortOrder]="dataOrderDesc"><ng-container *clrDgHideableColumn="{hidden: false}">Date</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'filter'"><ng-container *clrDgHideableColumn="{hidden: false}">Filter or entity</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'auto'"><ng-container *clrDgHideableColumn="{hidden: false}">Automatic</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'state'"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
        <clr-dg-column ><ng-container *clrDgHideableColumn="{hidden: false}">Action</ng-container></clr-dg-column>                                             
        
        <clr-dg-row *clrDgItems="let element of datatable_data" [clrDgItem]="element">
            <clr-dg-cell>{{formatDate(element.time)}}</clr-dg-cell>
            <clr-dg-cell>{{element.name}}</clr-dg-cell>
            <clr-dg-cell>{{formatFilter(element.filter, element.type)}}</clr-dg-cell>
            <clr-dg-cell>{{element.type}}</clr-dg-cell>
            <clr-dg-cell>{{element.auto}}</clr-dg-cell>
            <clr-dg-cell>{{element.state}}</clr-dg-cell>
            <clr-dg-cell>
                <a style="cursor: pointer;color:grey" (click)="getReport(element.name, element.uuid, pdf)"><fa-icon title="download as PDF" [icon]="['fas', 'file-pdf']"></fa-icon></a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a  style="cursor: pointer;color:grey" (click)="getReport(element.name, element.uuid, xls)"><fa-icon title="download as XLS" [icon]="['fas', 'file-excel']"></fa-icon></a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <cds-icon title="edit" (click)="createComponent(element.uuid)" shape='pencil' style="cursor:pointer;width:15px"></cds-icon>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <cds-icon title="delete" (click)="displayModalDeleteReport(element.uuid)" shape='trash' style="cursor:pointer;width:15px"></cds-icon>
                &nbsp;&nbsp;&nbsp;&nbsp;
            </clr-dg-cell>
        </clr-dg-row>                  
        <clr-dg-footer>
            <clr-dg-pagination #report_page [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                {{report_page.firstItem + 1}} - {{report_page.lastItem + 1}} of {{report_page.totalItems}} entries
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>

</div>

<!-- REPORT DELETE -->
<clr-modal [(clrModalOpen)]="delete_modal_report" [clrModalClosable]=false>
    <h3 class="modal-title">Delete report</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>This report will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="deleteReport()">Delete</button>
    </div>
</clr-modal>

<!-- INFO MODAL -->
<clr-modal [(clrModalOpen)]="info_modal" [clrModalClosable]=false [clrModalSize]="'sm'">
    <h3 class="modal-title">{{info_header}}</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;text-align:justify;">
            <span>{{info_body}}</span>
        </div>
        <div *ngIf="info_type" style="width:100%;margin-top:20px;text-align:justify;font-size:12px;">
            <span *ngIf="info_type == 'vm'">{{generation_vm_error}}</span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Ok</button>
    </div>
</clr-modal>
