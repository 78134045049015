import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-t11]'
})
export class T11Directive {

    constructor(public viewContainerRef11: ViewContainerRef) {}

}
