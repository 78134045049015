import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    BehaviorSubject,
    Observable
} from 'rxjs';
import {
    first,
    map
} from 'rxjs/operators';

import * as CryptoJS from 'crypto-js';

import {
    UntypedFormGroup
} from '@angular/forms';
import {
    ADSettings,
    Alias,
    AliasRec,
    ConnectType,
    Role,
    User,
    UserRec
} from '@app/model';
import {
    environment
} from '@environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AccountService {

    init_user: User = {
        login: '',
        alias: '',
        currency: '',
        role: '',
        token: '',
        last_co: 0,
        email: '',
        isMenuCloud: false,
        isMenuKpi: false,
        isMenuAlert: false,
        isMenuTrouble: false,
        isMenuCapa: false,
        isMenuGod: false,
        isMenuReco: false,
        isMenuReport: false,
        isMenuGreenit: false,
        isMenuDCNetScope: false,
        isMenuVcloud: false,
        isMenuVirtindex: false,
        isMenuExternalCloud: false,
        isMenuKubernetes: false,
        isDashboardT1: false,
        isDashboardT2: false,
        isDashboardT3: false,
        isDashboardT4: false,
        isDashboardT5: false,
        isDashboardT6: false
    };

    private currentUserSubject: BehaviorSubject < User > = new BehaviorSubject < User > (this.init_user);
    public user: Observable < User > = this.currentUserSubject.asObservable();

    constructor(private http: HttpClient) {}

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public changeUser(user: User): void {
        this.currentUserSubject.next(user)
    }

    crypt(password: string): string {
        var iv = CryptoJS.enc.Utf8.parse('btrCloud');
        var key = CryptoJS.enc.Hex.parse('bcb04b7e103a0cd8b57853051cdd08bc55abe029fbabae5e1d417e2ffb2a00a3');
        let hash = CryptoJS.AES.encrypt(password, key, {
            mode: CryptoJS.mode.CBC,
            iv: iv,
            padding: CryptoJS.pad.Pkcs7
        }).toString();

        return hash;
    }

    decrypt(password: string): string {
        var iv = CryptoJS.enc.Utf8.parse('btrCloud');
        var key = CryptoJS.enc.Hex.parse('bcb04b7e103a0cd8b57853051cdd08bc55abe029fbabae5e1d417e2ffb2a00a3');
        let hash = CryptoJS.AES.decrypt(password, key, {
            mode: CryptoJS.mode.CBC,
            iv: iv,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);

        return hash;
    }

    getLoginType() {
        return this.http.get < String > (`${environment.apiUrl}/user/getlogintype`).pipe(map(result => {
            return result;
        }));
    }

    login(login: string, password: string, type: ConnectType) {
        return this.http.post < User > (`${environment.apiUrl}/auth/login`, {
            "username": login,
            "password": password,
            "type": type
        }).pipe(map(user => {
            localStorage.setItem('user', JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
        }));
    }

    confirmAlias(alias: string, user: string) {
        return this.http.post < User > (`${environment.apiUrl}/auth/postlogin`, {
            "alias": alias,
            "user": user,
        }).pipe(map(
            user => {
                // XXX Keep token obtained previously
                let token = this.init_user.token;
                let user_data = user;
                user_data.token = token;
                localStorage.setItem('user', JSON.stringify(user_data));
                this.currentUserSubject.next(user_data);
                return user_data;
            }
        ));
    }

    logout(): void {
        this.init_user.login = "";
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.currentUserSubject.next(this.init_user);
    }

    reload() {
        if (JSON.parse(localStorage.getItem('user')) != null) {
            if (JSON.parse(localStorage.getItem('user')).login != "") {
                //this.init_user.uuid =  JSON.parse(localStorage.getItem('user')).uuid;
                this.currentUserSubject.next(JSON.parse(localStorage.getItem('user')));
                //alert(this.currentUserSubject.value.uuid);
            }
        }
    }

    private checkToken() {
        return this.http.get(`${environment.apiUrl}/auth/checktoken`, {
            responseType: 'text'
        });
    }

    async isLogged(): Promise < boolean > {
        return new Promise((resolve, reject) => {
            this.checkToken().pipe(first()).subscribe(
                success => {
                    resolve(true);
                },
                error => {
                    resolve(false);
                }
            );
        })
    }

    // ALIAS PART
    addAlias(alias: AliasRec) {
        return this.http.post < any > (`${environment.apiUrl}/user/addalias`, alias).pipe(map(result => {
            return result;
        }));
    }

    getAliasList() {
        return this.http.get < Alias[] > (`${environment.apiUrl}/user/aliases`).pipe(map(result => {
            return result;
        }));
    }

    getAliasUser(user: User) {
        return this.http.post < Alias[] > (`${environment.apiUrl}/user/getaliasuser`, [user.login]).pipe(map(result => {
            return result;
        }));
    }

    updateAlias(alias: AliasRec) {
        return this.http.put < any > (`${environment.apiUrl}/user/updatealias`, alias).pipe(map(result => {
            return result;
        }));
    }

    deleteAlias(alias: Alias) {
        return this.http.put < any > (`${environment.apiUrl}/user/delalias`, alias).pipe(map(result => {
            return result;
        }));
    }

    // USER PART
    getUserList() {
        return this.http.get < User[] > (`${environment.apiUrl}/user/users`).pipe(map(user => {
            return user;
        }));
    }

    addUser(user: UserRec) {
        return this.http.post < any > (`${environment.apiUrl}/user/adduser`, user).pipe(map(result => {
            return result;
        }));
    }

    checkUser(user: string, mode: string) {
        // Add timeago
        let timeago = new Date().getTime();

        return this.http.post < any > (`${environment.apiUrl}/user/checkuser`, [user, mode, timeago]).pipe(map(
            result => {
                return result;
            }));
    }

    updateUser(user: UserRec) {
        return this.http.put < any > (`${environment.apiUrl}/user/updateuser`, user).pipe(map(result => {
            return result;
        }));
    }

    updateCurrencyUser(rate: number) {
        return this.http.post < any > (`${environment.apiUrl}/user/updatecurrency`, [rate]).pipe(map(result => {
            return result;
        }));
    }

    deleteUser(user: User) {
        return this.http.put < any > (`${environment.apiUrl}/user/deluser`, user).pipe(map(result => {
            return result;
        }));
    }

    // ROLE PART
    checkUserRole(role: string) {
        return this.http.get < UserRec[] > (`${environment.apiUrl}/user/` + 'checkuserrole?role=' + role).pipe(map(
            names => {
                return names;
            }));
    }

    getRoleList() {
        return this.http.get < Role[] > (`${environment.apiUrl}/user/roles`).pipe(map(role => {
            return role;
        }));
    }

    addRole(role: Role) {
        return this.http.post < any > (`${environment.apiUrl}/user/addrole`, role).pipe(map(result => {
            return result;
        }));
    }

    updateRole(role: Role) {
        return this.http.put < any > (`${environment.apiUrl}/user/updaterole`, role).pipe(map(result => {
            return result;
        }));
    }

    deleteRole(role: Role) {
        return this.http.put < any > (`${environment.apiUrl}/user/delrole`, role).pipe(map(result => {
            return result;
        }));
    }

    // AD PART
    getADSettings() {
        return this.http.get(`${environment.apiUrl}/user/adsettings`).pipe(map(list => {
            return list;
        }));
    }

    getADInfo(form: UntypedFormGroup) {
        return this.http.post(`${environment.apiUrl}/user/adinfos`, [form.value.login, this.crypt(form.value
            .password)]);
    }

    updateADSettings(settings: JSON) {
        return this.http.post < ADSettings[] > (`${environment.apiUrl}/user/updateadsettings`, settings);
    }
}
