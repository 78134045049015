<clr-tabs>
	<clr-tab>
        	<button clrTabLink (click)="setDashboard()">ALERT DASHBOARD</button>
		<clr-tab-content *clrIfActive="true">
			<div class="clr-row">
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
					<div class="card">
						<div class="card-header card-ajust">
							<h5>
								<cds-icon shape="clock"></cds-icon>
								&nbsp;REAL TIME ANALYSIS
							</h5>
						</div>
						<div class="card-block">
							<div class="clr-row">
								<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
									<div class="card">
										<div class="card-block">
											<h5 class="card-title" style="text-align:center;color:grey;font-size:14px;font-weight:700;">ALERTS</h5>
											<div class="card-text">
												<div class="clr-row">
													<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
														<span class="label" style="background-color:#c21d00;border-color:#c21d00;width:100%;height:100px;font-size:13px;color:white;font-weight:700;display:block;" (click)="getAlertDetail('rt-critical')">
															<div>&nbsp;</div>
															CRITICAL
															<div>&nbsp;</div>
															<br>
															<label style="font-size:40px;color:white;text-align:center;font-weight:700;display: block">
																{{message.alertWatcher.rt_cri}}
															</label>
														</span>
													</div>
													<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
														<span class="label" style="background-color:#c25400;border-color:#c25400;width:100%;height:100px;font-size:13px;color:white;font-weight:700;display:block" (click)="getAlertDetail('rt-warning')">
															<div>&nbsp;</div>
															WARNING
															<div>&nbsp;</div>
															<br>
															<label style="font-size:40px;color:white;text-align:center;font-weight:700;display: block">
																{{message.alertWatcher.rt_warn}}
															</label>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
									<div class="card">
										<div class="card-block">
											<h5 class="card-title" style="text-align:center;color:grey;font-size:14px;font-weight:700;">VM & SERVER</h5>
											<div class="card-text">
												<span class="label" style="background-color:#3c8500;border-color:#3c8500;width:100%;height:100px;font-size:13px;color:white;font-weight:700;text-align:center;display:block;" (click)="getRunElement('min')">
													<div>&nbsp;</div>
													RUNNING<br>W/O ALERT
													<div>&nbsp;</div>
													<br>
													<label style="font-size:40px;color:white;text-align:center;font-weight:700;display: block">
														{{message.alertWatcher.rt_run}}
													</label>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>		
						</div>
					</div>
				</div>
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
					<div class="card">
						<div class="card-header card-ajust">
							<h5>
								<cds-icon shape="filter"></cds-icon>
								&nbsp;FILTER ANALYSIS
							</h5>
						</div>
						<div class="card-block">
							<div class="clr-row">
								<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
									<div class="card">
										<div class="card-block">
											<h5 class="card-title" style="text-align:center;color:grey;font-size:14px;font-weight:700;">ALERTS</h5>
											<div class="card-text">
												<div class="clr-row">
													<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
														<span class="label" style="background-color:#c21d00;border-color:#c21d00;width:100%;height:100px;font-size:13px;color:white;font-weight:700;display:block;" (click)="getAlertDetail('ft-critical')">
															<div>&nbsp;</div>
															CRITICAL
															<div>&nbsp;</div>
															<br>
															<label style="font-size:40px;color:white;text-align:center;font-weight:700;display: block">
																{{message.alertWatcher.dy_cri}}
															</label>
														</span>
													</div>
													<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
														<span class="label" style="background-color:#c25400;border-color:#c25400;width:100%;height:100px;font-size:13px;color:white;font-weight:700;display:block" (click)="getAlertDetail('ft-warning')">
															<div>&nbsp;</div>
															WARNING
															<div>&nbsp;</div>
															<br>
															<label style="font-size:40px;color:white;text-align:center;font-weight:700;display: block">
																{{message.alertWatcher.dy_warn}}
															</label>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
									<div class="card">
										<div class="card-block">
											<h5 class="card-title" style="text-align:center;color:grey;font-size:14px;font-weight:700;">FILTER</h5>
											<div class="card-text">
												<span class="label" style="background-color:#3c8500;border-color:#3c8500;width:100%;height:100px;font-size:13px;color:white;font-weight:700;text-align:center;display:block;" (click)="getRunElement('day')">
													<div>&nbsp;</div>
													RUNNING<br>W/O ALERT
													<div>&nbsp;</div>
													<br>
													<label style="font-size:40px;color:white;text-align:center;font-weight:700;display: block">
														{{message.alertWatcher.dy_run}}
													</label>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>		
						</div>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="showAlert">
					<div class="card">
						<div class="card-header card-ajust">
							<h5>
								<cds-icon shape="bell"></cds-icon>
								&nbsp;{{title_detail}} ANALYSIS - THE ELEMENTS LIST IN {{title_state}} VIGILANCE
							</h5>
							<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">&nbsp;</div>
							<span class="search"></span>
						</div>
						<div class="card-block">
							<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
							<ng-template load-table-state></ng-template>
						</div>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="showElement">
					<div class="card">
						<div class="card-header card-ajust">
							<h5>
								<cds-icon shape="bell"></cds-icon>
								&nbsp;{{title_detail}} ANALYSIS - THE RUNNING ELEMENTS WITHOUT ALERT
							</h5>
							<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">&nbsp;</div>
							<span class="search"></span>
						</div>
						<div class="card-block">
							<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
							<ng-template load-table-run></ng-template>
						</div>
					</div>
				</div>
			</div>
        	</clr-tab-content>
    	</clr-tab>
	<clr-tab>
        	<button clrTabLink (click)="setAlerts()">ALERT</button>
		<clr-tab-content *clrIfActive="false">
			<ng-template load-alerting-alert></ng-template>
        	</clr-tab-content>
    	</clr-tab>
	<clr-tab>
        	<button clrTabLink (click)="setNotification()">NOTIFICATION</button>
		<clr-tab-content *clrIfActive="false">
			<ng-template load-alerting-notification></ng-template>
        	</clr-tab-content>
    	</clr-tab>
	<clr-tab>
        	<button clrTabLink (click)="setHistory()">HISTORY</button>
		<clr-tab-content *clrIfActive="false">
			<div class="card">
				<div class="card-header card-ajust">
					<h5>
						<cds-icon shape="line-chart"></cds-icon>
						&nbsp;WHAT HAPPENED
					</h5>
				</div>
				<div class="card-block">
					<highcharts-chart
						[Highcharts]="Highcharts"
						[options]="chartOptions"
						[constructorType]="'stockChart'"
						style="width: 100%;display: block;" *ngIf="isReady">
					</highcharts-chart>
				</div>
			</div>
			<div class="card" *ngIf="isHistoryDay">
				<div class="card-header card-ajust">
					<h5>
						<cds-icon shape="bell"></cds-icon>
						&nbsp;THE ALERTED ITEMS LIST FOR {{history_day}} 
					</h5>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<span class="search"></span>
				</div>
				<div class="card-block">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<ng-template load-table></ng-template>
				</div>
			</div>
        	</clr-tab-content>
    	</clr-tab>
</clr-tabs>
