<clr-combobox-container>
    <clr-combobox class="full-width-input" clrMulti="true" [(ngModel)]="selectedElements"
        [clrLoading]="loadingResources" (focus)="_focus()" (clrInputChange)="_clrInputChange($event)"
        (clrOpenChange)="_clrOpenChange($event)" (click)="_click($event)" (clrSelectionChange)="_clrSelectionChange($event)"
        #combobox>

        <ng-container *clrOptionSelected="let selected">
            <span
                [attr.class]="selected?.resourceType.toUpperCase() == 'HOST' || selected?.resourceType.toUpperCase() == 'REGION' || selected?.resourceType.toUpperCase() == 'NODE' || selected?.resourceType.toUpperCase() == 'XCLARITY_NODE' ? 'badge badge-blue' : 'badge badge-light-blue'">
                {{selected?.resourceType }}</span>
            {{ selected?.name }}
        </ng-container>

        <clr-options *ngIf="combobox_showList">
            <clr-option *clrOptionItems="let item of items | async; field: 'name'" [clrValue]="item">
                <span class="badge"
                    [attr.class]="item?.resourceType.toUpperCase() == 'HOST' || item?.resourceType.toUpperCase() == 'REGION' || item?.resourceType.toUpperCase() == 'NODE' || item?.resourceType.toUpperCase() == 'XCLARITY_NODE' ? 'badge badge-blue' : 'badge badge-light-blue'">{{
                    item?.resourceType }}</span>
                {{ item.name }}
            </clr-option>
        </clr-options>
    </clr-combobox>

    <clr-control-helper>
        <clr-control-helper slot="displayWhenEmpty">Type a resource name</clr-control-helper>
    </clr-control-helper>

    <clr-control-error>
        <clr-control-error slot="displayWhenError">There was an error</clr-control-error>
    </clr-control-error>
</clr-combobox-container>
