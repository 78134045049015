import {
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {
    ClrSelectedState
} from "@clr/angular";

import {
    AccountService,
    InfrastructureService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    JSONTarget,
    Message,
    Searchbar,
    User
} from '@app/model';


@Component({
    selector: 'app-selectiontree',
    templateUrl: './selectiontree.component.html',
    styleUrls: ['./selectiontree.component.css']
})
export class SelectiontreeComponent implements OnInit {

    message: Message;

    css_id: string = '';

    infra: any[] = [];

    current_view: string = '';

    current_view_type: string = 'server';

    search = new UntypedFormGroup({
        item: new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),
    });
    selectedItem: Searchbar;

    organizations: string[] = [];

    same_node: any = [];

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private infra_svc: InfrastructureService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef
    ) {}

    getItemChildren = (item: {
        children: any;
    }) => item.children;

    ngOnInit(): void {

        this.authentication_svc.user.subscribe((user: User) => this.currentUser = user);

        this.infra = this.json_svc.json.dcInfraMin;

        this.message_svc.currentMessage.subscribe((message: Message) => this.message = message);

        if (this.message.isElementWizard)
            this.css_id = 'csstoadjustwizard'
        else
            this.css_id = 'csstoadjust'

        setTimeout(() => $('#' + this.css_id).removeClass('clr-form-horizontal'), 100);
        setTimeout(() => $('#' + this.css_id).removeClass('clr-form'), 100);

        this.organizations.push('vmware-root');

        let jt: JSONTarget = JSONTarget.HOST_VIEW;

        switch (this.message.elementViewContext) {
            case "multi":
                switch (this.message.elementViewType) {
                    case "server-scope":
                        jt = JSONTarget.MULTI_VIEW_SCOPE;
                        break;
                    default:
                        jt = JSONTarget.MULTI_VIEW;
                        break;
                }
                //jt = JSONTarget.MULTI_VIEW;
                this.organizations.push('xclarity-root');
                this.organizations.push('scaphandre-root');
                this.organizations.push('kubernetes-root');
                this.organizations.push('aws-root');
                this.organizations.push('azure-root');
                this.organizations.push('openmanage-root');
                this.organizations.push('oneview-root');
                this.organizations.push('ipmi-root');
                this.organizations.push('exagrid-root');
                this.organizations.push('ibm-root');
                this.organizations.push('network-root');
                this.organizations.push('storage-root');
                break;
            case "xclarity":
                jt = JSONTarget.XCLARITY_VIEW;
                this.organizations.push('xclarity-root');
                break;
            case "scaphandre":
                jt = JSONTarget.SCAPHANDRE_VIEW;
                this.organizations.push('scaphandre-root');
                break;
            case "kubernetes":
                jt = JSONTarget.KUBE_VIEW;
                this.organizations.push('kubernetes-root');
                break;
            case "vmware":
                switch (this.message.elementViewType) {
                    case "server":
                        jt = JSONTarget.HOST_VIEW;
                        break;
                    case "server-scope":
                        jt = JSONTarget.HOST_VIEW_SCOPE;
                        break;
                    case "folder":
                        jt = JSONTarget.FD_VIEW;
                        break;
                    case "rp":
                        jt = JSONTarget.RP_VIEW;
                        break;
                    case "tag":
                        jt = JSONTarget.TAG_VIEW;
                        break;
                    case "datastore":
                        jt = JSONTarget.DS_VIEW;
                        break;
                    case "multi":
                        jt = JSONTarget.MULTI_VIEW;
                        break;
                    default:
                        break;
                }
                break;
            case "aws":
                switch (this.message.elementViewType) {
                    case "server-scope":
                        jt = JSONTarget.AWS_VIEW_SCOPE;
                        break;
                    default:
                        jt = JSONTarget.AWS_VIEW;
                        break;
                }
                //jt = JSONTarget.AWS_VIEW;
                this.organizations.push('aws-root');
                break;
            case "azure":
                switch (this.message.elementViewType) {
                    case "server-scope":
                        jt = JSONTarget.AZURE_VIEW_SCOPE;
                        break;
                    default:
                        jt = JSONTarget.AZURE_VIEW;
                        break;
                }
                //jt = JSONTarget.AZURE_VIEW;
                this.organizations.push('azure-root');
                break;
            case "openmanage":
                jt = JSONTarget.OPENMANAGE_VIEW;
                this.organizations.push('openmanage-root');
                break;
            case "oneview":
                jt = JSONTarget.ONEVIEW_VIEW;
                this.organizations.push('oneview-root');
                break;
            case "network":
                jt = JSONTarget.NETWORK_VIEW;
                this.organizations.push('network-root');
                break;
            case "storage":
                jt = JSONTarget.STORAGE_VIEW;
                this.organizations.push('storage-root');
                break;
            case "ipmi":
                jt = JSONTarget.IPMI_VIEW;
                this.organizations.push('ipmi-root');
                break;
            case "exagrid":
                jt = JSONTarget.EXAGRID_VIEW;
                this.organizations.push('exagrid-root');
                break;
            case "ibm":
                jt = JSONTarget.IBM_VIEW;
                this.organizations.push('ibm-root');
                break;
            default:
                break;
        }

        this.json_svc.getData(this.currentUser.login, 'all', jt).subscribe(
            data => {
                this.message.elementViewTree = data;
            }
        );

        this.infra_svc.getSameNode().subscribe(
            data => {
                for (var i = 0; i < data.length; ++i) {
                    let sn: any = {
                        'identifier': data[i].IDENTIFIER,
                        'id': data[i].ID
                    };
                    this.same_node.push(sn);
                }
            }
        );


    }

    ngAfterViewChecked() {
        this.cd.detectChanges();
    }

    elementTreeAnalysis(item, event): void {

        this.message.isTreeAnalysis = true;

        if (event > 0) {
            if (item.name != undefined) {
                if (item.selected == 1) {
                    this.enableChild(item);
                    this.actionOnMatchNode(item, "enable");
                }
            }
        } else {
            if (item.name != undefined) {
                if (item.selected == 0) {
                    this.disableChild(item);
                    this.actionOnMatchNode(item, "disable");
                }
            }
        }
    }

    submit(value): void {

        this.message.isTreeAnalysis = true;
        this.selectedItem = this.getSelectedEntity(value);
        if (this.selectedItem != undefined) {
            for (var i = 0; i < this.message.elementViewTree.length; ++i) {
                if (this.message.elementViewTree[i].id == this.selectedItem.uuid) {
                    this.message.elementViewTree[i].selected = ClrSelectedState.SELECTED;
                    this.enableChild(this.message.elementViewTree[i]);
                    this.actionOnMatchNode(this.message.elementViewTree[i], "enable");
                } else {
                    this.analyseSearchSubmitChild(this.selectedItem, this.message.elementViewTree[i]);
                }
            }
        }
    }

    enter(): void {

        this.message.isTreeAnalysis = true;
        if (this.search.value.item != "") {
            for (var i = 0; i < this.message.elementViewTree.length; ++i) {
                //if (this.search.value.item.includes(this.message.elementViewTree[i].name)) {
                if (this.message.elementViewTree[i].name.toLowerCase().includes(this.search.value.item.toLowerCase())) {
                    this.message.elementViewTree[i].selected = ClrSelectedState.SELECTED;
                    this.enableChild(this.message.elementViewTree[i]);
                    this.actionOnMatchNode(this.message.elementViewTree[i], "enable");
                } else {
                    this.analyseSearchChild(this.message.elementViewTree[i], this.search.value.item);
                }
            }
        }
    }

    private actionOnMatchNode(node: any, action: string): void {

        let match: number = 0;
        for (var i = 0; i < this.same_node.length; ++i) {
            if (this.same_node[i].identifier == node.id) {
                match = this.same_node[i].id;
                node.common = this.same_node[i].id;
                break;
            }
        }

        if (match > 0) {
            for (var i = 0; i < this.same_node.length; ++i) {
                if (this.same_node[i].id == match) {
                    if (this.same_node[i].identifier != node.id) {
                        node.common = this.same_node[i].id;
                        for (var j = 0; j < this.organizations.length; j++) {
                            // GET NODE ORGANIZATION FROM UUID
                            let target_org: any = this.getSelectedEntityFromId(this.organizations[j]);
                            if (target_org != undefined)
                                this.analyseMatchChild(target_org, this.same_node[i].identifier, action);
                        }
                    }
                }
            }
        } else {
            if (node.children != null) {
                if (node.children.length > 0) {
                    for (var i = 0; i < node.children.length; ++i) {
                        this.actionOnMatchNode(node.children[i], action);
                    }
                }
            }
        }
    }

    private enableChild(node: any) {

        for (var i = 0; i < node.children.length; ++i) {
            node.children[i].selected = ClrSelectedState.SELECTED;
            if (node.children[i].type != "vm" &&
                node.children[i].type != "xclarity_node" &&
                node.children[i].type != "scaphandre_node" &&
                node.children[i].type != "openmanage_node" &&
                node.children[i].type != "oneview_node" &&
                node.children[i].type != "ipmi_node" &&
                node.children[i].type != "exagrid_san" &&
                node.children[i].type != "ibm_san" &&
                node.children[i].type != "kubernetes-pod" &&
                node.children[i].type != "aws-instance" &&
                node.children[i].type != "azure-instance" &&
                node.children[i].type != "firewall" &&
                node.children[i].type != "switch" &&
                node.children[i].type != "wifiAccessPoint" &&
                node.children[i].type != "san" &&
                node.children[i].type != "nas" &&
                node.children[i].type != "backup")
                this.enableChild(node.children[i]);
        }
    }

    private disableChild(node: any) {

        for (var i = 0; i < node.children.length; ++i) {
            node.children[i].selected = ClrSelectedState.UNSELECTED;
            if (node.children[i].type != "vm" &&
                node.children[i].type != "xclarity_node" &&
                node.children[i].type != "scaphandre_node" &&
                node.children[i].type != "openmanage_node" &&
                node.children[i].type != "oneview_node" &&
                node.children[i].type != "ipmi_node" &&
                node.children[i].type != "exagrid_san" &&
                node.children[i].type != "ibm_san" &&
                node.children[i].type != "kubernetes-pod" &&
                node.children[i].type != "aws-instance" &&
                node.children[i].type != "azure-instance" &&
                node.children[i].type != "firewall" &&
                node.children[i].type != "switch" &&
                node.children[i].type != "wifiAccessPoint" &&
                node.children[i].type != "san" &&
                node.children[i].type != "nas" &&
                node.children[i].type != "backup")

                this.disableChild(node.children[i]);
        }
    }

    private analyseSearchSubmitChild(selectedItem: any, node: any): void {

        if (node != undefined) {
            if (node.children.length > 0) {
                for (var i = 0; i < node.children.length; ++i) {
                    if (node.children[i].id == selectedItem.uuid) {
                        node.children[i].selected = ClrSelectedState.SELECTED;
                        this.actionOnMatchNode(node.children[i], "enable");
                        if (node.children[i].type != "vm" &&
                            node.children[i].type != "xclarity_node" &&
                            node.children[i].type != "scaphandre_node" &&
                            node.children[i].type != "openmanage_node" &&
                            node.children[i].type != "oneview_node" &&
                            node.children[i].type != "ipmi_node" &&
                            node.children[i].type != "exagrid_san" &&
                            node.children[i].type != "ibm_san" &&
                            node.children[i].type != "kubernetes-pod" &&
                            node.children[i].type != "aws-instance" &&
                            node.children[i].type != "azure-instance" &&
                            node.children[i].type != "firewall" &&
                            node.children[i].type != "switch" &&
                            node.children[i].type != "wifiAccessPoint" &&
                            node.children[i].type != "san" &&
                            node.children[i].type != "nas" &&
                            node.children[i].type != "backup")

                            this.enableChild(node.children[i]);
                    } else {
                        if (node.children[i].type != "vm" &&
                            node.children[i].type != "xclarity_node" &&
                            node.children[i].type != "scaphandre_node" &&
                            node.children[i].type != "openmanage_node" &&
                            node.children[i].type != "oneview_node" &&
                            node.children[i].type != "ipmi_node" &&
                            node.children[i].type != "exagrid_san" &&
                            node.children[i].type != "ibm_san" &&
                            node.children[i].type != "kubernetes-pod" &&
                            node.children[i].type != "aws-instance" &&
                            node.children[i].type != "azure-instance" &&
                            node.children[i].type != "firewall" &&
                            node.children[i].type != "switch" &&
                            node.children[i].type != "wifiAccessPoint" &&
                            node.children[i].type != "san" &&
                            node.children[i].type != "nas" &&
                            node.children[i].type != "backup")

                            this.analyseSearchSubmitChild(selectedItem, node.children[i]);
                    }
                }
                this.checkParentState(node);
            }
        }
    }

    private analyseSearchChild(node: any, val: string): void {

        if (node != undefined) {
            if (node.children.length > 0) {
                for (var i = 0; i < node.children.length; ++i) {
                    //if (val.includes(node.children[i].name)) {
                    if (node.children[i].name.toLowerCase().includes(val.toLowerCase())) {
                        node.children[i].selected = ClrSelectedState.SELECTED;
                        this.actionOnMatchNode(node.children[i], "enable");
                        if (node.children[i].type != "vm" &&
                            node.children[i].type != "xclarity_node" &&
                            node.children[i].type != "scaphandre_node" &&
                            node.children[i].type != "openmanage_node" &&
                            node.children[i].type != "oneview_node" &&
                            node.children[i].type != "ipmi_node" &&
                            node.children[i].type != "exagrid_san" &&
                            node.children[i].type != "ibm_san" &&
                            node.children[i].type != "kubernetes-pod" &&
                            node.children[i].type != "aws-instance" &&
                            node.children[i].type != "azure-instance" &&
                            node.children[i].type != "firewall" &&
                            node.children[i].type != "switch" &&
                            node.children[i].type != "wifiAccessPoint" &&
                            node.children[i].type != "san" &&
                            node.children[i].type != "nas" &&
                            node.children[i].type != "backup")

                            this.enableChild(node.children[i]);
                    } else {
                        if (node.children[i].type != "vm" &&
                            node.children[i].type != "xclarity_node" &&
                            node.children[i].type != "scaphandre_node" &&
                            node.children[i].type != "openmanage_node" &&
                            node.children[i].type != "oneview_node" &&
                            node.children[i].type != "ipmi_node" &&
                            node.children[i].type != "exagrid_san" &&
                            node.children[i].type != "ibm_san" &&
                            node.children[i].type != "kubernetes-pod" &&
                            node.children[i].type != "aws-instance" &&
                            node.children[i].type != "azure-instance" &&
                            node.children[i].type != "firewall" &&
                            node.children[i].type != "switch" &&
                            node.children[i].type != "wifiAccessPoint" &&
                            node.children[i].type != "san" &&
                            node.children[i].type != "nas" &&
                            node.children[i].type != "backup")

                            this.analyseSearchChild(node.children[i], val);
                    }
                }
                this.checkParentState(node);
            }
        }
    }

    private getSelectedEntity(selectedName: string): Searchbar {
        return this.infra.find(item => item.text === selectedName);
    }

    private getSelectedEntityFromId(selectedId: string): any {
        //console.log(this.message.elementViewTree);
        return this.message.elementViewTree.find((item: {
            id: string;
        }) => item.id === selectedId);
    }

    private analyseMatchChild(node: any, id: string, action: string): void {

        if (node != undefined) {
            if (node.children.length > 0) {
                for (var i = 0; i < node.children.length; ++i) {
                    if (node.children[i].id == id) {
                        if (action == "enable")
                            node.children[i].selected = ClrSelectedState.SELECTED;
                        else
                            node.children[i].selected = ClrSelectedState.UNSELECTED;

                        if (node.children[i].type != "vm" &&
                            node.children[i].type != "xclarity_node" &&
                            node.children[i].type != "scaphandre_node" &&
                            node.children[i].type != "openmanage_node" &&
                            node.children[i].type != "oneview_node" &&
                            node.children[i].type != "ipmi_node" &&
                            node.children[i].type != "exagrid_san" &&
                            node.children[i].type != "ibm_san" &&
                            node.children[i].type != "kubernetes-pod" &&
                            node.children[i].type != "aws-instance" &&
                            node.children[i].type != "azure-instance" &&
                            node.children[i].type != "firewall" &&
                            node.children[i].type != "switch" &&
                            node.children[i].type != "wifiAccessPoint" &&
                            node.children[i].type != "san" &&
                            node.children[i].type != "nas" &&
                            node.children[i].type != "backup") {
                            if (action == "enable")
                                this.enableChild(node.children[i]);
                            else
                                this.disableChild(node.children[i]);
                        }
                        if (node.type != "organization" && this.message.isElementWizard)
                            this.checkParentState(node);
                    } else {
                        if (node.children[i].type != "vm" &&
                            node.children[i].type != "xclarity_node" &&
                            node.children[i].type != "scaphandre_node" &&
                            node.children[i].type != "openmanage_node" &&
                            node.children[i].type != "oneview_node" &&
                            node.children[i].type != "ipmi_node" &&
                            node.children[i].type != "exagrid_san" &&
                            node.children[i].type != "ibm_san" &&
                            node.children[i].type != "kubernetes-pod" &&
                            node.children[i].type != "aws-instance" &&
                            node.children[i].type != "azure-instance" &&
                            node.children[i].type != "firewall" &&
                            node.children[i].type != "switch" &&
                            node.children[i].type != "wifiAccessPoint" &&
                            node.children[i].type != "san" &&
                            node.children[i].type != "nas" &&
                            node.children[i].type != "backup")

                            this.analyseMatchChild(node.children[i], id, action);
                    }
                }

                if (node.type != "organization" && this.message.isElementWizard)
                    this.checkParentState(node);
            }
        }
    }

    private checkParentState(node: any): void {

        if (node != undefined) {
            let all_child_selected: boolean = true;
            let nb_child_selected: number = 0;

            for (var i = 0; i < node.children.length; ++i) {
                switch (node.children[i].selected) {
                    case 0:
                        all_child_selected = false;
                        break;
                    case 1:
                        ++nb_child_selected;
                        break;
                    case 2:
                        all_child_selected = false;
                        ++nb_child_selected;
                        break;
                    default:
                        break;
                }
            }

            if (node.children.length > 0) {
                if (all_child_selected)
                    node.selected = ClrSelectedState.SELECTED;
                else {
                    if (nb_child_selected > 0)
                        node.selected = ClrSelectedState.INDETERMINATE;
                    else
                        node.selected = ClrSelectedState.UNSELECTED;
                }
            } else {
                node.selected = ClrSelectedState.UNSELECTED;
            }

            let father: any = this.getFather(node);
            if (father != null) {
                this.checkParentState(father);
            }
        }
    }

    private getFather(node: any): any {

        let father: any = null;

        for (var i = 0; i < this.message.elementViewTree.length; ++i) {
            if (this.message.elementViewTree[i].children.length > 0) {
                for (var j = 0; j < this.message.elementViewTree[i].children.length; ++j) {
                    if (this.message.elementViewTree[i].children[j].id == node.id)
                        return this.message.elementViewTree[i];
                }
            }
        }

        return father;
    }
}