import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    map
} from 'rxjs/operators';

import {
    COST,
    FilterActivation,
    FilterDelete,
    FilterMgt,
    SelectionFilter,
    SelectionFilterRec,
    ElementView,
    RecoServerStop,
    TimeFilter,
    IhmSettings,
    VM_COST,
    DS_COST,
    SERVER_COST,
    TagCost,
    TagCostElement,
    TagCostRec,
    TagCostMin,
    COST_UPDATE,
    GreenItDevice,
    ElementType,
    InstanceType,
} from '@app/model';

import {
    environment
} from '@environments/environment';
import {
    BehaviorSubject,
    Observable
} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ManagementService {

    // Elements type check
    vmware_type_check: boolean = false;
    aws_type_check: boolean = false;
    azure_type_check: boolean = false;
    gcp_type_check: boolean = false;
    kubernetes_type_check: boolean = false;
    scaphandre_type_check: boolean = false;
    xclarity_type_check: boolean = false;
    openmanage_type_check: boolean = false;
    oneview_type_check: boolean = false;
    ipmi_type_check: boolean = false;
    storage_type_check: boolean = false;
    network_type_check: boolean = false;
    exagrid_type_check: boolean = false;
    ibm_type_check: boolean = false;

    private vmwareCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .vmware_type_check);
    vmwareCheck: Observable < boolean > = this.vmwareCheckSubject.asObservable();

    private awsCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this.aws_type_check);
    awsCheck: Observable < boolean > = this.awsCheckSubject.asObservable();

    private azureCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this.azure_type_check);
    azureCheck: Observable < boolean > = this.azureCheckSubject.asObservable();

    private gcpCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this.gcp_type_check);
    gcpCheck: Observable < boolean > = this.gcpCheckSubject.asObservable();

    private kubernetesCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .kubernetes_type_check);
    kubernetesCheck: Observable < boolean > = this.kubernetesCheckSubject.asObservable();

    private scaphandreCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .scaphandre_type_check);
    scaphandreCheck: Observable < boolean > = this.scaphandreCheckSubject.asObservable();

    private xclarityCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .xclarity_type_check);
    xclarityCheck: Observable < boolean > = this.xclarityCheckSubject.asObservable();

    private openmanageCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .openmanage_type_check);
    openmanageCheck: Observable < boolean > = this.openmanageCheckSubject.asObservable();

    private oneviewCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .oneview_type_check);
    oneviewCheck: Observable < boolean > = this.oneviewCheckSubject.asObservable();

    private ipmiCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .ipmi_type_check);
    ipmiCheck: Observable < boolean > = this.ipmiCheckSubject.asObservable();

    private exagridCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .exagrid_type_check);
    exagridCheck: Observable < boolean > = this.exagridCheckSubject.asObservable();

    private ibmCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .ibm_type_check);
    ibmCheck: Observable < boolean > = this.ibmCheckSubject.asObservable();

    private storageCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .storage_type_check);
    storageCheck: Observable < boolean > = this.storageCheckSubject.asObservable();

    private networkCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .network_type_check);
    networkCheck: Observable < boolean > = this.networkCheckSubject.asObservable();

    /**
     * 
     */
    constructor(private http: HttpClient) {}

    getUserSelection(user: string, filter: string) {
        return this.http.get < SelectionFilter > (`${environment.apiUrl}/management/` + 'findselection?user=' +
            user + '&filter=' + filter).pipe(map(selection => {
            return selection;
        }));
    }

    checkUserSelection(selections: SelectionFilterRec[]) {
        return this.http.post < any > (`${environment.apiUrl}/management/checkuserselection`, selections).pipe(
            map(result => {
                return result;
            }));
    }

    remSelection(user: string, filter: string) {
        let selection_o: any = {
            user: user,
            filter: filter
        };
        return this.http.put < SelectionFilter > (`${environment.apiUrl}/management/remselection`, selection_o);
    }

    /*addSelection(user: string, filter: string, name: string, type: string, uuid: string, flag: number, resource: string) {
        let selection_o: any = {
            user: user,
            filter: filter,
            name: name,
            type: type,
            uuid: uuid,
            flag: flag,
            resource: resource
        };
        return this.http.post < SelectionFilter > (`${environment.apiUrl}/management/addselection`, selection_o);
    }*/

    addSelectionList(selections: SelectionFilterRec[]) {
        return this.http.post < any > (`${environment.apiUrl}/management/addselections`, selections).pipe(map(
            result => {
                return result;
            }));
    }

    /*addSelectionList0(selections: SelectionFilterRec[]) {
        return this.http.post < any > (`${environment.apiUrl}/management/` + 'addselections0', selections).pipe(map(
            result => {
                return result;
            }));
    }*/

    /*updSelection(user: string, filter: string, name: string, type: string, uuid: string, flag: number) {
        return this.http.post < SelectionFilter > (`${environment.apiUrl}/` + 'management/updselection?user=' +
            user + '&filter=' + filter + '&' + name + '&' + type + '&' + uuid + '&' + flag, [user, filter, name,
                type, uuid, flag
            ]);
    }*/

    /*remElement(user: string, filter: string, uuid: string) {
        return this.http.put < SelectionFilter > (`${environment.apiUrl}/` + 'management/remelement?user=' + user +
            '&filter=' + filter + '&' + uuid, [user, filter, uuid]);
    }*/

    getElementView(user: string) {
        return this.http.get < ElementView[] > (`${environment.apiUrl}/management/` + 'findelementview?user=' +
            user).pipe(map(view => {
            return view;
        }));
    }

    getTimeViewAll(user: string) {
        return this.http.get < TimeFilter > (`${environment.apiUrl}/management/` + 'findtimeviewall?user=' + user)
            .pipe(map(view => {
                return view;
            }));
    }

    addTimeView(user: string, filter: string, begin: string, end: string) {
        let timeview_o: any = {
            user: user,
            filter: filter,
            begin: begin,
            end: end
        };
        return this.http.post < TimeFilter > (`${environment.apiUrl}/management/addtimeview`, timeview_o);
    }

    getTimeView(user: string) {
        return this.http.get < TimeFilter[] > (`${environment.apiUrl}/management/` + 'findtimeview?user=' + user)
            .pipe(map(view => {
                return view;
            }));
    }

    updTimeView(user: string, filter: string, begin: string, end: string) {
        let timeview_o: any = {
            user: user,
            filter: filter,
            begin: begin,
            end: end
        };
        return this.http.post < SelectionFilter > (`${environment.apiUrl}/management/updtimefilter`, timeview_o);
    }

    remTimeFilter(user: string, filter: string) {
        return this.http.put < TimeFilter > (`${environment.apiUrl}/management/` + 'remtimefilter?user=' + user +
            '&filter=' + filter, [user, filter]);
    }

    getFilterList(user: string) {
        return this.http.get < FilterMgt[] > (`${environment.apiUrl}/management/` + 'findfilterlist?user=' + user)
            .pipe(map(filter => {
                return filter;
            }));
    }

    getFullFilterList(user: string) {
        return this.http.get < FilterMgt[] > (`${environment.apiUrl}/management/` + 'findfullfilterlist?user=' +
            user).pipe(map(filter => {
            return filter;
        }));
    }

    getSharedFilterList() {
        return this.http.get < FilterMgt[] > (`${environment.apiUrl}/management/findsharedfilterlist`).pipe(
            map(filter => {
                return filter;
            }));
    }

    getUsersFilterList() {
        return this.http.get < FilterMgt[] > (`${environment.apiUrl}/management/findusersfilterlist`).pipe(map(
            filter => {
                return filter;
            }));
    }

    getViewsFromSharedFilter(user: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'findviewsfromsharedfilter?user=' +
            user).pipe(map(view => {
            return view;
        }));
    }

    testSharedFilter(user: string, ev: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'testsharedfilter?user=' + user +
            '&ev=' + ev).pipe(map(test => {
            return test;
        }));
    }

    getFilterFromId(id: string) {
        return this.http.get < FilterMgt > (`${environment.apiUrl}/management/` + 'findfilterfromid?id=' + id).pipe(
            map(filter => {
                return filter;
            }));
    }

    addSymLink(user: string, filter: string) {
        return this.http.post(`${environment.apiUrl}/` + 'management/addsymboliclink/', [user, filter]);
    }

    unLink(user: string, filter: string) {
        return this.http.post(`${environment.apiUrl}/` + 'management/unlink/', [user, filter]);
    }

    updFilter(user: string, filter: string, id: number, element: string, time: string, wd: boolean, share: boolean) {
        const replacer = (key, value) => (value == null) ? undefined : value;
        let settings = {
            user: user,
            filter: filter,
            id: id,
            element: element,
            time: time,
            wd: wd,
            share: share,
            timeago: new Date().getTime(),
        };
        return this.http.post < any > (`${environment.apiUrl}/management/` + 'updfilter/', JSON.parse(JSON
            .stringify(settings, replacer)));
    }

    addFilter(user: string, filter: string, element: string, time: string, wd: boolean, share: boolean) {
        let filter_o: any = {
            user: user,
            filter: filter,
            element: element,
            time: time,
            wd: wd,
            share: share,
            timeago: new Date().getTime(),
        };
        return this.http.post < SelectionFilter > (`${environment.apiUrl}/management/addfilter`, filter_o).pipe(map(
            result => {
                return result;
            }));
    }

    remFilter(filter: FilterDelete) {
        return this.http.put < any > (`${environment.apiUrl}/management/remfilter`, filter).pipe(map(
            result => {
                return result;
            }));
    }

    updateView(view: any) {
        return this.http.post < any > (`${environment.apiUrl}/management/updateview`, view).pipe(map(
            result => {
                return result;
            }));
    }

    enableFilter(filter: FilterActivation) {
        return this.http.post < SelectionFilter > (`${environment.apiUrl}/management/enablefilter`, filter);
    }

    getElementsType(user: string, filter: string) {
        return this.http.get(`${environment.apiUrl}/management/` + 'elementstype?user=' + user + '&filter=' + filter)
            .pipe(
                map(elements => {
                    return elements;
                }));
    }

    /**
     * Initalization of instance types according to filter perimeter
     */
    initElementsType(user: string, filter: FilterMgt) {
        let tmp_vmware_check = false;
        let tmp_aws_check = false;
        let tmp_azure_check = false;
        let tmp_gcp_check = false;
        let tmp_kubernetes_check = false;
        let tmp_scaphandre_check = false;
        let tmp_xclarity_check = false;
        let tmp_openmanage_check = false;
        let tmp_oneview_check = false;
        let tmp_ipmi_check = false;
        let tmp_exagrid_check = false;
        let tmp_ibm_check = false;
        let tmp_storage_check = false;
        let tmp_network_check = false;
        // Storage & network tabs active (extrapolation or selection_filter)
        //XXX Extrapolation only for root & element_view all
        if (user == "root" && filter.element_view == "all") {
            tmp_storage_check = true;
            tmp_network_check = true;
        }

        // FIX shared user
        let shared_user = user;
        if (filter.shared)
            shared_user = "root";

        this.http.get(`${environment.apiUrl}/management/` + 'elementstype?user=' + shared_user + '&filter=' + filter
            .name).pipe(map(infos => {
            return infos
        })).subscribe(
            data => {
                let elements: any = data;
                elements.forEach((element: {
                    element_type: string;
                }) => {
                    let element_type = element.element_type.toUpperCase();
                    try {
                        let tmp_type: keyof typeof ElementType = element_type as keyof typeof ElementType;
                        let tmp_type_enum: ElementType = ElementType[tmp_type];
                        switch (tmp_type_enum) {
                            // Storage
                            case ElementType.BACKUP:
                            case ElementType.SAN:
                            case ElementType.NAS:
                                tmp_storage_check = true;
                                break;
                                // Network
                            case ElementType.FIREWALL:
                            case ElementType.SWITCH:
                            case ElementType.WIFIACCESSPOINT:
                                tmp_network_check = true;
                                break;
                                // Node
                            case ElementType.IPMI_NODE:
                                tmp_ipmi_check = true;
                                break;
                            case ElementType.EXAGRID_SAN:
                                tmp_exagrid_check = true;
                                break;
                            case ElementType.IBM_SAN:
                                tmp_ibm_check = true;
                                break;
                            case ElementType.KUBE_NODE:
                                tmp_kubernetes_check = true;
                                break;
                            case ElementType.ONEVIEW_NODE:
                                tmp_oneview_check = true;
                                break;
                            case ElementType.OPENMANAGE_NODE:
                                tmp_openmanage_check = true;
                                break;
                            case ElementType.SCAPHANDRE_NODE:
                                tmp_scaphandre_check = true;
                                break;
                            case ElementType.SERVER:
                                tmp_vmware_check = true;
                                break;
                            case ElementType.XCLARITY_NODE:
                                tmp_xclarity_check = true;
                                break;
                                // Cloud
                            case ElementType.INSTANCE:
                                tmp_aws_check = true;
                                tmp_azure_check = true;
                                tmp_gcp_check = true;
                                break;
                        }
                    } catch {
                        console.log("Unchecked element : " + element_type);
                    }
                });

                // Update status
                Object.keys(InstanceType).forEach(instance => {
                    switch (InstanceType[instance]) {
                        case InstanceType.VMWARE:
                            this.vmwareCheckSubject.next(tmp_vmware_check || !environment.production);
                            break;
                        case InstanceType.AWS:
                            this.awsCheckSubject.next(tmp_aws_check || !environment.production);
                            break;
                        case InstanceType.AZURE:
                            this.azureCheckSubject.next(tmp_azure_check || !environment.production);
                            break;
                        case InstanceType.GCP:
                            this.gcpCheckSubject.next(tmp_gcp_check || !environment.production);
                            break;
                        case InstanceType.KUBERNETES:
                            this.kubernetesCheckSubject.next(tmp_kubernetes_check || !environment
                                .production);
                            break;
                        case InstanceType.XCLARITY:
                            this.xclarityCheckSubject.next(tmp_xclarity_check || !environment.production);
                            break;
                        case InstanceType.SCAPHANDRE:
                            this.scaphandreCheckSubject.next(tmp_scaphandre_check || !environment
                                .production);
                            break;
                        case InstanceType.OPENMANAGE:
                            this.openmanageCheckSubject.next(tmp_openmanage_check || !environment
                                .production);
                            break;
                        case InstanceType.ONEVIEW:
                            this.oneviewCheckSubject.next(tmp_oneview_check || !environment.production);
                            break;
                        case InstanceType.IPMI:
                            this.ipmiCheckSubject.next(tmp_ipmi_check || !environment.production);
                            break;
                        case InstanceType.EXAGRID:
                            this.exagridCheckSubject.next(tmp_exagrid_check || !environment.production);
                            break;
                        case InstanceType.IBM:
                            this.ibmCheckSubject.next(tmp_ibm_check || !environment.production);
                            break;
                        case InstanceType.STORAGE:
                            this.storageCheckSubject.next(tmp_storage_check || !environment.production);
                            break;
                        case InstanceType.NETWORK:
                            this.networkCheckSubject.next(tmp_network_check || !environment.production);
                            break;
                    }
                });
            }
        );
    }

    // RECOMMENDATION PART
    /*getServerStop(user: string, filter: string) {
        return this.http.get < RecoServerStop[] > (`${environment.apiUrl}/` + 'management/findserverstop?user=' +
            user + '&filter=' + filter).pipe(map(uuids => {
            return uuids;
        }));
    }*/

    getNbStop(user: string, filter: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'findnbserverstop?user=' + user +
            '&filter=' + filter).pipe(map(uuids => {
            return uuids;
        }));
    }

    updateNbStop(reco_srv: RecoServerStop) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatenbserverstop`, reco_srv).pipe(
            map(result => {
                return result;
            }));
    }

    addServerStop(reco_srv: RecoServerStop) {
        return this.http.post < any > (`${environment.apiUrl}/management/addserverstop`, reco_srv).pipe(map(
            result => {
                return result;
            }));
    }

    delServerStop(reco_srv: RecoServerStop) {
        return this.http.put < any > (`${environment.apiUrl}/management/remserverstop`, reco_srv).pipe(map(
            result => {
                return result;
            }));
    }

    // COST PART
    updateIsWithRatio(isratio: any) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatecostisratio`, isratio).pipe(map(
            result => {
                return result;
            }));
    }

    getCost(user: string) {
        return this.http.get < COST > (`${environment.apiUrl}/management/` + 'findcost?user=' + user).pipe(map(
            cost => {
                return cost;
            }));
    }

    updateCost(cost: COST) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatecost`, cost).pipe(map(result => {
            return result;
        }));
    }

    getVmCost(user: string) {
        return this.http.get < VM_COST[] > (`${environment.apiUrl}/management/` + 'findvmcost?user=' + user).pipe(
            map(cost => {
                return cost;
            }));
    }

    updateVmCost(vm_cost: VM_COST) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatevmcost`, vm_cost).pipe(map(
            result => {
                return result;
            }));
    }

    updateVmCosts(vms_cost: any) {
        return this.http.put < any > (`${environment.apiUrl}/management/` + 'updatevmcosts', vms_cost).pipe(map(
            result => {
                return result;
            }));
    }

    getStorageCost(user: string) {
        return this.http.get < DS_COST[] > (`${environment.apiUrl}/management/` + 'findstocost?user=' + user).pipe(
            map(cost => {
                return cost;
            }));
    }

    updateStorageCost(ds_cost: DS_COST) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatestocost`, ds_cost).pipe(map(
            result => {
                return result;
            }));
    }

    updateStorageCosts(dss_cost: any) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatestocosts`, dss_cost).pipe(map(
            result => {
                return result;
            }));
    }

    getServerCost(user: string) {
        return this.http.get < SERVER_COST[] > (`${environment.apiUrl}/management/` + 'findsrvcost?user=' + user)
            .pipe(map(cost => {
                return cost;
            }));
    }

    updateServerCost(srv_cost: SERVER_COST) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatesrvcost`, srv_cost).pipe(map(
            result => {
                return result;
            }));
    }

    updateServerCosts(srvs_cost: any) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatesrvcosts`, srvs_cost).pipe(map(
            result => {
                return result;
            }));
    }

    listTagCost() {
        return this.http.get < TagCost[] > (`${environment.apiUrl}/management/listtagcosts`).pipe(map(
            tagcosts => {
                return tagcosts;
            }));
    }

    addTagCost(tagcost: TagCost) {
        return this.http.post < any > (`${environment.apiUrl}/management/addtagcosts`, tagcost).pipe(map(
            result => {
                return result;
            }));
    }

    addTagCostElement(tagcostels: TagCostElement[]) {
        return this.http.post < any > (`${environment.apiUrl}/management/addtagcostelements`, tagcostels).pipe(
            map(result => {
                return result;
            }));
    }

    delTagCost(taguuid: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'deltagcosts?uuid=' + taguuid).pipe(map(
            result => {
                return result;
            }));
    }

    updateTagCost(tagcost: TagCost) {
        return this.http.put < any > (`${environment.apiUrl}/management/` + 'updatetagcosts', tagcost).pipe(map(
            result => {
                return result;
            }));
    }

    getTagCost(taguuid: string) {
        return this.http.get < TagCost > (`${environment.apiUrl}/management/` + 'findtagcost?uuid=' + taguuid).pipe(
            map(
                tagcost => {
                    return tagcost;
                }));
    }

    getTagCostElement(taguuid: string) {
        return this.http.get < TagCostElement[] > (`${environment.apiUrl}/management/` +
            'findtagcostelements?uuid=' + taguuid).pipe(map(tagcostel => {
            return tagcostel;
        }));
    }

    getTagsVm(vmuuid: string) {
        return this.http.get < TagCostMin[] > (`${environment.apiUrl}/management/` + 'findtagsvm?uuid=' + vmuuid)
            .pipe(
                map(tagcost => {
                    return tagcost;
                }));
    }

    delTagCostElement(tagcostel: TagCostElement) {
        return this.http.put < any > (`${environment.apiUrl}/management/deltagcostelement`, tagcostel).pipe(
            map(result => {
                return result;
            }));
    }

    updateGenericCpuCost(cost: COST) {
        return this.http.put < any > (`${environment.apiUrl}/management/updategenericcpucost`, cost).pipe(map(
            result => {
                return result;
            }));
    }

    updateGenericStoCost(cost: COST) {
        return this.http.put < any > (`${environment.apiUrl}/management/updategenericstocost`, cost).pipe(map(
            result => {
                return result;
            }));
    }

    updateGenericSrvCost(cost: COST) {
        return this.http.put < any > (`${environment.apiUrl}/management/updategenericsrvcost`, cost).pipe(map(
            result => {
                return result;
            }));
    }

    getCostInfo() {
        return this.http.get < COST_UPDATE > (`${environment.apiUrl}/management/getcostinfo`).pipe(map(
            costinfo => {
                return costinfo;
            }));
    }

    updateCostInfo(costinfo: COST_UPDATE) {
        return this.http.put < any > (`${environment.apiUrl}/management/updatecostinfo`, costinfo).pipe(map(
            result => {
                return result;
            }));
    }

    getRegions(provider: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'getregions?provider=' + provider).pipe(
            map(regions => {
                return regions;
            }));
    }

    getCloudTags(provider: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'getcloudtags?provider=' + provider).pipe(
            map((tags: any) => {
                return tags;
            }));
    }

    // KUBERNETES
    getKubernetes() {
        return this.http.get < any > (`${environment.apiUrl}/management/getkubernetes`).pipe(map(
            kubernetes => {
                return kubernetes;
            }));
    }

    getKubernetesNodes(user: string, view: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'getkubernetesnodes?user=' + user +
            '&view=' + view).pipe(map(nodes => {
            return nodes;
        }));
    }

    getPods(user: string, view: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'getkubernetespods?user=' + user +
            '&view=' + view).pipe(map(pods => {
            return pods;
        }));
    }

    /*getContainers(cluster: string) {
      return this.http.get<any>(`${environment.apiUrl}/` + 'management/getkubernetescontainers/' + cluster).pipe(map(containers => {
        return containers;
      }));
    }*/

    // COMMON TO XCLARITY OPENMANAGE ONEVIEW IPMI
    getNodes(user: string, view: string, infra: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + infra + '?user=' + user + '&view=' +
            view).pipe(map(nodes => {
            return nodes;
        }));
    }

    addNetworkDevice(devices: GreenItDevice[]) {
        return this.http.post < any > (`${environment.apiUrl}/management/addnetworkdevice`, devices).pipe(
            map(result => {
                return result;
            }));
    }

    updateGreenItDevice(device: GreenItDevice) {
        return this.http.put < any > (`${environment.apiUrl}/management/updategreenitdevice`, device).pipe(map(
            result => {
                return result;
            }));
    }

    deleteGreenItDevice(device: GreenItDevice) {
        return this.http.put < any > (`${environment.apiUrl}/management/deletegreenitdevice`, device).pipe(map(
            result => {
                return result;
            }));
    }

    addStorageDevice(devices: GreenItDevice[]) {
        return this.http.post < any > (`${environment.apiUrl}/management/addstoragedevice`, devices).pipe(
            map(result => {
                return result;
            }));
    }

    getGreenItDevice(metaType: string) {
        return this.http.get < any > (`${environment.apiUrl}/management/` + 'getgreenitdevice?metaType=' + metaType)
            .pipe(map(
                devices => {
                    return devices;
                }));
    }

    getConnectors() {
        return this.http.get < any > (`${environment.apiUrl}/management/getconnectors`).pipe(map(
            connectors => {
                return connectors;
            }));
    }

    getMachineAssociation() {
        return this.http.get < any > (`${environment.apiUrl}/management/getmachineassociation`)
            .pipe(map((mas: any) => {
                return mas;
            }));
    }

    addMachineAssociation(ma: any) {
        return this.http.post < any > (`${environment.apiUrl}/management/addmachineassociation`, ma).pipe(
            map((result: any) => {
                return result;
            }));
    }

    deleteMachineAssociation(ma: any) {
        return this.http.put < any > (`${environment.apiUrl}/management/deletemachineassociation`, ma).pipe(map(
            (result: any) => {
                return result;
            }));
    }
}
