<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="user"></cds-icon>
			&nbsp;LDAP Settings
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
            <div class="clr-row">
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
				    <button style="float: right;" type="button" class="btn btn-danger-outline btn-sm" (click)="saveSettings()" *ngIf="save_button">Update</button>
				</div>
            </div>
            <div class="clr-row">
                <!-- GENERAL -->
                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <div class="card" style="min-height:500px;">
                        <div class="card-block">
                            <h5 class="card-title">General settings</h5>
                            <div class="card-text">
                                <table>
                                    <tr>
                                        <td style="width:45%"><b style="font-size:13px">Type&nbsp;&nbsp;</b></td>
                                        <td>
                                            <clr-radio-container clrInline>
                                                <clr-radio-wrapper>
                                                    <input type="radio" clrRadio [name]="property.TYPE" required [value]="type.AD" [(ngModel)]="ldap_type" (change)="save_button=true"/>
                                                    <label>Active Directory</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                    <input type="radio" clrRadio [name]="property.TYPE" required [value]="type.OPENLDAP" [(ngModel)]="ldap_type" (change)="save_button=true"/>
                                                    <label>OpenLDAP</label>
                                                </clr-radio-wrapper>
                                            </clr-radio-container>
                                        </td>
                                    </tr>
                                    <hr>
                                    <tr>
                                        <td style="width:45%"><b style="font-size:13px">Port&nbsp;&nbsp;</b></td>
                                        <td>
                                            <div class="clr-select-wrapper">
                                                <select class="clr-select" [(ngModel)]="ldap_port" (change)="save_button=true">
                                                    <option value=389>389 (ldap)</option>
                                                    <option value=636>636 (ldaps)</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <hr>
                                    <tr>
                                        <td style="width:45%"><b style="font-size:13px">Server address&nbsp;&nbsp;</b></td>
                                        <td>
                                            <div class="clr-form-control" style="flex-direction:row;">
                                                <div class="clr-control-container">
                                                    <div class="clr-input-wrapper">
                                                        <input class="clr-input" [id]="property.HOSTNAME" placeholder="ex: srv-ad.easyvirt.local"[(ngModel)]="ldap_hostname" style="width:20em;" (change)="addSettings($event.target);">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <hr>
                                    <tr>
                                        <td style="width:45%"><b style="font-size:13px">Group DN&nbsp;&nbsp;</b></td>
                                        <td>
                                            <div class="clr-form-control" style="flex-direction:row;">
                                                <div class="clr-control-container">
                                                    <div class="clr-input-wrapper">
                                                        <input class="clr-input" [id]="property.GROUPDN" placeholder="ex: OU=groups,DC=easyvirt,DC=local" [(ngModel)]="ldap_groupdn" style="width:20em;" (change)="addSettings($event.target);">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <hr *ngIf="ldap_type == type.OPENLDAP">
                                    <tr *ngIf="ldap_type == type.OPENLDAP">
                                        <td style="width:45%"><b style="font-size:13px">User base DN&nbsp;&nbsp;</b></td>
                                        <td>
                                            <div class="clr-form-control" style="flex-direction:row;">
                                                <div class="clr-control-container">
                                                    <div class="clr-input-wrapper">
                                                        <input class="clr-input" [id]="property.USERDN" placeholder="ex: ou=people,dc=easyvirt,dc=local" [(ngModel)]="ldap_userdn" style="width:20em;" (change)="addSettings($event.target);">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr> -->
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- OPTIONAL -->
                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <div class="card" style="min-height:500px;">
                        <div class="card-block">
                            <h5 class="card-title">Optional settings</h5>
                            <div class="card-text">
                                 <table>
                                    <tr>
                                        <td style="width:45%"><b style="font-size:13px">Base DN&nbsp;&nbsp;</b></td>
                                        <td>
                                            <div class="clr-form-control" style="flex-direction:row;">
                                                <div class="clr-control-container">
                                                    <div class="clr-input-wrapper">
                                                        <input class="clr-input" [id]="property.BASEDN" placeholder="ex: DC=easyvirt,DC=local" [(ngModel)]="ldap_basedn" style="width:20em;" (change)="addSettings($event.target);">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <hr>
                                    <tr>
                                        <td style="width:45%"><b style="font-size:13px">Domain&nbsp;&nbsp;</b></td>
                                        <td>
                                            <div class="clr-form-control" style="flex-direction:row;">
                                                <div class="clr-control-container">
                                                    <div class="clr-input-wrapper">
                                                        <input class="clr-input" [id]="property.DOMAIN" placeholder="ex: easyvirt.local" [(ngModel)]="ldap_domain" style="width:20em;" (change)="addSettings($event.target);">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <hr *ngIf="ldap_type == type.OPENLDAP">
                                    <tr *ngIf="ldap_type == type.OPENLDAP">
                                        <td style="width:45%"><b style="font-size:13px">User objectclass&nbsp;&nbsp;</b></td>
                                        <td>
                                            <div class="clr-form-control" style="flex-direction:row;">
                                                <div class="clr-control-container">
                                                    <div class="clr-input-wrapper">
                                                        <input class="clr-input" [id]="property.USEROBJCLASS" placeholder="default: person" [(ngModel)]="ldap_userobjclass" style="width:20em;" (change)="addSettings($event.target);">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <hr *ngIf="ldap_type == type.OPENLDAP">
                                    <tr *ngIf="ldap_type == type.OPENLDAP">
                                        <td style="width:45%"><b style="font-size:13px">Bind user DN&nbsp;&nbsp;</b></td>
                                        <td>
                                            <div class="clr-form-control" style="flex-direction:row;">
                                                <div class="clr-control-container">
                                                    <div class="clr-input-wrapper">
                                                        <input class="clr-input" [id]="property.BINDDN" placeholder="ex: cn=binduser,ou=people,dc=easyvirt,dc=local" [(ngModel)]="ldap_binddn" style="width:20em;" (change)="addSettings($event.target);">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <hr *ngIf="ldap_type == type.OPENLDAP">
                                    <tr *ngIf="ldap_type == type.OPENLDAP">
                                        <td style="width:45%"><b style="font-size:13px">Bind user password&nbsp;&nbsp;</b></td>
                                        <td>
                                            <clr-password-container style="margin-top:0px;">
                                                <input clrPassword [id]="property.BINDPWD" [(ngModel)]="ldap_bindpwd" style="width:16em;" (change)="addSettings($event.target);">
                                            </clr-password-container>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
