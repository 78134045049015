import {
    Component,
    OnInit
} from '@angular/core';

@Component({
    selector: 'app-provider',
    templateUrl: './provider.component.html',
    styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

}
