<!-- OVERCOMMIT -->
<div class="stack-view" style="height:auto;margin-top:30px;">
    <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
        <clr-stack-label>Overcommitment options</clr-stack-label>
        <clr-stack-content></clr-stack-content>
        <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
            <clr-stack-label>
                <label for="capa.p2" class="clr-control-label">VCPU (per logical core)</label>
            </clr-stack-label>
            <clr-stack-content>
                <div class="clr-form-control" style="margin-top:0;">
                    <div [ngClass]="(capa_settings.p2 == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="capa_p2" type="number" [(ngModel)]="capa_settings.p2" style="width:4em;text-align:right" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_settings.p2 == null" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-stack-content>
        </clr-stack-block>
        <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="2">
            <clr-stack-label>
                <label for="capa.p1" class="clr-control-label">VRAM (%)</label>
            </clr-stack-label>
            <clr-stack-content>
                <div class="clr-form-control" style="margin-top:0;">
                    <div [ngClass]="(capa_settings.p1 == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="capa_p1" type="number" [(ngModel)]="capa_settings.p1" style="width:4em;text-align:right" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_settings.p1 == null" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-stack-content>
        </clr-stack-block>
        <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="3">
            <clr-stack-label>
                <label for="capa.p3" class="clr-control-label">VDISK (%)</label>
            </clr-stack-label>
            <clr-stack-content>
                <div class="clr-form-control" style="margin-top:0;">
                    <div [ngClass]="(capa_settings.p3 == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="capa_p3" type="number" [(ngModel)]="capa_settings.p3" style="width:4em;text-align:right" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_settings.p3 == null" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-stack-content>
        </clr-stack-block>
    </clr-stack-block>
</div>
<!-- RESERVATION -->
<div class="stack-view" style="height:auto;margin-top:30px;">
    <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
        <clr-stack-label>Reservation </clr-stack-label>
        <clr-stack-content>only for 'Prediction ', 'How many virtual machines can be created' and 'Capacity Planning' sections</clr-stack-content>
        <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
            <clr-stack-label>
                <label for="capa.p5" class="clr-control-label">Compute reservation (CPU/RAM) (%)</label>
            </clr-stack-label>
            <clr-stack-content>
                <div class="clr-form-control" style="margin-top:0;">
                    <div [ngClass]="(capa_settings.p5 == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="capa_p5" type="number" [(ngModel)]="capa_settings.p5" style="width:4em;text-align:right" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_settings.p5 == null" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-stack-content>
        </clr-stack-block>
        <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="2">
            <clr-stack-label>
                <label for="capa.p5" class="clr-control-label">Storage reservation (%)</label>
            </clr-stack-label>
            <clr-stack-content>
                <div class="clr-form-control" style="margin-top:0;">
                    <div [ngClass]="(capa_settings.p20 == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="capa_p20" type="number" [(ngModel)]="capa_settings.p20" style="width:4em;text-align:right" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_settings.p20 == null" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-stack-content>
        </clr-stack-block>
    </clr-stack-block>
</div>
<!-- TEMPLATES -->
<div class="stack-view" style="height:auto;margin-top:30px;">
    <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
        <clr-stack-label>Allocation templates </clr-stack-label>
        <clr-stack-content>only for 'How many virtual machines can be created' section</clr-stack-content>
        <clr-stack-block style="height:80px" [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
            <clr-stack-label style="display:flex;">
                <clr-select-container style="margin-top:0;width:50%">
                    <label class="clr-control-label" style="text-align:center;margin-bottom:10px;">Template selected</label>
                    <select clrSelect [(ngModel)]="capa_settings.p6" (change)="setTemplate(true);">
                        <option value="0">Your average VM</option>
                        <option value="1">{{capa_settings.p7}}</option>
                        <option value="2">{{capa_settings.p11}}</option>
                        <option value="3">{{capa_settings.p15}}</option>
                    </select>
                </clr-select-container>
                <div class="clr-form-control" style="margin-top:0;width:50%">
                    <div [ngClass]="( capa_template_name == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <label class="clr-control-label" style="text-align:center;margin-bottom:10px;">Template name</label>
                            <input class="clr-input" id="capa_template_name" [(ngModel)]="capa_template_name" style="width:12em;text-align:right" [disabled]="capa_settings.p6 == '0'" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_template_name" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-stack-label>
            <clr-stack-content style="display:flex;">
                <div class="clr-form-control" style="margin-top:0;width:33%">
                    <div [ngClass]="( capa_template_vcpu == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <label class="clr-control-label" style="text-align:center;margin-bottom:10px;">vCPU</label>
                            <input class="clr-input" type="number" id="capa_template_vcpu" [(ngModel)]="capa_template_vcpu" style="width:4em;text-align:right" [disabled]="capa_settings.p6 == '0'" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_template_vcpu" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div class="clr-form-control" style="margin-top:0;width:33%">
                    <div [ngClass]="( capa_template_vram == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <label class="clr-control-label" style="text-align:center;margin-bottom:10px;">RAM (MB)</label>
                            <input class="clr-input" type="number" id="capa_template_vram" [(ngModel)]="capa_template_vram" style="width:5em;text-align:right" [disabled]="capa_settings.p6 == '0'" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_template_vram" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div class="clr-form-control" style="margin-top:0;width:33%">
                    <div [ngClass]="( capa_template_vdisk == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <label class="clr-control-label" style="text-align:center;margin-bottom:10px;">DISK (MB)</label>
                            <input class="clr-input" type="number" id="capa_template_vdisk" [(ngModel)]="capa_template_vdisk" style="width:6em;text-align:right" [disabled]="capa_settings.p6 == '0'" (change)="checkCapaSettings($event.target);">
                            <cds-icon class="clr-validate-icon" *ngIf="capa_template_vdisk" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-stack-content>
        </clr-stack-block>
    </clr-stack-block>
</div>

<button type="button" class="btn btn-danger-outline btn-sm" *ngIf="update_button" style="margin-top:20px;float:right" (click)="update()">
    UPDATE
</button>
