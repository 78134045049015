<table id="element-consover-dis" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
    		<tr>
			<th rowspan="2" style="display:none;"></th>
			<th rowspan="2">from</th>
			<th rowspan="2">to</th>
			<th rowspan="2">name</th >
			<th width="7%" rowspan="2">nb. of values</th>
			<th colspan="3">IO-Disk consumption (Mo/s)</th>
			<th colspan="3">DISK Latency (ms)</th>
		</tr>
		<tr>
			<th width="4%">avg</th>
			<th width="4%">min</th>
			<th width="4%">max</th>
			<th width="4%">avg</th>
			<th width="4%">min</th>
			<th width="4%">max</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let element of data_elements">
			<td style="display:none;">{{element.timeStart}}</td>
			<td>{{element.start}}</td>
			<td>{{element.end}}</td>
			<td>{{element.name}}
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph(element)" *ngIf="currentUser.isMenuGod">
					<cds-icon shape="line-chart"></cds-icon>
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callZoom(element)" *ngIf="element.timeEnd-element.timeStart>3600000">
					<cds-icon shape="zoom-in"></cds-icon>
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callZoomOut(element)" *ngIf="zoom_out">
					<cds-icon shape="zoom-out"></cds-icon>
				</button>
			</td>
			<td>{{element.values}}</td>
			<td>{{element.avgiodisk}}</td>
			<td>{{element.miniodisk}}</td>
			<td>{{element.maxiodisk}}</td>
			<td>{{element.avglatdisk}}</td>
			<td>{{element.minlatdisk}}</td>
			<td>{{element.maxlatdisk}}</td>
    		</tr>
  	</tbody>
</table>
