import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[cloud-co2-detail]'
})
export class Cloudco2Directive {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
