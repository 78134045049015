<clr-datagrid>
    <clr-dg-column [clrDgField]="'src_address'">
        Source
        <clr-dg-filter [clrDgFilter]="sourceResourceFilter">
            <resource-filter [items]="flowDetails"
                             [sharedOptions]="sharedOptions"
                             [side]="'source'"
                             [tableUpdate]="shouldUpdateFilters" [label]="'Displayable storage types'"
                             #sourceResourceFilter>
            </resource-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showHosts">
        Source Host
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'dst_address'">
        Destination
        <clr-dg-filter [clrDgFilter]="destinationResourceFilter">
            <resource-filter [items]="flowDetails"
                             [sharedOptions]="sharedOptions"
                             [side]="'destination'"
                             [tableUpdate]="shouldUpdateFilters" [label]="'Displayable storage types'"
                             #destinationResourceFilter>
            </resource-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showHosts">
        Destination Host
    </clr-dg-column>

    <ng-container *ngIf="showCountry">
        <clr-dg-column [clrDgField]="'source_country'">
            Source Country
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'destination_country'">
            Destination Country
        </clr-dg-column>
    </ng-container>

    <ng-container *ngIf="showASN">
        <clr-dg-column [clrDgField]="'source_asn'">
            Source Network
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'destination_asn'">
            Destination Network
        </clr-dg-column>
    </ng-container>

    <ng-container *ngIf="showThreats">
        <clr-dg-column [clrDgField]="'score'">
            Risk Score
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'threat'">
           Threat
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'reason'">
            Reason
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'tags'">
            Tags
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'date'">
            Date
        </clr-dg-column>
    </ng-container>

    <ng-container *ngIf="perPortRow">
        <clr-dg-column [clrDgField]="'port'" [clrDgSortOrder]="descSort">Port</clr-dg-column>
        <clr-dg-column [clrDgField]="'protocol'">Protocol</clr-dg-column>
    </ng-container>

    <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort" *ngIf="!hideTotalBytes">
        Total bytes
        <clr-dg-filter [clrDgFilter]="totalBytesFilter">
            <column-range-filter [fieldName]="'exchanged_bytes'" [items]="flowDetails"
                                 [formatType]="'bytes'"
                                 [tableUpdate]="shouldUpdateFilters" #totalBytesFilter>
            </column-range-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'exchanged_packets'" *ngIf="!hidePacketsExchanged">
        Total (packets)
        <clr-dg-filter [clrDgFilter]="totalPacketsFilter">
            <column-range-filter [fieldName]="'exchanged_packets'" [items]="flowDetails"
                                 [formatType]="'numbers'"
                                 [tableUpdate]="shouldUpdateFilters" #totalPacketsFilter>
            </column-range-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showActions">Actions</clr-dg-column>

    <clr-dg-row *clrDgItems="let flowDetail of flowDetails" [clrDgItem]="flowDetail">
        <ng-container *ngFor="let arg of [{addr: flowDetail.src_address, resolved: flowDetail.src_ip_resolved, resolved_class: flowDetail.src_ip_resolved_class, rsc: flowDetail.source, dir: 'src'}, {addr: flowDetail.dst_address, resolved: flowDetail.dst_ip_resolved, resolved_class: flowDetail.dst_ip_resolved_class, rsc: flowDetail.target, dir: 'dst'}]">
            <!-- The following container is for resources that should be displayed as links that redirects to a flow view -->
            <ng-container *ngIf="displayLinksToRessources">
                <clr-dg-cell *ngIf="arg.rsc === undefined || (arg.rsc.type === 'unknown_ip')">{{arg.addr}} <span *ngIf="arg.resolved !== undefined" class="label label-{{arg.resolved_class}}">{{arg.resolved}}</span><a style="cursor: pointer;"><cds-icon (click)="resolveDomain(flowDetail, arg.dir)" shape="network-settings"></cds-icon></a></clr-dg-cell>
                <clr-dg-cell *ngIf="arg.rsc !== undefined && arg.rsc.type === 'external_ip'"><span class="badge badge-orange">{{arg.rsc.address}}</span></clr-dg-cell>

                <clr-dg-cell *ngIf="arg.rsc !== undefined && arg.rsc.type === 'vm' && (arg.rsc.is_in_filter || ignoreFilters) && displayLinksToRessources"><a routerLink="/netscope/flows/dependencies-viewer" [queryParams]="flowDetail.threat === undefined ? {start_time: temporalParameter.startTime, end_time: temporalParameter.endTime, time_resolution: temporalParameter.timeResolution, focused_vms: [arg.rsc.uuid], show_everything: 'yes'} : {start_time: flowDetail.date, end_time: flowDetail.date, time_resolution: 'hourly', focused_vms: [arg.rsc.uuid], show_everything: 'yes'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{arg.rsc.name}}</span></a></clr-dg-cell>
                <clr-dg-cell *ngIf="arg.rsc !== undefined && arg.rsc.type === 'host' && (arg.rsc.is_in_filter || ignoreFilters) && displayLinksToRessources"><a routerLink="/netscope/flows/dependencies-viewer" [queryParams]="flowDetail.threat === undefined ? {start_time: temporalParameter.startTime, end_time: temporalParameter.endTime, time_resolution: temporalParameter.timeResolution, focused_vms: [arg.rsc.uuid], show_everything: 'yes'} : {start_time: flowDetail.date, end_time: flowDetail.date, time_resolution: 'hourly', focused_vms: [arg.rsc.uuid], show_everything: 'yes'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge">{{arg.rsc.name}}</span></a></clr-dg-cell>

                <clr-dg-cell *ngIf="arg.rsc !== undefined && arg.rsc.type === 'vm' && !(arg.rsc.is_in_filter || ignoreFilters)"><span class="label label-blue">{{flowDetail.src_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><cds-icon shape="info-circle" size="24"></cds-icon><span class="tooltip-content">Resource {{flowDetail.src_address}} is not available in this filter</span></a></clr-dg-cell>
                <clr-dg-cell *ngIf="arg.rsc !== undefined && arg.rsc.type === 'host' && !(arg.rsc.is_in_filter || ignoreFilters)"><span class="label">{{flowDetail.src_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><cds-icon shape="info-circle" size="24"></cds-icon><span class="tooltip-content">Resource {{flowDetail.src_address}} is not available in this filter</span></a></clr-dg-cell>
            </ng-container>
            <!-- The following container is for resources that are clickable and associated to an action -->
            <ng-container *ngIf="!displayLinksToRessources">
                <clr-dg-cell *ngIf="arg.rsc.type === 'unknown_ip'">{{arg.rsc.name}} <span *ngIf="arg.resolved !== undefined" class="label label-{{arg.resolved_class}}">{{arg.resolved}}</span><a style="cursor: pointer;"><cds-icon (click)="resolveDomain(flowDetail, arg.dir)" shape="network-settings"></cds-icon></a></clr-dg-cell>
                <clr-dg-cell *ngIf="arg.rsc.type === 'external_ip'"><span class="badge badge-orange" (click)="switchFocus(arg.rsc)" style="cursor: pointer; max-width: 100%; justify-content: left;">{{arg.rsc.name}} <span *ngIf="isFocusedRessource(arg.rsc.uuid)"><cds-icon style="height: 12px; color: #e79807;" shape="pin" class="is-solid"></cds-icon></span></span></clr-dg-cell>
                <clr-dg-cell *ngIf="arg.rsc.type === 'vm'"><span class="badge badge-blue" (click)="switchFocus(arg.rsc)" style="cursor: pointer; max-width: 100%; justify-content: left;">{{arg.rsc.name}} <span *ngIf="isFocusedRessource(arg.rsc.uuid)"><cds-icon style="height: 12px; color: #e79807;" shape="pin" class="is-solid"></cds-icon></span></span></clr-dg-cell>
                <clr-dg-cell *ngIf="arg.rsc.type === 'host'"><span class="badge" (click)="switchFocus(arg.rsc)" style="cursor: pointer; max-width: 100%; justify-content: left;">{{arg.rsc.name}} <span *ngIf="isFocusedRessource(arg.rsc.uuid)"><cds-icon style="height: 12px; color: #e79807;" shape="pin" class="is-solid"></cds-icon></span></span></clr-dg-cell>
            </ng-container>
            <!-- The following container is for when host of resource should be displayed -->
            <ng-container *ngIf="showHosts">
                <clr-dg-cell *ngIf="arg.rsc !== undefined && arg.rsc.type !== 'vm'">n/a</clr-dg-cell>
                <clr-dg-cell *ngIf="arg.rsc !== undefined && arg.rsc.type === 'vm'"><a routerLink="/god/resource/{{arg.rsc.host.short_uuid}}" [queryParams]="{useResourceCountersFor: 'SERVER'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge">{{arg.rsc.host.name}}</span></a></clr-dg-cell>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="perPortRow">
            <clr-dg-cell><span class="label">{{flowDetail.port}}</span> <span class="label label-info" *ngFor="let transport_protocol of flowDetail.transport_protocols">{{transport_protocol.protoLabel}}</span></clr-dg-cell>
            <clr-dg-cell>
                <a style="margin-right: 5px;" routerLink="/netscope/flows/protocol-analysis/protocols/{{flowDetail.protocol}}" [queryParams]="{}" routerLinkActive="active">
                    <span class="label">{{flowDetail.protocol}}</span>
                </a>
            </clr-dg-cell>
        </ng-container>

        <ng-container *ngIf="showCountry">
            <clr-dg-cell>
                {{ flowDetail.source_country }}
            </clr-dg-cell>
            <clr-dg-cell>
                {{ flowDetail.destination_country }}
            </clr-dg-cell>
        </ng-container>

        <ng-container *ngIf="showASN">
            <clr-dg-cell>
                {{ flowDetail.source_asn }}
            </clr-dg-cell>
            <clr-dg-cell>
                {{ flowDetail.destination_asn }}
            </clr-dg-cell>
        </ng-container>

        <ng-container *ngIf="showThreats">
            <clr-dg-cell>

                <span class="label">
                    <span class="text">{{ flowDetail.threat.score * 100 }} %</span>

                    <span class="badge badge-info" *ngIf="flowDetail.threat.risk_level == 'low'">low</span>
                    <span class="badge badge-warning" *ngIf="flowDetail.threat.risk_level == 'medium'">medium</span>
                    <span class="badge badge-danger" *ngIf="flowDetail.threat.risk_level == 'high'">high</span>
                </span>

            </clr-dg-cell>
            <clr-dg-cell>
                <label class="label label label-danger">{{ flowDetail.threat.threat }}</label>
            </clr-dg-cell>
            <clr-dg-cell>
                Connection with <span class="label label-blue">
                    {{ flowDetail.threat.ip }}
                <span class="badge">{{flowDetail.threat.port}}</span>
                </span>
            </clr-dg-cell>
            <clr-dg-cell>
                <label class="label label label-warning" *ngFor="let tag of flowDetail.threat.tags">{{ tag }}</label>
            </clr-dg-cell>
            <clr-dg-cell>{{ flowDetail.date * 1000 | date: 'dd/MM/yyyy HH:mm'}}</clr-dg-cell>
        </ng-container>

        <clr-dg-cell *ngIf="!hideTotalBytes">{{flowDetail.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
        <clr-dg-cell *ngIf="!hidePacketsExchanged">{{flowDetail.exchanged_packets | number}}</clr-dg-cell>

        <ng-template #actions_if_true>
            <a *ngIf="flowDetail.showDependencyViewerIcon" style="margin-right: 5px;" routerLink="/netscope/flows/dependencies-viewer" [queryParams]="flowDetail.threat === undefined ? {start_time: temporalParameter.startTime, end_time: temporalParameter.endTime, time_resolution: temporalParameter.timeResolution, focused_vms: [flowDetail.source.uuid, flowDetail.target.uuid], show_everything: 'yes'} : {start_time: flowDetail.date, end_time: flowDetail.date, time_resolution: 'hourly', focused_vms: [flowDetail.source.uuid, flowDetail.target.uuid], show_everything: 'yes'}" routerLinkActive="active"><cds-icon shape="recycle"></cds-icon></a>
            <a *ngIf="flowDetail.showClustersViewerIcon" style="margin-right: 5px;" routerLink="/netscope/flows/clusters-viewer" [queryParams]="flowDetail.threat === undefined ? {start_time: temporalParameter.startTime, end_time: temporalParameter.endTime, time_resolution: temporalParameter.timeResolution, focused_vms: [flowDetail.source.uuid, flowDetail.target.uuid], show_everything: 'yes'} : {start_time: flowDetail.date, end_time: flowDetail.date, time_resolution: 'hourly', focused_vms: [flowDetail.source.uuid, flowDetail.target.uuid], show_everything: 'yes'}" routerLinkActive="active"><cds-icon shape="organization"></cds-icon></a>
            <a *ngIf="flowDetail.showHeatmapViewerIcon" routerLink="/netscope/heatmap-viewer/{{flowDetail.source.uuid}}/{{flowDetail.target.uuid}}" [queryParams]="flowDetail.threat === undefined ? {start_time: temporalParameter.startTime, end_time: temporalParameter.endTime, time_resolution: temporalParameter.timeResolution} : {start_time: flowDetail.date, end_time: flowDetail.date, time_resolution: 'hourly'}" routerLinkActive="active"><cds-icon shape="heat-map"></cds-icon></a>

            <ng-container *ngIf="showThreats">
                <a target="_blank" href="{{ flowDetail.threat.link }}"><span class="label label-info"> <cds-icon shape="info-circle"></cds-icon> more info</span></a>
            </ng-container>
        </ng-template>
        <ng-template #actions_if_not_true></ng-template>
        <clr-dg-cell *ngIf="showActions">
            <div *ngIf="flowDetail.showActions; then actions_if_true else actions_if_not_true"></div>
        </clr-dg-cell>

        <ng-container ngProjectAs="clr-dg-row-detail" *ngIf="!perPortRow">
            <app-expandable-rows *clrIfExpanded [flowDetail]="flowDetail" [bidirectional]="bidirectional" [temporalParameter]="flowDetail.threat === undefined ? temporalParameter : flowDetail.temporalParameter" ngProjectAs="clr-dg-row-detail"></app-expandable-rows>
        </ng-container>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
        </clr-dg-pagination>

        <button id="export_csv_button" class="btn btn-sm" style="float: left;" *ngIf="exportCsvEnabled" (click)="callExportCsv()">Export CSV</button>
    </clr-dg-footer>

</clr-datagrid>