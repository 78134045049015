import { Component, OnInit } from '@angular/core';

import {
  first
} from 'rxjs/operators';
import {
  ClrDatagridSortOrder
} from '@clr/angular';
import {
  SupervisionService
} from '@app/services';


@Component({
  selector: 'app-supervision',
  templateUrl: './supervision.component.html',
  styleUrls: ['./supervision.component.css']
})
export class SupervisionComponent implements OnInit {

  dataOrder = ClrDatagridSortOrder.DESC;

  supervision_modal: boolean = false;

  serviceToMonitored: any = [];

  constructor(
    private supervision_svc: SupervisionService,
  ) { }

  ngOnInit(): void {
  }

  /**
     * Display settings modal
     */
  async displayModalSupervision(): Promise < void > {

    this.supervision_modal = true;

    // First initialization
    let jetty_svc: any = {
      name: 'jetty api',
      internalName: 'jetty9',
      description: 'Api service for the consumption data',
      status: 'unknown',
      withAction: true
    };
    this.serviceToMonitored.push(jetty_svc);

    let express_svc: any = {
      name: 'express api',
      internalName: 'expressjs_api',
      description: 'Api service for the appliance',
      status: 'unknown',
      withAction: false
    };
    this.serviceToMonitored.push(express_svc);

    let mariadb_svc: any = {
      name: 'database',
      internalName: 'mariadb',
      description: 'Database service',
      status: 'unknown',
      withAction: false
    };
    this.serviceToMonitored.push(mariadb_svc);

    let cron_svc: any = {
      name: 'cron',
      internalName: 'cron',
      description: 'Regular background program processing daemon',
      status: 'unknown',
      withAction: true
    };
    this.serviceToMonitored.push(cron_svc);

    let dialog_svc: any = {
      name: 'dialog',
      internalName: 'dialog',
      description: 'Appliance administration console',
      status: 'unknown',
      withAction: true
    };
    this.serviceToMonitored.push(dialog_svc);
    
    this.loadServiceStatus();

    let snmp_svc: any = {
      name: 'snmp',
      internalName: 'snmp',
      description: 'Simple Network Management Protocol daemon',
      status: 'unknown',
      withAction: true
    };
    this.serviceToMonitored.push(snmp_svc);
    
    this.loadServiceStatus();

    let ssh_svc: any = {
      name: 'ssh',
      internalName: 'ssh',
      description: 'Secure Shell server',
      status: 'unknown',
      withAction: true
    };
    this.serviceToMonitored.push(ssh_svc);
    
    this.loadServiceStatus();
  }

  private loadServiceStatus(): void {

    for(let svc of this.serviceToMonitored) {
      this.supervision_svc.getServiceAction(svc.internalName, 'status').pipe(first()).subscribe(
        (data: any) => {
          if(data.code == 0)
            svc.status = 'running';
          else
            svc.status = 'inactive';
        },
        (error: any) => {
          if (error != null)
            console.log(error)
        }
      );
    }
  }

  actionOnService(svc: any, status: string): void {

    svc.status = 'unknown';

    this.supervision_svc.getServiceAction(svc.internalName, status).pipe(first()).subscribe(
      (data: any) => {
        if(data.code == 0)
          this.loadServiceStatus();
        else
          console.log('fail to ' + status + ' the ' + svc.name + ' service.')
      },
      (error: any) => {
        if (error != null)
          console.log(error)
      }
    );
  }

  refreshServices(): void {

    this.loadServiceStatus();
  }
}
