<div class="card" style="margin-top:0.6rem">
    <div class="card-header card-ajust" style="padding-top:0">
        <h5><cds-icon [attr.shape]="icon"></cds-icon>&nbsp;{{title}}</h5>
    </div>
    <div class="card-block" style="height:270px;">
        <div style="width:100%;text-align:center;">
            <button type="button" class="btn btn-sm" (click)=showElementModal(); style="width:150px"><b> Deploy / Delete</b></button>
        </div>
        <hr>
        <div style="height:66%;overflow:auto;color:#0072a3">
            <table #addtable id="{{ 'capa-simul-' + target }}" style="width:100%;border-radius:10px;border-color:#e8e8e8;border-style:outset;text-align:center;">
                <tr class="title">
                    <td style='width:50px'></td>
                    <td><label  class="clr-control-label" style='color:#0072a3;font-size:13px'>TEMPLATE</label></td>
                    <td><label class="clr-control-label" style='color:#0072a3;font-size:13px'>QUANTITY</label></td>
                    <td></td>
                    <td></td>
                </tr>
                <!-- auto -->
            </table>
        </div>
    </div>
</div>

<!-- ELEMENT MODAL -->
<clr-modal [(clrModalOpen)]="element_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title" *ngIf="target == 'vm'"> Deploy / Delete Virtual Machines</h3>
    <h3 class="modal-title" *ngIf="target == 'host'"> Deploy / Delete Physical Machines</h3>
    <h3 class="modal-title" *ngIf="target == 'ds'"> Deploy / Delete Datastores</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <clr-datagrid #dtable *ngIf="element_modal">
                <clr-dg-action-bar style="margin-top:0px">
                    <div class="btn-group" style="float:right">  
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column [clrDgField]="'ACTION'"><ng-container>Action</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'NAME'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                <clr-dg-column *ngIf="target == 'vm'" [clrDgField]="'RAMCAP'"><ng-container *clrDgHideableColumn="{hidden: false}">VRAM (MB)</ng-container></clr-dg-column>
                <clr-dg-column *ngIf="target == 'vm'" [clrDgField]="'CPUTHREADNB'"><ng-container *clrDgHideableColumn="{hidden: false}">VCPU</ng-container></clr-dg-column>
                <clr-dg-column *ngIf="target == 'vm'" [clrDgField]="'DISKCAP'"><ng-container *clrDgHideableColumn="{hidden: false}">VDISK (MB)</ng-container></clr-dg-column>
                <clr-dg-column *ngIf="target == 'vm'" [clrDgField]="'RAMCONS'"><ng-container *clrDgHideableColumn="{hidden: false}">RAM (MB)</ng-container></clr-dg-column>
                <clr-dg-column *ngIf="target == 'vm'" [clrDgField]="'CPUCONSMHZ'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU (MHz)</ng-container></clr-dg-column>
                <clr-dg-column *ngIf="target == 'host'" [clrDgField]="'RAMCAP'"><ng-container *clrDgHideableColumn="{hidden: false}">RAM Capacity (MB)</ng-container></clr-dg-column>
                <clr-dg-column *ngIf="target == 'host'" [clrDgField]="'CPUTHREADNB'"><ng-container *clrDgHideableColumn="{hidden: false}">Logical Cores</ng-container></clr-dg-column>
                <clr-dg-column *ngIf="target == 'ds'" [clrDgField]="'DISKCAP'"><ng-container *clrDgHideableColumn="{hidden: false}">Storage Capacity (MB)</ng-container></clr-dg-column>
                <clr-dg-row *clrDgItems="let element of modal_data" [clrDgItem]="element">
                    <clr-dg-cell>
                        <div class="clr-select-wrapper">
                            <select [attr.data-id]="element.ID + simulation_input_suffix" class="clr-select" [(ngModel)]="element.ACTION" (change)="actionElement(element)">
                                <option [ngValue]="action_empty"></option>
                                <option [ngValue]="action_add">Deploy</option>
                                <option *ngIf="!(element.NAME.startsWith('--') && element.NAME.endsWith('--'))" [ngValue]="action_del">Delete</option>
                            </select>
                        </div>
                    </clr-dg-cell>
                    <clr-dg-cell>{{element.NAME}}</clr-dg-cell>
                    <clr-dg-cell *ngIf="target == 'vm' || target == 'host'">{{element.RAMCAP}}</clr-dg-cell>
                    <clr-dg-cell *ngIf="target == 'vm' || target == 'host'">{{element.CPUTHREADNB}}</clr-dg-cell>
                    <clr-dg-cell *ngIf="target == 'vm' || target == 'ds'">{{element.DISKCAP}}</clr-dg-cell>
                    <clr-dg-cell *ngIf="target == 'vm'">{{element.RAMCONS == undefined ? 0 : element.RAMCONS}}</clr-dg-cell>
                    <clr-dg-cell *ngIf="target == 'vm'">{{element.CPUCONSMHZ == undefined ? 0 : element.CPUCONSMHZ}}</clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                    <clr-dg-pagination #entities_page [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{entities_page.firstItem + 1}} - {{entities_page.lastItem + 1}} of {{entities_page.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>

<!-- EDIT MODAL -->
<clr-modal [(clrModalOpen)]="edit_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Edit template</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%">
            <table #edittable style="width:100%;border-radius:10px;text-align:center;">
            </table>
            <hr>
            <table #legendtable>
            </table>
        </div>
    </div>
    <div class="modal-footer">
	    <button type="submit" class="btn btn-primary" (click)="closeEditModal()">Save</button>
    </div>
</clr-modal>

