import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    HostListener,
    AfterViewInit,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    first
} from 'rxjs/operators';
import {
    Subject,
    zip
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    MeasurementService,
    ShareService
} from '@app/services';

import {
    ConsOver,
    Counter,
    Json,
    MergeMeasurement,
    Message,
    User
} from '@app/model';

import * as moment from 'moment';

import {
    getGranularityFromInterval
} from '../../../assets/js/tools.js';
import {
    sleep
} from '@cds/core/internal';

const cons_cache: any = [];


@Component({
    selector: 'app-consovercpu',
    templateUrl: './consovercpu.component.html',
    styleUrls: ['./consovercpu.component.css']
})
export class ConsovercpuComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: any = {};

    dtTrigger: Subject < any > = new Subject();

    moment: any = moment;

    jsonLoader: Json;

    message: Message;

    zoom_out: boolean = false;

    data_elements: ConsOver[] = [];

    currentUser: User;

    private current_table: any;

    private requests_counter: Counter[] = [];

    private requests_threshold: Counter[] = [];

    private page_zoom: number = 0;


    constructor(
        private router: Router,
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private measurement_svc: MeasurementService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode == 8) {
            if (this.current_table != undefined) {
                if (this.current_table.api().search() != undefined && this.current_table.api().search() == "")
                    this.message.searchConsover = '';
            }
        }
    }

    ngOnInit(): void {

        $("div.dataTables_filter").remove();
        $("dt-buttons").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.reloadDataTable(null, null);

        this.initDtOptions();
    }

    ngAfterViewInit(): void {

        this.current_table = $('#element-consover-cpu').dataTable();
        this.dtTrigger.next();
    }

    ngAfterViewChecked(): void {

        if (this.current_table != undefined) {
            if (this.current_table.api().search() != undefined && this.current_table.api().search() != "")
                this.message.searchConsover = this.current_table.api().search();
        }
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {

        this.dtTrigger.unsubscribe();
        this.zoom_out = false;
    }

    callGraph(element: any): void {

        if (element != null && element != undefined) {
            this.message.currentUuid = element.uuid;
            this.message.currentName = element.name;
            if (this.message.isVmConsover)
                this.message.currentType = 'VM';
            else
                this.message.currentType = 'SERVER';

            this.router.navigate([`/god/resource/${element.uuid}`], {
                queryParams: {
                    useResourceCountersFor: this.message.currentType
                }
            });
        }
    }

    async callZoom(element: any): Promise < void > {

        this.message.waiting = true;
        this.requests_counter = [];
        this.requests_threshold = [];

        let element_type: string = 'HOST';
        if (this.message.isVmConsover)
            element_type = 'VM';

        ++this.page_zoom;
        this.zoom_out = true;

        let granularityInfo: any = getGranularityFromInterval(parseInt(element.timeStart), parseInt(element
            .timeEnd));

        const t0: number = parseInt(element.timeStart);
        const t1: number = parseInt(element.timeEnd);

        let intervals = [];
        let realStartTime = parseInt(element.timeStart);

        if (granularityInfo.beforeInterval) {
            intervals.push({
                start: t0,
                end: granularityInfo.beforeInterval.end,
                granularity: null
            })
            realStartTime = granularityInfo.beforeInterval.end
        }

        if (granularityInfo.afterInterval) {
            intervals.push({
                start: realStartTime,
                end: granularityInfo.afterInterval.start,
                granularity: granularityInfo.granularity
            })
            intervals.push({
                start: granularityInfo.afterInterval.start,
                end: t1,
                granularity: null
            })
        } else {
            intervals.push({
                start: realStartTime,
                end: t1,
                granularity: granularityInfo.granularity
            })
        }

        const uuid = element.uuid;
        const name = element.name;

        let zip_nb: number = 0;

        for (let i in intervals) {
            let tmp_thres = this.measurement_svc.getThresholds(uuid, element_type, intervals[i].start, intervals[i]
                .end, intervals[
                    i].granularity).pipe(first());
            let tmp_counters = this.measurement_svc.getCounters(uuid, element_type, intervals[i].start, intervals[i]
                .end, intervals[i]
                .granularity).pipe(first());

            zip(tmp_thres, tmp_counters).subscribe(
                data => {
                    this.requests_threshold = this.requests_threshold.concat(data[0]);
                    this.requests_counter = this.requests_counter.concat(data[1]);
                    zip_nb++;
                },
                error => {
                    if (error != null)
                        console.log(error);

                    this.dtTrigger.unsubscribe();
                    this.message.isReloadConsover = true;
                    zip_nb++;
                }
            );
        }

        while (zip_nb != intervals.length) {
            //console.log("Waiting data");
            await sleep(200);
        }

        this.reloadDataTable(uuid, name);
    }

    callZoomOut(element: any): void {

        this.data_elements = [];
        --this.page_zoom;
        this.data_elements = cons_cache[this.page_zoom];
        if (this.page_zoom == 0) {
            this.dtTrigger.unsubscribe();
            this.message.isReloadConsover = true;
        }
    }

    private reloadDataTable(uuid: string, name: string): void {

        let result: ConsOver[] = [];

        if (this.page_zoom == 0) {
            if (this.message.isVmConsover) {
                if (this.router.url == "/vmviews/consumption")
                    result = this.jsonLoader.vmConsover;
                else
                    result.push(this.filterData());
            } else {
                if (this.router.url == "/hostviews/consumption")
                    result = this.jsonLoader.hostConsover;
                else
                    result.push(this.filterData());
            }
        } else {
            let mergedMeasurements = {};
            for (let i = 0; i < this.requests_counter.length; i++) {
                if (!mergedMeasurements[this.requests_counter[i].time]) {
                    mergedMeasurements[this.requests_counter[i].time] = {};
                    mergedMeasurements[this.requests_counter[i].time]["metrics"] = {};
                    mergedMeasurements[this.requests_counter[i].time]["timeInterval"] = {};
                    Object.assign(mergedMeasurements[this.requests_counter[i].time]["timeInterval"], this
                        .requests_counter[i].timeInterval);
                }
                Object.assign(mergedMeasurements[this.requests_counter[i].time]["metrics"], this.toDict(this
                    .requests_counter[i].dataPoints));
                mergedMeasurements[this.requests_counter[i].time]["totalPoints"] = this.requests_counter[i]
                    .numberOfPoints;
            }
            for (let i = 0; i < this.requests_threshold.length; i++) {
                if (mergedMeasurements[this.requests_threshold[i].time]) {
                    Object.assign(mergedMeasurements[this.requests_threshold[i].time]["metrics"], this.toDict(this
                        .requests_threshold[i].dataPoints));
                }
            }

            const vcpu: number = this.filterVcpu(uuid);

            Object.values(mergedMeasurements).forEach((measurement: MergeMeasurement, i) => {

                let avgcpu: number = Math.floor(measurement.metrics.AVG_CPU_USAGE / 10);
                let mincpu: number = Math.floor(measurement.metrics.MIN_CPU_USAGE / 10);
                let maxcpu: number = Math.floor(measurement.metrics.MAX_CPU_USAGE / 10);
                if (this.message.isVmConsover) {
                    avgcpu = Math.floor(measurement.metrics.AVG_CPU_USAGE_INTRA / 10)
                    mincpu = Math.floor(measurement.metrics.MIN_CPU_USAGE_INTRA / 10)
                    maxcpu = Math.floor(measurement.metrics.MAX_CPU_USAGE_INTRA / 10)
                }
                let totalPointsUnder10 = measurement.metrics.CPU_2 + measurement.metrics.CPU_5 + measurement
                    .metrics.CPU_10;
                let totalPointsOver70 = measurement.metrics.CPU_90 + measurement.metrics.CPU_100;
                let totalPointsCpu = measurement.totalPoints;
                let prdy: number = 0;
                if (vcpu > 0)
                    prdy = (measurement.metrics.AVG_CPU_READY / 200 / vcpu);

                const consover: ConsOver = {
                    timeStart: measurement.timeInterval.startTime,
                    timeEnd: measurement.timeInterval.endTime,
                    start: moment.unix(measurement.timeInterval.startTime / 1000).format(
                        "MMM Do YYYY - HH:mm"),
                    end: moment.unix(measurement.timeInterval.endTime / 1000).format(
                        "MMM Do YYYY - HH:mm"),
                    uuid: uuid,
                    name: name,
                    values: measurement.totalPoints,
                    avgcpu: avgcpu,
                    mincpu: mincpu,
                    maxcpu: maxcpu,
                    cpu10: Math.floor((totalPointsUnder10 * 100) / totalPointsCpu),
                    cpu70: Math.floor((totalPointsOver70 * 100) / totalPointsCpu),
                    avgcpurdy: measurement.metrics.AVG_CPU_READY,
                    mincpurdy: measurement.metrics.MIN_CPU_READY,
                    maxcpurdy: measurement.metrics.MAX_CPU_READY,
                    percpurdy: prdy,
                    avgram: 0,
                    minram: 0,
                    maxram: 0,
                    ram10: 0,
                    ram70: 0,
                    avgiodisk: 0,
                    miniodisk: 0,
                    maxiodisk: 0,
                    avglatdisk: 0,
                    minlatdisk: 0,
                    maxlatdisk: 0,
                    avgionet: 0,
                    minionet: 0,
                    maxionet: 0
                };
                result.push(consover);
            });
        }
        cons_cache[this.page_zoom] = result;
        this.data_elements = [];
        this.data_elements = result;
        if (this.page_zoom > 0)
            this.reloadTable();
        else
            setTimeout(() => this.current_table.api().search(this.message.searchConsover).draw(), 1000);

        this.message.waiting = false;
    }

    private filterVcpu(uuid: string): number {

        if (this.message.isVmConsover)
            return this.jsonLoader.vmSynthesis.find(element => element.uuid === uuid).vcpu;
        else
            return this.jsonLoader.hostSynthesis.find(element => element.uuid === uuid).cpucap;
    }

    private filterData(): ConsOver {

        if (this.message.currentUuid != undefined && this.message.currentUuid != "") {
            if (this.message.isVmConsover)
                return this.jsonLoader.vmConsover.find(vmcons => vmcons.uuid === this.message.currentUuid);
            else
                return this.jsonLoader.hostConsover.find(srvcons => srvcons.uuid === this.message.currentUuid);
        }
    }

    private toDict(dataPoints: any): any {

        var result = {};
        dataPoints.forEach(dp => {
            result[dp.metricName] = dp.value
        });

        return result;
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("dt-buttons").remove();
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            order: [
                [3, 'asc'],
                [0, 'asc']
            ],
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            deferRender: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
                $('div.dt-buttons').appendTo('span.export');
                $("[id*='element-consover-cpu_length']").css({
                    'padding-top': '5px'
                });
                $("[name*='element-consover-cpu_length']").css({
                    'padding': '0px'
                });
            },
            language: {
                loadingRecords: "loading .. ",
                zeroRecords: "loading ...",
                search: '',
                searchPlaceholder: " search..."
            },
            dom: '<"top"B><"clear">frtlip',
            buttons: [{
                extend: 'csv',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'copy',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'print',
                className: 'btn btn-link btn-sm'
            }]
        };
    }
}
