<clr-tabs>
	<clr-tab>
        	<button clrTabLink (click)="switchResource('cpu')">CPU</button>
		<clr-tab-content *clrIfActive="cpu_tab">
			<ng-template load-consover-cpu></ng-template>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
		<button clrTabLink (click)="switchResource('ram')">RAM</button>
		<clr-tab-content *clrIfActive="ram_tab">
			<ng-template load-consover-ram></ng-template>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
		<button clrTabLink (click)="switchResource('dis')">DISK</button>
		<clr-tab-content *clrIfActive="dis_tab">
			<ng-template load-consover-disk></ng-template>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
		<button clrTabLink (click)="switchResource('net')">NETWORK</button>
		<clr-tab-content *clrIfActive="net_tab">
			<ng-template load-consover-net></ng-template>
		</clr-tab-content>
	</clr-tab>
</clr-tabs>
