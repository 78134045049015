<clr-alerts>
	<clr-alert *ngIf="alert_state_vm != 'info'" [clrAlertType]="alert_state_vm" [clrAlertSizeSmall]=true [clrAlertAppLevel]=true>
		<clr-alert-item>
			<span class="alert-text">
				VM that can be created
			</span>
		</clr-alert-item>
	</clr-alert>
	<clr-alert *ngIf="alert_state_date != 'info'" [clrAlertType]="alert_state_date" [clrAlertSizeSmall]=true [clrAlertAppLevel]=true>
		<clr-alert-item>
			<span class="alert-text">
				Saturation date
			</span>
		</clr-alert-item>
	</clr-alert>
</clr-alerts>

<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;">
		</highcharts-chart>
	</div>
</div>
<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
	<div class="clr-row">
		<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
			<form clrForm clrLayout="vertical">
				<span class="badge" style="background-color:#046380;font-size:12px;">{{rp_prediction.NBVM_RES}}</span>
				<div class="clr-input">
					<label>VM that can be created</label>
					&nbsp;&nbsp;<cds-icon shape="warning-standard" [ngClass]="rp_badge.nbvm_res_class" size="18" *ngIf="rp_badge.nbvm_res"></cds-icon>
    			</div>
				<clr-control-helper>based on reservation</clr-control-helper>
			</form>
		</div>
		<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
			<form clrForm clrLayout="vertical">
				<span class="badge" style="background-color:#5EA11A;font-size:12px;">{{rp_prediction.NBVM_USAGE}}</span>
				<div class="clr-input">
					<label>VM that can be created</label>
					&nbsp;&nbsp;<cds-icon shape="warning-standard" [ngClass]="rp_badge.nbvm_usage_class" size="18" *ngIf="rp_badge.nbvm_usage"></cds-icon>
      			</div>
				<clr-control-helper>based on average utilization</clr-control-helper>
			</form>
		</div>
	</div>
</div>
<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
	<div class="clr-row">
		<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
			<form clrForm clrLayout="vertical">
				<div class="clr-input">
					<label>Reservation saturation</label>
				</div>
				<span class="badge" [ngClass]="rp_badge.res_sat" style="font-size:12px;">{{rp_prediction.RES_DATE_SAT}}</span>
				<clr-control-helper>the saturation date based on reservation</clr-control-helper>
			</form>
		</div>
		<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
			<form clrForm clrLayout="vertical">
				<div class="clr-input">
					<label>Utilization saturation</label>
				</div>
				<span class="badge" [ngClass]="rp_badge.usage_sat" style="font-size:12px;">{{rp_prediction.USAGE_DATE_SAT}}</span>
				<clr-control-helper>the saturation date based on utilization</clr-control-helper>
			</form>
		</div>
	</div>
</div>
