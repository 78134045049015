import {
    Component,
    OnInit
} from '@angular/core';

@Component({
    selector: 'app-trouble-detail',
    templateUrl: './trouble-detail.component.html',
    styleUrls: ['./trouble-detail.component.css']
})
export class TroubleDetailComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

}
