import {
    Component,
    OnInit
} from '@angular/core';
import {
    AccountService,
    JsonloaderService,
    CapaplanService,
    ShareService,
    SettingsService
} from '@app/services';
import {
    User,
    Message,
    IhmSettings,
    IhmSettingsTarget,
    JSONTarget
} from '@app/model';
import {
    CapaEntity,
    CapaResource,
    HALevel,
    SimulationData
} from './capaplan.enums';
import {
    Subject,
    zip
} from 'rxjs';
import * as uuid from 'uuid';
import {
    ClrDatagridSortOrder
} from '@clr/angular';

import * as moment from 'moment';

/**
 * 
 */
@Component({
    selector: 'app-capaplan',
    templateUrl: './capaplan.component.html',
    styleUrls: ['./capaplan.component.css']
})
export class CapaplanComponent implements OnInit {

    infraSubject: Subject < void > = new Subject < void > ();
    simuSubject: Subject < void > = new Subject < void > ();
    configSubject: Subject < void > = new Subject < void > ();
    templatesSubject: Subject < void > = new Subject < void > ();

    dataOrder = ClrDatagridSortOrder.ASC;

    message: Message;
    currentUser: User;

    //FOR SETTINGS
    capa_settings: IhmSettings;

    // FOR DATA
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October",
        "November", "December"
    ];

    capaplan_json: any;
    capaplan_simulation_json: any;
    capaplan_simulation_config_json: any;

    // Backup data from server
    server_simulation_json: any;
    server_simulation_config_json: any;

    capaplan_lastdate_format: string;

    capaplan_simulation_option: number;

    capaplan_haslider_level: HALevel;
    capaplan_haslider_label: string;
    capaplan_haslider_label_totalvm: number;
    ha_highest: number;
    ha_high: number;
    ha_medium: number;
    ha_low: number;
    ha_lowest: number;
    ha_other: number;

    cap: CapaResource;
    cons: CapaResource;

    // Types
    host: CapaEntity;
    vm: CapaEntity;
    ds: CapaEntity;

    simulation_id_suffix: string = "-ROW";
    simulation_quantity_suffix = "-QUANTITY";
    simulation_input_suffix = "-INPUT";
    simulation_value_separator = "#";

    // For simulation creation
    simulation_name_modal: boolean = false;
    simulation_name;
    simulation_save_disabled: boolean = true;
    simulation_remove_disabled: boolean = true;

    simulation_selected: SimulationData;
    simulation_list: Array < SimulationData > = [];

    simulation_noselected_data: string = "";

    export_report_disabled: boolean = true;

    // Error
    error_modal: boolean;
    error_header: string;
    error_body: string;

    // Progress
    info_progress: boolean;

    // Template
    templates_modal: boolean = false;
    templates_data: any;
    save_templates_button: boolean = false;

    saturation_info: string;


    /**
     *  
     */
    constructor(private authenticationService: AccountService, private message_svc: ShareService,
        private capaplan_svc: CapaplanService, private settings_svc: SettingsService, private json_svc:
        JsonloaderService) {

        this.cap = CapaResource.CAP;
        this.cons = CapaResource.CONS;
        this.host = CapaEntity.HOST;
        this.vm = CapaEntity.VM;
        this.ds = CapaEntity.DS;

        this.capaplan_lastdate_format = "";
        this.saturation_info = "";

        // Default values
        this.capaplan_simulation_option = 0;
        this.capaplan_haslider_level = HALevel.OTHERS;

        this.capaplan_haslider_label = HALevel[this.capaplan_haslider_level].toLowerCase();
        this.capaplan_haslider_label_totalvm = 0;
        this.ha_highest = 0;
        this.ha_high = 0;
        this.ha_medium = 0;
        this.ha_low = 0;
        this.ha_lowest = 0;
        this.ha_other = 0;

    }

    /**
     * 
     */
    ngOnInit(): void {
        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.saturation_info = this.formatTimeviewDate(this.message.minTimeFilter, false) + " and " + this
            .formatTimeviewDate(this.message.maxTimeFilter, false);

        // Reload capacity settings
        this.settings_svc.reload(this.currentUser.login, this.message.currentFilter, IhmSettingsTarget.CAPACITY,
            true);

        // Update capacity data
        this.settings_svc.currentCapa.subscribe(
            capaSettings => {
                this.capa_settings = capaSettings;
                this.capaplan_simulation_option = Number(this.capa_settings.p19);
                this.hasliderChange(Number(this.capa_settings.p4));
            }
        );

        // GET DATA
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.CAPA_INFRA).subscribe(
            data => {
                this.capaplan_json = data;
                //Notify child
                this.emitInfra();

                if (this.capaplan_json != null) {
                    // Init data date
                    let lasttime = Number(this.capaplan_json.CONS.CONSLASTMODELDATE);
                    if (lasttime != undefined) {
                        this.capaplan_lastdate_format = this.formatTimeviewDate(lasttime, false);
                    }

                    // Init HA VM number
                    this.ha_highest = Number(this.capaplan_json.HA.HA_HIGHEST);
                    this.ha_high = Number(this.capaplan_json.HA.HA_HIGH);
                    this.ha_medium = Number(this.capaplan_json.HA.HA_MEDIUM);
                    this.ha_low = Number(this.capaplan_json.HA.HA_LOW);
                    this.ha_lowest = Number(this.capaplan_json.HA.HA_LOWEST);
                    this.ha_other = Number(this.capaplan_json.HA.HA_OTHER);

                    // Update slider
                    this.hasliderChange(Number(this.capa_settings.p4));
                }
            }
        );

        // GET TEMPLATES
        this.capaplan_svc.getTemplates(this.currentUser.login).subscribe(
            data => {
                this.templates_data = data;
                //Notify child
                this.emitTemplates();
            }
        );

        // GET SIMULATIONS & SIMU CONF & SIMU CONF
        let tmp_simulations = this.capaplan_svc.getSimulations(this.currentUser.login);
        let tmp_simu_result = this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
            .CAPA_SIMU);
        let tmp_simu_conf = this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
            .CAPA_SIMU_CONFIG);

        zip(tmp_simulations, tmp_simu_result, tmp_simu_conf).subscribe(
            all => {
                this.simulation_list = all[0];
                this.capaplan_simulation_json = all[1];
                this.capaplan_simulation_config_json = all[2];

                this.server_simulation_config_json = this.capaplan_simulation_config_json;
                this.server_simulation_json = this.capaplan_simulation_json;

                this.simulation_selected = this.simulation_list[0];

                if (this.capaplan_simulation_config_json && this.capaplan_simulation_config_json.metadata) {
                    let current_simu = this.simulation_list.find(x => x.ID == this
                        .capaplan_simulation_config_json.metadata.ID);
                    if (current_simu) {
                        this.simulation_selected = current_simu;
                    }
                }

                this.switchSimulation();
            }
        );
    }

    emitInfra() {
        this.infraSubject.next(this.capaplan_json);
    }

    emitSimu() {
        this.simuSubject.next(this.capaplan_simulation_json);
    }

    emitConfig() {
        this.configSubject.next(this.capaplan_simulation_config_json);
    }

    emitTemplates() {
        this.templatesSubject.next(this.templates_data);
    }

    /**
     * 
     */
    hasliderChange(halevel_number: number): void {

        // Update haslider label
        let keys = Object.keys(HALevel).filter(x => HALevel[x] == halevel_number);
        this.capaplan_haslider_level = HALevel[keys[0]];

        let vm: number = 0;

        switch (this.capaplan_haslider_level) {
            case HALevel.HIGHEST:
                this.capaplan_haslider_label_totalvm = this.ha_highest;
                vm = this.ha_highest;
                break;
            case HALevel.HIGH:
                this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high;
                vm = this.ha_high;
                break;
            case HALevel.MEDIUM:
                this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high + this.ha_medium;
                vm = this.ha_medium;
                break;
            case HALevel.LOW:
                this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high + this.ha_medium + this
                    .ha_low;
                vm = this.ha_low;
                break;
            case HALevel.LOWEST:
                this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high + this.ha_medium + this
                    .ha_low + this.ha_lowest;
                vm = this.ha_lowest;
                break;
            case HALevel.OTHERS:
                this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high + this.ha_medium + this
                    .ha_low + this.ha_lowest + this.ha_other;
                vm = this.ha_other;
                break;
        }

        this.capaplan_haslider_label = keys[0].toLowerCase() + ' (' + vm + ' VM)';

    }

    /**
     * SIMULATIONS
     */
    newSimulation(): void {
        this.simulation_name_modal = false;

        let tmp_simu: SimulationData = {
            NAME: this.simulation_name,
            USER: this.currentUser.login,
            ID: uuid.v4(),
            DATA: this.simulation_noselected_data
        }
        this.simulation_list.push(tmp_simu);

        this.simulation_selected = tmp_simu;
        this.switchSimulation();

        // enable save & remove buttons
        this.simulation_save_disabled = false;
        this.simulation_remove_disabled = false;

        // save again
        if (this.simulation_noselected_data)
            this.saveSimulation();

        // restore default values
        this.simulation_name = "";
        this.simulation_noselected_data = "";

    }

    /**
     * 
     */
    saveSimulation(): void {
        // Convert data to btoa
        let json = this.generateSimulationConfigJson();

        if (this.simulation_selected) {
            this.simulation_selected.DATA = btoa(JSON.stringify(json));

            // Clear simulation result
            this.clearSimulationResult();

            // Push simulation data to server
            this.capaplan_svc.pushSimulation(this.simulation_selected).subscribe(
                success => {
                    this.simulation_save_disabled = true;
                },
                error => {
                    this.message.waiting = false;
                    this.error_body = "An error has occurred during save simulation !";
                    this.displayError();
                }
            );
        } else {
            // simulation creation
            this.simulation_name_modal = true;
            // backup data
            this.simulation_noselected_data = btoa(JSON.stringify(json));
        }
    }

    /**
     * 
     */
    removeSimulation(): void {
        // Clear simulation result
        this.clearSimulationResult();

        this.capaplan_svc.removeSimulation(this.simulation_selected).subscribe(
            success => {
                this.capaplan_svc.getSimulations(this.currentUser.login).subscribe(
                    data => {
                        this.simulation_list = data;
                        this.simulation_selected = data[0];
                        this.switchSimulation();
                    }
                );
            },
            error => {
                this.message.waiting = false;
                this.error_body = "An error has occurred during save simulation !";
                this.displayError();
            }
        );
    }

    /**
     * 
     */
    exportSimulation(): void {
        let json = JSON.stringify(this.generateSimulationConfigJson());

        let file_type = 'application/json';

        // Create temp link
        let blob: Blob = new Blob([json], {
            type: file_type
        });
        let fileName = 'Capaplan-simulation.json';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    /**
     * 
     */
    switchSimulation(): void {
        // disable export button
        this.export_report_disabled = true;

        // disable save button
        this.simulation_save_disabled = true;

        // update simulation config
        this.capaplan_simulation_config_json = {};

        if (this.simulation_selected) {
            let data = atob(this.simulation_selected.DATA);
            if (data)
                this.capaplan_simulation_config_json = JSON.parse(data);

            // enable remove button
            this.simulation_remove_disabled = false;

        } else {
            // disable remove button
            this.simulation_remove_disabled = true;
            // enable export report button (able to export without simu config)
            this.export_report_disabled = false;
        }

        // Notify child
        this.emitConfig();

        // update simulation result
        this.capaplan_simulation_json = {};

        if (this.server_simulation_config_json && this.server_simulation_config_json.metadata) {
            // Restore simulation result & export button
            if (this.server_simulation_config_json.metadata.ID == this.simulation_selected.ID) {
                this.capaplan_simulation_json = this.server_simulation_json;
                // enable export button
                this.export_report_disabled = false;
            }
        }

        // Notify child
        this.emitSimu();
    }

    /**
     * 
     */
    checkSimulationName(): void {
        this.simulation_name = this.checkName(this.simulation_name);
    }

    /**
     * 
     */
    private clearSimulationResult(): void {
        if (this.server_simulation_config_json && this.server_simulation_config_json.metadata)
            if (this.server_simulation_config_json.metadata.ID == this.simulation_selected.ID) {
                this.server_simulation_config_json = {};
                this.server_simulation_json = {};
                this.capaplan_simulation_json = {};

                //Emit simu
                this.emitSimu();

                //Remove files on server
                this.capaplan_svc.removeCapaSimulationFiles(this.currentUser.login, this.message.currentFilter)
                    .subscribe(
                        data => {}
                    );

                //Disable export report button
                this.export_report_disabled = true;
            }
    }

    /**
     * 
     */
    private generateSimulationConfigJson(): any {
        let vms: HTMLCollectionOf < HTMLTableRowElement > = document.getElementById("capa-simul-vm")
            .getElementsByTagName("tr");
        let servers: HTMLCollectionOf < HTMLTableRowElement > = document.getElementById("capa-simul-host")
            .getElementsByTagName("tr");
        let datastores: HTMLCollectionOf < HTMLTableRowElement > = document.getElementById("capa-simul-ds")
            .getElementsByTagName("tr");

        let tmp_metadata: {};
        if (this.simulation_selected) {
            tmp_metadata = {
                USER: this.simulation_selected.USER,
                ID: this.simulation_selected.ID
            }
        }

        let json = {
            metadata: tmp_metadata,
            vm: [],
            server: [],
            datastore: []
        };

        // ADD VM
        for (let i = 1; i < vms.length; i++) {
            let vm = vms[i].dataset.id;
            let action = vms[i].dataset.action;
            let value = vms[i].dataset.value;
            let dataVM = value.split(this.simulation_value_separator);

            // Looking for QUANTITY
            let quantity: number = 1;
            let input: HTMLInputElement;
            let query = '[data-id~="' + vm.replace(this.simulation_id_suffix, this.simulation_quantity_suffix) +
                '"]';
            let list: NodeList = vms[i].querySelectorAll(query);
            if (list.length > 0) {
                input = list[0] as HTMLInputElement;
                quantity = Number(input.value);
            }

            let element: any = {};
            element.ACTION = action;
            element.ID = dataVM[0];
            element.NAME = dataVM[1];
            element.RAMCAP = Number(dataVM[2]);
            element.CPUTHREADNB = Number(dataVM[3]);
            element.DISKCAP = Number(dataVM[4]);
            element.RAMCONS = Number(dataVM[5]);
            element.CPUCONSMHZ = Number(dataVM[6]);
            element.QUANTITY = quantity;

            json.vm.push(element);
        }

        // ADD SRV
        for (let i = 1; i < servers.length; i++) {
            let srv = servers[i].dataset.id;
            let action = servers[i].dataset.action;
            let value = servers[i].dataset.value;
            let dataSRV = value.split(this.simulation_value_separator);

            // Looking for QUANTITY
            let quantity: number = 1;
            let input: HTMLInputElement;
            let query = '[data-id~="' + srv.replace(this.simulation_id_suffix, this.simulation_quantity_suffix) +
                '"]';
            let list: NodeList = servers[i].querySelectorAll(query);
            if (list.length > 0) {
                input = list[0] as HTMLInputElement;
                quantity = Number(input.value);
            }

            let element: any = {};
            element.ACTION = action;
            element.ID = dataSRV[0];
            element.NAME = dataSRV[1];
            element.RAMCAP = Number(dataSRV[2]);
            element.CPUTHREADNB = Number(dataSRV[3]);
            element.RAMCONS = 0;
            element.CPUCONSMHZ = 0;
            element.QUANTITY = quantity;

            json.server.push(element);
        }


        // ADD DS
        for (let i = 1; i < datastores.length; i++) {
            let ds = datastores[i].dataset.id;
            let action = datastores[i].dataset.action;
            let value = datastores[i].dataset.value;
            let dataDS = value.split(this.simulation_value_separator);

            // Looking for QUANTITY
            let quantity: number = 1;
            let input: HTMLInputElement;
            let query = '[data-id~="' + ds.replace(this.simulation_id_suffix, this.simulation_quantity_suffix) +
                '"]';
            let list: NodeList = datastores[i].querySelectorAll(query);
            if (list.length > 0) {
                input = list[0] as HTMLInputElement;
                quantity = Number(input.value);
            }

            let element: any = {};
            element.ACTION = action;
            element.ID = dataDS[0];
            element.NAME = dataDS[1];
            element.DISKCAP = Number(dataDS[2]);
            element.QUANTITY = quantity;

            json.datastore.push(element);
        }

        return json;
    }

    /**
     * Save simulation into a JSON & push to server
     */
    private pushSimulationConfigFile(): void {
        let json = this.generateSimulationConfigJson();

        // Send JSON to server
        this.capaplan_svc.saveCapaSimulationConfigFile(this.currentUser.login, this.message.currentFilter, JSON
            .parse(JSON
                .stringify(json))).subscribe(
            success => {
                // Update the simulation config file from server
                this.server_simulation_config_json = json;
            },
            error => {
                if (error != null)
                    console.log("ERROR " + error);
            }
        );
    }

    /**
     * TEMPLATES
     */
    async manageTemplate(): Promise < void > {
        // GET TEMPLATES (async)
        let templates_subscribe = await this.capaplan_svc.getTemplates(this.currentUser.login).toPromise();
        this.templates_data = templates_subscribe;

        this.templates_modal = true;
        this.save_templates_button = true;
    }

    /**
     * 
     */
    addTemplate(type: CapaEntity): void {
        let tmp_data: any;

        switch (type) {
            case CapaEntity.VM:
                tmp_data = {
                    USER: this.currentUser.login,
                    ID: uuid.v4(),
                    NAME: "my_vm_template",
                    TYPE: type,
                    CPUTHREADNB: 1,
                    RAMCAP: 4096, //in MB
                    DISKCAP: 51200 //in MB
                };
                break;
            case CapaEntity.HOST:
                tmp_data = {
                    USER: this.currentUser.login,
                    ID: uuid.v4(),
                    NAME: "my_host_template",
                    TYPE: type,
                    CPUTHREADNB: 24,
                    RAMCAP: 262144, //in MB
                    DISKCAP: 0,
                };
                break;
            case CapaEntity.DS:
                tmp_data = {
                    USER: this.currentUser.login,
                    ID: uuid.v4(),
                    NAME: "my_ds_template",
                    TYPE: type,
                    CPUTHREADNB: 0,
                    RAMCAP: 0,
                    DISKCAP: 51200 //in MB
                };
                break;
            default:
                break;
        }

        this.templates_data.push(tmp_data);

        // Check for all templates
        this.checkTemplates();
    }

    /**
     * 
     */
    deleteTemplate(res: any) {
        this.templates_data = this.templates_data.filter(template => template.ID != res.ID);
    }

    /**
     * 
     */
    checkTemplatesSettings(element: HTMLInputElement, template: any): void {
        switch (element.id) {
            case 'name':
                this.checkTemplateName(template);
                break;
            default:
                // Check values
                if (element.valueAsNumber >= 0) {
                    this.save_templates_button = true;
                } else {
                    element.valueAsNumber = 0;
                    this.save_templates_button = false;
                }
                break;
        }

        // Check for all templates
        this.checkTemplates();
    }

    /**
     * 
     */
    private checkTemplates(): void {
        this.save_templates_button = true;

        for (let template of this.templates_data) {
            switch (template.TYPE) {
                case CapaEntity.VM:
                    if (template.NAME == "" || template.CPUTHREADNB <= 0 || template.RAMCAP <= 0 || template
                        .DISKCAP <= 0) {
                        this.save_templates_button = false;
                    }
                    break;
                case CapaEntity.DS:
                    if (template.NAME == "" || template.DISKCAP <= 0) {
                        this.save_templates_button = false;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * 
     */
    saveTemplates(): void {
        let json: JSON = JSON.parse(JSON.stringify(this.templates_data));

        //FIX empty templates
        if (this.templates_data.length == 0) {
            let tmp_data: any = {
                USER: this.currentUser.login,
            };
            let tmp_templates: any = [];
            tmp_templates.push(tmp_data);
            json = JSON.parse(JSON.stringify(tmp_templates));
        }

        // Save templates
        this.capaplan_svc.updateTemplates(json).subscribe(
            success => {},
            error => {}
        );

        this.templates_modal = false;

        //Notify uptdate
        this.emitTemplates();
    }

    /**
     * 
     */
    checkTemplateName(template: any): void {
        template.NAME = this.checkName(template.NAME);
    }

    /**
     * 
     */
    getTemplatesFromType(type: CapaEntity): any {
        if (this.templates_data !== undefined)
            return this.templates_data.filter(template => template.TYPE == type);
    }

    /**
     * RUN SIMU
     */
    runSimulation(): void {

        if (this.capaplan_haslider_label_totalvm > 0) {

            // Show waiting
            this.message.waiting = true;

            // Push simu conf to server
            this.pushSimulationConfigFile();

            // Save settings
            this.capa_settings.p4 = this.capaplan_haslider_level.toString();
            this.capa_settings.p19 = this.capaplan_simulation_option.toString();
            this.settings_svc.saveIhmSettings(this.capa_settings);


            // TODO progress bar ?
            this.capaplan_svc.runSimulation(this.currentUser.login, this.message.currentFilter).subscribe(
                success => {

                    // GET SIMU DATA
                    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                        .CAPA_SIMU).subscribe(
                        data => {
                            this.capaplan_simulation_json = data;
                            this.server_simulation_json = this.capaplan_simulation_json;

                            //Notify child
                            this.emitSimu();

                            //Enable export report button
                            this.export_report_disabled = false;

                            this.message.waiting = false;
                        }
                    );

                },
                error => {
                    this.message.waiting = false;
                    this.error_body = "An error has occurred during simulation !";
                    this.displayError();
                }
            );

        } else {
            this.error_body = "Can not run simulation : No VM for HA option : " + this.capaplan_haslider_label;
            this.displayError();
        }

    }

    /**
     * GENERATE REPORT
     */
    generateReport(): void {
        // Show waiting
        this.message.waiting = true;

        // Generate & get report
        this.capaplan_svc.generateReport(this.currentUser.login, this.message.currentFilter).subscribe(
            res => {

                // Hide waiting
                this.message.waiting = false;

                //window.open(window.URL.createObjectURL(res), '_blank');

                let file_type = "application/pdf";

                // Create temp link
                let blob: Blob = new Blob([res], {
                    type: file_type
                });
                let fileName = 'CapaPlan.pdf';
                let objectUrl: string = URL.createObjectURL(blob);

                let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
            },
            error => {
                // Hide waiting
                this.message.waiting = false;
                this.error_body = "An error has occurred during report generation !";
                this.displayError();

            }
        );

    }

    /**
     * 
     */
    private displayError() {
        this.error_header = "Sorry ... ";
        this.error_modal = true;
    }

    /**
     *
     */
    private checkName(name: string): string {
        //Remove accent
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        for (var i = 0; i < accent.length; i++) {
            name = name.replace(accent[i], noaccent[i]);
        }

        //Remove specs chars
        name = name.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:.;?,<> ]/g, "_");

        return name;
    }

    /**
     *
     */
    private formatTimeviewDate(time: number, showhour: boolean): string {
        if (!showhour) {
            return moment(time).format('MMMM Do YYYY');
        } else
            return moment(time).format('MMMM Do YYYY h:mm a');
    }

}
