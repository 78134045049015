import {
    Component,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    GreenitTarget
} from '@app/greenit/greenit.enums';
import {
    GreenitcfComponent
} from '@app/greenit/greenitcf/greenitcf.component';
import {
    JSONTarget,
    Message,
    User
} from '@app/model';
import {
    AccountService,
    GreenitService,
    JsonloaderService,
    ShareService
} from '@app/services';
import {
    T8Component
} from '@app/t8/t8.component';
import {
    ClrDatagridSortOrder
} from '@clr/angular';
import {
    Subscription
} from 'rxjs';

@Component({
    selector: 'app-allinclusive',
    templateUrl: './allinclusive.component.html',
    styleUrls: ['./allinclusive.component.css']
})
export class AllinclusiveComponent implements OnInit {

    @ViewChild('greenitcf') greenitcf: GreenitcfComponent;
    @ViewChild('t8') t8: T8Component;

    dataOrder = ClrDatagridSortOrder.ASC;

    message: Message;

    private currentUser: User;

    isReady: boolean = false;
    allowReload: boolean = false;

    t1_target: GreenitTarget = GreenitTarget.ALL_INCLUSIVE_CO2;
    t2_target: JSONTarget = JSONTarget.T8_ALL;

    json_subscription: Subscription;

    network_data_check: boolean = false;
    storage_data_check: boolean = false;
    refnodes_data_check: boolean = false;

    greenit_refnodes: any;
    refnodes_modal: boolean = false;

    warning_message: string = "Storage & Network data are extrapolations.";


    /**
     * 
     */
    constructor(private json_svc: JsonloaderService, private greenit_svc: GreenitService,
        private message_svc: ShareService, private authenticationService: AccountService) {}

    /**
     * 
     */
    async ngOnInit(): Promise < void > {
        this.isReady = true;

        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        // Check network data
        await this.greenit_svc.networkDataCheck.subscribe(
            details => {
                this.network_data_check = details;
            }
        );

        // Check storage data
        await this.greenit_svc.storageDataCheck.subscribe(
            details => {
                this.storage_data_check = details;
            }
        );

        // Get refnodes
        await this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
            .GREENIT_REFNODES_LIST).subscribe(
            data => {
                try {
                    let tmp_data: string = data;
                    if (tmp_data.length > 0) {
                        this.greenit_refnodes = data;
                        if (this.greenit_refnodes) {
                            this.refnodes_data_check = true;
                        }
                    }
                } catch {
                    // Nothing
                }
            }
        );

        // Need subscription to JsonloaderService to detect filter reload
        this.json_subscription = this.json_svc.currentJson.subscribe(
            json => {
                if (this.greenitcf !== undefined && this.allowReload) {
                    this.greenitcf.load(true);
                }
                if (this.t8 !== undefined && this.allowReload) {
                    this.t8.load(true);
                }
                this.allowReload = true;
            }
        )
    }

    /**
     * 
     */
    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.json_subscription != undefined) {
            this.json_subscription.unsubscribe();
        }
        //console.log("destroyed allinclusive");
    }

    /**
     * 
     */
    getWarnMessage(): string {
        // Extrapolation message
        if (this.network_data_check && !this.storage_data_check)
            this.warning_message = "Storage data is an extrapolation.";
        else if (!this.network_data_check && this.storage_data_check)
            this.warning_message = "Network data is an extrapolation.";
        else if (this.network_data_check && this.storage_data_check)
            this.warning_message = "";
        return this.warning_message;
    }


    /**
     * 
     */
    export (): void {
        let res = ["NAME", "TYPE", "REF_NAME", "REF_TYPE"]
            .join(',') + '\n';
        res += Object.values < any > (this.greenit_refnodes).map(node => [node.NAME, node.TYPE, node.REF_NAME, node
            .REF_TYPE
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'samenodes.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

}
