<br>
<span style="float: right;" ngClass="addLink" (click)="message.kubeDetail = false" *ngIf="this.message.kubeDetail">
    <cds-icon shape="times" size="24"></cds-icon>
</span>
<div class="clr-row" *ngIf="this.message.kubeDetail">
    <div class="clr-col-12">
        <div clr-col-9>
            Current selection:
            <span *ngFor="let pathPart of displayedPath; let first = first; let last=last;">
                <span *ngIf="!first">/ </span>
                <a *ngIf="!last" (click)="changeSelectionFromTopMenu(pathPart)">
                    <span class="badge badge-1 navigation_link">{{ pathPart }}</span>
                </a>
                <a *ngIf="last" (click)="changeSelectionFromTopMenu(pathPart)">
                    <span class="badge badge-blue navigation_link">{{ pathPart }}</span></a>
            </span>
        </div>
    </div>
</div>

<div id="divSvg" style="margin-top: 10px; width: 100%; height: 80%; min-height: 400px;" *ngIf="this.message.kubeDetail"></div>

<span *ngIf="this.message.kubeDetail">
    <h5 style="display: inline; margin-right: 10px;">Containers matching the selection</h5>
    <span *ngFor="let pathPart of displayedPath; let first = first; let last=last;">
        <span *ngIf="!first">/ </span>
        <a *ngIf="!last" (click)="changeSelectionFromTopMenu(pathPart)">
            <span class="badge badge-1 navigation_link">{{ pathPart }}</span>
        </a>
        <a *ngIf="last" (click)="changeSelectionFromTopMenu(pathPart)">
            <span class="badge badge-blue navigation_link">{{ pathPart }}</span></a>
    </span>
</span>

<div class="datagrid-limit-height" *ngIf="this.message.kubeDetail">
    <clr-datagrid [clrDgLoading]="loading" class="datastoreDataGrid">
        <clr-dg-column [clrDgField]="'name'">CONTAINER</clr-dg-column>
        <clr-dg-column [clrDgField]="'pod'">POD</clr-dg-column>
        <clr-dg-column [clrDgField]="'node'">NODE</clr-dg-column>
        <clr-dg-column [clrDgField]="'cluster'">CLUSTER</clr-dg-column>
        <clr-dg-column [clrDgField]="'cpu'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'ram'"><ng-container *clrDgHideableColumn="{hidden: false}">RAM</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'power'"><ng-container *clrDgHideableColumn="{hidden: false}">POWER</ng-container></clr-dg-column>
        <clr-dg-row *clrDgItems="let container of listedContainers" [clrDgItem]="container">
            <clr-dg-cell>{{ container.name }}</clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric != 'storage'">
                <a (click)="changeSelection(container.pod_path)">
                    <span class="badge badge-blue navigation_link">{{ container.pod }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric != 'storage'">
                <a (click)="changeSelection(container.node_path)">
                    <span class="badge badge-blue navigation_link">{{ container.node }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric != 'storage'">
                <a (click)="changeSelection(container.cluster_path)">
                    <span class="badge badge-blue navigation_link">{{ container.cluster }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell>{{ container.cpu }} %</clr-dg-cell>
            <clr-dg-cell>{{ container.ram }} %</clr-dg-cell>
            <clr-dg-cell>{{ container.power }} Watt(s)</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-detail *clrIfDetail="let container">
            <clr-dg-detail-header>Container {{container.name}}</clr-dg-detail-header>
            <clr-dg-detail-body>
                <table class="table">
                    <thead>&nbsp;Container</thead>
                    <tr>
                        <th>Creation Date</th>
                        <th>Namespace</th>
                        <th>Image</th>
                        <th>State</th>
                    </tr>
                    <tr>
                        <td>{{convertTimeToDate(container.creation)}}</td>
                        <td>{{container.namespace}}</td>
                        <td>{{container.image}}</td>
                        <td>{{container.state}}</td>
                    </tr>
                </table>
                <table class="table">
                    <thead>&nbsp;Pod</thead>
                    <tr>
                        <th>Creation Date</th>
                        <th>Name</th> 
                        <th>Namespace</th>
                        <th>IP</th>
                    </tr>
                    <tr>
                        <td>{{convertTimeToDate(container.podCreation)}}</td>
                        <td>{{container.pod}}</td>
                        <td>{{container.podNamespace}}</td>
                        <td>{{container.podIp}}</td>
                    </tr>
                </table>
                <table class="table">
                    <thead>&nbsp;Node</thead>
                    <tr>
                        <th>Creation Date</th>
                        <th>Name</th>
                        <th>IP</th>
                        <th>Architecture</th>
                        <th>OS</th>
                        <th>OS Image</th>
                        <th>CPU</th>
                        <th>Memory (GB)</th>
                    </tr>
                    <tr>
                        <td>{{convertTimeToDate(container.nodeCreation)}}</td>
                        <td>{{container.node}}</td>
                        <td>{{container.nodeIp}}</td>
                        <td>{{container.nodeArch}}</td>
                        <td>{{container.nodeOs}}</td>
                        <td>{{container.nodeOsimage}}</td>
                        <td>{{container.nodeCpu}}</td>
                        <td>{{(container.nodeRam/1048576/1024).toFixed(2)}}</td>
                    </tr>
                </table>
            </clr-dg-detail-body>
          </clr-dg-detail>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Containers per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Containers
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>