<div *ngIf="isVmwareReady && tab==instancetype.VMWARE">
	<div class="clr-row">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload"></app-conscalendar>
		</div>
	</div>
</div>

<div *ngIf="tab==instancetype.AWS">
	<div class="clr-row" *ngIf="this.message.isValidAws">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload" *ngIf="isAwsReady"></app-conscalendar>
		</div>
	</div>
	<div *ngIf="!this.message.isValidAws">
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<div>
						<strong class="center">No data available !</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="tab==instancetype.AZURE">
    <div class="clr-row" *ngIf="this.message.isValidAzure">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload" *ngIf="isAzureReady"></app-conscalendar>
		</div>
	</div>
	<div *ngIf="!this.message.isValidAzure">
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<div>
						<strong class="center">No data available !</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="tab==instancetype.GCP">
	<div class="clr-row" *ngIf="this.message.isValidGcp">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload" *ngIf="isGcpReady"></app-conscalendar>
		</div>
	</div>
	<div *ngIf="!this.message.isValidGcp">
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<div>
						<strong class="center">No data available !</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="tab==instancetype.KUBERNETES">
	<div class="clr-row" *ngIf="this.message.isValidKubernetes">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload" *ngIf="isKubernetesReady"></app-conscalendar>
		</div>
	</div>
	<div *ngIf="!this.message.isValidKubernetes">
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<div>
						<strong class="center">No data available !</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="isXclarityReady && tab==instancetype.XCLARITY">
	<div class="clr-row">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload"></app-conscalendar>
		</div>
	</div>
</div>

<div *ngIf="isOpenmanageReady && tab==instancetype.OPENMANAGE">
	<div class="clr-row">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload"></app-conscalendar>
		</div>
	</div>
</div>

<div *ngIf="isOneviewReady && tab==instancetype.ONEVIEW">
	<div class="clr-row">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload" *ngIf="isOneviewReady"></app-conscalendar>
		</div>
	</div>
</div>

<div *ngIf="isIpmiReady && tab==instancetype.IPMI">
	<div class="clr-row">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
			<app-conscalendar [isreload]="isDataReload" *ngIf="isIpmiReady"></app-conscalendar>
		</div>
	</div>
</div>
