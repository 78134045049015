<div [ngClass]="cssClass">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="tree"></cds-icon>
			&nbsp;Efficiency
            <span *ngIf="!isCee && month_date" class="label" style="float:right">for {{month_date}}</span>
		</h5>
	</div>
	<div class="card-block" id="dashboard-t9-core" style="height:255px">
		<div class="card-text">
			<div *ngIf="isReady">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
						<cds-icon class="is-solid" shape="host"></cds-icon>
						&nbsp;<span class="label" style="background:#476888;color:white;border-color:#476888;">{{isCee ? "Less efficient" : "More energy-consuming"}}</span>
						<br>
						<label class="clr-control-label" style="padding-top:10px;margin-left:25px;word-wrap:anywhere;">{{worst_element.name}}&nbsp;({{isCee ? worst_element.cee : worst_element.value + " " + unit}})</label>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
						<cds-icon class="is-solid" shape="host"></cds-icon>
						&nbsp;<span class="label" style="background:white;color:black;">{{isCee ? "More efficient" : "Less energy-consuming"}}</span>
						<br>
						<label class="clr-control-label" style="padding-top:10px;margin-left:25px;word-wrap:anywhere;">{{best_element.name}}&nbsp;({{isCee ? best_element.cee : best_element.value + "  " + unit}})</label>
					</div>
				</div>
				<highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="margin-top:1.1rem;width:100%;display:block;" ></highcharts-chart>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
		</div>
	</div>
	<div class="card-footer" *ngIf="isReady && isCee && isVmware">
		<button class="btn btn-link" (click)="displayModalEfficiency()">Servers efficiency list</button>
        <button class="btn btn-link" (click)="displayModalOptimization()" style="float:right;">Servers optimization</button>
	</div>
</div>

<app-efficiencylist *ngIf="isReady && isCee && isVmware" #child [target]="target" [efficiency_data]="efficiency_data" [renewal_server_data]="renewal_data" (update)="initCEE()"></app-efficiencylist>
