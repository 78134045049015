import {
    Component,
    Input,
    ElementRef,
    ViewChild,
    OnInit
} from '@angular/core';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

declare var require: any;
import * as Highcharts from "highcharts";
require("highcharts/modules/networkgraph")(Highcharts);
import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offline(Highcharts);

@Component({
    selector: 'app-graph-region',
    templateUrl: './graph-region.component.html',
    styleUrls: ['./graph-region.component.css']
})
export class GraphRegionComponent implements OnInit {

    dataOrder = ClrDatagridSortOrder.ASC;

    region: string = '';
    region_str: string = '';

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};
    chartConstructor = "chart";
    chartOptions2: Highcharts.Options = {};

    chartCallback = function(chart) {

        let highChart: any = Highcharts;
        Highcharts.addEvent(Highcharts.Series, "afterSetOptions", function(
            e: any
        ) {
            var colors = Highcharts.getOptions().colors,
                i = 0,
                nodes = {};
            if (
                this instanceof highChart.seriesTypes.networkgraph &&
                e.options.id === "region-model"
            ) {
                e.options.data.forEach(function(link) {
                    if (link.length > 2) {
                        if (link[2] === "root") {
                            nodes[link[0]] = {
                                id: link[0],
                                marker: {
                                    radius: 50
                                }
                            };
                            nodes[link[1]] = {
                                id: link[1],
                                marker: {
                                    radius: 30
                                },
                                color: colors[i++]
                            };
                        } else if (nodes[link[0]] && nodes[link[0]].color) {
                            nodes[link[1]] = {
                                id: link[1],
                                color: nodes[link[0]].color
                            };
                        }
                    }
                });
                e.options.nodes = Object.keys(nodes).map(function(id) {
                    return nodes[id];
                });
            }
        });
        chart.series[0].update({})
    }; // optional function, defaults to null
    updateFlag = false; // optional boolean
    oneToOneFlag = true; // optional boolean, defaults to false
    runOutsideAngular = false;

    message: Message;

    datas: any = [];

    instances: any = [];

    isReady: boolean = false;
    isReady2: boolean = false;

    isRegionMap: boolean = false;

    isRegionList: boolean = false;

    instance_selected: any = {
        id: '',
        create: '',
        it: '',
        name: '',
        vcpu: 0,
        vram: 0,
        status: '',
        runh: 0,
        state: '',
        os: '',
        monitoring: '',
        billing: '',
        computeCost: 0,
        storageCost: 0,
        direct_power: 0,
        direct_co2: 0,
        grey_co2: 0,
        cpucons: 0,
        diskread: 0,
        diskwrite: 0,
        netin: 0,
        netout: 0,
        creditusage: 0,
        creditbalance: 0,
        surpluscreditscharged: 0
    };

    isInstanceInfo: boolean = false;

    resource: string = 'vram';
    unit: string = 'MB';
    regionSelected: string = '';
    regions: string[] = [];
    provider: string = '';

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.provider = this.message.cloudProvider;

        this.buildShape();

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_GRAPH_REGION2).subscribe(
            data => {
                // SET A REGION FILTER
                for (let i = 0; i < data.length; i++) {
                    if (!this.regions.includes(data[i].region))
                        this.regions.push(data[i].region);
                }
                this.regionSelected = this.regions[0];

                if (this.regionSelected != "") {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i] != undefined) {
                            if (data[i].region == this.regionSelected)
                                this.datas.push(data[i]);
                        }
                    }
                }

                Highcharts.setOptions({
                    plotOptions: {
                        series: {
                            animation: {
                                duration: 0
                            }
                        }
                    }
                });
                this.loadGraph2();
            }
        );
    }

    loadList() {

        this.isReady = false;
        this.isReady2 = false;
        this.instances = [];
        if (this.isRegionList) {
            for (let i = 0; i < this.datas.length; i++) {
                for (let j = 0; j < this.datas[i].data.length; j++) {
                    this.instances.push(this.datas[i].data[j]);
                }
            }
        } else {
            this.isReady2 = true;
        }
    }

    reloadGraph() {

        this.isRegionList = false;
        this.isReady = false;
        this.isReady2 = false;

        this.datas = [];
        if (this.isRegionMap) {
            this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
                .cloudProvider, JSONTarget.CLOUD_GRAPH_REGION).subscribe(
                data => {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i][0] == this.regionSelected || data[i][2] == this.regionSelected)
                            this.datas.push(data[i]);
                    }

                    Highcharts.setOptions({
                        plotOptions: {
                            series: {
                                animation: {
                                    duration: 0
                                }
                            }
                        }
                    });
                    this.loadGraph();
                }
            );
        } else {
            this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
                .cloudProvider, JSONTarget.CLOUD_GRAPH_REGION2).subscribe(
                data => {
                    // FILTER BY SELECTED REGION
                    if (this.regionSelected != "") {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i] != undefined) {
                                if (data[i].region == this.regionSelected)
                                    this.datas.push(data[i]);
                            }
                        }
                    }
                    for (let i = 0; i < data.length; i++) {
                        if (data[i] != undefined) {
                            for (let j = 0; j < data[i].data.length; j++) {
                                if (data[i].data[j] != undefined) {
                                    switch (this.resource) {
                                        case "cpucons":
                                            data[i].data[j].value = data[i].data[j].cpucons;
                                            this.unit = '%';
                                            break;
                                        case "vcpu":
                                            data[i].data[j].value = data[i].data[j].vcpu;
                                            this.unit = '';
                                            break;
                                        case "vram":
                                            data[i].data[j].value = data[i].data[j].vram;
                                            this.unit = 'MB';
                                            break;
                                        case "diskread":
                                            data[i].data[j].value = data[i].data[j].diskread;
                                            this.unit = 'bytes';
                                            break;
                                        case "diskwrite":
                                            data[i].data[j].value = data[i].data[j].diskwrite;
                                            this.unit = 'bytes';
                                            break;
                                        case "netin":
                                            data[i].data[j].value = data[i].data[j].netin;
                                            this.unit = 'bytes';
                                            break;
                                        case "netout":
                                            data[i].data[j].value = data[i].data[j].netout;
                                            this.unit = 'bytes';
                                            break;
                                        case "creditusage":
                                            data[i].data[j].value = data[i].data[j].creditusage;
                                            this.unit = '';
                                            break;
                                        case "creditbalance":
                                            data[i].data[j].value = data[i].data[j].creditusage;
                                            this.unit = '';
                                            break;
                                        case "surpluscreditscharged":
                                            data[i].data[j].value = data[i].data[j].surpluscreditscharged;
                                            this.unit = '';
                                            break;
                                        case "runhour":
                                            data[i].data[j].value = data[i].data[j].runh;
                                            this.unit = 'hours';
                                            break;
                                        case "computecost":
                                            let cost: number = 0;
                                            cost = +data[i].data[j].computeCost;
                                            data[i].data[j].value = cost;
                                            this.unit = '$';
                                            break;
                                        case "storagecost":
                                            let cost2: number = 0;
                                            cost2 = +data[i].data[j].storageCost;
                                            data[i].data[j].value = cost2;
                                            this.unit = '$';
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                        }
                    }

                    Highcharts.setOptions({
                        plotOptions: {
                            series: {
                                animation: {
                                    duration: 0
                                }
                            }
                        }
                    });
                    this.loadGraph2();
                }
            );
        }
    }

    convertToNumber(val: string): number {

        let value: number = 0;
        value = +val;

        return value;
    }

    toFixedNumber(val: any, nb: number): number {

        let value: number = 0;
        if (val != null)
            value = val.toFixed(nb);

        return value;
    }

    export () {

        let res = ["ID", "Name", "Subscription", "Region", "Creation date", "Type", "vCPU", "vRAM (MB)", "OS", "State",
            "Monitoring", "Billing", "Running hour in month", "Compute cost ($)", "Storage cost ($)",
            "Direct power (Wh)", "Direct Co2 (kgCo2eq)", "Grey emission (kgCo2eq)", "Cpu cons (%)",
            "Disk read (bytes)", "Disk write (bytes)", "Network in (bytes)", "Network out (bytes)", "Credit usage",
            "Credit balance", "Surplus credit charged"
        ].join(',') + '\n';
        res += Object.values < any > (this.instances).map(instance => [instance.id, instance.name, instance
            .subscription, instance.region, instance.create, instance.it, instance.vcpu, instance.vram,
            instance.os, instance.status, instance.monitoring, instance.billing, instance.runh,
            this.convertToNumber(instance.computeCost), this.convertToNumber(instance.storageCost),
            instance.direct_power, instance.direct_co2, instance.grey_co2,
            instance.cpucons, instance.diskread, instance.diskwrite, instance.netin, instance.netout,
            instance.creditusage, instance.creditbalance, instance.surpluscreditscharged
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'region_instances.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    private loadGraph() {

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: '',
                style: {
                    color: 'grey',
                    fontSize: '18px'
                }
            },
            chart: {
                height: "100%"
            },
            plotOptions: {
                networkgraph: {
                    keys: ['from', 'to'],
                    layoutAlgorithm: {
                        enableSimulation: true,
                        friction: -0.9
                    }
                },
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function(event) {
                                this.callInstanceInfo(event.point.id);
                            }.bind(this)
                        }
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        text: "download",
                        symbolStroke: "#0072A3",
                        theme: {
                            fill: "#FFFFFF",
                        },
                        x: -10
                    }
                }
            },
            series: [{
                id: "region-model",
                type: 'networkgraph',
                marker: {
                    radius: 12
                },
                dataLabels: {
                    enabled: true,
                    textPath: {
                        enabled: false
                    },
                    linkFormat: '',
                    allowOverlap: true
                },
                draggable: true,
                data: this.datas
            }]
        };

        this.isReady = true;
    }

    private loadGraph2() {

        let ins: any = {
            id: '',
            it: '',
            name: '',
            vcpu: 0,
            value: 0,
            runh: 0,
            status: '',
            os: '',
            monitoring: '',
            billing: ''
        };

        this.chartOptions2 = {
            credits: {
                enabled: false
            },
            chart: {
                type: 'packedbubble',
                height: '100%'
            },
            title: {
                text: ''
            },
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.name}:</b> {point.value} ' + this.unit
            },
            plotOptions: {
                packedbubble: {
                    minSize: '20%',
                    maxSize: '60%',
                    //useSimulation: false,
                    layoutAlgorithm: {
                        gravitationalConstant: 0.05,
                        splitSeries: "true",
                        seriesInteraction: false,
                        dragBetweenSeries: true,
                        parentNodeLimit: true
                    },
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                        filter: {
                            property: 'y',
                            operator: '>=',
                            value: 0
                        },
                        style: {
                            color: 'black',
                            textOutline: 'none',
                            fontWeight: 'normal'
                        }
                    }
                },
                series: {
                    cursor: 'pointer',
                    events: {
                        click: function(event) {
                            ins = event.point;
                        }
                    },
                    point: {
                        events: {
                            click: function() {
                                this.callInstanceInfo2(ins);
                            }.bind(this)
                        }
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        text: "download",
                        symbolStroke: "#0072A3",
                        x: -10
                    }
                }
            },
            series: this.datas
        };

        this.isReady2 = true;
    }

    private callInstanceInfo(id: string): void {

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_GRAPH_REGION2).subscribe(
            data => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i] != undefined) {
                        for (let j = 0; j < data[i].data.length; j++) {
                            if (data[i].data[j] != undefined) {
                                if (this.message.cloudProvider == "aws") {
                                    if (data[i].data[j].name == id) {
                                        this.instance_selected = data[i].data[j];
                                        this.isInstanceInfo = true;
                                        break;
                                    }
                                } else if (this.message.cloudProvider == "azure") {
                                    if (data[i].data[j].name == id) {
                                        this.instance_selected = data[i].data[j];
                                        this.isInstanceInfo = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        );
    }

    private callInstanceInfo2(ins: any): void {

        this.instance_selected = ins;
        this.isInstanceInfo = true;
    }

    private buildShape() {

        Highcharts.SVGRenderer.prototype.symbols.download = function(x, y, w, h) {
            var path = [
                // Arrow stem
                'M', x + w * 0.5, y,
                'L', x + w * 0.5, y + h * 0.7,
                // Arrow head
                'M', x + w * 0.3, y + h * 0.5,
                'L', x + w * 0.5, y + h * 0.7,
                'L', x + w * 0.7, y + h * 0.5,
                // Box
                'M', x, y + h * 0.9,
                'L', x, y + h,
                'L', x + w, y + h,
                'L', x + w, y + h * 0.9
            ];
            return path;
        };
    }
}
