import * as d3 from "d3";
import {
    ComboboxSelectionItem, GraphDataObject,
    NetscopeCommonFlowviewComponent
} from "@app/netscope/netscope-common-flowview/netscope-common-flowview.component";
import {
    FlowDetail,
    FlowMetric,
    FlowRessourceObject, Threat
} from "@app/netscope/netscope-flows-datagrid/netscope-flows-datagrid.component";
import {
    DCNetscopeResourceWithName, DomainResolution,
    GetDomainResolutionResponse,
    SuccessGetDomainResolutionResponse
} from "@app/services";
import {cpuIcon} from "@cds/core/icon";


export class ClustersViewerRenderer {

    computeVisualisationDate(graphData: GraphDataObject, flowComponent: NetscopeCommonFlowviewComponent) {
        let totalBytes = 0;
        let minBytes = 0;
        let maxBytes = 0;
        let links = graphData.links;
        if (links.length > 0) {
            totalBytes = links.map((d) => d.exchanged_bytes).reduce((a, b) => a + b);
            minBytes = Math.min(...links.map((d) => d.exchanged_bytes));
            maxBytes = Math.max(...links.map((d) => d.exchanged_bytes));
        }

        // Filter links with a datacenter
        links = links.filter((l) => {
            if (l.source.type === 'datacenter' || l.target.type === 'datacenter:') {
                return false;
            }
            return true;
        });

        let ignoredUuids = flowComponent.currentComboboxSelection
            .filter((selectionElement) => selectionElement.mode === "ignore")
            .map((selectionElement) => selectionElement.resource.uuid);
        let focusedUuids = flowComponent.currentComboboxSelection
            .filter((selectionElement) => selectionElement.mode === "focus")
            .map((selectionElement) => selectionElement.resource.uuid);

        // Compute position of vms
        let vms = graphData.vms
            .filter((vm) => vm.host !== undefined)
            .filter((n) => ignoredUuids.indexOf(n.uuid) === -1)
            .sort((vmA, vmB) => vmA.host.uuid.localeCompare(vmB.host.uuid));
        const vmsUuids = vms.map((vm) => vm.uuid);

        // Find relevant external IPs (that appear in at least one link)
        let mapExternalIpsUuidToExternalIp: Map<string, {uuid: string; name: string }> = new Map();
        for (let externalIp of graphData.external_ips) {
            mapExternalIpsUuidToExternalIp[externalIp.uuid] = externalIp;
        }

        let relavantExternalIpUuidsIdx = {};
        for (let link of graphData.links) {
            if (link.source.type === "external_ip" && relavantExternalIpUuidsIdx[link.source.uuid] === undefined) {
                relavantExternalIpUuidsIdx[link.source.uuid] = link.source.uuid;
            }
            if (link.target.type === "external_ip" && relavantExternalIpUuidsIdx[link.target.uuid] === undefined) {
                relavantExternalIpUuidsIdx[link.target.uuid] = link.target.uuid;
            }
        }
        // Check error here
        let relavantExternalIpNames = Object.keys(relavantExternalIpUuidsIdx)
            .map((uuid: string) => mapExternalIpsUuidToExternalIp[uuid]?.name);

        // Add External IPs to vms list (hacky)
        let ExternalIpsCommonHost = {
            name: "External IPs",
            uuid: "vim.ExternalIpaddressHost:commonHost",
            type: "external_ips_host",
            vms: [],
            children: [],
            visible: false
        }
        let relevantExternalIps = graphData.external_ips
            .filter((ipAddress) => relavantExternalIpNames.indexOf(ipAddress.name) !== -1);
        let ExternalIpsAsVms = relevantExternalIps
            .map((external_ip) => ({
                name: external_ip["name"],
                uuid: external_ip["uuid"],
                id: external_ip["uuid"],
                type: "external_ip",
                host: ExternalIpsCommonHost
            }));
        let ExternalIpsAsVmsUuids = ExternalIpsAsVms.map((ExternalIp) => ExternalIp.uuid);
        vms.push(...ExternalIpsAsVms)
        ExternalIpsCommonHost.vms = ExternalIpsAsVms;
        ExternalIpsCommonHost.children = ExternalIpsAsVmsUuids

        // Find relevant unknown IPs (that appear in at least one link)
        let relavantUnknownIpUuidsIdx = {};
        for (let link of graphData.links) {
            if (link.source.type === "unknown_ip" && relavantUnknownIpUuidsIdx[link.source.uuid] === undefined) {
                relavantUnknownIpUuidsIdx[link.source.uuid] = link.source.uuid;
            }
            if (link.target.type === "unknown_ip" && relavantUnknownIpUuidsIdx[link.target.uuid] === undefined) {
                relavantUnknownIpUuidsIdx[link.target.uuid] = link.target.uuid;
            }
        }
        let relavantUnknownIpNames = Object.keys(relavantUnknownIpUuidsIdx).map((uuid: string) => uuid.split(":")[1]);

        // Add unknown IPs to vms list (hacky)
        let UnknownIpsCommonHost = {
            name: "Unknown IPs",
            uuid: "vim.UnknownIpaddressHost:commonHost",
            type: "unknown_ips_host",
            vms: [],
            children: [],
            visible: false
        }
        let relevantUnknownIps = graphData.unknown_ips
            .filter((ipAddress) => relavantUnknownIpNames.indexOf(ipAddress.ipaddress) !== -1);
        let UnknownIpsAsVms = relevantUnknownIps
            .map((unknown_ip) => ({
                name: unknown_ip["resolved"] === undefined ? unknown_ip["ipaddress"] : unknown_ip["resolved"],
                uuid: `vim.UnknownIpaddress:${unknown_ip["ipaddress"]}`,
                id: `vim.UnknownIpaddress:${unknown_ip["ipaddress"]}`,
                type: "unknown_ip",
                host: UnknownIpsCommonHost
            }));
        let UnknownIpsAsVmsUuids = UnknownIpsAsVms.map((UnknownIp) => UnknownIp.uuid);
        vms.push(...UnknownIpsAsVms)
        UnknownIpsCommonHost.vms = UnknownIpsAsVms;
        UnknownIpsCommonHost.children = UnknownIpsAsVmsUuids;

        function isRelevant(uuid) {
            return vmsUuids.indexOf(uuid) !== -1 || ExternalIpsAsVmsUuids.indexOf(uuid) !== -1 ||
                UnknownIpsAsVmsUuids.indexOf(uuid) !== -1;
        }

        let hostsUuidsdIndex = new Map();
        vms.map((vm) => {
            hostsUuidsdIndex.set(vm.host.uuid, vm.host);
            if (vm.host.vms === undefined) {
                vm.host.vms = [];
            }
            vm.host.vms.push(vm);
        });
        let focusedVmsUuids = flowComponent.currentComboboxSelection
            .filter((s) => s.mode === "focus")
            .map((s) => s.resource.uuid);

        const hosts = graphData.routers
            .filter((n) => ignoredUuids.indexOf(n.uuid) === -1);

        links = links.filter((l) => isRelevant(l.source.uuid) && isRelevant(l.target.uuid));

        const totalTrafficBytes = links
            .map((l) => l.metrics)
            .reduce((a, b) => a.concat(b), [])
            .map((m) => m.exchanged_bytes)
            .reduce((a, b) => a + b, 0);

        if (focusedVmsUuids.length > 0 && flowComponent.showIndirectLinks === "no") {
            links = links.filter((l) => focusedVmsUuids.indexOf(l.source.uuid) !== -1 || focusedVmsUuids.indexOf(l.target.uuid) !== -1);
        }

        // Remove nodes that are not relevant to the current selection (focus)
        if (focusedVmsUuids.length > 0) {
            let relevantUuids = [...new Set(links.map((l) => [l.source.uuid, l.target.uuid]).flat())];
            vms = vms.filter((node) => relevantUuids.indexOf(node.id) !== -1 || focusedVmsUuids.indexOf(node.id) !== -1);
        }

        flowComponent.filteredLinks = links;

        // Ensure that between two hosts, there is at most one link that is displayed
        let linkIndex: Map<string, FlowDetail> = new Map();
        for (let link of links) {
            let key = `${link.source.uuid}__${link.target.uuid}`;
            if (! linkIndex.has(key)) {
                linkIndex.set(key, link)
            } else {
                let existingLink = linkIndex.get(key);
                existingLink.metrics.push(...link.metrics);
            }
        }
        links = [...linkIndex.values()];

        // Add ExternalIps Host and Unknown IPs host to the hosts
        hosts.push(ExternalIpsCommonHost);
        hosts.push(UnknownIpsCommonHost);

        return {
            links: links,
            vms: vms,
            hosts: hosts,
            minBytes: minBytes,
            maxBytes: maxBytes,
            totalTrafficBytes: totalTrafficBytes,
            focusedVmsUuids: focusedVmsUuids,
            ignoredUuids: ignoredUuids
        };
    }

    render(graphData: GraphDataObject, flowComponent: NetscopeCommonFlowviewComponent): void {

        let visualisationData = this.computeVisualisationDate(graphData, flowComponent);
        let links = visualisationData.links;
        let vms = visualisationData.vms;
        let hosts = visualisationData.hosts;
        let minBytes = visualisationData.minBytes;
        let maxBytes = visualisationData.maxBytes;
        let totalTrafficBytes = visualisationData.totalTrafficBytes;
        let focusedVmsUuids = visualisationData.focusedVmsUuids;
        let ignoredUuids = visualisationData.ignoredUuids;

        //
        // START RENDERING GRAPHICS
        //

        let d3Links = links.map((l) => {
            let linkClone = Object.assign({}, l);
            // @ts-ignore
            linkClone.source = linkClone.source.uuid;
            // @ts-ignore
            linkClone.target = linkClone.target.uuid;
            return linkClone;
        });

        // Redefine gravity
        if (flowComponent.gravityMode === "automatic" || flowComponent.gravityValue === undefined) {
            flowComponent.gravityValue = (-1) * (600 + ((10000 - 600) / 200) * vms.length);
        }

        let svgHeight = document.getElementById('divSvg').clientHeight;
        let svgWidth = document.getElementById('divSvg').clientWidth;

        if (svgHeight == 0) {
            svgHeight = flowComponent.lastSvgHeight;
        } else {
            flowComponent.lastSvgHeight = svgHeight;
        }

        if (svgWidth == 0) {
            svgWidth = flowComponent.lastSvgWidth;
        } else {
            flowComponent.lastSvgWidth = svgWidth;
        }

        const forceParameter = flowComponent.gravityValue;
        let graphDataNodesOnlyVms = vms;

        let ratioMinSizeOverMaxSize = 10;

        d3.select('div#divSvg').select("svg").remove();
        const svg = d3.select('div#divSvg')
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .on("click", (d, i) => {
                // Remove a previously open dropdown menus
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }
            });
        flowComponent.svg = svg;

        // Add zoom
        svg.call(d3.zoom()
            .extent([
                [0, 0],
                [svgWidth, svgHeight]
            ])
            .scaleExtent([0.01, ratioMinSizeOverMaxSize])
            .on("zoom", zoomed));

        const g = svg.append("g");

        const self = flowComponent;

        function zoomed({
            transform
        }) {
            self.graphParameters.userMovedMap = true;
            self.graphParameters.lastTransformation = transform;
            g.attr("transform", transform);
        }

        function safeLog(v) {
            if (v > 0) {
                return Math.log(v);
            }
            return 0;
        }

        const link = g.append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(d3Links)
            .enter().append('line')
            .attr('stroke', (d) => {
                if (flowComponent.linksAreColored === "yes" && (safeLog(maxBytes) - safeLog(minBytes)) > 0) {
                    let ratio = (safeLog(d.exchanged_bytes) - safeLog(minBytes)) / (safeLog(maxBytes) - safeLog(minBytes));
                    let angle = Math.round((1.0 - ratio) * 120);
                    let color = `hsl(${angle}, 100%, 50%)`;
                    return color;
                }

                if (focusedVmsUuids.indexOf(d.source.uuid) != -1 || focusedVmsUuids.indexOf(d.target.uuid) != -1) {
                    return `#e79807`;
                }

                return `#aaa`;
            })
            .on('mouseover', function(d, i) {
                d3.select(this).style('cursor', 'pointer');
            })
            .on('mouseout', function(d, i) {
                d3.select(this).style('cursor', 'default');
            })
            .on("click", function(d, i) {
                d.stopPropagation();

                // @ts-ignore
                let node_id = `${i.source.uuid}_${i.target.uuid}`.replaceAll(":", "__").replaceAll(".", "__");
                let dropdownId = 'dropdownmenu_' + node_id;

                let resourceUuidsAssociatedToThisLink = [i.source.uuid, i.target.uuid];
                let relevantMetrics = graphData.links
                    .filter((l) => resourceUuidsAssociatedToThisLink.indexOf(l.source.uuid) !== -1 &&
                        resourceUuidsAssociatedToThisLink.indexOf(l.target.uuid) !== -1)
                    .map((l) => l.metrics)
                    .flat()
                    .sort((a, b) => b.exchanged_bytes - a.exchanged_bytes);

                let existingDropdownForThisNode = document.getElementById(dropdownId) !== undefined && document
                    .getElementById(dropdownId) !== null;

                // Remove a previously open dropdown
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }

                if (!existingDropdownForThisNode) {

                    // let textNode = d3.select(this).selectAll("text");
                    // let focusLabel = focusedVmsUuids.indexOf(i.uuid) === -1 ? "Focus" : "Unfocus";

                    let mouseEvent: number[] = d3.pointer(d);
                    let mouseX = mouseEvent[0];
                    let mouseY = mouseEvent[1];

                    let uuidToNameIdx = {}

                    for (let resource of [i.source, i.target]) {
                        uuidToNameIdx[resource.uuid] = resource.name;
                    }

                    let metricsHtmlCode =
                        "<table class=\"table\" id='table_flows_links_popover' style='margin-top: 0px; min-width: 400px;'>";

                    metricsHtmlCode += `<thead>
					<tr>
						<th>Flow</th>
						<th>Information</th>
						<th style="white-space:nowrap">Total bytes</th>
					</tr>
					</thead>`;

                    function formatExchangedBytes(value, remainingUnits = ["bytes", "kiB", "MiB", "GiB"]) {
                        if (value < 1024 || remainingUnits.length === 1) {
                            let unit = remainingUnits[0];
                            if (unit === "bytes") {
                                return `${value} ${remainingUnits[0]}`;
                            }
                            return `${value.toFixed(2)} ${remainingUnits[0]}`;
                        }
                        return formatExchangedBytes(value / 1024, remainingUnits.slice(1));
                    }

                    for (let metric of relevantMetrics) {
                        let nameSource = uuidToNameIdx[metric.source_address.uuid];
                        let nameTarget = uuidToNameIdx[metric.destination_address.uuid];

                        if (nameSource == undefined) {
                            nameSource = metric.source_address.ipaddress;
                        }

                        if (nameTarget == undefined) {
                            nameTarget = metric.destination_address.ipaddress;
                        }

                        let metricHtmlCode = "<tr>";
                        if (metric.direction === 1) {
                            metricHtmlCode +=
                                `<td><span style="white-space:nowrap"><span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameSource}<span class="badge">${metric.port}</span></span>`;
                            metricHtmlCode += `&rarr; `;
                            metricHtmlCode +=
                                `<span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameTarget}</span></span></td>`;
                        } else {
                            metricHtmlCode +=
                                `<td><span style="white-space:nowrap; vertical-align: middle;"><span class="label" style="max-width: 100%; justify-content: left;">${nameSource}</span>`;
                            metricHtmlCode += `&rarr; `;
                            metricHtmlCode +=
                                `<span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameTarget}<span class="badge">${metric.port}</span></span></span></td>`;
                        }
                        metricHtmlCode += "<td><span style=\"white-space:nowrap\">";

                        let transportProtocolParts = metric.transport_protocol.split(" ");
                        if (transportProtocolParts.length > 1) {
                            let transportProtocol = transportProtocolParts[1];
                            metricHtmlCode += `<span class="label">${transportProtocol}</span>`;
                        }

                        let applicationProtocol = metric.application_protocol;
                        metricHtmlCode += `<span class="label">${applicationProtocol}</span>`;
                        metricHtmlCode += "</span></td>";

                        metricHtmlCode += "<td><span style=\"white-space:nowrap\">";
                        metricHtmlCode += formatExchangedBytes(metric.exchanged_bytes);
                        metricHtmlCode += "</span></td>";

                        metricHtmlCode += "</tr>";

                        metricsHtmlCode += metricHtmlCode;
                    }
                    metricsHtmlCode += "</table>"

                    let popover = g
                        .append("foreignObject")
                        .attr("class", "dropdown_actions")
                        .attr('id', dropdownId)
                        .attr('x', mouseX)
                        .attr('y', mouseY)
                        .attr('height', 0)
                        .attr('width', 0)
                        .html(metricsHtmlCode);

                    // Ensure that the node and its dropdown menu are above all elements of the svg parent node.
                    // This moves the nodes at the beginning of the parent's list of children.
                    d3.select(this).raise();

                    // Adjust size of popover
                    setTimeout(() => {
                        let element = document.getElementById("table_flows_links_popover");
                        popover
                            .transition()
                            .attr("height", element.offsetHeight + 5)
                            .attr("width", element.offsetWidth + 5);
                    }, 100);
                }
            })
            .attr('stroke-width', (d) => 1.0);

        const visuNode = g.append('g')
            .attr('class', 'nodes')
            .selectAll('g')
            .data(graphDataNodesOnlyVms)
            .enter().append('g');

        const nodeRadius = 15;

        let currentInstanceObject = flowComponent;
        visuNode
            .filter((d) => {
                return d.type === 'vm' || d.type === 'host' || d.type === 'external_ip' || d.type === 'unknown_ip';
            })
            .on("mouseover", function(d, i) {
                d3.select(this).style("cursor", "pointer");
            })
            .on("mouseout", function(d, i) {
                d3.select(this).style("cursor", "default");
            })
            .on("click", function(d, i) {
                d.stopPropagation();

                let mouseEvent: number[] = d3.pointer(d);

                let mouseX = i.x + mouseEvent[0];
                let mouseY = i.y + mouseEvent[1] - 12;

                let node_id = i.id.replaceAll(":", "__").replaceAll(".", "__");
                let node_name = i.name;
                let dropdownId = 'dropdownmenu_' + node_id;

                let existingDropdownForThisNode = document.getElementById(dropdownId) !== undefined && document
                    .getElementById(dropdownId) !== null;

                // Remove a previously open dropdown
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }

                if (!existingDropdownForThisNode) {

                    let textNode = d3.select(this).selectAll("text");
                    let focusLabel = focusedVmsUuids.indexOf(i.id) === -1 ? "Focus" : "Unfocus";

                    let popover = g
                        .append("foreignObject")
                        .attr("class", "dropdown_actions")
                        .attr('id', dropdownId)
                        .attr('width', 0)
                        .attr('height', 0)
                        .attr('x', mouseX)
                        .attr('y', mouseY)
                        .html("<div class=\"dropdown open\" style='z-index: 999; margin-top: 0px;'>\n" +
                            "    <div id=\"node_action_popover\" class=\"dropdown-menu\">\n" +
                            "        <h4 class=\"dropdown-header\">Select an action</h4>\n" +
                            "        <div id=\"dropdown_focus_" + node_id + "\" class=\"dropdown-item\">" +
                            focusLabel + "</div>\n" +
                            "        <div id=\"dropdown_ignore_" + node_id +
                            "\" class=\"dropdown-item\">Ignore</div>\n" +
                            "        <div id=\"dropdown_resolve_" + node_id +
                            "\"class=\"dropdown-item\">Resolve DNS</div>\n" +
                            "        <div id=\"dropdown_resolve_dns_neighbours_" + node_id +
                            "\"class=\"dropdown-item\">Resolve DNS of neighbours</div>\n" +
                            "    </div>\n" +
                            "</div>");

                    // Ensure that the node and its dropdown menu are above all elements of the svg parent node.
                    // This moves the nodes at the beginning of the parent's list of children.
                    d3.select(this).raise();

                    // Adjust size of popover
                    setTimeout(() => {
                        let element = document.getElementById("node_action_popover");
                        popover
                            .transition()
                            .attr("height", element.offsetHeight + 24)
                            .attr("width", element.offsetWidth + 5);
                    }, 100);

                    d3.select("#dropdown_ignore_" + node_id).on("click", function(d2, i2) {
                        flowComponent.currentComboboxSelection.push(new ComboboxSelectionItem(new DCNetscopeResourceWithName(i.uuid, i.name), "ignore"));
                        flowComponent.reloadUiAndRecomputeDistances();
                    });

                    d3.select("#dropdown_focus_" + node_id).on("click", function(d2, i2) {
                        d2.stopPropagation();
                        document.getElementById(dropdownId).remove();
                        currentInstanceObject.switchFocus(i);
                    });

                    d3.select("#dropdown_resolve_" + node_id).on("click", function(d2, i2) {
                        d2.stopPropagation();

                        let ipAddressCandidate = i.name;
                        document.getElementById(dropdownId).remove();
                        flowComponent.resolveDomain(ipAddressCandidate, (domainResolution: DomainResolution) => {
                            if (domainResolution.status !== "ok") {
                                return;
                            }
                            let ipValue = domainResolution.result.trim();
                            textNode.text(ipValue);
                            flowComponent.replayDataCopy.unknown_ips.filter((unknownIp) => unknownIp.uuid === i.uuid).map((unknownIp) => unknownIp.resolved = ipValue);
                        });
                    });

                    d3.select("#dropdown_resolve_dns_neighbours_" + node_id).on("click", function(d2, i2) {
                        d2.stopPropagation();
                        document.getElementById(dropdownId).remove();

                        let linksWithCurrentNode = links.filter((l) => l.source.uuid === i.id || l.target.uuid === i.id);
                        let neighboursUuids = linksWithCurrentNode.map((l) => [l.source.uuid, l.target.uuid]).flat()
                        let neighboursNodes = vms.filter((node) => neighboursUuids.indexOf(node.id) !== -1);

                        for (let node of neighboursNodes) {
                            let ipAddressCandidate = node.name;

                            let textNode = visuNode.filter((d) => d.id === node.id).selectAll("text");

                            flowComponent.resolveDomain(ipAddressCandidate, (domainResolution: DomainResolution) => {
                                if (domainResolution.status !== "ok") {
                                    return;
                                }
                                let ipValue = domainResolution.result.trim();
                                textNode.text(ipValue);
                                flowComponent.replayDataCopy.unknown_ips.filter((unknownIp) => unknownIp.uuid === i.uuid).map((unknownIp) => unknownIp.resolved = ipValue);
                            });
                        }
                    })
                }
            });

        const circles = visuNode.append('g')
            .append('circle')
            .attr('class', 'vmCircle')
            .attr('r', nodeRadius)
            .style('stroke-width', 5) // set the stroke width
            .attr('fill', (d) => 'white');

        function updateCirclesStrokeColor() {
            visuNode
                .selectAll('.vmCircle')
                .style('stroke', (d: FlowRessourceObject) => {
                    let focusedVmsIds = currentInstanceObject.currentComboboxSelection
                        .filter((s) => s.mode === "focus")
                        .map((s) => s.resource.uuid);
                    if (ignoredUuids.indexOf(d.uuid) === -1) {
                        if (focusedVmsIds.indexOf(d.uuid) !== -1 || focusedVmsIds.indexOf(d.uuid) !== -1) {
                            return '#e79807';
                        } else {
                            if (d.type === "external_ip") {
                                return '#613200';
                            }
                            if (d.type === "unknown_ip") {
                                return '#54ec2e';
                            }
                            return '#007FCB';
                        }
                    } else {
                        if (d.type === "external_ip") {
                            return '#613200';
                        }
                        if (d.type === "unknown_ip") {
                            return '#54ec2e';
                        }
                        return '#d91919';
                    }
                });
        }

        updateCirclesStrokeColor();

        if (flowComponent.selectedAppsGroups.length > 0) {
            flowComponent.addGroupsAppsCircles(visuNode);
        }

        const iconsSvgCode = {
            "datacenter": "<path d=\"M26.5,4.08C22.77,4.08,19,5.4,19,7.91V9.5a18.75,18.75,0,0,1,2,.2V7.91c0-.65,2.09-1.84,5.5-1.84S32,7.27,32,7.91V18.24c0,.54-1.46,1.44-3.9,1.73v2c3.13-.32,5.9-1.6,5.9-3.75V7.91C34,5.4,30.23,4.08,26.5,4.08Z\"/><path d=\"M4,18.24V7.91c0-.65,2.09-1.84,5.5-1.84S15,7.27,15,7.91V9.7a18.75,18.75,0,0,1,2-.2V7.91c0-2.52-3.77-3.84-7.5-3.84S2,5.4,2,7.91V18.24C2,20.4,4.77,21.67,7.9,22V20C5.46,19.68,4,18.78,4,18.24Z\"/><path d=\"M18,10.85c-4.93,0-8.65,1.88-8.65,4.38V27.54c0,2.5,3.72,4.38,8.65,4.38s8.65-1.88,8.65-4.38V15.23C26.65,12.73,22.93,10.85,18,10.85Zm6.65,7.67c-.85,1-3.42,2-6.65,2A14.49,14.49,0,0,1,14,20v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v3.12c-.85,1-3.42,2-6.65,2a14.49,14.49,0,0,1-4-.53v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v2.29c0,.95-2.65,2.38-6.65,2.38s-6.65-1.43-6.65-2.38V15.23c0-.95,2.65-2.38,6.65-2.38s6.65,1.43,6.65,2.38Z\"/>",
            "network": "<path d=\"M26.58,32h-18a1,1,0,1,0,0,2h18a1,1,0,0,0,0-2Z\"/><path d=\"M17.75,2a14,14,0,0,0-14,14c0,.45,0,.89.07,1.33l0,0h0A14,14,0,1,0,17.75,2Zm0,2a12,12,0,0,1,8.44,3.48c0,.33,0,.66,0,1A18.51,18.51,0,0,0,14,8.53a2.33,2.33,0,0,0-1.14-.61l-.25,0c-.12-.42-.23-.84-.32-1.27s-.14-.81-.19-1.22A11.92,11.92,0,0,1,17.75,4Zm-3,5.87A17,17,0,0,1,25.92,10a16.9,16.9,0,0,1-3.11,7,2.28,2.28,0,0,0-2.58.57c-.35-.2-.7-.4-1-.63a16,16,0,0,1-4.93-5.23,2.25,2.25,0,0,0,.47-1.77Zm-4-3.6c0,.21.06.43.1.64.09.44.21.87.33,1.3a2.28,2.28,0,0,0-1.1,2.25A18.32,18.32,0,0,0,5.9,14.22,12,12,0,0,1,10.76,6.27Zm0,15.71A2.34,2.34,0,0,0,9.2,23.74l-.64,0A11.94,11.94,0,0,1,5.8,16.92l.11-.19a16.9,16.9,0,0,1,4.81-4.89,2.31,2.31,0,0,0,2.28.63,17.53,17.53,0,0,0,5.35,5.65c.41.27.83.52,1.25.76A2.32,2.32,0,0,0,19.78,20a16.94,16.94,0,0,1-6.2,3.11A2.34,2.34,0,0,0,10.76,22Zm7,6a11.92,11.92,0,0,1-5.81-1.51l.28-.06a2.34,2.34,0,0,0,1.57-1.79,18.43,18.43,0,0,0,7-3.5,2.29,2.29,0,0,0,3-.62,17.41,17.41,0,0,0,4.32.56l.53,0A12,12,0,0,1,17.75,28Zm6.51-8.9a2.33,2.33,0,0,0-.33-1.19,18.4,18.4,0,0,0,3.39-7.37q.75.35,1.48.78a12,12,0,0,1,.42,8.2A16,16,0,0,1,24.27,19.11Z\"/>",
            "switch": "<path d=\"M5.71,14H20.92V12H5.71L9.42,8.27A1,1,0,1,0,8,6.86L1.89,13,8,19.14a1,1,0,1,0,1.42-1.4Z\"/><rect x=\"23\" y=\"12\" width=\"3\" height=\"2\"/><rect x=\"28\" y=\"12\" width=\"2\" height=\"2\"/><path d=\"M27.92,17.86a1,1,0,0,0-1.42,1.41L30.21,23H15v2H30.21L26.5,28.74a1,1,0,1,0,1.42,1.4L34,24Z\"/><rect x=\"10\" y=\"23\" width=\"3\" height=\"2\"/><rect x=\"6\" y=\"23\" width=\"2\" height=\"2\"/>",
            "port": "<path d=\"M6.06,30a1,1,0,0,1-1-1V8h-2a1,1,0,0,1,0-2h4V29A1,1,0,0,1,6.06,30Z\"/><path d=\"M30.06,27h-25V9h25a3,3,0,0,1,3,3V24A3,3,0,0,1,30.06,27Zm-23-2h23a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1h-23Z\"/><rect x=\"22.06\" y=\"20\" width=\"6\" height=\"2\"/><rect x=\"22.06\" y=\"14\" width=\"6\" height=\"2\"/><path d=\"M19.06,22h-8V20h7V14h2v7A1,1,0,0,1,19.06,22Z\"/>",
            "host": "<path d=\"M18,24.3a2.48,2.48,0,1,0,2.48,2.47A2.48,2.48,0,0,0,18,24.3Zm0,3.6a1.13,1.13,0,1,1,1.13-1.12A1.13,1.13,0,0,1,18,27.9Z\"/><rect x=\"13.5\" y=\"20.7\" width=\"9\" height=\"1.44\"/><path d=\"M25.65,3.6H10.35A1.35,1.35,0,0,0,9,4.95V32.4H27V4.95A1.35,1.35,0,0,0,25.65,3.6Zm-.45,27H10.8V5.4H25.2Z\"/><rect x=\"12.6\" y=\"7.2\" width=\"10.8\" height=\"1.44\"/><rect x=\"12.6\" y=\"10.8\" width=\"10.8\" height=\"1.44\"/>",
            "vm": "<path d=\"M11,5H25V8h2V5a2,2,0,0,0-2-2H11A2,2,0,0,0,9,5v6.85h2Z\"/><path d=\"M30,10H17v2h8v6h2V12h3V26H22V17a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2V31a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V28h8a2,2,0,0,0,2-2V12A2,2,0,0,0,30,10ZM6,31V17H20v9H16V20H14v6a2,2,0,0,0,2,2h4v3Z\"/>",
            "cloud": "<path d=\"M29,16.66a10.07,10.07,0,0,0,.25-2.24A10.19,10.19,0,0,0,8.91,13.36,10,10,0,0,0,1,23.1C1,28.19,5.62,33,10.57,33H27.09C31.28,33,35,29.1,35,24.65A8.29,8.29,0,0,0,29,16.66Z\"></path>"
        };
        const iconSvg = visuNode.append('g')
            .attr('viewBox', `0 0 ${nodeRadius} ${nodeRadius}`)
            .append('g')
            .attr('transform', `translate(-11, -10) scale(0.60)`)
            .html((d) => iconsSvgCode[d.type]);

        const labels = visuNode.append('text')
            .attr("id", (d) => "text_label_" + d.id)
            .text((d) => d.name)
            .attr('x', (d) => d.name.length * 6 / 2 * (-1))
            .attr('y', nodeRadius + 15)
            .attr('style', 'text-shadow: 1px 1px 2px white, -1px -1px 2px white;');

        // If relevant, set the last known positions of vms and links
        vms.map((vm) => {
            const vmKey = `${vm.uuid}`;
            if (self.lastForcePositions.hasOwnProperty(vmKey)) {
                vm.x = self.lastForcePositions[vmKey].x;
                vm.y = self.lastForcePositions[vmKey].y;
                vm.vx = self.lastForcePositions[vmKey].vx;
                vm.vy = self.lastForcePositions[vmKey].vy;
            }
        });

        d3Links.map((link: FlowDetail) => {
            const linkKey = `${link.source.uuid}_${link.target.uuid}`;
            if (self.lastForcePositions.hasOwnProperty(linkKey)) {
                link.x1 = self.lastForcePositions[linkKey].x1;
                link.y1 = self.lastForcePositions[linkKey].y1;
                link.x2 = self.lastForcePositions[linkKey].x2;
                link.y2 = self.lastForcePositions[linkKey].y2;
            }
        })

        const simulation = d3.forceSimulation()
            // @ts-ignore
            .force('link', d3.forceLink().id((d) => d.id))
            .force('charge', d3.forceManyBody().strength(forceParameter))
            .force('center', d3.forceCenter(svgWidth / 2, svgHeight / 2))
            .force('x', d3.forceX())
            .force('y', d3.forceY());

        simulation
            .nodes(vms)
            .on('tick', ticked);

        simulation
            .force('link')
            // @ts-ignore
            .links(d3Links);

        if (flowComponent.simulationAlreadyLoaded) {
            if (flowComponent.simulationShouldRestart) {
                simulation.alpha(2.0).restart();
                flowComponent.simulationShouldRestart = false;
            } else {
                simulation.alpha(flowComponent.lastAlpha).restart();
            }
        }

        flowComponent.simulationAlreadyLoaded = true;

        function ticked() {
            link
                .attr('x1', (d) => d.source.x)
                .attr('y1', (d) => d.source.y)
                .attr('x2', (d) => d.target.x)
                .attr('y2', (d) => d.target.y);
            link
                .each((d) => {
                    self.lastForcePositions[`${d.source.uuid}_${d.target.uuid}`] = {
                        x1: d.source.x,
                        y1: d.source.y,
                        x2: d.target.x,
                        y2: d.target.y,
                    }
                })

            visuNode
                .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')');
            visuNode
                .each((d) => {
                    self.lastForcePositions[`${d.uuid}`] = {
                        x: d.x,
                        y: d.y,
                        vx: d.vx,
                        vy: d.vy
                    }
                })

            self.lastAlpha = simulation.alpha();
        }

        // step1: zoom out, and let d3 recompute positions and sizes
        const [x, y, k] = [0, 0, 1.0];
        g.attr('transform', 'translate(' + x + ',' + y + ') scale(' + k + ')');
        flowComponent.svg.call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(x, y).scale(k)
        );

        // step2: center the main group by translating in the middle of the size difference
        // between the group and the svg
        let [x2, y2, k2] = [
            (g.node().getBBox().width - flowComponent.svg.node().getBBox().width) / 2 + 20,
            (g.node().getBBox().height - flowComponent.svg.node().getBBox().height) / 2 + 20,
            1.0
        ];

        if (flowComponent.graphParameters.userMovedMap) {
            [x2, y2, k2] = [flowComponent.graphParameters.lastTransformation.x, flowComponent.graphParameters
                .lastTransformation.y, flowComponent.graphParameters.lastTransformation.k
            ];
        }

        g.attr('transform', 'translate(' + x2 + ',' + y2 + ') scale(' + k2 + ')');
        flowComponent.svg.call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(x2, y2).scale(k2)
        );

        // Save initial visualisation's position parameters to enable "zoom-in", "zoom-out" and "reset" buttons to work.
        if (flowComponent.graphParameters.firstTransformation === undefined) {
            flowComponent.graphParameters.firstTransformation = {
                x: x2,
                y: y2,
                k: k2
            }
        }
    }
}

