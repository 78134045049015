import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    BehaviorSubject,
    Observable
} from 'rxjs';
import {
    first,
    map
} from 'rxjs/operators';
import {
    InstanceType,
    MonitorSettings,
    Status,
    StatusTarget
} from '@app/model';
import {
    environment
} from '@environments/environment';

import {
    CO2ScopeMenu
} from '@app/menus/co2scopemenu/co2scopemenu.enum';

import * as forge from 'node-forge';


@Injectable({
    providedIn: 'root'
})
export class MonitorService {

    instance_global_alert: number = 2;
    instance_details: Array < Status > = [];

    db_global_alert: number = 2;
    db_details: Array < Status > = [];

    //Default empty status
    default_status: Status = {
        status: 2,
        name: "No element found",
        date: new Date().getTime(),
        msg: "No status found",
        type: undefined,
        zombies: undefined
    };

    // Instance monitor check
    vmware_monitor_check: boolean = false;
    vcloud_monitor_check: boolean = false;
    aws_monitor_check: boolean = false;
    azure_monitor_check: boolean = false;
    kubernetes_monitor_check: boolean = false;
    scaphandre_monitor_check: boolean = false;
    xclarity_monitor_check: boolean = false;
    openmanage_monitor_check: boolean = false;
    oneview_monitor_check: boolean = false;
    ipmi_monitor_check: boolean = false;
    gcp_monitor_check: boolean = false;
    exagrid_monitor_check: boolean = false;
    ibm_monitor_check: boolean = false;

    // For tabs
    current_app_tab: InstanceType = InstanceType.ALL;
    app_tabs_status: Map < string,
    Boolean > = new Map < string,
    Boolean > ();

    // Custom status for network / storage tab (extrapolation or not) [updated from co2scopemenu.component]
    show_network_tab: boolean = false;
    show_storage_tab: boolean = false;


    private instanceGlobalSubject: BehaviorSubject < number > = new BehaviorSubject < number > (this
        .instance_global_alert);
    instanceGlobal: Observable < number > = this.instanceGlobalSubject.asObservable();

    private instanceDetailsSubject: BehaviorSubject < Array < Status >> = new BehaviorSubject < Array < Status >> (this
        .instance_details);
    instanceDetails: Observable < Array < Status >> = this.instanceDetailsSubject.asObservable();

    private vmwareCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .vmware_monitor_check);
    vmwareCheck: Observable < boolean > = this.vmwareCheckSubject.asObservable();

    private vcloudCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .vcloud_monitor_check);
    vcloudCheck: Observable < boolean > = this.vcloudCheckSubject.asObservable();

    private awsCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this.aws_monitor_check);
    awsCheck: Observable < boolean > = this.awsCheckSubject.asObservable();

    private azureCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this.azure_monitor_check);
    azureCheck: Observable < boolean > = this.azureCheckSubject.asObservable();

    private gcpCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this.gcp_monitor_check);
    gcpCheck: Observable < boolean > = this.gcpCheckSubject.asObservable();

    private kubernetesCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .kubernetes_monitor_check);
    kubernetesCheck: Observable < boolean > = this.kubernetesCheckSubject.asObservable();

    private scaphandreCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .scaphandre_monitor_check);
    scaphandreCheck: Observable < boolean > = this.scaphandreCheckSubject.asObservable();

    private xclarityCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .xclarity_monitor_check);
    xclarityCheck: Observable < boolean > = this.xclarityCheckSubject.asObservable();

    private openmanageCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .openmanage_monitor_check);
    openmanageCheck: Observable < boolean > = this.openmanageCheckSubject.asObservable();

    private oneviewCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .oneview_monitor_check);
    oneviewCheck: Observable < boolean > = this.oneviewCheckSubject.asObservable();

    private ipmiCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .ipmi_monitor_check);
    ipmiCheck: Observable < boolean > = this.ipmiCheckSubject.asObservable();

    private exagridCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .exagrid_monitor_check);
    exagridCheck: Observable < boolean > = this.exagridCheckSubject.asObservable();

    private ibmCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .ibm_monitor_check);
    ibmCheck: Observable < boolean > = this.ibmCheckSubject.asObservable();

    private dbGlobalSubject: BehaviorSubject < number > = new BehaviorSubject < number > (this.db_global_alert);
    dbGlobal: Observable < number > = this.dbGlobalSubject.asObservable();

    private dbDetailsSubject: BehaviorSubject < Array < Status >> = new BehaviorSubject < Array < Status >> (this
        .db_details);
    dbDetails: Observable < Array < Status >> = this.dbDetailsSubject.asObservable();

    private currentAppTabSubject: BehaviorSubject < InstanceType > = new BehaviorSubject < InstanceType > (this
        .current_app_tab);
    currentAppTab: Observable < InstanceType > = this.currentAppTabSubject.asObservable();

    private appTabsStatusSubject: BehaviorSubject < Map < string,
    Boolean >> = new BehaviorSubject < Map < string,
    Boolean >> (this.app_tabs_status);
    AppTabsStatus: Observable < Map < string,
    Boolean >> = this.appTabsStatusSubject.asObservable();

    /**
     *
     */
    constructor(private http: HttpClient) {}


    /**
     * For tabs
     */
    initAppTabs() {
        let default_tab: InstanceType = InstanceType.ALL;
        let default_menu: CO2ScopeMenu = CO2ScopeMenu.OVERVIEW;

        // Looking for tab
        let tmp_app_tab = localStorage.getItem("current_app_tab");
        if (tmp_app_tab !== undefined || tmp_app_tab != "undefined") {
            if (Object.values(InstanceType).some((tab: string) => tab === tmp_app_tab))
                default_tab = < InstanceType > tmp_app_tab;
            //console.log("tab key : " + default_tab + " for tab : " + tmp_app_tab);
        }

        // Looking for menu
        let tmp_app_menu = localStorage.getItem("current_app_menu");
        if (tmp_app_menu !== undefined || tmp_app_menu != "undefined") {
            if (Object.values(CO2ScopeMenu).some((tab: string) => tab === tmp_app_menu))
                default_menu = < CO2ScopeMenu > tmp_app_menu;
            //console.log("menu key : " + default_menu + " for menu : " + tmp_app_menu);
        }

        // FIX for VMware (keep default menu to null for tabs)
        if (default_tab == InstanceType.VMWARE && tmp_app_menu == "null")
            default_menu = null;


        this.setAppTab(default_tab);
        this.updateAppTabStatus(default_menu);
    }

    /**
     *
     */
    setAppTab(tab: InstanceType): void {
        this.current_app_tab = tab;
        localStorage.setItem("current_app_tab", tab);
        this.currentAppTabSubject.next(tab);
    }

    /**
     *
     */
    updateAppTabStatus(menu: CO2ScopeMenu): void {
        // Store current menu
        localStorage.setItem("current_app_menu", menu);
        // Init tab default status (true)
        Object.keys(InstanceType).forEach(element => {
            this.app_tabs_status.set(element.toLowerCase(), true);
        });

        if (menu) {
            switch (menu) {
                // ALL TABS
                case CO2ScopeMenu.OVERVIEW:
                    break;
                    // ONLY VMWARE
                case CO2ScopeMenu.GOD:
                case CO2ScopeMenu.POWER_DIST:
                case CO2ScopeMenu.GREEN_INDEX:
                case CO2ScopeMenu.GREENIT_ANALYSIS:
                case CO2ScopeMenu.CAPACITY_PLAN:
                case CO2ScopeMenu.SIMULATION:
                case CO2ScopeMenu.CLOUD_IMPACT:
                case CO2ScopeMenu.COST:
                    Object.keys(InstanceType).forEach(element => {
                        this.app_tabs_status.set(element.toLowerCase(), false);
                    });
                    this.app_tabs_status.set(InstanceType.VMWARE, true);
                    break;
                    // ONLY VMWARE/AWS/AZURE/GCP
                case CO2ScopeMenu.OPPORTUNITIES:
                    Object.keys(InstanceType).forEach(element => {
                        this.app_tabs_status.set(element.toLowerCase(), false);
                    });
                    this.app_tabs_status.set(InstanceType.VMWARE, true);
                    this.app_tabs_status.set(InstanceType.AWS, true);
                    this.app_tabs_status.set(InstanceType.AZURE, true);
                    this.app_tabs_status.set(InstanceType.GCP, true);
                    break;
                    // ONLY AWS/AZURE/GCP
                case CO2ScopeMenu.CLOUD_COMPARATOR:
                    Object.keys(InstanceType).forEach(element => {
                        this.app_tabs_status.set(element.toLowerCase(), false);
                    });
                    this.app_tabs_status.set(InstanceType.AWS, true);
                    this.app_tabs_status.set(InstanceType.AZURE, true);
                    this.app_tabs_status.set(InstanceType.GCP, true);
                    break;
                    // ALL EXCEPT ALL/SCAPHANDRE/NETWORK/STORAGE
                case CO2ScopeMenu.POWER_USAGE:
                    this.app_tabs_status.set(InstanceType.ALL, false);
                    this.app_tabs_status.set(InstanceType.SCAPHANDRE, false);
                    this.app_tabs_status.set(InstanceType.STORAGE, false);
                    this.app_tabs_status.set(InstanceType.NETWORK, false);
                    break;
                    // ALL EXCEPT ALL/STORAGE(extrpolation)/NETWORK
                case CO2ScopeMenu.POWER_GRAPH:
                    this.app_tabs_status.set(InstanceType.ALL, false);
                    this.app_tabs_status.set(InstanceType.STORAGE, this.show_storage_tab);
                    this.app_tabs_status.set(InstanceType.NETWORK, false);
                    break;
                    // ALL EXCEPT ALL/STORAGE(extrpolation)/NETWORK(extrapolation)
                case CO2ScopeMenu.CO2_EVOLUTION:
                    this.app_tabs_status.set(InstanceType.ALL, false);
                    this.app_tabs_status.set(InstanceType.STORAGE, this.show_storage_tab);
                    this.app_tabs_status.set(InstanceType.NETWORK, this.show_network_tab);
                    break;
                    // ONLY VMWARE/SCAPHANDRE/XCLARITY/OPENMANAGE/ONEVIEW/IPMI/STORAGE/NETWORK
                case CO2ScopeMenu.REPORT:
                    Object.keys(InstanceType).forEach(element => {
                        this.app_tabs_status.set(element.toLowerCase(), false);
                    });
                    this.app_tabs_status.set(InstanceType.VMWARE, true);
                    this.app_tabs_status.set(InstanceType.SCAPHANDRE, true);
                    this.app_tabs_status.set(InstanceType.XCLARITY, true);
                    this.app_tabs_status.set(InstanceType.OPENMANAGE, true);
                    this.app_tabs_status.set(InstanceType.ONEVIEW, true);
                    this.app_tabs_status.set(InstanceType.IPMI, true);
                    this.app_tabs_status.set(InstanceType.STORAGE, this.show_storage_tab);
                    this.app_tabs_status.set(InstanceType.NETWORK, this.show_network_tab);
                    break;
                default:
                    break;
            }
        } else {
            // menu is null or undefined
            // ONLY VMWARE
            Object.keys(InstanceType).forEach(element => {
                this.app_tabs_status.set(element.toLowerCase(), false);
            });
            this.app_tabs_status.set(InstanceType.VMWARE, true);
        }

        this.appTabsStatusSubject.next(this.app_tabs_status);
    }

    /**
     * Get monitoring/database status
     */
    getStatus(): void {
        this.getInstanceStatus();
        this.getDBStatus();
    }

    /**
     * Monitoring instances status
     */
    private getInstanceStatus(): void {
        this.http.get < any > (`${environment.apiUrl}/trackdc/getinstancesstatus`).pipe(map(infos => {
            return infos
        })).subscribe(
            data => {
                this.initStatus(data, null, StatusTarget.INSTANCE);
            },
            error => {
                this.initStatus(null, error, StatusTarget.INSTANCE);
            }
        );
    }

    /**
     * Database status
     */
    private getDBStatus(): void {
        this.http.get < any > (`${environment.apiUrl}/trackdc/getdbstatus`).pipe(map(infos => {
            return infos
        })).subscribe(
            data => {
                this.initStatus(data, null, StatusTarget.DB);
            },
            error => {
                this.initStatus(null, error, StatusTarget.DB);
            }
        );
    }

    /**
     *
     */
    private initStatus(data: any, error: any, target: StatusTarget): void {

        let tmp_global_alert: number = 2;
        let tmp_details: Array < Status > = [];
        let tmp_vmware_check = false;
        let tmp_vcloud_check = false;
        let tmp_aws_check = false;
        let tmp_azure_check = false;
        let tmp_gcp_check = false;
        let tmp_kubernetes_check = false;
        let tmp_scaphandre_check = false;
        let tmp_xclarity_check = false;
        let tmp_openmanage_check = false;
        let tmp_ipmi_check = false;
        let tmp_oneview_check = false;
        let tmp_exagrid_check = false;
        let tmp_ibm_check = false;

        if (data) {

            let total_alert: number = 0;
            let nb_alert = 0;

            // Build alert, details & check instance
            for (let i in data) {
                let tmp: Status = data[i];

                // Check if instance type is declared
                switch (tmp.type) {
                    case InstanceType.VMWARE:
                        tmp_vmware_check = true;
                        break;
                    case InstanceType.VCLOUD:
                        tmp_vcloud_check = true;
                        break;
                    case InstanceType.AWS:
                        tmp_aws_check = true;
                        break;
                    case InstanceType.AZURE:
                        tmp_azure_check = true;
                        break;
                    case InstanceType.GCP:
                        tmp_gcp_check = true;
                        break;
                    case InstanceType.KUBERNETES:
                        tmp_kubernetes_check = true;
                        break;
                    case InstanceType.SCAPHANDRE:
                        tmp_scaphandre_check = true;
                        break;
                    case InstanceType.XCLARITY:
                        tmp_xclarity_check = true;
                        break;
                    case InstanceType.OPENMANAGE:
                        tmp_openmanage_check = true;
                        break;
                    case InstanceType.ONEVIEW:
                        tmp_oneview_check = true;
                        break;
                    case InstanceType.IPMI:
                        tmp_ipmi_check = true;
                        break;
                    case InstanceType.EXAGRID:
                        tmp_exagrid_check = true;
                        break;
                    case InstanceType.IBM:
                        tmp_ibm_check = true;
                        break;
                }

                // Sum total alert & build details
                total_alert += tmp.status;
                if (tmp.status > 0 || tmp.zombies) {
                    tmp.name = i;
                    tmp_details.push(tmp);
                }
                nb_alert++;
            }

            // Compute global alert
            tmp_global_alert = total_alert / nb_alert;
            if (tmp_global_alert == 0)
                tmp_global_alert = 0;
            //else if(tmp_global_alert > 0 && tmp_global_alert < 1)
            //  tmp_global_alert = 1;
            else
                tmp_global_alert = 2;

        } else if (error) {
            // error : no status
            tmp_details.push(this.default_status);
        }

        // Update status
        switch (target) {
            case StatusTarget.INSTANCE:
                this.instanceGlobalSubject.next(tmp_global_alert);
                this.instanceDetailsSubject.next(tmp_details);
                // Force show VMWare menu if dev mode
                this.vmwareCheckSubject.next(tmp_vmware_check || !environment.production);
                // Force show vCloud Director menu if dev mode
                this.vcloudCheckSubject.next(tmp_vcloud_check || !environment.production);
                // Force show AWS menu if dev mode
                this.awsCheckSubject.next(tmp_aws_check || !environment.production);
                // Force show Azure menu if dev mode
                this.azureCheckSubject.next(tmp_azure_check || !environment.production);
                // Force show GCP menu if dev mode
                this.gcpCheckSubject.next(tmp_gcp_check || !environment.production);
                // Force show Kubernetes menu if dev mode
                this.kubernetesCheckSubject.next(tmp_kubernetes_check || !environment.production);
                // Force show Scaphandre menu if dev mode
                this.scaphandreCheckSubject.next(tmp_scaphandre_check || !environment.production);
                // Force show XClarity menu if dev mode
                this.xclarityCheckSubject.next(tmp_xclarity_check || !environment.production);
                // Force show Openmanage menu if dev mode
                this.openmanageCheckSubject.next(tmp_openmanage_check || !environment.production);
                // Force show Oneview menu if dev mode
                this.oneviewCheckSubject.next(tmp_oneview_check || !environment.production);
                // Force show ipmi menu if dev mode
                this.ipmiCheckSubject.next(tmp_ipmi_check || !environment.production);
                // Force show exagrid menu if dev mode
                this.exagridCheckSubject.next(tmp_exagrid_check || !environment.production);
                // Force show ibm menu if dev mode
                this.ibmCheckSubject.next(tmp_ibm_check || !environment.production);
                break;
            case StatusTarget.DB:
                this.dbGlobalSubject.next(tmp_global_alert);
                this.dbDetailsSubject.next(tmp_details);
                break;
            default:
                break;
        }

    }

    /**
     *
     */
    getArchive(user: string): Observable < Blob > {
        return this.http.get(`${environment.apiUrl}/trackdc/` + 'getarchive?user=' + user, {
            responseType: 'blob'
        });
    }

    /**
     *
     */
    checkInstall() {
        return this.http.get(`${environment.apiUrl}/trackdc/checkInstall`).pipe(map(list => {
            return list;
        }));
    }

    /**
     *
     */
    getMonitorList() {
        return this.http.get(`${environment.apiUrl}/trackdc/getinstanceslist`).pipe(map(list => {
            return list;
        }));
    }

    /**
     *
     */
    unregisterInstance(instance: string) {
        return this.http.post(`${environment.apiUrl}/trackdc/unregisterinstance`, [instance]);
    }

    /**
     *
     */
    registerInstance(data: JSON) {
        return this.http.post(`${environment.apiUrl}/trackdc/registerinstance`, data);
    }

    /**
     *
     */
    migrateTest(ip: string, password: string) {
        return this.http.post(`${environment.apiUrl}/trackdc/migratetest`, [ip, password]);
    }

    /**
     *
     */
    migrate(ip: string, password: string, retention: number) {
        return this.http.post(`${environment.apiUrl}/trackdc/migrate`, [ip, password, retention]);
    }

    /**
     *
     */
    getMigrateDetails() {
        return this.http.get(`${environment.apiUrl}/trackdc/migratedetails`).pipe(map(infos => {
            return infos;
        }, error => {}));
    }

    /**
     *
     */
    dropMigrate() {
        this.http.post(`${environment.apiUrl}/trackdc/dropmigrate`, ["btrpersist_migration"]).subscribe();
    }

    /**
     *
     */
    getApp() {
        return this.http.get < MonitorSettings[] > (`${environment.apiUrl}/trackdc/getapp`).pipe(map(
            infos => {
                return infos;
            }, error => {}));
    }

    /**
     *
     */
    getSettings() {
        return this.http.get < MonitorSettings[] > (`${environment.apiUrl}/trackdc/getsettings`).pipe(map(
            infos => {
                return infos;
            }, error => {}));
    }

    /**
     * 
     */
    private getTrackdcPK() {
        return this.http.get < String > (`${environment.apiUrl}/trackdc/getpk`).pipe(map(data => {
            return forge.pki.publicKeyFromPem(( < any > data).publicKey);
        }));
    }

    async crypt(password: string) {
        let data_pk = await this.getTrackdcPK().pipe(first()).toPromise();
        let hash = data_pk.encrypt(password, 'RSA-OAEP', {
            md: forge.md.sha256.create(),
            mgf1: {
                md: forge.md.sha1.create()
            }
        });
        return forge.util.encode64(hash);
    }

    /**
     *
     */
    checkDatalock() {
        return this.http.get < any > (`${environment.apiUrl}/trackdc/checkdatalock`).pipe(map(data => {
            return data;
        }));
    }

    /**
     *
     */
    getPluginXML() {
        return this.http.get(`${environment.apiUrl}/plugin/getxml`, {
            responseType: 'blob'
        });
    }

    /**
     *
     */
    updatePlugin(hostname: string) {
        return this.http.post(`${environment.apiUrl}/plugin/update`, [hostname]);
    }

}
