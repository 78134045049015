import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    BehaviorSubject,
    Observable
} from 'rxjs';
import {
    map
} from 'rxjs/operators';

import {
    Alert,
    AlertCatalog,
    AlertHistory,
    AlertTimeHistory,
    Behavior,
    ElementBehaviors,
    MetaAlert,
    MetaRunAlert,
    NotifAlert,
    NotifInfo,
    Notification,
    TriggeredAlert
} from '@app/model';
import {
    environment
} from '@environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AuditService {

    data: Behavior = {
        time: 0,
        behavior: ''
    };

    currentBeh: BehaviorSubject < Behavior > = new BehaviorSubject < Behavior > (this.data);
    public behs: Observable < Behavior > = this.currentBeh.asObservable();

    constructor(private http: HttpClient) {}

    getData(uuid: string, start: number, end: number) {
        return this.http.get < Behavior > (`${environment.apiUrl}/audit/` + 'behavior?uuid=' + uuid + '&start=' +
            start + '&end=' + end).pipe(map(data => {
            localStorage.setItem('behavior', JSON.stringify(data));
            this.currentBeh.next(data);
            return data;
        }));
    }

    getMetaWatcher(user: string, ev: string) {
        return this.http.get < any > (`${environment.apiUrl}/audit/` + 'findmetawatcher?user=' + user + '&filter=' + ev)
            .pipe(map(meta => {
                return meta;
            }));
    }

    getMetaAlert(user: string, filter: string, granularity: string) {
        return this.http.get < MetaAlert > (`${environment.apiUrl}/audit/` + 'findmetaalert?user=' + user + '&filter=' +
            filter + '&granularity=' + granularity).pipe(map(meta => {
            return meta;
        }));
    }

    getMetaRunAlert(user: string, filter: string, granularity: string) {
        return this.http.get < MetaRunAlert > (`${environment.apiUrl}/audit/` + 'findmetarunalert?user=' + user +
            '&filter=' + filter + '&granularity=' + granularity).pipe(map(metarun => {
            return metarun;
        }));
    }

    getAlertTriggered(user: string, state: string, granularity: string, ev: string) {
        return this.http.get < TriggeredAlert[] > (`${environment.apiUrl}/audit/` + 'findalerttriggered?user=' + user +
            '&state=' + state + '&granularity=' + granularity + '&filter=' + ev).pipe(map(alerts => {
            return alerts;
        }));
    }

    getRunElement(user: string, ev: string, granularity: string) {
        return this.http.get < string[] > (`${environment.apiUrl}/audit/` + 'findrunelement?user=' + user + '&filter=' +
            ev + '&granularity=' + granularity).pipe(map(uuids => {
            return uuids;
        }));
    }

    getAlertList(user: string) {
        return this.http.get < Alert[] > (`${environment.apiUrl}/audit/` + 'findalertlist?user=' + user).pipe(map(
            alerts => {
                return alerts;
            }));
    }

    getAlertCatalog() {
        return this.http.get < AlertCatalog[] > (`${environment.apiUrl}/audit/findalertcatalog`).pipe(map(
            alerts => {
                return alerts;
            }));
    }

    addAlert(alert: Alert) {
        return this.http.post < any > (`${environment.apiUrl}/audit/addAlert`, alert).pipe(map(result => {
            console.log(`${environment.apiUrl}` + '::' + alert.timeago);
            return result;
        }));
    }

    createTrigger() {
        return this.http.post < any > (`${environment.apiUrl}/audit/createtrigger`, []).pipe(map(result => {
            return result;
        }));
    }

    discoverSnmp(user: string) {
        return this.http.post < any > (`${environment.apiUrl}/audit/discoversnmp`, [user]).pipe(map(result => {
            return result;
        }));
    }

    updateAlert(alert: Alert) {
        return this.http.put < any > (`${environment.apiUrl}/audit/updateAlert`, {
            'timeago': alert.timeago,
            'uuid': alert.uuid,
            'identifier': alert.identifier,
            'type': alert.type,
            'threshold_1': alert.threshold_1,
            'threshold_2': alert.threshold_2,
            'frequency': alert.frequency,
            'definition': alert.definition,
        }).pipe(map(result => {
            return result;
        }));
    }

    getAlertHistory(user: string) {
        return this.http.get < AlertHistory[] > (`${environment.apiUrl}/audit/` + 'findalerthistory?user=' + user).pipe(
            map(alert_h => {
                return alert_h;
            }));
    }

    getAlertTimeHistory(user: string, timeago: number) {
        return this.http.get < AlertTimeHistory[] > (`${environment.apiUrl}/audit/` + 'findalerttimehistory?user=' +
            user + '&time=' + timeago).pipe(map(alert_th => {
            return alert_th;
        }));
    }

    delAlert(uuid: string) {
        return this.http.put < any > (`${environment.apiUrl}/audit/delalert`, {
            uuid: uuid
        });
    }

    updateAlertState(uuid: string, state: string) {
        let alert_o: any = {
            uuid: uuid,
            state: state
        };
        return this.http.put < any > (`${environment.apiUrl}/audit/updatealertstate`, alert_o).pipe(map(
            result => {
                return result;
            }));
    }

    // NOTIFICATION
    getNotificationList(user: string) {
        return this.http.get < Notification[] > (`${environment.apiUrl}/audit/` + 'findnotificationlist?user=' + user)
            .pipe(map(notifs => {
                return notifs;
            }));
    }

    addNotification(notif: Notification) {
        return this.http.post < any > (`${environment.apiUrl}/audit/` + 'addNotification', notif).pipe(map(result => {
            return result;
        }));
    }

    getNotifInfo(uuid: string) {
        return this.http.get < NotifInfo > (`${environment.apiUrl}/audit/` + 'findnotifinfo?uuid=' + uuid).pipe(map(
            notifinfo => {
                return notifinfo;
            }));
    }

    getNotifEmail(uuid: string) {
        return this.http.get < string[] > (`${environment.apiUrl}/audit/` + 'findnotifemail?uuid=' + uuid).pipe(map(
            notifemail => {
                return notifemail;
            }));
    }

    getNotifAlert(uuid: string) {
        return this.http.get < NotifAlert[] > (`${environment.apiUrl}/audit/` + 'findnotifalert?uuid=' + uuid).pipe(map(
            notifalert => {
                return notifalert;
            }));
    }

    updateNotification(notification: Notification) {
        return this.http.put < any > (`${environment.apiUrl}/audit/updatenotification`, notification).pipe(map(
            result => {
                return result;
            }));
    }

    delNotification(uuid: string) {
        return this.http.put < any > (`${environment.apiUrl}/audit/delnotification`, {
            uuid: uuid
        });
    }

    updateNotifState(uuid: string, state: string) {
        let notif_o: any = {
            uuid: uuid,
            state: state
        };
        return this.http.put < any > (`${environment.apiUrl}/audit/updatenotifstate`, notif_o).pipe(map(
            result => {
                return result;
            }));
    }

    getElementBehaviors(user: string, filter: string, start: number, end: number) {
        return this.http.get < ElementBehaviors[] > (`${environment.apiUrl}/audit/` + 'findelementbehavior?user=' +
            user + '&filter=' + filter + '&start=' + start + '&end=' + end).pipe(map(behaviors => {
            return behaviors;
        }));
    }

    getValidTable(table: string) {
        return this.http.get < any > (`${environment.apiUrl}/audit/` + 'validtable?table=' + table).pipe(map(tbl => {
            return tbl;
        }));
    }
}
