<!-- GREENIT EE -->
<clr-modal [(clrModalOpen)]="efficiency_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Servers efficiency list</h3>
    <div class="modal-body">
        <div class="alert alert-info" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                    </div>
                    <span class="alert-text">CEE in red are assigned to servers that have no virtual machines.</span>
                </div>
            </div>
        </div>
        <clr-datagrid *ngIf="efficiency_modal">
            <clr-dg-action-bar style="margin-top:40px">
                <div class="btn-group" style="float:right">
                    <button type="button" class="btn btn-sm btn-link" (click)="exportEfficiency()">
                        Export to CSV
                    </button>
                </div>
            </clr-dg-action-bar>
            <clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'cee'" [clrDgSortOrder]="dataOrder">
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left">
                        CEE
                        <span class="tooltip-content dt-tooltip">Current Energy Efficiency = Power / Number of virtual machines</span>
                    </div>
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'tee'">
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left">
                        TEE
                        <span class="tooltip-content dt-tooltip">Theorical Energy Efficiency = Power / Theoretical max number of virtual machines"</span>
                    </div>
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'wh'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual kWh</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'wh'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual cost ({{globalCurrency}})</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual CO2 (kg CO2eq)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'power'"><ng-container *clrDgHideableColumn="{hidden: false}">Last 24h Power avg (W)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'renew'"><ng-container *clrDgHideableColumn="{hidden: false}">Renewal server lifespan (years)</ng-container></clr-dg-column>

            <clr-dg-row *clrDgItems="let host of efficiency_data" [clrDgItem]="host">
                <clr-dg-cell>&nbsp;{{host.name}}</clr-dg-cell>
                <clr-dg-cell><font [attr.color]="(host.vmnb == 0) ? 'red' : ''">{{host.cee}}</font></clr-dg-cell>
                <clr-dg-cell>{{number(host.tee).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.wh/1000).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.cost).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.co2).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.power).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell style="text-align:center;" [style.background-color]="host.calculator_bestindex > 4 ? (host.calculator_bestindex > 7 ? '#fff2f0' :'#fff4c7') :'#dff0d0'">
                    {{host.calculator_bestindex + 1}} year(s)
                    <button type="button" style="font-size:0.50rem;" class="btn btn-sm btn-link" (click)="displayModalCalculator(host)">
                        <cds-icon shape="search"></cds-icon>&nbsp;details
                    </button>
                </clr-dg-cell>
            </clr-dg-row>              
            <clr-dg-footer>
                <clr-dg-pagination #cee_page [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{cee_page.firstItem + 1}} - {{cee_page.lastItem + 1}} of {{cee_page.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="efficiency_modal = false">Close</button>
    </div>
</clr-modal>

<!-- GREENIT EFFICIENCY CALCULATOR -->
<clr-modal [(clrModalOpen)]="simulation_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Carbon Efficiency Calculator</h3>
    <div class="modal-body" *ngIf="simulation_data">
        <div class="clr-row">
            <div class="clr-col-lg-6 clr-col-md-12 clr-col-12">
                <div class="card">
                    <div class="card-header card-ajust">
                        <h5>
                            <cds-icon shape="tree"></cds-icon>&nbsp;{{simulation_data.name}}
                        </h5>
                    </div>

                    <div class="stack-view" style="height:auto;margin:10px;">
                        <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
                            <clr-stack-label>Embodied carbon details</clr-stack-label>
                            <clr-stack-content></clr-stack-content>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Total embodied carbon (kgCO2eq)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                     {{simulation_data.embodied}}
                                </clr-stack-content>
                            </clr-stack-block>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Lifespan (years)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{simulation_data.amortization}} (until {{simulation_data.endyear}}, purchased in {{simulation_data.year}})
                                    <ng-template [ngTemplateOutlet]="alertlifespan"></ng-template>
                                </clr-stack-content>
                            </clr-stack-block>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Remaining embodied carbon (kgCO2eq)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{simulation_data.remaining_embodied}} ({{simulation_data.remaining_year}} years)
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </div>

                    <div class="stack-view" style="height:auto;margin:10px;">
                        <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
                            <clr-stack-label>Direct carbon details</clr-stack-label>
                            <clr-stack-content></clr-stack-content>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Annual kWh</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{number(simulation_data.wh/1000).toFixed(2)}}
                                </clr-stack-content>
                            </clr-stack-block>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Annual CO2 (kgCO2eq)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{number(simulation_data.co2).toFixed(2)}}
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </div>

    <!--                  <div class="stack-view" style="height:auto;margin:10px;">
                        <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
                            <clr-stack-label>Total carbon details</clr-stack-label>
                            <clr-stack-content></clr-stack-content>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Annual CO2 (kgCO2eq)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    676
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </div> -->

                    <div class="stack-view" style="height:auto;margin:10px;">
                        <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
                            <clr-stack-label>Virtual machines details</clr-stack-label>
                            <clr-stack-content></clr-stack-content>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Max VM number that can be hosted</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{simulation_data.thvm}}
                                </clr-stack-content>
                            </clr-stack-block>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">TEE
                                        <clr-signpost class="grey-signpost">
                                            <clr-signpost-content [clrPosition]="'right-bottom'" *clrIfOpen class="info-signpost">
                                                <h5><b>Theorical Energy Efficiency</b></h5>
                                                <p>Theorical Energy Efficiency = Power / Theoretical max number of virtual machines"</p>
                                            </clr-signpost-content>
                                        </clr-signpost>
                                    </label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{simulation_data.tee}}
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </div>
                </div>
            </div>
            <div class="clr-col-lg-6 clr-col-md-12 clr-col-12">
                <div class="card">
                    <div class="card-header card-ajust">
                        <h5>
                            <cds-icon shape="tree"></cds-icon>&nbsp;Renewal server
                        </h5>
                    </div>

                     <div class="stack-view" style="height:auto;margin:10px;">
                        <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
                            <clr-stack-label>Embodied carbon details</clr-stack-label>
                            <clr-stack-content></clr-stack-content>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Total embodied carbon (kgCO2eq)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{renewal_server_data.new_embodied}}
                                </clr-stack-content>
                            </clr-stack-block>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Lifespan (years)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    Must be more than <b>{{simulation_data.calculator_bestindex + 1}}</b> years (see graphs below for details)
                                    <!-- FIX ROW SIZE -->
                                    <ng-template [ngTemplateOutlet]="emptylifespan"></ng-template>
                                </clr-stack-content>
                            </clr-stack-block>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Remaining embodied carbon (kgCO2eq)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{renewal_server_data.new_embodied}}
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </div>

                    <div class="stack-view" style="height:auto;margin:10px;">
                        <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
                            <clr-stack-label>Direct carbon details</clr-stack-label>
                            <clr-stack-content></clr-stack-content>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Annual kWh</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{renewal_server_data.new_kwh}}
                                </clr-stack-content>
                            </clr-stack-block>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Annual CO2 (kgCO2eq)</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{renewal_server_data.new_co2}}
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </div>

<!--                  <div class="stack-view" style="height:auto;margin:10px;">
                    <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
                        <clr-stack-label>Total carbon details</clr-stack-label>
                        <clr-stack-content></clr-stack-content>
                        <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                            <clr-stack-label>
                                <label class="clr-control-label">Annual CO2 (kgCO2eq)</label>
                            </clr-stack-label>
                            <clr-stack-content>
                                676
                            </clr-stack-content>
                        </clr-stack-block>
                    </clr-stack-block>
                </div> -->

                    <div class="stack-view" style="height:auto;margin:10px;">
                        <clr-stack-block [clrSbExpanded]="true" [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
                            <clr-stack-label>Virtual machines details</clr-stack-label>
                            <clr-stack-content></clr-stack-content>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                <clr-stack-label>
                                    <label class="clr-control-label">Max VM number that can be hosted</label>
                                </clr-stack-label>
                                <clr-stack-content>
                                    {{renewal_server_data.new_thvm}}
                                </clr-stack-content>
                            </clr-stack-block>
                            <clr-stack-block [clrStackViewLevel]="2" [clrStackViewSetsize]="3" [clrStackViewPosinset]="1">
                                 <clr-stack-label>
                                    <label class="clr-control-label">TEE
                                        <clr-signpost class="grey-signpost">
                                            <clr-signpost-content [clrPosition]="'right-bottom'" *clrIfOpen class="info-signpost">
                                                <h5><b>Theorical Energy Efficiency</b></h5>
                                                <p>Theorical Energy Efficiency = Power / Theoretical max number of virtual machines"</p>
                                            </clr-signpost-content>
                                        </clr-signpost>
                                    </label>
                                </clr-stack-label>
                                <clr-stack-content>
                                   {{renewal_server_data.new_tee}}
                                </clr-stack-content>
                            </clr-stack-block>
                        </clr-stack-block>
                    </div>
                </div>
            </div>
        </div>

        <div class="clr-row" >
            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                <div class="card">
                    <div class="card-header card-ajust" style="margin-bottom:20px;">
                        <div style="width:100%;">
                            <div style="float:right">
                                <label class="clr-control-label" style="margin-right:10px;display:inline;font-weight:400;line-height:1.2rem;vertical-align:top;">Show all years</label>
                                <clr-toggle-wrapper style="margin-right:0;float:right;">
                                    <input type="checkbox" clrToggle [value]="show_best" [(ngModel)]="show_best" (click)="switchGraph()"/>
                                    <label>details based on {{simulation_data.calculator_bestindex + 1}} years lifespan</label>
                                </clr-toggle-wrapper>
                            </div>
                            <div style="width:40%;">
                                <h5 *ngIf="show_best"><cds-icon shape="tree"></cds-icon>&nbsp;Carbon details based on {{simulation_data.calculator_bestindex + 1}} years lifespan</h5>
                                <h5 *ngIf="!show_best"><cds-icon shape="tree"></cds-icon>&nbsp;Carbon details based on lifespan (years)</h5>
                            </div>
                        </div>
                    </div>

                    <div class="clr-row" *ngIf="show_best">
                        <div class="clr-col-lg-mini"></div>
                        <div class="clr-col-lg-5 clr-col-md-12 clr-col-12">
                             <div class="card" style="border:none">
                                <ng-template [ngTemplateOutlet]="notiflifespan"></ng-template>
                                <clr-datagrid style="overflow:hidden;">
                                    <clr-dg-action-bar style="margin-top:0;">
                                        <div class="btn-group" style="float:right">
                                            <!-- <button type="button" class="btn btn-sm btn-link" (click)="exportGreenit()">
                                                Export to CSV
                                            </button> -->
                                        </div>
                                    </clr-dg-action-bar>
                                    <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}"></ng-container></clr-dg-column>
                                    <clr-dg-column [clrDgField]="'global'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual global CO2 per server (kgCO2eq)</ng-container></clr-dg-column>
                                    <clr-dg-column [clrDgField]="'pervm'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual global CO2 per VM (kgCO2eq)</ng-container></clr-dg-column>
                                    <clr-dg-row>
                                        <clr-dg-cell>{{simulation_data.name}}</clr-dg-cell>
                                        <clr-dg-cell>{{simulation_data.calculator_bestco2}}</clr-dg-cell>
                                        <clr-dg-cell>{{simulation_data.calculator_bestpervmco2}}</clr-dg-cell>
                                    </clr-dg-row>
                                    <clr-dg-row>
                                        <clr-dg-cell>Renewal server</clr-dg-cell>
                                         <clr-dg-cell>{{simulation_data.calculator_bestnewco2}}</clr-dg-cell>
                                        <clr-dg-cell>{{simulation_data.calculator_bestnewpervmco2}}</clr-dg-cell>
                                    </clr-dg-row>
                                     <clr-dg-footer>
                                        <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                        </clr-dg-pagination>
                                    </clr-dg-footer>
                                </clr-datagrid>
                            </div>
                        </div>
                        <div class="clr-col-lg-1 clr-col-md-12 clr-col-12"></div>
                        <div class="clr-col-lg-5 clr-col-md-12 clr-col-12">
                            <div class="card">
                                <highcharts-chart 
                                    [Highcharts]="Highcharts"
                                    [options]="options3"
                                    [callbackFunction]="chartCallback3"
                                    style="width:100%;display:block;margin-top:20px" >
                                </highcharts-chart>
                            </div>
                        </div>                            
                        <div class="clr-col-lg-mini"></div>
                    </div>

                     <div class="clr-row" *ngIf="!show_best">
                        <div class="clr-col-lg-2 clr-col-md-12 clr-col-12"></div>
                        <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
                            <div class="card" style="min-height:400px;margin-bottom:1.2rem;">
                                <div class="card-header card-ajust" style="margin-bottom:20px;">
                                    <h5>
                                        <cds-icon shape="tree"></cds-icon>&nbsp;Annual global CO2 per VM based on lifespan (years)
                                    </h5>
                                </div>
                                <ng-template [ngTemplateOutlet]="notiflifespan"></ng-template>
                                <highcharts-chart
                                        [Highcharts]="Highcharts"
                                        [options]="options2"
                                        [callbackFunction]="chartCallback2"
                                        style="width:100%;display:block;" >
                                </highcharts-chart>
                            </div>
                        </div>
                    </div>
                    <div class="clr-row" *ngIf="!show_best">
                        <div class="clr-col-lg-2 clr-col-md-12 clr-col-12"></div>
                        <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
                            <div class="card" style="min-height:400px;margin-bottom:1.2rem;">
                                <div class="card-header card-ajust" style="margin-bottom:20px;">
                                    <h5>
                                        <cds-icon shape="tree"></cds-icon>&nbsp;Annual global CO2 per server based on lifespan (years)
                                    </h5>
                                </div>
                                <ng-template [ngTemplateOutlet]="alertlifespan"></ng-template>
                                <highcharts-chart 
                                        [Highcharts]="Highcharts"
                                        [options]="options"
                                        [callbackFunction]="chartCallback"
                                        style="width:100%;display:block;" >
                                    </highcharts-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</clr-modal>


<!-- GREENIT MODAL OPTIMIZATION -->
<clr-modal [(clrModalOpen)]="optimization_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Energy Optimization</h3>
    <div class="modal-body">
        <div class="clr-row" style="width:100%;">
            <div style="float:left;text-align:center;width:50%;">
                <p style="font-size: 18px;margin:0"><b>Current annual kWh</b></p>
                <p style="font-size: 18px;padding-bottom:10px" id="greenit-prediction-current"></p>
                <div>Now you have : {{optimization_current_servers}} server(s)</div>
            </div>
            <div style="float:right;text-align:center;width:50%;">
                <p style="font-size: 18px;margin:0"><b>New annual kWh</b></p>
                <p style="font-size: 18px;padding-bottom:10px" id="greenit-prediction-new"></p>
                <div>After optimization you will have : {{optimization_new_servers}} server(s)</div>
            </div>
        </div>
        <hr>

            <clr-datagrid *ngIf="optimization_modal">
                <clr-dg-action-bar style="margin-top:0px">
                    <div class="btn-group" style="float:right">
                        <button type="button" class="btn btn-sm btn-link" (click)="exportOptimization()">
                            Export to CSV
                        </button>
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column [clrDgField]="'NAME'"><ng-container *clrDgHideableColumn="{hidden: false}">New server or server to keep</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'OLDKWH'"><ng-container *clrDgHideableColumn="{hidden: false}">Current annual kWh</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'KWH'"><ng-container *clrDgHideableColumn="{hidden: false}">New annual kWh (estimation)</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'OLDSERVERS'"><ng-container *clrDgHideableColumn="{hidden: false}">Servers that will be replaced</ng-container></clr-dg-column>
            
                <clr-dg-row *clrDgItems="let entry of optimization_json.SERVERS" [clrDgItem]="entry">
                    <clr-dg-cell><span *ngIf="entry.NEWFLAG" style="width:36px" class="badge badge-danger">NEW</span>{{entry.NAME}}</clr-dg-cell>
                    <clr-dg-cell>{{number(entry.OLDKWH).toFixed(0)}}</clr-dg-cell>
                    <clr-dg-cell>{{number(entry.KWH).toFixed(0)}}</clr-dg-cell>
                    <clr-dg-cell>{{entry.OLDSERVERS.split(',').join(', ')}}</clr-dg-cell>
                </clr-dg-row>              
                <clr-dg-footer>
                    <clr-dg-pagination #opti_page [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{opti_page.firstItem + 1}} - {{opti_page.lastItem + 1}} of {{opti_page.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
       

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="optimization_modal = false">Close</button>
    </div>
</clr-modal>

<ng-template #notiflifespan>
<div class="alert alert-success" role="alert" style="padding-left:5px;color:black;margin:10px">
    <div class="alert-items">
        <div class="alert-item static">
            <cds-icon shape="check-circle" size="md" style="margin-right:10px"></cds-icon>
                <span class="alert-text" style="margin:auto;font-size:12px;">
                    For the carbon impact of server renewal to be optimal, the server must have a lifespan of <b>{{simulation_data.calculator_bestindex + 1}} years or more</b>. 
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #alertlifespan>
    <div *ngIf="simulation_data.remaining_year > 0" #divalertlifespan class="alert alert-warning" role="alert" style="padding-left:5px;color:black;margin:10px">
        <div class="alert-items">
            <div class="alert-item static">
                <cds-icon shape="exclamation-triangle" size="md" style="margin-right:10px"></cds-icon>
                <span class="alert-text" style="margin:auto;font-size:12px;">
                    <b>{{simulation_data.name}}</b> still has a <b>{{simulation_data.remaining_year}}-years</b> lifespan. 
                </span>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #emptylifespan>
    <div *ngIf="simulation_data.remaining_year > 0" #divemptylifespan style="padding-left:5px;color:black;margin:10px">
    </div>
</ng-template>