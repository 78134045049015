export let KUBERNETES_ARCH: Array < any > = [{
    value: "native",
    display: "native"
}, {
    value: "tanzu",
    display: "tanzu"
}, {
    value: "openshift",
    display: "openshift"
}, ]

export let AWS_REGION: Array < any > = [{
    value: "af-south-1",
    display: "Africa (Cape Town)"
}, {
    value: "ap-east-1",
    display: "Asia Pacific (Hong Kong)"
}, {
    value: "ap-southeast-3",
    display: "Asie-Pacifique (Jakarta)"
}, {
    value: "ap-south-1",
    display: "Asia Pacific (Mumbai)"
}, {
    value: "ap-northeast-3",
    display: "Asia Pacific (Osaka)"
}, {
    value: "ap-northeast-2",
    display: "Asia Pacific (Seoul)"
}, {
    value: "ap-southeast-1",
    display: "Asia Pacific (Singapore)"
}, {
    value: "ap-southeast-2",
    display: "Asia Pacific (Sydney)"
}, {
    value: "ap-northeast-1",
    display: "Asia Pacific (Tokyo)"
}, {
    value: "ca-central-1",
    display: "Canada (Central)"
}, {
    value: "eu-central-1",
    display: "Europe (Frankfurt)"
}, {
    value: "eu-west-1",
    display: "Europe (Ireland)"
}, {
    value: "eu-west-2",
    display: "Europe (London)"
}, {
    value: "eu-south-1",
    display: "Europe (Milan)"
}, {
    value: "eu-west-3",
    display: "Europe (Paris)"
}, {
    value: "eu-north-1",
    display: "Europe (Stockholm)"
}, {
    value: "me-south-1",
    display: "Middle East (Bahrain)"
}, {
    value: "sa-east-1",
    display: "South America (São Paulo)"
}, {
    value: "us-east-1",
    display: "US East (N. Virginia)"
}, {
    value: "us-west-1",
    display: "US West (N. California)"
}, {
    value: "us-west-2",
    display: "US West (Oregon)"
}, ]

export let AZURE_REGION: Array < any > = [{
    value: "asia-pacific-east",
    display: "East Asia"
}, {
    value: "asia-pacific-southeast",
    display: "Southeast Asia"
}, {
    value: "australia-central",
    display: "Australia Central"
}, {
    value: "australia-central-2",
    display: "Australia Central 2"
}, {
    value: "australia-east",
    display: "Australia East"
}, {
    value: "australia-southeast",
    display: "Australia Southeast"
}, {
    value: "brazil-south",
    display: "Brazil South"
}, {
    value: "brazil-southeast",
    display: "Brazil Southeast"
}, {
    value: "canada-central",
    display: "Canada Central"
}, {
    value: "canada-east",
    display: "Canada East"
}, {
    value: "central-india",
    display: "Central India"
}, {
    value: "south-india",
    display: "South India"
}, {
    value: "west-india",
    display: "West India"
}, {
    value: "europe-north",
    display: "North Europe"
}, {
    value: "europe-west",
    display: "West Europe"
}, {
    value: "france-central",
    display: "France Central"
}, {
    value: "france-south",
    display: "France South"
}, {
    value: "germany-north",
    display: "Germany North"
}, {
    value: "germany-west-central",
    display: "Germany West Central"
}, {
    value: "japan-east",
    display: "Japan East"
}, {
    value: "japan-west",
    display: "Japan West"
}, {
    value: "korea-central",
    display: "Korea Central"
}, {
    value: "korea-south",
    display: "Korea South"
}, {
    value: "norway-east",
    display: "Norway East"
}, {
    value: "norway-west",
    display: "Norway West"
}, {
    value: "south-africa-north",
    display: "South Africa North"
}, {
    value: "south-africa-west",
    display: "South Africa West"
}, {
    value: "sweden-central",
    display: "Sweden Central"
}, {
    value: "sweden-south",
    display: "Sweden South"
}, {
    value: "switzerland-north",
    display: "Switzerland North"
}, {
    value: "switzerland-west",
    display: "Switzerland West"
}, {
    value: "uae-central",
    display: "UAE Central"
}, {
    value: "uae-north",
    display: "UAE North"
}, {
    value: "united-kingdom-south",
    display: "UK South"
}, {
    value: "united-kingdom-west",
    display: "UK West"
}, {
    value: "us-central",
    display: "Central US"
}, {
    value: "us-east",
    display: "East US"
}, {
    value: "us-east-2",
    display: "East US 2"
}, {
    value: "us-north-central",
    display: "North Central US"
}, {
    value: "us-south-central",
    display: "South Central US"
}, {
    value: "us-west-central",
    display: "West Central US"
}, {
    value: "us-west",
    display: "West US"
}, {
    value: "us-west-2",
    display: "West US 2"
}, {
    value: "us-west-3",
    display: "West US 3"
}, {
    value: "usgov-arizona",
    display: "US Gov Arizona"
}, {
    value: "usgov-texas",
    display: "US Gov Texas"
}, {
    value: "usgov-virginia",
    display: "US Gov Virginia"
}]

export enum Mode {
    INSTALL = "install",
        MIGRATE = "migrate"
}

export enum InstanceType {
    ALL = "all",
        VMWARE = "vmware",
        VCLOUD = "vcloud",
        AWS = "aws",
        AZURE = "azure",
        GCP = "gcp",
        SCAPHANDRE = "scaphandre",
        KUBERNETES = "kubernetes",
        XCLARITY = "xclarity",
        OPENMANAGE = "openmanage",
        ONEVIEW = "oneview",
        IPMI = "ipmi",
        EXAGRID = "exagrid",
        IBM = "ibm",
        STORAGE = "storage",
        NETWORK = "network"
}

export enum InstanceFullName {
    VMWARE = "VMware vCenter / ESXi",
        VCLOUD = "VMware vCloud Director",
        AWS = "Amazon Web Services",
        AZURE = "Microsoft Azure",
        GCP = "Google Cloud Platform",
        SCAPHANDRE = "Scaphandre Promotheus Database",
        KUBERNETES = "Kubernetes",
        XCLARITY = "Lenovo XClarity",
        OPENMANAGE = "Dell OpenManage",
        ONEVIEW = "HPE OneView",
        IPMI = "Intelligent Platform Management Interface",
        EXAGRID = "Exagrid",
        IBM = "IBM",
}

export interface Status {
    status: number,
    name: string,
    date: number,
    msg: string,
    type: InstanceType,
    zombies: any
}

export enum StatusTarget {
    INSTANCE,
    DB,
}

export enum StatusColor {
    GREEN = 0,
        ORANGE = 1,
        RED = 2
}

export interface DriverInfo {
    name: string,
    ip: string,
    type: InstanceType,
    login: string,
    status: number
}

export interface MonitorSettings {
    option: string,
    value: string,
}

export enum Lang {
    FR = "fr",
        EN = "en",
        ES = "es"
}

export enum Lang_FR {
    NEXT = "Suivant",
        PREVIOUS = "Précédent",
        FINISH = "Terminer",
        CANCEL = "Annuler",

        WELCOME_LANG_TITLE = "Bienvenue",
        WELCOME_LANG_TEXT = "Bienvenue sur la plateforme de configuration.",
        WELCOME_LANG_TEXT2 = "Sélectionnez la langue d'installation",

        WELCOME_LICENSE_TITLE = "Conditions Générales d'Utilisation",
        WELCOME_LICENSE_OTHER = "", //Only for other than french

        WELCOME_LICENSE_LICENSE =
        `<h4>Préambule : </h4> 
      Les présentes conditions générales d'utilisation s’appliquent à l'utilisation de la licence du logiciel DC-Scope en libre téléchargement sur www.easyvirt.com.
  EasyVirt SAS, société française dont le siège est sis à Nantes (France), 2 rue Alfred Kastler.<br><br>
  LE FAIT DE TELECHARGER, INSTALLER, COPIER, UTILISER LE LOGICIEL EMPORTE VOTRE ACCEPTATION PLEINE ET ENTIERE DES TERMES DU PRÉSENT CONTRAT DE LICENCE (« CONTRAT ») DE DC SCOPE. SI VOUS N'ACCEPTEZ PAS LES TERMES DU PRÉSENT CONTRAT, VOUS NE POUVEZ PAS TÉLÉCHARGER, INSTALLER, COPIER, OU UTILISER LE LOGICIEL. « VOUS » DÉSIGNE LA PERSONNE PHYSIQUE OU MORALE QUI ACCEPTE D'ÊTRE LIÉE PAR CE CONTRAT, SES EMPLOYÉS ET LES SOUS-TRAITANTS TIERS QUI VOUS FOURNISSENT DES SERVICES. VOUS SEREZ RESPONSABLE DE TOUT MANQUEMENT AUX STIPULATIONS DU PRÉSENT CONTRAT PAR LESDITS EMPLOYÉS ET SOUS-TRAITANTS TIERS.<br>

  <h4>1. CONCESSION ET DROITS D'UTILISATION DU LOGICIEL</h4>
  1.1 Licence<br>
  Le présent Contrat Vous concède un droit limité, non exclusif et non transférable d'utiliser une copie du Logiciel sous forme de code objet. 
  « Logiciel » désigne les produits logiciels qui vous sont concédés sous licence en vertu du présent Contrat, y compris, mais sans s'y limiter, tous les composants liés fournis avec le Logiciel ( interfaces de programmation d'application,  supports associés, la documentation imprimé,  en ligne ou électronique, et toutes leurs mises à jour et correctifs. Les composants logiciels sous licence libre (« Open Source ») fournis avec le Logiciel vous sont concédés selon les stipulations des contrats de licence applicables inclus avec ces composants logiciels sous licence libre. Les licences pour les logiciels sous licence libre sont disponibles sur demande à contact@easyvirt.com.<br>
  1.2 Limitations de licence<br>
  Vous n'êtes pas autorisé à copier le Logiciel ou supprimer les titres, les marques commerciales ou les noms commerciaux, les notices de copyright, les légendes ou les autres mentions de propriétés présentes sur le Logiciel. Il ne vous est accordé aucun droit sur les marques commerciales ou marques de service d’EasyVirt.<br>
  1.3 Restrictions<br>
  Vous n’avez pas l’autorisation de  (i) céder, louer, donner en licence ou en sous sous-licence, distribuer ou transférer de toute autre manière, en tout ou partie, le Logiciel à un tiers ; (ii) fournir, communiquer, divulguer ou rendre disponible, ou permettre l'utilisation du Logiciel, en tout ou partie, par un tiers, sans le consentement écrit préalable d’EasyVirt ; (iii) modifier ou créer des œuvres dérivées fondées sur le Logiciel ; ou (iv) créer, développer, donner en licence, installer, utiliser ou déployer un logiciel ou des services tiers dans le but de contourner, activer, modifier ou fournir un accès, une autorisation ou un droit en infraction avec les restrictions techniques du Logiciel, avec les conditions supplémentaires de licence stipulées par EasyVirt dans sa documentation produit, et/ou dans une notification et/ou avec les stipulations du présent Contrat. Sauf autorisation contraire d’EasyVirt, vous ne pouvez pas décompiler ou désassembler le Logiciel, en effectuer l'ingénierie inverse ou tenter de toute autre manière d'en obtenir le code source, en tout ou partie. Avant de d'exercer des droits que vous estimez posséder sur le fondement de dispositions légales, vous devez envoyer à EasyVirt un préavis écrit de 30 jours à l'adresse contact@easyvirt.com et communiquer à EasyVirt toutes informations permettant d'évaluer votre demande. Vous êtes autorisé à utiliser le Logiciel pour effectuer des tests de performance et des études d'évaluation en interne, dont vous (et non un tiers non autorisé) pouvez publier ou diffuser les résultats au public, à condition qu’EasyVirt ait revu et approuvé la méthodologie, les hypothèses et autres paramètres de l'étude. Pour en faire la demande, vous êtes priés de contacter EasyVirt à l'adresse contact@easyvirt.com.<br>

  <h4>2. TITRE</h4>
  EasyVirt conserve tout droit, titre de propriété et intérêt relatif au Logiciel et à tous droits d'auteur, secrets commerciaux, brevets, marques commerciales, ainsi qu'à tout autre droit de propriété intellectuelle et industrielle et droits exclusifs.<br>

  <h4>3. SERVICES DE SUPPORT</h4>
  Le support de DC Scope n'est assuré que dans le cadre d'un contrat de maintenance ou d’une souscription. EasyVirt et/ou le distributeur.<br>
  Dans le cadre d'une version d’évaluation de DC Scope, EasyVirt ne fournit pas de services de support.<br>

  <h4>4. DURÉE ET RÉSILIATION</h4>
  4.1 Durée et résiliation<br>
  Sauf contrat de souscription ou d'achat, le contrat prendra fin après expiration de la période d'essai applicable suite à votre téléchargement du Logiciel. En cas de constatation du non-respect des stipulations du présent Contrat, EasyVirt peut résilier celui-ci immédiatement et sans préavis.<br>
  4.2 Effet de la résiliation<br>
  Après résiliation ou expiration du présent Contrat, quelle qu'en soit le motif, tout droit, licence ou permission vous étant accordé relativement au Logiciel prendra immédiatement fin et vous devrez : (i) cesser immédiatement d'utiliser le Logiciel, en tout ou partie ; et (ii) détruire, supprimer ou désinstaller définitivement toute copie du Logiciel installé ou copié par vous. <br>

  <h4>5. GARANTIES ET RESPONSABILITES</h4>
  5.1 Exclusion de garanties<br>
  DANS TOUTE LA MESURE PERMISE PAR LES DISPOSITIONS IMPERATIVES DE LA LOI APPLICABLE, EASYVIRT ET SES CONCÉDANTS FOURNISSENT LE LOGICIEL SANS GARANTIE D'AUCUNE SORTE, QU'ELLE SOIT EXPRESSE, IMPLICITE, LÉGALE, OU DANS TOUTE AUTRE STIPULATION DU PRESENT CONTRAT OU TOUTE CORRESPONDANCE AVEC VOUS, ET EASYVIRT ET SES CONCÉDANTS DÉCLINENT SPÉCIFIQUEMENT TOUTE GARANTIE IMPLICITE DE QUALITÉ MARCHANDE, D'APTITUDE À UN QUELCONQUE USAGE SPÉCIFIQUE ET D'ABSENCE DE CONTREFAÇON.<br>
  5.2 Limitation de responsabilité<br>
  LIMITATION DE RESPONSABILITÉ. DANS LA MESURE OU LA LEGISLATION LE PERMET, EASYVIRT ET SES CONCÉDANTS NE PEUVENT ETRE TENUES  RESPONSABLES EN CAS D'OPPORTUNITÉS ÉCONOMIQUES MANQUÉES OU DE PERTE DE PROFITS, DE PERTE D'UTILISATION, D'INTERRUPTION D'ACTIVITÉ, DE PERTE DE DONNÉES OU DE TOUT AUTRE DOMMAGE INDIRECT, SPÉCIFIQUE, ACCESSOIRE OU SUBSÉQUENT, QUEL QUE SOIT LE FONDEMENT DE RESPONSABILITÉ, QU'IL SOIT FONDÉ SUR LA RESPONSABILITÉ CONTRACTUELLE, LA RESPONSABILITÉ DÉLICTUELLE, LA NÉGLIGENCE OU LA RESPONSABILITÉ DU FAIT DES PRODUITS DÉFECTUEUX OU DE TOUT AUTRE FONDEMENT. CERTAINES JURIDICTIONS N'AUTORISANT PAS L'EXCLUSION OU LA LIMITATION DE RESPONSABILITÉ POUR LES DOMMAGES ACCESSOIRES OU SUBSÉQUENT, LES LIMITATIONS STIPULÉES CI-DESSUS PEUVENT DONC NE PAS VOUS CONCERNER. LA RESPONSABILITÉ D4 EASYVIRT ET DE SES CONCÉDANTS EST EN TOUT ÉTAT DE CAUSE LIMITÉE AUX REDEVANCES DE LICENCE CONTRACTUELLES QUE VOUS AVEZ PAYÉES, LE CAS ÉCHÉANT POUR LE LOGICIEL QUI VOUS EST CONCÉDÉ AUX TERMES DU PRÉSENT CONTRAT. LES LIMITATIONS SUSMENTIONNÉES S'APPLIQUENT DANS TOUTE LA LIMITE PERMISE PAR LES DISPOSITIONS IMPÉRATIVES DE LA LOI APPLICABLE, ET CE QU’EASYVIRT OU SES CONCÉDANTS AIENT OU NON ÉTÉ INFORMÉS DE LA POSSIBILITÉ DE CES DOMMAGES. CES LIMITATIONS S'APPLIQUENT MEME SI ELLES SONT SUSCEPTIBLES DE FAIRE ECHEC A TOUT RECOURS QUI VOUS SERAIT PAR AILLEURS OUVERT.<br>

  <h4>6. COLLECTE DE DONNÉES</h4>
  Afin de remplir ses fonctions et valider les données de licences, DC Scope collecte et envoie les informations suivantes à nos serveurs: clé de licence, nombre de machines virtuelles, version DC Scope, Build de DC Scope et dernière date de connexion. DC Scope n'enregistre aucune information d'identification personnelle: aucun nom, aucune adresse e-mail, aucune donnée privée provenant d'autres applications, etc.<br>
  En acceptant les termes du présent accord et / ou l'utilisation du logiciel, vous autorisez la collecte, l'utilisation et la divulgation de ces données aux fins prévues dans le présent Accord et dans la Politique de Confidentialité.<br>

  <h4>7. GÉNÉRALITÉS</h4>
  7.1 Intégralité du Contrat<br>
  Le présent Contrat stipule l'entière responsabilité d’EasyVirt et votre recours exclusif en ce qui concerne le Logiciel et remplace les termes de toute correspondance ou publicité relative au Logiciel. Vous reconnaissez que le présent Contrat constitue l'intégralité de l'accord entre Vous et EasyVirt relatif au Logiciel et qu'il n'existe aucun autre accord, promesse, représentation ou description antérieures ou contemporaines en ce qui concerne le Logiciel.<br>
  7.2 Titres<br>
  Les titres utilisés dans le présent Contrat ont pour seule fin d'en faciliter la lecture et n'affectent pas l'interprétation de celui-ci.<br>
  7.3 Renonciation et modification<br>
  Le fait pour l'une des parties de ne pas exercer ou de ne pas se prévaloir de l'un de ses droits dans le cadre du présent Contrat ne constituera pas une renonciation à ces droits. Le présent Contrat peut seulement être modifié ou un des droits qu'il confère peut seulement être abandonné par un document écrit signé par la partie à laquelle il est opposé.<br>
  7.4 Divisibilité<br>
  Si l'une quelconque des clauses du présent Contrat était déclarée nulle ou illégale, elle continuera à s'appliquer dans les limites autorisées par la loi et les autres stipulations du présent Contrat continueraient à être valables et exécutoires.<br>
  7.5 Droit applicable<br>
  Le présent Contrat est régi par le droit français, quel que soit le lieu d’utilisation.<br>
  En cas de litige et après l'échec de toute tentative de recherche d'une solution amiable, les tribunaux français seront seuls compétents pour reconnaître ce litige.<br>
  Si une ou plusieurs stipulations des présentes Conditions Générales d'Utilisation sont tenues pour non valides ou déclarées telles en application d'une loi, d'un décret, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.<br>
  7.6 Contacts<br>
  Si vous avez des questions relatives au présent Contrat ou si vous souhaitez contacter EasyVirt pour toute autre raison, adressez vos correspondances à : EasyVirt, 2 rue Alfred Kastler, 44300 NANTES ou par e-mail à contact@easyvirt.com<br>
  `,

        WELCOME_LICENSE_TEXT = "DROIT APPLICABLE",
        WELCOME_LICENSE_TEXT2 = "Les présentes Conditions Générales d'Utilisation sont soumises au droit français.",
        WELCOME_LICENSE_TEXT3 = "Accepter",
        WELCOME_LICENSE_TEXT4 = "Refuser",

        WELCOME_MODE_TITLE = "Choix du type d'installation",
        WELCOME_MODE_TEXT = "Nouvelle installation",
        WELCOME_MODE_TEXT2 = "Migration depuis une application v7.4.1",

        INSTALL_TYPE_TITLE = "Type de connecteur",

        WELCOME_CREDENTIALS_TITLE = "Paramètres d'accès VMware vCenter / ESXi",
        INSTALL_CREDENTIALS_TITLE = "Paramètres d'accès",

        INSTALL_CREDENTIALS_TEXT = "Nom",
        INSTALL_CREDENTIALS_TEXT2 = "Nom simple pour identifier cette connexion",
        INSTALL_CREDENTIALS_TEXT3 = "Adresse IP / FQDN",
        INSTALL_CREDENTIALS_TEXT4 = "Nom d'utilisateur",
        INSTALL_CREDENTIALS_TEXT5 = "Indiquez le nom de domaine si besoin",
        INSTALL_CREDENTIALS_TEXT6 = "Mot de passe",

        WELCOME_CREDENTIALS_MIGRATE_TEXT = "Paramètres d'accès à DCScope v7.4.1",
        WELCOME_CREDENTIALS_MIGRATE_TEXT2 = "Si vide, le mot de passe par défaut sera utilisé",
        WELCOME_CREDENTIALS_MIGRATE_TEXT3 = "Historique",
        WELCOME_CREDENTIALS_MIGRATE_TEXT4 = "an",
        WELCOME_CREDENTIALS_MIGRATE_TEXT5 = "toutes les données",
        WELCOME_CREDENTIALS_MIGRATE_TEXT6 = "Historique de données à migrer dans DCScope v8",
        WELCOME_CREDENTIALS_MIGRATE_TEXT7 = "Attention : les données non migrées seront définitivement perdues !",
        WELCOME_CREDENTIALS_MIGRATE_TEXT8 =
        "La migration peut durer plusieurs heures, en fonction de l'historique des données et de la taille de l'infrastructure.",
        WELCOME_CREDENTIALS_MIGRATE_TEXT9 =
        "Pour des infrastructures de plus de 1000 VM et un historique de données de plusieurs années, la migration peut durer plusieurs jours.",
        WELCOME_CREDENTIALS_MIGRATE_TEXT10 =
        "Nous vous recommandons de sélectionner une période de conservation des données ne dépassant pas deux ans pour accélérer le processus.",

        INSTALL_PROGRESS_TITLE = "Status de connexion",
        INSTALL_PROGRESS_TEXT = "Merci de patienter pendant le paramètrage de la connexion",
        INSTALL_PROGRESS_TEXT2 = "a été enregistré avec succès !",
        INSTALL_PROGRESS_TEXT3 = "Afficher le journal d'erreurs",
        INSTALL_PROGRESS_TEXT4 = "Un problème est survenu lors de la connexion.",
        INSTALL_PROGRESS_TEXT5 =
        "Merci de corriger les informations précédemment renseignées ou de contacter EasyVirt à l'adresse ",
        INSTALL_PROGRESS_TEXT6 = "Merci de patienter pendant le paramètrage de la connexion",
        INSTALL_PROGRESS_TEXT7 = "Un problème est survenu lors de la connexion.",

        WELCOME_PROGRESS_MIGRATE_TEXT = "Progression de la migration",

        WELCOME_END_TITLE = "Activation",
        WELCOME_END_TEXT = "Vous venez de terminer la configuration de la solution.",
        WELCOME_END_TEXT2 = "Cependant, l'accès à l'interface web nécessite une clé de licence.",
        WELCOME_END_TEXT3 = "Une clé d'évaluation de 15 jours vous est automatiquement fournie.",
        WELCOME_END_TEXT4 =
        "Si vous souhaitez prolonger votre période d’essai, veuillez soumettre votre clé produit et le nombre de machines virtuelles souhaité à l'adresse ",
        WELCOME_END_TEXT5 =
        "Il faut au minimum 48 heures de données pour que l'ensemble des tableaux et analyses soient disponibles.",
        WELCOME_END_TEXT6 = "Votre clé produit",
        WELCOME_END_TEXT7 = "Impossible de générer la clé produit. Merci de contacter Easyvirt à l'adresse ",

        INSTALL_END_TITLE = "Finalisation",
        INSTALL_END_TEXT = "Vous venez d'ajouter une connexion à la solution.",
        INSTALL_END_TEXT2 = "En cliquant sur 'Terminer', le monitoring sera lancé.",
        INSTALL_END_TEXT3 = "L'accès aux nouvelles données / analyses ne sera effectif que sous 24H.",
};

export enum Lang_EN {
    NEXT = "Next",
        PREVIOUS = "Back",
        FINISH = "Finish",
        CANCEL = "Cancel",

        WELCOME_LANG_TITLE = "Welcome",
        WELCOME_LANG_TEXT = "Welcome to the configuration platform.",
        WELCOME_LANG_TEXT2 = "Select the installation language",

        WELCOME_LICENSE_TITLE = "Terms of Service",
        WELCOME_LICENSE_OTHER =
        "The following translation is for reference purposes. In case of discrepancy in interpretation, the French version shall prevail", //Only for other than french

        WELCOME_LICENSE_LICENSE =
        `<h4>Preamble:</h4>
  These general conditions of use apply to the use of the license of the software DC-Scope available on www.easyvirt.com. EasyVirt SAS, a French company headquartered in Nantes (France), 2 Alfred Kastler.<br><br>
  DOWNLOADING, INSTALLING, COPYING, USING THE SOFTWARE CONSTITUTES YOUR FULL AND COMPLETE ACCEPTANCE OF THE TERMS OF LICENSE AGREEMENT ("AGREEMENT") OF DC SCOPE. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU MAY NOT DOWNLOAD, INSTALL, COPY, OR USE THE SOFTWARE. "YOU" MEANS THE PHYSICAL OR LEGAL PERSON WHO AGREES TO BE BOUND BY THIS AGREEMENT, ITS EMPLOYEES AND THE THIRD PARTY CONTRACTORS THAT PROVIDE YOU WITH SERVICES. YOU SHALL BE RESPONSIBLE FOR ANY BREACH OF THE TERMS OF THIS AGREEMENT BY THOSE EMPLOYEES AND THIRD PARTY SUBCONTRACTORS.<br>

  <h4>1. CONCESSION AND RIGHTS OF USE OF THE SOFTWARE</h4>
  1.1 License<br>
  This Agreement grants You a limited, non-exclusive, non-transferable right to use a copy of the Software in the form of an object code.

  "Software" means the software products licensed to you under this Agreement, including, but not limited to, any linked components provided with the Software (application programming interfaces, associated media, documentation printed, online or electronic, and all their updates and patches.) The Open Source software components provided with the Software are licensed to you under the terms of the applicable license agreements included with those licensed software components. The licenses for the Open Source software are available upon request from contact@easyvirt.com.<br>
  1.2 License limitations<br>
  You are not allowed to copy the Software or remove any of the titles, trademarks, trade names, copyright notices, captions, or other proprietary notices contained on the Software. You are not granted any rights to EasyVirt trademarks or service marks.<br>
  1.3 Restrictions<br>
  You are not allowed to (i) assign, lease, sublicense,  distribute or otherwise transfer, in whole or in part, the Software to a third party; (ii) provide, communicate, disclose or otherwise make available, or permit the use of the Software, in whole or in part, by any third party without the prior written consent of EasyVirt; (iii) modify or create derivative works based on the Software; or (iv) create, develop, license, install, use or deploy any third party software or services for the purpose of circumventing, enabling, modifying or providing any access, authorization or right in violation of the technical restrictions of the Software , with the additional license terms stipulated by EasyVirt in its product documentation, and / or in a notice and / or with the provisions of this Agreement. Unless otherwise authorized by EasyVirt, you may not decompile or disassemble the Software, reverse engineer or otherwise attempt to obtain the source code, in whole or in part. Before exercising any rights you believe to have on the basis of legal provisions, you must send EasyVirt a written notice of 30 days to contact@easyvirt.com and communicate to EasyVirt all information to evaluate your request. You may use the Software to perform performance tests and evaluation studies internally, which you (and not an unauthorized third party) may publish or disseminate to the public, provided that EasyVirt has reviewed and approved the methodology, assumptions and other parameters of the study. To make a request, please contact EasyVirt at contact@easyvirt.com.<br>

  <h4>2. TITLE</h4>
  EasyVirt retains all rights, title and interest in the Software and all copyrights, trade secrets, patents, trademarks, and other intellectual and industrial property rights and exclusive rights.<br>

  <h4>3. SUPPORT SERVICES </h4>
  DC Scope support is only guaranteed under a maintenance or subscription contract. 
  EasyVirt or the distributor will not provide support services during the trial version of DC Scope. <br>

  <h4>4. DURATION AND TERMINATION</h4>
  4.1 Duration and termination<br>
  With the exception of a subscription or purchase contract, the agreement will be terminate after the expiration of the applicable trial period following your download of the Software. In the event of non-compliance with the terms of this Agreement, EasyVirt may terminate this Agreement immediately and without notice.<br>
  4.2 Effect of termination<br>
  Upon termination or expiration of this Agreement, for any reason, any right, license or permission granted to you in connection with the Software will terminate immediately and you must: (i) immediately discontinue the use of the Software, in whole or in part ; and (ii) destroy, delete or permanently uninstall any copy of the Software installed or copied by you.<br>

  <h4>5. GUARANTEES AND RESPONSIBILITIES</h4>
  5.1 Exclusion of guarantees<br>
  TO THE MAXIMUM EXTENT PERMITTED BY THE MANDATORY PROVISIONS OF APPLICABLE LAW, EASYVIRT AND ITS LICENSORS MAKE THE SOFTWARE WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR ANY OTHER PROVISION OF THIS AGREEMENT OR ANY CORRESPONDENCE WITH YOU, AND EASYVIRT AND ITS LICENSORS SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE AND NON-INFRINGEMENT.<br>
  5.2 Limitation of liability<br>
  LIMITATION OF LIABILITY. TO THE EXTENT PERMITTED BY LAW, EASYVIRT AND ITS LICENSORS SHALL NOT BE LIABLE FOR LOST ECONOMIC OPPORTUNITIES OR LOST PROFITS, LOSS OF USE, INTERRUPTION OF ACTIVITY, LOSS OF DATA OR ANY OTHER INDIRECT, SPECIFIC, INCIDENTAL OR CONSEQUENTIAL DAMAGES, WHETHER BASED ON LIABILITY, SHALL BE BASED ON CONTRACTUAL LIABILITY, LIABILITY, NEGLIGENCE OR LIABILITY FOR DEFECTIVE PRODUCTS OR ANY OTHER BASIS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. THE RESPONSIBILITY OF EASYVIRT AND ITS LICENSORS IS IN ANY CASE LIMITED TO THE CONTRACTUAL LICENSE FEES YOU HAVE PAID, IF ANY, FOR THE SOFTWARE THAT HAS BEEN GIVEN TO YOU UNDER THIS AGREEMENT. THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY THE IMPAIRABLE PROVISIONS OF APPLICABLE LAW, AND EASYVIRT OR ITS LICENSORS SHALL HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS APPLY EVEN IF THEY MAY HAVE FAILURE TO MAKE ANY REMEDIES THAT WOULD BE OTHERWISE OPEN TO YOU.<br>

   <h4>6. DATA COLLECTION</h4>
  In order to perform its functions and validate the information that concerns the License Key, DC Scope collects and send the following information to our servers: License Key, Number of Virtual Machines, DC Scope version, DC Scope Build and DC Scope last connection date. DC Scope does not record any personally identifying information: no names, no email addresses, no private data from other apps, and so on.<br>
  By Your acceptance of the terms of this Agreement and/or use of the Software, You authorize the collection, use and disclosure of this data for the purposes provided for in this Agreement and the Privacy Policy.<br>

  <h4>7. GENERAL</h4>
  7.1 Extensiveness of the Contract<br>
  This Agreement states the entire liability of EasyVirt and your exclusive remedy with respect to the Software and supersedes the terms of any correspondence or advertising relating to the Software. You acknowledge that this Agreement constitutes the entire agreement between You and EasyVirt with respect to the Software and that there is no other prior or contemporaneous agreement, promise, representation or description with respect to the Software.<br>
  7.2 Titles<br>
  The titles used in this Agreement are for ease of reference only and do not affect the interpretation of this Agreement.<br>
  7.3 Waiver and Modification<br>
  Failure by either party to exercise or waive any of its rights under this Agreement will not constitute a waiver of such rights. This Agreement may only be modified or any of the rights it confers may only be waived by a written document signed by the party to whom it is opposed.<br>
  7.4 Severability<br>
  If any provision of this Agreement is declared invalid or illegal, it shall continue to apply to the extent permitted by law and the other provisions of this Agreement shall continue to be valid and enforceable.<br>
  7.5 Applicable law<br>
  This Agreement is governed by French law, regardless of the place of use.<br>
  In case of dispute and after the failure of any attempt to find an amicable solution, the French courts will be competent to recognize this dispute.<br>
  If one or more stipulations of the present General Conditions of Use are considered as invalid or declared as such under a law, a decree, a regulation or following a final decision of a competent jurisdiction the other stipulations will retain all their force and their range.<br>
  7.6 Contacts<br>
  If you have any questions about this Agreement or if you would like to contact EasyVirt for any other reason, please send your correspondence to: EasyVirt, 2 Alfred Kastler Street, 44300 NANTES or e-mail to contact@easyvirt.com<br>
  `,

        WELCOME_LICENSE_TEXT = "APPLICABLE RIGHT",
        WELCOME_LICENSE_TEXT2 = "These Terms of Use are subject to French law.",
        WELCOME_LICENSE_TEXT3 = "Accept",
        WELCOME_LICENSE_TEXT4 = "Refuse",

        WELCOME_MODE_TITLE = "Installation mode",
        WELCOME_MODE_TEXT = "New installation",
        WELCOME_MODE_TEXT2 = "Migration from a v7.4.1 appliance",

        INSTALL_TYPE_TITLE = "Type of instance",

        WELCOME_CREDENTIALS_TITLE = "VMware vCenter / ESXi credentials",
        INSTALL_CREDENTIALS_TITLE = "Credentials",

        INSTALL_CREDENTIALS_TEXT = "Name",
        INSTALL_CREDENTIALS_TEXT2 = "Simple name to identify this connection",
        INSTALL_CREDENTIALS_TEXT3 = "IP address / FQDN",
        INSTALL_CREDENTIALS_TEXT4 = "Username",
        INSTALL_CREDENTIALS_TEXT5 = "Indicate the domain name if needed",
        INSTALL_CREDENTIALS_TEXT6 = "Password",
        INSTALL_CREDENTIALS_TEXT7 = "Region",
        INSTALL_CREDENTIALS_TEXT8 = "Access key",
        INSTALL_CREDENTIALS_TEXT9 = "Secret access key",
        INSTALL_CREDENTIALS_TEXT10 = "Organization",
        INSTALL_CREDENTIALS_TEXT11 = "Organization name",
        INSTALL_CREDENTIALS_TEXT12 = "Organization access key",
        INSTALL_CREDENTIALS_TEXT13 = "Organization secret access key",
        INSTALL_CREDENTIALS_TEXT14 = "Tenant id",
        INSTALL_CREDENTIALS_TEXT15 = "Client id",
        INSTALL_CREDENTIALS_TEXT16 = "Client secret",
        INSTALL_CREDENTIALS_TEXT17 = "Subscription id",
        INSTALL_CREDENTIALS_TEXT18 = "For azure, name is automatically setted from the region",

        WELCOME_CREDENTIALS_MIGRATE_TEXT = "DCScope v7.4.1 settings",
        WELCOME_CREDENTIALS_MIGRATE_TEXT2 = "Leave this field empty to use the default password",
        WELCOME_CREDENTIALS_MIGRATE_TEXT3 = "Data retention",
        WELCOME_CREDENTIALS_MIGRATE_TEXT4 = "year",
        WELCOME_CREDENTIALS_MIGRATE_TEXT5 = "keep all the data.",
        WELCOME_CREDENTIALS_MIGRATE_TEXT6 = "Historical data to keep for the migration to DCScope v8",
        WELCOME_CREDENTIALS_MIGRATE_TEXT7 = "Warning: data outside this period will be lost !",
        WELCOME_CREDENTIALS_MIGRATE_TEXT8 =
        "The data migration process can take several hours, depending on the number of virtual machines and the data retention period.",
        WELCOME_CREDENTIALS_MIGRATE_TEXT9 =
        "For infrastructures of more than 1000 VMs and with several years of data this process can take several days.",
        WELCOME_CREDENTIALS_MIGRATE_TEXT10 =
        "We recommend selecting a data retention period no longer than two years to accelerate the process.",

        INSTALL_PROGRESS_TITLE = "Connection status",
        INSTALL_PROGRESS_TEXT = "Please wait while setting up the connection",
        INSTALL_PROGRESS_TEXT2 = "has been successfully registered !",
        INSTALL_PROGRESS_TEXT3 = "View error logs",
        INSTALL_PROGRESS_TEXT4 = "There was a problem during the connection.",
        INSTALL_PROGRESS_TEXT5 = "Please correct the previously entered information or contact EasyVirt at ",
        INSTALL_PROGRESS_TEXT6 = "Please wait while setting up the connection",
        INSTALL_PROGRESS_TEXT7 = "There was a problem during the connection.",

        WELCOME_PROGRESS_MIGRATE_TEXT = "Migration progress",

        WELCOME_END_TITLE = "Activation",
        WELCOME_END_TEXT = "The set-up of DC Scope has been completed.",
        WELCOME_END_TEXT2 = "However, the access to the DC Scope web interface requires a license key.",
        WELCOME_END_TEXT3 = "A 15-day trial license is automatically generated.",
        WELCOME_END_TEXT4 =
        "If you want to extend your trial period, please submit your product key and the number of virtual machines in your infrastructure to ",
        WELCOME_END_TEXT5 = "It takes at least 48 hours of data for all tables and analyzes to be available.",
        WELCOME_END_TEXT6 = "Your product key is ",
        WELCOME_END_TEXT7 = "Unable to generate the product key. Please contact Easyvirt at ",

        INSTALL_END_TITLE = "Finalization",
        INSTALL_END_TEXT = "You have added a connection to the solution.",
        INSTALL_END_TEXT2 = "By clicking on 'Finish', the monitoring will be started.",
        INSTALL_END_TEXT3 = "It takes at least 24 hours of data for all tables and analyzes to be available.",

};

export enum Lang_ES {
    NEXT = "Siguiente",
        PREVIOUS = "Anterior",
        FINISH = "Finalizar",
        CANCEL = "Cancelar",

        WELCOME_LANG_TITLE = "Bienvenido",
        WELCOME_LANG_TEXT = "Bienvenido a la plataforma de configuración.",
        WELCOME_LANG_TEXT2 = "Seleccione el lenguaje de instalación",

        WELCOME_LICENSE_TITLE = "Términos de uso",
        WELCOME_LICENSE_OTHER =
        "La siguiente traducción es para fines de referencia. En caso de discrepancia en la interpretación, la versión francesa prevalecerá.", //Only for other than french

        WELCOME_LICENSE_LICENSE =
        `<h4>Preámbulo:</h4>
  Estas condiciones generales de uso se aplican al uso de la licencia del software DC-Scope disponible en www.easyvirt.com. EasyVirt SAS es una empresa francesa con sede en Nantes (Francia), 2 Alfred Kastler.<br>
  DESCARGAR, INSTALAR, COPIAR, USAR EL SOFTWARE PROPORCIONA SU ACEPTACIÓN PLENA Y ENTERA A LOS TÉRMINOS DE ESTE CONTRATO DE LICENCIA ("CONTRATO")DE DC SCOPE. SI NO ACEPTA LOS TÉRMINOS DE ESTE ACUERDO, NO PUEDE DESCARGAR, INSTALAR, COPIAR O UTILIZAR EL SOFTWARE. "USTED" SIGNIFICA LA PERSONA FÍSICA O LEGAL QUE ACEPTA LA OBLIGACIÓN DE ESTE ACUERDO, SUS EMPLEADOS Y LOS CONTRATISTAS DE TERCEROS QUE LE PROPORCIONAN SERVICIOS. USTED SERÁ RESPONSABLE DE CUALQUIER INCUMPLIMIENTO DE LOS TÉRMINOS DE ESTE ACUERDO DE ESOS EMPLEADOS Y SUBCONTRATISTAS DE TERCEROS.<br>

  <h4>1. CONCESIÓN Y DERECHOS DE USO DEL SOFTWARE.</h4>
  1.1 licencia<br>
  Este Acuerdo le otorga a usted un derecho limitado, no exclusivo e intransferible a utilizar una copia del Software en forma objeto. "Software" hace referencia a los productos de software que se le otorgan bajo este Acuerdo, incluidos, entre otros, todos los componentes vinculados provistos con el Software (interfaces de programación de aplicaciones, medios asociados, documentación). impresos, en línea o electrónicos, y todas sus actualizaciones y parches.) Los componentes de software de código abierto (« Open Source ») que se proporcionan con el Software se le otorgan bajo los términos de los acuerdos de licencia aplicables incluidos con los componentes de software con licencia libre. Las licencias para el software con licencia gratuita están disponibles a pedido en contact@easyvirt.com.<br>
  1.2 Limitaciones de la licencia<br>
  No esta autorizado a copiar el Software ni eliminar ninguno de los títulos, marcas comerciales, nombres comerciales, avisos de derechos de autor, leyendas u otros avisos de propiedad contenidos en el Software. No se le otorga ningún derecho sobre marcas registradas o marcas de servicio de EasyVirt.<br>
  1.3 Restricciones<br>
  No puede (i) ceder, ceder, sublicenciar, sublicenciar, distribuir o transferir de cualquier forma, en su totalidad o en parte, el Software a un tercero; (ii) proporcionar, comunicar, divulgar o poner a disposición de otro modo, o permitir el uso del Software, en su totalidad o en parte, por parte de un tercero sin el consentimiento previo por escrito de EasyVirt; (iii) modificar o crear trabajos derivados basados ​​en el Software; o (iv) crear, desarrollar, licenciar, instalar, usar o implementar software o servicios de terceros con el fin de eludir, habilitar, modificar o proporcionar cualquier acceso, autorización o derecho que infrinja las restricciones técnicas del Software, o con los términos de licencia adicionales estipulados por EasyVirt en la documentación de su producto y / o en un aviso y / o con las disposiciones de este Acuerdo. A menos que EasyVirt lo autorice, no puede descompilar o desarmar el Software, realizar ingeniería inversa ni intentar obtener el código fuente, en su totalidad o en parte. Antes de ejercer cualquier derecho que crea que tiene sobre la base de las disposiciones legales, debe enviar a EasyVirt un aviso por escrito de 30 días a contact@easyvirt.com y comunicar a EasyVirt toda la información para evaluar su solicitud. Puede utilizar el Software para realizar pruebas de rendimiento y estudios de evaluación internamente, que usted (y no un tercero no autorizado) puede publicar o difundir al público, siempre que EasyVirt haya revisado y aprobado. La metodología, suposiciones y otros parámetros del estudio. Para realizar una solicitud, comuníquese con EasyVirt a contact@easyvirt.com.<br>

  <h4>2. TITULO</h4>
  EasyVirt retiene todos los derechos, títulos e intereses en el Software y todos los derechos de autor, secretos comerciales, patentes, marcas comerciales y otros derechos de propiedad intelectual e industrial y derechos exclusivos.<br>

  <h4>3. SERVICIO DE SOPORTE.</h4>
  El soporte de DC Scope solo está garantizado bajo un contrato de mantenimiento o suscripción.
  EasyVirt o el distribuidor no proporcionarán servicios de soporte durante la versión de prueba de DC Scope.<br>

  <h4>4. DURACIÓN Y TERMINACIÓN.</h4>
  4.1 Duración y terminación<br>
  A menos que se realice un contrato de suscripción o compra de DC Scope, el presente contrato terminará con la fecha de expiración del período de prueba aplicable después de la descarga del Software.. En caso de incumplimiento de los términos de este Acuerdo, EasyVirt puede rescindir este Acuerdo de forma inmediata y sin previo aviso.<br>
  4.2 Efecto de la terminación<br>
  En caso de rescisión o expiración de este Acuerdo, por cualquier motivo, cualquier derecho, licencia o permiso otorgado a usted en relación con el Software finalizará de inmediato y deberá: (i) suspender de inmediato el uso del Software, total o parcialmente ; y (ii) destruir, eliminar o desinstalar permanentemente cualquier copia del Software instalada o copiada por usted.<br>

  <h4>5. GARANTÍAS Y RESPONSABILIDADES.</h4>
  5.1 Exclusión de garantías.<br>
  EN LA MÁXIMA MEDIDA PERMITIDA POR LAS DISPOSICIONES OBLIGATORIAS DE LA LEY APLICABLE, EASYVIRT Y SUS LICENCIADORES HACEN EL SOFTWARE SIN GARANTÍA DE NINGÚN TIPO, YA SEA EXPRESA, IMPLÍCITA, ESTATUTARIA, O CUALQUIER OTRA PROVISIÓN USTED, Y EASYVIRT Y SUS LICENCIADORES, EXCLUYEN ESPECÍFICAMENTE TODAS LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, APTITUD PARA CUALQUIER PROPÓSITO PARTICULAR Y NO INFRACCIÓN.<br>
  5.2 Limitación de responsabilidad<br>
  LIMITACIÓN DE RESPONSABILIDAD. EN LA MEDIDA EN QUE LO PERMITEN LA LEY, EASYVIRT Y SUS LICENCIANTES NO SERÁN RESPONSABLES POR OPORTUNIDADES ECONÓMICAS PERDIDAS O BENEFICIOS PERDIDOS, PÉRDIDA DE USO, INTERRUPCIÓN DE ACTIVIDAD, PÉRDIDA DE DATOS O CUALQUIER OTRO DAÑO INDIRECTO, ESPECÍFICO, INCIDENTAL O CONSECUENTE, YA SEA BASADO EN LA RESPONSABILIDAD, SE DEBE BASAR EN LA RESPONSABILIDAD CONTRACTUAL, LA RESPONSABILIDAD, LA NEGLIGENCIA O LA RESPONSABILIDAD POR PRODUCTOS DEFECTUOSOS O CUALQUIER OTRA BASE. ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE RESPONSABILIDAD POR DAÑOS INCIDENTALES O CONSECUENTES, POR LO QUE LAS LIMITACIONES ESTABLECIDAS ANTERIORMENTE PUEDEN NO APLICARSE A USTED. LA RESPONSABILIDAD DE EASYVIRT SE ENCUENTRA EN CUALQUIER CASO LIMITADO A LAS CUOTAS DE LICENCIA CONTRACTUAL QUE HA PAGADO, SI HAY, POR EL SOFTWARE QUE SE LE HA PROPORCIONADO BAJO ESTE ACUERDO. LAS LIMITACIONES ANTERIORES SE APLICARÁN EN LA MEDIDA MÁXIMA PERMITIDA POR LAS DISPOSICIONES IMPAIRABLES DE LA LEY APLICABLE, Y EASYVIRT O SUS LICENCIADORES DEBERÁN CONSIDERAR LA POSIBILIDAD DE DICHOS DAÑOS. ESTAS LIMITACIONES SE APLICAN INCLUSO SI PUEDEN TENER UNA FALTA DE HACER CUALQUIER RECURSO QUE DE NO ESTARÍA ABIERTO PARA USTED.<br>

  <h4>6. RECOPILACIÓN DE DATOS</h4>
  Para realizar sus funciones y validar la informacion de la Clave de Licencia, DC Scope recopila y envía la siguiente información a nuestros servidores: clave de licencia, número de máquinas virtuales, versión de DC Scope, DC Scope Build y última fecha de conexión de DC Scope. DC Scope no registra ningun tipo información personal como:  nombres, direcciones de correo electrónico,  datos privados de otras aplicaciones, etc.<br>
  Al aceptar los términos de este Acuerdo y/o el uso del Software, autoriza la recopilación, el uso y la divulgación de estos datos para los fines previstos en este Acuerdo y en la Política de privacidad.<br>

  <h4>7. GENERAL</h4>
  7.1 Cumplimiento del Contrato<br>
  Este Acuerdo establece la responsabilidad total de EasyVirt y su solución exclusiva con respecto al Software y reemplaza los términos de cualquier correspondencia o publicidad relacionada con el Software. Usted reconoce que este Acuerdo constituye el acuerdo completo entre usted y EasyVirt con respecto al Software y que no existe ningún otro acuerdo, promesa, representación o descripción anterior o contemporánea con respecto al Software.<br>
  7.2 títulos<br>
  Los títulos utilizados en este Acuerdo son solo para facilitar la referencia y no afectan la interpretación de este Acuerdo.<br>
  7.3 Renuncia y Modificación<br>
  El hecho de que cualquiera de las partes no ejerza o renuncie a cualquiera de sus derechos en virtud del presente Acuerdo no constituirá una renuncia a tales derechos. Este Acuerdo solo puede ser modificado o cualquiera de los derechos que confiere solo puede ser renunciado por un documento escrito firmado por la parte a la que se opone.<br>
  7.4 Divisibilidad<br>
  Si alguna disposición de este Acuerdo se declara inválida o ilegal, continuará aplicándose en la medida permitida por la ley y las demás disposiciones de este Acuerdo continuarán siendo válidas y aplicables.<br>
  7.5 Ley aplicable<br>
  Este Acuerdo se rige por la legislación francesa, independientemente del lugar de uso.<br>
  En caso de disputa y después del fracaso de cualquier intento de encontrar una solución amistosa, los tribunales franceses serán competentes para reconocer esta disputa.<br>
  Si una o más estipulaciones de las presentes Condiciones Generales de Uso son consideradas como no válidas o declaradas como tales bajo una ley, un decreto, un reglamento o después de una decisión final de una jurisdicción competente Las otras estipulaciones conservarán toda su fuerza y ​​su alcance.<br>
  7.6 contactos<br>
  Si tiene alguna pregunta sobre este Acuerdo o si desea ponerse en contacto con EasyVirt por cualquier otro motivo, envíe su correspondencia a: EasyVirt, 2 Alfred Kastler, 44300 NANTES o envíe un correo electrónico a contact@easyvirt.com<br>
  `,

        WELCOME_LICENSE_TEXT = "DERECHO APLICABLE",
        WELCOME_LICENSE_TEXT2 = "Estos Términos de uso están sujetos a la ley francesa.",
        WELCOME_LICENSE_TEXT3 = "Aceptar",
        WELCOME_LICENSE_TEXT4 = "Rechazar",

        WELCOME_MODE_TITLE = "Tipo de instalación",
        WELCOME_MODE_TEXT = "Nueva instalación",
        WELCOME_MODE_TEXT2 = "Migración desde una aplicación v7.4.1",

        INSTALL_TYPE_TITLE = "Tipo",

        WELCOME_CREDENTIALS_TITLE = "Configuración de acceso al VMware vCenter / ESXi",
        INSTALL_CREDENTIALS_TITLE = "Configuración de acceso",

        INSTALL_CREDENTIALS_TEXT = "Nombre",
        INSTALL_CREDENTIALS_TEXT2 = "Nombre simple para identificar la conexión",
        INSTALL_CREDENTIALS_TEXT3 = "Dirección IP / FQDN",
        INSTALL_CREDENTIALS_TEXT4 = "Nombre de usuario",
        INSTALL_CREDENTIALS_TEXT5 = "Indique el nombre de dominio si es necesario",
        INSTALL_CREDENTIALS_TEXT6 = "Contraseña",

        WELCOME_CREDENTIALS_MIGRATE_TEXT = "Configuración de DCScope v7.4.1",
        WELCOME_CREDENTIALS_MIGRATE_TEXT2 = "Deje este campo vacío para usar la contraseña predeterminada",
        WELCOME_CREDENTIALS_MIGRATE_TEXT3 = "Retención de datos",
        WELCOME_CREDENTIALS_MIGRATE_TEXT4 = "ano",
        WELCOME_CREDENTIALS_MIGRATE_TEXT5 = "conservar todos los datos",
        WELCOME_CREDENTIALS_MIGRATE_TEXT6 = "Datos históricos que se conservan durante la migración a DCScope v8",
        WELCOME_CREDENTIALS_MIGRATE_TEXT7 = "Advertencia: los datos por fuera de este período serán eliminados !",
        WELCOME_CREDENTIALS_MIGRATE_TEXT8 =
        "El proceso de migración de datos puede tardar varias horas, según la cantidad de máquinas virtuales y el período de retención de datos.",
        WELCOME_CREDENTIALS_MIGRATE_TEXT9 =
        "Para infraestructuras de más de 1000 maquinas virtuales y con varios años de datos, este proceso puede tardar varios días.",
        WELCOME_CREDENTIALS_MIGRATE_TEXT10 =
        "Recomendamos seleccionar un período de retención de datos que no supere los dos años para acelerar el proceso.",

        INSTALL_PROGRESS_TITLE = "Estado de la conexión",
        INSTALL_PROGRESS_TEXT = "Por favor espere durante la configuración",
        INSTALL_PROGRESS_TEXT2 = "es operativo !",
        INSTALL_PROGRESS_TEXT3 = "Ver registro de errores",
        INSTALL_PROGRESS_TEXT4 = "Hubo un problema al conectarse.",
        INSTALL_PROGRESS_TEXT5 =
        "Corrija la información proporcionada previamente o póngase en contacto con EasyVirt en ",
        INSTALL_PROGRESS_TEXT6 = "Por favor espere durante la configuración",
        INSTALL_PROGRESS_TEXT7 = "Hubo un problema al conectarse.",

        WELCOME_PROGRESS_MIGRATE_TEXT = "Progreso de la migración",

        WELCOME_END_TITLE = "Activación",
        WELCOME_END_TEXT = "La configuración de la solución DC Scope ha sido completada.",
        WELCOME_END_TEXT2 = "Sin embargo, el acceso a la interfaz web de DC Scope requiere una clave de licencia.",
        WELCOME_END_TEXT3 = "Se genera automáticamente una licencia de prueba por 15 días.",
        WELCOME_END_TEXT4 =
        "Si desea extender su período de prueba, por favor envíe su clave de producto y la cantidad de máquinas virtuales en su infraestructura a ",
        WELCOME_END_TEXT5 =
        "Se necesitan al menos 48 horas de recolección de datos para que todas las tablas y análisis estén disponibles.",
        WELCOME_END_TEXT6 = "Tu clave de producto",
        WELCOME_END_TEXT7 =
        "No se puede generar la clave del producto. Por favor, póngase en contacto con Easyvirt en ",

        INSTALL_END_TITLE = "Finalización",
        INSTALL_END_TEXT = "Ha agregado una conexión a la solución DC Scope.",
        INSTALL_END_TEXT2 = "Al hacer clic en 'Finalizar', se iniciará la monitorización.",
        INSTALL_END_TEXT3 =
        "Se necesitan al menos 24 horas de recolección de datos para que todas las tablas y análisis estén disponibles.",
};

export enum ElementType {
    // Storage
    BACKUP,
    SAN,
    NAS,
    // Network
    FIREWALL,
    SWITCH,
    WIFIACCESSPOINT,
    // Nodes
    IPMI_NODE,
    KUBE_NODE,
    ONEVIEW_NODE,
    OPENMANAGE_NODE,
    SCAPHANDRE_NODE,
    SERVER,
    XCLARITY_NODE,
    // Cloud
    INSTANCE,
    EXAGRID_SAN,
    IBM_SAN
}
