import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faPlug
} from '@fortawesome/free-solid-svg-icons';

import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);

import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offline(Highcharts);

import {
    PowerGraphComponent
} from '@app/greenit/power/power-graph/power-graph.component';
import {
    PowerVmStackedComponent
} from '@app/greenit/power/power-vm-stacked/power-vm-stacked.component';

import {
    AccountService,
    GreenitService,
    ShareService
} from '@app/services';

import {
    Message,
    User
} from '@app/model';


@Component({
    selector: 'app-power-azure',
    templateUrl: './power-azure.component.html',
    styleUrls: ['./power-azure.component.css']
})
export class PowerAzureComponent implements OnInit {

    currentUser: User;

    message: Message;

    @ViewChild('powerGraph') powerGraph: PowerGraphComponent;
    @ViewChild('powerVmStacked') powerVmStacked: PowerVmStackedComponent;

    selectedElements: string[] = [];

    targetInfrastructure: string = 'azure';

    isData: boolean = false;
    isReady: boolean = false;


    constructor(
        private account_svc: AccountService,
        private message_svc: ShareService,
        private library: FaIconLibrary,
        private greenit_svc: GreenitService
    ) {
        this.library.addIcons(faPlug);
    }

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.selectedElements = [];

        this.greenit_svc.getRegionsListObservable('', 'azure').subscribe(
            data => {
                if (data.length > 0) {
                    this.isData = true;
                    this.message.cloudAccount = data[0].account;
                    this.message.cloudAccountId = data[0].uuid;
                    this.greenit_svc.getRegionElementsListObservable('', 'azure', this.message.cloudAccountId,
                        this.currentUser.login, this.message.elementView).subscribe(
                        data => {
                            this.selectedElements.push(data[0]);
                            this.isReady = true;
                            setTimeout(() => {
                                this.updateChart();
                            }, 100);
                        }
                    );
                }
            }
        );
    }

    updateChart(): void {

        if (this.powerGraph != undefined) {
            setTimeout(() => {
                this.powerGraph.updateChart();
            }, 100);
        }
    }
}
