<div class="clr-row">
	<div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngIf="isTsDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>cpu</label>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
      			</div>
			<img [@rotatedState]='{value: ts_cpu_arrow_trigger, params: {ts_cpu_arrow_o: ts_cpu_arrow_o}}' ngClass="addLink" style="border-radius:50%" src="{{ts_cpu_url}}" (click)="setTroubleshootingCpuDetails()">
			<clr-control-helper>troubleshooting on the cpu resource</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngIf="isTsDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>disk</label>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
      			</div>
			<img [@rotatedState]='{value: ts_disk_arrow_trigger, params: {ts_disk_arrow_o: ts_disk_arrow_o}}' ngClass="addLink" style="border-radius:50%" src="{{ts_disk_url}}" (click)="setTroubleshootingDiskDetails()">
			<clr-control-helper>troubleshooting on the disk resource</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngIf="isTsDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>net</label>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
      			</div>
			<img [@rotatedState]='{value: ts_net_arrow_trigger, params: {ts_net_arrow_o: ts_net_arrow_o}}' ngClass="addLink" style="border-radius:50%" src="{{ts_net_url}}" (click)="setTroubleshootingNetDetails()">
			<clr-control-helper>troubleshooting on the network resource</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngIf="isTsDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>ram</label>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
      			</div>
			<img [@rotatedState]='{value: ts_ram_arrow_trigger, params: {ts_ram_arrow_o: ts_ram_arrow_o}}' ngClass="addLink" style="border-radius:50%" src="{{ts_ram_url}}" (click)="setTroubleshootingRamDetails()">
			<clr-control-helper>troubleshooting on the ram resource</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>cpu usage</label>
      			</div>
			<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph()" *ngIf="currentUser.isMenuGod">
				<cds-icon shape="line-chart"></cds-icon>
			</button>
			<clr-control-helper>observed counter</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>90%</label>
      			</div>
			<span style="background-image:radial-gradient(#FFD700,#FF5B2B);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the warning trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>95%</label>
      			</div>
			<span style="background-image:radial-gradient(#F7AEAE,#FC0000);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the critical trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>machine overload</label>
      			</div>
			<clr-control-helper>definition</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_cpu_crsr_orange}}</label>
      			</div>
			<clr-control-helper>number of points exceed 90% cpu usage</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_cpu_crsr_red}}</label>
      			</div>
			<clr-control-helper>number of points exceed 95% cpu usage</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>cpu ready</label>
      			</div>
			<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph()" *ngIf="currentUser.isMenuGod">
				<cds-icon shape="line-chart"></cds-icon>
			</button>
			<clr-control-helper>observed counter</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>5%</label>
      			</div>
			<span style="background-image:radial-gradient(#FFD700,#FF5B2B);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the warning trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>10%</label>
      			</div>
			<span style="background-image:radial-gradient(#F7AEAE,#FC0000);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the critical trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>too much activity on host</label>
      			</div>
			<clr-control-helper>definition</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_cpu_rdy_orange}}</label>
      			</div>
			<clr-control-helper>number of points exceed 5% cpu ready</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_cpu_rdy_red}}</label>
      			</div>
			<clr-control-helper>number of points exceed 10% cpu ready</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>cpu costop</label>
      			</div>
			<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph()" *ngIf="currentUser.isMenuGod">
				<cds-icon shape="line-chart"></cds-icon>
			</button>
			<clr-control-helper>observed counter</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>1%</label>
      			</div>
			<span style="background-image:radial-gradient(#FFD700,#FF5B2B);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the warning trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>3%</label>
      			</div>
			<span style="background-image:radial-gradient(#F7AEAE,#FC0000);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the critical trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>too much vCPU</label>
      			</div>
			<clr-control-helper>definition</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_cpu_cstp_orange}}</label>
      			</div>
			<clr-control-helper>number of points exceed 1% cpu co-stop</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsCpuDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_cpu_cstp_red}}</label>
      			</div>
			<clr-control-helper>number of points exceed 3% cpu co-stop</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail && message.currentType === 'VM'">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>command fails</label>
      			</div>
			<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph()" *ngIf="currentUser.isMenuGod">
				<cds-icon shape="line-chart"></cds-icon>
			</button>
			<clr-control-helper>observed counter</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail && message.currentType === 'VM'">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>1</label>
      			</div>
			<span style="background-image:radial-gradient(#FFD700,#FF5B2B);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the warning trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail && message.currentType === 'VM'">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>5</label>
      			</div>
			<span style="background-image:radial-gradient(#F7AEAE,#FC0000);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the critical trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail && message.currentType === 'VM'">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>lost commands</label>
      			</div>
			<clr-control-helper>definition</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail && message.currentType === 'VM'">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_disk_cmd_orange}}</label>
      			</div>
			<clr-control-helper>number of points that exceed 1 lost command</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail && message.currentType === 'VM'">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_disk_cmd_red}}</label>
      			</div>
			<clr-control-helper>number of points that exceed 5 lost commands</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>total latency</label>
      			</div>
			<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph()" *ngIf="currentUser.isMenuGod">
				<cds-icon shape="line-chart"></cds-icon>
			</button>
			<clr-control-helper>observed counter</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>20 ms</label>
      			</div>
			<span style="background-image:radial-gradient(#FFD700,#FF5B2B);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the warning trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>30 ms</label>
      			</div>
			<span style="background-image:radial-gradient(#F7AEAE,#FC0000);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the critical trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>read & write latency</label>
      			</div>
			<clr-control-helper>definition</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_disk_lat_orange}}</label>
      			</div>
			<clr-control-helper>number of points where the latency exceeds 20 ms</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsDiskDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_disk_lat_red}}</label>
      			</div>
			<clr-control-helper>number of points where the latency exceeds 30 ms</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsNetDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>lost packet</label>
      			</div>
			<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph()" *ngIf="currentUser.isMenuGod">
				<cds-icon shape="line-chart"></cds-icon>
			</button>
			<clr-control-helper>observed counter</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsNetDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>1</label>
      			</div>
			<span style="background-image:radial-gradient(#FFD700,#FF5B2B);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the warning trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsNetDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>5</label>
      			</div>
			<span style="background-image:radial-gradient(#F7AEAE,#FC0000);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the critical trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsNetDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>number of lost packets</label>
      			</div>
			<clr-control-helper>definition</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsNetDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_net_orange}}</label>
      			</div>
			<clr-control-helper>number of points that exceed 1 lost packet</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsNetDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_net_red}}</label>
      			</div>
			<clr-control-helper>number of points that exceed 5 lost packets</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsRamDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>ram usage</label>
      			</div>
			<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph()" *ngIf="currentUser.isMenuGod">
				<cds-icon shape="line-chart"></cds-icon>
			</button>
			<clr-control-helper>observed counter</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsRamDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>70%</label>
      			</div>
			<span style="background-image:radial-gradient(#FFD700,#FF5B2B);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the warning trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsRamDetail">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>90%</label>
      			</div>
			<span style="background-image:radial-gradient(#F7AEAE,#FC0000);border-radius:50%;width:11px;height:11px;display:inline-block"></span>
			<clr-control-helper>the critical trigger threshold</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsRamDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>machine overload</label>
      			</div>
			<clr-control-helper>definition</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsRamDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_ram_orange}}</label>
      			</div>
			<clr-control-helper>number of points that exceed 70% ram usage</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isTsRamDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{threshold_pts.pts_ram_red}}</label>
      			</div>
			<clr-control-helper>number of points that exceed 90% ram usage</clr-control-helper>
		</form>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="isTsRamDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{ts_selected.avg_ram_swp.toFixed(1)}} Mo</label>
				<clr-control-helper>the average amount of ram swapped</clr-control-helper>
      			</div>
			<img [@rotatedState]='{value: ts_ramswp_arrow_trigger, params: {ts_ramswp_arrow_o: ts_ramswp_arrow_o}}' style="border-radius:50%" src="{{ts_ramswp_url}}">
		</form>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="isTsRamDetail">
		<form clrForm clrLayout="vertical">
			<div class="clr-input">
				<label>{{ts_selected.avg_ram_bal.toFixed(1)}} Mo</label>
				<clr-control-helper>the average amount of ram ballooning</clr-control-helper>
      			</div>
			<img [@rotatedState]='{value: ts_rambal_arrow_trigger, params: {ts_rambal_arrow_o: ts_rambal_arrow_o}}' style="border-radius:50%" src="{{ts_rambal_url}}">
		</form>
	</div>
</div>
