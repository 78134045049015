<ng-template #templateSelectedResources let-item="item">
    <span [attr.class]="item?.resourceType == 'host' ? 'badge badge-blue' : (item?.resourceType == 'vm' ? 'badge badge-light-blue': 'badge badge-orange')">{{ item?.resourceType }}</span>
    {{ item?.name }}
</ng-template>

<ng-template #templateDisplayResources let-item='item'>
    <span class="badge" [attr.class]="item?.resourceType == 'host' ? 'badge badge-blue' : (item?.resourceType == 'vm' ? 'badge badge-light-blue': 'badge badge-orange')">{{ item?.resourceType }}</span>
    {{ item.name }}
</ng-template>

<ng-template #templateDisplayResourcesWhenEmpty>
    <clr-control-helper slot="displayWhenEmpty">Type a resource name</clr-control-helper>
</ng-template>

<ng-template #templateDisplayResourcesWhenErrors>
    <clr-control-error slot="displayWhenError">There was an error</clr-control-error>
</ng-template>

<div style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-flow: column;">

    <clr-alert [clrAlertType]="'danger'" [clrAlertAppLevel]="true" *ngIf="failureMode && !isLoading">
        <clr-alert-item>
            <span class="alert-text">Cannot load data from DC Netscope API. Please check the diagnostic page.</span>
            <div class="alert-actions">
                <a routerLink="/netscope/diagnostic" routerLinkActive="active">
                    <button class="btn alert-action">
                        <cds-icon shape="checkbox-list"></cds-icon>
                        Diagnostic
                    </button>
                </a>
            </div>
        </clr-alert-item>
    </clr-alert>

    <div class="clr-row" style="margin: 0px; background-color: rgb(0, 55, 76); color : white; overflow: visible; min-height: 74px;">
        <div class="clr-col" id="flow_view_title" style="flex-grow: 0; color: white; text-align: center;">
            <table style="height: 100%; min-width: 200px; font-weight: 400; font-size: .8rem; letter-spacing: .01em; text-decoration: none;">
                <tr>
                    <td>
                        <cds-icon shape="heat-map" style="margin-right: 10px; margin-bottom: 3px;" size="md"></cds-icon>
                    </td>
                    <td style="text-align: left;">{{ "dcnetscope.heatmap_viewer.name" | transloco }}</td>
                </tr>
            </table>
        </div>

        <!-- Empty column that separates title and group of buttons at the right -->
        <div class="clr-col" style="padding-top: 10px; padding-bottom: 5px;">
        </div>

        <div class="clr-col" style="width: 100px; flex-grow: 0; padding-left: 0px;">
            <table style="height: 100%;">
                <tr>
                    <td style="padding: 5px;">
                        <a href="javascript://" id="flow_view_reload_button" class="nav-link nav-icon" aria-label="reload">
                            <cds-icon shape="refresh" (click)="reloadData()" *ngIf="!isLoading" style="color: white;"></cds-icon>
                            <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <a href="javascript://" class="nav-link nav-icon" aria-label="tour">
                            <!--                            <cds-icon shape="help" style="color: white;" (click)="launchTour()"></cds-icon>-->
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <clr-dropdown [clrCloseMenuOnItemClick]="true">
                            <a clrDropdownTrigger href="javascript://" class="nav-link nav-icon" aria-label="settings">
                                <cds-icon shape="cog" style="color: white;"></cds-icon>
                            </a>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Language</label>
                                <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="setLanguage('french')">French</div>
                                <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="setLanguage('english')">English</div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div style="padding-left: 30px; padding-right: 30px;">

        <div class="clr-row">
            <div class="clr-col-md clr-col-lg clr-col-12">
                <div class="card">
                    <div class="card-block">
                        <div class="card-title">
                    <span class="badge badge-blue">
                      step 1
                    </span>
                            Pick a first resource
                        </div>
                        <div class="card-text">
                            <app-heatmap-combobox
                                    [(selectedItems)]="selectedResources1"
                                    [itemsGenerator]="fetchVms1"
                                    (onChange)="vm1SelectionChanged()"
                                    [templateSelectedItems]="templateSelectedResources"
                                    [templateDisplayItems]="templateDisplayResources"
                                    [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
                                    [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
                                    [itemsField]="'name'"
                                    [disabled]="combobox1IsDisabled"
                                    #vm1Combobox
                            >
                            </app-heatmap-combobox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
                <div class="card">
                    <div class="card-block" style="padding-right: 15px; padding-left: 15px;">
                        <div class="card-title">
                    <span class="badge badge-blue">
                      step 2
                    </span>
                            Select direction
                        </div>
                        <div class="card-text" style="text-align: center; padding-left: 35px; padding-right: 35px;">
                            <clr-radio-wrapper>
                                <input type="radio" [(ngModel)]="bidirectionalFlows" clrRadio value="no" name="options" (change)="directionChanged()"/>
                                <label><cds-icon shape="two-way-arrows"></cds-icon> Single way</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" [(ngModel)]="bidirectionalFlows" clrRadio value="yes" name="options" (change)="directionChanged()" />
                                <label><cds-icon shape="arrow" direction="right"></cds-icon> Two ways</label>
                            </clr-radio-wrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-col-md clr-col-lg clr-col-12">
                <div class="card">
                    <div class="card-block">
                        <div class="card-title">
                    <span class="badge badge-blue">
                      step 3
                    </span>
                            Pick a second resource
                            <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="combobox2IsLoading">Loading...</span>
                        </div>
                        <div class="card-text">
                            <app-heatmap-combobox
                                    [(selectedItems)]="selectedResources2"
                                    [itemsGenerator]="fetchVms2"
                                    (onChange)="vm2SelectionChanged()"
                                    [templateSelectedItems]="templateSelectedResources"
                                    [templateDisplayItems]="templateDisplayResources"
                                    [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
                                    [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
                                    [itemsField]="'name'"
                                    [disabled]="combobox2IsDisabled"
                                    #vm2Combobox
                            >
                            </app-heatmap-combobox>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="clr-row" *ngIf="selectedResources1?.length > 0 && selectedResources2?.length > 0">
            <div class="clr-col-12">
                <div class="card">
                    <div class="card-header">
                        Traffic between {{ selectedResources1[0].name }} and {{ selectedResources2[0].name }}
                        <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="heatmapIsLoading">Loading...</span>
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                        </div>
                        <div class="card-text">
                            <app-heatmap-highcharts-heatmap
                                    [onClick]=""
                                    [updateEmitter]="resourceSelectionChanged"
                                    [clickOnTimeSlotEmitter]="clickOnTimeSlotEmitter"
                                    [loadingStateEmitter]="loadingStateUpdateEmitter"
                                    [selectedResources1]="selectedResources1"
                                    [selectedResources2]="selectedResources2"
                                    [bidirectional]="bidirectionalFlows === 'yes'"
                                    [doubleClickSelectDay]="true"
                                    #temporalSelectionHeatMap
                            ></app-heatmap-highcharts-heatmap>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="clr-row" *ngIf="selectedResources1?.length > 0 && selectedResources2?.length > 0 && flowDetails !== undefined">

            <div class="clr-col-12">
                <div class="card">
                    <div class="card-header">
                        Details of the between {{ selectedResources1[0].name }} and {{ selectedResources2[0].name }} at {{ displayedTime }}
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                        </div>
                        <div class="card-text">
                            <clr-datagrid>
                                <clr-dg-column [clrDgField]="'src_address'">
                                    Source
                                    <clr-dg-string-filter [clrDgStringFilter]="sourceIpFilter"></clr-dg-string-filter>
                                </clr-dg-column>
                                <clr-dg-column [clrDgField]="'dst_address'">
                                    Destination
                                    <clr-dg-string-filter [clrDgStringFilter]="destinationIpFilter"></clr-dg-string-filter>
                                </clr-dg-column>
                                <clr-dg-column [clrDgField]="'port'" [clrDgSortOrder]="descSort">Port</clr-dg-column>
                                <clr-dg-column [clrDgField]="'application_protocol'">Protocol</clr-dg-column>
                                <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">Total bytes</clr-dg-column>

                                <clr-dg-row *clrDgItems="let flow of flowDetails.metrics" [clrDgItem]="flow">
                                    <clr-dg-cell>
                                        <a style="margin-right: 5px;" routerLink="/netscope/flows/dependencies-viewer" [queryParams]="{start_time: selectedTimestamp, time_resolution: selectedTimeResolution, focused_vms: [flow.source_address.uuid]}" routerLinkActive="active">
                                            <span [attr.class]="flow.source_address?.resourceType == 'host' ? 'badge badge-blue' : (flow.source_address?.resourceType == 'vm' ? 'badge badge-light-blue': 'badge badge-orange')">{{flow.source_address.name}}</span>
                                        </a>
                                    </clr-dg-cell>
                                    <clr-dg-cell>
                                        <a style="margin-right: 5px;" routerLink="/netscope/flows/dependencies-viewer" [queryParams]="{start_time: selectedTimestamp, time_resolution: selectedTimeResolution, focused_vms: [flow.destination_address.uuid]}" routerLinkActive="active">
                                            <span [attr.class]="flow.destination_address?.resourceType == 'host' ? 'badge badge-blue' : (flow.destination_address?.resourceType == 'vm' ? 'badge badge-light-blue': 'badge badge-orange')">{{flow.destination_address.name}}</span>
                                        </a>
                                    </clr-dg-cell>
                                    <clr-dg-cell><span class="label">{{flow.port}}</span> <span class="label label-info">{{flow.transport_protocol}}</span></clr-dg-cell>
                                    <clr-dg-cell>
                                        <a style="margin-right: 5px;" routerLink="/netscope/flows/protocol-analysis/protocols/{{flow.application_protocol}}" [queryParams]="{start_time: selectedTimestamp, time_resolution: selectedTimeResolution}" routerLinkActive="active">
                                            <span class="label">{{flow.application_protocol}}</span>
                                        </a>
                                    </clr-dg-cell>
                                    <clr-dg-cell>{{flow.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
                                </clr-dg-row>

                                <clr-dg-footer>
                                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                                    </clr-dg-pagination>
                                </clr-dg-footer>

                            </clr-datagrid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>