import {
    Component,
    EventEmitter,
    HostListener,
    OnInit,
    Output
} from '@angular/core';

import {
    AccountService,
    ShareService,
    SettingsService,
    JsonloaderService
} from '@app/services';

import {
    User,
    Message,
    IhmSettings,
    IhmSettingsTarget,
    JSONTarget
} from '@app/model';
import {
    Subscription
} from 'rxjs';

@Component({
    selector: 'app-capasettings',
    templateUrl: './capasettings.component.html',
    styleUrls: ['./capasettings.component.css']
})
export class CapasettingsComponent implements OnInit {

    @Output('reload') reloadChange = new EventEmitter < boolean > ();
    pushReload() {
        this.reloadChange.emit(true);
    }

    message: Message;
    currentUser: User;

    capa_settings: IhmSettings;
    bkp_settings: IhmSettings;

    capa_subscription: Subscription;

    capa_template_name: string;
    capa_template_vcpu: number;
    capa_template_vram: number;
    capa_template_vdisk: number;

    capa_template_avg_name: string;
    capa_template_avg_vcpu: number;
    capa_template_avg_vram: number;
    capa_template_avg_vdisk: number;

    update_button = false;


    /**
     * 
     */
    constructor(private authentication_svc: AccountService,
        private message_svc: ShareService, private settings_svc: SettingsService,
        private json_svc: JsonloaderService) {

    }


    /**
     * 
     */
    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        // Reload capa settings
        this.settings_svc.reload(this.currentUser.login, this.message.currentFilter, IhmSettingsTarget.CAPACITY,
            true);

        // Update settings
        this.capa_subscription = this.settings_svc.currentCapa.subscribe(capaSettings => {

            this.capa_settings = capaSettings;
            this.bkp_settings = Object.assign({}, capaSettings); // create a copy

            // Default values
            this.capa_template_avg_name = "Your average VM";
            this.capa_template_avg_vcpu = 4;
            this.capa_template_avg_vram = 4096;
            this.capa_template_avg_vdisk = 102400;

            // Get avg VM data
            this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.CAPA_VM)
                .subscribe(
                    data => {
                        let vm_data = data;
                        if (vm_data != null) {
                            for (let vm of vm_data) {
                                if (vm.ID == '--new-vm--') {
                                    this.capa_template_avg_vcpu = vm.CPUTHREADNB;
                                    this.capa_template_avg_vram = vm.RAMCAP;
                                    this.capa_template_avg_vdisk = vm.DISKCAP;
                                    break;
                                }
                            }
                        }
                        this.setTemplate(false); // update templates info
                    }
                );

        });
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.capa_subscription !== undefined) {
            this.capa_subscription.unsubscribe();
        }
        //console.log('destroyed');
    }

    /**
     * 
     */
    setTemplate(change: boolean): void {

        let template_id = Number(this.capa_settings.p6);

        switch (template_id) {
            case 0:
                this.capa_template_name = this.capa_template_avg_name;
                this.capa_template_vcpu = this.capa_template_avg_vcpu;
                this.capa_template_vram = this.capa_template_avg_vram;
                this.capa_template_vdisk = this.capa_template_avg_vdisk;
                break;
            case 1:
                this.capa_template_name = this.capa_settings.p7;
                this.capa_template_vcpu = Number(this.capa_settings.p8);
                this.capa_template_vram = Number(this.capa_settings.p9);
                this.capa_template_vdisk = Number(this.capa_settings.p10);
                break;
            case 2:
                this.capa_template_name = this.capa_settings.p11;
                this.capa_template_vcpu = Number(this.capa_settings.p12);
                this.capa_template_vram = Number(this.capa_settings.p13);
                this.capa_template_vdisk = Number(this.capa_settings.p14);
                break;
            case 3:
                this.capa_template_name = this.capa_settings.p15;
                this.capa_template_vcpu = Number(this.capa_settings.p16);
                this.capa_template_vram = Number(this.capa_settings.p17);
                this.capa_template_vdisk = Number(this.capa_settings.p18);
                break;
            default:
                this.capa_template_name = this.capa_template_avg_name;
                this.capa_template_vcpu = this.capa_template_avg_vcpu;
                this.capa_template_vram = this.capa_template_avg_vram;
                this.capa_template_vdisk = this.capa_template_avg_vdisk;
                break;
        }

        if (change) {
            if (this.capa_settings.user == '')
                this.capa_settings.user = this.currentUser.login;
            if (this.capa_settings.filter == '')
                this.capa_settings.filter = this.message.currentFilter;

            this.update_button = true;
        }

    }

    /**
     * 
     */
    checkCapaSettings(element: HTMLInputElement): void {

        // Format value
        switch (element.id) {
            case 'capa_template_name':
                element.value = this.checkTemplateName(element.value);
                break;
            default:
                if (element.id != "capa_p2")
                    element.value = element.value.replace('.', '').replace(',', ''); // FIX replace decimal symbol
                break;
        }

        // NO SAVE
        if (element.value.trim() == "" ||
            (element.id == "capa_p5" && Number(element.value) < 0) ||
            (element.id == "capa_p20" && Number(element.value) < 0) ||
            (element.id != "capa_p5" && element.id != "capa_p20" && element.id != "capa_template_name" && Number(
                element.value) <= 0) ||
            (element.id != "capa_p2" && element.id != "capa_template_name" && !Number.isInteger(Number(element
                .value)))
        ) {

            // Restore old value
            switch (element.id) {

                case "capa_p1":
                    this.capa_settings.p1 = this.bkp_settings.p1;
                    break;
                case "capa_p2":
                    this.capa_settings.p2 = this.bkp_settings.p2;
                    break;
                case "capa_p3":
                    this.capa_settings.p3 = this.bkp_settings.p3;
                    break;
                case "capa_p5":
                    this.capa_settings.p5 = this.bkp_settings.p5;
                    break;
                case "capa_p20":
                    this.capa_settings.p20 = this.bkp_settings.p20;
                    break;

                case "capa_template_name":
                    switch (Number(this.capa_settings.p6)) {
                        case 1:
                            this.capa_settings.p7 = this.bkp_settings.p7;
                            this.capa_template_name = this.bkp_settings.p7;
                            break;
                        case 2:
                            this.capa_settings.p11 = this.bkp_settings.p11;
                            this.capa_template_name = this.bkp_settings.p11;
                            break;
                        case 3:
                            this.capa_settings.p15 = this.bkp_settings.p15;
                            this.capa_template_name = this.bkp_settings.p15;
                            break;
                    }
                    break;
                case "capa_template_vcpu":
                    switch (Number(this.capa_settings.p6)) {
                        case 1:
                            this.capa_settings.p8 = this.bkp_settings.p8;
                            this.capa_template_vcpu = Number(this.bkp_settings.p8);
                            break;
                        case 2:
                            this.capa_settings.p12 = this.bkp_settings.p12;
                            this.capa_template_vcpu = Number(this.bkp_settings.p12);
                            break;
                        case 3:
                            this.capa_settings.p16 = this.bkp_settings.p16;
                            this.capa_template_vcpu = Number(this.bkp_settings.p16);
                            break;
                    }
                    break;
                case "capa_template_vram":
                    switch (Number(this.capa_settings.p6)) {
                        case 1:
                            this.capa_settings.p9 = this.bkp_settings.p9;
                            this.capa_template_vram = Number(this.bkp_settings.p9);
                            break;
                        case 2:
                            this.capa_settings.p13 = this.bkp_settings.p13;
                            this.capa_template_vram = Number(this.bkp_settings.p13);
                            break;
                        case 3:
                            this.capa_settings.p17 = this.bkp_settings.p17;
                            this.capa_template_vram = Number(this.bkp_settings.p17);
                            break;
                    }
                    break;
                case "capa_template_vdisk":
                    switch (Number(this.capa_settings.p6)) {
                        case 1:
                            this.capa_settings.p10 = this.bkp_settings.p10;
                            this.capa_template_vdisk = Number(this.bkp_settings.p10);
                            break;
                        case 2:
                            this.capa_settings.p14 = this.bkp_settings.p14;
                            this.capa_template_vdisk = Number(this.bkp_settings.p14);
                            break;
                        case 3:
                            this.capa_settings.p18 = this.bkp_settings.p18;
                            this.capa_template_vdisk = Number(this.bkp_settings.p18);
                            break;
                    }
                    break;
            }

        } else {
            // SAVE

            // Checking template
            if (element.id.startsWith("capa_template")) {
                switch (element.id) {
                    case "capa_template_name":
                        switch (Number(this.capa_settings.p6)) {
                            case 1:
                                this.capa_settings.p7 = this.capa_template_name;
                                break;
                            case 2:
                                this.capa_settings.p11 = this.capa_template_name;
                                break;
                            case 3:
                                this.capa_settings.p15 = this.capa_template_name;
                                break;
                        }
                        break;
                    case "capa_template_vcpu":
                        switch (Number(this.capa_settings.p6)) {
                            case 1:
                                this.capa_settings.p8 = this.capa_template_vcpu.toString();
                                break;
                            case 2:
                                this.capa_settings.p12 = this.capa_template_vcpu.toString();
                                break;
                            case 3:
                                this.capa_settings.p16 = this.capa_template_vcpu.toString();
                                break;
                        }
                        break;
                    case "capa_template_vram":
                        switch (Number(this.capa_settings.p6)) {
                            case 1:
                                this.capa_settings.p9 = this.capa_template_vram.toString();
                                break;
                            case 2:
                                this.capa_settings.p13 = this.capa_template_vram.toString();
                                break;
                            case 3:
                                this.capa_settings.p17 = this.capa_template_vram.toString();
                                break;
                        }
                        break;
                    case "capa_template_vdisk":
                        switch (Number(this.capa_settings.p6)) {
                            case 1:
                                this.capa_settings.p10 = this.capa_template_vdisk.toString();
                                break;
                            case 2:
                                this.capa_settings.p14 = this.capa_template_vdisk.toString();
                                break;
                            case 3:
                                this.capa_settings.p18 = this.capa_template_vdisk.toString();
                                break;
                        }
                        break;
                }
            }

            if (this.capa_settings.user == '')
                this.capa_settings.user = this.currentUser.login;
            if (this.capa_settings.filter == '')
                this.capa_settings.filter = this.message.currentFilter;

            // Show update button
            this.update_button = true;

        }

    }

    /**
     * Push changes state to parent
     * & store settings to database
     */
    update(): void {
        this.pushReload();
        this.settings_svc.saveIhmSettings(this.capa_settings);
        this.update_button = false;
    }


    /**
     * 
     */
    private checkTemplateName(name: string): string {
        //Remove accent
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        for (var i = 0; i < accent.length; i++) {
            name = name.replace(accent[i], noaccent[i]);
        }

        //Remove specs chars
        name = name.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:.;?, ]/g, "_");

        return name;
    }

}
