import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    Message
} from '@app/model';

import {
    ManagementService,
    ShareService
} from '@app/services';

import {
    GraphCounterComponent
} from '../aws/graph-counter/graph-counter.component';
import {
    CloudgraphcounterDirective
} from '../../directives/cloudgraphcounter.directive';

import {
    GraphRegionComponent
} from '../aws/graph-region/graph-region.component';
import {
    CloudgraphregionDirective
} from '../../directives/cloudgraphregion.directive';

import {
    ConsumptionProfileComponent
} from '../aws/consumption-profile/consumption-profile.component';
import {
    CloudconsumptionprofileDirective
} from '../../directives/cloudconsumptionprofile.directive';

import {
    DashboardCloudComponent
} from '../dashboard-cloud/dashboard-cloud.component';

import {
    OpportunitiesCloudComponent
} from '../opportunities-cloud/opportunities-cloud.component';


@Component({
    selector: 'app-gcp',
    templateUrl: './gcp.component.html',
    styleUrls: ['./gcp.component.css']
})
export class GcpComponent implements AfterViewChecked, OnInit {

    isDcscope = true;
    isCo2scope = false;

    @ViewChild('dashboardCloud') dashboard: DashboardCloudComponent;
    @ViewChild('opportunitiesCloud') opportunities: OpportunitiesCloudComponent;

    @ViewChild(CloudgraphcounterDirective) addCloudGraphCounter: CloudgraphcounterDirective;
    @ViewChild(CloudgraphregionDirective) addCloudGraphRegion: CloudgraphregionDirective;
    @ViewChild(CloudconsumptionprofileDirective) addCloudConsumptionProfile: CloudconsumptionprofileDirective;

    message: Message;

    viewContainerRef: any;

    isDataReload: boolean = false;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private mgt_svc: ManagementService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {

        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.message.cloudProvider = 'gcp';

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;

        this.mgt_svc.getRegions('gcp').pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.message.isValidGcp = true;
                }
            }
        );
    }

    ngAfterViewChecked(): void {

        this.cd.detectChanges();
    }

    loadRegion(): void {

        this.isDataReload = true;
        setTimeout(() => this.isDataReload = false, 100);
    }

    // COUNTER GRAPH
    loadGraphCounter(): void {
        setTimeout(() => this.loadCloudGraphCounter(), 100);
    }

    // REGION GRAPH
    loadGraphRegion(): void {
        setTimeout(() => this.loadCloudGraphRegion(), 100);
    }

    // CONSUMPTION PROFILE
    loadConsumptionProfile(): void {
        setTimeout(() => this.loadCloudConsumptionProfile(), 100);
    }

    private loadCloudGraphCounter(): void {

        if (this.addCloudGraphCounter != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GraphCounterComponent);
            const viewContainerRef = this.addCloudGraphCounter.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCloudGraphRegion(): void {

        if (this.addCloudGraphRegion != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GraphRegionComponent);
            const viewContainerRef = this.addCloudGraphRegion.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCloudConsumptionProfile(): void {

        if (this.addCloudConsumptionProfile != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                ConsumptionProfileComponent);
            const viewContainerRef = this.addCloudConsumptionProfile.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

}
