import {
    Component,
    OnInit
} from '@angular/core';

import * as Highcharts from 'highcharts';

declare var require: any;


@Component({
    selector: 'app-boxplot',
    templateUrl: './boxplot.component.html',
    styleUrls: ['./boxplot.component.css']
})
export class BoxplotComponent implements OnInit {

    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.test();
    };

    options: Highcharts.Options = {
        chart: {
            height: '250px',
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            type: 'bar' // inverse
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        xAxis: {
            categories: [
                'idle<br><span style="font-size:12px;font-weight:normal">(top ' + 80 + '%)</span>',
                'oversized<br><span style="font-size:12px;font-weight:normal">(top ' + 48 + '%)</span>',
                'undersized<br><span style="font-size:12px;font-weight:normal">(top ' + 70 + '%)</span>'
            ],
            crosshair: true,
            labels: {
                style: {
                    color: 'black',
                    fontSize: '12px',
                    fontWeight: 'bold'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: ''
            }
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            }
        },
        series: [{
            name: 'Behavior',
            type: 'boxplot',
            data: [
                [6, 8, 10, 15, 25],
                [15, 40, 65, 70, 75],
                [0, 0, 1, 1, 3]
            ],
            tooltip: {
                headerFormat: '',
            }
        }]
    }

    /**
     * 
     */
    constructor() {}

    /**
     * 
     */
    ngOnInit(): void {}

    /**
     * 
     */
    private test(): void {

        // Push customer data to chart
        this.chart.addSeries({
            type: 'scatter',
            name: "customer",
            cursor: 'pointer',
            color: 'black',
            data: [15, 63, 1],
            enableMouseTracking: false
        });

        // Update idle point color
        this.chart.series[1].data[0].update({
            label: String(this.chart.series[1].data[0].y),
            marker: {
                enabled: true,
                fillColor: 'red',
                radius: 5,
                states: {
                    hover: {
                        fillColor: 'red'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                color: 'red',
                align: 'left',
                style: {
                    fontSize: "16px"
                },
                formatter: function() {
                    return this.point.options.label;
                }
            }
        });

        // Update oversized point color
        this.chart.series[1].data[1].update({
            label: String(this.chart.series[1].data[1].y),
            marker: {
                enabled: true,
                fillColor: 'orange',
                radius: 5,
                states: {
                    hover: {
                        fillColor: 'orange'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                color: 'orange',
                align: 'left',
                style: {
                    fontSize: "16px"
                },
                formatter: function() {
                    return this.point.options.label;
                }
            }
        });

        // Update undersized point color
        this.chart.series[1].data[2].update({
            label: String(this.chart.series[1].data[2].y),
            marker: {
                enabled: true,
                fillColor: 'red',
                radius: 5,
                states: {
                    hover: {
                        fillColor: 'red'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                color: 'red',
                align: 'left',
                style: {
                    fontSize: "16px"
                },
                formatter: function() {
                    return this.point.options.label;
                }
            },
        });

    }
}
