<clr-tabs>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.ALL)" (click)="setTab(instancetype.ALL)">ALL IT</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.ALL)"></clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.VMWARE)" (click)="setTab(instancetype.VMWARE)">VMWARE</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.VMWARE)">
            <app-inframenu></app-inframenu>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.AWS)" (click)="setTab(instancetype.AWS)">AWS</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.AWS)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.AZURE)" (click)="setTab(instancetype.AZURE)">AZURE</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.AZURE)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.GCP)" (click)="setTab(instancetype.GCP)">GCP</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.GCP)">
    </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.KUBERNETES)" (click)="setTab(instancetype.KUBERNETES)">KUBERNETES</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.KUBERNETES)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.SCAPHANDRE)" (click)="setTab(instancetype.SCAPHANDRE)">SCAPHANDRE</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.SCAPHANDRE)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.XCLARITY)" (click)="setTab(instancetype.XCLARITY)">XCLARITY</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.XCLARITY)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.OPENMANAGE)" (click)="setTab(instancetype.OPENMANAGE)">OPENMANAGE</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.OPENMANAGE)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.ONEVIEW)" (click)="setTab(instancetype.ONEVIEW)">ONEVIEW</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.ONEVIEW)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.IPMI)" (click)="setTab(instancetype.IPMI)">IPMI</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.IPMI)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.NETWORK)" (click)="setTab(instancetype.NETWORK)">NETWORK</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.NETWORK)">
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="!checkTab(instancetype.STORAGE)" (click)="setTab(instancetype.STORAGE)">STORAGE</button>
        <clr-tab-content *clrIfActive="checkActive(instancetype.STORAGE)">
        </clr-tab-content>
    </clr-tab>
</clr-tabs>
<div style="padding-bottom:20px;"></div>
