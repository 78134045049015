<div class="clr-row" style="width: 100%">
    <div class="clr-col-8">
        <h3 style="margin: 5px;">
            Threat center

            <span class="label label-blue" *ngIf="blocklistVersion > 0">
                database version
                <span class="badge">{{ blocklistVersion * 1000 | date: 'dd/MM/yyyy - HH:mm'}}</span>
            </span>

            <span *ngIf="isLoading" style="margin-left: 5px; margin-top: 2px;">
                <clr-spinner [clrSmall]="true" style="top: 2px;">Loading ...</clr-spinner>
            </span>
        </h3>
    </div>
    <div class="clr-col-4">
        <div class="btn-group btn-primary" style="float: right;">
            <button (click)="reloadData()" class="btn btn-primary btn-sm"><cds-icon shape="refresh"></cds-icon> reload</button>
            <button (click)="updateBlocklist()" class="btn btn-primary btn-sm"><cds-icon shape="download-cloud"></cds-icon> update blocklist</button>
            <button (click)="clearData()" class="btn btn-danger btn-sm"><cds-icon shape="trash"></cds-icon> clear</button>
        </div>
    </div>
</div>

<app-netscope-flows-datagrid  [temporalParameter]="temporalParameter" [flowDetails]="flowDetails" [showThreats]="true" [hidePacketsExchanged]="true" [hideTotalBytes]="true" [perPortRow]="false"></app-netscope-flows-datagrid>
