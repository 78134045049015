<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
        		<div class="card-header">
                		<span style="color:grey;font-size:16px;">
                        		Settings selection
                		</span>
			</div>
        		<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
						<div class="clr-control-container">
							<div class="clr-select-wrapper">
								<select class="clr-select" [(ngModel)]="cons_selected" (ngModelChange)="loadData()">
									<option>monthly</option>
									<option>yearly</option>
									<option>overall</option>
								</select>
							</div>
							<span class="clr-subtext">select a consumption profile</span>
						</div>
					</div>
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="cons_selected == 'monthly' && !isReady2">
						<div class="clr-control-container">
                                                        <div class="clr-select-wrapper">
                                                                <select class="clr-select"
                                                                        [(ngModel)]="month_selected"
                                                                        (ngModelChange)="loadData()">
                                                                        <option *ngFor="let month of months">{{month.name}}</option>
                                                                </select>
                                                        </div>
                                                        <span class="clr-subtext">select month to display</span>
                                                </div>
                                        </div>
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="cons_selected == 'yearly' && !isReady2">
						<div class="clr-control-container">
                                                        <div class="clr-select-wrapper">
                                                                <select class="clr-select"
                                                                        [(ngModel)]="year_selected"
                                                                        (ngModelChange)="loadData()">
                                                                        <option *ngFor="let year of years">{{year}}</option>
                                                                </select>
                                                        </div>
                                                        <span class="clr-subtext">select year to display</span>
                                                </div>
                                        </div>
					<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="!isReady2">
						<clr-combobox-container *ngIf="message.cloudProvider == 'aws'">
							<clr-combobox
								[(ngModel)]="instance_selected"
								(ngModelChange)="loadData()"
								(clrOpenChange)="initInstanceList()"
								placeholder="select an instance"
								required>
							  <clr-options>
								<clr-option *clrOptionItems="let instance of instances; field:'fullName'" [clrValue]="instance">
									{{instance.fullName}}
								</clr-option>
							  </clr-options>
							</clr-combobox>
							<clr-control-helper>enter an instance name or an instance id</clr-control-helper>
							<clr-control-error>instance not found</clr-control-error>
						</clr-combobox-container>
						<clr-combobox-container *ngIf="message.cloudProvider == 'azure' || message.cloudProvider == 'gcp'">
							<clr-combobox 
								[(ngModel)]="instance_selected"
								(ngModelChange)="loadData()"
								(clrOpenChange)="initInstanceList()"
								placeholder="select an instance"
								required>
							  <clr-options>
								<clr-option *clrOptionItems="let instance of instances; field:'name'" [clrValue]="instance">
									{{instance.name}}
								</clr-option>
							  </clr-options>
							</clr-combobox>
							<clr-control-helper>enter an instance name</clr-control-helper>
							<clr-control-error>instance not found</clr-control-error>
						</clr-combobox-container>
					</div>
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="!isReady2">
						<div class="clr-control-container">
							<div class="clr-select-wrapper">
								<select class="clr-select" [(ngModel)]="counter_selected" (ngModelChange)="reloadCons()">
									<option>cpu usage</option>
									<option *ngIf="message.cloudProvider != 'gcp'">credit usage</option>
									<option *ngIf="message.cloudProvider != 'gcp'">credit balance</option>
									<option *ngIf="message.cloudProvider == 'aws'">credit surplus charged</option>
									<!--<option>disk read</option>
									<option>disk write</option>-->
									<option>network in</option>
									<option>network out</option>
									<option *ngIf="message.cloudProvider == 'azure'">ram usage</option>
								</select>
							</div>
							<span class="clr-subtext">select a counter</span>
						</div>
					</div>
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="isRollup && !isReady2">
						<div class="clr-control-container">
							<div class="clr-select-wrapper">
								<select class="clr-select" [(ngModel)]="rollup_selected" (ngModelChange)="reloadCons()">
									<option>average</option>
									<option>max</option>
									<option>min</option>
								</select>
							</div>
							<span class="clr-subtext">select a rollup</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
			<div class="card-block">
				<highcharts-chart
					id="spec-cloud-provider-aws-profilegraph"
					[Highcharts] = "highcharts"
					[options] = "chartOptions"
					[constructorType]="'chart'"
					style = "width: 100%; height: 100%; display: block;" *ngIf="isReady">
				</highcharts-chart>
				<div class="datagrid-limit-height" *ngIf="isReady2">
					<clr-datagrid>
						<clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'values'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Values</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'avgcpu'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Avg cpu (%)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'mincpu'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Min cpu (%)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'maxcpu'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Max cpu (%)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'avgionetin'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Avg net in (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'minionetin'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Min net in (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'maxionetin'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Max net in (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'avgionetout'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Avg net out (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'minionetout'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Min net out (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'maxionetout'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Max net out (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'avgdiskread'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Avg disk read (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'mindiskread'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Min disk read (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'maxdiskread'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Max disk read (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'avgdiskwrite'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Avg disk write (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'mindiskwrite'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Min disk write (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'maxdiskwrite'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Max disk write (bytes)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'avgram'" [clrDgSortOrder]="dataOrder" *ngIf="message.cloudProvider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Avg ram (%)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'minram'" [clrDgSortOrder]="dataOrder" *ngIf="message.cloudProvider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Min ram (%)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'maxram'" [clrDgSortOrder]="dataOrder" *ngIf="message.cloudProvider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Max ram (%)</ng-container></clr-dg-column>
						<clr-dg-row *clrDgItems="let instance of overall" [clrDgItem]="instance">
							<clr-dg-cell>{{instance.id}}</clr-dg-cell>
							<clr-dg-cell>{{instance.name}}</clr-dg-cell>
							<clr-dg-cell>{{instance.values}}</clr-dg-cell>
							<clr-dg-cell>{{instance.avgcpu}}</clr-dg-cell>
							<clr-dg-cell>{{instance.mincpu}}</clr-dg-cell>
							<clr-dg-cell>{{instance.maxcpu}}</clr-dg-cell>
							<clr-dg-cell>{{instance.avgionetin}}</clr-dg-cell>
							<clr-dg-cell>{{instance.minionetin}}</clr-dg-cell>
							<clr-dg-cell>{{instance.maxionetin}}</clr-dg-cell>
							<clr-dg-cell>{{instance.avgionetout}}</clr-dg-cell>
							<clr-dg-cell>{{instance.minionetout}}</clr-dg-cell>
							<clr-dg-cell>{{instance.maxionetout}}</clr-dg-cell>
							<clr-dg-cell>{{instance.avgdiskread}}</clr-dg-cell>
							<clr-dg-cell>{{instance.mindiskread}}</clr-dg-cell>
							<clr-dg-cell>{{instance.maxdiskread}}</clr-dg-cell>
							<clr-dg-cell>{{instance.avgdiskwrite}}</clr-dg-cell>
							<clr-dg-cell>{{instance.mindiskwrite}}</clr-dg-cell>
							<clr-dg-cell>{{instance.maxdiskwrite}}</clr-dg-cell>
							<clr-dg-cell *ngIf="message.cloudProvider == 'azure'">{{instance.avgram}}</clr-dg-cell>
							<clr-dg-cell *ngIf="message.cloudProvider == 'azure'">{{instance.minram}}</clr-dg-cell>
							<clr-dg-cell *ngIf="message.cloudProvider == 'azure'">{{instance.maxram}}</clr-dg-cell>
						</clr-dg-row>
						<clr-dg-footer>
							<clr-dg-pagination #pagination [clrDgPageSize]="10">
								<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
									entries per page
								</clr-dg-page-size>
								{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
							</clr-dg-pagination>
						</clr-dg-footer>
					</clr-datagrid>
				</div>
				<div style="text-align: center;">
					<span *ngIf="!isReady && !isReady2">{{state}}</span>
				</div>
			</div>
		</div>
	</div>
 </div>
