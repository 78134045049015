<clr-tabs *ngIf="isRoot">
	<clr-tab>
        <button clrTabLink (click)="callUserMgt()">USER & GROUP MANAGEMENT</button>
		<clr-tab-content *clrIfActive="false">
			<ng-template load-usermgt-root></ng-template>
        </clr-tab-content>
	</clr-tab>
	<clr-tab *ngIf="isDcscope">
        <button clrTabLink (click)="callRoleMgt()">ROLE MANAGEMENT</button>
		<clr-tab-content *clrIfActive="false">
			<ng-template load-rolemgt></ng-template>
        </clr-tab-content>
	</clr-tab>
	<clr-tab>
        <button clrTabLink>LDAP SETTINGS</button>
		<clr-tab-content *clrIfActive="false">
			<app-ldap></app-ldap>
		</clr-tab-content>
	</clr-tab>
</clr-tabs>
<ng-template load-usermgt-noroot *ngIf="!isRoot"></ng-template>
