<table id="reco-resizing-disk-list" datatable [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th>VM/ZOMBIE</th>
			<th>ACTION</th>
		</tr>
	</thead>
	<tbody>
    		<tr *ngFor="let reco of disk_model">
			<td>{{reco.name}}</td>
			<td>{{reco.reco_behavior}}</td>
		</tr>
	</tbody>
</table>
