import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    GetFlowDetailsResponse,
    NetscopeService,
    SuccessGetFlowDetailsResponse,
    TemporalParameter
} from "@app/services";

@Component({
    selector: 'app-expandable-rows',
    templateUrl: './expandable-rows.component.html',
    styleUrls: ['./expandable-rows.component.css']
})
export class ExpandableRowsComponent implements OnInit {

    @Input() flowDetail;
    @Input() temporalParameter: TemporalParameter;
    @Input() bidirectional = false;
    loading: boolean;

    ports = []

    constructor(private netscopeService: NetscopeService) {}

    ngOnInit(): void {
        this.loading = true;

        if (this.flowDetail !== undefined && this.flowDetail.temporalParameter !== undefined) {
            this.temporalParameter = this.flowDetail.temporalParameter;
        }

        if (this.flowDetail !== undefined) {
            let srcIp = this.flowDetail.src_address;
            let dstIp = this.flowDetail.dst_address;
            this.netscopeService.getFlowDetails(srcIp, dstIp, this.temporalParameter, this.bidirectional).subscribe((flowDetailsResponse:
                GetFlowDetailsResponse) => {

                let flowDetails;
                if (flowDetailsResponse instanceof SuccessGetFlowDetailsResponse) {
                    flowDetails = flowDetailsResponse.flowDetails;
                }

                // @ts-ignore
                this.ports = flowDetails.ports;

                // Fix transportProtocols property in ports. They are in the form "17 TCP", we will transform them in the following form:
                // {protoNumber: 17, protoLabel: "TCP"}
                for (let port of flowDetails.ports) {
                    port.transport_protocols = port.transport_protocols.map((proto) => {
                        let protoNumber = -1;
                        let protoLabel = "unknown";

                        let protoParts = proto.split(" ");
                        if (protoParts.length == 2) {
                            protoNumber = Number.parseInt(protoParts[0]);
                            protoLabel = protoParts[1];
                        }

                        return {
                            protoNumber: protoNumber,
                            protoLabel: protoLabel,
                        }
                    });
                }

                setTimeout(() => {
                    this.loading = false;
                }, 300);
            })
        }
    }

}

