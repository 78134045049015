import {
    Component,
    ChangeDetectorRef,
    ComponentFactoryResolver,
    ComponentRef,
    OnInit,
    AfterViewInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import * as moment from 'moment';

import * as Highcharts from "highcharts/highstock";
import {
    Options
} from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import {
    AccountService,
    AuditService,
    ShareService
} from '@app/services';
import {
    MetaAlert,
    Message,
    User,
    Watcher
} from '@app/model';

import {
    AlertingalertComponent
} from '@app/alerting/alertingalert/alertingalert.component';
import {
    AlertingalertDirective
} from '@app/directives/alertingalert.directive';

import {
    AlertingnotificationComponent
} from '@app/alerting/alertingnotification/alertingnotification.component';
import {
    AlertingnotificationDirective
} from '@app/directives/alertingnotification.directive';

import {
    AlertingtableComponent
} from '@app/alerting/alertingtable/alertingtable.component';
import {
    AlertingtablesDirective
} from '@app/directives/alertingtables.directive';

import {
    AlertingtablestateComponent
} from '@app/alerting/alertingtablestate/alertingtablestate.component';
import {
    AlertingtablestateDirective
} from '@app/directives/alertingtablestate.directive';

import {
    AlertingtablerunComponent
} from '@app/alerting/alertingtablerun/alertingtablerun.component';
import {
    AlertingtablerunDirective
} from '@app/directives/alertingtablerun.directive';

import {
    AlertingwatcherComponent
} from '@app/alerting/alertingwatcher/alertingwatcher.component';
import {
    AlertingwatcherDirective
} from '@app/directives/alertingwatcher.directive';

var componentRef: ComponentRef < any > ;


@Component({
    selector: 'app-alerting',
    templateUrl: './alerting.component.html',
    styleUrls: ['./alerting.component.css']
})
export class AlertingComponent implements AfterViewInit, OnInit {

    @ViewChild(AlertingalertDirective) addAlert: AlertingalertDirective;
    @ViewChild(AlertingnotificationDirective) addNotification: AlertingnotificationDirective;
    @ViewChild(AlertingtablesDirective) addTable: AlertingtablesDirective;
    @ViewChild(AlertingtablestateDirective) addTableState: AlertingtablestateDirective;
    @ViewChild(AlertingtablerunDirective) addTableRun: AlertingtablerunDirective;
    @ViewChild(AlertingwatcherDirective) addWatcher: AlertingwatcherDirective;

    message: Message;

    title_detail: string = '';

    title_state: string = '';

    showAlert: boolean = false;

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    isReady: boolean = false;

    showElement: boolean = false;

    isHistoryDay: boolean = false;

    history_day: string = '';

    private currentUser: User;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private authentication_svc: AccountService,
        private message_svc: ShareService,
        private audit_svc: AuditService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
    }

    ngAfterViewInit(): void {
        this.setDashboard();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    setDashboard(): void {

        this.showAlert = false;
        this.showElement = false;
        this.isHistoryDay = false;
    }

    setAlerts(): void {

        this.showAlert = false;
        this.showElement = false;
        this.isHistoryDay = false;
        setTimeout(() => this.loadAlert(), 100);
    }

    setNotification(): void {

        this.showAlert = false;
        this.showElement = false;
        this.isHistoryDay = false;
        setTimeout(() => this.loadNotification(), 100);
    }

    setHistory(): void {

        this.showAlert = false;
        this.showElement = false;
        let seriesOptions = [];
        this.audit_svc.getAlertHistory(this.currentUser.login).pipe(first()).subscribe(
            data => {
                let counterData = [];
                for (var i = 0; i < data.length; i++) {
                    counterData[i] = [];
                    counterData[i][0] = data[i].timeago;
                    counterData[i][1] = data[i].nb;
                }

                seriesOptions[0] = {
                    type: 'line',
                    name: 'alert number',
                    cursor: 'pointer',
                    color: '#5EA11A',
                    lineWidth: 2,
                    marker: {
                        enabled: true,
                        radius: 6,
                        fillColor: '#ED7427'
                    },
                    tooltip: {
                        valueDecimals: 0
                    },
                    point: {
                        events: {
                            click: function(e) {
                                const p = e.point;
                                this.getHistoryDay(p);
                            }.bind(this)
                        }
                    },
                    data: counterData
                };

                this.loadGraphHistory(seriesOptions);
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    getAlertDetail(state: string): void {

        this.loadWatcher();
        switch (state) {
            case "rt-critical":
                this.message.isAlertRt = true;
                this.title_detail = 'REAL TIME';
                this.title_state = 'CRITICAL';
                break;
            case "rt-warning":
                this.message.isAlertRt = true;
                this.title_detail = 'REAL TIME';
                this.title_state = 'WARNING';
                break;
            case "ft-critical":
                this.message.isAlertRt = false;
                this.title_detail = 'FILTER';
                this.title_state = 'CRITICAL';
                break;
            case "ft-warning":
                this.message.isAlertRt = false;
                this.title_detail = 'FILTER';
                this.title_state = 'WARNING';
                break;
        }

        this.message.alertTriggeredState = state;
        this.showAlert = true;
        this.showElement = false;
        setTimeout(() => this.loadStateTable(), 100);
    }

    getRunElement(granularity: string): void {

        if (granularity == "min") {
            this.message.isAlertRt = true;
            this.title_detail = 'REAL TIME';
        } else {
            this.message.isAlertRt = false;
            this.title_detail = 'FILTER';
        }

        this.showAlert = false;
        this.showElement = true;
        setTimeout(() => this.loadRunTable(), 100);
    }

    private loadAlert(): void {

        if (this.addAlert != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AlertingalertComponent);
            const viewContainerRef = this.addAlert.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadNotification(): void {

        if (this.addNotification != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                AlertingnotificationComponent);
            const viewContainerRef = this.addNotification.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadGraphHistory(data: any): void {

        this.chartOptions = {
            chart: {
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        align: 'center',
                        y: -15
                    }
                },
                height: '20%',
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            series: data
        };
        this.isReady = true;
    }

    private getHistoryDay(p: any): void {

        this.isHistoryDay = true;
        this.history_day = moment.unix(p.x / 1000).format('MMMM Do YYYY');
        this.message.alertHistoryTime = p.x;
        setTimeout(() => this.loadHistoryTable(), 100);
    }

    private loadHistoryTable(): void {

        if (this.addTable != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AlertingtableComponent);
            const viewContainerRef = this.addTable.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadStateTable(): void {

        if (this.addTableState != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                AlertingtablestateComponent);
            const viewContainerRef = this.addTableState.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadRunTable(): void {

        if (this.addTableRun != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                AlertingtablerunComponent);
            const viewContainerRef = this.addTableRun.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadWatcher(): void {
        if (this.addWatcher != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                AlertingwatcherComponent);
            const viewContainerRef = this.addWatcher.viewContainerRef;
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }
}
