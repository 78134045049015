import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-cluster-distribution10]'
})
export class Clusterdistribution10Directive {

    constructor(public viewContainerRef10: ViewContainerRef) {}

}
