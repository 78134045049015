<clr-modal [(clrModalOpen)]="supervision_modal" [clrModalClosable]="true" [clrModalSize]="'lg'">
    <h3 class="modal-title">Services management</h3>
    <div class="modal-body" style="min-height:100px;">
        <div class="datagrid-limit-height" >
            <clr-datagrid >
                <clr-dg-column [clrDgField]="'service'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'description'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Description</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'status'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Status</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'action'"><ng-container *clrDgHideableColumn="{hidden: false}">Action</ng-container></clr-dg-column>
                <clr-dg-row *clrDgItems="let service of serviceToMonitored" [clrDgItem]="service">
                    <clr-dg-cell>{{service.name}}</clr-dg-cell>
                    <clr-dg-cell>{{service.description}}</clr-dg-cell>
                    <clr-dg-cell>{{service.status}} : 
                        <div *ngIf="service.status == 'inactive'" style="background-image:radial-gradient(#FF0000,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
                        <div *ngIf="service.status == 'unknown'" style="background-image:radial-gradient(#FFD700,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
                        <div *ngIf="service.status == 'running'" style="background-image:radial-gradient(#00FF00,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
                    </clr-dg-cell>
                    <clr-dg-cell>
                        <cds-icon shape="stop" title="start" ngClass="addLink" (click)="actionOnService(service, 'stop')" *ngIf="service.withAction"></cds-icon>&nbsp;&nbsp;&nbsp;
                        <cds-icon shape="play" title="stop" ngClass="addLink" (click)="actionOnService(service, 'start')" *ngIf="service.withAction"></cds-icon>&nbsp;&nbsp;&nbsp;
                        <cds-icon shape="replay-one" title="restart" ngClass="addLink" (click)="actionOnService(service, 'restart')" *ngIf="service.withAction"></cds-icon>&nbsp;&nbsp;&nbsp;
                    </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                    <button class="btn btn-sm" style="float: right;" (click)="refreshServices()">REFRESH</button>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
</clr-modal>