import {
    Component,
    OnInit
} from '@angular/core';
import {
    User,
    Message
} from '@app/model';

import {
    AccountService,
    GreenitService,
    ShareService
} from '@app/services';

import {
    GreenitComponent
} from '../greenit.component';
import {
    GreenitTarget
} from '../greenit.enums';

import * as Highcharts from 'highcharts';


@Component({
    selector: 'app-greenitvm',
    templateUrl: './greenitvm.component.html',
    styleUrls: ['./greenitvm.component.css']
})
export class GreenitvmComponent implements OnInit {

    message: Message;

    private currentUser: User;

    private greenit_vmpower_data: any;
    private greenit_vmperhost_data: any;
    private greenit_vmpersocket_data: any;

    greenit_alert: boolean = false;

    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.chart.showLoading();
    };

    options: Highcharts.Options = {
        chart: {
            height: '250px',
            backgroundColor: 'rgba(255, 255, 255, 0.0)'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function() {
                    return Highcharts.dateFormat('%m-%Y', this.value);
                }
            },
        },
        yAxis: {
            min: 0,
            labels: {
                align: 'right',
                x: -10
            },
            title: {
                text: 'VM number'
            }
        },
        tooltip: {
            formatter: function() {
                return `<span style="color: ${this.color}">•</span> ${this.series.name}: <b>${this.y}</b>`;
            }
        }
    }

    /**
     * 
     */
    constructor(
        private authenticationService: AccountService,
        private greenit_svc: GreenitService,
        private message_svc: ShareService
    ) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        setTimeout(() => {
            //Inject perhost data
            this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter,
                GreenitTarget.FILTER_DC_VM_PERHOST).subscribe(
                data => {
                    this.greenit_vmperhost_data = data;

                    if (this.greenit_vmperhost_data.length == 0)
                        this.greenit_alert = true;

                    // set data
                    this.setVMPerhostData();
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );

            //Inject persocket data
            this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter,
                GreenitTarget.FILTER_DC_VM_PERSOCKET).subscribe(
                data => {
                    this.greenit_vmpersocket_data = data;

                    if (this.greenit_vmpersocket_data.length == 0)
                        this.greenit_alert = true;

                    // set data
                    this.setVMPersocketData();
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );

            //Inject vmpower data
            this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter,
                GreenitTarget.FILTER_DC_VM_POWER).subscribe(
                data => {
                    this.greenit_vmpower_data = data;

                    if (this.greenit_vmpower_data.length == 0)
                        this.greenit_alert = true;

                    // set data
                    this.setVMPowerData();
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        }, 100);
    }

    /**
     * 
     */
    private setVMPersocketData(): void {
        let times = [];
        let nbvms = [];

        for (let i in this.greenit_vmpersocket_data) {
            if (this.greenit_vmpersocket_data[i] != undefined) {
                let lastTime = this.formatLastTime(parseInt(this.greenit_vmpersocket_data[i].time));
                let lastNb = this.greenit_vmpersocket_data[i].nbvm;

                // Save the point
                times[i] = lastTime;
                nbvms[i] = Number(parseFloat(lastNb).toFixed(2));
            }
        }

        // Set categories
        this.chart.xAxis[0].setCategories(times);

        // Push to chart
        this.chart.addSeries({
            type: 'column',
            name: "VM number (per socket)",
            cursor: 'pointer',
            color: '#434348',
            //dashStyle: this.linestyle,
            data: nbvms
        });

        // Hide chart loading
        this.chart.hideLoading();
    }

    /**
     * 
     */
    private setVMPerhostData(): void {
        let times = [];
        let nbvms = [];

        for (let i in this.greenit_vmperhost_data) {
            if (this.greenit_vmperhost_data[i] != undefined) {
                let lastTime = this.formatLastTime(parseInt(this.greenit_vmperhost_data[i].time));
                let lastNb = this.greenit_vmperhost_data[i].nbvm;

                // Save the point
                times[i] = lastTime;
                nbvms[i] = Number(parseFloat(lastNb).toFixed(2));
            }
        }

        // Set categories
        this.chart.xAxis[0].setCategories(times);

        // Push to chart
        this.chart.addSeries({
            type: 'column',
            name: "VM number (per server)",
            cursor: 'pointer',
            color: '#7cb5ec',
            //dashStyle: this.linestyle,
            data: nbvms
        });

        // Hide chart loading
        this.chart.hideLoading();
    }

    /**
     * 
     */
    private setVMPowerData(): void {
        let times = [];
        let powers = [];

        for (let i in this.greenit_vmpower_data) {
            if (this.greenit_vmpower_data[i] != undefined) {
                let lastTime = this.formatLastTime(parseInt(this.greenit_vmpower_data[i].time));
                times[i] = lastTime;
                powers[i] = parseFloat(this.greenit_vmpower_data[i].power);

            }
        }

        // Add secondary Y-axis
        this.chart.addAxis({
            id: 'yaxis-2',
            opposite: true,
            title: {
                text: "Watts",
            },
        }, false);

        // Set categories
        this.chart.xAxis[0].setCategories(times);

        // Push to chart
        this.chart.addSeries({
            type: 'spline',
            name: "Watts (per VM)",
            yAxis: 'yaxis-2',
            cursor: 'pointer',
            color: '#80699B',
            //dashStyle: this.linestyle,
            data: powers
        });

        // Hide chart loading
        this.chart.hideLoading();
    }

    /**
     *
     */
    private formatLastTime(lastTime: number): number {
        //XXX in order to show last month number (first day of month minus a day)
        let tmp_time = lastTime - 24 * 3600 * 1000;

        //FIX 2nd day of month
        let tmp_date_day = new Date(lastTime).getDate();
        if (tmp_date_day == 2) {
            tmp_time = lastTime;
        }

        return tmp_time;
    }


}
