import {
    Component,
    EventEmitter,
    OnInit
} from '@angular/core';
import {
    ClrDatagridComparatorInterface
} from "@clr/angular";

import * as Highcharts from "highcharts/highstock";

import {
    GraphOnDemandService,
    JsonloaderService,
    ShareService
} from "@app/services";
import {
    ActivatedRoute
} from "@angular/router";
import {
    FetchResult,
    MetricItem,
    StorageOverviewService
} from "@app/services/storage-overview.service";
import {
    Observable
} from "rxjs";
import {
    RangeFilterComponent
} from "@app/storage-overview/range-filter/range-filter.component";
import {
    Chart,
    SeriesOptionsType
} from "highcharts";

class TotalComparator implements ClrDatagridComparatorInterface < any > {
    compare(a: any, b: any) {
        return (a.computeCost + a.storageCost) - (b.computeCost + b.storageCost);
    }
}

const roundTo = function(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
};

@Component({
    selector: 'app-storage-overview',
    templateUrl: './storage-overview-vm.component.html',
    styleUrls: ['./storage-overview-vm.component.css']
})
export class StorageOverviewVmComponent implements OnInit {

    Highcharts: typeof Highcharts = Highcharts;
    selectedVm: any;
    selectedDatastores = [];
    listedDatastores = [];
    listedVms = [];

    storageChartOptions: Highcharts.Options = this.generateDefaultHighchartsOptions("Number of Volumes");
    chart: Chart;
    updateStorageChartFlag = true;
    storageChartReady = false;
    onReady: EventEmitter < any > = new EventEmitter();
    secondTableShouldChange = new EventEmitter();

    options: any;

    winsFilter = new RangeFilterComponent();

    datastoresAreLoading: boolean = false;
    graphsAreLoading: boolean = false;
    totalComparator: any = new TotalComparator();
    opened: boolean = false;

    startTime = 0
    endTime = 2553270400000;

    minRange = this.startTime;
    maxRange = this.endTime;

    availableMetrics: MetricItem[] = [];
    selectedMetricName: string;

    displayGraph = true
    noDataMessage = ""

    constructor(public jsonLoaderService: JsonloaderService, private shareService: ShareService, public route:
        ActivatedRoute, public storageOverviewService: StorageOverviewService, public graphOnDemandService:
        GraphOnDemandService) {
        this.jsonLoaderService.currentJson.subscribe((json) => {
            this.listedVms = json.storageOverviewsVmData;

            this.shareService.currentMessage.subscribe((msg) => {
                // Change default time interval if specified in filter
                if (msg.minTimeFilter != 0) {
                    this.startTime = msg.minTimeFilter;
                    this.minRange = msg.minTimeFilter;
                }
                if (msg.maxTimeFilter != 0) {
                    this.endTime = msg.maxTimeFilter;
                    this.maxRange = msg.maxTimeFilter;
                }
            });
        });
    }

    ngOnInit(): void {
        this.availableMetrics = this.storageOverviewService.getMetrics();
        this.selectedMetricName = "lat_max";
    }

    getSelectedMetric(): MetricItem {
        let [result] = this.availableMetrics
            .filter((m) => m.id == this.selectedMetricName)
        return result;
    }

    /**
     * This method export the data table into a CSV file
     */
    exportDatastoreCSV() {
        let csvContent = ["NAME", "CAPACITY (Go)", "USAGE (%)", "FREE (Go)", "PROVISIONED (Go)", "IOPS (avg)",
            "IOPS (max)", "IOPS READ (avg)", "IOPS READ (max)", "IOPS WRITE (avg)", "IOPS WRITE (max)",
            "LATENCY (avg)", "LATENCY (max)", "LATENCY READ (avg)", "LATENCY READ (max)", "LATENCY WRITE (avg)",
            "LATENCY WRITE (max)"
        ].join(',') + '\n';
        csvContent += Object.values(this.listedDatastores).map(datastore => [datastore.name, datastore.capacity,
            datastore.usageP, datastore.free, datastore.usage, datastore.avgiops,
            datastore.maxiops, datastore.avgiopsread, datastore.maxiopsread, datastore.avgiopswrite, datastore
            .maxiopswrite,
            datastore.avglat, datastore.maxlat, datastore.avglatread, datastore.maxlatread, datastore
            .avglatwrite,
            datastore.maxlatwrite
        ].join(",")).join('\n');

        let exportedFilename = 'Datastore capacity.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    /**
     * This method export the VMs associated to the selected table into a CSV file
     */
    exportVmsCSV() {
        let csvContent = ["VIRTUAL MACHINE NAME", "USAGE (%)", "USAGE (Go)", "PROVISIONED (Go)", "IOPS (avg)",
            "IOPS (max)", "LATENCY (avg)", "LATENCY (max)", "SNAPSHOT NB", "CURRENT SNAPSHOT", "SNAPSHOT SIZE",
            "SNAPSHOT DATE", "IS THE LAST?"
        ].join(',') + '\n';
        csvContent += Object.values(this.listedVms).map(vm => [vm.name, vm.sto, vm.stocom, vm.stoprov, vm.avgiops, vm
            .maxiops, vm.avglat, vm.maxlat, "na", "na", "na", "na", "na"
        ].join(",")).join('\n');

        let exportedFilename = 'Datastore details.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    selectionChanged = async (newlySelectedVm) => {
        let triggeredByFilter = false;

        // Detect if the change is caused by setting-up a filter
        if (newlySelectedVm == null) {
            triggeredByFilter = true
        }

        // Ignore change events caused by filters
        if (triggeredByFilter) {
            return;
        }

        this.jsonLoaderService.currentJsonSubject.subscribe((json) => {
            this.datastoresAreLoading = true;
            let storageOverviewsDatastoreVmData = json.storageOverviewsDatastoreVmData;

            let allVmDatastoreAssociations = storageOverviewsDatastoreVmData.children
                .map((d) => d.children)
                .flat();

            let vmData = allVmDatastoreAssociations
                .filter((a) => a.name == newlySelectedVm.name);

            let displayDatastoreData = vmData
                .map((datastore) => json.storageOverviewsDatastoreData
                    .filter((datastore2) => datastore.father_name == datastore2.name)
                    .map((datastore2) => Object({
                        ...datastore,
                        ...datastore2,
                        ...{
                            vm: this.selectedVm,
                            item: datastore,
                            datastore: datastore2
                        }
                    })))
                .flat()

            // Call API to fetch usage data
            this.fetchChartNavigationData().subscribe((data: FetchResult[]) => {
                let consumptionData = data[0].results[0];
                for (let consData of displayDatastoreData) {
                    let sortedConsumptionWithThisDatastoreAndThisVM = consumptionData
                        .filter((d) => d.storageuuid == consData.datastore.url)
                        .sort((d1, d2) => d1.time - d2.time);
                    let lastKnownConsumption = sortedConsumptionWithThisDatastoreAndThisVM[
                        sortedConsumptionWithThisDatastoreAndThisVM.length - 1];
                    let rawConsumedStorage = lastKnownConsumption.dataPoints[0].value;
                    let consumedStorage = rawConsumedStorage / (1024 * 1024 * 1024);
                    consData["consummedStorage"] = roundTo(consumedStorage, 2);
                    consData["consummedStorageUsage"] = roundTo(100.0 * consumedStorage / consData[
                        "dscap"], 2);
                }
                this.listedDatastores = displayDatastoreData;
                this.secondTableShouldChange.emit(this.listedDatastores);
                this.datastoresAreLoading = false;
            });

        });
    }

    showVmDetails(vm): void {
        //console.log(`display details of vm ${vm}`);
        this.opened = true;
    }

    chartCallbackStorageChart: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.storageChartReady = true;
        this.onReady.emit(this);

        setTimeout(() => {
            chart.reflow();
        });
    };

    fetchChartNavigationData = (): Observable < Object > => {
        let vmIds = [this.selectedVm].map((vm) => vm.identifier);
        return this.storageOverviewService.fetchChartData(vmIds, this.startTime, this.endTime);
    };

    fetchChartData = (min: number, max: number): Observable < Object > => {
        let vmIds = [this.selectedVm].map((vm) => vm.identifier);
        return this.storageOverviewService.fetchChartData(vmIds, min, max);
    };

    updateChartData = (event) => {
        if (this.selectedDatastores.length == 0) {
            return;
        }
        this.graphsAreLoading = true;

        // First manage navigator data
        this.fetchChartNavigationData().subscribe((data: FetchResult[]) => {
            const chartData: SeriesOptionsType[] = [];
            data.map((fr: FetchResult) => {
                let selectedMetric = this.getSelectedMetric();
                let countersSeries = selectedMetric.type == "counters" ? fr.results[1] : fr.results[0];

                this.selectedDatastores.map((selectedDatastore) => {
                    let vmPoints = countersSeries
                        .filter((point) => point.uuid == this.selectedVm.identifier)
                        .filter((point) => point.storageuuid == selectedDatastore.url)
                        .map((point) => Object({
                            timestamp: point.time,
                            value: point.dataPoints.filter((p) => p.metricName ==
                                selectedMetric.metricName)[0].value
                        }))
                        .map((p) => [p.timestamp, p.value * selectedMetric.conversionFactor]);

                    chartData.push({
                        type: 'line',
                        name: `${selectedMetric.label} (${selectedDatastore.name}) navigator`,
                        data: vmPoints,
                        dataGrouping: {
                            enabled: false
                        },
                    });
                })
            })

            this.chart.update({
                navigator: {
                    series: chartData
                }
            });
        });

        // Now Update the displayed series with more detailed points. I remove one hour (specified in ms) to min date
        // and add one hour (specified in ms) to the max date to give the impression that zoomed lines are continuous
        const oneHourInMs = 1 * 3600 * 1000;
        this.fetchChartData(this.minRange - oneHourInMs, this.maxRange + oneHourInMs).subscribe((data2:
            FetchResult[]) => {
            const chartData2: SeriesOptionsType[] = [];
            data2.map((fr2: FetchResult) => {
                let selectedMetric = this.getSelectedMetric();
                let countersSeries2 = selectedMetric.type == "counters" ? fr2.results[1] : fr2.results[
                    0];
                // let [selectedVm2] = this.listedVms.filter((vm) => vm.uuid == fr2.vmId);

                this.selectedDatastores.map((selectedDatastore2) => {
                    let vmPoints2 = countersSeries2
                        .filter((point) => point.uuid == this.selectedVm.identifier)
                        .filter((point) => point.storageuuid == selectedDatastore2.url)
                        .map((point) => Object({
                            timestamp: point.time,
                            value: point.dataPoints.filter((p) => p.metricName ==
                                selectedMetric.metricName)[0].value
                        }))
                        .map((p) => [p.timestamp, p.value * selectedMetric.conversionFactor]);

                    chartData2.push({
                        type: 'line',
                        name: `${selectedMetric.label} (${selectedDatastore2.name})`,
                        data: vmPoints2,
                        showInNavigator: false,
                        dataGrouping: {
                            enabled: false
                        },
                        tooltip: {
                            valueDecimals: 2,
                            valueSuffix: ` ${selectedMetric.unit}`
                        }
                    })
                });
            });

            // Remove series that should not be display
            let displayedSeriesName = chartData2.map((s) => s.name);
            let seriesThatShouldNotBeDisplayed = this.chart.series
                .filter(s => displayedSeriesName.indexOf(s.name.split("_navigator")[0]) == -1);
            seriesThatShouldNotBeDisplayed.map((s) => s.remove());

            // Add missing series
            for (let c of chartData2) {
                let matchingExistingSeries = this.chart.series.filter(s => s.name == c.name);
                if (matchingExistingSeries.length == 0) {
                    this.chart.addSeries(c, false);
                }
            }

            // Update all series
            for (let c of chartData2) {
                let matchingExistingSeries = this.chart.series.filter(s => s.name == c.name);
                if (matchingExistingSeries.length > 0) {
                    matchingExistingSeries[0].update(c);
                }
            }
            this.graphsAreLoading = false;
        });
    }

    generateDefaultHighchartsOptions(title: string): Highcharts.Options {
        let result: Highcharts.Options = {
            chart: {
                type: 'stock',
                zoomType: 'x',
            },
            time: {
                useUTC: false
            },
            series: [],
            navigator: {
                series: []
            },
            scrollbar: {
                liveRedraw: false
            },
            rangeSelector: {
                buttons: [{
                    type: 'hour',
                    count: 1,
                    text: '1h'
                }, {
                    type: 'day',
                    count: 1,
                    text: '1d'
                }, {
                    type: 'month',
                    count: 1,
                    text: '1m'
                }, {
                    type: 'year',
                    count: 1,
                    text: '1y'
                }, {
                    type: 'all',
                    text: 'All'
                }],
                inputEnabled: false, // it supports only days
                selected: 4 // all
            },
            xAxis: {
                events: {
                    afterSetExtremes: (event) => {
                        // Prevent duplicate trigger of this event
                        if (this.minRange == event.min && this.maxRange == event.max) {
                            return;
                        }

                        // Prevent this event to be triggered by the setting of a new navigator serie
                        if (event.trigger != "zoom" && event.trigger != "navigator" && event.trigger !=
                            "rangeSelectorButton") {
                            return;
                        }

                        //console.log(
                        //    `afterSetExtremes ${this.minRange} ${event.min} ${this.maxRange} ${event.max}`
                        //);
                        //console.log(
                        //    `                 (${new Date(this.minRange)} ${new Date(event.min)} ${new Date(this.maxRange)} ${new Date(event.max)})`
                        //);
                        this.minRange = event.min;
                        this.maxRange = event.max;

                        this.updateChartData(this.selectedVm);
                    }
                },
                type: 'datetime',
                minRange: 3600 * 1000 // one hour
            },
            credits: {
                enabled: false
            }
        };
        return result;
    }
}

