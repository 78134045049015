<div class="clr-row">
	<div [ngClass]="col_class" *ngIf="itemList">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="vm"></cds-icon>
					&nbsp;Resources exceeding thresholds
					<span class="spinner spinner-inline" style="vertical-align: baseline;" *ngIf="isProcessing">Loading...</span>
				</h5>
				<span class="export"></span>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<table id="ts-vm-list-all" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  					<thead>
    						<tr>
      							<th rowspan="2">NAME</th>
                                        		<th colspan="2">CPU</th>
                                        		<th colspan="2">DISK</th>
                                        		<th colspan="2">NET</th>
                                        		<th colspan="2">RAM</th>
						</tr>
						<tr>
                                        		<th>
								<span class="badge badge-orange" style="background-color:#f3a10b;">warning</span>
							</th>
                                        		<th>
								<span class="badge badge-danger" style="background-color:#ff0000;">critical</span>
							</th>
                                        		<th>
								<span class="badge badge-orange" style="background-color:#f3a10b;">warning</span>
							</th>
                                        		<th>
								<span class="badge badge-danger" style="background-color:#ff0000;">critical</span>
							</th>
                                        		<th>
								<span class="badge badge-orange" style="background-color:#f3a10b;">warning</span>
							</th>
                                        		<th>
								<span class="badge badge-danger" style="background-color:#ff0000;">critical</span>
							</th>
                                        		<th>
								<span class="badge badge-orange" style="background-color:#f3a10b;">warning</span>
							</th>
                                        		<th>
								<span class="badge badge-danger" style="background-color:#ff0000;">critical</span>
							</th>
						</tr>
  					</thead>
					<tbody>
    						<tr *ngFor="let ts of ts_data" class="addLink" (click)="setDetail(ts.uuid)">
							<td>{{getVmData(ts.uuid)?.name}}</td>
							<td>{{ts.pts_cpu_total_orange}}</td>
							<td>{{ts.pts_cpu_total_red}}</td>
							<td>{{ts.pts_disk_total_orange}}</td>
							<td>{{ts.pts_disk_total_red}}</td>
							<td>{{ts.pts_net_orange}}</td>
							<td>{{ts.pts_net_red}}</td>
							<td>{{ts.pts_ram_orange}}</td>
							<td>{{ts.pts_ram_red}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="itemDetail">
		<div class="card">
			<div class="card-header card-ajust">
				<button type="button" id="t1-close" class="close" aria-label="Close" (click)="closeCard()">
					<cds-icon aria-hidden="true" shape="close"></cds-icon>
				</button>
				<h5>
					<cds-icon shape="vm"></cds-icon>
					&nbsp;{{ts_selected.name}}
					<div>&nbsp;</div>
					{{this.vm_selected.vcpu}}vCPUs - {{this.vm_selected.vram/1024}}GB RAM - {{this.vm_selected.ds}}GB Disk
				</h5>
			</div>
			<div class="card-block">
				<ng-template load-troubleshooting-card-list></ng-template>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="itemGraph">
		<div class="card">
			<div class="card-block">
				<ng-template load-troubleshooting-graph></ng-template>
			</div>
		</div>
	</div>
</div>
