import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    HostListener
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    ManagementService,
    MonitorService,
    ShareService
} from '@app/services';

import {
    JSONTarget,
    Message,
    InstanceType
} from '@app/model';

import {
    T7Component
} from '@app/t7/t7.component';
import {
    T7Directive
} from '@app/directives/t7.directive';
import {
    T9Component
} from '@app/t9/t9.component';
import {
    T9Directive
} from '@app/directives/t9.directive';
import {
    T12Component
} from '@app/t12/t12.component';
import {
    T12Directive
} from '@app/directives/t12.directive';
import {
    T13Component
} from '@app/t13/t13.component';
import {
    T13Directive
} from '@app/directives/t13.directive';

import {
    InstancesCo2Component
} from '@app/provider/aws/instances-co2/instances-co2.component';
import {
    GreenitTarget
} from '../greenit.enums';
import {
    Subscription
} from 'rxjs';


@Component({
    selector: 'app-evolution',
    templateUrl: './evolution.component.html',
    styleUrls: ['./evolution.component.css']
})
export class EvolutionComponent implements AfterViewChecked, OnInit {

    message: Message;

    @ViewChild(T7Directive) addT7: T7Directive;
    @ViewChild(T9Directive) addT9: T9Directive;
    @ViewChild(T12Directive) addT12: T12Directive;
    @ViewChild(T13Directive) addT13: T13Directive;
    @ViewChild('addAwsCo2') addAwsCo2: InstancesCo2Component;

    isAccountMgtReady: boolean = false;
    isAwsReady: boolean = false;
    isAzureReady: boolean = false;
    isGcpReady: boolean = false;
    isContainerMgtReady: boolean = false;
    isKubernetesReady: boolean = false;

    scaph_target: JSONTarget = JSONTarget.GREENIT_SCAPH_CEE;
    xclarity_target: JSONTarget = JSONTarget.GREENIT_XCLARITY_CEE;
    openmanage_target: JSONTarget = JSONTarget.GREENIT_OPENMANAGE_CEE;
    oneview_target: JSONTarget = JSONTarget.GREENIT_ONEVIEW_CEE;
    ipmi_target: JSONTarget = JSONTarget.GREENIT_IPMI_CEE;
    network_target: JSONTarget = JSONTarget.GREENIT_NETWORK_CEE;
    storage_target: JSONTarget = JSONTarget.GREENIT_STORAGE_CEE;

    // For tabs
    tab: string;
    tab_subscription: Subscription;
    instancetype = InstanceType;

    /**
     * 
     */
    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef, private mgt_svc: ManagementService,
        private message_svc: ShareService, private monitor_svc: MonitorService) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.tab_subscription = this.monitor_svc.currentAppTab.subscribe((tab) => {
            this.tab = tab;

            this.isAccountMgtReady = false;
            this.isAwsReady = false;
            this.isAzureReady = false;
            this.isGcpReady = false;
            this.isContainerMgtReady = false;
            this.isKubernetesReady = false;

            switch (tab) {
                case InstanceType.VMWARE:
                    this.setVmware();
                    break;
                case InstanceType.AWS:
                    this.message.cloudProvider = tab;
                    this.setAws();
                    break;
                case InstanceType.AZURE:
                    this.message.cloudProvider = tab;
                    this.setAzure();
                    break;
                case InstanceType.GCP:
                    this.message.cloudProvider = tab;
                    this.setGcp();
                    break;
                case InstanceType.KUBERNETES:
                    this.setKubernetes();
                    break;
                case InstanceType.XCLARITY:
                    this.setXClarity();
                    break;
                case InstanceType.SCAPHANDRE:
                    this.setScaphandre();
                    break;
                case InstanceType.OPENMANAGE:
                    this.setOpenManage();
                    break;
                case InstanceType.ONEVIEW:
                    this.setOneView();
                    break;
                case InstanceType.IPMI:
                    this.setIpmi();
                    break;
                case InstanceType.NETWORK:
                    this.setNetwork();
                    break;
                case InstanceType.STORAGE:
                    this.setStorage();
                    break;
                default:
                    break;
            }
        });
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        if (this.tab_subscription != undefined) {
            this.tab_subscription.unsubscribe();
        }
    }

    /**
     * 
     */
    ngAfterViewInit(): void {}

    /**
     * 
     */
    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    setVmware(): void {
        setTimeout(() => this.loadVMwareCards(), 100);
    }

    setAws(): void {
        this.mgt_svc.getRegions('aws').pipe(first()).subscribe(
            data => {
                if (data.length > 0)
                    this.message.isValidAws = true;

                if (this.message.isValidAws) {
                    this.isAccountMgtReady = true;
                    setTimeout(() => this.loadAws(), 500);
                }
            }
        );
    }

    setAzure(): void {
        this.mgt_svc.getRegions('azure').pipe(first()).subscribe(
            data => {
                if (data.length > 0)
                    this.message.isValidAzure = true;

                if (this.message.isValidAzure) {
                    this.isAccountMgtReady = true;
                    setTimeout(() => this.loadAzure(), 500);
                }
            }
        );
    }

    setGcp(): void {
        this.mgt_svc.getRegions('gcp').pipe(first()).subscribe(
            data => {
                if (data.length > 0)
                    this.message.isValidGcp = true;

                if (this.message.isValidGcp) {
                    //this.isAccountMgtReady = true;
                    setTimeout(() => this.loadGcp(), 500);
                }
            }
        );
    }

    setKubernetes(): void {
        this.message.kubeDetail = true;
        this.mgt_svc.getKubernetes().pipe(first()).subscribe(
            data => {
                if (data.length > 0)
                    this.message.isValidKubernetes = true;

                if (this.message.isValidKubernetes) {
                    this.isContainerMgtReady = true;
                    setTimeout(() => this.isKubernetesReady = true, 500);
                }
            }
        );
    }

    setScaphandre(): void {
        setTimeout(() => this.loadCards(InstanceType.SCAPHANDRE), 100);
    }

    setXClarity(): void {
        setTimeout(() => this.loadCards(InstanceType.XCLARITY), 100);
    }

    setOpenManage(): void {
        setTimeout(() => this.loadCards(InstanceType.OPENMANAGE), 100);
    }

    setOneView(): void {
        setTimeout(() => this.loadCards(InstanceType.ONEVIEW), 100);
    }

    setIpmi(): void {
        setTimeout(() => this.loadCards(InstanceType.IPMI), 100);
    }

    setNetwork(): void {
        setTimeout(() => this.loadCards(InstanceType.NETWORK), 100);
    }

    setStorage(): void {
        setTimeout(() => this.loadCards(InstanceType.STORAGE), 100);
    }

    loadRegion(): void {
        this.isAwsReady = false;
        this.isAzureReady = false;

        if (this.message.cloudProvider == "aws")
            setTimeout(() => this.loadAws(), 500);
        else
            setTimeout(() => this.loadAzure(), 500);
    }

    loadCluster(): void {
        this.isKubernetesReady = false;
        setTimeout(() => this.isKubernetesReady = true, 500);
    }

    private loadAws() {
        if (this.message.isValidAws) {
            this.message.cloudProvider = 'aws';
            this.message.creditSelected = 'co2';
            this.isAwsReady = true;
        }
    }

    private loadAzure() {
        if (this.message.isValidAzure) {
            this.message.cloudProvider = 'azure';
            this.message.creditSelected = 'co2';
            this.isAzureReady = true;
        }
    }

    private loadGcp() {
        if (this.message.isValidGcp) {
            this.message.cloudProvider = 'gcp';
            this.message.creditSelected = 'co2';
            this.isGcpReady = true;
        }
    }

    private loadVMwareCards(): void {
        this.loadT7Card(InstanceType.VMWARE);
        this.loadT9Card(InstanceType.VMWARE);
        this.loadT12Card();
        this.loadT13Card();
    }

    private loadCards(type: InstanceType): void {
        this.loadT9Card(type);
        this.loadT7Card(type);
    }

    private loadT7Card(type: InstanceType): void {
        if (this.addT7 != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T7Component);
            const viewContainerRef = this.addT7.viewContainerRef7;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
            switch (type) {
                case InstanceType.SCAPHANDRE:
                    componentRef.instance.target = GreenitTarget.SCAPHANDRE_CO2;
                    break;
                case InstanceType.XCLARITY:
                    componentRef.instance.target = GreenitTarget.XCLARITY_CO2;
                    break;
                case InstanceType.OPENMANAGE:
                    componentRef.instance.target = GreenitTarget.OPENMANAGE_CO2;
                    break;
                case InstanceType.ONEVIEW:
                    componentRef.instance.target = GreenitTarget.ONEVIEW_CO2;
                    break;
                case InstanceType.IPMI:
                    componentRef.instance.target = GreenitTarget.IPMI_CO2;
                    break;
                case InstanceType.NETWORK:
                    componentRef.instance.target = GreenitTarget.NETWORK_CO2;
                    break;
                case InstanceType.STORAGE:
                    componentRef.instance.target = GreenitTarget.STORAGE_CO2;
                    break;
            }
        }
    }

    private loadT9Card(type: InstanceType): void {
        if (this.addT9 != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T9Component);
            const viewContainerRef = this.addT9.viewContainerRef9;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
            switch (type) {
                case InstanceType.SCAPHANDRE:
                    componentRef.instance.target = GreenitTarget.SCAPHANDRE_NODE_CEE;
                    break;
                case InstanceType.XCLARITY:
                    componentRef.instance.target = GreenitTarget.XCLARITY_NODE_CEE;
                    break;
                case InstanceType.OPENMANAGE:
                    componentRef.instance.target = GreenitTarget.OPENMANAGE_NODE_CEE;
                    break;
                case InstanceType.ONEVIEW:
                    componentRef.instance.target = GreenitTarget.ONEVIEW_NODE_CEE;
                    break;
                case InstanceType.IPMI:
                    componentRef.instance.target = GreenitTarget.IPMI_NODE_WH;
                    break;
                case InstanceType.NETWORK:
                    componentRef.instance.target = GreenitTarget.NETWORK_WH;
                    break;
                case InstanceType.STORAGE:
                    componentRef.instance.target = GreenitTarget.STORAGE_WH;
                    break;
            }
        }
    }

    private loadT12Card(): void {
        if (this.addT12 != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T12Component);
            const viewContainerRef = this.addT12.viewContainerRef12;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadT13Card(): void {
        if (this.addT13 != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T13Component);
            const viewContainerRef = this.addT13.viewContainerRef13;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }
}
