import {
    Injectable
} from '@angular/core';
import {
    HttpClient
} from '@angular/common/http';
import {
    map
} from 'rxjs/operators';

import {
    environment
} from '@environments/environment';
import {
    Lcy
} from '@app/model';

@Injectable({
    providedIn: 'root'
})
export class LcyService {

    constructor(private http: HttpClient) {}

    getLcy(uuid: string) {
        return this.http.get < Lcy[] > (`${environment.apiUrl}/lcy/getlcy?uuid=` + uuid).pipe(map(lcy => {
            return lcy;
        }));
    }
}
