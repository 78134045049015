import {
    Component,
    HostListener,
    OnInit
} from '@angular/core';
import {
    InstanceType,
    Message
} from '@app/model';
import {
    MonitorService,
    ShareService
} from '@app/services';
import {
    Subscription
} from 'rxjs';


@Component({
    selector: 'app-power',
    templateUrl: './power.component.html',
    styleUrls: ['./power.component.css']
})
export class PowerComponent implements OnInit {

    message: Message;

    isDcscope = true;
    isCo2scope = false;

    isVmwareReady: boolean = true;
    isAwsReady: boolean = false;
    isAzureReady: boolean = false;
    isGcpReady: boolean = false;
    isKubernetesReady: boolean = false;
    isScaphReady: boolean = false;
    isContainerMgtReady: boolean = false;
    isDataReload: boolean = false;
    isXclarityReady: boolean = false;
    isOpenmanageReady: boolean = false;
    isOneviewReady: boolean = false;
    isIpmiReady: boolean = false;
    isStorageReady: boolean = false;

    // For tabs
    tab: string;
    tab_subscription: Subscription;
    instancetype = InstanceType;

    /**
     * 
     */
    constructor(private data: ShareService, private monitor_svc: MonitorService) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.data.currentMessage.subscribe(message => this.message = message);

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;

        this.tab_subscription = this.monitor_svc.currentAppTab.subscribe((tab) => {
            this.tab = tab;

            this.isVmwareReady = false;
            this.isAwsReady = false;
            this.isAzureReady = false;
            this.isGcpReady = false;
            this.isKubernetesReady = false;
            this.isContainerMgtReady = false;
            this.isScaphReady = false;
            this.isXclarityReady = false;
            this.isOneviewReady = false;
            this.isOpenmanageReady = false;
            this.isIpmiReady = false;
            this.isStorageReady = false;

            switch (tab) {
                case InstanceType.VMWARE:
                    this.isVmwareReady = true;
                    break;
                case InstanceType.AWS:
                    this.isAwsReady = true;
                    break;
                case InstanceType.AZURE:
                    this.isAzureReady = true;
                    break;
                case InstanceType.GCP:
                    this.isGcpReady = true;
                    break;
                case InstanceType.KUBERNETES:
                    this.isKubernetesReady = true;
                    this.isContainerMgtReady = true;
                    break;
                case InstanceType.SCAPHANDRE:
                    this.isScaphReady = true;
                    break;
                case InstanceType.XCLARITY:
                    this.isXclarityReady = true;
                    break;
                case InstanceType.OPENMANAGE:
                    this.isOpenmanageReady = true;
                    break;
                case InstanceType.ONEVIEW:
                    this.isOneviewReady = true;
                    break;
                case InstanceType.IPMI:
                    this.isIpmiReady = true;
                    break;
                case InstanceType.STORAGE:
                    this.isStorageReady = true;
                    break;
                default:
                    break;
            }
        });
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.tab_subscription != undefined) {
            this.tab_subscription.unsubscribe();
        }
    }
}
