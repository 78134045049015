export enum DATA_ORDER {
    ASC,
    DESC
}

export enum Tab {
    GREENIT,
    BEHAVIOR,
    INFRA
}

export enum VirtindexProperty {
    VCPU_PERLOGICALCORE = 'vcpu.perlogicalcore',
        VM_PERSERVER = 'vm.perserver',
        VM_PERSOCKET = 'vm.persocket',
        VM_WATT = 'watt.pervm',
        SERVER_KWH = 'kwh.perserver',
        SERVER_CO2G = 'co2.grey.perserver',
        VM_CO2G = 'co2.grey.pervm',
        VM_CO2T = 'co2.total.pervm',
        SERVER_AGE = 'age.perserver',
        SERVER_PUE = 'pue.perserver',
        SERVER_CO2_COEFF = 'co2coeff.perserver',
        IDLE = 'idle',
        OVERSIZED = 'oversized',
        UNDERSIZED = 'undersized',
        IDLE_EVOL = 'idle.evol',
        OVERSIZED_EVOL = 'oversized.evol',
        UNDERSIZED_EVOL = 'undersized.evol',
        RAM_ALLOC_PERCENT = 'ram.alloc.percent',
        RAM_USE_PERCENT = 'ram.use.percent',
        CPU_USE_PERCENT = 'cpu.use.percent',
}

export enum VirtindexName {
    VCPU_PERLOGICALCORE = 'vCPU per logical core',
        VM_PERSERVER = 'VM per server',
        VM_PERSOCKET = 'VM per CPU socket',
        VM_WATT = 'Average Watt per VM',
        SERVER_KWH = 'Annual kWh per server',
        SERVER_CO2G = 'Annual embodied CO2 (kg CO2eq) per server',
        VM_CO2G = 'Annual embodied CO2 (kg CO2eq) per VM',
        VM_CO2T = 'Annual global CO2 (kg CO2eq) per VM',
        SERVER_AGE = 'Average Server age (years)',
        SERVER_PUE = 'PUE',
        SERVER_CO2_COEFF = 'Emission factor (kg CO2eq/kWh)',
        IDLE = 'Percent of idle VMs (%)',
        OVERSIZED = 'Percent of oversized VMs (%)',
        UNDERSIZED = 'Percent of undersized VMs (%)',
        IDLE_EVOL = 'Trend of idle VMs (%)',
        OVERSIZED_EVOL = 'Trend of oversized VMs (%)',
        UNDERSIZED_EVOL = 'Trend of undersized VMs (%)',
        RAM_ALLOC_PERCENT = 'RAM allocation (%)',
        RAM_USE_PERCENT = 'RAM use (%)',
        CPU_USE_PERCENT = 'CPU use (%)',
}

export enum VirtindexDesc {
    VCPU_PERLOGICALCORE = '',
        VM_PERSERVER = '',
        VM_PERSOCKET = '',
        VM_WATT = '',
        SERVER_KWH = '',
        SERVER_CO2G = '',
        VM_CO2G = '',
        VM_CO2T = '',
        SERVER_AGE = '',
        SERVER_PUE = '',
        SERVER_CO2_COEFF = '',
        IDLE = '',
        OVERSIZED = '',
        UNDERSIZED = '',
        IDLE_EVOL = '(since the beginning of the year)',
        OVERSIZED_EVOL = '(since the beginning of the year)',
        UNDERSIZED_EVOL = '(since the beginning of the year)',
        RAM_ALLOC_PERCENT = '',
        RAM_USE_PERCENT = '',
        CPU_USE_PERCENT = '',
}
