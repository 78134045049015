import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    JsonloaderService
} from "@app/services/jsonloader.service";
import {
    MeasurementService
} from "@app/services/measurement.service";
import {
    environment
} from "@environments/environment";
import {
    Observable,
    forkJoin
} from 'rxjs';
import {
    map
} from 'rxjs/operators';

export class FetchResult {
    vmId: string;
    results: any[];

    constructor(vmId: string, results: any[]) {
        this.vmId = vmId;
        this.results = results;
    }
}

export class MetricItem {
    id: string;
    type: string;
    label: string
    metricName: string;
    unit: string;
    conversionFactor: number;

    constructor(id: string, type: string, label: string, metricName: string, unit: string, conversionFactor: number =
        1.0) {
        this.id = id;
        this.type = type;
        this.label = label;
        this.metricName = metricName;
        this.unit = unit;
        this.conversionFactor = conversionFactor;
    }
}

@Injectable({
    providedIn: 'root'
})
export class StorageOverviewService {
    constructor(private http: HttpClient, private jsonLoaderService: JsonloaderService, private measurementService:
        MeasurementService) {}

    getMetrics(): MetricItem[] {
        return [{
            id: "lat_max",
            label: "Maximum latency",
            type: "counters",
            metricName: "MAX_VM_STORAGE_LATENCY",
            unit: "ms",
            conversionFactor: 1.0
        }, {
            id: "lat_avg",
            label: "Average latency",
            type: "counters",
            metricName: "AVG_VM_STORAGE_LATENCY",
            unit: "ms",
            conversionFactor: 1.0
        }, {
            id: "lat_read_max",
            label: "Maximum read latency",
            type: "counters",
            metricName: "MAX_VM_STORAGE_READ_LATENCY",
            unit: "ms",
            conversionFactor: 1.0
        }, {
            id: "lat_read_avg",
            label: "Average read latency",
            type: "counters",
            metricName: "AVG_VM_STORAGE_READ_LATENCY",
            unit: "ms",
            conversionFactor: 1.0
        }, {
            id: "lat_write_max",
            label: "Maximum write latency",
            type: "counters",
            metricName: "MAX_VM_STORAGE_WRITE_LATENCY",
            unit: "ms",
            conversionFactor: 1.0
        }, {
            id: "lat_write_avg",
            label: "Average write latency",
            type: "counters",
            metricName: "AVG_VM_STORAGE_WRITE_LATENCY",
            unit: "ms",
            conversionFactor: 1.0
        }, {
            id: "iops_max",
            label: "Maximum IOPS",
            type: "counters",
            metricName: "MAX_VM_STORAGE_IOPS",
            unit: "iops",
            conversionFactor: 1.0
        }, {
            id: "iops_avg",
            label: "Average IOPS",
            type: "counters",
            metricName: "AVG_VM_STORAGE_IOPS",
            unit: "iops",
            conversionFactor: 1.0
        }, {
            id: "iops_read_max",
            label: "Maximum read IOPS",
            type: "counters",
            metricName: "MAX_VM_STORAGE_READ_RATE",
            unit: "iops",
            conversionFactor: 1.0
        }, {
            id: "iops_read_avg",
            label: "Average read IOPS",
            type: "counters",
            metricName: "AVG_VM_STORAGE_READ_RATE",
            unit: "iops",
            conversionFactor: 1.0
        }, {
            id: "iops_write_max",
            label: "Maximum write IOPS",
            type: "counters",
            metricName: "MAX_VM_STORAGE_WRITE_RATE",
            unit: "iops",
            conversionFactor: 1.0
        }, {
            id: "iops_write_avg",
            label: "Average write IOPS",
            type: "counters",
            metricName: "AVG_VM_STORAGE_WRITE_RATE",
            unit: "iops",
            conversionFactor: 1.0
        }, {
            id: "provisioned",
            label: "Provisioned storage",
            type: "consumption",
            metricName: "CAPACITY",
            unit: "GiB",
            conversionFactor: 1.0 / (1024 * 1024 * 1024)
        }, {
            id: "commited",
            label: "Commited storage",
            type: "consumption",
            metricName: "COMMITED",
            unit: "GiB",
            conversionFactor: 1.0 / (1024 * 1024 * 1024)
        }, {
            id: "uncommited",
            label: "Uncommited storage",
            type: "consumption",
            metricName: "UNCOMMITED",
            unit: "GiB",
            conversionFactor: 1.0 / (1024 * 1024 * 1024)
        }, {
            id: "unshared",
            label: "Unshared storage",
            type: "consumption",
            metricName: "UNSHARED",
            unit: "GiB",
            conversionFactor: 1.0 / (1024 * 1024 * 1024)
        }, ];
    }

    _fetchChartData(minRange: number, maxRange: number, vmIds: string[]): Observable < Object[] > {

        // Compute granularity
        let durationInDays = (maxRange - minRange) / (24 * 3600 * 1000);
        let granularity: string;
        if (durationInDays < (1.0 / 12)) {
            granularity = "MINUTELY";
        } else if (durationInDays < 30) {
            granularity = "HOURLY";
        } else if (durationInDays < 365) {
            granularity = "DAILY";
        } else {
            granularity = "DAILY";
        }

        let consumptionQueryDataParams = {
            "timeInterval": {
                "startTime": minRange,
                "endTime": maxRange
            },
            "resourceFilter": {
                "fields": [
                    "UUID"
                ],
                "values": [
                    vmIds
                ]
            },
            "level": "VM_DATASTORE",
            "counters": [
                "CAPACITY",
                "COMMITED",
                "UNCOMMITED",
                "UNSHARED"
            ]
        };

        let datastoreQueryDataParams = {
            "aggregationGranularity": granularity,
            "resourceType": "VM",
            "timeInterval": {
                "startTime": minRange,
                "endTime": maxRange
            },
            "resourceFilter": {
                "fields": [
                    "UUID"
                ],
                "values": [
                    vmIds
                ]
            },
            "metrics": [{
                "counter": "VM_STORAGE_READ_RATE"
            }, {
                "counter": "VM_STORAGE_WRITE_RATE"
            }, {
                "counter": "VM_STORAGE_READ_LATENCY"
            }, {
                "counter": "VM_STORAGE_WRITE_LATENCY"
            }, {
                "counter": "VM_STORAGE_LATENCY"
            }, {
                "counter": "VM_STORAGE_IOPS"
            }]
        };

        let consumptionQueryDataPromise = this.http.post(`${environment.api2Url}/v1/storage/consumption/query`,
            consumptionQueryDataParams);
        let datastoreQueryDataPromise = this.http.post(`${environment.api2Url}/v1/storage/counters/query`,
            datastoreQueryDataParams);

        return forkJoin([consumptionQueryDataPromise, datastoreQueryDataPromise]);
    }

    fetchChartData(vmIds: string[], minRange: number, maxRange: number): Observable < FetchResult[] > {
        let promises = vmIds.map((vmId) => {
            return this._fetchChartData(minRange, maxRange, [vmId]).pipe(
                map((r) => new FetchResult(vmId, r))
            );
        });
        return forkJoin(promises);
    }
}
