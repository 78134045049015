import {
    Component,
    OnInit,
    Input,
    HostListener
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    JSONTarget,
    User,
    Message
} from '@app/model';

import {
    AccountService,
    GreenitService,
    JsonloaderService,
    ManagementService,
    MonitorService,
    ShareService
} from '@app/services';

import {
    Resource,
    GreenitTarget,
    DefaultValues
} from '../greenit.enums';

import * as Highcharts from 'highcharts';

import * as moment from 'moment';
import {
    Subscription
} from 'rxjs';


@Component({
    selector: 'app-greenitcf',
    templateUrl: './greenitcf.component.html',
    styleUrls: ['./greenitcf.component.css']
})
export class GreenitcfComponent implements OnInit {

    @Input() target: GreenitTarget;
    @Input() show_nbhost: boolean;
    @Input() showStackLabel: boolean;
    @Input() allowReload: boolean;
    @Input() showPie: boolean;

    message: Message;

    private currentUser: User;

    needReload: boolean = false;

    settings_subscription: Subscription;

    greenit_alert: boolean = false;
    greenit_nodata: boolean = false;

    network_data_check: boolean = false;
    storage_data_check: boolean = false;

    private greenit_data: any;
    private greenit_data_nbhost: any;
    private greenit_cloud_aws_data: any = [];
    private greenit_cloud_azure_data: any = [];
    private greenit_cloud_gcp_data: any = [];

    private greenit_resource: Resource;

    private greenit_color = ['#7cb5ec', '#95959d', '#39ac73', '#a47d7c', '#ff6666', '#946ac8'];
    private greenit_grey_color = ['#476888', '#434348', '#26734d', '#694a49', '#b30000', '#613795'];

    Highcharts: typeof Highcharts = Highcharts;

    /**
     * PIE CHART
     */
    chart2: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};

    isPieReady: boolean = false;

    /**
     * CF CHART
     */
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;

        //Stacked column
        this.chart.update({
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                }
            }
        });
        this.chart.update({
            yAxis: {
                min: 0,
                stackLabels: {
                    enabled: this.showStackLabel == undefined ? true : false,
                    style: {
                        fontWeight: 'bold',
                        color: 'gray'
                    }
                }
            }
        });

        this.chart.showLoading();
    };

    options: Highcharts.Options = {
        chart: {
            height: '250px',
            backgroundColor: 'rgba(255, 255, 255, 0.0)'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function() {
                    return Highcharts.dateFormat('%m-%Y', this.value);
                }
            },
        },
        yAxis: {
            min: 0,
            labels: {
                align: 'right',
                x: -10
            },
            title: {
                text: ''
            }
        },
        tooltip: {
            borderColor: 'black',
            formatter: function() {
                return `<span style="color: ${this.color}">•</span> ${this.series.name}: <b>${this.y}</b>`;
            }
        }
    }

    /**
     * STORAGE & NETWORK EXTRAPOLATION
     */
    host_ratio: number = DefaultValues.HOST_EXTRAPOLATION_RATIO;
    storage_ratio: number = DefaultValues.STORAGE_EXTRAPOLATION_RATIO;
    network_ratio: number = DefaultValues.NETWORK_EXTRAPOLATION_RATIO;

    /**
     * FOR ALL INCLUSIVE CHART
     */
    aws_monitor_check: boolean = false;
    azure_monitor_check: boolean = false;
    gcp_monitor_check: boolean = false;

    /**
     * DETAILS CHART
     */

    isDetailsReady: boolean = false;

    /**
     * 
     */
    constructor(private authenticationService: AccountService, private greenit_svc: GreenitService,
        private json_svc: JsonloaderService, private mgt_svc: ManagementService, private message_svc: ShareService,
        private monitor_svc: MonitorService) {}

    /**
     * 
     */
    async ngOnInit(): Promise < void > {

        this.greenit_data = [];

        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        // For all inclusive chart
        this.monitor_svc.awsCheck.subscribe((details) => (this.aws_monitor_check = details));
        this.monitor_svc.azureCheck.subscribe((details) => (this.azure_monitor_check = details));
        this.monitor_svc.gcpCheck.subscribe((details) => (this.gcp_monitor_check = details));


        // GET SETTINGS (async)
        try {
            let settings_subscribe = await this.greenit_svc.getGreenitSettings().toPromise();
            this.updateDataSettings(settings_subscribe);
        } catch {
            // Nothing, already on console
        }

        // Check network data
        this.greenit_svc.networkDataCheck.subscribe((details) => (this.network_data_check = details));

        // Check storage data
        this.greenit_svc.storageDataCheck.subscribe((details) => (this.storage_data_check = details));

        // LOAD DATA
        this.load(false);

        // Need subscription to GreenitService to detect settings changes & reload extrapolation
        if (this.allowReload) {
            this.settings_subscription = this.greenit_svc.currentSettings.subscribe(
                data => {
                    if (this.needReload) {
                        this.updateDataSettings(data);
                        this.load(this.needReload);
                    }
                    this.needReload = true;
                }
            );
        }

    }

    /**
     * 
     */
    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.settings_subscription != undefined) {
            this.settings_subscription.unsubscribe();
        }
    }

    /**
     * 
     */
    load(reload: boolean): void {

        if (reload) {
            this.greenit_cloud_aws_data = [];
            this.greenit_cloud_azure_data = [];
            this.greenit_cloud_gcp_data = [];

            // Remove old series
            for (var i = this.chart.series.length - 1; i >= 0; i--) {
                this.chart.series[i].remove(false);
            }

            // Show loading
            this.chart.showLoading();

        }

        let current_target: GreenitTarget = this.target;

        // XXX GET GreenitTarget.ALL_CO2 DATA
        if (this.target == GreenitTarget.ALL_INCLUSIVE_CO2 || this.target == GreenitTarget
            .EXTRAPOLATION_STORAGE_CO2 || this.target == GreenitTarget.EXTRAPOLATION_NETWORK_CO2) {

            current_target = GreenitTarget.ALL_CO2;
        }

        this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter, current_target)
            .subscribe(
                data => {
                    this.greenit_data = data;

                    this.greenit_nodata = false;

                    this.greenit_alert = (this.greenit_data.length == 0);

                    switch (this.target) {
                        case GreenitTarget.ALL_INCLUSIVE_CO2:
                            this.greenit_resource = Resource.CO2;
                            this.setAllInclusiveChartData();
                            break;
                        case GreenitTarget.GLOBAL_CO2:
                            this.greenit_resource = Resource.CO2;
                            this.setGlobalChartData();
                            break;
                        case GreenitTarget.DESKTOP_CO2:
                            this.greenit_resource = Resource.CO2;
                            this.setDesktopChartData();
                            break;
                        case GreenitTarget.EXTRAPOLATION_STORAGE_CO2:
                        case GreenitTarget.EXTRAPOLATION_NETWORK_CO2:
                            this.greenit_resource = Resource.CO2;
                            this.setExtrapolationChartData();
                            break;
                        case GreenitTarget.DC_HOST_WH:
                            this.greenit_resource = Resource.WH;
                            this.setChartData(this.target);
                            break;
                        case GreenitTarget.DC_HOST_CO2:
                        case GreenitTarget.SCAPHANDRE_CO2:
                        case GreenitTarget.XCLARITY_CO2:
                        case GreenitTarget.OPENMANAGE_CO2:
                        case GreenitTarget.ONEVIEW_CO2:
                        case GreenitTarget.IPMI_CO2:
                            this.greenit_resource = Resource.CO2;
                            this.setChartData(this.target);
                            break;
                        case GreenitTarget.NETWORK_CO2:
                            this.greenit_resource = Resource.CO2;
                            this.setChartData(this.target, false);
                            break;
                        case GreenitTarget.STORAGE_CO2:
                            this.greenit_resource = Resource.CO2;
                            let show_pie = this.showPie == undefined ? false : this.showPie;
                            this.setChartData(this.target, show_pie);
                            break;
                        default:
                            console.error("greenitcf.target not set properly ! (" + this.target + ")");
                            break;
                    }
                },
                error => {
                    this.greenit_nodata = true;
                    if (this.chart !== undefined) {
                        this.chart.hideLoading();
                    }
                }
            );

        // Build host data
        if (this.show_nbhost) {
            this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter, GreenitTarget
                .FILTER_DC_HOST_NB).subscribe(
                data => {
                    this.greenit_data_nbhost = data;
                    this.setNbHostData();
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    /**
     * 
     */
    private setNbHostData(): void {

        // Init vars
        let times = [];
        let nbhost = [];

        for (let i in this.greenit_data_nbhost) {
            if (this.greenit_data_nbhost[i] != undefined) {
                let lastTime = this.formatLastTime(parseInt(this.greenit_data_nbhost[i].time));
                times[i] = lastTime;
                nbhost[i] = parseFloat(this.greenit_data_nbhost[i].nbhost);
            }
        }

        // Add secondary Y-axis
        this.chart.addAxis({
            id: 'yaxis-2',
            opposite: true,
            title: {
                text: "servers",
            },
        }, false);

        // Push to chart
        this.chart.addSeries({
            type: 'spline',
            name: "Servers number",
            yAxis: 'yaxis-2',
            cursor: 'pointer',
            color: '#80699B',
            //dashStyle: this.linestyle,
            data: nbhost
        });

    }

    /**
     * Global View (GreenIT DCScope)
     */
    private setGlobalChartData(): void {

        // Init vars
        let div = 1;
        let yname: string;
        if (this.greenit_resource == Resource.WH) {
            yname = "kWh";
            div = 1000;
        } else if (this.greenit_resource == Resource.CO2) {
            yname = "kg CO2eq";
        }

        // Init data arrays
        let times = [];
        let dc = [];
        //let network = [];
        let desktop = [];

        for (let i in this.greenit_data) {
            if (this.greenit_data[i] != undefined) {
                let lastTime = this.formatLastTime(parseInt(this.greenit_data[i].time));

                let lastdc = this.greenit_data[i].dc;
                //let lastNetwork = this.greenit_data[i].network;
                let lastDesktop = this.greenit_data[i].desktop;

                lastdc = Number((parseFloat(lastdc) / div).toFixed(2));
                //lastNetwork = Number((parseFloat(lastNetwork) / div).toFixed(2));
                lastDesktop = Number((parseFloat(lastDesktop) / div).toFixed(2));

                // Save points
                times[i] = lastTime;
                dc[i] = Number(lastdc);
                //network[i] = Number(lastNetwork);
                desktop[i] = Number(lastDesktop);

                // Merge with grey points
                if (this.greenit_resource == Resource.CO2) {
                    let lastdcG = this.greenit_data[i].dcG;
                    //let lastNetworkG = this.greenit_data[i].networkG;
                    let lastDesktopG = this.greenit_data[i].desktopG;

                    lastdcG = Number((parseFloat(lastdcG) / div).toFixed(2));
                    //lastNetworkG = Number((parseFloat(lastNetworkG) / div).toFixed(2));
                    lastDesktopG = Number((parseFloat(lastDesktopG) / div).toFixed(2));

                    // XXX  only G for network & desktop
                    dc[i] = Number(parseFloat(lastdc + lastdcG).toFixed(2));
                    //network[i] = Number(lastNetworkG);
                    desktop[i] = Number(lastDesktopG);
                }
            }
        }

        // Update chart title
        this.chart.update({
            yAxis: {
                title: {
                    text: yname
                }
            }
        });

        // Set categories
        this.chart.xAxis[0].setCategories(times);

        // Push dc
        this.chart.addSeries({
            type: 'column',
            name: "DC",
            cursor: 'pointer',
            color: this.greenit_color[0],
            //dashStyle: this.linestyle,
            data: dc
        }, false);

        // Push network
        /*this.chart.addSeries({
            type: 'column',
            name: "Network",
            cursor: 'pointer',
            color: '#434348',
            dashStyle: this.linestyle,
            data: network
        }, false);*/

        // Push desktop
        this.chart.addSeries({
            type: 'column',
            name: "Desktop",
            cursor: 'pointer',
            color: this.greenit_grey_color[2],
            //dashStyle: this.linestyle,
            data: desktop
        }, false);

        // Hide chart loading
        this.chart.hideLoading();

        // Redraw
        this.chart.redraw();

    }

    /**
     * VMWARE / SCAPHANDRE / XCLARITY / OPENMANAGE / ONEVIEW / NETWORK / STORAGE
     */
    private setChartData(type: GreenitTarget, show_pie: boolean = false): void {

        if (this.chart === undefined || this.chart.xAxis === undefined)
            return;

        // Init type
        let use_host: boolean = true;
        let legend_type: string = "Servers";
        let show_storage_pie: boolean = false;

        switch (type) {
            case GreenitTarget.STORAGE_CO2:
                show_storage_pie = true && this.showPie;
            case GreenitTarget.NETWORK_CO2:
                legend_type = "Devices";
                use_host = false;
                break;
            default:
                break;
        }

        // Init vars
        let div = 1;
        let yname: string;
        let firstlegend: string;
        if (this.greenit_resource == Resource.WH) {
            yname = "kWh";
            firstlegend = legend_type;
            div = 1000;
        } else if (this.greenit_resource == Resource.CO2) {
            yname = "kg CO2eq";
            firstlegend = legend_type + " (direct)";
        }

        let times = [];
        let values = [];
        let valuesG = [];

        for (let i in this.greenit_data) {
            if (this.greenit_data[i] != undefined) {
                let lastTime = this.formatLastTime(parseInt(this.greenit_data[i].time));

                let lastValue = "0";
                if (use_host)
                    lastValue = this.greenit_data[i].host;
                else
                    lastValue = this.greenit_data[i].device;

                // Save points
                times[i] = lastTime;
                values[i] = Number((parseFloat(lastValue) / div).toFixed(2));

                // Save Grey points
                if (this.greenit_resource == Resource.CO2) {
                    let lastValueG = "0";
                    if (use_host)
                        lastValueG = this.greenit_data[i].hostG;
                    else
                        lastValueG = this.greenit_data[i].deviceG;
                    valuesG[i] = Number((parseFloat(lastValueG) / div).toFixed(2));
                }
            }
        }

        // Set categories
        this.chart.xAxis[0].setCategories(times);

        // Update chart title
        this.chart.update({
            yAxis: {
                title: {
                    text: yname
                }
            }
        });

        if (this.greenit_resource == Resource.WH) {
            this.chart.update({
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: false
                        }
                    }
                }
            });
        }


        // Show pie
        let events_data: any = {};
        let point_data: any = {};
        let point_grey_data: any = {};
        let time = 0;

        if (show_pie) {
            events_data = {
                click: function(event) {
                    let time_str: string = event.point.category;
                    time = +time_str;
                }
            };
            point_data = {
                events: {
                    click: function() {
                        this.isPieReady = false;
                        this.loadStoragePieChart(time, 'direct');
                    }.bind(this)
                }
            };
            point_grey_data = {
                events: {
                    click: function() {
                        this.isPieReady = false;
                        this.loadStoragePieChart(time, 'grey');
                    }.bind(this)
                }
            };
        }

        // Push data
        this.chart.addSeries({
            type: 'column',
            name: firstlegend,
            cursor: 'pointer',
            color: this.greenit_color[0],
            //dashStyle: this.linestyle,
            data: values,
            events: events_data,
            point: point_data,
        }, false);

        if (this.greenit_resource == Resource.CO2) {
            this.chart.addSeries({
                type: 'column',
                name: legend_type + " (embodied)",
                cursor: 'pointer',
                color: this.greenit_grey_color[0],
                //dashStyle: this.linestyle,
                data: valuesG,
                events: events_data,
                point: point_grey_data,
            }, false);
        }

        // Hide chart loading
        this.chart.hideLoading();

        // Redraw
        this.chart.redraw();

        // Show pie
        if (show_storage_pie) {
            let lastTime = times.sort((a, b) => a - b).pop();
            setTimeout(() => {
                this.loadStoragePieChart(lastTime, 'grey');
            }, 200);
        }
    }

    /**
     * 
     */
    private setDesktopChartData(): void {

        // Init vars
        let div = 1;
        let yname: string;
        if (this.greenit_resource == Resource.WH) {
            yname = "kWh";
            div = 1000;
        } else if (this.greenit_resource == Resource.CO2) {
            yname = "kg CO2eq";
        }

        let times = [];
        let desktopG = [];
        let printerG = [];
        let laptopG = [];
        let screenG = [];
        let networkG = [];
        let otherG = [];

        for (let i in this.greenit_data) {
            if (this.greenit_data[i] != undefined) {
                let lastTime = this.formatLastTime(parseInt(this.greenit_data[i].time));

                // Save Grey points
                if (this.greenit_resource == Resource.CO2) {
                    let lastDesktopG = this.greenit_data[i].desktopG;
                    let lastPrinterG = this.greenit_data[i].printerG;
                    let lastLaptopG = this.greenit_data[i].laptopG;
                    let lastScreenG = this.greenit_data[i].screenG;
                    let lastNetworkG = this.greenit_data[i].networkG;
                    let lastOtherG = this.greenit_data[i].otherG;

                    times[i] = lastTime;
                    desktopG[i] = Number((parseFloat(lastDesktopG) / div).toFixed(2));
                    printerG[i] = Number((parseFloat(lastPrinterG) / div).toFixed(2));
                    laptopG[i] = Number((parseFloat(lastLaptopG) / div).toFixed(2));
                    screenG[i] = Number((parseFloat(lastScreenG) / div).toFixed(2));
                    networkG[i] = Number((parseFloat(lastNetworkG) / div).toFixed(2));
                    otherG[i] = Number((parseFloat(lastOtherG) / div).toFixed(2));
                }
            }
        }

        // Set categories
        this.chart.xAxis[0].setCategories(times);

        // Update chart title
        this.chart.update({
            yAxis: {
                title: {
                    text: yname
                }
            }
        });

        if (this.greenit_resource == Resource.CO2) {
            // Push desktop
            this.chart.addSeries({
                type: 'column',
                name: "Desktop (embodied)",
                cursor: 'pointer',
                color: this.greenit_grey_color[0],
                //dashStyle: this.linestyle,
                data: desktopG
            }, false);

            // Push printer
            this.chart.addSeries({
                type: 'column',
                name: "Printer (embodied)",
                cursor: 'pointer',
                color: this.greenit_grey_color[1],
                //dashStyle: this.linestyle,
                data: printerG
            }, false);

            // Push desktop
            this.chart.addSeries({
                type: 'column',
                name: "Laptop (embodied)",
                cursor: 'pointer',
                color: this.greenit_grey_color[2],
                //dashStyle: this.linestyle,
                data: laptopG
            }, false);

            // Push screen
            this.chart.addSeries({
                type: 'column',
                name: "Screen (embodied)",
                cursor: 'pointer',
                color: this.greenit_grey_color[3],
                //dashStyle: this.linestyle,
                data: screenG
            }, false);

            // Push network
            this.chart.addSeries({
                type: 'column',
                name: "Network (embodied)",
                cursor: 'pointer',
                color: this.greenit_grey_color[4],
                //dashStyle: this.linestyle,
                data: networkG
            }, false);

            // Push other
            this.chart.addSeries({
                type: 'column',
                name: "Other (embodied)",
                cursor: 'pointer',
                color: this.greenit_grey_color[5],
                //dashStyle: this.linestyle,
                data: otherG
            }, false);
        }

        // Hide chart loading
        this.chart.hideLoading();

        // Redraw
        this.chart.redraw();

    }

    /**
     *  Storage & Network extrapolation
     */
    private setExtrapolationChartData(): void {

        // Init vars
        let div = 1;
        let yname: string = "kg CO2eq";
        let serverlegend: string;
        let ratio: number;
        let globalratio: number = this.host_ratio;

        if (this.target == GreenitTarget.EXTRAPOLATION_STORAGE_CO2) {
            serverlegend = "Storage extrapolation (direct)";
            ratio = this.storage_ratio;
            // FIX globalratio
            if (this.network_data_check)
                globalratio += this.network_ratio;

        } else if (this.target == GreenitTarget.EXTRAPOLATION_NETWORK_CO2) {
            serverlegend = "Network extrapolation (direct)";
            ratio = this.network_ratio;
            // FIX globalratio
            if (this.storage_data_check)
                globalratio += this.storage_ratio;
        }

        let times = [];
        let values = [];


        for (let i in this.greenit_data) {
            if (this.greenit_data[i] != undefined) {
                let lastTime = this.formatLastTime(parseInt(this.greenit_data[i].time));

                let lastApp = this.greenit_data[i].app;

                // Save points
                times[i] = lastTime;
                values[i] = Number((parseFloat(lastApp) / div).toFixed(2));

                // Apply extrapolation ratio from filter data
                let tmp_data = (values[i] * ratio) / globalratio;
                values[i] = Number((parseFloat(String(tmp_data)) / div).toFixed(2));

            }
        }

        // Set categories
        this.chart.xAxis[0].setCategories(times);

        // Update chart title
        this.chart.update({
            yAxis: {
                title: {
                    text: yname
                }
            }
        });

        if (this.greenit_resource == Resource.WH) {
            this.chart.update({
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: false
                        }
                    }
                }
            });
        }

        // Push hosts
        this.chart.addSeries({
            type: 'column',
            name: serverlegend,
            cursor: 'pointer',
            color: this.greenit_color[0],
            //dashStyle: this.linestyle,
            data: values
        }, false);

        // Hide chart loading
        this.chart.hideLoading();

        // Redraw
        this.chart.redraw();

    }

    /**
     * ALL INCLUSIVE CF (APP, AWS, AZURE, GCP) CO2Scope
     */
    private async setAllInclusiveChartData(): Promise < void > {


        this.greenit_cloud_aws_data = [];
        this.greenit_cloud_azure_data = [];
        this.greenit_cloud_gcp_data = [];

        // GET AWS (async)
        if (this.aws_monitor_check) {
            try {
                let aws_data = await this.mgt_svc.getRegions('aws').pipe(first()).toPromise();
                if (aws_data.length > 0) {
                    let aws_regions: any = [];
                    this.message.isValidAws = true;
                    for (let i = 0; i < aws_data.length; i++) {
                        aws_regions.push(aws_data[i]);
                    }
                    this.aggregateProviderData(aws_regions, 'AWS');
                }
            } catch {
                // Nothing, already on console
            }
        }

        // GET Azure (async)
        if (this.azure_monitor_check) {
            try {
                let aws_data = await this.mgt_svc.getRegions('azure').pipe(first()).toPromise();
                if (aws_data.length > 0) {
                    let aws_regions: any = [];
                    this.message.isValidAws = true;
                    for (let i = 0; i < aws_data.length; i++) {
                        aws_regions.push(aws_data[i]);
                    }
                    this.aggregateProviderData(aws_regions, 'Azure');
                }
            } catch {
                // Nothing, already on console
            }
        }

        // GET GCP (async)
        if (this.gcp_monitor_check) {
            try {
                let gcp_data = await this.mgt_svc.getRegions('gcp').pipe(first()).toPromise();
                if (gcp_data.length > 0) {
                    let gcp_regions: any = [];
                    this.message.isValidGcp = true;
                    for (let i = 0; i < gcp_data.length; i++) {
                        gcp_regions.push(gcp_data[i]);
                    }
                    this.aggregateProviderData(gcp_regions, 'GCP');
                }
            } catch {
                // Nothing, already on console
            }
        }

        // Init vars
        let times = [];
        let it = [];
        let itG = [];

        // FIX globalratio for extrapolations
        let globalratio: number = this.host_ratio;
        if (this.network_data_check)
            globalratio += this.network_ratio;
        if (this.storage_data_check)
            globalratio += this.storage_ratio;


        if (this.greenit_data.length > 0) {
            for (let i in this.greenit_data) {
                if (this.greenit_data[i] != undefined) {
                    let lastTime = this.formatLastTime(parseInt(this.greenit_data[i].time));

                    // Greenit 'filter' value
                    let lastFilter = this.greenit_data[i].app;
                    let value = Number((parseFloat(lastFilter)).toFixed(2));

                    // If no storage data, apply storage extrapolation ratio from filter data
                    let storage_value = 0;
                    if (!this.storage_data_check) {
                        let tmp_data = (value * this.storage_ratio) / globalratio;
                        storage_value = Number((parseFloat(String(tmp_data))).toFixed(2));
                    }

                    // If no network data, apply network extrapolation ratio from filter data
                    let network_value = 0;
                    if (!this.network_data_check) {
                        let tmp_data = (value * this.network_ratio) / globalratio;
                        network_value = Number((parseFloat(String(tmp_data))).toFixed(2));
                    }

                    // Cloud AWS / Azure /Gcp values
                    let cloud_direct: number = 0;
                    let cloud_grey: number = 0;
                    for (let k = 0; k < this.greenit_cloud_aws_data.length; k++) {
                        if (this.isSameMonth(this.greenit_cloud_aws_data[k].month, lastTime)) {
                            cloud_direct += this.greenit_cloud_aws_data[k].direct;
                            cloud_grey += this.greenit_cloud_aws_data[k].grey;
                        }
                    }
                    for (let k = 0; k < this.greenit_cloud_azure_data.length; k++) {
                        if (this.isSameMonth(this.greenit_cloud_azure_data[k].month, lastTime)) {
                            cloud_direct += this.greenit_cloud_azure_data[k].direct;
                            cloud_grey += this.greenit_cloud_azure_data[k].grey;
                        }
                    }
                    for (let k = 0; k < this.greenit_cloud_gcp_data.length; k++) {
                        if (this.isSameMonth(this.greenit_cloud_gcp_data[k].month, lastTime)) {
                            cloud_direct += this.greenit_cloud_gcp_data[k].direct;
                            cloud_grey += this.greenit_cloud_gcp_data[k].grey;
                        }
                    }

                    // Save Direct points
                    times[i] = lastTime;
                    it[i] = Number(Number(value + storage_value + network_value + cloud_direct).toFixed(2));

                    // Save Grey points
                    if (this.greenit_resource == Resource.CO2) {
                        let lastHostG = this.greenit_data[i].appG;

                        itG[i] = Number((parseFloat(lastHostG) + cloud_grey).toFixed(2));
                    }
                }
            }
        } else {
            // Only Cloud (AWS / Azure / GCP)

            let months: string[] = [];

            // Init months
            for (let j in this.greenit_cloud_aws_data) {
                if (!months.includes(this.greenit_cloud_aws_data[j].month))
                    months.push(this.greenit_cloud_aws_data[j].month);
            }

            for (let j in this.greenit_cloud_azure_data) {
                if (!months.includes(this.greenit_cloud_azure_data[j].month))
                    months.push(this.greenit_cloud_azure_data[j].month);
            }

            for (let j in this.greenit_cloud_gcp_data) {
                if (!months.includes(this.greenit_cloud_gcp_data[j].month))
                    months.push(this.greenit_cloud_gcp_data[j].month);
            }

            for (let i in months) {
                if (months[i] != undefined) {

                    let cloud_direct: number = 0;
                    let cloud_grey: number = 0;

                    for (let j in this.greenit_cloud_aws_data) {
                        if (months[i] == this.greenit_cloud_aws_data[j].month) {
                            cloud_direct += this.greenit_cloud_aws_data[j].direct;
                            cloud_grey += this.greenit_cloud_aws_data[j].grey;
                        }
                    }
                    for (let j in this.greenit_cloud_azure_data) {
                        if (months[i] == this.greenit_cloud_azure_data[j].month) {
                            cloud_direct += this.greenit_cloud_azure_data[j].direct;
                            cloud_grey += this.greenit_cloud_azure_data[j].grey;
                        }
                    }
                    for (let j in this.greenit_cloud_gcp_data) {
                        if (months[i] == this.greenit_cloud_gcp_data[j].month) {
                            cloud_direct += this.greenit_cloud_gcp_data[j].direct;
                            cloud_grey += this.greenit_cloud_gcp_data[j].grey;
                        }
                    }

                    let lastMoment = moment(months[i], "YYYY MMM");
                    let lastTime = lastMoment.unix() * 1000;

                    // Save Direct points
                    times[i] = lastTime;
                    it[i] = Number(Number(cloud_direct).toFixed(2));

                    // Save Grey points
                    if (this.greenit_resource == Resource.CO2) {
                        itG[i] = Number((cloud_grey).toFixed(2));
                    }

                }
            }
        }

        // FIX alert
        if (it.length > 0)
            this.greenit_alert = false;

        // Set categories
        this.chart.xAxis[0].setCategories(times);

        // Update chart title
        this.chart.update({
            yAxis: {
                title: {
                    text: 'kg CO2eq'
                }
            }
        });

        if (this.greenit_resource == Resource.WH) {
            this.chart.update({
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: false
                        }
                    }
                }
            });
        }

        // FIX : Remove old series
        for (var i = this.chart.series.length - 1; i >= 0; i--) {
            this.chart.series[i].remove(false);
        }

        // Push hosts
        let time: number = 0;
        this.chart.addSeries({
            type: 'column',
            name: 'All (direct)',
            cursor: 'pointer',
            color: this.greenit_color[0],
            data: it,
            events: {
                click: function(event) {
                    let time_str: string = event.point.category;
                    time = +time_str;
                }
            },
            point: {
                events: {
                    click: function() {
                        this.isPieReady = false;
                        this.loadAllInclusivePieChart(time, 'direct');
                    }.bind(this)
                }
            }
        }, false);

        if (this.greenit_resource == Resource.CO2) {
            this.chart.addSeries({
                type: 'column',
                name: "All (embodied)",
                cursor: 'pointer',
                color: this.greenit_grey_color[0],
                data: itG,
                events: {
                    click: function(event) {
                        let time_str: string = event.point.category;
                        time = +time_str;
                    }
                },
                point: {
                    events: {
                        click: function() {
                            this.isPieReady = false;
                            this.loadAllInclusivePieChart(time, 'grey');
                        }.bind(this)
                    }
                }
            }, false);
        }

        // Hide chart loading
        this.chart.hideLoading();

        // Redraw
        this.chart.redraw();

        // Show pie
        let lastTime = times.sort((a, b) => a - b).pop();
        setTimeout(() => {
            this.loadAllInclusivePieChart(lastTime, 'grey');
        }, 200);
    }

    /**
     * 
     */
    private aggregateProviderData(data: any, provider: string): void {

        let clouddata: any[];
        let current_provider = provider.toLowerCase();

        switch (current_provider) {
            case "aws":
                clouddata = this.greenit_cloud_aws_data;
                break;
            case "azure":
                clouddata = this.greenit_cloud_azure_data;
                break;
            case "gcp":
                clouddata = this.greenit_cloud_gcp_data;
                break;
        }

        let months: string[] = [];


        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + current_provider,
            JSONTarget.CLOUD_GLOBAL_CO2).subscribe(
            data2 => {
                if (data2) {

                    // Init months         
                    for (let j = 0; j < data2.length; j++) {
                        if (data2[j].month) {
                            if (!months.includes(data2[j].month)) {
                                months.push(data2[j].month);
                                let cloud_co2: any = {
                                    month: data2[j].month,
                                    direct: 0,
                                    grey: 0,
                                    it: provider
                                };
                                clouddata.push(cloud_co2);
                            }
                        }
                    }

                    // Init aggregate data per month
                    if (clouddata.length > 0) {
                        for (let j = 0; j < data2.length; j++) {
                            for (let k = 0; k < clouddata.length; k++) {
                                if (clouddata[k].month === data2[j].month) {
                                    clouddata[k].direct += data2[j].direct;
                                    clouddata[k].grey += data2[j].grey;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        );
    }

    /**
     * 
     */
    private isSameMonth(t1: string, t2: number): boolean {
        return (t1 === moment.unix(t2 / 1000).format("YYYY MMM"));
    }

    /**
     * ALL INCLUSIVE PIE (APP, AWS, AZURE, GCP)
     */
    private async loadAllInclusivePieChart(time: number, co2emission: string): Promise < void > {

        let serie_name: string = 'direct emission';
        if (co2emission == 'grey')
            serie_name = 'embodied emission';


        let target_date = moment.unix(time / 1000).format("YYYY MMM");
        let series: any = [];

        let data: any = [];
        let serie_it: any = {
            name: serie_name,
            type: 'pie',
            data: data
        };
        series.push(serie_it);

        let current_target = GreenitTarget.PIE_CO2;
        switch (co2emission) {
            case "grey":
                current_target = GreenitTarget.PIE_CO2G;
                break;
            default:
                break;
        }

        // FIX time for GREENIT
        let greenit_time = time + 24 * 3600 * 1000
        // FIX 2nd day of month
        let tmp_date_day = new Date(time).getDate();
        if (tmp_date_day == 2) {
            greenit_time = time;
        }

        // GET GREENIT DATA (async)
        let greenit_data_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this.message
            .currentFilter, current_target, greenit_time).toPromise();
        let pie_data = < any > greenit_data_subscribe;


        // Push greenit data
        if (pie_data.length > 0) {

            // Filter value
            let lastFilter = pie_data[0].filter;
            let valFilter = Number((parseFloat(lastFilter)).toFixed(2));

            // DC value
            let lastDC = pie_data[0].dc;
            let dc_value = Number((parseFloat(lastDC)).toFixed(2));
            if (dc_value > 0) {
                let data_it: any = {
                    name: 'VMware',
                    y: dc_value
                };
                data.push(data_it);
            }

            // Kube value
            let lastKube = pie_data[0].kube;
            let val = Number((parseFloat(lastKube)).toFixed(2));
            if (val > 0) {
                let data_it = {
                    name: 'Kubernetes',
                    y: val
                };
                data.push(data_it);
            }

            // Scaphandre value
            let lastScaph = pie_data[0].scaph;
            val = Number((parseFloat(lastScaph)).toFixed(2));
            if (val > 0) {
                let data_it = {
                    name: 'Scaphandre',
                    y: val
                };
                data.push(data_it);
            }

            // XClarity value
            let lastXClarity = pie_data[0].xclarity;
            val = Number((parseFloat(lastXClarity)).toFixed(2));
            if (val > 0) {
                let data_it = {
                    name: 'XClarity',
                    y: val
                };
                data.push(data_it);
            }

            // Openmanage value
            let lastOpenManage = pie_data[0].openmanage;
            val = Number((parseFloat(lastOpenManage)).toFixed(2));
            if (val > 0) {
                let data_it = {
                    name: 'OpenManage',
                    y: val
                };
                data.push(data_it);
            }

            // Openmanage value
            let lastOneView = pie_data[0].oneview;
            val = Number((parseFloat(lastOneView)).toFixed(2));
            if (val > 0) {
                let data_it = {
                    name: 'OneView',
                    y: val
                };
                data.push(data_it);
            }

            // IPMI value
            let lastIpmi = pie_data[0].ipmi;
            val = Number((parseFloat(lastIpmi)).toFixed(2));
            if (val > 0) {
                let data_it = {
                    name: 'IPMI',
                    y: val
                };
                data.push(data_it);
            }

            // Network value
            if (this.network_data_check) {
                let lastNetwork = pie_data[0].network;
                val = Number((parseFloat(lastNetwork)).toFixed(2));
                if (val > 0) {
                    let data_it = {
                        name: 'Network',
                        y: val
                    };
                    data.push(data_it);
                }
            } else {
                // Apply network extrapolation ratio from filter data
                if (valFilter > 0 && co2emission == 'direct') {
                    // FIX globalratio
                    let globalratio = this.host_ratio;
                    if (this.storage_data_check)
                        globalratio += this.storage_ratio;

                    let tmp_data = (valFilter * this.network_ratio) / globalratio;
                    let network_val = Number((parseFloat(String(tmp_data))).toFixed(2));

                    let data_it: any = {
                        name: 'Network extrapolation',
                        y: network_val
                    };
                    data.push(data_it);
                }
            }

            // Storage value
            if (this.storage_data_check) {
                let lastStorage = pie_data[0].storage;
                val = Number((parseFloat(lastStorage)).toFixed(2));
                if (val > 0) {
                    let data_it = {
                        name: 'Storage',
                        y: val
                    };
                    data.push(data_it);
                }
            } else {
                // Apply storage extrapolation ratio from filter data
                if (valFilter > 0 && co2emission == 'direct') {
                    // FIX globalratio
                    let globalratio = this.host_ratio;
                    if (this.network_data_check)
                        globalratio += this.network_ratio;

                    let tmp_data = (valFilter * this.storage_ratio) / globalratio;
                    let storage_val = Number((parseFloat(String(tmp_data))).toFixed(2));

                    let data_it: any = {
                        name: 'Storage extrapolation',
                        y: storage_val
                    };
                    data.push(data_it);
                }
            }
        }

        // Push AWS data
        for (let i in this.greenit_cloud_aws_data) {
            if (this.greenit_cloud_aws_data[i].month === target_date) {
                let val: number = this.greenit_cloud_aws_data[i].direct;
                if (co2emission == 'grey')
                    val = this.greenit_cloud_aws_data[i].grey;

                if (val > 0) {
                    let data_it: any = {
                        name: this.greenit_cloud_aws_data[i].it,
                        y: val
                    };
                    data.push(data_it);
                }
                break;
            }
        }

        // Push Azure data
        for (let i in this.greenit_cloud_azure_data) {
            if (this.greenit_cloud_azure_data[i].month === target_date) {
                let val: number = this.greenit_cloud_azure_data[i].direct;
                if (co2emission == 'grey')
                    val = this.greenit_cloud_azure_data[i].grey;

                if (val > 0) {
                    let data_it: any = {
                        name: this.greenit_cloud_azure_data[i].it,
                        y: val
                    };
                    data.push(data_it);
                }
                break;
            }
        }

        // Push Gcp data
        for (let i in this.greenit_cloud_gcp_data) {
            if (this.greenit_cloud_gcp_data[i].month === target_date) {
                let val: number = this.greenit_cloud_gcp_data[i].direct;
                if (co2emission == 'grey')
                    val = this.greenit_cloud_gcp_data[i].grey;

                if (val > 0) {
                    let data_it: any = {
                        name: this.greenit_cloud_gcp_data[i].it,
                        y: val
                    };
                    data.push(data_it);
                }
                break;
            }
        }

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: 'Distribution of carbon emission by IT',
                style: {
                    color: 'grey',
                    fontSize: '20px'
                }
            },
            subtitle: {
                text: serie_name + ' - ' + target_date
            },
            legend: {
                enabled: false
            },
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: '340px',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: series
        };

        this.isPieReady = true;
    }

    /**
     * STROAGE PIE (STATIC, EXAGRID, IBM)
     */
    private async loadStoragePieChart(time: number, co2emission: string): Promise < void > {

        let serie_name: string = 'direct emission';
        if (co2emission == 'grey')
            serie_name = 'embodied emission';


        let target_date = moment.unix(time / 1000).format("YYYY MMM");
        let series: any = [];

        let data: any = [];
        let serie_it: any = {
            name: serie_name,
            type: 'pie',
            data: data
        };
        series.push(serie_it);

        let current_target = GreenitTarget.PIE_STORAGE_CO2;
        switch (co2emission) {
            case "grey":
                current_target = GreenitTarget.PIE_STORAGE_CO2G;
                break;
            default:
                break;
        }

        // FIX time for GREENIT
        let greenit_time = time + 24 * 3600 * 1000
        // FIX 2nd day of month
        let tmp_date_day = new Date(time).getDate();
        if (tmp_date_day == 2) {
            greenit_time = time;
        }

        // GET GREENIT DATA (async)
        let greenit_data_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this.message
            .currentFilter, current_target, greenit_time).toPromise();
        let pie_data = < any > greenit_data_subscribe;

        // Push greenit data
        if (pie_data.length > 0) {

            // Filter value
            let lastFilter = pie_data[0].filter;
            let valFilter = Number((parseFloat(lastFilter)).toFixed(2));

            // Exagrid_san value
            let lastExagrid = pie_data[0].exagridsan;
            let valExagrid = Number((parseFloat(lastExagrid)).toFixed(2));
            if (valExagrid > 0) {
                let data_it = {
                    name: 'Exagrid',
                    y: valExagrid
                };
                data.push(data_it);
            }

            // Ibm_san value
            let lastIbm = pie_data[0].ibmsan;
            let valIbm = Number((parseFloat(lastIbm)).toFixed(2));
            if (valIbm > 0) {
                let data_it = {
                    name: 'Ibm',
                    y: valIbm
                };
                data.push(data_it);
            }

            // Static storage devices values
            let valStatic = valFilter - (valExagrid + valIbm);
            valStatic = valStatic < 0 ? 0 : valStatic;

            if (valStatic > 0) {
                let data_it = {
                    name: 'Static devices',
                    y: valStatic
                };
                data.push(data_it);
            }

        }

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: 'Distribution of carbon emission by IT',
                style: {
                    color: 'grey',
                    fontSize: '20px'
                }
            },
            subtitle: {
                text: serie_name + ' - ' + target_date
            },
            legend: {
                enabled: false
            },
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: '340px',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: series
        };

        this.isPieReady = true;
    }

    /**
     * 
     */
    private updateDataSettings(data: any) {
        // Update storage extrapoloation ratio
        let storage_entry = data.filter((e: {
            option: string;
        }) => e.option === "storage.direct.ratio");
        if (storage_entry[0])
            this.storage_ratio = Number(storage_entry[0].value);

        // Update network extrapolation ratio
        let network_entry = data.filter((e: {
            option: string;
        }) => e.option === "network.direct.ratio");
        if (network_entry[0])
            this.network_ratio = Number(network_entry[0].value);


        // Compute host part from previous data
        this.host_ratio = 100 - this.storage_ratio - this.network_ratio;
        if (this.host_ratio < 0)
            this.host_ratio = 0;
    }

    /**
     *
     */
    private formatLastTime(lastTime: number): number {
        //XXX in order to show last month number (first day of month minus a day)
        let tmp_time = lastTime - 24 * 3600 * 1000;

        //FIX 2nd day of month
        let tmp_date_day = new Date(lastTime).getDate();
        if (tmp_date_day == 2) {
            tmp_time = lastTime;
        }

        return tmp_time;
    }

}
