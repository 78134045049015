import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';

import {
    SimulationData
} from '@app/capaplan/capaplan.enums';
import {
    environment
} from '@environments/environment';
import {
    Observable
} from 'rxjs';
import {
    map
} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CapaplanService {

    constructor(private http: HttpClient) {}


    /**
     * CAPAPLAN
     */
    saveCapaSimulationConfigFile(user: string, filter: string, content: JSON) {
        return this.http.post < String > (`${environment.apiUrl}/` + 'capaplan/savesimulation', [user, filter,
            content
        ]);
    }

    removeCapaSimulationFiles(user: string, filter: string) {
        return this.http.post < String > (`${environment.apiUrl}/` + 'capaplan/delsimulationfiles', [user,
            filter
        ]);
    }

    runSimulation(user: string, filter: string) {
        return this.http.post(`${environment.apiUrl}/` + 'capaplan/runsimulation', [user, filter]);
    }

    generateReport(user: string, filter: string): Observable < Blob > {
        return this.http.get(`${environment.apiUrl}/` + 'capaplan/generatereport?user=' + user + '&filter=' +
            filter, {
                responseType: 'blob'
            });
    }

    /**
     * VDC / RP synthesis
     */
    getEvolution(uuid: string) {
        return this.http.get(`${environment.apiUrl}/` + 'capaplan/getevolution?uuid=' + uuid);
    }

    /**
     * TEMPLATES
     */
    updateTemplates(data: JSON) {
        return this.http.post(`${environment.apiUrl}/` + 'capaplan/savetemplates', data);
    }

    getTemplates(user: string) {
        return this.http.get(`${environment.apiUrl}/` + 'capaplan/gettemplates?user=' + user).pipe(map(data => {
            return data;
        }));
    }

    /**
     * SIMULATION
     */
    pushSimulation(simu: SimulationData) {
        return this.http.post(`${environment.apiUrl}/` + 'capaplan/pushsimulation', simu);
    }

    removeSimulation(simu: SimulationData) {
        return this.http.post(`${environment.apiUrl}/` + 'capaplan/removesimulation', simu);
    }

    getSimulations(user: string) {
        return this.http.get < SimulationData[] > (`${environment.apiUrl}/` + 'capaplan/getsimulations?user=' +
                user)
            .pipe(map(data => {
                return data;
            }));
    }

}
