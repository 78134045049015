import {
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    Subject
} from 'rxjs';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    AccountService,
    JsonloaderService,
    MeasurementService,
    ShareService
} from '@app/services';

import {
    Counter,
    JSONTarget,
    Message,
    User
} from '@app/model';

import * as moment from 'moment';

import * as Highcharts from "highcharts/highstock";
//import { Options } from "highcharts/highstock";

import heatmap from 'highcharts/modules/heatmap';
heatmap(Highcharts);

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);


@Component({
    selector: 'app-consumption-profile',
    templateUrl: './consumption-profile.component.html',
    styleUrls: ['./consumption-profile.component.css']
})
export class ConsumptionProfileComponent implements OnInit {

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};

    dataOrder = ClrDatagridSortOrder.ASC;

    message: Message;

    moment: any = moment;

    instance_selected: any = null;

    instancesAll: any = [];

    instances: any = [];

    months: any = [];

    years: any = [];

    start: number = 0;

    end: number = 0;

    cons_selected: string = 'monthly';

    month_selected: string = '';

    year_selected: string = '';

    counter_selected: string = 'cpu usage';

    rollup_selected: string = 'average';

    isRollup: boolean = true;

    isReady: boolean = false;

    isReady2: boolean = false;

    state: string = 'loading ...';

    overall: any = [];

    isCompute: boolean = true;

    private requests_counter: Counter[] = [];

    private requests_counterstorage: Counter[] = [];

    region_str: string = '';

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private measurement_svc: MeasurementService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        let first_instance: any = null;

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_INSTANCE).subscribe(
            data => {
                this.instancesAll = data;
                this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this
                    .message.cloudProvider, JSONTarget.CLOUD_MONTH).subscribe(
                    data2 => {
                        this.months = data2;
                        this.setYears();
                        let val: string = '';
                        val = this.months[0].name;
                        this.month_selected = val;
                        this.setDate();
                        for (let obj of this.instancesAll[val.replace(/ /g, "_")]) {
                            if (obj.state != "destroy") {
                                first_instance = obj;
                                obj['fullName'] = obj.name + ' (' + obj.instanceId + ')';
                                this.instances.push(obj);
                            }
                        }

                        if (first_instance != null) {
                            this.instance_selected = first_instance;
                            /*if(this.message.cloudProvider == "aws")
                              this.instance_selected = first_instance.instanceId + ' (' + first_instance.name + ')';
                            else if(this.message.cloudProvider == "azure")
                              this.instance_selected = first_instance.name;*/

                            this.measurement_svc.getInstanceCounters(
                                first_instance.identifier,
                                this.start - 3600000,
                                this.end - 3600000,
                                'HOURLY').pipe(first()).subscribe(
                                data => {
                                    this.requests_counter = this.requests_counter.concat(data);
                                    this.reloadCons();
                                },
                                error => {
                                    if (error != null)
                                        console.log(error)
                                }
                            );
                        }
                    }
                );
            }
        );
    }

    loadData(): void {

        this.isReady = false;
        this.isReady2 = false;

        if (this.cons_selected == "overall") {
            if (this.overall.length == 0) {
                this.state = "loading ...";
                this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this
                    .message.cloudProvider, JSONTarget.CLOUD_INSTANCE_COUNTER).subscribe(
                    data => {
                        this.overall = data;
                        this.isReady2 = true;
                    }
                );
            } else {
                this.isReady2 = true;
            }
        } else {
            this.state = "loading ...";
            if (this.cons_selected == "yearly") {
                const d = new Date(this.year_selected + "-01-15T00:00:00");
                this.start = moment(d).utc().startOf('year').unix() * 1000;
                this.end = moment(d).utc().endOf('year').unix() * 1000;
            } else {
                this.setDate();
            }

            let ins: any;
            if (this.message.cloudProvider == "aws") {
                let id: string = this.instance_selected.instanceId;
                ins = this.filterInstance(id)
            } else if (this.message.cloudProvider == "azure" || this.message.cloudProvider == "gcp") {
                ins = this.filterInstanceByName(this.instance_selected.name);
            }

            if (ins != null) {
                if (this.counter_selected == "disk read" || this.counter_selected == "disk write") {
                    this.isCompute = false;
                    this.requests_counterstorage = [];
                    this.measurement_svc.getInstanceStorageCounters(
                        ins.identifier,
                        this.start - 3600000,
                        this.end - 3600000,
                        'HOURLY').pipe(first()).subscribe(
                        data => {
                            this.requests_counterstorage = this.requests_counterstorage.concat(data);
                            if (this.cons_selected == "yearly")
                                this.reloadConsYear();
                            else
                                this.reloadConsMonth();
                        },
                        error => {
                            if (error != null)
                                console.log(error)
                        }
                    );
                } else {
                    this.isCompute = true;
                    this.requests_counter = [];
                    this.measurement_svc.getInstanceCounters(
                        ins.identifier,
                        this.start - 3600000,
                        this.end - 3600000,
                        'HOURLY').pipe(first()).subscribe(
                        data => {
                            this.requests_counter = this.requests_counter.concat(data);
                            if (this.cons_selected == "yearly")
                                this.reloadConsYear();
                            else
                                this.reloadConsMonth();
                        },
                        error => {
                            if (error != null)
                                console.log(error)
                        }
                    );
                }
            }
        }
    }

    reloadCons(): void {

        if (this.cons_selected == "yearly")
            this.reloadConsYear();
        else
            this.reloadConsMonth();
    }

    reloadConsMonth(): void {

        let maxVal: number = 0;
        let metric_str: string = 'cpu usage';
        let unit: string = '';

        let datas: any = [];
        if (this.isCompute) {
            for (let i = 0; i < this.requests_counter.length; i++) {
                let date: string = moment(this.requests_counter[i].time).utc().format('YYYY-MM-DD');
                let hour: number = moment(this.requests_counter[i].time).utc().hour();
                let monthDay: number = moment(this.requests_counter[i].time).utc().date();

                let result: any = this.toDict(this.requests_counter[i].dataPoints);
                let value: number = 0;
                metric_str = this.counter_selected;
                console.log(this.counter_selected);
                switch (this.counter_selected) {
                    case "cpu usage":
                        this.isRollup = true;
                        unit = '%';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_CPU_INTRA;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_CPU_INTRA;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_CPU_INTRA;
                        break;
                    case "credit usage":
                        this.isRollup = false;
                        value = result.CPU_CREDITUSAGE;
                        break;
                    case "credit balance":
                        this.isRollup = false;
                        value = result.CPU_CREDITBALANCE;
                        break;
                    case "credit surplus charged":
                        this.isRollup = false;
                        value = result.CPU_SURPLUSCREDITSCHARGED;
                        break;
                    case "ram usage":
                        this.isRollup = true;
                        unit = '%';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_RAM_INTRA;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_RAM_INTRA;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_RAM_INTRA;
                        break;
                    case "network in":
                        this.isRollup = true;
                        unit = '';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_NET_IN;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_NET_IN;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_NET_IN;
                        break;
                    case "network out":
                        this.isRollup = true;
                        unit = '';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_NET_OUT;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_NET_OUT;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_NET_OUT;
                        break;
                    default:
                        break;
                }

                maxVal = Math.max(maxVal, value);
                if (value > 0)
                    datas.push([monthDay, hour, value, date]);
            }
        } else {
            for (let i = 0; i < this.requests_counterstorage.length; i++) {
                let date: string = moment(this.requests_counterstorage[i].time).utc().format('YYYY-MM-DD');
                let hour: number = moment(this.requests_counterstorage[i].time).utc().hour();
                let yearDay: number = moment(this.requests_counterstorage[i].time).utc().dayOfYear();

                let result: any = this.toDict(this.requests_counterstorage[i].dataPoints);
                let value: number = 0;
                metric_str = this.counter_selected;
                switch (this.counter_selected) {
                    case "disk read":
                        this.isRollup = true;
                        unit = '';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_STORAGE_DISK_READ / 1024;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_STORAGE_DISK_READ / 1024;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_STORAGE_DISK_READ / 1024;
                        break;
                    case "disk write":
                        this.isRollup = true;
                        unit = '';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_STORAGE_DISK_WRITE;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_STORAGE_DISK_WRITE;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_STORAGE_DISK_WRITE;
                        break;
                    default:
                        break;
                }

                maxVal = Math.max(maxVal, value);
                if (value > 0)
                    datas.push([hour, yearDay, value, date]);
            }
        }

        let title: string = '';

        if (datas.length > 0) {
            this.chartOptions = {
                credits: {
                    enabled: false
                },
                chart: {
                    type: 'heatmap'
                },
                boost: {
                    useGPUTranslations: true
                },
                title: {
                    text: title,
                },
                xAxis: {
                    opposite: true,
                    title: {
                        text: 'day of month',
                        align: 'middle'
                    },
                    labels: {
                        formatter: function() {
                            return '<span class="h5">' + this.value + '</span>'
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: 'hour'
                    },
                    labels: {
                        formatter: function() {
                            return String(this.value) + "h00";
                        }
                    },
                    minPadding: 0,
                    maxPadding: 0,
                    startOnTick: false,
                    endOnTick: false,
                    tickPositions: [1, 4, 8, 12, 16, 20, 24],
                    tickWidth: 0.5,
                    min: 0,
                    max: 23,
                    reversed: true
                },
                colorAxis: {
                    min: 0,
                    max: maxVal,
                    gridLineWidth: 1,
                    gridLineColor: 'white',
                    stops: [
                        [0, '#3060cf'],
                        [0.5, '#fffbbc'],
                        [0.9, '#c4463a']
                    ]
                },
                legend: {
                    align: 'right',
                    layout: 'vertical',
                    margin: 0,
                    verticalAlign: 'top',
                    y: 25,
                    symbolHeight: 280
                },
                tooltip: {
                    formatter: function() {
                        let text;
                        if (this.point.value) {
                            let date: string = '';
                            for (let i = 0; i < datas.length; i++) {
                                if (datas[i][0] == this.point.x && datas[i][1] == this.point.y) {
                                    date = datas[i][3];
                                    break;
                                }
                            }
                            text = date + ' [' + (this.point.y) + '-' + (this.point.y + 1) + 'h]<br>' +
                                metric_str + ': ' + this.point.value.toLocaleString("fr-FR", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) + ' ' + unit;

                        } else {
                            text = 'No data available'
                        }
                        return text
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                series: [{
                    type: 'heatmap',
                    turboThreshold: 8800,
                    borderWidth: 0.1,
                    borderColor: 'black',
                    data: datas,
                    dataLabels: {
                        enabled: false,
                        color: '#000000'
                    }
                }]
            };
            this.isReady = true;
        } else {
            this.state = "no data";
            this.isReady = false;
        }
    }

    reloadConsYear(): void {

        let maxVal: number = 0;
        let metric_str: string = 'cpu usage';
        let unit: string = '';

        let datas: any = [];
        if (this.isCompute) {
            for (let i = 0; i < this.requests_counter.length; i++) {
                let date: string = moment(this.requests_counter[i].time).utc().format('YYYY-MM-DD');
                let hour: number = moment(this.requests_counter[i].time).utc().hour();
                let yearDay: number = moment(this.requests_counter[i].time).utc().dayOfYear();

                let result: any = this.toDict(this.requests_counter[i].dataPoints);
                let value: number = 0;
                metric_str = this.counter_selected;
                switch (this.counter_selected) {
                    case "cpu usage":
                        this.isRollup = true;
                        unit = '%';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_CPU_INTRA;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_CPU_INTRA;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_CPU_INTRA;
                        break;
                    case "credit usage":
                        this.isRollup = false;
                        value = result.CPU_CREDITUSAGE;
                        break;
                    case "credit balance":
                        this.isRollup = false;
                        value = result.CPU_CREDITBALANCE;
                        break;
                    case "credit surplus charged":
                        this.isRollup = false;
                        value = result.CPU_SURPLUSCREDITSCHARGED;
                        break;
                    case "ram usage":
                        this.isRollup = true;
                        unit = '%';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_RAM_INTRA;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_RAM_INTRA;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_RAM_INTRA;
                        break;
                    case "network in":
                        this.isRollup = true;
                        unit = '';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_NET_IN;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_NET_IN;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_NET_IN;
                        break;
                    case "network out":
                        this.isRollup = true;
                        unit = '';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_NET_OUT;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_NET_OUT;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_NET_OUT;
                        break;
                    default:
                        break;
                }

                maxVal = Math.max(maxVal, value);
                if (value > 0)
                    datas.push([hour, yearDay, value, date]);
            }
        } else {
            for (let i = 0; i < this.requests_counterstorage.length; i++) {
                let date: string = moment(this.requests_counterstorage[i].time).utc().format('YYYY-MM-DD');
                let hour: number = moment(this.requests_counterstorage[i].time).utc().hour();
                let yearDay: number = moment(this.requests_counterstorage[i].time).utc().dayOfYear();

                let result: any = this.toDict(this.requests_counterstorage[i].dataPoints);
                let value: number = 0;
                metric_str = this.counter_selected;
                switch (this.counter_selected) {
                    case "disk read":
                        this.isRollup = true;
                        unit = '%';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_STORAGE_DISK_READ;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_STORAGE_DISK_READ;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_STORAGE_DISK_READ;
                        break;
                    case "disk write":
                        this.isRollup = true;
                        unit = '%';
                        if (this.rollup_selected == "average")
                            value = result.AVG_INSTANCE_STORAGE_DISK_WRITE;
                        else if (this.rollup_selected == "min")
                            value = result.MIN_INSTANCE_STORAGE_DISK_WRITE;
                        else if (this.rollup_selected == "max")
                            value = result.MAX_INSTANCE_STORAGE_DISK_WRITE;
                        break;
                    default:
                        break;
                }

                maxVal = Math.max(maxVal, value);
                if (value > 0)
                    datas.push([hour, yearDay, value, date]);
            }
        }

        let title: string = '';

        if (datas.length > 0) {
            this.chartOptions = {
                credits: {
                    enabled: false
                },
                chart: {
                    type: 'heatmap'
                },
                boost: {
                    useGPUTranslations: true
                },
                title: {
                    text: title,
                },
                xAxis: {
                    title: {
                        text: 'hours of the day',
                        align: 'middle'
                    },
                    opposite: true,
                    labels: {
                        formatter: function() {
                            return '<span class="h5">' + this.value + 'h</span>'
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: 'days',
                    },
                    //type: 'datetime',
                    labels: {
                        formatter: function() {
                            let dateStr: string = "";
                            for (let i = 0; i < datas.length; i++) {
                                if (datas[i][1] == this.value) {
                                    dateStr = datas[i][3];
                                    break;
                                }
                            }

                            return dateStr;
                        }
                    },
                    //tickInterval: 24 * 60 * 60 * 1000,
                    minPadding: 0,
                    maxPadding: 0,
                    startOnTick: false,
                    endOnTick: false,
                    reversed: true
                },
                colorAxis: {
                    min: 0,
                    max: maxVal,
                    gridLineWidth: 1,
                    gridLineColor: 'white',
                    stops: [
                        [0, '#3060cf'],
                        [0.5, '#fffbbc'],
                        [0.9, '#c4463a']
                    ]
                },
                legend: {
                    align: 'right',
                    layout: 'vertical',
                    margin: 0,
                    verticalAlign: 'top',
                    y: 25,
                    symbolHeight: 280
                },
                tooltip: {
                    formatter: function() {
                        let text;
                        if (this.point.value) {
                            let date: string = '';
                            for (let i = 0; i < datas.length; i++) {
                                if (datas[i][0] == this.point.x && datas[i][1] == this.point.y) {
                                    date = datas[i][3];
                                    break;
                                }
                            }
                            text = date + ' [' + (this.point.x) + '-' + (this.point.x + 1) + 'h]<br>' +
                                metric_str + ': ' + this.point.value.toLocaleString("fr-FR", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) + ' ' + unit;

                        } else {
                            text = 'No data available'
                        }
                        return text
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                series: [{
                    type: 'heatmap',
                    turboThreshold: 8800,
                    borderWidth: 0.1,
                    borderColor: 'black',
                    data: datas,
                    //rowsize: 24 * 36e5,
                    dataLabels: {
                        enabled: false,
                        color: '#000000'
                    }
                }]
            };
            this.isReady = true;
        } else {
            this.state = "no data";
            this.isReady = false;
        }
    }

    private setYears(): void {

        for (let i = 0; i < this.months.length; i++) {
            let year: string = this.months[i].name.split(" ")[0];
            if (!this.years.includes(year))
                this.years.push(year);
        }
        if (this.years.length > 0)
            this.year_selected = this.years[0];
    }

    private setDate(): any {

        let year: string = this.month_selected.split(" ")[0];
        let month_str: string = this.month_selected.split(" ")[1];
        let month: string = "00";

        switch (month_str) {
            case "Jan":
                month = "01";
                break;
            case "Feb":
                month = "02";
                break;
            case "Mar":
                month = "03";
                break;
            case "Apr":
                month = "04";
                break;
            case "May":
                month = "05";
                break;
            case "Jun":
                month = "06";
                break;
            case "Jul":
                month = "07";
                break;
            case "Aug":
                month = "08";
                break;
            case "Sep":
                month = "09";
                break;
            case "Oct":
                month = "10";
                break;
            case "Nov":
                month = "11";
                break;
            case "Dec":
                month = "12";
                break;
            default:
                break;
        }

        const d = new Date(year + "-" + month + "-15T00:00:00");
        this.start = moment(d).utc().startOf('month').unix() * 1000;
        this.end = moment(d).utc().endOf('month').unix() * 1000;
    }

    private toDict(dataPoints: any): any {

        var result = {};
        dataPoints.forEach(dp => {
            result[dp.metricName] = dp.value
        });

        return result;
    }

    private filterInstance(id: string): any {

        return this.instances.find(item => item.instanceId === id);
    }

    private filterInstanceByName(name: string): any {

        return this.instances.find(item => item.name === name);
    }

    initInstanceList(): void {

        this.instance_selected = null;
    }
}
