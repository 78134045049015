import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import {
    AccountService,
    JsonloaderService,
    MonitorService,
    ShareService
} from '@app/services';
import {
    ReportService
} from '@app/services/report.service';

import {
    User,
    Message,
    Json,
    JSONTarget,
    FilterMgt
} from '@app/model';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    Subject,
    zip
} from 'rxjs';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faFilePdf,
    faFileExcel
} from '@fortawesome/free-solid-svg-icons';

import {
    ReportCard,
    ReportFormat
} from './report.enums';
import {
    ReportcardComponent,
    ReportType
} from './reportcard/reportcard.component';
import {
    ReportEmail,
    ReportHistory,
    ReportSettings
} from '@app/model/report';
import {
    UploadType
} from '../upload/upload.enums';
import {
    ClrDatagridSortOrder
} from '@clr/angular';


@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtable: DataTableDirective;
    @ViewChild("editcard", {
        read: ViewContainerRef
    }) editcard;


    generation_error: string = "An error has occurred during PDF/XLS generation !";
    generation_vm_error: string = "Note : reporting fails if the entity does not contain virtual machines."
    noinfos_error: string = "No settings informations found for this report !";
    noreport_error: string = "We can not found your report :(";

    message: Message;

    currentUser: User;

    jsonLoader: Json;

    dataOrderDesc = ClrDatagridSortOrder.DESC;

    // FOR TABLE
    private report_db_data: ReportHistory[];
    private min_year: number;
    private max_year: number;
    private report_data: Map < number, Map < number, Array < ReportHistory >>> ;

    current_year: number;
    current_month: number;
    noprevious_year: boolean = false;
    nonext_year: boolean = true;

    badges: any;

    datatable_data: ReportHistory[];
    datatable_hidden: boolean;

    //FOR REPORT
    management: ReportCard;
    operations: ReportCard;
    recommendations: ReportCard;
    greenit: ReportCard;
    custom: ReportCard;
    edit: ReportCard;

    pdf: ReportFormat;
    xls: ReportFormat;

    //FOR INFO
    info_modal: boolean;
    info_header: string;
    info_body: string;
    info_type: string;

    //FOR DELETE
    delete_modal_report: boolean;
    delete_uuid: string;

    //FOR UPLOAD
    report: UploadType;
    updates: UploadType;
    netdevice: UploadType;

    //FOR OBJECTS NAME
    objects_subject: Subject < any > = new Subject < any > ();
    objects_name: Map < string, any > ;

    // For trackdc instances
    vmware_monitor_check: boolean = false;

    /**
     * 
     */
    constructor(private authenticationService: AccountService, private message_svc: ShareService,
        private report_svc: ReportService, library: FaIconLibrary, private resolver: ComponentFactoryResolver,
        private json_svc: JsonloaderService, private monitor_svc: MonitorService) {

        library.addIcons(faFilePdf, faFileExcel);

        this.report_db_data = [];
        this.report_data = new Map < number, Map < number, Array < ReportHistory >>> ();

        this.min_year = new Date().getFullYear();;
        this.max_year = this.min_year;
        this.current_year = this.min_year;

        this.nonext_year = true;
        this.noprevious_year = true;

        this.badges = {
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0
        };

        //FOR REPORT
        this.management = ReportCard.MANAGE;
        this.operations = ReportCard.OPS;
        this.recommendations = ReportCard.RECO;
        this.greenit = ReportCard.GREENIT;
        this.custom = ReportCard.CUSTOM;

        this.edit = ReportCard.EDIT;
        this.pdf = ReportFormat.PDF;
        this.xls = ReportFormat.XLS

        //FOR INFO
        this.info_modal = false;

        //FOR DELETE
        this.delete_modal_report = false;

        //FOR UPLOAD
        this.report = UploadType.REPORT;
        this.updates = UploadType.UPDATES;
        this.netdevice = UploadType.NETDEVICE;

        //FOR OBJECTS NAME
        this.objects_name = new Map < string, any > ();
    }

    /**
     * 
     */
    ngOnInit(): void {
        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);

        // GET VIEWS
        let host_view = this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
            .HOST_VIEW);
        let fd_view = this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.FD_VIEW);
        let rp_view = this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RP_VIEW);
        let tag_view = this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
            .TAG_VIEW);

        zip(host_view, fd_view, rp_view, tag_view).subscribe(
            all => {
                for (let i in all) {
                    this.searchChildren(all[i]);
                }
                // Notify child
                this.objects_subject.next(this.objects_name);
            }
        );

        // Get report history
        this.updateHistory();

        // Check trackdc instances
        this.monitor_svc.vmwareCheck.subscribe((details) => (this.vmware_monitor_check = details));
    }

    /**
     * 
     */
    ngAfterViewInit(): void {}

    /**
     * 
     */
    updateHistory(): void {
        this.report_svc.getReportHistory(this.currentUser.login).subscribe(
            data => {
                this.report_db_data = data;

                this.report_data = new Map < number, Map < number, Array < ReportHistory >>> ();

                if (this.report_db_data.length > 0) {

                    // Get min/max years
                    let entry = this.report_db_data[0];
                    this.min_year = new Date(entry.time).getFullYear();
                    entry = this.report_db_data[this.report_db_data.length - 1];
                    this.max_year = new Date(entry.time).getFullYear();
                    this.current_month = new Date(entry.time).getMonth() + 1; // (January gives 0)

                    // Init years
                    this.current_year = this.max_year;
                    this.nonext_year = true;

                    if (this.min_year != this.max_year)
                        this.noprevious_year = false;

                    // Init empty map
                    for (let i = this.min_year; i <= this.max_year; i++) {
                        let y = new Map < number,
                            Array < ReportHistory >> ();
                        for (let j = 1; j <= 12; j++) {
                            y.set(j, new Array());
                        }
                        this.report_data.set(i, y);
                    }

                    // Populate
                    for (let entry of this.report_db_data) {
                        let entry_date = new Date(entry.time);
                        let entry_year = entry_date.getFullYear();
                        let entry_month = entry_date.getMonth() + 1; // (January gives 0)
                        let array = this.report_data.get(entry_year).get(entry_month);
                        array.push(entry);
                    }

                    // Update badges
                    this.updateBadges();

                    // Update tabs
                    this.updateActiveTab();

                    // Update datatable infos
                    this.updateDatatable(this.current_month);

                }
            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );

    }

    /**
     * 
     */
    updateBadges(): void {
        let rows = this.report_data.get(this.current_year);

        if (rows != undefined) {
            this.badges.jan = rows.get(1).length;
            this.badges.feb = rows.get(2).length;
            this.badges.mar = rows.get(3).length;
            this.badges.apr = rows.get(4).length;
            this.badges.may = rows.get(5).length;
            this.badges.jun = rows.get(6).length;
            this.badges.jul = rows.get(7).length;
            this.badges.aug = rows.get(8).length;
            this.badges.sep = rows.get(9).length;
            this.badges.oct = rows.get(10).length;
            this.badges.nov = rows.get(11).length;
            this.badges.dec = rows.get(12).length;
        }
    }

    /**
     * 
     */
    updateActiveTab(): void {
        this.badges.jan_tab = false;
        this.badges.feb_tab = false;
        this.badges.mar_tab = false;
        this.badges.apr_tab = false;
        this.badges.may_tab = false;
        this.badges.jun_tab = false;
        this.badges.jul_tab = false;
        this.badges.aug_tab = false;
        this.badges.sep_tab = false;
        this.badges.oct_tab = false;
        this.badges.nov_tab = false;
        this.badges.dec_tab = false;

        // Looking for the first tab to activate
        let found: boolean = false;

        if (!found && this.badges.dec > 0) {
            this.badges.dec_tab = true;
            found = true;
            this.current_month = 12;
        }
        if (!found && this.badges.nov > 0) {
            this.badges.nov_tab = true;
            found = true;
            this.current_month = 11;
        }
        if (!found && this.badges.oct > 0) {
            this.badges.oct_tab = true;
            found = true;
            this.current_month = 10;
        }
        if (!found && this.badges.sep > 0) {
            this.badges.sep_tab = true;
            found = true;
            this.current_month = 9;
        }
        if (!found && this.badges.aug > 0) {
            this.badges.aug_tab = true;
            found = true;
            this.current_month = 8;
        }
        if (!found && this.badges.jul > 0) {
            this.badges.jul_tab = true;
            found = true;
            this.current_month = 7;
        }
        if (!found && this.badges.jun > 0) {
            this.badges.jun_tab = true;
            found = true;
            this.current_month = 6;
        }
        if (!found && this.badges.may > 0) {
            this.badges.may_tab = true;
            found = true;
            this.current_month = 5;
        }
        if (!found && this.badges.apr > 0) {
            this.badges.apr_tab = true;
            found = true;
            this.current_month = 4;
        }
        if (!found && this.badges.mar > 0) {
            this.badges.mar_tab = true;
            found = true;
            this.current_month = 3;
        }
        if (!found && this.badges.feb > 0) {
            this.badges.feb_tab = true;
            found = true;
            this.current_month = 2;
        }
        if (!found && this.badges.jan > 0) {
            this.badges.jan_tab = true;
            found = true;
            this.current_month = 1;
        }
    }

    /**
     * 
     */
    updateDatatable(month: number): void {
        this.current_month = month;

        this.datatable_hidden = true;

        this.datatable_data = this.report_data.get(this.current_year).get(this.current_month);

        setTimeout(() => {
            this.datatable_hidden = false; // For better 'animation'
        }, 100);

    }

    /**
     * 
     */
    previousYear(): void {
        this.current_year = this.current_year - 1;
        if (this.min_year == this.current_year)
            this.noprevious_year = true;
        this.nonext_year = false;

        this.updateBadges();
        //this.updateActiveTab();
        this.updateDatatable(this.current_month);
    }

    /**
     * 
     */
    nextYear(): void {
        this.current_year = this.current_year + 1;
        if (this.max_year == this.current_year)
            this.nonext_year = true;
        this.noprevious_year = false;

        this.updateBadges();
        //this.updateActiveTab();
        this.updateDatatable(this.current_month);
    }

    /**
     * 
     */
    formatDate(time: number): string {
        return new Date(time).toLocaleString('en-EN');
    }

    /**
     * 
     */
    formatFilter(filter: string, type: string): string {
        let result: string = filter + " [" + 'UNKNOWN' + "]";

        switch (type.toLowerCase()) {
            case ReportType.VM:
                if (this.objects_name.has(filter)) {
                    let obj = this.objects_name.get(filter);
                    if (obj)
                        return obj.name + " [" + obj.type.toUpperCase() + "]";

                }
                //break;
                // add filters for ReportType.VM
            case ReportType.INFRA:
                let filterList: FilterMgt[] = this.message.filterList;
                let filterFound = filterList.find(f => f.name == filter);
                if (filterFound)
                    return filter + " [" + 'FILTER' + "]";
                break;
            default:
                break;
        }

        return result;
    }

    /**
     * 
     */
    createComponent(report_uuid: string): void {

        this.editcard.clear();
        const factory: ComponentFactory < ReportcardComponent > = this.resolver.resolveComponentFactory(
            ReportcardComponent);
        const componentRef = this.editcard.createComponent(factory);
        componentRef.instance.target = this.edit;
        componentRef.instance.objects_observable = this.objects_subject.asObservable();
        componentRef.instance.objects_name = this.objects_name;

        //Need to initialize the EventEmitter
        componentRef.instance.update.subscribe(
            res => {
                this.updateHistory();
            }
        );
        componentRef.instance.errorInfo.subscribe(
            res => {
                this.displayInfo('Error', this.generation_error, componentRef.instance.report_type);
            }
        );

        // Get report history
        let tmp_data = this.report_data.get(this.current_year).get(this.current_month);
        let history_datas = tmp_data.filter(({
            uuid
        }) => uuid == report_uuid);

        let history: ReportHistory;
        if (history_datas.length > 0) {
            history = history_datas[0];
        }

        if (history != undefined) {

            //Waiting for ngOnInit() ...
            setTimeout(() => {

                // Get report settings
                this.report_svc.getReportSettings(this.currentUser.login, report_uuid).subscribe(
                    data => {
                        let settings: ReportSettings = data;

                        if (settings != undefined) {

                            // Get emails
                            this.report_svc.getReportEmail(report_uuid).subscribe(
                                data => {
                                    let emails: ReportEmail[] = data;
                                    // Show wizard
                                    componentRef.instance.editWizard(settings, history, emails);
                                },
                                error => {
                                    if (error != null)
                                        console.log(error);
                                }
                            );

                        } else {
                            let header = "Sorry ...";
                            let body = this.noinfos_error;
                            this.displayInfo(header, body, "");
                        }
                    },
                    error => {
                        if (error != null) {
                            let header = "Sorry ...";
                            let body = this.noinfos_error;
                            this.displayInfo(header, body, "");
                        }
                    }
                );

            }, 100);

        } else {
            let header = "Sorry ...";
            let body = this.noinfos_error;
            this.displayInfo(header, body, "");
        }

    }

    /**
     * 
     */
    getReport(name: string, uuid: string, format: ReportFormat): void {

        this.report_svc.getReport(this.currentUser.login, uuid, format).subscribe(
            res => {
                //window.open(window.URL.createObjectURL(res), '_blank');

                let file_type = undefined;

                switch (format) {
                    case ReportFormat.PDF:
                        file_type = 'application/pdf';
                        break;
                    case ReportFormat.XLS:
                        file_type = 'application/vnd.ms-excel';
                        break;
                }

                // Create temp link
                let blob: Blob = new Blob([res], {
                    type: file_type
                });
                let fileName = 'Report-' + name + '-(' + uuid + ').' + format.toLowerCase();
                let objectUrl: string = URL.createObjectURL(blob);

                let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
            },
            error => {
                let header = "Sorry ...";
                let body = this.noreport_error;
                this.displayInfo(header, body, "");
            });
    }

    /**
     * 
     */
    deleteReport(): void {
        this.delete_modal_report = false;

        this.report_svc.deleteReport(this.currentUser.login, this.delete_uuid).subscribe(
            data => {
                //Remove from data
                let tmp_data = this.report_data.get(this.current_year).get(this.current_month);
                tmp_data = tmp_data.filter(({
                    uuid
                }) => uuid !== this.delete_uuid);

                //Push list without delete_uuid
                this.report_data.get(this.current_year).set(this.current_month, tmp_data);

                //Update badges
                this.updateBadges();

                //Update datatable
                this.updateDatatable(this.current_month);

                this.delete_uuid = undefined;

            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );
    }

    /**
     * 
     */
    displayInfo(header: string, body: string, type: string) {
        this.info_modal = true;
        this.info_header = header;
        this.info_body = body;
        this.info_type = type;
    }

    /**
     * 
     */
    displayModalDeleteReport(uuid: string) {
        this.delete_modal_report = true;
        this.delete_uuid = uuid;
    }

    /**
     * 
     */
    closeModal(): void {
        this.delete_modal_report = false;
        this.info_modal = false;
        this.info_body = "";
        this.info_header = "";
        this.info_type = "";
    }

    /**
     * 
     */
    private searchChildren(node: any) {
        for (let i in node) {
            // Ignore root element type
            if ((node[i].type.toLowerCase() == "tag" && node[i].id.toLowerCase() == "root") ||
                (node[i].type.toLowerCase() == "rp" && node[i].name.toLowerCase() == "resources")) {
                // ignore
            } else {
                this.objects_name.set(node[i].id, node[i]);
            }

            this.searchChildren(node[i].children);
        }
    }

    /**
     * 
     */
    showManage(): boolean {
        return this.vmware_monitor_check && (this.currentUser.isMenuCapa || this.currentUser.isMenuKpi);
    }

    /**
     * 
     */
    showOps(): boolean {
        return this.vmware_monitor_check;
    }

    /**
     * 
     */
    showReco(): boolean {
        return this.vmware_monitor_check && this.currentUser.isMenuReco;
    }

    /**
     * 
     */
    showGreenit(): boolean {
        return this.currentUser.isMenuGreenit;
    }

}
