
import Step from 'shepherd.js/src/types/step';

import {
    NetscopeCommonFlowviewComponent
} from "@app/netscope/netscope-common-flowview/netscope-common-flowview.component";

export function generateFlowComponentShepperdParameters(component: NetscopeCommonFlowviewComponent, selectedView: string, currentDateFormatted: string) {
    const builtInButtons = {
        cancel: {
            classes: 'cancel-button',
            secondary: true,
            text: 'Exit',
            type: 'cancel'
        },
        next: {
            classes: 'next-button',
            text: 'Next',
            type: 'next'
        },
        back: {
            classes: 'back-button',
            secondary: true,
            text: 'Back',
            type: 'back'
        }
    };

    const defaultStepOptions: Step.StepOptions = {
        classes: 'shepherd-theme-arrows custom-default-class',
        scrollTo: true,
        cancelIcon: {
            enabled: true
        }
    };

    let heatmapHelperUpdateCount = 0;

    let checkFlowTabInterval = undefined;

    const steps: Step.StepOptions[] = [

        {
            buttons: [
                builtInButtons.cancel,
                builtInButtons.next
            ],
            id: 'page1',
            title: component.translocoService.translate("dcnetscope.flows.help.page1.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page1.content")
        },
        {
            attachTo: {
                element: '#flow_view_title',
                on: 'bottom-end'
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
                builtInButtons.next
            ],
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
            },
            id: 'page2',
            title: component.translocoService.translate("dcnetscope.flows.help.page2.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page2.content")
        },
        {
            attachTo: {
                element: '#flow_view_datepicker_button',
                on: 'top'
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
                builtInButtons.next
            ],
            when: {
                show: function() {
                    window.scrollTo(0, 0);
                },
            },
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
            },
            id: 'dateselection2',
            title: component.translocoService.translate("dcnetscope.flows.help.page3.title"),
            text:  component.translocoService.translate("dcnetscope.flows.help.page3.content", { currentDateFormatted: currentDateFormatted })
        },{
            attachTo: {
                element: '#dateSelectionHeatmap',
                on: 'top'
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
                builtInButtons.next
            ],
            when: {
                show: function() {
                    component.openHeatmapModal = true;
                    if (heatmapHelperUpdateCount == 0) {
                        // component.openHeatmapModal = true;
                        setTimeout(() => {
                            heatmapHelperUpdateCount += 1;
                            this.show();
                        }, 300);
                    }
                },
                hide: function() {
                    component.openHeatmapModal = false;
                }
            },
            classes: 'custom-class-name-1 custom-class-name-2',
            id: 'dateselection3',
            title: component.translocoService.translate("dcnetscope.flows.help.page4.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page4.content")
        },
        {
            attachTo: {
                element: '#column-flow-combobox',
                on: 'bottom'
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
                builtInButtons.next
            ],
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
            },
            id: 'buttons',
            title: component.translocoService.translate("dcnetscope.flows.help.page5.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page5.content")
        },
        {
            attachTo: {
                element: '.btn-group',
                on: 'bottom'
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
                builtInButtons.next
            ],
            when: {
                show: function() {
                }
            },
            modalOverlayOpeningPadding: 5,
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
            },
            title: component.translocoService.translate("dcnetscope.flows.help.page6.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page6.content")
        },
        {
            attachTo: {
                element: '.signpost',
                on: 'bottom'
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
                builtInButtons.next
            ],
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
            },
            title: component.translocoService.translate("dcnetscope.flows.help.page7.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page7.content")
        },
        {
            attachTo: {
                element: '#flows_datatable_button',
                on: 'bottom'
            },
            when: {
                show: function () {
                    // checkFlowTabInterval = setInterval(() => {
                    //     let plop = this;
                    //     console.log(`active tab: ${component.flowsDatatableIsActiveTab}`);
                    // }, 200);
                },
                hide: function () {
                    clearInterval(checkFlowTabInterval)
                    checkFlowTabInterval = undefined

                    let flowDatatableButton = document.getElementById("flows_datatable_button");
                    if (flowDatatableButton !== null) {
                        flowDatatableButton.click();
                    }
                }
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
                builtInButtons.next
            ],
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
            },
            title: component.translocoService.translate("dcnetscope.flows.help.page8.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page8.content")
        },
        {
            attachTo: {
                element: '.datagrid-host',
                on: 'top'
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
                builtInButtons.next,
            ],
            when: {
                show: function () {
                    function waitForExportCsvButton (step) {
                        let element = document.getElementById("export_csv_button");
                        console.log(element);
                        if (element) {
                            step.show();
                        } else {
                            setTimeout(waitForExportCsvButton, 200, step);
                        }
                    }
                    let button = document.getElementById("export_csv_button");
                    if (button == null) {
                        waitForExportCsvButton(this);
                    }
                }
            },
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
            },
            title: component.translocoService.translate("dcnetscope.flows.help.page9.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page9.content")
        },
        {
            attachTo: {
                element: '#export_csv_button',
                on: 'top'
            },
            buttons: [
                builtInButtons.cancel,
                builtInButtons.back,
            ],
            when: {
                show: function () {
                    function waitForExportCsvButton (step) {
                        let element = document.getElementById("export_csv_button");
                        console.log(element);
                        if (element) {
                            step.show();
                        } else {
                            setTimeout(waitForExportCsvButton, 200, step);
                        }
                    }
                    let button = document.getElementById("export_csv_button");
                    if (button == null) {
                        waitForExportCsvButton(this);
                    }
                }
            },
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
            },
            title: component.translocoService.translate("dcnetscope.flows.help.page10.title"),
            text: component.translocoService.translate("dcnetscope.flows.help.page10.content")
        }
    ];

    let result = {
        builtInButtons: builtInButtons,
        defaultStepOptions: defaultStepOptions,
        steps: steps
    };

    return result;
}