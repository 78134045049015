<div class="clr-row" *ngIf="message.creditSelected == 'co2stat'">
    <div class="card">
        <div class="card-header">
            <span style="color:grey;font-size:16px;text-align: center;">
                {{model.title}}
            </span>
            <cds-icon shape="heat-map" size="sm" ngClass="addLink" title="instances distribution" style="float: right;margin-right: 10px;" (click)="loadMapChart()" *ngIf="model.isValidMap"></cds-icon>
            <cds-icon shape="view-list" size="sm" ngClass="addLink" title="instances list" style="float: right;margin-right: 10px;" (click)="loadList()" *ngIf="model.isValidList"></cds-icon>
            <cds-icon shape="view-cards" size="sm" ngClass="addLink" title="statistics" style="float: right;margin-right: 10px;" (click)="loadStat()" *ngIf="model.isValidStat"></cds-icon>
        </div>
        <div class="card-block" *ngIf="!model.isValidStat">
			<div class="clr-row">
                <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                    <div class="card">
                        <div class="card-header">
                            <span style="color:grey;font-size:16px;text-align: center;">
                                analized period
                            </span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">start date</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:14px;font-weight: bold;">{{message.cloudMessage.cloudStat.start}}</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">end date</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:14px;font-weight: bold;">{{message.cloudMessage.cloudStat.end}}</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">cumulative hours</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{message.cloudMessage.cloudStat.aggHour.toLocaleString()}}</span>
                                    <span>&nbsp;&nbsp;hours</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">vm average</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{roundValue(message.cloudMessage.cloudStat.avgHour)}}</span>
                                    <span>&nbsp;&nbsp;hours</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">vm maximum</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{message.cloudMessage.cloudStat.maxHour}}</span>
                                    <span>&nbsp;&nbsp;hours</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                    <div class="card">
                        <div class="card-header">
                            <span style="color:grey;font-size:16px;text-align: center;">
                                carbon emission
                            </span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">Total</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{roundValue(message.cloudMessage.cloudStat.aggCo2)}} </span>kgCO2 eq
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">vm average</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{message.cloudMessage.cloudStat.avgCo2.toFixed(2)}} </span>kgCO2 eq
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">vm maximum</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{message.cloudMessage.cloudStat.maxCo2.toFixed(2)}} </span>kgCO2 eq
                                    <br>
                                    <span style="font-size:14px;color: grey;">{{message.cloudMessage.cloudStat.name}} ({{message.cloudMessage.cloudStat.it}})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                    <div class="card">
                        <div class="card-header">
                            <span style="color:grey;font-size:16px;text-align: center;">
                                power consumption
                            </span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">Total</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{roundValue(message.cloudMessage.cloudStat.aggWh).toLocaleString()}} </span>
                                    {{message.cloudMessage.cloudStat.aggWhUnit}}
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">vm average</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{roundValue(message.cloudMessage.cloudStat.avgWh)}} </span>wh
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">vm maximum</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{roundValue(message.cloudMessage.cloudStat.maxWh)}} </span>wh
                                    <br>
                                    <span style="font-size:14px;color: grey;">{{message.cloudMessage.cloudStat.name}} ({{message.cloudMessage.cloudStat.it}})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                    <div class="card">
                        <div class="card-header">
                            <span style="color:grey;font-size:16px;text-align: center;">
                                {{message.cloudMessage.profile}} evolution
                            </span>
                        </div>
                        <div class="card-block">
                            <div class="clr-row">
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">Maximum</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{roundValue(message.cloudMessage.cloudStat.evolutionMax)}} </span>%
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">Minimum</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{roundValue(message.cloudMessage.cloudStat.evolutionMin)}} </span>%
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="left: right;color: grey;">Average</span>
                                </div>
                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                    <span style="font-size:16px;font-weight: bold;">{{roundValue(message.cloudMessage.cloudStat.evolutionAvg)}} </span>%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-block" *ngIf="!model.isValidList">
            <div class="datagrid-limit-height" >
                <clr-datagrid >
                    <clr-dg-column [clrDgField]="'instanceId'" *ngIf="provider == 'aws'"><ng-container *clrDgHideableColumn="{hidden: false}">Id</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Subscription</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'aws'"><ng-container *clrDgHideableColumn="{hidden: false}">Account</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'gcp'"><ng-container *clrDgHideableColumn="{hidden: false}">Project</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'region'"><ng-container *clrDgHideableColumn="{hidden: false}">Region</ng-container></clr-dg-column>
                   <clr-dg-column [clrDgField]="'resgroup'" *ngIf="provider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Resource</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'instanceType'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'state'"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'runH'"><ng-container *clrDgHideableColumn="{hidden: false}">Running hours</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'direct_power'"><ng-container *clrDgHideableColumn="{hidden: false}">Direct power (Wh)</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'co2'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Total CO2 emission (kg CO2eq)</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'co2d'"><ng-container *clrDgHideableColumn="{hidden: false}">Direct CO2 emission (kg CO2eq)</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'co2e'"><ng-container *clrDgHideableColumn="{hidden: false}">Embodied emission (kg CO2eq)</ng-container></clr-dg-column>
                    <clr-dg-row *clrDgItems="let instance of instances" [clrDgItem]="instance">
                        <clr-dg-cell *ngIf="provider == 'aws'">{{instance.identifier}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.name}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.subscriptionDesc.subscription}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.subscriptionDesc.region}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="provider == 'azure'">{{instance.subscriptionDesc.resgroup}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.it}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.state}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.hour}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.wh.toFixed(2)}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.co2.toFixed(2)}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.co2d.toFixed(2)}}</clr-dg-cell>
                        <clr-dg-cell>{{instance.co2e.toFixed(2)}}</clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                        <clr-dg-pagination #pagination [clrDgPageSize]="10">
                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                                entries per page
                            </clr-dg-page-size>
                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                        </clr-dg-pagination>
                    </clr-dg-footer>
                </clr-datagrid>
            </div>
        </div>
        <div class="card-block" *ngIf="!model.isValidMap">
            <span style="color:grey;font-size:14px;font-weight: bold;text-align: center;">{{model.mapState}}</span>
            <highcharts-chart
                            [Highcharts] = "highcharts"
                            [options] = "chartOptions"
                            [constructorType]="'chart'"
                            style = "width: 100%;height: 275px;display: block;" 
                            *ngIf="isReady">
            </highcharts-chart>
        </div>
    </div>
</div>
