<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="bar-chart"></cds-icon>
					&nbsp;Cost evolution
					<span style="float: right;" ngClass="addLink">
						<cds-icon id="kpi-cost-close" shape="times" size="24"></cds-icon>
					</span>
				</h5>
			</div>
			<div class="card-block">
				<div class="card-text">
					<highcharts-chart
						[Highcharts]="highcharts"
						[options]="chartOptions"
						[constructorType]="'chart'"
						style="width: 100%; height: 350px; display: block;" *ngIf="isReady">
					</highcharts-chart>
                		</div>
			</div>
		</div>
	</div>
</div>
