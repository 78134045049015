<div class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card" style="height:320px">
			<div class="card-header">
				<span style="color:grey;font-size:20px;">1-Average server</span>
				<span class="label" style="float:right">
					running server
					<span class="badge">{{reco_meta_data.servers}}</span>
				</span>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-4">
						<span style="font-weigth:bold;font-size:24px;float:right;">{{reco_meta_data.over_vm}}</span>
					</div>
					<div class="clr-col-8">
						Virtual machines on average by server
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="progress-block">
						<label>CPU Consumption</label>
						<div [ngClass]="consolidation_cpu_class">
							<progress value="{{reco_meta_data.over_cpu_cons}}" max="100"></progress>
						</div>
						<label>{{reco_meta_data.over_cpu_cons}}%</label>
					</div>
					<div class="progress-block">
						<label>RAM Consumption</label>
						<div [ngClass]="consolidation_ram_class">
							<progress value="{{reco_meta_data.over_ram_cons}}" max="100"></progress>
						</div>
						<label>{{reco_meta_data.over_ram_cons}}%</label>
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="clr-col-12">
						<span style="font-weigth:bold;font-size:20px;float:left">Resource commitment</span>
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="clr-col-6">
						<span style="font-weigth:bold;font-size:24px">{{reco_meta_data.over_vcpu}} vCPUs</span>
						<br>
						/Logical core
					</div>
					<div class="clr-col-6">
						<span style="font-weigth:bold;font-size:24px">{{reco_meta_data.over_vram}}%</span>
						<br>
						of RAM
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card" style="height:320px">
			<div class="card-header">
				<span style="color:grey;font-size:20px;">2-Servers to stop</span>
				<span class="label" style="float:right">
					stopped server
					<span class="badge">{{nb_server_stop}}</span>
				</span>
			</div>
			<div class="card-block">
				<!--<table class="table">
					<thead>
						<tr>
							<th>Server</th>
							<th>Number of VMs</th>
							<th>CPU Consumption</th>
							<th>RAM Consumption</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let reco of message.recoServerStop">
							<td>{{reco.name}}</td>
							<td>{{reco.vm_nb}}</td>
							<td>{{reco.avg_cpu_cons}}%</td>
							<td>{{reco.avg_ram_cons}}%</td>
						</tr>
					</tbody>
				</table>-->
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<div class="clr-control-container">
                                		<div class="clr-input-wrapper">
                                        		<input type="number" class="clr-input" [(ngModel)]="nb_server_stop" (ngModelChange)="updateServerStop()">
                                		</div>
                                        	<clr-control-helper>number of server to stop</clr-control-helper>
                        		</div>
                        	</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<button type="button" class="btn btn-primary" style="float:right" aria-label="settings" (click)="consolidate()" [disabled]="test">
						RUN CONSOLIDATION
					</button>
                        	</div>
			</div>
			<!--<div class="card-footer">
				<button type="button" class="btn btn-info-outline" aria-label="settings" (click)="isModalRecoServerList = true;loadServerList()">
					SERVER LIST
				</button>
				<button type="button" class="btn btn-primary" style="float:right" aria-label="settings" (click)="consolidate()" [disabled]="test">
					RUN CONSOLIDATION
				</button>
			</div>-->
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card" style="height:320px">
			<div class="card-header">
				<span style="color:grey;font-size:20px;">3-After consolidation</span>
				<span class="label" style="float:right">
					running server
					<span class="badge">{{reco_meta_data.servers-nb_server_stop_consolidated}}</span>
				</span>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<!--<div class="clr-col-12">
						<clr-checkbox-wrapper>
							<input type="checkbox" clrCheckbox name="options" value="option1" [(ngModel)]="reco_easyvirt" (ngModelChange)="updateConsolidate()" />
							<label>with Easyvirt recommendation</label>
						</clr-checkbox-wrapper>
					</div>-->
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="clr-col-4">
						<span style="font-weigth:bold;font-size:24px;float:right;">{{consolidate_server.vm_nb}}</span>
					</div>
					<div class="clr-col-8">
						Virtual machines on average by server
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="progress-block">
						<label>CPU Consumption</label>
						<div [ngClass]="consolidate_cpu_class">
							<progress value="{{consolidate_server.avg_cpu_cons}}" max="100"></progress>
						</div>
						<label>{{consolidate_server.avg_cpu_cons}}%</label>
					</div>
					<div class="progress-block">
						<label>RAM Consumption</label>
						<div [ngClass]="consolidate_ram_class">
							<progress value="{{consolidate_server.avg_ram_cons}}" max="100"></progress>
						</div>
						<label>{{consolidate_server.avg_ram_cons}}%</label>
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="clr-col-12">
						<span style="font-weigth:bold;font-size:20px;float:left">Resource commitment</span>
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="clr-col-6">
						<span style="font-weigth:bold;font-size:24px">{{consolidate_server.over_cpu}} vCPUs</span>
						<br>
						/Logical core
					</div>
					<div class="clr-col-6">
						<span style="font-weigth:bold;font-size:24px">{{consolidate_server.over_ram}}%</span>
						<br>
						of RAM
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<clr-modal [(clrModalOpen)]="isModalRecoServerList" [clrModalSize]="'lg'">
    <div class="modal-body">
	<div class="card">
		<div class="card-header">
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<span style="color:grey;font-size:20px;">Select servers to stop</span>
    				</div>
    			</div>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
			<span class="search"></span>
    		</div>
		<div class="card-block">
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
			<ng-template load-recommendation-consolidation-server-list></ng-template>
    		</div>
    	</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isModalRecoServerList = false">OK</button>
    </div>
</clr-modal>
