<clr-wizard #wizard [(clrWizardOpen)]="_open">
    <clr-wizard-title>External IPs management wizard</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'finish'" (click)="finish()">Finish</clr-wizard-button>

    <clr-wizard-page>
        <ng-template clrPageTitle>Import from CSV file</ng-template>
        <ng-template clrPageNavTitle>Import from CSV file</ng-template>

        <div class="clr-row">
            <div class="clr-col">
                <div class="card">
                    <div class="card-header">
                        <span class="badge badge-blue">1</span> Pick upload a CSV file
                    </div>
                    <div class="card-block">
                        <input type='file' (change)="fileChanged($event)">
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col">
                <div class="card">
                    <div class="card-header">
                        <span class="badge badge-blue">2</span> Review the external Ips
                    </div>
                    <div class="card-block">
                        <clr-datagrid>
                            <clr-dg-column>Ip address</clr-dg-column>
                            <clr-dg-column>Name</clr-dg-column>

                            <clr-dg-row *ngFor="let externalIp of externalIpsFromCsv">
                                <clr-dg-cell>{{externalIp.ipAddress}}</clr-dg-cell>
                                <clr-dg-cell>{{externalIp.name}}</clr-dg-cell>
                            </clr-dg-row>

                            <clr-dg-footer>{{externalIpsFromCsv.length}} external ips</clr-dg-footer>
                        </clr-datagrid>
                    </div>
                </div>
            </div>
        </div>
    </clr-wizard-page>
</clr-wizard>
