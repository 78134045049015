<div *ngIf="this.message.isValidAzure">
        <!--<app-account-management [targetInfrastructure]="this.message.cloudProvider" (loadRegion)="loadRegion()" *ngIf="isAccountMgtReady"></app-account-management>-->
        <clr-tabs>
                <clr-tab  *ngIf="isDcscope">
                        <button clrTabLink>DASHBOARD</button>
                        <clr-tab-content *clrIfActive="true">
                                <app-dashboard-cloud [isreload]="isDataReload"></app-dashboard-cloud>
                        </clr-tab-content>
                </clr-tab>
                <clr-tab>
                        <button clrTabLink (click)="message.creditSelected = ''">OPPORTUNITIES</button>
                        <clr-tab-content *clrIfActive="false">
                                <app-opportunities-cloud [isreload]="isDataReload"></app-opportunities-cloud>
                        </clr-tab-content>
                </clr-tab>
                <clr-tab *ngIf="isDcscope">
                        <button clrTabLink (click)="loadGraphCounter();message.creditSelected = ''">CONSUMPTION COUNTER</button>
                        <clr-tab-content *clrIfActive="false">
                                <ng-template cloud-graph-counter></ng-template>
                        </clr-tab-content>
                </clr-tab> 
                <clr-tab *ngIf="isDcscope">
                        <button clrTabLink (click)="loadConsumptionProfile();message.creditSelected = ''">CONSUMPTION PROFILE</button>
                        <clr-tab-content *clrIfActive="false">
                                <ng-template cloud-cons-profile></ng-template>
                        </clr-tab-content>
                </clr-tab>
                <clr-tab *ngIf="isDcscope">
                        <button clrTabLink (click)="loadGraphRegion();message.creditSelected = ''">REGION GRAPH</button>
                        <clr-tab-content *clrIfActive="false">
                                <ng-template cloud-graph-region></ng-template>
                        </clr-tab-content>
                </clr-tab>
        </clr-tabs>
</div>
<div *ngIf="!this.message.isValidAzure">
        <div class="card">
                <div class="card-block">
                        <div class="card-text">
                                <div>
                                        <strong class="center">No data available !</strong>
                                </div>
                        </div>
                </div>
        </div>
</div>