import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-t1]'
})
export class T1Directive {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
