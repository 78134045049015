import {
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AuditService,
    ShareService
} from '@app/services';

import {
    Message
} from '@app/model';

import * as Highcharts from 'highcharts';
import addMore from "highcharts/highcharts-more";
addMore(Highcharts)

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

import * as moment from 'moment';


@Component({
    selector: 'app-behaviorcal',
    templateUrl: './behaviorcal.component.html',
    styleUrls: ['./behaviorcal.component.css']
})
export class BehaviorcalComponent implements OnInit {

    message: Message;

    private options: any;

    private chart: any;

    yearly_range_date: string = '';

    isNoPrevData: boolean = false;

    isNoNextData: boolean = true;

    maxEndTime: number = 0;

    data_cache: any;


    constructor(
        private audit_svc: AuditService,
        private message_svc: ShareService) {}

    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    ngAfterViewInit(): void {
        this.maxEndTime = this.message.maxTimeFilter / 1000;
        this.callYearlyApi();
    }

    previous(): void {
        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const lastDayPreviousYear = moment.unix(yearLastDay).subtract(1, 'year').endOf('year');
        // CONVERT STRING TO NUMBER
        var y: number = +(lastDayPreviousYear.valueOf() / 1000).toFixed();
        this.maxEndTime = y;
        this.callYearlyApi();
        this.isNoNextData = false;
    }

    next(): void {
        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const lastDayNextYear = moment.unix(yearLastDay).add(1, 'year').endOf('year');
        var y: number = +(lastDayNextYear.valueOf() / 1000).toFixed();
        this.maxEndTime = y;
        if (this.maxEndTime < (this.message.maxTimeFilter / 1000)) {
            this.callYearlyApi();
        } else if (this.maxEndTime = (this.message.maxTimeFilter / 1000)) {
            this.callYearlyApi();
            this.isNoNextData = true;
        } else {
            this.isNoNextData = true;
        }
        this.isNoPrevData = false;
    }

    private callYearlyApi(): void {
        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const lastDay = moment.unix(yearLastDay).add(1, 'seconds');
        const firstDay = moment.unix(yearLastDay).startOf('year');

        this.audit_svc.getData(this.message.currentUuid, firstDay.valueOf(), lastDay.valueOf()).pipe(first())
            .subscribe(
                data => {
                    this.data_cache = data;
                    this.setRangeDate();
                    this.loadCalendar(data);
                },
                error => {
                    this.isNoPrevData = true;
                }
            );
    }

    private setRangeDate(): void {
        var yearLastDay = null;
        yearLastDay = this.maxEndTime - 1;
        const lastDay = moment.unix(yearLastDay).add(0, 'seconds')
        const firstDay = moment.unix(yearLastDay).startOf('year')

        let dateFormatPattern1 = 'MMMM Do'
        let dateFormatPattern2 = dateFormatPattern1 + ' YYYY'
        if (firstDay.year() != lastDay.year()) {
            dateFormatPattern1 += ' YYYY'
        }
        this.yearly_range_date = firstDay.format(dateFormatPattern1) + ' - ' + lastDay.format(dateFormatPattern2);
    }

    private loadCalendar(data): void {
        let yearMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
            'October', 'November', 'December'
        ];

        let datas = [];
        data.forEach(result => {
            //let d = moment(moment.unix(result[i].timeago).utc()/1000);
            //let d = moment(moment.unix(result.timeago) /1000);
            let d = moment.unix(result.timeago / 1000);
            var avg = 50;
            if (result.behavior == "idle")
                avg = 5;
            else if (result.behavior == "lazy")
                avg = 15;
            else if (result.behavior == "oversized")
                avg = 25;
            else if (result.behavior == "undersized")
                avg = 75;
            else if (result.behavior == "busy")
                avg = 95;

            datas.push([d.month(), d.date(), avg])
        });

        this.options = {
            chart: {
                renderTo: 'vmsynthesis-yearly-beh',
                type: 'heatmap',
                marginTop: 40,
                marginBottom: 10,
                plotBorderWidth: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                height: '500px'
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            xAxis: {
                categories: yearMonths.map(month => month.substring(0, 3)),
                title: {
                    text: 'month',
                    align: 'middle'
                },
                opposite: true,
                labels: {
                    formatter: function() {
                        return '<span class="h4">' + this.value + '</span>'
                    }
                },
            },
            yAxis: {
                title: {
                    text: 'day'
                },
                min: 1,
                max: 31,
                minPadding: 0,
                maxPadding: 0,
                startOnTick: false,
                endOnTick: false,
                reversed: true
            },
            colorAxis: {
                dataClasses: [{
                    from: 0,
                    to: 10,
                    color: '#FC0000'
                }, {
                    from: 11,
                    to: 20,
                    color: '#FF5B2B'
                }, {
                    from: 21,
                    to: 30,
                    color: '#F8E300'
                }, {
                    from: 31,
                    to: 70,
                    color: '#96CA2D'
                }, {
                    from: 71,
                    to: 90,
                    color: '#696969'
                }, {
                    from: 91,
                    to: 100,
                    color: '#000000'
                }]
            },
            tooltip: {
                formatter: function() {
                    let text;
                    var beh = 'normal';
                    if (this.point.value <= 10)
                        beh = 'idle';
                    if (this.point.value > 10 && this.point.value <= 20)
                        beh = 'lazy';
                    else if (this.point.value >= 20 && this.point.value < 30)
                        beh = 'oversized';
                    else if (this.point.value >= 70 && this.point.value < 90)
                        beh = 'undersized';
                    else if (this.point.value >= 90)
                        beh = 'busy';

                    if (this.point.value) {
                        text = yearMonths[this.point.x] + ' ' + this.point.y + ' - Behavior: ' + beh;
                    } else {
                        text = 'No data available'
                    }

                    return text
                }
            },
            series: [{
                turboThreshold: 8800,
                borderWidth: 0.1,
                borderColor: 'black',
                data: datas,
                dataLabels: {
                    enabled: false,
                    color: '#000000'
                }
            }]
        };

        this.chart = new Highcharts.Chart(this.options);
    }
}
