<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th>VIRTUAL MACHINE</th>
			<th>ACTION SHOULD BE APPLIED</th>
			<th>STATE</th>
			<th>BEHAVIOR</th>
			<th>COMMENT</th>
			<th>SERVER</th>
			<th>DISK SAVED (GB)</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let reco of data_elements">
			<td>{{reco.name}}</td>
			<td>{{reco.reco_behavior}}</td>
			<td>{{reco.state}}</td>
			<td>{{reco.behavior}}</td>
			<td>{{reco.comment}}</td>
			<td>{{reco.server}}</td>
			<td>{{math.ceil(reco.disk_saved)}}</td>
		</tr>
	</tbody>
</table>
