import {
    Injectable
} from '@angular/core';
import {
    HttpClient
} from '@angular/common/http';
import {
    NetscopeSettings
} from "@app/model";
import {
    environment
} from "@environments/environment";
import {
    map,
    mergeMap,
    catchError,
    switchMap
} from "rxjs/operators";
import {
    tap
} from "rxjs/internal/operators";
import {
    Observable,
    of
} from 'rxjs';

// Classes defined as models for DC Netscope components

export class GenericTopologyTreeNode {
    type: string;
    name: string;
}

export class GenericTopologyTreeNodeWithUuid extends GenericTopologyTreeNode {
    uuid: string;
}

export class VirtualMachineTreeNode extends GenericTopologyTreeNodeWithUuid {}

export class HostSystemTreeNode extends GenericTopologyTreeNodeWithUuid {}

export class GenericNetworkResourceTopologyTreeNode extends GenericTopologyTreeNodeWithUuid {}

export class NetworkTopologyTreeNode extends GenericNetworkResourceTopologyTreeNode {
    children: Array<VirtualMachineTreeNode | HostSystemTreeNode>;
    vms: Array<VirtualMachineTreeNode>;
    hosts: Array<HostSystemTreeNode>;
}
export class DistributedVSwitchTreeNode extends GenericNetworkResourceTopologyTreeNode {
    children: Array<DistributedPortgroupTreeNode>;
    portgroups: Array<DistributedPortgroupTreeNode>;
}
export class DistributedPortgroupTreeNode extends GenericNetworkResourceTopologyTreeNode {
    uplink: boolean;
    children: Array<HostSystemTreeNode>;
}

export class DatacenterTopologyTreeNode extends GenericTopologyTreeNode {
    children: Array<NetworkTopologyTreeNode | DistributedVSwitchTreeNode>;
}

export class DCNetscopeResource {
    uuid: string;

    constructor(uuid: string) {
        this.uuid = uuid;
    }

    get key(): string {
        return `${this.uuid}`;
    }
}

export class DCNetscopeResourceWithName extends DCNetscopeResource {
    name: string;
    type?: string;
    ipaddress?: string;

    constructor(uuid: string, name: string) {
        super(uuid);
        this.name = name;
    }

    get key(): string {
        return `${this.name}`;
    }
}

export class VirtualMachine extends DCNetscopeResourceWithName {}
export class HostSystem extends DCNetscopeResourceWithName {
    children: Array<VirtualMachine>;
}
export class Network extends DCNetscopeResourceWithName {}
export class DistributedVSwitch extends DCNetscopeResourceWithName {
    children: Array<PortGroup>;
}
export class PortGroup extends DCNetscopeResourceWithName {}

export class InfrastructureVersion {
    id: number;
    start_time: number;
    topology: {
        node_mapping: Map<string, VirtualMachine | HostSystem>;
        complete_topology: Array<DatacenterTopologyTreeNode>;
        vm_only_topology:  {
            vms: Array<VirtualMachine>;
            hosts: Array<HostSystem>;
            networks: Array<Network>;
            dswitches: Array<DistributedVSwitch>;
            portgroups: Array<PortGroup>;
        }
    }
}

export class DomainResolution {
    status: string;
    result: string;
}

export class AppsGroupsMetadata {
    uuid?: string;
    trackVmWareCategory?: string;
    trackVmWareResourceTypes?: string[];
    trackVmWareTag?: string
}

export class AppsGroups {
    metadata: AppsGroupsMetadata;
    name: string;
    vms: {
        uuid: string;
    }[];
}

export class AppsGroupsData {
    apps_groups: AppsGroups[];
    vmware_tracked_categories: {
        name: string;
    }[];
    vmware_tracked_tags: {
        category: string;
        name: string
    }[];
}

export class ReportsData {
    reports: {
        flow_run_uuid: string;
        generation_result?: {
            pdf_path: string;
            status: string;
        }
        params: {
            start_date: string;
            end_date: string;
        }
        state: string;
        uuid: string;
        created_at: number;
        launched_at?: number;
        finished_at?: number;
        elapsed_time: number;
    }[];
}

export class DownloadReportData {
    result: number[];
    filename: string
}

export class ASN {
    name: string;
}

export class Country {
    name: string;
    code: string;
}

export class IpDescription {
    ip_version: string;
    start_ip: string;
    end_ip: string;
    country_code : string;
    country: string;
    latitude: number;
    longitude: number;
    accuracy: number;
    ip_range : string;
    asns: {
        range_start: string;
        range_end: string;
        asn_number:number
        country_code: string;
        asn_description: string;
    }[]
}

export class SourceOrDestinationDescription {
    source?: IpDescription;
    destination?: IpDescription;
}

export class VmOpenPortDescription {
    description: {
        name: string;
        uuid: string;
    }
    ports: Map<number, string[]>;
}

export type TroubleshootingData = {
    all_ips: [string, string, string, string, number, number, SourceOrDestinationDescription][];
    vms_with_accessible_ports: VmOpenPortDescription[];
    params: {
        start_time: string;
        end_time: string;
    }
};

export class TemporalParameter {

    constructor(startTime, endTime, timeResolution) {
        this.startTime = startTime;
        this.endTime = endTime;
        this.timeResolution = timeResolution;
    }

    startTime: any;
    endTime: any;
    timeResolution: "hourly" | "daily";
}

export interface Response<C> {}

export class Success<C> implements Response<C> {
    constructor(public element: C) {
    }
}

export class Failure<C> implements Response<C> {}

// Classes defined for responses of functions of the DC Netscope service

export interface GetDailyTimestampResponse {}
export class FailureGetDailyTimestampResponse implements GetDailyTimestampResponse {}
export class SuccessGetDailyTimestampResponse implements GetDailyTimestampResponse {
    constructor(public dailyTimestamps) {}
}

export interface GetDashboardDataForFilterResponse {}
export class SuccessGetDashboardDataForFilterResponse implements GetDashboardDataForFilterResponse {
    constructor(public dashboardDataForFilter) {}
}
export class FailureGetDashboardDataForFilterResponse implements GetDashboardDataForFilterResponse {}


export interface GetDashboardGraphDataForFilter {}
export class SuccessGetDashboardGraphDataForFilter implements GetDashboardGraphDataForFilter {
    constructor(public dashboardGraphDataForFilter) {}
}
export class FailureGetDashboardGraphDataForFilter implements GetDashboardGraphDataForFilter {}


export interface GetDomainResolutionResponse {};
export class SuccessGetDomainResolutionResponse implements GetDomainResolutionResponse {
    constructor(public domainResolution: DomainResolution) {}
}
export class FailureGetDomainResolutionResponse implements GetDomainResolutionResponse {}


export interface GetHeatmapFlowsDataResponse {}
export class SuccessGetHeatmapFlowsDataResponse implements GetHeatmapFlowsDataResponse {
    constructor(public heatmapFlowsData) {}
}
export class FailureGetHeatmapFlowsDataResponse implements GetHeatmapFlowsDataResponse {}

export interface GetHeatmapFlowsDetailsResponse {}
export class SuccessGetHeatmapFlowsDetailsResponse implements GetHeatmapFlowsDetailsResponse {
    constructor(public heatmapFlowsDetails) {}
}
export class FailureGetHeatmapFlowsDetailsResponse implements GetHeatmapFlowsDetailsResponse {}

export interface GetHeatmapFlowsCombinationsResponse {}
export class FailureGetHeatmapFlowsCombinationsResponse implements GetHeatmapFlowsCombinationsResponse {}
export class SuccessGetHeatmapFlowsCombinationsResponse implements GetHeatmapFlowsCombinationsResponse {
    constructor(public heatmapFlowsCombinations) {}
}

export interface GetCustomProtocolsRulesResponse {}
export class FailureGetCustomProtocolsRulesResponse implements GetCustomProtocolsRulesResponse {}
export class SuccessGetCustomProtocolsRulesResponse implements GetCustomProtocolsRulesResponse {
    constructor(public customProtocolsRules) {}
}

export interface PushCustomProtocolsResponse {}
export class SuccessPushCustomProtocols implements PushCustomProtocolsResponse {}
export class FailurePushCustomProtocols implements PushCustomProtocolsResponse {}

export interface GetExternalIpsResponse {}
export class FailureGetExternalIpsResponse implements GetExternalIpsResponse {}
export class SuccessGetExternalIpsResponse implements GetExternalIpsResponse {
    constructor(public externalIps) {}
}

export interface PushExternalIpsResponse {}
export class SuccessPushExternalIpsResponse implements PushExternalIpsResponse {}
export class FailurePushExternalIpsResponse implements PushExternalIpsResponse {}

export interface GetAppsGroupsResponse {}
export class SuccessGetAppsGroupsResponse implements GetAppsGroupsResponse {
    constructor(public appsGroupsData: AppsGroupsData) {}
}
export class FailureGetAppsGroupsResponse implements GetAppsGroupsResponse {}

export interface PushAppsGroupsResponse {}
export class SuccessPushAppsGroupsResponse implements PushAppsGroupsResponse {}
export class FailurePushAppsGroupsResponse implements PushAppsGroupsResponse {}

export interface GetTagsResponse {}
export class SuccessGetTagsResponse implements GetTagsResponse {
    constructor(public tags) {}
}
export class FailureGetTagsResponse implements GetTagsResponse {}

export interface GetServicesStatusResponse {}
export class SuccessGetServicesStatusResponse implements GetServicesStatusResponse {
    constructor(public servicesStatus) {}
}
export class FailureGetServicesStatusResponse implements GetServicesStatusResponse {}

export interface GetVcentersStatusResponse {}
export class SuccessGetVcentersStatusResponse implements GetVcentersStatusResponse {
    constructor(public vcentersStatus) {}
}
export class FailureGetVcentersStatusResponse implements GetVcentersStatusResponse {}

export interface GetOverallStatusResponse {}
export class SuccessGetOverallStatusResponse implements GetOverallStatusResponse {
    constructor(public overallStatus) {}
}
export class FailureGetOverallStatusResponse implements GetOverallStatusResponse {}

export interface GetDetectedThreatsResponse {}
export class SuccessGetDetectedThreatsResponse implements GetDetectedThreatsResponse {
    constructor(public detectedThreats) {}
}
export class FailureGetDetectedThreatsResponse implements GetDetectedThreatsResponse {}

export interface ClearDetectedThreatsResponse {}
export class SuccessClearDetectedThreatsResponse implements ClearDetectedThreatsResponse {
    constructor(public detectedThreats) {}
}
export class FailureClearDetectedThreatsResponse implements ClearDetectedThreatsResponse {}

export interface GetBlocklistVersionResponse {}
export class SuccessGetBlocklistVersionResponse implements GetBlocklistVersionResponse {
    constructor(public blocklistVersion) {}
}
export class FailureGetBlocklistVersionResponse implements GetBlocklistVersionResponse {}

export interface GetClustersWithFiltersResponse {}
export class FailureGetClustersWithFiltersResponse implements GetClustersWithFiltersResponse {}
export class SuccessGetClustersWithFiltersResponse implements GetClustersWithFiltersResponse {
    constructor(public clustersWithFiltersData) {}
}

export interface GetLastInfrastructureVersionResponse {}
export class SuccessGetLastInfrastructureVersionResponse implements GetLastInfrastructureVersionResponse {
    constructor(public lastInfrastructureVersion) {}
}
export class FailureGetLastInfrastructureVersionResponse implements GetLastInfrastructureVersionResponse {}

export interface GetFlowDetailsResponse {}
export class SuccessGetFlowDetailsResponse implements GetFlowDetailsResponse {
    constructor(public flowDetails) {}
}
export class FailureGetFlowDetailsResponse implements GetFlowDetailsResponse {}

export interface GetInfrastructureVersionsBetweenResponse {}
export class SuccessGetInfrastructureVersionsBetweenResponse implements GetInfrastructureVersionsBetweenResponse {
    constructor(public infrastructureVersionsBetween: InfrastructureVersion) {}
}
export class FailureGetInfrastructureVersionsBetweenResponse implements GetInfrastructureVersionsBetweenResponse {}

export interface GetReportsDataResponse {}
export class SuccessGetReportsDataResponse implements GetReportsDataResponse {
    constructor(public reportsData: ReportsData) {}
}
export class FailureGetReportsDataResponse implements GetReportsDataResponse {}

export interface RequestReportGenerationResponse {}
export class SuccessRequestReportGenerationResponse implements RequestReportGenerationResponse {
    constructor() {}
}
export class FailureRequestReportGenerationResponse implements RequestReportGenerationResponse {}

export interface RequestReportDownloadResponse {}
export class SuccessRequestReportDownloadResponse implements RequestReportDownloadResponse {
    constructor(public reportData: DownloadReportData) {}
}
export class FailureRequestReportDownloadResponse implements RequestReportDownloadResponse {}

export interface RequestReportDeletionResponse {}
export class SuccessRequestReportDeletionResponse implements RequestReportDeletionResponse {
    constructor(public reportData: DownloadReportData) {}
}
export class FailureRequestReportDeletionResponse implements RequestReportDeletionResponse {}

export interface RequestTroubleshootingDataResponse {}
export class SuccessRequestTroubleshootingDataResponse implements RequestTroubleshootingDataResponse {
    constructor(public troubleshootingData: TroubleshootingData) {}
}
export class FailureRequestTroubleshootingDataResponse implements RequestTroubleshootingDataResponse {}

// Below is defined the DC Netscope service

@Injectable({
    providedIn: 'root'
})
export class NetscopeService {

    constructor(private http: HttpClient) {}

    generateHeaders() {
        // const username = sessionStorage.getItem('username');
        // const password = sessionStorage.getItem('password');
        // const header = {
        //   'Authorization': 'Basic ' + btoa(`${username}:${password}`)
        // };
        // return header;
        return {};
    }

    getNetscopeSettings() {
        return this.http.get < NetscopeSettings[] > (`${environment.apiUrl}/` + 'management/getnetscopesettings/')
            .pipe(map(settings => {
                return settings;
            }));
    }

    updateNetscopeSettings(settings: JSON) {
        return this.http.post < NetscopeSettings[] > (`${environment.apiUrl}/` +
            'management/updatenetscopesettings/', settings);
    }

    doGetHttpRequest < TGeneric, TSuccess extends TGeneric, TFailure extends TGeneric > (urlDcNetscopeApi: string,
            params, constructorSuccess: new(something) => TSuccess, constructorFailure: new() => TFailure): Observable <
        TGeneric > {
            let getParamsHeaders = {
                headers: this.generateHeaders()
            };

            var getParams = Object.assign(getParamsHeaders, params);

            let observable = this.getNetscopeSettings().pipe(mergeMap(settings => {
                let agentUrl = "http://localhost:5000";
                settings.filter((setting) => setting.option === "agent_url").map((setting) => {
                    agentUrl = setting.value;
                })
                const url = `${environment.apiUrl}${urlDcNetscopeApi}`;
                return this.http.get < any[] > (url, getParams);
            }));
            return observable.pipe(
                switchMap(result => of(new constructorSuccess(result))),
                catchError(error => of(new constructorFailure()))
            );
        }

    doPostHttpRequest < TGeneric, TSuccess extends TGeneric, TFailure extends TGeneric > (urlDcNetscopeApi: string,
            params, constructorSuccess: new(something) => TSuccess, constructorFailure: new() => TFailure): Observable <
        TGeneric > {
            let postParamsHeaders = {
                headers: this.generateHeaders()
            };

            var postParams = Object.assign(postParamsHeaders, params);


            let observable = this.getNetscopeSettings().pipe(mergeMap(settings => {
                let agentUrl = "http://localhost:5000";
                settings.filter((setting) => setting.option === "agent_url").map((setting) => {
                    agentUrl = setting.value;
                })
                const url = `${environment.apiUrl}${urlDcNetscopeApi}`;
                return this.http.post < any[] > (url, postParams);
            }));
            return observable.pipe(
                switchMap(result => of(new constructorSuccess(result))),
                catchError(error => of(new constructorFailure()))
            );
        }

    _recursiveFixProtocolData = (clustersData, protocolData) => {
        if (protocolData.role == "source" || protocolData.role == "destination") {
            let resource_obj_from_obj = clustersData["resource_idx"][protocolData.object.uuid];
            protocolData.object.id = resource_obj_from_obj["id"];
            protocolData.object.type = resource_obj_from_obj["type"];
            protocolData.object.name = resource_obj_from_obj["name"];
            protocolData.object.short_uuid = resource_obj_from_obj["short_uuid"];
        }
        protocolData.children.map((child) => this._recursiveFixProtocolData(clustersData, child));
    }

    _fixCompressedClustersData = (clustersData) => {
        for (let link_attr of ["links"]) {
            for (let link of clustersData[link_attr]) {
                for (let resource of ["source", "target", "via"]) {
                    if (link[resource] === undefined) {
                        continue;
                    }
                    let resource_obj = link[resource];
                    let resource_obj_from_obj = clustersData["resource_idx"][resource_obj["uuid"]];
                    resource_obj["id"] = resource_obj_from_obj["id"];
                    resource_obj["type"] = resource_obj_from_obj["type"];
                    resource_obj["name"] = resource_obj_from_obj["name"];
                    resource_obj["short_uuid"] = resource_obj_from_obj["short_uuid"];
                }
            }
        }
        // Fix protocols data
        this._recursiveFixProtocolData(clustersData, clustersData["protocols"])
    }

    public getClustersWithFilters(startTimeInterval, endTimeInterval, vmsUuids, hostsUuids, only_flows_with_src_and_dst_in_the_filter, timeResolution = "daily", showEverything = false): Observable <GetClustersWithFiltersResponse > {
            let observable = this.getNetscopeSettings().pipe(mergeMap(settings => {
                let agentUrl = "http://localhost:5000";
                settings.filter((setting) => setting.option === "agent_url").map((setting) => {
                    agentUrl = setting.value;
                })
                const url =
                    `${environment.apiUrl}/v1/netscope/api/forward/clusters/compute_vms_clusters?startTimeInterval=${startTimeInterval}&endTimeInterval=${endTimeInterval}&timeResolution=${timeResolution}`;
                return this.http.post < any[] > (url, {
                    vmsUuids: vmsUuids,
                    hostsUuids: hostsUuids,
                    only_flows_with_src_and_dst_in_the_filter: only_flows_with_src_and_dst_in_the_filter,
                    showEverything: showEverything
                }, {
                    headers: this.generateHeaders()
                });
            })).pipe(
                tap(result => {
                    this._fixCompressedClustersData(result);
                    return result;
                })
            );
            return observable.pipe(
                switchMap(result => of(new SuccessGetClustersWithFiltersResponse(result))),
                catchError(error => of(new FailureGetClustersWithFiltersResponse()))
            );
        }

    public getLastInfrastructureVersion() {
        return this.doGetHttpRequest(
            "/v1/netscope/api/forward/infrastructure/version/last",
            {},
            SuccessGetLastInfrastructureVersionResponse,
            FailureGetLastInfrastructureVersionResponse
        );
    }

    public getFlowDetails(srcIp, dstIp, temporalParameter: TemporalParameter,
        bidirectional = false) {
        let params = {
            srcIp: srcIp,
            dstIp: dstIp,
            temporalParameter: temporalParameter,
            // timeResolution: timeResolution,
            bidirectional: bidirectional
        };
        let url = `/v1/netscope/api/forward/flow/details`;
        return this.doPostHttpRequest(url, params, SuccessGetFlowDetailsResponse, FailureGetFlowDetailsResponse);
    }

    public getInfrastructureVersionsBetween(startTimeInterval, endTimeInterval, vmsUuids, hostsUuids, skipFiltering=false): Observable<GetInfrastructureVersionsBetweenResponse> {
        let params = {
            vmsUuids: vmsUuids,
                hostsUuids: hostsUuids,
                skipFiltering: skipFiltering,
                headers: this.generateHeaders()
        };
        let url = `/v1/netscope/api/forward/infrastructure/version/between?startTimeInterval=${startTimeInterval}&endTimeInterval=${endTimeInterval}`;
        return this.doPostHttpRequest(url, params, SuccessGetInfrastructureVersionsBetweenResponse, FailureGetInfrastructureVersionsBetweenResponse);
    }

    public getDailyTimestamps(): Observable < GetDailyTimestampResponse > {
        return this.doGetHttpRequest("/v1/netscope/api/forward/networking/visualisation/timeranges/daily", {},
            SuccessGetDailyTimestampResponse, FailureGetDailyTimestampResponse);
    }

    public getDashboardDataForFilter(vmsUuids, hostsUuids, only_flows_with_src_and_dst_in_the_filter, maxTimestamp) {
        let params = {
            vmsUuids: vmsUuids,
            hostsUuids: hostsUuids,
            only_flows_with_src_and_dst_in_the_filter: only_flows_with_src_and_dst_in_the_filter,
            day: maxTimestamp
        };
        let url = `/v1/netscope/api/forward/dashboard/data_for_filter`;
        return this.doPostHttpRequest(url, params, SuccessGetDashboardDataForFilterResponse,
            FailureGetDashboardDataForFilterResponse);
    }

    public getDashboardGraphDataForFilter(vmsUuids, hostsUuids, only_flows_with_src_and_dst_in_the_filter) {
        let params = {
            vmsUuids: vmsUuids,
            hostsUuids: hostsUuids,
            only_flows_with_src_and_dst_in_the_filter: only_flows_with_src_and_dst_in_the_filter
        };
        let url = `/v1/netscope/api/forward/dashboard/graph_summary_data_for_filter`;
        return this.doPostHttpRequest(url, params, SuccessGetDashboardGraphDataForFilter,
            FailureGetDashboardGraphDataForFilter);
    }

    public getDomainResolution(ip) {
        let params = {
            srcIp: ip
        };
        let url = `/v1/netscope/api/forward/resolve_domain`;
        return this.doPostHttpRequest(url, params, SuccessGetDomainResolutionResponse,
            FailureGetDomainResolutionResponse);
    }

    public getHeatmapFlowsCombinations(): Observable < GetHeatmapFlowsCombinationsResponse > {
        return this.doPostHttpRequest("/v1/netscope/api/forward/heatmap/get_flows_combinations", {},
            SuccessGetHeatmapFlowsCombinationsResponse, FailureGetHeatmapFlowsCombinationsResponse);
    }

    public getHeatmapFlowsData(srcUuid, dstUuid, bidirectional=true) {
        let params = {
            srcUuid: srcUuid,
            dstUuid: dstUuid,
            bidirectional: bidirectional
        };
        let url = `/v1/netscope/api/forward/heatmap/get_flow_data`;
        return this.doPostHttpRequest(url, params, SuccessGetHeatmapFlowsDataResponse,
            FailureGetHeatmapFlowsDataResponse);
    }

    public getHeatmapFlowsDetails(srcUuid, dstUuid, timestamp, timeResolution = "hourly", bidirectional=true) {
        let params = {
            srcUuid: srcUuid,
            dstUuid: dstUuid,
            timestamp: timestamp,
            timeResolution: timeResolution,
            bidirectional: bidirectional
        };
        let url = `/v1/netscope/api/forward/heatmap/get_flow_details`;
        return this.doPostHttpRequest(url, params, SuccessGetHeatmapFlowsDetailsResponse,
            FailureGetHeatmapFlowsDetailsResponse);
    }

    public getCustomProtocolsRules(): Observable < GetCustomProtocolsRulesResponse > {
        return this.doPostHttpRequest("/v1/netscope/api/forward/custom_protocols/rules/fetch", {},
            SuccessGetCustomProtocolsRulesResponse, FailureGetCustomProtocolsRulesResponse);
    }

    public pushCustomProtocols(custom_rules) {
        let params = {
            custom_rules: custom_rules
        };
        let url = `/v1/netscope/api/forward/custom_protocols/rules/push`;
        return this.doPostHttpRequest(url, params, SuccessPushCustomProtocols, FailurePushCustomProtocols);
    }

    public getExternalIps(): Observable < GetExternalIpsResponse > {
        return this.doPostHttpRequest("/v1/netscope/api/forward/external_ips/fetch", {},
            SuccessGetExternalIpsResponse, FailureGetExternalIpsResponse);
    }

    public pushExternalIps(externalIps) {
        let params = {
            external_ips: externalIps,
        }
        let url = `/v1/netscope/api/forward/external_ips/push`;
        return this.doPostHttpRequest(url, params, SuccessPushExternalIpsResponse, FailurePushExternalIpsResponse);
    }

    public getAppsGroups(use_automatic_apps_groups=false): Observable< GetAppsGroupsResponse > {
        let params = {
            use_automatic_apps_groups: use_automatic_apps_groups
        };
        let url = `/v1/netscope/api/forward/apps_groups/groups/fetch`;
        return this.doPostHttpRequest(url, params, SuccessGetAppsGroupsResponse, FailureGetAppsGroupsResponse);
    }

    public pushAppsGroups(apps_groups) {
        let params = {
            apps_groups: apps_groups,
        };
        let url = `/v1/netscope/api/forward/apps_groups/groups/push`;
        return this.doPostHttpRequest(url, params, SuccessPushAppsGroupsResponse, FailurePushAppsGroupsResponse);
    }

    public getTags() {
        let params = {};
        let url = `/v1/netscope/api/forward/apps_groups/tags/fetch`;
        return this.doPostHttpRequest(url, params, SuccessGetTagsResponse, FailureGetTagsResponse);
    }

    public getServicesStatus() {
        let params = {};
        let url = `/v1/netscope/api/forward/api/health/services/all`;
        return this.doPostHttpRequest(url, params, SuccessGetServicesStatusResponse,
            FailureGetServicesStatusResponse);
    }

    public getVcentersStatus() {
        let params = {};
        let url = ``;
        return this.doPostHttpRequest(url, params, SuccessGetVcentersStatusResponse,
            FailureGetVcentersStatusResponse);
    }

    public getOverallStatus() {
        let params = {};
        let url = `/v1/netscope/api/forward/api/overall/status`;
        return this.doPostHttpRequest(url, params, SuccessGetOverallStatusResponse,
            FailureGetOverallStatusResponse);
    }

    public getDetectedThreats() {
        let params = {};
        let url = `/v1/netscope/api/forward/threats`;
        return this.doGetHttpRequest(url, params, SuccessGetDetectedThreatsResponse,
            FailureGetDetectedThreatsResponse);
    }

    public clearDetectedThreats() {
        let params = {};
        let url = `/v1/netscope/api/forward/threats/clear`;
        return this.doPostHttpRequest(url, params, SuccessClearDetectedThreatsResponse,
            FailureClearDetectedThreatsResponse);
    }

    public getBlocklistVersion() {
        let params = {};
        let url = `/v1/netscope/api/forward/threats/blocklist/version`;
        return this.doGetHttpRequest(url, params, SuccessGetBlocklistVersionResponse,
            FailureGetBlocklistVersionResponse);
    }

    askToFetchNewBlocklist() {
        return new Observable((observer) => {
            this.http.get("https://www.easyvirt.com/downloadseasyvirt/blocklist.tar.bz2.enc", {
                responseType: 'blob'
            }).subscribe((archiveBlob) => {
                var b: any = archiveBlob;
                //A Blob() is almost a File() - it's just missing the two properties below which we will add
                b.lastModifiedDate = new Date();
                b.name = "blocklist.tar.bz2.enc";

                //Cast to a File() type
                let archiveFile = < File > archiveBlob;

                let blocklistUpdateUrl =
                    `${environment.apiUrl}/v1/netscope/api/forward/threats/blocklist/update`;
                let formData: FormData = new FormData();
                formData.append('file_upload', archiveFile, archiveFile.name);
                this.http.post < any > (blocklistUpdateUrl, formData).subscribe((value) => {
                    observer.next(value);
                    observer.complete();
                })
            });
        });
    }

    public getReportsData(): Observable<GetReportsDataResponse> {
        let params = {};
        let url = `/v1/netscope/api/forward/api/reports/data`;
        return this.doPostHttpRequest(url, params, SuccessGetReportsDataResponse, FailureGetReportsDataResponse);
    }

    public requestReportGeneration(start, end, sendByEmail: boolean): Observable<RequestReportGenerationResponse> {
        let params = {
            start: start,
            end: end,
            sendByEmail: sendByEmail
        };
        let url = `/v1/netscope/api/forward/api/reports/generate`;
        return this.doPostHttpRequest(url, params, SuccessRequestReportGenerationResponse, FailureRequestReportGenerationResponse);
    }

    public requestReportDownload(reportUuid: string): Observable<RequestReportDownloadResponse> {
        let params = {
            reportUuid: reportUuid
        };
        let url = `/v1/netscope/api/forward/api/reports/download`;
        return this.doPostHttpRequest(url, params, SuccessRequestReportDownloadResponse, FailureRequestReportDownloadResponse);
    }

    public requestReportDeletion(reportUuid: string): Observable<RequestReportDeletionResponse> {
        let params = {
            report_uuid: reportUuid
        };
        let url = `/v1/netscope/api/forward/api/reports/delete`;
        return this.doPostHttpRequest(url, params, SuccessRequestReportDeletionResponse, FailureRequestReportDeletionResponse);
    }

    public requestTroubleshootingData(): Observable<RequestTroubleshootingDataResponse> {
        let params = {};
        let url = `/v1/netscope/api/forward/api/troubleshooting/data`;
        return this.doPostHttpRequest(url, params, SuccessRequestTroubleshootingDataResponse, FailureRequestTroubleshootingDataResponse);
    }

    public heatmapGetSourceResources(): Observable<Response<Array<string>>> {
        let params = {};
        let url = `/v1/netscope/api/forward/heatmap/get_src_resources`;
        return this.doPostHttpRequest(url, params, (Success<Array<string>>), (Failure<Array<string>>));
    }

    public heatmapLinkedResources(src_resource_uuid): Observable<Response<Array<string>>> {
        let params = {
            src_resource_uuid: src_resource_uuid
        };
        let url = `/v1/netscope/api/forward/heatmap/get_linked_resources`;
        return this.doPostHttpRequest(url, params, (Success<Array<string>>), (Failure<Array<string>>));
    }

}
