import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faPlug
} from '@fortawesome/free-solid-svg-icons';

import {
    PowerGraphComponent
} from '@app/greenit/power/power-graph/power-graph.component';
import {
    PowerVmStackedComponent
} from '@app/greenit/power/power-vm-stacked/power-vm-stacked.component';

import {
    AccountService,
    GreenitService,
    ManagementService,
    ShareService
} from '@app/services';

import {
    InstanceType,
    Message,
    User
} from '@app/model';


@Component({
    selector: 'app-power-kubernetes',
    templateUrl: './power-kubernetes.component.html',
    styleUrls: ['./power-kubernetes.component.css']
})
export class PowerKubernetesComponent implements OnChanges, OnInit {

    @Input() isreload: boolean = false;

    message: Message;

    @ViewChild('powerGraph') powerGraph: PowerGraphComponent;
    @ViewChild('powerVmStacked') powerVmStacked: PowerVmStackedComponent;

    all_nodes: boolean = false;

    listNodes: any[] = [];

    selectedElements: string[] = [];

    isAllDisable: boolean = false;

    isStackedDisable: boolean = false;

    isStacked: boolean = false;

    targetInfrastructure: string = InstanceType.KUBERNETES;

    isSettings: boolean = false;

    isData: boolean = false;

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private message_svc: ShareService,
        private library: FaIconLibrary,
        private mgt_svc: ManagementService,
        private greenit_svc: GreenitService
    ) {
        this.library.addIcons(faPlug);
    }

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        setTimeout(() => this.initContainerData(), 100);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['isreload'].currentValue)
            this.initContainerData();

    }

    updateChart(): void {

        if (this.powerGraph != undefined) {
            setTimeout(() => {
                this.powerGraph.updateChart();
            }, 100);
        }
    }

    checkAll() {

        // Reset
        this.selectedElements = [];

        if (this.all_nodes) {
            this.selectedElements.push.apply(this.selectedElements, this.listNodes);
            this.isStackedDisable = true;
        } else {
            this.isStackedDisable = false;
        }

        setTimeout(() => {
            this.updateChart();
        }, 100);
    }

    stackOnNode(): void {

        if (this.isStacked)
            this.isAllDisable = true;
        else
            this.isAllDisable = false;

        setTimeout(() => {
            this.updateChart();
        }, 100);
    }

    private initContainerData(): void {

        this.all_nodes = false;
        this.isStacked = false
        this.selectedElements = [];

        this.isData = false;

        this.greenit_svc.getKubeNodeListObservable(this.currentUser.login, this.message.elementView).subscribe(
            data => {
                if (data.length > 0) {
                    this.isData = true;
                    this.listNodes = data;
                    this.selectedElements.push(data[0]);
                    setTimeout(() => {
                        this.updateChart();
                    }, 100);
                }
            }
        );
    }
}
