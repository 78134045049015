import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-host-consover]'
})
export class HostconsoverDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
