<div>
    <span>
        <span class="cloudimpact-header">Cloud Impact</span>
        <span>
            <button class="btn btn-link btn-icon cloud-header-information-icon" (click)="informationModalOpened = true">
                <cds-icon shape="info-circle" size="24"></cds-icon>
            </button>
        </span>
        <span class="spinner spinner-sm" *ngIf="isLoading">Loading...</span>

        <span style="float: right;">
            <span href="..." class="label label-blue clickable">
                prices version
                <span class="badge">{{ dateLastUpdatePrices }}</span>
            </span>
            <span href="..." class="label label-blue clickable">
                emissions version
                <span class="badge">{{ dateLastUpdateEmissions }}</span>
            </span>
            <button [clrLoading]="pricesFetchState" type="button" class="btn btn-icon btn-primary" aria-label="reload"
                (click)="askToFetchNewData()">
                <cds-icon shape="sync"></cds-icon>Reload
            </button>
        </span>
    </span>
</div>

<div class="clr-row">
    <div class="clr-col-4 progressBar" style="margin-top: 15px;">
        <div class="clr-row">
            <div class="clr-col-3">
                <div class="clr-form-control" style="margin-top: 0px;">
                    <label for="currencySelector" class="clr-control-label">Currency</label>
                    <div class="clr-select-wrapper">
                        <select class="full-width-input clr-select" id="currencySelector" name="options"
                                [(ngModel)]="currencyOption" (change)="keepCloudImpactParameters();reloadUi()">
                            <option value="USD">USD</option>
                            <option value="EUR">Euros</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="clr-col-3">
                <div class="clr-form-control" style="margin-top: 0px;">
                    <label for="periodSelector" class="clr-control-label">Period</label>
                    <div class="clr-select-wrapper">
                        <select class="full-width-input clr-select" id="periodSelector" name="options"
                                [(ngModel)]="period" (change)="keepCloudImpactParameters();reloadUi()">
                            <option value="year">Year</option>
                            <option value="month">Month</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="clr-col-3">
                <div class="clr-form-control" style="margin-top: 0px;">
                    <label for="billingTypeSelector" class="clr-control-label">Billing Type</label>
                    <div class="clr-select-wrapper">
                        <select class="full-width-input clr-select" id="billingTypeSelector" [(ngModel)]="billingType"
                                (change)="keepCloudImpactParameters();reloadUi()">
                            <option value="od">On Demand</option>
                            <option value="1yr">Reservation (1 year)</option>
                            <option value="3yr">Reservation (3 years)</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-4 progressBar" style="margin-top: 15px;">
        <div class="clr-row">
            <div class="clr-form-control" style="margin-top: 0px;">
                <label for="billingTypeSelector" class="clr-control-label">Apply recommendations</label>
                <input class="full-width-input" type="checkbox" clrCheckbox [(ngModel)]="isRecoEnabled"
                       (change)="reloadUi()" />
            </div>
            <div class="clr-form-control" style="margin-top: 0px; margin-left: 15px;">
                <label for="billingTypeSelector" class="clr-control-label">Only Europe</label>
                <input class="full-width-input" type="checkbox" clrCheckbox [(ngModel)]="isOnlyEuropeansEnabled"
                       (change)="reloadUi()" />
            </div>
        </div>
    </div>
    <div class="clr-col-4 progressBar" style="margin-top: 15px;">
        <div class="progress" *ngIf="isLoading">
            <progress max="60" [value]="progress" #progressBar></progress>
        </div>
        <span *ngIf="isLoading">Loading {{ displayLoading }}</span>
    </div>
</div>


<div class="clr-col cloudimpact-dashboard">
    <div class="clr-row-12">
        <div class="card">
            <div class="card-block" id="dashboard-computeTypeChart-core">
                <div class="card-text">
                    <div style=" display:flex; justify-content: center; align-items: center; flex-direction: column;">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="dashboardOptions"
                            [callbackFunction]="chartCallbackPriceEmissionsChart"
                            [oneToOne]="true"
                            [(update)]="updateFlag"
                            style="width: 100%; height: 650px; display: block;">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="clr-col cloudimpact-dashboard">
    <div class="clr-row-12">
        <div class="card">
            <div class="card-block" id="dashboard-computeTypeChart-core">
                <div class="card-text">
                    <div style=" display:flex; justify-content: center; align-items: center; flex-direction: column;">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="pricechartOptions"
                            [callbackFunction]="chartCallbackPriceChart"
                            [oneToOne]="true"
                            [(update)]="updateFlag"
                            style="width: 100%; height: 650px; display: block;">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-warning" role="alert" style="margin-top: 20px;" *ngIf="!isCloudPricingLicenceEnabled">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
            </div>
            <span class="alert-text">Find out estimations of how much your infrastructure would cost on cloud-provider
                such as <span class="badge">aws</span>, <span class="badge">azure</span>, <span class="badge">gcp</span>
                or <span class="badge">ovh</span>, thanks to the <b>Cloud Pricing</b> module.</span>
            <div class="alert-actions">
                <a href="https://www.easyvirt.com/dc-scope-operations-management/cloud-pricing/" class="alert-action"
                    target="_blank" rel="noopener noreferrer">Learn more about the Cloud Pricing module</a>
            </div>
        </div>
    </div>
</div>

<clr-modal [(clrModalOpen)]="informationModalOpened" [clrModalStaticBackdrop]="false">
    <h2 class="modal-title">Cloud Pricing Calculation</h2>
    <div class="modal-body">
        <h3>
            <cds-icon shape="warning-standard" size="18"></cds-icon>
            These estimates should not be used as official quotes, since they do not take into account costs incurred of
            :
        </h3>
        <ul>
            <li>Any <b>Network</b> traffic</li>
            <li>Supplementary <b>disk I/O</b> transactions</li>
            <li><b>Backup</b> and <b>snapshots</b> storage</li>
            <li>Prediction on future compute and storage needs*</li>
            <li><b>Personnel</b> cost</li>
        </ul>

        <p class="p8">* It is very likely that the needs of storage capacity will increase with time.</p>

        <h3>
            <cds-icon shape="flask" size="18"></cds-icon>
            Methodology
        </h3>

        <p>For each existing VM in DC Scope, we match compute and storage resources as follows :</p>

        <ul>
            <li><b>Compute:</b> the cheapest offered compute/instance type that meets the VM requirements in terms of
                number
                of <b>VCPUS</b> and <b>allocated RAM</b></li>
            <li><b>Storage:</b> the storage costs are calculated on a per-GB basis** over the <b>storage
                    provisioned</b>.
                We
                match the cheapest offered storage type that meets the VM requirements in terms of <b>capacity, IOPS and
                    throughput.</b></li>
        </ul>
        <p class="p8">** On Microsoft Azure, managed virtual disks are provisioned with fixed pre-defined sizes.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="informationModalOpened = false">
            Close
        </button>
    </div>
</clr-modal>
