<div class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isActivityCard">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:26px;">Activity</span>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<img class="centerLink" src="{{activity_url}}" (click)="callKpiDetail('activity')" >
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{kpi_meta.run}}</span>
								<br>
								VMs running
							</div>
						</div>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{kpi_meta.off}}</span>
								<br>
								VMs Off
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isBehaviorCard">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:26px;">Behavior</span>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<img class="centerLink" src="{{behavior_url}}" (click)="callKpiDetail('behavior')" >
					</div>
					<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{kpi_meta.idle}}</span>
								<br>
								VMs idle
							</div>
						</div>
					</div>
					<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{kpi_meta.normal}}</span>
								<br>
								VMs Normal
							</div>
						</div>
					</div>
					<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{kpi_meta.oversized}}</span>
								<br>
								VMs Oversized
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="isCapacityCard">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:26px;">Capacity</span>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<img class="centerLink" src="{{capacity_url}}" (click)="callKpiDetail('capacity')" >
					</div>
					<div class="clr-col-4">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{math.ceil(kpi_meta.avg_cpu_cons)}}%</span>
								<br>
								CPU
								<div [ngClass]="consumption_cpu_class">
									<progress value="{{kpi_meta.avg_cpu_cons}}" max="100"></progress>
                						</div>
                					</div>
                				</div>
            				</div>
					<div class="clr-col-4">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{math.ceil(kpi_meta.avg_ram_cons)}}%</span>
								<br>
								RAM
								<div [ngClass]="consumption_ram_class">
									<progress value="{{kpi_meta.avg_ram_cons}}" max="100"></progress>
                						</div>
                					</div>
                				</div>
            				</div>
					<div class="clr-col-4">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{math.ceil(kpi_meta.avg_disk_cons)}}%</span>
								<br>
								DISK
								<div [ngClass]="consumption_disk_class">
									<progress value="{{kpi_meta.avg_disk_cons}}" max="100"></progress>
                						</div>
                					</div>
                				</div>
            				</div>
				</div>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="currentUser.isDashboardT6 && isCostCard">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:26px;">Cost</span>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<img class="centerLink" src="{{cost_url}}" (click)="callKpiDetail('cost')" >
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{globalCurrency}} {{formatVal(kpi_meta.cost)}}</span>
								<br>
								Monthly allocated cost
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="issHealthCard">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:26px;">Health</span>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<img class="centerLink" src="{{health_url}}" (click)="callKpiDetail('health')" >
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<div class="card">
							<div class="card-block">
								<span style="font-weigth:bold;font-size:24px">{{kpi_meta.health}}</span>
								<br>
								VMs with performance issues
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<ng-template load-kpi-activity *ngIf="isActivity"></ng-template>
		<ng-template load-kpi-behavior *ngIf="isBehavior"></ng-template>
		<ng-template load-kpi-capacity *ngIf="isCapacity"></ng-template>
		<ng-template load-kpi-cost *ngIf="isCost"></ng-template>
		<ng-template load-kpi-health *ngIf="isHealth"></ng-template>
	</div>
</div>
