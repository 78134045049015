import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    GetAppsGroupsResponse, GetLastInfrastructureVersionResponse,
    GetTagsResponse,
    NetscopeService,
    PushAppsGroupsResponse,
    SuccessGetAppsGroupsResponse, SuccessGetLastInfrastructureVersionResponse,
    SuccessGetTagsResponse
} from "@app/services";
import {
    Router
} from "@angular/router";
import {
    of
} from "rxjs";
import {
    AppsGroupsComboboxComponent
} from "@app/netscope/netscope-apps-groups-edit/apps-groups-combobox/apps-groups-combobox.component";
import {
    AppsGroupsWizardTagsSelectionComponent,
    WizardActionEvent,
    NewAppGroupFromVMwareTagEvent,
    NewAppGroupFromVMwareCategoryEvent,
    NewEmptyManualAppgroupEvent,
    MergeAppsGroupsWithTheseAppsGroupsEvent
} from "@app/netscope/netscope-apps-groups-edit/apps-groups-wizard-tags-selection/apps-groups-wizard-tags-selection.component";


@Component({
    selector: 'app-netscope-apps-groups-edit',
    templateUrl: './netscope-apps-groups-edit.component.html',
    styleUrls: ['./netscope-apps-groups-edit.component.css']
})
export class NetscopeAppsGroupsEditComponent implements OnInit {

    isLoadingJson = false;
    isLoadingAppsGroups = false;
    isPushingAppsGroups = false;
    wizardIsLoading = false;

    failureMode = false;
    appsGroupsData;
    appsGroups = [];
    vms = [];

    // To simplify UI, we maintain these three following lists:
    manualAppGroups = [];
    tagsVMware = [];
    categoriesVMware = [];

    manualAppsGroupsIsActive = true;
    vmwareTagsIsActive = false;
    vmwareCategoriesIsActive = false;

    @ViewChild("wizard")
    wizard: AppsGroupsWizardTagsSelectionComponent;
    registeredToWizardEvent = false;

    csvSperator = ",";

    constructor(private netscopeService: NetscopeService, private route: Router) {
    }

    ngOnInit(): void {
        this.reloadAppsGroupsDataFromServer();
    }

    openWizard = () => {
        this.wizardIsLoading = true;
        this.netscopeService.getTags().subscribe((tagsDataResponse: GetTagsResponse) => {

            let tagsData;
            if (tagsDataResponse instanceof SuccessGetTagsResponse) {
                tagsData = tagsDataResponse.tags;
            }

            if (!this.registeredToWizardEvent) {
                this.wizard.newAppGroupFromWizard.subscribe((newElementEvent: WizardActionEvent) => {
                    if (newElementEvent instanceof NewAppGroupFromVMwareTagEvent) {
                        let newAppGroup = newElementEvent.newAppGroup;

                        if (newAppGroup.metadata !== undefined && newAppGroup.metadata
                            .trackVmWareResourceTypes !== undefined && newAppGroup.metadata
                            .trackVmWareResourceTypes.indexOf("tag") !== -1) {
                            if (this.appsGroupsData.vmware_tracked_tags === undefined) {
                                this.appsGroupsData.vmware_tracked_tags = [];
                            }
                            this.appsGroupsData.vmware_tracked_tags.push({
                                "name": newAppGroup.metadata.trackVmWareTag,
                                "category": newAppGroup.metadata.trackVmWareCategory,
                                "appGroup": newAppGroup
                            });
                        }
                    }

                    if (newElementEvent instanceof NewAppGroupFromVMwareCategoryEvent) {
                        let newCategory = newElementEvent.newCategory;
                        if (this.appsGroupsData.vmware_tracked_categories === undefined) {
                            this.appsGroupsData.vmware_tracked_categories = [];
                        }
                        this.appsGroupsData.vmware_tracked_categories.push(newCategory);
                    }

                    if (newElementEvent instanceof NewEmptyManualAppgroupEvent) {
                        this.addNewAppGroup();
                    }

                    if (newElementEvent instanceof MergeAppsGroupsWithTheseAppsGroupsEvent) {
                        this.mergeAppsGroups(newElementEvent.appsGroups)
                    }

                    this.refreshAppsGroups();
                });
                this.registeredToWizardEvent = true;
            }
            this.wizard.tagsData = tagsData;
            this.wizardIsLoading = false;
            this.wizard.open();
        })
    }

    exportManualAppsGroupsAsCSV = () => {
        let maxVmsPerAppGroup = Math.max(...this.manualAppGroups.map((appGroup) => appGroup.vms.length));
        let vmsLabelsColumns = [...Array(maxVmsPerAppGroup).keys()].map((columnNumber) => `VM${columnNumber}`);
        let csvHeader = ["App Group"].concat(vmsLabelsColumns).join(this.csvSperator);
        let csvContent = csvHeader + "\n";

        for (let manualAppGroup of this.manualAppGroups) {
            let lineValue = [manualAppGroup.name].concat(...manualAppGroup.vms.map((vm) => vm.name)).join(this
                .csvSperator) + "\n";
            csvContent += lineValue;
        }

        let exportedFilename = 'netscope-manual-appsgroups.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    exportVmsNotBelongingToAnAppGroupAsCSV = () => {
        let allVmsBelongingToAppsGroups = this.appsGroupsData.apps_groups.map((_) => _.vms).flat();
        const vmUuidIndex = new Map();
        allVmsBelongingToAppsGroups.map((_) => {
            vmUuidIndex.set(_.uuid, _.name);
        })

        // Find the VMs in the JSON data that are not in an appGroup
        let vmsNotBelongingToAnAppGroup = this.vms.filter((_) => vmUuidIndex.has(_.uuid));

        let csvHeader = ["Name", "Full_UUID", "Short_UUID"].join(this.csvSperator);
        let csvContent = csvHeader + "\n";

        for (let vm of vmsNotBelongingToAnAppGroup) {
            let lineValue = [vm.name, `vim.VirtualMachine:${vm.uuid}`, vm.uuid].join(this.csvSperator) + "\n";
            csvContent += lineValue;
        }

        let exportedFilename = 'netscope-vms-not-appsgroups.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    pushData = () => {
        this.isPushingAppsGroups = true;
        let curatedAppsGroups = this.appsGroupsData.apps_groups.map((nonCuratedAppGroup) => {
            let curatedAppGroup = {
                name: nonCuratedAppGroup.name,
                vms: nonCuratedAppGroup.vms.map((vm) => {
                    return {
                        uuid: vm.uuid
                    };
                }),
                metadata: {}
            };
            if (nonCuratedAppGroup.metadata !== undefined) {
                curatedAppGroup.metadata = nonCuratedAppGroup.metadata;
            }
            return curatedAppGroup;
        });

        if (this.appsGroupsData.vmware_tracked_categories === undefined) {
            this.appsGroupsData.vmware_tracked_categories = [];
        }

        let curatedVMwareCategories = this.appsGroupsData.vmware_tracked_categories.map((categoryData) => {
            let curatedVMwareCategory = {
                name: categoryData.name
            }
            return curatedVMwareCategory;
        });

        if (this.appsGroupsData.vmware_tracked_tags === undefined) {
            this.appsGroupsData.vmware_tracked_tags = [];
        }

        let curatedVMwareTags = this.appsGroupsData.vmware_tracked_tags.map((tagData) => {
            let curatedVMwareTag = {
                name: tagData.name,
                category: tagData.category
            }
            return curatedVMwareTag;
        });
        let appsGroupsPayload = {
            apps_groups: curatedAppsGroups,
            vmware_tracked_categories: curatedVMwareCategories,
            vmware_tracked_tags: curatedVMwareTags
        }
        this.netscopeService.pushAppsGroups(appsGroupsPayload).subscribe((response: PushAppsGroupsResponse) => {
            this.isPushingAppsGroups = false;
        })
    }

    reloadAppsGroupsDataFromServer = () => {
        this.isLoadingAppsGroups = true;

        this.netscopeService.getLastInfrastructureVersion().subscribe((infrastructureVersionResponse: GetLastInfrastructureVersionResponse) => {
            let infrastructureVersion;

            if (infrastructureVersionResponse instanceof SuccessGetLastInfrastructureVersionResponse) {
                infrastructureVersion = infrastructureVersionResponse.lastInfrastructureVersion;
            } else {
                this.failureMode = true;
                this.isLoadingAppsGroups = false;
                return;
            }

            this.vms = infrastructureVersion.topology.vm_only_topology.vms;

            this.netscopeService.getAppsGroups().subscribe((appsGroupsDataReponse: GetAppsGroupsResponse) => {
                let appsGroupsData;
                if (appsGroupsDataReponse instanceof SuccessGetAppsGroupsResponse) {
                    appsGroupsData = appsGroupsDataReponse.appsGroupsData;
                }
                this.appsGroupsData = appsGroupsData;
                this.isLoadingAppsGroups = false;
                this.refreshAppsGroups()
            });
        });

    }

    refreshAppsGroups = () => {
        let vmsUuuidToNameIdx = {};
        for (let vm of this.vms) {
            vmsUuuidToNameIdx[vm.uuid] = vm.name;
        }
        this.appsGroups = this.appsGroupsData.apps_groups;
        for (let appGroup of this.appsGroups) {
            for (let vm of appGroup.vms) {
                vm.name = vmsUuuidToNameIdx[vm.uuid];
            }
        }
        // Update the lists used for UI
        this.manualAppGroups = this.appsGroups.filter((appGroup) => appGroup.metadata === undefined || appGroup
            .metadata.trackVmWareTag === undefined || appGroup.metadata.trackVmWareTag === undefined);
        this.tagsVMware = this.appsGroupsData.vmware_tracked_tags;
        if (this.tagsVMware !== undefined) {
            for (let tagVMware of this.tagsVMware) {
                // Add appGroup to the VMwareTag
                let appGroupCandidates = this.appsGroups
                    .filter((appGroup) => appGroup.metadata !== undefined)
                    // .filter((appGroup) => appGroup.metadata.trackVmWareResourceTypes === "tag")
                    .filter((appGroup) => appGroup.metadata.trackVmWareCategory === tagVMware.category && appGroup
                        .metadata.trackVmWareTag === tagVMware.name);
                if (appGroupCandidates.length > 0) {
                    tagVMware.appGroup = appGroupCandidates[0];
                }
            }
        }

        this.categoriesVMware = this.appsGroupsData.vmware_tracked_categories !== undefined ? this.appsGroupsData
            .vmware_tracked_categories : [];
        if (this.categoriesVMware !== undefined) {
            for (let categoryVMware of this.categoriesVMware) {
                let tags = this.appsGroups
                    .filter((appGroup) => appGroup.metadata !== undefined)
                    // .filter((appGroup) => appGroup.metadata.trackVmWareResourceTypes === "category")
                    .filter((appGroup) => appGroup.metadata.trackVmWareCategory === categoryVMware.name)
                    .map((appGroup) => appGroup.name);
                if (categoryVMware.tags === undefined || categoryVMware.tags.length === 0) {
                    categoryVMware.tags = tags;
                }
            }
        }
    }

    mergeAppsGroups = (newAppsGroups) => {
        let existingAppsGroupsNames = this.appsGroupsData.apps_groups.map((_) => _.name);

        let newAppsGroupsThatDontExist = newAppsGroups.filter((_) => existingAppsGroupsNames.indexOf(_.name) === -
            1);
        let newAppsGroupsThatExist = newAppsGroups.filter((_) => existingAppsGroupsNames.indexOf(_.name) !== -1);

        // First, edit appGroups that don't exist
        for (let appGroup of newAppsGroupsThatExist) {
            let matchingAppGroups = this.appsGroupsData.apps_groups.filter((_) => _.name === appGroup.name);
            for (let matchingAppGroup of matchingAppGroups) {
                matchingAppGroup.vms = appGroup.vms;
            }
        }

        // Second, add appGroups that don't exist
        for (let appGroup of newAppsGroupsThatDontExist) {
            this.appsGroupsData.apps_groups.push({
                name: appGroup.name,
                vms: appGroup.vms
            })
        }

        this.refreshAppsGroups();
    }

    addNewAppGroup = () => {
        let newAppGroupName = "New app group";
        this.appsGroupsData.apps_groups.push({
            name: newAppGroupName,
            vms: []
        })
        this.refreshAppsGroups();
    }

    checkAppGroupName = (appGroup) => {
        const nameRegex = /^[A-Za-z0-9_]+$/;
        let checkNameResult = nameRegex.test(appGroup.name);

        appGroup.nameIsValid = checkNameResult;

        return checkNameResult;
    }

    startEditing = (appGroup) => {
        appGroup.showEditingFeatures = true;
    }

    stopEditing = (appGroup) => {
        let appGroupNameCheckResult = this.checkAppGroupName(appGroup);
        if (appGroupNameCheckResult) {
            appGroup.showEditingFeatures = false;
            this.refreshAppsGroups();
        }
    }

    deleteAppGroup = (appGroup) => {
        let indexOfAppGroup = this.appsGroupsData.apps_groups.indexOf(appGroup);
        if (indexOfAppGroup !== -1) {
            this.appsGroupsData.apps_groups = this.appsGroupsData.apps_groups.filter((e) => e !== appGroup);
            this.refreshAppsGroups();
        }
    }

    deleteVMwareTag = (tag) => {
        let indexOfAppGroup = this.appsGroupsData.vmware_tracked_tags.indexOf(tag);
        if (indexOfAppGroup !== -1) {
            this.appsGroupsData.vmware_tracked_tags = this.appsGroupsData.vmware_tracked_tags.filter((e) => e !==
                tag);
            this.refreshAppsGroups();
        }
    }

    deleteCategorie = (categorie) => {
        let indexOfAppGroup = this.appsGroupsData.vmware_tracked_categories.indexOf(categorie);
        if (indexOfAppGroup !== -1) {
            this.appsGroupsData.vmware_tracked_categories = this.appsGroupsData.vmware_tracked_categories.filter((
                e) => e !== categorie);
            this.refreshAppsGroups();
        }
    }

    fetchVms = (filter = '', combobox: AppsGroupsComboboxComponent) => {
        combobox.loadingResources = true;

        let resources = this.vms
            .filter((o) => o !== undefined)
            // @ts-ignore
            .filter((o) => o.uuid.indexOf(filter) !== 1 || o.name.indexOf(filter) !== -1);

        combobox.asyncResources$ = of(resources);
        combobox.loadingResources = false;
    }

    vmSelectionChanged = (appGroup, event) => {
        for (let r of appGroup.selectedResources) {
            appGroup.vms.push({
                uuid: r.uuid
            });
        }
        appGroup.selectedResources = [];
        event.model = [];
        this.refreshAppsGroups();
    }

    deleteVmFromAppGroup = (appGroup, vm) => {
        appGroup.vms = appGroup.vms.filter((vm2) => vm2.uuid !== vm.uuid);
    }

}

