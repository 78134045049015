import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    JsonloaderService,
    ShareService,
    CapaplanService
} from '@app/services';

import {
    JSONTarget,
    Message,
    RPBadge,
    RPPrediction,
    RpSynthesis,
    User
} from '@app/model';

import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import {
    RPType
} from '../rpsynthesis.enums';


@Component({
    selector: 'app-rpevolution',
    templateUrl: './rpevolution.component.html',
    styleUrls: ['./rpevolution.component.css']
})
export class RpevolutionComponent implements OnInit {

    @Input() data: RpSynthesis;

    message: Message;

    private currentUser: User;

    private data_prediction: any;

    rp_prediction: RPPrediction = {
        NBVM_RES: 0,
        NBVM_USAGE: 0,
        RESCPU_DATE: 0,
        RESRAM_DATE: 0,
        USAGECPU_DATE: 0,
        USAGERAM_DATE: 0,
        RES_DATE_SAT: '',
        USAGE_DATE_SAT: ''
    };

    rp_badge: RPBadge = {
        nbvm_res: false,
        nbvm_res_class: 'is-success',
        nbvm_usage: false,
        nbvm_usage_class: 'is-success',
        res_sat: 'badge-success',
        usage_sat: 'badge-success'
    };

    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.chart.showLoading();
    };

    // Default option
    options: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false
        },
        title: {
            text: 'Evolution of the number of VM',
            style: {
                font: 'normal 12px arial',
                color: 'grey'
            },
            align: 'center'
        },
        chart: {
            type: 'line',
            height: '33%',
            backgroundColor: '#ffffff',
            zoomType: 'x',
            resetZoomButton: {
                position: {
                    align: 'center',
                    y: -15
                }
            }
        },
        legend: {
            enabled: false
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            min: 0,
            labels: {
                align: 'right',
                x: -10
            },
            title: {
                text: 'VM number'
            }
        },
        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueDecimals: 0
        }
    };

    alert_state_vm: string;
    alert_state_date: string;


    /**
     * 
     */
    constructor(private authentication_svc: AccountService, private capaplan_svc: CapaplanService,
        private json_svc: JsonloaderService, private message_svc: ShareService) {

        this.alert_state_vm = 'info';
        this.alert_state_date = 'info';
    }

    /**
     * 
     */
    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        let target: JSONTarget = JSONTarget.RP_PREDICTION;
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, target).subscribe(
            data => {
                this.data_prediction = data;

                for (var i in this.data_prediction) {
                    if (this.data_prediction[i].UUID == this.message.currentUuid) {
                        this.rp_prediction.NBVM_RES = this.data_prediction[i].NBVM_CAP;
                        this.rp_prediction.NBVM_USAGE = this.data_prediction[i].NBVM_CONS;
                        this.rp_prediction.RESCPU_DATE = this.data_prediction[i].CAPCPU_DATE;
                        this.rp_prediction.RESRAM_DATE = this.data_prediction[i].CAPRAM_DATE;
                        this.rp_prediction.USAGECPU_DATE = this.data_prediction[i].CONSCPU_DATE;
                        this.rp_prediction.USAGERAM_DATE = this.data_prediction[i].CONSRAM_DATE;
                        break;
                    }
                }

                if (this.rp_prediction.NBVM_RES <= (this.data.vms * 0.1)) {
                    this.rp_badge.nbvm_res = true;
                    this.rp_badge.nbvm_res_class = 'is-error';
                    this.alert_state_vm = 'danger';
                } else if (this.rp_prediction.NBVM_RES <= (this.data.vms * 0.2)) {
                    this.rp_badge.nbvm_res = true;
                    this.rp_badge.nbvm_res_class = 'is-warning';
                    this.alert_state_vm = 'warning';
                }

                if (this.rp_prediction.NBVM_USAGE <= (this.data.vms * 0.1)) {
                    this.rp_badge.nbvm_usage = true;
                    this.rp_badge.nbvm_usage_class = 'is-error';
                    this.alert_state_vm = 'danger';
                } else if (this.rp_prediction.NBVM_USAGE <= (this.data.vms * 0.2)) {
                    this.rp_badge.nbvm_usage = true;
                    this.rp_badge.nbvm_usage_class = 'is-warning';
                    if (this.alert_state_vm != "danger") {
                        this.alert_state_vm = 'warning';
                    }
                }

                this.rp_prediction.RES_DATE_SAT = this.initSaturation(this.rp_prediction.RESCPU_DATE, this
                    .rp_prediction.RESRAM_DATE, RPType.RES);
                this.rp_prediction.USAGE_DATE_SAT = this.initSaturation(this.rp_prediction.USAGECPU_DATE, this
                    .rp_prediction.USAGERAM_DATE, RPType.USAGE);

            }
        );

        this.capaplan_svc.getEvolution(this.message.currentUuid).pipe(first()).subscribe(
            data => {
                let datas: any = data;
                let times = datas.map((e) => e["time"]);
                let vms = datas.map((e) => e["vms"]);
                let dataPoints = vms.map((e, i) => [times[i], e]);

                // Push to chart
                this.chart.addSeries({
                    type: 'line',
                    name: "The total number of VM",
                    cursor: 'pointer',
                    data: dataPoints
                });

                // Hide chart loading
                this.chart.hideLoading();

            }, error => {
                console.log('no evolution data ' + this.message.currentUuid);
            }
        );

    }

    /**
     * 
     */
    private initSaturation(cpu_date: number, ram_date: number, type: RPType): string {

        let date: string = '';
        let now = moment();

        let sat_date = moment.unix(cpu_date / 1000);
        if (ram_date < cpu_date)
            sat_date = moment.unix(ram_date / 1000);

        // INIT DATE & ALERT
        if (sat_date > now) {

            if ((sat_date.year() - now.year()) > 1) {
                date = 'over one year';
            } else {

                let days_remaining = 0;

                if (sat_date.year() == now.year())
                    days_remaining = sat_date.dayOfYear() - now.dayOfYear();
                else
                    days_remaining = 365 - now.dayOfYear() + sat_date.dayOfYear();

                if (days_remaining < 180) {
                    date = sat_date.format('LL');
                    if (days_remaining < 30) {
                        if (type == RPType.RES)
                            this.rp_badge.res_sat = 'badge-danger';
                        else
                            this.rp_badge.usage_sat = 'badge-danger';
                        this.alert_state_date = 'danger';
                    } else {
                        if (type == RPType.RES)
                            this.rp_badge.res_sat = 'badge-warning';
                        else
                            this.rp_badge.usage_sat = 'badge-warning';
                        if (this.alert_state_date != "danger")
                            this.alert_state_date = 'warning';
                    }
                } else {
                    date = 'over 6 months';
                }

            }
        } else {
            date = 'the date has passed';
            this.alert_state_date = 'danger';

            if (type == RPType.RES)
                this.rp_badge.res_sat = 'badge-danger';
            else
                this.rp_badge.usage_sat = 'badge-danger';
        }

        return date;
    }

}
