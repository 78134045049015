<div class="card-block">
	<div class="card-text">
		<div class="clr-row">
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
				<button class="btn btn-primary" style="float:left;" (click)="wizardnv.open();initWizard()">
					<cds-icon shape="plus-circle" class="is-solid"></cds-icon>
					CREATE NOTIFICATION
				</button>
			</div>
			<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
				<div class="card">
					<div class="card-block">
						<h5 class="card-title">select your notification</h5>
						<div class="card-text">
							<div class="clr-control-container">
								<div class="clr-select-wrapper">
									<select id="notification-view" class="clr-select" [(ngModel)]="selected_notification" (ngModelChange)="switchNotification($event)">
										<option *ngFor="let notif of notifications">{{notif.name}}</option>
									</select>
								</div>
								<span class="clr-subtext">the list of notifications</span>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="clr-control-container clr-control-inline">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="editNotification()">
								EDIT
							</button>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="removeNotification(true)">
								REMOVE
							</button>
							<clr-toggle-wrapper>
								<input type="checkbox" clrToggle name="state" value="enable" [(ngModel)]="enable_notif" (ngModelChange)="updateState($event)" />
								<label style="color:#0072a3;font-size:0.55rem">{{notif_state}}</label>
							</clr-toggle-wrapper>
						</div>
            				</div>
				</div>
			</div>
			<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
				<div class="card">
					<div class="card-block">
						<h5 class="card-title" *ngIf="isRdy">information on your notification {{selected_notification.name}}</h5>
						<div class="card-text" *ngIf="isRdy">
							<table class="table" *ngIf="isRdy">
  								<thead>
    									<tr>
      										<th>SNMP</th>
      										<th>EMAIL</th>
      										<th>EMAIL NUMBER</th>
      										<th>ALERT CONDITION</th>
      										<th>ALERT TYPE</th>
      										<th>ALERT NUMBER</th>
    									</tr>
  								</thead>
  								<tbody>
    									<tr>
										<td>{{this.info_model.snmp}}</td>
										<td>{{this.info_model.email}}</td>
										<td>{{this.info_model.email_nb}}</td>
										<td>{{this.info_model.alert_condition}}</td>
										<td>{{this.info_model.alert_type}}</td>
										<td>{{this.info_model.alert_nb}}</td>
    									</tr>
  								</tbody>
  							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isEdit">
				<div class="card">
					<div class="card-block">
						<div class="clr-row">
							<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
								<h5 class="card-title" *ngIf="isRdy">edit alert {{selected_notification.name}}</h5>
							</div>
							<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
								<button style="float: right;" type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateNotification()" *ngIf="isUpdate">
									Update
								</button>
							</div>
						</div>
						<div class="card-text">
							<clr-timeline>
								<clr-timeline-step clrState="success">
									<clr-timeline-step-header>{{formatEditDate(this.selected_notification.timeago)}}</clr-timeline-step-header>
    									<clr-timeline-step-title>CREATE or UPDATE</clr-timeline-step-title>
									<clr-timeline-step-description>Last modification date</clr-timeline-step-description>
  								</clr-timeline-step>
								<clr-timeline-step clrState="success">
									<clr-timeline-step-header>STEP 1</clr-timeline-step-header>
									<clr-timeline-step-title>NAME</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<div class="clr-input-container">
												<label>{{selected_notification.name}}</label>
											</div>
											<span class="clr-subtext">(cannot be changed)</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
							</clr-timeline>
							<clr-timeline style="padding-left:15%">
								<clr-timeline-step clrState="current">
									<clr-timeline-step-header>STEP 2</clr-timeline-step-header>
									<clr-timeline-step-title>SNMP</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<clr-checkbox-wrapper>
    												<input type="checkbox" clrCheckbox [(ngModel)]="edit_model.snmp" (ngModelChange)="enableUpdateSnmp($event)">
												<label>snmp status</label>
											</clr-checkbox-wrapper>
											<span class="clr-subtext">enable or disable the snmp notification</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="current">
									<clr-timeline-step-header>STEP 3</clr-timeline-step-header>
									<clr-timeline-step-title>EMAIL</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<clr-checkbox-wrapper>
    												<input type="checkbox" clrCheckbox [(ngModel)]="edit_model.email" (ngModelChange)="enableUpdateEmail($event)">
												<label>email status</label>
											</clr-checkbox-wrapper>
											<span class="clr-subtext">enable or disable the email notification</span>
										</div>
										<button class="btn btn-sm" (click)="isModalEmail = true;getEditEmailList()">EMAIL LIST</button>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="current">
									<clr-timeline-step-header>STEP 4</clr-timeline-step-header>
									<clr-timeline-step-title>ALERT CONDITION</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<clr-radio-wrapper>
												<input type="radio" clrRadio name="alertCondition" required [(ngModel)]="edit_model.alert_active" (ngModelChange)="enableUpdateCondition($event)" [value]=true />
												<label>all alerts are active</label>
  											</clr-radio-wrapper>
											<clr-radio-wrapper>
												<input type="radio" clrRadio name="alertCondition" required [(ngModel)]="edit_model.alert_active" (ngModelChange)="enableUpdateCondition($event)" [value]=false />
												<label>one of the alerts is active</label>
											</clr-radio-wrapper>
											<clr-control-helper>select the alert condition</clr-control-helper>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="current">
									<clr-timeline-step-header>STEP 5</clr-timeline-step-header>
									<clr-timeline-step-title>ALERT TYPE</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<clr-radio-wrapper>
												<input type="radio" clrRadio name="alertType" required [(ngModel)]="edit_model.alert_type_rt" (ngModelChange)="enableUpdateType($event)" [value]=true />
												<label>real time</label>
  											</clr-radio-wrapper>
											<clr-radio-wrapper>
												<input type="radio" clrRadio name="alertType" required [(ngModel)]="edit_model.alert_type_rt" (ngModelChange)="enableUpdateType($event)" [value]=false />
												<label>filter</label>
											</clr-radio-wrapper>
											<clr-control-helper>select the alert type</clr-control-helper>
										</div>
										<button class="btn btn-sm" (click)="isModalAlert = true;getEditAlertList()">ALERT LIST</button>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="{{update_state}}">
									<clr-timeline-step-header>{{now}}</clr-timeline-step-header>
									<clr-timeline-step-title>UPDATE</clr-timeline-step-title>
									<clr-timeline-step-description>
										{{update_result}}
									</clr-timeline-step-description>
								</clr-timeline-step>
							</clr-timeline>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<clr-wizard #wizardNotification
	[clrWizardSize]="'xl'"
	(clrWizardOnPrevious)="goBack()"
	(clrWizardOnCancel)=doReset() 
	(clrWizardOnFinish)="doFinish()">
    <clr-wizard-title>Create a notification</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="wizard_model.name == '' || filterList(wizard_model.name)">
	<ng-template clrPageTitle>The name of the notification</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-form-control">
					<div class="clr-input-container" [ngClass]="{'clr-error' :  wizard_model.error}">
						<input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="wizard_model.name" name="name" (ngModelChange)="checkName($event)" required />
						<cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="wizard_model.error"></cds-icon>
						<clr-control-error *ngIf="wizard_model.error">This field cannot set {{wizard_model.errorName}}</clr-control-error>
					</div>
					<span class="clr-subtext">the name of new notification</span>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
    <clr-wizard-page>
	<ng-template clrPageTitle>The type of the notification</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-row">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<clr-checkbox-container clrInline>
							<clr-checkbox-wrapper>
								<input type="checkbox" clrCheckbox name="notif_type" value="option1" [(ngModel)]="wizard_model.snmp" />
								<label>snmp</label>
							</clr-checkbox-wrapper>
							<clr-checkbox-wrapper>
								<input type="checkbox" clrCheckbox name="notif_type" value="option2" [(ngModel)]="wizard_model.email" />
								<label>email</label>
							</clr-checkbox-wrapper>
							<clr-control-helper>the way to be informed</clr-control-helper>
						</clr-checkbox-container>
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="wizard_model.email">
						<div class="clr-form-control">
							<div class="clr-control-container">
								<div class="clr-input-wrapper">
									<input type="text" id="add-email" placeholder="Enter new email address" class="clr-input" [(ngModel)]="wizard_model.add_email" />
									<cds-icon shape="plus-circle" class="addLink" style="color:#0072a3" (click)="addEmail(true)"></cds-icon>
								</div>
								<span class="clr-subtext">enter a new email address</span>
							</div>
						</div>
						<table class="table">
  							<thead>
    								<tr>
      									<th>LIST OF EMAIL ADDRESSES</th>
    								</tr>
  							</thead>
  							<tbody>
								<tr *ngFor="let email of wizard_model.emails">
									<td (click)="removeEmail(email, true)">
										{{email}}
										<cds-icon shape="minus-circle" class="addLink"></cds-icon>
									</td>
    								</tr>
  							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
    <clr-wizard-page>
	<ng-template clrPageTitle>Condition and type of alert</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-row">
					<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
						<clr-radio-container clrInline>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="alertCondition" required [(ngModel)]="wizard_model.alert_active" [value]=true />
								<label>all alerts are active</label>
  							</clr-radio-wrapper>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="alertCondition" required [(ngModel)]="wizard_model.alert_active" [value]=false />
								<label>one of the alerts is active</label>
							</clr-radio-wrapper>
							<clr-control-helper>select the alert condition</clr-control-helper>
						</clr-radio-container>
					</div>
					<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
						<clr-radio-container clrInline>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="alertType" required [(ngModel)]="wizard_model.alert_type_rt" [value]=true />
								<label>real time</label>
  							</clr-radio-wrapper>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="alertType" required [(ngModel)]="wizard_model.alert_type_rt" [value]=false />
								<label>filter</label>
							</clr-radio-wrapper>
							<clr-control-helper>select the alert type</clr-control-helper>
						</clr-radio-container>
					</div>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
    <clr-wizard-page [clrWizardPageNextDisabled]="wizard_model.alerts.length == 0">
	<ng-template clrPageTitle>The alert selection</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<clr-combobox-container>
					<clr-combobox [(ngModel)]="wizard_model.alerts" name="multiSelect" clrMulti="true" required>
						<ng-container *clrOptionSelected="let selected">
							<cds-icon shape="bell" role="img" aria-label="welcome home"></cds-icon> {{selected?.notified_name}}
						</ng-container>
						<clr-options>
							<clr-option *clrOptionItems="let alert of filterAlertByType(true); field:'notified_name'" [clrValue]="alert">
								<cds-icon shape="bell" role="img"></cds-icon> {{alert.notified_name}}
							</clr-option>
						</clr-options>
					</clr-combobox>
					<clr-control-helper>select one or more alerts</clr-control-helper>
				</clr-combobox-container>
			</div>
		</div>
	</div>
    </clr-wizard-page>
</clr-wizard>

<clr-modal [(clrModalOpen)]="isModalEmail" [clrModalSize]="'xs'">
	<h3 class="modal-title" *ngIf="isRdy">Email list for notification {{selected_notification.name}}</h3>
	<div class="modal-body">
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-form-control">
					<div class="clr-control-container">
						<div class="clr-input-wrapper">
							<input type="text" id="add-edit-email" placeholder="Enter new email address" class="clr-input" [(ngModel)]="edit_model.add_email" />
							<cds-icon shape="plus-circle" class="addLink" style="color:#0072a3" (click)="addEmail(false)"></cds-icon>
						</div>
						<span class="clr-subtext">enter a new email address</span>
					</div>
				</div>
				<table class="table">
  					<thead>
    						<tr>
      							<th>LIST OF EMAIL ADDRESSES</th>
    						</tr>
  					</thead>
  					<tbody>
						<tr *ngFor="let email of edit_model.emails">
							<td (click)="removeEmail(email, false)">
								{{email.email_add}}
								<cds-icon shape="minus-circle" class="addLink"></cds-icon>
							</td>
    						</tr>
  					</tbody>
				</table>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isModalEmail = false">OK</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isModalAlert" [clrModalSize]="'xs'">
    <h3 class="modal-title" *ngIf="isRdy">Alert list for notification {{selected_notification.name}}</h3>
    <div class="modal-body">
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<clr-combobox-container>
					<clr-combobox [(ngModel)]="edit_model.alerts" name="multiSelect" clrMulti="true" required>
						<ng-container *clrOptionSelected="let selected">
							<cds-icon shape="bell" role="img" aria-label="welcome home"></cds-icon> {{selected?.notified_name}}
						</ng-container>
						<clr-options>
							<clr-option *clrOptionItems="let alert of filterAlertByType(false); field:'notified_name'" [clrValue]="alert">
								<cds-icon shape="bell" role="img"></cds-icon> {{alert.notified_name}}
							</clr-option>
						</clr-options>
					</clr-combobox>
					<clr-control-helper>select one or more alerts</clr-control-helper>
				</clr-combobox-container>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isModalAlert = false">OK</button>
    </div>
</clr-modal>
