import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-selection-tree]'
})
export class SelectiontreeDirective {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
