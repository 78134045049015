import {
    Component,
    OnInit,
    Output,
    Input,
    ViewChild,
    EventEmitter
} from '@angular/core';
import {
    Observable,
    of
} from "rxjs";
import {
    ClrCombobox
} from "@clr/angular";
import {
    AccountService,
    GreenitService,
    ShareService
} from '@app/services';
import {
    tap
} from 'rxjs/internal/operators';
import {
    InstanceType,
    Message,
    User
} from '@app/model';


@Component({
    selector: 'app-power-combobox',
    templateUrl: './power-combobox.component.html',
    styleUrls: ['./power-combobox.component.css']
})
export class PowerComboboxComponent implements OnInit {

    currentUser: User;

    message: Message;

    loadingResources: boolean;

    items: Observable < any > ;
    selectedElementsArray: String[] = [];

    combobox_showList: boolean = false;
    combobox_active: boolean = false;

    skipEventBeforeThisTimestamp;

    @ViewChild('combobox') combobox: ClrCombobox < string > ;

    @Input()
    targetInfrastructure: string;
    getTargetInfrastructure() {
        return this.targetInfrastructure;
    }

    @Input()
    allElements: any;

    @Input()
    get selectedElements() {
        return this.selectedElementsArray;
    }

    @Output() selectedElementsChange = new EventEmitter();
    set selectedElements(element) {

        if (element == undefined) {
            element = [];
        }

        this.selectedElementsArray = element;
        this.selectedElementsChange.emit(this.selectedElementsArray);
        this.combobox_showList = false;

        // Execute this block 100ms later to give the time of the event to propagate
        setTimeout(() => {
            if (this.combobox != undefined) {
                this.combobox.searchText = "";
                this.combobox.focused = false;
                try {
                    this.combobox.textbox.nativeElement.blur();
                } catch (e) {
                    //console.log(e);
                }
            }
        }, 500);
    }

    @Output()
    onChange: EventEmitter < any > = new EventEmitter();


    /**
     * 
     */
    constructor(
        private account_svc: AccountService,
        private greenit_svc: GreenitService,
        private message_svc: ShareService) {
        this.loadingResources = false;
    }

    /**
     * 
     */
    ngOnInit() {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    clickComboBox = (childElement, combo: any, event) => {
        if (event.target !== undefined && event.target.currentShapeAttrVal === "close") {
            return
        }
        this.sendArrowDown();
    }

    sendArrowDown(): void {
        const event = new KeyboardEvent('keydown', {
            key: "ArrowDown"
        });
        this.combobox_showList = true;
        this.combobox.textbox.nativeElement.dispatchEvent(event, "plop");
    }

    _focus = () => {
        this.generateList('');
    }

    _clrInputChange = (event: string) => {
        if (this.combobox_active) {
            return this.generateList(event);
        } else {
            return null;
        }
    }

    _clrOpenChange = (event) => {

        let now = new Date().getTime();

        if (event && this.skipEventBeforeThisTimestamp !== undefined && now < this.skipEventBeforeThisTimestamp) {
            console.log(`I ignore openChange events as ${this.skipEventBeforeThisTimestamp} > now (${now})`);
            this.combobox_showList = false;
            return this._clrOpenChange(false);
        }
        this.combobox_active = event;

        let result = null;
        if (this.combobox_active) {
            this.clickComboBox(undefined, this, event);
            result = this.generateList('')
        } else {
            this.skipEventBeforeThisTimestamp = new Date().getTime() + 0.5 * 1000;
            console.log(`I set comboboxes to ignore openChange events before ${this.skipEventBeforeThisTimestamp}`);
        }

        return result;
    }

    _click = (event) => {
        this.clickComboBox(undefined, this, event);
    }

    _clrSelectionChange = (event: any) => {
        this.onChange.emit(event);
    }

    generateList(pattern: string): void {

        this.loadingResources = true;

        //console.log(this.allElements);
        //console.log(this.targetInfrastructure);

        switch (this.targetInfrastructure) {
            case InstanceType.VMWARE:
                this.items = this.greenit_svc.getElementsListObservable(pattern).pipe(tap(() => {
                    this.loadingResources = false;
                    this.sendArrowDown();
                }));
                break;
            case InstanceType.KUBERNETES:
                this.items = this.greenit_svc.getKubeElementsListObservable(this.currentUser.login, this.message
                    .elementView).pipe(tap(() => {
                    this.loadingResources = false;
                    this.sendArrowDown();
                }));
                break;
            case InstanceType.SCAPHANDRE:
            case InstanceType.XCLARITY:
            case InstanceType.OPENMANAGE:
            case InstanceType.ONEVIEW:
            case InstanceType.IBM:
            case InstanceType.STORAGE:
                let result = this.allElements
                    .flat()
                    .filter((o) => (o.name.toLowerCase().indexOf(pattern.toLowerCase()) > -1));

                this.items = of(result.slice(0, 20)).pipe()
                this.loadingResources = false;
                this.sendArrowDown();
                break;
            default:
                this.items = this.greenit_svc.getRegionElementsListObservable(pattern, this.targetInfrastructure,
                    this.message.cloudAccountId, this.currentUser.login, this.message.elementView).pipe(tap(
                    () => {
                        this.loadingResources = false;
                        this.sendArrowDown();
                    }));
                break;
        }
    }

}
