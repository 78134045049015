<div class="clr-row">
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
		<form clrForm>
			<clr-date-container>
				<label>start</label>
				<input type="date" clrDate name="start" [(ngModel)]="start" (ngModelChange)="updateStartDate($event)" min="{{range_time.min}}" max="{{range_time.max}}" [disabled]="disabled">
			</clr-date-container>
		</form>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
		<form clrForm>
			<clr-date-container>
				<label>end</label>
				<input type="date" clrDate name="end" [(ngModel)]="end" (ngModelChange)="updateEndDate($event)" min="{{range_time.min}}" max="{{range_time.max}}" [disabled]="disabled">
			</clr-date-container>
		</form>
	</div>
</div>
