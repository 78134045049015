import {
    Component,
    OnInit,
    Output,
    Input,
    ViewChild,
    EventEmitter,
    TemplateRef,
    ElementRef
} from '@angular/core';
import {
    Observable
} from "rxjs";
import {
    ClrCombobox,
    ClrComboboxContainer
} from "@clr/angular";

@Component({
    selector: 'app-apps-groups-combobox',
    templateUrl: './apps-groups-combobox.component.html',
    styleUrls: ['./apps-groups-combobox.component.css']
})
export class AppsGroupsComboboxComponent implements OnInit {

    loadingResources = false;
    asyncResources$: Observable < any > ;

    selectedItemsValue: String[] = [];
    comboboxOptionsAreVisible: Boolean = false;
    comboboxIsActive: Boolean = false;

    @Input()
    get selectedItems() {
        return this.selectedItemsValue;
    }

    @Output() selectedItemsChange = new EventEmitter();

    set selectedItems(val) {

        if (val == undefined) {
            val = [];
        }

        this.selectedItemsValue = val;
        this.selectedItemsChange.emit(this.selectedItemsValue);
        this.comboboxOptionsAreVisible = false;

        // Execute this block 100ms later to give the time of the event to propagate
        setTimeout(() => {
            if (this.resourceComboBox != undefined) {
                this.resourceComboBox.searchText = "";
                this.resourceComboBox.focused = false;
                this.resourceComboBox.textbox.nativeElement.blur();
            }
        }, 100);
    }

    @Output()
    onChange: EventEmitter < any > = new EventEmitter();

    @Input()
    itemsGenerator: (...args) => void;

    @Input()
    templateSelectedItems: TemplateRef < any > ;

    @Input()
    templateDisplayItems: TemplateRef < any > ;

    @Input()
    templateDisplayWhenEmpty: TemplateRef < any > ;

    @Input()
    templateDisplayWhenErrors: TemplateRef < any > ;

    @Input()
    itemsField: String;

    disabledCounters = {
        resource: false
    };

    @ViewChild('resourceComboBox') resourceComboBox: ClrCombobox < string > ;

    constructor() {}

    ngOnInit(): void {}

    clickComboBox = (childElement, combo: any) => {
        this.sendArrowDown();
    }

    sendArrowDown = () => {
        const event = new KeyboardEvent('keydown', {
            key: "ArrowDown"
        });
        this.comboboxOptionsAreVisible = true;
        this.resourceComboBox.textbox.nativeElement.dispatchEvent(event, "plop");
    }

    _focus = () => {
        console.log("_focus()");
        this.itemsGenerator();
    }

    _clrInputChange = (event) => {
        console.log(`_clrInputChange(${event})`);
        if (this.comboboxIsActive) {
            return this.itemsGenerator(event, this);
        } else {
            return null;
        }
    }

    _clrOpenChange = (event) => {
        console.log(`_clrOpenChange(${event})`);
        this.comboboxIsActive = event;
        let result = null;
        if (this.comboboxIsActive) {
            this.clickComboBox(this.resourceComboBox, this);
            result = this.itemsGenerator('', this)
        }
        return result;
    }

    _click = (resourceComboBox) => {
        console.log("_click()");
        this.clickComboBox(resourceComboBox, this);
    }

    _clrSelectionChange = (event) => {
        if (event.model !== null && event.model !== undefined && event.model.length > 1) {
            event.model = [event.model[event.model.length - 1]];
        }

        console.log(`_clrSelectionChange(${event})`);

        this.onChange.emit(event);
    }

}
