<div class="clr-row">
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <div class="card noScroll">
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="tree"></cds-icon>
                    &nbsp;CO2 Emissions
                </h5>
            </div>
            <div class="card-block">
                <div class="card-text">
                    <div *ngIf="isReady">
                        <div *ngIf="!network_data_check || !storage_data_check || refnodes_data_check" class="alert alert-warning" role="alert" style="margin-bottom:20px">
                            <div class="alert-items">
                                <div *ngIf="warning_message.length > 0" class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                    </div>
                                    <span class="alert-text">{{getWarnMessage()}}</span>
                                </div>
                                <div *ngIf="refnodes_data_check" class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                    </div>
                                    <span class="alert-text">
                                        Same nodes have been detected !&nbsp;&nbsp;<button style="vertical-align:unset;margin:0;float:right" class="btn btn-sm btn-link" (click)="refnodes_modal = true">See details</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <app-greenitcf #greenitcf [showStackLabel]=false [target]="t1_target" [allowReload]="true"></app-greenitcf>
                    </div>
                    <div *ngIf="!isReady">
                        <strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <app-t8 #t8 [target]="t2_target"></app-t8>
    </div>
</div>

<!-- MODAL REFNODES -->
<clr-modal [(clrModalOpen)]="refnodes_modal" [clrModalClosable]="true" [clrModalSize]="'xl'">
    <h3 class="modal-title">List of same nodes</h3>
    <div class="modal-body">
        <div class="clr-row" style="margin-top:20px;margin-left:0;">
            <div class="clr-col-1"></div>
            <div class="clr-col-10 info-custom">
                <p><cds-icon size="24" shape="help-info" solid="true"></cds-icon>&nbsp;Here are listed physical servers supervised by CO2 Scope that have been detected as same nodes.<br><br>
                    <b>For example :</b><br>
                </p>
                 <ul>
                    <li>A server, <em>serverA</em> is a Lenovo XClarity server with VMware ESXi installed,</li>
                    <li>CO2Scope is connected to VMware vCenter,</li>
                    <li>CO2Scope is connected to the Lenovo XClarity console.</li>
                </ul>
                <br>
                <p>==> : <em>serverA</em> is analyzed twice by CO2Scope: from the VMware connector and from the XClarity connector.</p>
                <br>
                <p>
                    <b>Solution :</b>&nbsp;CO2Scope analyzes the various data collected about physical servers to detect same nodes.
                    So, the energy and carbon weight of a same node (belonging to two connectors) is counted only once.
                </p>
                <br>
                <p><b>In the list below, <em>the reference node</em> is the main node, the one used by Green IT.</b></p>
            </div>
        </div>

        <clr-datagrid style="margin-top:20px;margin-bottom:20px;">
            <clr-dg-action-bar style="margin-top:0px">
                <div class="btn-group" style="float:right">
                    <button type="button" class="btn btn-sm btn-link" (click)="export()">
                        Export to CSV
                    </button>
                </div>
            </clr-dg-action-bar>
			<clr-dg-column [clrDgField]="'NAME'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">NAME</ng-container></clr-dg-column>
			<clr-dg-column [clrDgField]="'TYPE'"><ng-container *clrDgHideableColumn="{hidden: false}">TYPE</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'REF_NAME'"><ng-container *clrDgHideableColumn="{hidden: false}">REFERENCE NODE NAME</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'REF_TYPE'"><ng-container *clrDgHideableColumn="{hidden: false}">REFERENCE NODE TYPE</ng-container></clr-dg-column>
									
			<clr-dg-row *clrDgItems="let node of greenit_refnodes" [clrDgItem]="node">
				<clr-dg-cell>{{node.NAME}}</clr-dg-cell>
				<clr-dg-cell>{{node.TYPE}}</clr-dg-cell>
                <clr-dg-cell>{{node.REF_NAME}}</clr-dg-cell>
				<clr-dg-cell>{{node.REF_TYPE}}</clr-dg-cell>
			</clr-dg-row>
		</clr-datagrid>
    </div>
    <div class="modal-footer">
        <button type="button"  class="btn btn-primary" (click)="refnodes_modal=false">OK</button>
    </div>
</clr-modal>
