<ng-template #templateSelectedResources let-item="item">
    <span *ngIf="item?.type == 'host'" class="badge badge-blue">{{ item?.type }}</span>
    <span *ngIf="item?.type == 'vm'" class="badge badge-light-blue">{{ item?.type }}</span>
    <span *ngIf="item?.type == 'external_ip'" class="badge badge-orange">{{ item?.type }}</span>
    <span *ngIf="item?.type == 'unknown_ip'" class="badge badge-danger">{{ item?.type }}</span>
    {{ item?.name }}
</ng-template>

<ng-template #templateDisplayResources let-item='item'>
    <span *ngIf="item?.type == 'host'" class="badge badge-blue">{{ item?.type }}</span>
    <span *ngIf="item?.type == 'vm'" class="badge badge-light-blue">{{ item?.type }}</span>
    <span *ngIf="item?.type == 'external_ip'" class="badge badge-orange">{{ item?.type }}</span>
    <span *ngIf="item?.type == 'unknown_ip'" class="badge badge-danger">{{ item?.type }}</span>
    {{ item?.name }}
</ng-template>

<ng-template #templateDisplayResourcesWhenEmpty>
    <clr-control-helper slot="displayWhenEmpty">Type a resource name</clr-control-helper>
</ng-template>

<ng-template #templateDisplayResourcesWhenErrors>
    <clr-control-error slot="displayWhenError">There was an error</clr-control-error>
</ng-template>

<div style="width: 300px;">
    <clr-toggle-container>
        <label>Filter table values that match these values:</label>
        <clr-toggle-wrapper>
            <input type="checkbox" clrToggle name="options" value="option1" [(ngModel)]="sharedOptions.filterIsActive"
                   (change)="triggerEvent()"/>
            <label>{{ sharedOptions.filterIsActive ? "Filter is active" : "Filter is inactive" }}</label>
        </clr-toggle-wrapper>
    </clr-toggle-container>

    <resource-combobox
            [(selectedItems)]="displayedSelectedResources"
            [itemsGenerator]="fetchResources"
            (onChange)="resourceSelectionChanged($event)"
            [templateSelectedItems]="templateSelectedResources"
            [templateDisplayItems]="templateDisplayResources"
            [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
            [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
            [itemsField]="'name'"
            (click)="disableEventPropagation()">
    </resource-combobox>

    <ng-container *ngFor="let resourceWithSide of sharedOptions.selectedResources" >

        <span class="label clickable"style="margin: 0; padding: 0 .3rem .05rem;">
            <span class="badge badge-blue" style="margin-right: 5px; cursor: pointer;" (click)="toggleSide(resourceWithSide)">{{ resourceWithSide.side }}</span>

            {{ resourceWithSide.resource.name }}
            <cds-icon shape="times-circle" class="is-solid"
                      style="margin-left: 5px; color: gray;"
                      (click)="removeResource(resourceWithSide.resource)">
            </cds-icon>
        </span>
    </ng-container>
</div>
