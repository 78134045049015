<div class="clr-row">
        <div class="card" *ngIf="selected_sp == null">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<cds-icon shape="book"></cds-icon>
				Savings plan
			</span>
			<span *ngIf="!selected_sp" style="margin-left:100px;" class="label label-info">
				Pick a family to view its instances
			</span>
			<button class="btn btn-link" style="position:relative;float:right;" (click)="exportSavingsPlan()">
				Export
			</button>
                </div>
                <div class="card-block">
			<clr-datagrid [(clrDgSingleSelected)]="selected_sp">
				<clr-dg-column [clrDgField]="'family'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Family</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'computeCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute cost On Demand ($/month)</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'computeCost1Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Computer cost 1 Year ($/month)</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'commitment1Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Estimated commitment 1 Year ($/hour)</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'computeCost3Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Computer cost 3 Years ($/month)</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'commitment3Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Estimated commitment cost 3 Years ($/hour)</ng-container></clr-dg-column>
				<clr-dg-row *clrDgItems="let sp of savingplans" [clrDgItem]="sp">
					<clr-dg-cell>{{sp.family}}</clr-dg-cell>
					<clr-dg-cell>{{sp.computeCost.toFixed()}}</clr-dg-cell>
					<clr-dg-cell>{{sp.computeCost1Y.toFixed()}}</clr-dg-cell>
					<clr-dg-cell>{{sp.commitment1Y.toFixed(4)}}</clr-dg-cell>
					<clr-dg-cell>{{sp.computeCost3Y.toFixed()}}</clr-dg-cell>
					<clr-dg-cell>{{sp.commitment3Y.toFixed(4)}}</clr-dg-cell>
				</clr-dg-row>
				<clr-dg-footer>
					<clr-dg-pagination #pagination [clrDgPageSize]="10">
						<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
							entries per page
						</clr-dg-page-size>
						{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
					</clr-dg-pagination>
				</clr-dg-footer>
                         </clr-datagrid>
		</div>
	</div>
	<div class="card" *ngIf="selected_sp != null">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				Instance list in the {{selected_sp.family}} family
			</span>
			<span style="float: right;" ngClass="addLink" (click)="selected_sp = null">
				<cds-icon shape="times"></cds-icon>
			</span>
                </div>
                <div class="card-block">
			<div class="datagrid-limit-height">
				<clr-datagrid>
					<clr-dg-column>Compute cost ($/month)</clr-dg-column>
					<clr-dg-column>Computer cost 1 Year ($/month)</clr-dg-column>
					<clr-dg-column>Estimated commitment 1 Year ($/hour)</clr-dg-column>
					<clr-dg-column>Computer cost 3 Years ($/month)</clr-dg-column>
					<clr-dg-column>Estimated commitment 3 Years ($/hour)</clr-dg-column>
					<clr-dg-row>
						<clr-dg-cell>{{selected_sp.computeCost.toFixed()}}</clr-dg-cell>
						<clr-dg-cell>{{selected_sp.computeCost1Y.toFixed()}}</clr-dg-cell>
						<clr-dg-cell>{{selected_sp.commitment1Y.toFixed(4)}}</clr-dg-cell>
						<clr-dg-cell>{{selected_sp.computeCost3Y.toFixed()}}</clr-dg-cell>
						<clr-dg-cell>{{selected_sp.commitment3Y.toFixed(4)}}</clr-dg-cell>
					</clr-dg-row>	
				</clr-datagrid>
				<br>
				<span style="color:grey;font-size:18px;">
					Instances
				</span>
				<clr-datagrid>
					<clr-dg-column [clrDgField]="'instanceId'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'instanceType'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Instance type</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'runHour'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Running hours</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'avgRunHour'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Number of running hours per day</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'computeCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute cost On Demand ($/month)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'computeCost1Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute cost 1Y ($/month)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'commitment1Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Estimated commitment 1Y ($/hour)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'computeCost3Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute cost 3Y ($/month)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'commitment3Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Estimated commitment 3Y ($/hour)</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let spi of selected_sp.spi" [clrDgItem]="spi">
						<clr-dg-cell>{{spi.instanceId}}</clr-dg-cell>
						<clr-dg-cell>{{spi.instanceType}}</clr-dg-cell>
						<clr-dg-cell>{{spi.bd.runHour}}</clr-dg-cell>
						<clr-dg-cell>{{spi.bd.avgRunHour}}</clr-dg-cell>
						<clr-dg-cell>{{spi.bd.computeCost.toFixed(2)}}</clr-dg-cell>
						<clr-dg-cell>{{spi.bd.computeCost1Y.toFixed(2)}}</clr-dg-cell>
						<clr-dg-cell>{{spi.bd.commitment1Y.toFixed(4)}}</clr-dg-cell>
						<clr-dg-cell>{{spi.bd.computeCost3Y.toFixed(2)}}</clr-dg-cell>
						<clr-dg-cell>{{spi.bd.commitment3Y.toFixed(4)}}</clr-dg-cell>
					</clr-dg-row>
					<clr-dg-footer>
						<clr-dg-pagination #pagination [clrDgPageSize]="10">
							<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
								entries per page
							</clr-dg-page-size>
							{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
						</clr-dg-pagination>
					</clr-dg-footer>
				</clr-datagrid>
			</div>
		</div>
	</div>
	<div class="card" *ngIf="resizes.length > 0">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				Instance reservation
			</span>
			<!--<button class="btn btn-link" style="position:relative;float:right;" (click)="exportResizing()">
				Export
			</button>-->
                </div>
                <div class="card-block">
			<div class="datagrid-limit-height">
				<clr-datagrid>
					<clr-dg-column [clrDgField]="'instanceId'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'resaSolution.odEstimated1Y'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">On demand (estimated over one year)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'resaSolution.resa1Year'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">1 year reservation</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'resaSolution.resa3Years'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">3 years reservation</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let resize of resizes" [clrDgItem]="resize">
						<clr-dg-cell>{{resize.instanceId}}</clr-dg-cell>
						<clr-dg-cell>{{resize.name}}</clr-dg-cell>
						<clr-dg-cell>{{resize.resaSolution.odEstimated1Y.toFixed()}}</clr-dg-cell>
						<clr-dg-cell>{{resize.resaSolution.resa1Year.toFixed()}}</clr-dg-cell>
						<clr-dg-cell>{{resize.resaSolution.resa3Years.toFixed()}}</clr-dg-cell>
					</clr-dg-row>
					<clr-dg-footer>
                				<clr-dg-pagination #pagination [clrDgPageSize]="10">
                    					<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
								    entries per page
							</clr-dg-page-size>
                    					{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                				</clr-dg-pagination>
            				</clr-dg-footer>
				</clr-datagrid>
			</div>
                </div>
        </div>
</div>