import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-costgeneric-vm]'
})
export class CostvmgenericDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
