import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-monthly-cal]'
})
export class MonthlycalDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
