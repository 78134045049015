<table id="reco-resizing-cpu-list" datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th>VM</th>
			<th>ACTION</th>
		</tr>
	</thead>
	<tbody>
    		<tr *ngFor="let reco of cpu_model">
			<td>{{reco.name}}</td>
			<td *ngIf="reco.reco_cpu < 0; else addCpu">remove {{math.abs(reco.reco_cpu)}} vcpu</td>
			<ng-template #addCpu>
				<td>add {{reco.reco_cpu}} vcpu</td>
			</ng-template>
		</tr>
	</tbody>
</table>
