import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-t4]'
})
export class T4Directive {
    constructor(public viewContainerRef4: ViewContainerRef) {}
}
