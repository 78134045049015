import {
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {
    Message,
    User
} from '@app/model';
import {
    AccountService,
    ShareService
} from '@app/services';
import {
    VirtindexService
} from '@app/services/virtindex.service';
import {
    ClrDatagridSortOrder
} from '@clr/angular';

@Component({
    selector: 'app-virtindex',
    templateUrl: './virtIndex.component.html',
    styleUrls: ['./virtIndex.component.css']
})
export class VirtIndexComponent implements OnInit {

    role: User;

    message: Message;

    dataOrder = ClrDatagridSortOrder.ASC;

    toggle_modal: boolean;

    alert_modal: boolean;
    alert_msg: string = "An error occurred while retrieving data ...";

    overview_active: boolean;
    infrastructure_active: boolean;
    greenit_active: boolean;
    behavior_active: boolean;

    show_data: boolean;
    show_example: boolean;

    settings_status: boolean;

    private unsubscribe_init;
    private unsubscribe_data;

    infrastructure_data: any = {};
    greenit_data: any = {};
    behavior_data: any = {};

    carbon_radar: Array < any > = [];

    default_values_alert: boolean;
    default_values: Array < any > = [];
    default_names: string = "";

    /**
     * 
     */
    constructor(private cdref: ChangeDetectorRef, private authentication_svc: AccountService,
        private message_svc: ShareService, private virtindex_svc: VirtindexService) {

        this.settings_status = false;
        this.show_data = false;
        this.show_example = false;

        this.overview_active = true;
        this.infrastructure_active = false;
        this.greenit_active = false;
        this.behavior_active = false;

        this.toggle_modal = false;
        this.alert_modal = false;

        this.default_values_alert = false;

    }

    /**
     * 
     */
    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.role = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.unsubscribe_init = this.virtindex_svc.virtindexSettings.subscribe(settings => {

            // Get settings
            for (let i in settings) {
                if (settings[i] != undefined) {
                    switch (settings[i].option) {
                        case 'status':
                            this.settings_status = String(settings[i].value) == "true";
                            break;
                        default:
                            break;
                    }
                }
            }

            // Build data
            if (this.settings_status) {
                this.buildData();
            } else {
                this.show_example = true;
            }

        });

        this.unsubscribe_init.unsubscribe();
    }

    /**
     * 
     */
    ngAfterContentChecked(): void {
        this.cdref.detectChanges();
    }

    /**
     * 
     */
    buildData(): void {

        if (this.unsubscribe_data != undefined) {
            this.unsubscribe_data.unsubscribe();
        }

        // FIX ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.message.waiting = true;
        });

        // Update data from server
        this.virtindex_svc.getVirtindexData();

        // Get data
        this.unsubscribe_data = this.virtindex_svc.virtindexData.subscribe(
            data => {

                // Waiting data
                // alert != undefined, data ok
                if (this.virtindex_svc.show_alert != undefined) {
                    this.message.waiting = false;

                    if (this.virtindex_svc.show_alert) {

                        // If error show alert
                        this.alert_modal = true;
                        this.alert_msg = this.virtindex_svc.alert_msg;

                    } else {

                        if (data.server != undefined) {

                            // Init infra tab data
                            this.infrastructure_data.vcpu = data.vcpu;
                            this.infrastructure_data.server = data.server;
                            this.infrastructure_data.socket = data.socket;
                            this.infrastructure_data.ramallocpercent = data.ramallocpercent;
                            this.infrastructure_data.ramusepercent = data.ramusepercent;
                            this.infrastructure_data.cpuusepercent = data.cpuusepercent;

                            // Init greenit tab data
                            this.greenit_data.ageserver = data.ageserver;
                            this.greenit_data.pueserver = data.pueserver;
                            this.greenit_data.coeffco2server = data.coeffco2server;
                            this.greenit_data.wattsvm = data.wattsvm;
                            this.greenit_data.co2gvm = data.co2gvm;
                            this.greenit_data.co2tvm = data.co2tvm;
                            this.greenit_data.co2gserver = data.co2gserver;
                            this.greenit_data.kwhserver = data.kwhserver;

                            // Init behavior tab data
                            this.behavior_data.idle = data.idle;
                            this.behavior_data.over = data.over;
                            this.behavior_data.under = data.under;
                            this.behavior_data.idle_evol = data.idle_evol;
                            this.behavior_data.over_evol = data.over_evol;
                            this.behavior_data.under_evol = data.under_evol;

                            // Init overview (gauge & radar) data
                            this.carbon_radar = [data.server, data.socket,
                                data.ageserver, data.pueserver, data.coeffco2server,
                                data.kwhserver, data.co2gserver,
                                data.wattsvm, data.co2gvm, data.co2tvm
                            ];

                            // Alert if default value detected

                            this.default_values_alert = false;
                            this.default_values = [];
                            this.default_names = "";

                            const keys = Object.keys(this.greenit_data);
                            for (let i = 0; i < keys.length; i++) {
                                const key = keys[i];
                                let tmp_data = this.greenit_data[key];
                                if (tmp_data.alert_default == true) {
                                    this.default_values_alert = true;
                                    let data = {
                                        'name': tmp_data.name,
                                        'value': tmp_data.you
                                    };
                                    this.default_values.push(data);
                                }
                            }

                            if (this.default_values_alert) {
                                this.default_names = Object.values < any > (this.default_values).map(data =>
                                    data.name).join(", ");
                            }

                            // Show data
                            this.show_data = true;
                            this.show_example = false;
                        }
                    }

                }

            },
            error => {
                // If no data show alert
                this.alert_modal = true;
                this.alert_msg = this.virtindex_svc.alert_msg;
                this.message.waiting = false;
            });
    }

    /**
     * 
     */
    toggleAction(): void {

        if (!this.settings_status) {
            this.toggle_modal = true;
        } else {
            this.show_data = false;
            this.show_example = true;

            // update settings 
            let json = '{"status" : ' + this.show_data + ' }';
            this.virtindex_svc.updateVirtindexSettings(JSON.parse(json));
        }
    }

    /**
     * 
     */
    confirmData(show: boolean) {

        this.toggle_modal = false;
        this.settings_status = show;

        if (show) {
            // update settings 
            let json = '{"status" : ' + this.settings_status + '}';
            this.virtindex_svc.updateVirtindexSettings(JSON.parse(json));

            // no example
            this.show_example = false;

            // build data
            this.buildData();
        }
    }

    /**
     * 
     */
    changeTab(tab_index: number): void {
        switch (tab_index) {
            case 1:
                this.infrastructure_active = true;
                break;
            case 2:
                this.greenit_active = true;
                break;
            default:
                break;
        }
    }

    /**
     * 
     */
    formatNumber(entry: string): Number {
        return Number(entry);
    }

    /**
     * 
     */
    exportGreenit() {
        let res = ["Metric", "Me", "Min", "Median", "Max"].join(',') + '\n';
        res += Object.values < any > (this.greenit_data).map(data => [data.name, data.you, data.min, data.median, data
            .max
        ].join(",")).join('\n');

        this.exportCSV(res);
    }

    /**
     * 
     */
    exportInfra() {
        let res = ["Metric", "Me", "Min", "Median", "Max"].join(',') + '\n';
        res += Object.values < any > (this.infrastructure_data).map(data => [data.name, data.you, data.min, data.median,
            data.max
        ].join(",")).join('\n');

        this.exportCSV(res);
    }

    /**
     * 
     */
    exportBehavior() {
        let res = ["Metric", "Me", "Min", "Median", "Max"].join(',') + '\n';
        res += Object.values < any > (this.behavior_data).map(data => [data.name, data.you, data.min, data.median, data
            .max
        ].join(",")).join('\n');

        this.exportCSV(res);
    }

    /**
     * 
     */
    private exportCSV(res: string) {
        let file_type = "text/csv;charset=utf-8;";

        let fileName = 'export.csv';
        if (this.greenit_active)
            fileName = "greenit.csv";
        else if (this.infrastructure_active)
            fileName = "infrastructure.csv";
        else if (this.behavior_active)
            fileName = "behavior.csv";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

}
