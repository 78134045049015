import {
    Component,
    ChangeDetectorRef,
    OnInit,
    Input
} from '@angular/core';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    Json,
    JSONTarget,
    Message,
    User
} from '@app/model';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);


@Component({
    selector: 'app-t8',
    templateUrl: './t8.component.html',
    styleUrls: ['./t8.component.css']
})
export class T8Component implements OnInit {

    @Input() target: JSONTarget;

    message: Message;

    private currentUser: User;

    data_t8: any = {};

    isReady: boolean = false;

    myClass: string = "card noScroll";


    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.chart.showLoading();
    };

    options: Highcharts.Options = {
        chart: {
            height: '250px',
            backgroundColor: 'rgba(255, 255, 255, 0.0)'
        },
        credits: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        exporting: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            ordinal: false
        },
        yAxis: [{
            id: 'yaxis-1',
            title: {
                text: 'kg CO2eq'
            }
        }, ]
    }

    /**
     * 
     */
    constructor(private authentication_svc: AccountService, private message_svc: ShareService,
        private json_svc: JsonloaderService, private cd: ChangeDetectorRef) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        if (this.target == undefined)
            this.target = JSONTarget.T8;

        if (this.target == JSONTarget.T8)
            this.myClass = "card addScroll";

        // Load data
        this.load(false);
    }

    /**
     * 
     */
    load(reload: boolean): void {
        this.isReady = false;

        // Get data
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, this.target).subscribe(
            data => {
                this.data_t8 = data;

                if (this.data_t8 != undefined && this.data_t8.TIME != undefined) {
                    this.isReady = true;
                    setTimeout(() => {
                        this.setData(reload);
                    }, 100);
                }
            }
        );
    }

    /**
     * 
     */
    private setData(reload: boolean): void {

        // Check chart
        if (this.chart === undefined || this.chart.axes === undefined)
            return;

        if (reload) {
            try {
                // Show loading
                this.chart.showLoading();

                // Remove old series
                for (var i = this.chart.series.length - 1; i >= 0; i--) {
                    this.chart.series[i].remove(false);
                }

                // Remove old axis
                this.chart.get('yaxis-2').remove(false);
            } catch {
                // Nothing
            }
        }

        // Init vars
        let time_data = [];
        let wh_data: number[] = [];
        let co2_data: number[] = [];

        // Dynamic unit for Wh
        let wh_unit = "Wh";

        let granularity = this.data_t8.GRANULARITY;
        time_data = this.data_t8.TIME;
        wh_data = this.data_t8.WH;
        co2_data = this.data_t8.KGCO2;

        wh_data.forEach(function(element, index, array) {
            if (element > 2000) {
                wh_unit = "kWh";
            }
        });

        wh_data.forEach(function(element, index, array) {
            if (wh_unit == "kWh") {
                element = Number(element) / Number(1000);
                element = Number(element.toFixed(2));
                array[index] = element;
            }
        });

        co2_data.forEach(function(element, index, array) {
            element = Number(element.toFixed(2));
            array[index] = element;
        });

        let co2_tmp: Array < Array < Number >> = [];
        let wh_tmp: Array < Array < Number >> = [];

        time_data.forEach(function(element, index, array) {
            let lastTime = element;
            if (granularity == "MONTHLY") {
                //XXX in order to show last month number (first day of month minus a day)
                // then build date from month & year
                lastTime = parseInt(lastTime) - 24 * 3600 * 1000;
                let date: Date = new Date(lastTime);

                let year_date = date.getFullYear();
                let month_date = date.getMonth() + 1; //month begins from 0
                let new_date: Date = new Date(year_date + '-' + month_date + '-01');

                lastTime = new_date.getTime();
            }

            co2_tmp[index] = [lastTime, co2_data[index]];
            wh_tmp[index] = [lastTime, wh_data[index]];
        });

        // Custom tooltip for monthly
        let tooltip: string;
        if (granularity == "MONTHLY") {
            tooltip = '%b %Y';
        }

        // Push to chart
        this.chart.addSeries({
            name: "CO2 emissions (kg CO2eq)",
            color: '#08298A',
            type: 'line',
            data: co2_tmp,
            yAxis: 'yaxis-1',
            tooltip: {
                xDateFormat: tooltip
            }
        }, false);

        // Add secondary Y-axis
        this.chart.addAxis({
            id: 'yaxis-2',
            opposite: true,
            title: {
                text: wh_unit
            },
        }, false);

        this.chart.addSeries({
            name: 'Direct energy (' + wh_unit + ')',
            color: '#0B610B',
            type: 'line',
            data: wh_tmp,
            yAxis: 'yaxis-2',
            tooltip: {
                xDateFormat: tooltip
            }
        });

        // Point coloration
        for (var i = 0; i < this.chart.series.length; i++) {
            var nbpoints = this.chart.series[i].data.length;
            this.chart.series[i].data[nbpoints - 1].update({
                marker: {
                    enabled: true,
                    fillColor: 'orange',
                    radius: 5,
                    states: {
                        hover: {
                            fillColor: 'orange'
                        }
                    }
                }
            });
        }

        // Hide loading
        this.chart.hideLoading();
    }
}
