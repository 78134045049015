import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import * as d3 from 'd3';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';


@Component({
    selector: 'app-container-detail',
    templateUrl: './container-detail.component.html',
    styleUrls: ['./container-detail.component.css']
})
export class ContainerDetailComponent implements OnChanges, OnInit {

    @Input() isreload: boolean = false;

    dataOrder = ClrDatagridSortOrder.ASC;

    message: Message;

    isDcscope = true;
    isCo2scope = false;

    months: any = [];

    overEvolution: any = [];

    med: any = null;

    containersAll: any = [];

    podsAll: any = [];

    nodesAll: any = [];

    containers: any = [];

    pods: any = [];

    nodes: any = [];

    isReady: boolean = false;

    data = {};

    svg;
    height = 1024;
    width = 768;
    margin = 20;

    loading = false;

    availableMetrics = [{
        "name": "cpu",
        "label": "cpu consumption",
        "json_property": "cpu"
    }, {
        "name": "ram",
        "label": "ram consumption",
        "json_property": "ram"
    }, {
        "name": "power",
        "label": "power consumption",
        "json_property": "power"
    }]

    selectedMetric = "power"

    displayedPath = ["Infrastructure"];
    secondTableShouldChange = new EventEmitter();

    listedContainers = [];

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_MONTH)
            .subscribe(
                data => {
                    this.months = data;
                    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                        .KUBERNETES_POD).subscribe(
                        data => {
                            this.podsAll = data;
                            let val: string = '';
                            val = this.months[0].name;
                            for (let obj of this.podsAll[val.replace(/ /g, "_")]) {
                                this.pods.push(obj);
                            }

                            this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                                .KUBERNETES_NODE).subscribe(
                                data => {
                                    this.nodesAll = data;
                                    for (let obj of this.nodesAll[val.replace(/ /g, "_")]) {
                                        this.nodes.push(obj);
                                    }
                                    this.loadContainers(val);
                                }
                            );
                        }
                    );
                }
            );

        setTimeout(() => {
            this.refreshData(this.message.kubeCluster);

            window.onresize = () => {
                this.eraseTreeMap();
                this.createTreeMap();
            };
        }, 100);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.message != undefined) {
            if (changes['isreload'].currentValue)
                this.refreshData(this.message.kubeCluster);
        }
    }

    convertTimeToDate(time: number): string {
        return new Date(time * 1000).toLocaleDateString('en-EN');
    }

    resetNavigation() {
        this.displayedPath = ["Infrastructure"];
        this.refreshData(this.message.kubeCluster);
    }

    selectMetric(metric) {

        let previousMetric = this.selectedMetric;
        this.selectedMetric = metric;

        this.refreshData(this.message.kubeCluster);
    }

    changeSelectionFromTopMenu(lastElementPath) {
        let indexOfLastElementPath = this.displayedPath.indexOf(lastElementPath);
        this.changeSelection(this.displayedPath.slice(0, indexOfLastElementPath + 1));
    }

    changeSelection(newPath) {
        this.displayedPath = newPath;
        this.displayedPath[0] = "Infrastructure";
        this.refreshData(this.message.kubeCluster);
    }

    disableDetail(): void {

        setTimeout(() => {
            this.message.kubeDetail = false
        }, 100);
    }

    private refreshData(cluster: any) {

        let cluster_json: string = cluster.replaceAll('-', '');

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_TREEMAP)
            .subscribe(
                data => {
                    let dcviewTreeDataCopy = JSON.parse(JSON.stringify(data));
                    this.data = this.processData(dcviewTreeDataCopy);
                    this.createTreeMap();
                }
            );
    }

    private processData(data: any) {

        let selectedMetric = "power";

        function color(data) {
            if (data.type == "ROOT" || data.type == "CLUSTER") {
                return "white";
            }
            if (data.type == "CONTAINER") {
                return "grey";
            }
            return "black";
        }

        let addAggregatedData = (data, selectedMetric, recursionLevel = 0, parentPath = []) => {
            let currentPath = parentPath.concat(data.name);
            data.recursionLevel = recursionLevel
            data.textColor = color(data);
            data.path = currentPath;
            data.children.map((c) => c.parent = data);
            data.children = data.children.map((c) => addAggregatedData(c, selectedMetric, recursionLevel + 1,
                currentPath));
            data.sumChildrenValues = data.children.map((c) => c.value).reduce((a, b) => a + b, 0);
            data.labelled_name = data.name;

            if (data.type == "CONTAINER") {
                let propertyName = this.availableMetrics.filter((m) => m.name == this.selectedMetric)[0][
                    "json_property"
                ];
                data.value = data[propertyName];
            }

            if (data.type == "ROOT") {
                data.labelled_name = "Infrastructure";
            }

            let desiredPodMinSize = 15;
            if (data.type == "POD" && data.sumChildrenValues <= desiredPodMinSize) {
                if (data.sumChildrenValues > 0) {
                    data.value = desiredPodMinSize - data.sumChildrenValues;
                    if (data.value > 0.66 * desiredPodMinSize) {
                        let ratio = (desiredPodMinSize - data.value) / data.sumChildrenValues;
                        data.children.map((c) => c.value = ratio * c.value);
                        data.sumChildrenValues = data.children.map((c) => c.value).reduce((a, b) => a + b, 0);
                        data.value = desiredPodMinSize - data.sumChildrenValues;
                    }
                } else {
                    data.value = desiredPodMinSize;
                }
            }

            if (data.type == "CONTAINER") {
                data.labelled_name = "";
            }

            if (data.type == "CONTAINER") {
                let create: number = 0;
                let ns: string = '';
                let image: string = '';
                let state: string = '';
                let podState: string = '';
                let podNamespace: string = '';
                let podIp: string = '';
                let pod: string = '';
                let podCreation: number = 0;
                let node: string = '';
                let nodeIp: string = '';
                let nodeArch: string = '';
                let nodeOs: string = '';
                let nodeOsimage: string = '';
                let nodeCpu: number = 0;
                let nodeRam: number = 0;
                let nodeCreation: number = 0;

                for (let obj of this.containers) {
                    if (obj.identifier === data.uuid) {
                        create = obj.creation;
                        ns = obj.namespace;
                        image = obj.image;
                        state = obj.state;
                        for (let obj2 of this.pods) {
                            if (obj2.identifier === obj.fatherId) {
                                podState = obj2.state;
                                podNamespace = obj2.namespace;
                                podIp = obj2.ip;
                                pod = obj2.name;
                                podCreation = obj2.creation;
                                for (let obj3 of this.nodes) {
                                    if (obj3.identifier === obj2.fatherId) {
                                        node = obj3.name;
                                        nodeIp = obj3.ip;
                                        nodeArch = obj3.arch;
                                        nodeOs = obj3.os;
                                        nodeOsimage = obj3.osimage;
                                        nodeCpu = obj3.cpu;
                                        nodeRam = obj3.ram;
                                        nodeCreation = obj3.creation;
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }
                }

                let containerObject = {
                    name: data.name,
                    cpu: data.cpu,
                    ram: data.ram,
                    power: data.power,
                    uuid: data.uuid,
                    creation: create,
                    namespace: ns,
                    image: image,
                    state: state,
                    pod: pod,
                    podState: podState,
                    podIp: podIp,
                    podNamespace: podNamespace,
                    podCreation: podCreation,
                    node: node,
                    nodeIp: nodeIp,
                    nodeArch: nodeArch,
                    nodeOs: nodeOs,
                    nodeOsimage: nodeOsimage,
                    nodeCpu: nodeCpu,
                    nodeRam: nodeRam,
                    nodeCreation: nodeCreation
                };
                containerObject["pod"] = data.parent.name;
                containerObject["pod_path"] = data.parent.path;
                containerObject["node"] = data.parent.parent.name;
                containerObject["node_path"] = data.parent.parent.path;
                containerObject["cluster"] = data.parent.parent.parent.name;
                containerObject["cluster_path"] = data.parent.parent.parent.path;
                this.listedContainers.push(containerObject);
            }

            return data;
        }

        function filterOnlyDisplayedData(data, path) {
            let firstPart = path[0];
            if (firstPart == data.name || (firstPart == "Infrastructure" && data.name == "root")) {
                let nextPath = path.slice(1)
                if (nextPath.length > 0) {
                    let newChildren = data.children
                        .map((c) => filterOnlyDisplayedData(c, nextPath))
                        .filter((p) => p != null);
                    data.children = newChildren;
                }
                return data
            } else {
                return null;
            }
        }

        this.listedContainers = [];
        let selectedResult = filterOnlyDisplayedData(data, this.displayedPath);
        let completedResult = addAggregatedData(selectedResult, selectedMetric);
        // Send an event that forces the filters of the datatable to refresh.
        // The event is triggered with 1000ms of delay, to give some time to
        // the datatables to update correctly.
        setTimeout(() => {
            this.secondTableShouldChange.emit(this.listedContainers);
        }, 2000);
        return completedResult;
    }

    private createTreeMap(): void {

        d3.select('div#divSvg').select("svg").remove();
        this.svg = d3.select('div#divSvg')
            .append('svg')
            .attr('width', "100%")
            .attr('height', "100%")
            .attr('style', 'overflow:visible')
            .append('g')
            .attr('id', 'graph_svg');
        this.refreshTreeMap();
    }

    private eraseTreeMap(): void {

        d3.select('div#divSvg')
            .selectAll('*')
            .remove();
    }

    private refreshTreeMap(): void {

        let svgHeight = document.getElementById("divSvg").clientHeight - 40;
        let svgWidth = document.getElementById("divSvg").clientWidth;

        let treemap = data => d3.treemap()
            .size([svgWidth, svgHeight])
            .paddingOuter(3)
            .paddingTop(19)
            .paddingInner(1)
            .round(true)
            (d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value));

        const root = treemap(this.data);

        const svg = d3.select('g#graph_svg');

        function roundedSize(node) {
            if (node.data.type == "CONTAINER") {
                return 0;
            }
            return 5;
        }

        let colors;
        if (this.selectedMetric == "storage") {
            colors = [
                "#0C67AD",
                "#BADFEA",
                "hsl(198, 83%, 94%)"
            ]
        } else {
            colors = [
                "#094789",
                "#3C9FD0",
                "#0C67AD",
                "#BADFEA",
                "hsl(198, 83%, 94%)"
            ]
        }
        let format = d3.format(",d");

        const node = svg.selectAll("g")
            .data(d3.group(root, d => d.height))
            .join("g")
            .selectAll("g")
            .data(d => d[1])
            .join("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`);

        node.append("title")
            .text(d => `${d.data["name"]}`);

        let nextId = 0;
        let generateUid = (str) => {
            return `id-${str}-${nextId++}`
        }

        // @ts-ignore
        node.append("rect")
            .attr("id", d => (d["nodeUid"] = generateUid("node")))
            // @ts-ignore
            .attr("fill", d => colors[d.data.recursionLevel])
            .attr("rx", d => roundedSize(d))
            .attr("ry", d => roundedSize(d))
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0);

        node.append("clipPath")
            .attr("id", d => (d["clipUid"] = generateUid("clip")))
            .append("use")
            .attr("xlink:href", d => `#${d["nodeUid"]}`);

        // @ts-ignore
        node.append("text")
            .style("max-width", (d, i, nodes) => d.x1 - d.x0 - 10)
            .style("max-height", (d, i, nodes) => d.y1 - d.y0 - 10)
            .style("overflow", "visible")
            // @ts-ignore
            .attr("fill", (d, i, nodes) => d.data.textColor)
            .attr("clip-path", d => d["clipUid"])
            .selectAll("tspan")
            // @ts-ignore
            .data((d, i, nodes) => [d.data.labelled_name])
            .join("tspan")
            .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
            .attr("font-size", (d, i, nodes) => "8px")
            .text(d => d);

        // @ts-ignore
        node
            .on("click", (d, i) => {
                // @ts-ignore
                this.changeSelection(i.data.path);
            })
            .on("mouseover", function(d, i) {
                d3.select(this).select("rect").style('opacity', '0.66');
                d3.select(this).style("cursor", "pointer");
            })
            .on("mouseout", function(d, i) {
                d3.select(this).select("rect").style('opacity', '1.0');
                d3.select(this).style("cursor", "default");
            })

        // @ts-ignore
        node.filter(d => d.children).selectAll("tspan")
            .attr("dx", 3)
            .attr("y", 13);

        node.filter(d => !d.children).selectAll("tspan")
            .attr("x", 3)
            // @ts-ignore
            .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`);
    }

    private loadContainers(val: string): void {

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_CONTAINER)
            .subscribe(
                data => {
                    this.containersAll = data;
                    for (let obj of this.containersAll[val.replace(/ /g, "_")]) {
                        let pod: any = this.getPodFromId(obj.fatherId);
                        if (pod != undefined) {
                            obj.podIp = pod.ip;
                            obj.podCreation = pod.creation;
                            obj.podNamespace = pod.namespace;

                            let node: any = this.getNodeFromId(pod.fatherId);
                            if (node != undefined) {
                                obj.nodeIp = node.ip;
                                obj.nodeCreation = node.creation;
                                obj.nodeArch = node.arch;
                                obj.nodeOs = node.os;
                                obj.nodeOsimage = node.osimage;
                                obj.nodeCpu = node.cpu;
                                obj.nodeRam = node.ram;
                            }
                        }
                        this.containers.push(obj);
                    }
                }
            );
    }

    private getPodFromId(uuid: string): any {
        return this.pods.find((pod: {
            identifier: string;
        }) => pod.identifier === uuid);
    }

    private getNodeFromId(uuid: string): any {
        return this.nodes.find((node: {
            identifier: string;
        }) => node.identifier === uuid);
    }
}

