import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-cluster-distribution5]'
})
export class Clusterdistribution5Directive {

    constructor(public viewContainerRef5: ViewContainerRef) {}

}
