import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    ClrSelectedState,
    ClrWizard
} from "@clr/angular";


export interface WizarExternalIpsdActionEvent {

}

export class MergeExternalIpsWithTheseIps implements WizarExternalIpsdActionEvent {
    constructor(public externalIps) {}
}


@Component({
    selector: 'external-ips-wizard-import-csv',
    templateUrl: './external-ips-wizard-import-csv.component.html',
    styleUrls: ['./external-ips-wizard-import-csv.component.css']
})
export class ExternalIpsWizardImportCsvComponent implements OnInit {

    loading = false;

    @ViewChild("wizard")
    wizard: ClrWizard;

    @Output()
    newAppGroupFromWizard: EventEmitter < WizarExternalIpsdActionEvent > = new EventEmitter();

    _open: boolean = false;

    externalIpsFromCsv = [];

    ngOnInit(): void {}

    fileChanged = (e) => {
        let file = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            let importedAppsGroups = [];

            let rawLines: String = fileReader.result as string;
            let lines = rawLines.split("\n").map((_) => _.trim());

            let isHeader = true;
            for (let line of lines) {
                console.log("line: " + line)
                if (!isHeader) {

                    let delimiter;

                    if (line.indexOf(',') !== -1) {
                        delimiter = ',';
                    }

                    if (line.indexOf(';') !== -1) {
                        delimiter = ';';
                    }

                    if (delimiter === undefined) {
                        continue;
                    }


                    let allColumns = line.split(delimiter);

                    if (allColumns.length < 2) {
                        continue;
                    }

                    let externalIpAddress = allColumns[0];
                    let externalIpName = allColumns[1];

                    // Add the apps groups
                    importedAppsGroups.push({
                        "name": externalIpName,
                        "ipAddress": externalIpAddress
                    });
                }

                isHeader = false;
            }

            this.externalIpsFromCsv = importedAppsGroups;
            console.log("a => " + fileReader.result);
        }
        fileReader.readAsText(file);

        // Reset file in file input
        // @ts-ignore
        event.target.value = null;
    }


    open() {
        this.wizard.reset();
        this._open = true;
        this.externalIpsFromCsv = [];
    }

    finish() {
        this.newAppGroupFromWizard.emit(new MergeExternalIpsWithTheseIps(this.externalIpsFromCsv));
    }
}

