import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    FailureGetExternalIpsResponse,
    GetExternalIpsResponse,
    NetscopeService,
    PushExternalIpsResponse,
    SuccessGetExternalIpsResponse,
} from "@app/services";
import {
    Router
} from "@angular/router";
import {
    Subscription
} from "rxjs";
import {
    ExternalIpsWizardImportCsvComponent,
    MergeExternalIpsWithTheseIps,
    WizarExternalIpsdActionEvent
} from "@app/netscope/netscope-external-ips/external-ips-wizard-import-csv/external-ips-wizard-import-csv.component";
import {TranslocoService} from "@ngneat/transloco";

class ExternalIpElement {
    ipAddress: String;
    name: String;

    constructor(ipAddress: String, name: String) {
        this.ipAddress = ipAddress;
        this.name = name;
    }
}

@Component({
    selector: 'app-netscope-external-ips',
    templateUrl: './netscope-external-ips.component.html',
    styleUrls: ['./netscope-external-ips.component.css']
})
export class NetscopeExternalIpsComponent implements OnInit {

    externalIpsData: ExternalIpElement[] = [];
    isLoading: boolean = false;
    isPushingExternalIps: boolean = false;
    failureMode = false;
    newExternalIpAddress: string;
    newExternalIpName: string;

    newNameIsInvalid: Boolean;
    newIpAddressIsInvalid: Boolean;

    csvSperator: string = ",";

    @ViewChild("wizard")
    wizard: ExternalIpsWizardImportCsvComponent;
    registeredToWizardEvent: Boolean = false;

    constructor(private netscopeService: NetscopeService, private route: Router, public translocoService: TranslocoService) {}

    ngOnInit(): void {
        this.reloadData();
    }

    reloadData = () => {
        this.isLoading = true;
        this.failureMode = false;

        this.netscopeService.getExternalIps().subscribe((externalIpsDataResponse: GetExternalIpsResponse) => {

            if (externalIpsDataResponse instanceof FailureGetExternalIpsResponse) {
                this.failureMode = true;
                this.isLoading = false;
                return;
            }

            let result;
            if (externalIpsDataResponse instanceof SuccessGetExternalIpsResponse) {
                result = externalIpsDataResponse.externalIps;
            }

            this.externalIpsData = result["external_ips"];
            this.isLoading = false;
        });
    }

    checkNewName = (name: string) => {
        const nameRegex = /^[A-Za-z0-9_]+$/;
        let checkNameResult = nameRegex.test(name);

        return checkNameResult;
    }

    checkIpAddress = (ipAddress: string) => {

        if (ipAddress.indexOf("/") != -1) {
            let networkAddressParts = ipAddress.split("/");

            if (networkAddressParts.length != 2) {
                return false;
            }

            let networkIpAddress = networkAddressParts[0];
            let networkSizeBytes = Number(networkAddressParts[1]);

            return this.checkIpAddress(networkIpAddress) && networkSizeBytes != undefined;
        }

        const isValidUrl = urlString => {
            try {
                return Boolean(new URL(urlString));
            } catch (e) {
                return false;
            }
        }

        let checkIpAddressResult = true;

        let maybeIpV4: boolean = ipAddress.indexOf(".") !== -1;
        let maybeIpV6: boolean = ipAddress.indexOf(":") !== -1;

        let possibleUrlString = maybeIpV4 ? `http://${ipAddress}/test` : `http://[${ipAddress}]/test`;

        if (!isValidUrl(possibleUrlString)) {
            checkIpAddressResult = false;
        }

        if (checkIpAddressResult && maybeIpV4 && maybeIpV6) {
            checkIpAddressResult = false;
        }

        // IP V4
        if (checkIpAddressResult && maybeIpV4) {
            let goodEnoughIpV4Regex = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;
            checkIpAddressResult = goodEnoughIpV4Regex.test(ipAddress);
        }

        return checkIpAddressResult;
    }

    checkIfNameAndIpaddressAreCorrect = () => {
        let result = true;

        if (!this.checkNewName(this.newExternalIpName)) {
            this.newNameIsInvalid = true;
            result = false;
        } else {
            this.newNameIsInvalid = false;
        }

        if (!this.checkIpAddress(this.newExternalIpAddress)) {
            this.newIpAddressIsInvalid = true;
            result = false;
        } else {
            this.newIpAddressIsInvalid = false;
        }

        return result;
    }

    addExternalIp = () => {
        let everythingIsCorrect = this.checkIfNameAndIpaddressAreCorrect();

        if (everythingIsCorrect) {
            let newExternalIpData = new ExternalIpElement(this.newExternalIpAddress, this.newExternalIpName);
            this.externalIpsData.push(newExternalIpData);
        }
    }

    deleteExternalIp = (ExternalIpData: ExternalIpElement) => {
        this.externalIpsData = this.externalIpsData
            .filter((ipElement) => ipElement.name !== ExternalIpData.name || ipElement.ipAddress !== ExternalIpData
                .ipAddress);
    }

    openWizardImportCsv = () => {
        if (!this.registeredToWizardEvent) {
            this.wizard.newAppGroupFromWizard.subscribe((newElementEvent: WizarExternalIpsdActionEvent) => {
                if (newElementEvent instanceof MergeExternalIpsWithTheseIps) {
                    this.mergeExternalIps(newElementEvent.externalIps);
                }
            });
            this.registeredToWizardEvent = true;
        }
        this.wizard.open();
    }

    mergeExternalIps = (newExternalIps) => {
        // We make the assumption that a resource can have several IP addresses, but a given IP address must belong
        // to a single resource. Thus, we group on the IP addresses.
        let existingExternalIpsAddresses = this.externalIpsData.map((_) => _.ipAddress);

        let newIpAddressesThatDontExist = newExternalIps.filter((_) => existingExternalIpsAddresses.indexOf(_
            .ipAddress) === -1);
        let newIpAddressesThatExist = newExternalIps.filter((_) => existingExternalIpsAddresses.indexOf(_.ipAddress) !==
            -1);

        // First, edit appGroups that don't exist
        for (let externalIpAddress of newIpAddressesThatExist) {
            let matchingExternalIpAddresses = this.externalIpsData.filter((_) => _.ipAddress === externalIpAddress
                .ipAddress);
            for (let matchingExternalIpAddress of matchingExternalIpAddresses) {
                matchingExternalIpAddress.name = externalIpAddress.name;
            }
        }

        // Second, add appGroups that don't exist
        for (let externalIpAddress of newIpAddressesThatDontExist) {
            this.externalIpsData.push({
                name: externalIpAddress.name,
                ipAddress: externalIpAddress.ipAddress
            })
        }
    }

    exportExternalIpsAsCsv = () => {
        let csvHeader = ["IpAddress", "ResourceName"].join(this.csvSperator);
        let csvContent = csvHeader + "\n";

        for (let externalIp of this.externalIpsData) {
            let lineValue = [externalIp.ipAddress, externalIp.name].join(this.csvSperator) + "\n";
            csvContent += lineValue;
        }

        let exportedFilename = 'netscope-external-ips.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    pushData = () => {
        this.isPushingExternalIps = true;
        let externalIpsDataPayload = {
            external_ips: this.externalIpsData
        }
        this.netscopeService.pushExternalIps(externalIpsDataPayload).subscribe((result: PushExternalIpsResponse) => {
            this.isPushingExternalIps = false;
        })
    }

    setLanguage = (language: "french" | "english") => {
        let languageMap: Map<string, string> = new Map([
            ["french", "fr"],
            ["english", "en"]
        ]);
        let language_code = languageMap.get(language);
        this.translocoService.setDefaultLang(language_code);
        this.translocoService.setActiveLang(language_code);
        localStorage.setItem("language", language_code);
    }
}

