import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    ManagementService,
    ShareService
} from '@app/services';

import {
    JSONTarget,
    Message,
    RecoServer,
    RecoServerStop,
    User
} from '@app/model';


@Component({
    selector: 'app-recommendationconsolidationserverlist',
    templateUrl: './recommendationconsolidationserverlist.component.html',
    styleUrls: ['./recommendationconsolidationserverlist.component.css']
})
export class RecommendationconsolidationserverlistComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: DataTables.Settings = {};

    dtTrigger: Subject < any > = new Subject();

    message: Message;

    reco_meta_data: any = [];

    reco_servers_data: RecoServer[] = [];

    filter_srv: RecoServer[] = [];

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private management_svc: ManagementService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.initDtOptions();

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_SERVER).subscribe(
            data => {
                this.reco_servers_data = data;
                if (this.reco_servers_data.length > 0)
                    this.filterServerList();
            }
        );
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    updateServerToStop(reco_srv: RecoServer): void {

        if (reco_srv.isStop) {
            this.message.recoServerStop.push(reco_srv);
            let srv_tmp: RecoServerStop = {
                user: this.currentUser.login,
                filter: this.message.currentFilter,
                uuid: reco_srv.uuid,
                servers: this.message.recoServerStop.length
            };
            this.management_svc.addServerStop(srv_tmp).pipe(first()).subscribe(
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        } else {
            this.message.recoServerStop = this.message.recoServerStop.filter(obj => obj.uuid !== reco_srv.uuid);
            console.log(this.message.recoServerStop);
            let srv_tmp: RecoServerStop = {
                user: this.currentUser.login,
                filter: this.message.currentFilter,
                uuid: reco_srv.uuid,
                servers: this.message.recoServerStop.length
            };
            this.management_svc.delServerStop(srv_tmp).pipe(first()).subscribe(
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    private filterServerList(): void {

        for (var i = 0; i < this.reco_servers_data.length; i++) {
            if (this.reco_servers_data[i].uuid != "xdm" && this.reco_servers_data[i].uuid != "xwrdm") {
                let reco_srv: RecoServer = this.filterRecoServerStop(this.reco_servers_data[i].uuid);
                if (reco_srv != undefined) {
                    if (reco_srv.isStop)
                        this.reco_servers_data[i].isStop = true;
                }
                this.filter_srv.push(this.reco_servers_data[i]);
            }
        }
        if (this.filter_srv.length > 0)
            this.reloadTable();
    }

    private filterRecoServerStop(uuid: string): RecoServer {
        return this.message.recoServerStop.find(reco => reco.uuid === uuid);
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
            },
            language: {
                loadingRecords: "loading .. ",
                zeroRecords: "loading ...",
                search: '',
                searchPlaceholder: " search a server..."
            },
            dom: '<"top">rt<"bottom"ifp>'
        };
    }
}
