<div class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<fa-icon [icon]="['fas', 'chart-pie']"></fa-icon>
					&nbsp;Operating system distribution
				</h5>
			</div>
			<div class="card-block">
				<highcharts-chart id="vm-info-os"
					[Highcharts]="Highcharts"
					[options]="chartOptions"
					[constructorType]="'chart'"
					style="width: 100%; display: block;" *ngIf="isReady">
				</highcharts-chart>
				<span class="label label-info">select an operating system to see its detail</span>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<fa-icon [icon]="[icon_os_ref, icon_os_detail]"></fa-icon>
					&nbsp;Operating system detail
				</h5>
			</div>
			<div class="card-block">
				<ng-template vm-info-detail></ng-template>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="table"></cds-icon>
					&nbsp;Virtual machine list
				</h5>
				<span class="export"></span>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<table id="vm-info-list" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  					<thead>
    						<tr>
      							<th>NAME</th>
      							<th>IDENTIFIER</th>
      							<th>OS</th>
      							<th>VCPU#</th>
							<th>RAM CAP (MB)</th>
      							<th>CPU Res (MHz)</th>
      							<th>RAM Res (MB)</th>
							<th>VMware T.</th>
						</tr>
  					</thead>
  					<tbody>
    						<tr *ngFor="let vm of data_vms.data" ngClass="addLink" (click)="callroute(vm)">
							<td>{{vm[0]}}</td>
							<td>{{vm[1]}}</td>
							<td>{{vm[2]}}</td>
							<td>{{vm[3]}}</td>
							<td>{{vm[4]}}</td>
							<td>{{vm[5]}}</td>
							<td>{{vm[6]}}</td>
							<td>{{vm[7]}}</td>
						</tr>
  					</tbody>
  				</table>
			</div>
		</div>
	</div>
</div>
