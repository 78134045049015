import {
    Directive
} from '@angular/core';

@Directive({
    selector: '[appGraphregion]'
})
export class GraphregionDirective {

    constructor() {}

}
