import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-cluster-distribution9]'
})
export class Clusterdistribution9Directive {

    constructor(public viewContainerRef9: ViewContainerRef) {}

}
