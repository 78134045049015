import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
    Input
} from '@angular/core';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);
import dumbbell from 'highcharts/modules/dumbbell';
dumbbell(Highcharts);

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';


@Component({
    selector: 'app-dashboard-container',
    templateUrl: './dashboard-container.component.html',
    styleUrls: ['./dashboard-container.component.css']
})
export class DashboardContainerComponent implements OnChanges, OnInit {

    @Input() isreload: boolean = false;

    dataOrder = ClrDatagridSortOrder.ASC;

    message: Message;

    metadata: any = [];

    isDcscope = true;
    isCo2scope = false;

    highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options = {};
    chartOptions3: Highcharts.Options = {};
    chartOptions4: Highcharts.Options = {};

    months: any = [];

    evolution: any = [];

    behavior: any = [];

    ageStructure: any = [];

    model: any = {
        more30Days: 0,
        lastDay: 0,
        insLastDay: 0
    };

    med: any = null;

    isReady: boolean = false;
    isReady3: boolean = false;
    isReady4: boolean = false;
    isContainerDetail: boolean = false;
    isGreenitDetail: boolean = false;
    isOpportunitiesDetail: boolean = false;

    node_modal: boolean = false;

    pod_modal: boolean = false;

    container_modal: boolean = false;

    containersAll: any = [];

    podsAll: any = [];

    nodesAll: any = [];

    containers: any = [];

    pods: any = [];

    nodes: any = [];

    isDataReload: boolean = false;

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        setTimeout(() => this.initContainerData(this.message.kubeCluster), 100);

        this.buildShape();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['isreload'].currentValue)
            this.initContainerData(this.message.kubeCluster);

    }

    loadContainersDetail(): void {

        this.isGreenitDetail = false;
        this.isOpportunitiesDetail = false;
        this.isContainerDetail = true;
        this.message.kubeDetail = true
        this.isDataReload = true;
        setTimeout(() => this.isDataReload = false, 100);
    }

    loadCo2Detail(): void {

        this.isContainerDetail = false;
        this.isOpportunitiesDetail = false;
        this.isGreenitDetail = true;
        this.message.kubeDetail = true;
    }

    loadOpportunitiesDetail(): void {

        this.isContainerDetail = false;
        this.isGreenitDetail = false;
        this.isOpportunitiesDetail = true;
        this.message.kubeDetail = true;
    }

    toFixedNumber(val: any, nb: number): number {

        let value: number = 0;
        if (val != null)
            value = val.toFixed(nb);

        return value;
    }

    convertTimeToDate(time: number): string {
        return new Date(time * 1000).toLocaleDateString('en-EN');
    }

    private initContainerData(cluster: any): void {

        this.nodesAll = [];
        this.nodes = [];
        this.podsAll = [];
        this.pods = [];
        this.containersAll = [];
        this.containers = [];

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_MONTH)
            .subscribe(
                data => {
                    this.months = data;
                    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                        .KUBERNETES_POD).subscribe(
                        data => {
                            this.podsAll = data;
                            let val: string = '';
                            val = this.months[0].name;
                            for (let obj of this.podsAll[val.replace(/ /g, "_")]) {
                                this.pods.push(obj);
                            }

                            this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                                .KUBERNETES_NODE).subscribe(
                                data => {
                                    this.nodesAll = data;
                                    for (let obj of this.nodesAll[val.replace(/ /g, "_")]) {
                                        this.nodes.push(obj);
                                    }
                                    this.loadContainers(val);
                                }
                            );
                        }
                    );
                }
            );

        setTimeout(() => this.loadContainerData(this.message.kubeCluster), 500);
    }

    private loadContainerData(cluster: any): void {

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_METADATA)
            .subscribe(
                data => {
                    this.metadata = data;
                }
            );

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_EVOLUTION)
            .subscribe(
                data => {
                    if (data.length > 0) {
                        this.evolution = data;
                        this.loadEvolutionGraph();
                    }
                }
            );

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_STRUCTURE)
            .subscribe(
                data => {
                    if (data.length > 0) {
                        this.ageStructure = data;
                        this.loadAgeGraph();
                    }
                }
            );

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_MONTH)
            .subscribe(
                data => {
                    this.months = data;
                    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                        .KUBERNETES_BEHAVIOR).subscribe(
                        data => {
                            this.behavior = data;
                            let val: string = '';
                            val = this.months[0].name;
                            let nbIdle: number = 0;
                            let nbNormal: number = 0;
                            let nbBusy: number = 0;

                            for (let obj of this.behavior[val.replace(/ /g, "_")]) {
                                if (obj.behavior == "idle")
                                    ++nbIdle
                                else if (obj.behavior == "normal")
                                    ++nbNormal
                                else if (obj.behavior == "busy")
                                    ++nbBusy
                            }
                            this.med = new Object();
                            this.med.idle = nbIdle;
                            this.med.normal = nbNormal;
                            this.med.busy = nbBusy;

                            this.loadGraph();
                        }
                    );
                }
            );
    }

    exportNode(): void {

        let res = ["Name", "Cluster", "Tanzu kubernetes cluster", "Tanzu kubernetes namespace", "Creation", "IP",
            "Arch", "OSIMAGE", "CPU", "RAM (GB)"
        ].join(',') + '\n';
        res += Object.values < any > (this.nodes).map(node => [node.name, node.cluster, node.tkgCluster, node.tkgNs,
            node.creation, node.ip, node.arch, node.osimage, node.cpu, node.ram
        ].join(",")).join('\n');

        this.exportElement(res, 'nodes');
    }

    exportPod(): void {

        let res = ["Name", "Node", "Cluster", "Creation", "IP", "Namespace", "State"].join(',') + '\n';
        res += Object.values < any > (this.pods).map(pod => [pod.name, pod.node, pod.cluster, pod.creation, pod.ip,
            pod.namespace, pod.state
        ].join(",")).join('\n');

        this.exportElement(res, 'pods');
    }

    exportContainer(): void {
        let res = ["Name", "Pod", "Node", "Cluster", "Namespace", "Creation", "Image", "State"].join(',') + '\n';
        res += Object.values < any > (this.containers).map(container => [container.name, container.pod, container
            .node, container.cluster, container.namespace, container.creation, container.image, container
            .state
        ].join(",")).join('\n');

        this.exportElement(res, 'containers');
    }

    private exportElement(res: any, file: string): void {

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = file + '.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    private loadGraph(): void {

        let title: string = '';
        let titleSize: string = '20px';

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            chart: {
                plotBorderWidth: null,
                plotShadow: false
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: [{
                type: 'pie',
                name: 'Behavior',
                size: '80%',
                data: [{
                    name: 'busy',
                    y: this.med.busy,
                    color: '#CCCCCC'
                }, {
                    name: 'idle',
                    y: this.med.idle,
                    color: '#5eb715',
                    sliced: true,
                    selected: true
                }, {
                    name: 'normal',
                    y: this.med.normal,
                    color: '#613200'
                }]
            }]
        };
        this.isReady = true;
    }

    private loadEvolutionGraph(): void {

        let title: string = '';
        let titleSize: string = '20px';
        let offset_y: number = 1.5;
        let offset_x: number = 1.5;

        let datas: any = [];
        let vm_datas: any = [];
        for (let i in this.evolution) {
            let x_all: number = this.evolution[i].all;
            let x_create: number = this.evolution[i].all + this.evolution[i].nbCreate;
            let x_del: number = x_all - this.evolution[i].ngDestroy;

            let j: number = +i;
            j = j + offset_y;

            let offset_2: number = 2 * x_all / 100;
            let offset_50: number = 50 * x_all / 100;
            if (x_all < 25) {
                offset_2 = offset_x;
                offset_50 = 50;
            }

            let del_color: string = "#5eb715";
            let del_offset: number = x_del;

            let add_color: string = "#0072a3";
            let add_offset: number = x_create + offset_2;

            if (x_create == x_all) {
                add_color = "#ffffff";
                add_offset = x_all + offset_50
            }
            if (x_del == x_all) {
                del_offset = x_all - offset_50;
                del_color = "#ffffff";
            }

            let data_del: any = {
                x: del_offset,
                x2: x_all,
                y: j + 1, // day
                color: del_color,
                text: ' ' + Highcharts.dateFormat('%Y/%m/%d', this.evolution[i].timeago) +
                    '<br>' + Math.abs(this.evolution[i].ngDestroy) + ' deleted',
            };
            datas.push(data_del);

            let data_all: any = {
                x: x_all,
                x2: x_all + offset_2,
                y: j + 1, // day
                color: '#c21d00',
                text: ' ' + Highcharts.dateFormat('%Y/%m/%d', this.evolution[i].timeago) +
                    '<br>' + ' total: ' + this.evolution[i].all
            };
            datas.push(data_all);

            let data_add: any = {
                x: x_all + offset_2,
                x2: add_offset,
                y: j + 1, // day
                color: add_color,
                text: ' ' + Highcharts.dateFormat('%Y/%m/%d', this.evolution[i].timeago) +
                    '<br>' + Math.abs(this.evolution[i].nbCreate) + ' added',
            };
            datas.push(data_add);
            vm_datas.push(this.evolution[i].all);
        }

        let serie0: any = {
            type: 'xrange',
            name: 'evolution',
            pointWidth: 5,
            data: datas
        };
        let series: any = [];
        series.push(serie0);

        this.chartOptions3 = {
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            chart: {
                inverted: false,
                plotBorderWidth: null,
                plotShadow: false,
            },
            xAxis: {
                visible: true,
                title: {
                    text: "containers",
                    align: 'high'
                },
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                visible: true,
                title: {
                    text: 'past days',
                    align: 'high'
                },
                labels: {
                    enabled: false
                }
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span><b>{point.text}</b><br/>'
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                },
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        symbolStroke: "#0072A3"
                    }
                }
            },
            series: series
        };

        this.isReady3 = true;
    }

    private loadAgeGraph(): void {

        let title: string = '';
        let datas: any = [];
        let cat: any = [];

        this.model.lastDay = this.ageStructure[0].nbDay;
        this.model.insLastDay = this.ageStructure[0].nbContainer;

        this.model.more30Days = 0;
        for (let i in this.ageStructure) {
            cat.push(this.ageStructure[i].nbDay);
            datas.push(this.ageStructure[i].nbContainer);
            if (this.ageStructure[i].nbDay >= 30)
                this.model.more30Days += this.ageStructure[i].nbContainer;
        }

        let serie0: any = {
            type: 'bar',
            name: 'number of containers',
            pointWidth: 5,
            data: datas
        };
        let series: any = [];
        series.push(serie0);

        this.chartOptions4 = {
            credits: {
                enabled: false
            },
            title: {
                text: title
            },
            xAxis: {
                categories: cat,
                title: {
                    text: 'past days',
                    align: 'high',
                    rotation: -90
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'containers',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' '
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            legend: {
                enabled: false
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        symbolStroke: "#0072A3"
                    }
                }
            },
            series: series
        };

        this.isReady4 = true;
    }

    private buildShape() {

        Highcharts.SVGRenderer.prototype.symbols.download = function(x, y, w, h) {
            var path = [
                // Arrow stem
                'M', x + w * 0.5, y,
                'L', x + w * 0.5, y + h * 0.7,
                // Arrow head
                'M', x + w * 0.3, y + h * 0.5,
                'L', x + w * 0.5, y + h * 0.7,
                'L', x + w * 0.7, y + h * 0.5,
                // Box
                'M', x, y + h * 0.9,
                'L', x, y + h,
                'L', x + w, y + h,
                'L', x + w, y + h * 0.9
            ];
            return path;
        };
    }

    private loadContainers(val: string): void {

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KUBERNETES_CONTAINER)
            .subscribe(
                data => {
                    this.containersAll = data;
                    for (let obj of this.containersAll[val.replace(/ /g, "_")]) {
                        let pod: any = this.getPodFromId(obj.fatherId);
                        if (pod != undefined) {
                            obj.podIp = pod.ip;
                            obj.podCreation = pod.creation;
                            obj.podNamespace = pod.namespace;

                            let node: any = this.getNodeFromId(pod.fatherId);
                            if (node != undefined) {
                                obj.nodeIp = node.ip;
                                obj.nodeCreation = node.creation;
                                obj.nodeArch = node.arch;
                                obj.nodeOs = node.os;
                                obj.nodeOsimage = node.osimage;
                                obj.nodeCpu = node.cpu;
                                obj.nodeRam = node.ram;
                            }
                        }
                        this.containers.push(obj);
                    }
                }
            );
    }

    private getPodFromId(uuid: string): any {
        return this.pods.find((pod: {
            identifier: string;
        }) => pod.identifier === uuid);
    }

    private getNodeFromId(uuid: string): any {
        return this.nodes.find((node: {
            identifier: string;
        }) => node.identifier === uuid);
    }
}
