import {
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    Subject
} from 'rxjs';

import {
    AccountService,
    JsonloaderService,
    MeasurementService,
    ShareService
} from '@app/services';

import {
    Counter,
    JSONTarget,
    Message,
    User
} from '@app/model';

import * as moment from 'moment';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offline(Highcharts);


@Component({
    selector: 'app-graph-counter-stack',
    templateUrl: './graph-counter-stack.component.html',
    styleUrls: ['./graph-counter-stack.component.css']
})
export class GraphCounterStackComponent implements OnInit {

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};

    moment: any = moment;

    months: any = [];

    years: any = [];

    start: number = 0;

    end: number = 0;

    message: Message;

    granularity: string = 'DAILY';

    creditsMetrics: any = ['surplusCharged'];

    isReady: boolean = false;

    msg_state: string = '';

    target_uuids: any = [];

    model: any = {
        threshold: 0,
        selectedMonth: '',
        selectedYear: '',
        profile: 'monthly'
    };

    currentUser: User;

    private requests_counter: Counter[] = [];


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private measurement_svc: MeasurementService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.buildShape();

        this.start = moment().utc().startOf('month').unix() * 1000;
        this.end = moment().utc().startOf('day').unix() * 1000;

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_MONTH).subscribe(
            data => {
                this.months = data;
                this.setYears();
            }
        );

        this.loadData();
    }

    loadProfile(): void {

        if (this.model.profile == "yearly") {
            if (this.years.length > 0) {
                this.model.selectedYear = this.years[0];
                this.loadYear(this.years[0]);
            }
        } else {
            if (this.months.length > 0) {
                this.model.selectedMonth = this.months[0].name;
                this.loadMonth(this.months[0].name);
            }
        }
    }

    loadMonth(event: any): void {

        this.granularity = 'DAILY';
        this.target_uuids = [];
        this.requests_counter = [];
        this.isReady = false;
        if (event != null) {
            let year: string = event.split(" ")[0];
            let month_str: string = event.split(" ")[1];
            let month: number = this.convertMonth(month_str);
            let date: any = moment.utc();
            date.set({
                'year': year,
                'month': month
            });
            this.start = date.startOf('month').unix() * 1000;
            this.end = date.endOf('month').unix() * 1000;
            this.loadData();
        }
    }

    loadYear(event: any): void {

        this.granularity = 'DAILY';
        this.target_uuids = [];
        this.requests_counter = [];
        this.model.threshold = 0;
        this.isReady = false;
        if (event != null) {
            const d = new Date(this.model.selectedYear + "-01-15T00:00:00");
            this.start = moment(d).utc().startOf('year').unix() * 1000;
            this.end = moment(d).utc().endOf('year').unix() * 1000;
            this.loadData();
        }
    }

    updateThreshold(): void {

        this.target_uuids = [];
        this.requests_counter = [];
        this.isReady = false;
        if (this.model.threshold != "")
            this.loadData();

    }

    reset(): void {

        if (this.model.profile == "yearly") {
            if (this.model.selectedYear != "") {
                const d = new Date(this.model.selectedYear + "-01-15T00:00:00");
                this.start = moment(d).utc().startOf('year').unix() * 1000;
                this.end = moment(d).utc().endOf('year').unix() * 1000;
            }
        } else {
            if (this.model.selectedMonth != "") {
                let year: string = this.model.selectedMonth.split(" ")[0];
                let month_str: string = this.model.selectedMonth.split(" ")[1];
                let month: number = this.convertMonth(month_str);
                let date: any = moment.utc();
                date.set({
                    'year': year,
                    'month': month
                });
                this.start = date.startOf('month').unix() * 1000;
                this.end = date.endOf('month').unix() * 1000;
            }
        }
        this.granularity = 'DAILY';
        this.target_uuids = [];
        this.requests_counter = [];
        this.isReady = false;
        this.loadData();
    }

    private reloadGraph() {

        this.model.threshold = 0;
        this.target_uuids = [];
        this.requests_counter = [];
        this.isReady = false;
        this.loadData();
    }

    private loadData(): void {

        this.measurement_svc.getSurplusCredits(this.start, this.end, this.model.threshold, this.granularity)
            .subscribe(
                data => {
                    this.msg_state = "loading ...";
                    let uuids: any = [];
                    for (let i = 0; i < data.length; i++) {
                        this.target_uuids.push(data[i].uuid);
                        let uuid: any = [];
                        uuid.push(data[i].uuid);
                        uuids.push(uuid);
                    }
                    this.measurement_svc.getInstancesCounters(
                        uuids,
                        this.start,
                        this.end,
                        this.granularity).pipe(first()).subscribe(
                        data => {
                            this.requests_counter = this.requests_counter.concat(data);
                            this.buildData();
                        },
                        error => {
                            if (error != null)
                                console.log(error)
                        }
                    );
                },
                error => {
                    this.isReady = false;
                    this.msg_state = "no data ...";
                    this.granularity = 'DAILY';
                    this.target_uuids = [];
                    this.requests_counter = [];
                    this.start = moment().utc().startOf('month').unix() * 1000;
                    this.end = moment().utc().startOf('day').unix() * 1000;
                }
            );
    }

    private buildData(): void {

        let credit_series: any = [];

        for (let j = 0; j < this.target_uuids.length; j++) {
            let cpu_cc_data: any = [];
            for (let i = 0; i < this.requests_counter.length; i++) {
                if (this.target_uuids[j] == this.requests_counter[i].uuid) {
                    let cpu_credits_charged = [];
                    cpu_credits_charged[0] = this.requests_counter[i].time;
                    let result: any = this.toDict(this.requests_counter[i].dataPoints);
                    cpu_credits_charged[1] = result.CPU_SURPLUSCREDITSCHARGED;

                    cpu_cc_data.push(cpu_credits_charged);
                }
            }

            if (this.message.cloudProvider == "aws") {
                if (this.creditsMetrics.find(x => x === "surplusCharged")) {
                    let creditSurplus: any = {
                        type: 'area',
                        name: 'i-' + this.target_uuids[j].split('-')[6],
                        data: cpu_cc_data
                    };
                    credit_series.push(creditSurplus);
                }
            }
        }

        if (credit_series.length > 0)
            this.loadComputeGraphs(credit_series);
    }

    private loadComputeGraphs(data: any): void {

        this.chartOptions = {
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'x',
                type: 'area'
            },
            title: {
                text: 'Surplus credits charged analysis'
            },
            xAxis: {
                type: 'datetime',
                tickmarkPlacement: 'on',
                events: {
                    setExtremes: function(event) {
                        this.start = Math.round(event.min);
                        this.end = Math.round(event.max);
                        this.granularity = 'HOURLY';
                        this.reloadGraph();
                    }.bind(this)
                }
            },
            yAxis: {
                title: {
                    text: 'credits'
                },
            },
            tooltip: {
                split: true,
                valueSuffix: ''
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        text: "download",
                        symbolStroke: "#0072A3",
                        x: -10
                    }
                }
            },
            series: data
        };
        this.isReady = true;
    }

    private toDict(dataPoints: any): any {

        var result = {};
        dataPoints.forEach(dp => {
            result[dp.metricName] = dp.value
        });

        return result;
    }

    private setYears(): void {

        for (let i = 0; i < this.months.length; i++) {
            let year: string = this.months[i].name.split(" ")[0];
            if (!this.years.includes(year))
                this.years.push(year);
        }
        if (this.years.length > 0)
            this.model.selectedYear = this.years[0];
    }

    private convertMonth(month_str: string): number {

        let month: number = 0;

        switch (month_str) {
            case "Jan":
                month = 0;
                break;
            case "Feb":
                month = 1;
                break;
            case "Mar":
                month = 2;
                break;
            case "Apr":
                month = 3;
                break;
            case "May":
                month = 4;
                break;
            case "Jun":
                month = 5;
                break;
            case "Jul":
                month = 6;
                break;
            case "Aug":
                month = 7;
                break;
            case "Sep":
                month = 8;
                break;
            case "Oct":
                month = 9;
                break;
            case "Nov":
                month = 10;
                break;
            case "Dec":
                month = 11;
                break;
            default:
                break;
        }

        return month;
    }

    private buildShape() {

        Highcharts.SVGRenderer.prototype.symbols.download = function(x, y, w, h) {
            var path = [
                // Arrow stem
                'M', x + w * 0.5, y,
                'L', x + w * 0.5, y + h * 0.7,
                // Arrow head
                'M', x + w * 0.3, y + h * 0.5,
                'L', x + w * 0.5, y + h * 0.7,
                'L', x + w * 0.7, y + h * 0.5,
                // Box
                'M', x, y + h * 0.9,
                'L', x, y + h,
                'L', x + w, y + h,
                'L', x + w, y + h * 0.9
            ];
            return path;
        };
    }
}
