import {
    Component,
    OnInit
} from '@angular/core';

@Component({
    selector: 'app-t12',
    templateUrl: './t12.component.html',
    styleUrls: ['./t12.component.css']
})
export class T12Component implements OnInit {

    isReady: boolean = false;


    constructor() {}

    ngOnInit(): void {

        this.isReady = true;
    }

}
