<!-- GREENIT SETTINGS -->
<clr-modal [(clrModalOpen)]="settings_modal" [clrModalClosable]="true" [clrModalSize]="'xl'">
    <h3 class="modal-title">Green IT</h3>
    <div class="modal-body" style="overflow:auto">
        <clr-tabs clrLayout="vertical">
            <clr-tab>
                <button clrTabLink>Servers</button>
                <clr-tab-content *clrIfActive="true">
                    <div class="widget-content" id="div-settings-dc" style="width:70rem">
                        <clr-tabs>
                            <clr-tab>
                                <button clrTabLink>Definitions</button>
                                <clr-tab-content *clrIfActive="true">
                                    <div>
                                        <p><b></b></p>
                                        <table cellspacing="0px" width="100%">
                                            <tr>
                                                <td style="width:33%;">
                                                    <div class="clr-form-control" style="flex-direction:row;">
                                                        <label for="average.host.year" class="clr-control-label"> Average server year&nbsp;&nbsp;</label>
                                                        <div [ngClass]="(average_host_year < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input disabled class="clr-input" id="average.host.year" type="number" [(ngModel)]="average_host_year" style="width:4em;text-align:right">
                                                                <cds-icon class="clr-validate-icon" *ngIf="average_host_year < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                    <b>Description</b><br>The average year of the server in your infrastructure.<br>Increasing the age of your server reduce the annual consumption of <b>embodied energy</b>.
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px"><b>Where to find</b><br>This value is an estimation of the age of the server in your data
                                                    center.
                                                </td>
                                            </tr>
                                        </table>
                                        <hr>
                                    </div>
                                    <div>
                                        <p><b></b></p>
                                        <table cellspacing="0px" width="100%">
                                            <tr>
                                                <td style="width:33%;vertical-align:top;">
                                                    <div class="clr-form-control" style="flex-direction:row;">
                                                        <label for="average.pue" class="clr-control-label">Average PUE&nbsp;&nbsp;</label>
                                                        <div [ngClass]="(average_pue <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input disabled class="clr-input" id="average.pue" type="number" [(ngModel)]="average_pue" style="width:4em;text-align:right">
                                                                <cds-icon class="clr-validate-icon" *ngIf="average_pue  <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                    <b>Description</b><br>PUE or Power Usage Effectiveness is a ratio to measure the energy efficiency
                                                    of the data center.<br>It is determined by dividing the total amount of power entering the data
                                                    center facilities by the power consumed to run the IT equipment (servers, storage, switch, etc). A
                                                    ratio closer to 1 represents a more efficient data center.
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px"><b>Where to
                                                        find</b><br>This indicator can be provided by your host provider or using the PUE formula in
                                                    case you own your own data center.<br><br>PUE = Total facility power / IT equipment
                                                    power.<br><br>Tip: The optimization of the servers room layout, the densification of servers and the
                                                    increase in the thresholds of temperature can help you to improve this ratio.</td>
                                            </tr>
                                        </table>
                                        <hr>
                                    </div>
                                    <div>
                                        <p><b></b></p>
                                        <table cellspacing="0px" width="100%;">
                                            <tr>
                                                <td style="width:33%;vertical-align:top;">
                                                    <div class="clr-form-control" style="flex-direction:column;">
                                                        <label for="average.co2" class="clr-control-label">Average Emission factor (kg CO2eq/kWh)&nbsp;&nbsp;</label>
                                                        <div [ngClass]="(average_co2 <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input disabled class="clr-input" id="average.co2" type="number" [(ngModel)]="average_co2" style="width:4em;text-align:right">
                                                                <cds-icon class="clr-validate-icon" *ngIf="average_co2  <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                    <b>Description</b><br>The kg CO2eq / Kwh coefficient allows you to convert the Kwh you consumed to
                                                    CO2 emissions.
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px"><b>Where to
                                                        find</b><br>This indicator can be provided by your electricity supplier.<br><br>Tip: To improve
                                                    the CO2eq/Kwh coefficient you need to seek electricity suppliers with green electricity contracts,
                                                    that produce energy from renewable sources.</td>
                                            </tr>
                                        </table>
                                        <hr>
                                    </div>
                                    <div>
                                        <p><b></b></p>
                                        <table cellspacing="0px" width="100%">
                                            <tr>
                                                <td style="width:33%;vertical-align:top;">
                                                    <div class="clr-form-control" style="flex-direction:row;">
                                                        <label for="average.cost" class="clr-control-label">Average kWh ({{globalCurrency}})&nbsp;&nbsp;</label>
                                                        <div [ngClass]="(average_cost <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input disabled class="clr-input" id="average.cost" type="number" [(ngModel)]="average_cost" style="width:4em;text-align:right">
                                                                <cds-icon class="clr-validate-icon" *ngIf="average_cost  <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                    <b>Description</b><br>The cost per kWh of the electricity.
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px"><b>Where to
                                                        find</b><br>This indicator can be provided by your electricity supplier and is generally
                                                    available in the electricity bill.</td>
                                            </tr>
                                        </table>
                                        <hr>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink>Indicators</button>
                                <clr-tab-content *clrIfActive="false">
                                    <div class="clr-row">
                                        <div class="clr-col-7 info-custom">
                                            <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Here are listed physical servers&nbsp;<b>supervised by {{productname}}</b>.<br>
                                            {{productname}} can estimate annual energy consumption (kWh), anuual cost and emissions in CO2eq.<br><br>
                                            <b>For each server, indicate the various parameters to refine the estimates.</b><br>
                                            </p>
                                        </div>
                                        <div class="clr-col-1" style="max-width:4%"></div>
                                        <div class="clr-col-4" style="min-width:37%;">
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <button *ngIf="currentUser.login == 'root'" style="margin-top:40px;" [disabled]="show_indicators_details" type="button" class="btn btn-secondary btn-sm" aria-label="settings" (click)="showIndicatorsDetails(true)">
                                        SHOW DETAILS
                                    </button>
                                    <button *ngIf="currentUser.login == 'root'" style="margin-top:40px;" [disabled]="!show_indicators_details" type="button" class="btn btn-secondary btn-sm" aria-label="settings" (click)="showIndicatorsDetails(false)">
                                        QUICK TOOL SELECTION
                                    </button>
                                    <hr>
                                    <div [hidden]="!show_indicators_details">
                                        <clr-datagrid>
                                            <clr-dg-action-bar style="margin-top:0px">
                                                <div class="btn-group" style="float:right">
                                                    <button type="button" class="btn btn-sm btn-link" (click)="exportServersIndicators()">
                                                        Export to CSV
                                                    </button>
                                                </div>
                                            </clr-dg-action-bar>
                                            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'year'"><ng-container *clrDgHideableColumn="{hidden: false}">Date of purchase</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'amortization'"><ng-container *clrDgHideableColumn="{hidden: false}">Lifespan (year)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'embodied'"><ng-container *clrDgHideableColumn="{hidden: false}">Embodied carbon (kg CO2eq)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Energy factor emission (kg CO2eq/kWh)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'cost'"><ng-container *clrDgHideableColumn="{hidden: false}">Energy cost ({{globalCurrency}} / kWh)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'pue'"><ng-container *clrDgHideableColumn="{hidden: false}">PUE</ng-container></clr-dg-column>                                             
                                            <clr-dg-row *clrDgItems="let host of indicators_data" [clrDgItem]="host">
                                                <clr-dg-cell>{{host.name}}</clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(host.year <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'"  class="clr-input" id="indicator.year" type="number" [(ngModel)]="host.year" style="width:4em;text-align:right" (change)="checkServerIndicator($event.target, host);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="host.year <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(host.amortization < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.amortization" type="number" [(ngModel)]="host.amortization" style="width:4em;text-align:right" (change)="checkServerIndicator($event.target, host);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="host.amortization < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(host.embodied < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.embodied" type="number" [(ngModel)]="host.embodied" style="width:5em;text-align:right" (change)="checkServerIndicator($event.target, host);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="host.embodied < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(host.co2 < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                             <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.co2" type="number" [(ngModel)]="host.co2" style="width:5em;text-align:right" (change)="checkServerIndicator($event.target, host);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="host.co2 < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(host.cost < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.cost" type="number" [(ngModel)]="host.cost" style="width:4em;text-align:right" (change)="checkServerIndicator($event.target, host);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="host.cost < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(host.pue <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.pue" type="number" [(ngModel)]="host.pue" style="width:4em;text-align:right" (change)="checkServerIndicator($event.target, host);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="host.pue <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                            </clr-dg-row>                  
                                            <clr-dg-footer>
                                                <clr-dg-pagination #indicator_page [clrDgPageSize]="10">
                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                                                    {{indicator_page.firstItem + 1}} - {{indicator_page.lastItem + 1}} of {{indicator_page.totalItems}} entries
                                                </clr-dg-pagination>
                                            </clr-dg-footer>
                                        </clr-datagrid>
                                    </div>
                                    <div style="margin-top:20px;" [hidden]="show_indicators_details">
                                        <app-greenittool [target]="indicatortree" [value_purchase]="average_host_year" [value_pue]="average_pue" [value_cost]="average_cost" [value_co2]="average_co2" (treedata)="applyServersIndicators($event)"></app-greenittool>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink>Servers min/max power</button>
                                <clr-tab-content *clrIfActive="false">
                                    <div class="clr-row">
                                        <div class="clr-col-7 info-custom">
                                            <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Here are listed physical servers&nbsp;<b>supervised by {{productname}}</b>&nbsp;without real power consumption.<br>
                                            {{productname}} can estimate power consumption of these servers based on their real activity associated to min & max power values.<br><br>
                                            <b>For each, indicate an estimation of both the min and the max power consumption.</b>
                                            </p>
                                        </div>
                                        <div class="clr-col-1" style="max-width:4%"></div>
                                        <div class="clr-col-4" style="min-width:37%;">
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <button *ngIf="currentUser.login == 'root'" style="margin-top:40px;" [disabled]="show_minmax_details" type="button" class="btn btn-secondary btn-sm" aria-label="settings" (click)="showMinMaxDetails(true)">
                                        SHOW DETAILS
                                    </button>
                                    <button *ngIf="currentUser.login == 'root'" style="margin-top:40px;" [disabled]="!show_minmax_details" type="button" class="btn btn-secondary btn-sm" aria-label="settings" (click)="showMinMaxDetails(false)">
                                        QUICK TOOL SELECTION
                                    </button>
                                    <hr>
                                    <div [hidden]="!show_minmax_details">
                                        <clr-datagrid>
                                            <clr-dg-action-bar style="margin-top:0px">
                                                <div class="btn-group" style="float:right">
                                                    <button type="button" class="btn btn-sm btn-link" (click)="exportMinMax()">
                                                        Export to CSV
                                                    </button>
                                                </div>
                                            </clr-dg-action-bar>
                                            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'model'"><ng-container *clrDgHideableColumn="{hidden: false}">Model</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'cpumodel'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU model</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'sockets'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU #</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'ramcap'"><ng-container *clrDgHideableColumn="{hidden: false}">RAM Capacity (MB)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'min'"><ng-container *clrDgHideableColumn="{hidden: false}">Power min (W)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'max'"><ng-container *clrDgHideableColumn="{hidden: false}">Power max (W)</ng-container></clr-dg-column>                                  
                                            <clr-dg-row *clrDgItems="let host of minmax_data" [clrDgItem]="host">
                                                <clr-dg-cell>{{host.name}}</clr-dg-cell>
                                                <clr-dg-cell>{{host.model ? host.model : "Not available"}}</clr-dg-cell>
                                                <clr-dg-cell>{{host.cpumodel}}</clr-dg-cell>
                                                <clr-dg-cell>{{number(host.sockets)}}</clr-dg-cell>
                                                <clr-dg-cell>{{number(host.ramcap)}}</clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(host.min > host.max || host.min < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="min" type="number" [(ngModel)]="host.min" style="width:4em;text-align:right" (change)="checkMinMaxSettings($event.target);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="(host.min > host.max || host.min < 0)" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(host.max < host.min || host.max < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="max" type="number" [(ngModel)]="host.max" style="width:4em;text-align:right" (change)="checkMinMaxSettings($event.target);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="(host.max < host.min || host.max < 0)" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                            </clr-dg-row>                     
                                            <clr-dg-footer>
                                                <clr-dg-pagination #minmax_page [clrDgPageSize]="10">
                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                                                    {{minmax_page.firstItem + 1}} - {{minmax_page.lastItem + 1}} of {{minmax_page.totalItems}} entries
                                                </clr-dg-pagination>
                                            </clr-dg-footer>
                                        </clr-datagrid>
                                    </div>
                                    <div style="margin-top:20px;" [hidden]="show_minmax_details">
                                        <app-greenittool [target]="minmaxtree" (treedata)="applyMinMax($event)"></app-greenittool>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink>Others servers</button>
                                <clr-tab-content *clrIfActive="false">
                                    <div class="clr-row">
                                        <div class="clr-col-7 info-custom">
                                            <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Indicate here the hardware characteristics of yours physical servers <b>not supervised by {{productname}}</b>.<br><br>
                                            These informations will enrich the estimate of the power consumption of your infrastructure as well as its carbon footprint.
                                            </p>
                                        </div>
                                        <div class="clr-col-1" style="max-width:4%"></div>
                                        <div class="clr-col-4" style="min-width:37%;">
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="margin-top:40px">
                                        <p><b></b></p>
                                        <hr>
                                        <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                            <tr>
                                                <td style="width:50%;vertical-align:top">
                                                    <div class="clr-form-control" style="flex-direction:row;">
                                                        <b style="font-size:13px">Number&nbsp;&nbsp;</b>
                                                        <div [ngClass]="(settings_host_other_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="static.host.other.number" type="number" [(ngModel)]="settings_host_other_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="settings_host_other_number < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:50%;font-size:13px">
                                                    <b>Description</b><br>The number of physical servers not supervised by {{productname}}.
                                                </td>
                                            </tr>
                                        </table>
                                        <hr>
                                    </div>
                                    <div>
                                        <p><b></b></p>
                                        <table cellspacing="0px" width="100%">
                                            <tr>
                                                <td style="width:50%;vertical-align:top">
                                                    <div class="clr-form-control" style="flex-direction:row;">
                                                        <b style="font-size:13px">Total CPU sockets&nbsp;&nbsp;</b>
                                                        <div [ngClass]="(settings_host_other_sockets < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="static.host.other.sockets" type="number" [(ngModel)]="settings_host_other_sockets" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="settings_host_other_sockets < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:50%;font-size:13px">
                                                    <b>Description</b><br>The total number of CPU sockets.
                                                </td>
                                            </tr>
                                        </table>
                                        <hr>
                                    </div>
                                    <div>
                                        <p><b></b></p>
                                        <table cellspacing="0px" width="100%">
                                            <tr>
                                                <td style="width:50%;vertical-align:top">
                                                    <div class="clr-form-control" style="flex-direction:row;">
                                                        <b style="font-size:13px">Total RAM capacity (GB)&nbsp;&nbsp;</b>
                                                        <div [ngClass]="(settings_host_other_memory < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="static.host.other.memory" type="number" [(ngModel)]="settings_host_other_memory" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="settings_host_other_memory < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:50%;font-size:13px">
                                                    <b>Description</b><br>The total RAM capacity in GB.
                                                </td>
                                            </tr>
                                        </table>
                                        <hr>
                                    </div>
                                    <div>
                                        <p><b></b></p>
                                        <table cellspacing="0px" width="100%">
                                            <tr>
                                                <td style="width:50%;vertical-align:top">
                                                    <div class="clr-form-control" style="flex-direction:row;">
                                                        <b style="font-size:13px">Average power (W)&nbsp;&nbsp;</b>
                                                        <div [ngClass]="(settings_host_other_power < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="static.host.other.power" type="number" [(ngModel)]="settings_host_other_power" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="settings_host_other_power < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="vertical-align: top;padding-right:5%;text-align:justify;width:50%;font-size:13px">
                                                    <b>Description</b><br>The Average power in W.<br><br>
                                                    <b>Note:&nbsp;</b>If available, the average power is computed from the real power consumption of servers supervised by {{productname}}.
                                                </td>
                                            </tr>
                                        </table>
                                        <hr>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                        </clr-tabs>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <clr-tab *ngIf="isCo2scope">
                <button clrTabLink>Network & Storage</button>
                <clr-tab-content *clrIfActive="false">
                    <div class="widget-content" style="width:70rem">
                        <clr-tabs>
                            <clr-tab>
                                <button (click)="showIndicatorsDetails(true)" clrTabLink>Network indicators</button>
                                <clr-tab-content *clrIfActive="true">
                                    <div class="clr-row">
                                        <div class="clr-col-7 info-custom">
                                            <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Here are listed network devices&nbsp;<b>supervised by {{productname}}</b>.<br>
                                            {{productname}} can estimate annual energy consumption (kWh), anuual cost and emissions in CO2eq.<br><br>
                                            <b>For each device, indicate the various parameters to refine the estimates.</b><br>
                                            </p>
                                        </div>
                                        <div class="clr-col-1" style="max-width:4%"></div>
                                        <div class="clr-col-4" style="min-width:37%;">
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <button *ngIf="currentUser.login == 'root'" style="margin-top:40px;" [disabled]="show_indicators_details" type="button" class="btn btn-secondary btn-sm" aria-label="settings" (click)="showIndicatorsDetails(true)">
                                        SHOW DETAILS
                                    </button>
                                    <button *ngIf="currentUser.login == 'root'" style="margin-top:40px;" [disabled]="!show_indicators_details" type="button" class="btn btn-secondary btn-sm" aria-label="settings" (click)="showIndicatorsDetails(false)">
                                        QUICK TOOL SELECTION
                                    </button>
                                    <hr>
                                    <div [hidden]="!show_indicators_details">
                                        <clr-datagrid>
                                            <clr-dg-action-bar style="margin-top:0px">
                                                <div class="btn-group" style="float:right">
                                                    <button type="button" class="btn btn-sm btn-link" (click)="exportNetworkIndicators()">
                                                        Export to CSV
                                                    </button>
                                                </div>
                                            </clr-dg-action-bar>
                                            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'year'"><ng-container *clrDgHideableColumn="{hidden: false}">Date of purchase</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'amortization'"><ng-container *clrDgHideableColumn="{hidden: false}">Lifespan (year)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'embodied'"><ng-container *clrDgHideableColumn="{hidden: false}">Embodied carbon (kg CO2eq)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Energy factor emission (kg CO2eq/kWh)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'cost'"><ng-container *clrDgHideableColumn="{hidden: false}">Energy cost ({{globalCurrency}} / kWh)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'pue'"><ng-container *clrDgHideableColumn="{hidden: false}">PUE</ng-container></clr-dg-column>                                             
                                            <clr-dg-row *clrDgItems="let device of indicators_network_data" [clrDgItem]="device">
                                                <clr-dg-cell>{{device.name}}</clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.year <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'"  class="clr-input" id="indicator.year" type="number" [(ngModel)]="device.year" style="width:4em;text-align:right" (change)="checkNetworkIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.year <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.amortization < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.amortization" type="number" [(ngModel)]="device.amortization" style="width:4em;text-align:right" (change)="checkNetworkIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.amortization < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.embodied < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.embodied" type="number" [(ngModel)]="device.embodied" style="width:5em;text-align:right" (change)="checkNetworkIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.embodied < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.co2 < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                             <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.co2" type="number" [(ngModel)]="device.co2" style="width:5em;text-align:right" (change)="checkNetworkIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.co2 < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.cost < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.cost" type="number" [(ngModel)]="device.cost" style="width:4em;text-align:right" (change)="checkNetworkIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.cost < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.pue <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.pue" type="number" [(ngModel)]="device.pue" style="width:4em;text-align:right" (change)="checkNetworkIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.pue <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                            </clr-dg-row>                  
                                            <clr-dg-footer>
                                                <clr-dg-pagination #indicator_page [clrDgPageSize]="10">
                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                                                    {{indicator_page.firstItem + 1}} - {{indicator_page.lastItem + 1}} of {{indicator_page.totalItems}} entries
                                                </clr-dg-pagination>
                                            </clr-dg-footer>
                                        </clr-datagrid>
                                    </div>
                                    <div style="margin-top:20px;" [hidden]="show_indicators_details">
                                        <app-greenittool [target]="networkindicatortree" [value_purchase]="average_network_year" [value_pue]="average_pue" [value_cost]="average_cost" [value_co2]="average_co2" (treedata)="applyNetworkIndicators($event)"></app-greenittool>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button (click)="showIndicatorsDetails(true)" clrTabLink>Storage indicators</button>
                                <clr-tab-content *clrIfActive="false">
                                    <div class="clr-row">
                                        <div class="clr-col-7 info-custom">
                                            <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Here are listed storage devices&nbsp;<b>supervised by {{productname}}</b>.<br>
                                            {{productname}} can estimate annual energy consumption (kWh), anuual cost and emissions in CO2eq.<br><br>
                                            <b>For each device, indicate the various parameters to refine the estimates.</b><br>
                                            </p>
                                        </div>
                                        <div class="clr-col-1" style="max-width:4%"></div>
                                        <div class="clr-col-4" style="min-width:37%;">
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <button *ngIf="currentUser.login == 'root'" style="margin-top:40px;" [disabled]="show_indicators_details" type="button" class="btn btn-secondary btn-sm" aria-label="settings" (click)="showIndicatorsDetails(true)">
                                        SHOW DETAILS
                                    </button>
                                    <button *ngIf="currentUser.login == 'root'" style="margin-top:40px;" [disabled]="!show_indicators_details" type="button" class="btn btn-secondary btn-sm" aria-label="settings" (click)="showIndicatorsDetails(false)">
                                        QUICK TOOL SELECTION
                                    </button>
                                    <hr>
                                    <div [hidden]="!show_indicators_details">
                                        <clr-datagrid>
                                            <clr-dg-action-bar style="margin-top:0px">
                                                <div class="btn-group" style="float:right">
                                                    <button type="button" class="btn btn-sm btn-link" (click)="exportStorageIndicators()">
                                                        Export to CSV
                                                    </button>
                                                </div>
                                            </clr-dg-action-bar>
                                            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'year'"><ng-container *clrDgHideableColumn="{hidden: false}">Date of purchase</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'amortization'"><ng-container *clrDgHideableColumn="{hidden: false}">Lifespan (year)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'embodied'"><ng-container *clrDgHideableColumn="{hidden: false}">Embodied carbon (kg CO2eq)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Energy factor emission (kg CO2eq/kWh)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'cost'"><ng-container *clrDgHideableColumn="{hidden: false}">Energy cost ({{globalCurrency}} / kWh)</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'pue'"><ng-container *clrDgHideableColumn="{hidden: false}">PUE</ng-container></clr-dg-column>                                             
                                            <clr-dg-row *clrDgItems="let device of indicators_storage_data" [clrDgItem]="device">
                                                <clr-dg-cell>{{device.name}}</clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.year <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'"  class="clr-input" id="indicator.year" type="number" [(ngModel)]="device.year" style="width:4em;text-align:right" (change)="checkStorageIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.year <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.amortization < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.amortization" type="number" [(ngModel)]="device.amortization" style="width:4em;text-align:right" (change)="checkStorageIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.amortization < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.embodied < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.embodied" type="number" [(ngModel)]="device.embodied" style="width:5em;text-align:right" (change)="checkStorageIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.embodied < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.co2 < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                             <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.co2" type="number" [(ngModel)]="device.co2" style="width:5em;text-align:right" (change)="checkStorageIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.co2 < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.cost < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.cost" type="number" [(ngModel)]="device.cost" style="width:4em;text-align:right" (change)="checkStorageIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.cost < 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                                <clr-dg-cell>
                                                    <div class="clr-form-control" style="margin:auto;display:unset;">
                                                        <div [ngClass]="(device.pue <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                            <div class="clr-input-wrapper">
                                                                <input [disabled]="currentUser.login != 'root'" class="clr-input" id="indicator.pue" type="number" [(ngModel)]="device.pue" style="width:4em;text-align:right" (change)="checkStorageIndicator($event.target, device);">
                                                                <cds-icon class="clr-validate-icon" *ngIf="device.pue <= 0" shape="exclamation-circle"></cds-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </clr-dg-cell>
                                            </clr-dg-row>                  
                                            <clr-dg-footer>
                                                <clr-dg-pagination #indicator_page [clrDgPageSize]="10">
                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                                                    {{indicator_page.firstItem + 1}} - {{indicator_page.lastItem + 1}} of {{indicator_page.totalItems}} entries
                                                </clr-dg-pagination>
                                            </clr-dg-footer>
                                        </clr-datagrid>
                                    </div>
                                    <div style="margin-top:20px;" [hidden]="show_indicators_details">
                                        <app-greenittool [target]="storageindicatortree" [value_purchase]="average_storage_year" [value_pue]="average_pue" [value_cost]="average_cost" [value_co2]="average_co2" (treedata)="applyStorageIndicators($event)"></app-greenittool>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink (click)="settings_tab='network'">Others network devices</button>
                                <clr-tab-content *clrIfActive="false">
                                    <div class="clr-row">
                                        <div class="clr-col-7 info-custom">
                                            <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Indicate here the hardware characteristics of yours network devices <b>not supervised by {{productname}}</b>.<br><br>
                                            These informations will enrich the estimate of the power consumption of your infrastructure as well as its carbon footprint.
                                            </p>
                                        </div>
                                        <div class="clr-col-1" style="max-width:4%"></div>
                                        <div class="clr-col-4" style="min-width:37%;">
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clr-row" style="margin-top:40px">
                                        <hr>
                                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                            <div class="clr-form-control">
                                                <div class="clr-control-container clr-control-inline">
                                                  <div class="clr-radio-wrapper">
                                                    <input type="radio" id="vertical-radio2" name="radio-full" value="manual" [(ngModel)]="network_params.entry" class="clr-radio" />
                                                    <label for="vertical-radio2" class="clr-control-label">manual entry</label>
                                                  </div>
                                                  <div class="clr-radio-wrapper">
                                                    <input type="radio" id="vertical-radio1" name="radio-full" value="file" [(ngModel)]="network_params.entry" class="clr-radio" />
                                                    <label for="vertical-radio1" class="clr-control-label">import from file</label>
                                                  </div>
                                                  <div class="clr-subtext-wrapper">
                                                    <span class="clr-subtext">select input devices</span>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="card-header">
                                                    <span style="color: gray;">Add network devices</span>
                                                </div>
                                                <div class="card-block" *ngIf="network_params.entry == 'file'">
                                                    <app-upload #uploadmodal [target]="netdevice"></app-upload>
                                                    <br>
                                                </div>
                                                <div class="card-block" *ngIf="network_params.entry == 'manual'">
                                                    <div class="card-text">
                                                        <div class="clr-row">
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-input-container" [ngClass]="{'clr-error' :  network_device.error}">
                                                                        <input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="network_device.name" name="name" (ngModelChange)="validNewDevice(true)" required />
                                                                        <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="network_device.error"></cds-icon>
                                                                        <clr-control-error *ngIf="network_device.error">This field cannot set {{network_device.errorName}}</clr-control-error>
                                                                    </div>
                                                                    <span class="clr-subtext">name of network device</span>
                                                                </div>
                                                            </div>
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-input-container" [ngClass]="{'clr-error' :  network_device.error}">
                                                                        <input type="text" class="clr-input" placeholder="enter model ..." [(ngModel)]="network_device.model" name="model" (ngModelChange)="validNewDevice(true)" required />
                                                                        <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="network_device.error"></cds-icon>
                                                                        <clr-control-error *ngIf="network_device.error">This field cannot set {{network_device.errorName}}</clr-control-error>
                                                                    </div>
                                                                    <span class="clr-subtext">model of network device</span>
                                                                </div>
                                                            </div>
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-input-container" [ngClass]="{'clr-error' :  network_device.error}">
                                                                        <input type="text" class="clr-input" placeholder="enter manufacturer ..." [(ngModel)]="network_device.manufacturer" name="manufacturer" (ngModelChange)="validNewDevice(true)" required />
                                                                        <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="network_device.error"></cds-icon>
                                                                        <clr-control-error *ngIf="network_device.error">This field cannot set {{network_device.errorName}}</clr-control-error>
                                                                    </div>
                                                                    <span class="clr-subtext">manufacturer of network device</span>
                                                                </div>
                                                            </div>
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-control-container">
                                                                        <div class="clr-select-wrapper">
                                                                            <select id="select-resource" class="clr-select" [(ngModel)]="network_device.type">
                                                                                <option value="switch">Switch</option>
                                                                                <option value="firewall">Firewall</option>
                                                                                <option value="wifiAccessPoint">Wifi access point</option>
                                                                                <!--<option value="wifiController">Wifi controller</option>-->
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <span class="clr-subtext">select the network device type</span>
                                                                </div>
                                                            </div>
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-input-container" [ngClass]="{'clr-error' :  network_device.error}">
                                                                        <input type="text" class="clr-input" placeholder="enter power consumption ..." [(ngModel)]="network_device.power" name="power" (ngModelChange)="validNewDevice(true)" required />
                                                                    </div>
                                                                    <span class="clr-subtext">power consumption value (Watt)</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer" *ngIf="network_params.entry == 'manual'">
                                                    <button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="addNetworkDevice()" style="float: right;" [disabled]="network_device.isAdd" >
                                                        Add
                                                    </button>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                                            <div class="card">
                                                <div class="card-header">
                                                    <span style="color: gray;">Network device list</span>
                                                </div>
                                                <div class="card-block">
                                                    <div class="card-text">
                                                         <clr-datagrid [clrDgLoading]="isLoading">
                                                            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'model'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Model</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'manufacturer'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Manufacturer</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'identifier'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Id</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'type'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'power'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Power (W)</ng-container></clr-dg-column>
                                                            <clr-dg-row *clrDgItems="let device of network_devices" [clrDgItem]="device">
                                                                <clr-dg-cell>{{device.name}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.model}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.manufacturer}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.identifier}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.type}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.power}}</clr-dg-cell>
                                                            </clr-dg-row>
                                                            <ng-container ngProjectAs="clr-dg-detail">
                                                                <clr-dg-detail *clrIfDetail="let detail">
                                                                    <clr-dg-detail-header>Edit {{detail.name}} network device</clr-dg-detail-header>
                                                                    <clr-dg-detail-body>
                                                                        <div class="card">
                                                                            <div class="card-block">
                                                                                <div class="card-text">
                                                                                    <div class="clr-row">
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-input-container">
                                                                                                    <input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="detail.name" name="name" (ngModelChange)="validNewDevice(true)" required />
                                                                                                    <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="network_device.error"></cds-icon>
                                                                                                    <clr-control-error *ngIf="network_device.error">This field cannot set {{network_device.errorName}}</clr-control-error>
                                                                                                </div>
                                                                                                <span class="clr-subtext">name of network device</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-input-container">
                                                                                                    <input type="text" class="clr-input" placeholder="enter model ..." [(ngModel)]="detail.model" name="model" (ngModelChange)="validNewDevice(true)" required />
                                                                                                    <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="network_device.error"></cds-icon>
                                                                                                    <clr-control-error *ngIf="network_device.error">This field cannot set {{network_device.errorName}}</clr-control-error>
                                                                                                </div>
                                                                                                <span class="clr-subtext">model of network device</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-input-container">
                                                                                                    <input type="text" class="clr-input" placeholder="enter manufacturer ..." [(ngModel)]="detail.manufacturer" name="manufacturer" (ngModelChange)="validNewDevice(true)" required />
                                                                                                    <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="network_device.error"></cds-icon>
                                                                                                    <clr-control-error *ngIf="network_device.error">This field cannot set {{network_device.errorName}}</clr-control-error>
                                                                                                </div>
                                                                                                <span class="clr-subtext">manufacturer of network device</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-control-container">
                                                                                                    <div class="clr-select-wrapper">
                                                                                                        <select id="select-resource" class="clr-select" [(ngModel)]="detail.type">
                                                                                                            <option value="switch">Switch</option>
                                                                                                            <option value="firewall">Firewall</option>
                                                                                                            <option value="wifiAccessPoint">Wifi access point</option>
                                                                                                            <option value="wifiController">Wifi controller</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="clr-subtext">select the network device type</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-input-container">
                                                                                                    <input type="text" class="clr-input" placeholder="enter power consumption ..." [(ngModel)]="detail.power" name="power" (ngModelChange)="validNewDevice(true)" required />
                                                                                                </div>
                                                                                                <span class="clr-subtext">power consumption value (Watt)</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <button title="delete" type="button" class="btn btn-danger btn-icon btn-sm" aria-label="error" (click)="deleteNetworkDevice(detail)" style="float: right;">
                                                                                    <cds-icon shape="trash"></cds-icon>
                                                                                </button>
                                                                                <button title="update" type="button" class="btn btn-icon btn-sm" aria-label="warning" (click)="updateNetworkDevice(detail)" style="float: right;" >
                                                                                    <cds-icon shape="update"></cds-icon>
                                                                                </button>
                                                                                <br>
                                                                            </div>
                                                                        </div>
                                                                    </clr-dg-detail-body>
                                                                </clr-dg-detail>
                                                            </ng-container>
                                                            <clr-dg-footer>
                                                                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                                                                        entries per page
                                                                    </clr-dg-page-size>
                                                                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                                                </clr-dg-pagination>
                                                            </clr-dg-footer>
                                                        </clr-datagrid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink (click)="settings_tab='storage'">Others storage devices</button>
                                <clr-tab-content *clrIfActive="false">
                                    <div class="clr-row">
                                        <div class="clr-col-7 info-custom">
                                            <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Indicate here the hardware characteristics of yours storage devices <b>not supervised by {{productname}}</b>.<br><br>
                                            These informations will enrich the estimate of the power consumption of your infrastructure as well as its carbon footprint.
                                            </p>
                                        </div>
                                        <div class="clr-col-1" style="max-width:4%"></div>
                                        <div class="clr-col-4" style="min-width:37%;">
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clr-row" style="margin-top:40px">
                                        <hr>
                                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                            <div class="clr-form-control">
                                                <div class="clr-control-container clr-control-inline">
                                                  <div class="clr-radio-wrapper">
                                                    <input type="radio" id="vertical-radio2" name="radio-full" value="manual" [(ngModel)]="storage_params.entry" class="clr-radio" />
                                                    <label for="vertical-radio2" class="clr-control-label">manual entry</label>
                                                  </div>
                                                  <div class="clr-radio-wrapper">
                                                    <input type="radio" id="vertical-radio1" name="radio-full" value="file" [(ngModel)]="storage_params.entry" class="clr-radio" />
                                                    <label for="vertical-radio1" class="clr-control-label">import from file</label>
                                                  </div>
                                                  <div class="clr-subtext-wrapper">
                                                    <span class="clr-subtext">select input devices</span>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="card-header">
                                                    <span style="color: gray;">Add storage devices</span>
                                                </div>
                                                <div class="card-block" *ngIf="storage_params.entry == 'file'">
                                                    <app-upload #uploadmodal [target]="stodevice"></app-upload>
                                                    <br>
                                                </div>
                                                <div class="card-block" *ngIf="storage_params.entry == 'manual'">
                                                    <div class="card-text">
                                                        <div class="clr-row">
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-input-container" [ngClass]="{'clr-error' :  storage_device.error}">
                                                                        <input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="storage_device.name" name="name" (ngModelChange)="validNewDevice(false)" required />
                                                                        <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="storage_device.error"></cds-icon>
                                                                        <clr-control-error *ngIf="storage_device.error">This field cannot set {{storage_device.errorName}}</clr-control-error>
                                                                    </div>
                                                                    <span class="clr-subtext">name of storage device</span>
                                                                </div>
                                                            </div>
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-input-container" [ngClass]="{'clr-error' :  storage_device.error}">
                                                                        <input type="text" class="clr-input" placeholder="enter model ..." [(ngModel)]="storage_device.model" name="model" (ngModelChange)="validNewDevice(false)" required />
                                                                        <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="storage_device.error"></cds-icon>
                                                                        <clr-control-error *ngIf="storage_device.error">This field cannot set {{storage_device.errorName}}</clr-control-error>
                                                                    </div>
                                                                    <span class="clr-subtext">model of storage device</span>
                                                                </div>
                                                            </div>
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-input-container" [ngClass]="{'clr-error' :  storage_device.error}">
                                                                        <input type="text" class="clr-input" placeholder="enter manufacturer ..." [(ngModel)]="storage_device.manufacturer" name="manufacturer" (ngModelChange)="validNewDevice(false)" required />
                                                                        <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="storage_device.error"></cds-icon>
                                                                        <clr-control-error *ngIf="storage_device.error">This field cannot set {{storage_device.errorName}}</clr-control-error>
                                                                    </div>
                                                                    <span class="clr-subtext">manufacturer of storage device</span>
                                                                </div>
                                                            </div>
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-control-container">
                                                                        <div class="clr-select-wrapper">
                                                                            <select id="select-resource" class="clr-select" [(ngModel)]="storage_device.type">
                                                                                <option value="san">SAN</option>
                                                                                <option value="nas">NAS</option>
                                                                                <option value="backup">BACKUP</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <span class="clr-subtext">select the storage device type</span>
                                                                </div>
                                                            </div>
                                                            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                                <div class="clr-form-control">
                                                                    <div class="clr-input-container" [ngClass]="{'clr-error' :  storage_device.error}">
                                                                        <input type="text" class="clr-input" placeholder="enter power consumption ..." [(ngModel)]="storage_device.power" name="power" (ngModelChange)="validNewDevice(false)" required />
                                                                    </div>
                                                                    <span class="clr-subtext">power consumption value (Watt)</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer" *ngIf="storage_params.entry == 'manual'">
                                                    <button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="addStorageDevice()" style="float: right;" [disabled]="storage_device.isAdd" >
                                                        Add
                                                    </button>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                                            <div class="card">
                                                <div class="card-header">
                                                    <span style="color: gray;">Storage device list</span>
                                                </div>
                                                <div class="card-block">
                                                    <div class="card-text">
                                                         <clr-datagrid [clrDgLoading]="isLoading">
                                                            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'model'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Model</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'manufacturer'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Manufacturer</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'identifier'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Id</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'type'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
                                                            <clr-dg-column [clrDgField]="'power'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Power (W)</ng-container></clr-dg-column>
                                                            <clr-dg-row *clrDgItems="let device of storage_devices" [clrDgItem]="device">
                                                                <clr-dg-cell>{{device.name}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.model}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.manufacturer}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.identifier}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.type}}</clr-dg-cell>
                                                                <clr-dg-cell>{{device.power}}</clr-dg-cell>
                                                            </clr-dg-row>
                                                            <ng-container ngProjectAs="clr-dg-detail">
                                                                <clr-dg-detail *clrIfDetail="let detail">
                                                                    <clr-dg-detail-header>Edit {{detail.name}} storage device</clr-dg-detail-header>
                                                                    <clr-dg-detail-body>
                                                                        <div class="card">
                                                                            <div class="card-block">
                                                                                <div class="card-text">
                                                                                    <div class="clr-row">
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-input-container">
                                                                                                    <input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="detail.name" name="name" (ngModelChange)="validNewDevice(false)" required />
                                                                                                    <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="storage_device.error"></cds-icon>
                                                                                                    <clr-control-error *ngIf="storage_device.error">This field cannot set {{storage_device.errorName}}</clr-control-error>
                                                                                                </div>
                                                                                                <span class="clr-subtext">name of storage device</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-input-container">
                                                                                                    <input type="text" class="clr-input" placeholder="enter model ..." [(ngModel)]="detail.model" name="model" (ngModelChange)="validNewDevice(false)" required />
                                                                                                    <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="storage_device.error"></cds-icon>
                                                                                                    <clr-control-error *ngIf="storage_device.error">This field cannot set {{storage_device.errorName}}</clr-control-error>
                                                                                                </div>
                                                                                                <span class="clr-subtext">model of storage device</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-input-container">
                                                                                                    <input type="text" class="clr-input" placeholder="enter manufacturer ..." [(ngModel)]="detail.manufacturer" name="manufacturer" (ngModelChange)="validNewDevice(false)" required />
                                                                                                    <cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="storage_device.error"></cds-icon>
                                                                                                    <clr-control-error *ngIf="storage_device.error">This field cannot set {{storage_device.errorName}}</clr-control-error>
                                                                                                </div>
                                                                                                <span class="clr-subtext">manufacturer of storage device</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-control-container">
                                                                                                    <div class="clr-select-wrapper">
                                                                                                        <select id="select-resource" class="clr-select" [(ngModel)]="detail.type">
                                                                                                            <option value="san">SAN</option>
                                                                                                            <option value="nas">NAS</option>
                                                                                                            <option value="backup">BACKUP</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="clr-subtext">select the storage device type</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                                                            <div class="clr-form-control">
                                                                                                <div class="clr-input-container">
                                                                                                    <input type="text" class="clr-input" placeholder="enter power consumption ..." [(ngModel)]="detail.power" name="power" (ngModelChange)="validNewDevice(false)" required />
                                                                                                </div>
                                                                                                <span class="clr-subtext">power consumption value (Watt)</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <button title="delete" type="button" class="btn btn-danger btn-icon btn-sm" aria-label="error" (click)="deleteStorageDevice(detail)" style="float: right;">
                                                                                    <cds-icon shape="trash"></cds-icon>
                                                                                </button>
                                                                                <button title="update" type="button" class="btn btn-icon btn-sm" aria-label="warning" (click)="updateStorageDevice(detail)" style="float: right;" >
                                                                                    <cds-icon shape="update"></cds-icon>
                                                                                </button>
                                                                                <br>
                                                                            </div>
                                                                        </div>
                                                                    </clr-dg-detail-body>
                                                                </clr-dg-detail>
                                                            </ng-container>
                                                            <clr-dg-footer>
                                                                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                                                                        entries per page
                                                                    </clr-dg-page-size>
                                                                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                                                </clr-dg-pagination>
                                                            </clr-dg-footer>
                                                        </clr-datagrid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink (click)="setExtrapolationTab();">Extrapolations</button>
                                <clr-tab-content *clrIfActive="false">
                                    <div class="clr-row">
                                        <div class="clr-col-7 info-custom">
                                            <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Indicate here ratios for network and storage extrapolation.<br><br>
                                            These informations will enrich the estimate of the carbon footprint of your infrastructure.<br><br>
                                            <b>Network and storage data are estimated by extrapolation from server consumption data.</b>
                                            </p>
                                        </div>
                                        <div class="clr-col-1" style="max-width:4%"></div>
                                        <div class="clr-col-4" style="min-width:37%;">
                                            <!-- <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div> -->
                                            <div class="clr-row">
                                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clr-row" style="margin-top:40px">
                                        <div class="clr-col-8">
                                            <div>
                                                <hr>
                                                <p><b>Network :</b></p>
                                                <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                                    <tr>
                                                        <td style="width:40%">
                                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                                <label for="network.direct.ratio" class="clr-control-label"> Ratio&nbsp;(%)&nbsp;&nbsp;</label>
                                                                <div [ngClass]="(settings_network_direct_ratio < 0 || settings_network_direct_ratio > 100 || extrapolation_alert) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                                    <div class="clr-input-wrapper">
                                                                        <input class="clr-input" id="network.direct.ratio" type="number" min=0 max=100 [(ngModel)]="settings_network_direct_ratio" style="width:4em;text-align:right" (ngModelChange)="addNetworkStorageSettings('network.direct.ratio')">
                                                                        <cds-icon class="clr-validate-icon" *ngIf="(settings_network_direct_ratio < 0 || settings_network_direct_ratio > 100 || extrapolation_alert)" shape="exclamation-circle"></cds-icon>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                            <b>Description</b><br>Part of network energy consumption<br><br>
                                                            <span><b>Network data are estimated by extrapolation from server consumption data.</b></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            <hr>
                                            </div>
                                            <div>
                                                <p><b>Storage :</b></p>
                                                <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                                    <tr>
                                                        <td style="width:40%">
                                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                                <label for="storage.direct.ratio" class="clr-control-label">Ratio&nbsp;(%)&nbsp;&nbsp;</label>
                                                                <div [ngClass]="(settings_storage_direct_ratio < 0 || settings_storage_direct_ratio > 100 || extrapolation_alert) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                                    <div class="clr-input-wrapper">
                                                                        <input class="clr-input" id="storage.direct.ratio" type="number" min=0 max=100 [(ngModel)]="settings_storage_direct_ratio" style="width:4em;text-align:right" (ngModelChange)="addNetworkStorageSettings('storage.direct.ratio')">
                                                                        <cds-icon class="clr-validate-icon" *ngIf="(settings_storage_direct_ratio  < 0 || settings_storage_direct_ratio > 100 || extrapolation_alert)" shape="exclamation-circle"></cds-icon>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                            <b>Description</b><br>Part of storage energy consumption<br><br>
                                                            <b>Storage data are estimated by extrapolation from server consumption data.</b>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <hr>
                                            </div>
                                        </div>
                                        <div class="clr-col-4">
                                            <highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;"></highcharts-chart>
                                        </div>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                        </clr-tabs>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <!-- <clr-tab>
                <button clrTabLink>Desktop</button>
                <clr-tab-content *clrIfActive="false">
                    <div class="widget-content" id="div-settings-desktop" style="width:70rem">
                        <div class="clr-row">
                            <div class="clr-col-7 info-custom">
                                <p><cds-icon size="24" shape="help-info"></cds-icon>&nbsp;Indicate here the number of hardware you have.<br><br>
                                These informations will enrich the estimate of the carbon footprint of your infrastructure.
                                </p>
                            </div>
                            <div class="clr-col-1" style="max-width:4%"></div>
                            <div class="clr-col-4" style="min-width:37%;">
                                <div class="clr-row">
                                    <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                </div>
                                <div class="clr-row">
                                    <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top:40px">
                            <div>
                                <hr>
                                <p><b>Desktop & screen :</b></p>
                                <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                    <tr>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.desktop.age" class="clr-control-label">Average age&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_desktop_age < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.desktop.age" type="number" [(ngModel)]="settings_desktop_age" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_desktop_age < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.desktop.number" class="clr-control-label">Desktops&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_desktop_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.desktop.number" type="number" [(ngModel)]="settings_desktop_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_desktop_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.keyboard.number" class="clr-control-label">Keyboards&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_keyboard_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.keyboard.number" type="number" [(ngModel)]="settings_keyboard_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_keyboard_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.mouse.number" class="clr-control-label">Mouses&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_mouse_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.mouse.number" type="number" [(ngModel)]="settings_mouse_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_mouse_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:20%"></td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.screen.17.number" class="clr-control-label">17"&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_screen_17_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.screen.17.number" type="number" [(ngModel)]="settings_screen_17_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_screen_17_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.screen.22.number" class="clr-control-label">22"&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_screen_22_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.screen.22.number" type="number" [(ngModel)]="settings_screen_22_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_screen_22_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.screen.27.number" class="clr-control-label">27"&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_screen_27_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.screen.27.number" type="number" [(ngModel)]="settings_screen_27_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_screen_27_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">&nbsp;</td>
                                    </tr>
                                </table>
                                <hr>
                            </div>
                            <div>
                                <p><b>Printer :</b></p>
                                <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                    <tr>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.printer.age" class="clr-control-label">Average age&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_printer_age < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.printer.age" type="number" [(ngModel)]="settings_printer_age" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_printer_age < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.printer.home.number" class="clr-control-label">Home&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_printer_home_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.printer.home.number" type="number" [(ngModel)]="settings_printer_home_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_printer_home_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.printer.pro.number" class="clr-control-label">Pro&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_printer_pro_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.printer.pro.number" type="number" [(ngModel)]="settings_printer_pro_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_printer_pro_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">&nbsp;</td>
                                        <td style="width:20%">&nbsp;</td>
                                    </tr>
                                </table>
                                <hr>
                            </div>
                            <div>
                                <p><b>Laptop :</b></p>
                                <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                    <tr>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.laptop.age" class="clr-control-label">Average age&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_laptop_age < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.laptop.age" type="number" [(ngModel)]="settings_laptop_age" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_laptop_age < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.laptop.13.number" class="clr-control-label">13"&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_laptop_13_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.laptop.13.number" type="number" [(ngModel)]="settings_laptop_13_number" style="width:4em;text-align:right" (change)="addSettings($event.target);autoSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_laptop_13_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.laptop.15.number" class="clr-control-label">15"&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_laptop_15_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.laptop.15.number" type="number" [(ngModel)]="settings_laptop_15_number" style="width:4em;text-align:right" (change)="addSettings($event.target);autoSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_laptop_15_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.laptop.17.number" class="clr-control-label">17"&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_laptop_17_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.laptop.17.number" type="number" [(ngModel)]="settings_laptop_17_number" style="width:4em;text-align:right" (change)="addSettings($event.target);autoSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_laptop_17_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.laptop.charger.number" class="clr-control-label">Chargers&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_laptop_charger_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.laptop.charger.number" type="number" [(ngModel)]="settings_laptop_charger_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_laptop_charger_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <hr>
                            </div>
                            <div>
                                <p><b>Network :</b></p>
                                <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                    <tr>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.router.number" class="clr-control-label">Routers&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_desktop_router_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.router.number" type="number" [(ngModel)]="settings_desktop_router_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_desktop_router_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:40%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.network.other.number" class="clr-control-label">Internet equipment&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_desktop_network_other_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.network.other.number" type="number" [(ngModel)]="settings_desktop_network_other_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_desktop_network_other_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">&nbsp;</td>
                                        <td style="width:20%">&nbsp;</td>
                                    </tr>
                                </table>
                                <hr>
                            </div>
                            <div>
                                <p><b>Other :</b></p>
                                <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                    <tr>
                                        <td style="width:20%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.tablet.number" class="clr-control-label">Tablets&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_tablet_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.tablet.number" type="number" [(ngModel)]="settings_tablet_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_tablet_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:40%">
                                            <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                <label for="desktop.smartphone.number" class="clr-control-label">Smartphones&nbsp;&nbsp;</label>
                                                <div [ngClass]="(settings_smartphone_number < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                    <div class="clr-input-wrapper">
                                                        <input [disabled]="currentUser.login != 'root'" class="clr-input" id="desktop.smartphone.number" type="number" [(ngModel)]="settings_smartphone_number" style="width:4em;text-align:right" (change)="addSettings($event.target);">
                                                        <cds-icon class="clr-validate-icon" *ngIf="settings_smartphone_number < 0" shape="exclamation-circle"></cds-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:20%">&nbsp;</td>
                                        <td style="width:20%">&nbsp;</td>
                                    </tr>
                                </table>
                                <hr>
                            </div>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab> -->
            <clr-tab>
                <button clrTabLink>Renewal & optimization</button>
                <clr-tab-content *clrIfActive="false">
                    <div class="widget-content" style="width:70rem">
                        <clr-tabs>
                            <clr-tab>
                                <button clrTabLink>Renewal server</button>
                                <clr-tab-content *clrIfActive="true">
                                    <div class="widget-content" id="div-settings-renewalserver" style="width:70rem;min-height:600px;">
                                        <div class="clr-row">
                                            <div class="clr-col-7 info-custom">
                                                <p><cds-icon size="24" shape="help-info"></cds-icon>
                                                    &nbsp;Indicate here the server characteristics for the renewal.<br><br>
                                                    <b>Note:</b> if not indicated, characteristics are those of an average server in your infrastructure.
                                                </p>
                                            </div>
                                            <div class="clr-col-1" style="max-width:4%"></div>
                                            <div class="clr-col-4" style="min-width:37%;">
                                                <div class="clr-row">
                                                    <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clr-row" style="margin-top:40px">
                                            <div class="clr-col-8">
                                                <div>
                                                    <hr>
                                                    <p><b>Hardaware capabilites :</b></p>
                                                    <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                                        <tr>
                                                            <td style="width:40%">
                                                                <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                                    <label for="renewal.ram" class="clr-control-label" style="margin-right:20px;">RAM capacity (GB)&nbsp;&nbsp;</label>
                                                                    <div [ngClass]="(renewal_serverram == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                                        <div class="clr-input-wrapper">
                                                                            <input class="clr-input" type="number" min=1 [(ngModel)]="renewal_serverram" style="text-align:right;width:4em" id="renewal.ram" (change)="checkRenewalSettings($event.target);">
                                                                            <cds-icon class="clr-validate-icon" *ngIf="renewal_serverram <= 0" shape="exclamation-circle"></cds-icon>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                                <b>Description</b><br>The memory capacity in GB<br><br>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width:40%">
                                                                <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                                    <label for="renewal.socket" class="clr-control-label" style="margin-right:30px;">Sockets number&nbsp;&nbsp;</label>
                                                                    <div [ngClass]="(renewal_serversocket == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                                        <div class="clr-input-wrapper">
                                                                            <input class="clr-input" type="number" min=1 [(ngModel)]="renewal_serversocket" style="text-align:right;width:4em" id="renewal.socket" (change)="checkRenewalSettings($event.target);">
                                                                            <cds-icon class="clr-validate-icon" *ngIf="renewal_serversocket <= 0" shape="exclamation-circle"></cds-icon>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                                <b>Description</b><br>The number of CPU sockets<br><br>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width:40%">
                                                                <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                                    <label for="renewal.thread" class="clr-control-label" style="margin-right:30px;">Threads number&nbsp;&nbsp;</label>
                                                                    <div class="clr-form-control" style="margin-top:0;">
                                                                        <div [ngClass]="(renewal_serverthread == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                                            <div class="clr-input-wrapper">
                                                                                <input class="clr-input" type="number" min=1 [(ngModel)]="renewal_serverthread" style="text-align:right;width:4em" id="renewal.thread" (change)="checkRenewalSettings($event.target);">
                                                                                <cds-icon class="clr-validate-icon" *ngIf="renewal_serverthread <= 0" shape="exclamation-circle"></cds-icon>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                                <b>Description</b><br>The number of CPU threads<br><br>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                <hr>
                                                </div>
                                                <div>
                                                    <hr>
                                                    <p><b>Energy & carbon informations :</b></p>
                                                    <table cellspacing="0px" width="100%" style="table-layout:fixed;">
                                                        <tr>
                                                            <td style="width:40%">
                                                                <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                                    <label for="renewal.embodied" class="clr-control-label" style="margin-right:20px;">Total embodied carbon (kgCO2eq)&nbsp;&nbsp;</label>
                                                                    <div [ngClass]="(renewal_serverram == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                                        <div [ngClass]="(renewal_serverembodied == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                                            <div class="clr-input-wrapper">
                                                                                <input class="clr-input" type="number" min=1 [(ngModel)]="renewal_serverembodied" style="text-align:right;width:5em" id="renewal.embodied" (change)="checkRenewalSettings($event.target);">
                                                                                <cds-icon class="clr-validate-icon" *ngIf="renewal_serverembodied < 0" shape="exclamation-circle"></cds-icon>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                                <b>Description</b><br>The total embodied carbon of the server in kgCO2eq<br><br>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width:40%">
                                                                <div class="clr-form-control" style="margin-left:15%;flex-direction:row;">
                                                                    <label for="renewal.kwh" class="clr-control-label" style="margin-right:70px;">Annual kWh (without PUE)&nbsp;&nbsp;</label>
                                                                     <div class="clr-form-control" style="margin-top:0;">
                                                                        <div [ngClass]="(renewal_serverkWh == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                                                            <div class="clr-input-wrapper">
                                                                                <input class="clr-input" type="number" min=1 [(ngModel)]="renewal_serverkWh" style="text-align:right;width:5em" id="renewal.kwh" (change)="checkRenewalSettings($event.target);">
                                                                                <cds-icon class="clr-validate-icon" *ngIf="renewal_serverkWh < 0" shape="exclamation-circle"></cds-icon>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style="vertical-align: top;padding-right:5%;text-align:justify;width:33%;font-size:13px">
                                                                <b>Description</b><br>The annual power consumption of the server in kWh<br><br>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink>Optimization</button>
                                <clr-tab-content *clrIfActive="false">
                                    <div class="widget-content" id="div-settings-optimization" style="width:70rem;min-height:500px;">
                                        <div class="clr-row">
                                            <div class="clr-col-7 info-custom">
                                                <p><cds-icon size="24" shape="help-info"></cds-icon>
                                                    &nbsp;Choose the typical server for the energy optimization algorithm.<br><br>
                                                    The energy optimization algorithm will try to replace existing servers with typical servers, depending on capacities and energy consumed by servers.<br><br>
                                                    <b>Note:</b> if not indicated, the algorithm will automatically define the best server.
                                                </p>
                                            </div>
                                            <div class="clr-col-1" style="max-width:4%"></div>
                                            <div class="clr-col-4" style="min-width:37%;">
                                                <div class="clr-row">
                                                    <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'settings'}"></ng-template>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-top:40px">
                                            <form clrForm clrLayout="horizontal">
                                                <clr-datalist-container>
                                                    <label>Choose your server for the optimization algorithm :</label>
                                                    <input clrDatalistInput id="optimization.host" [(ngModel)]="settings_optimization_host" (change)="addSettings($event.target);" placeholder="Select a server ..." name="report_filter_select" minlength="50">
                                                    <datalist id="greenit-host-selected">
                                                        <option *ngFor="let i of optimization_hosts_uuid" [id]="i[0]" [value]="i[1]">{{i[1]}}</option>
                                                    </datalist>
                                                </clr-datalist-container>
                                            </form>
                                        </div>
                                    </div>
                                </clr-tab-content>
                            </clr-tab>
                        </clr-tabs>
                    </div>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink>Remove history</button>
                <clr-tab-content *clrIfActive="false">
                    <div class="widget-content" id="div-settings-history" style="width:70rem;min-height:500px;">
                        <div class="clr-row">
                            <div class="clr-col-7 info-custom">
                                <p><cds-icon size="24" shape="help-info"></cds-icon>
                                    &nbsp;{{productname}} allows you to observe the evolution of your carbon footprint.<br><br>
                                    However, you can if you wish delete the existing history (after modifying some parameters for example).<br><br>
                                    <b>By clicking on the button 'Remove history', your history will be deleted and a new one from today will be started.</b>
                                </p>
                            </div>
                            <div class="clr-col-1" style="max-width:4%"></div>
                            <div class="clr-col-4" style="min-width:37%;">
                                <ng-template [ngTemplateOutlet]="infodate" [ngTemplateOutletContext]="{type : 'history'}"></ng-template>
                            </div>
                        </div>
                        <div class="clr-row" style="min-height:100px;"></div>
                        <div class="clr-row">
                            <ng-template [ngTemplateOutlet]="alertdata" [ngTemplateOutletContext]="{type : 'history'}"></ng-template>
                        </div>
                         <div class="clr-row">
                            <div class="clr-col-10"></div>
                            <div class="clr-col-2">
                                <div style="float:right">
                                    <button type="button" class="btn btn-danger" (click)="history_modal=true" [disabled]="history_disabled"><cds-icon shape="trash" style="margin-right:10px"></cds-icon>Remove history</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
    <div class="modal-footer" style="min-height:60px;">
        <button type="button" class="btn btn-primary" (click)="saveSettings()" *ngIf="save_button">Save</button>
    </div>
</clr-modal>

<!-- ALERT DATA -->
<ng-template #alertdata let-type="type">
    <div class="alert alert-warning" role="alert" style="margin-top:20px;padding-left:5px;">
        <div class="alert-items">
            <div class="alert-item static">
                <cds-icon shape="exclamation-triangle" size="md" style="margin-right:10px"></cds-icon>
                <span *ngIf="type == 'settings'" class="alert-text" style="margin:auto">
                    Any changes will be applied to the future data (from tomorrow) only.<br><br>Previous data are not affected.
                </span>
                <span *ngIf="type == 'history'" class="alert-text" style="margin:auto">
                    The deletion of the history will be carried out during the next data consolidation (from tomorrow).
                </span>
            </div>
        </div>
    </div>
</ng-template>

<!-- INFO DATE -->
<ng-template #infodate let-type="type">
    <div class="alert alert-success" role="alert" style="margin-top:20px;padding-left:5px;color:black">
        <div class="alert-items">
            <div class="alert-item static">
                <cds-icon shape="check-circle" size="md" style="margin-right:10px"></cds-icon>
                <span *ngIf="type == 'settings'" class="alert-text" style="margin:auto">
                    Last settings update : {{formatDate(last_update)}}
                </span>
                <span *ngIf="type == 'history'" class="alert-text" style="margin:auto">
                    Last deletion date : {{formatDate(last_history)}}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<!-- CONFIRM DELETE -->
<clr-modal [(clrModalOpen)]="history_modal" [clrModalClosable]=false>
    <h3 class="modal-title">Remove history</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>The GreenIt history will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="history_modal=false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="confirmClear()"><cds-icon shape="trash" style="margin-right:10px"></cds-icon>Remove history</button>
    </div>
</clr-modal>
