<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="table"></cds-icon>
					&nbsp;Resource Pool list 
				</h5>
				<span class="export"></span>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  					<thead>
    					<tr>
      						<th>NAME</th>
							<th>IDENTIFIER</th>
							<th>VMS</th>
							<th>CPU RES. (MHz)</th>
							<th>CPU LIMIT (MHz)</th>
							<th>CPU EXPANDABLE</th>
							<th>MEMORY RES. (MB)</th>
							<th>MEMORY LIMIT (MB)</th>
							<th>MEMORY EXPANDABLE</th>
						</tr>
  					</thead>
  					<tbody>
    						<tr *ngFor="let rp of data_rp" ngClass="addLink" (click)="callroute(rp.uuid, rp.name)">
							<td>{{rp.name}}</td>
							<td>{{rp.uuid}}</td>
							<td>{{rp.vms}}</td>
							<td>{{rp.cpures}}</td>
							<td>{{rp.cpulimit}}</td>
							<td>{{rp.cpuexpandable}}</td>
							<td>{{rp.ramres}}</td>
							<td>{{rp.ramlimit}}</td>
							<td>{{rp.ramexpandable}}</td>
						</tr>
  					</tbody>
  				</table>
			</div>
		</div>
	</div>
</div>