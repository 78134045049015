<div style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-flow: column;">
    <div class="clr-row" style="margin: 0px; background-color: rgb(0, 55, 76); color : white; overflow: visible; min-height: 74px;">
        <div class="clr-col" id="flow_view_title" style="flex-grow: 0; color: white; text-align: center;">
            <table style="height: 100%; min-width: 100px; font-weight: 400; font-size: .8rem; letter-spacing: .01em; text-decoration: none;">
                <tr>
                    <td>
                        <cds-icon shape="checkbox-list" style="margin-right: 10px; margin-bottom: 3px;" size="md"></cds-icon>
                    </td>
                    <td style="text-align: left;">{{ "dcnetscope.diagnostic.name" | transloco }}</td>
                </tr>
            </table>
        </div>

        <!-- Empty column that separates title and group of buttons at the right -->
        <div class="clr-col" style="padding-top: 10px; padding-bottom: 5px;">
        </div>

        <div class="clr-col" style="width: 100px; flex-grow: 0; padding-left: 0px;">
            <table style="height: 100%;">
                <tr>
                    <td style="padding: 5px;">
                    </td>
                    <td style="padding: 5px;">
                        <clr-dropdown [clrCloseMenuOnItemClick]="true">
                            <a clrDropdownTrigger href="javascript://" class="nav-link nav-icon" aria-label="settings">
                                <cds-icon shape="cog" style="color: white;"></cds-icon>
                            </a>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Language</label>
                                <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="setLanguage('french')">French</div>
                                <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="setLanguage('english')">English</div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="clr-row" style="position: relative; height: 100%; bottom: 0px; width: 100%; margin: 0px;">
    </div>
</div>

<div style="width: 100%; height: 50px;">
    <!-- Prevent the following content to be overlap by the top bar -->
</div>

<div class="clr-row">
    <div class="clr-col-6">
        <div class="card">
            <h3 class="card-header" style="margin-top: 0px;">Installation status</h3>
            <div class="card-text">
                <clr-timeline clrLayout="vertical" style="width: 100%">
                    <clr-timeline-step clrState="{{statuses.step1}}">
                        <clr-timeline-step-header>Step 1</clr-timeline-step-header>
                        <clr-timeline-step-title>Deploy the DC Netscope VM</clr-timeline-step-title>
                        <clr-timeline-step-description>DC Netscope requires a second VM to installed besides the DC Scope VM.
                            <br/>
                            You have to download the OVA file for DC Netscope (<a target="_blank" href="https://www.easyvirt.com/en/download/">here</a>) and deploy it on your infrastructure.
                            <br/>
                            <br/>
                            You will find more information on the installation process here at the following URL:
                            <br/>
                            <a target="_blank" href="https://www.easyvirt.com/dc-netscope-network-monitoring-tool/installation-dc-netscope">https://www.easyvirt.com/dc-netscope-network-monitoring-tool/installation-dc-netscope</a>
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{statuses.step2}}">
                        <clr-timeline-step-header>Step 2</clr-timeline-step-header>
                        <clr-timeline-step-title>Initialize DC Netscope</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Use the VMware console of the DC Netscope VM to configure DC Netscope.
                            <br/>
                            You will have to do the following:
                            <ul>
                                <li>Choose a password</li>
                                <li>Configure the connection to a vCenter
                                    <span *ngFor="let vcenter of vcenters" class="label {{ vcenter.cssClass }}">
                                        {{ vcenter.name }}
                                        <span class="badge">{{ vcenter.status }}</span>
                                    </span>
                                </li>
                            </ul>
                            <br/>
                            You will find more information on the initialization process here at the following URL:
                            <br/>
                            <a target="_blank" href="https://www.easyvirt.com/dc-netscope-network-monitoring-tool/installation-dc-netscope">https://www.easyvirt.com/dc-netscope-network-monitoring-tool/installation-dc-netscope</a>

                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step class="gemini-ignore" clrState="{{statuses.step3}}">
                        <clr-timeline-step-header>Step 3</clr-timeline-step-header>
                        <clr-timeline-step-title>Add the DC Netscope agent's URL in DC Scope's settings</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Ensure that the URL to the DC Netscope agent is added in DC Scope's settings. To do so, do the following:
                            <ul>
                                <li>Go in DC Scope settings</li>
                                <li>Go to the DC Netscope tab</li>
                                <li>Add the URL to the DC Netscope agent. The URL is displayed in the console of the DC Netscope VM.
                                    It is usually in the form of
                                    <code>http://ip_address:5000</code> or <code>https://ip_address:5443</code></li>
                                <li>Press enter to confirm</li>
                            </ul>

                            <span *ngIf="canConnectToDcnetscope" class="label label-success">
                                DC Scope can connect to DC Netscope
                            </span>
                            <span *ngIf="this.urlDcNetscopeAgent !== undefined && !canConnectToDcnetscope" class="label label-danger">
                                DC Scope cannot connect to DC Netscope ({{ this.urlDcNetscopeAgent }})
                            </span>
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{statuses.step4}}">
                        <clr-timeline-step-header>Step 4</clr-timeline-step-header>
                        <clr-timeline-step-title>Configure the Distributed vSwitches</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Configuration of Distributed vSwitches is made in two steps:
                            <ol>
                                <li>Configure the URL to the DC Netscope exporter in the Netflow parameters of the Distributed vSwitches. The URL is usually in the form of
                                    <code>http://ip_address:9000</code></li>
                                <li>Enable Netflow in the settings of Portgroups of the Distributed vSwitches.</li>
                            </ol>
                            <br/>
                            <br/>
                            You will find more information on the initialization process here at the following URL:
                            <br/>
                            <a target="_blank" href="https://www.easyvirt.com/dc-netscope-network-monitoring-tool/installation-dc-netscope">https://www.easyvirt.com/dc-netscope-network-monitoring-tool/installation-dc-netscope</a>
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{statuses.collect_status}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>Collect is working</clr-timeline-step-title>
                        <clr-timeline-step-description>Distributed vSwitches are exporting netflow data to DC Netscope.</clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{statuses.operation_status}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>DC Netscope is operational</clr-timeline-step-title>
                        <clr-timeline-step-description>DC Netscope is configured and is collecting flows information from Distributed vSwitches.</clr-timeline-step-description>
                    </clr-timeline-step>
                </clr-timeline>
            </div>
        </div>
    </div>

    <div class="clr-col-6">
        <div class="card">
            <h3 class="card-header" style="margin-top: 0px;">Endpoint status</h3>
            <div class="card-text">
                <clr-timeline clrLayout="vertical" style="width: 100%">
                    <clr-timeline-step clrState="{{endpointStatuses.lastInfrastructure}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>Recent infrastructure description</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Check that DC Netscope has a recent description of the infrastructure.
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{endpointStatuses.dashboard}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>Dashboard endpoint</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Check that data for the dashboard component is available.
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{endpointStatuses.collectHistory}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>Collect history</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Check that data for the dashboard's collect history graph is available.
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{endpointStatuses.topology}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>Topology</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Check that data for topology component is available.
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{endpointStatuses.flows}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>Flows data</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Check that data for flows visualizations is available.
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{endpointStatuses.appsGroups}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>App groups</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Check that data for app groups visualizations is available.
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                    <clr-timeline-step clrState="{{endpointStatuses.externalIps}}">
                        <clr-timeline-step-header></clr-timeline-step-header>
                        <clr-timeline-step-title>External IPs</clr-timeline-step-title>
                        <clr-timeline-step-description>
                            Check that data for External IPs is available.
                        </clr-timeline-step-description>
                    </clr-timeline-step>
                </clr-timeline>
            </div>
        </div>
    </div>
</div>
