<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div id="cost-vm-list" class="card" style="margin-top:unset;">
			<div class="card-block">
				<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  					<thead>
    						<tr>
      							<th>Name</th>
      							<th>VM cost ({{globalCurrency}})</th>
						</tr>
  					</thead>
					<tbody>
    						<tr *ngFor="let vm of data_cost">
							<td>{{vm.name}}</td>
							<td style="width:15%;vertical-align:top">
								<div class="clr-form-control" style="flex-direction:column;margin-top:unset;">
                                        				<div>
                                            					<div class="clr-input-wrapper">
											<b><input class="clr-input" type="number" [(ngModel)]="vm.cost" (ngModelChange)="enableVmUpdate()" style="width:6em;text-align:left"></b>
                                                					<cds-icon class="clr-validate-icon" *ngIf="vm.cost == null" shape="exclamation-circle"></cds-icon>
                                            					</div>
                                        				</div>
                                    				</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="message.isCostTool">
		<div class="card" style="margin-top:unset;">
			<div class="card-header card-ajust">
				<button type="button" class="close" aria-label="Close" (click)="closeCard()">
					<cds-icon aria-hidden="true" shape="close"></cds-icon>
				</button>
				<h6>
					<cds-icon shape="tools"></cds-icon>
					&nbsp;selection tool
				</h6>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<clr-control-helper>1- select VMs where the new cost must be apply</clr-control-helper>
						<ng-template load-selection-tree></ng-template>
					</div>
					<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
						<div class="clr-input-wrapper">
							<input class="clr-input" id="cost.sto.gen" type="number" [(ngModel)]="model.cost" style="width:6em;text-align:left">
							<clr-control-helper>2- enter the new VM cost in {{globalCurrency}}</clr-control-helper>
							<cds-icon class="clr-validate-icon" *ngIf="model.cost == null" shape="exclamation-circle"></cds-icon>
						</div>
					</div>
					<div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngIf="message.isTreeAnalysis">
						<button type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="applyElement()">
							APPLY
						</button>
						<clr-control-helper>3- apply cost to selected VMs</clr-control-helper>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
