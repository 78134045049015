import {
    Component,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    GreenitTarget
} from '@app/greenit/greenit.enums';
import {
    GreenitcfComponent
} from '@app/greenit/greenitcf/greenitcf.component';
import {
    JSONTarget
} from '@app/model';
import {
    GreenitService,
    JsonloaderService
} from '@app/services';
import {
    T8Component
} from '@app/t8/t8.component';
import {
    Subscription
} from 'rxjs';

@Component({
    selector: 'app-network',
    templateUrl: './network.component.html',
    styleUrls: ['./network.component.css']
})
export class NetworkComponent implements OnInit {

    @ViewChild('greenitcf') greenitcf: GreenitcfComponent;
    @ViewChild('t8') t8: T8Component;

    isReady: boolean = false;
    allowReload: boolean = false;

    t1_target: GreenitTarget = GreenitTarget.NETWORK_CO2;
    t2_target: JSONTarget = JSONTarget.T8_NETWORK;

    json_subscription: Subscription;

    network_data_check: boolean = false;

    /**
     * 
     */
    constructor(private json_svc: JsonloaderService, private greenit_svc: GreenitService) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.isReady = true;

        // Check network data
        this.greenit_svc.networkDataCheck.subscribe(
            details => {
                this.network_data_check = details;
                if (!this.network_data_check)
                    this.t1_target = GreenitTarget.EXTRAPOLATION_NETWORK_CO2;
                else
                    this.t1_target = GreenitTarget.NETWORK_CO2;
            }
        );

        // Need subscription to JsonloaderService to detect filter reload
        this.json_subscription = this.json_svc.currentJson.subscribe(
            json => {
                if (this.greenitcf !== undefined && this.allowReload) {
                    this.greenitcf.load(true);
                }
                if (this.t8 !== undefined && this.allowReload) {
                    this.t8.load(true);
                }
                this.allowReload = true;
            }
        )
    }

    /**
     * 
     */
    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.json_subscription != undefined) {
            this.json_subscription.unsubscribe();
        }
        //console.log("destroyed network");
    }

}
