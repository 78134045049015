import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    ReportService,
    ShareService
} from '@app/services';

import {
    JSONTarget,
    User,
    Message,
    PowershellResource,
    RecoVm
} from '@app/model';


@Component({
    selector: 'app-recommendationresizingdetailcpu',
    templateUrl: './recommendationresizingdetailcpu.component.html',
    styleUrls: ['./recommendationresizingdetailcpu.component.css']
})
export class RecommendationresizingdetailcpuComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: any = {};

    dtTrigger: Subject < any > = new Subject();
    dtTrigger2: Subject < any > = new Subject();

    // POWERSHELL
    powershell_ids: Set < String > = new Set();
    powershell_dtOptions: DataTables.Settings = {};

    // INFO
    info_modal: boolean = false;
    info_header: string;
    info_body: string;

    message: Message;

    math = Math;

    data_model: RecoVm[] = [];

    data_elements: RecoVm[] = [];

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private report_svc: ReportService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();
        $("dt-buttons").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.message.isRecoPowershell = true;
        this.message.isPowershellRam = false;
        this.message.isPowershellCpu = true;

        this.initDtOptions();

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_VM).subscribe(
            data => {
                this.data_model = data;
                if (this.data_model.length > 0)
                    this.buildData();
            }
        );
        this.powershell_ids = new Set();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
        //this.dtTrigger2.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
        this.dtTrigger2.unsubscribe();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    displayInfo(header: string, body: string) {

        this.info_modal = true;
        this.info_header = header;
        this.info_body = body;
    }

    manageElement(input: HTMLInputElement): void {
        if (input.checked) {
            this.powershell_ids.add(input.value);

        } else {
            this.powershell_ids.delete(input.value);
        }
    }

    exportPowershell(): void {

        this.message.isModalPowershellCpu = false;

        if (this.powershell_ids.size > 0) {
            this.message.waiting = true;

            let vmids = "'" + Array.from(this.powershell_ids).join("','") + "'";
            let resource = PowershellResource.CPU;
            //call api
            this.report_svc.getPowershell(this.currentUser.login, this.message.currentFilter, resource, vmids)
                .subscribe(
                    res => {
                        this.message.waiting = false;

                        // Create temp link
                        let blob: Blob = new Blob([res], {
                            type: 'zip'
                        });
                        let fileName = 'scripts.zip';
                        let objectUrl: string = URL.createObjectURL(blob);

                        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
                        a.href = objectUrl;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();

                        document.body.removeChild(a);
                        URL.revokeObjectURL(objectUrl);
                    },
                    error => {
                        console.log(error);
                        this.message.waiting = false;
                        let error_header = "Sorry ...";
                        let error_body = "Error during powershell archive generation !";
                        this.displayInfo(error_header, error_body);
                    }
                );
        }
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
                this.dtTrigger2.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
                $('div.dt-buttons').appendTo('span.export');
                $("[id*='DataTables_Table_']").css({
                    'padding-top': '5px'
                });
                $("[name*='DataTables_Table_']").css({
                    'padding': '0px'
                });
            },
            language: {
                loadingRecords: "loading ...",
                zeroRecords: "no data",
                search: '',
                searchPlaceholder: " search a vm..."
            },
            dom: '<"top"B><"clear">frtlip',
            buttons: [{
                extend: 'csv',
                filename: 'reco-resizing-cpu',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'copy',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'print',
                className: 'btn btn-link btn-sm'
            }]
        };
        this.powershell_dtOptions = {
            dom: 'frtlip',
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            //destroy: true,
            order: [
                [1, 'asc']
            ],
            language: {
                loadingRecords: "loading ...",
                zeroRecords: "no data",
                search: '',
                searchPlaceholder: " search ..."
            },
        };
    }

    private buildData(): void {

        this.data_elements = [];
        for (var i = 0; i < this.data_model.length; i++) {
            if (this.data_model[i].cpu_gain > 0)
                this.data_elements.push(this.data_model[i]);
        }
        this.reloadTable();
    }
}
