<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="table"></cds-icon>
					&nbsp;Cluster list 
				</h5>
				<span class="export"></span>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  					<thead>
    						<tr>
      							<th>NAME</th>
							<!--<th>IDENTIFIER</th>-->
      							<th>HOST#</th>
      							<th>VM#</th>
      							<th>LOGICAL CORES</th>
      							<th>RAM CAPACITY (GB)</th>
      							<th>STORAGE CAPACITY (GB)</th>
      							<th>Distributed Resource scheduler</th>
      							<th>Distributed Power Management</th>
      							<th>High-Availability</th>
      							<th>VMware SAN</th>
						</tr>
  					</thead>
  					<tbody>
    						<tr *ngFor="let cluster of data_clusters" ngClass="addLink" (click)="callroute(cluster)">
							<td>{{cluster.name}}</td>
							<!--<td>{{cluster.uuid}}</td>-->
							<td>{{cluster.hosts}}</td>
							<td>{{cluster.vms}}</td>
							<td>{{cluster.cpucap}}</td>
							<td>{{math.round(cluster.ramcap/1024)}}</td>
							<td>{{math.round(cluster.stocap/1024)}}</td>
							<td *ngIf="cluster.drs; else disDRS">enabled</td>
							<ng-template #disDRS>
								<td>disabled</td>
							</ng-template>
							<td *ngIf="cluster.dpm; else disDPM">enabled</td>
							<ng-template #disDPM>
								<td>disabled</td>
							</ng-template>
							<td *ngIf="cluster.ha; else disHA">enabled</td>
							<ng-template #disHA>
								<td>disabled</td>
							</ng-template>
							<td *ngIf="cluster.vsan; else disVSAN">enabled</td>
							<ng-template #disVSAN>
								<td>disabled</td>
							</ng-template>
						</tr>
  					</tbody>
  				</table>
			</div>
		</div>
	</div>
</div>
