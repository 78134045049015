import {
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    DataService,
    GreenitService,
    ShareService
} from '@app/services';

import {
    FilterMgt,
    Message
} from '@app/model';

import * as Highcharts from 'highcharts';

import heatmap from 'highcharts/modules/heatmap';
heatmap(Highcharts);

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';


@Component({
    selector: 'app-weeklycal',
    templateUrl: './weeklycal.component.html',
    styleUrls: ['./weeklycal.component.css']
})
export class WeeklycalComponent implements OnInit {

    message: Message;

    weekly_range_date: string = '';

    isNoPrevData: boolean = false;

    isNoNextData: boolean = true;

    isMinMaxValue: boolean = true;

    maxEndTime: number = 0;

    data_cache: any;

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    isReady: boolean = false;


    constructor(
        private counter_svc: DataService,
        private greenit_svc: GreenitService,
        private message_svc: ShareService) {}

    ngOnInit(): void {

        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    ngAfterViewInit(): void {

        this.maxEndTime = this.message.maxTimeFilter / 1000;
        this.callWeeklyApi();
    }

    previous(): void {

        var weekLastDay = null;
        weekLastDay = this.maxEndTime - 1;
        const lastDayPreviousWeek = moment.unix(weekLastDay).subtract(1, 'weeks').endOf('week');
        // CONVERT STRING TO NUMBER
        var y: number = +(lastDayPreviousWeek.valueOf() / 1000).toFixed();
        this.maxEndTime = y;
        this.callWeeklyApi();
        this.isNoNextData = false;
    }

    next(): void {

        var weekLastDay = null;
        weekLastDay = this.maxEndTime - 1;
        const lastDayNextWeek = moment.unix(weekLastDay).add(1, 'weeks').endOf('week');
        var y: number = +(lastDayNextWeek.valueOf() / 1000).toFixed();
        this.maxEndTime = y;
        if (this.maxEndTime < (this.message.maxTimeFilter / 1000)) {
            this.callWeeklyApi();
        } else if (this.maxEndTime = (this.message.maxTimeFilter / 1000)) {
            this.callWeeklyApi();
            this.isNoNextData = true;
        } else {
            this.isNoNextData = true;
        }
        this.isNoPrevData = false;
    }

    updateWeeklyOptions(event): void {

        if (this.data_cache.length > 0) {
            if (event.target.id == "weekly-radio-100")
                this.loadCalendar(this.data_cache, false);
            else
                this.loadCalendar(this.data_cache, true);
        }
    }

    private callWeeklyApi(): void {

        this.message.waiting = true;
        var weekLastDay = null;
        weekLastDay = this.maxEndTime - 1;
        const lastDay = moment.unix(weekLastDay).add(1, 'seconds');
        const firstDay = moment.unix(weekLastDay).startOf('week');
        let start: number = 0;
        let end: number = 0;

        if (firstDay.valueOf() < this.message.minTimeFilter) {
            start = this.message.minTimeFilter / 1000;
            this.isNoPrevData = true;
        } else {
            start = firstDay.valueOf() / 1000
        }

        if (lastDay.valueOf() > this.message.maxTimeFilter)
            end = this.message.maxTimeFilter / 1000;
        else
            end = lastDay.valueOf() / 1000;

        if (start > 0 && end > 0) {
            if (this.message.powerUsageEnv == "vmware") {
                let filter: FilterMgt = this.getFilter(this.message.currentFilter);
                let wd: boolean = false;
                if (filter.work_days == 1)
                    wd = true;

                if(this.message.calcons_counter == "threshold") {
                    let th: number = +this.message.calcons_rollup;
                    this.counter_svc.getDataThreshold(
                        this.message.currentUuid,
                        th,
                        firstDay.valueOf() / 1000,
                        lastDay.valueOf() / 1000,
                    ).pipe(first()).subscribe(
                        data => {
                            //console.log(data);
                            this.setRangeDate();
                            this.data_cache = data;
                            this.loadCalendar(data, false);
                            this.message.waiting = false;
                        },
                        error => {
                            console.log(error);
                            this.message.waiting = false;
                        }
                    );
                } else {
                    this.counter_svc.getData(
                        this.message.currentUuid,
                        firstDay.valueOf() / 1000,
                        lastDay.valueOf() / 1000,
                        this.message.calcons_counter,
                        this.message.calcons_rollup,
                        this.message.currentType,
                        wd).pipe(first()).subscribe(
                        data => {
                            this.setRangeDate();
                            this.data_cache = data;
                            this.loadCalendar(data, this.isMinMaxValue);
                            this.message.waiting = false;
                        },
                        error => {
                            console.log(error);
                            this.message.waiting = false;
                        }
                    );
                }
            } else {
                let elements: any = [];
                let element: any = {
                    uuid: this.message.currentUuid,
                    type: this.message.currentType
                };
                elements.push(element);

                let powerInfo: any = {
                    start: firstDay.valueOf(),
                    end: lastDay.valueOf(),
                    granularity: 'HOURLY',
                    data: elements
                };

                let query: any = '';
                switch (this.message.powerUsageEnv) {
                    case "kubernetes":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpowerkubenode');
                        break;
                    case "xclarity":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpowerxclarity');
                        break;
                    case "openmanage":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpoweropenmanagenode');
                        break;
                    case "oneview":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpoweroneviewnode');
                        break;
                    case "ipmi":
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpoweripminode');
                        break;
                    case "aws":
                    case "azure":
                    case "gcp":
                        powerInfo.account = this.message.powerUsageEnv;
                        let uuids: string[] = [];
                        uuids.push(this.message.currentUuid);
                        powerInfo.data = uuids;
                        query = this.greenit_svc.getPowerNode(powerInfo, 'getpowerregion');
                        break;
                    default:
                        break;
                }

                if (query != "") {
                    query.subscribe(
                        data => {
                            let values: any = [];
                            for (let i in data) {
                                let val: any = {
                                    time: data[i].TIMEAGO / 1000,
                                    avg_power_float: data[i].POWER
                                };
                                values.push(val);
                            }
                            this.setRangeDate();
                            this.data_cache = values;
                            this.loadCalendar(values, this.isMinMaxValue);
                            this.message.waiting = false;
                        },
                        error => {
                            this.message.waiting = false;
                            if (error != null)
                                console.log(error);
                        }
                    );
                }
            }
        }
    }

    private setRangeDate(): void {

        var weekLastDay = null;
        weekLastDay = this.maxEndTime - 1;
        const lastDay = moment.unix(weekLastDay).add(0, 'seconds')
        const firstDay = moment.unix(weekLastDay).startOf('week')

        let dateFormatPattern1 = 'MMMM Do'
        let dateFormatPattern2 = dateFormatPattern1 + ' YYYY'
        if (firstDay.year() != lastDay.year()) {
            dateFormatPattern1 += ' YYYY'
        }
        this.weekly_range_date = firstDay.format(dateFormatPattern1) + ' - ' + lastDay.format(dateFormatPattern2);
    }

    private loadCalendar(data, isMaxVal): void {

        let weekDays = [];
        var maxVal = 100;
        var endFilter = this.maxEndTime;

        var weekLastDay = null;
        weekLastDay = this.maxEndTime - 1;
        const lastDay = moment.unix(weekLastDay).add(1, 'seconds')
        const firstDay = moment.unix(weekLastDay).startOf('week')
        for (let d = moment(lastDay); d.diff(firstDay, 'days') > 0; d.subtract(1, 'days')) {
            let end = d.valueOf()
            var start = (end - 86400000);
            weekDays.unshift(moment(d).subtract(1, 'days'))
        }

        let cpucap: number = 0;
        if (this.message.currentType == "VM")
            cpucap = this.message.vmSynth.vcpu;
        else if (this.message.currentType == "SERVER")
            cpucap = this.message.hostSynth.cpucap;
        else if (this.message.currentType == "CLUSTER")
            cpucap = this.message.clusterSynth.cpucap;

        let unit: string = '%';

        var v = {}
        var tmp = 0;
        data.forEach(measurement => {
            let timestamp = parseInt(measurement.time);
            if (!v[timestamp]) {
                v[timestamp] = []
            }
            switch (this.message.calcons_counter) {
                case "cpu_usage":
                    switch (this.message.calcons_rollup) {
                        case "avg":
                            v[timestamp].push(measurement.avg_cpu_usage / 10);
                            break;
                        case "max":
                            v[timestamp].push(measurement.max_cpu_usage / 10);
                            break;
                        case "min":
                            v[timestamp].push(measurement.min_cpu_usage / 10);
                            break;
                        default:
                            break;
                    }
                    break;
                case "ram_usage":
                    switch (this.message.calcons_rollup) {
                        case "avg":
                            v[timestamp].push(measurement.avg_ram_usage / 10);
                            break;
                        case "max":
                            v[timestamp].push(measurement.max_ram_usage / 10);
                            break;
                        case "min":
                            v[timestamp].push(measurement.min_ram_usage / 10);
                            break;
                        default:
                            break;
                    }
                    break;
                case "cpu_ready":
                    switch (this.message.calcons_rollup) {
                        case "avg":
                            if (cpucap > 0)
                                v[timestamp].push(measurement.avg_cpu_ready / 200 / cpucap);
                            break;
                        case "max":
                            if (cpucap > 0)
                                v[timestamp].push(measurement.max_cpu_ready / 200 / cpucap);
                            break;
                        case "min":
                            if (cpucap > 0)
                                v[timestamp].push(measurement.min_cpu_ready / 200 / cpucap);
                            break;
                        default:
                            break;
                    }
                    break;
                case "power_float":
                    unit = 'W';
                    switch (this.message.calcons_rollup) {
                        case "avg":
                            v[timestamp].push(measurement.avg_power_float);
                            break;
                        default:
                            break;
                    }
                    break;
                case "threshold":
                    unit = 'pts';
                    v[timestamp].push(measurement.pts);
                    maxVal = Math.max(maxVal, measurement.pts);
                    break;
                default:
                    break;
            }
            if (isMaxVal) {
                switch (this.message.calcons_counter) {
                    case "cpu_usage":
                        switch (this.message.calcons_rollup) {
                            case "avg":
                                tmp = Math.max(tmp, measurement.avg_cpu_usage);
                                break;
                            case "max":
                                tmp = Math.max(tmp, measurement.max_cpu_usage);
                                break;
                            case "min":
                                tmp = Math.max(tmp, measurement.min_cpu_usage);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "ram_usage":
                        switch (this.message.calcons_rollup) {
                            case "avg":
                                tmp = Math.max(tmp, measurement.avg_ram_usage);
                                break;
                            case "max":
                                tmp = Math.max(tmp, measurement.max_ram_usage);
                                break;
                            case "min":
                                tmp = Math.max(tmp, measurement.min_ram_usage);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "cpu_ready":
                        switch (this.message.calcons_rollup) {
                            case "avg":
                                tmp = Math.max(tmp, measurement.avg_cpu_ready);
                                break;
                            case "max":
                                tmp = Math.max(tmp, measurement.max_cpu_ready);
                                break;
                            case "min":
                                tmp = Math.max(tmp, measurement.min_cpu_ready);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "power_float":
                        unit = 'W';
                        switch (this.message.calcons_rollup) {
                            case "avg":
                                tmp = Math.max(tmp, measurement.avg_power_float);
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
        });
        if (isMaxVal) {
            if (this.message.calcons_counter == "cpu_ready") {
                if (cpucap > 0)
                    maxVal = Math.round(tmp / 200 / cpucap);
            } else if (this.message.calcons_counter == "power_float") {
                maxVal = Math.round(tmp);
            } else {
                maxVal = Math.round(tmp / 10);
            }
        }

        let datas = [];
        Object.keys(v).forEach(k => {
            let avg = v[k].reduce((a, b) => a + b, 0) / v[k].length
            var y: number = +(k);
            let d = moment.unix(y)
            let hour = d.hour()
            let weekDay = d.day()
            if (hour == 0) {
                hour = 24;
                if (weekDay > 0)
                    weekDay = weekDay - 1;
            }
            // case of last day of week
            if (d.valueOf() / 1000 == endFilter && hour == 24) {
                //if(d.valueOf() == lastDay && hour == 24) {
                var z: number = +(k);
                let prev_d = moment.unix(z - 1)
                //let prev_d = moment.unix(k-1)
                weekDay = prev_d.day()
            }
            //console.log(weekDay + " :: " + hour + " :: " + avg);
            datas.push([weekDay, hour, avg])
        });

        let metric_str = this.message.calcons_counter;
        if (this.message.calcons_counter == "power_float")
            metric_str = 'power';

        this.chartOptions = {
            chart: {
                type: 'heatmap',
                marginTop: 40,
                marginBottom: 10,
                plotBorderWidth: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                categories: weekDays.map(wd => wd.format('ddd DD')),
                opposite: true,
                labels: {
                    enabled: true,
                    formatter: function() {
                        return String(this.value);
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'hour'
                },
                labels: {
                    enabled: true,
                    formatter: function() {
                        return '<span style="padding-top:35px">' + (this.value) + 'h00</span>';
                    }
                },
                minPadding: 0,
                maxPadding: 0,
                startOnTick: false,
                endOnTick: true,
                tickPositions: [1, 4, 8, 12, 16, 20, 24],
                tickWidth: 0.5,
                min: 1,
                max: 24,
                reversed: true
            },
            colorAxis: {
                min: 0,
                max: maxVal,
                gridLineWidth: 1,
                gridLineColor: 'white',
                stops: [
                    [0, '#3060cf'],
                    [0.5, '#fffbbc'],
                    [0.9, '#c4463a']
                ]
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 25,
                symbolHeight: 280
            },
            tooltip: {
                formatter: function() {
                    let text;
                    if (this.point.value) {
                        text = metric_str + ': ' + this.point.value.toLocaleString("fr-FR", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) + unit + ' [' + (this.point.y - 1) + '-' + this.point.y + 'h]';
                    } else {
                        text = 'No data available'
                    }
                    return text
                }
            },
            series: [{
                type: 'heatmap',
                name: 'Average Hourly Consumption',
                turboThreshold: 1000,
                borderWidth: 0.1,
                borderColor: 'black',
                data: datas,
                dataLabels: {
                    enabled: false,
                    color: 'grey',
                    formatter: function() {
                        return this.point.x + ' [' + (this.point.y - 1) + '-' + this.point.y + 'h]';
                    }
                }
            }]
        };
        this.isReady = true;
    }

    private getFilter(name: string): FilterMgt {
        return this.message.filterList.find(filter => filter.name === name);
    }
}
