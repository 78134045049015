import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faPlug
} from '@fortawesome/free-solid-svg-icons';

import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);

import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offline(Highcharts);

import {
    PowerGraphComponent
} from '@app/greenit/power/power-graph/power-graph.component';
import {
    PowerVmStackedComponent
} from '@app/greenit/power/power-vm-stacked/power-vm-stacked.component';

import {
    GreenitService
} from '@app/services';
import {
    InstanceType
} from '@app/model';


@Component({
    selector: 'app-power-vmware',
    templateUrl: './power-vmware.component.html',
    styleUrls: ['./power-vmware.component.css']
})
export class PowerVmwareComponent implements OnInit {

    @ViewChild('powerGraph') powerGraph: PowerGraphComponent;
    @ViewChild('powerVmStacked') powerVmStacked: PowerVmStackedComponent;

    targetInfrastructure: string = InstanceType.VMWARE;

    listHosts: any[] = [];

    selectedElements: string[] = [];

    isAllDisable: boolean = false;
    isStackedDisable: boolean = false;

    isAll: boolean = false;
    isStacked: boolean = false;


    constructor(
        private library: FaIconLibrary,
        private greenit_svc: GreenitService) {
        this.library.addIcons(faPlug);
    }

    ngOnInit(): void {

        this.greenit_svc.getHostsListObservable('').subscribe(
            data => {
                if (data.length > 0) {
                    this.listHosts = data;
                    this.selectedElements.push(data[0]);
                    setTimeout(() => {
                        this.updateChart();
                    }, 100);
                }
            }
        );
    }

    reset(): void {
        this.isAll = false;
        this.isStacked = false;
        this.isAllDisable = false;
        this.isStackedDisable = false;

        setTimeout(() => {
            this.checkStackDisable();
            this.updateChart();
        }, 100);

    }

    updateChart(): void {
        if (this.powerGraph != undefined) {
            setTimeout(() => {
                this.powerGraph.updateChart();
            }, 100);
        }
    }

    /**
     * 
     */
    checkAll() {
        // Reset
        this.selectedElements = [];

        if (this.isAll) {
            // Add all hosts
            this.selectedElements.push.apply(this.selectedElements, this.listHosts);
            this.isStackedDisable = true;
        } else {
            this.isStackedDisable = false;
        }

        setTimeout(() => {
            this.updateChart();
        }, 100);
    }

    /**
     * 
     */
    stackVMS(): void {

        if (this.isStacked) {
            this.isAllDisable = true;
        } else {
            this.isAllDisable = false;
        }

        setTimeout(() => {
            this.updateChart();
        }, 100);
    }

    checkStackDisable(): void {
        let hasVM = this.selectedElements.filter((e: any) => e.resourceType == "VM").length > 0;

        if (this.isStackedDisable || this.selectedElements.length == 0 || this.selectedElements.length > 1 || hasVM)
            this.isStackedDisable = true;

    }
}
