<div class="main-container">

	<ng-template load-alerting-watcher></ng-template>

	<app-alertmenu *ngIf="message.isNotLoginPage"></app-alertmenu>

	<!-- HEADER MENU-->
	<header class="header-1" *ngIf="message.isNotLoginPage">
		<div class="branding">
			<a clrVerticalNavLink routerLink="./dashboard" routerLinkActive="active">
				<img src="assets/img/logo.png">
			</a>
		</div>
		<div style="padding-left:2rem;padding-top:1rem;">
    		<cds-icon shape="home"></cds-icon>
    		&nbsp;
    		<span class="label white-breadcrump">
				{{message.breadcrumpRoute}}&nbsp;
    		</span>
    		&nbsp;&nbsp;
    		<cds-icon shape="filter"></cds-icon>
    		&nbsp;
    		<span class="label white-breadcrump">
				{{message.currentFilter}}
			</span>
    		&nbsp;&nbsp;
			<cds-icon shape="target"></cds-icon>
    		&nbsp;
    		<span class="label white-breadcrump">
				{{message.elementView}}
			</span>
    		&nbsp;&nbsp;
    		<cds-icon shape="calendar"></cds-icon>
    		&nbsp;
    		<span class="label white-breadcrump">
				{{message.periodView}}
			</span>
		</div>
		<div style="padding-left:2rem;padding-top:1rem;">
			{{filter_dsp}}
			<div class="clr-select-wrapper">
				<select id="myfilter" class="clr-select" style="color:white;" [(ngModel)]="message.currentFilter" (ngModelChange)="switchFilter($event)">
					<option *ngFor="let filter of message.filterList" [ngValue]="filter.name">{{filter.name}}</option>
				</select>
			</div>
		</div>
		<div class="header-actions">
			<clr-dropdown>
				<button class="nav-icon" clrDropdownTrigger aria-label="toggle filter menu">
					<cds-icon shape="filter"></cds-icon>
					<cds-icon shape="angle" direction="down"></cds-icon>
				</button>
				<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
					<a clrDropdownItem routerLink="./periodview" routerLinkActive="active">
						<cds-icon shape="calendar"></cds-icon>
						Time view
					</a>
					<a clrDropdownItem routerLink="./selectionview" routerLinkActive="active">
						<cds-icon shape="target"></cds-icon>
						Selection view
					</a>
					<a clrDropdownItem routerLink="./filter" routerLinkActive="active">
						<cds-icon shape="filter"></cds-icon>
						Filter management
					</a>
				</clr-dropdown-menu>
			</clr-dropdown>

			<!-- SETTINGS DROPDOWN -->
			<clr-dropdown>
				<button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
					<cds-icon shape="cog"></cds-icon>
					<cds-icon shape="angle" direction="down"></cds-icon>
				</button>
				<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
					<!-- SETTINGS -->
					<a clrDropdownItem (click)="callSettings()">
						<cds-icon shape="cog"></cds-icon>
						&nbsp;Settings
					</a>
					<div class="dropdown-divider" role="separator"></div>
					<!-- GREENIT SETTINGS -->
					<a *ngIf="role.login == 'root' || !isProduction" clrDropdownItem (click)="callGreenitSettings()">
						<fa-icon [icon]="['fas', 'leaf']"></fa-icon>
						&nbsp;Green IT Settings
					</a>
					<div *ngIf="role.login == 'root' || !isProduction" class="dropdown-divider" role="separator"></div>
					<!-- <clr-dropdown>
                        <button clrDropdownTrigger>
                            <cds-icon shape="list"></cds-icon>
                            &nbsp;Documentation
                        </button>
                        <clr-dropdown-menu>
                            <button clrDropdownItem (click)="callDoc('fr')"><img src="../assets/img/fr.svg" height="11">&nbsp;&nbsp;Français</button>
                            <button clrDropdownItem (click)="callDoc('en')"><img src="../assets/img/en.svg" height="11">&nbsp;&nbsp;English</button>
                            <button clrDropdownItem (click)="callDoc('es')"><img src="../assets/img/es.svg" height="11">&nbsp;&nbsp;Español</button>
                        </clr-dropdown-menu>
                    </clr-dropdown> -->
                    <a clrDropdownItem (click)="callDoc('fr')">
                        <cds-icon shape="list"></cds-icon>
                        &nbsp;Documentation
                    </a>
					<!-- ABOUT -->
					<div class="dropdown-divider" role="separator"></div>
					<a clrDropdownItem (click)="callAbout()">
						<cds-icon shape="help-info"></cds-icon>
						&nbsp;About DCScope
					</a>
				</clr-dropdown-menu>
			</clr-dropdown>
			
			<!-- FLAG DROPDOWN -->
			<clr-dropdown>
				<button class="nav-icon" clrDropdownTrigger aria-label="toggle filter menu">
					<cds-icon [style.color]="updateFlagColor()" shape="flag"></cds-icon>
					<cds-icon shape="angle" direction="down"></cds-icon>
				</button>
				<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">  
                    <!-- LICENSE STATUS-->
					<clr-dropdown>
						<button clrDropdownTrigger>
							<cds-icon shape="key"></cds-icon>
							&nbsp;License status :&nbsp;
							<div *ngIf="license_info.alert == 2" style="background-image:radial-gradient(#FF0000,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
							<div *ngIf="license_info.alert == 1" style="background-image:radial-gradient(#FFD700,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
							<div *ngIf="license_info.alert == 0" style="background-image:radial-gradient(#00FF00,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</button>
						<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
							<div clrDropdownItem>
								<b>VM number :</b> {{license_info.vmclient}} / {{license_info.vmlicense == 'noli' ? 'No limit number' : license_info.vmlicense}}
								<br>
								<i>excluded from the license :</i> {{license_info.vmclientexcluded}}
								<br><br>
								<b>Expiration date :</b> {{formatDate(license_info.endlicense, false)}}
							</div>
						</clr-dropdown-menu>
					</clr-dropdown>
					<!-- VMWARE STATUS-->
					<clr-dropdown>
						<div *ngIf="instance_global_alert == 0" clrDropdownItem>
							<fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
							&nbsp;Monitoring heartbeat :&nbsp;
							<div style="background-image:radial-gradient(#00FF00,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</div>
						<button *ngIf="instance_global_alert > 0" clrDropdownTrigger>
							<fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
							&nbsp;Monitoring heartbeat :&nbsp;
							<div *ngIf="instance_global_alert == 2" style="background-image:radial-gradient(#FF0000,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
							<div *ngIf="instance_global_alert == 1" style="background-image:radial-gradient(#FFD700,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</button>
						<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
							<div *ngFor="let data of instance_details" clrDropdownItem>
								<p style="margin:0px;"><b>Date :</b> {{formatDate(data.date, true)}}</p>
								<p style="margin:0px;"><b>Name :</b> {{data.name}}</p>
								<p style="margin:0px;word-wrap:break-word;white-space:normal;"><b>Reason :</b> {{data.msg}}</p>
							</div>
							<div class="dropdown-divider" role="separator"></div>
						</clr-dropdown-menu>
					</clr-dropdown>
					<!-- DB STATUS-->
					<clr-dropdown>
						<div *ngIf="db_global_alert == 0" clrDropdownItem>
							<cds-icon shape="storage"></cds-icon>
							&nbsp;Database status :&nbsp;
							<div style="background-image:radial-gradient(#00FF00,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</div>
						<button *ngIf="db_global_alert > 0" clrDropdownTrigger>
							<cds-icon shape="storage"></cds-icon>
							&nbsp;Database status :&nbsp;
							<div *ngIf="db_global_alert == 2" style="background-image:radial-gradient(#FF0000,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
							<div *ngIf="db_global_alert == 1" style="background-image:radial-gradient(#FFD700,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</button>
						<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
							<div *ngFor="let data of db_details" clrDropdownItem>
								<p style="margin:0px;"><b>Date :</b> {{formatDate(data.date, true)}}</p>
								<p style="margin:0px;"><b>Name :</b> {{data.name}}</p>
								<p style="margin:0px;word-wrap:break-word;white-space:normal;"><b>Reason :</b> {{data.msg}}</p>
							</div>
							<div class="dropdown-divider" role="separator"></div>
						</clr-dropdown-menu>
					</clr-dropdown>
					<!-- ARCHIVE-->
					<div *ngIf="role.login == 'root' || !isProduction" class="dropdown-divider" role="separator"></div>
					<button *ngIf="role.login == 'root' || !isProduction" clrDropdownItem (click)="callArchive()">
						<cds-icon shape="download"></cds-icon>
						&nbsp;Get DCScope status archive
					</button>
				</clr-dropdown-menu>
			</clr-dropdown>
			
			<!-- USER DROPDOWN -->
			<clr-dropdown>
				<button class="nav-icon" clrDropdownTrigger aria-label="toggle user settings menu">
					<cds-icon shape="user"></cds-icon>
					<cds-icon shape="angle" direction="down"></cds-icon>
				</button>
				<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
					<span style="font-size:12px;padding-top:0;padding-bottom:0;"clrDropdownItem>
						<cds-icon shape="user"></cds-icon>
						<b>&nbsp;Connected as  {{role.alias}}@{{role.login}}</b>
					</span>
					<!-- COST -->
					<div *ngIf="role.login == 'root' || !isProduction" class="dropdown-divider" role="separator"></div>
					<a clrDropdownItem routerLink="./cost" routerLinkActive="active" *ngIf="role.login == 'root' || !isProduction">
						<cds-icon shape="dollar"></cds-icon>
						Cost management
					</a>
					<!-- USERS -->
					<div *ngIf="role.login == 'root' || !isProduction" class="dropdown-divider" role="separator"></div>
					<a clrDropdownItem routerLink="./user" routerLinkActive="active">
						<cds-icon shape="users"></cds-icon>
						User management
					</a>
					<!-- MONITOR MANAGEMENT -->
					<div *ngIf="role.login == 'root' || !isProduction" class="dropdown-divider" role="separator"></div>
					<button *ngIf="role.login == 'root' || !isProduction" clrDropdownItem (click)="callMonitorList()">
						<fa-icon [icon]="['fas', 'cogs']"></fa-icon>
						&nbsp;Monitoring management
					</button>
					<!-- PLUGIN-->
					<!--<div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
					<button *ngIf="role.login == 'root'" clrDropdownItem (click)="callPlugin()">
						<cds-icon shape="view-cards"></cds-icon>
						&nbsp;vSphere Client Plug-in
					</button>-->
					<!-- LICENSE-->
					<div *ngIf="role.login == 'root' || !isProduction" class="dropdown-divider" role="separator"></div>
					<button *ngIf="role.login == 'root' || !isProduction" clrDropdownItem (click)="callLicense()">
						<cds-icon shape="key"></cds-icon>
						&nbsp;License Key
					</button>
					<!-- UPLOAD MODAL-->
					<div *ngIf="role.login == 'root' || !isProduction" class="dropdown-divider" role="separator"></div>
					<button *ngIf="role.login == 'root' || !isProduction" clrDropdownItem (click)="callUpload()">
						<cds-icon shape="upload"></cds-icon>
						&nbsp;Upload DCScope update
					</button>
					<!-- LOGOUT -->
					<div class="dropdown-divider" role="separator"></div>
					<button clrDropdownItem  (click)="logout()">
						<cds-icon shape="logout"></cds-icon>
						&nbsp;Sign out
					</button>
				</clr-dropdown-menu>
			</clr-dropdown>
			
			<!-- ALERT DROPDOWN -->
			<clr-dropdown>
				<button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu" routerLink="./alerting">
					<cds-icon shape="bell"></cds-icon>
					<span class="badge badge-danger" style="position:absolute;">{{message.alertWatcher.all_cri}}</span>
				</button>
			</clr-dropdown>	
		</div>
	</header>

	<!-- VMWARE MENU-->
	<app-inframenu></app-inframenu>	

	<div class="content-container" style="position: relative;">
		<!-- VERTICAL MENU-->
		<app-dcscopemenu  *ngIf="message.isNotLoginPage"></app-dcscopemenu>
        <div class="content-area" style="position: relative;">
           	<router-outlet></router-outlet>
        </div>
	</div>
</div>

<!-- MODAL ABOUT -->
<clr-modal [(clrModalOpen)]="isModalAbout" [clrModalSize]="'xs'">
    <h3 class="modal-title">About DCScope</h3>
	<div class="modal-body">
		<table style="margin-right:auto; margin-left:auto;">
			<tr><td><b>version:</b></td><td style="padding-left:50px">{{jsonLoader.version.release}}</td></tr>
			<tr><td><b>build:</b></td><td style="padding-left:50px">{{jsonLoader.version.build}}</td></tr>
		</table>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isModalAbout = false">OK</button>
    </div>
</clr-modal>

<!-- MODAL PROGRESS -->
<clr-modal [(clrModalOpen)]="message.waiting" [clrModalClosable]=false [clrModalSize]="'l'">
    <h3 class="modal-title">Please wait</h3>
    <div class="modal-body" style="min-height:100px;">
        <span class="spinner" style="position:absolute;top:50%;right:45%;"></span>
    </div>
    <div class="modal-footer">
    </div>
</clr-modal>

<!-- MODAL DATALOCK -->
<clr-modal [(clrModalOpen)]="isModalDatalock" [clrModalSize]="'xs'">
    <h3 class="modal-title">Welcome to DC Scope</h3>
	<div class="modal-body">
		DC Scope is a tool for analyzing and managing your infrastructure.<br><br>
        To do this, it must have a minimum of data history :
        <ol>
            <li>wait until the <b>next hour</b> for the 'database connection' status to be updated (must change from orange to green),</li>
            <li>wait until <b>2 days after the installation date</b> for all features (especially for capacity planning)</li>
        </ol>
        <br><br>
        <b>Note : </b>This message will disappear once 2 days of data collected.
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isModalDatalock = false">OK</button>
    </div>
</clr-modal>

<!-- SETTINGS -->
<app-settings #settings (reload)="loadFilter($event)"></app-settings>

<!-- GREENIT SETTINGS -->
<app-greenitsettings #greenitsettings (reload)="loadFilter($event)"></app-greenitsettings>

<!-- LICENSE -->
<app-license #license [target]="modal"></app-license>

<!-- UPLOAD -->
<app-upload #upload></app-upload>

<!-- STATUS / MONITOR -->
<app-monitor #monitor (progress)="message.waiting=$event"></app-monitor>

<!-- PLUGIN -->
<app-plugin #plugin></app-plugin>
