import {
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    GreenitTarget
} from '@app/greenit/greenit.enums';
import {
    GreenitcfComponent
} from '@app/greenit/greenitcf/greenitcf.component';

@Component({
    selector: 'app-t7',
    templateUrl: './t7.component.html',
    styleUrls: ['./t7.component.css']
})
export class T7Component implements OnInit {

    @Input() target: GreenitTarget;

    @ViewChild('greenitcf') greenitcf: GreenitcfComponent;
    getGreenitcf() {
        return this.greenitcf;
    }

    isReady: boolean = false;

    cssClass: string = "card mymediumcard";

    /**
     * 
     */
    constructor() {
        if (this.target == undefined)
            this.target = GreenitTarget.DC_HOST_CO2;
    }

    /**
     * 
     */
    ngOnInit(): void {
        if (this.target == GreenitTarget.DC_HOST_CO2) {
            this.cssClass = "card myminicard";
        } else {
            this.cssClass = "card mymediumcard";
        }
        this.isReady = true;
    }
}
