<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="slider"></cds-icon>
			&nbsp;How many virtual machines can be created
		</h5>
	</div>
	<div class="card-block" id="dashboard-t5-core">
		<div class="card-text">
			<div *ngIf="isReady">
				<highcharts-chart id="t5-graph"
					[Highcharts]="highcharts"
					[options]="chartOptions"
					[constructorType]="'chart'"
					style="width: 100%; height: 250px; display: block;" *ngIf="isReady">
				</highcharts-chart>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
        </div>
	</div>
</div>