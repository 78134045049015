<div *ngIf="tab==instancetype.VMWARE">
    <app-recommendation></app-recommendation>
</div>
<div *ngIf="tab==instancetype.AWS">
    <app-opportunities-cloud [isreload]="isDataReload" *ngIf="isReady == true"></app-opportunities-cloud>
    <div *ngIf="!this.message.isValidAws">
        <div class="card">
            <div class="card-block">
                <div class="card-text">
                    <div>
                        <strong class="center">No data available !</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="tab==instancetype.AZURE">
    <app-opportunities-cloud [isreload]="isDataReload" *ngIf="isReady == true"></app-opportunities-cloud>
    <div *ngIf="!this.message.isValidAzure">
        <div class="card">
            <div class="card-block">
                <div class="card-text">
                    <div><strong class="center">No data available !</strong></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="tab==instancetype.GCP">
    <app-opportunities-cloud [isreload]="isDataReload" *ngIf="isReady == true"></app-opportunities-cloud>
    <div *ngIf="!this.message.isValidGcp">
        <div class="card">
            <div class="card-block">
                <div class="card-text">
                    <div><strong class="center">No data available !</strong></div>
                </div>
            </div>
        </div>
    </div>
</div>
