<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon [attr.shape]="badge_dist.face" [ngClass]="badge_dist.faceClass"></cds-icon>
			{{title}}
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div id="{{dis_id}}" (click)="zoom(cmpt_ref)" class="addLink"></div>
                </div>
	</div>
</div>
<clr-modal [(clrModalOpen)]="isModalDistribution" [clrModalSize]="'lg'">
	<h3 class="modal-title">{{titlezoom}} {{message.currentName}} cluster</h3>
	<div class="modal-body">
		<div id="cluster-distribution-graph-zoom"></div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="isModalDistribution = false">OK</button>
	</div>
</clr-modal>
