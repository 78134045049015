<div class="clr-row">
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <app-t7 #t7 [target]="t1_target"></app-t7>
    </div>
     <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <app-t8 #t8 [target]="t2_target"></app-t8>
    </div>
</div>
<!-- <div class="clr-row">
     <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <app-t9 #t9 [target]="t3_target"></app-t9>
    </div>
</div> -->