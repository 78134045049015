<div class="clr-row">
	<div class="clr-col-xl-4 clr-col-lg-6 clr-col-12">
		<div class="card" style="min-height:465px">
			<div class="card-header">
				<div class="clr-row clr-justify-content-between">
					<div class="clr-col-xl-4 clr-col-12">
						<span style="color:grey;font-size:26px;">Operation</span>
					</div>
					<div class="clr-col-xl-auto">
						<span class="label" style="float:right;">
							Other
							<span class="badge">{{reco_meta_data.vms-(reco_meta_data.running+reco_meta_data.off)}}</span>
						</span>
						<span class="label" style="float:right;">
							Off
							<span class="badge">{{reco_meta_data.off}}</span>
						</span>
						<span class="label" style="float:right;">
							Running
							<span class="badge">{{reco_meta_data.running}}</span>
						</span>
					</div>
				</div>
			</div>
			<div class="card-block" style="min-height:352px">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="card">
							<div class="card-block">
								<div class="clr-row">
									<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
										<span style="color:#FC0000;">
											<cds-icon shape="vm"></cds-icon>
										</span>
									</div>
									<div class="clr-col-lg-10 clr-col-md-10 clr-col-12">
										<span style="font-size:24px">{{reco_meta_data.idle}} VMs</span>
										<br>
										Idle
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="card">
							<div class="card-block">
								<div class="clr-row">
									<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
										<span style="color:#FF5B2B;">
											<cds-icon shape="vm"></cds-icon>
										</span>
									</div>
									<div class="clr-col-lg-10 clr-col-md-10 clr-col-12">
										<span style="font-size:24px">{{reco_meta_data.lazy}} VMs</span>
										<br>
										Lazy
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="card">
							<div class="card-block">
								<div class="clr-row">
									<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
										<span style="color:#F8E300;">
											<cds-icon shape="vm"></cds-icon>
										</span>
									</div>
									<div class="clr-col-lg-10 clr-col-md-10 clr-col-12">
										<span style="font-size:24px">{{reco_meta_data.oversized}} VMs</span>
										<br>
										Oversized
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="card">
							<div class="card-block">
								<div class="clr-row">
									<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
										<span style="color:#000000;">
											<cds-icon shape="vm"></cds-icon>
										</span>
									</div>
									<div class="clr-col-lg-10 clr-col-md-10 clr-col-12">
										<span style="font-size:24px">{{reco_meta_data.busy+reco_meta_data.undersized}} VMs</span>
										<br>
										Undersized
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="card">
							<div class="card-block">
								<div class="clr-row">
									<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
										<span style="color:#696969;">
											<cds-icon shape="tools"></cds-icon>
										</span>
									</div>
									<div class="clr-col-lg-10 clr-col-md-10 clr-col-12">
										<span style="font-size:24px">{{reco_meta_data.tools}}</span>
										<br>
										Tools
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="card">
							<div class="card-block">
								<div class="clr-row">
									<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
										<span style="color:blueviolet;">
											<cds-icon shape="vm"></cds-icon>
										</span>
									</div>
									<div class="clr-col-lg-10 clr-col-md-10 clr-col-12">
										<span style="font-size:24px">{{reco_meta_data.zombie}}</span>
										<br>
										Zombies
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<button type="button" class="btn btn-primary" aria-label="settings" (click)="loadOperation()">
							MORE...
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="clr-col-xl-4 clr-col-lg-6 clr-col-12">
		<div class="card" style="min-height:465px">
			<div class="card-header">
				<div class="clr-row clr-justify-content-between">
					<div class="clr-col-xl-4 clr-col-12">
						<span style="color:grey;font-size:26px;">Resizing</span>
					</div>
					<div class="clr-col-xl-auto">
						<span class="label">
							from new predictions (kg CO2eq)
							<span class="badge">{{greenit_prediction_co2}}</span>
							<span>
								<clr-signpost style="margin: 0 -.7rem 0 .5rem;" class="grey-signpost">
									<clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen class="info-signpost">
										<h5><b>Carbon emissions</b></h5>
										<p>If all resizing recommendations are applied, you will free up capacity on your servers. Thus, new saturation dates are predicted.</p>
										<p style="margin-top:10px">The value represented here corresponds to the estimate of embodied carbon emissions that could be saved between the current saturation date and the new predicted date.</p>
										<p style="margin-top:10px"><cds-icon size="24" shape="help-info"></cds-icon><b>The objective is to highlight the carbon emissions that can be avoided by resizing (and therefore not purchasing new servers).</b></p>
									</clr-signpost-content>
								</clr-signpost>
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="card-block" style="min-height:352px">
				<div class="card">
					<div class="card-block">
						<div class="clr-row">
							<div class="clr-col-6">
								<span style="font-size:22px">{{formatNumber(reco_meta_data.vcpu_saved)}} vCPU</span>
								<br>
								to reclaim
                			</div>
							<div class="clr-col-6 text-right">
								<span *ngIf="currentUser.isDashboardT6" style="font-size:22px;margin-right:0.8rem;">
									{{globalCurrency}} {{formatNumber(math.round(cost_vcpus))}}
								</span>
								<br>
								<span style="font-size:20px">
									<fa-icon [icon]="['fas', 'leaf']" style="font-size:0.75em;"></fa-icon>
									{{greenit_vcpu_co2}} <span style="font-size:12px;"> kg CO2eq</span>
									
									<clr-signpost class="grey-signpost">
										<clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen class="info-signpost">
											<h5><b>Carbon emissions</b></h5>
											<p>Embodied carbon emissions of a server can be shared between all VM hosted on it. So, an estimation per vCPU can be done.</p>
											<p style="margin-top:10px">The value represented here is the estimate of embodied carbon emissions computed from vCPU resizing.</p>
											<p style="margin-top:10px"><cds-icon size="24" shape="help-info"></cds-icon><b>The objective is to highlight the carbon emissions that can be avoided by resizing (and therefore not purchasing new servers).</b></p>
											<p style="margin-top:10px">Also, that's as much carbon emissions as :</p>
											<p style="width:100%;"><fa-icon [icon]="['fas', 'plane']" style="font-size:1.9em;margin-right:25px"></fa-icon>{{formatNumber(math.round(greenit_vcpu_co2 * greenit_plane_ratio))}} km by plane</p>
											<p style="width:100%;"><fa-icon [icon]="['fas', 'train']" style="font-size:1.9em;margin-right:25px"></fa-icon>{{formatNumber(math.round(greenit_vcpu_co2 * greeeit_train_ratio))}} km by train</p>
											<p style="width:100%;"><fa-icon [icon]="['fas', 'faucet']" style="font-size:1.9em;margin-right:25px"></fa-icon>{{formatNumber(math.round(greenit_vcpu_co2 * greenit_water_ratio))}} liters of water</p>
										</clr-signpost-content>
									</clr-signpost>

								</span>
                			</div>
                		</div>
						<div [ngClass]="resizing_cpu_class">
							<progress value="{{reco_meta_data.vcpu_save_per}}" max="100"></progress>
                		</div>
            		</div>
        		</div>
				<div class="card">
					<div class="card-block">
						<div class="clr-row">
							<div class="clr-col-6">
								<span style="font-size:22px">{{formatNumber(reco_meta_data.ram_saved)}} GB RAM</span>
								<br>
								to reclaim
                			</div>
							<div class="clr-col-6 text-right">
								<span *ngIf="currentUser.isDashboardT6" style="font-size:22px;margin-right:0.8rem;">
									{{globalCurrency}} {{formatNumber(math.round(cost_vrams))}}
								</span>
								<br>
								<span style="font-size:20px">
									<fa-icon [icon]="['fas', 'leaf']" style="font-size:0.75em;"></fa-icon>
									{{greenit_ram_co2}} <span style="font-size:12px;"> kg CO2eq</span>

									<clr-signpost class="grey-signpost">
										<clr-signpost-content [clrPosition]="'left-middle'" *clrIfOpen class="info-signpost">
											<h5><b>Carbon emissions</b></h5>
											<p>Embodied carbon emissions of a server can be shared between all VM hosted on it. So, an estimation per GB can be done.</p>
											<p style="margin-top:10px">The value represented here is the estimate of embodied carbon emissions computed from RAM resizing.</p>
											<p style="margin-top:10px"><cds-icon size="24" shape="help-info"></cds-icon><b>The objective is to highlight the carbon emissions that can be avoided by resizing (and therefore not purchasing new servers).</b></p>
											<p style="margin-top:10px">Also, that's as much carbon emissions as :</p>
											<p style="width:100%;"><fa-icon [icon]="['fas', 'plane']" style="font-size:1.9em;margin-right:25px"></fa-icon>{{formatNumber(math.round(greenit_ram_co2 * greenit_plane_ratio))}} km by plane</p>
											<p style="width:100%;"><fa-icon [icon]="['fas', 'train']" style="font-size:1.9em;margin-right:25px"></fa-icon>{{formatNumber(math.round(greenit_ram_co2 * greeeit_train_ratio))}} km by train</p>
											<p style="width:100%;"><fa-icon [icon]="['fas', 'faucet']" style="font-size:1.9em;margin-right:25px"></fa-icon>{{formatNumber(math.round(greenit_ram_co2 * greenit_water_ratio))}} liters of water</p>
										</clr-signpost-content>
									</clr-signpost>

								</span>
                			</div>
                		</div>
						<div [ngClass]="resizing_ram_class">
							<progress value="{{reco_meta_data.ram_save_per}}" max="100"></progress>
                		</div>
            		</div>
        		</div>
				<div class="card">
					<div class="card-block">
						<div class="clr-row">
							<div class="clr-col-6">
								<span style="font-size:22px">{{formatNumber(reco_meta_data.disk_saved)}} GB DISK</span>
								<br>
								to reclaim
                					</div>
							<div class="clr-col-6 text-right" *ngIf="currentUser.isDashboardT6">
								<span style="font-size:22px;margin-right:0.8rem;">
									{{globalCurrency}} {{formatNumber(math.round(cost_vdisks))}}
								</span>
                					</div>
                				</div>
						<div [ngClass]="resizing_disk_class">
							<progress value="{{reco_meta_data.disk_save_per}}" max="100"></progress>
                				</div>
            				</div>
        			</div>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-primary" aria-label="settings" (click)="loadResizing();message.isRecoResize = false;">
					MORE...
				</button>
			</div>
		</div>
	</div>
	<div class="clr-col-xl-4 clr-col-lg-6 clr-col-12">
		<div class="card" style="min-height:465px">
			<div class="card-header">
				<div class="clr-row clr-justify-content-between">
					<div class="clr-col-xl-6 clr-col-12">
						<span style="color:grey;font-size:26px;">Consolidation</span>
					</div>
					<div class="clr-col-xl-auto">
						<span class="label" style="float:right;">
							servers in your filters
							<span class="badge">{{reco_meta_data.servers}}</span>
						</span>
					</div>
				</div>
			</div>
			<div class="card-block" style="min-height:352px">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span style="font-size:18px;text-align:center;">An average server in your infrastructure</span>
				<div class="clr-col-12">&nbsp;</div>
				<div class="clr-row">
					<div class="clr-col-4">
						Hosts
						<br>
						<span style="font-size:24px">{{reco_meta_data.over_vm}} VMs</span>
						<br>
						running
					</div>
					<div class="clr-col-4">
						Commits
						<br>
						<span style="font-size:24px">{{reco_meta_data.over_vcpu}} vCPUs</span>
						<br>
						/logical core
					</div>
					<div class="clr-col-4">
						Commits
						<br>
						<span style="font-size:24px">{{reco_meta_data.over_vram}}%</span>
						<br>
						of RAM
					</div>
				</div>
				<div class="clr-col-12">&nbsp;</div>
				<div class="clr-row">
					<div class="clr-col-6">
						<div class="card">
							<div class="card-block">
								Average
								<br>
								<span style="font-size:24px">{{reco_meta_data.over_cpu_cons}}%</span>
								<br>
								CPU consumption
								<div [ngClass]="consolidation_cpu_class">
									<progress value="{{reco_meta_data.over_cpu_cons}}" max="100"></progress>
                						</div>
                					</div>
                				</div>
            				</div>
					<div class="clr-col-6">
						<div class="card">
							<div class="card-block">
								Average
								<br>
								<span style="font-size:24px">{{reco_meta_data.over_ram_cons}}%</span>
								<br>
								RAM consumption
								<div [ngClass]="consolidation_ram_class">
									<progress value="{{reco_meta_data.over_ram_cons}}" max="100"></progress>
                						</div>
                					</div>
                				</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-primary" aria-label="settings" (click)="loadConsolidation();message.isRecoConsolidation = false;">
					MORE...
				</button>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div *ngIf="isOperation">
			<ng-template load-recommendation-operation></ng-template>
		</div>
		<div *ngIf="isResizing">
			<div class="card">
				<div class="card-header">
					<div class="clr-row">
						<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
							<span style="color:grey;font-size:24px;">{{resize_title}}</span>
						</div>
						<div class="clr-col-lg-8 clr-col-md-8 clr-col-12" style="padding-bottom:10px;">
							<button type="button" class="btn btn-info-outline" style="float:right" aria-label="settings" (click)="loadResizingDetails(true)" *ngIf="!message.isRecoResize">
								DETAILS VIEW
							</button>
							<button type="button" class="btn btn-info-outline" style="float:right" aria-label="settings" (click)="loadResizingDetails(false)" *ngIf="message.isRecoResize">
								TOP 10 VIEW
							</button>
							<button type="button" class="btn btn-primary" style="float:right" (click)="loadPowerShell()" *ngIf="message.isRecoPowershell">
								EXPORT POWERSHELL
							</button>
						</div>
					</div>
					<span class="export"></span>
					<span class="search"></span>
				</div>
				<div class="card-block">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!message.isRecoResize">
						<ng-template load-recommendation-resizing></ng-template>
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="message.isRecoResize">
						<ng-template load-recommendation-resizing-detail></ng-template>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="isConsolidation">
			<div class="card">
				<div class="card-header">
					<div class="clr-row">
						<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
							<span style="color:grey;font-size:24px;">Consolidation</span>
						</div>
						<div class="clr-col-lg-8 clr-col-md-8 clr-col-12" style="padding-bottom:10px;">
							<button type="button" class="btn btn-info-outline" style="float:right" aria-label="settings" (click)="loadConsolidationDetails(true)" *ngIf="!message.isRecoConsolidation">
								DETAILS VIEW
							</button>
							<button type="button" class="btn btn-info-outline" style="float:right" aria-label="settings" (click)="loadConsolidationDetails(false)" *ngIf="message.isRecoConsolidation">
								CONSOLIDATION VIEW
							</button>
						</div>
					</div>
					<span class="search"></span>
				</div>
				<div class="card-block">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!message.isRecoConsolidation">
						<ng-template load-recommendation-consolidation></ng-template>
					</div>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="message.isRecoConsolidation">
						<ng-template load-recommendation-consolidation-detail></ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


