<div style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-flow: column;">

    <clr-alert [clrAlertType]="'danger'" [clrAlertAppLevel]="true" *ngIf="failureMode && !isLoading">
        <clr-alert-item>
            <span class="alert-text">Cannot load data from DC Netscope API. Please check the diagnostic page.</span>
            <div class="alert-actions">
                <a routerLink="/netscope/diagnostic" routerLinkActive="active">
                    <button class="btn alert-action">
                        <cds-icon shape="checkbox-list"></cds-icon>
                        Diagnostic
                    </button>
                </a>
            </div>
        </clr-alert-item>
    </clr-alert>

    <div class="clr-row" style="margin: 0px; background-color: rgb(0, 55, 76); color : white; overflow: visible; min-height: 74px;">
        <div class="clr-col" id="flow_view_title" style="flex-grow: 0; color: white; text-align: center;">
            <table style="height: 100%; min-width: 180px; font-weight: 400; font-size: .8rem; letter-spacing: .01em; text-decoration: none;">
                <tr>
                    <td>
                        <cds-icon shape="tags" style="margin-right: 10px; margin-bottom: 3px;" size="md"></cds-icon>
                    </td>
                    <td style="text-align: left;">{{ "dcnetscope.custom_protocols.name" | transloco }}</td>
                </tr>
            </table>
        </div>

        <!-- Empty column that separates title and group of buttons at the right -->
        <div class="clr-col" style="padding-top: 10px; padding-bottom: 5px;">
        </div>

        <div class="clr-col" style="width: 100px; flex-grow: 0; padding-left: 0px;">
            <table style="height: 100%;">
                <tr>
                    <td style="padding: 5px;">
                        <a href="javascript://" id="flow_view_reload_button" class="nav-link nav-icon" aria-label="reload">
                            <cds-icon shape="refresh" (click)="reloadData()" *ngIf="!isLoading" style="color: white;"></cds-icon>
                            <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <a href="javascript://" class="nav-link nav-icon" aria-label="tour">
                            <!--                            <cds-icon shape="help" style="color: white;" (click)="launchTour()"></cds-icon>-->
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <clr-dropdown [clrCloseMenuOnItemClick]="true">
                            <a clrDropdownTrigger href="javascript://" class="nav-link nav-icon" aria-label="settings">
                                <cds-icon shape="cog" style="color: white;"></cds-icon>
                            </a>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Language</label>
                                <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="setLanguage('french')">French</div>
                                <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="setLanguage('english')">English</div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </table>
        </div>
    </div>

<!--    <div class="clr-row" style="position: relative; height: 100%; bottom: 0px; width: 100%; margin: 0px;">-->
<!--    </div>-->

<!--    <div style="width: 100%; height: 50px;">-->
<!--        &lt;!&ndash; Prevent the following content to be overlap by the top bar &ndash;&gt;-->
<!--    </div>-->

    <div class="clr-row" style="margin: 0px;">
        <div class="clr-col-8">
            <h3 style="margin: 5px;">
                <button (click)="addNewProtocol()" class="btn btn-success btn-sm btn-icon" style="z-index: 1; margin-left: 15px;">
                    + add new protocol
                </button>

                <span *ngIf="isLoading" style="margin: 0px;">
                <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
            </span>
            </h3>
        </div>
        <div class="clr-col-4">
    <span *ngIf="!isLoading" style="float: right;">
        <button (click)="reloadData()" class="btn btn-primary btn-sm" style="z-index: 1; float: right;">
            <cds-icon shape="download"></cds-icon>
            pull from server
        </button>
        <button class="btn btn-outline btn-sm" style="z-index: 1; float: right;" *ngIf="isPushingRules">
            <clr-spinner clrInline>
            </clr-spinner>
            pushing
        </button>
        <button (click)="pushData()" class="btn btn-success btn-sm" style="z-index: 1; float: right;" *ngIf="!isPushingRules">
            <cds-icon shape="upload"></cds-icon>
            push to server
        </button>
    </span>
        </div>
    </div>

    <div class="clr-row"  style="margin: 0px; padding: 10px;">
        <div class="card" *ngFor="let rule of rules" style="margin-top: 0px; margin-bottom: 20px;">
            <div class="card-header">
                <span *ngIf="!rule.showEditingFeatures">{{rule.protocol}}</span>
                <span *ngIf="rule.showEditingFeatures">
                <input type="text" [(ngModel)]="rule.protocol" (change)="updateProtocolName(rule)"/>
            </span>
                <cds-icon style="color: green;" shape="check-circle" *ngIf="rule.checkMade && rule.nameIsValid"></cds-icon>
                <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg tooltip-right" *ngIf="rule.checkMade && !rule.nameIsValid">
                    <cds-icon style="color: red;" shape="error-standard" ></cds-icon>
                    <span class="tooltip-content">
                    The name is invalid. Please use the following chars:
                    <ul>
                        <li>Lowercase characters [a-z]</li>
                        <li>Uppercase characters [A-Z]</li>
                        <li>Numbers [0-9]</li>
                        <li>Underscode [_]</li>
                    </ul>
                </span>
                </a>

                <span style="margin-left: 5px; float: right; cursor: pointer;">
                <span class="label label-warning" (click)="checkRule(rule)">check</span>
                <span class="label label-blue" *ngIf="!rule.showEditingFeatures" style="cursor: pointer;" (click)="startEditing(rule)">edit</span>
                <span class="label label-blue" *ngIf="rule.showEditingFeatures" (click)="stopEditing(rule)"
                      style="cursor: pointer;">save</span>
                <span class="label label-danger" (click)="deleteProtocol(rule)">delete</span>
            </span>
            </div>
            <div class="card-block">
                <div class="card-text">
                    <div *ngIf="rule.criterion === undefined">
                        <div class="alert alert-warning alert-sm" role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                    </div>
                                    <div class="alert-text">
                                        This custom protocol has no associated rules.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <clr-dropdown *ngIf="rule.showEditingFeatures" [clrCloseMenuOnItemClick]="false">
                            <button class="btn btn-sm" clrDropdownTrigger aria-label="Dropdown demo button" style="width: 100%;">
                                click to add criterion
                            </button>
                            <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Add a criteria</label>
                                <div aria-label="Dropdown add composite criteria" (click)="addCompositeCriteria(rule, undefined)"
                                     clrDropdownItem>add a composite criteria
                                </div>
                                <div aria-label="Dropdown add composite criteria" (click)="addNotCriteria(rule, undefined)"
                                     clrDropdownItem>add a 'not' criteria
                                </div>
                                <div aria-label="Dropdown add simple criteria" (click)="addSimpleCriteria(rule, undefined)"
                                     clrDropdownItem>add a simple criteria
                                </div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </div>
                    <clr-tree *ngIf="rule.criterion !== undefined">
                        <clr-tree-node *clrRecursiveFor="let file of rule.criterion; getChildren: getChildren"
                                       [clrExpanded]="true">
                            <span *ngIf="!file.editing">
                                <span *ngIf="file.rightObject === undefined">{{file.name}}</span>
                                <span *ngIf="file.rightObject !== undefined">{{file.leftLabel}} {{file.operator}}
                                    <span class="badge badge-blue"
                                          *ngIf="file.rightObject.type === 'vm'">{{file.rightObject.name}}</span>
                                    <span class="badge"
                                          *ngIf="file.rightObject.type === 'server'">{{file.rightObject.name}}</span>
                                </span>

                                <cds-icon style="color: green; margin-bottom: 3px;" shape="check-circle" *ngIf="rule.checkMade && file.errors.length == 0"></cds-icon>
                                <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg" *ngIf="rule.checkMade && file.errors.length > 0">
                                    <cds-icon style="color: red;" shape="error-standard" ></cds-icon>
                                    <span class="tooltip-content">
                                        <ul>
                                            <li *ngFor="let error of file.errors">{{error}}</li>
                                        </ul>
                                    </span>
                                </a>

                                <span *ngIf="rule.showEditingFeatures">

                                    <cds-icon shape="pencil" (click)="file.basic = true" style="cursor: pointer; margin-left: 5px;"></cds-icon>
                                    <clr-modal [(clrModalOpen)]="file.basic" [clrModalSize]="'lg'">
                                        <h3 class="modal-title">Edit criterion</h3>
                                        <div class="modal-body" style="width: 100%;">
                                            <ng-container *ngTemplateOutlet="FileSignpostContentTemplate; context: {file: file}"></ng-container>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-success" (click)="processEditing(file); file.basic = false;">Save</button>
                                            <button type="button" class="btn btn-outline" (click)="file.basic = false">Close</button>
                                        </div>
                                    </clr-modal>

                                    <clr-dropdown *ngIf="file.type === 'nAryOperator' || file.type === 'unaryOperator'">
                                        <button class="dropdown-toggle" clrDropdownTrigger>
                                            <cds-icon shape="tree-view" size="16" style="color: #666;"></cds-icon>
                                        </button>
                                        <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                                            <label class="dropdown-header" aria-hidden="true">Add a criteria</label>
                                            <div aria-label="Dropdown add composite criteria" (click)="addCompositeCriteria(rule, file)" clrDropdownItem>
                                                add a composite criteria
                                            </div>
                                            <div aria-label="Dropdown add composite criteria" (click)="addNotCriteria(rule, file)"
                                                 clrDropdownItem>add a 'not' criteria
                                            </div>
                                            <div aria-label="Dropdown add simple criteria" (click)="addSimpleCriteria(rule, file)" clrDropdownItem>
                                                add a simple criteria
                                            </div>
                                        </clr-dropdown-menu>
                                    </clr-dropdown>

                                    <cds-icon (click)="deleteRule(file)" shape="trash" style="cursor: pointer;"></cds-icon>
                                </span>
                            </span>
                        </clr-tree-node>
                    </clr-tree>
                </div>
            </div>
        </div>
        <!--</div>-->

        <ng-template let-file="file" #FileSignpostContentTemplate>
    <span *ngIf="file.type == 'nAryOperator'">
        <div class="clr-select-wrapper">
            <select [(ngModel)]="file.operator" class="clr-select">
                <option *ngFor="let option of n_ary_operators" value="{{option}}"
                        [selected]="option == file.operator ? true : false">{{labels[option]}}</option>
            </select>
        </div>
    </span>
            <span *ngIf="file.type == 'unaryOperator'">
        <div class="clr-select-wrapper">
            <select [(ngModel)]="file.operator" class="clr-select">
                <option *ngFor="let option of unary_operators" value="{{option}}"
                        [selected]="option == file.operator ? true : false">{{labels[option]}}</option>
            </select>
        </div>
    </span>
            <span *ngIf="file.type == 'arithmeticOperator'" style="width: 100%;">
        <div class="clr-select-wrapper" style="margin-right: 5px; width: 39%;">
            <select [(ngModel)]="file.left" class="clr-select" style="line-height: 25px; height: 25px; width: 100%;">
                <option *ngFor="let option of targets" value="{{option}}"
                        [selected]="option == file.left ? true : false">{{labels[option]}}</option>
            </select>
        </div>
        <div class="clr-select-wrapper" style="margin-right: 5px; width: 19%;">
            <select [(ngModel)]="file.operator" class="clr-select" style="line-height: 25px; height: 25px; width: 100%;">
                <option *ngFor="let option of arithmetic_operators" value="{{option}}"
                        [selected]="option == file.operator ? true : false">{{option}}</option>
            </select>
        </div>
        <clr-combobox-container *ngIf="file.left === 'src.uuid' || file.left === 'dst.uuid'" style="line-height: 24px; display: inline; width: 39%;">
            <clr-combobox [ngModel]="file.rightObject" name="two" required placeholder="Select a computing resource" style="width: 100%;"
                          (clrSelectionChange)="keyboardSelect(file, $event)"
                          class="full-width-input">
                <ng-container *clrOptionSelected="let selected">
                    <cds-icon shape="house" role="img" aria-label="welcome home"></cds-icon>
                    {{selected}}
                </ng-container>
                <clr-options>
                    <clr-option
                            *clrOptionItems="let computingResource of computingResources; field:'name'"
                            [clrValue]="computingResource"
                            (click)="updateRightSelectedResource(file, computingResource)">
                        {{computingResource.name}}
                    </clr-option>
              </clr-options>
            </clr-combobox>
        </clr-combobox-container>
        <input type="text" class="clr-input" [(ngModel)]="file.right" style="visibility: {{ (file.left === 'src.uuid' || file.left === 'dst.uuid') ? 'hidden' : 'visible'}}; width: {{ (file.left === 'src.uuid' || file.left === 'dst.uuid') ? '0%' : '39%'}};">
    </span>

        </ng-template>
    </div>

</div>