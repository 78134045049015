<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<button class="btn btn-primary" style="float:left;" (click)="isNewTag = true;model.name = '';model.cost = 0">
			<cds-icon shape="plus-circle" class="is-solid"></cds-icon>
			NEW TAG
		</button>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
	<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
		<div class="card">
			<div class="card-block">
				<h5 class="card-title">Select a tag</h5>
				<div class="card-text">
					<div class="clr-row">
					<div class="clr-col-lg-7 clr-col-md-7 clr-col-12">
					<div class="clr-control-container">
						<div class="clr-select-wrapper">
							<select id="select-tag" class="clr-select" [(ngModel)]="model.tagSelected" (ngModelChange)="switchTag()">
								<option *ngFor="let tag of tags">{{tag.NAME}}</option>
							</select>
						</div>
						<span class="clr-subtext">the list of tags</span>
					</div>
					</div>
					<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
						<span>{{model.tagSelectedCost}} {{globalCurrency}}</span>
					</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="isEditTag = true">
					EDIT
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="isDeleteTag = true">
					DELETE
				</button>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-10 clr-col-md-10 clr-col-12">
		<div class="card-text">
			<table id="vm-tagcost-list" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  				<thead>
    					<tr>
      						<th></th>
      						<th>VM NAME</th>
      						<th>OPERATING SYSTEM</th>
      						<th>VCPU#</th>
						<th>RAM ALLOCATED (MB)</th>
						<th>DATACENTER</th>
						<th>CLUSTER</th>
						<th>SERVER</th>
					</tr>
  				</thead>
  				<tbody>
					<tr *ngFor="let vm of data_tbl" id="{{vm[1]}}">
						<td>
							<input type="checkbox" id="check-{{vm[1]}}" (click)="manageSelection($event)" [checked]="vm[8]">&nbsp;&nbsp;
							<cds-icon shape="view-list" id="list-{{vm[1]}}" (click)="getVmTags($event);isListTagVm = true" ngClass="addLink"></cds-icon>
						</td>
						<td>{{vm[0]}}</td>
						<td>{{vm[2]}}</td>
						<td>{{vm[3]}}vcpu</td>
						<td>{{vm[4]}}</td>
						<td>{{vm[5]}}</td>
						<td>{{vm[6]}}</td>
						<td>{{vm[7]}}</td>
					</tr>
  				</tbody>
  			</table>
		</div>
	</div>
</div>
<clr-modal [(clrModalOpen)]="isNewTag" [clrModalSize]="'xs'">
    <h3 class="modal-title">Create new tag</h3>
    <div class="modal-body">
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<label class="clr-control-label">Name</label>
						<div class="clr-control-container">
							<div class="clr-input-wrapper">
								<input type="text" class="clr-input" [(ngModel)]="model.name">
      							</div>
							<clr-control-helper>the name of the tag</clr-control-helper>
						</div>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<label class="clr-control-label">Cost ({{globalCurrency}})</label>
						<div class="clr-control-container">
							<div class="clr-input-wrapper">
								<input type="number" class="clr-input" [(ngModel)]="model.cost">
      							</div>
							<clr-control-helper>the cost assigned to the tag</clr-control-helper>
						</div>
					</div>
				</div>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isNewTag = false;createTag()">SAVE</button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="isDeleteTag" [clrModalClosable]=false>
    <h3 class="modal-title">Delete tag</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>This tag will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteTag = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="removeTag();isDeleteTag = false">Delete</button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="isEditTag" [clrModalSize]="'xs'">
    <h3 class="modal-title">Edit tag</h3>
    <div class="modal-body">
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<label class="clr-control-label">Name</label>
						<div class="clr-control-container">
							<div class="clr-input-wrapper">
								<input type="text" class="clr-input" [(ngModel)]="this.model.tagSelected">
      							</div>
							<clr-control-helper>the name of the tag</clr-control-helper>
						</div>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<label class="clr-control-label">Cost ({{globalCurrency}})</label>
						<div class="clr-control-container">
							<div class="clr-input-wrapper">
								<input type="number" class="clr-input" [(ngModel)]="this.model.tagSelectedCost">
      							</div>
							<clr-control-helper>the cost assigned to the tag</clr-control-helper>
						</div>
					</div>
				</div>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isEditTag = false;updateTag()">SAVE</button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="isListTagVm" [clrModalSize]="'xs'">
    <h3 class="modal-title">The list of tags for {{model.VmSelected}}</h3>
    <div class="modal-body">
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<table class="table table-compact">
  					<thead>
    						<tr>
      							<th>TAG</th>
							<th>COST {{globalCurrency}}</th>
						</tr>
  					</thead>
  					<tbody>
    						<tr>
						<tr *ngFor="let tag of tags_vm" id="{{tag.IDENTIFIER}}">
							<td (click)="removeTagForVm(tag)">
								{{tag.NAME}}
								<cds-icon shape="minus-circle" size="12" ngClass="addLink"></cds-icon>
							</td>
							<td>{{tag.COST}}</td>
						</tr>
  					</tbody>
  				</table>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isListTagVm = false">OK</button>
    </div>
</clr-modal>
