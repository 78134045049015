import {
    Component,
    ChangeDetectorRef,
    OnInit,
    HostListener
} from '@angular/core';
import {
    Router
} from '@angular/router';

import {
    AccountService,
    JsonloaderService,
    SettingsService,
    ShareService
} from '@app/services';

import {
    Json,
    Message,
    T1Vm
} from '@app/model';

import * as Highcharts from 'highcharts';

import map from 'highcharts/modules/map';
import * as $ from 'jquery';

map(Highcharts);
import xrange from 'highcharts/modules/xrange';

xrange(Highcharts);

@Component({
    selector: 'app-t1',
    templateUrl: './t1.component.html',
    styleUrls: ['./t1.component.css']
})
export class T1Component implements OnInit {

    message: Message;

    jsonLoader: Json;

    data_t1: any = [];

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    isReady: boolean = false;

    link_class: string = '';

    private currentUser;


    constructor(
        private router: Router,
        private json_svc: JsonloaderService,
        private authentication_svc: AccountService,
        private management_svc: SettingsService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        if (this.currentUser.isMenuGod)
            this.link_class = 'addLink';

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.currentJson.subscribe(
            json => {
                this.jsonLoader = json;
                if (this.jsonLoader.t1 != null) {
                    this.data_t1 = this.jsonLoader.t1;
                } else {
                    this.data_t1 = [];
                    this.isReady = false;
                }
            }
        );
    }

    ngAfterViewInit(): void {
        if (Object.keys(this.data_t1).length > 0)
            this.buildCard();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    resizeCard(): void {
        $('#dashboard-t1-core').slideToggle();
    }

    closeCard(): void {
        this.management_svc.updateSettings(this.currentUser.login, 'isT1', false).subscribe(
            error => {
                if (error != null)
                    console.log(error)
            }
        );
        $('#dashboard-t1').hide();
    }

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        let chartRef = chart;
        window.onresize = () => {
            if (chart.options != undefined)
                chartRef.reflow();
        };
    };

    buildCard(): void {
        let data = this.data_t1;

        let columnsCount = Math.max(Math.max(...data.map((d) => d[0])));
        let linesCount = Math.max(Math.max(...data.map((d) => d[1])));

        let t1Vm: T1Vm = {
            name: '',
            uuid: '',
            tag: '',
            cpu: 0,
            ram: 0,
        };
        this.chartOptions = {
            chart: {
                marginTop: 0,
                marginBottom: 0,
                plotBorderWidth: 0,
                plotBorderColor: '#ffffff',
                type: "mapchart"
            },
            title: {
                text: null
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            xAxis: {
                min: 0,
                max: columnsCount,
                categories: [],
                visible: false,
            },
            yAxis: {
                min: 0,
                max: linesCount,
                title: null,
                categories: [],
                visible: false,
                gridLineColor: 'transparent',
            },
            colorAxis: {
                dataClasses: [{
                    from: 0,
                    to: 10,
                    color: '#FC0000'
                }, {
                    from: 11,
                    to: 20,
                    color: '#FF5B2B'
                }, {
                    from: 21,
                    to: 30,
                    color: '#F8E300'
                }, {
                    from: 31,
                    to: 70,
                    color: '#96CA2D'
                }, {
                    from: 71,
                    to: 90,
                    color: '#696969'
                }, {
                    from: 91,
                    to: 100,
                    color: '#000000'
                }]
            },
            tooltip: {
                backgroundColor: '#FCFFC5',
                borderColor: 'black',
                borderRadius: 10,
                borderWidth: 0.5,
                useHTML: true,
                headerFormat: '',
                pointFormatter: function() {
                    var r = '';
                    r = '<hr>Name: <b>' + t1Vm.name + '</b><br><hr>Behavior: <b>' + t1Vm.tag +
                        '</b><br><hr>CPU: ' + t1Vm.cpu + '%<br><hr>RAM: ' + t1Vm.ram + '%';

                    return r;
                }.bind(this)
            },
            series: [{
                name: 'VM behavior',
                borderWidth: 0.5,
                borderColor: 'white',
                data: data,
                type: 'heatmap',
                point: {
                    events: {
                        click: function(e) {
                            const p = e.point;
                            this.callRoute(p);
                        }.bind(this),
                        mouseOver: function(e) {
                            const p = e.target;
                            t1Vm = this.getT1Vm(p.x, p.y);
                        }.bind(this)
                    }

                }
            }]
        };
        this.isReady = true;
    }

    private callRoute(p: any): void {

        const t1Vm: T1Vm = this.getT1Vm(p.x, p.y);

        this.message.currentUuid = t1Vm.uuid;
        this.message.currentName = t1Vm.name;
        this.message.currentType = 'VM';

        if (this.currentUser.isMenuGod)
            this.router.navigate([`/god/resource/${t1Vm.uuid}`]);
    }

    private getT1Vm(x: number, y: number): T1Vm {

        let t1Vm: T1Vm = {
            name: '',
            uuid: '',
            tag: '',
            cpu: 0,
            ram: 0,
        };
        for (var i = 0; i < this.data_t1.length; i++) {
            if (this.data_t1[i][0] == x && this.data_t1[i][1] == y) {
                t1Vm.name = this.data_t1[i][3];
                t1Vm.uuid = this.data_t1[i][4];
                t1Vm.tag = this.data_t1[i][5];
                t1Vm.cpu = this.data_t1[i][6];
                t1Vm.ram = this.data_t1[i][7];
            }
        }

        return t1Vm;
    }
}
