<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="user"></cds-icon>
			&nbsp;User management
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">

			<div class="clr-row">
				<div class="clr-col-lg-2 clr-col-md-4 clr-col-12">
					<button class="btn btn-primary" style="float:left;" (click)="initWizardAlias()">
						<cds-icon shape="plus-circle" class="is-solid"></cds-icon>
						CREATE USER
					</button>
				</div>
			</div>

			<div class="clr-row">
				<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">select a user</h5>
							<div class="card-text">
								<div class="clr-control-container">
									<div class="clr-select-wrapper">
										<select id="user-list" class="clr-select" [(ngModel)]="alias_selected" (ngModelChange)="isEditAlias=false">
											<option *ngFor="let alias of aliases" [ngValue]="alias">{{alias.login}}</option>
										</select>
									</div>
									<span class="clr-subtext">the list of users</span>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="editAlias()">
								Edit
							</button>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="isDeleteAlias = true" *ngIf="alias_selected.login != 'root' && alias_selected.login != currentUser.alias">
								Delete
							</button>
       					</div>
					</div>
				</div>
				<div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title"><cds-icon shape="user"></cds-icon>&nbsp;&nbsp;Information about {{alias_selected.login}}</h5>
							<div class="card-text">
								<clr-datagrid>
									<clr-dg-column>NAME</clr-dg-column>
									<clr-dg-column>GROUP</clr-dg-column>
									<clr-dg-column>LOCAL</clr-dg-column>
									<clr-dg-column>LDAP</clr-dg-column>				
									<clr-dg-row>
										<clr-dg-cell>{{alias_selected.login}}</clr-dg-cell>
										<clr-dg-cell>{{alias_selected.user}}</clr-dg-cell>
										<clr-dg-cell>{{alias_selected.is_local == 1 ? "true": "false"}}</clr-dg-cell>
										<clr-dg-cell>{{alias_selected.is_ldap == 1 ? "true": "false"}}</clr-dg-cell>
									</clr-dg-row>
								</clr-datagrid>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isEditAlias">
					<div class="card">
						<button type="button" class="close" aria-label="Close" (click)="isEditAlias=false">
							<cds-icon shape="window-close" size="24"></cds-icon>
						</button>
						<div class="card-block">
							<div class="clr-row">
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
									<h5 class="card-title">edit parameters for {{alias_selected.login}}</h5>
								</div>
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
									<button style="float: right;" type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateAlias()" *ngIf="isUpdateAlias">
										Update
									</button>
								</div>
							</div>
							<div class="card-text">
								<clr-timeline>
									<clr-timeline-step clrState="success">
										<clr-timeline-step-header>{{formatEditDate(this.selected_user.last_co)}}</clr-timeline-step-header>
    										<clr-timeline-step-title>CONNECTION or UPDATE</clr-timeline-step-title>
										<clr-timeline-step-description>Last connection date</clr-timeline-step-description>
  									</clr-timeline-step>
									<clr-timeline-step clrState="success">
										<clr-timeline-step-header>STEP 1</clr-timeline-step-header>
										<clr-timeline-step-title>NAME</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<div class="clr-input-container">
													<label>{{alias_selected.login}}</label>
												</div>
												<span class="clr-subtext">(cannot be changed)</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>
									<clr-timeline-step [clrState]="!isRootAlias ? 'current' : 'success'">
										<clr-timeline-step-header>STEP 2</clr-timeline-step-header>
										<clr-timeline-step-title>GROUP</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<div class="clr-select-wrapper">
													<select class="clr-select" [(ngModel)]="edit_alias_model.user" (ngModelChange)="enableAliasUpdate()" [disabled]="isRootAlias">
														<option *ngFor="let user of users" [ngValue]="user">{{user.login}}</option>
													</select>
												</div>
												<span class="clr-subtext">select the group</span>
												<span class="clr-subtext" *ngIf="isRootAlias">(cannot be changed for root)</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>
									<clr-timeline-step [clrState]="edit_alias_model.is_ldap == 0 ? 'current' : 'success'">
										<clr-timeline-step-header>STEP 3</clr-timeline-step-header>
										<clr-timeline-step-title>PASSWORD</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<div class="clr-input-container">
													<input type="password" class="clr-input" [(ngModel)]="edit_alias_model.password" disabled >
												</div>
											</div>
											<div>&nbsp;</div>
											<button class="btn btn-sm" (click)="isModalPassword = true;" [disabled]="edit_alias_model.is_ldap == 1">CHANGE</button>
											<span class="clr-subtext" *ngIf="edit_alias_model.is_ldap == 1">(cannot be changed)</span>
										</clr-timeline-step-description>
									</clr-timeline-step>
									<clr-timeline-step clrState="{{update_state}}">
										<clr-timeline-step-header>{{now}}</clr-timeline-step-header>
										<clr-timeline-step-title>UPDATE</clr-timeline-step-title>
										<clr-timeline-step-description>
											{{update_result}}
										</clr-timeline-step-description>
									</clr-timeline-step>
								</clr-timeline>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="users"></cds-icon>
			&nbsp;Group management
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div class="clr-row">
				<div class="clr-col-lg-2 clr-col-md-4 clr-col-12">
					<button class="btn btn-primary" style="float:left;" (click)="initWizardUser()">
						<cds-icon shape="plus-circle" class="is-solid"></cds-icon>
						CREATE GROUP
					</button>
				</div>
			</div>
			<div class="clr-row">	
				<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">select a group</h5>
							<div class="card-text">
								<div class="clr-control-container">
									<div class="clr-select-wrapper">
										<select id="user-list" class="clr-select" [(ngModel)]="selected_user" (ngModelChange)="switchUser($event)">
											<option *ngFor="let user of users" [ngValue]="user">{{user.login}}</option>
										</select>
									</div>
									<span class="clr-subtext">the list of groups</span>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="editUser()">
								Edit
							</button>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="isDeleteUser = true" *ngIf="selected_user.login != 'root'">
								Delete
							</button>
       					</div>
					</div>
				</div>
				<div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title"><cds-icon shape="users"></cds-icon>&nbsp;&nbsp;Information about {{selected_user.login}}</h5>
							<div class="card-text">
								<clr-datagrid>
									<clr-dg-column>NAME</clr-dg-column>
									<clr-dg-column>EMAIL</clr-dg-column>
									<clr-dg-column>CURRENCY</clr-dg-column>
									<clr-dg-column *ngIf="message.isDCScope">ROLE</clr-dg-column>	
									<clr-dg-column>USERS</clr-dg-column>			
									<clr-dg-row>
										<clr-dg-cell>{{selected_user.login}}</clr-dg-cell>
										<clr-dg-cell>{{selected_user.email}}</clr-dg-cell>
										<clr-dg-cell>{{getCurrency(selected_user.currency)}}</clr-dg-cell>
										<clr-dg-cell *ngIf="message.isDCScope">{{selected_user.role}}</clr-dg-cell>
										<clr-dg-cell>
											<button class="btn btn-link" (click)="showAliasForUser(selected_user)">
												<span style="position:relative;bottom:33%;">{{getAliasesFromUser(selected_user).length}}</span>
											</button>
										</clr-dg-cell>
									</clr-dg-row>
								</clr-datagrid>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isEditUser">
					<div class="card">
						<button type="button" class="close" aria-label="Close" (click)="isEditUser=false">
							<cds-icon shape="window-close" size="24"></cds-icon>
						</button>
						<div class="card-block">
							<div class="clr-row">
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
									<h5 class="card-title">edit parameters for {{selected_user.login}}</h5>
								</div>
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
									<button style="float: right;" type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateUser()" *ngIf="isUpdateUser">
										Update
									</button>
								</div>
							</div>
							<div class="card-text">
								<clr-timeline>
									<clr-timeline-step clrState="success">
										<clr-timeline-step-header>{{formatEditDate(this.selected_user.last_co)}}</clr-timeline-step-header>
    										<clr-timeline-step-title>CONNECTION or UPDATE</clr-timeline-step-title>
										<clr-timeline-step-description>Last connection date for the group {{selected_user.login}}.</clr-timeline-step-description>
  									</clr-timeline-step>
									<clr-timeline-step clrState="success">
										<clr-timeline-step-header>STEP 1</clr-timeline-step-header>
										<clr-timeline-step-title>NAME</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<div class="clr-input-container">
													<label>{{selected_user.login}}</label>
												</div>
												<span class="clr-subtext">(cannot be changed)</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>
									<clr-timeline-step clrState="current">
										<clr-timeline-step-header>STEP 2</clr-timeline-step-header>
										<clr-timeline-step-title>EMAIL</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-form-control" style="margin-top:0px">
												<div class="clr-input-container" [ngClass]="{'clr-error' :  edit_user_model.email_error}">
													<input id="edit-user-email" type="text" class="clr-input" [(ngModel)]="edit_user_model.email" (ngModelChange)="checkMail(edit_user_model);enableUserUpdate(!edit_user_model.email_error)">
													<cds-icon shape="exclamation-circle" class="is-error" size="18" *ngIf="edit_user_model.email_error"></cds-icon>
													<clr-control-error *ngIf="edit_user_model.email_error">you have entered an invalid email address !</clr-control-error>
												</div>
												<span class="clr-subtext" *ngIf="!edit_user_model.email_error">enter an email</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>									
									<clr-timeline-step [clrState]="!isRootUser ? 'current' : 'success'" *ngIf="message.isDCScope">
										<clr-timeline-step-header>STEP 3</clr-timeline-step-header>
										<clr-timeline-step-title>ROLE</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<div class="clr-select-wrapper">
													<select id="edit-user-role" class="clr-select" [(ngModel)]="edit_user_model.role" (ngModelChange)="enableUserUpdate()" [disabled]="isRootUser">
														<option *ngFor="let role of roles">{{role.role}}</option>
													</select>
												</div>
												<span class="clr-subtext">select the role</span>
												<span class="clr-subtext" *ngIf="isRootUser">(cannot be changed for root)</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>
								</clr-timeline>
								<clr-timeline style="padding-left:15%">
									<clr-timeline-step [clrState]="!isRootUser ? 'current' : 'success'">
										<clr-timeline-step-header>STEP {{message.isDCScope ? 4 : 3}}</clr-timeline-step-header>
										<clr-timeline-step-title>SCOPE</clr-timeline-step-title>
										<clr-timeline-step-description>
											<button class="btn btn-sm" (click)="isModalScope = true;loadScope()" [disabled]="isRootUser">CHANGE</button>
											<span class="clr-subtext" *ngIf="isRootUser">(cannot be changed for root)</span>
										</clr-timeline-step-description>
									</clr-timeline-step>

									<clr-timeline-step [clrState]="isRootUser ? 'current' : 'success'">
										<clr-timeline-step-header>STEP {{message.isDCScope ? 5 : 4}}</clr-timeline-step-header>
										<clr-timeline-step-title>CURRENCY</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<div class="clr-select-wrapper">
													<select id="edit-user-currency" class="clr-select" [(ngModel)]="edit_user_model.currency" (ngModelChange)="updateUserCurrency()" [disabled]="!isRootUser">
														<option *ngFor="let currency of currencies">{{getCurrency(currency)}}</option>
													</select>
												</div>
												<span class="clr-subtext">select the currency</span>
												<span class="clr-subtext" *ngIf="!isRootUser">(cannot be changed)</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>

									<clr-timeline-step clrState="{{update_state}}">
										<clr-timeline-step-header>{{now}}</clr-timeline-step-header>
										<clr-timeline-step-title>UPDATE</clr-timeline-step-title>
										<clr-timeline-step-description>
											{{update_result}}
										</clr-timeline-step-description>
									</clr-timeline-step>
								</clr-timeline>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--CREATE ALIAS (aka USER)-->
<clr-wizard #wizardAlias
	[clrWizardSize]="'xl'"
	(clrWizardOnCancel)="doResetAlias()"
	(clrWizardOnFinish)="doFinishAlias()">
    <clr-wizard-title>Create a user</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

	<!-- 1 -->
	<clr-wizard-page
		(clrWizardPageNext)="initAliasMode()" >
        <ng-template clrPageTitle>Creation mode</ng-template>
		<div style="height:100%;margin-top:100px;margin-left:15%">
			<br><br>
			<div class="clr-control-container clr-control-inline" style="width:100%;">
				<clr-radio-wrapper>
					<input type="radio" clrRadio name="mode" required value="local" [(ngModel)]="alias_create_mode" />
					<label style="display:grid;margin:auto;">
						<cds-icon shape="user" size="64"></cds-icon>
						<b style="margin-top:20px;">Create local user</b>
					</label>
				</clr-radio-wrapper>
				<div style="margin-left:20%"></div>
				<clr-radio-wrapper>
					<input type="radio" clrRadio name="mode" required value="ldap" [(ngModel)]="alias_create_mode" />
					<label style="display:grid;margin:auto;">
						<cds-icon shape="list" size="64"></cds-icon>
						<b style="margin-top:20px;">Create users from LDAP</b>
					</label>
				</clr-radio-wrapper>
			</div>
		</div>
	</clr-wizard-page>

	<!--CREATE ALIAS (aka USER) LOCAL -->

	<!-- 1 LOCAL -->
	<clr-wizard-page *ngIf="alias_create_mode == local_mode" 
		[clrWizardPageNextDisabled]="alias_model.name == '' || existsAlias(alias_model.name)">
		<ng-template clrPageTitle>User settings</ng-template>
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<div class="clr-row">
						<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
							<div class="clr-form-control">
								<label>STEP 1 - user name</label>
								<div class="clr-input-container" [ngClass]="{'clr-error' :  alias_model.error}">
									<input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="alias_model.name" name="name" (ngModelChange)="checkName(alias_model)" required />
									<cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="alias_model.error"></cds-icon>
									<clr-control-error *ngIf="alias_model.error">This field cannot set {{alias_model.errorName}}</clr-control-error>
								</div>
								<span class="clr-subtext">the name for this user</span>
							</div>
						</div>			
						<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
							<div class="clr-form-control">
								<label>STEP 2 - associated group</label>
								<div class="clr-select-wrapper">
									<select class="clr-select" [(ngModel)]="alias_model.user">
										<option *ngFor="let user of users" [ngValue]="user">{{user.login}}</option>
									</select>
								</div>
								<span class="clr-subtext">select the group</span>
							</div>
						</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					</div>
				</div>
			</div>
		</div>
	</clr-wizard-page>

	<!-- 2 LOCAL -->
	<clr-wizard-page *ngIf="alias_create_mode == local_mode"
		[clrWizardPageNextDisabled]="signUpForm.status != 'VALID'">
		<ng-template clrPageTitle>User access</ng-template>
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<div class="clr-row">
						<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
							<div class="clr-form-control">
								<label>STEP 3 - user password</label>
								<ng-template [ngTemplateOutlet]="passwordtemplate"></ng-template>
							</div>
						</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					</div>
				</div>
			</div>
		</div>
	</clr-wizard-page>

	<!--CREATE ALIAS (aka USER) AD-->

	<!-- 1 AD -->
    <clr-wizard-page *ngIf="alias_create_mode == ad_mode"
		[clrWizardPageNextDisabled]="ad_connect == false"
		(clrWizardPageNext)="selectAliasAD()">

        <ng-template clrPageTitle>Connect to {{ad_settings.type}}</ng-template>

		<div class="alert alert-info" role="alert" style="padding-left:5px;padding-right:5px;padding-top:10px;padding-bottom:10px;margin-top:20px;">
            <b>Your LDAP settings :</b>
			<div><br>
				<table cellPadding=8>
					<tr><td><b>hostname:</b></td><td>{{ad_settings.hostname}}</td></tr>
					<tr><td><b>group:</b></td><td>{{ad_settings.group}}</td></tr>
				</table>
			</div>
        </div>

		<div class="card" syle="margin-top:20px">
			<div class="card-block">
				<div class="card-text">
					<div class="clr-row">

						<clr-alert style="width:100%;" [clrAlertType]="ad_message_type" [clrAlertAppLevel]=true [clrAlertClosable]=false>
							<clr-alert-item>
								<span class="alert-text">
									{{ad_message}}
								</span>
							</clr-alert-item>
						</clr-alert>

						<form clrForm [formGroup]="ad_form">
							<div class="clr-form-control" style="flex-direction:row;margin-top:50px">
								<label class="clr-control-label clr-col-12 clr-col-md-2">Login :</label>
								<div class="clr-control-container">
									<clr-input-container style="margin-top:0px">
										<input clrInput style="margin-top:0px;width:20em" type="text" name="username" [placeholder]="ad_property" formControlName="login">
									</clr-input-container>
								</div>
							</div>

							<div class="clr-form-control" style="flex-direction:row;margin-top:50px">
								<label class="clr-control-label clr-col-12 clr-col-md-2">Password :</label>
								<div class="clr-control-container">
									<clr-password-container style="margin-top:0px">
										<input clrPassword style="margin-top:0px;width:18em" type="password" name="password" placeholder="password" formControlName="password"/>
									</clr-password-container>
								</div>
							</div>

						</form>

						<div style="width:100%;margin-top:50px;">
							<button [clrLoading]="ad_button_state" style="float:right" [disabled]="(ad_form.value.login == '' || ad_form.value.password == '') || ad_settings.lock" type="button"
								class="btn btn-outline btn-sm" (click)="connectAD()">
								<span style="min-width:200px;display:block;">CONNECT</span>
							</button>
						</div>

					</div>
				</div>
			</div>
		</div>

    </clr-wizard-page>

	<!-- 2 AD -->
 	<clr-wizard-page *ngIf="alias_create_mode == ad_mode"
		[clrWizardPageNextDisabled]="(ad_persons_selected.length == 0 && ad_groups_selected.length == 0)">

        <ng-template clrPageTitle>Groups and persons from {{ad_settings.type}}</ng-template>

		<span>
            <cds-icon shape="users" size="20"></cds-icon>
            <h5 style="display:inline;">&nbsp;Groups</h5>
            <span style="margin-left:10px;" class="label label-info">Select groups to create users for</span>
        </span>		

		<div class="alert alert-info" role="alert" style="margin-top:20px">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="help-info"></cds-icon>
                     </div>
                    <span class="alert-text" style="font-size:13px;text-align:justify;">
						all members of the same group can connect through the same {{message.isDCScope ? "DC Scope" : "CO2Scope" }} user.
                    </span>
                </div>
            </div>
        </div>

		<clr-datagrid style="margin-top:20px;margin-bottom:20px;" [(clrDgSelected)]="ad_persons_selected">
			<clr-dg-column [clrDgField]="'samaccountname'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
			<clr-dg-column [clrDgField]="'objectclass'"><ng-container *clrDgHideableColumn="{hidden: false}">ObjectClass</ng-container></clr-dg-column>
									
			<clr-dg-row *clrDgItems="let person of filterADList('group')" [clrDgItem]="person" [clrDgSelectable]="!person.lock">
				<clr-dg-cell>
					{{person.samaccountname}}
					<span *ngIf="person.lock" style="margin-left:10px;font-size:10px;" class="label label-danger">user already exists</span>
				</clr-dg-cell>
				<clr-dg-cell><cds-icon shape="users"></cds-icon>&nbsp;{{person.objectclass}}</clr-dg-cell>
			</clr-dg-row> 

			<clr-dg-footer>
				<clr-dg-pagination #pagination [clrDgPageSize]="10">
					<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
					{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
				</clr-dg-pagination>
			</clr-dg-footer>
		</clr-datagrid>

		<span>
            <cds-icon shape="user" size="20"></cds-icon>
            <h5 style="display:inline;">&nbsp;Persons</h5>
            <span style="margin-left:10px;" class="label label-info">Select persons to create users for</span>
        </span>

		<clr-datagrid style="margin-top:20px;" [(clrDgSelected)]="ad_groups_selected">
			<clr-dg-column [clrDgField]="'samaccountname'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">{{ad_property}}</ng-container></clr-dg-column>
			<clr-dg-column [clrDgField]="'objectclass'"><ng-container *clrDgHideableColumn="{hidden: false}">ObjectClass</ng-container></clr-dg-column>
											
			<clr-dg-row *clrDgItems="let person of filterADList('person')" [clrDgItem]="person" [clrDgSelectable]="!person.lock">
				<clr-dg-cell>
					{{person.samaccountname}}
					<span *ngIf="person.lock" style="margin-left:10px;font-size:10px;" class="label label-danger">user already exists</span>
				</clr-dg-cell>
				<clr-dg-cell><cds-icon shape="user"></cds-icon>&nbsp;{{person.objectclass}}</clr-dg-cell>
			</clr-dg-row>

			<clr-dg-footer>
				<clr-dg-pagination #pagination2 [clrDgPageSize]="10">
					<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
					{{pagination2.firstItem + 1}} - {{pagination2.lastItem + 1}} of {{pagination2.totalItems}} entries
				</clr-dg-pagination>
			</clr-dg-footer>
		</clr-datagrid>

    </clr-wizard-page>

	<!-- 3 AD -->
 	<clr-wizard-page *ngIf="alias_create_mode == ad_mode"
		[clrWizardPageNextDisabled]="ad_user_wait">

        <ng-template clrPageTitle>Choose DC Scope user group</ng-template>

		<div>
			<span style="margin-left:10px;margin-top:30px;margin-left:auto;margin-right:auto" class="label label-info">Choose the DC Scope user group your users will belong to, or create a new one.</span>
			<table cellspacing="0px" style="table-layout:fixed;margin-top:30px;">
				<tr>
					<td style="width:50%">
						<div class="clr-form-control" style="flex-direction:row;margin:0">
							<label class="clr-control-label">List of groups :</label>
							<clr-select-container style="flex-direction:row;margin-top:0;margin-left:20px">
								<select clrSelect [(ngModel)]="ad_user_selected">
									<option *ngFor="let user of users" [ngValue]="user">{{user.login}}</option>
								</select>
							</clr-select-container>
						</div>
					</td>
					<td>
						<button [clrLoading]="ad_button_state" style="margin-left:20px;" class="btn btn-link" (click)="initWizardUser()">Create a new group</button>
					</td>
				<tr>
			</table>
		</div>
		<div class="card" style="margin-top:75px">
			<div class="card-block">
				<h5 class="card-title">information about {{ad_user_selected.login}}</h5>
				<div class="card-text">
					<table class="table">
  						<thead>
    						<tr>
      							<th>LOGIN</th>
      							<th>EMAIL</th>
      							<th>CURRENCY</th>
      							<th>ROLE</th>
    						</tr>
  						</thead>
  						<tbody>
							<td>{{ad_user_selected.login}}</td>
							<td>{{ad_user_selected.email}}</td>
							<td>{{getCurrency(ad_user_selected.currency)}}</td>
							<td>{{ad_user_selected.role}}</td>
  						</tbody>
					</table>
				</div>
			</div>
		</div>

	</clr-wizard-page>
	
</clr-wizard>

<!--CREATE USER (aka GROUP)-->
<clr-wizard #wizardUser
	[clrWizardSize]="'xl'"
	(clrWizardOnPrevious)="goBackUser()"
	(clrWizardOnCancel)="doResetUser()"
	(clrWizardOnFinish)="doFinishUser()">
    <clr-wizard-title>Create a group</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="user_model.name == '' || existsUser(user_model.name) || checkMail(user_model)">
        <ng-template clrPageTitle>The group settings</ng-template>
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<div class="clr-row">
						<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
							<div class="clr-form-control">
								<label>STEP 1 - group name</label>
								<div class="clr-input-container" [ngClass]="{'clr-error' :  user_model.error}">
									<input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="user_model.name" name="name" (ngModelChange)="checkName(user_model)" required />
									<cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="user_model.error"></cds-icon>
									<clr-control-error *ngIf="user_model.error">this field cannot set {{user_model.errorName}}</clr-control-error>
								</div>
								<span class="clr-subtext" *ngIf="!user_model.error">the name for this group</span>
							</div>
						</div>			
						<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
							<div class="clr-form-control">
								<label>STEP 2 - group mail</label>
								<div class="clr-input-container" [ngClass]="{'clr-error' :  user_model.email_error}">
									<input type="text" class="clr-input" placeholder="enter email ..." [(ngModel)]="user_model.email" name="email" (ngModelChange)="checkMail(user_model)" required />
									<cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="user_model.email_error"></cds-icon>
									<clr-control-error *ngIf="user_model.email_error">you have entered an invalid email address !</clr-control-error>
								</div>
								<span class="clr-subtext" *ngIf="!user_model.email_error">enter an email</span>
							</div>
						</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div *ngIf="message.isDCScope" class="clr-col-lg-6 clr-col-md-6 clr-col-12">
							<div class="clr-form-control">
								<label>STEP 3 - group role</label>
								<div class="clr-select-wrapper">
									<select class="clr-select" [(ngModel)]="user_model.role">
										<option *ngFor="let role of roles">{{role.role}}</option>
									</select>
								</div>
								<span class="clr-subtext">select the role</span>
							</div>
						</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					</div>
				</div>
			</div>
		</div>
    </clr-wizard-page>
	<clr-wizard-page>
        <ng-template clrPageTitle>Select the context</ng-template>
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<div class="clr-row">
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
							<div class="clr-form-control">
								<div class="clr-control-container">
									<div class="clr-select-wrapper">
										<select id="select-resource" class="clr-select" [(ngModel)]="message.elementViewContext">
											<option value="multi">Multi infrastructure</option>
											<option value="vmware">VMware infrastructure</option>
											<option value="xclarity">XClarity infrastructure</option>
											<option value="scaphandre">Scaphandre infrastructure</option>
											<option value="kubernetes">Kubernetes infrastructure</option>
											<option value="aws">Aws infrastructure</option>
											<option value="azure">Azure infrastructure</option>
											<option value="openmanage">OpenManage infrastructure</option>
											<option value="oneview">OneView infrastructure</option>
											<option value="network">Network infrastructure</option>
											<option value="storage">Storage infrastructure</option>
										</select>
									</div>
								</div>
								<span class="clr-subtext">to select the context for your infrastructure</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </clr-wizard-page>
    <clr-wizard-page (clrWizardPageOnLoad)="loadScope()" [clrWizardPageNextDisabled]="!message.isTreeAnalysis">
        <ng-template clrPageTitle>The group scope</ng-template>
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<ng-template load-wizard-selection-tree></ng-template>
				</div>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callCollapse()">
					Collapse
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callExpand()">
					Expand
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="selectALL()">
					SelectALL
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="unSelect()">
					Unselect
				</button>
			</div>
		</div>
    </clr-wizard-page>
</clr-wizard>

<clr-modal [(clrModalOpen)]="isModalPassword" [clrModalSize]="'xs'">
    <h3 class="modal-title">Enter new password for {{alias_selected.login}}</h3>
    <div class="modal-body">
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<ng-template [ngTemplateOutlet]="passwordtemplate"></ng-template>
				</div>
			</div>
		</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" [disabled]="signUpForm.status != 'VALID'"
			(click)="updatePwd()">OK</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isModalScope" [clrModalSize]="'xs'">
    <h3 class="modal-title">Select the scope for {{selected_user.login}}</h3>
    <div class="modal-body">
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<ng-template load-selection-tree></ng-template>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isModalScope = false;updateScope()">OK</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isDeleteAlias" [clrModalClosable]=false>
    <h3 class="modal-title">Delete user</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>This user will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteAlias = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="removeAlias()">Delete</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isAliasUser" [clrModalSize]="'l'">
    <h3 class="modal-title">
		<cds-icon shape="users" size="24"></cds-icon>
		Users of {{selected_user.login}}
	</h3>
    <div class="modal-body" style="overflow:auto">
		<clr-datagrid style="margin-top:20px;margin-bottom:20px;">
			<clr-dg-column [clrDgField]="'login'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">NAME</ng-container></clr-dg-column>
			<clr-dg-column><ng-container *clrDgHideableColumn="{hidden: false}">GROUP</ng-container></clr-dg-column>
			<clr-dg-column><ng-container *clrDgHideableColumn="{hidden: false}">LOCAL</ng-container></clr-dg-column>
			<clr-dg-column><ng-container *clrDgHideableColumn="{hidden: false}">LDAP</ng-container></clr-dg-column>
			
			<clr-dg-row *clrDgItems="let alias of user_aliases" [clrDgItem]="alias">
				<clr-dg-cell>{{alias.login}}</clr-dg-cell>
				<clr-dg-cell>{{alias.user}}</clr-dg-cell>
				<clr-dg-cell>{{alias.is_local == 1 ? "true": "false"}}</clr-dg-cell>
				<clr-dg-cell>{{alias.is_ldap == 1 ? "true": "false"}}</clr-dg-cell>
			</clr-dg-row>
			
			<clr-dg-footer>
                <clr-dg-pagination #user_aliases_page [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{user_aliases_page.firstItem + 1}} - {{user_aliases_page.lastItem + 1}} of {{user_aliases_page.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>
		</clr-datagrid>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-primary" (click)="isAliasUser = false">OK</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isDeleteUser" [clrModalClosable]=false>
    <h3 class="modal-title">Delete group</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>This group and associated users will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteUser = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="removeUser();">Delete</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isScopeError" [clrModalClosable]=false>
    <h3 class="modal-title">Cannot update scope</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
		<p><b>the following view elements are attached to {{selected_user.login}} scope</b></p>
	    <table class="table table-compact">
	    	<thead>
			<tr>
				<th>view name</th>
			</tr>
	    	</thead>
	    	<tbody>
    			<tr *ngFor="let view of view_not_match">
				<td>{{view}}</td>
			</tr>
	    	</tbody>
	    </table>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isScopeError = false">OK</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isCurrencyUser" [clrModalClosable]=false>
    <h3 class="modal-title">Currency</h3>
    <div class="modal-body">
        <div class="clr-row">
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
				<p>You have chosen to modify your currency.</p>
				<p>It's necessary to apply an exchange rate to the costs already recorded in DC Scope.</p>
				<br><br>
			</div>
        </div>
		<div class="clr-row">
			<div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
				<b>Please indicate the current exchange rate {{getCurrency(selected_user.currency)}} --> {{edit_user_model.currency}}</b>
			</div>
			<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
				<div class="clr-control-container" style="width:100%">
					<div class="clr-input-wrapper">
						<input style="width:100%" type="number" class="clr-input" [(ngModel)]="edit_user_model.rate" (ngModelChange)="checkUserCurrencyRate()">
					</div>
				</div>
				<br><br>
			</div>
		</div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="confirmUserCurrency(false)">Cancel</button>
        <button type="button" class="btn btn-success" (click)="confirmUserCurrency(true)" [disabled]="!confirmCurrency">Confirm</button>
    </div>
</clr-modal>

<ng-template #passwordtemplate >
	<form clrForm [formGroup]="signUpForm">
		<clr-password-container>
			<label>Password</label>
			<input clrPassword formControlName="password"/>
			<clr-control-error *clrIfError="'required'">This password is required!</clr-control-error>
			<clr-control-error *clrIfError="'minlength'">It must be at least 8 characters !</clr-control-error>
			<clr-control-error *clrIfError="'maxlength'">It must be less than 20 characters !</clr-control-error>
			<clr-control-error *clrIfError="'pattern'">It must contains lowercases, uppercases, digits and special characters !</clr-control-error>
		</clr-password-container>
		<clr-password-container>
			<label> Confirm Password</label>
			<input clrPassword formControlName="confirmPassword"/>
			<clr-control-error *clrIfError="'required'">Confirm password is required !</clr-control-error>
			<clr-control-error *clrIfError="'mismatch'">Passwords don't match !</clr-control-error>
		</clr-password-container>
	</form>
</ng-template>
