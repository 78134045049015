function commaBigNumber(val) {

    while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ' ' + '$2');
    }

    return val;
}

function getUserCurrency(val) {

    var testcurrency = val;
    var res = testcurrency.split('-');
    var rescode = '';
    for (var i = 0; i < res.length; i++) {
        rescode += String.fromCharCode(res[i]);
    }

    return rescode;
}

function isPair(i) {

    var j = i;
    if ((i % 2) != 0) {
        if (parseInt((i % 2)) == 0)
            j = parseInt(j) + 2;
        else
            j = parseInt(j) + parseInt((i % 2));
    }

    return j;
}

function getGranularityFromInterval(startTime, endTime) {

    const t0 = startTime;
    const t1 = endTime;

    const MIN_NB_POINTS = 2;

    var diffMs = endTime - startTime;
    var diffHs = diffMs / 3600000;
    var diffDays = diffHs / 24;
    var granularity;

    let beforeInterval;
    let afterInterval;

    let sdt = moment(t0).utc()
    let edt = moment(t1).utc()

    if (diffDays >= MIN_NB_POINTS * 365) {
        granularity = "YEARLY";

        if (!sdt.isSame(moment(sdt).startOf('year'))) {
            beforeInterval = {
                start: t0,
                end: sdt.add(1, 'years').startOf('year').valueOf()
            }
        }

        if (!edt.isSame(moment(edt).startOf('year'))) {
            afterInterval = {
                start: edt.startOf('year').valueOf(),
                end: t1
            }
        }

    } else if (diffDays >= MIN_NB_POINTS * 30) {
        granularity = "MONTHLY";

        if (!sdt.isSame(moment(sdt).startOf('month'))) {
            beforeInterval = {
                start: t0,
                end: sdt.add(1, 'months').startOf('month').valueOf()
            }
        }

        if (!edt.isSame(moment(edt).startOf('month'))) {
            afterInterval = {
                start: edt.startOf('month').valueOf(),
                end: t1
            }
        }

    } else if (diffDays >= MIN_NB_POINTS * 7) {
        granularity = "WEEKLY";

        if (!sdt.isSame(moment(sdt).startOf('week').add(1, 'days'))) {
            beforeInterval = {
                start: t0,
                end: sdt.add(1, 'weeks').startOf('week').add(1, 'days').valueOf()
            }
        }
        if (!edt.isSame(moment(edt).startOf('week').add(1, 'days'))) {
            afterInterval = {
                start: edt.startOf('week').add(1, 'days').valueOf(),
                end: t1
            }
        }
    } else if (diffDays >= MIN_NB_POINTS) {
        granularity = "DAILY";

        if (!sdt.isSame(moment(sdt).startOf('day'))) {
            beforeInterval = {
                start: t0,
                end: sdt.add(1, 'days').startOf('day').valueOf()
            }
        }

        if (!edt.isSame(moment(edt).startOf('day'))) {
            afterInterval = {
                start: edt.startOf('day').valueOf(),
                end: t1
            }
        }
    } else if (diffHs >= MIN_NB_POINTS) {
        granularity = "HOURLY";

        if (!sdt.isSame(moment(sdt).startOf('hour'))) {
            beforeInterval = {
                start: t0,
                end: sdt.add(1, 'hours').startOf('hour').valueOf()
            }
        }

        if (!edt.isSame(moment(edt).startOf('hour'))) {
            afterInterval = {
                start: edt.startOf('hour').valueOf(),
                end: t1
            }
        }

    } else {
        granularity = "MINUTELY";
    }

    return {
        granularity: granularity,
        beforeInterval: beforeInterval,
        afterInterval: afterInterval
    };
}

function alertConvApiToIhm(data) {

    var convObj = null;
    var th1 = data.threshold_1;
    var th2 = data.threshold_2;
    var freq = 'since ' + data.frequency;
    var base = 'consumption';
    var unit = '%';
    var item = data.identifier;
    var type = data.type;
    var itemname = '';

    if (data.identifier == "all_vm") {
        itemname = '---ALL VM---';
        type = 'vm';
    } else if (data.identifier == "all_esx") {
        itemname = '---ALL ESX---';
        type = 'server';
    }

    switch (data.granularity) {
        case "min":
            if (data.frequency == 1)
                freq += ' minute';
            else
                freq += ' minutes';
            break;
        case "day":
            if (data.frequency == 1)
                freq += ' day';
            else
                freq += ' days';
            break;
        default:
            break;
    }

    switch (data.counter) {
        // real time
        case "cpuusage":
        case "ramusage":
            th1 = Number(th1) / 10;
            th2 = Number(th2) / 10;
            break;
        case "cpuready":
        case "cpucostop":
            th1 = Number(th1) / 200;
            th2 = Number(th2) / 200;
            break;
        case "ramactive":
        case "ramconsumed":
        case "ramswapped":
            th1 = Number(th1) / 1048576;
            th2 = Number(th2) / 1048576;
            unit = 'GB';
            break;
            // allocation
        case "cpu_remain":
            unit = ' remaining vcpu';
            base = 'allocation';
            break;
        case "ram_remain":
        case "disk_remain":
            unit = ' remaining GB';
            base = 'allocation';
            break;
        case "vcpu_sat":
        case "vram_sat":
        case "vdisk_sat":
            unit = ' remaining days';
            base = 'allocation';
            break;
        case "vcpu_allocated":
            unit = ' vcpu/core';
            base = 'allocation';
            break;
        case "ram_allocated":
        case "disk_allocated":
            base = 'allocation';
            break;
        case "vm_cost":
            unit = '';
            base = 'allocation';
            break;
        case "vm_prov":
            unit = ' remaining VM';
            base = 'allocation';
            break;
            // consumption
        case "cpu_ghzremain":
            unit = ' remaining GHz';
            base = 'consumption';
            break;
        case "disk_goremain":
        case "ram_goremain":
            unit = ' remaining GB';
            base = 'consumption';
            break;
        case "cpu_sat":
        case "disk_sat":
        case "ram_sat":
            unit = ' remaining days';
            base = 'consumption';
            break;
        case "snap_date":
            unit = ' days';
            base = 'consumption';
            break;
        case "snap_nb":
            unit = ' snapshots';
            base = 'consumption';
            break;
        case "snap_size":
            unit = 'GB';
            base = 'consumption';
            break;
        case "vm_costcons":
            unit = '';
            base = 'consumption';
            break;
        case "vm_remain":
            unit = ' remaining VM';
            base = 'consumption';
            break;
            // behavior
        case "busy_behavior":
        case "critical_behavior":
        case "idle_behavior":
            unit = 'VM';
            base = 'behavior';
            break;
        case "zombie_behavior":
            unit = 'GB';
            base = 'behavior';
            break;
        default:
            break;
    }

    convObj = {
        'base': base,
        'th1': th1,
        'th2': th2,
        'freq': freq,
        'unit': unit,
        'item': item,
        'uuid': data.uuid,
        'type': type,
        'itemname': itemname
    };

    return convObj;
}

function alertConvIhmToApi(data) {

    let convObj = null;
    let identifier = data.item;
    let th1 = 0;
    let th2 = 0;
    let frequency = data.freq.split(" ")[1];
    let definition = '';
    let type = '';
    var base = 'consumption';

    if (data.item == "---ALL VM---") {
        identifier = 'all_vm';
        type = 'vm';
    } else if (data.item == "---ALL ESX---") {
        identifier = 'all_esx';
        type = 'server';
    }

    switch (data.counter) {
        case "CPU Usage ( %)":
            th1 = Number(data.th1 * 10);
            th2 = Number(data.th2 * 10);
            if (frequency == 1)
                definition = 'Be alerted when the CPU Usage ( %) is greater than or equal to %STATE% since at least ' +
                frequency + ' minute';
            else
                definition = 'Be alerted when the CPU Usage ( %) is greater than or equal to %STATE% since at least ' +
                frequency + ' minutes';
            break;
        case "CPU Ready ( %)":
            th1 = Number(data.th1 * 200);
            th2 = Number(data.th2 * 200);
            if (frequency == 1)
                definition = 'Be alerted when the CPU Ready ( %) is greater than or equal to %STATE% since at least ' +
                frequency + ' minute';
            else
                definition = 'Be alerted when the CPU Ready ( %) is greater than or equal to %STATE% since at least ' +
                frequency + ' minutes';
            break;
        case "CPU CoStop ( %)":
            th1 = Number(data.th1 * 200);
            th2 = Number(data.th2 * 200);
            if (frequency == 1)
                definition = 'Be alerted when the CPU Costop ( %) is greater than or equal to %STATE% since at least ' +
                frequency + ' minute';
            else
                definition = 'Be alerted when the CPU Costop ( %) is greater than or equal to %STATE% since at least ' +
                frequency + ' minutes';
            break;
        case "RAM Usage ( %)":
            th1 = Number(data.th1 * 10);
            th2 = Number(data.th2 * 10);
            if (frequency == 1)
                definition = 'Be alerted when the RAM Usage ( %) is greater than or equal to %STATE% since at least ' +
                frequency + ' minute';
            else
                definition = 'Be alerted when the RAM Usage ( %) is greater than or equal to %STATE% since at least ' +
                frequency + ' minutes';
            break;
        case "RAM Active (GB)":
            th1 = Number(data.th1 * 1048576);
            th2 = Number(data.th2 * 1048576);
            if (frequency == 1)
                definition =
                'Be alerted when the RAM Active ( GB) is greater than or equal to %STATE% since at least ' + frequency +
                ' minute';
            else
                definition =
                'Be alerted when the RAM Active ( GB) is greater than or equal to %STATE% since at least ' + frequency +
                ' minutes';
            break;
        case "RAM Consumed (GB)":
            th1 = Number(data.th1 * 1048576);
            th2 = Number(data.th2 * 1048576);
            if (frequency == 1)
                definition =
                'Be alerted when the RAM Consumed ( GB) is greater than or equal to %STATE% since at least ' +
                frequency + ' minute';
            else
                definition =
                'Be alerted when the RAM Consumed ( GB) is greater than or equal to %STATE% since at least ' +
                frequency + ' minutes';
            break;
        case "RAM Swapped (GB)":
            th1 = Number(data.th1 * 1048576);
            th2 = Number(data.th2 * 1048576);
            if (frequency == 1)
                definition =
                'Be alerted when the RAM Swapped ( GB) is greater than or equal to %STATE% since at least ' +
                frequency + ' minute';
            else
                definition =
                'Be alerted when the RAM Swapped ( GB) is greater than or equal to %STATE% since at least ' +
                frequency + ' minutes';
            break;
        case "Free vCPU resource":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the number of vCPU that can be used is less than or equal to %STATE% since at least 1 day';
            else
                definition =
                'Be alerted when the number of vCPU that can be used is less than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'allocation';
            type = 'filter';
            break;
        case "Free vRAM resource":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the amount of vRAM that can be still used is less than or equal to %STATE% since at least 1 day';
            else
                definition =
                'Be alerted when the amount of vRAM that can be still used is less than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'allocation';
            type = 'filter';
            break;
        case "Free vDISK resource":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the amount of vDISK that can be still used is less than or equal to %STATE% since at least 1 day';
            else
                definition =
                'Be alerted when the amount of vDISK that can be still used is less than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'allocation';
            type = 'filter';
            break;
        case "Saturation date in vCPU":
        case "Saturation date in vRAM":
        case "Saturation date in vDISK":
            th1 = data.th1;
            th2 = data.th2;
            definition = 'Be alerted %STATE% days before the saturation date since yesterday';
            base = 'allocation';
            type = 'filter';
            break;
        case "VCPU Allocation ( VCPU/CORE)":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the number of vCPU compared to logical cores (threads) is greater than or equal to %STATE% since at least 1 day';
            else
                definition =
                'Be alerted when the number of vCPU compared to logical cores (threads) is greater than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'allocation';
            type = 'filter';
            break;
        case "VRAM Allocation ( %)":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the amount of RAM allocated to VM is greater than or equal to %STATE% % of the servers capacity since at least 1 day';
            else
                definition =
                'Be alerted when the amount of RAM allocated to VM is greater than or equal to %STATE% % of the servers capacity since at least ' +
                frequency + ' days';
            base = 'allocation';
            type = 'filter';
            break;
        case "VDISK Allocation ( %)":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the amount of storage provisioned to VM is greater than or equal to %STATE% % of the datastores capacity since at least 1 day';
            else
                definition =
                'Be alerted when the amount of storage provisioned to VM is greater than or equal to %STATE% % of the datastores capacity since at least ' +
                frequency + ' days';
            base = 'allocation';
            type = 'filter';
            break;
        case "VM Cost":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition = 'Be alerted when the VM cost is greater than or equal to %STATE% since at least 1 day';
            else
                definition = 'Be alerted when the VM cost is greater than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'allocation';
            type = 'filter';
            break;
        case "VM Provisioning":
            th1 = data.th1;
            th2 = data.th2;
            definition = 'Be alerted when the number of VM(s) that can be created is less than or equal to %STATE%';
            base = 'allocation';
            type = 'filter';
            break;
        case "DISK remaining in GB":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the amount of DISK that can be still used is less than or equal to %STATE% since at least 1 day';
            else
                definition =
                'Be alerted when the amount of DISK that can be still used is less than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'consumption';
            type = 'filter';
            break;
        case "RAM remaining in GB":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the amount of RAM that can be still used is less than or equal to %STATE% since at least 1 day';
            else
                definition =
                'Be alerted when the amount of RAM that can be still used is less than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'consumption';
            type = 'filter';
            break;
        case "CPU remaining in GHz":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when the amount of CPU that can be used is less than or equal to %STATE% since at least 1 day';
            else
                definition =
                'Be alerted when the amount of CPU that can be used is less than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'consumption';
            type = 'filter';
            break;
        case "Saturation date in CPU":
        case "Saturation date in DISK":
        case "Saturation date in RAM":
            th1 = data.th1;
            th2 = data.th2;
            definition = 'Be alerted %STATE% days before the saturation date';
            base = 'consumption';
            type = 'filter';
            break;
        case "Snapshot date":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition = 'Be alerted if the last snapshot is more than %STATE% day(s) old since at least 1 day';
            else
                definition = 'Be alerted if the last snapshot is more than %STATE% day(s) old since at least ' +
                frequency + ' days';
            base = 'consumption';
            type = 'filter';
            break;
        case "Snapshot number":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition = 'Be alerted when the number of snapshot per VM exceeds %STATE% since at least 1 day';
            else
                definition = 'Be alerted when the number of snapshot per VM exceeds %STATE% since at least ' +
                frequency + ' days';
            base = 'consumption';
            type = 'filter';
            break;
        case "Snapshot size in GB":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition = 'Be alerted when the amount of snapshot exceeds %STATE% since at least 1 day';
            else
                definition = 'Be alerted when the amount of snapshot exceeds %STATE% since at least ' + frequency +
                ' days';
            base = 'consumption';
            type = 'filter';
            break;
        case "VM consumed cost":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition = 'Be alerted when the VM cost is greater than or equal to %STATE% since at least 1 day';
            else
                definition = 'Be alerted when the VM cost is greater than or equal to %STATE% since at least ' +
                frequency + ' days';
            base = 'consumption';
            type = 'filter';
            break;
        case "VM remaining":
            th1 = data.th1;
            th2 = data.th2;
            definition = 'Be alerted when the number of VM(s) that can be created is less than or equal to %STATE%';
            base = 'consumption';
            type = 'filter';
            break;
        case "BUSY Behavior":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition = 'Be alerted when at least %STATE% VM(s) are BUSY since at least 1 day';
            else
                definition = 'Be alerted when at least %STATE% VM(s) are BUSY since at least ' + frequency + ' days';
            base = 'behavior';
            type = 'filter';
            break;
        case "IDLE Behavior":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition = 'Be alerted when at least %STATE% VM(s) are IDLE since at least 1 day';
            else
                definition = 'Be alerted when at least %STATE% VM(s) are IDLE since at least ' + frequency + ' days';
            base = 'behavior';
            type = 'filter';
            break;
        case "TROUBLESHOOTING CRITICAL Behavior":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition =
                'Be alerted when at least %STATE% VM(s) are CRITICAL in the troubleshooting part since at least 1 day';
            else
                definition =
                'Be alerted when at least %STATE% VM(s) are CRITICAL in the troubleshooting part since at least ' +
                frequency + ' days';
            base = 'behavior';
            type = 'filter';
            break;
        case "ZOMBIE Behavior":
            th1 = data.th1;
            th2 = data.th2;
            if (frequency == 1)
                definition = 'Be alerted when the amount of zombie exceeds %STATE% since at least 1 day';
            else
                definition = 'Be alerted when the amount of zombie exceeds %STATE% since at least ' + frequency +
                ' days';
            base = 'behavior';
            type = 'filter';
            break;
        default:
            break;
    }

    convObj = {
        identifier: identifier,
        type: type,
        threshold_1: th1,
        threshold_2: th2,
        frequency: frequency,
        definition: definition,
        base: base
    };

    return convObj;
}

export {
    commaBigNumber,
    getUserCurrency,
    isPair,
    getGranularityFromInterval,
    alertConvApiToIhm,
    alertConvIhmToApi
};
