<button class="btn btn-link btn-sm" style="float: right;size: 16px;" (click)="isSettings = true">
    <cds-icon shape="cog" size="16"></cds-icon>
</button>
<span style="float: right;color:grey;font-size:16px;">monitoring instance: {{message.cloudAccount}}</span>
<clr-modal [(clrModalOpen)]="isSettings" [clrModalClosable]=false>
	<h3 class="modal-title">
			Settings
	</h3>
	<div class="modal-body" style="overflow:auto">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:16px;">
						Selected an account
				</span>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
						<div class="clr-select-wrapper">
							<select class="clr-select" [(ngModel)]="model.accountSelected" (ngModelChange)="initLoadAccount()">
								<option *ngFor="let account of accounts">
									{{account.INSTANCE_NAME}}
								</option>
							</select>
						</div>
						<span class="clr-subtext">select an account</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer" style="margin-top:20px">
			<button type="button" class="btn btn-link" (click)="isSettings = false; reloadAccount()">OK</button>
	</div>
</clr-modal>