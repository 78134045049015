import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ComponentRef,
    HostListener
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    ManagementService,
    MonitorService,
    ShareService
} from '@app/services';

import {
    InstanceType,
    Message
} from '@app/model';

import {
    ConscalendarComponent
} from '@app/conscalendar/conscalendar.component';
import {
    ConscalendarDirective
} from '@app/directives/conscalendar.directive';
import {
    Subscription
} from 'rxjs';

var componentRef: ComponentRef < any > ;


@Component({
    selector: 'app-elementsynthesis',
    templateUrl: './elementsynthesis.component.html',
    styleUrls: ['./elementsynthesis.component.css']
})
export class ElementsynthesisComponent implements AfterViewChecked, AfterViewInit, OnInit {

    @ViewChild(ConscalendarDirective, {
        static: false
    }) addConsCal: ConscalendarDirective;

    message: Message;

    isContainerMgtReady: boolean = false;

    isDataReload: boolean = false;

    isVmwareReady: boolean = false;
    isAwsReady: boolean = false;
    isAzureReady: boolean = false;
    isGcpReady: boolean = false;
    isKubernetesReady: boolean = false;
    isXclarityReady: boolean = false;
    isOpenmanageReady: boolean = false;
    isOneviewReady: boolean = false;
    isIpmiReady: boolean = false;

    // For tabs
    tab: string;
    tab_subscription: Subscription;
    instancetype = InstanceType;


    constructor(private componentFactoryResolver: ComponentFactoryResolver, private mgt_svc: ManagementService,
        private message_svc: ShareService, private cd: ChangeDetectorRef, private monitor_svc: MonitorService) {}

    ngOnInit(): void {

        this.message_svc.currentMessage.subscribe(message => this.message = message);







        this.tab_subscription = this.monitor_svc.currentAppTab.subscribe((tab) => {
            this.tab = tab;

            this.isVmwareReady = false;
            this.isAwsReady = false;
            this.message.isValidAws = false;
            this.isAzureReady = false;
            this.message.isValidAzure = false;
            this.isGcpReady = false;
            this.message.isValidGcp = false;
            this.isKubernetesReady = false;
            this.isXclarityReady = false;
            this.isOpenmanageReady = false;
            this.isOneviewReady = false;
            this.isIpmiReady = false;

            this.message.powerUsageEnv = tab;

            switch (tab) {
                case InstanceType.VMWARE:
                    this.isVmwareReady = true;
                    break;
                case InstanceType.AWS:
                    this.mgt_svc.getRegions('aws').pipe(first()).subscribe(
                        data => {
                            if (data.length > 0)
                                this.message.isValidAws = true;
                        }
                    );
                    this.isAwsReady = true;
                    break;
                case InstanceType.AZURE:
                    this.mgt_svc.getRegions('azure').pipe(first()).subscribe(
                        data => {
                            if (data.length > 0)
                                this.message.isValidAzure = true;
                        }
                    );
                    this.isAzureReady = true;
                    break;
                case InstanceType.GCP:
                    this.mgt_svc.getRegions('gcp').pipe(first()).subscribe(
                        data => {
                            if (data.length > 0)
                                this.message.isValidGcp = true;
                        }
                    );
                    this.isGcpReady = true;
                    break;
                case InstanceType.KUBERNETES:
                    this.mgt_svc.getKubernetes().pipe(first()).subscribe(
                        data => {
                            if (data.length > 0)
                                this.message.isValidKubernetes = true;
                        }
                    );
                    this.isKubernetesReady = true;
                    break;
                case InstanceType.XCLARITY:
                    this.isXclarityReady = true;
                    break;
                case InstanceType.OPENMANAGE:
                    this.isOpenmanageReady = true;
                    break;
                case InstanceType.ONEVIEW:
                    this.isOneviewReady = true;
                    break;
                case InstanceType.IPMI:
                    this.isIpmiReady = true;
                    break;
                default:
                    break;
            }
        });
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.tab_subscription != undefined) {
            this.tab_subscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.loaddConsCal();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    loadCloud(): void {
        if (componentRef != undefined)
            componentRef.destroy();

        setTimeout(() => {
            this.loaddConsCal();
        }, 100);
    }

    loadCluster(): void {
        this.isDataReload = true;
        setTimeout(() => this.isDataReload = false, 100);
    }

    private loaddConsCal(): void {
        this.message.calcons_counter = 'power_float';
        if (this.addConsCal != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConscalendarComponent);
            const viewContainerRef = this.addConsCal.viewContainerRef;
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }
}
