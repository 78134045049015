import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    JSONTarget,
    Message,
    RecoServer,
    User
} from '@app/model';


@Component({
    selector: 'app-recommendationconsolidationdetail',
    templateUrl: './recommendationconsolidationdetail.component.html',
    styleUrls: ['./recommendationconsolidationdetail.component.css']
})
export class RecommendationconsolidationdetailComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: DataTables.Settings = {};

    dtTrigger: Subject < any > = new Subject();

    message: Message;

    reco_servers_data: RecoServer[] = [];

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.initDtOptions();

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_SERVER).subscribe(
            data => {
                this.reco_servers_data = data;
                if (this.reco_servers_data.length > 0)
                    this.reloadTable();
            }
        );
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
                $("[id*='reco-consolidation-detail_length']").css({
                    'padding-top': '5px'
                });
                $("[name*='reco-consolidation-detail_length']").css({
                    'padding': '0px'
                });
            },
            "order": [
                [0, "desc"]
            ],
            language: {
                loadingRecords: "loading .. ",
                zeroRecords: "loading ...",
                search: '',
                searchPlaceholder: " search a server..."
            },
            dom: '<"top">rt<"bottom"lifp>'
        };
    }
}
