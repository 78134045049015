<clr-datagrid>
	<clr-dg-column [clrDgField]="'element'" [clrDgSortOrder]="dataOrderDesc"><ng-container *clrDgHideableColumn="{hidden: false}">Element</ng-container></clr-dg-column>
	<clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Element type</ng-container></clr-dg-column>
	<clr-dg-column [clrDgField]="'alert'"><ng-container *clrDgHideableColumn="{hidden: false}">Alert</ng-container></clr-dg-column>
	<clr-dg-column [clrDgField]="'about'"><ng-container *clrDgHideableColumn="{hidden: false}">About</ng-container></clr-dg-column>
	
	<clr-dg-row *clrDgItems="let element of elements" [clrDgItem]="element">
		<clr-dg-cell>{{element.name}}</clr-dg-cell>
		<clr-dg-cell>{{element.type}}</clr-dg-cell>
		<clr-dg-cell>none</clr-dg-cell>
		<clr-dg-cell>running without alert</clr-dg-cell>
	</clr-dg-row>                  
	<clr-dg-footer>
		<clr-dg-pagination #element [clrDgPageSize]="10">
			<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
			{{element.firstItem + 1}} - {{element.lastItem + 1}} of {{element.totalItems}} entries
		</clr-dg-pagination>
	</clr-dg-footer>
</clr-datagrid>