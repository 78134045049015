import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-t2]',
})
export class T2Directive {
    constructor(public viewContainerRef2: ViewContainerRef) {}
}
