import {
    HttpClient
} from '@angular/common/http';
import {
    EventEmitter,
    Injectable
} from '@angular/core';
import {
    AsyncSubject,
    BehaviorSubject,
    Observable,
    zip
} from 'rxjs';
import {
    map
} from 'rxjs/operators';

import {
    environment
} from '@environments/environment';

import {
    Router
} from '@angular/router';
import {
    Json,
    JSONTarget,
    Message
} from '@app/model';
import {
    ShareService
} from '@app/services/share.service';

declare var require: any;


@Injectable({
    providedIn: 'root'
})
export class JsonloaderService {

    // Empty json returns by the API if no json file found
    API_EMPTY_RESULT: string = "{}";


    message: Message;

    /**
     *
     */
    json: Json = {
        dcInfraMin: null,
        rpSynthesis: null,
        clusterSynthesis: null,
        clustersPrediction: null,
        hostInfo: null,
        hostConsover: null,
        hostSynthesis: null,
        hostsPrediction: null,
        vmInfo: null,
        vmConsover: null,
        vmSynthesis: null,
        vmComputeStorage: null,
        vmComputeStorageReco: null,
        costAllocated: null,
        t1: null,
        t2: null,
        opportunities: null,
        t3a: null,
        t3b: null,
        t4: null,
        t5: null,
        t6: null,
        priceExchangeRates: null,
        priceUpdateInfo: null,
        storageOverviewsDatastoreData: null,
        storageOverviewsVmData: null,
        storageOverviewsDatastoreVmData: null,
        version: require('../../assets/version.json'),
        kpiHealth: null,
        dcviewTreeData: null,
        dcviewTreeStorageData: null,
        greenitPower: null
    };

    public currentJsonSubject: BehaviorSubject < Json > = new BehaviorSubject < Json > (this.json);
    public currentJson: Observable < Json > = this.currentJsonSubject.asObservable();
    public eventJsonAsyncLoaded = new EventEmitter < Json > ()
    public currentJsonSimpleSubject = new AsyncSubject < Json > ();

    /**
     *
     */
    constructor(private http: HttpClient, private router: Router,
        private message_svc: ShareService) {
        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    /**
     *
     */
    changeJson(user: string, filter: string): void {

        this.message.waiting = true;

        // Get json files
        let dcinframin = this.getData(user, filter, JSONTarget.DC_INFRA_MIN);
        let rpSynthesis = this.getData(user, filter, JSONTarget.RP_SYNTHESIS);
        let clusterSynthesis = this.getData(user, filter, JSONTarget.CLUSTER_SYNTHESIS);
        let clustersPrediction = this.getData(user, filter, JSONTarget.CLUSTERS_PREDICTION);
        let hostInfo = this.getData(user, filter, JSONTarget.HOST_INFO);
        let hostConsover = this.getData(user, filter, JSONTarget.HOST_CONSOVER);
        let hostSynthesis = this.getData(user, filter, JSONTarget.HOST_SYNTHESIS);
        let hostsPrediction = this.getData(user, filter, JSONTarget.HOSTS_PREDICTION);
        let vmInfo = this.getData(user, filter, JSONTarget.VM_INFO);
        let vmConsover = this.getData(user, filter, JSONTarget.VM_CONSOVER);
        let vmSynthesis = this.getData(user, filter, JSONTarget.VM_SYNTHESIS);
        let costAllocated = this.getData(user, filter, JSONTarget.COST_ALLOCATED);
        let t1 = this.getData(user, filter, JSONTarget.T1);
        let t2 = this.getData(user, filter, JSONTarget.T2);
        let opportunities = this.getData(user, filter, JSONTarget.OPPORTUNITIES);
        let t3a = this.getData(user, filter, JSONTarget.T3A);
        let t3b = this.getData(user, filter, JSONTarget.T3B);
        let t4 = this.getData(user, filter, JSONTarget.T4);
        let t5 = this.getData(user, filter, JSONTarget.T5);
        let t6 = this.getData(user, filter, JSONTarget.T6);
        let aws = this.getCloudPricingData(JSONTarget.CLOUD_AWS);
        let az = this.getCloudPricingData(JSONTarget.CLOUD_AZURE);
        let gcp = this.getCloudPricingData(JSONTarget.CLOUD_GCP);
        let ovh = this.getCloudPricingData(JSONTarget.CLOUD_OVH);
        let rates = this.getCloudPricingData(JSONTarget.CLOUD_RATES);
        let update_infos = this.getCloudPricingData(JSONTarget.CLOUD_UPDATE_INFO);
        let storageOverviewsDatastoreData = this.getData(user, filter, JSONTarget.STORAGE_OVERVIEWS_DS);
        let storageOverviewsVmData = this.getData(user, filter, JSONTarget.STORAGE_OVERVIEWS_VM);
        let storageOverviewsDatastoreVmData = this.getData(user, filter, JSONTarget.DCVIEW_TREE_STORAGE_DATA);
        let kpiHealth = this.getData(user, filter, JSONTarget.KPI_HEALTH);

        let vmComputeStorage = this.getData(user, filter, JSONTarget.VM_COMPUTE_STORAGE);
        let vmComputeStorageReco = this.getData(user, filter, JSONTarget.VM_COMPUTE_STORAGE_RECO);
        let dcViewTreeData = this.getData(user, filter, JSONTarget.DCVIEW_TREE_DATA);
        let dcViewTreeStorageData = this.getData(user, filter, JSONTarget.DCVIEW_TREE_STORAGE_DATA);
        let greenitPower = this.getData(user, filter, JSONTarget.GREENIT_POWER);

        // wait until all observables have emitted a value
        zip(dcinframin, rpSynthesis, clusterSynthesis, clustersPrediction, hostInfo, hostConsover, hostSynthesis,
            hostsPrediction,
            vmInfo, vmConsover, vmSynthesis, costAllocated, t1, t2, opportunities, t3a, t3b, t4, t5, t6,
            aws, az, gcp, ovh, rates, update_infos, storageOverviewsDatastoreData, storageOverviewsVmData,
            storageOverviewsDatastoreVmData,
            kpiHealth, vmComputeStorage, vmComputeStorageReco, dcViewTreeData, dcViewTreeStorageData,
            greenitPower).subscribe(

            all => {
                // Notify
                this.currentJsonSubject.next(this.json);
                this.eventJsonAsyncLoaded.emit(this.json);
                this.currentJsonSimpleSubject.next(this.json);
                this.currentJsonSimpleSubject.complete();

                let whiteList = ["/god", "/cloud/*", "/cloudimpact", "/my_cloud_pricing/*", "/dcviews/*",
                    "/storage-overview", "/storage-overview-vm", "/netscope/*"
                ];

                if (whiteList.map((p) => this.router.url.match(p)).filter((v) => v !== null).length) {
                    // Do nothing: these components don't need to be reloaded!
                } else {
                    // Route to dashboard
                    if (this.router.url != "/dashboard") {
                        this.router.navigate(['/dashboard']);
                    }
                }
                this.message.waiting = false;

            },
            error => {
                console.log(error);

                // empty json
                this.resetJson();

                this.message.waiting = false;
            }
        );
    }

    /**
     *
     */
    initJson() {
        this.json = {
            dcInfraMin: null,
            rpSynthesis: null,
            clusterSynthesis: null,
            clustersPrediction: null,
            hostInfo: null,
            hostConsover: null,
            hostSynthesis: null,
            hostsPrediction: null,
            vmInfo: null,
            vmConsover: null,
            vmSynthesis: null,
            vmComputeStorage: null,
            vmComputeStorageReco: null,
            costAllocated: null,
            t1: null,
            t2: null,
            opportunities: null,
            t3a: null,
            t3b: null,
            t4: null,
            t5: null,
            t6: null,
            priceExchangeRates: null,
            priceUpdateInfo: null,
            storageOverviewsDatastoreData: null,
            storageOverviewsVmData: null,
            storageOverviewsDatastoreVmData: null,
            version: require('../../assets/version.json'),
            kpiHealth: null,
            dcviewTreeData: null,
            dcviewTreeStorageData: null,
            greenitPower: null
        };
    }

    /**
     *
     */
    initJsonVmCons(): void {
        this.json.vmConsover = null;
        // Notify
        this.currentJsonSubject.next(this.json);
    }

    /**
     *
     */
    reloadJsonCloudPricing(): void {

        // Get cloud pricing jsons
        let aws = this.getCloudPricingData(JSONTarget.CLOUD_AWS);
        let az = this.getCloudPricingData(JSONTarget.CLOUD_AZURE);
        let gcp = this.getCloudPricingData(JSONTarget.CLOUD_GCP);
        let ovh = this.getCloudPricingData(JSONTarget.CLOUD_OVH);
        let rates = this.getCloudPricingData(JSONTarget.CLOUD_RATES);
        let infos = this.getCloudPricingData(JSONTarget.CLOUD_UPDATE_INFO);

        zip(aws, az, gcp, ovh, rates, infos).subscribe(
            all => {
                // Notify
                this.currentJsonSubject.next(this.json);
            }
        );
    }

    /**
     *
     */
    getData(user: string, filter: string, target: JSONTarget) {
        let apiEndpoint = 'undefinedendpoint';
        switch (target) {
            case JSONTarget.AWS_VIEW:
                apiEndpoint = 'awsview';
                break;
            case JSONTarget.AWS_VIEW_SCOPE:
                apiEndpoint = 'awsviewscope';
                break;
            case JSONTarget.AZURE_VIEW:
                apiEndpoint = 'azureview';
                break;
            case JSONTarget.AZURE_VIEW_SCOPE:
                apiEndpoint = 'azureviewscope';
                break;
            case JSONTarget.CAPA_DS:
                apiEndpoint = 'datastorecapacity';
                break;
            case JSONTarget.CAPA_INFRA:
                apiEndpoint = 'infraprediction';
                break;
            case JSONTarget.CAPA_SIMU:
                apiEndpoint = 'simulationprediction';
                break;
            case JSONTarget.CAPA_SIMU_CONFIG:
                apiEndpoint = 'simulationconfig';
                break;
            case JSONTarget.CAPA_SRV:
                apiEndpoint = 'hostcapacity';
                break;
            case JSONTarget.CAPA_VM:
                apiEndpoint = 'vmcapacity';
                break;
            case JSONTarget.CLOUD_RATES:
                apiEndpoint = 'exchangerates';
                break;
            case JSONTarget.CLUSTERS_PREDICTION:
                apiEndpoint = 'clusterprediction';
                break;
            case JSONTarget.CLUSTER_SYNTHESIS:
                apiEndpoint = 'clustersynthesis';
                break;
            case JSONTarget.COST_ALLOCATED:
                apiEndpoint = 'allocatedcosts';
                break;
            case JSONTarget.COST_CONSUMED:
                apiEndpoint = 'consumedcosts';
                break;
            case JSONTarget.COST_WASTED:
                apiEndpoint = 'wastedcosts';
                break;
            case JSONTarget.DC_INFRA_MIN:
                apiEndpoint = 'dcinfra';
                break;
            case JSONTarget.DCVIEW_TREE_STORAGE_DATA:
                apiEndpoint = 'storagetreemap';
                break;
            case JSONTarget.DCVIEW_TREE_DATA:
                apiEndpoint = 'treemap';
                break;
            case JSONTarget.DS_VIEW:
                apiEndpoint = 'datastoreview';
                break;
            case JSONTarget.FD_VIEW:
                apiEndpoint = 'folderview';
                break;
            case JSONTarget.FD_VIEW_EXAMPLE:
                apiEndpoint = 'folderviewexample';
                break;
            case JSONTarget.GREENIT_DC_CEE:
                apiEndpoint = 'greenitserverscee';
                break;
            case JSONTarget.GREENIT_IPMI_CEE:
                apiEndpoint = 'greenitipmicee';
                break;
            case JSONTarget.GREENIT_NETWORK_CEE:
                apiEndpoint = 'greenitnetworkcee';
                break;
            case JSONTarget.GREENIT_ONEVIEW_CEE:
                apiEndpoint = 'greenitoneviewcee';
                break;
            case JSONTarget.GREENIT_OPENMANAGE_CEE:
                apiEndpoint = 'greenitopenmanagecee';
                break;
            case JSONTarget.GREENIT_POWER:
                apiEndpoint = 'greenitpower';
                break;
            case JSONTarget.GREENIT_RECO:
                apiEndpoint = 'greenitrecommendations';
                break;
            case JSONTarget.GREENIT_REFNODES_LIST:
                apiEndpoint = 'greenitrefnodeslist';
                break;
            case JSONTarget.GREENIT_RESIZE:
                apiEndpoint = 'greenitresize';
                break;
            case JSONTarget.GREENIT_SCAPH_CEE:
                apiEndpoint = 'greenitscaphandrecee';
                break;
            case JSONTarget.GREENIT_SCAPH_PROCESS:
                apiEndpoint = 'greenitscaphandreprocess';
                break;
            case JSONTarget.GREENIT_SERVERS:
                apiEndpoint = 'greenitservers';
                break;
            case JSONTarget.GREENIT_STORAGE_CEE:
                apiEndpoint = 'greenitstoragecee';
                break;
            case JSONTarget.GREENIT_XCLARITY_CEE:
                apiEndpoint = 'greenitxclaritycee';
                break;
            case JSONTarget.HOST_CONSOVER:
                apiEndpoint = 'hostconsumptions';
                break;
            case JSONTarget.HOST_INFO:
                apiEndpoint = 'hostinfo';
                break;
            case JSONTarget.HOST_SYNTHESIS:
                apiEndpoint = 'hostsynthesis';
                break;
            case JSONTarget.HOST_VIEW:
                apiEndpoint = 'hostview';
                break;
            case JSONTarget.HOST_VIEW_EXAMPLE:
                apiEndpoint = 'hostviewexample';
                break;
            case JSONTarget.HOST_VIEW_SCOPE:
                apiEndpoint = 'hostviewscope';
                break;
            case JSONTarget.HOSTS_PREDICTION:
                apiEndpoint = 'hostprediction';
                break;
            case JSONTarget.KPI_ACTIVITY:
                apiEndpoint = 'kpiactivity';
                break;
            case JSONTarget.KPI_BEHAVIOR:
                apiEndpoint = 'kpibehavior';
                break;
            case JSONTarget.KPI_CAPACITY:
                apiEndpoint = 'kpicapacity';
                break;
            case JSONTarget.KPI_COST:
                apiEndpoint = 'kpicost';
                break;
            case JSONTarget.KPI_HEALTH:
                apiEndpoint = 'kpihealth';
                break;
            case JSONTarget.KPI_META:
                apiEndpoint = 'kpiconsumption';
                break;
            case JSONTarget.KUBE_VIEW:
                apiEndpoint = 'kubeview';
                break;
            case JSONTarget.KUBERNETES_BEHAVIOR:
                apiEndpoint = 'kubebehavior';
                break;
            case JSONTarget.KUBERNETES_EVOLUTION:
                apiEndpoint = 'kubeevolution';
                break;
            case JSONTarget.KUBERNETES_GREENIT:
                apiEndpoint = 'kubegreenit';
                break;
            case JSONTarget.KUBERNETES_METADATA:
                apiEndpoint = 'kubedata';
                break;
            case JSONTarget.KUBERNETES_MONTH:
                apiEndpoint = 'kubemonth';
                break;
            case JSONTarget.KUBERNETES_NODE:
                apiEndpoint = 'kubenodes';
                break;
            case JSONTarget.KUBERNETES_POD:
                apiEndpoint = 'kubepods';
                break;
            case JSONTarget.KUBERNETES_CONTAINER:
                apiEndpoint = 'kubecontainer';
                break;
            case JSONTarget.KUBERNETES_STRUCTURE:
                apiEndpoint = 'kubestructure';
                break;
            case JSONTarget.KUBERNETES_TREEMAP:
                apiEndpoint = 'kubetreemap';
                break;
            case JSONTarget.MULTI_VIEW:
                apiEndpoint = 'multiview';
                break;
            case JSONTarget.MULTI_VIEW_SCOPE:
                apiEndpoint = 'multiviewscope';
                break;
            case JSONTarget.NETWORK_VIEW:
                apiEndpoint = 'networkview';
                break;
            case JSONTarget.ONEVIEW_VIEW:
                apiEndpoint = 'oneviewview';
                break;
            case JSONTarget.OPENMANAGE_VIEW:
                apiEndpoint = 'openmanageview';
                break;
            case JSONTarget.OPPORTUNITIES:
                apiEndpoint = 'opportunities';
                break;
            case JSONTarget.RECO_VM:
                apiEndpoint = 'vmrecommendations';
                break;
            case JSONTarget.RECO_META:
                apiEndpoint = 'recommendations';
                break;
            case JSONTarget.RECO_SERVER:
                apiEndpoint = 'hostrecommendations';
                break;
            case JSONTarget.RP_PREDICTION:
                apiEndpoint = 'resourcepoolprediction';
                break;
            case JSONTarget.RP_SYNTHESIS:
                apiEndpoint = 'resourcepoolsynthesis';
                break;
            case JSONTarget.RP_VIEW:
                apiEndpoint = 'resourcepoolview';
                break;
            case JSONTarget.RP_VIEW_EXAMPLE:
                apiEndpoint = 'resourcepoolviewexample';
                break;
            case JSONTarget.SCAPHANDRE_VIEW:
                apiEndpoint = 'scaphandreview';
                break;
            case JSONTarget.STORAGE_OVERVIEWS_DS:
                apiEndpoint = 'datastorestorage';
                break;
            case JSONTarget.STORAGE_OVERVIEWS_VM:
                apiEndpoint = 'vmstorage';
                break;
            case JSONTarget.STORAGE_VIEW:
                apiEndpoint = 'storageview';
                break;
            case JSONTarget.T1:
                apiEndpoint = 'vmpie';
                break;
            case JSONTarget.T2:
                apiEndpoint = 'prediction';
                break;
            case JSONTarget.T3A:
                apiEndpoint = 'opportunitiesafter';
                break;
            case JSONTarget.T3B:
                apiEndpoint = 'opportunitiesbefore';
                break;
            case JSONTarget.T4:
                apiEndpoint = 'dcsummary';
                break;
            case JSONTarget.T5:
                apiEndpoint = 'dccapacity';
                break;
            case JSONTarget.T6:
                apiEndpoint = 'costs';
                break;
            case JSONTarget.T8:
                apiEndpoint = 'greenitpredictions';
                break;
            case JSONTarget.T8_ALL:
                apiEndpoint = 'greenitallpredictions';
                break;
            case JSONTarget.T8_IPMI:
                apiEndpoint = 'greenitipmipredictions';
                break;
            case JSONTarget.T8_NETWORK:
                apiEndpoint = 'greenitnetworkpredictions';
                break;
            case JSONTarget.T8_ONEVIEW:
                apiEndpoint = 'greenitoneviewpredictions';
                break;
            case JSONTarget.T8_OPENMANAGE:
                apiEndpoint = 'greenitopenmanagepredictions';
                break;
            case JSONTarget.T8_SCAPHANDRE:
                apiEndpoint = 'greenitscaphandrepredictions';
                break;
            case JSONTarget.T8_STORAGE:
                apiEndpoint = 'greenitstoragepredictions';
                break;
            case JSONTarget.T8_XCLARITY:
                apiEndpoint = 'greenitxclaritypredictions';
                break;
            case JSONTarget.TAG_VIEW:
                apiEndpoint = 'tagview';
                break;
            case JSONTarget.TAG_VIEW_EXAMPLE:
                apiEndpoint = 'tagviewexample';
                break;
            case JSONTarget.TROUBLE_MAP:
                apiEndpoint = 'troubleshooting';
                break;
            case JSONTarget.VDC_INFOS:
                apiEndpoint = 'vcloudinformation';
                break;
            case JSONTarget.VDC_PREDICTION:
                apiEndpoint = 'vcloudprediction';
                break;
            case JSONTarget.VDC_SYNTHESIS:
                apiEndpoint = 'vcloudsynthesis';
                break;
            case JSONTarget.VIRT_INDEX:
                apiEndpoint = 'greenitindex';
                break;
            case JSONTarget.VM_COMPUTE_STORAGE:
                apiEndpoint = 'vmcomputestorage';
                break;
            case JSONTarget.VM_COMPUTE_STORAGE_RECO:
                apiEndpoint = 'vmstoragerecommendations';
                break;
            case JSONTarget.VM_CONSOVER:
                apiEndpoint = 'vmconsumptions';
                break;
            case JSONTarget.VM_INFO:
                apiEndpoint = 'vminfo';
                break;
            case JSONTarget.VM_SYNTHESIS:
                apiEndpoint = 'vmsynthesis';
                break;
            case JSONTarget.XCLARITY_VIEW:
                apiEndpoint = 'xclarityview';
                break;
            case JSONTarget.IPMI_VIEW:
                apiEndpoint = 'ipmiview';
                break;
            case JSONTarget.EXAGRID_VIEW:
                apiEndpoint = 'exagridview';
                break;
            case JSONTarget.IBM_VIEW:
                apiEndpoint = 'ibmview';
                break;
            default:
                let error = 'undefined endpoint: ' + target;
                console.log(error);
                alert(error);
        };
        let uriWithParameters = 'json/' + apiEndpoint + '?user=' + user + '&filter=' + filter;
        return this.http.get < any > (`${environment.apiUrl}/` + uriWithParameters).pipe(map(
            data => {
                //Treat empty data
                if (JSON.stringify(data) == this.API_EMPTY_RESULT) {
                    data = null;
                }

                switch (target) {
                    case JSONTarget.DC_INFRA_MIN:
                        this.json.dcInfraMin = data;
                        break;
                    case JSONTarget.RP_SYNTHESIS:
                        this.json.rpSynthesis = data;
                        break;
                    case JSONTarget.CLUSTER_SYNTHESIS:
                        this.json.clusterSynthesis = data;
                        break;
                    case JSONTarget.CLUSTERS_PREDICTION:
                        this.json.clustersPrediction = data;
                        break;
                    case JSONTarget.HOST_INFO:
                        this.json.hostInfo = data;
                        break;
                    case JSONTarget.HOST_CONSOVER:
                        this.json.hostConsover = data;
                        break;
                    case JSONTarget.HOST_SYNTHESIS:
                        this.json.hostSynthesis = data;
                        break;
                    case JSONTarget.HOSTS_PREDICTION:
                        this.json.hostsPrediction = data;
                        break;
                    case JSONTarget.VM_INFO:
                        this.json.vmInfo = data;
                        break;
                    case JSONTarget.VM_CONSOVER:
                        this.json.vmConsover = data;
                        break;
                    case JSONTarget.VM_SYNTHESIS:
                        this.json.vmSynthesis = data;
                        break;
                    case JSONTarget.COST_ALLOCATED:
                        this.json.costAllocated = data;
                        break;
                    case JSONTarget.T1:
                        this.json.t1 = data;
                        break;
                    case JSONTarget.T2:
                        this.json.t2 = data;
                        break;
                    case JSONTarget.OPPORTUNITIES:
                        this.json.opportunities = data;
                        break;
                    case JSONTarget.T3A:
                        this.json.t3a = data;
                        break;
                    case JSONTarget.T3B:
                        this.json.t3b = data;
                        break;
                    case JSONTarget.T4:
                        this.json.t4 = data;
                        break;
                    case JSONTarget.T5:
                        this.json.t5 = data;
                        break;
                    case JSONTarget.T6:
                        this.json.t6 = data;
                        break;
                    case JSONTarget.STORAGE_OVERVIEWS_DS:
                        this.json.storageOverviewsDatastoreData = data;
                        break;
                    case JSONTarget.STORAGE_OVERVIEWS_VM:
                        this.json.storageOverviewsVmData = data;
                        break;
                    case JSONTarget.DCVIEW_TREE_STORAGE_DATA:
                        this.json.storageOverviewsDatastoreVmData = data;
                        this.json.dcviewTreeStorageData = data;
                        break;
                    case JSONTarget.DCVIEW_TREE_DATA:
                        this.json.dcviewTreeData = data;
                        break;
                    case JSONTarget.KPI_HEALTH:
                        this.json.kpiHealth = data;
                        break;
                    case JSONTarget.VM_COMPUTE_STORAGE:
                        this.json.vmComputeStorage = data;
                        break;
                    case JSONTarget.VM_COMPUTE_STORAGE_RECO:
                        this.json.vmComputeStorageReco = data;
                        break;
                    case JSONTarget.GREENIT_POWER:
                        this.json.greenitPower = data;
                        break;
                }

                return data;
            }
        ));
    }

    /**
     *
     */
    getCloudData(user: string, filter: string, path: string, target: JSONTarget) {
        let apiEndpoint = 'undefinedendpoint';
        switch (target) {
            case JSONTarget.CLOUD_AGE_STRUCTURE:
                apiEndpoint = 'agestructure';
                break;
            case JSONTarget.CLOUD_BEHAVIOR:
                apiEndpoint = 'currentbehavior';
                break;
            case JSONTarget.CLOUD_CO2:
                apiEndpoint = 'currentco2';
                break;
            case JSONTarget.CLOUD_GLOBAL_CO2:
                apiEndpoint = 'globalco2';
                break;
            case JSONTarget.CLOUD_GLOBAL_COST:
                apiEndpoint = 'globalcost';
                break;
            case JSONTarget.CLOUD_GRAPH_REGION2:
                apiEndpoint = 'graphregion2';
                break;
            case JSONTarget.CLOUD_INSTANCE:
                apiEndpoint = 'cloudinstance';
                break;
            case JSONTarget.CLOUD_INSTANCE_CURRENT_COST:
                apiEndpoint = 'currentcost';
                break;
            case JSONTarget.CLOUD_INSTANCE_MONTH_CO2:
                apiEndpoint = 'monthco2';
                break;
            case JSONTarget.CLOUD_INSTANCE_MONTH_COST:
                apiEndpoint = 'monthcost';
                break;
            case JSONTarget.CLOUD_KPI_EVOLUTION:
                apiEndpoint = 'kpievolution';
                break;
            case JSONTarget.CLOUD_KPI_SAVINGSPLAN:
                apiEndpoint = 'savingsplan';
                break;
            case JSONTarget.CLOUD_KPI_CREDITS:
                apiEndpoint = 'kpicredits';
                break;
            case JSONTarget.CLOUD_METADATA:
                apiEndpoint = 'clouddata';
                break;
            case JSONTarget.CLOUD_MONTH:
                apiEndpoint = 'cloudmonth';
                break;
            case JSONTarget.CLOUD_OPP_SPOT:
                apiEndpoint = 'oppspot';
                break;
            case JSONTarget.CLOUD_OVERVIEW_EVOLUTION:
                apiEndpoint = 'overviewevolution';
                break;
            case JSONTarget.CLOUD_RESIZING:
                apiEndpoint = 'cloudresizing';
                break;
            case JSONTarget.CLOUD_SAVINGSPLAN_SOLUTION:
                apiEndpoint = 'savingssolution';
                break;
            case JSONTarget.CLOUD_TREEMAP:
                apiEndpoint = 'cloudtreemap';
                break;
            default:
                alert("undefined endpoint for /json/cloudmetadata: " + target);
        }
        return this.http.get < any > (`${environment.apiUrl}/json/` + apiEndpoint +
            '?user=' + user + '&filter=' + filter + '&directory=' + path).pipe(map(

            data => {
                //Treat empty data
                if (JSON.stringify(data) == this.API_EMPTY_RESULT) {
                    data = null;
                }

                return data;
            }
        ));
    }

    /**
     *
     */
    getCloudPricingData(target: JSONTarget) {
        let uriWithParameters = 'undefinedendpoint';
        switch (target) {
            case JSONTarget.CLOUD_RATES:
                uriWithParameters = 'exchangerates';
                break;
            case JSONTarget.CLOUD_UPDATE_INFO:
                uriWithParameters = 'updateinfo';
                break;
            default:
                uriWithParameters = 'cloudpricingdata?provider=' + target;
                break;
        }
        return this.http.get < any > (`${environment.apiUrl}/json/` + uriWithParameters).pipe(map(
            data => {
                //Treat empty data
                if (JSON.stringify(data) == this.API_EMPTY_RESULT) {
                    data = null;
                }
                switch (target) {
                    case JSONTarget.CLOUD_RATES:
                        this.json.priceExchangeRates = data;
                        break;
                    case JSONTarget.CLOUD_UPDATE_INFO:
                        this.json.priceUpdateInfo = data;
                        break;
                }
                return data;
            }
        ));
    }

    // Check jsons
    checkJson(user: string, filter: string) {
        let json_o: any = {
            user: user,
            filter: filter,
            process: 'main'
        };
        return this.http.post(`${environment.apiUrl}/json/check`, json_o);
    }

    // Clean jsons
    cleanJson(user: string, filter: string) {
        let json_o: any = {
            user: user,
            filter: filter
        };
        return this.http.post(`${environment.apiUrl}/json/clean`, json_o);
    }

    // Clean all jsons
    cleanAllJson() {
        return this.http.delete(`${environment.apiUrl}/` + 'json/cleanall');
    }

    resetJson() {
        this.initJson();
        // Notify
        this.currentJsonSubject.next(this.json);
        this.eventJsonAsyncLoaded.emit(this.json);
        this.currentJsonSimpleSubject.next(this.json);
        this.currentJsonSimpleSubject.complete();
    }


}
