import * as d3 from "d3";
import {
    ComboboxSelectionItem,
    NetscopeCommonFlowviewComponent
} from "@app/netscope/netscope-common-flowview/netscope-common-flowview.component";
import {FlowRessourceObject} from "@app/netscope/netscope-flows-datagrid/netscope-flows-datagrid.component";


export class AppsGroupsRenderer {

    setSelection = (flowComponent: NetscopeCommonFlowviewComponent, newSelection, groupsAppsOnlyHighlighted, linksOnlyHighlighted) => {
        let groupsAppsColorIndex = {};

        let i = 0;
        for (let groupApp of flowComponent.groupsApps) {
            groupsAppsColorIndex[groupApp.name] = flowComponent.groupsAppsColorPalette[i % (flowComponent.groupsAppsColorPalette.length)];
            groupApp.color = groupsAppsColorIndex[groupApp.name];
            i += 1;
        }

        let selectedGroupsIds = flowComponent.selectedAppsGroups.map((g) => g.id);
        let highlightedGroupsIds = groupsAppsOnlyHighlighted.map((g) => g.id);

        let isHighlightedLineFunc = (line) =>
            (selectedGroupsIds.includes(line.source.id) && selectedGroupsIds.includes(line.target.id))
            // @ts-ignore
            || (linksOnlyHighlighted.some((d2) => line.source.id === d2.source.id && line.target.id === d2.target.id));

        let isHighlightedCircleFunc = (circle) =>
            selectedGroupsIds.includes(circle.id)
            // @ts-ignore
            || highlightedGroupsIds.includes(circle.id)

        // Prepare lines and circles
        let lines = d3.selectAll("line")
            .filter((d) => d !== undefined);
        let circles = d3.selectAll("circle")
            .filter((d) => d !== undefined);

        let highlightedLines = lines
            .filter((d) => isHighlightedLineFunc(d));
        let nonHighlightedLines = lines
            .filter((d) => !isHighlightedLineFunc(d));
        let highlightedCircles = circles
            .filter((d) => isHighlightedCircleFunc(d));
        let nonHighlightedCircles = circles
            .filter((d) => !isHighlightedCircleFunc(d));

        // All links become gray
        nonHighlightedLines
            .style('cursor', 'default')
            .transition()
            .duration(200)
            .style("stroke", "#aaa");

        // Reset color of circles
        nonHighlightedCircles
            .style('cursor', 'pointer')
            .transition()
            .duration(200)
            // @ts-ignore
            .style("stroke", (d2) => groupsAppsColorIndex[d2.name]);

        // Highlight select apps groups
        highlightedLines
            .style('cursor', 'pointer')
            .transition()
            .duration(200)
            .style("stroke", "orange");

        highlightedCircles
            .style('cursor', 'pointer')
            .transition()
            .duration(200)
            .style("stroke", "orange");

        // Update the toolbar
        let topbar = d3.select("#dcnetscope-visualisation-topbar");
        topbar
            .style("opacity", 1.0);
    }

    render(graphData, flowComponent: NetscopeCommonFlowviewComponent): void {
        let groupData = []
        // Create data summarising Apps groups and their respective connexions
        for (let appGroup of flowComponent.groupsApps) {
            let appGroupVmIdx = {};
            for (let vm of appGroup.vms) {
                // appGroupVmIdx[`vim.VirtualMachine:${vm.uuid}`] = true;
                appGroupVmIdx[`${vm.uuid}`] = true;
            }
            let totalExchangedBytes = graphData.links
                .filter((link) => appGroupVmIdx[link.source.uuid] || appGroupVmIdx[link.target.uuid])
                .map((a) => a.exchanged_bytes)
                .reduce((a, b) => a + b, 0);
            let totalExchangedBytesWithOutside = graphData.links
                .filter((link) => appGroupVmIdx[link.source.uuid] || appGroupVmIdx[link.target.uuid])
                .filter((link) => !(appGroupVmIdx[link.source.uuid] && appGroupVmIdx[link.target.uuid]))
                .map((a) => a.exchanged_bytes)
                .reduce((a, b) => a + b, 0);

            groupData.push({
                appGroup: appGroup,
                totalExchangedBytes: totalExchangedBytes,
                totalExchangedBytesWithOutside: totalExchangedBytesWithOutside,
                vmsUuids: Object.keys(appGroupVmIdx)
            });
        }

        // Define a map of the names of displayed resources
        let vmsNamesIdx = {}
        for (let vm of graphData.vms) {
            let shortUuid = vm.uuid.split(":")[1];
            vmsNamesIdx[vm.uuid] = vm.name
            vmsNamesIdx[shortUuid] = vm.name
        }

        // Define the links between Apps groups
        let vmsIdx = {}
        for (let group of groupData) {
            for (let vmUuid of group.vmsUuids) {
                if (vmsIdx[vmUuid] === undefined) {
                    vmsIdx[vmUuid] = [];
                }
                vmsIdx[vmUuid].push(group.appGroup.name);
            }
        }

        let linksIdx = {};
        let filteredLinks = [];
        for (let group of groupData) {
            let srcGroupName = group.appGroup.name;
            let appGroupVmIdx = {};
            for (let vmUuid of group.vmsUuids) {
                appGroupVmIdx[vmUuid] = true;
            }
            let linksWithOutside = graphData.links
                .filter((link) => appGroupVmIdx[link.source.uuid] || appGroupVmIdx[link.target.uuid])
                .filter((link) => appGroupVmIdx[link.source.uuid] !== undefined)
                .filter((link) => !(appGroupVmIdx[link.source.uuid] && appGroupVmIdx[link.target.uuid]));
            let appGroupNamesIdx = {};
            for (let link of linksWithOutside) {
                let srcGroupsApps = vmsIdx[link.source.uuid];
                let targetGroupsApps = vmsIdx[link.target.uuid];

                if (srcGroupsApps === undefined || targetGroupsApps === undefined) {
                    continue;
                }

                let commonGroupsApps = targetGroupsApps.filter((g) => srcGroupsApps.includes(g));

                if (commonGroupsApps.length === 0) {
                    for (let vmUuid of [link.source.uuid, link.target.uuid]) {
                        if (vmsIdx[vmUuid] !== undefined) {
                            let relevantRemoteAppsGroupsNames = vmsIdx[vmUuid]
                                .filter((appGroupName) => appGroupName !== srcGroupName)
                                .filter((appGroupName) => appGroupNamesIdx[appGroupName] === undefined);

                            for (let groupName of relevantRemoteAppsGroupsNames) {
                                // let key = `${srcGroupName}__${groupName}`;
                                let key = [srcGroupName, groupName].sort().join("__");
                                if (linksIdx[key] === undefined) {
                                    linksIdx[key] = {
                                        source_name: srcGroupName,
                                        target_name: groupName,
                                        value: 0,
                                        links: []
                                    }
                                }
                                linksIdx[key].value += link.exchanged_bytes;
                                linksIdx[key].links.push(link);
                                filteredLinks.push(link);
                            }
                        }
                    }
                }
            }
        }
        flowComponent.filteredLinks = filteredLinks;

        // Draw the visualisation

        let totalBytes = groupData.map((g) => g.totalExchangedBytes).reduce((a, b) => a + b, 0);
        let minValue = Math.min(...groupData.map((g) => g.totalExchangedBytes));
        let maxValue = Math.max(...groupData.map((g) => g.totalExchangedBytes));

        if (minValue === maxValue) {
            maxValue = minValue + 1;
        }

        let maxNodeRadius = 100;
        let minNodeRadius = 30;

        // @ts-ignore
        let minValueLink = Math.min(...Object.entries(linksIdx).map(([k, v]) => v.value));
        // @ts-ignore
        let maxValueLink = Math.max(...Object.entries(linksIdx).map(([k, v]) => v.value));

        let minLinkStrokeWidth = 3;
        let maxLinkStrokeWidth = 15;

        let links = Object.entries(linksIdx).map(([k, v]) => Object({
            // @ts-ignore
            source: v.source_name,
            // @ts-ignore
            target: v.target_name,
            value: 10,
            // @ts-ignore
            strokeWidth: minLinkStrokeWidth + (v.value - minValueLink) / (maxValueLink - minValueLink) * (maxLinkStrokeWidth - minLinkStrokeWidth)
        }));
        let graphDataNodes = groupData.map((g) => Object({
            id: g.appGroup.name,
            name: g.appGroup.name,
            value: g.totalExchangedBytes,
            vms: g.vmsUuids,
            r: minNodeRadius + (g.totalExchangedBytes - minValue) / (maxValue - minValue) * (
                maxNodeRadius - minNodeRadius)
        }));

        let groupsAppsColorIndex = {};

        let i = 0;
        for (let groupApp of flowComponent.groupsApps) {
            groupsAppsColorIndex[groupApp.name] = flowComponent.groupsAppsColorPalette[i % (flowComponent
                .groupsAppsColorPalette.length)];
            groupApp.color = groupsAppsColorIndex[groupApp.name];
            i += 1;
        }

        //
        // START RENDERING GRAPHICS
        //

        let svgHeight = document.getElementById('divSvg').clientHeight;
        let svgWidth = document.getElementById('divSvg').clientWidth;

        // @ts-ignore
        d3.select('div#divSvg').select("svg").remove();
        const svg = d3.select('div#divSvg')
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .on("click", (d, i) => {
                // Remove a previously open dropdown menus
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }
            });

        flowComponent.svg = svg;

        // @ts-ignore
        let ratioMinSizeOverMaxSize = 10;

        // Add zoom
        svg.call(d3.zoom()
            .extent([
                [0, 0],
                [svgWidth, svgHeight]
            ])
            .scaleExtent([0.01, ratioMinSizeOverMaxSize])
            .on("zoom", zoomed));

        const g = svg.append("g");

        const self = flowComponent;

        function zoomed({
            transform
        }) {
            self.graphParameters.userMovedMap = true;
            self.graphParameters.lastTransformation = transform;
            g.attr("transform", transform);
        }

        let visuNode;

        let renderer = this;

        const link = g.append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(links)
            .enter().append('line')
            .attr('stroke', (d) => {
                return `#aaa`;
            })
            .style('filter', 'brightness(130%)')
            .style("opacity", (d) => 1.0)
            .on('mouseover', function(d, i) {
                d3.select(this).style('cursor', 'pointer');
            })
            .on('mouseout', function(d, i) {
                d3.select(this).style('cursor', 'default');
            })
            .on("click", function(d, i) {
                d.stopPropagation();

                // @ts-ignore
                let node_id = `${i.source.id}_${i.target.id}`.replaceAll(":", "__").replaceAll(".", "__");
                let dropdownId = 'dropdownmenu_' + node_id;

                let resourceUuidsAssociatedToThisLink = [i.source.id, i.target.id];
                let key = resourceUuidsAssociatedToThisLink.sort().join("__");
                let linksObject = linksIdx[key];
                let relevantMetrics = linksObject.links
                    .map((l) => l.metrics)
                    .flat()
                    .sort((a, b) => b.exchanged_bytes - a.exchanged_bytes);

                let existingDropdownForThisNode = document.getElementById(dropdownId) !== undefined && document
                    .getElementById(dropdownId) !== null;

                // Remove a previously open dropdown
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }

                if (!existingDropdownForThisNode) {

                    // let textNode = d3.select(this).selectAll("text");
                    // let focusLabel = focusedVmsUuids.indexOf(i.uuid) === -1 ? "Focus" : "Unfocus";

                    let mouseEvent: number[] = d3.pointer(d);
                    let mouseX = mouseEvent[0];
                    let mouseY = mouseEvent[1];

                    let uuidToNameIdx = {}

                    for (let resource of [i.source, i.target]) {
                        for (let vm of graphData.vms) {
                            uuidToNameIdx[vm.uuid] = vm.name;
                        }
                    }

                    let metricsHtmlCode =
                        "<table class=\"table\" id='table_flows_links_popover' style='margin-top: 0px; min-width: 400px;'>";

                    metricsHtmlCode += `<thead>
					<tr>
						<th>Flow</th>
						<th>Information</th>
						<th style="white-space:nowrap">Total bytes</th>
					</tr>
					</thead>`;

                    function formatExchangedBytes(value, remainingUnits = ["bytes", "kiB", "MiB", "GiB"]) {
                        if (value < 1024 || remainingUnits.length === 1) {
                            let unit = remainingUnits[0];
                            if (unit === "bytes") {
                                return `${value} ${remainingUnits[0]}`;
                            }
                            return `${value.toFixed(2)} ${remainingUnits[0]}`;
                        }
                        return formatExchangedBytes(value / 1024, remainingUnits.slice(1));
                    }

                    for (let metric of relevantMetrics) {
                        let nameSource = uuidToNameIdx[metric.source_address.uuid];
                        let nameTarget = uuidToNameIdx[metric.destination_address.uuid];

                        if (nameSource == undefined) {
                            nameSource = metric.source_address.ipaddress;
                        }

                        if (nameTarget == undefined) {
                            nameTarget = metric.destination_address.ipaddress;
                        }

                        let metricHtmlCode = "<tr>";
                        if (metric.direction === 1) {
                            metricHtmlCode +=
                                `<td><span style="white-space:nowrap"><span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameSource}<span class="badge">${metric.port}</span></span>`;
                            metricHtmlCode += `&rarr; `;
                            metricHtmlCode +=
                                `<span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameTarget}</span></span></td>`;
                        } else {
                            metricHtmlCode +=
                                `<td><span style="white-space:nowrap; vertical-align: middle;"><span class="label" style="max-width: 100%; justify-content: left;">${nameSource}</span>`;
                            metricHtmlCode += `&rarr; `;
                            metricHtmlCode +=
                                `<span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameTarget}<span class="badge">${metric.port}</span></span></span></td>`;
                        }
                        metricHtmlCode += "<td><span style=\"white-space:nowrap\">";

                        let transportProtocolParts = metric.transport_protocol.split(" ");
                        if (transportProtocolParts.length > 1) {
                            let transportProtocol = transportProtocolParts[1];
                            metricHtmlCode += `<span class="label">${transportProtocol}</span>`;
                        }

                        let applicationProtocol = metric.application_protocol;
                        metricHtmlCode += `<span class="label">${applicationProtocol}</span>`;
                        metricHtmlCode += "</span></td>";

                        metricHtmlCode += "<td><span style=\"white-space:nowrap\">";
                        metricHtmlCode += formatExchangedBytes(metric.exchanged_bytes);
                        metricHtmlCode += "</span></td>";

                        metricHtmlCode += "</tr>";

                        metricsHtmlCode += metricHtmlCode;
                    }
                    metricsHtmlCode += "</table>"

                    let popover = g
                        .append("foreignObject")
                        .attr("class", "dropdown_actions")
                        .attr('id', dropdownId)
                        .attr('x', mouseX)
                        .attr('y', mouseY)
                        .attr('height', 0)
                        .attr('width', 0)
                        .html(metricsHtmlCode);

                    // Ensure that the node and its dropdown menu are above all elements of the svg parent node.
                    // This moves the nodes at the beginning of the parent's list of children.
                    d3.select(this).raise();

                    // Adjust size of popover
                    setTimeout(() => {
                        let element = document.getElementById("table_flows_links_popover");
                        popover
                            .transition()
                            .attr("height", element.offsetHeight + 5)
                            .attr("width", element.offsetWidth + 5);
                    }, 100);
                }
            })
            // @ts-ignore
            .attr('stroke-width', (d) => d.strokeWidth);

        visuNode = g.append('g')
            .attr('class', 'nodes')
            .selectAll('g')
            .data(graphDataNodes)
            .enter().append('g');

        let currentInstanceObject = flowComponent;
        visuNode.filter((d) => {
                // @ts-ignore
                return d.type === 'vm' || d.type === 'host';
            })
            .on("mouseover", function(d, i) {
                d3.select(this).style("cursor", "pointer");
            })
            .on("mouseout", function(d, i) {
                d3.select(this).style("cursor", "default");
            })
            .on('click', (d, i) => {})

        const circles = visuNode.append('g')
            .append('circle')
            .attr('id', (d) => `circle_${d.id}`)
            .attr('class', 'vmCircle')
            .attr('r', (d) => d.r)
            .style('stroke-width', 5) // set the stroke width
            .style("stroke", (d) => groupsAppsColorIndex[d.name])
            .attr('fill', (d) => groupsAppsColorIndex[d.name])
            .style('filter', 'brightness(115%) saturate(50%)')
            .style("opacity", (d) => 1.0);

        // const labels = visuNode.append('text')
        //     // @ts-ignore
        //     .text((d) => d.name)
        //     // @ts-ignore
        //     .attr('x', (d) => d.name.length * 6 / 2 * (-1))
        //     .attr('y', (d) => d.r + 15)
        //     .attr('style', 'text-shadow: 1px 1px 2px white, -1px -1px 2px white;');

        // Redefine gravity
        if (flowComponent.gravityMode === "automatic" || flowComponent.gravityValue === undefined) {
            flowComponent.gravityValue = -500;
            // flowComponent.gravityValue = -5000;
        }

        let forceParameter = flowComponent.gravityValue;

        const simulation = d3.forceSimulation(graphDataNodes)
            .force("link", d3
                .forceLink()
                .distance((d) => {
                    // @ts-ignore
                    return 150 + d.source.r + d.target.r + d.value * 5;
                })
                // @ts-ignore
                .id(function(d) {
                    // @ts-ignore
                    return d.id;
                })
            )
            // .alphaTarget(0.3) // stay hot
            .velocityDecay(0.1) // low friction
            .force("x", d3.forceX().strength(0.01))
            .force("y", d3.forceY().strength(0.01))
            // @ts-ignore
            .force("collide", d3.forceCollide().radius(d => d.r + 1).iterations(3))
            // .force("charge", d3.forceManyBody().strength((d, i) => i ? 0 : -svgWidth * 2 / 3))
            .force("charge", d3.forceManyBody().strength(forceParameter))
            .force('center', d3.forceCenter(svgWidth / 2, svgHeight / 2))
            .on("tick", ticked);

        simulation
            .nodes(graphDataNodes)
            .on('tick', ticked);

        simulation
            .force('link')
            // @ts-ignore
            .links(links);

        function ticked() {
            link
                // @ts-ignore
                .attr('x1', (d) => d.source.x)
                // @ts-ignore
                .attr('y1', (d) => d.source.y)
                // @ts-ignore
                .attr('x2', (d) => d.target.x)
                // @ts-ignore
                .attr('y2', (d) => d.target.y);
            link
                .each((d) => {
                    // @ts-ignore
                    self.lastForcePositions[`${d.source.uuid}_${d.target.uuid}`] = {
                        // @ts-ignore
                        x1: d.source.x,
                        // @ts-ignore
                        y1: d.source.y,
                        // @ts-ignore
                        x2: d.target.x,
                        // @ts-ignore
                        y2: d.target.y,
                    }
                })

            visuNode
                // @ts-ignore
                .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')');
            visuNode
                .each((d) => {
                    // @ts-ignore
                    self.lastForcePositions[`${d.uuid}`] = {
                        // @ts-ignore
                        x: d.x,
                        // @ts-ignore
                        y: d.y,
                        // @ts-ignore
                        vx: d.vx,
                        // @ts-ignore
                        vy: d.vy
                    }
                })

            // @ts-ignore
            self.lastAlpha = simulation.alpha();
        }

        graphDataNodes.forEach((d) => {
            let mainCircleUuid = d.id;
            let groupVms = d.vms.map(vmUuid => {
                return {
                    id: vmUuid,
                    name: vmsNamesIdx.hasOwnProperty(vmUuid) ? vmsNamesIdx[vmUuid] : vmUuid.split(":")[1]
                }
            });
            let newNodes = visuNode
                .filter((d) => {
                    // @ts-ignore
                    return d.id === mainCircleUuid;
                })
                .selectAll(".circle_" + d.id)
                .data((d) => groupVms)
                .enter()
                .append('g')
                .attr('class', "circle_vm circle_vms_" + d.id)

            const nodeRadius = 15;

            const circles = newNodes.append('g')
                .append('circle')
                .attr('r', nodeRadius)
                .attr('class', "vmCircle")
                .style('stroke-width', 5) // set the stroke width
                .attr('fill', (d) => 'white');

            function updateCirclesStrokeColor() {
                newNodes
                    .selectAll('.vmCircle')
                    .style('stroke', (d: FlowRessourceObject) => {
                        return groupsAppsColorIndex[mainCircleUuid];
                    });
            }

            updateCirclesStrokeColor();

            if (flowComponent.selectedAppsGroups.length > 0) {
                flowComponent.addGroupsAppsCircles(visuNode);
            }

            const labels = newNodes.append('text')
                .attr("id", (d) => "text_label_" + d.id)
                .text((d) => d.name)
                .attr('x', (d) => d.name.length * 6 / 2 * (-1))
                .attr('y', nodeRadius + 15)
                .attr('style', 'text-shadow: 1px 1px 2px white, -1px -1px 2px white;');

            const groupSimulation = d3.forceSimulation(groupVms)
                // .alphaTarget(0.3) // stay hot
                .velocityDecay(0.1) // low friction
                .force("x", d3.forceX().strength(0.01))
                .force("y", d3.forceY().strength(0.01))
                .force("collide", d3.forceCollide().radius(d => 25).iterations(3))
                // .force("charge", d3.forceManyBody().strength((d, i) => i ? 0 : -500 * 2 / 3))
                .on("tick", tickedGroupSimulation);

            function tickedGroupSimulation() {
                let newNodes = visuNode
                    .selectAll(".circle_vms_" + d.id)
                    .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')');
                let maxDistance = Math.max(
                    ...groupVms
                        .map((node) => [node.x, node.y])
                        .flat()
                        .map(v => Math.abs(v))
                )
                let expectedRadius = maxDistance + 30;


                let groupCircle = d3.select(`#circle_${d.id}`);
                groupCircle.attr("r", expectedRadius);


                let restartSimulation = false;

                let minimumDifference;
                groupCircle.each((d) => {
                    // @ts-ignore
                    if (minimumDifference === undefined || Math.abs(d.r - expectedRadius) < minimumDifference) {
                        // @ts-ignore
                        minimumDifference = Math.abs(d.r - expectedRadius);
                    }
                    // @ts-ignore
                    d.r = expectedRadius;
                });

                if (minimumDifference > 1) {
                    restartSimulation = true;
                }

                // Compute minimum distance between all circles
                let minDistance;
                visuNode.each((d1) => {
                    visuNode.each((d2) => {
                        // @ts-ignore
                        if (d1.name === d2.name) {
                            return;
                        }
                        // @ts-ignore
                        let distance = Math.sqrt((d1.x - d2.x) ** 2 + (d1.y - d2.y) ** 2) - d1.r - d2.r;

                        if (minDistance === undefined || distance < minDistance) {
                            minDistance = distance;
                        }
                    });
                });

                if (minDistance < 30) {
                    console.log("Increasing gravity value");
                    forceParameter -= 100;
                    flowComponent.gravityValue = forceParameter;
                }

                if (restartSimulation) {
                    console.log("Restart global simulation");
                    simulation
                        .alpha(0.5)
                        // @ts-ignore
                        .force("collide", d3.forceCollide().radius(d => d.r + 1).iterations(3))
                        .force("charge", d3.forceManyBody().strength(forceParameter))
                        .restart();
                }

            }
        });

        d3.selectAll(".circle_vm")
            .on("mouseover", function(d3Node, data) {
                // d3.select(this).style("cursor", "pointer");
                d3.selectAll(".circle_vm")
                    .filter((d2) => {
                        // @ts-ignore
                        return d2.id !== data.id;
                    })
                    .style("opacity", "25%");
            })
            .on("mouseout", function(d3Node, data) {
                // d3.select(this).style("cursor", "default");
                d3.selectAll(".circle_vm")
                    .filter((d2) => {
                        // @ts-ignore
                        return d2.id !== data.id;
                    })
                    .style("opacity", "100%");
            });


        // step1: zoom out, and let d3 recompute positions and sizes
        const [x, y, k] = [0, 0, 1.0];
        g.attr('transform', 'translate(' + x + ',' + y + ') scale(' + k + ')');
        flowComponent.svg.call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(x, y).scale(k)
        );

        // step2: center the main group by translating in the middle of the size difference
        // between the group and the svg
        let [x2, y2, k2] = [
            (g.node().getBBox().width - flowComponent.svg.node().getBBox().width) / 2 + 20,
            (g.node().getBBox().height - flowComponent.svg.node().getBBox().height) / 2 + 20,
            1.0
        ];

        if (flowComponent.graphParameters.userMovedMap) {
            [x2, y2, k2] = [flowComponent.graphParameters.lastTransformation.x, flowComponent.graphParameters
                .lastTransformation.y, flowComponent.graphParameters.lastTransformation.k
            ];
        }

        g.attr('transform', 'translate(' + x2 + ',' + y2 + ') scale(' + k2 + ')');
        flowComponent.svg.call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(x2, y2).scale(k2)
        );

        // Save initial visualisation's position parameters to enable "zoom-in", "zoom-out" and "reset" buttons to work.
        if (flowComponent.graphParameters.firstTransformation === undefined) {
            flowComponent.graphParameters.firstTransformation = {
                x: x2,
                y: y2,
                k: k2
            }
        }
    }
}

