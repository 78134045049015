import { 
  Injectable 
} from '@angular/core';

import {
  HttpClient
} from '@angular/common/http';

import {
    environment
} from '@environments/environment';

import {
  map
} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SupervisionService {

  constructor(private http: HttpClient) {}

  getServiceAction(name: string, action: string) {
    return this.http.get < any > (`${environment.apiUrl}/supervision/` + 'servicestatus?name=' + name + '&action=' + action).pipe(
        map((supervision: any) => {
            return supervision;
        }));
  }
}
