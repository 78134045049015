<div class="card-text" style="position: relative; min-width: 500px;">
    <div class="clr-row" style="padding-right: 10px;">
        <div class="clr-col" style="flex: 120;">
            <div *ngIf="!(!doubleClickSelectDay && selectionPosition.plotSelection.type != 'none')" class="alert alert-info alert-sm" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                        </div>
                        <span class="alert-text">
                            <span *ngIf="doubleClickSelectDay && selectionPosition.plotSelection.type == 'daily'">{{ "dcnetscope.heatmap.visualising_whole_day_text" | transloco }} </span>
                            <span *ngIf="selectionPosition.plotSelection.type !== 'hourly'">{{ "dcnetscope.heatmap.switch_to_one_hour_text" | transloco }}</span>
                            <span *ngIf="doubleClickSelectDay && (selectionPosition.plotSelection.type == 'none' || selectionPosition.plotSelection.type == 'hourly')">{{ "dcnetscope.heatmap.switch_to_whole_day_text" | transloco }}</span>
                        </span>
                    </div>
                </div>
                <button type="button" class="close" aria-label="Close">
                    <cds-icon aria-hidden="true" shape="close"></cds-icon>
                </button>
            </div>
        </div>
        <div class="clr-col" style="flex: 1; padding: 0;">
            <clr-signpost>
                <cds-icon shape="cog"
                          style="margin-top: 12px;"
                          solid
                          clrSignpostTrigger>
                </cds-icon>
                <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
                    <clr-radio-container clrInline>
                        <label>{{ "dcnetscope.heatmap.label_color_axis" | transloco }}</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="colorAxisScaleType" required value="linear" [(ngModel)]="colorAxisScaleType" (ngModelChange)="updateColorAxisOptions()"/>
                            <label>{{ "dcnetscope.heatmap.linear" | transloco }}</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="colorAxisScaleType" required value="logarithmic" [(ngModel)]="colorAxisScaleType" (ngModelChange)="updateColorAxisOptions()"/>
                            <label>{{ "dcnetscope.heatmap.logarithmic" | transloco }}</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                </clr-signpost-content>
            </clr-signpost>
        </div>
    </div>

    <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="overallTrafficChartOptions"
            [(update)]="updateHeatmapChartFlag"
            [oneToOne]="true"
            [callbackFunction]="callbackHeatmapChartRef"
            style="width: 100%; height: 100%; max-height: {{maxHeight}}px; display: block;">
    </highcharts-chart>
</div>
