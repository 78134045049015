<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
        	<tr>
            		<th>Name</th>
	    		<th>vCPU</th>
	    		<th>vRAM</th>
	    		<th>Storage</th>
	    		<th>Date</th>
	    		<th>Since</th>
        	</tr>
    	</thead>
    	<tbody>
		<tr *ngFor="let model of model_kpi">
			<td>{{model.name}}</td>
			<td>{{model.vcpu}}</td>
			<td>{{model.vram/1024}} Go</td>
			<td>{{math.ceil(model.vdisk/1024)}} Go</td>
			<td>{{moment.unix(model.date/1000).format("MMM Do YYYY")}}</td>
			<td *ngIf="model.nb > 0; else addDate">{{model.nb}} days ago</td>
                        <ng-template #addDate>
				<td>{{moment.unix(model.date/1000).fromNow()}}</td>
			</ng-template>

        	</tr>
    	</tbody>
</table>
