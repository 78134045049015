import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    ManagementService,
    ShareService
} from '@app/services';

import {
    Message,
    DS_COST,
    StorageCost,
    User
} from '@app/model';

import {
    SelectiontreeComponent
} from '@app/selectiontree/selectiontree.component';
import {
    SelectiontreeDirective
} from '@app/directives/selectiontree.directive';

import {
    getUserCurrency
} from '../../../assets/js/tools.js';
import * as $ from 'jquery';


@Component({
    selector: 'app-coststodetail',
    templateUrl: './coststodetail.component.html',
    styleUrls: ['./coststodetail.component.css']
})
export class CoststodetailComponent implements OnInit {

    @ViewChild(SelectiontreeDirective) addTree: SelectiontreeDirective;

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: DataTables.Settings = {};

    dtTrigger: Subject < any > = new Subject();

    model: any;

    data_cost: StorageCost[] = [];

    message: Message;

    globalCurrency: string = '';

    private currentUser: User;

    private current_tool: boolean = false;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private management_svc: ManagementService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        this.initDtOptions();

        const infra: any[] = this.json_svc.json.storageOverviewsDatastoreData;
        for (var i = 0; i < infra.length; i++) {
            let ds_cost: StorageCost = {
                uuid: infra[i].url,
                name: infra[i].name,
                capacity: infra[i].capacity,
                cost: 1
            };
            this.data_cost.push(ds_cost);
        }

        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.message.elementViewType = 'datastore';

        this.model = {
            cost: 1
        };
    }

    ngAfterViewInit(): void {

        this.dtTrigger.next();
        this.initStorageCost();
    }

    ngAfterViewChecked(): void {

        if (this.current_tool != this.message.isCostTool) {
            this.current_tool = this.message.isCostTool;
            if (this.current_tool) {
                $("div.dataTables_filter").remove();
                this.loadTree();
                $('#cost-ds-list').slideToggle();
            } else {
                $('#cost-ds-list').slideToggle();
                this.reloadTable();
            }
        }
        this.cd.detectChanges();
    }

    private applyChild(node: any): void {

        if (node != undefined) {
            for (var i = 0; i < node.children.length; i++) {
                if (node.children[i].type == "datastore" && node.children[i].selected == 1) {
                    try {
                        let sto_cost: StorageCost = this.findDsCost(node.children[i].id);
                        sto_cost.cost = this.model.cost;
                    } catch (e) {
                        console.log('fail to load StorageCost');
                    }
                } else {
                    this.applyChild(node.children[i]);
                }
            }
        }
    }

    applyElement(): void {

        for (var i = 0; i < this.message.elementViewTree.length; ++i) {
            if (this.message.elementViewTree[i].type == "vm" && this.message.elementViewTree[i].selected == 1) {
                try {
                    let sto_cost: StorageCost = this.findDsCost(this.message.elementViewTree[i].id);
                    sto_cost.cost = this.model.cost;
                } catch (e) {
                    console.log('fail to load StorageCost');
                }
            } else {
                this.applyChild(this.message.elementViewTree[i]);
            }
        }

        this.message.isTreeAnalysis = false;
        this.message.elementViewType = 'server';
        this.message.isCostTool = false;
        this.message.isCostRecord = true;
        this.message.costStoDetail = this.data_cost;
    }

    enableDsUpdate(): void {

        this.message.isCostRecord = true;
        this.message.costStoDetail = this.data_cost;
    }

    closeCard(): void {
        this.message.isCostTool = false;
    }

    private initStorageCost(): void {

        this.management_svc.getStorageCost('root').pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        try {
                            let stoCost: StorageCost = this.findDsCost(data[i].URL);
                            stoCost.cost = data[i].COST;
                        } catch (e) {
                            console.log('fail to find StorageCost');
                        }
                    }
                    this.reloadTable();
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private findDsCost(uuid: string): StorageCost {
        return this.data_cost.find(sc => sc.uuid === uuid);
    }

    private loadTree(): void {

        if (this.addTree != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectiontreeComponent);
            const viewContainerRef = this.addTree.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
                $("[id*='DataTables_Table_']").css({
                    'padding-top': '5px'
                });
                $("[name*='DataTables_Table_']").css({
                    'padding': '0px'
                });
            },
            language: {
                loadingRecords: "loading .. ",
                zeroRecords: "loading ...",
                search: '',
                searchPlaceholder: " search..."
            },
            dom: '<"top">rt<"bottom"lifp>'
        };
    }
}
