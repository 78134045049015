import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';

import {
    SelectiontreeComponent
} from '@app/selectiontree/selectiontree.component';
import {
    SelectiontreeDirective
} from '@app/directives/selectiontree.directive';
import {
    WizardselectiontreeDirective
} from '@app/directives/wizardselectiontree.directive';
import {
    AccountService,
    ShareService
} from '@app/services';
import {
    GreenitIndicators,
    GreenitMinmax,
    Message,
    User
} from '@app/model';

import {
    getUserCurrency
} from '../../../assets/js/tools';
import {
    TreeTarget,
} from '../greenit.enums';


@Component({
    selector: 'app-greenittool',
    templateUrl: './greenittool.component.html',
    styleUrls: ['./greenittool.component.css']
})
export class GreenittoolComponent implements OnInit {

    @ViewChild(SelectiontreeDirective) addTree: SelectiontreeDirective;
    @ViewChildren(WizardselectiontreeDirective) addTrees: any;

    @Input() target: TreeTarget;

    @Input() value_purchase: number;
    @Input() value_pue: number;
    @Input() value_co2: number;
    @Input() value_cost: number;

    @Output('treedata') treedata = new EventEmitter();
    pushData() {
        this.treedata.emit(this.datas);
    }

    private componentWizardRefs: Array < ComponentRef < any >> = [];

    message: Message;

    currentUser: User;
    globalCurrency: string;

    indicatortree: TreeTarget;
    network_indicatortree: TreeTarget;
    storage_indicatortree: TreeTarget;
    minmaxtree: TreeTarget;

    elementViewContext = "multi";

    // Default settings
    value_amortization: number = 4;
    value_embodied: number = -1;

    value_min: number = 100;
    value_max: number = 200;

    // Checkboxes
    check_purchase: boolean = false;
    check_amortization: boolean = false;
    check_embodied: boolean = false;
    check_co2: boolean = false;
    check_cost: boolean = false;
    check_pue: boolean = false;

    check_min: boolean = false;
    check_max: boolean = false;

    apply_button: boolean;
    datas: any;

    /**
     * 
     */
    constructor(private componentFactoryResolver: ComponentFactoryResolver, private message_svc: ShareService,
        private authenticationService: AccountService) {

        this.indicatortree = TreeTarget.INDICATOR;
        this.network_indicatortree = TreeTarget.NETWORK_INDICATOR;
        this.storage_indicatortree = TreeTarget.STORAGE_INDICATOR;
        this.minmaxtree = TreeTarget.MINMAX;

        this.apply_button = false;
        this.datas = [];
    }

    /**
     * 
     */
    ngOnInit(): void {
        this.authenticationService.user.subscribe(user => this.currentUser = user);

        // BUILD TREE
        this.message_svc.currentMessage.subscribe(
            message => {
                this.message = message;

                setTimeout(() => {
                    this.loadTree();
                    // FIX tree
                    setTimeout(() => {
                        let custom_tree = [];
                        switch (this.target) {
                            case TreeTarget.MINMAX:
                            case TreeTarget.INDICATOR:
                                // Keep only servers nodes from 'multi' context
                                for (var i = 0; i < this.message.elementViewTree.length; i++) {
                                    if (this.message.elementViewTree[i].name == "vmware" ||
                                        this.message.elementViewTree[i].name == "kubernetes" ||
                                        this.message.elementViewTree[i].name == "scaphandre" ||
                                        this.message.elementViewTree[i].name == "xclarity" ||
                                        this.message.elementViewTree[i].name == "openmanage" ||
                                        this.message.elementViewTree[i].name == "oneview" ||
                                        this.message.elementViewTree[i].name == "ipmi") {
                                        custom_tree.push(this.message.elementViewTree[i]);
                                    }
                                }
                                break;
                            case TreeTarget.NETWORK_INDICATOR:
                                // Keep all devices from 'network' context
                                for (var i = 0; i < this.message.elementViewTree.length; i++) {
                                    custom_tree.push(this.message.elementViewTree[i]);
                                }
                                break;
                            case TreeTarget.STORAGE_INDICATOR:
                                // Keep only storage nodes from 'multi' context
                                for (var i = 0; i < this.message.elementViewTree.length; i++) {
                                    if (this.message.elementViewTree[i].name == "san-s" ||
                                        this.message.elementViewTree[i].name == "nas-s" ||
                                        this.message.elementViewTree[i].name == "backup-s" ||
                                        this.message.elementViewTree[i].name == "exagrid" ||
                                        this.message.elementViewTree[i].name == "ibm") {
                                        custom_tree.push(this.message.elementViewTree[i]);
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                        this.message.elementViewTree = custom_tree;

                    }, 500);
                }, 100);
            });

        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        switch (this.target) {
            case TreeTarget.NETWORK_INDICATOR:
                this.value_amortization = 6;
                this.elementViewContext = "network";
                break;
            case TreeTarget.STORAGE_INDICATOR:
                this.value_amortization = 6;
                //this.elementViewContext = "storage";
                this.elementViewContext = "multi";
                break;
            default:
                break;
        }

    }

    /**
     * 
     */
    private loadTree(): void {
        this.message.elementViewContext = this.elementViewContext;

        if (this.addTrees != undefined) {
            this.addTrees._results.forEach(element => {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                    SelectiontreeComponent);
                const viewContainerRef = element.viewContainerRef;
                viewContainerRef.clear();
                this.componentWizardRefs.push(viewContainerRef.createComponent(componentFactory));
            });
        }
        this.message.isTreeAnalysis = false;
    }

    /**
     * 
     */
    saveTree(): void {
        this.datas = [];

        for (var i = 0; i < this.message.elementViewTree.length; ++i) {
            if ((this.message.elementViewTree[i].type == "server" ||
                    this.message.elementViewTree[i].type == "kubernetes-node" ||
                    this.message.elementViewTree[i].type == "scaphandre_node" ||
                    this.message.elementViewTree[i].type == "xclarity_node" ||
                    this.message.elementViewTree[i].type == "openmanage_node" ||
                    this.message.elementViewTree[i].type == "oneview_node" ||
                    this.message.elementViewTree[i].type == "ipmi_node" ||
                    this.message.elementViewTree[i].type == "firewall" ||
                    this.message.elementViewTree[i].type == "loadBalancer" ||
                    this.message.elementViewTree[i].type == "router" ||
                    this.message.elementViewTree[i].type == "switch" ||
                    this.message.elementViewTree[i].type == "wifiAccessPoint" ||
                    this.message.elementViewTree[i].type == "san" ||
                    this.message.elementViewTree[i].type == "nas" ||
                    this.message.elementViewTree[i].type == "backup" ||
                    this.message.elementViewTree[i].type == "exagrid_san" ||
                    this.message.elementViewTree[i].type == "ibm_san") &&
                this.message.elementViewTree[i].selected == 1) {
                this.initValues(this.message.elementViewTree[i]);
            } else {
                this.checkTreeChild(this.message.elementViewTree[i]);
            }
        }

        this.apply_button = false;
        this.pushData();

        this.resetTree(this.message.elementViewTree);

    }

    /**
     * 
     */
    private checkTreeChild(node: any): void {
        if (node != undefined) {
            for (var i = 0; i < node.children.length; i++) {
                if ((node.children[i].type == "server" ||
                        node.children[i].type == "kubernetes-node" ||
                        node.children[i].type == "scaphandre_node" ||
                        node.children[i].type == "xclarity_node" ||
                        node.children[i].type == "openmanage_node" ||
                        node.children[i].type == "oneview_node" ||
                        node.children[i].type == "ipmi_node" ||
                        node.children[i].type == "firewall" ||
                        node.children[i].type == "loadBalancer" ||
                        node.children[i].type == "router" ||
                        node.children[i].type == "switch" ||
                        node.children[i].type == "wifiAccessPoint" ||
                        node.children[i].type == "san" ||
                        node.children[i].type == "nas" ||
                        node.children[i].type == "backup" ||
                        node.children[i].type == "exagrid_san" ||
                        node.children[i].type == "ibm_san") &&
                    node.children[i].selected == 1) {
                    this.initValues(node.children[i]);

                } else {
                    this.checkTreeChild(node.children[i]);
                }
            }
        }
    }

    /**
     * 
     */
    private resetTree(node: any): void {
        node.forEach(
            element => {
                element.selected = 0;
                this.resetTree(element.children);
            });
    }

    /**
     * 
     */
    private initValues(node: any): void {

        let name = node.name;
        let identifier = node.id;
        let year = node.year;
        let amort = node.amortization;
        let embodied = node.embodied;
        let co2 = node.co2;
        let cost = node.cost;
        let pue = node.pue;
        let min = node.min;
        let max = node.max;

        if (this.check_purchase)
            year = this.value_purchase;
        if (this.check_amortization)
            amort = this.value_amortization;
        if (this.check_embodied && this.value_embodied > -1)
            embodied = this.value_embodied;
        if (this.check_co2)
            co2 = this.value_co2;
        if (this.check_cost)
            cost = this.value_cost;
        if (this.check_pue)
            pue = this.value_pue;

        // No checkboxes for min/max, need to push the couple
        min = this.value_min;
        max = this.value_max;

        switch (this.target) {
            case TreeTarget.INDICATOR:
            case TreeTarget.NETWORK_INDICATOR:
            case TreeTarget.STORAGE_INDICATOR:
                let data: GreenitIndicators = {
                    name: name,
                    identifier: identifier,
                    year: year,
                    amortization: amort,
                    embodied: embodied,
                    co2: co2,
                    cost: cost,
                    pue: pue,
                    timeago: new Date().getTime()
                };
                this.datas.push(data);
                break;
            case TreeTarget.MINMAX:
                let serverB: GreenitMinmax = {
                    name: node.name,
                    identifier: node.id,
                    min: min,
                    max: max,
                    model: '',
                    cpumodel: '',
                    ramcap: 0,
                    sockets: 0,
                    power: 0
                };
                this.datas.push(serverB);
                break;
            default:
                break;
        }

    }

    /**
     * 
     */
    checkSettings(): void {
        let overall_status = false;

        switch (this.target) {
            case TreeTarget.INDICATOR:
            case TreeTarget.NETWORK_INDICATOR:
            case TreeTarget.STORAGE_INDICATOR:
                if (this.check_purchase)
                    if (Number(this.value_purchase) && this.value_purchase > 0)
                        overall_status = true;
                    else {
                        overall_status = false;
                        break;
                    }
                if (this.check_amortization) {
                    if (Number(this.value_amortization) && this.value_amortization >= 0)
                        overall_status = true;
                    else {
                        overall_status = false;
                        break;
                    }
                }
                if (this.check_embodied)
                    if (Number(this.value_embodied) && this.value_embodied >= 0)
                        overall_status = true;
                    else {
                        overall_status = false;
                        break;
                    }
                if (this.check_co2)
                    if (Number(this.value_co2) && this.value_co2 >= 0)
                        overall_status = true;
                    else {
                        overall_status = false;
                        break;
                    }
                if (this.check_cost)
                    if (Number(this.value_cost) && this.value_cost >= 0)
                        overall_status = true;
                    else {
                        overall_status = false;
                        break;
                    }
                if (this.check_pue)
                    if (Number(this.value_pue) && this.value_pue > 0)
                        overall_status = true;
                    else {
                        overall_status = false;
                        break;
                    }
                break;
            case TreeTarget.MINMAX:
                if (this.value_min >= 0 && this.value_max >= 0 && this.value_min <= this.value_max) {
                    overall_status = true;
                }
                break;
        }

        // Update apply button status
        this.apply_button = overall_status;
    }

}
