import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  NetscopeService,
  ReportsData, RequestReportDeletionResponse,
  RequestReportDownloadResponse,
  SuccessGetReportsDataResponse, SuccessRequestReportDownloadResponse
} from "@app/services";
import {TranslocoService} from "@ngneat/transloco";
import {NetscopeSupervisionComponent} from "@app/netscope/netscope-supervision/netscope-supervision.component";
import {response} from "express";

@Component({
  selector: "app-netscope-reports",
  templateUrl: "./netscope-reports.component.html",
  styleUrls: ["./netscope-reports.component.css"],
})
export class NetscopeReportsComponent implements OnInit, OnDestroy {
  @Output()
  loadingStateEmitter = new EventEmitter<boolean>();
  @Output()
  errorStateEmitter = new EventEmitter<boolean>();

  failureMode = false;
  isLoading = false;

  openGenerateReportModal = false;
  duration_type = "multiple_days";
  email_send = "no";
  start_date: Date = undefined;
  end_date: Date = undefined;

  reportsData: ReportsData;
  intervalRefreshReports;

  constructor(
    private netscopeService: NetscopeService,
    public translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.intervalRefreshReports = setInterval(() => {
      this.reloadData();
    }, 5000);
    this.reloadData();
  }

  ngOnDestroy(): void {
    if (this.intervalRefreshReports != undefined) {
      clearInterval(this.intervalRefreshReports);
    }
  }

  reloadData = () => {
    this.isLoading = true;
    this.loadingStateEmitter.emit(true);

    this.netscopeService.getReportsData().subscribe((reportsDataResponse) => {
      let reportsData: ReportsData;
      if (reportsDataResponse instanceof SuccessGetReportsDataResponse) {
        reportsData = reportsDataResponse.reportsData;
      } else {
        this.failureMode = true;
        this.errorStateEmitter.emit(true);
        this.isLoading = false;
        this.loadingStateEmitter.emit(false);
        return;
      }

      this.reportsData = reportsData;

      setTimeout(() => {
        this.isLoading = false;
        this.loadingStateEmitter.emit(false);
      }, 300);
    });
  };

  generateReport = () => {
    let startDate = this.start_date.getTime() / 1000.0;
    let endDate = this.end_date != undefined ? this.end_date.getTime() / 1000.0 : startDate;
    let sendByEmail: boolean = this.email_send == "yes";

    this.netscopeService
      .requestReportGeneration(startDate, endDate, sendByEmail)
      .subscribe((reportGenerationResponse) => {
        console.log("Report generation request made");
        this.reloadData();
      });
  };

  deleteReport = (reportUuid: string) => {
    this.netscopeService.requestReportDeletion(reportUuid).subscribe((response: RequestReportDeletionResponse) => {
      this.reloadData();
    })
  }

  closeModal = () => {
    this.openGenerateReportModal = false;
    this.duration_type = "single_day";
    this.email_send = "no";
    this.start_date = undefined;
    this.end_date = undefined;
  };

  downloadReport = (reportUuid: string) => {
    this.netscopeService
      .requestReportDownload(reportUuid)
      .subscribe((response: RequestReportDownloadResponse) => {
        let reportData;
        if (response instanceof SuccessRequestReportDownloadResponse) {
          reportData = response.reportData;
        }

        var bytes = new Uint8Array(reportData.result.length);
        for (var i = 0; i < reportData.result.length; i++) {
          bytes[i] = reportData.result[i];
        }

        let exportedFilename = reportData.filename;

        let mimeType = "application/octet-stream";

        if (exportedFilename.endsWith(".pdf")) {
          mimeType = "application/pdf";
        }

        if (exportedFilename.endsWith(".doc")) {
          mimeType = "application/msword";
        }

        if (exportedFilename.endsWith(".docx")) {
          mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        }

        let blob = new Blob([bytes], {
          type: mimeType,
        });
        // @ts-ignore
        if (navigator.msSaveBlob) {
          // IE 10+
          // @ts-ignore
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
  };
}