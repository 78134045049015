<form clrForm [formGroup]="search" id="{{css_id}}">
	<clr-datalist-container>
		<input clrDatalistInput (ngModelChange)="submit($event)" (keyup.enter)="enter()" placeholder="search ..." name="Options" formControlName="item" required size="30">
		<datalist>
			<option *ngFor="let item of infra" [ngValue]="item.uuid">{{item.name}} - [{{item.type}}]</option>
        	</datalist>
    	</clr-datalist-container>
</form>
<clr-tree [clrLazy]="true">
	<clr-tree-node *clrRecursiveFor="let item of message.elementViewTree; getChildren: getItemChildren"
		        [clrExpandable]="item.isExpandable"
			[(clrExpanded)]="item.opened"
			[(clrSelected)]="item.selected"
			(clrSelectedChange)="elementTreeAnalysis(item, $event)">
		<cds-icon [attr.shape]="item.type == 'vm' ? 'vm' :
					item.type == 'aws-instance' ? 'vm' : 
					item.type == 'azure-instance' ? 'vm' : 
					item.type == 'aws-region' ? 'cloud' : 
					item.type == 'az-region' ? 'cloud' : 
					item.type == 'aws-account' ? 'folder' : 
					item.type == 'azure-subscription' ? 'folder' : 
					item.type == 'server' ? 'host' : 
					item.type == 'cluster' ? 'cluster' : 
					item.type == 'dc' ? 'data-cluster' : 
					item.type == 'folder' ? 'folder-open' : 
					item.type == 'tag' ? 'tag' : 
					item.type == 'category' ? 'tag' : 
					item.type == 'rp' ? 'resource-pool' : 
					item.type == 'datastore' ? 'storage' :
					item.type == 'kubernetes-cluster' ? 'cluster' : 
					item.type == 'kubernetes-node' ? 'host' : 
					item.type == 'kubernetes-pod' ? 'pod' : 
					item.type == 'xclarity_node' ? 'host' : 
					item.type == 'scaphandre_node' ? 'host' : 
					item.type == 'openmanage_node' ? 'host' : 
					item.type == 'oneview_node' ? 'host' : 
					item.type == 'ipmi_node' ? 'host' : 
					item.type == 'exagrid_san' ? 'storage' : 
					item.type == 'ibm_san' ? 'storage' : 
					item.type == 'firewall' ? 'network-settings' : 
					item.type == 'switch' ? 'network-switch' : 
					item.type == 'wifiAccessPoint' ? 'network-globe' : 
					item.type == 'firewalls' ? 'network-settings' : 
					item.type == 'switchs' ? 'network-switch' : 
					item.type == 'wifiAccessPoints' ? 'network-globe' :
					item.type == 'routers' ? 'network-switch' :
					item.type == 'router' ? 'network-switch' :
					item.type == 'loadBalancers' ? 'network-switch' :
					item.type == 'loadBalancer' ? 'network-switch' :
					item.type == 'san' ? 'storage' : 
					item.type == 'sans' ? 'storage' : 
					item.type == 'nas' ? 'storage' : 
					item.type == 'backup' ? 'backup' : 
					item.type == 'backups' ? 'backup' : 
					item.type == 'organization' ? 'organization' : 
					item.type == 'view' ? 'tree-view' : ''">
		</cds-icon>
		{{item.name}}&nbsp;&nbsp;
		<span class="badge badge-info" *ngIf="item.common > 0 
												&& item.selected == 1
												&& message.elementViewContext == 'multi'">
			&nbsp;matched node on id {{item.common}}&nbsp;
		</span>
    </clr-tree-node>
</clr-tree>
