<span style="float: right;" ngClass="addLink" (click)="message.creditSelected = ''">
    <cds-icon id="resource-detail-close" shape="times" size="24"></cds-icon>
</span>
<div class="clr-row" *ngIf="this.message.creditSelected == 'infra'">
    <div class="clr-col-12">
        <div clr-col-9>
            Current selection:
            <span *ngFor="let pathPart of displayedPath; let first = first; let last=last;">
                <span *ngIf="!first">/ </span>
                <a *ngIf="!last" (click)="changeSelectionFromTopMenu(pathPart)">
                    <span class="badge badge-1 navigation_link">{{ pathPart }}</span>
                </a>
                <a *ngIf="last" (click)="changeSelectionFromTopMenu(pathPart)">
                    <span class="badge badge-blue navigation_link">{{ pathPart }}</span></a>
            </span>
        </div>
    </div>
</div>

<div id="divSvg" style="margin-top: 10px; width: 100%; height: 80%; min-height: 400px;" *ngIf="this.message.creditSelected == 'infra'"></div>

<span *ngIf="this.message.creditSelected == 'infra'">
    <h5 style="display: inline; margin-right: 10px;">Instances matching the selection</h5>
    <span *ngFor="let pathPart of displayedPath; let first = first; let last=last;">
        <span *ngIf="!first">/ </span>
        <a *ngIf="!last" (click)="changeSelectionFromTopMenu(pathPart)">
            <span class="badge badge-1 navigation_link">{{ pathPart }}</span>
        </a>
        <a *ngIf="last" (click)="changeSelectionFromTopMenu(pathPart)">
            <span class="badge badge-blue navigation_link">{{ pathPart }}</span></a>
    </span>
</span>

<div class="datagrid-limit-height" *ngIf="this.message.creditSelected == 'infra'">
    <clr-datagrid [clrDgLoading]="loading" class="datastoreDataGrid">
        <clr-dg-column [clrDgField]="'name'">INSTANCE</clr-dg-column>
        <clr-dg-column [clrDgField]="'pod'" *ngIf="message.cloudProvider != 'gcp'">REGION</clr-dg-column>
        <clr-dg-column [clrDgField]="'pod'" *ngIf="message.cloudProvider == 'gcp'">ZONE</clr-dg-column>
        <clr-dg-column [clrDgField]="'node'" *ngIf="message.cloudProvider == 'azure'">SUBSCRIPTION</clr-dg-column>
        <clr-dg-column [clrDgField]="'node'" *ngIf="message.cloudProvider == 'aws'">ACCOUNT</clr-dg-column>
        <clr-dg-column [clrDgField]="'node'" *ngIf="message.cloudProvider == 'gcp'">REGION</clr-dg-column>
        <clr-dg-column [clrDgField]="'cpu'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'ram'"><ng-container *clrDgHideableColumn="{hidden: false}">RAM</ng-container></clr-dg-column>
        <clr-dg-column [clrDgField]="'power'"><ng-container *clrDgHideableColumn="{hidden: false}">POWER</ng-container></clr-dg-column>
        <clr-dg-row *clrDgItems="let instance of listedContainers" [clrDgItem]="instance">
            <clr-dg-cell>{{ instance.name }}</clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric != 'storage'">
                <a (click)="changeSelection(instance.region_path)">
                    <span class="badge badge-blue navigation_link">{{ instance.region }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="selectedMetric != 'storage'">
                <a (click)="changeSelection(instance.subscription_path)">
                    <span class="badge badge-blue navigation_link">{{ instance.subscription }}</span>
                </a>
            </clr-dg-cell>
            <clr-dg-cell>{{ instance.cpu }} %</clr-dg-cell>
            <clr-dg-cell>{{ instance.ram }} %</clr-dg-cell>
            <clr-dg-cell>{{ instance.power }} Watt(s)</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Instances per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Instances
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>