export enum RPResource {
    CPU,
    RAM,
    STORAGE
}

export enum RPType {
    RES,
    USAGE
}
