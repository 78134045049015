<div class="clr-row">
	<div class="clr-col-xxl-5 clr-col-xl-6 clr-col-12" *ngIf="vmListLoad">
		<div class="card">
			<div class="card-header card-ajust">
				<div class="clr-row">
					<div class="clr-col-lg-11 clr-col-md-11 clr-col-11">
						<clr-toggle-container clrInline>
							<clr-toggle-wrapper>
								<input type="checkbox" clrToggle name="isAddVms" value="isAddVms" [(ngModel)]="isAddVms" (ngModelChange)="filterTs('element')" />
								<label>
									Virtual machines
                                                        		<span [ngClass]="vms_class">{{ts_data.length}}</span>
								</label>
							</clr-toggle-wrapper>
							<clr-toggle-wrapper>
								<input type="checkbox" clrToggle name="isAddServers" value="isAddServers" [(ngModel)]="isAddServers" (ngModelChange)="filterTs('element')" />
								<label>
									Servers
									<span [ngClass]="servers_class">{{data_hosts.length}}</span>
								</label>
							</clr-toggle-wrapper>
							<clr-control-helper>add or remove element in the table</clr-control-helper>
							<clr-control-error>there is no more element in the table!</clr-control-error>
						</clr-toggle-container>
					</div>
					<div class="clr-col-lg-1 clr-col-md-1 clr-col-1" style="padding-right: 0px; text-align: right;">
						<span class="spinner spinner-inline" style="vertical-align: text-bottom;" *ngIf="isProcessing">Loading...</span>
					</div>
				</div>
				<div class="clr-row">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				</div>
				<div class="clr-row">
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
						<span [ngClass]="cri_class" (click)="filterTs('critical')">
							Degradation
							<span [ngClass]="cri_badge_class">{{cri_nb}}</span>
						</span>
						<span [ngClass]="cpu_class" (click)="filterTs('cpu')">
							CPU
							<span class="badge">{{cpu_nb}}</span>
						</span>
						<span [ngClass]="disk_class" (click)="filterTs('disk')">
							DISK
							<span class="badge">{{disk_nb}}</span>
						</span>
						<span [ngClass]="net_class" (click)="filterTs('net')">
							NET
							<span class="badge">{{net_nb}}</span>
						</span>
						<span [ngClass]="ram_class" (click)="filterTs('ram')">
							RAM
							<span class="badge">{{ram_nb}}</span>
						</span>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<table id="ts-vm-list" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  					<thead>
    						<tr>
      							<th>NAME</th>
      							<th>STATUS</th>
      							<th>RESOURCES</th>
						</tr>
  					</thead>
					<tbody>
    						<tr *ngFor="let ts of ts_model" class="addLink" (click)="callCardList(ts)">
							<td>{{ts.name}}</td>
							<td>
								<img [@rotatedState]='{value: ts.overall_arrow_trigger, params: {ts_overall_arrow_o: ts.overall_arrow_o}}' style="border-radius:50%" src="{{ts.overall_url}}"><span style="display:none;">{{ts.weight}}</span>
							</td>
							<td>
								<span [ngClass]="ts.cpu_class" [ngStyle]="{'background-color':ts.cpu_class === 'badge badge-orange' ? '#f3a10b' : ts.cpu_class === 'badge badge-danger' ? '#ff0000' : 'green'}" *ngIf="!ts.cpu_tri && greenResources">CPU</span>
								<span [ngClass]="ts.cpu_class" [ngStyle]="{'background-color':ts.cpu_class === 'badge badge-orange' ? '#f3a10b' : ts.cpu_class === 'badge badge-danger' ? '#ff0000' : 'green'}" *ngIf="ts.cpu_tri">CPU</span>
								<span [ngClass]="ts.disk_class" [ngStyle]="{'background-color':ts.disk_class === 'badge badge-orange' ? '#f3a10b' : ts.disk_class === 'badge badge-danger' ? '#ff0000' : 'green'}" *ngIf="!ts.disk_tri && greenResources">DISK</span>
								<span [ngClass]="ts.disk_class" [ngStyle]="{'background-color':ts.disk_class === 'badge badge-orange' ? '#f3a10b' : ts.disk_class === 'badge badge-danger' ? '#ff0000' : 'green'}" *ngIf="ts.disk_tri">DISK</span>
								<span [ngClass]="ts.net_class" [ngStyle]="{'background-color':ts.net_class === 'badge badge-orange' ? '#f3a10b' : ts.net_class === 'badge badge-danger' ? '#ff0000' : 'green'}" *ngIf="!ts.net_tri && greenResources">NET</span>
								<span [ngClass]="ts.net_class" [ngStyle]="{'background-color':ts.net_class === 'badge badge-orange' ? '#f3a10b' : ts.net_class === 'badge badge-danger' ? '#ff0000' : 'green'}" *ngIf="ts.net_tri">NET</span>
								<span [ngClass]="ts.ram_class" [ngStyle]="{'background-color':ts.ram_class === 'badge badge-orange' ? '#f3a10b' : ts.ram_class === 'badge badge-danger' ? '#ff0000' : 'green'}" *ngIf="!ts.ram_tri && greenResources">RAM</span>
								<span [ngClass]="ts.ram_class" [ngStyle]="{'background-color':ts.ram_class === 'badge badge-orange' ? '#f3a10b' : ts.ram_class === 'badge badge-danger' ? '#ff0000' : 'green'}" *ngIf="ts.ram_tri">RAM</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="clr-col-xxl-7 clr-col-xl-6 clr-col-12">
		<div class="clr-row">
			<div [ngClass]="col_class" *ngIf="vmCardListLoad">
				<div class="card">
					<div class="card-header card-ajust">
						<h5>
							<cds-icon shape="vm"></cds-icon>
							&nbsp;{{ts_selected.name}}
							<div>&nbsp;</div>
							{{this.vm_selected.vcpu}}vCPUs - {{math.round(this.vm_selected.vram/1024)}}GB RAM - {{this.vm_selected.ds}}GB Disk
						</h5>
					</div>
					<div class="card-block">
						<ng-template load-troubleshooting-card-list></ng-template>
					</div>
					<div class="card-footer">
						<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="loadServerInfo()">
								SERVER INFORMATION
						</button>
					</div>
				</div>
			</div>
			<div class="clr-col-xxl-6 clr-col-12" *ngIf="serverListLoad">
				<div class="card">
					<div class="card-header card-ajust">
						<h5>
							<cds-icon shape="host"></cds-icon>
							&nbsp;{{host_selected.name}}
							<div>&nbsp;</div>
							{{this.host_selected.cpucap}}CPUs - {{math.round(this.host_selected.rams/1024)}}GB RAM
						</h5>
					</div>
					<div class="card-block">
						<table class="table">
    							<thead>
        							<tr>
            								<th class="left">Resource</th>
	    								<th class="left">Description</th>
            								<th>warning pts</th>
            								<th>critical pts</th>
        							</tr>
    							</thead>
    							<tbody>
        							<tr class="addLink" (click)="callGraph('smo')">
            								<td class="left">CPU</td>
            								<td class="left">Too much activity on server</td>
	    								<td>
		    								<span class="badge badge-orange" style="background-color:#f3a10b;">{{threshold_pts.pts_cpu_crsr_orange}}</span>
	    								</td>
	    								<td>
		    								<span class="badge badge-danger" style="background-color:#ff0000;">{{threshold_pts.pts_cpu_crsr_red}}</span>
	    								</td>
        							</tr>
        							<tr class="addLink" (click)="callGraph('sdisk')">
            								<td class="left">DISK</td>
            								<td class="left">Total latency</td>
	    								<td>
		    								<span class="badge badge-orange" style="background-color:#f3a10b;">{{threshold_pts.pts_disk_lat_orange}}</span>
	    								</td>
	    								<td>
		    								<span class="badge badge-danger" style="background-color:#ff0000;">{{threshold_pts.pts_disk_lat_red}}</span>
	    								</td>
        							</tr>
        							<tr class="addLink" (click)="callGraph('snet')">
            								<td class="left">NET</td>
            								<td class="left">Number of lost packets</td>
	    								<td>
		    								<span class="badge badge-orange" style="background-color:#f3a10b;">{{threshold_pts.pts_net_orange}}</span>
	    								</td>
	    								<td>
		    								<span class="badge badge-danger" style="background-color:#ff0000;">{{threshold_pts.pts_net_red}}</span>
	    								</td>
        							</tr>
        							<tr class="addLink" (click)="callGraph('sram')">
            								<td class="left">RAM</td>
            								<td class="left">Too much activity on server </td>
	    								<td>
		    								<span class="badge badge-orange" style="background-color:#f3a10b;">{{threshold_pts.pts_ram_orange}}</span>
	    								</td>
	    								<td>
		    								<span class="badge badge-danger" style="background-color:#ff0000;">{{threshold_pts.pts_ram_red}}</span>
	    								</td>
        							</tr>
    							</tbody>
    						</table>
					</div>
					<div class="card-footer">
						<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="hideServerInfo()">
								HIDE SERVER INFORMATION
						</button>
					</div>
				</div>
			</div>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="vmCardGraphLoad">
				<div class="card">
					<div class="card-block">
						<ng-template load-troubleshooting-graph></ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
