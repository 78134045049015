import {Component, OnInit, ViewChild} from '@angular/core';
import {NetscopeService} from "@app/services";
import {TranslocoService} from "@ngneat/transloco";
import {
  NetscopeThreatCenterComponent
} from "@app/netscope/netscope-supervision/netscope-threat-center/netscope-threat-center.component";
import {NetscopeReportsComponent} from "@app/netscope/netscope-supervision/netscope-reports/netscope-reports.component";
import {
  NetscopeOutsideFlowsComponent
} from "@app/netscope/netscope-supervision/netscope-outside-flows/netscope-outside-flows.component";


@Component({
  selector: 'app-netscope-supervision',
  templateUrl: './netscope-supervision.component.html',
  styleUrls: ['./netscope-supervision.component.css']
})
export class NetscopeSupervisionComponent implements OnInit {

  failureMode = false;
  isLoading = false;

  @ViewChild('reportsComponent')
  reportsComponent: NetscopeReportsComponent;
  @ViewChild('troubleshootingComponent')
  troubleshootingComponent: NetscopeOutsideFlowsComponent;
  @ViewChild('threatCenterComponent')
  threatCenterComponent: NetscopeThreatCenterComponent;

  constructor(private netscopeService: NetscopeService, public translocoService: TranslocoService) { }

  ngOnInit(): void {
  }

  reloadData(): void {
    this.isLoading = true;

    if (this.reportsComponent != undefined) {
      this.reportsComponent.reloadData();
    }

    if (this.troubleshootingComponent != undefined) {
      this.troubleshootingComponent.reloadData();
    }

    if (this.threatCenterComponent != undefined) {
      this.threatCenterComponent.reloadData();
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  }

  launchTour = () => {

  }

  setLanguage = (language: "french" | "english") => {
    let languageMap: Map<string, string> = new Map([
      ["french", "fr"],
      ["english", "en"]
    ]);
    let language_code = languageMap.get(language);
    this.translocoService.setDefaultLang(language_code);
    this.translocoService.setActiveLang(language_code);
    localStorage.setItem("language", language_code);
  }

  updateLoadingState = (newLoadingState: boolean) => {
    this.isLoading = newLoadingState;
  }

  updateErrorState = (newErrorState: boolean) => {
    this.failureMode = newErrorState;
  }

}
