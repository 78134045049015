<table id="alert-trigger-history" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  	<thead>
    		<tr>
      			<th>ELEMENT</th>
      			<th>TYPE</th>
      			<th>ALERT</th>
      			<th>BASED ON</th>
      			<th>ABOUT</th>
      			<th>STATE</th>
      			<th>WARNING</th>
      			<th>CRITICAL</th>
      			<th>GRANULARITY</th>
    		</tr>
  	</thead>
  	<tbody>
		<tr *ngFor="let alert of historical_alerts">
			<td>{{alert.name}}</td>
			<td>{{alert.type}}</td>
			<td>{{alert.alert}}</td>
			<td>{{alert.base}}</td>
			<td>{{alert.definition}}</td>
			<td>{{alert.state}}</td>
			<td>{{alert.th1}}</td>
			<td>{{alert.th2}}</td>
			<td>{{alert.granularity}}</td>
		</tr>
  	</tbody>
</table>
