<div class="clr-row">
        <div [ngClass]="model.carbonDashboardClass" *ngIf="model.isCo2Dashboard">
                <div class="card">
                        <div class="card-header" style="text-align: center;">
                                <span style="color:grey;font-size:16px;text-align: center;">
                                        {{model.entityMsg}} Carbon Emission
                                </span>
                                <br>
                                <fa-icon [icon]="['fas', 'leaf']" style="color:grey;font-size:14px;"></fa-icon>&nbsp;&nbsp;
                                <span style="color:black;font-size:20;">
                                        <b>{{message.cloudMessage.metaCo2.aggregate}} kgCO2 eq</b>
                                </span>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <highcharts-chart
                                                        id="spec-dashboard-co2"
                                                        [Highcharts] = "highcharts"
                                                        [options] = "chartOptions1"
                                                        [constructorType]="'chart'"
                                                        style = "width: 100%;height: 275px;display: block;" 
                                                        *ngIf="isReady">
                                                </highcharts-chart>
                                        </div>
                                </div>
                        </div>
                        <div class="card-footer">
                                <span style="float: left;color: grey; font-weight: bold;">
                                        {{model.profilMsg}}
                                </span>
                                <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadCo2Detail()" *ngIf="model.detail_str == ''">
                                        details ...
                                </span>
                                <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="initData()" *ngIf="model.detail_str == 'co2'">
                                        hide details
                                </span>
                                <br>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="model.isCostDashboard">
                <div class="card">
                        <div class="card-header" style="text-align: center;">
                                <span style="color:grey;font-size:16px;text-align: center;">
                                        Total Costs
                                </span>
                                <br>
                                <span style="color:black;font-size:20;">
                                        <b>$ {{metadata.computeCost+metadata.storageCost}}</b>
                                </span>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <highcharts-chart
                                                        id="spec-dashboard-cost"
                                                        [Highcharts] = "highcharts"
                                                        [options] = "chartOptions2"
                                                        [constructorType]="'chart'"
                                                        style = "width: 100%;height: 275px;display: block;" 
                                                        *ngIf="isReady2">
                                                </highcharts-chart>
                                        </div>
                                </div>
                        </div>
                        <div class="card-footer">
                                <span style="float: left;color: grey; font-weight: bold;">
                                        over the current month
                                </span>
                                <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadCostDetail()" *ngIf="model.detail_str == ''">
                                        details ...
                                </span>
                                <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="initData()" *ngIf="model.detail_str == 'cost'">
                                        hide details
                                </span>
                                <br>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngIf="model.isInfraDashboard">
                <div class="card">
                        <div class="card-header" style="text-align: center;">
                                <span style="color:grey;font-size:16px;text-align: center;">
                                        Infrastructure
                                </span>
                                <br>
                                <cds-icon shape="vm"></cds-icon>&nbsp;&nbsp;
                                <span style="color:black;font-size:20;" id="spec-cloud-provider-aws-total-instance">
                                        <b>{{metadata.runInstance}} running instances</b>
                                </span>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <highcharts-chart
						        [Highcharts] = "highcharts"
							[options] = "chartOptions3"
							[constructorType]="'chart'"
							style = "width: 100%;height: 275px;display: block;" 
                                                        *ngIf="isReady3">
                                                </highcharts-chart>
                                        </div>
                                </div>
                        </div>
                        <div class="card-footer">
                                <span style="float: left;color: grey; font-weight: bold;">
                                        over the current month
                                </span>
                                <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadInstancesDetail()" *ngIf="model.detail_str == ''">
                                        details ...
                                </span>
                                <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="initData()" *ngIf="model.detail_str == 'instance'">
                                        hide details
                                </span>
                                <br>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-5 clr-col-md-5 clr-col-12" *ngIf="model.isConsoDashboard">
                <div class="card">
                        <div class="card-header" style="text-align: center;">
                                <span style="color:grey;font-size:16px;text-align: center;">
                                        Cloud Consumption
                                </span>
                                <br>
                                <span style="color:black;font-size:20;">
                                        <b>over {{metadata.nbSubscriptions}} account and {{metadata.nbRegions}} region</b>
                                </span>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <div class="clr-row">
                                                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                                <span style="left: right;color: grey;">
                                                                        Elasticity
                                                                </span>
                                                        </div>
                                                        <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                                                                <span style="font-size:16px;font-weight: bold;">{{metadata.kpiEvolutionCreated}} </span>
                                                                <span>instances created over the current month</span>
                                                                <br>
                                                                <span style="float: right;padding-bottom: 10px;color: #057ff5;" ngClass="addLink" (click)="loadElasticity()">
                                                                        display graph ...
                                                                </span>
                                                        </div>
                                                </div>
                                        </div>
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <div class="clr-row">
                                                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                                <span style="left: right;color: grey;">
                                                                        Dynamicity
                                                                </span>
                                                        </div>
                                                        <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                                                                <span style="font-size:16px;font-weight: bold;" id="spec-cloud-provider-aws-dynamicity">{{model.more30Days}} </span>
                                                                <span>instances that are over 30 days old</span>
                                                                <br>
                                                                <span style="float: right;padding-bottom: 10px;color: #057ff5;" ngClass="addLink" (click)="loadDynamicity()">
                                                                        display graph ...
                                                                </span>
                                                        </div>
                                                </div>
                                        </div>
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <div class="clr-row">
                                                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                                <span>
                                                                        Cpu Credits
                                                                </span>
                                                        </div>
                                                        <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                                                                <span style="font-size:16px;font-weight: bold;" id="spec-cloud-provider-aws-credits">{{metadata.kpiCreditAggUsage}} </span>
                                                                <span>credits used over the last day</span>
                                                                <br>
                                                                <span style="float: right;padding-bottom: 10px;color: #057ff5;" ngClass="addLink" (click)="loadCreditsDetail()">
                                                                        display graph ...
                                                                </span>
                                                        </div>
                                                </div>
                                        </div>
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <div class="clr-row">
                                                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                                <span style="left: right;color: grey;">
                                                                        Resource Usage
                                                                </span>
                                                        </div>
                                                        <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                                                                <span style="font-size:16px;font-weight: bold;">{{metadata.runInstance}} </span>
                                                                <span>running instances</span>
                                                                <br>
                                                                <span style="float: right;padding-bottom: 10px;color: #057ff5;" ngClass="addLink" (click)="loadResourceUsage()">
                                                                        details ...
                                                                </span>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-7 clr-col-md-7 clr-col-12" *ngIf="model.isElasticityDashboard">
                <div class="card">
                        <div class="card-header" style="height: 60px;">
                                <span style="color:grey;font-size:20px;">
                                        <cds-icon shape="vm"></cds-icon>
                                        ELASTICITY
                                </span>
                                <clr-tooltip>
                                        <cds-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></cds-icon>
                                        <clr-tooltip-content clrPosition="middle-right" clrSize="lg" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                                <span style="color: #0072a3">Elasticity</span>
                                                <p>
                                                        The objective is to ensure that the number of instances created each day does not increase exponentially.
                                                        <br><br>The graph shows the last 30 days.
                                                        <br>The green bar indicates the number of deleted instances for a given day.
                                                        <br>The blue bar indicates the number of instances created for a given day.
                                                        <br>The red dot indicates the number of instances existing at the end of the day.
                                                        <br>If the red dot drifts to the right, the number of instances increases compared to the previous day. Conversely, if the red dot drifts to the left, the number of instances decreases compared to the previous day.
                                                </p>
                                        </clr-tooltip-content>
                                </clr-tooltip>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <highcharts-chart
                                                        id="spec-elasticity"
                                                        [Highcharts] = "highcharts"
                                                        [options] = "chartOptions4"
                                                        [constructorType]="'chart'"
                                                        style = "width: 100%;height: 275px;display: block; " 
                                                        *ngIf="model.isElasticityDashboard">
                                                </highcharts-chart>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-7 clr-col-md-7 clr-col-12" *ngIf="model.isDynamicityDashboard">
                <div class="card">
                        <div class="card-header" style="height: 60px;">
                                <span style="color:grey;font-size:20px;">
                                        <cds-icon shape="vm"></cds-icon>
                                        DYNAMICITY
                                </span>
                                <clr-tooltip>
                                        <cds-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></cds-icon>
                                        <clr-tooltip-content clrPosition="middle-right" clrSize="md" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                                <span style="color: #0072a3">Dynamicity</span>
                                                <p>
                                                        The objective is to ensure that the instances created have a short lifespan.
                                                        <br><br>The graph shows how long ago the instances were created.
                                                        <br>For example {{model.insLastDay}} instances have been created since {{model.lastDay}} days.
                                                </p>
                                        </clr-tooltip-content>
                                </clr-tooltip>
                        </div>
                        <div class="card-block">
                                <div class="clr-row">
                                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                <highcharts-chart
                                                        id="spec-dynamicity"
                                                        [Highcharts] = "highcharts"
                                                        [options] = "chartOptions5"
                                                        [constructorType]="'chart'"
                                                        style = "width: 100%;height: 275px;display: block; " 
                                                        *ngIf="model.isDynamicityDashboard">
                                                </highcharts-chart>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
        <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                <app-configurator *ngIf="model.detail_str == 'co2'" #addConfigurator></app-configurator>
        </div>
        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                <app-statistical *ngIf="model.detail_str == 'co2'" #addStatistical></app-statistical>
        </div>
</div>
<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <ng-template cloud-co2-detail *ngIf="model.detail_str == 'co2'"></ng-template>
        <ng-template cloud-costs-detail *ngIf="model.detail_str == 'cost'"></ng-template>
        <ng-template cloud-instances-detail *ngIf="model.detail_str == 'instance'"></ng-template>
        <ng-template cloud-kpi-credits *ngIf="model.detail_str == 'kpicredits'"></ng-template>
        <app-infra-detail *ngIf="model.isResourceUsage"></app-infra-detail>
</div>

    <!--<div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngIf="kpiSavingsplans.length > 0">
            <div class="card">
                    <div class="card-header" style="height: 60px;">
                            <div class="clr-row">
                                    <div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
                                            <span style="color:grey;font-size:20px;">
                                                    <cds-icon shape="book"></cds-icon>
                                                    SAVINGS PLAN
                                            </span>
                                            <clr-tooltip>
                                                    <cds-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></cds-icon>
                                                    <clr-tooltip-content clrPosition="left" clrSize="lg" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                                            <span style="color: #0072a3">Savings plan</span>
                                                            <p>
                                                                    The objective is to show the utilization rate of a saving plan which must be between 90 and 100% each day.
                                                                    <br><br>The graph shows the distribution of the number of days according to the utilization rate.
                                                                    <br>Normal means that the utilization rate is between 90 and 100%.
                                                                    <br>Underused means that the utilization rate is less than 90%.
                                                                    <br>Overused means that the utilization rate is greater than 100%.
                                                            </p>
                                                    </clr-tooltip-content>
                                            </clr-tooltip>
                                            </div>
                                            <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                                            <div style="float:right;">
                                                    <div class="clr-control-container">
                                                            <div class="clr-select-wrapper">
                                                                    <select class="clr-select" [(ngModel)]="model.selectedId" (ngModelChange)="loadSp($event)">
                                                                            <option *ngFor="let sp of kpiSavingsplans">
                                                                                    savings plan {{sp.id}}
                                                                            </option>
                                                                    </select>
                                                            </div>
                                                            <span class="clr-subtext">select a savings plan</span>
                                                    </div>
                                            </div>
                                    </div>
                            </div>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                                            <highcharts-chart
                                                    [Highcharts] = "highcharts"
                                                    [options] = "chartOptions2"
                                                    [constructorType]="'chart'"
                                                    style = "width: 120%;height: 275px;display: block; z-index: 10;" 
                                                    *ngIf="isReady2">
                                            </highcharts-chart>
                                    </div>
                                    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12" style="padding-top: 180px;z-index: 99;">
                                            <span class="clr-subtext" style="float: right;">
                                                    usage rate
                                            </span>
                                            <br>
                                            <span [ngClass]="model.maxSpColor" style="font-size: 14px;">
                                                    {{model.maxSp}} %
                                            </span><br>
                                            <span [ngClass]="model.avgSpColor" style="font-size: 18px;padding-right: 10px;">
                                                    {{model.avgSp}} %
                                            </span><br>
                                            <span [ngClass]="model.minSpColor" style="font-size: 14px;">
                                                    {{model.minSp}} %
                                            </span>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
                            <span style="font-size: 28px">
                                    {{model.lastSp}}%
                            </span>
                            <br>
                            <span>
                                    usage rate over the last day 
                            </span>
                            <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadSpDetail()">
                                    more ...
                            </span>
                    </div>
            </div>
    </div>
</div>-->
<!--<div class="card-block">
    <app-infra-detail *ngIf="isInfraDetail"></app-infra-detail>
    <ng-template cloud-instances-detail *ngIf="detail_str == 'instance'"></ng-template>
    <ng-template cloud-costs-detail *ngIf="detail_str == 'cost'"></ng-template>
    <ng-template cloud-co2-detail *ngIf="detail_str == 'co2'"></ng-template>
    <ng-template cloud-kpi-credits *ngIf="detail_str == 'kpicredits'"></ng-template>
    <ng-template cloud-kpi-savingsplan *ngIf="detail_str == 'kpisp'"></ng-template>
    <ng-template cloud-kpi-evolution *ngIf="detail_str == 'kpievolution'"></ng-template>
</div>-->