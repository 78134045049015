import {
    CloudService
} from './cloud.service';

import {
    HttpClient
} from "@angular/common/http";
import {
    Injectable
} from '@angular/core';
import {
    environment
} from '@environments/environment';
import {
    Observable
} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CloudPricingService {

    constructor(private cloudService: CloudService, private http: HttpClient) {}

    computeProviderCosts(vmsRaw, providerData, onPremiseCosts, selectedProviderRegion, billingType, mapOS,
        exchangeRates, currencyOption, period) {
        let localVms = Object.entries(vmsRaw).map(([k, v]) => Object.assign(v, {
            "uuid": k
        }));

        // For each VM, find its compute type and its storage types
        let costData = this.compute(
            selectedProviderRegion,
            billingType,
            providerData.computeTypes,
            providerData.computePrices[selectedProviderRegion],
            providerData.storageTypes,
            providerData.storagePrices[selectedProviderRegion],
            mapOS,
            providerData.defaultOs,
            providerData.billingTypes,
            localVms);

        // Compute conversion factor: USD => chosen currency
        let exchangeRate = exchangeRates.currency[currencyOption].rate;

        // Period factor
        let periodFactor = period == "month" ? 1.0 / 12 : 1;

        // Prepare new compute types UI states
        let listedVMs = [];
        for (let vm of costData.vms) {

            let computeCost = vm.computeCost * exchangeRate * periodFactor;
            let storageCost = vm.storageCost * exchangeRate * periodFactor;
            let totalCost = (vm.computeCost + vm.storageCost) * exchangeRate * periodFactor;

            // Computes the savings for each vm
            let savings = {};
            for (let onPrVm of onPremiseCosts) {
                if (onPrVm.uuid === vm.uuid)
                    savings = {
                        "compute": (onPrVm.server_cost + onPrVm.cpu_cost + onPrVm.ram_cost) - computeCost,
                        "storage": onPrVm.sto_cost - storageCost,
                        "total": onPrVm.total_cost - totalCost,
                    };
            }

            listedVMs.push({
                "name": vm.vm,
                "compute": vm.instanceTypeKey,
                "storage": vm.storageTypeKey,
                "computeCost": computeCost,
                "storageCost": storageCost,
                "totalCost": totalCost,
                "savings": savings,
                "operatingSystem": providerData.os[vm.osKey],
                "state": vm.state,
                "comment": vm.comment,
                "vcpu": vm.vcpu,
                "memory": vm.memory,
                "storageDebug": vm.storageDebug,
                "computeDebug": vm.computeDebug
            });
        }

        let computeCost = 0;
        let storageCost = 0;

        try {
            if (listedVMs.length > 0) {
                computeCost = listedVMs.map(vm => vm.computeCost).reduce((a, b) => a + b);
                storageCost = listedVMs.map(vm => vm.storageCost).reduce((a, b) => a + b);
            }
        } catch (e) {
            console.error("Error during computation of costs");
        }

        return {
            "providerData": providerData,
            "listedVMs": listedVMs,
            "compute": computeCost,
            "storage": storageCost,
            "total": computeCost + storageCost,
            "faultyVMs": listedVMs.filter((vm) => vm.comment)
        }
    }

    fetchLastUpdateDetails() {
        let cloudPricingDetailsUrl = `${environment.apiUrl}/v1/cloudpricing/lastupdatedetails`;
        return this.http.get(cloudPricingDetailsUrl, {});
    }

    fetchExchangeRates() {
        let cloudPricingDetailsUrl = `${environment.apiUrl}/v1/cloudpricing/exchangerates`;
        return this.http.get(cloudPricingDetailsUrl, {});
    }

    askToFetchNewPrices() {
        return new Observable((observer) => {
            this.http.get("https://www.easyvirt.com/downloadseasyvirt/prices.tar.bz2.enc", {
                responseType: 'blob'
            }).subscribe((archiveBlob) => {
                console.log("plop");

                var b: any = archiveBlob;
                //A Blob() is almost a File() - it's just missing the two properties below which we will add
                b.lastModifiedDate = new Date();
                b.name = "prices.tar.bz2.enc";

                //Cast to a File() type
                let archiveFile = < File > archiveBlob;

                let cloudPricingDetailsUrl = `${environment.apiUrl}/v1/cloudpricing/fetchnewprices`;
                let formData: FormData = new FormData();
                formData.append('file_upload', archiveFile, archiveFile.name);

                this.http.post < any > (cloudPricingDetailsUrl, formData).subscribe((value) => {
                    console.log("plop2");
                    observer.next(value);
                    observer.complete();
                })
            });
        });
    }

    fetchProviderDetails(providerName) {
        let cloudPricingDetailsUrl = `${environment.apiUrl}/v1/cloudpricing/${providerName}/details`;
        return this.http.get(cloudPricingDetailsUrl, {});
    }

    fetchProviderCarbonDetails(providerName) {
        let cloudCarbonDetailsUrl = `${environment.apiUrl}/v1/cloudcarbon/${providerName}/details`;
        return this.http.get(cloudCarbonDetailsUrl, {});
    }

    updateMyprovideDetails(newDetails) {
        let cloudPricingUpdatesUrl = `${environment.apiUrl}/v1/cloudpricing/myprovider/update`;
        return this.http.post(cloudPricingUpdatesUrl, {
            newDetails: newDetails
        });
    }

    updateMyprovideCarbonDetails(newDetails) {
        let cloudCarbonUpdatesUrl = `${environment.apiUrl}/v1/cloudcarbon/myprovider/update`;
        return this.http.post(cloudCarbonUpdatesUrl, {
            newDetails: newDetails
        });
    }

    /**
     * This returns the matched compute and storage
     * types for each virtual machine
     */
    compute(region, selectedBillingType, computeTypes, computePrices, storageTypes, storagePrices, mapOs, defaultOs,
        billingTypes, vms): any {

        let selectedRegion = region;

        let computedData = {};

        computedData["vms"] = [];

        let total = 0;
        let totalCompute = 0;
        let totalStorage = 0;

        let sortedInstanceTypeCache = {};

        for (let vm of vms) {
            let computeMatch = this.cloudService.matchComputeOffering(vm, computePrices, mapOs,
                sortedInstanceTypeCache, selectedBillingType, computeTypes, defaultOs, billingTypes);
            let storageMatch = this.cloudService.matchStorageOffering(vm, storageTypes, storagePrices);

            let instanceTypeKey = computeMatch['instanceTypeKey'] || null;
            let storageTypeKey = storageMatch['storageTypeKey'];
            let osKey = computeMatch['osKey'];
            let state = computeMatch['state'];
            let comment = computeMatch['comment'];

            let computeCost = 0;
            let storageCost = 0;

            if (computeMatch["price"]) {
                computeCost = computeMatch["price"] / billingTypes[selectedBillingType].converterFactor;
                total += computeCost;
                totalCompute += computeCost
            }

            if (storageMatch["price"]) {
                storageCost = storageMatch["price"] / billingTypes[selectedBillingType].converterFactor;
                total += storageCost;
                totalStorage += storageCost
            }

            let storageCapacity = vms['requiredStorage'];

            computedData["vms"].push({
                uuid: vm.uuid,
                vm: vm.name,
                state: state,
                instanceTypeKey: instanceTypeKey,
                storageTypeKey: storageTypeKey,
                osKey: osKey,
                storageCapacity: storageCapacity,
                computeCost: computeCost,
                storageCost: storageCost,
                totalCost: computeCost + storageCost,
                comment: comment,
                vcpu: vm.vcpu,
                memory: vm.memory,
                storageDebug: storageMatch["storageDebug"],
                computeDebug: computeMatch["computeDebug"]
            });
        }

        computedData["totalCost"] = total;
        computedData["totalComputeCost"] = totalCompute;
        computedData["totalStorageCost"] = totalStorage;

        return computedData
    }

}
