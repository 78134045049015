import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {
    Observable
} from 'rxjs';

import {
    AccountService,
    ShareService,
    JsonloaderService,
    ManagementService
} from '@app/services';
import {
    ReportService
} from '@app/services/report.service';

import {
    User,
    Message,
    Json,
    ElementType
} from '@app/model';

import {
    ClrWizard
} from "@clr/angular";

import * as uuid from 'uuid';

import {
    ReportEmail,
    ReportHistory,
    ReportListElement,
    ReportSettings
} from '@app/model/report';
import {
    ReportCard,
    ReportState
} from '../report.enums';
import {
    CronOptions
} from '../reportcron/reportcron_options';



export enum ReportLang {
    FR = "1",
        UK = "2",
        ES = "3"
}

export enum ReportType {
    INFRA = "infra",
        VM = "vm"
}

export enum ReportAuto {
    YES = "yes",
        NO = "no"
}

export enum ReportSection {
    BEHAVIOR = "behavior",
        RECOMMENDATION = "reco",
        KPI = "kpi",
        CAPA = "capa",
        GREENIT = "greenit"
}


@Component({
    selector: 'app-reportcard',
    templateUrl: './reportcard.component.html',
    styleUrls: ['./reportcard.component.css']
})
export class ReportcardComponent implements OnInit {

    @ViewChild("createReport") createReport: ClrWizard;

    @Input('target') target: ReportCard;

    @Input('data') objects_observable: Observable < any > ;
    objects_name: Map < string, any > ;

    @Output('update') update = new EventEmitter();
    pushUpdate() {
        this.update.emit(true);
    }

    @Output('info') errorInfo = new EventEmitter();
    pushErrorInfo() {
        this.errorInfo.emit(this.report_type);
    }

    message: Message;

    currentUser: User;

    jsonLoader: Json;

    // FOR UI
    card_header: string;
    card_body: string;
    card_button: string;
    card_button_class: string;

    custom = ReportCard.CUSTOM;
    edit = ReportCard.EDIT;

    //FOR REPORT
    report_wizard: boolean;
    report_uuid: string;
    report_name: string;
    report_lang: ReportLang;
    report_type: ReportType;
    report_auto: ReportAuto;
    report_mail: string;
    report_mails: Array < String > ;
    report_cron: any;
    report_filter_list: Array < ReportListElement > ;
    report_filter: string;

    infra_type: ReportType;

    behavior_section: ReportSection;
    reco_section: ReportSection;
    kpi_section: ReportSection;
    capa_section: ReportSection;
    greenit_section: ReportSection;

    behavior_all: boolean;
    behavior_scope: boolean;
    behavior_behavior: boolean;
    behavior_idle: boolean;
    behavior_lazy: boolean;
    behavior_busy: boolean;
    behavior_undersize: boolean;
    behavior_oversize: boolean;
    behavior_ghost: boolean;
    behavior_eol: boolean;
    behavior_zombie: boolean;
    //behavior_powervm: boolean;
    behavior_info: boolean;
    behavior_indeterminate: boolean;

    reco_all: boolean;
    reco_tools: boolean;
    reco_ocr: boolean;
    reco_resizing_vp: boolean;
    reco_resizing_memory: boolean;
    reco_lcr: boolean;
    reco_lcb: boolean;
    reco_indeterminate: boolean;

    kpi_all: boolean;
    kpi_evolution: boolean;
    kpi_cost: boolean;
    kpi_capa: boolean;
    kpi_trouble: boolean;
    kpi_indeterminate: boolean;

    capa_all: boolean;

    greenit_all: boolean;

    //VMWARE
    greenit_vmware_evolution: boolean;
    greenit_vmware_indicators: boolean;
    greenit_vmware_efficiency: boolean;
    greenit_vmware_efficiency_vms: boolean;

    //Nodes & Storage
    greenit_scaphandre: boolean;
    greenit_xclarity: boolean;
    greenit_openmanage: boolean;
    greenit_oneview: boolean;
    greenit_ipmi: boolean;
    greenit_storage: boolean;
    greenit_network: boolean;
    greenit_indeterminate: boolean;


    report_cron_options: CronOptions = {
        defaultTime: '05:00:00',
        defaultCron: "0 5 * * *"
    };

    // For elements type
    vmware_perimeter_check: boolean = false;
    scaphandre_perimeter_check: boolean = false;
    xclarity_perimeter_check: boolean = false;
    openmanage_perimeter_check: boolean = false;
    oneview_perimeter_check: boolean = false;
    ipmi_perimeter_check: boolean = false;
    storage_perimeter_check: boolean = false;
    network_perimeter_check: boolean = false;

    /**
     * 
     */
    constructor(private authenticationService: AccountService, private report_svc: ReportService,
        private message_svc: ShareService, private json_svc: JsonloaderService,
        private management_svc: ManagementService) {

        this.infra_type = ReportType.INFRA

        this.behavior_section = ReportSection.BEHAVIOR;
        this.reco_section = ReportSection.RECOMMENDATION;
        this.kpi_section = ReportSection.KPI;
        this.capa_section = ReportSection.CAPA;
        this.greenit_section = ReportSection.GREENIT;

        this.behavior_indeterminate = false;
        this.reco_indeterminate = false;
        this.kpi_indeterminate = false;
        this.greenit_indeterminate = false;

        //FOR OBJECTS NAME
        this.report_filter_list = [];
        this.objects_name = new Map < string, any > ();
    }


    /**
     * 
     */
    ngOnInit(): void {
        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        // Check elements type
        this.management_svc.getElementsType(this.currentUser.login, "all").subscribe(
            data => {
                let elements: any = data;
                elements.forEach((element: {
                    element_type: string;
                }) => {
                    let element_type = element.element_type.toUpperCase();
                    try {
                        let tmp_type: keyof typeof ElementType =
                            element_type as keyof typeof ElementType;
                        let tmp_type_enum: ElementType = ElementType[tmp_type];
                        switch (tmp_type_enum) {
                            case ElementType.SERVER:
                                this.vmware_perimeter_check = true;
                                break;
                            case ElementType.SCAPHANDRE_NODE:
                                this.scaphandre_perimeter_check = true;
                                break;
                            case ElementType.XCLARITY_NODE:
                                this.xclarity_perimeter_check = true;
                                break;
                            case ElementType.OPENMANAGE_NODE:
                                this.openmanage_perimeter_check = true;
                                break;
                            case ElementType.ONEVIEW_NODE:
                                this.oneview_perimeter_check = true;
                                break;
                            case ElementType.IPMI_NODE:
                                this.ipmi_perimeter_check = true;
                                break;
                            case ElementType.BACKUP:
                            case ElementType.SAN:
                            case ElementType.NAS:
                            case ElementType.EXAGRID_SAN:
                            case ElementType.IBM_SAN:
                                this.storage_perimeter_check = true;
                                break;
                            case ElementType.FIREWALL:
                            case ElementType.SWITCH:
                            case ElementType.WIFIACCESSPOINT:
                                this.network_perimeter_check = true;
                                break;
                            default:
                                break;
                        }
                    } catch {
                        console.log("Unchecked element : " + element_type);
                    }
                });
            }
        );


        // INIT OBJECTS
        this.objects_observable.subscribe(
            data => {
                this.objects_name = data;
            }
        );

        // INIT CARD    
        switch (this.target) {
            case ReportCard.MANAGE:
                this.card_header = "Management";
                this.card_body =
                    "A report with Key Performance Indicators of the status and evolution of the infrastructure.";
                this.card_button = "Management report";
                this.card_button_class = "btn btn-sm btn-link";
                break;
            case ReportCard.OPS:
                this.card_header = "Operations";
                this.card_body =
                    "A report of the current status of the infrastructure, inventory of resources, and status of virtual machines.";
                this.card_button = "Operations report";
                this.card_button_class = "btn btn-sm btn-link";
                break;
            case ReportCard.RECO:
                this.card_header = "Recommendations";
                this.card_body =
                    "A report with optimizations for your infrastructure: VM resizing, VM zombies, VMware tools status.";
                this.card_button = "Recommendations report";
                this.card_button_class = "btn btn-sm btn-link";
                break;
            case ReportCard.GREENIT:
                this.card_header = "Green IT";
                this.card_body =
                    "A report including Green IT informations such as annual energy consumption, direct and embodied carbon emissions.";
                this.card_button = "Green IT report";
                this.card_button_class = "btn btn-sm btn-link";
                break;
            case ReportCard.CUSTOM:
                this.card_header = "Personalized Report";
                this.card_body = "Create a specific report with the sections of your preference.";
                this.card_button = "Generate Personalized Report";
                this.card_button_class = "btn btn-sm btn-primary";
                break;
            case ReportCard.EDIT:
                this.card_header = "Edit Report";
                this.card_body = "";
                this.card_button = "";
                this.card_button_class = "";
                break;
            default:
                console.error("reportcard.target (" + this.target + ") not set properly !");
                break;
        }
    }

    /**
     * 
     */
    private initEmptyReport(): void {
        this.report_uuid = uuid.v4();
        this.report_name = "";
        this.report_lang = ReportLang.FR;
        this.report_type = ReportType.INFRA;
        this.report_filter = "";

        this.report_auto = ReportAuto.NO;
        this.report_mail = "";
        this.report_mails = [];
        this.report_cron = this.report_cron_options.defaultCron;
    }

    /**
     * 
     */
    private initSections(): void {

        switch (this.target) {
            case ReportCard.CUSTOM:
                this.behavior_all = this.showBehavior();
                this.reco_all = this.showReco();
                this.kpi_all = this.showKpi();
                this.capa_all = this.showCapa();
                this.greenit_all = this.showGreenit();
                break;
            case ReportCard.MANAGE:
                this.behavior_all = false;
                this.reco_all = false;
                this.kpi_all = this.showKpi();
                this.capa_all = this.showCapa();
                this.greenit_all = false;
                break;
            case ReportCard.OPS:
                this.behavior_all = this.showBehavior();
                this.reco_all = false;
                this.kpi_all = false;
                this.capa_all = false;
                this.greenit_all = false;
                break;
            case ReportCard.RECO:
                this.behavior_all = false;
                this.reco_all = this.showReco();
                this.kpi_all = false;
                this.capa_all = false;
                this.greenit_all = false;
                break;
            case ReportCard.GREENIT:
                this.behavior_all = false;
                this.reco_all = false;
                this.kpi_all = false;
                this.capa_all = false;
                this.greenit_all = this.showGreenit();
                break;
        }


        if (this.message.isCO2Scope)
            this.kpi_all = false;

        this.checkAll(ReportSection.BEHAVIOR);
        this.checkAll(ReportSection.RECOMMENDATION);
        this.checkAll(ReportSection.KPI);
        this.checkAll(ReportSection.CAPA);
        this.checkAll(ReportSection.GREENIT);

    }

    /**
     * 
     */
    initFilters(): void {
        this.report_filter = "";
        this.report_filter_list = [];

        // Init list for select ...
        switch (this.report_type) {
            case ReportType.VM:
                let i = 0;
                this.objects_name.forEach((value: any, key: string) => {
                    let tmp_filter: ReportListElement = {
                        id: value.id,
                        name: value.name,
                        type: value.type.toUpperCase()
                    };
                    this.report_filter_list[i] = tmp_filter;
                    i++;
                });
                //break;
                // add filters for ReportType.VM
            case ReportType.INFRA:
                let filters = this.message.filterList;
                for (let i in filters) {
                    let tmp_filter: ReportListElement = {
                        id: filters[i].name,
                        name: filters[i].name,
                        type: "FILTER"
                    };
                    this.report_filter_list[i] = tmp_filter;
                }
                break;
        }

        // ... and sort it by name
        this.report_filter_list.sort(function(a, b) {
            return (b.name.toUpperCase()) < (a.name.toUpperCase()) ? 1 : -1;
        });

        //if(this.report_filter_list[0] != undefined)
        //  this.report_filter = this.report_filter_list[0][1];

    }

    /**
     * 
     */
    checkReportName(): void {

        //Remove accent
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        for (var i = 0; i < accent.length; i++) {
            this.report_name = this.report_name.replace(accent[i], noaccent[i]);
        }

        //Remove specs chars
        this.report_name = this.report_name.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:.;?,<> ]/g, "_");

    }

    /**
     * 
     */
    addEmail(): void {
        this.report_mails.push(this.report_mail);
        this.report_mail = "";
    }

    /**
     * 
     */
    removeEmail(email: any): void {
        const index: number = this.report_mails.indexOf(email);
        if (index !== -1) {
            this.report_mails.splice(index, 1);
        }
    }

    /**
     * 
     */
    checkAll(section: ReportSection): void {

        setTimeout(() => {
            switch (section) {
                case ReportSection.BEHAVIOR:
                    this.behavior_scope = this.behavior_all;
                    this.behavior_behavior = this.behavior_all;
                    this.behavior_idle = this.behavior_all;
                    this.behavior_lazy = this.behavior_all;
                    this.behavior_busy = this.behavior_all;
                    this.behavior_undersize = this.behavior_all;
                    this.behavior_oversize = this.behavior_all;
                    this.behavior_ghost = this.behavior_all;
                    this.behavior_eol = this.behavior_all;
                    this.behavior_zombie = this.behavior_all;
                    //this.behavior_powervm = this.behavior_all;
                    this.behavior_info = this.behavior_all;
                    break;
                case ReportSection.RECOMMENDATION:
                    this.reco_tools = this.reco_all;
                    this.reco_ocr = this.reco_all;
                    this.reco_resizing_vp = this.reco_all;
                    this.reco_resizing_memory = this.reco_all;
                    this.reco_lcr = this.reco_all;
                    this.reco_lcb = this.reco_all;
                    break;
                case ReportSection.KPI:
                    this.kpi_evolution = this.kpi_all;
                    this.kpi_cost = this.kpi_all;
                    this.kpi_capa = this.kpi_all;
                    this.kpi_trouble = this.kpi_all;
                    break;
                case ReportSection.GREENIT:
                    this.greenit_vmware_evolution = this.greenit_all;
                    this.greenit_vmware_indicators = this.greenit_all;
                    this.greenit_vmware_efficiency = this.greenit_all;
                    this.greenit_vmware_efficiency_vms = this.greenit_all;
                    this.greenit_scaphandre = this.greenit_all;
                    this.greenit_xclarity = this.greenit_all;
                    this.greenit_openmanage = this.greenit_all;
                    this.greenit_oneview = this.greenit_all;
                    this.greenit_ipmi = this.greenit_all;
                    this.greenit_storage = this.greenit_all;
                    this.greenit_network = this.greenit_all;
                    break;
                default:
                    break;
            }
        });

    }

    /**
     * 
     */
    checkIndeterminate(section: ReportSection): void {

        setTimeout(() => {

            switch (section) {
                case ReportSection.BEHAVIOR:
                    if (this.behavior_scope && this.behavior_behavior && this.behavior_idle &&
                        this.behavior_lazy && this.behavior_busy && this.behavior_undersize && this
                        .behavior_oversize &&
                        this.behavior_ghost && this.behavior_eol && this.behavior_zombie && this
                        .behavior_info) {
                        this.behavior_all = true;
                        this.behavior_indeterminate = false;
                    } else if (!this.behavior_scope && !this.behavior_behavior && !this.behavior_idle &&
                        !this.behavior_lazy && !this.behavior_busy && !this.behavior_undersize && !this
                        .behavior_oversize &&
                        !this.behavior_ghost && !this.behavior_eol && !this.behavior_zombie && !this
                        .behavior_info) {
                        this.behavior_all = false;
                        this.behavior_indeterminate = false;
                    } else {
                        this.behavior_all = false;
                        this.behavior_indeterminate = true;
                    }
                    break;
                case ReportSection.RECOMMENDATION:
                    if (this.reco_tools && this.reco_ocr && this.reco_resizing_vp && this
                        .reco_resizing_memory && this.reco_lcr && this.reco_lcb) {
                        this.reco_all = true;
                        this.reco_indeterminate = false;
                    } else if (!this.reco_tools && !this.reco_ocr && !this.reco_resizing_vp && !this
                        .reco_resizing_memory && !this.reco_lcr && !this.reco_lcb) {
                        this.reco_all = false;
                        this.reco_indeterminate = false;
                    } else {
                        this.reco_all = false;
                        this.reco_indeterminate = true;
                    }
                    break;
                case ReportSection.KPI:
                    if (this.kpi_evolution && this.kpi_cost && this.kpi_capa && this.kpi_trouble) {
                        this.kpi_all = true;
                        this.kpi_indeterminate = false;
                    } else if (!this.kpi_evolution && !this.kpi_cost && !this.kpi_capa && !this
                        .kpi_trouble) {
                        this.kpi_all = false;
                        this.kpi_indeterminate = false;
                    } else {
                        this.kpi_all = false;
                        this.kpi_indeterminate = true;
                    }
                    break;
                case ReportSection.GREENIT:
                    this.greenit_indeterminate = true;

                    if (this.greenit_vmware_evolution && this.greenit_vmware_indicators && this
                        .greenit_vmware_efficiency && this.greenit_vmware_efficiency_vms &&
                        this.greenit_scaphandre && this.greenit_xclarity && this.greenit_openmanage && this
                        .greenit_oneview && this.greenit_ipmi && this.greenit_storage && this
                        .greenit_network) {
                        this.greenit_all = true;
                        this.greenit_indeterminate = false;
                    } else if (!this.greenit_vmware_evolution && !this.greenit_vmware_indicators && !this
                        .greenit_vmware_efficiency && !this.greenit_vmware_efficiency_vms &&
                        !this.greenit_scaphandre && !this.greenit_xclarity && !this.greenit_openmanage && !
                        this.greenit_oneview && !this.greenit_openmanage && !this.greenit_ipmi && !this
                        .greenit_storage && !this.greenit_network) {

                        this.greenit_all = false;
                        this.greenit_indeterminate = false;
                    } else {
                        this.greenit_all = false;
                        this.greenit_indeterminate = true;
                    }
                    break;
                default:
                    break;
            }

        });
    }

    /**
     * 
     */
    private saveReportSettings(): void {

        // Show progress
        this.message.waiting = true;


        let settings: ReportSettings = {

            // Report settings
            user: this.currentUser.login,
            uuid: this.report_uuid,
            lang: this.report_lang,

            // Behavior section
            behavior_scope: this.vmware_perimeter_check ? this.behavior_scope : false,
            behavior_behavior: this.vmware_perimeter_check ? this.behavior_behavior : false,
            behavior_idle: this.vmware_perimeter_check ? this.behavior_idle : false,
            behavior_lazy: this.vmware_perimeter_check ? this.behavior_lazy : false,
            behavior_busy: this.vmware_perimeter_check ? this.behavior_busy : false,
            behavior_under: this.vmware_perimeter_check ? this.behavior_undersize : false,
            behavior_over: this.vmware_perimeter_check ? this.behavior_oversize : false,
            behavior_ghost: this.vmware_perimeter_check ? this.behavior_ghost : false,
            behavior_zombie: this.vmware_perimeter_check ? this.behavior_zombie : false,
            //behavior_powervm : this.behavior_powervm,
            behavior_powervm: false,
            behavior_info: this.vmware_perimeter_check ? this.behavior_info : false,
            behavior_eol: this.vmware_perimeter_check ? this.behavior_eol : false,

            // Reco section
            reco_tools: this.vmware_perimeter_check ? this.reco_tools : false,
            reco_ocr: this.vmware_perimeter_check ? this.reco_ocr : false,
            reco_res_vp: this.vmware_perimeter_check ? this.reco_resizing_vp : false,
            reco_res_ram: this.vmware_perimeter_check ? this.reco_resizing_memory : false,
            reco_lcr: this.vmware_perimeter_check ? this.reco_lcr : false,
            reco_lcb: this.vmware_perimeter_check ? this.reco_lcb : false,

            // KPI section
            kpi_evol: this.vmware_perimeter_check ? this.kpi_evolution : false,
            kpi_cost: this.vmware_perimeter_check ? this.kpi_cost : false,
            kpi_capa: this.vmware_perimeter_check ? this.kpi_capa : false,
            kpi_trouble: this.vmware_perimeter_check ? this.kpi_trouble : false,

            // Capa section
            capa: this.vmware_perimeter_check ? this.capa_all : false,

            // Greenit section
            greeint_dc_evol: this.vmware_perimeter_check ? this.greenit_vmware_evolution : false,
            greeint_dc_indicators: this.vmware_perimeter_check ? this.greenit_vmware_indicators : false,
            greeint_dc_efficiency: this.vmware_perimeter_check ? this.greenit_vmware_efficiency : false,
            greeint_dc_efficiency_vms: this.vmware_perimeter_check ? this.greenit_vmware_efficiency_vms : false,
            greenit_scaphandre: this.scaphandre_perimeter_check ? this.greenit_scaphandre : false,
            greenit_xclarity: this.xclarity_perimeter_check ? this.greenit_xclarity : false,
            greenit_openmanage: this.openmanage_perimeter_check ? this.greenit_openmanage : false,
            greenit_oneview: this.oneview_perimeter_check ? this.greenit_oneview : false,
            greenit_ipmi: this.ipmi_perimeter_check ? this.greenit_ipmi : false,
            greenit_storage: this.storage_perimeter_check ? this.greenit_storage : false,
            greenit_network: this.network_perimeter_check ? this.greenit_network : false

        };

        // Save settings
        this.report_svc.saveReportSettings(settings).subscribe(
            success => {
                //console.log("succcess settings");
            },
            error => {
                console.log(error);
            }
        );


        let history: ReportHistory = {
            time: new Date().getTime(),
            uuid: this.report_uuid,
            user: this.currentUser.login,
            filter: this.report_filter,
            filter_type: this.report_filter_list.find(x => x.id == this.report_filter).type,
            state: ReportState.INITIALIZED,
            name: this.report_name,
            auto: this.report_auto,
            cron: this.report_auto == ReportAuto.YES ? this.report_cron : undefined,
            type: this.report_type
        };


        // Save history
        this.report_svc.saveReportHistory(history).subscribe(
            success => {
                //console.log("succcess history");
            },
            error => {
                console.log(error);
            }
        );

        // Save emails
        if (this.report_auto == ReportAuto.YES && this.report_mails.length > 0) {

            let emails: ReportEmail[] = [];

            for (let mail of this.report_mails) {
                let reportmail: ReportEmail = {
                    uuid: this.report_uuid,
                    email: mail as string
                };

                emails.push(reportmail);
            }

            this.report_svc.saveReportEmail(emails).subscribe(
                success => {
                    //console.log("succcess mails");
                },
                error => {
                    console.log(error);
                }
            );
        }

        // TODO progress bar ?
        setTimeout(() => {
            this.report_svc.generateReport(this.currentUser.login, this.report_uuid, this.report_type)
                .subscribe(
                    success => {
                        // Update datatables
                        this.pushUpdate();

                        this.message.waiting = false;
                    },
                    error => {
                        console.log(error);

                        // Push error
                        this.pushErrorInfo();

                        this.message.waiting = false;
                    }
                );
        }, 500);
    }

    /**
     * 
     */
    reportWizard(): void {
        this.report_wizard = true;
        this.initEmptyReport();
        this.initSections();
        this.initFilters();
    }

    /**
     * 
     */
    editWizard(settings: ReportSettings, history: ReportHistory, emails: ReportEmail[]): void {

        //Fill infos
        this.report_uuid = settings.uuid;
        this.report_name = history.name;

        let keys = Object.keys(ReportLang).filter(x => ReportLang[x] == settings.lang);
        let key = keys.length > 0 ? keys[0] : null;
        this.report_lang = ReportLang[key];

        keys = Object.keys(ReportType).filter(x => ReportType[x] == history.type);
        key = keys.length > 0 ? keys[0] : null;
        this.report_type = ReportType[key];

        this.initFilters();

        this.report_filter = history.filter;

        keys = Object.keys(ReportAuto).filter(x => ReportAuto[x] == history.auto);
        key = keys.length > 0 ? keys[0] : null;
        this.report_auto = ReportAuto[key];

        this.report_mail = "";
        this.report_mails = [];
        if (emails.length > 0) {
            for (let mail of emails)
                this.report_mails.push(mail.email);
        }

        this.report_cron = history.cron == undefined ? this.report_cron_options.defaultCron : history.cron;

        // Init sections
        if (this.report_type == ReportType.INFRA) {

            // Behavior section
            this.behavior_scope = String(settings.behavior_scope) == "true";
            this.behavior_behavior = String(settings.behavior_behavior) == "true";
            this.behavior_idle = String(settings.behavior_idle) == "true";
            this.behavior_lazy = String(settings.behavior_lazy) == "true";
            this.behavior_busy = String(settings.behavior_busy) == "true";
            this.behavior_undersize = String(settings.behavior_under) == "true";
            this.behavior_oversize = String(settings.behavior_over) == "true";
            this.behavior_ghost = String(settings.behavior_ghost) == "true";
            this.behavior_zombie = String(settings.behavior_zombie) == "true";
            this.behavior_info = String(settings.behavior_info) == "true";
            this.behavior_eol = String(settings.behavior_eol) == "true";

            // Reco section
            this.reco_tools = String(settings.reco_tools) == "true";
            this.reco_ocr = String(settings.reco_ocr) == "true";
            this.reco_resizing_vp = String(settings.reco_res_vp) == "true";
            this.reco_resizing_memory = String(settings.reco_res_ram) == "true";
            this.reco_lcr = String(settings.reco_lcr) == "true";
            this.reco_lcb = String(settings.reco_lcb) == "true";

            // KPI section
            this.kpi_evolution = String(settings.kpi_evol) == "true";
            this.kpi_cost = String(settings.kpi_cost) == "true";
            this.kpi_capa = String(settings.kpi_capa) == "true";
            this.kpi_trouble = String(settings.kpi_trouble) == "true";

            // Capa section
            this.capa_all = String(settings.capa) == "true";

            // Greenit section
            this.greenit_vmware_evolution = String(settings.greeint_dc_evol) == "true";
            this.greenit_vmware_indicators = String(settings.greeint_dc_indicators) == "true";
            this.greenit_vmware_efficiency = String(settings.greeint_dc_efficiency) == "true";
            this.greenit_vmware_efficiency_vms = String(settings.greeint_dc_efficiency_vms) == "true";
            this.greenit_scaphandre = String(settings.greenit_scaphandre) == "true";
            this.greenit_xclarity = String(settings.greenit_xclarity) == "true";
            this.greenit_openmanage = String(settings.greenit_openmanage) == "true";
            this.greenit_oneview = String(settings.greenit_oneview) == "true";
            this.greenit_ipmi = String(settings.greenit_ipmi) == "true";
            this.greenit_storage = String(settings.greenit_storage) == "true";
            this.greenit_network = String(settings.greenit_network) == "true";

            // Update 'inderminate'
            this.checkIndeterminate(ReportSection.BEHAVIOR);
            this.checkIndeterminate(ReportSection.RECOMMENDATION);
            this.checkIndeterminate(ReportSection.KPI);
            this.checkIndeterminate(ReportSection.GREENIT);

        }

        this.report_wizard = true;

    }

    /**
     * 
     */
    doReset(): void {
        this.createReport.reset();
    }

    /**
     * 
     */
    doFinish(): void {
        if (this.report_type == ReportType.VM) {

            this.behavior_all = false;
            this.reco_all = false;
            this.kpi_all = false;
            this.capa_all = false;
            this.greenit_all = false;
            this.checkAll(ReportSection.BEHAVIOR);
            this.checkAll(ReportSection.RECOMMENDATION);
            this.checkAll(ReportSection.KPI);
            this.checkAll(ReportSection.CAPA);
            this.checkAll(ReportSection.GREENIT);
        }

        // Need to wait checkAll call if ReportType.VM
        setTimeout(() => {
            this.saveReportSettings();
            this.createReport.reset();
        });

    }

    /**
     * 
     */
    closeReportWizard(): void {
        this.report_wizard = false;
    }


    /**
     * 
     */
    showBehavior(): boolean {
        return this.vmware_perimeter_check && true;
    }

    /**
     * 
     */
    showReco(): boolean {
        return this.vmware_perimeter_check && this.currentUser.isMenuReco && true;
    }

    /**
     * 
     */
    showCapa(): boolean {
        return this.vmware_perimeter_check && this.currentUser.isMenuCapa && true;
    }

    /**
     * 
     */
    showKpi(): boolean {
        return (this.vmware_perimeter_check && this.currentUser.isMenuKpi) && !this.message.isCO2Scope && true;
    }

    /**
     * 
     */
    showGreenit(): boolean {
        return this.currentUser.isMenuGreenit && true;
    }

}
