<div class="clr-row" *ngIf="isDcscope">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t1">
        <ng-template load-t1></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t2">
        <ng-template load-t2 ></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t3">
        <ng-template load-t3 ></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t4">
        <ng-template load-t4 ></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t5">
        <ng-template load-t5 ></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t6">
        <ng-template load-t6 ></ng-template>
    </div>
</div>
<div class="clr-row" *ngIf="isCo2scope">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t7">
        <ng-template load-t7></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t8">
        <ng-template load-t8></ng-template>
        <!-- <ng-template load-t14></ng-template> -->
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t1">
        <ng-template load-t1></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t14">
        <ng-template load-t9></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t10">
        <ng-template load-t10></ng-template>
    </div>
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" id="dashboard-t11">
        <ng-template load-t11></ng-template>
    </div>
</div>