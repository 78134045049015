<external-ips-wizard-import-csv #wizard>
</external-ips-wizard-import-csv>

<div style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-flow: column;">

    <clr-alert [clrAlertType]="'danger'" [clrAlertAppLevel]="true" *ngIf="failureMode && !isLoading">
        <clr-alert-item>
            <span class="alert-text">Cannot load data from DC Netscope API. Please check the diagnostic page.</span>
            <div class="alert-actions">
                <a routerLink="/netscope/diagnostic" routerLinkActive="active">
                    <button class="btn alert-action">
                        <cds-icon shape="checkbox-list"></cds-icon>
                        Diagnostic
                    </button>
                </a>
            </div>
        </clr-alert-item>
    </clr-alert>

    <div class="clr-row" style="margin: 0px; background-color: rgb(0, 55, 76); color : white; overflow: visible; min-height: 74px;">
        <div class="clr-col" id="flow_view_title" style="flex-grow: 0; color: white; text-align: center;">
            <table style="height: 100%; min-width: 130px; font-weight: 400; font-size: .8rem; letter-spacing: .01em; text-decoration: none;">
                <tr>
                    <td>
                        <cds-icon shape="note" style="margin-right: 10px; margin-bottom: 3px;" size="md"></cds-icon>
                    </td>
                    <td style="text-align: left;">{{ "dcnetscope.external_ips.name" | transloco }}</td>
                </tr>
            </table>
        </div>

        <!-- Empty column that separates title and group of buttons at the right -->
        <div class="clr-col" style="padding-top: 10px; padding-bottom: 5px;">
        </div>

        <div class="clr-col" style="width: 100px; flex-grow: 0; padding-left: 0px;">
            <table style="height: 100%;">
                <tr>
                    <td style="padding: 5px;">
                        <a href="javascript://" id="flow_view_reload_button" class="nav-link nav-icon" aria-label="reload">
                            <cds-icon shape="refresh" (click)="reloadData()" *ngIf="!isLoading" style="color: white;"></cds-icon>
                            <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <a href="javascript://" class="nav-link nav-icon" aria-label="tour">
                            <!--                            <cds-icon shape="help" style="color: white;" (click)="launchTour()"></cds-icon>-->
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <clr-dropdown [clrCloseMenuOnItemClick]="true">
                            <a clrDropdownTrigger href="javascript://" class="nav-link nav-icon" aria-label="settings">
                                <cds-icon shape="cog" style="color: white;"></cds-icon>
                            </a>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Language</label>
                                <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="setLanguage('french')">French</div>
                                <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="setLanguage('english')">English</div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </table>
        </div>
    </div>

<!--    <div class="clr-row" style="position: relative; height: 100%; bottom: 0px; width: 100%; margin: 0px;">-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--    <div style="width: 100%; height: 50px;">-->
<!--        &lt;!&ndash; Prevent the following content to be overlap by the top bar &ndash;&gt;-->
<!--    </div>-->

    <div class="clr-row" style="margin: 0px; margin-top: 10px;">
        <div class="clr-col-8">
            <span>

                <button (click)="openWizardImportCsv()" class="btn btn-primary btn-sm btn-icon" style="margin-left: 15px;">
                    + import from csv
                </button>

                <clr-dropdown>
                    <button class="btn btn-primary btn-sm btn-icon" clrDropdownTrigger>
                        Export
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <label class="dropdown-header" aria-hidden="true">
                            Export as CSV file
                        </label>
                        <div aria-label="Dropdown Manual apps groups" clrDropdownItem (click)="exportExternalIpsAsCsv()">External ips</div>
                        <div class="dropdown-divider"></div>
                        <label class="dropdown-header" aria-hidden="true">
                            <clr-radio-container clrInline>
                                <label>CSV separator</label>
                                <clr-radio-wrapper>
                                    <input type="radio" clrRadio name="options" required value="," [(ngModel)]="csvSperator" />
                                    <label>comma</label>
                                </clr-radio-wrapper>
                                <clr-radio-wrapper>
                                    <input type="radio" clrRadio name="options" required value=";" [(ngModel)]="csvSperator" />
                                    <label>semi-colon</label>
                                </clr-radio-wrapper>
                            </clr-radio-container>
                        </label>
                    </clr-dropdown-menu>
                </clr-dropdown>

                <span *ngIf="isLoading" style="margin: 0px;">
                    <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
                </span>
            </span>
        </div>
        <div class="clr-col-4">
            <span *ngIf="!isLoading" style="float: right;">
                <button (click)="reloadData()" class="btn btn-primary btn-sm" style="z-index: 1; float: right;">
                    <cds-icon shape="download"></cds-icon>
                    pull from server
                </button>
                <button class="btn btn-outline btn-sm" style="z-index: 1; float: right;" *ngIf="isPushingExternalIps">
                    <clr-spinner clrInline>
                    </clr-spinner>
                    pushing
                </button>
                <button (click)="pushData()" class="btn btn-success btn-sm" style="z-index: 1; float: right;" *ngIf="!isPushingExternalIps">
                    <cds-icon shape="upload"></cds-icon>
                    push to server
                </button>
            </span>
        </div>
    </div>

    <div class="clr-row" style="margin: 0px;">
        <div class="clr-col">
            <div class="card" style="margin-top: 10px;">
                <div class="card-block">
                    <div class="card-text">
                        <div class="clr-row">
                            <div class="clr-col-5">
                                <input class="clr-input" placeholder="Ip address" name="ipaddress" [(ngModel)]="newExternalIpAddress" style="width: 90%;" />
                                <a role="tooltip" aria-haspopup="true" style="margin-left: 5px;" class="tooltip tooltip-lg tooltip-right" *ngIf="newIpAddressIsInvalid">
                                    <cds-icon style="color: red;" shape="error-standard" ></cds-icon>
                                    <span class="tooltip-content">
                                        Please enter a valid ipv4 ou ipv6 address. Here are valid examples:
                                        <ul>
                                            <li>192.168.0.1</li>
                                            <li>2001:db8:3333:4444:5555:6666:7777:8888</li>
                                            <li>2001:db8::</li>
                                            <li>::</li>
                                        </ul>
                                    </span>
                                </a>
                            </div>
                            <div class="clr-col-5">
                                <input class="clr-input" placeholder="Name" name="name" [(ngModel)]="newExternalIpName" style="width: 90%;" />
                                <a role="tooltip" aria-haspopup="true" style="margin-left: 5px;" class="tooltip tooltip-lg tooltip-right" *ngIf="newNameIsInvalid">
                                    <cds-icon style="color: red;" shape="error-standard" ></cds-icon>
                                    <span class="tooltip-content">
                                        The name is invalid. Please use the following chars:
                                        <ul>
                                            <li>Lowercase characters [a-z]</li>
                                            <li>Uppercase characters [A-Z]</li>
                                            <li>Numbers [0-9]</li>
                                            <li>Underscode [_]</li>
                                        </ul>
                                    </span>
                                </a>
                            </div>
                            <div class="clr-col-2">
                                <button (click)="addExternalIp()" class="btn btn-success btn-sm" style="z-index: 1; float: right; width: 100%;" *ngIf="!isPushingExternalIps">
                                    Add external IP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="clr-row" style="margin: 0px;">
        <div class="clr-col">
            <clr-datagrid>
                <clr-dg-column>Ip address</clr-dg-column>
                <clr-dg-column>Name</clr-dg-column>
                <clr-dg-column>Actions</clr-dg-column>

                <clr-dg-row *ngFor="let ExternalIp of externalIpsData">
                    <clr-dg-cell>{{ExternalIp.ipAddress}}</clr-dg-cell>
                    <clr-dg-cell>{{ExternalIp.name}}</clr-dg-cell>
                    <clr-dg-cell>
                        <button (click)="deleteExternalIp(ExternalIp)" class="btn btn-danger btn-sm">
                            delete
                        </button>
                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>{{externalIpsData.length}} external ips</clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
</div>

