<!-- MODAL INSTANCES LIST -->
<clr-modal [(clrModalOpen)]="instances_list_modal" [clrModalSize]="'xl'">
    <h3 class="modal-title">Monitoring management</h3>
    <div class="modal-body" style="overflow:auto;min-height:500px;">
        <div style="width:100%;margin-bottom:50px;">
            <button class="btn btn-outline" style="position:relative;float:right;" (click)="initWizard(false,false,false)">
                New registration
            </button>
        </div>
        <clr-tabs>
            <clr-tab>
                <button clrTabLink [disabled]="badges.vmware == 0">VMware&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.vmware == 0" class="badge">{{badges.vmware}}</span></button>
                <clr-tab-content *clrIfActive="true"><ng-template [ngTemplateOutlet]="vmwaredatatable"></ng-template></clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink [disabled]="badges.cloud == 0">AWS / Azure / GCP&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.cloud == 0" class="badge">{{badges.cloud}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="clouddatatable"></ng-template></clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink [disabled]="badges.kube == 0">Kubernetes&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.kube == 0" class="badge">{{badges.kube}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="kubedatatable"></ng-template></clr-tab-content>
            </clr-tab>
            <clr-tab *ngIf="isCo2scope">
                <button clrTabLink [disabled]="badges.scaph == 0">Scaphandre Prometheus database&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.scaph == 0" class="badge">{{badges.scaph}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="scaphandredatatable"></ng-template></clr-tab-content>
            </clr-tab>
             <clr-tab *ngIf="isCo2scope">
                <button clrTabLink [disabled]="badges.xclarity == 0">Lenovo XClarity&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.xclarity == 0" class="badge">{{badges.xclarity}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="instancedatatable" [ngTemplateOutletContext]="{itype:xclarity_type}"></ng-template></clr-tab-content>
            </clr-tab>
            <clr-tab *ngIf="isCo2scope">
                <button clrTabLink [disabled]="badges.openmanage == 0">Dell OpenManage&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.openmanage == 0" class="badge">{{badges.openmanage}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="instancedatatable" [ngTemplateOutletContext]="{itype:openmanage_type}"></ng-template></clr-tab-content>
            </clr-tab>
            <clr-tab *ngIf="isCo2scope">
                <button clrTabLink [disabled]="badges.oneview == 0">HPE OneView&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.oneview == 0" class="badge">{{badges.oneview}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="instancedatatable" [ngTemplateOutletContext]="{itype:oneview_type}"></ng-template></clr-tab-content>
            </clr-tab>
            <clr-tab *ngIf="isCo2scope">
                <button clrTabLink [disabled]="badges.ipmi == 0">IPMI&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.ipmi == 0" class="badge">{{badges.ipmi}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="instancedatatable" [ngTemplateOutletContext]="{itype:ipmi_type}"></ng-template></clr-tab-content>
            </clr-tab>
            <clr-tab *ngIf="isCo2scope">
                <button clrTabLink [disabled]="badges.exagrid == 0">Exagrid&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.exagrid == 0" class="badge">{{badges.exagrid}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="instancedatatable" [ngTemplateOutletContext]="{itype:exagrid_type}"></ng-template></clr-tab-content>
            </clr-tab>
            <clr-tab *ngIf="isCo2scope">
                <button clrTabLink [disabled]="badges.ibm == 0">IBM&nbsp;<span style="width:17px;background:#0072a3" [hidden]="badges.ibm == 0" class="badge">{{badges.ibm}}</span></button>
                <clr-tab-content *clrIfActive="false"><ng-template [ngTemplateOutlet]="instancedatatable" [ngTemplateOutletContext]="{itype:ibm_type}"></ng-template></clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="instances_list_modal = false">Close</button>
    </div>
</clr-modal>

<!-- ADD / EDIT WIZARD -->
<clr-wizard #add [(clrWizardOpen)]="register_wizard" (clrWizardOnCancel)=doCancel(false)
    (clrWizardOnFinish)=doFinish(false) [clrWizardPreventDefaultCancel]="true" [clrWizardClosable]="false"
    [clrWizardDisableStepnav]="instance_edit">

    <clr-wizard-title>Registration</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{lang.CANCEL}}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{lang.PREVIOUS}}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{lang.NEXT}}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{lang.FINISH}}</clr-wizard-button>

    <!-- 1 TYPE -->
    <clr-wizard-page>
        <ng-template clrPageTitle>{{lang.INSTALL_TYPE_TITLE}}</ng-template>
        <ng-template [ngTemplateOutlet]="wizardtype"></ng-template>
    </clr-wizard-page>

    <!-- 2 CREDENTIALS -->
    <clr-wizard-page
        [clrWizardPageNextDisabled]="!checkInstance()"
        (clrWizardPageNext)="pushInstance(false)" #editpage>
        <ng-template clrPageTitle>{{lang.INSTALL_CREDENTIALS_TITLE}}</ng-template>
        <ng-template [ngTemplateOutlet]="wizardcredentials"></ng-template>
    </clr-wizard-page>

    <!-- 3 PROGRESS -->
    <clr-wizard-page [clrWizardPageNextDisabled]="instance_status != 0"
        [clrWizardPagePreviousDisabled]="instance_status <= 0" [clrWizardPageHasError]="instance_status != 0">
        <ng-template clrPageTitle>{{lang.INSTALL_PROGRESS_TITLE}}</ng-template>
        <ng-template *ngIf="register_wizard"  [ngTemplateOutlet]="addstatusdiv"></ng-template>
    </clr-wizard-page>

    <!-- 4 END -->
    <clr-wizard-page [clrWizardPagePreviousDisabled]="true" *ngIf="!instance_edit">

        <ng-template clrPageTitle>{{lang.INSTALL_END_TITLE}}</ng-template>

        <div style="height:100%;margin-top:60px;margin-left:20px">
            <p>{{lang.INSTALL_END_TEXT}}</p><br>

            <p>{{lang.INSTALL_END_TEXT2}}</p>

            <div class="alert alert-warning" role="alert"
                style="margin-top:80px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
                <span style="text-align:center;" class="alert-text">
                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                    <b>{{lang.INSTALL_END_TEXT3}}</b>
                </span>
            </div>

        </div>

    </clr-wizard-page>
</clr-wizard>

<!-- WELCOME WIZARD-->
<clr-wizard #welcome [(clrWizardOpen)]="welcome_wizard" (clrWizardOnCancel)=doCancel(true)
    (clrWizardOnFinish)=doFinish(true) [clrWizardPreventDefaultCancel]="true" [clrWizardClosable]="false">

    <clr-wizard-title>{{lang.WELCOME_LANG_TITLE}}</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{lang.CANCEL}}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{lang.PREVIOUS}}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{lang.NEXT}}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{lang.FINISH}}</clr-wizard-button>

    <!-- 1 WELCOME -->
    <clr-wizard-page [clrWizardPagePreviousDisabled]="true">

        <ng-template clrPageTitle>{{lang.WELCOME_LANG_TITLE}}</ng-template>

        <div style="height:100%;margin-top:60px;margin-left:20px">
            <p>{{lang.WELCOME_LANG_TEXT}}</p>
            <br><br>

            <clr-radio-container clrInline>
                <label style="margin-bottom:10px">{{lang.WELCOME_LANG_TEXT2}} : <br><br></label>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="lang" required value="fr" [(ngModel)]="welcome_lang"
                        (click)="updateLang()" />
                    <label><img src="../../assets/img/fr.svg" height="24"></label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="lang" required value="en" [(ngModel)]="welcome_lang"
                        (click)="updateLang()" />
                    <label><img src="../../assets/img/en.svg" height="24"></label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="lang" required value="es" [(ngModel)]="welcome_lang"
                        (click)="updateLang()" />
                    <label><img src="../../assets/img/es.svg" height="24"></label>
                </clr-radio-wrapper>
            </clr-radio-container>

        </div>

    </clr-wizard-page>

    <!-- 2 LICENSE -->
    <clr-wizard-page [clrWizardPageNextDisabled]="welcome_license_status == 'false'">

        <ng-template clrPageTitle>{{lang.WELCOME_LICENSE_TITLE}}</ng-template>

        <p>{{lang.WELCOME_LICENSE_OTHER}}</p>

        <div [innerHTML]="welcome_license_innerhtml"></div>

        <h4>7. {{lang.WELCOME_LICENSE_TEXT}}</h4>
        {{lang.WELCOME_LICENSE_TEXT2}} <br>

        <clr-radio-container clrInline>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="license" required value="true"
                    [(ngModel)]="welcome_license_status" />
                <label>{{lang.WELCOME_LICENSE_TEXT3}}</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="license" required value="false"
                    [(ngModel)]="welcome_license_status" />
                <label>{{lang.WELCOME_LICENSE_TEXT4}}</label>
            </clr-radio-wrapper>
        </clr-radio-container>

    </clr-wizard-page>

    <!-- 3 INSTALL OR MIGRATE -->
   <!--  <clr-wizard-page *ngIf="isDcscope">

        <ng-template clrPageTitle>{{lang.WELCOME_MODE_TITLE}}</ng-template>

        <div style="height:100%;margin-top:100px;margin-left:15%">
            <br><br>

            <div class="clr-control-container clr-control-inline" style="width:100%;">
                <clr-radio-wrapper style="width:25%">
                    <input type="radio" clrRadio name="mode" required value="install" [(ngModel)]="welcome_mode" />
                    <label style="display:grid;margin:auto;">
                        <cds-icon shape="wrench" size="128"></cds-icon>
                        <b style="margin-top:20px;text-align:center;">{{lang.WELCOME_MODE_TEXT}}</b>
                    </label>
                </clr-radio-wrapper>
                <div style="margin-left:20%"></div>
                <clr-radio-wrapper style="width:25%">
                    <input type="radio" clrRadio name="mode" required value="migrate" [(ngModel)]="welcome_mode" />
                    <label style="display:grid;margin:auto;">
                        <cds-icon shape="storage" size="128"></cds-icon>
                        <b style="margin-top:20px;text-align:center;">{{lang.WELCOME_MODE_TEXT2}}</b>
                    </label>
                </clr-radio-wrapper>
            </div>

        </div>

    </clr-wizard-page> -->

    <!-- 4 INSTALL TYPE -->
    <clr-wizard-page *ngIf="welcome_mode == install_mode">
        <ng-template clrPageTitle>{{lang.INSTALL_TYPE_TITLE}}</ng-template>
        <ng-template [ngTemplateOutlet]="wizardtype"></ng-template>
    </clr-wizard-page>

    <!-- 5 INSTALL CREDENTIALS -->
    <clr-wizard-page *ngIf="welcome_mode == install_mode"
        [clrWizardPageNextDisabled]="!checkInstance()"
        (clrWizardPageNext)="pushInstance(true)">
        <ng-template clrPageTitle>{{lang.INSTALL_CREDENTIALS_TITLE}}</ng-template>
        <ng-template [ngTemplateOutlet]="wizardcredentials"></ng-template>
    </clr-wizard-page>

    <!-- 6 INSTALL PROGRESS -->
    <clr-wizard-page *ngIf="welcome_mode == install_mode" [clrWizardPageNextDisabled]=" instance_status !=0"
        [clrWizardPagePreviousDisabled]="instance_status <= 0" [clrWizardPageHasError]="instance_status != 0">
        <ng-template clrPageTitle>{{lang.INSTALL_PROGRESS_TITLE}}</ng-template>
        <ng-template *ngIf="welcome_wizard" [ngTemplateOutlet]="addstatusdiv"></ng-template>
    </clr-wizard-page>

    <!-- 4 MIGRATE CREDENTIALS -->
<!--     <clr-wizard-page *ngIf="welcome_mode == migrate_mode"
        [clrWizardPageNextDisabled]="migrate_connect == false"
        [clrWizardPagePreviousDisabled]="migrate_status != undefined" (clrWizardPageNext)="pushMigrate()">

        <ng-template clrPageTitle>{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT}}</ng-template>

        <div class="alert alert-info" role="alert"
            style="text-align:justify;font-size:13px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
            <ul style="list-style:none;">
                <li style="margin-bottom:10px">{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT8}}</li>
                <li style="margin-bottom:20px">{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT9}}</li>
                <li><b>{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT10}}</b></li>
            </ul>
        </div>

        <div  style="width:100%">
            <div style="width:75%">
                <div class="clr-form-control" style="flex-direction:row;margin-top:50px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT3}} :</label>
                    <div [ngClass]="(migrate_ip == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [disabled]="migrate_connect" [(ngModel)]="migrate_ip" style="width:20em"
                                (keyup)="removeSpaces($event.target);migrate_connect= false;">
                            <cds-icon class="clr-validate-icon" *ngIf="migrate_ip == ''" shape="exclamation-circle">
                            </cds-icon>
                        </div>
                    </div>
                </div>

                <div *ngIf="migrate_pwd_show"  class="clr-form-control" style="flex-direction:row;margin-top:50px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT6}} ('root' OS) :</label>
                    <div [ngClass]="(migrate_pwd == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <clr-password-container style="margin-top:0px">
                            <input clrPassword [disabled]="migrate_connect" [(ngModel)]="migrate_pwd" style="width:20em"
                                (keyup)="migrate_connect= false">
                        </clr-password-container>
                    </div>
                </div>
            </div>
            <div  style="width:25%;float:right">
                <button *ngIf="(migrate_pwd != '' || migrate_ip != '')" [disabled]="migrate_connect" [clrLoading]="migrate_connect_button_state" type="button"
                    [ngClass]="(!migrate_connect) ? 'btn btn-danger-outline btn-sm' : 'btn btn-success-outline btn-sm'" (click)="testMigrate()">
                    <span style="min-width:178px" *ngIf="!migrate_connect">{{migrate_connect_button_text}}</span>
                    <cds-icon style="min-width:152px" *ngIf="migrate_connect" shape="check"></cds-icon>
                </button>
            </div>
        </div>

        <div  style="width:80%">
            <clr-select-container style="flex-direction:row;margin-top:50px">
                <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT3}} :</label>
                <select clrSelect [(ngModel)]="migrate_retention">
                    <option value="1">1 {{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT4}}</option>
                    <option value="2">2 {{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT4}}s</option>
                    <option value="0">{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT5}}</option>
                </select>
                <clr-control-helper>{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT6}}</clr-control-helper>
            </clr-select-container>
        </div>

        <div *ngIf="migrate_retention > 0" class="alert alert-warning" role="alert"
            style="margin-top:40px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
            <span style="text-align:center;" class="alert-text">
                <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                <b>{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT7}}</b>
            </span>
        </div>

    </clr-wizard-page> -->

    <!-- 5 MIGRATE PROGRESS -->
<!--     <clr-wizard-page #migrationpage *ngIf="welcome_mode == migrate_mode"
        [clrWizardPageNextDisabled]="migrate_status != 0" [clrWizardPagePreviousDisabled]="migrate_status <= 0"
        [clrWizardPageHasError]="migrate_status != 0">

        <ng-template clrPageTitle>{{lang.WELCOME_PROGRESS_MIGRATE_TEXT}}</ng-template>

        <div #welcomestatus style="height:100%;text-align:center;margin-top:100px;">
            <div [ngClass]="migrate_progress_class">
                <div class="progress-meter" [attr.data-value]="migrate_progress_value"
                    [attr.data-displayval]="migrate_progress_value + '%'"></div>
                <span>{{migrate_progress_value}}%</span>
            </div>
            <div style="margin-top:10%;">
                <b><span>{{migrate_progress_message}}</span></b>
            </div>
        </div>

    </clr-wizard-page> -->

    <!-- 6 / 7 END -->
    <clr-wizard-page [clrWizardPagePreviousDisabled]="true">

        <ng-template clrPageTitle>{{lang.WELCOME_END_TITLE}}</ng-template>

        <div style="height:100%;margin-top:40px;margin-left:20px">
            {{lang.WELCOME_END_TEXT}}<br><br>

            {{lang.WELCOME_END_TEXT2}}

            <div class="alert alert-info" role="alert"
                style="margin-top:30px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
                <span style="text-align:center;" class="alert-text">
                    <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                    <b>{{lang.WELCOME_END_TEXT3}}</b>
                </span>
            </div>

            <br><br>{{lang.WELCOME_END_TEXT4}}<a href="mailto:licence@easyvirt.com">licence@easyvirt.com</a>

            <div class="alert alert-warning" role="alert"
                style="margin-top:80px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
                <span style="text-align:center;" class="alert-text">
                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                    <b>{{lang.WELCOME_END_TEXT5}}</b>
                </span>
            </div>
        </div>

    </clr-wizard-page>

</clr-wizard>

<!-- MODAL INFO -->
<clr-modal [(clrModalOpen)]="info_modal" [clrModalClosable]=false [clrModalSize]="info_modal_size">
    <h3 class="modal-title">{{info_header}}</h3>
    <div class="modal-body" style="min-height:100px;">
        <div #info style="width:100%;overflow:auto;"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="info_modal = false">Ok</button>
    </div>
</clr-modal>

<!-- MODAL UNREGISTER CONFIRM -->
<clr-modal [(clrModalOpen)]="unregister_modal" [clrModalClosable]=false>
    <h3 class="modal-title">Unregistration</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>The instance <b>{{unregister_instance}}</b> will be permanently unregistered !</p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="unregister_modal = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="unregisterInstance()">Unregister</button>
    </div>
</clr-modal>

<!-- ADD INSTANCE MODEL -->
<ng-template #newinstancemodel let-itype='itype' let-iicon="iicon">
     <clr-radio-wrapper>
        <input type="radio" clrRadio name="type" required [value]="itype" [(ngModel)]="instance_type" [disabled]="itype=='scaphandre' && this.scaphandre_check"/>
            <label style="display:grid;margin:auto;width:200px">
                <fa-icon *ngIf="itype != kube_type"  [icon]="iicon" style="margin:auto;font-size:4em"></fa-icon>
                <cds-icon *ngIf="itype == kube_type" shape="nodes" style="margin:auto;width:55px;height:55px;"></cds-icon>
                <b style="margin-top:20px;margin-left:auto;margin-right:auto;">{{getInstanceFullname(itype)}}</b>
            </label>
        </clr-radio-wrapper>
</ng-template>

<!-- INSTANCE STATUS DIV -->
<ng-template #addstatusdiv>
    <div #addstatus style="height:100%;text-align:center;margin-top:40px;"></div>
</ng-template>

<!-- VMWARE LIST -->
<ng-template #vmwaredatatable>
    <div style="width:100%;margin-top:40px">
        <clr-datagrid>
            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
            <clr-dg-column>IP</clr-dg-column>
            <clr-dg-column>Login</clr-dg-column>
            <clr-dg-column style="max-width:5%">Status</clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>

            <clr-dg-row *clrDgItems="let data of findVmwareInstances()" [clrDgItem]="data">
                <clr-dg-cell>{{data.name}}</clr-dg-cell>
                <clr-dg-cell>
                    <fa-icon [icon]="vmware_icon"></fa-icon>
                    &nbsp;&nbsp;{{getInstanceFullname(data.type)}}
                </clr-dg-cell>
                <clr-dg-cell>{{data.ip}}</clr-dg-cell>
                <clr-dg-cell>{{data.login}}</clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <cds-icon *ngIf="data.status == 0" shape="check" style="color:#306b00" size='md'></cds-icon>
                    <cds-icon *ngIf="data.status != 0" shape="times" style="color:#991700" size='md'></cds-icon>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-info-outline' aria-label='edit' (click)="initEdit(data)">
                        <cds-icon shape='pencil'></cds-icon>
                    </button>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove' (click)="initUnregister(data.name)">
                        <cds-icon shape='trash'></cds-icon>
                    </button>
                </clr-dg-cell>

                <ng-container ngProjectAs="clr-dg-row-detail" *ngIf="data.plugins.length > 0">
                    <ng-template *clrIfExpanded [ngTemplateOutlet]="pluginstable" [ngTemplateOutletContext]="{idata:data}" ngProjectAs="clr-dg-row-detail"></ng-template>
                </ng-container>

            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>

        </clr-datagrid>
    </div>
</ng-template>

<!-- CLOUD PROVIDER LIST -->
<ng-template #clouddatatable>
    <div style="width:100%;margin-top:40px">
        <clr-datagrid>
            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
            <clr-dg-column style="max-width:5%">Status</clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>

            <clr-dg-row *clrDgItems="let data of findCloudInstances()" [clrDgItem]="data">
                <clr-dg-cell>{{data.name}}</clr-dg-cell>
                <clr-dg-cell>
                    <fa-icon *ngIf="data.type == aws_type" [icon]="aws_icon"></fa-icon>
                    <fa-icon *ngIf="data.type == azure_type" [icon]="azure_icon"></fa-icon>
                    <fa-icon *ngIf="data.type == gcp_type" [icon]="gcp_icon"></fa-icon>
                    &nbsp;&nbsp;{{getInstanceFullname(data.type)}}
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <cds-icon *ngIf="data.status == 0" shape="check" style="color:#306b00" size='md'></cds-icon>
                    <cds-icon *ngIf="data.status != 0" shape="times" style="color:#991700" size='md'></cds-icon>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-info-outline' aria-label='edit' (click)="initEdit(data)">
                        <cds-icon shape='pencil'></cds-icon>
                    </button>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove' (click)="initUnregister(data.name)">
                        <cds-icon shape='trash'></cds-icon>
                    </button>
                 </clr-dg-cell>
            </clr-dg-row>

             <clr-dg-footer>
                 <clr-dg-pagination #pagination2 [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{pagination2.firstItem + 1}} - {{pagination2.lastItem + 1}} of {{pagination2.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>

        </clr-datagrid>
    </div>
</ng-template>

<!-- SCAPHANDRE LIST -->
<ng-template #scaphandredatatable>
    <div style="width:100%;margin-top:40px">
        <clr-datagrid>
            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
            <clr-dg-column>URL</clr-dg-column>
            <clr-dg-column>Login</clr-dg-column>
            <clr-dg-column style="max-width:5%">Status</clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>

            <clr-dg-row *clrDgItems="let data of findInstances(scaph_type)" [clrDgItem]="data">
                <clr-dg-cell>{{data.name}}</clr-dg-cell>
                <clr-dg-cell>
                    <fa-icon [icon]="scaph_icon"></fa-icon>
                     &nbsp;&nbsp;{{getInstanceFullname(data.type)}}
                </clr-dg-cell>
                <clr-dg-cell>{{data.ip}}</clr-dg-cell>
                <clr-dg-cell>{{data.login}}</clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <cds-icon *ngIf="data.status == 0" shape="check" style="color:#306b00" size='md'></cds-icon>
                    <cds-icon *ngIf="data.status != 0" shape="times" style="color:#991700" size='md'></cds-icon>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-info-outline' aria-label='edit' (click)="initEdit(data)">
                        <cds-icon shape='pencil'></cds-icon>
                    </button>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove' (click)="initUnregister(data.name)">
                        <cds-icon shape='trash'></cds-icon>
                    </button>
                </clr-dg-cell>
             </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination3 [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{pagination3.firstItem + 1}} - {{pagination3.lastItem + 1}} of {{pagination3.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>

        </clr-datagrid>
    </div>
</ng-template>

<!-- LENOVO XCLARITY / DELL OPENMANAGE / HP ONEVIEW / IPMI / EXAGRID / IBM LIST -->
<ng-template #instancedatatable let-itype='itype'>
    <div style="width:100%;margin-top:40px">
        <clr-datagrid>
            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
            <clr-dg-column>URL</clr-dg-column>
            <clr-dg-column>Login</clr-dg-column>
            <clr-dg-column style="max-width:5%">Status</clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>

            <clr-dg-row *clrDgItems="let data of findInstances(itype)" [clrDgItem]="data">
                <clr-dg-cell>{{data.name}}</clr-dg-cell>
                <clr-dg-cell>
                    <fa-icon [icon]="xclarity_icon"></fa-icon>
                     &nbsp;&nbsp;{{getInstanceFullname(data.type)}}
                </clr-dg-cell>
                <clr-dg-cell>{{data.ip}}</clr-dg-cell>
                <clr-dg-cell>{{data.login}}</clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <cds-icon *ngIf="data.status == 0" shape="check" style="color:#306b00" size='md'></cds-icon>
                    <cds-icon *ngIf="data.status != 0" shape="times" style="color:#991700" size='md'></cds-icon>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-info-outline' aria-label='edit' (click)="initEdit(data)">
                        <cds-icon shape='pencil'></cds-icon>
                    </button>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove' (click)="initUnregister(data.name)">
                        <cds-icon shape='trash'></cds-icon>
                    </button>
                </clr-dg-cell>
             </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination4 [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{pagination4.firstItem + 1}} - {{pagination4.lastItem + 1}} of {{pagination4.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>

        </clr-datagrid>
    </div>
</ng-template>

<!-- KUBE LIST -->
<ng-template #kubedatatable>
    <div style="width:100%;margin-top:40px">
        <clr-datagrid>
            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'arch'"><ng-container *clrDgHideableColumn="{hidden: false}">Architecture</ng-container></clr-dg-column>
            <clr-dg-column>URL</clr-dg-column>
            <clr-dg-column style="max-width:5%">Status</clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>

            <clr-dg-row *clrDgItems="let data of findInstances(kube_type)" [clrDgItem]="data">
                <clr-dg-cell>{{data.name}}</clr-dg-cell>
                <clr-dg-cell>
                    <cds-icon shape="nodes"></cds-icon>
                     &nbsp;&nbsp;{{getInstanceFullname(data.type)}}
                </clr-dg-cell>
                <clr-dg-cell>{{data.arch}}</clr-dg-cell>
                <clr-dg-cell>{{data.url}}</clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <cds-icon *ngIf="data.status == 0" shape="check" style="color:#306b00" size='md'></cds-icon>
                    <cds-icon *ngIf="data.status != 0" shape="times" style="color:#991700" size='md'></cds-icon>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-info-outline' aria-label='edit' (click)="initEdit(data)">
                        <cds-icon shape='pencil'></cds-icon>
                    </button>
                </clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove' (click)="initUnregister(data.name)">
                        <cds-icon shape='trash'></cds-icon>
                    </button>
                </clr-dg-cell>

                <ng-container ngProjectAs="clr-dg-row-detail" *ngIf="data.plugins.length > 0">
                    <ng-template *clrIfExpanded [ngTemplateOutlet]="pluginstable" [ngTemplateOutletContext]="{idata:data}" ngProjectAs="clr-dg-row-detail"></ng-template>
                </ng-container>

             </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination2 [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{pagination2.firstItem + 1}} - {{pagination2.lastItem + 1}} of {{pagination2.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>

        </clr-datagrid>
    </div>
</ng-template>

<!-- PLUGINS LIST -->
<ng-template #pluginstable let-idata='idata'>
    <div style="margin-bottom:10px;">
        <hr>
        <b style="padding-left:10px;">Plugins :</b>
        <clr-datagrid style="width:90%;margin:auto">
            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Server name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
            <clr-dg-column>IP</clr-dg-column>
            <clr-dg-column>Login</clr-dg-column>
            <clr-dg-column style="max-width:5%"></clr-dg-column>

            <clr-dg-row *clrDgItems="let data of idata.plugins" [clrDgItem]="data">
                <clr-dg-cell>{{data.name}}</clr-dg-cell>
                <clr-dg-cell>
                    <cds-icon shape='bolt'></cds-icon>
                    &nbsp;&nbsp;{{data.type}}
                </clr-dg-cell>
                <clr-dg-cell>{{data.ip == '' ? 'n/a' : data.ip}}</clr-dg-cell>
                <clr-dg-cell>{{data.login == '' ? 'n/a' : data.login}}</clr-dg-cell>
                <clr-dg-cell style="max-width:5%">
                    <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove'>
                        <cds-icon shape='trash'></cds-icon>
                    </button>
                </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagin [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{pagin.firstItem + 1}} - {{pagin.lastItem + 1}} of {{pagin.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>

        </clr-datagrid>
    </div>
</ng-template>

<!-- WIZARD INSTANCE TYPE -->
<ng-template #wizardtype>
    <div style="margin-top:20px;margin-left:auto;margin-right:auto;">

        <!-- VIRTUALIZATION PLATEFORM -->
        <clr-accordion>
            <clr-accordion-panel [clrAccordionPanelOpen]="true">
                <clr-accordion-title>Virtualization plateforms (3)</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <div class="clr-control-container clr-control-inline" style="width:100%;margin:20px;">
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:vmware_type, iicon:vmware_icon}"></ng-container>
                        <div style="margin-left:10%"></div>
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:vcloud_type, iicon:vmware_icon}"></ng-container>
                        <div style="margin-left:10%"></div>
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:kube_type, iicon:kube_icon}"></ng-container>
                    </div>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>

        <!-- CLOUD -->
        <clr-accordion>
            <clr-accordion-panel [clrAccordionPanelOpen]="true">
                <clr-accordion-title>Cloud providers (3)</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <div class="clr-control-container clr-control-inline" style="width:100%;margin:20px;">
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:aws_type, iicon:aws_icon}"></ng-container>
                        <div style="margin-left:10%"></div>
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:azure_type, iicon:azure_icon}"></ng-container>
                        <div style="margin-left:10%"></div>
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:gcp_type, iicon:gcp_icon}"></ng-container>
                    </div>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>

        <!-- SERVER PLATEFORM -->
        <clr-accordion *ngIf="isCo2scope">
            <clr-accordion-panel>
                <clr-accordion-title>Servers plateforms (3)</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <div class="clr-control-container clr-control-inline" style="width:100%;margin:20px;">
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:xclarity_type, iicon:xclarity_icon}"></ng-container>
                        <div style="margin-left:10%"></div>
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:openmanage_type, iicon:openmanage_icon}"></ng-container>
                        <div style="margin-left:10%"></div>
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:oneview_type, iicon:oneview_icon}"></ng-container>                        
                    </div>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>

        <!-- STORAGE PLATEFORM -->
        <clr-accordion *ngIf="isCo2scope">
            <clr-accordion-panel>
                <clr-accordion-title>Storage plateforms (2)</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <div class="clr-control-container clr-control-inline" style="width:100%;margin:20px;">
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:exagrid_type, iicon:exagrid_icon}"></ng-container>
                        <div style="margin-left:10%"></div>
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:ibm_type, iicon:ibm_icon}"></ng-container>
                    </div>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>

        <!-- OTHERS -->
        <clr-accordion *ngIf="isCo2scope">
            <clr-accordion-panel>
                <clr-accordion-title>Others (2)</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <div class="clr-control-container clr-control-inline" style="width:100%;margin:20px;">
                        <ng-container [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:ipmi_type, iicon:ipmi_icon}"></ng-container>
                        <div style="margin-left:10%"></div>
                        <ng-container *ngIf="isCo2scope" [ngTemplateOutlet]="newinstancemodel"  [ngTemplateOutletContext]="{itype:scaph_type, iicon:scaph_icon}"></ng-container>
                    </div>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>

    </div>
</ng-template>

<!-- WIZARD INSTANCE CREDENTIALS -->
<ng-template #wizardcredentials>
    <ng-template [ngTemplateOutlet]="instancenametemplate"></ng-template>

    <ng-template *ngIf="instance_type == vmware_type || instance_type == vcloud_type" [ngTemplateOutlet]="vmwaretemplate"></ng-template>
    <ng-template *ngIf="instance_type == aws_type" [ngTemplateOutlet]="awstemplate"></ng-template>
    <ng-template *ngIf="instance_type == gcp_type" [ngTemplateOutlet]="gcptemplate"></ng-template>
    <ng-template *ngIf="instance_type == azure_type" [ngTemplateOutlet]="azuretemplate"></ng-template>
    <ng-template *ngIf="instance_type == scaph_type" [ngTemplateOutlet]="scaphandretemplate"></ng-template>
    <ng-template *ngIf="instance_type == kube_type" [ngTemplateOutlet]="kubernetestemplate"></ng-template>
    <ng-template *ngIf="instance_type == xclarity_type" [ngTemplateOutlet]="instancetemplate" [ngTemplateOutletContext]="{tmpinstance:instance_xclarity}"></ng-template>
    <ng-template *ngIf="instance_type == openmanage_type" [ngTemplateOutlet]="instancetemplate" [ngTemplateOutletContext]="{tmpinstance:instance_openmanage}"></ng-template>
    <ng-template *ngIf="instance_type == oneview_type" [ngTemplateOutlet]="instancetemplate" [ngTemplateOutletContext]="{tmpinstance:instance_oneview}"></ng-template>
    <ng-template *ngIf="instance_type == ipmi_type" [ngTemplateOutlet]="instancetemplate" [ngTemplateOutletContext]="{tmpinstance:instance_ipmi}"></ng-template>
    <ng-template *ngIf="instance_type == exagrid_type" [ngTemplateOutlet]="instancetemplate" [ngTemplateOutletContext]="{tmpinstance:instance_exagrid}"></ng-template>
    <ng-template *ngIf="instance_type == ibm_type" [ngTemplateOutlet]="instancetemplate" [ngTemplateOutletContext]="{tmpinstance:instance_ibm}"></ng-template>
</ng-template>

<!-- WIZARD INSTANCE NAME INPUT -->
<ng-template #instancenametemplate>
    <div class="clr-form-control" style="flex-direction:row;margin-top:25px">
        <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT}} :</label>
        <div [ngClass]="(instance_name == '' || instance_name_forbidden) ? 'clr-control-container clr-error' : 'clr-control-container'">
            <div class="clr-input-wrapper">
                <input class="clr-input" type="text" [(ngModel)]="instance_name" style="width:20em"
                    (keyup)="checkName($event.target);" (change)="checkName($event.target);" [disabled]="instance_name_disable">
                <cds-icon class="clr-validate-icon" *ngIf="(instance_name == '' || instance_name_forbidden )" shape="exclamation-circle">
                </cds-icon>
            </div>
            <clr-control-helper>{{lang.INSTALL_CREDENTIALS_TEXT2}}</clr-control-helper>
            <!--<clr-control-helper *ngIf="instance_type == azure_type">{{lang.INSTALL_CREDENTIALS_TEXT18}}</clr-control-helper>-->
            <clr-control-helper *ngIf="instance_name_forbidden">Name already used !</clr-control-helper>
        </div>
    </div>
</ng-template>

<!-- WIZARD INSTANCE PROXY -->
<ng-template #proxytemplate let-tmpinstance='tmpinstance'>
    <div class="card">
        <div class="card-block">
            <div class="card-text">
                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">Proxy :</label>
                    <clr-select-container style="flex-direction:row;margin:0">
                        <select clrSelect [(ngModel)]="tmpinstance.isproxy" >
                            <option value=false>false</option>
                            <option value=true>true</option>
                        </select>
                    </clr-select-container>
                </div>
                <div *ngIf="tmpinstance.isproxy == 'true'" class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">Proxy host :</label>
                    <div [ngClass]="(tmpinstance.proxyhost == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="tmpinstance.proxyhost" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="tmpinstance.proxyhost == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="tmpinstance.isproxy == 'true'" class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">Proxy port :</label>
                    <div [ngClass]="(tmpinstance.proxyport == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="tmpinstance.proxyport" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="tmpinstance.proxyport == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- VMWARE / VCLOUD -->
<ng-template #vmwaretemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">

                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT3}} :</label>
                    <div [ngClass]="(instance_vmware_vcloud.ip == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_vmware_vcloud.ip" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_vmware_vcloud.ip == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:50px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT4}} :</label>
                    <div [ngClass]="(instance_vmware_vcloud.login == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_vmware_vcloud.login" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_vmware_vcloud.login == ''" shape="exclamation-circle">
                            </cds-icon>
                            <clr-control-helper> {{lang.INSTALL_CREDENTIALS_TEXT5}} </clr-control-helper>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT6}} :</label>
                    <div [ngClass]="(instance_vmware_vcloud.pwd == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <clr-password-container style="margin-top:0px">
                            <input clrPassword name="password" style="width:18em" [(ngModel)]="instance_vmware_vcloud.pwd" />
                        </clr-password-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<!-- AWS -->
<ng-template #awstemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">
                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT8}} :</label>
                    <div [ngClass]="(instance_aws.accesskey == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.accesskey" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_aws.accesskey == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT9}} :</label>
                    <div [ngClass]="(instance_aws.secretaccesskey == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.secretaccesskey" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_aws.secretaccesskey == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-block">
            <div class="card-text">
                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT10}} :</label>
                    <clr-select-container style="flex-direction:row;margin:0">
                        <select clrSelect [(ngModel)]="instance_aws.isorg" >
                            <option value=false>false</option>
                            <option value=true>true</option>
                        </select>
                    </clr-select-container>
                </div>
                <div *ngIf="instance_aws.isorg == 'true'" class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT11}} :</label>
                    <div [ngClass]="(instance_aws.orgname == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.orgname" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_aws.orgname == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="instance_aws.isorg == 'true'" class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT12}} :</label>
                    <div [ngClass]="(instance_aws.orgaccesskey == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.orgaccesskey" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_aws.orgaccesskey == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="instance_aws.isorg == 'true'" class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT13}} :</label>
                    <div [ngClass]="(instance_aws.orgsecretaccesskey == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.orgsecretaccesskey" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_aws.orgsecretaccesskey == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="proxytemplate" [ngTemplateOutletContext]="{tmpinstance:instance_aws}"></ng-template>
</ng-template>

<!-- AZURE -->
<ng-template #azuretemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">

                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT14}}</label>
                    <div [ngClass]="(instance_azure.tenantid == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_azure.tenantid" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_azure.tenantid == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT15}}</label>
                    <div [ngClass]="(instance_azure.clientid == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_azure.clientid" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_azure.clientid == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT16}}</label>
                    <div [ngClass]="(instance_azure.clientsecret == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_azure.clientsecret" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_azure.clientsecret == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="proxytemplate" [ngTemplateOutletContext]="{tmpinstance:instance_azure}"></ng-template>
</ng-template>

<!-- GCP -->
<ng-template #gcptemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">
                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">Project id :</label>
                    <div [ngClass]="(instance_gcp.projectid == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_gcp.projectid" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_gcp.projectid == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
                <div class="clr-form-control" style="flex-direction:row;margin-top:50px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">Configuration file :</label>
                    <div [ngClass]="(instance_gcp.file == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [disabled]="true" [(ngModel)]="instance_gcp.file" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_gcp.file == ''" shape="exclamation-circle"></cds-icon>
                            <button style="color:black;margin-left:20px" class="btn btn-sm btn-info-outline" (click)="fileinput.click()">
                                <cds-icon shape="folder-open"></cds-icon>
                                <span>&nbsp;&nbsp;Select file ...&nbsp;&nbsp;</span>
                                <input #fileinput type="file" (change)="pushGcpConfigFile($event)" style="display:none;" />
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="proxytemplate" [ngTemplateOutletContext]="{tmpinstance:instance_gcp}"></ng-template>
</ng-template>

<!-- SCAPHANDRE -->
<ng-template #scaphandretemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">

                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT3}} :</label>
                    <div [ngClass]="(instance_scaphandre.ip == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" placeholder="https://localhost:9090" [(ngModel)]="instance_scaphandre.ip" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_scaphandre.ip == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:50px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT4}} :</label>
                    <div [ngClass]="(instance_scaphandre.login == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_scaphandre.login" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_scaphandre.login == ''" shape="exclamation-circle">
                            </cds-icon>
                            <clr-control-helper> {{lang.INSTALL_CREDENTIALS_TEXT5}} </clr-control-helper>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT6}} :</label>
                    <div [ngClass]="(instance_scaphandre.pwd == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <clr-password-container style="margin-top:0px">
                            <input clrPassword name="password" style="width:18em" [(ngModel)]="instance_scaphandre.pwd" />
                        </clr-password-container>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-template>

<!-- LENOVO XCLARITY / DELL OPENMANAGE / HP ONEVIEW / IPMI / EXAGRID / IBM -->
<ng-template #instancetemplate let-tmpinstance='tmpinstance'>
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">

                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT3}} :</label>
                    <div [ngClass]="(tmpinstance.ip == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" placeholder="https://localhost" [(ngModel)]="tmpinstance.ip" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <cds-icon class="clr-validate-icon" *ngIf="tmpinstance.ip == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:50px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT4}} :</label>
                    <div [ngClass]="(tmpinstance.login == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="tmpinstance.login" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <cds-icon class="clr-validate-icon" *ngIf="tmpinstance.login == ''" shape="exclamation-circle">
                            </cds-icon>
                            <clr-control-helper> {{lang.INSTALL_CREDENTIALS_TEXT5}} </clr-control-helper>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT6}} :</label>
                    <div [ngClass]="(tmpinstance.pwd == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <clr-password-container style="margin-top:0px">
                            <input clrPassword name="password" style="width:18em" [(ngModel)]="tmpinstance.pwd" />
                        </clr-password-container>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-template>

<!-- KUBERNETES -->
<ng-template #kubernetestemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">

                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT3}} :</label>
                    <div [ngClass]="(instance_kubernetes.ip == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" placeholder="https://localhost:6443" [(ngModel)]="instance_kubernetes.ip" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_kubernetes.ip == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>

                <!-- <div class="clr-form-control" style="flex-direction:row;margin-top:50px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">Authentication file :</label>
                    <div [ngClass]="(instance_kubernetes.file == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [disabled]="true" [(ngModel)]="instance_kubernetes.file" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_kubernetes.file == ''" shape="exclamation-circle"></cds-icon>
                            <button style="color:black;margin-left:20px" class="btn btn-sm btn-info-outline" (click)="fileinput.click()">
                                <cds-icon shape="folder-open"></cds-icon>
                                <span>&nbsp;&nbsp;Select file ...&nbsp;&nbsp;</span>
                                <input #fileinput type="file" (change)="pushKubernetesConfigFile($event)" style="display:none;" />
                            </button>
                        </div>

                    </div>
                </div> -->

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">Architecture :</label>
                    <clr-select-container style="flex-direction:row;margin:0">
                        <select clrSelect [(ngModel)]="instance_kubernetes.arch" >
                            <option *ngFor="let arch of kubernetes_arch" [ngValue]="arch.value">{{arch.display}}</option>
                        </select>
                    </clr-select-container>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">Token :</label>
                    <div [ngClass]="(instance_kubernetes.token == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_kubernetes.token" style="width:20em">
                            <cds-icon class="clr-validate-icon" *ngIf="instance_kubernetes.token == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</ng-template>

<!-- MODAL PROGRESS -->
<clr-modal [(clrModalOpen)]="show_progress" [clrModalClosable]=false [clrModalSize]="'l'">
    <h3 class="modal-title">Please wait</h3>
    <div class="modal-body" style="min-height:100px;">
        <span class="spinner" style="position:absolute;top:50%;right:45%;"></span>
    </div>
    <div class="modal-footer">
    </div>
</clr-modal>
