

export class NetscopeUtils {

    static formatBytes(value, units = ["bytes", "KiB", "MiB", "GiB", "TiB"]) {
        if (value < 1024 || units.length === 1) {
            // Check if the number has decimals (https://stackoverflow.com/questions/2304052/check-if-a-number-has-a-decimal-place-is-a-whole-number)
            if (value % 1 === 0) {
                return `${value} ${units[0]}`;
            } else {
                return `${value.toFixed(2)} ${units[0]}`;
            }
        }

        return this.formatBytes(value / 1024, units.slice(1));
    }
}