<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="calendar"></cds-icon>
			&nbsp;Management of time views
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<button class="btn btn-primary" style="float:left;" (click)="wizardpv.open();initWizard()">
						<cds-icon shape="plus-circle" class="is-solid"></cds-icon>
						CREATE VIEW
					</button>
				</div>
				<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">select a generated time view</h5>
							<div class="card-text">
								<div class="clr-control-container">
									<div class="clr-select-wrapper">
										<select id="period-view" class="clr-select" [(ngModel)]="message.elementPeriodSelected" (change)="switchView()">
											<option *ngFor="let view of usr_views" [ngValue]="view.filter" >{{view.filter}}</option>
										</select>
									</div>
									<span class="clr-subtext">the list of time views</span>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="isDeletable()" *ngIf="message.elementPeriodSelected != ''">
								Remove
							</button>
            			</div>
					</div>
				</div>
				<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">time view configuration</h5>
							<div class="card-text">
								<ng-template load-period-calendar></ng-template>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateChange()" *ngIf="current_start != message.elementPeriodStart || current_end != message.elementPeriodEnd">
								Update
							</button>
            			</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<clr-wizard id="csstoadjust" #wizardPeriodView 
	[clrWizardSize]="'xl'"
	(clrWizardOnPrevious)="goBack()"
	(clrWizardOnCancel)=doReset() 
	(clrWizardOnFinish)="doFinish()">
    <clr-wizard-title>Create a time view</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="model.name == '' || model.name == 'all' || model.name == 'ALL' || filterList(model.name)">
        <ng-template clrPageTitle>The name of the view</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-form-control">
					<div class="clr-input-container" [ngClass]="{'clr-error' :  model.error}">
						<input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="model.name" name="name" (ngModelChange)="checkName($event)" required />
						<cds-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="model.error"></cds-icon>
						<clr-control-error *ngIf="model.error">This field cannot set {{model.errorName}}</clr-control-error>
					</div>
					<span class="clr-subtext">the name of new element view</span>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="current_start == '' || current_end == ''" (clrWizardPageOnLoad)="callWizardCalendar()">
        <ng-template clrPageTitle>Select date range</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<ng-template load-wizard-period-calendar></ng-template>
			</div>
			
		</div>
	</div>
    </clr-wizard-page>
</clr-wizard>
<clr-modal [(clrModalOpen)]="isDeleteView" [clrModalClosable]=false>
    <h3 class="modal-title">Delete view</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>This view will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteView = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="removeView();isDeleteView = false">Delete</button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="isDeleteError" [clrModalClosable]=false>
    <h3 class="modal-title">Cannot delete view</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
	    <p><b>the following filters are attached to this view</b></p>
	    <table class="table table-compact">
	    	<thead>
			<tr>
				<th>filter</th>
			</tr>
	    	</thead>
	    	<tbody>
    			<tr *ngFor="let filter of filter_view">
				<td>{{filter.name}}</td>
			</tr>
	    	</tbody>
	    </table>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteError = false">OK</button>
    </div>
</clr-modal>
