<div style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-flow: column;">
    <clr-alert [clrAlertType]="'danger'" [clrAlertAppLevel]="true" *ngIf="failureMode && !isLoading">
        <clr-alert-item>
            <span class="alert-text">Cannot load data from DC Netscope API. Please check the diagnostic page.</span>
            <div class="alert-actions">
                <a routerLink="/netscope/diagnostic" routerLinkActive="active">
                    <button class="btn alert-action">
                        <cds-icon shape="checkbox-list"></cds-icon>
                        Diagnostic
                    </button>
                </a>
            </div>
        </clr-alert-item>
    </clr-alert>

    <div class="clr-row" style="margin: 0px; background-color: rgb(0, 55, 76); color : white; overflow: visible; min-height: 74px;">
        <div class="clr-col" id="flow_view_title" style="flex-grow: 0; color: white; text-align: center;">
            <table style="height: 100%; min-width: 100px; font-weight: 400; font-size: .8rem; letter-spacing: .01em; text-decoration: none;">
                <tr>
                    <td>
                        <cds-icon shape="dashboard" style="margin-right: 10px; margin-bottom: 3px;" size="md"></cds-icon>
                    </td>
                    <td style="text-align: left;">{{ "dcnetscope.dashboard.name" | transloco }}</td>
                </tr>
            </table>
        </div>

        <div class="clr-col" id="flow_view_datepicker_button" style="flex-grow: 0; padding-left: 12px; padding-right: 12px; background-color: rgb(38, 85, 103); min-width: 144px;">
            <a style="pointer-events: none">
                <div class="clr-row" style="height: 100%; padding: 5px; pointer-events: none;">
                    <table style="height: 100%; color: rgb(250, 250, 250); ; text-align: center; font-family: 'Metropolis', 'Avenir Next', 'Helvetica Neue', 'Arial, sans-serif';
                    font-size: 18px; font-weight: 500;" *ngIf="currentDayTimestamp != undefined">
                        <tr>
                            <td>
                                <cds-icon shape="calendar" class="is-solid" style="margin: auto; color: white;">
                                </cds-icon>
                            </td>
                            <td style="padding: 5px;">
                                <ng-container *ngIf="true">
                                    <span>{{ currentDayTimestamp * 1000 | date: 'dd/MM/yyyy'}}</span>
                                </ng-container>
                                <ng-container>

                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="min-width: 100px;">
                                <ng-container>
                                    <a href="javascript://" (click)="setPreviousTimestamp()" style="margin-right: 5px;">
                                        <span class="badge" style="position: relative; bottom: 5px; height: 20px;">
                                            <cds-icon shape="step-forward" solid="true" flip="horizontal"></cds-icon>
                                        </span>
                                    </a>
                                    <a href="javascript://" (click)="setNextTimestamp()" style="margin-left: 5px;">
                                        <span class="badge" style="position: relative; bottom: 5px; height: 20px;">
                                            <cds-icon shape="step-forward" solid="true"></cds-icon>
                                        </span>
                                    </a>
                                </ng-container>
                            </td>
                        </tr>
                    </table>
                </div>
            </a>
        </div>

        <!-- Empty column that separates title and group of buttons at the right -->
        <div class="clr-col" style="padding-top: 10px; padding-bottom: 5px;">
        </div>

        <div class="clr-col" style="width: 100px; flex-grow: 0; padding-left: 0px;">
            <table style="height: 100%;">
                <tr>
                    <td style="padding: 5px;">
                        <a href="javascript://" id="flow_view_reload_button" class="nav-link nav-icon" aria-label="reload">
                            <cds-icon shape="refresh" (click)="reloadData()" *ngIf="!isLoading" style="color: white;"></cds-icon>
                            <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <a href="javascript://" class="nav-link nav-icon" aria-label="tour">
<!--                            <cds-icon shape="help" style="color: white;" (click)="launchTour()"></cds-icon>-->
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <clr-dropdown [clrCloseMenuOnItemClick]="true">
                            <a clrDropdownTrigger href="javascript://" class="nav-link nav-icon" aria-label="settings">
                                <cds-icon shape="cog" style="color: white;"></cds-icon>
                            </a>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Language</label>
                                <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="setLanguage('french')">French</div>
                                <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="setLanguage('english')">English</div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </table>
        </div>
    </div>

<!--    <div class="clr-row" style="position: relative; height: 100%; bottom: 0px; width: 100%; margin: 0px;">-->
<!--    </div>-->

    <clr-modal [(clrModalOpen)]="showFlowModal" [clrModalClosable]="true" [clrModalStaticBackdrop]="false" [clrModalSize]="'xl'">
        <div class="modal-body">
            <div class="clr-row">
                <div class="clr-col-12">
                    <h3>{{ selectFlowDetailsModalTitle }}</h3>
                    <app-netscope-flows-datagrid [temporalParameter]="temporalParameter" [flowDetails]="selectFlowDetails" *ngIf="showFlowModal"></app-netscope-flows-datagrid>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="showFlowModal = false">
                Close
            </button>
        </div>
    </clr-modal>

    <div style="padding-right: 30px; padding-left: 30px;">
        <div class="clr-row">
            <div class="clr-col-12">
                <div class="card">
                    <div class="card-header">
                        Overall traffic
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                        </div>
                        <div class="card-text">
                            <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="overallTrafficChartOptions"
                                    [(update)]="updateOverallTrafficChartFlag"
                                    [oneToOne]="true"
                                    [callbackFunction]="callbackOverallTrafficChartRef"
                                    style="width: 100%; height: 100%; max-height: 400px; display: block;">
                            </highcharts-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="clr-row">
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-header">
                        Inside vs Outside vCenter
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                        </div>
                        <div class="card-text">
                            <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="originPieChartOptions"
                                    [(update)]="updateOriginPieChartFlag"
                                    [callbackFunction]="callbackOriginPieChartRef"
                                    style="width: 100%; height: 100%; max-height: 400px; display: block;">
                            </highcharts-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-header">
                        Protocols
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                        </div>
                        <div class="card-text">
                            <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="protocolPieChartOptions"
                                    [(update)]="updateProtocolPieChartFlag"
                                    [callbackFunction]="callbackProtocolPieChartRef"
                                    style="width: 100%; height: 100%; max-height: 400px; display: block;">
                            </highcharts-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-header">
                        Top 20 Emitters
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                        </div>
                        <div class="card-text">
                            <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="emitterPieChartOptions"
                                    [(update)]="updateEmitterPieChartFlag"
                                    [callbackFunction]="callbackResourcePieChartRef"
                                    style="width: 100%; height: 100%; max-height: 400px; display: block;">
                            </highcharts-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="clr-row">
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">Data analysis</h3>
                        <p class="card-text">
                            {{ NetscopeUtils.formatBytes(amountOfDataProcessed) }} analysed
                        </p>
                    </div>
                </div>
            </div>
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">Packets analysis</h3>
                        <p class="card-text">
                            {{amountOfPacketsProcessed  | number: '1.0-0'}} packets analysed
                        </p>
                    </div>
                </div>
            </div>
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">Flows analysis</h3>
                        <p class="card-text">
                            {{amountOfFlowsProcessed  | number: '1.0-0'}} flows analysed
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="clr-row">
            <div class="clr-col-12">
                <div class="card">
                    <div class="card-header">
                        Details of traffic <button class="btn btn-primary btn-sm" style="float: right; margin-top: 0px; margin-bottom: 5px;" (click)="exportCSV()">Export CSV</button>
                    </div>
                    <div class="card-block" style="padding-top: 0px;">
                        <div class="card-title">
                        </div>
                        <div class="card-text">

                            <clr-tabs>
                                <clr-tab>
                                    <button clrTabLink>Matching flows</button>
                                    <clr-tab-content *clrIfActive>
                                        <app-netscope-flows-datagrid  [temporalParameter]="temporalParameter" [flowDetails]="flowDetails"></app-netscope-flows-datagrid>
                                    </clr-tab-content>
                                </clr-tab>

                                <clr-tab>
                                    <button clrTabLink>Unmatching flows</button>
                                    <clr-tab-content *clrIfActive>
                                        <app-netscope-flows-datagrid  [temporalParameter]="temporalParameter" [flowDetails]="outsideFlowDetails" [showActions]="false"></app-netscope-flows-datagrid>
                                    </clr-tab-content>
                                </clr-tab>
                            </clr-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
