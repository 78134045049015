<div class="clr-row">
  <div class="card">
    <div class="card-header">
      <div class="clr-row">
        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
          <clr-toggle-container clrInline>
            <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="isBestCost" value="params.isBestCostByRegion" [(ngModel)]="params.isBestCostByRegion" (ngModelChange)="loadData()" />
                    <label>for each region</label>
            </clr-toggle-wrapper>
            <clr-control-helper>display the best instance type</clr-control-helper>
          </clr-toggle-container>
        </div>
        <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
          <div class="clr-form-control">
            <div class="clr-control-container ">
              <div class="clr-radio-wrapper">
                <input type="radio" id="vertical-radio2" name="radio-full" value="carbon" [(ngModel)]="params.criteria" (ngModelChange)="loadData()" class="clr-radio" />
                <label for="vertical-radio2" class="clr-control-label">carbon emission</label>
              </div>
              <div class="clr-radio-wrapper">
                <input type="radio" id="vertical-radio1" name="radio-full" value="cost" [(ngModel)]="params.criteria" (ngModelChange)="loadData()" class="clr-radio" />
                <label for="vertical-radio1" class="clr-control-label">cost</label>
              </div>
              <div class="clr-subtext-wrapper">
                <span class="clr-subtext">select criterion</span>
              </div>
            </div>
          </div>
        </div>
        <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
          <clr-toggle-container clrInline>
            <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="isFamily" value="params.isFamily" [(ngModel)]="params.isFamily" (ngModelChange)="loadData()" [disabled]="isFamily"/>
                    <label>family</label>
            </clr-toggle-wrapper>
            <clr-control-helper>keep the instance family</clr-control-helper>
          </clr-toggle-container>
          <clr-toggle-container clrInline>
            <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="isType" value="params.isType" [(ngModel)]="params.isType" (ngModelChange)="loadData()" />
                    <label>type</label>
            </clr-toggle-wrapper>
            <clr-control-helper>keep the instance type</clr-control-helper>
          </clr-toggle-container>
        </div>
        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
          <div class="clr-control-container" style="padding-top: 20px;" *ngIf="isRegionRdy">
            <clr-combobox-container>
							<clr-combobox
                  name="formSimuRegions"
                  class="full-width-input"
                  clrMulti="true"
								  [(ngModel)]="selected_regions"
								  (ngModelChange)="loadData()"
								  placeholder="select a region"
                  #resourceComboBox>
                
                <ng-container *clrOptionSelected="let selected">
                    {{ selected?.name }}
                </ng-container>
        
                <clr-options>
                    <clr-option *clrOptionItems="let item of params.regions;" [clrValue]="item">
                        {{ item.code }} ({{item.name}})
                  </clr-option>
                </clr-options>

							</clr-combobox>
              <clr-control-helper>
                <div class="clr-row">
                  <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <span style="float: left;">filter by region</span>
                  </div>
                  <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <span style="float: right;" [ngClass]="template.desclass" (click)="deselectAll()">deselect all</span>
                  </div>
                </div>
              </clr-control-helper>
              <clr-control-helper>
                <div class="clr-row">
                  <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <span ngClass="addLink" (click)="selectRegions('eu')">select europe regions</span>
                  </div>
                  <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <span ngClass="addLink" (click)="selectRegions('us')">select us regions</span>
                  </div>
                </div>
              </clr-control-helper>
              <clr-control-helper>
                <div class="clr-row">
                  <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <span ngClass="addLink" (click)="selectRegions('ap')">select asia regions</span>
                  </div>
                  <div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="message.cloudProvider == 'aws'">
                    <span ngClass="addLink" (click)="selectRegions('me')">select moyen-orient regions</span>
                  </div>
                  <div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="message.cloudProvider == 'azure' || message.cloudProvider == 'gcp'">
                    <span ngClass="addLink" (click)="selectRegions('au')">select australia regions</span>
                  </div>
                </div>
              </clr-control-helper>
						</clr-combobox-container>
          </div>
        </div>
        <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
          <div class="clr-control-container" style="padding-top: 20px;">
            <div class="clr-input-wrapper">
              <input type="number" class="clr-input" [(ngModel)]="params.target_cost" (ngModelChange)="loadData()">
            </div>
            <clr-control-helper>annual cost limit ($)</clr-control-helper>
          </div>
          <div class="clr-control-container" style="padding-top: 20px;" *ngIf="params.criteria == 'carbon' || params.criteria == 'cost'">
            <div class="clr-input-wrapper">
              <input type="number" class="clr-input" [(ngModel)]="params.target_carbon" (ngModelChange)="loadData()">
            </div>
            <clr-control-helper>annual emission carbon limit (kgCO2 eq)</clr-control-helper>
          </div>
        </div>
      </div>
    </div>
    <div class="card-block">
      <span *ngIf="detailSimu == null" class="label label-info">
				Open an instance to simulate the resizing
			</span>
      <div class="datagrid-limit-height" style="margin-top: 0px;">
        <clr-datagrid [clrDgLoading]="isLoading" class="vmDataGrid">
            <clr-dg-column [clrDgField]="'id'">Instance ID</clr-dg-column>
            <clr-dg-column [clrDgField]="'name'"> NAME</clr-dg-column>
            <clr-dg-column [clrDgField]="'name'"> REGION</clr-dg-column>
            <clr-dg-column [clrDgField]="'name'"> FAMILY</clr-dg-column>
            <clr-dg-column [clrDgField]="'type'">TYPE</clr-dg-column>
            <clr-dg-column [clrDgField]="'type'">BILLING</clr-dg-column>
            <clr-dg-column [clrDgField]="'type'">STATE</clr-dg-column>
            <clr-dg-row *clrDgItems="let vm of instancesAll" [clrDgItem]="vm">
                <clr-dg-cell>{{ vm.id }}</clr-dg-cell>
                <clr-dg-cell>{{ vm.name }}</clr-dg-cell>
                <clr-dg-cell>{{ vm.region }}</clr-dg-cell>
                <clr-dg-cell>{{ vm.uc }}</clr-dg-cell>
                <clr-dg-cell>{{ vm.it }}</clr-dg-cell>
                <clr-dg-cell>{{ vm.billing }}</clr-dg-cell>
                <clr-dg-cell>{{ vm.status }}</clr-dg-cell>
            </clr-dg-row>
    
            <ng-template [(clrIfDetail)]="detailSimu" clrIfDetail let-detail (clrIfDetailChange)="onDetailOpen($event)">
                <clr-dg-detail>
                  <clr-dg-detail-header>
                    {{detail.id}} ({{detail.name}})<br>
                    <span class="label label-info">{{detail.uc}}</span>
                    <span class="label label-info">{{detail.region}}</span>
                    <span class="label label-info">{{detail.it}}</span>
                    <span class="label label-info">billing {{detail.billing}}</span>
                    <span class="label label-info">annual compute cost {{detail.computeCostYear.toFixed(0)}} ($)</span>
                    <span class="label label-info" *ngIf="params.criteria == 'cost' || params.criteria == 'carbon'">annual carbon emission {{detail.carbonYear.toFixed(2)}} (kgCO2 eq)</span>
                  </clr-dg-detail-header>
                  <clr-dg-detail-body>
                      <clr-datagrid>
                        <clr-dg-column [clrDgField]="'region'">region</clr-dg-column>
                        <clr-dg-column [clrDgField]="'rname'">name</clr-dg-column>
                        <clr-dg-column [clrDgField]="'family'">family</clr-dg-column>
                        <clr-dg-column [clrDgField]="'type'">type</clr-dg-column>
                        <clr-dg-column [clrDgField]="'cost_estimated_year'">annual compute cost ($)</clr-dg-column>
                        <clr-dg-column [clrDgField]="'carbon_emission_y'" *ngIf="params.criteria == 'cost' || params.criteria == 'carbon'">annual carbon emission (kgCO2 eq)</clr-dg-column>
                        <clr-dg-row *clrDgItems="let simu of simulation_res" [clrDgItem]="simu">
                          <clr-dg-cell>{{ simu.region }}</clr-dg-cell>
                          <clr-dg-cell>{{ getRegionName(simu.region).name }}</clr-dg-cell>
                          <clr-dg-cell>{{ simu.family }}</clr-dg-cell>
                          <clr-dg-cell>{{ simu.it }}</clr-dg-cell>
                          <clr-dg-cell *ngIf="simu.cost_estimated_year.toFixed(0) > 0; else addNodata">{{ simu.cost_estimated_year.toFixed(0) }}</clr-dg-cell>
                          <ng-template #addNodata>
                            <clr-dg-cell>no data</clr-dg-cell>
                          </ng-template>
                          <clr-dg-cell *ngIf="params.criteria == 'cost' || params.criteria == 'carbon'">{{ simu.carbon_emission_y.toFixed(2) }}</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-footer>
                        <clr-dg-pagination #pagination [clrDgPageSize]="10">
                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Regions per page</clr-dg-page-size>
                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
                            Regions
                        </clr-dg-pagination>
                      </clr-dg-footer>
                      </clr-datagrid>
                  </clr-dg-detail-body>
                </clr-dg-detail>
            </ng-template>
    
            <clr-dg-footer>
              <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
                <button class="btn btn-link" style="position:relative;float:left;" (click)="initNewInstance()">
                    New Instance
                </button>
              </div>
              <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Instances per page</clr-dg-page-size>
                  {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
                  Instances
              </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="isNewInstance" [clrModalSize]="'xl'">
  <h3 class="modal-title">Simulate a new instance</h3>
  <div class="modal-body">
    <div class="card">
      <div class="card-block">
        <div class="card-text">
          <div class="clr-row">
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
              <span style="font-weight:bold;font-size:14px">Instance environment</span>
              <div class="clr-row">
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <br>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" class="clr-input" [(ngModel)]="modelInstance.name">
                    </div>
                    <clr-control-helper>Name of the instance</clr-control-helper>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12"><span>&nbsp;</span></div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select class="clr-select" [(ngModel)]="modelInstance.location" (ngModelChange)="getItEstimation()">
                          <option *ngFor="let region of params.regions">{{region.code}} ({{region.name}})</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <clr-control-helper>Select the location</clr-control-helper>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12"><span>&nbsp;</span></div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select class="clr-select" [(ngModel)]="modelInstance.os" (ngModelChange)="getItEstimation()">
                          <option *ngFor="let os of oss">{{os}}</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="clr-subtext">Select an operating system</span>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12"><span>&nbsp;</span></div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select class="clr-select" [(ngModel)]="modelInstance.billing" (ngModelChange)="getItEstimation()">
                          <option *ngFor="let commit of commits">{{commit}}</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <clr-control-helper>Select the commited usage</clr-control-helper>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12"><span>&nbsp;</span></div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" class="clr-input" [(ngModel)]="modelInstance.hours" (ngModelChange)="getItEstimation()">
                    </div>
                    <clr-control-helper>Average hours per month</clr-control-helper>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12"><span>&nbsp;</span></div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" class="clr-input" [(ngModel)]="modelInstance.sustained" (ngModelChange)="getItEstimation()">
                    </div>
                    <clr-control-helper>Sustained use discount applied (%)</clr-control-helper>
                  </div>
                </div>
              </div>
            </div>
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
              <span style="font-weight:bold;font-size:14px">Compute environment</span>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <br>
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select class="clr-select" [(ngModel)]="modelInstance.family" (ngModelChange)="buildSeries()">
                          <option *ngFor="let family of families">{{family}}</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="clr-subtext">Select the machine family</span>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12"><span>&nbsp;</span></div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select class="clr-select" [(ngModel)]="modelInstance.serie" (ngModelChange)="buildIts()">
                          <option *ngFor="let serie of series">{{serie}}</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <clr-control-helper>Select the machine serie</clr-control-helper>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12"><span>&nbsp;</span></div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select class="clr-select" [(ngModel)]="modelInstance.it" (ngModelChange)="getItProperties()">
                          <option *ngFor="let it of its">{{it}}</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <clr-control-helper>Select the instance type</clr-control-helper>
                  </div>
                </div>
            </div>
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
              <span style="font-weight:bold;font-size:14px">Storage environment</span>
              <div class="clr-row">
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <br>
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select class="clr-select" [(ngModel)]="modelInstance.bootdisktype" (ngModelChange)="buildStorage()">
                          <option *ngFor="let bootdisktype of bootdisktypes">{{bootdisktype}}</option>
                        </select>
                        <cds-icon class="clr-validate-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <clr-control-helper>Select the boot disk type</clr-control-helper>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12"><span>&nbsp;</span></div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="provider == 'gcp' || provider == 'aws'">
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" class="clr-input" [(ngModel)]="modelInstance.bootdisksize" (ngModelChange)="getItEstimation()">
                    </div>
                    <clr-control-helper>Boot disk size (GB)</clr-control-helper>
                  </div>
                </div>
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="provider == 'azure'">
                  <div class="clr-select-wrapper">
                    <select class="clr-select" [(ngModel)]="modelInstance.bootdisksizetype" (ngModelChange)="getItEstimation()">
                      <option *ngFor="let bootdisk of bootdisksizes_azure">{{bootdisk.type}}</option>
                    </select>
                    <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                </div>
                <clr-control-helper>Select the boot disk size</clr-control-helper>
                </div>
              </div>
            </div>
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
              <span style="font-weight:bold;font-size:14px">GreenIT environment</span>
              <div class="clr-row">
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                  <br>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" class="clr-input" [(ngModel)]="modelInstance.avgcpu" (ngModelChange)="getItEstimation()">
                    </div>
                    <clr-control-helper>Average CPU consumption (%)</clr-control-helper>
                  </div>
                </div>
              </div>
            </div>
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
              <div class="card" style="margin-top: 0px;">
                <div class="card-header">
                  <span style="font-size:16px">
                    {{modelInstance.name}}
                  </span>
                </div>
                <div class="card-block">
                  <span style="font-size:14px">
                    operating system: {{modelInstance.os}}
                  </span>
                  <br>
                  <span style="font-size:14px">
                    family: {{modelInstance.family}}
                  </span>
                  <br>
                  <span style="font-size:14px">
                    serie: {{modelInstance.serie}}
                  </span>
                  <br>
                  <span style="font-size:14px">
                    type: {{modelInstance.it}} (vCPU: {{modelInstance.vcpu}}, RAM: {{modelInstance.vram/1024}}GB)
                  </span>
                  <br>
                  <span style="font-size:14px">
                    location: {{modelInstance.location}}
                  </span>
                  <br>
                  <span style="font-size:14px">
                    running hours per month: {{modelInstance.hours}}
                  </span>
                  <br>
                  <span style="font-size:14px">
                    commited usage: {{modelInstance.billing}}
                  </span>
                  <br>
                  <span style="font-size:14px">
                    boot disk type: {{modelInstance.bootdisktype}}
                  </span>
                  <br>
                  <span style="font-size:14px">
                    boot disk size: {{modelInstance.bootdisksize}} GB
                  </span>
                </div>
                <div class="card-footer">
                  <div class="clr-row">
                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                      <span style="font-size:16px">
                        Monthly estimate
                      </span>
                      <span style="font-weight:bold;font-size:16px;float:right;" *ngIf="modelInstance.cost > 0; else noData">
                        $ {{modelInstance.cost.toFixed(2)}}
                      </span>
                      <br>
                      <span style="font-weight:bold;font-size:16px;float:right;" *ngIf="modelInstance.co2 > 0; else noData">
                        <fa-icon [icon]="['fas', 'leaf']" style="font-size:0.75em;"></fa-icon>
                        {{modelInstance.co2.toFixed(2)}} kg CO2eq
                      </span>
                      <ng-template #noData>
                        <span style="font-weight:bold;font-size:16px;float:right;">
                          <fa-icon [icon]="['fas', 'leaf']" style="font-size:0.75em;"></fa-icon>
                          no data
                        </span>
                      </ng-template>
                      <span class="addLink" style="font-size: 10px;" (click)="isDetail = true" *ngIf="isDetail == false; else hide">show details</span>
                      <ng-template #hide>
                        <span class="addLink" style="font-size: 10px;" (click)="isDetail = false">hide details</span>
                      </ng-template>
                      <br>
                    </div>
                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                      <div class="clr-row" *ngIf="isDetail">
                        <br>
                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                          <span style="font-size:12px;">
                            Compute $ {{modelInstance.cost_it.toFixed(2)}}
                          </span>
                          <br>
                          <span style="font-size:12px;">
                            Storage $ {{modelInstance.cost_storage.toFixed(2)}}
                          </span>
                          <br>
                          <span style="font-size:12px;" *ngIf="provider != 'aws'">
                            Operating system $ {{modelInstance.cost_os.toFixed(2)}}
                          </span>
                          <br>
                          <span style="font-size:12px;" *ngIf="provider != 'aws'">
                            License $ {{modelInstance.cost_license.toFixed(2)}}
                          </span>
                          <br>
                        </div>
                        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                          <span style="font-size:12px;">
                            Direct
                            <fa-icon [icon]="['fas', 'leaf']" style="font-size:0.75em;"></fa-icon>
                            {{modelInstance.co2_direct.toFixed(2)}} kg CO2eq
                          </span>
                          <br>
                          <span style="font-size:12px;">
                            Embodied
                            <fa-icon [icon]="['fas', 'leaf']" style="font-size:0.75em;"></fa-icon>
                            {{modelInstance.co2_grey.toFixed(2)}} kg CO2eq
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>                      
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="isNewInstance = false;createNewInstance()">ADD TO SIMULATE</button>
  </div>
</clr-modal>