import {
    AfterViewInit,
    Component,
    ChangeDetectorRef,
    OnInit,
    ViewChild,
    ComponentFactoryResolver
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    InfrastructureService,
    JsonloaderService,
    SettingsService,
    ShareService
} from '@app/services';

import {
    ClusterSynthesis,
    Comment,
    HostBadge,
    Json,
    JSONTarget,
    Message,
    User
} from '@app/model';

import * as $ from 'jquery';
import * as Highcharts from 'highcharts';

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';

import {
    T4Component
} from '@app/t4/t4.component';
import {
    T4Directive
} from '@app/directives/t4.directive';

import {
    EvolutionsynthesisComponent
} from '@app/evolutionsynthesis/evolutionsynthesis.component';
import {
    EvolutionDirective
} from '@app/directives/evolution.directive';

import {
    ClusterdistributionComponent
} from '@app/clusterdistribution/clusterdistribution.component';
import {
    ClusterdistributionDirective
} from '@app/directives/clusterdistribution.directive';
import {
    Clusterdistribution2Directive
} from '@app/directives/clusterdistribution2.directive';
import {
    Clusterdistribution3Directive
} from '@app/directives/clusterdistribution3.directive';
import {
    Clusterdistribution4Directive
} from '@app/directives/clusterdistribution4.directive';
import {
    Clusterdistribution5Directive
} from '@app/directives/clusterdistribution5.directive';
import {
    Clusterdistribution6Directive
} from '@app/directives/clusterdistribution6.directive';
import {
    Clusterdistribution7Directive
} from '@app/directives/clusterdistribution7.directive';
import {
    Clusterdistribution8Directive
} from '@app/directives/clusterdistribution8.directive';
import {
    Clusterdistribution9Directive
} from '@app/directives/clusterdistribution9.directive';
import {
    Clusterdistribution10Directive
} from '@app/directives/clusterdistribution10.directive';
import {
    Clusterdistribution11Directive
} from '@app/directives/clusterdistribution11.directive';
import {
    Clusterdistribution12Directive
} from '@app/directives/clusterdistribution12.directive';

import {
    ConscalendarComponent
} from '@app/conscalendar/conscalendar.component';
import {
    ConscalendarDirective
} from '@app/directives/conscalendar.directive';

import {
    VsanComponent
} from '@app/vsan/vsan.component';
import {
    VsanDirective
} from '@app/directives/vsan.directive';

import {
    VmlistComponent
} from '@app/vmlist/vmlist.component';
import {
    VmlistDirective
} from '@app/directives/vmlist.directive';

import {
    HostlistComponent
} from '@app/hostlist/hostlist.component';
import {
    HostlistDirective
} from '@app/directives/hostlist.directive';

import {
    commaBigNumber,
    getUserCurrency
} from '../../assets/js/tools.js';


@Component({
    selector: 'app-clustersynthesis',
    templateUrl: './clustersynthesis.component.html',
    styleUrls: ['./clustersynthesis.component.css']
})
export class ClustersynthesisComponent implements AfterViewInit, OnInit {

    jsonLoader: Json;

    @ViewChild(T4Directive) addT4: T4Directive;

    @ViewChild(EvolutionDirective) addEvolution: EvolutionDirective;

    @ViewChild(ClusterdistributionDirective) addCl1: ClusterdistributionDirective;

    @ViewChild(Clusterdistribution2Directive) addCl2: Clusterdistribution2Directive;

    @ViewChild(Clusterdistribution3Directive) addCl3: Clusterdistribution3Directive;

    @ViewChild(Clusterdistribution4Directive) addCl4: Clusterdistribution4Directive;

    @ViewChild(Clusterdistribution5Directive) addCl5: Clusterdistribution5Directive;

    @ViewChild(Clusterdistribution6Directive) addCl6: Clusterdistribution6Directive;

    @ViewChild(Clusterdistribution7Directive) addCl7: Clusterdistribution7Directive;

    @ViewChild(Clusterdistribution8Directive) addCl8: Clusterdistribution8Directive;

    @ViewChild(Clusterdistribution9Directive) addCl9: Clusterdistribution9Directive;

    @ViewChild(Clusterdistribution10Directive) addCl10: Clusterdistribution10Directive;

    @ViewChild(Clusterdistribution11Directive) addCl11: Clusterdistribution11Directive;

    @ViewChild(Clusterdistribution12Directive) addCl12: Clusterdistribution12Directive;

    @ViewChild(ConscalendarDirective, {
        static: false
    }) addConsCal: ConscalendarDirective;

    @ViewChild(VsanDirective) addVsan: VsanDirective;

    @ViewChild(VmlistDirective, {
        static: false
    }) addVmlist: VmlistDirective;

    @ViewChild(HostlistDirective, {
        static: false
    }) addHostlist: HostlistDirective;

    math = Math;

    message: Message;

    globalCurrency: string = '';

    data_clusters: any;

    data_vms: any;

    data_hosts: any;

    cluster_synth: ClusterSynthesis = {
        uuid: 'not registered',
        name: 'not registered',
        datacenter: 'not registered',
        hosts: 0,
        vms: 0,
        cost: 0,
        cpucap: 0,
        ramcap: 0,
        drs: false,
        dpm: false,
        ha: false,
        vsan: false,
        cpucapghz: 0,
        cpuConsAvg: 0,
        cpuConsMax: 0,
        cpuprov_ghz: 0,
        ramConsAvg: 0,
        ramConsMax: 0,
        stocap: 0,
        sto_prov: 0,
        stoConsAvg: 0,
        stoConsMax: 0,
        cpu_oc: 0,
        ram_oc: 0,
        sto_oc: 0,
        vcpus: 0,
        rams: 0,
        current_dis: '',
        vms_avg: 0,
        vms_min: 0,
        vms_max: 0,
        srv_vms_max: '',
        srv_vms_min: '',
        vcpu_avg: 0,
        vcpu_min: 0,
        vcpu_max: 0,
        srv_vcpu_max: '',
        srv_vcpu_min: '',
        vram_avg: 0,
        vram_min: 0,
        vram_max: 0,
        srv_vram_max: '',
        srv_vram_min: '',
        cpucons_avg: 0,
        cpucons_min: 0,
        cpucons_max: 0,
        srv_cpucons_max: '',
        srv_cpucons_min: '',
        ramcons_avg: 0,
        ramcons_min: 0,
        ramcons_max: 0,
        srv_ramcons_max: '',
        srv_ramcons_min: '',
        cpuready_avg: 0,
        cpuready_min: 0,
        cpuready_max: 0,
        srv_cpuready_max: '',
        srv_cpuready_min: '',
        cpuconsmax_avg: 0,
        cpuconsmax_min: 0,
        cpuconsmax_max: 0,
        srv_cpuconsmax_max: '',
        srv_cpuconsmax_min: '',
        ramconsmax_avg: 0,
        ramconsmax_min: 0,
        ramconsmax_max: 0,
        srv_ramconsmax_max: '',
        srv_ramconsmax_min: '',
        ramswp_avg: 0,
        ramswp_min: 0,
        ramswp_max: 0,
        srv_ramswp_max: '',
        srv_ramswp_min: '',
        iodisk_avg: 0,
        iodisk_min: 0,
        iodisk_max: 0,
        srv_iodisk_max: '',
        srv_iodisk_min: '',
        latdisk_avg: 0,
        latdisk_min: 0,
        latdisk_max: 0,
        srv_latdisk_max: '',
        srv_latdisk_min: '',
        ionet_avg: 0,
        ionet_min: 0,
        ionet_max: 0,
        srv_ionet_max: '',
        srv_ionet_min: '',
        co2: 0
    };

    cluster_badge: HostBadge = {
        cpu_commitment: 'badge-success',
        ram_commitment: 'badge-success',
        sto_commitment: 'badge-success',
        vm_off: 'badge-success',
        vm_other: 'badge-success',
        tools_te: 'badge-success',
        tools_ti: 'badge-success',
        tools_tu: 'badge-success',
        nbvm_cap: false,
        nbvm_class: 'is-success',
        nbvm_capmax: false,
        nbvmmax_class: 'is-success',
        nbvm_cons: false,
        nbvmcons_class: 'is-success',
        cpu_sat: 'badge-success',
        ram_sat: 'badge-success'
    };

    comment: Comment = {
        ID: 0,
        USER: 'not registered',
        IDENTIFIER: 'not registered',
        COMMENT: 'not registered',
        OWNER: 'not registered',
        ENV: 'not registered',
        CREATE_DATE: 'not registered',
        END_DATE: 'not registered'
    };

    isModalVmList: boolean = false;

    isModalHostList: boolean = false;

    isNoCpuAlert: boolean = true;

    isNoRamAlert: boolean = true;

    isNoStoAlert: boolean = true;

    cpu_state: string = 'info';

    ram_state: string = 'info';

    sto_state: string = 'info';

    isNoEvoAlert: boolean = true;

    evolution_state: string = 'info';

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    isReady: boolean = false;

    currentUser: User;

    datas_green: any = [];


    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private infra_svc: InfrastructureService,
        private authentication_svc: AccountService,
        private settings_svc: SettingsService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
        this.data_clusters = this.jsonLoader.clusterSynthesis;
        this.data_hosts = this.jsonLoader.hostSynthesis;
        this.data_vms = this.jsonLoader.vmSynthesis;

        this.message_svc.currentMessage.subscribe(
            message => {
                this.message = message;
                this.message.vmListFilter = '';
                if (this.message.currentUuid != '') {
                    this.cluster_synth = this.getClusterData(this.message.currentUuid);
                    if (this.cluster_synth === undefined) {
                        this.cluster_synth = this.data_clusters[0];
                        this.message.currentUuid = this.cluster_synth.uuid;
                        this.message.currentName = this.cluster_synth.name;
                        this.message.currentType = "CLUSTER";
                        this.message.powerUsageEnv = 'vmware';
                    }
                } else {
                    this.cluster_synth = this.data_clusters[0];
                    this.message.currentUuid = this.cluster_synth.uuid;
                    this.message.currentName = this.cluster_synth.name;
                    this.message.currentType = "CLUSTER";
                    this.message.powerUsageEnv = 'vmware';
                }
            }
        );

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.buildCard();

    }

    ngAfterViewInit(): void {

        this.setCpuAllocation();
        this.loadT4Card();
        setTimeout(() => $('#but1torem').remove(), 100);
        setTimeout(() => $('#but2torem').remove(), 100);
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    updateEnv(value): void {
        this.comment.ENV = value;
        if (value == "")
            value = 'NULL';
        this.callUpdateComment('ENV', value);
    }

    setCard(): void {

        $('#cluster-t4').show();
        this.setCpuAllocation();
        this.setBadges();
        setTimeout(() => this.loadT4Card(), 100);
    }

    setDistribution(): void {

        setTimeout(() => this.loadClDis1Card(), 100);
        setTimeout(() => this.loadClDis2Card(), 100);
        setTimeout(() => this.loadClDis3Card(), 100);
        setTimeout(() => this.loadClDis4Card(), 100);
        setTimeout(() => this.loadClDis5Card(), 100);
        setTimeout(() => this.loadClDis6Card(), 100);
        setTimeout(() => this.loadClDis7Card(), 100);
        setTimeout(() => this.loadClDis8Card(), 100);
        setTimeout(() => this.loadClDis9Card(), 100);
        setTimeout(() => this.loadClDis10Card(), 100);
        setTimeout(() => this.loadClDis11Card(), 100);
        setTimeout(() => this.loadClDis12Card(), 100);
    }

    setCalCons(): void {
        setTimeout(() => this.loaddConsCal(), 100);
    }

    setVsan(): void {
        setTimeout(() => this.loadVsan(), 100);
    }

    setCpuAllocation(): void {

        let cat_cpu = ['threads capacity', 'vcpu allocated to VMs', 'max vcpu'];
        var data_cpu = [{
            y: this.cluster_synth.cpucap,
            color: '#CCC6AD'
        }, {
            y: this.cluster_synth.vcpus,
            color: '#046380'
        }, {
            y: this.cluster_synth.cpucap * this.cluster_synth.cpu_oc,
            color: '#e74c3c'
        }];
        this.callBarGraph(data_cpu, cat_cpu, '');
    }

    setRamAllocation(): void {

        let cat_ram = ['ram capacity', 'vram allocated to VMs', 'max vram'];
        var data_ram = [{
            y: Math.round(this.cluster_synth.ramcap / 1024),
            color: '#CCC6AD'
        }, {
            y: Math.round(this.cluster_synth.rams / 1024),
            color: '#046380'
        }, {
            y: Math.round(this.cluster_synth.ramcap / 1024 * this.cluster_synth.ram_oc / 100),
            color: '#e74c3c'
        }];
        var ram_unit: string = 'GB';
        if ((this.cluster_synth.ramcap / 1024) > 10000 && this.cluster_synth.rams > 10000)
            ram_unit = 'TB';

        this.callBarGraph(data_ram, cat_ram, ram_unit);
    }

    setStoAllocation(): void {

        let cat_sto = ['datastore', 'provisioned', 'max prov'];
        var data_sto = [{
            y: Math.round(this.cluster_synth.stocap / 1024),
            color: '#CCC6AD'
        }, {
            y: Math.round(this.cluster_synth.sto_prov),
            color: '#046380'
        }, {
            y: Math.round(this.cluster_synth.stocap / 1024 * this.cluster_synth.sto_oc / 100),
            color: '#e74c3c'
        }];
        var sto_unit = 'GB';
        if ((this.cluster_synth.stocap / 1024) > 1024 && this.cluster_synth.sto_prov > 1024) {
            sto_unit = 'TB';
            data_sto = [];
            data_sto = [{
                y: Math.round(this.cluster_synth.stocap / 1048576),
                color: '#CCC6AD'
            }, {
                y: Math.round(this.cluster_synth.sto_prov / 1024),
                color: '#046380'
            }, {
                y: Math.round(this.cluster_synth.stocap / 1048576 * this.cluster_synth.sto_oc / 100),
                color: '#e74c3c'
            }];
        }

        this.callBarGraph(data_sto, cat_sto, sto_unit);
    }

    setEvolution(): void {
        setTimeout(() => this.loadEvolution(), 100);
    }

    callHostList(): void {

        this.isModalHostList = true;
        setTimeout(() => this.loadHostList(), 100);
    }

    callVmList(): void {

        this.isModalVmList = true;
        setTimeout(() => this.loadVmList(), 100);
    }

    filterVm(vm: any): any {

        let vms: any = [];
        for (var i in this.data_vms) {
            if (this.data_vms[i].name != "vm_average") {
                if (this.data_vms[i].cluster == this.message.currentUuid)
                    vms.push(this.data_vms[i]);
            }
        }

        return vms;
    }

    private buildCard(): void {

        this.globalCurrency = getUserCurrency(this.currentUser.currency);
        if (!isNaN(this.cluster_synth.cost))
            this.cluster_synth.cost = commaBigNumber(this.math.round(this.cluster_synth.cost));

        // GET HOST CO2
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_POWER)
            .subscribe(
                data => {
                    this.datas_green = data;
                    let co2_el: number = this.getPowerElement();
                    this.cluster_synth.co2 = co2_el;
                }
            );

        this.setBadges();

        // GET LOCATION	
        this.infra_svc.getElementInfra(this.message.currentUuid, 'CLUSTER').pipe(first()).subscribe(
            data => {
                this.cluster_synth.datacenter = data.datacenter;
            }, error => {
                console.log('no location data ' + this.message.currentUuid);
            }
        );

        // GET ENVIRONMENT
        this.settings_svc.getUserComment('root', this.message.currentUuid).pipe(first()).subscribe(
            data => {
                this.comment.ID = data.ID;
                this.comment.USER = data.USER;
                this.comment.ENV = data.ENV;

            }, error => {
                console.log('no comment data for ' + this.message.currentUuid);
                this.comment.ENV = '';

                // No data, getMaxIdComment
                this.settings_svc.getMaxIdComment().pipe(first()).subscribe(
                    data => {
                        var y: number = Number(data.id);
                        this.comment.ID = y;
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
            }
        );

        // SHARE THE ClusterSynthesis OBJECT
        this.message.clusterSynth = this.cluster_synth;
    }

    private setBadges(): void {

        // BADGE & ALERT MANAGEMENT
        // CPU ALLOCATION
        if (this.math.round(this.cluster_synth.vcpus) >= this.math.round(this.cluster_synth.cpucap * this
                .cluster_synth.cpu_oc)) {
            this.isNoCpuAlert = false;
            this.cluster_badge.cpu_commitment = 'badge-danger';
            this.cpu_state = 'danger';
            setTimeout(() => $('#cluster-alert-text').html('cpu allocation: critical'), 100);
        } else {
            if (this.math.round(this.cluster_synth.vcpus) >= 0.8 * (this.math.round(this.cluster_synth.cpucap * this
                    .cluster_synth.cpu_oc))) {
                this.isNoCpuAlert = false;
                this.cluster_badge.cpu_commitment = 'badge-warning';
                this.cpu_state = 'warning';
                setTimeout(() => $('#cluster-alert-text').html('cpu allocation: warning'), 100);
            }
        }
        // RAM ALLOCATION
        if (this.math.round(this.cluster_synth.rams / 1024) >= this.math.round(this.cluster_synth.ramcap / 1024 *
                this.cluster_synth.ram_oc / 100)) {
            this.isNoRamAlert = false;
            this.cluster_badge.ram_commitment = 'badge-danger';
            this.ram_state = 'danger';
            setTimeout(() => $('#cluster-alert2-text').html('ram allocation: critical'), 100);
        } else {
            if (this.math.round(this.cluster_synth.rams / 1024) >= 0.8 * (this.math.round(this.cluster_synth
                    .ramcap / 1024 * this.cluster_synth.ram_oc / 100))) {
                this.isNoRamAlert = false;
                this.cluster_badge.ram_commitment = 'badge-warning';
                this.ram_state = 'warning';
                setTimeout(() => $('#cluster-alert2-text').html('ram allocation: warning'), 100);
            }
        }
        // STORAGE ALLOCATION
        if (this.math.round(this.cluster_synth.sto_prov) >= this.math.round(this.cluster_synth.stocap / 1024 * this
                .cluster_synth.sto_oc / 100)) {
            this.isNoStoAlert = false;
            this.cluster_badge.sto_commitment = 'badge-danger';
            this.sto_state = 'danger';
            setTimeout(() => $('#cluster-alert3-text').html('storage provisonned: critical'), 100);
        } else {
            if (this.math.round(this.cluster_synth.sto_prov) >= 0.8 * (this.math.round(this.cluster_synth.stocap /
                    1024 * this.cluster_synth.sto_oc / 100))) {
                this.isNoStoAlert = false;
                this.cluster_badge.sto_commitment = 'badge-warning';
                this.sto_state = 'warning';
                setTimeout(() => $('#cluster-alert3-text').html('storage provisonned: warning'), 100);
            }
        }

        // EVOLUTION
        var data_prediction = this.jsonLoader.clustersPrediction;
        var nbvm_cap: number = 0;
        var consram: number = 0;
        var conscpu: number = 0;
        for (var i in data_prediction) {
            if (data_prediction[i].UUID == this.message.currentUuid) {
                nbvm_cap = data_prediction[i].NBVM_CAP;
                conscpu = data_prediction[i].CONSCPU_DATE;
                consram = data_prediction[i].CONSRAM_DATE;
            }
        }
        if (nbvm_cap < (this.cluster_synth.vms * 0.2)) {
            this.isNoEvoAlert = false;
            this.evolution_state = 'warning';
            setTimeout(() => $('#cluster-alert4-text').html('evolution: warning'), 100);
        }
        if (nbvm_cap < (this.cluster_synth.vms * 0.1)) {
            this.isNoEvoAlert = false;
            this.evolution_state = 'danger';
            setTimeout(() => $('#cluster-alert4-text').html('evolution: critical'), 100);
        }
        if (this.evolution_state != "danger") {
            var cpusat_state = '';
            cpusat_state = this.analyseEvolution(conscpu);
            if (cpusat_state != "danger") {
                var ramsat_state = '';
                ramsat_state = this.analyseEvolution(consram);
                if (ramsat_state == "danger") {
                    this.isNoEvoAlert = false;
                    this.evolution_state = 'danger';
                    setTimeout(() => $('#cluster-alert4-text').html('evolution: critical'), 100);
                } else if (ramsat_state == "warning" || cpusat_state == "warning") {
                    this.isNoEvoAlert = false;
                    this.evolution_state = 'warning';
                    setTimeout(() => $('#cluster-alert4-text').html('evolution: warning'), 100);
                }
            } else {
                this.isNoEvoAlert = false;
                this.evolution_state = 'danger';
                setTimeout(() => $('#cluster-alert4-text').html('evolution: critical'), 100);
            }
        }
    }

    private analyseEvolution(sat_date_unix: number): string {

        var alert: string = '';
        let now = moment();
        const sat_date = moment.unix(sat_date_unix / 1000);
        if (sat_date.year() < now.year()) {
            alert = 'danger';
        } else if (sat_date.year() == now.year()) {
            if ((sat_date.dayOfYear() - now.dayOfYear()) < 180) {
                if ((sat_date.dayOfYear() - now.dayOfYear()) < 30)
                    alert = 'danger';
                else
                    alert = 'warning';
            }
        }

        return alert;
    }

    private getClusterData(uuid: string): ClusterSynthesis {
        return this.data_clusters.find(cluster => cluster.uuid === uuid);
    }

    private loadT4Card(): void {

        if (this.addT4 != undefined) {
            const componentFactory4 = this.componentFactoryResolver.resolveComponentFactory(T4Component);
            const viewContainerRef4 = this.addT4.viewContainerRef4;
            const componentRef4 = viewContainerRef4.createComponent(componentFactory4);
        }
    }

    private loadEvolution(): void {

        if (this.addEvolution != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                EvolutionsynthesisComponent);
            const viewContainerRef = this.addEvolution.viewContainerRef;
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private callUpdateComment(item: string, value: string): void {

        this.settings_svc.updateComment(this.comment.ID, this.message.currentUuid, item, value).subscribe(
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private loadClDis1Card(): void {

        this.cluster_synth.current_dis = 'dis1';
        if (this.addCl1 != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef = this.addCl1.viewContainerRef;
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadClDis2Card(): void {

        this.cluster_synth.current_dis = 'dis2';
        if (this.addCl2 != undefined) {
            const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef2 = this.addCl2.viewContainerRef2;
            const componentRef2 = viewContainerRef2.createComponent(componentFactory2);
        }
    }

    private loadClDis3Card(): void {

        this.cluster_synth.current_dis = 'dis3';
        if (this.addCl3 != undefined) {
            const componentFactory3 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef3 = this.addCl3.viewContainerRef3;
            const componentRef3 = viewContainerRef3.createComponent(componentFactory3);
        }
    }

    private loadClDis4Card(): void {

        this.cluster_synth.current_dis = 'dis4';
        if (this.addCl4 != undefined) {
            const componentFactory4 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef4 = this.addCl4.viewContainerRef4;
            const componentRef4 = viewContainerRef4.createComponent(componentFactory4);
        }
    }

    private loadClDis5Card(): void {

        this.cluster_synth.current_dis = 'dis5';
        if (this.addCl5 != undefined) {
            const componentFactory5 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef5 = this.addCl5.viewContainerRef5;
            const componentRef5 = viewContainerRef5.createComponent(componentFactory5);
        }
    }

    private loadClDis6Card(): void {

        this.cluster_synth.current_dis = 'dis6';
        if (this.addCl6 != undefined) {
            const componentFactory6 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef6 = this.addCl6.viewContainerRef6;
            const componentRef6 = viewContainerRef6.createComponent(componentFactory6);
        }
    }

    private loadClDis7Card(): void {

        this.cluster_synth.current_dis = 'dis7';
        if (this.addCl7 != undefined) {
            const componentFactory7 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef7 = this.addCl7.viewContainerRef7;
            const componentRef7 = viewContainerRef7.createComponent(componentFactory7);
        }
    }

    private loadClDis8Card(): void {

        this.cluster_synth.current_dis = 'dis8';
        if (this.addCl8 != undefined) {
            const componentFactory8 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef8 = this.addCl8.viewContainerRef8;
            const componentRef8 = viewContainerRef8.createComponent(componentFactory8);
        }
    }

    private loadClDis9Card(): void {

        this.cluster_synth.current_dis = 'dis9';
        if (this.addCl9 != undefined) {
            const componentFactory9 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef9 = this.addCl9.viewContainerRef9;
            const componentRef9 = viewContainerRef9.createComponent(componentFactory9);
        }
    }

    private loadClDis10Card(): void {

        this.cluster_synth.current_dis = 'dis10';
        if (this.addCl10 != undefined) {
            const componentFactory10 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef10 = this.addCl10.viewContainerRef10;
            const componentRef10 = viewContainerRef10.createComponent(componentFactory10);
        }
    }

    private loadClDis11Card(): void {

        this.cluster_synth.current_dis = 'dis11';
        if (this.addCl11 != undefined) {
            const componentFactory11 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef11 = this.addCl11.viewContainerRef11;
            const componentRef11 = viewContainerRef11.createComponent(componentFactory11);
        }
    }

    private loadClDis12Card(): void {

        this.cluster_synth.current_dis = 'dis12';
        if (this.addCl12 != undefined) {
            const componentFactory12 = this.componentFactoryResolver.resolveComponentFactory(
                ClusterdistributionComponent);
            const viewContainerRef12 = this.addCl12.viewContainerRef12;
            const componentRef12 = viewContainerRef12.createComponent(componentFactory12);
        }
    }

    private loaddConsCal(): void {

        if (this.addConsCal != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConscalendarComponent);
            const viewContainerRef = this.addConsCal.viewContainerRef;
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadVmList(): void {

        if (this.addVmlist != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(VmlistComponent);
            const viewContainerRef = this.addVmlist.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadHostList(): void {

        if (this.addHostlist != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(HostlistComponent);
            const viewContainerRef = this.addHostlist.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadVsan(): void {

        if (this.addVsan != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(VsanComponent);
            const viewContainerRef = this.addVsan.viewContainerRef;
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private callBarGraph(data: any, cat: any, unit: string): void {

        this.chartOptions = {
            chart: {
                type: 'bar',
                height: '20%',
                backgroundColor: '#ffffff'
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            title: {
                text: '',
                style: {
                    font: 'normal 12px arial',
                    color: 'grey'
                },
                align: 'center'
            },
            xAxis: {
                categories: cat
            },
            yAxis: {
                visible: false
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        color: 'grey',
                        format: '{point.y} {series.name}'
                    }
                },
                series: {
                    enableMouseTracking: false
                }
            },
            series: [{
                type: 'bar',
                name: unit,
                data: data
            }]
        };
        this.isReady = true;
    }

    private filterHost(): any {

        let hosts: any = [];
        for (var i in this.data_hosts) {
            if (this.data_hosts[i].father == this.message.currentUuid)
                hosts.push(this.data_hosts[i]);
        }

        return hosts;
    }

    private getPowerElement(): number {

        let hosts: any = this.filterHost();

        let co2: number = 0;

        if (hosts.length > 0) {
            for (let i in hosts) {
                for (let j in this.datas_green) {
                    if (hosts[i].father === this.message.currentUuid && this.datas_green[j].IDENTIFIER === hosts[i]
                        .uuid) {
                        let co2_el: any = this.datas_green[j];
                        let direct: number = +co2_el.CO2;
                        let grey: number = +co2_el.CO2G;
                        co2 += Math.round(direct + grey);
                    }
                }
            }
        }

        return co2;
    }
}
