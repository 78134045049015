<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
<table id="reco-consolidation-detail" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th rowspan="2">SERVER</th>
			<!--<th colspan="3">MANAGEMENT TOOLS</th>-->
			<th rowspan="2">NUMBER of VMs</th>
			<th colspan="2">CONSUMPTION</th>
			<th colspan="2">OVER COMMITMENT</th>
		</tr>
		<tr>
			<!--<th>TO INSTALL</th>
			<th>TO UPDATE</th>
			<th>TO ENABLE</th>-->
			<th>CPU(%)</th>
			<th>RAM(%)</th>
			<th>vCPU/logical core</th>
			<th>% of RAM</th>
		</tr>
	</thead>
	<tbody>
    		<tr *ngFor="let reco of reco_servers_data">
			<td>{{reco.name}}</td>
			<!--<td>{{reco.tools_install}}</td>
			<td>{{reco.tools_update}}</td>
			<td>{{reco.tools_enable}}</td>-->
			<td>{{reco.vm_nb}}</td>
			<td>{{reco.avg_cpu_cons}}</td>
			<td>{{reco.avg_ram_cons}}</td>
			<td>{{reco.over_cpu}}</td>
			<td>{{reco.over_ram}}</td>
		</tr>
	</tbody>
</table>
