<clr-tabs>
	<clr-tab>
        <button clrTabLink (click)="setActiveTab('daily', true)">Daily</button>
        <ng-template [(clrIfActive)]="daily_active">
            <clr-tab-content>
                <div style="margin-top: 1.3rem;">
                    <label class="clr-control-label">Every day</label>
                </div>
            </clr-tab-content>
        </ng-template>
    </clr-tab>

    <clr-tab>
        <button clrTabLink (click)="setActiveTab('weekly', true)">Weekly</button>
        <ng-template [(clrIfActive)]="weekly_active">
            <clr-tab-content>
                 <clr-radio-container clrInline style="flex-direction:initial">
                    <label style="align-self:center">Every&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio (change)="regenerateCron()" name="report_dow" value="MON" [(ngModel)]="data.weekly.day"/>
                        <label>Monday</label>
                     </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio (change)="regenerateCron()" name="report_dow" value="TUE" [(ngModel)]="data.weekly.day" />
                         <label>Tuesday</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio (change)="regenerateCron()" name="report_dow" value="WED" [(ngModel)]="data.weekly.day" />
                        <label>Wednesday</label>
                     </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio (change)="regenerateCron()" name="report_dow" value="THU" [(ngModel)]="data.weekly.day" />
                        <label>Thursday</label>
                    </clr-radio-wrapper>
                     <clr-radio-wrapper>
                        <input type="radio" clrRadio (change)="regenerateCron()" name="report_dow" value="FRI" [(ngModel)]="data.weekly.day" />
                        <label>Friday</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio (change)="regenerateCron()" name="report_dow" value="SAT" [(ngModel)]="data.weekly.day" />
                        <label>Saturday</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input type="radio" clrRadio (change)="regenerateCron()" name="report_dow" value="SUN" [(ngModel)]="data.weekly.day" />
                        <label>Sunday</label>
                    </clr-radio-wrapper>
                </clr-radio-container>
            </clr-tab-content>
        </ng-template>
    </clr-tab>

    <clr-tab>
        <button clrTabLink (click)="setActiveTab('monthly', true)">Monthly</button>
        <ng-template [(clrIfActive)]="monthly_active">
            <clr-tab-content>
                <clr-select-container style="flex-direction:initial">
                    <label style="align-self:center">Every </label>
                    <select clrSelect (change)="regenerateCron()" [(ngModel)]="data.monthly.day">
                        <option *ngFor="let day of selectOptions.monthDaysWithLast" [ngValue]="day">
                            {{monthDayDisplay(day)}}
                        </option>
                    </select>
                </clr-select-container>
            </clr-tab-content>
        </ng-template>
    </clr-tab>

    <clr-tab>
        <button clrTabLink (click)="setActiveTab('yearly', true)">Yearly</button>
        <ng-template [(clrIfActive)]="yearly_active">
            <clr-tab-content>
                <div style="width:25%;float:left;">
                    <clr-select-container style="flex-direction:initial">
                        <label style="align-self:center">Every </label>
                        <select clrSelect (change)="regenerateCron()" [(ngModel)]="data.yearly.month">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{monthDisplay(month)}}
                            </option>
                        </select>
                    </clr-select-container>
                </div>
                <div style="float:left;">
                    <clr-select-container style="flex-direction:initial">
                        <label style="align-self:center"> the </label>
                        <select clrSelect (change)="regenerateCron()" [(ngModel)]="data.yearly.day">
                            <option *ngFor="let day of selectOptions.monthDaysWithLast" [ngValue]="day">
                                {{monthDayDisplay(day)}}
                         </option>
                        </select>
                    </clr-select-container>
                </div>
            </clr-tab-content>
        </ng-template>
    </clr-tab>
    
</clr-tabs>
