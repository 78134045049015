import {
    AfterViewChecked,
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ChangeDetectorRef,
    ViewContainerRef,
    ComponentFactory
} from '@angular/core';
import {
    NavigationEnd,
    NavigationStart,
    Router
} from '@angular/router';
import {
    first
} from 'rxjs/operators';
import {
    Title
} from '@angular/platform-browser';
import {
    AccountService,
    JsonloaderService,
    ManagementService,
    ShareService,
    SettingsService,
    LicenseService,
    MonitorService
} from '@app/services';
import {
    FilterMgt,
    Json,
    Message,
    User,
    Status,
    StatusColor,
    IhmSettingsTarget,
    TimeFilter
} from '@app/model';

import {
    AlertingwatcherComponent
} from '@app/alerting/alertingwatcher/alertingwatcher.component';
import {
    AlertingwatcherDirective
} from '@app/directives/alertingwatcher.directive';
import {
    LicenseComponent
} from '@app/license/license.component';
import {
    LicenseType
} from '@app/license/license.enums';
import {
    LicenseInfo
} from '@app/model/license';
import {
    UploadComponent
} from '@app/upload/upload.component';
import {
    UploadType
} from '@app/upload/upload.enums';
import {
    MonitorComponent
} from '@app/monitor/monitor.component';

import {
    SettingsComponent
} from '@app/settings/settings.component';
import {
    Options
} from '@app/model/settings';
import {
    PluginComponent
} from '@app/plugin/plugin.component';
import {
    interval,
    Subscription
} from 'rxjs';
import {
    VirtindexService
} from '@app/services/virtindex.service';
import {
    environment
} from "@environments/environment";
import {
    LdapComponent
} from '@app/settings/ldap/ldap.component';

// import * as $ from 'jquery';

export let browserRefresh = false;

import '@cds/core/icon/register.js';
import {
    ClarityIcons,
    dashboardIcon,
    libraryIcon,
    thumbsUpIcon,
    lineChartIcon,
    sliderIcon,
    exclamationCircleIcon,
    bellIcon,
    barChartIcon,
    detailsIcon,
    tableIcon,
    boltIcon,
    bellCurveIcon,
    cloudIcon,
    resourcePoolIcon,
    networkGlobeIcon,
    treeViewIcon,
    recycleIcon,
    chatBubbleIcon,
    organizationIcon,
    heatMapIcon,
    tagsIcon,
    nodeGroupIcon,
    noteIcon,
    checkboxListIcon,
    homeIcon,
    filterIcon,
    targetIcon,
    calendarIcon,
    cogIcon,
    listIcon,
    helpInfoIcon,
    flagIcon,
    keyIcon,
    storageIcon,
    downloadIcon,
    uploadIcon,
    userIcon,
    dollarIcon,
    usersIcon,
    viewCardsIcon,
    logoutIcon,
    dataClusterIcon,
    gridViewIcon,
    shuffleIcon,
    clusterIcon,
    hostIcon,
    vmIcon,
    searchIcon,
    angleIcon,
    errorStandardIcon,
    infoCircleIcon,
    syncIcon,
    timesCircleIcon,
    linkIcon,
    checkCircleIcon,
    pencilIcon,
    networkSettingsIcon,
    windowCloseIcon,
    euroIcon,
    toolsIcon,
    plusCircleIcon,
    clockIcon,
    treeIcon,
    memoryIcon,
    mapMarkerIcon,
    cpuIcon,
    playIcon,
    eraserIcon,
    plusIcon,
    minusIcon,
    trashIcon,
    deployIcon,
    floppyIcon,
    employeeGroupIcon,
    checkIcon,
    idBadgeIcon,
    processOnVmIcon,
    nodesIcon,
    folderOpenIcon,
    updateIcon,
    applicationsIcon,
    hostGroupIcon,
    folderIcon,
    networkSwitchIcon,
    warningStandardIcon,
    zoomInIcon,
    viewListIcon,
    minusCircleIcon,
    zoomOutIcon,
    containerIcon,
    backupIcon,
    podIcon,
    rackServerIcon,
    pieChartIcon,
    refreshIcon,
    shieldCheckIcon,
    stopIcon,
    replayOneIcon,
    downloadCloudIcon,
    helpIcon,
    pinIcon,
    radarIcon,
    crosshairsIcon,
    tagIcon,
    stepForwardIcon,
    twoWayArrowsIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(dashboardIcon, libraryIcon, thumbsUpIcon, lineChartIcon, sliderIcon, exclamationCircleIcon,
    bellIcon, barChartIcon, detailsIcon, tableIcon, boltIcon, bellCurveIcon, cloudIcon, resourcePoolIcon,
    networkGlobeIcon, treeViewIcon, recycleIcon, chatBubbleIcon, organizationIcon, heatMapIcon, tagsIcon,
    nodeGroupIcon, noteIcon, checkboxListIcon, homeIcon, filterIcon, targetIcon, calendarIcon, cogIcon, listIcon,
    helpInfoIcon, flagIcon, keyIcon, storageIcon, downloadIcon, uploadIcon, userIcon, dollarIcon, usersIcon,
    viewCardsIcon, logoutIcon, dataClusterIcon, gridViewIcon, shuffleIcon, clusterIcon, hostIcon, vmIcon,
    searchIcon, angleIcon, errorStandardIcon, infoCircleIcon, syncIcon, timesCircleIcon, linkIcon, treeIcon,
    checkCircleIcon, pencilIcon, networkSettingsIcon, windowCloseIcon, euroIcon, toolsIcon, plusCircleIcon,
    clockIcon, mapMarkerIcon, memoryIcon, cpuIcon, playIcon, eraserIcon, plusIcon, trashIcon, deployIcon,
    idBadgeIcon, processOnVmIcon, helpInfoIcon, nodesIcon, applicationsIcon, hostGroupIcon,
    minusIcon, floppyIcon, employeeGroupIcon, checkIcon, folderOpenIcon, folderIcon, updateIcon, networkSwitchIcon,
    warningStandardIcon, zoomInIcon, viewListIcon, minusCircleIcon, zoomOutIcon, containerIcon, backupIcon, podIcon,
    rackServerIcon, shieldCheckIcon, refreshIcon, downloadCloudIcon, helpIcon, pinIcon, radarIcon, crosshairsIcon,
    tagIcon, stepForwardIcon, twoWayArrowsIcon,
    warningStandardIcon, zoomInIcon, viewListIcon, minusCircleIcon, zoomOutIcon, containerIcon, backupIcon, podIcon,
    rackServerIcon, pieChartIcon, shieldCheckIcon, stopIcon, replayOneIcon);

@Component({
    selector: 'dcscope-app-root',
    templateUrl: './dcscope-app.component.html',
    styleUrls: ['./dcscope-app.component.css']
})
export class DcscopeAppComponent implements AfterViewChecked, OnInit {

    title: string = 'DC Scope';

    @ViewChild(AlertingwatcherDirective) addWatcher: AlertingwatcherDirective;
    @ViewChild("upload", {
        static: true
    }) uploadChild: UploadComponent;
    @ViewChild("license", {
        static: true
    }) licenseChild: LicenseComponent;
    @ViewChild("monitor", {
        static: true
    }) monitorChild: MonitorComponent;
    @ViewChild("settings", {
        static: true
    }) settingsChild: SettingsComponent;
    @ViewChild("greenitsettings", {
        static: true
    }) greenitsettingsChild;
    @ViewChild("plugin", {
        static: true
    }) pluginChild: PluginComponent;
    @ViewChild("ldap", {
        static: true
    }) ldapChild: LdapComponent;

    jsonLoader: Json;

    role: User;

    message: Message;

    // Filter
    filter_dsp: string = 'my filter';

    // License
    license_info: LicenseInfo;
    modal: LicenseType = LicenseType.MODAL;

    // Status
    instance_global_alert: number;
    instance_details: Array < Status > ;
    db_global_alert: number;
    db_details: Array < Status > ;

    // About
    isModalAbout: boolean = false;

    // Navigation
    start_url: string;
    end_url: string;
    from_router_subscription: Subscription;

    // Datalock modal
    isModalDatalock: boolean = false;

    // Alert
    alertWatcherComponent: any;

    // Session
    session_subscription: Subscription;

    // For compatibility with Canada on staging
    isProduction: boolean = environment.production;

    /**
     *
     */
    constructor(
        private titleService: Title,
        private componentFactoryResolver: ComponentFactoryResolver,
        private router: Router,
        private authentication_svc: AccountService,
        private settings_svc: SettingsService,
        private message_svc: ShareService,
        private management_svc: ManagementService,
        private json_svc: JsonloaderService,
        private license_svc: LicenseService,
        private monitor_svc: MonitorService,
        private cd: ChangeDetectorRef,
        private resolver: ComponentFactoryResolver,
        private virtindex_svc: VirtindexService) {

        this.titleService.setTitle(this.title);
    }


    /**
     * XXX Need async for checkSession
     */
    async ngOnInit(): Promise < void > {

        this.authentication_svc.user.subscribe(user => this.role = user);

        // Reload auth (for refresh)
        this.authentication_svc.reload();

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.currentJson.subscribe(
            json => {
                this.jsonLoader = json
            }
        );

        this.router.events.subscribe(route => {
            // Sometimes url can contains a base and get parameters. For example, an URL such as
            // "http://foo.bar/a/b?a=1&b=2" can be divided in two parts:
            //    - an URL: http://foo.bar/a/b
            //    - one or several GET parameters: {a:1, b:2}
            let urlParts = this.router.url.toString().split("?");
            let url = urlParts[0];
            this.message.breadcrumpRoute = url;
            if (route instanceof NavigationStart) {
                this.start_url = this.router.url;
            }
            if (route instanceof NavigationEnd) {
                this.end_url = this.router.url;
            }

            // After login, get user filters, check current filter / check datalock
            if (this.start_url == "/login" && this.end_url == "/dashboard" && this.message.isNotLoginPage) {
                this.reload();
            }
        });

        //XXX check token : need to waiting for isNotLoginPage
        await this.checkSession();

        // After refresh, if session is valid
        if (this.message.isNotLoginPage) {
            this.reload();
        }

        this.license_svc.licenseInfo.subscribe(infos => {
            this.license_info = infos
        });

        this.monitor_svc.instanceGlobal.subscribe(global => this.instance_global_alert = global);
        this.monitor_svc.instanceDetails.subscribe(details => this.instance_details = details);
        this.monitor_svc.dbGlobal.subscribe(global => this.db_global_alert = global);
        this.monitor_svc.dbDetails.subscribe(details => this.db_details = details);

        this.virtindex_svc.virtindexStatus.subscribe(status => {
            let greenindex_status = status;

            // Send & receive data for green-index
            if (greenindex_status) {
                this.virtindex_svc.getVirtindexData();
            }
        });
    }

    ngAfterViewInit(): void {
        var element = document.getElementById("trm");
        $(element).children(".clr-col-md-2").remove();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    /**
     *
     */
    private reload(): void {

        this.license_svc.getLicenseInfo();

        this.monitor_svc.getStatus();

        this.virtindex_svc.getVirtindexSettings();

        // Load alert watcher
        this.loadWatcher();

        // Load session ping
        this.loadSessionPing();

        // TODO : get myfilter & update this.message.currentFilter
        // XXX :  default this.message.currentFilter is 'all'

        this.from_router_subscription = this.management_svc.getFilterList(this.role.login).pipe(first()).subscribe(
            data => {
                // Update user filters
                this.message.filterList = [];
                this.message.filterList = data;
                this.addSharedFilter();

                // Load filter
                this.switchFilter('all');

                // Check datalock
                this.monitor_svc.checkDatalock().subscribe(
                    data => {
                        this.isModalDatalock = data.show;
                    }
                );
            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );
    }


    /**
     * For filters
     */
    switchFilter(val): void {
        let currentfilter: FilterMgt = this.getFilter(val);

        if (currentfilter.name != undefined) {

            this.message.currentFilter = currentfilter.name;

            // Init filter; check if jsons are valid, or create them !
            let options: Options = {
                reload: false,
                wd: false
            }
            // XXX Only reload 'cleared' filters
            let status = currentfilter.status;
            if (status == "cleared") {
                options.reload = true
            }

            // Load filter
            this.loadFilter(options);

            if (currentfilter.shared) {
                this.filter_dsp = 'shared filter';
                this.message.isShared = true;
            } else {
                this.filter_dsp = 'my filter';
                this.message.isShared = false;
            }

        } else {
            //XXX better UI : use info modal
            console.log('fail to find filter ' + val);
        }
    }

    private getFilter(name: string): FilterMgt {
        return this.message.filterList.find(filter => filter.name === name);
    }

    /**
     * Called by switchFilter
     * Called if settings changed
     */
    loadFilter(options: Options) {

        let reload_filter = false;
        let wd_change = false;

        if (options != null) {
            reload_filter = options.reload;
            wd_change = options.wd;
        }

        // Update filter settings
        let currentfilter = this.getFilter(this.message.currentFilter);

        this.message.elementView = currentfilter.element_view;
        this.message.periodView = currentfilter.time_view;

        this.management_svc.getTimeView(this.role.login).subscribe(
            views => {
                let timeview: TimeFilter = views.find(view => view.filter == currentfilter.time_view);
                if (timeview) {
                    this.message.minTimeFilter = timeview.begin;
                    this.message.maxTimeFilter = timeview.end;
                }
            },
            error => {
                //XXX better UI : use info modal
                console.log('fail to find timeview for filter ' + this.message.currentFilter);
            }
        );

        // Update elements type (for menu)
        this.management_svc.initElementsType(this.role.login, currentfilter);

        // If WD have changed from settings
        if (wd_change) {
            // maybe current filter is WD filter
            // so need to switch to 'all'
            if (currentfilter != undefined && currentfilter.work_days == 1) {
                this.switchFilter('all');
                return; // DO NOT RELOAD FILTER IF WD FILTER
            }
        }

        // Reload filter
        if (reload_filter) {
            // Show progress
            this.message.waiting = true;

            // Check / create json files
            this.json_svc.checkJson(this.role.login, this.message.currentFilter).subscribe(
                complete => {
                    // Reload json
                    this.json_svc.changeJson(this.role.login, this.message.currentFilter);
                    // Update filter status
                    currentfilter.status = "ready";
                },
                error => {
                    // Reload json
                    this.json_svc.changeJson(this.role.login, this.message.currentFilter);
                    // Update filter status
                    currentfilter.status = "failed";
                }
            );
        } else {
            // Reload json
            this.json_svc.changeJson(this.role.login, this.message.currentFilter);
        }

        // Force update threshold & capacity & god settings & greenit settings from database
        this.settings_svc.reload(this.role.login, this.message.currentFilter, IhmSettingsTarget.RECOMMENDATION,
            true);
        this.settings_svc.reload(this.role.login, this.message.currentFilter, IhmSettingsTarget.CAPACITY, true);
        this.settings_svc.reload(this.role.login, this.message.currentFilter, IhmSettingsTarget.GOD, true);

        // FIX ExpressionChangedAfterItHasBeenCheckedError
        this.cd.detectChanges();

    }


    /**
     *
     */
    private addSharedFilter(): void {
        if (this.role.login != "root") {
            this.management_svc.getViewsFromSharedFilter(this.role.login).pipe(first()).subscribe(
                data => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            const view = data[i].name;
                            const filter_id = data[i].id;
                            this.management_svc.testSharedFilter(this.role.login, view).pipe(first()).subscribe(
                                data => {
                                    if (data) {
                                        this.management_svc.getFilterFromId(filter_id).pipe(first())
                                            .subscribe(
                                                data => {
                                                    this.management_svc.addSymLink(this.role.login, data
                                                        .name).pipe(first()).subscribe();
                                                    this.message.filterList.push(data);
                                                }
                                            );
                                    }
                                }
                            );
                        }
                    }
                }
            );
        }
    }

    /**
     * Alerts
     */
    private loadWatcher(): void {
        if (this.addWatcher != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                AlertingwatcherComponent);
            const viewContainerRef = this.addWatcher.viewContainerRef;
            this.alertWatcherComponent = viewContainerRef.createComponent(componentFactory);
        }
    }

    private stopWatcher(): void {
        if (this.alertWatcherComponent != undefined)
            this.alertWatcherComponent.destroy();
    }

    /**
     * Session
     */
    private loadSessionPing(): void {
        let session_interval = interval(60000);
        this.session_subscription = session_interval.subscribe(val => this.checkSession());
    }

    private async checkSession(): Promise < void > {
        let isLogged = await this.authentication_svc.isLogged();
        if (isLogged)
            this.message.isNotLoginPage = true;
        else
            this.logout();
    }

    private stopSessionPing(): void {
        if (this.session_subscription != undefined)
            this.session_subscription.unsubscribe();
    }

    /**
     *
     */
    callAbout(): void {
        this.isModalAbout = true;
    }

    /**
     *
     */
    callDoc(lang: string): void {
        //update documentation href based on the version
        let docBaseUrl = "https://easyvirt.gitlab.io/dcscope/user-guide/v" + this.jsonLoader.version.release + "/" +
            lang;

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = docBaseUrl;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    /**
     * 
     */
    callGreenitSettings(): void {
        this.greenitsettingsChild.displayModalSettings();
    }

  /**
   *
   */
  callSettings(): void {
    this.settingsChild.callSettings();
  }

    /**
     *
     */
    formatDate(time: number, showhour: boolean): string {
        if (!showhour)
            return new Date(time).toLocaleDateString('en-EN');
        else
            return new Date(time).toLocaleString('en-EN');
    }

    /**
     *
     */
    updateFlagColor(): string {
        let alert_max = Math.max(this.license_info.alert, Math.max(this.instance_global_alert, this
            .db_global_alert));
        return StatusColor[alert_max];
    }

    /**
     *
     */
    callLicense(): void {
        this.licenseChild.target = LicenseType.MODAL;
        this.licenseChild.displayModalLicense();
    }

    /**
     *
     */
    callUpload(): void {
        this.uploadChild.target = UploadType.UPDATES;
        this.uploadChild.displayModalUpload();
    }

    /**
     *
     */
    callArchive(): void {
        this.monitorChild.getArchive(this.role);
    }

    /**
     *
     */
    callMonitorList(): void {
        this.monitorChild.getMonitorList();
    }

    /**
     *
     */
    callPlugin(): void {
        //Update plugin properties
        this.pluginChild.update();
    }

    /**
     *
     */
    logout(): void {

        this.filter_dsp = 'my filter';
        /* RESET MESSAGE */
        this.message_svc.resetMessage();

        /* STOP ALERT WATCHER */
        this.stopWatcher();

        /* STOP SESSION PING */
        this.stopSessionPing();

        /* REMOVE SUBSCRIPTION */
        if (this.from_router_subscription != undefined)
            this.from_router_subscription.unsubscribe();

        /* RESET JSON */
        this.json_svc.resetJson();

        /* REMOVE INFO */
        this.license_svc.removeInfo();
        this.settings_svc.removeInfo();
        this.authentication_svc.logout();

        /* ROUTE TO LOGIN */
        this.router.navigate(['login']);

    }
}
