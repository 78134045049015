<app-power-vmware *ngIf="(isVmwareReady && tab==instancetype.VMWARE) || isDcscope"></app-power-vmware>

<app-power-aws *ngIf="isAwsReady && tab==instancetype.AWS"></app-power-aws>

<app-power-azure *ngIf="isAzureReady && tab==instancetype.AZURE"></app-power-azure>

<app-power-gcp *ngIf="isGcpReady && tab==instancetype.GCP"></app-power-gcp>

<app-power-kubernetes [isreload]="isDataReload" *ngIf="isKubernetesReady && tab==instancetype.KUBERNETES"></app-power-kubernetes>

<app-power-scaphandre *ngIf="isScaphReady && tab==instancetype.SCAPHANDRE"></app-power-scaphandre>

<app-power-xclarity *ngIf="isXclarityReady && tab==instancetype.XCLARITY"></app-power-xclarity>

<app-power-openmanage *ngIf="isOpenmanageReady && tab==instancetype.OPENMANAGE"></app-power-openmanage>

<app-power-oneview *ngIf="isOneviewReady && tab==instancetype.ONEVIEW"></app-power-oneview>

<app-power-ipmi *ngIf="isIpmiReady && tab==instancetype.IPMI"></app-power-ipmi>

<app-power-storage *ngIf="isStorageReady && tab==instancetype.STORAGE"></app-power-storage>
