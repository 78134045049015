export enum CO2ScopeMenu {
    OVERVIEW = "Dashboard",
        CO2_EVOLUTION = "CO2 Evolution",
        POWER_GRAPH = "Power Graph",
        GOD = "Graph on Demand",
        POWER_DIST = "Power Distribution",
        POWER_USAGE = "Power Usage",
        REPORT = "Report",
        GREEN_INDEX = "Green-Index",
        OPPORTUNITIES = "Opportunities",
        GREENIT_ANALYSIS = "GreenIT Analysis",
        CAPACITY_PLAN = "Capacity Planning",
        SIMULATION = "Project Simulator",
        CLOUD_COMPARATOR = "Cloud Comparator",
        CLOUD_IMPACT = "Cloud Impact",
        COST = "Cost"
}

export enum CO2ScopeSection {
    MEASURE = "Measure",
        ANALYSE = "Analyse",
        REDUCE = "Reduce"
}
