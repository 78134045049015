import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnInit,
    AfterViewInit,
    ViewChild
} from '@angular/core';

import {
    ShareService
} from '@app/services';

import {
    Message
} from '@app/model';

import {
    TroubleshootingmapComponent
} from '@app/troubleshooting/troubleshootingmap/troubleshootingmap.component';
import {
    TroubleshootingmapDirective
} from '@app/directives/troubleshootingmap.directive';

import {
    TroubleshootinglistComponent
} from '@app/troubleshooting/troubleshootinglist/troubleshootinglist.component';
import {
    TroubleshootinglistDirective
} from '@app/directives/troubleshootinglist.directive';


@Component({
    selector: 'app-troubleshooting',
    templateUrl: './troubleshooting.component.html',
    styleUrls: ['./troubleshooting.component.css']
})
export class TroubleshootingComponent implements AfterViewInit, OnInit {

    @ViewChild(TroubleshootingmapDirective) addMap: TroubleshootingmapDirective;
    @ViewChild(TroubleshootinglistDirective) addList: TroubleshootinglistDirective;

    text_info: string = 'The analysis is performed on the last 30 days';

    message: Message;

    isListView: boolean = false;

    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    ngAfterViewInit(): void {
        this.loadMap();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    manageView(isMap: boolean): void {

        if (isMap) {
            this.isListView = false;
            setTimeout(() => this.loadMap(), 100);
            this.text_info = 'The analysis is performed on the last 30 days';
            this.message.isTroubleMap = true;
        } else {
            this.isListView = true;
            setTimeout(() => this.loadList(), 100);
            this.text_info = 'The analysis is performed on the entire time view of the filter';
            this.message.isTroubleMap = false;
        }
    }

    private loadMap(): void {

        if (this.addMap != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                TroubleshootingmapComponent);
            const viewContainerRef = this.addMap.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadList(): void {

        if (this.addList != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                TroubleshootinglistComponent);
            const viewContainerRef = this.addList.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }
}
