<!-- UPLOAD DIV FOR REPORT -->
<div *ngIf="target==report">
    <button style="float:left" *ngIf="currentUser.login == 'root'" class="btn btn-link btn-sm" (click)="displayModalUpload()">
        Upload your logo
    </button>
</div>
<div *ngIf="target==netdevice">
    <button style="float:left" *ngIf="currentUser.login == 'root'" class="btn btn-link btn-sm" (click)="displayModalUpload()">
        Upload your network device file
    </button>
</div>
<div *ngIf="target==stodevice">
    <button style="float:left" *ngIf="currentUser.login == 'root'" class="btn btn-link btn-sm" (click)="displayModalUpload()">
        Upload your storage device file
    </button>
</div>
<div *ngIf="target==view">
    <div class="clr-row">
        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
            <button style="margin-top:20px;margin-bottom:50px;color:black" class="btn btn-sm btn-info-outline" (click)="fileinput.click()">
                <cds-icon shape="folder-open"></cds-icon>
                <span>&nbsp;&nbsp;Select file ...&nbsp;&nbsp;</span>
                <input #fileinput type="file" (change)="init($event)" style="display:none;" />
            </button>
            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                <clr-progress-bar [ngClass]="upload_progress_class" [clrValue]="upload_progress" style="width: 48%;margin-left: 10px;"></clr-progress-bar>
            </div>
            <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                <form (ngSubmit)="upload()">
                    <div style="text-align:center;margin-top:30px;">
                        <div *ngIf="upload_progress_message">
                            <span *ngIf="upload_progress_class.includes('danger')" style="color:#991700">
                                <cds-icon size="24" shape="exclamation-circle"></cds-icon>
                                <b>{{upload_progress_message}}</b>
                            </span>
                            <span *ngIf="upload_progress_class.includes('success')" style="color:#306b00">
                                <cds-icon size="24" shape="check-circle"></cds-icon>
                                <b>{{upload_progress_message}}</b>
                            </span>
                            <span *ngIf="upload_progress_class.includes('init')" style="color:black">
                                <b>{{upload_progress_message}}</b>
                            </span>
                        </div>
                        <button [clrLoading]="upload_button_state" style="margin-top:30px" *ngIf="upload_button_ready" class="btn  btn-block btn-primary" aria-label="upload">
                            <cds-icon shape="upload"></cds-icon>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
            <button class="btn btn-sm btn-info-outline" (click)="type_modal = true">
                <cds-icon shape="help-info"></cds-icon>
                type help
            </button>
            <div class="card" style="float: right;">
                <div class="card-header card-ajust">
                    <h6>
                        &nbsp;CSV file example
                    </h6>
                </div>
                <div class="card-block">
                    <pre>
                        <code>
                            name,type
                            esxi-1,server
                            vm-2,vm
                            Ruckus R510,wifiAccessPoints
                            node-1,openmanage_node
                        </code>
                    </pre>
                </div>
            </div>
        </div>
    </div>
</div>

<clr-modal [(clrModalOpen)]="type_modal" [clrModalClosable]=true >
    <div class="modal-body" style="overflow:auto">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    &nbsp;Accepted types
                </h5>
            </div>
            <div class="card-block">
                <div class="card-text">
                    <clr-datagrid>
                        <clr-dg-column [clrDgField]="'DEVICE'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Device</ng-container></clr-dg-column>
                        <clr-dg-column [clrDgField]="'TYPE'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
                        <clr-dg-row>
                            <clr-dg-cell>VMware ESX</clr-dg-cell>
                            <clr-dg-cell>server</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>VMware virtual machine</clr-dg-cell>
                            <clr-dg-cell>vm</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Openmanage node</clr-dg-cell>
                            <clr-dg-cell>openmanage_node</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Xclarity node</clr-dg-cell>
                            <clr-dg-cell>xclarity_node</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Oneview node</clr-dg-cell>
                            <clr-dg-cell>oneview_node</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Scaphandre node</clr-dg-cell>
                            <clr-dg-cell>scaphandre_node</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Azure subscription</clr-dg-cell>
                            <clr-dg-cell>azure-subscription</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Azure region</clr-dg-cell>
                            <clr-dg-cell>az-region</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Azure instance</clr-dg-cell>
                            <clr-dg-cell>azure-instance</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Aws account</clr-dg-cell>
                            <clr-dg-cell>aws-account</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Aws region</clr-dg-cell>
                            <clr-dg-cell>aws-region</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Aws instance</clr-dg-cell>
                            <clr-dg-cell>aws-instance</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Kubernetes cluster</clr-dg-cell>
                            <clr-dg-cell>kubernetes-cluster</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Kubernetes node</clr-dg-cell>
                            <clr-dg-cell>kubernetes-node</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Kubernetes pod</clr-dg-cell>
                            <clr-dg-cell>kubernetes-pod</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Firewall</clr-dg-cell>
                            <clr-dg-cell>firewall</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Load balancer</clr-dg-cell>
                            <clr-dg-cell>loadBalancer</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Router</clr-dg-cell>
                            <clr-dg-cell>router</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Switch</clr-dg-cell>
                            <clr-dg-cell>switch</clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-row>
                            <clr-dg-cell>Wifi access point</clr-dg-cell>
                            <clr-dg-cell>wifiAccessPoint</clr-dg-cell>
                        </clr-dg-row>
                    </clr-datagrid>
                </div>
            </div>
        </div>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="upload_modal" [clrModalClosable]=false [clrModalSize]="'ll'">
    <h3 *ngIf="target==updates" class="modal-title">Upload your update</h3>
    <h3 *ngIf="target==report" class="modal-title">Upload your logo</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
           
            <button style="margin-top:20px;margin-bottom:50px;color:black" class="btn btn-sm btn-info-outline" (click)="fileinput.click()">
                <cds-icon shape="folder-open"></cds-icon>
                <span>&nbsp;&nbsp;Select file ...&nbsp;&nbsp;</span>
                <input #fileinput type="file" (change)="init($event)" style="display:none;" />
            </button>

            <clr-progress-bar [ngClass]="upload_progress_class" [clrValue]="upload_progress" clrMax="100"></clr-progress-bar>

            <div *ngIf="target==updates" style="margin-top:15px;"><b>Accepted file type : .tar.gz</b></div>
            <div *ngIf="target==report" style="margin-top:15px;"><b>Accepted file type : .jpg .jpeg .png</b></div>
            <div *ngIf="target==netdevice || target==stodevice" style="margin-top:15px;"><b>Accepted file type : .csv</b></div>
            <div *ngIf="target==netdevice || target==stodevice" style="margin-top:15px;"><b>File header format : name,model,manufacturer,type,power</b></div>
            <div *ngIf="target==netdevice || target==stodevice" style="margin-top:15px;"><b>Field delimiter : ,</b></div>

            <form (ngSubmit)="upload()">
                <div style="text-align:center;margin-top:30px;">
                    <div *ngIf="upload_progress_message">
                        <span *ngIf="upload_progress_class.includes('danger')" style="color:#991700">
                            <cds-icon size="24" shape="exclamation-circle"></cds-icon>
                            <b>{{upload_progress_message}}</b>
                        </span>
                        <span *ngIf="upload_progress_class.includes('success')" style="color:#306b00">
                            <cds-icon size="24" shape="check-circle"></cds-icon>
                            <b>{{upload_progress_message}}</b>
                        </span>
                        <span *ngIf="upload_progress_class.includes('init')" style="color:black">
                            <b>{{upload_progress_message}}</b>
                        </span>
                    </div>
                    <button [clrLoading]="upload_button_state" style="margin-top:30px" *ngIf="upload_button_ready" class="btn  btn-block btn-primary" aria-label="upload">
                        <cds-icon shape="upload"></cds-icon>
                    </button>
                </div>
            </form>

            <hr *ngIf="target == report">
                        
            <div *ngIf="target == report" style="text-align:center;">
                <b>Custom logo uploaded : &nbsp;&nbsp;</b>
                <cds-icon *ngIf="!custom_logo" shape="times" style="color:#991700" size="36"></cds-icon>
                <!-- <cds-icon *ngIf="custom_logo" shape="check" style="color:#306b00" size="36"></cds-icon> -->
                <img *ngIf="custom_logo" [src]="url_logo">
                <cds-icon *ngIf="custom_logo" (click)="delete_modal_logo = true" style="cursor:pointer;margin-left:30px;"shape='trash' size="15"></cds-icon>
            </div>

        </div>
        
    </div>
    <div class="modal-footer">
	    <button id="upload-close" [disabled]="!upload_close_button" type="button" class="btn btn-primary" (click)="upload_modal = false">Close</button>
    </div>
</clr-modal>

<!-- REPORT LOGO DELETE -->
<clr-modal [(clrModalOpen)]="delete_modal_logo" [clrModalClosable]=false>
    <h3 class="modal-title">Delete logo</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>Are you sure you want to delete current logo ?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-link" (click)="delete_modal_logo = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="deleteLogo()">Delete</button>
    </div>
</clr-modal>
