import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[vm-info-detail]'
})
export class VminfodetailDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
