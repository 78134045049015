<h2>Storage overview</h2>

<div class="clr-row">
    <div class="clr-col-10">
        <span>
            <cds-icon shape="process-on-vm"></cds-icon>
            <h4 style="display: inline; margin-left: 15px;">Snapshots</h4>
            <!-- <span style="margin-left: 10px;" class="label label-info">(Click on a snapshot name to display the list)</span> -->
        </span>
    </div>
    <div class="clr-col-2">
        <clr-dropdown>
            <button class="btn btn-outline-primary" clrDropdownTrigger>
                Export
                <cds-icon shape="angle" direction="down"></cds-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen [clrPosition]="'bottom-right'">
                <label class="dropdown-header" aria-hidden="true">CSV file</label>
                <div clrDropdownItem><button class="btn" (click)="exportSnapshots()">All snapshots</button></div>
            </clr-dropdown-menu>
        </clr-dropdown>
    </div>
</div>

<div class="datagrid-limit-height">
	<clr-datagrid>
		<clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Virtual machine</ng-container></clr-dg-column>
		<clr-dg-column [clrDgField]="'snapshots'"><ng-container *clrDgHideableColumn="{hidden: false}">Snapshot number</ng-container></clr-dg-column>
		<clr-dg-column [clrDgField]="'snaphotName'"><ng-container *clrDgHideableColumn="{hidden: false}">Snapshot name</ng-container></clr-dg-column>
		<clr-dg-column [clrDgField]="'status'"><ng-container *clrDgHideableColumn="{hidden: false}">Status</ng-container></clr-dg-column>
		<clr-dg-column [clrDgField]="'size'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Size (GB)</ng-container></clr-dg-column>
		<clr-dg-row *clrDgItems="let vm of vm_snapshot" [clrDgItem]="vm" clickable>
			<clr-dg-cell>{{vm.name}}</clr-dg-cell>
			<clr-dg-cell>{{vm.snapshots}}</clr-dg-cell>
			<clr-dg-cell><a ngClass="addLink" (click)="callUrl(vm)">{{vm.snaphotName}}</a></clr-dg-cell>
			<clr-dg-cell>{{vm.status}}</clr-dg-cell>
			<clr-dg-cell>{{vm.size.toFixed(2)}}</clr-dg-cell>
		</clr-dg-row>
		<clr-dg-footer>
			<clr-dg-pagination #pagination [clrDgPageSize]="10">
				<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
					entries per page
				</clr-dg-page-size>
				{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
			</clr-dg-pagination>
		</clr-dg-footer>
	</clr-datagrid>
</div>
