import {
    AfterViewInit,
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    Json,
    JSONTarget,
    Message,
    User,
    VmSynthesis
} from '@app/model';

import {
    commaBigNumber,
    getUserCurrency
} from '../../assets/js/tools';
import {
    zip
} from 'rxjs';


@Component({
    selector: 'app-t11',
    templateUrl: './t11.component.html',
    styleUrls: ['./t11.component.css']
})
export class T11Component implements AfterViewChecked, AfterViewInit, OnInit {

    message: Message;

    jsonLoader: Json;

    math = Math;

    isReady: boolean = false;

    isNoData: boolean = true;

    globalCurrency: string;

    reco_meta_data: any = [];

    co2ToAvoid: number = 0;

    costToAvoid: number = 0;

    reco_vms_data: any = [];

    cost_reclaim: number = 0;

    private data_cost: any = [];

    private data_vm: any = [];

    private greenit_reco_data: any;

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private message_svc: ShareService,
        private json_svc: JsonloaderService,
        private cd: ChangeDetectorRef) {}

    /**
     * 
     */
    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        this.json_svc.currentJson.subscribe(
            json => {
                this.jsonLoader = json;

                if (this.jsonLoader.vmSynthesis != undefined) {
                    this.data_vm = this.jsonLoader.vmSynthesis;

                    this.data_cost = this.jsonLoader.costAllocated;

                    let reco_meta = this.json_svc.getData(this.currentUser.login, this.message.currentFilter,
                        JSONTarget.RECO_META);
                    let zip_reco_vm = this.json_svc.getData(this.currentUser.login, this.message.currentFilter,
                        JSONTarget.RECO_VM);
                    let reco_greenit = this.json_svc.getData(this.currentUser.login, this.message.currentFilter,
                        JSONTarget.GREENIT_RECO);

                    zip(reco_meta, zip_reco_vm, reco_greenit).subscribe(
                        all => {
                            this.reco_meta_data = all[0];
                            this.reco_vms_data = all[1];
                            this.greenit_reco_data = all[2];

                            if (this.greenit_reco_data != undefined) {
                                this.co2ToAvoid = this.co2ToAvoid + this.greenit_reco_data
                                    .RECO_RESIZE_VCPU_CO2;
                                this.co2ToAvoid = this.co2ToAvoid + this.greenit_reco_data
                                    .RECO_RESIZE_RAM_CO2;
                            }

                            this.isNoData = false;

                            this.buildData();
                        }
                    );
                }
            }
        );

    }

    ngAfterViewInit(): void {}

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    private buildData(): void {

        let cost_vcpus: number = 0;
        let cost_vrams: number = 0;
        let cost_vdisks: number = 0;

        for (var i = 0; i < this.reco_vms_data.length; i++) {
            if (this.reco_vms_data[i].type == "VM") {
                const vm: VmSynthesis = this.getVmData(this.reco_vms_data[i].uuid);
                const vm_costs: any = this.getVmCost(this.reco_vms_data[i].uuid);
                let cost_vcpu: number = 0;
                let cost_vram: number = 0;
                let cost_vdisk: number = 0;
                if (vm.vcpu > 0)
                    cost_vcpu = vm_costs.cpu_cost / vm.vcpu;
                if (vm.vram > 0)
                    cost_vram = vm_costs.ram_cost / vm.vram;
                if (vm.ds > 0)
                    cost_vdisk = vm_costs.sto_cost / vm.ds;

                if (this.reco_vms_data[i].reco_cpu < 0)
                    cost_vcpus += this.math.abs(cost_vcpu * this.reco_vms_data[i].reco_cpu);
                else if (this.reco_vms_data[i].reco_cpu > 0)
                    cost_vcpus -= cost_vcpu * this.reco_vms_data[i].reco_cpu;

                if (this.reco_vms_data[i].reco_ram < 0)
                    cost_vrams += this.math.abs(cost_vram * this.reco_vms_data[i].reco_ram);
                else if (this.reco_vms_data[i].reco_ram > 0)
                    cost_vrams -= cost_vram * this.reco_vms_data[i].reco_ram;

                if (this.reco_vms_data[i].disk_saved > 0)
                    cost_vdisks += cost_vdisk * this.reco_vms_data[i].disk_saved;
            }
        }
        this.cost_reclaim = commaBigNumber(this.math.round(cost_vcpus + cost_vrams + cost_vdisks));
    }

    private getVmData(uuid: string): VmSynthesis {
        return this.data_vm.find(vm => vm.uuid === uuid);
    }

    private getVmCost(uuid: string): any {
        return this.data_cost.find(vm => vm.uuid === uuid);
    }
}
