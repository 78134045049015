<div class="clr-row">
    <div class="clr-col-7">
        <div class="card">
            <div class="card-header card-ajust" style="padding-top:0;height:100px;">
                <div class="clr-row">
                    <div class="clr-col-8">
                        <h5><cds-icon shape="vm"></cds-icon>&nbsp;Estimations for carbon footprint reductions associated to the resizing and deletion of virtual machines</h5>
                    </div>
                    <div class="clr-col-4">
                        <h5 style="font-size:0.75rem;float:right;">
                            <fa-icon [icon]="['fas', 'leaf']" style="margin-right:15px"></fa-icon>
                            <span style="font-size:0.9rem;" [style.color]="total_resize_co2 <= 0 ? '#306b00' : '#991700'"><b *ngIf="total_resize_co2 > 0">+</b><b>{{total_resize_co2}}</b></span>
                            <span>&nbsp;kg CO2eq&nbsp;</span>
                             <clr-signpost style="margin-left:5px" class="grey-signpost">
                                <clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen class="info-signpost">
                                    <p>An estimate of embodied carbon emissions computed from vCPU and RAM resizing and deletions.</p>
                                    <p style="margin-top:20px"><cds-icon size="24" shape="help-info"></cds-icon><b>The objective is to highlight the carbon emissions that can be avoided by resizing or deletion (and therefore not purchasing new servers).</b></p>
                                </clr-signpost-content>
                            </clr-signpost>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="card-block" style="height:620px;overflow-y:auto;">
                <clr-datagrid>
                    <clr-dg-action-bar>
                        <div class="btn-group" style="float:right">
                            <button type="button" class="btn btn-sm btn-link" (click)="exportResize()">
                                Export to CSV
                            </button>
                        </div>
                    </clr-dg-action-bar>
                    <clr-dg-column [clrDgField]="'NAME'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'HISTORY_ACTION'"><ng-container *clrDgHideableColumn="{hidden: false}">Operation</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'HISTORY_DATE'"><ng-container *clrDgHideableColumn="{hidden: false}">Date</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'HISTORY_RESIZE_VCPU'"><ng-container *clrDgHideableColumn="{hidden: false}">VCPU action</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'HISTORY_RESIZE_RAM'"><ng-container *clrDgHideableColumn="{hidden: false}">RAM (MB) action</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'HISTORY_RESIZE_VCPU_CO2'">
                        <ng-container *clrDgHideableColumn="{hidden: false}">Carbon emission VCPU (kg CO2eq)</ng-container>
                        <!-- <clr-signpost style="margin-left:5px">
                            <clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen class="info-signpost">
                                <p>An estimate of embodied carbon emissions computed from vCPU resizing.</p>
                                <p style="margin-top:20px"><cds-icon size="24" shape="help-info solid"></cds-icon><b>The objective is to highlight the carbon emissions that can be avoided by resizing or deletion (and therefore not purchasing new servers).</b></p>
                            </clr-signpost-content>
                        </clr-signpost> -->
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'HISTORY_RESIZE_RAM_CO2'">
                        <ng-container *clrDgHideableColumn="{hidden: false}">Carbon emission RAM (kg CO2eq)</ng-container>
                        <!-- <clr-signpost style="margin-left:5px">
                            <clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen class="info-signpost">
                                <p>An estimate of embodied carbon emissions computed from RAM resizing.</p>
                                <p style="margin-top:20px"><cds-icon size="24" shape="help-info solid"></cds-icon><b>The objective is to highlight the carbon emissions that can be avoided by resizing or deletion (and therefore not purchasing new servers).</b></p>
                            </clr-signpost-content>
                        </clr-signpost> -->
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'HISTORY_RESIZE_TOTAL_CO2'" [clrDgSortOrder]="dataOrder">
                        <ng-container *clrDgHideableColumn="{hidden: false}">Carbon emission TOTAL (kg CO2eq)</ng-container>
                        <clr-signpost style="margin-left:5px" class="grey-signpost">
                            <clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen class="info-signpost">
                                <p>An estimate of embodied carbon emissions computed from vCPU and RAM resizing and deletions.</p>
                                <p style="margin-top:20px"><cds-icon size="24" shape="help-info"></cds-icon><b>The objective is to highlight the carbon emissions that can be avoided by resizing or deletion (and therefore not purchasing new servers).</b></p>
                            </clr-signpost-content>
                        </clr-signpost>
                    </clr-dg-column>         
                    <clr-dg-row *clrDgItems="let data of resize_data" [clrDgItem]="data">
                        <clr-dg-cell>{{data.NAME}}</clr-dg-cell>
                        <clr-dg-cell>{{toLower(data.HISTORY_ACTION)}}</clr-dg-cell>
                        <clr-dg-cell>{{formatDate(data.HISTORY_DATE,false)}}</clr-dg-cell>
                        <clr-dg-cell>{{data.HISTORY_RESIZE_VCPU}}</clr-dg-cell>
                        <clr-dg-cell>{{data.HISTORY_RESIZE_RAM}}</clr-dg-cell>
                        <clr-dg-cell [style.color]="data.HISTORY_RESIZE_VCPU_CO2 <= 0 ? '#306b00' : '#991700'">{{formatNumber(data.HISTORY_RESIZE_VCPU_CO2)}}</clr-dg-cell>
                        <clr-dg-cell [style.color]="data.HISTORY_RESIZE_RAM_CO2 <= 0 ? '#306b00' : '#991700'">{{formatNumber(data.HISTORY_RESIZE_RAM_CO2)}}</clr-dg-cell>
                        <!--<clr-dg-cell [style.color]="data.HISTORY_RESIZE_TOTAL_CO2 <= 0 ? '#306b00' : '#991700'">{{formatNumber(data.HISTORY_RESIZE_TOTAL_CO2)}}</clr-dg-cell>-->
                        <clr-dg-cell [style.color]="data.HISTORY_RESIZE_TOTAL_CO2 <= 0 ? '#306b00' : '#991700'">{{formatNumbers(data.HISTORY_RESIZE_VCPU_CO2, data.HISTORY_RESIZE_RAM_CO2)}}</clr-dg-cell>
                    </clr-dg-row>              
                    <clr-dg-footer>
                        <clr-dg-pagination #resize_page [clrDgPageSize]="10">
                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                            {{resize_page.firstItem + 1}} - {{resize_page.lastItem + 1}} of {{resize_page.totalItems}} entries
                        </clr-dg-pagination>
                    </clr-dg-footer>
                </clr-datagrid>
            </div>
        </div>
    </div>
    <div class="clr-col-5">
        <div class="card">
            <div class="card-header card-ajust" style="padding-top:0;height:100px;">
                <div class="clr-row">
                    <div class="clr-col-7">
                        <h5><cds-icon shape="bar-chart"></cds-icon>&nbsp;Embodied carbon repartition</h5>
                    </div>
                    <div class="clr-col-5">
                        <h5 style="font-size:0.75rem;float:right;">
                            <!-- <span>Evolution from previous month :</span>
                            <fa-icon [icon]="['fas', 'arrow-up']" [class]="last_monthy_arrow_class" style="font-size:1.1rem;display:inline-block;margin-left:10px" [style.color]="last_monthy_arrow_class == 'fa-rotate-45' ? '#991700' : '#306b00'"></fa-icon>
                             --><clr-signpost style="margin-left:15px" class="grey-signpost">
                                <clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen class="info-signpost">
                                    <p>This value represents the "unused" embodied carbon, which is not assigned to virtual machines.</p>
                                    <p style="margin-top:20px"><cds-icon size="24" shape="help-info"></cds-icon><b>The objective is to highlight the proper use of servers so that their carbon impact is as much as possible associated with VMs.</b></p>
                                </clr-signpost-content>
                            </clr-signpost>
                        </h5>
                    </div>
                </div>
            </div>

            <div class="card-block" style="height:620px;">
                <div *ngIf="greenit_alert" class="alert alert-info" role="alert">
                    <div class="alert-items">
                        <div class="alert-item static">
                            <div class="alert-icon-wrapper">
                                <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                            </div>
                            <span class="alert-text">If you have just created your filter, data will be available tomorrow</span>
                        </div>
                    </div>
                </div>
                <div [ngStyle]="{'margin-top': greenit_alert ? '0px' : '0px'}">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;" ></highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>


