<div class="card">
	<div class="card-header card-adjust">
		<span class="export"></span>
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<span class="search"></span>
	</div>
	<div class="card-block">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  			<thead>
    				<tr>
      					<th>NAME</th>
      					<th>FIXED cost ({{globalCurrency}})</th>
      					<th>TAGS cost ({{globalCurrency}})</th>
      					<th>CPU cost ({{globalCurrency}})</th>
      					<th>RAM cost ({{globalCurrency}})</th>
      					<th>STORAGE cost ({{globalCurrency}})</th>
      					<th>TOTAL cost ({{globalCurrency}})</th>
				</tr>
  			</thead>
			<tbody>
    				<tr *ngFor="let vm of data_cost">
					<td>{{vm.name}}</td>
					<td>{{vm.server_cost}}</td>
					<td>{{vm.tag_cost.toFixed(2)}}</td>
					<td>{{vm.cpu_cost.toFixed(2)}}</td>
					<td>{{vm.ram_cost.toFixed(2)}}</td>
					<td>{{vm.sto_cost.toFixed(2)}}</td>
					<td>{{vm.total_cost.toFixed(2)}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
