import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    InstanceType,
    Message,
    User
} from '@app/model';
import {
    PowerGraphComponent
} from '../power-graph/power-graph.component';
import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faPlug
} from '@fortawesome/free-solid-svg-icons';
import {
    AccountService,
    GreenitService,
    ShareService
} from '@app/services';

@Component({
    selector: 'app-power-storage',
    templateUrl: './power-storage.component.html',
    styleUrls: ['./power-storage.component.css']
})
export class PowerStorageComponent implements OnInit {

    @ViewChild('powerGraph') powerGraph: PowerGraphComponent;

    targetInfrastructure: string = InstanceType.STORAGE;

    message: Message;

    currentUser: User;

    allElements: any[] = [];

    selectedElements: string[] = [];

    /**
     * 
     */
    constructor(private library: FaIconLibrary, private greenit_svc: GreenitService,
        private authenticationService: AccountService, private message_svc: ShareService) {
        this.library.addIcons(faPlug);
    }

    /**
     * 
     */
    async ngOnInit(): Promise < void > {
        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        let data = await this.greenit_svc.getStorageElementsList(this.currentUser.login, this.message
            .currentFilter);

        if (( < any > data).length > 0) {
            this.allElements = data;
            this.selectedElements.push(data[0]);
            setTimeout(() => {
                this.updateChart();
            }, 100);
        }
    }

    reset(): void {
        setTimeout(() => {
            this.updateChart();
        }, 100);

    }

    updateChart(): void {
        if (this.powerGraph != undefined) {
            setTimeout(() => {
                this.powerGraph.updateChart();
            }, 100);
        }
    }
}
