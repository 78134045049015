import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-t8]'
})
export class T8Directive {

    constructor(public viewContainerRef8: ViewContainerRef) {}

}
