<div class="clr-row">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="line-chart"></cds-icon>&nbsp;Power Graph
                </h5>

                 <clr-toggle-container style="flex-direction:row;float:right">
                    <clr-toggle-wrapper style="margin-bottom:20px;margin-right:20px;">
                        <input type="checkbox" clrToggle [(ngModel)]="isAll" (ngModelChange)="checkAll()" [disabled]="isAllDisable" />
                        <label>
                            <span class="btn btn-sm btn-link" style="margin:0px">all hosts</span>
                        </label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>

                <app-power-combobox (onChange)="reset();" [allElements]="allElements" [(selectedElements)]="selectedElements" [targetInfrastructure]="targetInfrastructure" style="width:100%;display:inline-block;"></app-power-combobox>
            </div>

            <div class="card-block" style="width:100%;display:block;">
                <app-power-graph [selectedElements]="selectedElements" [targetInfrastructure]="targetInfrastructure" #powerGraph></app-power-graph>
            </div>
        </div>
    </div>
</div>