import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faPlug
} from '@fortawesome/free-solid-svg-icons';

import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);

import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offline(Highcharts);

import {
    PowerGraphComponent
} from '@app/greenit/power/power-graph/power-graph.component';

import {
    AccountService,
    GreenitService,
    ShareService
} from '@app/services';
import {
    InstanceType,
    Message,
    User
} from '@app/model';

@Component({
    selector: 'app-power-openmanage',
    templateUrl: './power-openmanage.component.html',
    styleUrls: ['./power-openmanage.component.css']
})
export class PowerOpenmanageComponent implements OnInit {

    @ViewChild('powerGraph') powerGraph: PowerGraphComponent;

    targetInfrastructure: string = InstanceType.OPENMANAGE;

    message: Message;

    currentUser: User;

    allElements: any[] = [];

    selectedElements: string[] = [];

    isAllDisable: boolean = false;

    isAll: boolean = false;

    /**
     * 
     */
    constructor(private library: FaIconLibrary, private greenit_svc: GreenitService,
        private authenticationService: AccountService, private message_svc: ShareService) {
        this.library.addIcons(faPlug);
    }

    /**
     * 
     */
    async ngOnInit(): Promise < void > {
        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        let data = await this.greenit_svc.getOpenmanageElementsList(this.currentUser.login, this.message
            .currentFilter);

        if (( < any > data).length > 0) {
            this.allElements = data;
            this.selectedElements.push(data[0]);
            setTimeout(() => {
                this.updateChart();
            }, 100);
        }
    }

    reset(): void {
        this.isAll = false;
        this.isAllDisable = false;

        setTimeout(() => {
            this.updateChart();
        }, 100);

    }

    updateChart(): void {
        if (this.powerGraph != undefined) {
            setTimeout(() => {
                this.powerGraph.updateChart();
            }, 100);
        }
    }

    /**
     * 
     */
    checkAll() {
        // Reset
        this.selectedElements = [];

        if (this.isAll) {
            // Add all hosts
            this.selectedElements.push.apply(this.selectedElements, this.allElements);
        }

        setTimeout(() => {
            this.updateChart();
        }, 100);
    }
}
