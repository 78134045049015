import {
    Component,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    HeatmapComboboxComponent
} from "@app/netscope/netscope-heatmap-viewer/heatmap-combobox/heatmap-combobox.component";
import {
    of
} from "rxjs";
import {
    NetscopeResource,
    ResourceWithSide,
    SharedFilterOptions
} from "@app/netscope/netscope-flows-datagrid/netscope-flows-datagrid.component";


@Component({
    selector: 'resource-filter',
    templateUrl: './resource-filter.component.html',
    styleUrls: ['./resource-filter.component.css']
})
export class ResourceFilterComponent implements OnInit {

    options;
    @Input()
    label;

    @Input()
    side = "source";

    @Input()
    items: any[];

    @Input()
    sharedOptions: SharedFilterOptions = new SharedFilterOptions();

    @Input()
    tableUpdate: EventEmitter < any > ;

    allResources = [];

    selected;
    displayedSelectedResources: any[] = [];
    selectedResources: ResourceWithSide[] = [];

    changes: any = new EventEmitter < any > (false);

    manualRefresh: EventEmitter < void > = new EventEmitter < void > ();

    disableEventPropagation = () => {
        setTimeout(() => {
            // @ts-ignore
            for (let element of document.getElementsByClassName("clr-combobox-options")) {
                element.onclick = (event) => {
                    event.stopPropagation();
                }
            }
        }, 200);
    }

    ngOnInit() {
        this.initFilter();

        // If table data is not dynamic, there is no need to provide an event emitter
        if (this.tableUpdate != undefined) {
            this.tableUpdate.subscribe(items => {
                this.items = items;
                this.initFilter();
                this.manualRefresh.emit();
            });
        }
    }

    initFilter = () => {
        let resourcesIdx = {};

        if (this.items !== undefined) {
            for (let flowDetail of this.items) {
                let resource = undefined;
                if (this.side === "source") {
                    resource = flowDetail.source;
                }
                if (this.side === "destination") {
                    resource = flowDetail.target;
                }
                if (resource !== undefined && resourcesIdx[resource.uuid] === undefined) {
                    resourcesIdx[resource.uuid] = resource;
                }
            }
        }

        this.allResources = Object.values(resourcesIdx);
    }

    triggerEvent() {
        this.changes.emit(true);
    }

    fetchResources = (filter = '', combobox: HeatmapComboboxComponent) => {
        combobox.loadingResources = true;

        let resources = this.allResources
            .filter((o) => o !== undefined)
            // @ts-ignore
            .filter((o) => o.uuid.indexOf(filter) !== 1 || o.name.indexOf(filter) !== -1);

        combobox.asyncResources$ = of(resources);
        combobox.loadingResources = false;
    }

    accepts(item: any) {
        let selectedResourceIndex = {};
        for (let resource of this.sharedOptions.selectedResources) {
            selectedResourceIndex[resource.resource.uuid] = resource;
        }

        if (this.isActive() && this.sharedOptions.selectedResources.length > 0) {

            for (let resourceSide of [{
                    resource: item.source,
                    side: "source"
                }, {
                    resource: item.target,
                    side: "destination"
                }]) {
                if (resourceSide.resource !== undefined && resourceSide.resource.uuid !== undefined &&
                    selectedResourceIndex[resourceSide.resource.uuid] !== undefined) {
                    let resourceWithSide = selectedResourceIndex[resourceSide.resource.uuid];

                    if (resourceWithSide.side === "source or destination" || resourceWithSide.side === resourceSide
                        .side) {
                        return true;
                    }
                }
            }

            return false;
        } else {
            return true;
        }
    }

    removeResource = (resource) => {
        this.sharedOptions.selectedResources = this.sharedOptions.selectedResources.filter((otherResource) =>
            resource.uuid !== otherResource.resource.uuid);

        if (this.sharedOptions.selectedResources.length === 0) {
            this.sharedOptions.filterIsActive = false;
        }

        this.triggerEvent();
    }

    toggleSide = (resourceWithSide: ResourceWithSide) => {
        if (resourceWithSide.side === "source") {
            resourceWithSide.side = "destination";
        } else {
            if (resourceWithSide.side === "destination") {
                resourceWithSide.side = "source or destination";
            } else {
                resourceWithSide.side = "source";
            }
        }

        this.triggerEvent();
    }

    resourceSelectionChanged = (event) => {
        let newResourcesWithSide: ResourceWithSide[] = event.model.map((resource) => {
            let result = new ResourceWithSide();
            result.side = this.side;
            result.resource = resource;
            return result;
        });
        this.sharedOptions.selectedResources.push(...newResourcesWithSide);
        event.model = [];
        this.triggerEvent();
    }

    isActive(): boolean {
        if (this.sharedOptions === undefined) {
            return false;
        }
        return this.sharedOptions.filterIsActive;
    }
}

