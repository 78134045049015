import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    ClrCombobox,
    ClrDatagridSortOrder,
    ClrDatagridStringFilterInterface,
    ClrSignpostContent
} from "@clr/angular";
import {
    DCNetscopeResource,
    DCNetscopeResourceWithName, DomainResolution,
    FailureGetClustersWithFiltersResponse,
    FailureGetDailyTimestampResponse,
    FailureGetExternalIpsResponse, FailureGetInfrastructureVersionsBetweenResponse,
    GetAppsGroupsResponse,
    GetClustersWithFiltersResponse,
    GetDailyTimestampResponse,
    GetDomainResolutionResponse,
    GetExternalIpsResponse, GetInfrastructureVersionsBetweenResponse, HostSystem, InfrastructureVersion,
    LicenseService,
    NetscopeService,
    SuccessGetAppsGroupsResponse,
    SuccessGetClustersWithFiltersResponse,
    SuccessGetDailyTimestampResponse,
    SuccessGetDomainResolutionResponse,
    SuccessGetExternalIpsResponse, SuccessGetInfrastructureVersionsBetweenResponse, TemporalParameter, VirtualMachine
} from "@app/services";
import {
    ActivatedRoute,
    NavigationEnd,
    Router
} from "@angular/router";
import {
    take
} from "rxjs/operators";
import {
    environment
} from "@environments/environment";
import * as d3 from "d3";
import {
    Location
} from '@angular/common';
import {
    HeatmapHighchartsHeatmapComponent
} from "@app/netscope/netscope-heatmap-viewer/heatmap-highcharts-heatmap/heatmap-highcharts-heatmap.component";

import {
    DependenciesViewerRenderer
} from "@app/netscope/netscope-common-flowview/renderers/DependenciesViewerRenderer";
import {
    ProtocolAnalysisRenderer
} from "@app/netscope/netscope-common-flowview/renderers/ProtocolAnalysisRenderer";
import {
    ClustersViewerRenderer
} from "@app/netscope/netscope-common-flowview/renderers/ClustersViewerRenderer";
import {
    AppsGroupsRenderer
} from "@app/netscope/netscope-common-flowview/renderers/AppsGroupsRenderer";
import {
    filter
} from 'rxjs/operators';
import {
    FlowDetail
} from '@app/netscope/netscope-flows-datagrid/netscope-flows-datagrid.component';

import {of} from "rxjs";
import {
    NetscopeCommonFlowviewComboboxComponent
} from "@app/netscope/netscope-common-flowview/netscope-common-flowview-combobox/netscope-common-flowview-combobox.component";

import { ShepherdService } from 'angular-shepherd';
import {generateFlowComponentShepperdParameters} from "@app/netscope/netscope-common-flowview/data/shepherd-data";
import {TranslocoService} from "@ngneat/transloco";

export class GraphDataObject {
    links: FlowDetail[] = [];
    protocols = [];
    vms = [];
    routers = [];
    clusters = [];
    external_ips = [];
    unknown_ips = []
}

export class VirtualMachineWithAppsGroups {
    name: string;
    uuid: string;
    apps_groups: any[];
    type = "vm";

    constructor(uuid, name, apps_groups) {
        this.uuid = uuid;
        this.name = name;
        this.apps_groups = apps_groups;
    }
}

function getShortUuid(o: DCNetscopeResourceWithName): string {
    if (o.uuid.indexOf(":") != -1) {
        let uuidParts = o.uuid.split(":");
        if (uuidParts.length >= 2) {
            return uuidParts[1];
        }
    }
    return this.uuid;
}

export class ComputingResourceCollection<T extends DCNetscopeResourceWithName> extends Array<T> {
    generateShortUuids(): Array<string> {
        return this.map((resource) => getShortUuid(resource));
    }
}

export class ComboboxSelectionItem {
    resource: DCNetscopeResourceWithName;
    mode: string = "focus";

    constructor(resource: DCNetscopeResourceWithName, mode: string) {
        this.resource = resource;
        this.mode = mode;
    }

    get key(): string {
        if (this.resource.name === undefined && this.resource.ipaddress !== undefined) {
            return `${this.mode} ${this.resource.ipaddress}`;
        }
        return `${this.mode} ${this.resource.name}`;
    }
}

@Component({
    selector: 'app-netscope-common-flowview',
    templateUrl: './netscope-common-flowview.component.html',
    styleUrls: ['./netscope-common-flowview.component.css']
})
export class NetscopeCommonFlowviewComponent implements OnInit {

    isLoading = false;
    openHeatmapModal = false;
    failureMode = false;
    svg;
    height = 1024;
    width = 768;
    chartRef;
    chartCheckInterval;
    reloadButtonColorClass = "btn-primary";
    linksAreColored = "yes";
    showIndirectLinks = "yes";

    isNetscopeLicenceEnabled = true;
    skipNextUrlChange = false;

    lastSvgWidth = 0;
    lastSvgHeight = 0;

    // focusedVms = [];
    focusedProtocols = [];

    resourceUuidToObjectsIndex = {};

    root = [];
    nodesMapping = {};
    timestamps = [];
    replayData;
    replayDataCopy;
    graphData: GraphDataObject = new GraphDataObject();
    filteredLinks: FlowDetail[] = [];
    nodeRadius = 15;
    startTime = 0;
    endTime = 0;
    temporalParameter: TemporalParameter;
    timeResolution = "daily";
    minTime = 0;
    maxTime = 0;

    descSort = ClrDatagridSortOrder.DESC;

    @ViewChild('combobox')
    combobox: ClrCombobox < any > ;

    @ViewChild('temporalSelectionHeatMap')
    temporalSelectionHeatMap: HeatmapHighchartsHeatmapComponent;

    selectedResourcesWithSearchCombobox = [];
    resourcesForSearchCombobox = [];

    @ViewChild('dataSettingsPanel')
    dataSettingsPanel;

    targetedResources = {
        hosts: [],
        virtualMachines: []
    };
    targetedResourcePattern = "";

    filters = {
        protocols: {},
        source: {},
        destination: {}
    };

    isPlaying = false;
    currentRangeValue = 0;
    minRangeValue = 0;
    maxRangeValue = 0;

    graphParameters = {
        userMovedMap: false,
        lastTransformation: undefined,
        firstTransformation: undefined
    }

    updateFlag = false;

    resourceSelectionChanged = new EventEmitter();
    clickOnTimeSlotEmitter = new EventEmitter();

    gravityValue = undefined; // It will be redefined when we have the numbers of displayed VMs
    gravityMode = "automatic";

    lastForcePositions = {};
    lastAlpha = 0.0;
    simulationAlreadyLoaded = false;
    simulationShouldRestart = false;
    simulationErasePreviousPositions = false;

    showEverything = "no";

    currentSelection;
    focusLevel = 1;
    focusNode;
    zoomFunction;

    perProtocolFlow = [];

    groupsAppsColorPalette = [
        "#a7cf50",
        "#9a7ee9",
        "#d5a939",
        "#77a2df",
        "#e17c50",
        "#5acfbd",
        "#e76a86",
        "#6bc876",
        "#da7fc8",
        "#beb56d"
    ];
    externalIps = [];
    groupsApps = [];
    selectedAppsGroups = [];
    appsGroupsMode = "automatic";

    // Selected view can be "protocol-analysis", "dependencies-viewer", "clusters-viewer" or "apps-groups"
    selectedView = "dependencies-viewer";
    // Selected view can be "Protocols analysis", "Dependencies viewer", "Clusters Viewer" or "Apps Groups"
    viewLabel = "Dependencies viewer"

    isListeningToRouteChange = false;

    currentMaxEvent = 0;

    vms: ComputingResourceCollection<VirtualMachine> = new ComputingResourceCollection<VirtualMachine>();
    hosts: ComputingResourceCollection<HostSystem> = new ComputingResourceCollection<HostSystem>();

    currentComboboxSelection: ComboboxSelectionItem[] = [];

    infrastructureVersion: InfrastructureVersion;
    regroupUnknownIps = "yes";

    constructor(private netscopeService: NetscopeService, public licenceService: LicenseService,
        private activatedRouter: ActivatedRoute, public route: Router,
        private location: Location, private shepherdService: ShepherdService,
        public translocoService: TranslocoService) {}

    ngOnInit(): void {

        if (!this.isListeningToRouteChange) {
            this.isListeningToRouteChange = true;

            this.route.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    // @ts-ignore
                    let snapshot = this.activatedRouter.snapshot;
                    //Do something with the NavigationEnd event object.
                    console.log(event);

                    let currentUrl = snapshot['_routerState'].url;

                    // Check that the id of the event has not been processed yet
                    if (event.id > this.currentMaxEvent && currentUrl === event.url) {
                        this.currentMaxEvent = event.id;
                        this.processRouterParams(snapshot.params, snapshot.queryParams);
                        this.updateSelectedView(false);
                    }
                });

            this.processRouterParams(this.activatedRouter.params, this.activatedRouter.queryParams);
        }

        this.reloadData();

        window.onresize = () => {
            if (this.replayData !== undefined) {
                this.reloadUi();
            }
        };

        this.route.events.subscribe((event) => {
            this.ngOnDestroy();
        });

        this.licenceService.licenseInfo.subscribe(licenceInfo => {
            if (environment.production) {
                // Check second bit to be different from 0
                this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
            } else {
                this.isNetscopeLicenceEnabled = true;
            }
        });

        this.clickOnTimeSlotEmitter.subscribe((event) => {
            console.log(event);
            // @ts-ignore
            let newStartTime = event[0];
            // @ts-ignore
            let newEndTime = event[0];
            // @ts-ignore
            let newTimeResolution = event[2];

            if (newStartTime !== this.startTime || newTimeResolution !== this.timeResolution) {
                this.startTime = newStartTime;
                this.endTime = newEndTime;
                this.timeResolution = newTimeResolution;
                this.updateUrl();
                this.reloadData();
            }
        });
    }

    launchTour() {
        let flowDatatableButton = document.getElementById("flows_visualisation_button");
        if (flowDatatableButton !== null) {
            flowDatatableButton.click();
        }

        let currentDateAsDatetime = new Date(this.startTime * 1000);
        let currentDateFormatted = this.timeResolution == "daily" ? currentDateAsDatetime.toLocaleDateString() : currentDateAsDatetime.toLocaleString();
        let stepsParameters = generateFlowComponentShepperdParameters(this, this.viewLabel, currentDateFormatted);

        // Close heatmap
        this.openHeatmapModal = false;
        // Prepare shepard tour component
        this.shepherdService.defaultStepOptions = stepsParameters.defaultStepOptions;
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
        this.shepherdService.addSteps(stepsParameters.steps);
        this.shepherdService.start();
        this.shepherdService.tourObject.on('complete', (event) => {
            this.openHeatmapModal = false;
            let flowDatatableButton = document.getElementById("flows_visualisation_button");
            if (flowDatatableButton !== null) {
                flowDatatableButton.click();
            }
        });
        this.shepherdService.tourObject.on('cancel', (event) => {
            this.openHeatmapModal = false;
            let flowDatatableButton = document.getElementById("flows_visualisation_button");
            if (flowDatatableButton !== null) {
                flowDatatableButton.click();
            }
        });
    }

    fixedGetParams = (queryParams, paramName) => {
        if (paramName in queryParams) {
            return queryParams[paramName];
        }

        if ("_value" in queryParams) {
            return queryParams._value[paramName];
        }

        return undefined;
    }

    processRouterParams = async (params, queryParams) => {
        // Check if a view is specified in the parameters
        let selectedView: string = this.fixedGetParams(params, "selected_view");

        // Remove arguments from selectedView: they are generated by updateUrl() function.
        if (selectedView !== undefined && selectedView.indexOf("?") !== -1) {
            selectedView = selectedView.split("?")[0];
        }

        if (selectedView !== undefined) {
            this.selectedView = selectedView;
            this.updateViewLabel();
        }
        // Check if some resources are specified in the parameters
        let resource_uuids_str_value = this.fixedGetParams(params, "resource_uuids");
        if (resource_uuids_str_value !== undefined) {
            let resource_uuids: string[] = resource_uuids_str_value.split(",");
            this.currentComboboxSelection = resource_uuids
                .map((resource_uuid) => new ComboboxSelectionItem(new DCNetscopeResourceWithName(resource_uuid, undefined), "focus"));
            // this.fixFocusedResourcesWithUnknownNames();
        }
        // Check if a start_time parameter has been specified
        let start_time_str_value = this.fixedGetParams(queryParams, "start_time");
        let end_time_str_value = this.fixedGetParams(queryParams, "end_time");
        let time_resolution_str_value = this.fixedGetParams(queryParams, "time_resolution");
        if (start_time_str_value !== undefined && time_resolution_str_value !== undefined) {
            this.startTime = Number.parseFloat(start_time_str_value);

            if (end_time_str_value !== undefined) {
                this.endTime = Number.parseFloat(end_time_str_value);
            } else {
                this.endTime = this.startTime
            }

            this.timeResolution = time_resolution_str_value;
            let that = this;

            function tryToSetDateOnHeatmap(maxRemainingAttempt = 5) {
                if (that.temporalSelectionHeatMap !== undefined && that.temporalSelectionHeatMap.heatmapChartRef
                    .series.length > 0) {
                    that.temporalSelectionHeatMap.selectDateComplete(that.startTime, that.endTime, that.timeResolution);
                } else {
                    if (maxRemainingAttempt > 0) {
                        setTimeout(() => tryToSetDateOnHeatmap(maxRemainingAttempt - 1), 300);
                    }
                }
            }
            tryToSetDateOnHeatmap();
        }
        // Check if some apps groups are specified in the parameters
        let apps_groups_names_str_value = this.fixedGetParams(params, "apps_groups");

        if (this.groupsApps === undefined || this.groupsApps.length === 0) {
            let all_apps_groups_response: GetAppsGroupsResponse = await this.netscopeService.getAppsGroups().pipe(take(1)).toPromise();

            if (all_apps_groups_response instanceof SuccessGetAppsGroupsResponse) {
                let success_all_apps_groups_response: SuccessGetAppsGroupsResponse = all_apps_groups_response
                this.groupsApps = success_all_apps_groups_response.appsGroupsData.apps_groups;
            }

        }

        if (apps_groups_names_str_value !== undefined) {
            let apps_groups_names = apps_groups_names_str_value.split(",");

            this.selectedAppsGroups = this.groupsApps
                .filter((appGroup) => apps_groups_names.indexOf(appGroup.name) !== -1);

            this.updateFocusedVmsFromSelectedAppsGroups();
        }

        // Take into account focused VMs
        let focusedVmsUuids = this.fixedGetParams(queryParams, "focused_vms");
        if (focusedVmsUuids === undefined) {
            focusedVmsUuids = [];
        }

        if (focusedVmsUuids !== undefined && focusedVmsUuids !== "") {
            let existingFocusedVmsUuids = this.currentComboboxSelection
                .filter((s) => s.mode === "focus")
                .map((s) => s.resource.uuid);
            let requestedFocusedVmsUuids = Array.isArray(focusedVmsUuids) ? focusedVmsUuids : focusedVmsUuids.split(",");

            requestedFocusedVmsUuids
                .filter((uuid) => existingFocusedVmsUuids.indexOf(uuid) === -1)
                .map((uuid) => {
                    this.currentComboboxSelection.push(new ComboboxSelectionItem(new DCNetscopeResourceWithName(uuid, undefined), "focus"));
                });
        }

        // Take into account protocols
        let protocols = this.fixedGetParams(params, "protocols");
        if (protocols !== undefined) {
            this.focusedProtocols = [protocols]
        }

        let oneFocusedResourceIsUnknown = false;
        for (let uuid of focusedVmsUuids) {
            if (uuid.indexOf("UnknownIp") !== -1) {
                oneFocusedResourceIsUnknown = true;
            }
        }

        // Take into account 'show_everything' parameter
        let show_everything = this.fixedGetParams(queryParams, "show_everything");
        if (show_everything !== undefined) {
            this.showEverything = show_everything;
        }

        // Take into account 'regroup_unknown_ips' parameter
        let regroup_unknown_ips = this.fixedGetParams(queryParams, "regroup_unknown_ips");
        if (regroup_unknown_ips !== undefined) {
            this.regroupUnknownIps = regroup_unknown_ips;
        }
    }

    updateFocusedVmsFromSelectedAppsGroups = () => {
        let vmsIndex = new Map<string, VirtualMachineWithAppsGroups>();
        let selectedGroupsAppsNames = this.selectedAppsGroups.map((g) => g.name);
        for (let appGroup of this.groupsApps) {

            if (selectedGroupsAppsNames.indexOf(appGroup.name) === -1) {
                continue;
            }

            for (let vm of appGroup.vms) {
                if (vmsIndex[vm.uuid] === undefined) {
                    let vmUuid = `vim.VirtualMachine:${vm.uuid}`
                    vmsIndex[vm.uuid] = new VirtualMachineWithAppsGroups(vmUuid, undefined, []);
                }
                vmsIndex[vm.uuid].apps_groups.push(appGroup.name);
            }
        }

        let existingVmsInFocusUuid = this.currentComboboxSelection
            .filter((s) => s.mode === "focus")
            .map((s) => s.resource.uuid);
        Object.values(vmsIndex).map((vmObject) => {
            if (existingVmsInFocusUuid.indexOf(vmObject.uuid) === -1) {
                this.currentComboboxSelection.push(new ComboboxSelectionItem(new DCNetscopeResourceWithName(vmObject.uuid, undefined), "focus"));
            }
        });
    }

    ngOnDestroy = () => {
        if (this.chartCheckInterval) {
            clearInterval(this.chartCheckInterval);
        }
        // for (let subscription of this.subscriptions) {
        //     subscription.unsubscribe();
        // }
    }

    callbackChartRef = (ref) => {
        this.chartRef = ref;
    }

    _generateListOfTargetedResources = () => {

        if (this.targetedResourcePattern === "" || this.targetedResourcePattern.length < 2) {
            return {
                switches: [],
                networks: [],
                hosts: [],
                virtualMachines: []
            };
        }

        var isValidRegex = true;
        try {
            new RegExp(this.targetedResourcePattern);
        } catch (e) {
            isValidRegex = false;
        }

        // Hosts
        let selectedHosts = this.replayData.routers
            .filter((host) => {
                if (host.name.toUpperCase().indexOf(this.targetedResourcePattern.toUpperCase()) !== -1) {
                    return true;
                }
                if (isValidRegex && host.name.match(this.targetedResourcePattern)) {
                    return true;
                }
                return false;
            });
        // VirtualMachines
        let selectedVirtualMachines = this.replayData.vms
            .filter((vm) => {
                if (vm.name.toUpperCase().indexOf(this.targetedResourcePattern.toUpperCase()) !== -1) {
                    return true;
                }
                if (isValidRegex && vm.name.match(this.targetedResourcePattern)) {
                    return true;
                }
                return false;
            });

        return {
            hosts: selectedHosts,
            virtualMachines: selectedVirtualMachines
        };
    }

    setButtonPrimaryRed = () => {
        this.reloadButtonColorClass = "btn-warning";
    }

    reloadData = () => {
        this.isLoading = true;
        this.failureMode = false;

        const timestampsAndCountsObservable = this.netscopeService.getDailyTimestamps();

        // Reload visualisation: fetch data and update visualisation
        timestampsAndCountsObservable.subscribe((timestampsAndCountsData: GetDailyTimestampResponse) => {

            if (timestampsAndCountsData instanceof FailureGetDailyTimestampResponse) {
                this.failureMode = true;
                this.isLoading = false;
                return;
            }

            let timestampsAndCounts;
            if (timestampsAndCountsData instanceof SuccessGetDailyTimestampResponse) {
                timestampsAndCounts = timestampsAndCountsData.dailyTimestamps;
            }

            this.netscopeService.getExternalIps().subscribe((externalIpsDataResponse: GetExternalIpsResponse) => {

                if (externalIpsDataResponse instanceof FailureGetExternalIpsResponse) {
                    this.failureMode = true;
                    this.isLoading = false;
                    return;
                }

                let externalIpsData;
                if (externalIpsDataResponse instanceof SuccessGetExternalIpsResponse) {
                    externalIpsData = externalIpsDataResponse.externalIps;
                }

                this.externalIps = externalIpsData.external_ips;

                let use_automatic_apps_groups = this.appsGroupsMode === "automatic";

                this.netscopeService.getAppsGroups(use_automatic_apps_groups).subscribe((appsGroupsResponse: GetAppsGroupsResponse) => {
                    let appsGroups;
                    if (appsGroupsResponse instanceof SuccessGetAppsGroupsResponse) {
                        appsGroups = appsGroupsResponse.appsGroupsData;
                    }
                    this.groupsApps = appsGroups.apps_groups;

                    this.timestamps = timestampsAndCounts
                        .filter((t) => t.start_time !== 0)
                        .map((t) => t.start_time);

                    if (this.timestamps.length > 0) {
                        if (this.startTime === 0) {
                            this.startTime = this.timestamps[this.timestamps.length - 1];
                            this.endTime = this.startTime
                        }
                    }

                    const startTimeInterval = this.startTime;
                    const lastTimeInterval: any = Math.max(...this.timestamps);
                    let endTimeInterval;
                    if (this.endTime === 0) {
                        if (this.timeResolution === "daily") {
                            if (lastTimeInterval <= startTimeInterval) {
                                endTimeInterval = 'now()';
                            } else {
                                endTimeInterval = Math.min(...this.timestamps.filter((t) => t > startTimeInterval),
                                    lastTimeInterval);
                            }
                        } else {
                            endTimeInterval = startTimeInterval + 3600 - 1;
                        }
                    } else {
                        endTimeInterval = this.endTime;
                    }

                    this.netscopeService.getInfrastructureVersionsBetween(startTimeInterval, endTimeInterval, [], [], true).subscribe((infrastructureVersionResponse: GetInfrastructureVersionsBetweenResponse) => {
                        if (infrastructureVersionResponse instanceof FailureGetInfrastructureVersionsBetweenResponse) {
                            this.failureMode = true;
                            this.isLoading = false;
                            return;
                        }

                        // let infrastructureVersion;
                        if (infrastructureVersionResponse instanceof SuccessGetInfrastructureVersionsBetweenResponse) {
                            this.infrastructureVersion = infrastructureVersionResponse.infrastructureVersionsBetween;
                        }
                        // console.log(infrastructureVersion);

                        this.isLoading = false;
                        this.buildResourceUuidToObjectIndex(this.infrastructureVersion);
                        this.reloadVisualization();
                    });
                });
            });
        });

        // Reload the heatmap if relevant
        if (this.temporalSelectionHeatMap !== undefined) {
            this.temporalSelectionHeatMap.displayHeatmap();
        }
    }

    buildResourceUuidToObjectIndex = (infrastructureVersion : InfrastructureVersion) => {
        // Create index of UUID => resource
        for (let tuple2 of [
            ["vm", infrastructureVersion.topology.vm_only_topology.vms],
            ["host", infrastructureVersion.topology.vm_only_topology.hosts],
            ["external_ip", this.externalIps]
        ]) {
            let resourceType = tuple2[0];
            let resources = tuple2[1];

            if (resources === undefined) {
                continue;
            }

            for (let resource of resources) {
                let longUuid = `${resource.uuid}`;
                let shortUuid = longUuid.split(":")[1];

                if (resourceType === "external_ip") {
                    resource.short_uuid = shortUuid;
                    resource.uuid = longUuid;
                }

                let resourceObject = {
                    "shortUuid": shortUuid,
                    "uuid": longUuid,
                    "name": resource.name,
                    "resourceType": resourceType,
                };
                this.resourceUuidToObjectsIndex[longUuid] = resourceObject;
                this.resourceUuidToObjectsIndex[shortUuid] = resourceObject;
            }
        }
    }

    reloadUrlParametersDataAndRecomputeDistances = () => {
        this.simulationAlreadyLoaded = false;
        this.updateUrl();
        this.reloadData();
    }

    reloadDataAndRecomputeDistances = () => {
        this.simulationAlreadyLoaded = false;
        this.reloadData();
    }

    reloadUrlParametersUiAndRecomputeDistances = () => {
        this.simulationShouldRestart = true;
        this.updateUrl();
        this.reloadUi();
    }

    reloadUiAndRecomputeDistances = () => {
        this.simulationShouldRestart = true;
        this.reloadUi();
    }

    reloadVisualization = () => {
        const startTimeInterval = this.startTime;
        const lastTimeInterval: any = Math.max(...this.timestamps);
        let endTimeInterval;

        if (this.endTime === 0) {
            if (this.timeResolution === "daily") {
                if (lastTimeInterval <= startTimeInterval) {
                    endTimeInterval = 'now()';
                } else {
                    endTimeInterval = Math.min(...this.timestamps.filter((t) => t > startTimeInterval), lastTimeInterval);
                }
            } else {
                endTimeInterval = startTimeInterval + 3600 - 1;
            }
        } else {
            endTimeInterval = this.endTime;
        }

        this.vms = new ComputingResourceCollection<VirtualMachine>(...this.infrastructureVersion.topology.vm_only_topology.vms);
        this.hosts = new ComputingResourceCollection<HostSystem>(...this.infrastructureVersion.topology.vm_only_topology.hosts);

        this.fixFocusedResourcesWithUnknownNames();

        const vmsUuids = this.vms.generateShortUuids();
        const hostsUuids = this.hosts.generateShortUuids();

        const showEverything = this.showEverything === "yes";

        this.isLoading = true;
        this.failureMode = false;
        this.netscopeService.getClustersWithFilters(startTimeInterval, endTimeInterval, vmsUuids, hostsUuids, true, this.timeResolution, showEverything).subscribe((clustersDataResponse: GetClustersWithFiltersResponse) => {
            this.isLoading = false;

            this.reloadButtonColorClass = "btn-primary";

            if (clustersDataResponse instanceof FailureGetClustersWithFiltersResponse) {
                this.failureMode = true;
                this.isLoading = false;
                return;
            }

            let clustersData;
            if (clustersDataResponse instanceof SuccessGetClustersWithFiltersResponse) {
                clustersData = clustersDataResponse.clustersWithFiltersData;
            }

            // Initialize replayData
            this.replayData = clustersData;
            this.replayDataCopy = JSON.parse(JSON.stringify(clustersData));

            // Reload UI
            this.reloadUi();
        });
    }

    fixFocusedResourcesWithUnknownNames = () => {
        for (let selectedResource of this.currentComboboxSelection) {
            if (selectedResource.resource.name === undefined) {
                let type = this.computeManuallyTypeOfResource(selectedResource.resource);

                if (type === "vm" || type === "host" || type === "external_ip") {
                    let correspondingResourceInIndex = this.resourceUuidToObjectsIndex[selectedResource.resource.uuid]
                    if (correspondingResourceInIndex !== undefined) {
                        selectedResource.resource.name = correspondingResourceInIndex.name
                    }
                }
                if (type === "unknown_ip") {
                    selectedResource.resource.name = selectedResource.resource.uuid.split(":")[1]
                }
            }
        }
    }

    ensureLinksAreCompatibleWithDatagridFormat(links) {
        for (let link of links) {
            link.src_address = link.source.ipaddress;
            link.source.is_in_filter = true;
            link.source.type = link.source.type;
            link.dst_address = link.target.ipaddress;
            link.target.is_in_filter = true;
            link.target.type = link.target.type;

        }
        return links;
    }

    reloadUi = () => {
        // // Prevent to show everything if nothing is focused
        // if (this.currentComboboxSelection.length === 0) {
        //     this.showEverything = "no";
        // }

        // Prevent side effects related to switching between views
        this.replayData = JSON.parse(JSON.stringify(this.replayDataCopy));

        const sortedTimes = this.timestamps
            .sort((a, b) => a - b);

        if (this.minTime === 0 || this.maxTime === 0) {
            this.minTime = Math.min(...sortedTimes);
            this.maxTime = Math.max(...sortedTimes);
            console.log(`setting replay start time at ${this.startTime}`);
        }
        if (this.startTime === 0) {
            this.startTime = Math.max(...sortedTimes);
            console.log(`setting replay start time at ${this.startTime}`);
        }

        // Set the temporalParameter object for the flows datagrid
        this.temporalParameter = new TemporalParameter(this.startTime, this.endTime, this.timeResolution)

        // Set the range value
        this.minRangeValue = 0;
        this.maxRangeValue = sortedTimes.length - 1;
        const indexOfCurrentTime = sortedTimes.indexOf(this.startTime);
        this.currentRangeValue = indexOfCurrentTime;
        // Set the right links
        this.graphData.links = this.replayData.links;
        this.graphData.protocols = this.replayData.protocols;
        this.graphData.vms = this.replayData.vms;

        // Add custom ips
        // this.graphData.external_ips = this.ExternalIps;
        this.graphData.external_ips = this.replayData.external_ips;

        // Add unknown ips
        this.graphData.unknown_ips = this.replayData.unknown_ips;

        if (this.showEverything === "no") {
            this.graphData.unknown_ips = [];
        }

        // Take into account focused VMs
        let selectionForFocus = this.currentComboboxSelection
            .filter((s) => s.mode === "focus");
        if (selectionForFocus.length > 0) {
            let focusedVmsIds = selectionForFocus.map((s) => s.resource.uuid);
            // Filter relevant links
            let relevantLinks = this.graphData.links.filter((link) => {
                if (focusedVmsIds.indexOf(link.source.uuid) !== -1 || focusedVmsIds.indexOf(link.target.uuid) !== -1) {
                    return true;
                }
                return false;
            })
            // Extract uuids of VMs in links
            let uuidVmMap = new Map();
            relevantLinks.map((link) => {
                uuidVmMap.set(link.source.uuid, link.source);
                uuidVmMap.set(link.target.uuid, link.target);
            })
            // Filter VMs
            this.graphData.vms = Array.from(uuidVmMap.values());
            // Add focused VMs if they are not in the new vms list
            for (let selection of selectionForFocus) {
                let newVmsIds = this.graphData.vms.map((vm) => vm.id);
                if (newVmsIds.indexOf(selection.resource.uuid) === -1) {
                    this.graphData.vms.push({
                        "uuid": selection.resource.uuid,
                        "id": selection.resource.uuid,
                        "name": selection.resource.name,
                        "type": this.computeManuallyTypeOfResource(selection.resource)
                    });
                }
            }
            // Filter links
            this.graphData.links = this.replayData.links.filter((link) => {
                if (uuidVmMap.has(link.source.id) || uuidVmMap.has(link.target.id)) {
                    return true;
                }
                return false;
            });

        }

        let focusedVmsIds = selectionForFocus.map((s) => s.resource.uuid);
        // Filter relevant external IPs that are directly linked to focused VMs
        let tempRelevantExternalIpMap = new Map<string, object>();
        this.graphData.external_ips
            .filter((e) => focusedVmsIds.indexOf(e.uuid) !== -1)
            .map((e) => {
                tempRelevantExternalIpMap.set(e.uuid, e);
            });
        this.graphData.links
            .filter((l) => l.source.type == "external_ip" || l.target.type == "external_ip")
            .map((l) => {
                if (l.source.type == "external_ip" && focusedVmsIds.indexOf(l.target.uuid) !== -1) {
                    tempRelevantExternalIpMap.set(l.source.uuid, l.source);
                }
                if (l.target.type == "external_ip" && focusedVmsIds.indexOf(l.source.uuid) !== -1) {
                    tempRelevantExternalIpMap.set(l.target.uuid, l.target);
                }
            });
        this.graphData.external_ips = this.graphData.external_ips
            .filter((e) => tempRelevantExternalIpMap.has(e.uuid));
        // Filter unknown IPs that are directly linked to focused VMs
        let tempRelevantUnknownIpsMap = new Map<string, object>();
        // Add Unknown Ips that are in the focus set
        focusedVmsIds
            .filter((uuid) => uuid.indexOf("vim.UnknownIpaddress:") !== -1)
            .map((uuid) => {
                tempRelevantUnknownIpsMap.set(uuid, {uuid: uuid, type: "unknown_ip"});
            })
        if (this.regroupUnknownIps === "no") {
            // Iterate on flows and add relevant unknown IPs
            this.graphData.links
                .filter((l) => l.source.type == "unknown_ip" || l.target.type == "unknown_ip")
                .map((l) => {
                    if (l.source.type == "unknown_ip") {
                        tempRelevantUnknownIpsMap.set(l.source.uuid, l.source);
                    }
                    if (l.target.type == "unknown_ip") {
                        tempRelevantUnknownIpsMap.set(l.target.uuid, l.target);
                    }
                });
            this.graphData.unknown_ips = this.graphData.unknown_ips
                .filter((u) => tempRelevantUnknownIpsMap.has(u.uuid));
        } else {
            // Iterate on flows and add relevant unknown
            let hasOverallUnknownIp = false;

            let unknownIpObject = {
                "uuid": "vim.UnknownIpaddress:Outside Traffic",
                "ipaddress": "Outside Traffic"
            }

            this.graphData.links
                .filter((l) => l.source.type == "unknown_ip" || l.target.type == "unknown_ip")
                .map((l) => {
                    if (l.source.type == "unknown_ip") {
                        if (focusedVmsIds.indexOf(l.source.uuid) !== -1) {
                            tempRelevantUnknownIpsMap.set(l.source.uuid, l.source);
                        } else {
                            l.source.uuid = unknownIpObject.uuid;
                            hasOverallUnknownIp = true;
                        }
                    }
                    if (l.target.type == "unknown_ip" && focusedVmsIds.indexOf(l.source.uuid) !== -1) {

                        if (focusedVmsIds.indexOf(l.target.uuid) !== -1) {
                            tempRelevantUnknownIpsMap.set(l.target.uuid, l.target);
                        } else {
                            l.target.uuid = unknownIpObject.uuid;
                            hasOverallUnknownIp = true;
                        }
                    }
                });
            if (hasOverallUnknownIp) {
                tempRelevantUnknownIpsMap.set(unknownIpObject.uuid, unknownIpObject);
                this.graphData.unknown_ips.push(unknownIpObject);
            }
            this.graphData.unknown_ips = this.graphData.unknown_ips
                .filter((u) => tempRelevantUnknownIpsMap.has(u.uuid));
        }


        // Add hosts UUID to vms
        let vmUuidToHostIndex = {};
        this.replayData.routers.map((router) => router.children.map((vmUuid) => {
            let short_uuid = getShortUuid(router);
            vmUuidToHostIndex[vmUuid] = {
                uuid: router.uuid,
                short_uuid: short_uuid,
                name: router.name
            };
        }))
        this.graphData.vms.map((vm) => {
            vm.host = vmUuidToHostIndex[vm.uuid];
        });
        this.replayData.vms.map((vm) => {
            vm.host = vmUuidToHostIndex[vm.uuid];
        });
        this.graphData.routers = this.replayData.routers;
        this.graphData.clusters = this.replayData.clusters;

        // Set host on source and target of each link (for datagrid and export CSV)
        this.replayData.links.map((link) => {
            link.source.host = vmUuidToHostIndex[link.source.id];
            link.target.host = vmUuidToHostIndex[link.target.id];
        });

        this.resourcesForSearchCombobox = [
            ...this.graphData.routers,
            ...this.graphData.vms
        ]

        // Ensure compatibility of links with new format for common flow datagrid
        this.ensureLinksAreCompatibleWithDatagridFormat(this.graphData.links);

        // Filter protocols
        let focusedVmsUuids = this.currentComboboxSelection.filter((s) => s.mode === "focus");
        if (focusedVmsUuids.length > 0) {
            this.filterProtocols(this.graphData.protocols, {});
        }

        // Group unrecognized protocols in an unknown protocol
        let unknownProtocols = this.replayData.protocols.children.filter((c) => c.object.is_unknown);
        let knownProtocols = this.replayData.protocols.children.filter((c) => !c.object.is_unknown);
        this.replayData.protocols.children = knownProtocols;
        this.replayData.protocols.children.push({
            object: {
                "type": "protocol",
                "name": "unknown",
                "uuid": "unknown",
                "is_unknown": true,
                "port": -1
            },
            children: unknownProtocols
        });

        // Refresh network view
        this.eraseNetworkView();
        this.createNetworkView(this.graphData);
    }

    filterProtocols = (protocol_node, focusVmsUiidIndex) => {
        if (protocol_node.object.type === "root") {
            let focusedVmsUuids = this.currentComboboxSelection
                .filter((s) => s.mode === "focus")
                .map((s) => s.resource.uuid);
            for (let vmUuid of focusedVmsUuids) {
                focusVmsUiidIndex[vmUuid] = true;
            }
        }

        if (protocol_node.role !== undefined && protocol_node.role === "source") {
            protocol_node.children = protocol_node.children
                .filter((child) => focusVmsUiidIndex[protocol_node.object.uuid] || focusVmsUiidIndex[child.object
                    .uuid]);
        } else {
            protocol_node.children.map((child) => {
                this.filterProtocols(child, focusVmsUiidIndex);
            })
        }
    }

    computeManuallyTypeOfResource = (resource) => {
        let typeMap = {
            "vim.VirtualMachine": "vm",
            "vim.HostSystem": "host",
            "vim.ExternalIpaddress": "external_ip",
            "vim.UnknownIpaddress": "unknown_ip",
        };
        let [oType, oShortUuid] = resource.uuid.split(":");
        return typeMap[oType];
    }

    isFocusedVm = (vmUuid) => {
        let focusedVmsIds = this.currentComboboxSelection
            .filter((s) => s.mode === "focus")
            .map((s) => s.resource.uuid);
        return focusedVmsIds.indexOf(vmUuid) !== -1;
    }

    selectForFocus = (vmUuids) => {
        this.currentComboboxSelection = [];

        for (let vmUuid of vmUuids) {
            let vmName = this.vms.filter((vm) => vm.uuid === vmUuid)[0].name;
            this.currentComboboxSelection.push(new ComboboxSelectionItem(new DCNetscopeResourceWithName(vmUuid, vmName), "focus"));
        }
        this.updateUrl();
        this.reloadUi();
    }

    clearFocus = () => {
        this.updateUrl();
        this.selectForFocus([]);
    }

    switchFocus = (vm) => {
        let focusedVmsIds = this.currentComboboxSelection
            .filter((r) => r.mode === "focus")
            .map((s) => s.resource.uuid);

        let currentVmId = vm.id !== undefined ? vm.id : vm.uuid;

        if (focusedVmsIds.indexOf(currentVmId) !== -1) {
            this.currentComboboxSelection = this.currentComboboxSelection
                .filter((s) => !(s.mode === "focus" && s.resource.uuid === currentVmId));
        } else {
            this.currentComboboxSelection.push(new ComboboxSelectionItem(new DCNetscopeResourceWithName(currentVmId, vm.name), "focus"));
        }
        this.graphParameters.userMovedMap = false;
        this.updateUrl();
        this.reloadUiAndRecomputeDistances();
    }

    /**
     * This method export the data table into a CSV file
     */
    exportCSV = () => {
        let csvHeader = "Source, Destination, SourceHost, DestinationHost, ExchangedBytes, TotalPackets"
        let csvContent = csvHeader + "\n";
        for (let link of this.filteredLinks) {
            let sourceName = link.source.name;
            let targetName = link.target.name;
            let sourceHostName = link.source.host === undefined ? "na" : link.source.host.name;
            let targetHostName = link.target.host === undefined ? "na" : link.target.host.name;
            let lineValue =
                `${sourceName}, ${targetName}, ${sourceHostName}, ${targetHostName}, ${link.exchanged_bytes}, ${link.exchanged_packets}\n`;
            csvContent += lineValue;
        }

        let exportedFilename = 'netscope-dependencies.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    exportRowPerPortCSV = () => {
        let csvHeader = "Source, Destination, Port, Protocol, ExchangedBytes"
        let csvContent = csvHeader + "\n";
        for (let flow of this.perProtocolFlow) {
            let lineValue =
                `${flow.source.name}, ${flow.target.name}, ${flow.port}, ${flow.protocol}, ${flow.exchanged_bytes}\n`;
            csvContent += lineValue;
        }

        let exportedFilename = 'netscope-protocols.csv';
        let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    exportGraph = () => {
        let svgBody = this.svg.html();
        let svgCode =
            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${svgBody}</svg>`;
        let exportedFilename = 'netscope-dependencies.svg';

        let blob = new Blob([svgCode], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    updateViewLabel = () => {
        if (this.selectedView === "protocol-analysis") {
            this.viewLabel = "Protocol analysis";
        }
        if (this.selectedView === "dependencies-viewer") {
            this.viewLabel = "Dependencies viewer";
        }
        if (this.selectedView === "clusters-viewer") {
            this.viewLabel = "Clusters viewer";
        }
        if (this.selectedView === "apps-groups") {
            this.viewLabel = "Apps Groups";
        }
    }

    createNetworkView(graphData): void {
        d3.select('div#divSvg').select("svg").remove();

        console.log(`I will render flows with ${this.selectedView} renderer`);

        this.updateViewLabel();

        if (this.selectedView === "protocol-analysis") {
            let renderer = new ProtocolAnalysisRenderer();
            let zoomedProtocols;
            if (this.focusedProtocols) {
                zoomedProtocols = this.focusedProtocols[0];
            }
            renderer.render(graphData, this, zoomedProtocols);
        }
        if (this.selectedView === "dependencies-viewer") {
            let renderer = new DependenciesViewerRenderer()
            renderer.render(graphData, this);
        }
        if (this.selectedView === "clusters-viewer") {
            let renderer = new ClustersViewerRenderer()
            renderer.render(graphData, this);
        }
        if (this.selectedView === "apps-groups") {
            let renderer = new AppsGroupsRenderer()
            renderer.render(graphData, this);
        }
    }

    eraseNetworkView(): void {
        d3.select('div#divSvg')
            .selectAll('*')
            .remove();
    }

    removeSelection = () => {
        if (this.selectedView !== "apps-groups") {
            this.groupsApps = [];
        }
        this.selectedAppsGroups = [];
        this.currentComboboxSelection = [];
        // this.reloadUi();
        this.updateUrl();
        this.reloadUiAndRecomputeDistances();
    }

    navigateSelection = (where) => {
        this.selectedView = where;
        this.skipNextUrlChange = true;
        // this.updateSelectedView(true);
        this.updateUrl();
    }

    resetVisualisationCommonVariables = () => {
        this.graphParameters = {
            userMovedMap: false,
            lastTransformation: undefined,
            firstTransformation: undefined
        }

        this.updateFlag = false;

        this.gravityValue = undefined; // It will be redefined when we have the numbers of displayed VMs
        this.gravityMode = "automatic";

        this.lastForcePositions = {};
        this.lastAlpha = 0.0;
        this.simulationAlreadyLoaded = true;
        this.simulationShouldRestart = true;

        this.replayData = this.replayDataCopy;
    }

    updateUrl = () => {
        let baseUrl = `netscope/flows/${this.selectedView}`;
        let urlSuffix = "";

        if (this.selectedAppsGroups.length > 0) {
            urlSuffix = '/apps_groups/' + this.selectedAppsGroups.map((g) => g.name).join(",");
        }

        let queryParams = {};

        // Add date and time resolution to the URL queryParams
        queryParams["start_time"] = this.startTime;
        queryParams["time_resolution"] = this.timeResolution;
        queryParams["show_everything"] = this.showEverything;
        queryParams["regroup_unknown_ips"] = this.regroupUnknownIps;

        queryParams["focused_vms"] = this.currentComboboxSelection
            .filter((s) => s.mode === "focus")
            .map((s) => s.resource.uuid);

        // Add date and time resolution to the URL
        let urlParamsSuffix = "";

        if (Object.entries(queryParams).length > 0) {
            let urlParamsSuffixParts = [];
            for (let [k, v] of Object.entries(queryParams)) {
                urlParamsSuffixParts.push(`${k}=${v}`);
            }
            urlParamsSuffix = "?" + urlParamsSuffixParts.join("&");
        }

        let newUrl = `${baseUrl}${urlSuffix}`;
        let newUrlWithTimeSuffix = `${baseUrl}${urlSuffix}${urlParamsSuffix}`;

        this.location.go(newUrlWithTimeSuffix);
        this.route.navigate([newUrl], {
            queryParams: queryParams,
            skipLocationChange: true
        });
    }

    updateSelectedView = (updateUrl = true) => {
        if (updateUrl) {
            this.updateUrl();
        }
        if (this.selectedAppsGroups.length > 0) {
            this.updateFocusedVmsFromSelectedAppsGroups();
        }
        this.resetVisualisationCommonVariables();
        this.reloadUi();
    }

    setLanguage = (language: "french" | "english") => {
        let languageMap: Map<string, string> = new Map([
            ["french", "fr"],
            ["english", "en"]
        ]);
        let language_code = languageMap.get(language);
        this.translocoService.setDefaultLang(language_code);
        this.translocoService.setActiveLang(language_code);
        localStorage.setItem("language", language_code);
    }

    /*
##########################################
#
# DEPENDENCIES VIEWER
#
##########################################
   */

    addGroupsAppsCircles = (visuNode) => {
        let groupsAppsColorIndex = {};

        let i = 0;
        for (let groupApp of this.selectedAppsGroups) {
            groupsAppsColorIndex[groupApp.name] = this.groupsAppsColorPalette[i % (this.groupsAppsColorPalette
                .length)];
            groupApp.color = groupsAppsColorIndex[groupApp.name];
            i += 1;
        }

        let vmToAppgroupsIndex = {}
        for (let appGroup of this.selectedAppsGroups) {
            for (let vm of appGroup.vms) {
                let vmFullUuid = `vim.VirtualMachine:${vm.uuid}`;
                if (vmToAppgroupsIndex[vmFullUuid] === undefined) {
                    vmToAppgroupsIndex[vmFullUuid] = [];
                }
                vmToAppgroupsIndex[vmFullUuid].push(appGroup.name);
            }
            console.log("ici?");
        }

        const additionalVmCircles = visuNode
            .each((d) => {
                let focusedVmsIds = this.currentComboboxSelection
                    .filter((s) => s.mode === "focus")
                    .map((s) => s.resource.uuid);

                if (focusedVmsIds.indexOf(d.id) !== -1) {
                    let appGroupIndex = 0;

                    let vmAppsGroups = [];
                    if (vmToAppgroupsIndex[d.id] !== undefined) {
                        vmAppsGroups = vmToAppgroupsIndex[d.id];
                    }

                    for (let appGroupName of vmAppsGroups) {
                        visuNode
                            .filter((d2) => {
                                return d.uuid === d2.uuid;
                            })
                            .append('g')
                            .append('circle')
                            .attr('r', this.nodeRadius + (1 + appGroupIndex) * 5)
                            .style('stroke-width', 5) // set the stroke width
                            .style('stroke', (d) => {
                                return groupsAppsColorIndex[appGroupName];
                            })
                            .attr('fill', (d) => 'none');

                        appGroupIndex += 1;
                    }
                }
            });
    }

    _recursiveGenerateFlows(node, result = []) {
        if (node.children !== undefined && node.children.length > 0) {
            for (let child of node.children) {
                this._recursiveGenerateFlows(child, result);
            }
        } else {
            if (node.data.role === "destination") {
                // Fix transportProtocols property in ports. They are in the form "17 TCP", we will transform them in the following form:
                // {protoNumber: 17, protoLabel: "TCP"}
                let transportProtocols = node.data.transport_protocols.map((proto) => {
                    if ((typeof proto) === "string") {
                        let protoNumber = -1;
                        let protoLabel = "unknown";

                        let protoParts = proto.split(" ");
                        if (protoParts.length == 2) {
                            protoNumber = Number.parseInt(protoParts[0]);
                            protoLabel = protoParts[1];
                        }

                        return {
                            protoNumber: protoNumber,
                            protoLabel: protoLabel,
                        };
                    } else {
                        return proto;
                    }
                });

                result.push({
                    target: node.data.object,
                    source: node.parent.data.object,
                    port: node.parent.parent.data.object.port,
                    protocol: node.parent.parent.data.object.is_unknown ? "unknown" : node.parent.parent.data.object.name,
                    exchanged_bytes: node.value,
                    transport_protocols: transportProtocols
                });
                // Fix names and attributes for target and source
            }
        }
        return result;
    }

    zoomOnNode = (d) => {
        this.zoomFunction(d);
    }

    updatePerProtocolFlows = () => {
        this.perProtocolFlow.splice(0, this.perProtocolFlow.length);
        this._recursiveGenerateFlows(this.currentSelection[this.currentSelection.length - 1], this.perProtocolFlow);
    }

    resolveDomain = (ip: string, callback: (ipResult: DomainResolution) => void) => {
        this.netscopeService.getDomainResolution(ip).subscribe((domainResultResponse:
            GetDomainResolutionResponse) => {
            if (domainResultResponse instanceof SuccessGetDomainResolutionResponse) {
                callback(domainResultResponse.domainResolution);
            }
        });
    }

    onComboboxChange = (event) => {
        let processedSelection = this.currentComboboxSelection.filter((o) => o?.mode !== undefined);
        let newItemsInSelection = this.currentComboboxSelection.filter((o) => o?.mode === undefined);

        let processedNewItemsInSelection = newItemsInSelection.map((r) => new ComboboxSelectionItem(r.resource, "focus"));

        this.currentComboboxSelection = processedSelection.concat(processedNewItemsInSelection);

        event.model = this.currentComboboxSelection;

        this.reloadUiAndRecomputeDistances();
        this.updateUrl();
    };

    fetchResourcesForCombobox = (filter = '', combobox: NetscopeCommonFlowviewComboboxComponent) => {
        combobox.loadingResources = true;

        let words = filter.split(" ");

        let resources = [...this.replayData.vms, ...this.replayData.external_ips]

        if (this.showEverything === "yes" && filter.length >= 2) {
            resources.push(...this.replayData.unknown_ips);
        }

        let filteredResources = resources
            .filter((o) => o !== undefined)
            .filter((o) => o.uuid.indexOf(filter) !== 1 || o.name.indexOf(filter) !== -1);

        let ignoreAndFocusResults = [...filteredResources.map((o) => {
            return new ComboboxSelectionItem(o, "ignore");
        }), ...filteredResources.map((o) => {
            return new ComboboxSelectionItem(o, "focus");
        })]

        combobox.asyncResources$ = of(ignoreAndFocusResults);
        combobox.loadingResources = false;
    }

    zoomInVisualisation = () => {
        console.log("zoom in visualisation");
        let transformation = {
            x: this.graphParameters.firstTransformation.x,
            y: this.graphParameters.firstTransformation.y,
            k: this.graphParameters.firstTransformation.k
        };

        if (this.graphParameters?.lastTransformation?.x !== undefined) {
            transformation.x = this.graphParameters.lastTransformation.x;
        }

        if (this.graphParameters?.lastTransformation?.y !== undefined) {
            transformation.y = this.graphParameters.lastTransformation.y;
        }

        if (this.graphParameters?.lastTransformation?.k !== undefined) {
            transformation.k = this.graphParameters.lastTransformation.k;
        }

        transformation.k *= 1.2;
        this.graphParameters.userMovedMap = true;

        this.graphParameters.lastTransformation = transformation;
        this.reloadUi();
    }

    zoomOutVisualisation = () => {
        console.log("zoom out visualisation");
        let transformation = {
            x: this.graphParameters.firstTransformation.x,
            y: this.graphParameters.firstTransformation.y,
            k: this.graphParameters.firstTransformation.k
        };

        if (this.graphParameters?.lastTransformation?.x !== undefined) {
            transformation.x = this.graphParameters.lastTransformation.x;
        }

        if (this.graphParameters?.lastTransformation?.y !== undefined) {
            transformation.y = this.graphParameters.lastTransformation.y;
        }

        if (this.graphParameters?.lastTransformation?.k !== undefined) {
            transformation.k = this.graphParameters.lastTransformation.k;
        }

        transformation.k *= 0.8;
        this.graphParameters.userMovedMap = true;

        this.graphParameters.lastTransformation = transformation;
        this.reloadUi();
    }

    recenterVisualisation = () => {
        console.log("recenter visualisation");
        this.graphParameters.userMovedMap = false;
        this.graphParameters.lastTransformation.x = this.graphParameters.firstTransformation.x;
        this.graphParameters.lastTransformation.y = this.graphParameters.firstTransformation.y;
        this.graphParameters.lastTransformation.k = this.graphParameters.firstTransformation.k;
        this.reloadUi();
    }
}

