import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import {
    GetDomainResolutionResponse,
    NetscopeService,
    SuccessGetDomainResolutionResponse, TemporalParameter
} from "@app/services";
import {
    ClrDatagridSortOrder
} from "@clr/angular";


export class FlowHostResource {
    name: string;
    short_uuid: string;
}

export class FlowRessourceObject {
    type: string;
    uuid: string;
    name: string;
    is_in_filter: boolean;
    host: FlowHostResource;
    address: string;
    x?: number;
    y?: number;
}

export class FlowMetric {
    start_time: number;
    exchanged_bytes: number;
    exchanged_packets: number;
    transport_protocol: string;
    application_protocol: string;
    port: number;
    direction: number;
    source_address: {
        uuid: string;
        ipaddress: string;
    };
    destination_address: {
        uuid: string;
        ipaddress: string;
    };
}

export class ASN {
    range_start: string;
    range_end: string;
    asn_number: number;
    country_code: string;
    asn_description: string;
}

export class IpInfo {
    ip_version: string;
    start_ip: string;
    end_ip: string;
    country_code: string;
    latitude: number;
    longitude: number;
    accuracy: number;
    ip_range: string;
    asns: ASN[] = [];
}

export class Threat {
    ip: string;
    port: number;
    url: string;
    threat: string;
    tags: string[];
    link: string;
    score: number;
    risk_level: string;
    ip_info: IpInfo
}

export class FlowDetail {
    src_address: string;
    source: FlowRessourceObject;
    src_ip_resolved: string;
    src_ip_resolved_class: string;
    dst_address: string;
    target: FlowRessourceObject;
    dst_ip_resolved: string;
    dst_ip_resolved_class: string;
    exchanged_bytes: number;
    exchanged_packets: number;
    metrics: FlowMetric[] = [];
    port: number;
    protocol: string;
    transport_protocols: any[];
    date: number;
    threat: Threat;
    showActions: boolean = true;
    showDependencyViewerIcon: boolean = true;
    showClustersViewerIcon: boolean = true;
    showHeatmapViewerIcon: boolean = true;
    x1?: number;
    y1?: number;
    x2?: number;
    y2?: number;
    source_country?: string;
    source_asn?: string;
    destination_country?: string;
    destination_asn?: string;
    temporalParameter?: TemporalParameter
}

export class NetscopeResource {
    uuid: string;
    name: string;
    type: string;
}

export class ResourceWithSide {
    resource: NetscopeResource;
    side: string
}

export class SharedFilterOptions {
    filterIsActive: boolean = false;
    selectedResources: ResourceWithSide[] = []
}

@Component({
    selector: 'app-netscope-flows-datagrid',
    templateUrl: './netscope-flows-datagrid.component.html',
    styleUrls: ['./netscope-flows-datagrid.component.css']
})
export class NetscopeFlowsDatagridComponent implements OnInit {

    sourceHostIpFilter;
    destinationHostIpFilter;
    descSort = ClrDatagridSortOrder.DESC;

    @Input()
    temporalParameter: TemporalParameter;
    @Input()
    bidirectional = false;
    @Input()
    exportCsvEnabled = false;
    @Input()
    callExportCsv = () => {};

    @Input()
    flowDetails: FlowDetail[];

    sharedOptions: SharedFilterOptions = {
        filterIsActive: false,
        selectedResources: []
    }

    @Input()
    showActions = true;

    @Input()
    showHosts = false;

    @Input()
    perPortRow = false;

    @Input()
    displayLinksToRessources = true;

    @Input()
    hidePacketsExchanged = false;

    @Input()
    hideTotalBytes = false;

    @Input()
    showCountry = false;

    @Input()
    showASN = false;

    @Input()
    ignoreFilters = false;

    @Input()
    showThreats: boolean = false;

    @Input()
    switchFocus: (FlowRessourceObject) => void = (ressource) => {};

    @Input()
    isFocusedRessource: (FlowRessourceObject) => boolean = (ressource) => false;

    shouldUpdateFilters: EventEmitter < any > = new EventEmitter();

    constructor(private netscopeService: NetscopeService) {}

    ngOnInit(): void {}

    setFlowdetailIconEnabled(flowDetail: FlowDetail) {
        flowDetail.showActions = flowDetail.source !== undefined && (flowDetail.source.is_in_filter || this
            .ignoreFilters) && flowDetail.target !== undefined && (flowDetail.target.is_in_filter || this
            .ignoreFilters);
        flowDetail.showDependencyViewerIcon = flowDetail.source.type !== 'host' && flowDetail.target.type !==
            'host';
        flowDetail.showClustersViewerIcon = flowDetail.source.type !== 'host' && flowDetail.target.type !== 'host';
        flowDetail.showHeatmapViewerIcon = flowDetail.source.type !== undefined && flowDetail.source.type !==
            'unknown_ip' && flowDetail.target.type !== undefined && flowDetail.target.type !== 'unknown_ip';
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Iterate on flows
        for (let flowDetail of this.flowDetails) {
            this.setFlowdetailIconEnabled(flowDetail);
        }
        // Force the refresh of all filters in the datagrid
        this.shouldUpdateFilters.emit(this.flowDetails);
    }

    resolveDomain = (flowDetail, srcOrDst) => {
        let ip = srcOrDst === "src" ? flowDetail.src_address : flowDetail.dst_address;
        this.netscopeService.getDomainResolution(ip).subscribe((domainResultResponse:
            GetDomainResolutionResponse) => {

            let domainResult;
            if (domainResultResponse instanceof SuccessGetDomainResolutionResponse) {
                domainResult = domainResultResponse.domainResolution;
            }

            if (srcOrDst === "src") {
                // @ts-ignore
                flowDetail.src_ip_resolved = domainResult.status === "ok" ? domainResult.result : "not resolved";
                // @ts-ignore
                flowDetail.src_ip_resolved_class = domainResult.status === "ok" ? "info" : "danger";
            } else {
                // @ts-ignore
                flowDetail.dst_ip_resolved = domainResult.status === "ok" ? domainResult.result : "not resolved";
                // @ts-ignore
                flowDetail.dst_ip_resolved_class = domainResult.status === "ok" ? "info" : "danger";
            }
        })
    }

}

