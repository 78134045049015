import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ComponentRef,
    SimpleChanges
} from '@angular/core';
import {
    Router
} from '@angular/router';

import {
    AccountService,
    GreenitService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    WeeklycalComponent
} from '@app/weeklycal/weeklycal.component';
import {
    WeeklycalDirective
} from '@app/directives/weeklycal.directive';
import {
    MonthlycalComponent
} from '@app/monthlycal/monthlycal.component';
import {
    MonthlycalDirective
} from '@app/directives/monthlycal.directive';
import {
    YearlycalComponent
} from '@app/yearlycal/yearlycal.component';
import {
    YearlycalDirective
} from '@app/directives/yearlycal.directive';
import {
    ConsoverComponent
} from '@app/consover/consover.component';
import {
    VmconsoverDirective
} from '@app/directives/vmconsover.directive';

import {
    ClusterSynthesis,
    HostSynthesis,
    Json,
    Message,
    User,
    VmSynthesis
} from '@app/model';

import {
    Observable
} from 'rxjs';

var componentRef: ComponentRef < any > ;


@Component({
    selector: 'app-conscalendar',
    templateUrl: './conscalendar.component.html',
    styleUrls: ['./conscalendar.component.css']
})
export class ConscalendarComponent implements AfterViewChecked, AfterViewInit, OnChanges, OnInit {

    @Input() isreload: boolean = false;

    @ViewChild(WeeklycalDirective, {
        static: false
    }) addWeeklyCal: WeeklycalDirective;
    @ViewChild(MonthlycalDirective, {
        static: false
    }) addMonthlyCal: MonthlycalDirective;
    @ViewChild(YearlycalDirective, {
        static: false
    }) addYearlyCal: YearlycalDirective;
    @ViewChild(VmconsoverDirective, {
        static: false
    }) addVmConsover: VmconsoverDirective;

    message: Message;

    jsonLoader: Json;

    element_selected: any = '';

    cons_selected: string = 'weekly';

    elements: any = [];

    data_clusters: any = []

    data_hosts: any = [];

    data_vms: any = [];

    isSelectElement: boolean = false;

    items: Observable < any > ;

    data_cloud: any = [];

    currentUser: User;


    constructor(
        private router: Router,
        private componentFactoryResolver: ComponentFactoryResolver,
        private account_svc: AccountService,
        private greenit_svc: GreenitService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe((user: User) => {
            this.currentUser = user;
        });

        this.message_svc.currentMessage.subscribe((message: Message) => this.message = message);

        this.initData();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['isreload'].currentValue)
            this.initData();

    }

    ngAfterViewInit(): void {

        if (this.router.url == "/elementsynthesis") {
            this.isSelectElement = true;
            if (this.message.powerUsageEnv == "vmware")
                setTimeout(() => this.loadFirstServer(), 100);

        } else {
            this.message.calcons_counter = 'cpu_usage';
            setTimeout(() => this.loadWeeklyCal(), 100);
        }

    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    initElementList(): void {
        this.element_selected = null;
    }

    loadData(): void {

        componentRef.destroy();

        if (this.router.url == "/elementsynthesis") {
            this.isSelectElement = true;
            this.message.currentUuid = this.element_selected.uuid;
            this.message.currentName = this.element_selected.name;
            this.message.currentType = this.getType(this.element_selected.uuid);
        } else {
            this.isSelectElement = false;
        }

        if (this.message.calcons_counter == "threshold") {
            if (this.message.calcons_rollup == "avg" || this.message.calcons_rollup == "max" || this.message
                .calcons_rollup == "min")
                this.message.calcons_rollup = "10";
        } else {
            if (this.message.calcons_rollup == "10" || this.message.calcons_rollup == "30" || this.message
                .calcons_rollup == "70" ||
                this.message.calcons_rollup == "80" || this.message.calcons_rollup == "90")
                this.message.calcons_rollup = "avg";
        }

        if (this.message.powerUsageEnv == "vmware") {
            if (this.message.currentUuid != '' && this.message.currentType == "VM")
                this.message.vmSynth = this.getVmData(this.message.currentUuid);
            else if (this.message.currentUuid != '' && this.message.currentType == "CLUSTER")
                this.message.clusterSynth = this.getClusterData(this.message.currentUuid);
            else if (this.message.currentUuid != '' && this.message.currentType == "SERVER")
                this.message.hostSynth = this.getHostData(this.message.currentUuid);
        }

        if (this.cons_selected == "monthly") {
            setTimeout(() => this.loadMonthlyCal(), 100);
        } else if (this.cons_selected == "weekly") {
            setTimeout(() => this.loadWeeklyCal(), 100);
        } else if (this.cons_selected == "yearly") {
            setTimeout(() => this.loadYearlyCal(), 100);
        } else if (this.cons_selected == "overall") {
            setTimeout(() => this.loadVmConsOver(), 100);
        }
    }

    private initData(): void {

        if (componentRef != undefined)
            componentRef.destroy();

        switch (this.message.powerUsageEnv) {
            case "vmware":
                this.json_svc.currentJson.subscribe((json: Json) => {
                    this.jsonLoader = json
                    this.elements = this.jsonLoader.dcInfraMin;
                    this.data_vms = this.jsonLoader.vmSynthesis;
                    this.data_clusters = this.jsonLoader.clusterSynthesis;
                    this.data_hosts = this.jsonLoader.hostSynthesis;
                });
                break;
            case "aws":
            case "azure":
            case "gcp":
                setTimeout(() => this.loadCloudElements(), 100);
                break;
            case "kubernetes":
                setTimeout(() => this.loadKubernetesElements(), 100);
                break;
            case "xclarity":
            case "openmanage":
            case "oneview":
            case "ipmi":
                setTimeout(() => this.loadNodeElements(), 100);
                break;
            default:
                break;
        }
    }

    private loadKubernetesElements(): void {

        this.elements = [];

        let uuids: string[] = [];
        this.greenit_svc.getKubeElementsListObservable(this.currentUser.login, this.message.elementView).subscribe(
            (data: any) => {
                if (data.length > 0) {
                    for (let i in data) {
                        if (data[i].father == undefined) {
                            if (!uuids.includes(data[i].uuid)) {
                                uuids.push(data[i].uuid);
                                let el: any = {
                                    uuid: data[i].uuid,
                                    name: data[i].name,
                                    text: data[i].name + '- [' + data[i].resourceType + ']',
                                    type: data[i].resourceType,
                                    account: data[i].account
                                };
                                this.elements.push(el);
                            }
                        } else {
                            if (!uuids.includes(data[i].uuid)) {
                                uuids.push(data[i].uuid);
                                let el: any = {
                                    uuid: data[i].uuid,
                                    name: data[i].name,
                                    text: data[i].name + '- [' + data[i].resourceType + ']',
                                    type: data[i].resourceType,
                                    account: data[i].account
                                };

                                this.elements.push(el);
                            }
                        }
                    }

                    this.element_selected = this.elements[0];
                    this.message.currentUuid = this.element_selected.uuid;
                    this.message.currentName = this.element_selected.name;
                    this.message.currentType = this.element_selected.type;
                    setTimeout(() => this.loadWeeklyCal(), 100);
                }
            }
        );
    }

    private loadCloudElements(): void {

        this.elements = [];

        this.greenit_svc.getRegionsListObservable('', this.message.powerUsageEnv).subscribe(
            (data: any) => {
                if (data.length > 0) {
                    this.message.cloudAccount = data[0].account;
                    this.message.cloudAccountId = data[0].uuid;
                    let uuids: string[] = [];
                    this.greenit_svc.getRegionElementsListObservable('', this.message.powerUsageEnv, this
                            .message.cloudAccountId, this.currentUser.login, this.message.elementView)
                        .subscribe(
                            (data: any) => {
                                if (data.length > 0) {
                                    for (let i in data) {
                                        if (!uuids.includes(data[i].uuid)) {
                                            uuids.push(data[i].uuid);
                                            let el: any = {
                                                uuid: data[i].uuid,
                                                name: data[i].name,
                                                text: data[i].name + '- [' + data[i].resourceType + ']',
                                                type: data[i].resourceType,
                                                account: data[i].account
                                            };
                                            this.elements.push(el);
                                        }
                                    }

                                    this.element_selected = this.elements[0];
                                    this.message.currentUuid = this.element_selected.uuid;
                                    this.message.currentName = this.element_selected.name;
                                    this.message.currentType = this.element_selected.type;
                                    setTimeout(() => this.loadWeeklyCal(), 100);
                                }
                            }
                        );
                }
            }
        );
    }

    private loadNodeElements(): void {

        this.elements = [];

        let uuids: string[] = [];
        this.greenit_svc.getNodeListObservable(this.currentUser.login, this.message.elementView, this.message
            .powerUsageEnv).subscribe(
            (data: any) => {
                if (data.length > 0) {
                    for (let i in data) {
                        if (!uuids.includes(data[i].uuid)) {
                            uuids.push(data[i].uuid);
                            let el: any = {
                                uuid: data[i].uuid,
                                name: data[i].name,
                                text: data[i].name + '- [' + data[i].resourceType + ']',
                                type: data[i].resourceType
                            };

                            this.elements.push(el);
                        }
                    }

                    this.element_selected = this.elements[0];
                    this.message.currentUuid = this.element_selected.uuid;
                    this.message.currentName = this.element_selected.name;
                    this.message.currentType = this.element_selected.type;
                    setTimeout(() => this.loadWeeklyCal(), 100);
                }
            }
        );
    }

    private loadFirstServer(): void {

        if (this.data_hosts != null) {
            this.element_selected = this.data_hosts[0];

            this.message.currentUuid = this.element_selected.uuid;
            this.message.currentName = this.element_selected.name;
            this.message.currentType = "SERVER";

            if (this.message.currentUuid != '' && this.message.currentType == "SERVER")
                this.message.hostSynth = this.getHostData(this.message.currentUuid);

            setTimeout(() => this.loadWeeklyCal(), 100);
        }
    }

    private loadWeeklyCal(): void {

        if (this.addWeeklyCal != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WeeklycalComponent);
            const viewContainerRef = this.addWeeklyCal.viewContainerRef;
            componentRef = viewContainerRef.createComponent(componentFactory);

            this.cd.detectChanges();
        }
    }

    private loadMonthlyCal(): void {

        if (this.addMonthlyCal != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MonthlycalComponent);
            const viewContainerRef = this.addMonthlyCal.viewContainerRef;
            componentRef = viewContainerRef.createComponent(componentFactory);

            this.cd.detectChanges();
        }
    }

    private loadYearlyCal(): void {

        if (this.addYearlyCal != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(YearlycalComponent);
            const viewContainerRef = this.addYearlyCal.viewContainerRef;
            componentRef = viewContainerRef.createComponent(componentFactory);

            this.cd.detectChanges();
        }
    }

    private loadVmConsOver(): void {

        if (this.addVmConsover != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsoverComponent);
            const viewContainerRef = this.addVmConsover.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);

            this.cd.detectChanges();
        }
    }

    private getClusterData(uuid: string): ClusterSynthesis {
        return this.data_clusters.find((cluster: {
            uuid: string;
        }) => cluster.uuid === uuid);
    }

    private getHostData(uuid: string): HostSynthesis {
        return this.data_hosts.find((host: {
            uuid: string;
        }) => host.uuid === uuid);
    }

    private getVmData(uuid: string): VmSynthesis {
        return this.data_vms.find((vm: {
            uuid: string;
        }) => vm.uuid === uuid);
    }

    private getType(uuid: string): string {

        let type: string = '';
        let element: any = this.elements.find((element: {
            uuid: string;
        }) => element.uuid === uuid);
        if (element != undefined)
            type = element.type;

        return type;
    }
}
