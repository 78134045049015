import {
    Directive
} from '@angular/core';

@Directive({
    selector: '[appGraphcounter]'
})
export class GraphcounterDirective {

    constructor() {}

}
