<div class="clr-row" *ngIf="isData">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="line-chart"></cds-icon>&nbsp;Power Graph
                </h5>
                <app-power-combobox (onChange)="updateChart()" [(selectedElements)]="selectedElements" [targetInfrastructure]="targetInfrastructure" style="width:100%;display:inline-block;" *ngIf="isReady"></app-power-combobox>
            </div>

            <div class="card-block" style="width:100%;display:block;">
                <app-power-graph [selectedElements]="selectedElements" [targetInfrastructure]="targetInfrastructure" #powerGraph></app-power-graph>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isData">
    <div class="card">
            <div class="card-block">
                    <div class="card-text">
                            <div>
                                <strong class="center">No data available !</strong>
                            </div>
                    </div>
            </div>
    </div>
</div>