import {
    Component,
    ComponentFactoryResolver,
    ChangeDetectorRef,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    Json,
    Message
} from '@app/model';

import * as Highcharts from 'highcharts';
import * as $ from 'jquery';

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import {
    VminfodetailComponent
} from '@app/vminfo/vminfodetail/vminfodetail.component';
import {
    VminfodetailDirective
} from '@app/directives/vminfodetail.directive';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faTh,
    faChartPie,
    faDatabase
} from '@fortawesome/free-solid-svg-icons';
import {
    faLinux,
    faWindows
} from '@fortawesome/free-brands-svg-icons';


@Component({
    selector: 'app-vminfo',
    templateUrl: './vminfo.component.html',
    styleUrls: ['./vminfo.component.css']
})
export class VminfoComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;
    @ViewChild(VminfodetailDirective) addVminfoDetail: VminfodetailDirective;

    dtOptions: any = {};

    dtTrigger: Subject < any > = new Subject();

    jsonLoader: Json;

    message: Message;

    data_vms: any = [];

    icon_os_ref: any = 'fas';

    icon_os_detail: any = 'th';

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    isReady: boolean = false;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private router: Router,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef,
        private library: FaIconLibrary) {
        library.addIcons(faTh, faLinux, faDatabase, faWindows, faChartPie);
    }

    ngOnInit(): void {

        $("div.dataTables_filter").remove();
        $("dt-buttons").remove();

        this.initDtOptions();

        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
        this.data_vms = this.jsonLoader.vmInfo;
        if (this.data_vms.length > 0)
            this.reloadTable();

        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    ngAfterViewInit(): void {

        this.dtTrigger.next();
        this.getOsDis();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    callroute(vm): void {

        this.message.currentUuid = vm[1];
        this.message.currentName = vm[0];
        this.message.currentType = 'VM';
        this.router.navigate(['/vmviews/synthesis']);
    }

    private getOsDis(): void {

        let nb_vm_os: number[] = this.buildOs();
        this.chartOptions = {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            legend: {
                enabled: true,
                useHTML: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                margin: -10,
                padding: 0,
                itemMarginTop: 5
            },
            tooltip: {
                useHTML: true,
                formatter: function() {
                    return 'Number of virtual machines: <b>' + this.y + '</b> (' + Math.ceil(this.point
                        .percentage) + '%)';
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        distance: -40,
                        style: {
                            fontWeight: 'bold',
                            color: 'white'
                        }
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['60%', '80%'],
                    size: '135%'
                }
            },
            series: [{
                type: 'pie',
                name: 'Number of virtual machines',
                innerSize: '50%',
                showInLegend: true,
                point: {
                    events: {
                        click: function(e) {
                            const p = e.point
                            this.loadOsDetail(p.x);
                        }.bind(this)
                    }
                },
                data: [{
                    name: 'Microsoft',
                    x: 0,
                    y: nb_vm_os[0],
                    color: '#001939',
                    dataLabels: {
                        enabled: true,
                        useHTML: true
                    }
                }, {
                    name: 'Linux',
                    x: 1,
                    y: nb_vm_os[1],
                    color: "#BD0D10",
                    dataLabels: {
                        enabled: true,
                        useHTML: true
                    }
                }, {
                    name: 'Oracle',
                    x: 2,
                    y: nb_vm_os[2],
                    color: "#6B8699",
                    dataLabels: {
                        enabled: true,
                        useHTML: true
                    }
                }, {
                    name: 'Other',
                    x: 3,
                    y: nb_vm_os[3],
                    dataLabels: {
                        enabled: false
                    }
                }]
            }]
        };

        this.isReady = true;
    }

    private buildOs(): number[] {

        let nb_vm_os: number[] = [];
        let nb_win = 0,
            nb_lin = 0,
            nb_ora = 0,
            nb_oth = 0;

        for (var i in this.data_vms.data) {
            if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows nt')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows 2000') ||
                this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2000')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2003')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2008')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2012')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2016')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2019')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2022')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows server threshold')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows xp')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows vista')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows 7')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows 8')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('windows 10')) {
                ++nb_win;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('red hat enterprise') ||
                this.data_vms.data[i][2].toString().toLowerCase().includes('rhel')) {
                ++nb_lin;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('suse linux enterprise')) {
                ++nb_lin;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('debian')) {
                ++nb_lin;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('fedora') ||
                this.data_vms.data[i][2].toString().toLowerCase().includes('centos') ||
                this.data_vms.data[i][2].toString().toLowerCase().includes('ubuntu') ||
                this.data_vms.data[i][2].toString().toLowerCase().includes('autre linux') ||
                this.data_vms.data[i][2].toString().toLowerCase().includes('other linux') ||
                this.data_vms.data[i][2].toString().toLowerCase().includes('later linux') ||
                this.data_vms.data[i][2].toString().toLowerCase().includes('2.6.x linux')) {
                ++nb_lin;
            } else if (this.data_vms.data[i][2].toString().toLowerCase().includes('oracle')) {
                ++nb_ora;
            } else {
                ++nb_oth;
            }
        }

        nb_vm_os[0] = nb_win;
        nb_vm_os[1] = nb_lin;
        nb_vm_os[2] = nb_ora;
        nb_vm_os[3] = nb_oth;

        return nb_vm_os;
    }

    private loadOsDetail(pType: number): void {

        this.message.vmInfoOs = pType;
        switch (pType) {
            case 0:
                this.icon_os_ref = 'fab';
                this.icon_os_detail = 'windows';
                break;
            case 1:
                this.icon_os_ref = 'fab';
                this.icon_os_detail = 'linux';
                break;
            case 2:
                this.icon_os_ref = 'fas';
                this.icon_os_detail = 'database';
                break;
            case 3:
                this.icon_os_ref = 'fas';
                this.icon_os_detail = 'th';
                break;
            default:
                break;
        }
        if (this.addVminfoDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(VminfodetailComponent);
            const viewContainerRef = this.addVminfoDetail.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                $("dt-buttons").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
                $('div.dt-buttons').appendTo('span.export');
                $("[id*='vm-info-list_length']").css({
                    'padding-top': '5px'
                });
                $("[name*='vm-info-list_length']").css({
                    'padding': '0px'
                });
            },
            language: {
                search: '',
                searchPlaceholder: " search..."
            },
            dom: '<"top"B><"clear">frtlip',
            buttons: [{
                extend: 'csv',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'copy',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'print',
                className: 'btn btn-link btn-sm'
            }]
        };
    }
}
