import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    JsonloaderService,
    ManagementService,
    ShareService
} from '@app/services';

import {
    COST,
    Json,
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    getUserCurrency
} from '../../../assets/js/tools.js';


@Component({
    selector: 'app-coststogeneric',
    templateUrl: './coststogeneric.component.html',
    styleUrls: ['./coststogeneric.component.css']
})
export class CoststogenericComponent implements AfterViewInit, OnInit {

    message: Message;

    globalCurrency: string = '';

    model: any;

    private currentUser: User;

    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private management_svc: ManagementService,
        private data: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        this.data.currentMessage.subscribe(message => this.message = message);

        this.model = {
            datastoreCap: 0,
            stoCostGeneric: 1
        };

        const infra_ds: any[] = this.json_svc.json.storageOverviewsDatastoreData;
        if (infra_ds.length > 0) {
            let sto_cap: number = 0;
            for (var i = 0; i < infra_ds.length; i++) {
                sto_cap += infra_ds[i].capacity;
            }
            this.model.datastoreCap = Math.round(sto_cap / 1024);
        }
    }

    ngAfterViewInit(): void {
        this.getCost();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    enableStoUpdate(): void {

        this.message.isCostRecord = true;
        this.message.costStoGeneric = this.model.stoCostGeneric;
    }

    private getCost(): void {

        this.management_svc.getCost('root').pipe(first()).subscribe(
            data => {
                if (data.STO_COST != -1)
                    this.model.stoCostGeneric = data.STO_COST;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }
}
