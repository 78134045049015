import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    delay,
    first,
    map
} from 'rxjs/operators';

import {
    Counter,
    FilterMgt,
    GreenitSettings,
    InstanceType
} from '@app/model';
import {
    environment
} from '@environments/environment';

import {
    JsonloaderService
} from '@app/services/jsonloader.service';
import {
    ManagementService
} from '@app/services/management.service';
import {
    BehaviorSubject,
    forkJoin,
    Observable,
    of
} from 'rxjs';
import {
    GreenitTarget
} from '../greenit/greenit.enums';

@Injectable({
    providedIn: 'root'
})
export class GreenitService {

    greenit_settings: GreenitSettings[] = [];
    network_data_check: boolean = false;
    storage_data_check: boolean = false;

    currentSettings: BehaviorSubject < GreenitSettings[] > = new BehaviorSubject < GreenitSettings[] > (this
        .greenit_settings);

    private networkDataCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .network_data_check);
    networkDataCheck: Observable < boolean > = this.networkDataCheckSubject.asObservable();

    private storageDataCheckSubject: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (this
        .storage_data_check);
    storageDataCheck: Observable < boolean > = this.storageDataCheckSubject.asObservable();

    constructor(
        private http: HttpClient,
        private json_svc: JsonloaderService,
        private mgt_svc: ManagementService) {}

    getGreenitSettings() {
        return this.http.get < GreenitSettings[] > (`${environment.apiUrl}/` + 'greenit/getgreenitsettings').pipe(
            map(settings => {
                this.currentSettings.next(settings);
                return settings;
            }));
    }

    updateGreenitSettings(settings: JSON) {
        return this.http.post < GreenitSettings[] > (`${environment.apiUrl}/` + 'greenit/updategreenitsettings',
            settings);
    }

    // CHECK NETWORK DATA (STATIC DEVICES CAN BE ADDED)
    //XXX Extrapolation only for root
    checkGreenitNetworkData(user: string, filter: FilterMgt) {
        if (user == "root" && filter.element_view == "all") {
            this.getGreenitData(user, filter.name, GreenitTarget.NETWORK_CO2).subscribe(
                data => {
                    if (data) {
                        let data_length = ( < any > data).length;
                        if (data_length > 0) {
                            let value = data[data_length - 1].device;
                            if (value && value > 0) {
                                this.networkDataCheckSubject.next(true);
                            } else {
                                this.networkDataCheckSubject.next(false);
                            }
                        } else {
                            this.networkDataCheckSubject.next(false);
                        }
                    }
                },
                error => {
                    // Nothing already in console
                }
            );
        } else {
            // consider there is data so not activate extrapolation
            this.networkDataCheckSubject.next(true);
        }
    }

    // CHECK STORAGE DATA (STATIC DEVICES CAN BE ADDED)
    //XXX Extrapolation only for root
    checkGreenitStorageData(user: string, filter: FilterMgt) {
        if (user == "root" && filter.element_view == "all") {
            this.getGreenitData(user, filter.name, GreenitTarget.STORAGE_CO2).subscribe(
                data => {
                    if (data) {
                        let data_length = ( < any > data).length;
                        if (data_length > 0) {
                            let value = data[data_length - 1].device;
                            if (value && value > 0) {
                                this.storageDataCheckSubject.next(true);
                            } else {
                                this.storageDataCheckSubject.next(false);
                            }
                        } else {
                            this.storageDataCheckSubject.next(false);
                        }
                    }
                },
                error => {
                    // Nothing already in console
                }
            );
        } else {
            // consider there is data so not activate extrapolation
            this.storageDataCheckSubject.next(true);
        }
    }

    getGreenitData(user: string, filter: string, target: GreenitTarget, time: Number = 0) {
        let requestBody = {
            user: user,
            filter: filter,
            resource: target,
            time: time,
        };
        let endpointName = '';
        switch (target) {
            // Settings
            case GreenitTarget.SERVERS_SETTINGS:
                endpointName = "serverssettings";
                break;
            case GreenitTarget.NETWORK_SETTINGS:
                endpointName = "networksettings";
                break;
            case GreenitTarget.STORAGE_SETTINGS:
                endpointName = "storagesettings";
                break;
            case GreenitTarget.DC_HOST_MINMAX:
                endpointName = 'dchostminmax';
                break;
            case GreenitTarget.KUBE_NODE_MINMAX:
                endpointName = 'kubeminmax';
                break;
                // Data
            case GreenitTarget.ALL_CO2:
                endpointName = 'allco2';
                break;
            case GreenitTarget.GLOBAL_CO2:
                endpointName = 'globalco2';
                break;
            case GreenitTarget.DC_HOST_CEE:
                endpointName = "dchostcee";
                break;
            case GreenitTarget.DC_HOST_CO2:
                endpointName = "dchostco2";
                break;
            case GreenitTarget.DC_HOST_WH:
                endpointName = "dchostwh";
                break;
            case GreenitTarget.FILTER_DC_CO2_DATA:
                endpointName = 'filterdcco2';
                break;
            case GreenitTarget.FILTER_DC_HOST_ANALYZES:
                endpointName = 'filterdchostanalyzes';
                break;
            case GreenitTarget.FILTER_DC_HOST_NB:
                endpointName = "filterdchostnumber";
                break;
            case GreenitTarget.FILTER_DC_VM_PERHOST:
                endpointName = 'filterdcvmperhost';
                break;
            case GreenitTarget.FILTER_DC_VM_PERSOCKET:
                endpointName = 'filterdcvmpersocket';
                break;
            case GreenitTarget.FILTER_DC_VM_POWER:
                endpointName = 'filterdcvmpower';
                break;
            case GreenitTarget.IPMI_CO2:
                endpointName = 'ipmico2';
                break;
            case GreenitTarget.IPMI_NODE_CEE:
                endpointName = 'ipmicee';
                break;
            case GreenitTarget.IPMI_NODE_WH:
                endpointName = 'ipmiwh';
                break;
            case GreenitTarget.ONEVIEW_CO2:
                endpointName = 'oneviewco2';
                break;
            case GreenitTarget.ONEVIEW_NODE_CEE:
                endpointName = 'oneviewcee';
                break;
            case GreenitTarget.OPENMANAGE_CO2:
                endpointName = 'openmanageco2';
                break;
            case GreenitTarget.OPENMANAGE_NODE_CEE:
                endpointName = 'openmanagecee';
                break;
            case GreenitTarget.SCAPHANDRE_CO2:
                endpointName = 'scaphandreco2';
                break;
            case GreenitTarget.SCAPHANDRE_NODE_WH:
                endpointName = 'scaphandrewh';
                break;
            case GreenitTarget.SCAPHANDRE_NODE_CEE:
                endpointName = 'scaphandrecee';
                break;
            case GreenitTarget.XCLARITY_CO2:
                endpointName = 'xclarityco2';
                break;
            case GreenitTarget.XCLARITY_NODE_CEE:
                endpointName = 'xclaritycee';
                break;
            case GreenitTarget.NETWORK_CO2:
                endpointName = 'networkco2';
                break;
            case GreenitTarget.NETWORK_WH:
                endpointName = 'networkwh';
                break;
            case GreenitTarget.STORAGE_CO2:
                endpointName = 'storageco2';
                break;
            case GreenitTarget.STORAGE_WH:
                endpointName = 'storagewh';
                break;
            case GreenitTarget.DESKTOP_CO2:
                endpointName = "desktopco2";
                break;
            case GreenitTarget.PIE_CO2:
                endpointName = 'pieco2';
                break;
            case GreenitTarget.PIE_CO2G:
                endpointName = 'pieco2g';
                break;
            case GreenitTarget.PIE_STORAGE_CO2:
                endpointName = 'piestorageco2';
                break;
            case GreenitTarget.PIE_STORAGE_CO2G:
                endpointName = 'piestorageco2g';
                break;
            default:
                let error = "undefined greenit endpoint for '" + target + "'";
                console.log(error);
                alert(error);
        }
        return this.http.post(`${environment.apiUrl}/` + 'greenit/' + endpointName, requestBody).pipe(map(data => {
            return data;
        }));
    }

    updateServersMinMax(data: JSON) {
        return this.http.post(`${environment.apiUrl}/` + 'greenit/updateserversminmax', data);
    }

    updateGreenitIndicatorsServers(data: JSON) {
        return this.http.post(`${environment.apiUrl}/` + 'greenit/updategreenitindicatorsservers/', data);
    }

    updateGreenitIndicatorsNetwork(data: JSON) {
        return this.http.post(`${environment.apiUrl}/` + 'greenit/updategreenitindicatorsnetwork/', data);
    }

    updateGreenitIndicatorsStorage(data: JSON) {
        return this.http.post(`${environment.apiUrl}/` + 'greenit/updategreenitindicatorsstorage/', data);
    }

    runGreenit(user: string, filter: string) {
        return this.http.post(`${environment.apiUrl}/` + 'greenit/runoptimize', [user, filter]);
    }

    clearHistory() {
        return this.http.post(`${environment.apiUrl}/` + 'greenit/clearhistory', []);
    }

    getElementsListObservable(pattern: string): Observable < string[] > {
        return of(this.getFilteredElementsList(pattern)).pipe(delay(0));
    }

    private getFilteredElementsList(pattern: string): string[] {
        let resources = [];

        let hosts = this.json_svc.json.hostSynthesis;
        let vms = this.json_svc.json.vmSynthesis;

        // Add the resourceType HOST
        if (hosts != null) {
            for (let resource of hosts) {
                resource.resourceType = "HOST";
            }
            resources.push(hosts);
        }

        // Add the resourceType VM
        if (vms != null) {
            for (let resource of vms) {
                resource.resourceType = "VM";
            }
            resources.push(vms);
        }

        let result = resources
            .flat()
            .filter((o) => (o.name.toLowerCase().indexOf(pattern.toLowerCase()) > -1));

        return result.slice(0, 20);
    }

    getHostsListObservable(pattern: string): Observable < string[] > {
        return of(this.getHostsList(pattern)).pipe(delay(0));
    }

    private getHostsList(pattern: string): string[] {
        let resources = [];

        let hosts = this.json_svc.json.hostSynthesis;

        // Add the resourceType HOST
        if (hosts != null) {
            for (let resource of hosts) {
                resource.resourceType = "HOST";
            }
            resources.push(hosts);
        }

        let result = resources
            .flat()
            .filter((o) => (o.name.toLowerCase().indexOf(pattern.toLowerCase()) > -1));

        return result.slice();
    }

    getCountersListObservable(target: string): Observable < any[] > {
        return of(this.getCountersList(target)).pipe(delay(0));
    }

    private getCountersList(target: string): any[] {
        const rollups = ["AVG"];

        let countersConfig: any = "";

        switch (target) {
            case InstanceType.XCLARITY:
            case InstanceType.OPENMANAGE:
            case InstanceType.ONEVIEW:
            case InstanceType.IPMI:
            case InstanceType.STORAGE:
                countersConfig = {
                    power: {
                        key: "POWER",
                        icon: "fa fa-sitemap",
                        defaultCounters: [{
                            key: "POWER_FLOAT",
                            rollup: "AVG"
                        }],
                        counters: {
                            POWER_FLOAT: {
                                name: 'Power',
                                unit: 'Watts',
                                conversionFactor: 1,
                                rollup: rollups,
                                appliesTo: ["XCLARITY_NODE", "OPENMANAGE_NODE", "ONEVIEW_NODE", "IPMI_NODE",
                                    "EXAGRID_SAN", "IBM_SAN"
                                ]
                            }
                        }
                    }
                };
                break;
            default:
                countersConfig = {
                    power: {
                        key: "POWER",
                        icon: "fa fa-sitemap",
                        defaultCounters: [{
                            key: "POWER",
                            rollup: "AVG"
                        }],
                        counters: {
                            POWER: {
                                name: 'Power',
                                unit: 'Watts',
                                conversionFactor: 1,
                                rollup: rollups,
                                appliesTo: ["VM", "HOST"]
                            }
                        }
                    }
                };
                break;
        }

        let result = Object
            .entries(countersConfig.power.counters)
            .map(([k, v]) => v["rollup"].length == 0 ? [
                [k, v, undefined, k]
            ] : v["rollup"].map((r) => [k + " - " + r, v, r, k]))
            .flat()
            .map(([k, v, r, k_orig]) => Object({
                name: k,
                label: r != undefined ? `${v["name"]} - ${r} (${v["unit"]})` :
                    `${v["name"]} (${v["unit"]})`,
                description: v,
                metricName: k.indexOf("-") == -1 ? k : (x => x.split(" - ").reverse().join("_"))(k),
                // See graph-on-demand.service.ts : add the "metricNameMinutely" in each counters
                metricNameMinutely: k_orig
            }));

        return result;
    }

    getDataObservable(uuids: string[], startTime: number, endTime: number, requiredCounters: any[], showLast:
        boolean): Observable < Object > {
        let observables = uuids.map((r: any) => {
            return this.getData(r, startTime, endTime, requiredCounters, showLast, "");
        });
        return forkJoin(observables);
    }

    /**
     * Same as graph-on-demand.service.ts
     */
    private getData(r: any, startTime: number, endTime: number, requiredCounters: any[], showLast: boolean, lastHash:
        string) {
        let granularity = this.getGranularity(startTime, endTime);
        let resourceType = r["resourceType"].toUpperCase();
        let uuids = [r["uuid"]];

        let promiseResult = new Promise((resolve, reject) => {
            this.getCountersValues(resourceType, uuids, startTime, endTime, requiredCounters, granularity
                .granularity).subscribe((resp) => {

                // @ts-ignore
                if (resp.length > 0) {
                    let currentHash = `${granularity.granularity}_${startTime}_${endTime}`;

                    if (!showLast || (showLast && !granularity.alternativeLowerGranularity) ||
                        lastHash == currentHash) {
                        resolve(resp);
                    } else {
                        // @ts-ignore
                        const lastItem = resp[resp.length - 1];

                        if (lastItem.time == endTime) {
                            resolve(resp);
                        } else {
                            this.getData(r, lastItem.time, endTime, requiredCounters, showLast,
                                currentHash).then((resp2) => {
                                let additionalElement = [];
                                // @ts-ignore
                                if (resp2.length > 0) {
                                    // @ts-ignore
                                    additionalElement.push(resp2[resp2.length - 1]);
                                }
                                // @ts-ignore
                                resolve(resp.concat(additionalElement));

                            }).catch((reason) => {
                                console.error(`cannot fetch more precise results: ${reason}`);
                                console.error(
                                    'I am sending the results with an higher granularity');
                                resolve(resp);
                            });
                        }
                    }
                } else {
                    this.getCountersValues(resourceType, uuids, startTime - granularity
                        .alternativeRangeExtension, endTime + granularity.alternativeRangeExtension,
                        requiredCounters, granularity.alternativeGranularity).subscribe((resp2) => {
                        resolve(resp2);
                    });
                }

            })
        });
        return promiseResult;
    }


    private getCountersValues(resourceType: string, uuids: any, start: number, end: number, requiredCounters: any[],
        granularity: any) {
        let request = {
            "aggregationGranularity": granularity,
            "resourceType": resourceType,
            "timeInterval": {
                "startTime": start,
                "endTime": end
            },
            "resourceFilter": {
                "fields": ["UUID"],
                "values": [uuids]
            },
            "requiredCounters": requiredCounters
        };

        return this.http.post < Counter[] > (`${environment.apiUrl}/` + 'v1/counterswithrequired', request).pipe(map(
            data => {
                return data;
            },
            error => {
                console.log(error);
            }
        ));
    }

    /**
     * Same as graph-on-demand.service.ts
     */
    private getGranularity(start: number, end: number): any {

        // Compute granularity
        let durationInDays = (end - start) / (24 * 3600 * 1000);

        let granularity: string;
        let alternativeGranularity: string;
        let alternativeLowerGranularity: string;
        let alternativeRangeExtension: number = 0;

        if (durationInDays < 1) {
            granularity = "MINUTELY";
            alternativeGranularity = "HOURLY";
            alternativeRangeExtension = 2 * 3600 * 1000;
        } else if (durationInDays < 30) {
            granularity = "HOURLY";
            alternativeGranularity = "DAILY";
            alternativeLowerGranularity = "MINUTELY";
            alternativeRangeExtension = 2 * 24 * 3600 * 1000;
        } else if (durationInDays < 365) {
            granularity = "DAILY";
            alternativeGranularity = "MONTHLY";
            alternativeLowerGranularity = "HOURLY";
            alternativeRangeExtension = 2 * 30 * 24 * 3600 * 1000;
        } else {
            granularity = "DAILY";
            alternativeGranularity = "MONTHLY";
            alternativeLowerGranularity = "HOURLY";
            alternativeRangeExtension = 2 * 30 * 24 * 3600 * 1000;
        }

        let result: any = {};
        result.granularity = granularity;
        result.alternativeGranularity = alternativeGranularity;
        result.alternativeLowerGranularity = alternativeLowerGranularity;
        result.alternativeRangeExtension = alternativeRangeExtension;

        return result;
    }

    /**
     * AWS / AZURE
     */

    getRegionsListObservable(pattern: string, provider: string): Observable < any[] > {
        return of(this.getRegionssList(pattern, provider)).pipe(delay(500));
    }

    private getRegionssList(pattern: string, provider: string): string[] {

        let regions: any[] = [];

        this.mgt_svc.getRegions(provider).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    for (let i in data) {
                        let region: any = {
                            account: data[i].INSTANCE_NAME,
                            uuid: data[i].INSTANCE_ID
                        };
                        regions.push(region);
                    }
                }
            }
        );

        return regions;
    }

    getRegionElementsListObservable(pattern: string, provider: string, accountId: string, user: string, view:
        string): Observable < any[] > {
        return of(this.getFilteredRegionElementsList(pattern, provider, accountId, user, view)).pipe(delay(500));
    }

    private getFilteredRegionElementsList(pattern: string, provider: string, account: string, user: string, view:
        string): string[] {

        let resources: any[] = [];

        let pi: any = {
            provider: provider,
            account: account,
            user: user,
            view: view
        }

        this.http.post < any > (`${environment.apiUrl}/` + 'greenit/getinstances', pi).pipe(first()).subscribe(
            data => {
                for (let i in data) {
                    let instance: any = {
                        uuid: data[i].IDENTIFIER,
                        iid: data[i].INSTANCE_ID,
                        name: data[i].NAME,
                        resourceType: 'INSTANCE',
                        account: account,
                        father: data[i].REGION_NAME
                    };
                    resources.push(instance);
                }
            }
        );

        return resources;
    }

    getCloudData(account: string, uuid: string): any {
        let pi: any = {
            account: account,
            uuid: uuid
        };
        return this.http.post < any > (`${environment.apiUrl}/` + 'greenit/getdailypower', pi)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * KUBERNETES
     */
    getKubeNodeListObservable(user: string, view: string): Observable < any[] > {
        return of(this.getKubeNodesList(user, view)).pipe(delay(500));
    }

    private getKubeNodesList(user: string, view: string): string[] {

        let nodes: string[] = [];

        this.mgt_svc.getKubernetesNodes(user, view).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    for (let i in data) {
                        let node: any = {
                            cluster: data[i].CLUSTER,
                            name: data[i].NAME,
                            resourceType: 'NODE',
                            uuid: data[i].IDENTIFIER
                        };
                        nodes.push(node);
                    }
                }
            }
        );

        return nodes;
    }

    getKubeElementsListObservable(user: string, view: string): Observable < any[] > {
        return of(this.getFilteredKubeElementsList(user, view)).pipe(delay(500));
    }

    private getFilteredKubeElementsList(user: string, view: string): string[] {

        let resources = [];

        this.mgt_svc.getKubernetesNodes(user, view).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    for (let i in data) {
                        let pod: any = {
                            name: data[i].NAME,
                            resourceType: 'NODE',
                            uuid: data[i].IDENTIFIER,
                            account: data[i].NAME,
                            father: data[i].FATHERNAME
                        };
                        resources.push(pod);
                    }
                }
            }
        );

        this.mgt_svc.getPods(user, view).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    for (let i in data) {
                        let pod: any = {
                            name: data[i].NAME,
                            resourceType: 'POD',
                            uuid: data[i].IDENTIFIER,
                            account: data[i].NAME,
                            father: data[i].FATHERNAME
                        };
                        resources.push(pod);
                    }
                }
            }
        );

        return resources;
    }

    /**
     * SCAPHANDRE
     */
    async getScaphandreElementsList(user: string, filter: string): Promise < string[] > {
        let resources = [];

        let nodes = await this.getScaphandreNodes(user, filter).toPromise();

        if (( < any > nodes).length > 0) {
            for (let i in nodes) {
                let data: any = nodes[i];
                let node: any = {
                    name: data.name,
                    resourceType: 'NODE',
                    uuid: data.identifier
                };
                resources.push(node);
            }
        }

        return resources;
    }

    private getScaphandreNodes(user: string, filter: string) {
        let requestBody = {
            user: user,
            filter: filter,
            resource: 'scaphandre.nodes',
        };
        return this.http.post(`${environment.apiUrl}/` + 'greenit/scaphandrenodes', requestBody).pipe(map(data => {
            return data;
        }));
    }

    getDataScaphandreObservable(instances: string[], startTime: number, endTime: number): Observable < Object > {
        let observable = this.getDataScaphandre(instances, startTime, endTime);
        return forkJoin(observable);
    }

    private getDataScaphandre(instances: any, startTime: number, endTime: number) {
        //FIX minRange (round hour value) in order to fix average compute by Promotheus
        let minDate: Date = new Date(startTime);
        let millis = minDate.getMilliseconds();
        let secs = minDate.getSeconds();
        let mins = minDate.getMinutes();
        let total = millis + (secs * 1000) + (mins * 60000);
        let newmin = minDate.getTime() - total;
        startTime = newmin;

        // SET granularity
        let granularities = this.getGranularity(startTime, endTime);
        let granularity: string = "1d";
        let alternative_granularity = "1h";

        if (granularities.granularity == "HOURLY")
            granularity = "1h";


        let promiseResult = new Promise((resolve, reject) => {
            this.getPowerScaphandre2(instances, startTime, endTime, granularity).subscribe((resp) => {
                // @ts-ignore
                if (resp.length > 0) {
                    resolve(resp);
                } else {
                    this.getPowerScaphandre2(instances, startTime - granularities
                        .alternativeRangeExtension, endTime + granularities
                        .alternativeRangeExtension, alternative_granularity).subscribe((resp2) => {
                        resolve(resp2);
                    });
                }
            })
        });
        return promiseResult;
    }

    private getPowerScaphandre2(instances: any, startTime: number, endTime: number, granularity: string) {
        let request: any = {
            start: startTime / 1000,
            end: endTime / 1000,
            step: granularity,
            instances: instances.join('|')
        };

        return this.http.post < any > (`${environment.apiUrl}/greenit/` + 'getpowerscaphandre', request).pipe(map(
            data => {
                return data;
            },
            error => {
                console.log(error);
            }
        ));
    }

    /**
     * XCLARITY
     */
    async getXClarityElementsList(user: string, filter: string): Promise < string[] > {
        let resources = [];

        let nodes = await this.getXClarityNodes(user, filter).toPromise();

        if (( < any > nodes).length > 0) {
            for (let i in nodes) {
                let data: any = nodes[i];
                let node: any = {
                    name: data.name,
                    resourceType: 'XCLARITY_NODE',
                    uuid: data.identifier
                };
                resources.push(node);
            }
        }

        return resources;
    }

    private getXClarityNodes(user: string, filter: string) {
        let requestBody = {
            user: user,
            filter: filter,
            resource: 'xclarity.nodes',
            time: 0,
        };
        return this.http.post(`${environment.apiUrl}/` + 'greenit/xclaritynodes', requestBody)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * OPENMANAGE
     */
    async getOpenmanageElementsList(user: string, filter: string): Promise < string[] > {
        let resources = [];

        let nodes = await this.getOpenmanageNodes(user, filter).toPromise();

        if (( < any > nodes).length > 0) {
            for (let i in nodes) {
                let data: any = nodes[i];
                let node: any = {
                    name: data.name,
                    resourceType: 'OPENMANAGE_NODE',
                    uuid: data.identifier
                };
                resources.push(node);
            }
        }

        return resources;
    }

    private getOpenmanageNodes(user: string, filter: string) {
        let requestBody = {
            user: user,
            filter: filter,
            resource: 'openmanage.nodes',
            time: 0,
        };
        return this.http.post(`${environment.apiUrl}/` + 'greenit/openmanagenodes', requestBody)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * ONEVIEW
     */
    async getOneviewElementsList(user: string, filter: string): Promise < string[] > {
        let resources = [];

        let nodes = await this.getOneviewNodes(user, filter).toPromise();

        if (( < any > nodes).length > 0) {
            for (let i in nodes) {
                let data: any = nodes[i];
                let node: any = {
                    name: data.name,
                    resourceType: 'ONEVIEW_NODE',
                    uuid: data.identifier
                };
                resources.push(node);
            }
        }

        return resources;
    }

    private getOneviewNodes(user: string, filter: string) {
        let requestBody = {
            user: user,
            filter: filter,
            resource: 'oneview.nodes',
            time: 0,
        };
        return this.http.post(`${environment.apiUrl}/` + 'greenit/oneviewnodes', requestBody)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * IPMI
     */
    async getIpmiElementsList(user: string, filter: string): Promise < string[] > {
        let resources = [];

        let nodes = await this.getIpmiNodes(user, filter).toPromise();

        if (( < any > nodes).length > 0) {
            for (let i in nodes) {
                let data: any = nodes[i];
                let node: any = {
                    name: data.name,
                    resourceType: 'IPMI_NODE',
                    uuid: data.identifier
                };
                resources.push(node);
            }
        }

        return resources;
    }

    private getIpmiNodes(user: string, filter: string) {
        let requestBody = {
            user: user,
            filter: filter,
            resource: 'ipmi.nodes',
            time: 0,
        };
        return this.http.post(`${environment.apiUrl}/` + 'greenit/ipminodes', requestBody)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * STORAGE
     */
    async getStorageElementsList(user: string, filter: string): Promise < string[] > {
        let resources = [];

        let nodes = await this.getStorageNodes(user, filter).toPromise();

        if (( < any > nodes).length > 0) {
            for (let i in nodes) {
                let data: any = nodes[i];
                let node: any = {
                    name: data.name,
                    resourceType: data.type,
                    uuid: data.identifier
                };
                resources.push(node);
            }
        }

        return resources;
    }


    private getStorageNodes(user: string, filter: string) {
        let requestBody = {
            user: user,
            filter: filter,
            resource: 'storage.nodes',
            time: 0,
        };
        return this.http.post(`${environment.apiUrl}/` + 'greenit/storagenodes', requestBody)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * For calendar
     */
    getNodeListObservable(user: string, view: string, infra: string): Observable < any[] > {
        let query: string = '';
        switch (infra) {
            case "xclarity":
                query = 'getxclaritynodes';
                break;
            case "openmanage":
                query = 'getopenmanagenodes';
                break;
            case "oneview":
                query = 'getoneviewnodes';
                break;
            case "ipmi":
                query = 'getipminodes';
                break;
            default:
                break;
        }
        return of(this.getNodeList(user, view, query)).pipe(delay(500));
    }

    /**
     * For calendar
     */
    private getNodeList(user: string, view: string, query: string): string[] {
        let nodes: string[] = [];

        this.mgt_svc.getNodes(user, view, query).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    for (let i in data) {
                        let node: any = {
                            name: data[i].name,
                            resourceType: 'NODE',
                            uuid: data[i].uuid
                        };
                        nodes.push(node);
                    }
                }
            }
        );

        return nodes;
    }

    /**
     * For calendar
     */
    getPowerNode(pi: any, query: string) {
        return this.http.post < any > (`${environment.apiUrl}/greenit/` + query, pi).pipe(map(result => {
            return result;
        }));
    }
}

