import {
    Component,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Observable
} from 'rxjs';
import {
    tap
} from 'rxjs/internal/operators';
import {
    JsonloaderService,
    SettingsService,
    ShareService
} from "@app/services";
import {
    GraphOnDemandService
} from "@app/services/graph-on-demand.service";
import {
    ClrCombobox
} from "@clr/angular";
import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
import {
    GraphOnDemandComboboxComponent
} from "@app/graph-on-demand/graph-on-demand-combobox/graph-on-demand-combobox.component";
import {
    GraphOnDemandGraphComponent
} from "@app/graph-on-demand/graph-on-demand-graph/graph-on-demand-graph.component";
import {
    ActivatedRoute
} from "@angular/router";

HC_stock(Highcharts);

@Component({
    selector: 'app-graph-on-demand',
    templateUrl: './graph-on-demand.component.html',
    styleUrls: ['./graph-on-demand.component.css']
})
export class GraphOnDemandComponent implements OnInit {

    selectedResources: any[] = [];
    selectedCountersCpu: any[] = [];
    selectedCountersRam: any[] = [];
    selectedCountersDisk: any[] = [];
    selectedCountersNetwork: any[] = [];

    selectedCountersAll: any[] = [];

    categories: string[] = ["cpu", "ram", "disk", "network"];

    @ViewChild('resourceComboBox') resourceComboBox: ClrCombobox < string > ;
    @ViewChild('cpuComboBox') cpuComboBox: ClrCombobox < string > ;
    @ViewChild('ramComboBox') ramComboBox: ClrCombobox < string > ;
    @ViewChild('diskComboBox') diskComboBox: ClrCombobox < string > ;
    @ViewChild('networkComboBox') networkComboBox: ClrCombobox < string > ;
    @ViewChild('AllComboBox') allComboBox: ClrCombobox < string > ;

    @ViewChild('cpuGraph') cpuGraphRef: GraphOnDemandGraphComponent;
    @ViewChild('ramGraph') ramGraphRef: GraphOnDemandGraphComponent;
    @ViewChild('diskGraph') diskGraphRef: GraphOnDemandGraphComponent;
    @ViewChild('networkGraph') networkGraphRef: GraphOnDemandGraphComponent;
    @ViewChild('allGraph') allGraphRef: GraphOnDemandGraphComponent;

    // Can be 'separated_graphs' or 'merged_graphs'
    graphMergedOption = false
    graphsPresentationMode = "separated_graphs";
    // graphsPresentationMode = "merged_graphs";


    onSetExtremesChange: EventEmitter < any > = new EventEmitter();

    ihmCounters = {
        cpu: "",
        ram: "",
        disk: "",
        network: "",
    };

    chartRefs: any = {};

    minRange = 0;
    maxRange = 2553270400000;

    constructor(private jsonLoaderService: JsonloaderService, public route: ActivatedRoute,
        private graphOnDemandService: GraphOnDemandService, private settingsService: SettingsService,
        public shareService: ShareService) {}

    ngOnInit(): void {
        this.route.queryParamMap
            .subscribe((paramMap) => {
                this.settingsService.currentGraphOnDemandSettings.subscribe((ihmSettingsGraphOndemand) => {
                    // Extract data contained in table "btrpersist_management.ihm_settings". Each piece of data is currently
                    // a single string value.
                    this.ihmCounters.cpu = ihmSettingsGraphOndemand.p2;
                    this.ihmCounters.ram = ihmSettingsGraphOndemand.p1;
                    this.ihmCounters.disk = ihmSettingsGraphOndemand.p3;
                    this.ihmCounters.network = ihmSettingsGraphOndemand.p4;

                    let useResourceCountersFor = paramMap["params"]["useResourceCountersFor"];
                    if (useResourceCountersFor == "SERVER" || useResourceCountersFor == "VM") {
                        this.ihmCounters.cpu = useResourceCountersFor == "SERVER" ? "AVG_CPU_USAGE" :
                            "AVG_CPU_USAGE_EXTRA";
                        this.ihmCounters.ram = "AVG_RAM_VMMEMCTL";
                        this.ihmCounters.disk = "AVG_DISK_IO";
                        this.ihmCounters.network = "AVG_NET_IO";
                    }

                    this.updateCounters();
                    this.reloadUi("*");
                })
            });

        this.jsonLoaderService.currentJson.subscribe((json) => {
            // Check if the url is in the form of '/god/resource/:resource_uuid'. If this is the case, the following block
            // find the resource corresponding to the resource_uuid, and initializes the graphs with it.
            this.route.params.subscribe(params => {
                if (params.resource_uuid !== undefined) {
                    // Resource is passed in the form of '/god/resource/:resource_uuid'
                    this.graphOnDemandService
                        .getResources(params.resource_uuid)
                        .subscribe((data) => {
                            // @ts-ignore
                            this.selectedResources = data.filter((d) => d.uuid == params.resource_uuid);
                            this.updateCounters();
                            setTimeout(() => this.reloadUi("*"));
                        });
                }
            });

            this.shareService.currentMessage.subscribe((msg) => {
                this.onSetExtremesChange.emit([null, msg.minTimeFilter, msg.maxTimeFilter]);
            });

            // Check if selected resources are available with the current filter
            if (this.selectedResources.length > 0) {
                this.graphOnDemandService
                    .getResources("")
                    .subscribe((data) => {
                        // @ts-ignore
                        let dataUuids = data.map((r) => r.uuid);
                        let updatedSelectedResources = this.selectedResources.filter((r) => dataUuids
                            .indexOf(r.uuid) != -1);
                        let currentResourcesSignature = this.selectedResources.map((r) => r.uuid).sort()
                            .join("__");
                        let updatedSelectedResourcesSignature = updatedSelectedResources.map((r) => r
                            .uuid).sort().join("__");
                        if (currentResourcesSignature != updatedSelectedResourcesSignature) {
                            this.selectedResources = updatedSelectedResources;
                            this.updateCounters();
                            setTimeout(() => this.reloadUi("*"));
                        }
                    })
            }
        });

        setInterval(() => {
            this.syncAllComboboxes();
        }, 500);

        this.onSetExtremesChange.subscribe((c) => {
            this.minRange = c[1];
            this.maxRange = c[2];
        });
    }

    graphModeChanged = () => {
        console.log("graphModeChanged()");
        setTimeout(() => {
            this.reloadUi("*", this.minRange, this.maxRange);
        }, 100);
    }

    updateCounters = () => {
        for (let category of this.categories) {
            this.graphOnDemandService.getCounters(category, "").subscribe(countersResult => {
                let defaultCounterForThisCategory = this.ihmCounters[category];
                let countersNames = countersResult.map((c) => c.metricName);
                if (countersNames.indexOf(defaultCounterForThisCategory) != -1) {
                    this.ihmCounters[category] = defaultCounterForThisCategory;
                } else if (countersNames.indexOf(`AVG_${defaultCounterForThisCategory}`) != -1) {
                    this.ihmCounters[category] = `AVG_${defaultCounterForThisCategory}`;
                } else {
                    this.ihmCounters[category] = countersResult[0].metricName;
                }

                let countersMatchingDefault = countersResult.filter((c) => c.metricName == this.ihmCounters[
                    category]);
                this.setCategoryCounters(category, countersMatchingDefault);
            })
        }
        this.syncAllComboboxes(true, "*");
    }

    syncAllComboboxes(ignoreAllCounterHasChanged = false, category = "*") {
        let allCounters = [];

        if (this.selectedCountersCpu == null) {
            this.selectedCountersCpu = [];
        }
        if (this.selectedCountersRam == null) {
            this.selectedCountersRam = [];
        }
        if (this.selectedCountersDisk == null) {
            this.selectedCountersDisk = [];
        }
        if (this.selectedCountersNetwork == null) {
            this.selectedCountersNetwork = [];
        }
        if (this.selectedCountersAll == null) {
            this.selectedCountersAll = [];
        }

        if (category != "all") {
            allCounters.push(...this.selectedCountersCpu);
            allCounters.push(...this.selectedCountersRam);
            allCounters.push(...this.selectedCountersDisk);
            allCounters.push(...this.selectedCountersNetwork);
        } else {
            allCounters.push(...this.selectedCountersAll);
        }

        // Filter redundant counters
        function onlyUnique(value, index, self) {
            return self.map((c) => c.name).indexOf(value.name) === index;
        }
        let allUniqueCounters = allCounters.filter(onlyUnique);

        function countersArrayEqual(a1, a2) {
            let a1Signature = a1.map((c) => c.metricName).join("_");
            let a2Signature = a2.map((c) => c.metricName).join("_");
            return a1Signature == a2Signature;
        }

        // Sync CPU counters
        let cpuCounters = allCounters.filter((c) => c.description.category == "cpu");
        if (category != "cpu" && !countersArrayEqual(cpuCounters, this.selectedCountersCpu)) {
            this.selectedCountersCpu = cpuCounters;
        }
        // Sync RAM counters
        let ramCounters = allCounters.filter((c) => c.description.category == "ram");
        if (category != "ram" && !countersArrayEqual(ramCounters, this.selectedCountersRam)) {
            this.selectedCountersRam = ramCounters;
        }
        // Sync DISK counters
        let diskCounters = allCounters.filter((c) => c.description.category == "disk");
        if (category != "disk" && !countersArrayEqual(diskCounters, this.selectedCountersDisk)) {
            this.selectedCountersDisk = diskCounters;
        }
        // Sync NETWORK counters
        let networkCounters = allCounters.filter((c) => c.description.category == "network");
        if (category != "network" && !countersArrayEqual(networkCounters, this.selectedCountersNetwork)) {
            this.selectedCountersNetwork = networkCounters;
        }
        // Sync ALL counters
        if (category != "all" && !countersArrayEqual(allCounters, this.selectedCountersAll)) {
            this.selectedCountersAll = allCounters;
        }

        return allUniqueCounters;
    }

    fetchResources = (filter = '', combobox: GraphOnDemandComboboxComponent) => {
        combobox.loadingResources = true;
        combobox.asyncResources$ = this
            .graphOnDemandService
            .getResources(filter)
            .pipe(tap(() => {
                combobox.loadingResources = false;
                combobox.sendArrowDown();
            }));
    }

    fetchCounters = (category: string, filter = '', combobox: GraphOnDemandComboboxComponent) => {
        combobox.loadingResources = true;
        combobox.asyncResources$ = this
            .graphOnDemandService
            .getCounters(category, filter)
            .pipe(tap(() => {
                setTimeout(() => {
                    combobox.loadingResources = false;
                    if (combobox.resourceComboBox.focused) {
                        setTimeout(() => {
                            combobox.sendArrowDown();
                        }, 100);
                    }
                })
            }));
    }

    fetchCountersAll = (filter = '', combobox: GraphOnDemandComboboxComponent) => {
        this.fetchCounters("*", filter, combobox);
    };

    fetchCountersCpu = (filter = '', combobox: GraphOnDemandComboboxComponent) => {
        this.fetchCounters("cpu", filter, combobox);
    };

    fetchCountersRam = (filter = '', combobox: GraphOnDemandComboboxComponent) => {
        this.fetchCounters("ram", filter, combobox);
    };

    fetchCountersDisk = (filter = '', combobox: GraphOnDemandComboboxComponent) => {
        this.fetchCounters("disk", filter, combobox);
    };

    fetchCountersNetwork = (filter = '', combobox: GraphOnDemandComboboxComponent) => {
        this.fetchCounters("network", filter, combobox);
    };

    setCategoryCounters = (category: string, value) => {
        if (category == "cpu") {
            this.selectedCountersCpu = value;
        }
        if (category == "ram") {
            this.selectedCountersRam = value;
        }
        if (category == "disk") {
            this.selectedCountersDisk = value;
        }
        if (category == "network") {
            this.selectedCountersNetwork = value;
        }
        if (category == "all") {
            this.selectedCountersAll = value;
        }
    }

    getCategoryCounters = (category: string) => {
        if (category == "cpu") {
            return this.selectedCountersCpu;
        }
        if (category == "ram") {
            return this.selectedCountersRam;
        }
        if (category == "disk") {
            return this.selectedCountersDisk;
        }
        if (category == "network") {
            return this.selectedCountersNetwork;
        }
        if (category == "all") {
            return this.selectedCountersAll;
        }
        throw "Error: category is unknown (please use 'cpu', 'ram', 'disk', 'network' or 'all')";
    }

    chartCallback = (category: string): Highcharts.ChartCallbackFunction => {
        return (chart) => {
            if (this.chartRefs.hasOwnProperty(category) && this.chartRefs[category] != undefined) {
                return;
            }
            console.log(category);
            this.chartRefs[category] = chart;
            setTimeout(() => {
                chart.reflow();
            });
        };
    };

    chartCallbackCpu = (chart): void => {
        return this.chartCallback("cpu")(chart);
    };

    chartCallbackRam = (chart): void => {
        return this.chartCallback("ram")(chart);
    };

    chartCallbackAll = (chart): void => {
        return this.chartCallback("all")(chart);
    };

    chartCallbackDisk = (chart): void => {
        return this.chartCallback("disk")(chart);
    };

    chartCallbackNetwork = (chart): void => {
        return this.chartCallback("network")(chart);
    };

    fetchData(category: string): Observable < Object > {
        return this.graphOnDemandService.fetchData(this.selectedResources != null ? this.selectedResources : [],
            this.getCategoryCounters(category));
    }

    fetchSqlData(category: string, min: number, max: number, extendsWithLowerGranularity: boolean = true): Observable <
        Object > {
            return this.graphOnDemandService.fetchSqlData(this.selectedResources != null ? this.selectedResources : [],
                min, max, this.getCategoryCounters(category), extendsWithLowerGranularity);
        }

    fetchNavigatorDataCpu = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("cpu", min, max, true);
    };

    fetchDataCpu = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("cpu", min, max);
    };

    fetchNavigatorDataRam = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("ram", min, max, true);
    };

    fetchDataRam = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("ram", min, max);
    };

    fetchNavigatorDataDisk = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("disk", min, max, true);
    };

    fetchDataDisk = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("disk", min, max);
    };

    fetchNavigatorDataNetwork = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("network", min, max, true);
    };

    fetchDataNetwork = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("network", min, max);
    };

    fetchNavigatorDataAll = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("all", min, max, true);
    };

    fetchDataAll = (min: number, max: number): Observable < Object > => {
        return this.fetchSqlData("all", min, max);
    };

    reloadUi(category: string, newMinRange = undefined, newMaxRange = undefined) {
        return setTimeout(() => {
            if ((category == "*" || category === "cpu") && !this.graphMergedOption) {
                if (this.cpuGraphRef != undefined) {
                    this.cpuGraphRef.reloadDataChart("cpu", newMinRange, newMaxRange);
                }
            }
            if ((category == "*" || category === "ram") && !this.graphMergedOption) {
                if (this.ramGraphRef != undefined) {
                    this.ramGraphRef.reloadDataChart("ram", newMinRange, newMaxRange);
                }
            }
            if ((category == "*" || category === "disk") && !this.graphMergedOption) {
                if (this.diskGraphRef != undefined) {
                    this.diskGraphRef.reloadDataChart("disk", newMinRange, newMaxRange);
                }
            }
            if ((category == "*" || category === "network") && !this.graphMergedOption) {
                if (this.networkGraphRef != undefined) {
                    this.networkGraphRef.reloadDataChart("network", newMinRange, newMaxRange);
                }
            }
            if ((category == "*" || category === "all") && this.graphMergedOption) {
                if (this.allGraphRef != undefined) {
                    this.allGraphRef.reloadDataChart("all", newMinRange, newMaxRange);
                }
            }
        });
    }
}

