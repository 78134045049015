<div class="clr-row">
        <div class="card" *ngIf="!isSelected">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				Instance turn off
			</span>
			<span *ngIf="!selected_behavior" style="margin-left:100px;" class="label label-info">
				Pick an instance to view its idle thresholds
			</span>
			<button class="btn btn-link" style="position:relative;float:right;" (click)="exportBehavior()">
				Export
			</button>
                </div>
                <div class="card-block">
			<div class="datagrid-limit-height">
				<clr-datagrid [(clrDgSingleSelected)]="selected_beh" (clrDgSingleSelectedChange)="behChanged($event)">
					<clr-dg-column [clrDgField]="'uuid'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'region'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Region</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'behavior'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Behavior</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'cpuRef'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">vCPU Reference (%)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'runH'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute time in month (hour)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'computeCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute cost ($)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'action'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Action</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let behavior of behaviors" [clrDgItem]="behavior">
						<clr-dg-cell>{{behavior.uuid}}</clr-dg-cell>
						<clr-dg-cell>{{behavior.name}}</clr-dg-cell>
						<clr-dg-cell>{{behavior.subscriptionDesc.region}}</clr-dg-cell>
						<clr-dg-cell>{{behavior.idleThreshold}}</clr-dg-cell>
						<clr-dg-cell>{{behavior.cpuRef}}</clr-dg-cell>
						<clr-dg-cell>{{behavior.runH}}</clr-dg-cell>
						<clr-dg-cell>{{behavior.computeCost.toFixed(2)}}</clr-dg-cell>
						<clr-dg-cell>{{behavior.action}}</clr-dg-cell>
					</clr-dg-row>
					<clr-dg-footer>
                				<clr-dg-pagination #pagination [clrDgPageSize]="10">
                    					<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
								    entries per page
							</clr-dg-page-size>
                    					{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                				</clr-dg-pagination>
            				</clr-dg-footer>
				</clr-datagrid>
			</div>
                </div>
        </div>
	<div class="card" *ngIf="isSelected">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				Idle thresholds for {{selected_behavior.name}}
			</span>
			<span style="float: right;" ngClass="addLink" (click)="isSelected = false">
				<cds-icon shape="times"></cds-icon>
			</span>
                </div>
                <div class="card-block">
			<div class="datagrid-limit-height">
				<clr-datagrid>
					<clr-dg-column>ID</clr-dg-column>
					<clr-dg-column>Name</clr-dg-column>
					<clr-dg-column>Behavior</clr-dg-column>
					<clr-dg-column>vCPU Reference (%)</clr-dg-column>
					<clr-dg-column>Compute time in month (hour)</clr-dg-column>
					<clr-dg-column>Compute cost ($)</clr-dg-column>
					<clr-dg-column>Action</clr-dg-column>
					<clr-dg-row>
						<clr-dg-cell>{{selected_behavior.uuid}}</clr-dg-cell>
						<clr-dg-cell>{{selected_behavior.name}}</clr-dg-cell>
						<clr-dg-cell>{{selected_behavior.idleThreshold}}</clr-dg-cell>
						<clr-dg-cell>{{selected_behavior.cpuRef}}</clr-dg-cell>
						<clr-dg-cell>{{selected_behavior.runH}}</clr-dg-cell>
						<clr-dg-cell>{{selected_behavior.computeCost}}</clr-dg-cell>
						<clr-dg-cell>{{selected_behavior.action}}</clr-dg-cell>
					</clr-dg-row>	
				</clr-datagrid>
				<br>
				<span style="color:grey;font-size:18px;">
					Idle thresholds
				</span>
				<clr-datagrid>
					<clr-dg-column [clrDgField]="'behavior'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Threshold (%)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'value'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Idle</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'behavior_time'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute Idle time in month (%)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'idle_cost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute idle cost ($)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'resizing_action'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Action</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let threshold of selected_behavior.b_info" [clrDgItem]="threshold">
						<clr-dg-cell>{{threshold.behavior}}</clr-dg-cell>
						<clr-dg-cell>{{threshold.value}}</clr-dg-cell>
						<clr-dg-cell>{{threshold.behavior_time}}</clr-dg-cell>
						<clr-dg-cell>{{threshold.idle_cost.toFixed(2)}}</clr-dg-cell>
						<clr-dg-cell>{{threshold.resizing_action}}</clr-dg-cell>
					</clr-dg-row>
				</clr-datagrid>
			</div>
		</div>
	</div>
</div>