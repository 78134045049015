import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    Observable
} from 'rxjs';

import {
    AccountService,
    DataService,
    GreenitService,
    JsonloaderService,
    ShareService
} from '@app/services';
import {
    Json,
    Message,
    User
} from '@app/model';

import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);

import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
import export_data from 'highcharts/modules/export-data';
exporting(Highcharts);
offline(Highcharts);
export_data(Highcharts);


@Component({
    selector: 'app-power-vm-stacked',
    templateUrl: './power-vm-stacked.component.html',
    styleUrls: ['./power-vm-stacked.component.css']
})
export class PowerVmStackedComponent implements OnInit {

    @Input()
    targetInfrastructure: string = 'vmware';
    getTargetInfrastructure() {
        return this.targetInfrastructure;
    }

    currentUser: User;

    message: Message;

    jsonLoader: Json;

    @Input()
    selectedElements: any = [];
    getSelectedElements() {
        return this.selectedElements != null ? this.selectedElements : [];
    }

    granularity: string = 'DAILY';

    start: number = 0;

    end: number = 0;

    updateFlag: boolean = false;

    data_vms: any = [];

    data_instances: Observable < any > ;

    isReady: boolean = false;

    noDataMessage: string;

    vms: any = [];

    uuids: string[] = [];

    i: number = 0;

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartOptions: Highcharts.Options = {
        chart: {
            type: 'area',
            zoomType: 'x',
            events: {
                redraw: function() {
                    this.hideLoading();
                }
            }
        },
        credits: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        legend: {
            enabled: true,
            align: 'left',
            verticalAlign: 'top',
            borderWidth: 0,
            maxHeight: 70,
        },
        exporting: {
            enabled: true,
            fallbackToExportServer: false,
        },
        navigation: {
            buttonOptions: {
                align: 'right',
                verticalAlign: 'top',
                y: 0
            }
        },
        navigator: {
            enabled: true
        },
        rangeSelector: {
            inputEnabled: false,
            enabled: true
        },
        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                }
            }
        },
        scrollbar: {
            enabled: true
        },
        xAxis: {
            type: 'datetime',
            minRange: 3600 * 1000,
            events: {
                setExtremes: (event) => {
                    ++this.i;
                    if (event.trigger && (event.trigger == "zoom")) {
                        this.start = event.min != undefined ? Math.round(event.min / 1000) : this.message
                            .minTimeFilter / 1000;
                        this.end = event.max != undefined ? Math.round(event.max / 1000) : this.message
                            .maxTimeFilter / 1000;
                    } else if (event.trigger && (event.trigger == "rangeSelectorButton" || event.trigger ==
                            "navigator")) {
                        this.start = this.message.minTimeFilter / 1000;
                        this.end = this.message.maxTimeFilter / 1000;
                    } else {
                        this.start = this.message.minTimeFilter / 1000;
                        this.end = this.message.maxTimeFilter / 1000;
                    }

                    if (this.end - this.start <= 604800)
                        this.granularity = 'HOURLY';
                    else
                        this.granularity = 'DAILY';

                    if (this.i == 1) {
                        if (this.getTargetInfrastructure() == "vmware")
                            this.loadGraph();
                    }

                    //console.log(this.start + " :: " + this.end + " :: " + this.granularity + " :: " + (this.end - this.start))
                    //console.log(this.message.minTimeFilter/1000 + " :: " + this.message.maxTimeFilter/1000);            
                }
            }
        },
        yAxis: {
            title: {
                text: 'Watts'
            },
            opposite: false,
        },
        series: []
    };

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
    };


    constructor(
        private account_svc: AccountService,
        private counter_svc: DataService,
        private message_svc: ShareService,
        private greenit_svc: GreenitService,
        private json_svc: JsonloaderService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.start = this.message.minTimeFilter / 1000;
        this.end = this.message.maxTimeFilter / 1000;

        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);

        if (this.getTargetInfrastructure() == "vmware") {
            this.data_vms = this.jsonLoader.vmSynthesis;
            for (let i = 0; i < this.selectedElements.length; i++) {
                for (let j = 0; j < this.data_vms.length; j++) {
                    if (this.selectedElements[i].uuid === this.data_vms[j].father) {
                        this.uuids.push(this.data_vms[j].uuid);
                        let vm: any = {
                            uuid: this.data_vms[j].uuid,
                            name: this.data_vms[j].name
                        };
                        this.vms.push(vm);
                    }
                }
            }
            this.noDataMessage = "No virtual machine";
            this.loadGraph();
        } else if (this.getTargetInfrastructure() == "kubernetes") {
            // NOTHING TODO
        } else {
            // NOTHING TODO
        }
    }

    private loadGraph(): void {

        if (this.chart != undefined)
            this.chart.showLoading();

        if (this.uuids.length > 0) {
            let powerInfo: any = {
                start: this.start,
                end: this.end,
                granularity: this.granularity,
                data: this.uuids
            };
            this.counter_svc.getPowerAllVms(powerInfo).subscribe(
                data => {
                    this.buildSeries(data);
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );
        }
    }

    private buildSeries(data: any) {

        let vms_serie: any = [];

        this.isReady = true;
        this.chart.showLoading();

        // BUILD SERIES
        let uuids: string[] = [];
        for (let i = 0; i < data.length; i++) {
            if (!uuids.includes(data[i].uuid)) {
                uuids.push(data[i].uuid);
                for (let j = 0; j < this.vms.length; j++) {
                    if (this.vms[j].uuid === data[i].uuid) {
                        let vm_data: any = [];
                        let vm_serie = {
                            uuid: this.vms[j].uuid,
                            name: this.vms[j].name,
                            data: vm_data
                        };
                        vms_serie.push(vm_serie);
                        break;
                    }
                }
            }
        }

        // SET SERIES DATA
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < vms_serie.length; j++) {
                if (data[i].uuid === vms_serie[j].uuid) {
                    let val: any = [];
                    val[0] = data[i].time * 1000;
                    val[1] = data[i].power;
                    vms_serie[j].data.push(val);
                    break;
                }
            }
        }

        this.chartOptions.series = vms_serie;
        this.updateFlag = true;
        //if(this.chart != undefined)
        // this.chart.hideLoading();

        this.i = 0;
        this.isReady = true;
    }
}
