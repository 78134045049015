<div style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-flow: column;">

    <clr-alert [clrAlertType]="'danger'" [clrAlertAppLevel]="true" *ngIf="failureMode && !isLoading">
        <clr-alert-item>
            <span class="alert-text">Cannot load data from DC Netscope API. Please check the diagnostic page.</span>
            <div class="alert-actions">
                <a routerLink="/netscope/diagnostic" routerLinkActive="active">
                    <button class="btn alert-action">
                        <cds-icon shape="checkbox-list"></cds-icon>
                        Diagnostic
                    </button>
                </a>
            </div>
        </clr-alert-item>
    </clr-alert>

    <div class="clr-row" style="margin: 0px; background-color: rgb(0, 55, 76); color : white; overflow: visible; min-height: 74px;">
        <div class="clr-col" id="flow_view_title" style="flex-grow: 0; color: white; text-align: center;">
            <table style="height: 100%; min-width: 100px; font-weight: 400; font-size: .8rem; letter-spacing: .01em; text-decoration: none;">
                <tr>
                    <td>
                        <cds-icon shape="radar" style="margin-right: 10px; margin-bottom: 3px;" size="md"></cds-icon>
                    </td>
                    <td style="text-align: left;">{{ "dcnetscope.supervision_name" | transloco }}</td>
                </tr>
            </table>
        </div>

        <!-- Empty column that separates title and group of buttons at the right -->
        <div class="clr-col" style="padding-top: 10px; padding-bottom: 5px;">
        </div>

        <div class="clr-col" style="width: 100px; flex-grow: 0; padding-left: 0px;">
            <table style="height: 100%;">
                <tr>
                    <td style="padding: 5px;">
                        <a href="javascript://" id="flow_view_reload_button" class="nav-link nav-icon" aria-label="reload">
                            <cds-icon shape="refresh" (click)="reloadData()" *ngIf="!isLoading" style="color: white;"></cds-icon>
                            <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <a href="javascript://" class="nav-link nav-icon" aria-label="tour">
                            <cds-icon shape="help" style="color: white;" (click)="launchTour()"></cds-icon>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <clr-dropdown [clrCloseMenuOnItemClick]="true">
                            <a clrDropdownTrigger href="javascript://" class="nav-link nav-icon" aria-label="settings">
                                <cds-icon shape="cog" style="color: white;"></cds-icon>
                            </a>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Language</label>
                                <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="setLanguage('french')">French</div>
                                <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="setLanguage('english')">English</div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="clr-row" style="position: relative; height: 100%; bottom: 0px; width: 100%; margin: 0px;">
        <clr-tabs style="background-color: white; width: 100%; height: 100%;">

            <clr-tab>
                <button id="supervision_reports_button" clrTabLink style="margin-left: 25px;">{{ 'dcnetscope.supervision_reports_tab' | transloco }}</button>
                <clr-tab-content>
                    <div style="padding: 15px;">
                        <app-netscope-reports #reportsComponent (loadingStateEmitter)="updateLoadingState($event)" (errorStateEmitter)="updateErrorState($event)"></app-netscope-reports>
                    </div>
                </clr-tab-content>
            </clr-tab>

            <clr-tab>
                <button id="supervision_troubleshooting_button" clrTabLink>{{ 'dcnetscope.supervision_outside_flows_tab' | transloco }}</button>
                <clr-tab-content *clrIfActive>
                    <div style="padding: 15px;">
                        <app-netscope-outside-flows #troubleshootingComponent (loadingStateEmitter)="updateLoadingState($event)" (errorStateEmitter)="updateErrorState($event)"></app-netscope-outside-flows>
                    </div>
                </clr-tab-content>
            </clr-tab>

            <clr-tab>
                <button id="supervision_threats_button" clrTabLink>{{ 'dcnetscope.supervision_threats_tab' | transloco }}</button>
                <clr-tab-content *clrIfActive>
                    <div style="padding: 15px;">
                        <app-netscope-threat-center #threatCenterComponent (loadingStateEmitter)="updateLoadingState($event)" (errorStateEmitter)="updateErrorState($event)"></app-netscope-threat-center>
                    </div>
                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
</div>