import {
    ChangeDetectorRef,
    AfterContentChecked,
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ComponentRef
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    ClrWizard,
    ClrSelectedState
} from "@clr/angular";

import {
    AccountService,
    InfrastructureService,
    JsonloaderService,
    ManagementService,
    ShareService,
    UploadService
} from '@app/services';

import {
    ElementView,
    FilterMgt,
    JSONTarget,
    Message,
    SelectionFilter,
    SelectionFilterRec,
    Parent,
    Searchbar,
    User,
    CsvElementView
} from '@app/model';

import {
    SelectiontreeComponent
} from '@app/selectiontree/selectiontree.component';
import {
    SelectiontreeDirective
} from '@app/directives/selectiontree.directive';
import {
    WizardselectiontreeDirective
} from '@app/directives/wizardselectiontree.directive';
import {
    UploadType
} from '../upload/upload.enums';
import Papa, {
    ParseResult
} from "papaparse";


@Component({
    selector: 'app-selectionview',
    templateUrl: './selectionview.component.html',
    styleUrls: ['./selectionview.component.css']
})
export class SelectionviewComponent {

    message: Message;

    @ViewChild("wizardSelectionView") wizardsv: ClrWizard;

    @ViewChild(SelectiontreeDirective) addTree: SelectiontreeDirective;
    @ViewChild(WizardselectiontreeDirective) addWizardTree: WizardselectiontreeDirective;

    model: any;

    tree_view: any;

    tree_view_eg: any[] = [];

    usr_views: ElementView[] = [];

    current_view_type: string = 'this view is based on a SERVER type tree structure';

    current_view_type_eg: string = 'server';

    items: any;

    info: boolean = false;

    info_type: string = 'SERVER';

    element_lst: SelectionFilterRec[] = [];

    hierarchy_lst: string[] = [];

    filter_view: FilterMgt[] = [];

    isDeleteView: boolean = false;

    isDeleteError: boolean = false;

    isUpdModel: boolean = false;

    currentUser: User;

    connectors: string[] = [];

    private componentRef: ComponentRef < any > ;

    private componentWizardRef: ComponentRef < any > ;

    private isValidFilter: boolean = false;

    //FOR UPLOAD
    view: UploadType;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private authentication_svc: AccountService,
        private infra_svc: InfrastructureService,
        private json_svc: JsonloaderService,
        private management_svc: ManagementService,
        private message_svc: ShareService,
        private upload_svc: UploadService,
        private cd: ChangeDetectorRef) {
        //FOR UPLOAD
        this.view = UploadType.VIEW;
    }

    getItemChildren = (item: {
        children: any;
    }) => item.children;

    ngOnInit() {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.message.elementViewUser = this.currentUser.login;

        this.model = {
            name: '',
            method: 'manual',
            error: false,
            errorName: ''
        };

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.HOST_VIEW_EXAMPLE)
            .subscribe(
                data => {
                    this.tree_view_eg = data;
                }
            );

        this.management_svc.getConnectors().subscribe(
            data => {
                if (data.length > 0) {
                    for (var i = 0; i < data.length; ++i) {
                        this.connectors.push(data[i].connector);
                    }
                }
            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );
    }

    ngAfterViewInit(): void {

        this.getElementView();
        this.setTree();
    }

    ngAfterViewChecked() {

        if (this.current_view_type_eg != this.message.elementViewType)
            this.setInfo();

        this.cd.detectChanges();
    }

    initWizard(): void {

        this.info = false;
        this.message.isTreeAnalysis = false;
        this.message.elementViewContext = 'multi';

        if (this.componentRef != undefined)
            this.componentRef.destroy();
    }

    checkName(val: any): void {

        if (this.filterList(val)) {
            this.model.error = true;
            this.model.errorName = 'to ' + val + ' (view already exists)';
        } else if (val == "all" || val == "ALL") {
            this.model.error = true;
            this.model.errorName = 'to ' + val;
        } else if (val.length == 0) {
            this.model.error = true;
            this.model.errorName = 'empty';
        } else if (val.length > 0) {
            this.model.error = false;

            //Remove accent
            var accent = [
                /[\300-\306]/g, /[\340-\346]/g, // A, a
                /[\310-\313]/g, /[\350-\353]/g, // E, e
                /[\314-\317]/g, /[\354-\357]/g, // I, i
                /[\322-\330]/g, /[\362-\370]/g, // O, o
                /[\331-\334]/g, /[\371-\374]/g, // U, u
                /[\321]/g, /[\361]/g, // N, n
                /[\307]/g, /[\347]/g, // C, c
            ];
            var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

            for (var i = 0; i < accent.length; i++) {
                this.model.name = this.model.name.replace(accent[i], noaccent[i]);
            }

            //Remove specs chars
            this.model.name = this.model.name.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:.;?,<> ]/g, "_");
        }
    }

    setInfo(): void {

        this.current_view_type_eg = this.message.elementViewType;
        switch (this.message.elementViewType) {
            case "server":
                this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                    .HOST_VIEW_EXAMPLE).subscribe(
                    data => {
                        this.tree_view_eg = data;
                    }
                );
                this.info_type = 'SERVER';
                break;
            case "folder":
                this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                    .FD_VIEW_EXAMPLE).subscribe(
                    data => {
                        this.tree_view_eg = data;
                    }
                );
                this.info_type = 'FOLDER';
                break;
            case "rp":
                this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                    .RP_VIEW_EXAMPLE).subscribe(
                    data => {
                        this.tree_view_eg = data;
                    }
                );
                this.info_type = 'RESOURCE POOL';
                break;
            case "tag":
                this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget
                    .TAG_VIEW_EXAMPLE).subscribe(
                    data => {
                        this.tree_view_eg = data;
                    }
                );
                this.info_type = 'TAG';
                break;
            default:
                break;
        }
        this.info = true;
    }

    callWizardTree(): void {
        setTimeout(() => this.loadWizardTree(), 100);
    }

    loadFileData(): void {
        setTimeout(() => this.loadWizardTree(), 100);
        setTimeout(() => this.analyseTreeFromFile(), 500);
    }

    updateChange(): void {

        this.element_lst = this.buildElementToRec(this.message.elementViewSelected);

        if (this.element_lst.length > 0 && this.isValidFilter) {
            this.management_svc.addSelectionList(this.element_lst).subscribe(
                complete => {
                    this.updateView(this.message.elementViewSelected);
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );
        }
        this.message.isTreeAnalysis = false;
    }

    isDeletable(): void {

        for (var i = 0; i < this.message.filterList.length; i++) {
            if (this.message.filterList[i].element_view == this.message.elementViewSelected)
                this.filter_view.push(this.message.filterList[i]);
        }

        if (this.filter_view.length > 0) {
            this.isDeleteView = false;
            this.isDeleteError = true;
        } else {
            this.isDeleteError = false;
            this.isDeleteView = true;
        }
    }

    removeView(): void {

        if (this.message.elementViewSelected != "")
            this.deleteSelectionView(this.message.elementViewSelected);
    }


    selectALL(): void {

        if (this.message.elementViewTree != null) {
            for (var i = 0; i < this.message.elementViewTree.length; ++i) {
                this.message.elementViewTree[i].selected = ClrSelectedState.SELECTED;
                this.enableChild(this.message.elementViewTree[i]);
            }
        }
    }

    unSelect(): void {

        if (this.message.elementViewTree != null) {
            for (var i = 0; i < this.message.elementViewTree.length; ++i) {
                this.message.elementViewTree[i].selected = ClrSelectedState.UNSELECTED;
                this.disableChild(this.message.elementViewTree[i]);
            }
        }
    }

    goBack(): void {
        this.message.isTreeAnalysis = false;
    }

    doFinish(): void {

        this.element_lst = this.buildElementToRec(this.model.name);

        if (this.element_lst.length > 0 && this.isValidFilter) {
            this.management_svc.addSelectionList(this.element_lst).subscribe(
                complete => {
                    this.updateView(this.model.name);
                    this.doReset();
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );
        } else {
            this.doReset();
            this.message.waiting = false;
            alert('error to create view: no vm selected !');
        }
    }

    doReset(): void {

        this.isValidFilter = false;
        this.message.isTreeAnalysis = false;
        this.model.name = '';
        this.wizardsv.reset();
        this.setTree();
        this.getElementView();
    }

    filterList(val: string): boolean {

        let isInFilter: boolean = false;
        for (var i in this.usr_views) {
            if (this.usr_views[i].FILTER == val) {
                isInFilter = true;
                break;
            }
        }

        return isInFilter;
    }

    filterView(): ElementView[] {

        let views_auth: ElementView[] = [];
        for (var i in this.usr_views) {
            if (this.usr_views[i].FILTER != "all" && this.usr_views[i].FILTER != "last_1D" && this.usr_views[i]
                .FILTER != "last_30D" &&
                this.usr_views[i].FILTER != "last_60D" && this.usr_views[i].FILTER != "last_180D" && this.usr_views[
                    i].FILTER != "last_360D") {
                views_auth.push(this.usr_views[i]);
            }
        }

        return views_auth;
    }

    callCollapse(): void {

        this.tree_view = this.message.elementViewTree;

        for (var i = 0; i < this.tree_view.length; ++i) {
            this.tree_view[i].opened = false;
        }
    }

    callExpand(): void {

        this.tree_view = this.message.elementViewTree;

        for (var i = 0; i < this.tree_view.length; ++i) {
            this.tree_view[i].opened = true;
            this.expandChild(this.tree_view[i]);
        }
    }

    switchView(): void {

        this.filter_view = [];

        let selected_view = this.getView(this.message.elementViewSelected);
        if (selected_view != undefined) {
            this.message.elementViewContext = selected_view.CONTEXT;
            switch (this.message.elementViewContext) {
                case "multi":
                case "xclarity":
                case "scaphandre":
                case "kubernetes":
                case "aws":
                case "azure":
                case "openmanage":
                case "oneview":
                case "network":
                case "storage":
                case "ipmi":
                case "exagrid":
                case "ibm":
                    this.current_view_type = 'this view is based on a node type tree structure in ' + this.message
                        .elementViewContext + ' context';
                    this.message.elementViewType = 'node';
                    break;
                case "vmware":
                    if (selected_view.SRV_STATE == 1) {
                        this.current_view_type = 'this view is based on a server type tree structure in ' + this
                            .message.elementViewContext + ' context';
                        this.message.elementViewType = 'server';
                    } else if (selected_view.FD_STATE == 1) {
                        this.current_view_type = 'this view is based on a folder type tree structure in ' + this
                            .message.elementViewContext + ' context';
                        this.message.elementViewType = 'folder';
                    } else if (selected_view.RP_STATE == 1) {
                        this.current_view_type = 'this view is based on a resource POOL type tree structure in ' +
                            this.message.elementViewContext + ' context';
                        this.message.elementViewType = 'rp';
                    } else if (selected_view.TAG_STATE == 1) {
                        this.current_view_type = 'this view is based on a tag type tree structure in ' + this
                            .message.elementViewContext + ' context';
                        this.message.elementViewType = 'tag';
                    }
                    break;
                default:
                    break;
            }

            this.setTree();
        }
    }

    callUpdate(): void {

        this.message.genericInfoType = 'info';
        this.message.genericInfo = 'filter ' + this.message.currentFilter + ' processing ...';
        this.message.isNoInfo = false;
        this.message.waiting = true;
        this.infra_svc.updateModel('OD').subscribe(
            complete => {
                this.message.genericInfo = 'filter ' + this.message.currentFilter + ' is ready to use';
                this.message.isNoInfo = false;
                this.json_svc.changeJson(this.currentUser.login, this.message.currentFilter);

            },
            error => {
                if (error != null) {
                    this.message.isNoInfo = true;
                    this.message.waiting = false;
                    console.log(error);
                }
            }
        );
    }

    initFromFile(): void {

        this.message.elementViewContext = 'multi';
        this.message.elementViewType = "server";
    }

    private updateView(viewname: string): void {

        const view_upd: any = {
            user: this.currentUser.login,
            view: viewname
        };
        this.management_svc.updateView(view_upd).pipe(first()).subscribe(
            success => {

            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private setTree(): void {
        setTimeout(() => this.loadTree(), 100);
    }

    private loadTree(): void {

        if (this.componentRef != undefined)
            this.componentRef.destroy();

        if (this.componentWizardRef != undefined)
            this.componentWizardRef.destroy();

        this.message.isElementWizard = false;
        if (this.addTree != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectiontreeComponent);
            const viewContainerRef = this.addTree.viewContainerRef;
            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent(componentFactory);
            setTimeout(() => this.loadElements(), 500);
            setTimeout(() => this.message.isTreeAnalysis = false, 600);
        }
    }

    private loadWizardTree(): void {

        if (this.componentRef != undefined)
            this.componentRef.destroy();

        if (this.componentWizardRef != undefined)
            this.componentWizardRef.destroy();

        this.message.isElementWizard = true;

        if (this.addWizardTree != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectiontreeComponent);
            const viewContainerRef = this.addWizardTree.viewContainerRef;
            viewContainerRef.clear();
            this.componentWizardRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private analyseTreeFromFile(): void {

        this.callExpand();
        this.upload_svc.getViewFile().subscribe(
            data => {
                Papa.parse(data, {
                    header: true,
                    skipEmptyLines: true,
                    delimiter: ",",
                    complete: (results: ParseResult < CsvElementView > ) => {
                        //console.log(results.data);
                        let res: any = results.data;

                        for (let i = 0; i < res.length; i++) {
                            for (var j = 0; j < this.message.elementViewTree.length; ++j) {
                                this.actionOnNode(this.message.elementViewTree[j], res[i].name);
                            }
                        }
                    }
                });
            },
            error => {}
        );
    }

    private actionOnNode(node: any, targetName: string): void {

        if (node != undefined) {
            if (node.children != undefined) {
                if (node.children.length > 0) {
                    for (let i = 0; i < node.children.length; ++i) {
                        if (node.children[i].name == targetName) {
                            //console.log(targetName);
                            node.children[i].selected = ClrSelectedState.SELECTED;
                            node.selected = ClrSelectedState.INDETERMINATE;

                            for (let j = 0; j < this.message.elementViewTree.length; ++j) {
                                this.setFatherState(this.message.elementViewTree[j], node);
                                this.message.elementViewTree[j].opened = false;
                            }
                        } else {
                            this.actionOnNode(node.children[i], targetName);
                        }
                    }
                }
            }

            // CHECK ALL CHILD SELECTED
            for (let j = 0; j < this.message.elementViewTree.length; ++j) {
                this.checkFatherState(this.message.elementViewTree[j]);
            }
        }
    }

    private setFatherState(potential_father: any, node: any): void {

        let father: any = null;

        if (potential_father != undefined) {
            for (let i = 0; i < potential_father.children.length; ++i) {
                if (potential_father.children[i].id == node.id) {
                    //console.log(potential_father.name + " :: " + node.name);
                    father = potential_father;
                    father.selected = ClrSelectedState.INDETERMINATE;

                    if (father.type != "organization") {
                        for (let j = 0; j < this.message.elementViewTree.length; ++j) {
                            this.setFatherState(this.message.elementViewTree[j], father);
                        }
                    }
                }
            }

            if (father == null) {
                for (let i = 0; i < potential_father.children.length; ++i) {
                    this.setFatherState(potential_father.children[i], node)
                }
            }
        }
    }

    private checkFatherState(node: any): void {

        let isFull: boolean = true;

        if (node != undefined) {
            if (node.children != undefined) {
                if (node.children.length > 0) {
                    for (let i = 0; i < node.children.length; ++i) {
                        if (node.children[i].selected != 1)
                            isFull = false;
                    }
                } else {
                    isFull = false;
                }

                if (isFull) {
                    node.selected = ClrSelectedState.SELECTED;
                } else {
                    if (node.children.length > 0) {
                        for (let i = 0; i < node.children.length; ++i) {
                            if (node.children[i].type != 'vm' &&
                                node.children[i].type != "kubernetes-pod" &&
                                node.children[i].type != "xclarity_node" &&
                                node.children[i].type != "scaphandre_node" &&
                                node.children[i].type != "openmanage_node" &&
                                node.children[i].type != "oneview_node" &&
                                node.children[i].type != "ipmi_node" &&
                                node.children[i].type != "exagrid_san" &&
                                node.children[i].type != "ibm_san" &&
                                node.children[i].type != "aws-instance" &&
                                node.children[i].type != "azure-instance" &&
                                node.children[i].type != "firewall" &&
                                node.children[i].type != "switch" &&
                                node.children[i].type != "wifiAccessPoint" &&
                                node.children[i].type != "router" &&
                                node.children[i].type != "loadBalancer" &&
                                node.children[i].type != "san" &&
                                node.children[i].type != "nas" &&
                                node.children[i].type != "backup") {
                                this.checkFatherState(node.children[i]);
                            }
                        }
                    }
                }
            }
        }
    }

    private expandChild(node) {

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].type != 'vm' &&
                node.children[i].type != "kubernetes-pod" &&
                node.children[i].type != "xclarity_node" &&
                node.children[i].type != "scaphandre_node" &&
                node.children[i].type != "openmanage_node" &&
                node.children[i].type != "oneview_node" &&
                node.children[i].type != "ipmi_node" &&
                node.children[i].type != "exagrid_san" &&
                node.children[i].type != "ibm_san" &&
                node.children[i].type != "aws-instance" &&
                node.children[i].type != "azure-instance" &&
                node.children[i].type != "firewall" &&
                node.children[i].type != "switch" &&
                node.children[i].type != "wifiAccessPoint" &&
                node.children[i].type != "router" &&
                node.children[i].type != "loadBalancer" &&
                node.children[i].type != "san" &&
                node.children[i].type != "nas" &&
                node.children[i].type != "backup") {
                node.children[i].opened = true;
                this.expandChild(node.children[i]);
            }
        }
    }

    private findChildToRecForXclarity(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForScaphandre(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForKubernetes(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                let type: string = node.children[i].type;
                if (node.children[i].type == "kubernetes-cluster")
                    type = 'KUBE_CLUSTER';
                else if (node.children[i].type == "kubernetes-node")
                    type = 'KUBE_NODE';
                else if (node.children[i].type == "kubernetes-pod")
                    type = 'KUBE_POD';

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                if (node.children[i].type != "kubernetes-pod")
                    element_lst = element_lst.concat(this.findChildToRecForKubernetes(node.children[i], filter));
                else
                    this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForAws(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                let type: string = node.children[i].type;
                if (node.children[i].type == "aws-account")
                    type = 'ACCOUNT';
                else if (node.children[i].type == "aws-region")
                    type = 'REGION';
                else if (node.children[i].type == "aws-instance")
                    type = 'INSTANCE';

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                if (node.children[i].type != "aws-instance")
                    element_lst = element_lst.concat(this.findChildToRecForAws(node.children[i], filter));
                else
                    this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForAzure(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                let type: string = node.children[i].type;
                if (node.children[i].type == "azure-subscription")
                    type = 'SUBSCRIPTION';
                else if (node.children[i].type == "az-region")
                    type = 'REGION';
                else if (node.children[i].type == "azure-instance")
                    type = 'INSTANCE';

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                if (node.children[i].type != "azure-instance")
                    element_lst = element_lst.concat(this.findChildToRecForAzure(node.children[i], filter));
                else
                    this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForVmware(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                let type: string = node.children[i].type;
                if (node.children[i].type == "rp")
                    type = 'RESPOOL';

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                if (node.children[i].type != "vm")
                    element_lst = element_lst.concat(this.findChildToRecForVmware(node.children[i], filter));
                else {
                    this.isValidFilter = true;
                    switch (this.message.elementViewType) {
                        case "server":
                            if (node.children[i].folderId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].folderId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].folderId, node.children[i]
                                        .folderName, 'FOLDER', 0));
                                    this.hierarchy_lst.push(node.children[i].folderId);
                                }
                            }
                            if (node.children[i].rpId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].rpId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].rpId, node.children[i]
                                        .rpName, 'RESPOOL', 0));
                                    this.hierarchy_lst.push(node.children[i].rpId);
                                }
                            }
                            break;
                        case "folder":
                            if (node.children[i].clusterId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].clusterId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].clusterId, node.children[
                                        i].clusterName, 'CLUSTER', 0));
                                    this.hierarchy_lst.push(node.children[i].clusterId);
                                }
                            }
                            if (node.children[i].serverId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].serverId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].serverId, node.children[i]
                                        .serverName, 'SERVER', 0));
                                    this.hierarchy_lst.push(node.children[i].serverId);
                                }
                            }
                            if (node.children[i].rpId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].rpId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].rpId, node.children[i]
                                        .rpName, 'RESPOOL', 0));
                                    this.hierarchy_lst.push(node.children[i].rpId);
                                }
                            }
                            break;
                        case "rp":
                            if (node.children[i].serverId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].serverId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].serverId, node.children[i]
                                        .serverName, 'SERVER', 0));
                                    this.hierarchy_lst.push(node.children[i].serverId);
                                }
                            }
                            if (node.children[i].folderId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].folderId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].folderId, node.children[i]
                                        .folderName, 'FOLDER', 0));
                                    this.hierarchy_lst.push(node.children[i].folderId);
                                }
                            }
                            break;
                        case "tag":
                            if (node.children[i].dcId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].dcId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].dcId, node.children[i]
                                        .dcName, 'DATACENTER', 0));
                                    this.hierarchy_lst.push(node.children[i].dcId);
                                }
                            }
                            if (node.children[i].clusterId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].clusterId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].clusterId, node.children[
                                        i].clusterName, 'CLUSTER', 0));
                                    this.hierarchy_lst.push(node.children[i].clusterId);
                                }
                            }
                            if (node.children[i].serverId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].serverId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].serverId, node.children[i]
                                        .serverName, 'SERVER', 0));
                                    this.hierarchy_lst.push(node.children[i].serverId);
                                }
                            }
                            if (node.children[i].folderId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].folderId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].folderId, node.children[i]
                                        .folderName, 'FOLDER', 0));
                                    this.hierarchy_lst.push(node.children[i].folderId);
                                }
                            }
                            if (node.children[i].rpId != null) {
                                if (!this.hierarchy_lst.includes(node.children[i].rpId)) {
                                    element_lst.push(this.getSfr(filter, node.children[i].rpId, node.children[i]
                                        .rpName, 'RESPOOL', 0));
                                    this.hierarchy_lst.push(node.children[i].rpId);
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        return element_lst;
    }

    private findChildToRecForOpenManage(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForOneView(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForNetwork(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForStorage(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForIpmi(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForExagrid(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRecForIbm(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < node.children.length; ++i) {
            if (node.children[i].selected > 0) {
                let flag = 1;
                if (node.children[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, node.children[i].id, node.children[i].name, node.children[i]
                    .type, flag));
                this.hierarchy_lst.push(node.children[i].id);

                this.isValidFilter = true;
            }
        }

        return element_lst;
    }

    private findChildToRec(node: any, filter: string): SelectionFilterRec[] {

        let element_lst: SelectionFilterRec[] = [];

        switch (this.message.elementViewContext) {
            case "xclarity":
                element_lst = this.findChildToRecForXclarity(node, filter);
                break;
            case "scaphandre":
                element_lst = this.findChildToRecForScaphandre(node, filter);
                break;
            case "kubernetes":
                element_lst = this.findChildToRecForKubernetes(node, filter);
                break;
            case "aws":
                element_lst = this.findChildToRecForAws(node, filter);
                break;
            case "azure":
                element_lst = this.findChildToRecForAzure(node, filter);
                break;
            case "vmware":
                element_lst = this.findChildToRecForVmware(node, filter);
                break;
            case "multi":
                if (node.id == "xclarity-root")
                    element_lst = this.findChildToRecForXclarity(node, filter);
                else if (node.id == "scaphandre-root")
                    element_lst = element_lst.concat(this.findChildToRecForScaphandre(node, filter));
                else if (node.id == "kubernetes-root")
                    element_lst = element_lst.concat(this.findChildToRecForKubernetes(node, filter));
                else if (node.id == "aws-root")
                    element_lst = element_lst.concat(this.findChildToRecForAws(node, filter));
                else if (node.id == "azure-root")
                    element_lst = element_lst.concat(this.findChildToRecForAzure(node, filter));
                else if (node.id == "vmware-root")
                    element_lst = element_lst.concat(this.findChildToRecForVmware(node, filter));
                else if (node.id == "openmanage-root")
                    element_lst = element_lst.concat(this.findChildToRecForOpenManage(node, filter));
                else if (node.id == "oneview-root")
                    element_lst = element_lst.concat(this.findChildToRecForOneView(node, filter));
                else if (node.id == "firewall-root" || node.id == "switch-root" || node.id == "wap-root" || node.id == "router-root"
                    || node.id == "lb-root")
                    element_lst = element_lst.concat(this.findChildToRecForNetwork(node, filter));
                else if (node.id == "san-root" || node.id == "nas-root" || node.id == "backup-root")
                    element_lst = element_lst.concat(this.findChildToRecForStorage(node, filter));
                else if (node.id == "ipmi-root")
                    element_lst = element_lst.concat(this.findChildToRecForIpmi(node, filter));
                else if (node.id == "exagrid-root")
                    element_lst = element_lst.concat(this.findChildToRecForExagrid(node, filter));
                else if (node.id == "ibm-root")
                    element_lst = element_lst.concat(this.findChildToRecForIbm(node, filter));
                break;
            case "openmanage":
                element_lst = this.findChildToRecForOpenManage(node, filter);
                break;
            case "oneview":
                element_lst = this.findChildToRecForOneView(node, filter);
                break;
            case "network":
                element_lst = this.findChildToRecForNetwork(node, filter);
                break;
            case "storage":
                element_lst = this.findChildToRecForStorage(node, filter);
                break;
            case "ipmi":
                element_lst = this.findChildToRecForIpmi(node, filter);
                break;
            case "exagrid":
                element_lst = this.findChildToRecForExagrid(node, filter);
                break;
            case "ibm":
                element_lst = this.findChildToRecForIbm(node, filter);
                break;
            default:
                break;
        }

        return element_lst;
    }

    private buildElementToRec(filter: string): SelectionFilterRec[] {

        this.hierarchy_lst = [];
        let element_lst: SelectionFilterRec[] = [];

        for (var i = 0; i < this.message.elementViewTree.length; ++i) {
            if (this.message.elementViewTree[i].selected > 0) {
                let flag = 1;
                if (this.message.elementViewTree[i].selected == 2)
                    flag = 0;

                element_lst.push(this.getSfr(filter, this.message.elementViewTree[i].id, this.message
                    .elementViewTree[i].name, this.message.elementViewTree[i].type, flag));

                if (this.message.elementViewTree[i].type == "dc")
                    this.hierarchy_lst.push(this.message.elementViewTree[i].id);

                if (this.message.elementViewTree[i].type != "vm" &&
                    this.message.elementViewTree[i].type != "kubernetes-pod" &&
                    this.message.elementViewTree[i].type != "xclarity_node" &&
                    this.message.elementViewTree[i].type != "scaphandre_node" &&
                    this.message.elementViewTree[i].type != "openmanage_node" &&
                    this.message.elementViewTree[i].type != "oneview_node" &&
                    this.message.elementViewTree[i].type != "ipmi_node" &&
                    this.message.elementViewTree[i].type != "exagrid_san" &&
                    this.message.elementViewTree[i].type != "ibm_san" &&
                    this.message.elementViewTree[i].type != "aws-instance" &&
                    this.message.elementViewTree[i].type != "azure-instance" &&
                    this.message.elementViewTree[i].type != "firewall" &&
                    this.message.elementViewTree[i].type != "switch" &&
                    this.message.elementViewTree[i].type != "wifiAccessPoint" &&
                    this.message.elementViewTree[i].type != "router" &&
                    this.message.elementViewTree[i].type != "loadBalancer" &&
                    this.message.elementViewTree[i].type != "san" &&
                    this.message.elementViewTree[i].type != "nas" &&
                    this.message.elementViewTree[i].type != "backup")
                    element_lst = element_lst.concat(this.findChildToRec(this.message.elementViewTree[i], filter));
            }
        }

        return element_lst;
    }

    private getElementView(): void {

        this.management_svc.getElementView(this.currentUser.login).pipe(first()).subscribe(
            data => {
                this.usr_views = [];
                this.usr_views = data;
                this.loadViews();
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private loadViews(): void {

        this.message.isTreeAnalysis = false;

        const usr_views_dsp: ElementView[] = this.filterView();
        if (usr_views_dsp.length > 0) {
            this.message.elementViewSelected = usr_views_dsp[0].FILTER;
            this.switchView();
        }
    }

    private getView(filter: string): any {
        return this.usr_views.find(view => view.FILTER === filter);
    }

    private deleteSelectionView(view: string): void {

        this.management_svc.remSelection(this.currentUser.login, view).pipe(first()).subscribe();
        setTimeout(() => this.getElementView(), 500);
    }

    private loadElements(): void {

        if (this.message.elementViewSelected != "" && !this.message.isElementWizard) {
            this.management_svc.getUserSelection(this.message.elementViewUser, this.message.elementViewSelected)
                .pipe(first()).subscribe(
                    data => {
                        for (var i = 0; i < this.message.elementViewTree.length; ++i) {
                            const test: number = this.testSelected(data, this.message.elementViewTree[i].id);
                            if (test == 1) {
                                this.message.elementViewTree[i].selected = ClrSelectedState.SELECTED;
                                this.enableChild(this.message.elementViewTree[i]);
                            } else {
                                this.analyseTreeChild(data, this.message.elementViewTree[i]);
                            }
                        }
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
        }
    }

    private enableChild(node: any) {

        for (var i = 0; i < node.children.length; ++i) {
            node.children[i].selected = ClrSelectedState.SELECTED;
            if (node.children[i].type != "vm" &&
                node.children[i].type != "kubernetes-pod" &&
                node.children[i].type != "xclarity_node" &&
                node.children[i].type != "scaphandre_node" &&
                node.children[i].type != "openmanage_node" &&
                node.children[i].type != "oneview_node" &&
                node.children[i].type != "ipmi_node" &&
                node.children[i].type != "exagrid_san" &&
                node.children[i].type != "ibm_san" &&
                node.children[i].type != "aws-instance" &&
                node.children[i].type != "azure-instance" &&
                node.children[i].type != "firewall" &&
                node.children[i].type != "switch" &&
                node.children[i].type != "wifiAccessPoint" &&
                node.children[i].type != "router" &&
                node.children[i].type != "loadBalancer" &&
                node.children[i].type != "san" &&
                node.children[i].type != "nas" &&
                node.children[i].type != "backup")
                this.enableChild(node.children[i]);
        }
    }

    private disableChild(node: any) {

        for (var i = 0; i < node.children.length; ++i) {
            node.children[i].selected = ClrSelectedState.UNSELECTED;
            if (node.children[i].type != "vm" &&
                node.children[i].type != "kubernetes-pod" &&
                node.children[i].type != "xclarity_node" &&
                node.children[i].type != "scaphandre_node" &&
                node.children[i].type != "openmanage_node" &&
                node.children[i].type != "oneview_node" &&
                node.children[i].type != "ipmi_node" &&
                node.children[i].type != "exagrid_san" &&
                node.children[i].type != "ibm_san" &&
                node.children[i].type != "aws-instance" &&
                node.children[i].type != "azure-instance" &&
                node.children[i].type != "firewall" &&
                node.children[i].type != "switch" &&
                node.children[i].type != "wifiAccessPoint" &&
                node.children[i].type != "router" &&
                node.children[i].type != "loadBalancer" &&
                node.children[i].type != "san" &&
                node.children[i].type != "nas" &&
                node.children[i].type != "backup")
                this.disableChild(node.children[i]);
        }
    }

    private testSelected(data: any, uuid: string): number {

        var test: number = 0;

        var el = data.find(selection => selection.element_id === uuid);
        if (el != undefined) {
            if (el.flag == 1)
                test = 1;
            else if (el.flag == 0)
                test = 2;
        }

        return test;
    }

    private analyseTreeChild(data: any, node: any): void {

        if (node.name != undefined) {
            let all_child_selected: boolean = true;
            let nb_child_selected: number = 0;
            if (node.children.length > 0) {
                for (var i = 0; i < node.children.length; ++i) {
                    let test: number = this.testSelected(data, node.children[i].id);
                    if (test == 1) {
                        node.children[i].selected = ClrSelectedState.SELECTED;
                        ++nb_child_selected;
                    } else if (test == 2) {
                        node.children[i].selected = ClrSelectedState.INDETERMINATE;
                        all_child_selected = false;
                        ++nb_child_selected;
                    } else {
                        all_child_selected = false;
                    }
                    if (node.children[i].type != "vm" &&
                        node.children[i].type != "kubernetes-pod" &&
                        node.children[i].type != "xclarity_node" &&
                        node.children[i].type != "scaphandre_node" &&
                        node.children[i].type != "openmanage_node" &&
                        node.children[i].type != "oneview_node" &&
                        node.children[i].type != "ipmi_node" &&
                        node.children[i].type != "exagrid_san" &&
                        node.children[i].type != "ibm_san" &&
                        node.children[i].type != "aws-instance" &&
                        node.children[i].type != "azure-instance" &&
                        node.children[i].type != "firewall" &&
                        node.children[i].type != "switch" &&
                        node.children[i].type != "wifiAccessPoint" &&
                        node.children[i].type != "router" &&
                        node.children[i].type != "loadBalancer" &&
                        node.children[i].type != "san" &&
                        node.children[i].type != "nas" &&
                        node.children[i].type != "backup")
                        this.analyseTreeChild(data, node.children[i]);
                }
            } else {
                all_child_selected = false;
            }

            if (all_child_selected)
                node.selected = ClrSelectedState.SELECTED;
            else {
                if (nb_child_selected > 0)
                    node.selected = ClrSelectedState.INDETERMINATE;
                else
                    node.selected = ClrSelectedState.UNSELECTED;
            }
        }
    }

    private getSfr(filter: string, id: string, name: string, type: string, flag: number): SelectionFilterRec {

        let sfr: SelectionFilterRec = {
            filter: filter,
            user: this.currentUser.login,
            element_type: type,
            element_name: name,
            element_id: id,
            flag: flag,
            resource: this.message.elementViewType,
            context: this.message.elementViewContext
        };

        return sfr;
    }

    isValidConnector(connector: string): any {
        return this.connectors.includes(connector);
    }
}
