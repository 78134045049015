import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    zip
} from 'rxjs';
import {
    map
} from 'rxjs/operators';

import {
    environment
} from '@environments/environment';

import {
    ClientInfo,
    MonitorSettings
} from '@app/model';
import {
    LicenseInfo,
    LicenseKey,
    ProductKey
} from '../model/license';
import {
    JsonloaderService
} from './jsonloader.service';
import {
    MonitorService
} from './monitor.service';


@Injectable({
    providedIn: 'root'
})
export class LicenseService {

    // For client infos
    easyvirt_host: string = 'https://www.easyvirt.com/data.php';


    product_key: ProductKey = this.emptyProductKey();

    license_info: LicenseInfo = this.emptyLicenseInfo();

    private licenseInfoSubject: BehaviorSubject < LicenseInfo > = new BehaviorSubject < LicenseInfo > (this
        .license_info);
    licenseInfo: Observable < LicenseInfo > = this.licenseInfoSubject.asObservable();

    private productKeySubject: BehaviorSubject < ProductKey > = new BehaviorSubject < ProductKey > (this.product_key);
    productKey: Observable < ProductKey > = this.productKeySubject.asObservable();


    /**
     *
     */
    constructor(private http: HttpClient, private monitor_svc: MonitorService,
        private json_svc: JsonloaderService) {}


    /**
     * Product Key
     */
    getProductKey(): void {
        this.http.get < ProductKey > (`${environment.apiUrl}/` + 'license/getproductkey').pipe(map(infos => {
            this.productKeySubject.next(infos);
        })).subscribe(data => {}, error => {});
    }

    private emptyProductKey(): ProductKey {
        let key: ProductKey = {
            key: ''
        }
        return key;
    }

    /**
     * License
     */

    getLicenseKey() {
        return this.http.get < LicenseKey > (`${environment.apiUrl}/` + 'license/getlicensekey').pipe(map(key => {
            return key;
        }));
    }

    getLicenseInfo(): void {
        this.http.get < LicenseInfo > (`${environment.apiUrl}/` + 'license/getlicenseinfo').pipe(map(infos => {
            this.licenseInfoSubject.next(infos);
            localStorage.setItem('license', JSON.stringify(infos));
        })).subscribe(data => {}, error => {});
    }

    checkLicense() {
        return this.http.get < Object > (`${environment.apiUrl}/` + 'license/checklicense').pipe(map(infos => {
            return infos;
        }));
    }

    sendLicense(key: string) {
        return this.http.post < Object > (`${environment.apiUrl}/` + 'license/sendlicense/', [key]).pipe(map(infos => {
            return infos;
        }));
    }

    generateTrial() {
        return this.http.post < Object > (`${environment.apiUrl}/` + 'license/trial/', []).pipe(map(infos => {
            return infos;
        }));
    }

    private emptyLicenseInfo(): LicenseInfo {
        let infos: LicenseInfo = {
            valid: false,
            alert: 2,
            alertcode: 12,
            endlicense: 0,
            vmclient: 0,
            vmlicense: "0", // can be 'noli'
            moduleslicense: 0,
            vmclientexcluded: 0,
        }
        return infos;
    }

    /**
     *
     */
    reload(): void {
        if (localStorage.getItem('license') != undefined) {
            this.licenseInfoSubject.next(JSON.parse(localStorage.getItem('license')));
        } else {
            this.getLicenseInfo();
        }
    }


    /**
     *
     */
    removeInfo(): void {
        localStorage.removeItem('license');
    }


    /**
     *
     */
    sendInfos(): void {

        let version_info = this.json_svc.json.version;
        let license_info = this.licenseInfo;
        let license_key = this.getLicenseKey();
        let product_key = this.productKey;
        let settings = this.monitor_svc.getSettings();

        // wait until all observables have emitted a value
        const data = zip(license_info, license_key, product_key, settings);
        data.subscribe(
            all => {
                let licenseInfo: LicenseInfo = all[0] as LicenseInfo;
                let licenseKey: LicenseKey = all[1] as LicenseKey;
                let productKey: ProductKey = all[2] as ProductKey;
                let settingsInfo: any = all[3] as MonitorSettings[];

                let company: string = "";
                let email: string = "";
                let application: string = "";
                settingsInfo.forEach(element => {
                    switch (element.option) {
                        case "ovf.customer.company":
                            company = element.value;
                            break;
                        case "ovf.customer.email":
                            email = element.value;
                            break;
                        case "ovf.application.name":
                            application = element.value;
                            break;
                    };
                });

                let infos: ClientInfo = {
                    release: version_info.release,
                    build: version_info.build,
                    vmclient: licenseInfo.vmclient,
                    vmlicense: licenseInfo.vmlicense,
                    licensekey: licenseKey.key,
                    productkey: productKey.key,
                    expdate: licenseInfo.endlicense,
                    company: company,
                    email: email,
                    application: application
                }

                this.http.post < ClientInfo > (this.easyvirt_host, btoa(JSON.stringify(infos))).subscribe();

            },
            error => {}
        );
    }

}
