import * as d3 from "d3";
import {
    ComboboxSelectionItem,
    GraphDataObject,
    NetscopeCommonFlowviewComponent
} from "@app/netscope/netscope-common-flowview/netscope-common-flowview.component";
import {
    DCNetscopeResourceWithName,
    DomainResolution,
    GetDomainResolutionResponse,
    SuccessGetDomainResolutionResponse
} from "@app/services";
import {FlowDetail, FlowMetric} from "@app/netscope/netscope-flows-datagrid/netscope-flows-datagrid.component";
import {ClustersViewerRenderer} from "@app/netscope/netscope-common-flowview/renderers/ClustersViewerRenderer";

export class DependenciesViewerRenderer extends ClustersViewerRenderer {

    render(graphData: GraphDataObject, flowComponent: NetscopeCommonFlowviewComponent): void {

        let visualisationData = this.computeVisualisationDate(graphData, flowComponent);
        let links = visualisationData.links;
        let vms = visualisationData.vms;
        let hosts = visualisationData.hosts;
        let minBytes = visualisationData.minBytes;
        let maxBytes = visualisationData.maxBytes;
        let totalTrafficBytes = visualisationData.totalTrafficBytes;
        let focusedVmsUuids = visualisationData.focusedVmsUuids;
        let ignoredUuids = visualisationData.ignoredUuids;

        //
        // START RENDERING GRAPHICS
        //

        let svgHeight = document.getElementById('divSvg').clientHeight;
        let svgWidth = document.getElementById('divSvg').clientWidth;

        if (svgHeight == 0) {
            svgHeight = flowComponent.lastSvgHeight;
        } else {
            flowComponent.lastSvgHeight = svgHeight;
        }

        if (svgWidth == 0) {
            svgWidth = flowComponent.lastSvgWidth;
        } else {
            flowComponent.lastSvgWidth = svgWidth;
        }

        const viewCenterX = 0
        const viewCenterY = 0;

        // Initialize router radius
        let vmsOrbitCircleRadius = Math.min(svgWidth, svgHeight) / 2 * 0.90 - 40;

        if (vmsOrbitCircleRadius < 100) {
            vmsOrbitCircleRadius = 100.0;
        }

        let zoomRatio = 1.0;
        if (vms.length > 30) {
            let adjustedVmsOrbitCircleRadius = vms.length * 40 / (2 * Math.PI);
            zoomRatio = vmsOrbitCircleRadius / adjustedVmsOrbitCircleRadius;
            vmsOrbitCircleRadius = adjustedVmsOrbitCircleRadius;
        }

        let ratioMinSizeOverMaxSize = 50;

        d3.select('div#divSvg').select("svg").remove();
        const svg = d3.select('div#divSvg')
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .on("click", (d, i) => {
                // Remove a previously open dropdown menus
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }
            });
        flowComponent.svg = svg;

        // Add zoom
        svg.call(d3.zoom()
            .extent([
                [0, 0],
                [svgWidth, svgHeight]
            ])
            .scaleExtent([0.1, ratioMinSizeOverMaxSize])
            .on("zoom", zoomed));

        const g = svg.append("g");

        const self = flowComponent;

        // Add hosts UUID to vms
        let vmUuidToHostIndex = {};

        hosts.map((router) => router.children.map((vmUuid) => {
            delete router.minAngle;
            delete router.maxAngle;
            vmUuidToHostIndex[vmUuid] = router;
        }))

        const angleIncrementVms = 2 * Math.PI / vms.length;
        let currentAngleVm = 0;
        vms.forEach((vm) => {
            vm.angle = currentAngleVm;
            vm.fx = viewCenterX + Math.cos(vm.angle) * vmsOrbitCircleRadius;
            vm.fy = viewCenterY + Math.sin(vm.angle) * vmsOrbitCircleRadius;
            vm.linefx = viewCenterX + Math.cos(vm.angle) * (vmsOrbitCircleRadius - flowComponent
                .nodeRadius - 10);
            vm.linefy = viewCenterY + Math.sin(vm.angle) * (vmsOrbitCircleRadius - flowComponent
                .nodeRadius - 10);

            // Define angle in host
            let host = vmUuidToHostIndex[vm.uuid];
            if (!host.hasOwnProperty("minAngle")) {
                host.minAngle = vm.angle;
            }
            host.maxAngle = vm.angle;

            currentAngleVm += angleIncrementVms;
        });

        // D3's arcs is shifted compared to a trigonometric circle: while "0 deg rad" on a trigonometric circle is the
        // middle left point of the circle, on D3's arc "0 deg rad" it is the top middle point of the circle: to
        // make D3'es arc coordinates matches those of classic trigonometry we have to shift angles by PI / 2 on the right
        let shift = (1) * Math.PI / 2;

        // Adjust angles of hosts
        hosts.map((host) => {
            let coordinates = [host.minAngle + shift, host.maxAngle + shift];
            host.minAngle = Math.min(...coordinates) - angleIncrementVms / 4;
            host.maxAngle = Math.max(...coordinates) + angleIncrementVms / 4;
        });

        function zoomed({transform}) {
            self.graphParameters.userMovedMap = true;
            self.graphParameters.lastTransformation = transform;
            g.attr("transform", transform);
        }

        // Draw circle for vms
        g.append('g').append('circle')
            .attr('cx', viewCenterX)
            .attr('cy', viewCenterY)
            .attr('r', vmsOrbitCircleRadius)
            .attr('fill', 'transparent')
            .attr('stroke', 'rgba(200, 200, 200, 0.3)')
            .attr('stroke-width', '2px');

        const defs = g.append('svg:defs');

        const data = [{
            id: 0,
            name: 'circle',
            color: '#ddd',
            path: 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0',
            viewbox: '-6 -6 12 12'
        }, {
            id: 1,
            name: 'square',
            color: '#ddd',
            path: 'M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z',
            viewbox: '-5 -5 10 10'
        }, {
            id: 2,
            name: 'arrow',
            color: '#ddd',
            path: 'M 0,0 m -5,-5 L 2,0 L -5,5 Z',
            viewbox: '-10 -5 13 10'
        }, {
            id: 2,
            name: 'arrow_reversed',
            color: '#ddd',
            path: 'M 0,0 m -5,-5 L 2,0 L -5,5 Z',
            viewbox: '-10 -5 13 10',
            reversed: true
        }, {
            id: 3,
            name: 'stub',
            color: '#ddd',
            path: 'M 0,0 m -1,-5 L 1,-5 L 1,5 L -1,5 Z',
            viewbox: '-1 -5 2 10'
        }, {
            id: 4,
            name: 'focus_circle',
            color: '#e79807',
            path: 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0',
            viewbox: '-6 -6 12 12'
        }, {
            id: 5,
            name: 'focus_square',
            color: '#e79807',
            path: 'M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z',
            viewbox: '-5 -5 10 10'
        }, {
            id: 6,
            name: 'focus_arrow',
            color: '#e79807',
            path: 'M 0,0 m -5,-5 L 2,0 L -5,5 Z',
            viewbox: '-10 -5 13 10'
        }, {
            id: 6,
            name: 'focus_arrow_reversed',
            color: '#e79807',
            path: 'M 0,0 m -5,-5 L 2,0 L -5,5 Z',
            viewbox: '-10 -5 13 10',
            reversed: true
        }, {
            id: 7,
            name: 'focus_stub',
            color: '#e79807',
            path: 'M 0,0 m -1,-5 L 1,-5 L 1,5 L -1,5 Z',
            viewbox: '-1 -5 2 10'
        }];

        const margin = {
            top: 100,
            right: 100,
            bottom: 100,
            left: 100
        };

        const paths = g.append('svg:g')
            .attr('id', 'markers')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const marker = defs.selectAll('marker')
            .data(data)
            .enter()
            .append('svg:marker')
            .attr('id', function(d) {
                return 'marker_' + d.name;
            })
            .attr('markerHeight', 5)
            .attr('markerWidth', 5)
            .attr('markerUnits', 'strokeWidth')
            .attr('orient', function(d) {
                if (d.reversed === true) {
                    return 'auto-start-reverse';
                }
                return 'auto';
            })
            .attr('refX', 0)
            .attr('refY', 0)
            .attr('viewBox', function(d) {
                return d.viewbox;
            })
            .append('svg:path')
            .attr('d', function(d) {
                return d.path;
            })
            .attr('fill', function(d, i) {
                return d.color;
            });

        // Place hosts and vms

        // First, place hosts
        let visibleHosts = hosts.filter((host) => host?.visible !== false);
        const hostsNodes = g.append('g')
            .attr('class', 'hostsArc')
            .selectAll('g')
            .data(visibleHosts)
            .enter().append('g');

        const hostsArcs = hostsNodes
            .append("path")
            .attr("d", (d) => {
                let arc = d3.arc()
                    .innerRadius(vmsOrbitCircleRadius + 30)
                    .outerRadius(vmsOrbitCircleRadius + 50)
                    .startAngle(d.minAngle)
                    .endAngle(d.maxAngle)
                // @ts-ignore
                return arc();
            })
            .attr("fill", "rgb(145,64,184)");

        //Create an SVG path (based on bl.ocks.org/mbostock/2565344)
        hostsNodes
            .append("path")
            .attr("id", (d) => `arc_text_${d.uuid}`) //Unique id of the path
            .attr("d", (d) => {
                let startAngle = d.minAngle;
                let endAngle = d.maxAngle;

                let flip = false;

                let radius = vmsOrbitCircleRadius + (flip ? 55 : 65);

                let arc = d3.arc()
                    .innerRadius(radius)
                    .outerRadius(radius + 1)
                    .startAngle(startAngle)
                    .endAngle(endAngle)
                // @ts-ignore
                return arc();
            })
            .style("fill", "none");

        //Create an SVG text element and append a textPath element
        hostsNodes.append("text")
            .append("textPath") //append a textPath to the text element
            .attr("xlink:href", (d) => `#arc_text_${d.uuid}`) //place the ID of the path here
            .style("text-anchor", "middle") //place the text halfway on the arc
            .attr("startOffset", (d) => {
                let startAngle = d.minAngle;
                let endAngle = d.maxAngle;

                let flip = (startAngle + endAngle) / 2 > Math.PI ? true : false;

                return flip ? "25%" : "75%";
            })
            .attr("stroke", "rgb(145,64,184)")
            .text((d) => d.name);

        g.append('g')
            .attr('class', 'links')
            .selectAll('g')
            .data(links)
            .enter().append('path')
            .attr('d', (d: FlowDetail) => {
                let sourceNode = vms.filter((vm) => vm.uuid === d.source.uuid)[0];
                let targetNode = vms.filter((vm) => vm.uuid === d.target.uuid)[0];
                const x1 = sourceNode.linefx;
                const y1 = sourceNode.linefy;
                const x2 = viewCenterX;
                const y2 = viewCenterY;
                const x3 = targetNode.linefx;
                const y3 = targetNode.linefy;
                const result = `
             M ${x1} ${y1}
             Q ${x2} ${y2} ${x3} ${y3}`;
                return result;
            })
            .attr('marker-start', (d: FlowDetail) => {
                let sourceNode = vms.filter((vm) => vm.uuid === d.source.uuid)[0];
                let targetNode = vms.filter((vm) => vm.uuid === d.target.uuid)[0];

                if (focusedVmsUuids.indexOf(sourceNode.uuid) != -1 || focusedVmsUuids.indexOf(targetNode
                        .uuid) != -1) {
                    return 'url(#marker_focus_arrow_reversed)';
                }

                return 'url(#marker_arrow_reversed)';
            })
            .attr('marker-end', (d: FlowDetail) => {
                let sourceNode = vms.filter((vm) => vm.uuid === d.source.uuid)[0];
                let targetNode = vms.filter((vm) => vm.uuid === d.target.uuid)[0];

                if (focusedVmsUuids.indexOf(sourceNode.uuid) != -1 || focusedVmsUuids.indexOf(targetNode
                        .uuid) != -1) {
                    return 'url(#marker_focus_arrow)';
                }

                return 'url(#marker_arrow)';
            })
            .style('fill', `transparent`)
            .style('stroke', (d: FlowDetail) => {
                let sourceNode = vms.filter((vm) => vm.uuid === d.source.uuid)[0];
                let targetNode = vms.filter((vm) => vm.uuid === d.target.uuid)[0];

                if (focusedVmsUuids.indexOf(sourceNode.uuid) != -1 || focusedVmsUuids.indexOf(targetNode
                        .uuid) != -1) {
                    return `#e79807`;
                }

                return `#DDDDDD`;
            })
            .attr('pointer-events', 'visibleStroke')
            .on('mouseover', function(d, i) {
                d3.select(this).style('cursor', 'pointer');
            })
            .on('mouseout', function(d, i) {
                d3.select(this).style('cursor', 'default');
            })
            .on("click", function(d, i: FlowDetail) {
                d.stopPropagation();

                // @ts-ignore
                let node_id = `${i.source.uuid}_${i.target.uuid}`.replaceAll(":", "__").replaceAll(".", "__");
                let dropdownId = 'dropdownmenu_' + node_id;

                let resourceUuidsAssociatedToThisLink = [i.source.uuid, i.target.uuid];
                let relevantMetrics = graphData.links
                    .filter((l) => resourceUuidsAssociatedToThisLink.indexOf(l.source.uuid) !== -1 &&
                        resourceUuidsAssociatedToThisLink.indexOf(l.target.uuid) !== -1)
                    .map((l) => l.metrics)
                    .flat()
                    .sort((a, b) => b.exchanged_bytes - a.exchanged_bytes);

                let existingDropdownForThisNode = document.getElementById(dropdownId) !== undefined && document
                    .getElementById(dropdownId) !== null;

                // Remove a previously open dropdown
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }

                if (!existingDropdownForThisNode) {
                    let mouseEvent: number[] = d3.pointer(d);
                    let mouseX = mouseEvent[0];
                    let mouseY = mouseEvent[1];

                    let uuidToNameIdx = {}
                    for (let resource of [i.source, i.target]) {
                        uuidToNameIdx[resource.uuid] = resource.name;
                    }

                    let metricsHtmlCode =
                        "<table class=\"table\" id='table_flows_links_popover' style='margin-top: 0px; min-width: 400px;'>";

                    metricsHtmlCode += `<thead>
					<tr>
						<th>Flow</th>
						<th>Information</th>
						<th style="white-space:nowrap">Total bytes</th>
					</tr>
					</thead>`;

                    function formatExchangedBytes(value, remainingUnits = ["bytes", "kiB", "MiB", "GiB"]) {
                        if (value < 1024 || remainingUnits.length === 1) {
                            let unit = remainingUnits[0];
                            if (unit === "bytes") {
                                return `${value} ${remainingUnits[0]}`;
                            }
                            return `${value.toFixed(2)} ${remainingUnits[0]}`;
                        }
                        return formatExchangedBytes(value / 1024, remainingUnits.slice(1));
                    }

                    for (let metric of relevantMetrics) {
                        let nameSource = uuidToNameIdx[metric.source_address.uuid];
                        let nameTarget = uuidToNameIdx[metric.destination_address.uuid];

                        if (nameSource == undefined) {
                            nameSource = metric.source_address.ipaddress;
                        }

                        if (nameTarget == undefined) {
                            nameTarget = metric.destination_address.ipaddress;
                        }

                        let metricHtmlCode = "<tr>";
                        if (metric.direction === 1) {
                            metricHtmlCode +=
                                `<td><span style="white-space:nowrap"><span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameSource}<span class="badge">${metric.port}</span></span>`;
                            metricHtmlCode += `&rarr; `;
                            metricHtmlCode +=
                                `<span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameTarget}</span></span></td>`;
                        } else {
                            metricHtmlCode +=
                                `<td><span style="white-space:nowrap; vertical-align: middle;"><span class="label" style="max-width: 100%; justify-content: left;">${nameSource}</span>`;
                            metricHtmlCode += `&rarr; `;
                            metricHtmlCode +=
                                `<span class="label" style="max-width: 100%; justify-content: left; vertical-align: middle;">${nameTarget}<span class="badge">${metric.port}</span></span></span></td>`;
                        }
                        metricHtmlCode += "<td><span style=\"white-space:nowrap\">";

                        let transportProtocolParts = metric.transport_protocol.split(" ");
                        if (transportProtocolParts.length > 1) {
                            let transportProtocol = transportProtocolParts[1];
                            metricHtmlCode += `<span class="label">${transportProtocol}</span>`;
                        }

                        let applicationProtocol = metric.application_protocol;
                        metricHtmlCode += `<span class="label">${applicationProtocol}</span>`;
                        metricHtmlCode += "</span></td>";

                        metricHtmlCode += "<td><span style=\"white-space:nowrap\">";
                        metricHtmlCode += formatExchangedBytes(metric.exchanged_bytes);
                        metricHtmlCode += "</span></td>";

                        metricHtmlCode += "</tr>";

                        metricsHtmlCode += metricHtmlCode;
                    }
                    metricsHtmlCode += "</table>"

                    let popover = g
                        .append("foreignObject")
                        .attr("class", "dropdown_actions")
                        .attr('id', dropdownId)
                        .attr('x', mouseX)
                        .attr('y', mouseY)
                        .attr('height', 0)
                        .attr('width', 0)
                        .html(metricsHtmlCode);

                    // Ensure that the node and its dropdown menu are above all elements of the svg parent node.
                    // This moves the nodes at the beginning of the parent's list of children.
                    d3.select(this).raise();

                    // Adjust size of popover
                    setTimeout(() => {
                        let element = document.getElementById("table_flows_links_popover");
                        popover
                            .transition()
                            .attr("height", element.offsetHeight + 5)
                            .attr("width", element.offsetWidth + 5);
                    }, 100);
                }
            })
            .style('stroke-width', (m) => {
                // @ts-ignore
                return `${Math.max(1, 20 * m.value / totalTrafficBytes)}`;
            });
        console.log(`graphData.links.length = ${graphData.links.length}`);

        // Second, place VMs
        const nodes = vms;
        const visuNode = g.append('g')
            .attr('class', 'nodes')
            .selectAll('g')
            .data(nodes)
            .enter().append('g')
            .attr('transform', (d) => 'translate(' + d.fx + ',' + d.fy + ')');


        let currentInstanceObject = flowComponent;

        visuNode.on("click", function(d, i) {
            d.stopPropagation();

            let mouseEvent: number[] = d3.pointer(d);
            let mouseX = i.fx + mouseEvent[0];
            let mouseY = i.fy + mouseEvent[1] - 12;

            // @ts-ignore
            let node_id = i.uuid.replaceAll(":", "__").replaceAll(".", "__");
            let dropdownId = 'dropdownmenu_' + node_id;

            let existingDropdownForThisNode = document.getElementById(dropdownId) !== undefined && document
                .getElementById(dropdownId) !== null;

            // Remove a previously open dropdown
            let existingDropdowns = document.getElementsByClassName("dropdown_actions");

            for (let dropdown of Array.from(existingDropdowns)) {
                dropdown.remove();
            }

            if (!existingDropdownForThisNode) {
                let textNode = d3.select(this).selectAll("text");
                let focusLabel = focusedVmsUuids.indexOf(i.uuid) === -1 ? "Focus" : "Unfocus";

                let popover = g
                    .append("foreignObject")
                    .attr("class", "dropdown_actions")
                    .attr('id', dropdownId)
                    .attr('width', 0)
                    .attr('height', 0)
                    .attr('x', mouseX)
                    .attr('y', mouseY)
                    .html("<div class=\"dropdown open\" style='z-index: 999; margin-top: 0px;'>\n" +
                        "    <div id=\"node_action_popover\" class=\"dropdown-menu\">\n" +
                        "        <h4 class=\"dropdown-header\">Select an action</h4>\n" +
                        "        <div id=\"dropdown_focus_" + node_id + "\" class=\"dropdown-item\">" +
                        focusLabel + "</div>\n" +
                        "        <div id=\"dropdown_ignore_" + node_id +
                        "\" class=\"dropdown-item\">Ignore</div>\n" +
                        "        <div id=\"dropdown_resolve_" + node_id +
                        "\"class=\"dropdown-item\">Resolve DNS</div>\n" +
                        "        <div id=\"dropdown_resolve_dns_neighbours_" + node_id +
                        "\"class=\"dropdown-item\">Resolve DNS of neighbours</div>\n" +
                        "    </div>\n" +
                        "</div>");

                // Ensure that the node and its dropdown menu are above all elements of the svg parent node.
                // This moves the nodes at the beginning of the parent's list of children.
                d3.select(this).raise();

                // Adjust size of popover
                setTimeout(() => {
                    let element = document.getElementById("node_action_popover");
                    popover
                        .transition()
                        .attr("height", element.offsetHeight + 24)
                        .attr("width", element.offsetWidth + 5);
                }, 100);

                d3.select("#dropdown_ignore_" + node_id).on("click", function(d2, i2) {
                    flowComponent.currentComboboxSelection.push(new ComboboxSelectionItem(new DCNetscopeResourceWithName(i.uuid, i.name), "ignore"));
                    flowComponent.reloadUiAndRecomputeDistances();
                });

                d3.select("#dropdown_focus_" + node_id).on("click", function(d2, i2) {
                    d2.stopPropagation();
                    document.getElementById(dropdownId).remove();
                    currentInstanceObject.switchFocus(i);
                });

                d3.select("#dropdown_resolve_" + node_id).on("click", function(d2, i2) {
                    d2.stopPropagation();
                    let ipAddressCandidate = i.name;
                    document.getElementById(dropdownId).remove();
                    flowComponent.resolveDomain(ipAddressCandidate, (domainResolution: DomainResolution) => {
                        if (domainResolution.status !== "ok") {
                            return;
                        }
                        let ipValue = domainResolution.result.trim();
                        textNode.text(ipValue);
                        flowComponent.replayDataCopy.unknown_ips.filter((unknownIp) => unknownIp.uuid === i.uuid).map((unknownIp) => unknownIp.resolved = ipValue);
                    });
                });

                d3.select("#dropdown_resolve_dns_neighbours_" + node_id).on("click", function(d2, i2) {
                    d2.stopPropagation();
                    document.getElementById(dropdownId).remove();

                    let linksWithCurrentNode = links.filter((l) => l.source.uuid === i.uuid || l.target.uuid === i.uuid);
                    let neighboursUuids = linksWithCurrentNode.map((l) => [l.source.uuid, l.target.uuid]).flat();
                    let neighboursNodes = vms.filter((node) => neighboursUuids.indexOf(node.id) !== -1);

                    for (let node of neighboursNodes) {
                        let ipAddressCandidate = node.name;
                        let textNode = visuNode.filter((d) => d.uuid === node.uuid).selectAll("text");

                        // let textNode = document.getElementById("text_label_" + node.id);
                        flowComponent.resolveDomain(ipAddressCandidate, (domainResolution: DomainResolution) => {
                            if (domainResolution.status !== "ok") {
                                return;
                            }
                            let ipValue = domainResolution.result.trim();
                            textNode.text(ipValue);
                            flowComponent.replayDataCopy.unknown_ips.filter((unknownIp) => unknownIp.uuid === i.uuid).map((unknownIp) => unknownIp.resolved = ipValue);
                        });
                    }
                })
            }
        });


        const vmCircles = visuNode
            .append('g')
            .append('circle')
            .attr('r', flowComponent.nodeRadius)
            .style('stroke-width', 5) // set the stroke width
            .style('stroke', (d) => {
                let focusedVmsIds = currentInstanceObject.currentComboboxSelection
                    .filter((s) => s.mode === "focus")
                    .map((s) => s.resource.uuid);
                if (focusedVmsIds.indexOf(d.uuid) !== -1 || focusedVmsIds.indexOf(d.uuid) !== -1) {
                    return '#e79807';
                }
                if (d.type === "external_ip") {
                    return '#613200';
                }
                if (d.type === "unknown_ip") {
                    return '#54ec2e';
                }
                return '#007FCB';
            })
            .attr('fill', (d) => 'white');

        if (flowComponent.selectedAppsGroups.length > 0) {
            flowComponent.addGroupsAppsCircles(visuNode);
        }

        const iconsSvgCode = {
            "datacenter": "<path d=\"M26.5,4.08C22.77,4.08,19,5.4,19,7.91V9.5a18.75,18.75,0,0,1,2,.2V7.91c0-.65,2.09-1.84,5.5-1.84S32,7.27,32,7.91V18.24c0,.54-1.46,1.44-3.9,1.73v2c3.13-.32,5.9-1.6,5.9-3.75V7.91C34,5.4,30.23,4.08,26.5,4.08Z\"/><path d=\"M4,18.24V7.91c0-.65,2.09-1.84,5.5-1.84S15,7.27,15,7.91V9.7a18.75,18.75,0,0,1,2-.2V7.91c0-2.52-3.77-3.84-7.5-3.84S2,5.4,2,7.91V18.24C2,20.4,4.77,21.67,7.9,22V20C5.46,19.68,4,18.78,4,18.24Z\"/><path d=\"M18,10.85c-4.93,0-8.65,1.88-8.65,4.38V27.54c0,2.5,3.72,4.38,8.65,4.38s8.65-1.88,8.65-4.38V15.23C26.65,12.73,22.93,10.85,18,10.85Zm6.65,7.67c-.85,1-3.42,2-6.65,2A14.49,14.49,0,0,1,14,20v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v3.12c-.85,1-3.42,2-6.65,2a14.49,14.49,0,0,1-4-.53v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v2.29c0,.95-2.65,2.38-6.65,2.38s-6.65-1.43-6.65-2.38V15.23c0-.95,2.65-2.38,6.65-2.38s6.65,1.43,6.65,2.38Z\"/>",
            "network": "<path d=\"M26.58,32h-18a1,1,0,1,0,0,2h18a1,1,0,0,0,0-2Z\"/><path d=\"M17.75,2a14,14,0,0,0-14,14c0,.45,0,.89.07,1.33l0,0h0A14,14,0,1,0,17.75,2Zm0,2a12,12,0,0,1,8.44,3.48c0,.33,0,.66,0,1A18.51,18.51,0,0,0,14,8.53a2.33,2.33,0,0,0-1.14-.61l-.25,0c-.12-.42-.23-.84-.32-1.27s-.14-.81-.19-1.22A11.92,11.92,0,0,1,17.75,4Zm-3,5.87A17,17,0,0,1,25.92,10a16.9,16.9,0,0,1-3.11,7,2.28,2.28,0,0,0-2.58.57c-.35-.2-.7-.4-1-.63a16,16,0,0,1-4.93-5.23,2.25,2.25,0,0,0,.47-1.77Zm-4-3.6c0,.21.06.43.1.64.09.44.21.87.33,1.3a2.28,2.28,0,0,0-1.1,2.25A18.32,18.32,0,0,0,5.9,14.22,12,12,0,0,1,10.76,6.27Zm0,15.71A2.34,2.34,0,0,0,9.2,23.74l-.64,0A11.94,11.94,0,0,1,5.8,16.92l.11-.19a16.9,16.9,0,0,1,4.81-4.89,2.31,2.31,0,0,0,2.28.63,17.53,17.53,0,0,0,5.35,5.65c.41.27.83.52,1.25.76A2.32,2.32,0,0,0,19.78,20a16.94,16.94,0,0,1-6.2,3.11A2.34,2.34,0,0,0,10.76,22Zm7,6a11.92,11.92,0,0,1-5.81-1.51l.28-.06a2.34,2.34,0,0,0,1.57-1.79,18.43,18.43,0,0,0,7-3.5,2.29,2.29,0,0,0,3-.62,17.41,17.41,0,0,0,4.32.56l.53,0A12,12,0,0,1,17.75,28Zm6.51-8.9a2.33,2.33,0,0,0-.33-1.19,18.4,18.4,0,0,0,3.39-7.37q.75.35,1.48.78a12,12,0,0,1,.42,8.2A16,16,0,0,1,24.27,19.11Z\"/>",
            "switch": "<path d=\"M5.71,14H20.92V12H5.71L9.42,8.27A1,1,0,1,0,8,6.86L1.89,13,8,19.14a1,1,0,1,0,1.42-1.4Z\"/><rect x=\"23\" y=\"12\" width=\"3\" height=\"2\"/><rect x=\"28\" y=\"12\" width=\"2\" height=\"2\"/><path d=\"M27.92,17.86a1,1,0,0,0-1.42,1.41L30.21,23H15v2H30.21L26.5,28.74a1,1,0,1,0,1.42,1.4L34,24Z\"/><rect x=\"10\" y=\"23\" width=\"3\" height=\"2\"/><rect x=\"6\" y=\"23\" width=\"2\" height=\"2\"/>",
            "port": "<path d=\"M6.06,30a1,1,0,0,1-1-1V8h-2a1,1,0,0,1,0-2h4V29A1,1,0,0,1,6.06,30Z\"/><path d=\"M30.06,27h-25V9h25a3,3,0,0,1,3,3V24A3,3,0,0,1,30.06,27Zm-23-2h23a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1h-23Z\"/><rect x=\"22.06\" y=\"20\" width=\"6\" height=\"2\"/><rect x=\"22.06\" y=\"14\" width=\"6\" height=\"2\"/><path d=\"M19.06,22h-8V20h7V14h2v7A1,1,0,0,1,19.06,22Z\"/>",
            "host": "<path d=\"M18,24.3a2.48,2.48,0,1,0,2.48,2.47A2.48,2.48,0,0,0,18,24.3Zm0,3.6a1.13,1.13,0,1,1,1.13-1.12A1.13,1.13,0,0,1,18,27.9Z\"/><rect x=\"13.5\" y=\"20.7\" width=\"9\" height=\"1.44\"/><path d=\"M25.65,3.6H10.35A1.35,1.35,0,0,0,9,4.95V32.4H27V4.95A1.35,1.35,0,0,0,25.65,3.6Zm-.45,27H10.8V5.4H25.2Z\"/><rect x=\"12.6\" y=\"7.2\" width=\"10.8\" height=\"1.44\"/><rect x=\"12.6\" y=\"10.8\" width=\"10.8\" height=\"1.44\"/>",
            "vm": "<path d=\"M11,5H25V8h2V5a2,2,0,0,0-2-2H11A2,2,0,0,0,9,5v6.85h2Z\"/><path d=\"M30,10H17v2h8v6h2V12h3V26H22V17a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2V31a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V28h8a2,2,0,0,0,2-2V12A2,2,0,0,0,30,10ZM6,31V17H20v9H16V20H14v6a2,2,0,0,0,2,2h4v3Z\"/>"
        };
        const iconSvg = visuNode.append('g')
            .attr('viewBox', `0 0 ${flowComponent.nodeRadius} ${flowComponent.nodeRadius}`)
            .append('g')
            .attr('transform', `translate(-11, -10) scale(0.60)`)
            .html((d) => iconsSvgCode[d.type]);

        const labels = visuNode.append('text')
            .text((d) => d.name)
            .attr('x', (d) => d.name.length * 6 / 2 * (-1))
            .attr('y', flowComponent.nodeRadius + 15)
            .attr('style', 'text-shadow: 1px 1px 2px white, -1px -1px 2px white;');

        for (let d3elements of [iconSvg, visuNode]) {
            d3elements
                .on("mouseover", function(d3Node, data) {
                    d3.select(this).style("cursor", "pointer");
                    visuNode
                        .filter((d2) => d2.type === 'vm')
                        .filter((d2) => {
                            return d2.uuid !== data.uuid;
                        })
                        .style("opacity", "25%");
                })
                .on("mouseout", function(d3Node, data) {
                    d3.select(this).style("cursor", "default");
                    visuNode
                        .filter((d2) => d2.type === 'vm')
                        .filter((d2) => d2.uuid !== data.uuid)
                        .style("opacity", "100%");
                });
        }

        // step1: zoom out, and let d3 recompute positions and sizes
        const [x, y, k] = [0, 0, zoomRatio];
        g.attr('transform', 'translate(' + x + ',' + y + ') scale(' + k + ')');
        flowComponent.svg.call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(x, y).scale(k)
        );

        // step2: center the main group by translating in the middle of the size difference
        // between the group and the svg
        let [x2, y2, k2] = [
            svgWidth / 2,
            svgHeight / 2,
            zoomRatio
        ];

        if (flowComponent.graphParameters.userMovedMap) {
            [x2, y2, k2] = [flowComponent.graphParameters.lastTransformation.x, flowComponent.graphParameters
                .lastTransformation.y, flowComponent.graphParameters.lastTransformation.k
            ];
        }

        g.attr('transform', 'translate(' + x2 + ',' + y2 + ') scale(' + k2 + ')');
        flowComponent.svg.call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(x2, y2).scale(k2)
        );
        // Save initial visualisation's position parameters to enable "zoom-in", "zoom-out" and "reset" buttons to work.
        if (flowComponent.graphParameters.firstTransformation === undefined) {
            flowComponent.graphParameters.firstTransformation = {
                x: x2,
                y: y2,
                k: k2
            }
        }
    }
}

