import {
    Component,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    GreenitTarget
} from '@app/greenit/greenit.enums';
import {
    JSONTarget
} from '@app/model';
import {
    JsonloaderService
} from '@app/services';
import {
    T7Component
} from '@app/t7/t7.component';
import {
    T8Component
} from '@app/t8/t8.component';
import {
    Subscription
} from 'rxjs';

@Component({
    selector: 'app-ipmi',
    templateUrl: './ipmi.component.html',
    styleUrls: ['./ipmi.component.css']
})
export class IpmiComponent implements OnInit {

    @ViewChild('t7') t7: T7Component;
    @ViewChild('t8') t8: T8Component;

    t1_target: GreenitTarget = GreenitTarget.IPMI_CO2;
    t2_target: JSONTarget = JSONTarget.T8_IPMI;

    allowReload: boolean = false;

    json_subscription: Subscription;

    /**
     * 
     */
    constructor(private json_svc: JsonloaderService) {}

    /**
     * 
     */
    ngOnInit(): void {

        // Need subscription to JsonloaderService to detect filter reload
        this.json_subscription = this.json_svc.currentJson.subscribe(
            json => {
                if (this.t7 !== undefined && this.t8 !== undefined && this.allowReload) {
                    this.t7.getGreenitcf().load(true);
                    this.t8.load(true);
                }
                this.allowReload = true;
            }
        )
    }

    /**
     * 
     */
    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.json_subscription != undefined) {
            this.json_subscription.unsubscribe();
        }
        //console.log("destroyed storage");
    }

}
