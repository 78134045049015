import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-kpi-health]'
})
export class KpihealthDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
