import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    ShareService,
    CapaplanService
} from '@app/services';

import {
    Message,
    VDCAlert,
    VDCBadge,
    VDCPrediction,
    VDCResource
} from '@app/model';

import * as Highcharts from 'highcharts';
import * as moment from 'moment';


@Component({
    selector: 'app-vdcevolution',
    templateUrl: './vdcevolution.component.html',
    styleUrls: ['./vdcevolution.component.css']
})
export class VDCevolutionComponent implements OnInit {

    @Input() selected_vdc: any;
    @Input() data_prediction: Array < any > ;

    message: Message;

    startTime: number;
    endTime: number;

    vdc_prediction: VDCPrediction;
    vdc_badge: VDCBadge;
    vdc_alert: VDCAlert;

    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.chart.showLoading();
    };

    // Default option
    options: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false
        },
        title: {
            text: 'Evolution of the number of VM',
            style: {
                font: 'normal 12px arial',
                color: 'grey'
            },
            align: 'center'
        },
        chart: {
            type: 'line',
            height: '33%',
            backgroundColor: '#ffffff',
            zoomType: 'x',
            resetZoomButton: {
                position: {
                    align: 'center',
                    y: -15
                }
            }
        },
        legend: {
            enabled: false
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            min: 0,
            labels: {
                align: 'right',
                x: -10
            },
            title: {
                text: 'VM number'
            }
        },
        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueDecimals: 0
        }
    };

    /**
     * 
     */
    constructor(private capaplan_svc: CapaplanService, private message_svc: ShareService) {
        this.startTime = 0;
        this.endTime = 2553270400000;
        this.emptyData();
    }

    /**
     * 
     */
    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(
            message => {
                this.message = message;
                this.startTime = message.minTimeFilter != undefined ? message.minTimeFilter : this.startTime;
                this.endTime = message.maxTimeFilter != undefined ? message.maxTimeFilter : this.endTime;
            }
        );
    }

    /**
     * 
     */
    ngOnChanges(changes: any): void {
        if (!changes.selected_vdc.firstChange) {
            this.selected_vdc = changes.selected_vdc.currentValue;
            this.chart.showLoading();
            this.updateComponent();
        } else {
            this.updateComponent();
        }
    }

    /**
     * 
     */
    private emptyData(): void {

        // Init default values
        this.vdc_prediction = {
            NBVM_USAGE: 0,
            USAGECPU_DATE_SAT: '',
            USAGERAM_DATE_SAT: '',
            USAGEDISK_DATE_SAT: ''
        };

        this.vdc_badge = {
            nbvm_usage_class: 'is-success',
            usagecpu_sat: 'badge-success',
            usageram_sat: 'badge-success',
            usagedisk_sat: 'badge-success'
        };

        this.vdc_alert = {
            nbvm_usage: 'info',
            usagecpu_sat: 'info',
            usageram_sat: 'info',
            usagedisk_sat: 'info'
        };

    }


    /**
     * 
     */
    private updateComponent(): void {

        this.emptyData();

        // Get VDC infos
        let data_vdc: any = Object.values(this.data_prediction).find(e => e.UUID == this.selected_vdc.uuid);

        if (data_vdc) {
            this.vdc_prediction.NBVM_USAGE = data_vdc.NBVM_CONS;

            let vdc_usage_cpu = data_vdc.CONSCPU_DATE;
            let vdc_usage_ram = data_vdc.CONSRAM_DATE;
            let vdc_usage_disk = data_vdc.CONSDISK_DATE;
            let vdc_current_nbvm = data_vdc.NBVM;

            if (this.vdc_prediction.NBVM_USAGE <= (vdc_current_nbvm * 0.1)) {
                this.vdc_badge.nbvm_usage_class = 'is-error';
                this.vdc_alert.nbvm_usage = 'danger';
            } else if (this.vdc_prediction.NBVM_USAGE <= (vdc_current_nbvm * 0.2)) {
                this.vdc_badge.nbvm_usage_class = 'is-warning';
                this.vdc_alert.nbvm_usage = 'warning';
            }

            this.vdc_prediction.USAGECPU_DATE_SAT = this.initSaturation(vdc_usage_cpu, VDCResource.CPU);
            this.vdc_prediction.USAGERAM_DATE_SAT = this.initSaturation(vdc_usage_ram, VDCResource.RAM);
            this.vdc_prediction.USAGEDISK_DATE_SAT = this.initSaturation(vdc_usage_disk, VDCResource.DISK);
        }

        // Get VDC evolution
        this.capaplan_svc.getEvolution(this.selected_vdc.uuid).pipe(first()).subscribe(
            data => {
                let datas: any = data;
                let times = datas.map((e) => e["time"]);
                let vms = datas.map((e) => e["vms"]);
                let dataPoints = vms.map((e, i) => [times[i], e]);

                // Remove previous serie
                if (this.chart.series[0]) {
                    this.chart.series[0].remove(false);
                }

                // Push to chart
                this.chart.addSeries({
                    type: 'line',
                    name: "The total number of VM",
                    cursor: 'pointer',
                    data: dataPoints
                });

                // Hide chart loading
                this.chart.hideLoading();

            }
        );
    }

    /**
     * 
     */
    private initSaturation(res_date: number, type: VDCResource): string {

        let date: string = '';
        let now = moment();

        let tmp_badge = "badge-success";
        let tmp_alert = "info";


        let sat_date = moment.unix(res_date / 1000);


        // INIT DATE & ALERT
        if (sat_date > now) {

            if ((sat_date.year() - now.year()) > 1) {
                date = 'over one year';
            } else {

                let days_remaining = 0;

                if (sat_date.year() == now.year())
                    days_remaining = sat_date.dayOfYear() - now.dayOfYear();
                else
                    days_remaining = 365 - now.dayOfYear() + sat_date.dayOfYear();

                if (days_remaining < 180) {
                    date = sat_date.format('LL');
                    if (days_remaining < 30) {
                        tmp_badge = 'badge-danger';
                        tmp_alert = 'danger';
                    } else {
                        tmp_badge = 'badge-warning';
                        tmp_alert = 'warning';
                    }
                } else {
                    date = 'over 6 months';
                }

            }
        } else {
            date = 'the date has passed';
            tmp_alert = 'danger';
            tmp_badge = 'badge-danger';
        }

        switch (type) {
            case VDCResource.CPU:
                this.vdc_badge.usagecpu_sat = tmp_badge;
                this.vdc_alert.usagecpu_sat = tmp_alert;
                break;
            case VDCResource.RAM:
                this.vdc_badge.usageram_sat = tmp_badge;
                this.vdc_alert.usageram_sat = tmp_alert;
                break;
            case VDCResource.DISK:
                this.vdc_badge.usagedisk_sat = tmp_badge;
                this.vdc_alert.usagedisk_sat = tmp_alert;
                break;
        }

        return date;
    }

}
