<div class="clr-row">
    <div class="clr-col-md-12 clr-col-12">
        <h3>Define your own cloud provider <button class="btn btn-info-outline btn-sm" (click)="viewProviderSettings()">Settings</button></h3>
        <a class="btn btn-info-outline btn-sm" clrVerticalNavLink routerLink="/cloud/myprovider/myregion" routerLinkActive="active">
            <cds-icon shape="home"></cds-icon>
            Back to costs
        </a>
        <button class="btn btn-success-outline btn-sm" [clrLoading]="committingState" (click)="commitData()">
            <cds-icon shape="upload"></cds-icon>
            Apply configuration
        </button>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-md-6 clr-col-12">
        <h4><cds-icon shape="vm"></cds-icon> Available VM types</h4>
    </div>
    <div class="clr-col-md-6 clr-col-12">
        <button style="float: right; margin-top: 15px;" class="btn btn-primary" (click)="newVmType()">Add new VM type</button>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngFor="let vmType of availableVmTypes">
        <div class="card">
            <div class="card-header">
                {{ vmType.name }}
            </div>
            <div class="card-block">
                <ul>
                    <li>cores: {{ vmType.attributes.cores }}</li>
                    <li>ram: {{ vmType.attributes.ram }} Mo</li>
                </ul>
            </div>
            <div class="card-footer">
                <button class="btn btn-sm btn-link" (click)="selectVmType(vmType, 'details')">Details</button>
                <button class="btn btn-sm btn-link" (click)="selectVmType(vmType, 'edit')">Edit</button>
                <button class="btn btn-sm btn-link" (click)="deleteVmType(vmType)" style="color: hsl(9, 92%, 50%)">Delete</button>
            </div>
        </div>
    </div>
</div>


<div class="clr-row">
    <div class="clr-col-md-6 clr-col-12">
        <h4><cds-icon shape="storage"></cds-icon> Available storage types</h4>
    </div>
    <div class="clr-col-md-6 clr-col-12">
        <button style="float: right; margin-top: 15px;" class="btn btn-primary" (click)="newStorageType()">Add new storage type</button>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12" *ngFor="let storageType of availableStorageTypes">
        <div class="card">
            <div class="card-header">
                {{ storageType.name }}
            </div>
            <div class="card-block">
                <ul>
                    <li>Minimal size: {{ storageType.attributes.min_size }} Go</li>
                    <li>Maximal size: {{ storageType.attributes.max_size }} Go</li>
                    <li>IOPS (max): {{ storageType.attributes.max_iops }} Ops/s</li>
                    <li>Throughput (max): {{ storageType.attributes.max_throughput }} Go/s</li>
                </ul>
            </div>
            <div class="card-footer">
                <button class="btn btn-sm btn-link" (click)="selectStorageType(storageType, 'details')">Details</button>
                <button class="btn btn-sm btn-link" (click)="selectStorageType(storageType, 'edit')">Edit</button>
                <button class="btn btn-sm btn-link" (click)="deleteStorageType(storageType)" style="color: hsl(9, 92%, 50%)">Delete</button>
            </div>
        </div>
    </div>
</div>

<clr-modal [(clrModalOpen)]="showVmTypeDetailsModal || showVmTypeEditModal" *ngIf="selectedVmType" [clrModalClosable]="false">
    <div class="modal-body" *ngIf="showVmTypeDetailsModal">
        <h3 class="modal-title">Details of "{{ selectedVmType.name }}" VM type</h3>
        <form clrForm>
            <clr-input-container>
                <label>Name</label>
                <label><span class="label label-info">{{ selectedVmType.name }}</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Cores</label>
                <label><span class="label label-info">{{ selectedVmType.attributes.cores }} cores</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Ram</label>
                <label><span class="label label-info">{{ selectedVmType.attributes.ram }} Mo</span></label>
            </clr-input-container>

            <p class="p4">Prices</p>
            <table class="table">
                <thead>
                <tr>
                    <th>Billing period </th>
                    <th>Linux</th>
                    <th>Red Hat</th>
                    <th>Windows</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>on demand</td>
                    <td>{{ selectedVmType.prices["od"].linux }} $/year</td>
                    <td>{{ selectedVmType.prices["od"].rhel }} $/year</td>
                    <td>{{ selectedVmType.prices["od"].win }} $/year</td>
                </tr>
                <tr>
                    <td>1 year</td>
                    <td>{{ selectedVmType.prices["1y"].linux }} $/year</td>
                    <td>{{ selectedVmType.prices["1y"].rhel }} $/year</td>
                    <td>{{ selectedVmType.prices["1y"].win }} $/year</td>
                </tr>
                <tr>
                    <td>3 years</td>
                    <td>{{ selectedVmType.prices["3y"].linux }} $/year</td>
                    <td>{{ selectedVmType.prices["3y"].rhel }} $/year</td>
                    <td>{{ selectedVmType.prices["3y"].win }} $/year</td>
                </tr>
                </tbody>
            </table>

            <p class="p4">Power consumption</p>

            <clr-input-container>
                <label>Grey emission</label>
                <label><span class="label label-info">{{ selectedVmType.consumption["grey_emission"] * 1000 | number: '1.0-2' }} g CO2.eq per hour</span></label>
            </clr-input-container>

            <table class="table">
                <thead>
                <tr>
                    <th>CPU Load</th>
                    <th>Consumption</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>0%</td>
                    <td>{{ selectedVmType.consumption[0].toFixed(2) }} W</td>
                </tr>
                <tr>
                    <td>100%</td>
                    <td>{{ selectedVmType.consumption[100].toFixed(2) }} W</td>
                </tr>
                </tbody>
            </table>

            <table class="table">
                <thead>
                <tr>
                    <th>Component</th>
                    <th>Consumption</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>memory</td>
                    <td>{{ selectedVmType.consumption["memory"].toFixed(2) }} W</td>
                </tr>
                <tr>
                    <td>storage</td>
                    <td>{{ selectedVmType.consumption["storage"].toFixed(2) }} W</td>
                </tr>
                </tbody>
            </table>

        </form>
    </div>
    <div class="modal-body" *ngIf="showVmTypeEditModal">
        <h3 class="modal-title" *ngIf="selectedVmType.originalObject !== undefined">Editing "{{ selectedVmType.originalObject.name }}" VM type</h3>
        <h3 class="modal-title" *ngIf="selectedVmType.originalObject === undefined">Creating a new VM type</h3>
        <form clrForm id="vmTypeForm" (ngSubmit)="commitSelectedVmType()" ngNativeValidate>
            <clr-input-container>
                <label>Name</label>
                <input clrInput type="text" [(ngModel)]="selectedVmType.name" name="vmTypeName" required />
            </clr-input-container>
            <clr-input-container>
                <label>Cores</label>
                <input type="number" step="1" min="1" clrInput [(ngModel)]="selectedVmType.attributes.cores" name="vmTypeCores" required />
            </clr-input-container>
            <clr-input-container>
                <label>Ram</label>
                <input type="number" step="1" min="1" clrInput [(ngModel)]="selectedVmType.attributes.ram" name="vmTypeRam" required />
                <clr-control-helper>Mo</clr-control-helper>
            </clr-input-container>

            <p class="p4">Prices</p>
            <table class="table">
                <thead>
                <tr>
                    <th>Billing period </th>
                    <th>Linux</th>
                    <th>Red Hat</th>
                    <th>Windows</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>on demand</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['od'].linux" name="price_od_linux" required /> $/year</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['od'].rhel" name="price_od_rhel" required /> $/year</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['od'].win" name="price_od_win" required /> $/year</td>
                </tr>
                <tr>
                    <td>1 year</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['1y'].linux" name="price_1y_linux" required /> $/year</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['1y'].rhel" name="price_1y_rhel" required /> $/year</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['1y'].win" name="price_1y_win" required /> $/year</td>
                </tr>
                <tr>
                    <td>3 years</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['3y'].linux" name="price_3y_linux" required /> $/year</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['3y'].rhel" name="price_3y_rhel" required /> $/year</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedVmType.prices['3y'].win" name="price_3y_win" required /> $/year</td>
                </tbody>
            </table>

            <p class="p4">Power consumption</p>

            <clr-input-container>
                <label>Grey emission</label>
                <label><span class="label label-info">{{ selectedVmType.consumption["grey_emission"] * 1000 | number: '1.0-2' }} g CO2.eq per hour</span></label>
            </clr-input-container>

            <table class="table">
                <thead>
                <tr>
                    <th>CPU Load</th>
                    <th>Consumption</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>0%</td>
                    <td>{{ selectedVmType.consumption[0].toFixed(2) }} W</td>
                </tr>
                <tr>
                    <td>100%</td>
                    <td>{{ selectedVmType.consumption[100].toFixed(2) }} W</td>
                </tr>
                </tbody>
            </table>

            <table class="table">
                <thead>
                <tr>
                    <th>Component</th>
                    <th>Consumption</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>memory</td>
                    <td>{{ selectedVmType.consumption["memory"].toFixed(2) }} W</td>
                </tr>
                <tr>
                    <td>storage</td>
                    <td>{{ selectedVmType.consumption["storage"].toFixed(2) }} W</td>
                </tr>
                </tbody>
            </table>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="selectVmType(selectedVmType.originalObject, 'edit')" *ngIf="showVmTypeDetailsModal">Edit</button>
        <button type="submit" class="btn btn-primary" form="vmTypeForm" *ngIf="showVmTypeEditModal">Save</button>
        <button type="button" class="btn btn-outline" (click)="hideVmTypeModal()">Close</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showStorageTypeDetailsModal || showStorageTypeEditModal" *ngIf="selectedStorageType"  [clrModalClosable]="false">
    <div class="modal-body" *ngIf="showStorageTypeDetailsModal">
        <h3 class="modal-title">Details of "{{ selectedStorageType.name }}" Storage type</h3>
        <form clrForm>
            <clr-input-container>
                <label>Name</label>
                <label><span class="label label-info">{{ selectedStorageType.name }}</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Minimum size</label>
                <label><span class="label label-info">{{ selectedStorageType.attributes.min_size }} Go</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Maximum size</label>
                <label><span class="label label-info">{{ selectedStorageType.attributes.max_size }} Go</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Maximum IOPS</label>
                <label><span class="label label-info">{{ selectedStorageType.attributes.max_iops }} IOPS</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Maximum throughput</label>
                <label><span class="label label-info">{{ selectedStorageType.attributes.max_throughput }} Go/s</span></label>
            </clr-input-container>

            <p class="p4" style="color: black;">Prices</p>
            <table class="table">
                <thead>
                <tr>
                    <th>Billing period</th>
                    <th>Volume</th>
                    <th>IOPS</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>on demand</td>
                    <td>{{ selectedStorageType.prices["od"].volume }} $/month</td>
                    <td>{{ selectedStorageType.prices["od"].iops }} $/month</td>
                </tr>
                </tbody>
            </table>

            <p class="p4">Power consumption</p>

            <table class="table">
                <thead>
                <tr>
                    <th>Consumption</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{ selectedStorageType.consumption }} W</td>
                </tr>
                </tbody>
            </table>

        </form>
    </div>
    <div class="modal-body" *ngIf="showStorageTypeEditModal">
        <h3 class="modal-title" *ngIf="selectedStorageType.originalObject !== undefined">Editing "{{ selectedStorageType.originalObject.name }}" Storage type</h3>
        <h3 class="modal-title" *ngIf="selectedStorageType.originalObject === undefined">Creating a new Storage type</h3>
        <form clrForm  id="storageTypeForm" (ngSubmit)="commitSelectedStorageType()" ngNativeValidate>
            <clr-input-container>
                <label>Name</label>
                <input clrInput type="text" [(ngModel)]="selectedStorageType.name" name="storageTypeName" required />
                <clr-control-error>We need a valid name for the storage type</clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>Minimum size</label>
                <input type="number" step="1" min="0" clrInput [(ngModel)]="selectedStorageType.attributes.min_size" name="storageTypeMinSize" required />
                <clr-control-helper>Go</clr-control-helper>
                <clr-control-error>Please specify the minimum amount of storage (Go)</clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>Maximum size</label>
                <input type="number" step="1" min="0" clrInput [(ngModel)]="selectedStorageType.attributes.max_size" name="storageTypeMaxSize" required />
                <clr-control-helper>Go</clr-control-helper>
                <clr-control-error>Please specify the maximum amount of storage</clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>Maximum IOPS</label>
                <input type="number" step="1" min="0" clrInput [(ngModel)]="selectedStorageType.attributes.max_iops" name="storageTypeMaxIops" required />
                <clr-control-helper>Io/s</clr-control-helper>
                <clr-control-error>Please specify the maximum amount of IOPS (IO/S)</clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>Maximum throughput</label>
                <input type="number" step="1" min="0" clrInput [(ngModel)]="selectedStorageType.attributes.max_throughput" name="storageTypeMaxThroughput" required />
                <clr-control-helper>Go/s</clr-control-helper>
                <clr-control-error>Please specify the maximum amount thoughput (MB/s)</clr-control-error>
            </clr-input-container>

            <p class="p4">Prices</p>
            <table class="table">
                <thead>
                <tr>
                    <th>Billing period</th>
                    <th>Volume</th>
                    <th>IOPS</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>on demand</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedStorageType.prices['od'].volume" name="price_od_volume" required /> $/Go/month</td>
                    <td><input type="number" step="0.01" min="0" style="width: 45%" [(ngModel)]="selectedStorageType.prices['od'].iops" name="price_od_iops" required /> $/IOPS/month</td>
                </tr>
            </table>

            <p class="p4">Power consumption</p>
            <table class="table">
                <thead>
                <tr>
                    <th>Consumption</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{ selectedStorageType.consumption }} W</td>
                </tr>
                </tbody>
            </table>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="selectStorageType(selectedStorageType.originalObject, 'edit')" *ngIf="showStorageTypeDetailsModal">Edit</button>
        <button type="submit" class="btn btn-primary" form="storageTypeForm" *ngIf="showStorageTypeEditModal">Save</button>
        <button type="button" class="btn btn-outline" (click)="hideStorageTypeModal()">Close</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="providerSettingsDetailsModal || providerSettingsEditModal" [clrModalClosable]="false">
    <div class="modal-body" *ngIf="providerSettingsDetailsModal">
        <h3 class="modal-title">Provider settings</h3>
        <form clrForm class="clr-form">
            <clr-input-container>
                <label>Emission factor</label>
                <label><span class="label label-info">{{ providerSettings.emissionFactor }} g Co2.eq / KWh</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Minimum power draw of servers (0% CPU load)</label>
                <label><span class="label label-info">{{ providerSettings.minimumPowerDrawServer }} W</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Maximum power draw of servers (0% CPU load)</label>
                <label><span class="label label-info">{{ providerSettings.maximumPowerDrawServer }} W</span></label>
            </clr-input-container>
            <clr-input-container>
                <label>Average amount of CPU cores per server</label>
                <label><span class="label label-info">{{ providerSettings.cpuCoresPerServer }} cores</span></label>
            </clr-input-container>
        </form>
    </div>
    <div class="modal-body" *ngIf="providerSettingsEditModal">
        <h3 class="modal-title">Editing provider settings</h3>
        <form clrForm  id="providerSettingsForm" (ngSubmit)="commitProviderSettings()" ngNativeValidate class="clr-form">
            <clr-input-container>
                <label>Emission factor</label>
                <input type="number" step="1" min="0" clrInput [(ngModel)]="tempProviderSettings.emissionFactor" name="emissionFactor" required />
                <clr-control-helper>g Co2.eq / KWh</clr-control-helper>
                <clr-control-error>Please specify the minimum amount of Co2 produced for each Watt of energy (g Co2.eq / KWh)</clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>Minimum power draw of servers (0% CPU load)</label>
                <input type="number" step="1" min="0" clrInput [(ngModel)]="tempProviderSettings.minimumPowerDrawServer" name="minimumPowerDrawServer" required />
                <clr-control-helper>W</clr-control-helper>
                <clr-control-error>Please specify the minimum amount of power that a typical server consumes</clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>Maximum power draw of servers (0% CPU load)</label>
                <input type="number" step="1" min="0" clrInput [(ngModel)]="tempProviderSettings.maximumPowerDrawServer" name="maximumPowerDrawServer" required />
                <clr-control-helper>W</clr-control-helper>
                <clr-control-error>Please specify the maximum amount of power that a typical server consumes</clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>Average amount of CPU cores per server</label>
                <input type="number" step="1" min="0" clrInput [(ngModel)]="tempProviderSettings.cpuCoresPerServer" name="cpuCoresPerServer" required />
                <clr-control-helper>cores</clr-control-helper>
                <clr-control-error>Please specify the typical amount of CPU cores that a server has</clr-control-error>
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="editProviderSettings()" *ngIf="providerSettingsDetailsModal">Edit</button>
        <button type="submit" class="btn btn-primary" form="providerSettingsForm" *ngIf="providerSettingsEditModal">Save</button>
        <button type="button" class="btn btn-outline" (click)="hideProviderSettings()">Close</button>
    </div>
</clr-modal>
