import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-recommendation-resizing-detail-disk]'
})
export class RecommendationresizingdetaildiskDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
