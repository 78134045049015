import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ComponentRef
} from '@angular/core';
import {
    first,
    ignoreElements
} from 'rxjs/operators';
import {
    ClrWizard
} from "@clr/angular";

import {
    AccountService,
    ManagementService,
    ShareService
} from '@app/services';

import {
    FilterMgt,
    TimeFilter,
    Message,
    User
} from '@app/model';

import {
    PeriodcalendarComponent
} from '@app/periodcalendar/periodcalendar.component';
import {
    PeriodcalendarDirective
} from '@app/directives/periodcalendar.directive';
import {
    WizardperiodcalendarDirective
} from '@app/directives/wizardperiodcalendar.directive';

import * as moment from 'moment';


@Component({
    selector: 'app-periodview',
    templateUrl: './periodview.component.html',
    styleUrls: ['./periodview.component.css']
})
export class PeriodviewComponent implements OnInit {

    message: Message;

    @ViewChild(PeriodcalendarDirective) addPeriods: PeriodcalendarDirective;
    @ViewChild(WizardperiodcalendarDirective) addWizardPeriods: WizardperiodcalendarDirective;

    @ViewChild("wizardPeriodView") wizardpv: ClrWizard;

    model: any;

    usr_views: TimeFilter[] = [];

    current_start: string = '';

    current_end: string = '';

    filter_view: FilterMgt[] = [];

    isDeleteView: boolean = false;

    isDeleteError: boolean = false;

    private componentRef: ComponentRef < any > ;

    private componentWizardRef: ComponentRef < any > ;

    currentUser: User;


    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private management_svc: ManagementService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef,
        private authentication_svc: AccountService
    ) {}

    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.model = {
            name: '',
            error: false,
            errorName: ''
        };
    }

    ngAfterViewInit() {
        this.getTimeView();
    }

    ngAfterViewChecked() {
        if (this.message.isPeriodWizard) {
            if (this.current_start != this.message.elementPeriodStart)
                this.current_start = this.message.elementPeriodStart;
            if (this.current_end != this.message.elementPeriodEnd)
                this.current_end = this.message.elementPeriodEnd;
        }

        this.cd.detectChanges();
    }


    initWizard(): void {
        this.message.isPeriodWizard = true;

        this.message.elementPeriodStart = '';
        this.message.elementPeriodEnd = '';

        this.current_start = this.message.elementPeriodStart;
        this.current_end = this.message.elementPeriodEnd;
    }

    callWizardCalendar(): void {
        setTimeout(() => $('#csstoadjust').children().children().css({
            'z-index': 1000
        }), 100);
        setTimeout(() => this.loadWizardPeriods(), 100);
    }

    goBack(): void {
        this.message.elementPeriodStart = '';
        this.message.elementPeriodEnd = '';
    }

    doFinish(): void {
        this.message.elementPeriodSelected = this.model.name;
        let begin = this.formatDateForServer(this.message.elementPeriodStart);
        let end = this.formatDateForServer(this.message.elementPeriodEnd);

        this.management_svc.addTimeView(this.currentUser.login, this.model.name, begin, end).subscribe(
            success => {
                this.doReset();
            },
            error => {
                this.doReset();
                if (error != null)
                    console.log(error);
            }
        );
    }

    doReset(): void {
        this.message.isPeriodWizard = false;
        this.model.name = '';
        this.wizardpv.reset();
        this.getTimeView();
        setTimeout(() => {
            this.cd.detectChanges()
        }, 1000);
    }

    checkName(val): void {

        if (this.filterList(val)) {
            this.model.error = true;
            this.model.errorName = 'to ' + val + ' (view already exists)';
        } else if (val == "all" || val == "ALL") {
            this.model.error = true;
            this.model.errorName = 'to ' + val;
        } else if (val.length == 0) {
            this.model.error = true;
            this.model.errorName = 'empty';
        } else if (val.length > 0) {
            this.model.error = false;

            //Remove accent
            var accent = [
                /[\300-\306]/g, /[\340-\346]/g, // A, a
                /[\310-\313]/g, /[\350-\353]/g, // E, e
                /[\314-\317]/g, /[\354-\357]/g, // I, i
                /[\322-\330]/g, /[\362-\370]/g, // O, o
                /[\331-\334]/g, /[\371-\374]/g, // U, u
                /[\321]/g, /[\361]/g, // N, n
                /[\307]/g, /[\347]/g, // C, c
            ];
            var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

            for (var i = 0; i < accent.length; i++) {
                this.model.name = this.model.name.replace(accent[i], noaccent[i]);
            }

            //Remove specs chars
            this.model.name = this.model.name.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:.;?,<>\- ]/g, "_");
        }
    }

    filterList(val: string): boolean {
        let isInFilter: boolean = false;
        for (var i in this.usr_views) {
            if (this.usr_views[i].filter == val) {
                isInFilter = true;
                break;
            }
        }

        return isInFilter;
    }

    private loadPeriods(): void {
        if (this.componentRef != undefined)
            this.componentRef.destroy();

        if (this.componentWizardRef != undefined)
            this.componentWizardRef.destroy();

        if (this.addPeriods != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PeriodcalendarComponent);
            const viewContainerRef = this.addPeriods.viewContainerRef;
            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent(componentFactory);
            if (this.message.elementPeriodSelected != '')
                this.componentRef.instance.disabled = false;
        }
    }

    private loadWizardPeriods(): void {
        if (this.componentRef != undefined)
            this.componentRef.destroy();

        if (this.componentWizardRef != undefined)
            this.componentWizardRef.destroy();

        if (this.addWizardPeriods != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PeriodcalendarComponent);
            const viewContainerRef = this.addWizardPeriods.viewContainerRef;
            viewContainerRef.clear();
            this.componentWizardRef = viewContainerRef.createComponent(componentFactory);
            this.componentWizardRef.instance.disabled = false;
        }
    }

    private getTimeView(): void {
        this.management_svc.getTimeView(this.currentUser.login).pipe(first()).subscribe(
            data => {
                this.usr_views = [];
                this.usr_views = data;
                this.filterView();
                this.loadTimeView();
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private filterView(): void {
        let filter_views: TimeFilter[] = [];
        for (var i in this.usr_views) {
            if (this.usr_views[i].filter != "all" && this.usr_views[i].filter != "last_1D" && this.usr_views[i]
                .filter != "last_30D" &&
                this.usr_views[i].filter != "last_60D" && this.usr_views[i].filter != "last_180D" && this.usr_views[
                    i].filter != "last_360D") {
                filter_views.push(this.usr_views[i]);
            }
        }

        this.usr_views = filter_views;
    }

    private loadTimeView(): void {
        if (this.usr_views.length == 0) {
            this.message.elementPeriodSelected = '';
            this.message.elementPeriodStart = '';
            this.message.elementPeriodEnd = '';
            this.current_start = this.message.elementPeriodStart;
            this.current_end = this.message.elementPeriodEnd;
        } else if (this.message.elementPeriodSelected == '' && this.usr_views.length > 0) {
            this.message.elementPeriodSelected = this.usr_views[0].filter;
        }

        if (this.message.elementPeriodSelected != '') {
            this.switchView();
        } else {
            this.loadPeriods();
        }

    }

    switchView(): void {

        let view: TimeFilter = this.getView(this.message.elementPeriodSelected);

        this.message.elementPeriodStart = this.formatDateForPicker(view.beginDate);
        this.message.elementPeriodEnd = this.formatDateForPicker(view.endDate);

        this.current_start = this.message.elementPeriodStart;
        this.current_end = this.message.elementPeriodEnd;

        this.loadPeriods();
    }

    private getView(filter: string): any {
        return this.usr_views.find(view => view.filter === filter);
    }

    updateChange(): void {
        let begin = this.formatDateForServer(this.message.elementPeriodStart);
        let end = this.formatDateForServer(this.message.elementPeriodEnd);

        this.management_svc.updTimeView(this.currentUser.login, this.message.elementPeriodSelected, begin, end)
            .subscribe(
                success => {
                    this.getTimeView();
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );
    }

    isDeletable(): void {
        this.filter_view = [];

        for (var i = 0; i < this.message.filterList.length; i++) {
            if (this.message.filterList[i].time_view == this.message.elementPeriodSelected)
                this.filter_view.push(this.message.filterList[i]);
        }

        if (this.filter_view.length > 0) {
            this.isDeleteView = false;
            this.isDeleteError = true;
        } else {
            this.isDeleteError = false;
            this.isDeleteView = true;
        }
    }

    removeView(): void {
        if (this.message.elementPeriodSelected != "")
            this.deletePeriodView(this.message.elementPeriodSelected);
    }

    private deletePeriodView(view: string): void {
        this.management_svc.remTimeFilter(this.currentUser.login, view).pipe(first()).subscribe();
        setTimeout(() => {
            this.message.elementPeriodSelected = "";
            this.getTimeView();
        });
    }

    private formatDateForServer(date: any): string {
        return moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
    }

    private formatDateForPicker(date: any): string {
        return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
    }

}
