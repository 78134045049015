import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    Json,
    JSONTarget,
    Message,
    RecoVm,
    User
} from '@app/model';


@Component({
    selector: 'app-recommendationresizingcpu',
    templateUrl: './recommendationresizingcpu.component.html',
    styleUrls: ['./recommendationresizingcpu.component.css']
})
export class RecommendationresizingcpuComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions2: DataTables.Settings = {};

    dtTrigger: Subject < any > = new Subject();

    message: Message;

    math = Math;

    reco_vms_data: RecoVm[] = [];

    cpu_model: RecoVm[] = [];

    private cpu_table: any;

    private currentUser: User;

    private search: string = '';


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.initDtOptions();

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_VM).subscribe(
            data => {
                this.reco_vms_data = data;
                if (this.reco_vms_data.length > 0)
                    this.buildData();
            }
        );
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewChecked(): void {

        if (this.search != this.message.recoResizeSearch) {
            this.search = this.message.recoResizeSearch;
            if (this.cpu_table == undefined) {
                this.cpu_table = $('#reco-resizing-cpu-list').dataTable();
                if (this.cpu_table == undefined)
                    this.cpu_table.api().search(this.search).draw();
            } else {
                this.cpu_table.api().search(this.search).draw();
            }
        }

        this.cd.detectChanges();
    }

    private buildData(): void {

        for (var i = 0; i < this.reco_vms_data.length; i++) {
            if (this.reco_vms_data[i].cpu_gain > 0)
                this.cpu_model.push(this.reco_vms_data[i]);
        }
        this.cpu_model.sort((a, b) => (a.cpu_gain > b.cpu_gain) ? 1 : -1);
        this.reloadTable();
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions2 = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            destroy: true,
            language: {
                zeroRecords: "no data"
            },
            dom: ''
        }
    }
}
