import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    ClrCombobox
} from "@clr/angular";

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    CloudProviderService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    v4 as uuidv4
} from 'uuid';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faLeaf
} from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-resizing-simulator',
    templateUrl: './resizing-simulator.component.html',
    styleUrls: ['./resizing-simulator.component.css']
})
export class ResizingSimulatorComponent implements OnInit {

    message: Message;

    provider: string = '';

    instancesAll: any = [];

    currentUser: User;

    datas: any = [];

    isLoading: boolean = true;

    isDatadb: boolean = true;

    isFamily: boolean = false;

    isRegionRdy: boolean = false;

    params: any = {
        regions: [],
        target_cost: 0,
        target_carbon: 0,
        isFamily: false,
        isType: false,
        isBestCostByRegion: true,
        isBestCarbonByRegion: true,
        criteria: "carbon"
    };

    template: any = {
        desclass: ''
    };

    selected_regions: any[] = [];

    simulation_res: any;

    detailSimu: any;

    @ViewChild('resourceComboBox') resourceComboBox: ClrCombobox < string > ;

    oss_gcp: string[] = ['Free (Debian, CentOS, Ubuntu)', 'Ubuntu pro', 'Microsoft windows', 'Redhat enterprise linux',
        'Redhat enterprise linux SAP',
        'SLES', 'SLES 12 for SAP', 'SLES 15 for SAP', 'SQL server standard', 'SQL server web',
        'SQL server enterprise'
    ];

    oss_azure: string[] = ['Free (CentOS, Ubuntu)', 'Microsoft windows', 'Microsoft windows BizTalk Enterprise',
        'Microsoft windows BizTalk Standard', 'Microsoft windows SQL Server Enterprise',
        'Microsoft windows SQL Server Standard',
        'Microsoft windows SQL Web Enterprise', 'Ubuntu pro', 'Redhat enterprise linux',
        'Red Hat Enterprise Linux with HA',
        'RHEL for SAP with HA', 'RHEL for SAP Business Applications', 'SUSE Linux Enterprise',
        'SUSE Linux Enterprise + 24x7 Support',
        'SUSE Linux Enterprise for HPC', 'SUSE Linux Enterprise for SAP', 'Ubuntu Advantage Essential',
        'Ubuntu Advantage Standard',
        'Ubuntu Advantage Advanced', 'SQL Server Enterprise Ubuntu Linux', 'SQL Server Standard Ubuntu Linux',
        'SQL Server Web Ubuntu Linux',
        'SQL Server Enterprise Red Hat Enterprise Linux', 'SQL Server Standard Red Hat Enterprise Linux',
        'SQL Server Web Red Hat Enterprise Linux',
        'SQL Server Enterprise SUSE Priority', 'SQL Server Standard SUSE Priority', 'SQL Server Web SUSE Priority'
    ];

    oss_aws: string[] = ['Linux', 'Microsoft windows server', 'Microsoft windows with SQL Server Enterprise',
        'Microsoft windows with SQL Server Standard',
        'Microsoft windows with SQL Server Web', 'Ubuntu pro', 'Redhat enterprise linux',
        'Suse linux Enterprise Server',
        'Linux SQL Server Standard', 'Linux SQL Server Web', 'Linux SQL Server Enterprise',
        'Red Hat Enterprise Linux with HA',
        'Red Hat Enterprise Linux SQL Server Standard', 'Red Hat Enterprise Linux SQL Server Web',
        'Red Hat Enterprise Linux SQL Server Enterprise',
        'Red Hat Enterprise Linux with HA and SQL Server Standard',
        'Red Hat Enterprise Linux with HA and SQL Server Enterprise'
    ];

    oss: string[] = [];

    families: string[] = [];

    series: string[] = [];

    its: string[] = [];

    commits: string[] = ['on-demand', 'spot', '1-year', '3-years'];

    bootdisktypes: string[] = [];

    bootdisktypes_gcp: string[] = ['standard persistent disk', 'ssd persistent disk', 'balanced persistent disk'];

    bootdisktypes_azure: string[] = ['standardhdd', 'standardssd', 'premiumssd'];

    bootdisktypes_aws: string[] = ['gp2', 'gp3', 'io1', 'sc1', 'st1'];

    bootdisksizes_azure: any[] = [];

    isNewInstance: boolean = false;

    modelInstance: any = {
        name: 'New instance',
        os: '',
        family: '',
        serie: '',
        it: '',
        hours: 730,
        sustained: 0,
        location: '',
        billing: 'on-demand',
        cost: 0,
        vcpu: 0,
        vram: 0,
        avgcpu: 30,
        co2: 0,
        bootdisktype: '',
        bootdisksize: 10,
        bootdisksizetype: 'standardhdd-s10',
        cost_it: 0,
        cost_os: 0,
        cost_storage: 0,
        cost_license: 0,
        co2_direct: 0,
        co2_grey: 0
    };

    isDetail: boolean = false;


    constructor(
        private account_svc: AccountService,
        private cloud_svc: CloudProviderService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private library: FaIconLibrary
    ) {
        library.addIcons(faLeaf);
    }

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.provider = this.message.cloudProvider;

        if (this.provider == "gcp") {
            this.oss = this.oss_gcp;
            this.bootdisktypes = this.bootdisktypes_gcp;
        } else if (this.provider == "azure") {
            this.oss = this.oss_azure;
            this.bootdisktypes = this.bootdisktypes_azure;
        } else if (this.provider == "aws") {
            this.oss = this.oss_aws;
            this.bootdisktypes = this.bootdisktypes_aws;
        }

        this.cloud_svc.isValidData(this.provider).pipe(first()).subscribe(
            data => {
                if (data.data > 0)
                    this.isDatadb = true;
                else
                    this.isDatadb = false;
            },
            error => {
                if (error != null)
                    console.log(error)

                this.isDatadb = false;
            }
        );

        this.cloud_svc.getRegions(this.provider).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.buildRegion(data);
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    loadData(): void {

        if (this.params.isType) {
            this.isFamily = true;
            this.params.isFamily = true;
        } else {
            this.isFamily = false;
        }

        if (this.params.target_cost == null)
            this.params.target_cost = 0;

        if (this.params.target_carbon == null)
            this.params.target_carbon = 0;

        let id: string = '';
        if (this.detailSimu != null)
            id = this.detailSimu.id;

        this.detailSimu = null;

        if (id != "")
            setTimeout(() => {
                this.detailSimu = this.getInstance(id);
            }, 100);

    }

    onDetailOpen(event: any): void {

        if (event != null) {

            this.isLoading = true;

            let regions: string[] = [];
            if (this.selected_regions != null) {
                for (let i = 0; i < this.selected_regions.length; i++) {
                    regions.push(this.selected_regions[i].code);
                }
            }

            let billing: string = 'od';
            switch (event.billing) {
                case "on demand":
                case "on-demand":
                    billing = 'od';
                    break;
                case "saving plan 1 year":
                case "1-year":
                    billing = '1yr';
                    break;
                case "saving plan 3 years":
                case "3-years":
                    billing = '3yr';
                    break;
                case "spot":
                    billing = 'spot';
                    break;
                default:
                    break;
            }

            let os: string = 'linux';

            if (event.os.toLowerCase().includes("windows"))
                os = 'win';
            else if (event.os.toLowerCase().includes("suse"))
                os = 'sles';
            else if (event.os.toLowerCase().includes("redhat"))
                os = 'rhel';
            else if (event.os.toLowerCase().includes("redhat") && event.os.toLowerCase().includes("ha"))
                os = 'rhel-sap-ha';
            else if (event.os.toLowerCase().includes("sles") && event.os.toLowerCase().includes("12"))
                os = 'sles-sap-12';
            else if (event.os.toLowerCase().includes("sles") && event.os.toLowerCase().includes("15"))
                os = 'sles-sap-15';
            else if (event.os.toLowerCase().includes("ubuntu") && event.os.toLowerCase().includes("pro"))
                os = 'ubuntu-pro';
            else if (event.os.toLowerCase().includes("sql") && event.os.toLowerCase().includes("enterprise"))
                os = 'sql-enterprise';
            else if (event.os.toLowerCase().includes("sql") && event.os.toLowerCase().includes("standard"))
                os = 'sql-standard';
            else if (event.os.toLowerCase().includes("sql") && event.os.toLowerCase().includes("web"))
                os = 'sql-web';

            // COMPUTE OS FOR GCP & AZURE
            if (this.provider == "gcp" || this.provider == "azure")
                os = "free";

            let it: string = event.it;
            if (this.provider == "azure") {
                let it_s: string[] = event.it.split('_');
                if (it_s.length > 1)
                    it = it_s[1].toLowerCase();
                else
                    it = event.it;
            }

            let criteria = "";
            if (this.params.isBestCostByRegion) {
                if (this.params.criteria == "cost")
                    criteria = "multi_cost"
                else if (this.params.criteria == "carbon")
                    criteria = "multi_carbon"
            } else {
                if (this.params.criteria == "cost")
                    criteria = "mono_cost"
                else if (this.params.criteria == "carbon")
                    criteria = "mono_carbon"
            }

            let res: any = {
                provider: this.provider,
                it: it,
                billing: billing,
                os: os,
                avgcpu: event.cpucons,
                disk: event.disk,
                hours: event.runh,
                regions: regions,
                target_p: this.params.target_cost,
                target_c: this.params.target_carbon,
                isFamily: this.params.isFamily,
                isType: this.params.isType,
                criteria: criteria
            };

            this.runSimulation(res);
        }
    }

    selectRegions(reg: string): void {

        if (this.provider == "aws") {
            for (let i = 0; i < this.params.regions.length; i++) {
                let cont: string[] = this.params.regions[i].code.split("-");
                if (cont.length > 0) {
                    if (cont[0] == reg) {
                        this.selected_regions.push(this.params.regions[i]);
                    }
                }
            }
        } else if (this.provider == "azure") {
            let regs: string[] = [];
            switch (reg) {
                case "eu":
                    regs.push('europe');
                    regs.push('france');
                    regs.push('germany');
                    regs.push('norway');
                    regs.push('poland');
                    regs.push('sweden');
                    regs.push('switzerland');
                    break;
                case "ap":
                    regs.push('asia');
                    regs.push('japan');
                    regs.push('korea');
                    break;
                case "us":
                    regs.push('us');
                    break;
                case "au":
                    regs.push('australia');
                    break;
                default:
                    break;
            }
            if (regs.length > 0) {
                for (let i = 0; i < this.params.regions.length; i++) {
                    let cont: string[] = this.params.regions[i].code.split("-");
                    if (cont.length > 0) {
                        for (let j = 0; j < regs.length; j++) {
                            if (cont[0] == regs[j]) {
                                this.selected_regions.push(this.params.regions[i]);
                            }
                        }
                    }
                }
            }
        } else if (this.provider == "gcp") {
            let regs: string[] = [];
            switch (reg) {
                case "eu":
                    regs.push('europe-central2');
                    regs.push('europe-north1');
                    regs.push('europe-southwest1');
                    regs.push('europe-west1');
                    regs.push('europe-west10');
                    regs.push('europe-west12');
                    regs.push('europe-west2');
                    regs.push('europe-west3');
                    regs.push('europe-west4');
                    regs.push('europe-west6');
                    regs.push('europe-west8');
                    regs.push('europe-west9');
                    break;
                case "ap":
                    regs.push('asia-east1');
                    regs.push('asia-east2');
                    regs.push('asia-northeast1');
                    regs.push('asia-northesouth1ast2');
                    regs.push('asia-northeast3');
                    regs.push('asia-south1');
                    regs.push('asia-south2');
                    regs.push('asia-southeast1');
                    regs.push('asia-southeast2');
                    break;
                case "us":
                    regs.push('us-central1');
                    regs.push('us-east1');
                    regs.push('us-east4');
                    regs.push('us-east5');
                    regs.push('us-south1');
                    regs.push('us-west1');
                    regs.push('us-west2');
                    regs.push('us-west3');
                    regs.push('us-west4');
                    break;
                case "au":
                    regs.push('australia-southeast1');
                    regs.push('australia-southeast2');
                    break;
                default:
                    break;
            }
            if (regs.length > 0) {
                for (let i = 0; i < this.params.regions.length; i++) {
                    for (let j = 0; j < regs.length; j++) {
                        if (this.params.regions[i].code == regs[j]) {
                            this.selected_regions.push(this.params.regions[i]);
                        }
                    }
                }
            }
        }

        if (this.selectRegions.length > 0)
            this.template.desclass = 'addLink';
        else
            this.template.desclass = '';

        this.loadData();
    }

    deselectAll(): void {

        this.selected_regions = [];
        this.template.desclass = '';

        setTimeout(() => {
            this.loadData();
        }, 100);
    }

    initNewInstance(): void {

        if (this.provider == "gcp") {
            this.modelInstance.os = 'Free (Debian, CentOS, Ubuntu)';
            this.modelInstance.bootdisktype = 'balanced persistent disk';
        } else if (this.provider == "azure") {
            this.modelInstance.os = 'Free (CentOS, Ubuntu)';
            this.modelInstance.bootdisktype = 'standardhdd';
        } else if (this.provider == "aws") {
            this.modelInstance.os = 'Linux';
            this.modelInstance.bootdisktype = 'gp2';
        }

        this.isNewInstance = true;
        this.buildFamilies();
        this.buildRegions();

        if (this.provider == "azure")
            this.buildStorage();

        setTimeout(() => {
            this.getItEstimation();
        }, 5000);
    }

    createNewInstance(): void {

        let newInstance = {
            name: this.modelInstance.name,
            id: uuidv4(),
            region: this.modelInstance.location,
            uc: this.modelInstance.family,
            it: this.modelInstance.it,
            billing: this.modelInstance.billing,
            status: "running",
            os: "free", // COMPUTE OS FOR GCP AND AZURE
            cpucons: this.modelInstance.avgcpu,
            runh: this.modelInstance.hours,
            disk: this.modelInstance.bootdisksize,
            computeCostYear: this.modelInstance.cost_it * 12,
            carbonYear: this.modelInstance.co2 * 12
        };
        this.instancesAll.push(newInstance);
    }

    buildSeries(): void {

        if (this.modelInstance.family != "") {
            let uc: string = "";
            switch (this.modelInstance.family) {
                case "Accelerator optimized":
                case "highperformancecompute":
                case "Media Accelerator Instances":
                    uc = "ao";
                    break;
                case "Compute optimized":
                case "computeoptimized":
                    uc = "co";
                    break;
                case "General purpose":
                case "generalpurpose":
                    uc = "gp";
                    break;
                case "Memory optimized":
                case "memoryoptimized":
                    uc = "mo";
                    break;
                case "Storage optimized":
                case "storageoptimized":
                    uc = "so";
                    break;
                case "Machine Learning ASIC Instances":
                    uc = "ml";
                    break;
                case "GPU instance":
                    uc = "gpu";
                    break;
                case "FPGA instance":
                    uc = "fp";
                    break;
                default:
                    break;
            }
            this.cloud_svc.getSeries(this.provider, uc).pipe(first()).subscribe(
                data => {
                    if (data.length > 0) {
                        this.series = data;
                        if (this.provider == "gcp")
                            this.modelInstance.serie = 'E2';
                        else if (this.provider == "azure")
                            this.modelInstance.serie = 'B';
                        else if (this.provider == "aws")
                            this.modelInstance.serie = 'T3A';

                        this.buildIts();
                    }
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    buildIts(): void {

        this.cloud_svc.getIts(this.provider, this.modelInstance.serie).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.its = data;
                    this.modelInstance.it = this.its[0];
                    this.getItProperties();
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    getItProperties(): void {

        this.getItEstimation();

        this.cloud_svc.getItProperties(this.provider, this.modelInstance.it, this.modelInstance.location).pipe(
            first()).subscribe(
            data => {
                this.modelInstance.vcpu = data.vcpu;
                this.modelInstance.vram = data.vram;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private runSimulation(resource: any): void {

        let api_url: any = '';
        api_url = this.cloud_svc.getBestInstanceType(resource);

        api_url.pipe(first()).subscribe(
            data => {
                this.simulation_res = data;
                this.isLoading = false;
            },
            error => {
                if (error != null)
                    console.log(error)

                this.isLoading = false;
            }
        );
    }

    private buildRegion(data: any): void {

        for (let i = 0; i < data.length; i++) {
            let region: any = {
                code: data[i].code,
                name: data[i].name,
                type: 'REGION'
            };
            this.params.regions.push(region);
        }

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.provider,
            JSONTarget.CLOUD_GRAPH_REGION2).subscribe(
            data => {
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < data[i].data.length; j++) {
                            this.instancesAll.push(data[i].data[j]);
                            this.isLoading = false;
                        }
                    }
                    for (let i = 0; i < this.instancesAll.length; i++) {
                        let targetRegion = this.getRegionName(this.instancesAll[i].region);
                        if (targetRegion != undefined) {
                            let regionName = targetRegion.name;
                            let fullRegion: string = this.instancesAll[i].region + " (" + regionName + ")";
                            this.instancesAll[i].region = fullRegion;
                        }
                    }
                } else {
                    this.isLoading = false;
                }
            }
        );

        this.isRegionRdy = true;
    }

    private getInstance(id: string): any {
        return this.instancesAll.find((instance: {
            id: string;
        }) => instance.id === id);
    }

    private buildFamilies(): void {

        this.cloud_svc.getFamilies(this.provider).pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.families = data;
                    if (this.provider == "gcp" || this.provider == "aws")
                        this.modelInstance.family = "General purpose";
                    else if (this.provider == "azure")
                        this.modelInstance.family = "generalpurpose";

                    this.buildSeries();
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private buildRegions(): void {

        this.modelInstance.location = this.params.regions[0].code + " (" + this.params.regions[0].name + ")";
    }

    buildStorage(): void {

        if (this.provider == "azure") {
            this.cloud_svc.getDataStorage(this.provider, this.modelInstance.bootdisktype).pipe(first()).subscribe(
                data => {
                    this.bootdisksizes_azure = data;
                    this.modelInstance.bootdisksize = this.getStorageSize(this.modelInstance.bootdisksizetype)
                        .size;
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        } else {
            this.getItEstimation();
        }
    }

    getItEstimation(): void {

        let os: string = 'free';

        switch (this.modelInstance.os) {
            case "Linux":
                os = 'linux';
                break;
            case "Ubuntu pro":
                os = 'ubuntu-pro';
                break;
            case "Microsoft windows":
                if (this.provider == "gcp")
                    os = 'win';
                else if (this.provider == "azure")
                    os = 'windows';
                break;
            case "Microsoft windows server":
                os = 'windows';
                break;
            case "Microsoft windows BizTalk Enterprise":
                os = 'biztalk-enterprise';
                break;
            case "Microsoft windows BizTalk Standard":
                os = 'biztalk-standard';
                break;
            case "Microsoft windows SQL Server Enterprise":
                os = 'sql-server-enterprise';
                break;
            case "Microsoft windows SQL Server Standard":
                os = 'sql-server-standard';
                break;
            case "Microsoft windows SQL Web Enterprise":
                os = 'sql-server-web';
                break;
            case "Microsoft windows with SQL Server Enterprise":
                os = "windows-sql-ent";
                break;
            case "Microsoft windows with SQL Server Web":
                os = "windows-sql-web";
                break;
            case "Microsoft windows with SQL Server Standard":
                os = "windows-sql-std";
                break;
            case "Redhat enterprise linux":
                if (this.provider == "gcp" || this.provider == "aws")
                    os = 'rhel';
                else if (this.provider == "azure")
                    os = 'red-hat';
                break;
            case "Redhat enterprise linux SAP":
            case "RHEL for SAP with HA":
                os = 'rhel-sap-ha';
                break;
            case "SLES":
                os = 'sles';
                break;
            case "SLES 12 for SAP":
                os = 'sles-sap-12';
                break;
            case "SLES 15 for SAP":
                os = 'sles-sap-15';
                break;
            case "SQL server standard":
                os = 'sql-standard';
                break;
            case "SQL server web":
                os = 'sql-web';
                break;
            case "SQL server enterprise":
                os = 'sql-enterprise';
                break;
            case "Red Hat Enterprise Linux with HA":
                if (this.provider == "azure")
                    os = 'rhel-ha'
                else
                    os = "red-hat-enterprise-linux-with-ha";
                break;
            case "RHEL for SAP Business Applications":
                os = 'rhel-sap-business';
                break;
            case "SUSE Linux Enterprise":
                os = 'sles-basic';
                break;
            case "SUSE Linux Enterprise + 24x7 Support":
                os = 'sles-standard';
                break;
            case "SUSE Linux Enterprise for HPC":
                os = 'sles-hpc-standard';
                break;
            case "SUSE Linux Enterprise for SAP":
                os = 'sles-sap';
                break;
            case "Suse linux Enterprise Server":
                os = 'suse'
                break;
            case "Ubuntu Advantage Essential":
                os = 'ubuntu-advantage-essential';
                break;
            case "Ubuntu Advantage Standard":
                os = 'ubuntu-advantage-standard';
                break;
            case "Ubuntu Advantage Advanced":
                os = 'ubuntu-advantage-advanced'
                break;
            case "SQL Server Enterprise Ubuntu Linux":
                os = 'sql-server-enterprise-linux';
                break;
            case "SQL Server Standard Ubuntu Linux":
                os = 'sql-server-standard-linux';
                break;
            case "SQL Server Web Ubuntu Linux":
                os = 'sql-server-web-linux';
                break;
            case "SQL Server Enterprise Red Hat Enterprise Linux":
                os = 'sql-server-enterprise-redhat';
                break;
            case "SQL Server Standard Red Hat Enterprise Linux":
                os = 'sql-server-standard-redhat';
                break;
            case "SQL Server Web Red Hat Enterprise Linux":
                os = 'sql-server-web-redhat';
                break;
            case "SQL Server Enterprise SUSE Priority":
                os = 'sql-server-enterprise-sles';
                break;
            case "SQL Server Standard SUSE Priority":
                os = 'sql-server-standard-sles';
                break;
            case "SQL Server Web SUSE Priority":
                os = 'sql-server-web-sles';
                break;
            case "Linux SQL Server Standard":
                os = 'linux-sql-std';
                break;
            case "Linux SQL Server Web":
                os = 'linux-sql-web';
                break;
            case "Linux SQL Server Enterprise":
                os = 'linux-sql-ent';
                break;
            case "Red Hat Enterprise Linux SQL Server Standard":
                os = 'rhel-sql-std';
                break;
            case "Red Hat Enterprise Linux SQL Server Web":
                os = 'rhel-sql-web';
                break;
            case "Red Hat Enterprise Linux SQL Server Enterprise":
                os = 'rhel-sql-ent';
                break;
            case "Red Hat Enterprise Linux with HA and SQL Server Standard":
                os = 'red-hat-enterprise-linux-with-ha-sql-std';
                break;
            case "Red Hat Enterprise Linux with HA and SQL Server Enterprise":
                os = 'red-hat-enterprise-linux-with-ha-sql-ent';
                break;
            default:
                break;
        }

        let billing: string = 'od';
        switch (this.modelInstance.billing) {
            case "spot":
                billing = 'spot';
                break;
            case "1-year":
                billing = '1yr';
                break;
            case "3-years":
                billing = '3yr';
                break;
            default:
                break;
        }

        let diskType: string = '';
        if (this.provider == "gcp") {
            switch (this.modelInstance.bootdisktype) {
                case "balanced persistent disk":
                    diskType = 'pd-balanced';
                    break;
                case "standard persistent disk":
                    diskType = 'pd-standard';
                    break;
                case "ssd persistent disk":
                    diskType = 'pd-ssd';
                    break;
                default:
                    break;
            }
        } else if (this.provider == "azure") {
            diskType = this.modelInstance.bootdisksizetype;
            this.modelInstance.bootdisksize = this.getStorageSize(this.modelInstance.bootdisksizetype).size;
        } else if (this.provider == "aws") {
            diskType = this.modelInstance.bootdisktype;
        }

        let location: string = '';
        let cont: string[] = this.modelInstance.location.split(" ");
        if (cont.length > 0)
            location = cont[0];

        let res: any = {
            provider: this.provider,
            it: this.modelInstance.it,
            os: os,
            region: location, // REGION CODE
            hours: this.modelInstance.hours, // PER MONTH
            billing: billing,
            diskType: diskType,
            disk: this.modelInstance.bootdisksize, // IN GB
            avgcpu: this.modelInstance.avgcpu // IN %
        };

        this.cloud_svc.getItEstimation(res).pipe(first()).subscribe(
            data => {
                this.modelInstance.cost = data.cost_current_month;
                if (this.modelInstance.sustained > 0)
                    this.modelInstance.cost = this.modelInstance.cost - (this.modelInstance.cost * this
                        .modelInstance.sustained / 100);

                this.modelInstance.co2 = data.co2_current_month;
                this.modelInstance.cost_it = data.cost_it_current_month;
                this.modelInstance.cost_os = data.cost_os_current_month;
                this.modelInstance.cost_license = data.cost_license_current_month;
                this.modelInstance.cost_storage = data.cost_storage_current_month;
                this.modelInstance.co2_direct = data.co2_direct_current_month;
                this.modelInstance.co2_grey = data.co2_grey_current_month;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private getStorageSize(type: string): any {
        return this.bootdisksizes_azure.find(bootdisk => bootdisk.type === type);
    }

    getRegionName(code: string): any {
        console.log(this.provider + " :: " + code);
        if (this.provider == "azure" || this.provider == "aws")
            return this.params.regions.find((region: {
                code: string;
            }) => region.code.replace(/-/g, '') === code.replace(/-/g, ''));
        else
            return this.params.regions.find((region: {
                code: string;
            }) => region.code === code);
    }
}
