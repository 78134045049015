<ng-template #templateSelectedResources let-item="item" xmlns="http://www.w3.org/1999/html">
    <span class="label label-warning" *ngIf="item.mode == 'focus'" style="height: 0.75rem; vertical-align: baseline;">focus</span>
    <span class="label label-danger" *ngIf="item.mode == 'ignore'" style="height: 0.75rem; vertical-align: baseline;">ignore</span>
    <span>{{ item.resource.name }}</span>
</ng-template>

<ng-template #templateDisplayResources let-item='item'>
    <span class="label label-warning" *ngIf="item.mode == 'focus'" style="height: 0.75rem; vertical-align: baseline;">focus</span>
    <span class="label label-danger" *ngIf="item.mode == 'ignore'" style="height: 0.75rem; vertical-align: baseline;">ignore</span>
    <span *ngIf="item.resource.name === undefined && item.resource.ipaddress !== undefined">{{ item.resource.ipaddress }}</span>
    <span *ngIf="item.resource.name !== undefined">{{ item.resource.name }}</span>
</ng-template>

<ng-template #templateDisplayResourcesWhenEmpty>
</ng-template>

<ng-template #templateDisplayResourcesWhenErrors>
</ng-template>

<ng-template #signpost_dependencies_viewer>
    <label class="clr-control-label">Show everything:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="yes" name="showEverything" [(ngModel)]="showEverything"
               (change)="reloadUrlParametersDataAndRecomputeDistances()"/>
        <label>Yes</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="no" name="showEverything" [(ngModel)]="showEverything"
               (change)="reloadUrlParametersDataAndRecomputeDistances()"/>
        <label>No</label>
    </clr-radio-wrapper>

    <label class="clr-control-label">Regroup Unknown IPs:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="yes" name="regroupUnknownIps" [(ngModel)]="regroupUnknownIps"
               (change)="reloadUrlParametersUiAndRecomputeDistances()"/>
        <label>Yes</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="no" name="regroupUnknownIps" [(ngModel)]="regroupUnknownIps"
               (change)="reloadUrlParametersUiAndRecomputeDistances()"/>
        <label>No</label>
    </clr-radio-wrapper>

    <label class="clr-control-label">Show indirect links:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="yes" name="onlyDirectLinks" [(ngModel)]="showIndirectLinks"
               (change)="reloadUrlParametersUiAndRecomputeDistances()"/>
        <label>Yes</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="no" name="onlyDirectLinks" [(ngModel)]="showIndirectLinks"
               (change)="reloadUrlParametersUiAndRecomputeDistances()"/>
        <label>No</label>
    </clr-radio-wrapper>

    <label class="clr-control-label">Export:</label>
    <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-primary btn-sm" (click)="exportGraph()">Graph</button>
    </div>
</ng-template>

<ng-template #signpost_clusters_viewer>
    <label class="clr-control-label">Gravity:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="automatic" name="gravityMode" [(ngModel)]="gravityMode"/>
        <label>Automatic</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="manual" name="gravityMode" [(ngModel)]="gravityMode"/>
        <label>Manual</label>
    </clr-radio-wrapper>

    <clr-range-container [clrRangeHasProgress]="true" *ngIf="gravityMode == 'manual'" style="margin-top: 0px;">
        <input type="range" clrRange [(ngModel)]="gravityValue" min="-10000" max="-300" name="four"
               (change)="reloadUiAndRecomputeDistances()"/>
        <clr-control-helper>value: {{gravityValue}}</clr-control-helper>
    </clr-range-container>

    <label class="clr-control-label">Show everything:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="yes" name="showEverything" [(ngModel)]="showEverything"
               (change)="reloadUrlParametersDataAndRecomputeDistances()"/>
        <label>Yes</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="no" name="showEverything" [(ngModel)]="showEverything"
               (change)="reloadUrlParametersDataAndRecomputeDistances()"/>
        <label>No</label>
    </clr-radio-wrapper>

    <label class="clr-control-label">Regroup Unknown IPs:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="yes" name="regroupUnknownIps" [(ngModel)]="regroupUnknownIps"
               (change)="reloadUrlParametersUiAndRecomputeDistances()"/>
        <label>Yes</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="no" name="regroupUnknownIps" [(ngModel)]="regroupUnknownIps"
               (change)="reloadUrlParametersUiAndRecomputeDistances()"/>
        <label>No</label>
    </clr-radio-wrapper>

    <label class="clr-control-label">Show traffic on links:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="yes" name="linksAreColored" [(ngModel)]="linksAreColored"
               (change)="reloadUi()"/>
        <label>Yes</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="no" name="linksAreColored" [(ngModel)]="linksAreColored"
               (change)="reloadUi()"/>
        <label>No</label>
    </clr-radio-wrapper>

    <label class="clr-control-label">Show indirect links:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="yes" name="onlyDirectLinks" [(ngModel)]="showIndirectLinks"
               (change)="reloadUiAndRecomputeDistances()"/>
        <label>Yes</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="no" name="onlyDirectLinks" [(ngModel)]="showIndirectLinks"
               (change)="reloadUiAndRecomputeDistances()"/>
        <label>No</label>
    </clr-radio-wrapper>

    <label class="clr-control-label">Reload:</label>
    <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-primary btn-sm" (click)="reloadUiAndRecomputeDistances()">UI</button>
        <button class="btn btn-primary btn-sm" (click)="reloadDataAndRecomputeDistances()">Data and UI</button>
    </div>

    <label class="clr-control-label">Export:</label>
    <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-primary btn-sm" (click)="exportGraph()">Graph</button>
    </div>

<!--    <label *ngIf="focusedVms.length > 0" class="clr-control-label">Focus:</label>-->
<!--    <div *ngIf="focusedVms.length > 0" class="btn-group btn-primary btn-icon">-->
<!--        <button class="btn btn-danger btn-sm" (click)="clearFocus()">Clear focus</button>-->
<!--    </div>-->
</ng-template>

<ng-template #signpost_appsgroups_viewer>
    <label class="clr-control-label">Gravity:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="automatic" name="gravityMode" [(ngModel)]="gravityMode"/>
        <label>Automatic</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="manual" name="gravityMode" [(ngModel)]="gravityMode"/>
        <label>Manual</label>
    </clr-radio-wrapper>

    <clr-range-container [clrRangeHasProgress]="true" *ngIf="gravityMode == 'manual'" style="margin-top: 0px;">
        <input type="range" clrRange [(ngModel)]="gravityValue" min="-10000" max="-300" name="four"
               (change)="reloadUiAndRecomputeDistances()"/>
        <clr-control-helper>value: {{gravityValue}}</clr-control-helper>
    </clr-range-container>

    <label class="clr-control-label">Reload:</label>
    <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-primary btn-sm" (click)="reloadUiAndRecomputeDistances()">UI</button>
        <button class="btn btn-primary btn-sm" (click)="reloadDataAndRecomputeDistances()">Data and UI</button>
    </div>

    <label class="clr-control-label">Export:</label>
    <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-primary btn-sm" (click)="exportGraph()">Graph</button>
    </div>
</ng-template>

<ng-template #signpost_protocol_analysis>
    <label class="clr-control-label">Show everything:</label>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="yes" name="showEverything" [(ngModel)]="showEverything"
               (change)="reloadDataAndRecomputeDistances()"/>
        <label>Yes</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio value="no" name="showEverything" [(ngModel)]="showEverything"
               (change)="reloadDataAndRecomputeDistances()"/>
        <label>No</label>
    </clr-radio-wrapper>

    <label class="clr-control-label">Export:</label>
    <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-primary btn-sm" (click)="exportGraph()">Graph</button>
    </div>
</ng-template>

<clr-modal [(clrModalOpen)]="openHeatmapModal" [clrModalStaticBackdrop]="false" [clrModalSize]="'xl'">
    <h3 class="modal-title">{{ "dcnetscope.heatmap.select_time_text" | transloco }}</h3>
    <div class="modal-body">
        <div class="clr-row">
            <div class="clr-col-12">
                <app-heatmap-highcharts-heatmap
                        id="dateSelectionHeatmap"
                        [onClick]=""
                        [updateEmitter]="resourceSelectionChanged"
                        [clickOnTimeSlotEmitter]="clickOnTimeSlotEmitter"
                        [selectedResources1]="[{uuid: 'datacenter'}]"
                        [selectedResources2]="[{uuid: 'datacenter'}]"
                        [maxHeight]="400"
                        [legendMaxHeight]="280"
                        [doubleClickSelectDay]="true"
                        [selectLastDay]="true"
                        [selectedTime]="startTime"
                        [selectedTimePeriod]="timeResolution"
                        #temporalSelectionHeatMap
                ></app-heatmap-highcharts-heatmap>
            </div>
        </div>
    </div>
</clr-modal>


<div style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-flow: column;">

    <clr-alert [clrAlertType]="'danger'" [clrAlertAppLevel]="true" *ngIf="failureMode && !isLoading">
        <clr-alert-item>
            <span class="alert-text">Cannot load data from DC Netscope API. Please check the diagnostic page.</span>
            <div class="alert-actions">
                <a routerLink="/netscope/diagnostic" routerLinkActive="active">
                    <button class="btn alert-action">
                        <cds-icon shape="checkbox-list"></cds-icon>
                        Diagnostic
                    </button>
                </a>
            </div>
        </clr-alert-item>
    </clr-alert>

    <div class="clr-row" style="margin: 0px; background-color: rgb(0, 55, 76); color : white; overflow: visible;">
        <div class="clr-col" id="flow_view_title" style="flex-grow: 0; color: white; text-align: center;">
            <table style="height: 100%; min-width: 100px; font-weight: 400; font-size: .8rem; letter-spacing: .01em; text-decoration: none;">
                <tr>
                    <td>
                        <cds-icon shape="organization" style="margin-right: 10px; margin-bottom: 3px;" size="md" *ngIf="selectedView=='dependencies-viewer'"></cds-icon>
                        <cds-icon shape="chat-bubble" style="margin-right: 10px; margin-bottom: 3px;" size="md" *ngIf="selectedView=='protocol-analysis'"></cds-icon>
                        <cds-icon shape="recycle" style="margin-right: 10px; margin-bottom: 3px;" size="md" *ngIf="selectedView=='clusters-viewer'"></cds-icon>
                        <cds-icon shape="node-group" style="margin-right: 10px; margin-bottom: 3px;" size="md" *ngIf="selectedView=='apps-groups'"></cds-icon>
                    </td>
                    <td style="text-align: left;">{{ "dcnetscope." + viewLabel | transloco }}</td>
                </tr>
            </table>
        </div>

        <div class="clr-col" id="flow_view_datepicker_button" style="flex-grow: 0; cursor: pointer; background-color: rgb(38, 85, 103);" (click)="openHeatmapModal=!openHeatmapModal">
            <a style="pointer-events: none">
                <div class="clr-row" style="height: 100%; padding: 5px; pointer-events: none;">
                    <table style="height: 100%; color: rgb(250, 250, 250); ; text-align: center; font-family: 'Metropolis', 'Avenir Next', 'Helvetica Neue', 'Arial, sans-serif';
                    font-size: 18px; font-weight: 500;">
                        <tr>
                            <td>
                                <cds-icon shape="calendar" class="is-solid" style="margin: auto;">
                                </cds-icon>
                            </td>
                            <td style="padding: 5px;">
                                <ng-container *ngIf="startTime === endTime">
                                    <span *ngIf="timeResolution=='daily'">{{ startTime * 1000 | date: 'dd/MM/yyyy'}}</span>
                                    <span *ngIf="timeResolution=='hourly'">{{ startTime * 1000 | date: 'dd/MM/yyyy HH:mm'}}</span>
                                </ng-container>
                                <ng-container *ngIf="startTime !== endTime">
                                    <span *ngIf="timeResolution=='daily'">{{ startTime * 1000 | date: 'dd/MM/yyyy'}} to {{ endTime * 1000 | date: 'dd/MM/yyyy'}}</span>
                                    <span *ngIf="timeResolution=='hourly'">{{ startTime * 1000 | date: 'dd/MM/yyyy HH:mm'}} to {{ endTime * 1000 | date: 'dd/MM/yyyy HH:mm'}}</span>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <ng-container>
                                    <span class="label label-info"
                                          *ngIf="timeResolution=='daily'"
                                          style="position: relative; bottom: 5px;">{{ "dcnetscope.whole_day" | transloco }}</span>
                                    <span class="label label-info"
                                          *ngIf="timeResolution=='hourly'"
                                          style="position: relative; bottom: 5px;">{{ "dcnetscope.one_hour" | transloco }}</span>
                                </ng-container>
                            </td>
                        </tr>
                    </table>
                </div>
            </a>
        </div>

        <div id="column-flow-combobox" class="clr-col" style="padding-top: 10px; padding-bottom: 5px;">
            <netscope-common-flowview-combobox
                    [(selectedItems)]="currentComboboxSelection"
                    [itemsGenerator]="fetchResourcesForCombobox"
                    (onChange)="onComboboxChange($event)"
                    [templateSelectedItems]="templateSelectedResources"
                    [templateDisplayItems]="templateDisplayResources"
                    [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
                    [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
                    [itemsField]="'key'"
                    style="white-space: normal;"
            ></netscope-common-flowview-combobox>
        </div>

        <div class="clr-col" style="width: 100px; flex-grow: 0; padding-left: 0px;">
            <table style="height: 100%;">
                <tr>
                    <td style="padding: 5px;">
                        <a href="javascript://" id="flow_view_reload_button" class="nav-link nav-icon" aria-label="reload">
                            <cds-icon shape="refresh" (click)="reloadDataAndRecomputeDistances()" *ngIf="!isLoading" style="color: white;"></cds-icon>
                            <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <a href="javascript://" class="nav-link nav-icon" aria-label="tour">
                            <cds-icon shape="help" style="color: white;" (click)="launchTour()"></cds-icon>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <clr-dropdown [clrCloseMenuOnItemClick]="true">
                            <a clrDropdownTrigger href="javascript://" class="nav-link nav-icon" aria-label="settings">
                                <cds-icon shape="cog" style="color: white;"></cds-icon>
                            </a>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Language</label>
                                <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="setLanguage('french')">French</div>
                                <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="setLanguage('english')">English</div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="clr-row" style="position: relative; height: 100%; bottom: 0px; width: 100%; margin: 0px;">
        <clr-tabs style="background-color: white; width: 100%; height: 100%;">
            <clr-tab>
                <button id="flows_visualisation_button" clrTabLink style="margin-left: 25px;">{{ 'dcnetscope.flows_tab_visualisation' | transloco }}</button>
                <clr-tab-content>
                    <div class="clr-row" *ngIf="selectedView === 'protocol-analysis'" style="position: absolute; margin-top: 5px; margin-left: 35px; z-index: 200;">
                    <span *ngFor="let selectionItem of currentSelection; let i = index">
                        <span *ngIf="i > 0" style="margin-right: 5px;">/</span>
                        <span (click)="zoomOnNode(selectionItem)" style="cursor: pointer;">
                            <span class="label {{ i === currentSelection.length -1 ? 'label-info' : '' }}">{{ selectionItem.data.object.svg_label }} : {{ selectionItem.data.object.name }}</span>
                        </span>
                    </span>
                    </div>
                    <div style="position: absolute; left: 0px; right: 0px; top: 35px; bottom: 0px;">
                        <div class="clr-row" style="margin: 0px; position: absolute; z-index: 999; width: 100%; top: 0px; right: 15px; float: right;  pointer-events: none;">
                            <div class="clr-col-6">
                            <span style="position: relative; z-index: 999; top: 5px; left: 15px; pointer-events: auto;">
                                <clr-signpost>
                                     <cds-icon shape="cog"
                                               solid
                                               clrSignpostTrigger>
                                     </cds-icon>
                                     <clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen>
                                         <div *ngIf="selectedView === 'dependencies-viewer'">
                                            <ng-container *ngTemplateOutlet="signpost_dependencies_viewer"></ng-container>
                                         </div>
                                         <div *ngIf="selectedView === 'protocol-analysis'">
                                            <ng-container *ngTemplateOutlet="signpost_protocol_analysis"></ng-container>
                                         </div>
                                         <div *ngIf="selectedView === 'clusters-viewer'">
                                            <ng-container *ngTemplateOutlet="signpost_clusters_viewer"></ng-container>
                                         </div>
                                         <div *ngIf="selectedView === 'apps-groups'">
                                            <ng-container *ngTemplateOutlet="signpost_appsgroups_viewer"></ng-container>
                                         </div>
                                     </clr-signpost-content>
                                </clr-signpost>

                                <ng-container *ngIf="selectedView !== 'protocol-analysis'">
                                    <span style="margin-left: 10px;">
                                        <span class="badge" style="height: 1.1rem; cursor: pointer;" (click)="zoomInVisualisation()">
                                          <cds-icon shape="zoom-in"></cds-icon>
                                          <span class="clr-sr-only">zoom in</span>
                                        </span>

                                        <span class="badge" style="height: 1.1rem; cursor: pointer;" (click)="zoomOutVisualisation()">
                                          <cds-icon shape="zoom-out"></cds-icon>
                                          <span class="clr-sr-only">zoom out</span>
                                        </span>

                                        <span class="badge" style="height: 1.1rem; cursor: pointer;" (click)="recenterVisualisation()">
                                          <cds-icon shape="crosshairs"></cds-icon>
                                          <span class="clr-sr-only">re-center</span>
                                        </span>
                                    </span>
                                </ng-container>

                                <ng-container *ngIf="selectedView === 'apps-groups'">
                                    <input type="radio" value="automatic" [(ngModel)]="appsGroupsMode" class="clr-radio" (change)="reloadData()"/>
                                    Automatic
                                    <input type="radio" value="user_appsgroups" [(ngModel)]="appsGroupsMode" class="clr-radio" (change)="reloadData()"/>
                                    My apps groups

                                    <a [routerLink]="['/netscope/apps-groups/edit']" *ngIf="appsGroupsMode == 'user_appsgroups'">
                                        <span class="label label-info" style="margin-left: 10px;">edit apps groups</span>
                                    </a>
                                </ng-container>

                            </span>
                            </div>

                            <div class="clr-col-2" style="text-align: center;">
                            <span id="dcnetscope-visualisation-topbar" class="badge badge-blue"
                                  style="margin-top: 10px; font-size: 1em; padding: 15px; border-radius: 2rem; background-color: black; pointer-events: auto; background-color: white;"
                                  *ngIf="this.selectedAppsGroups.length > 0">
                                <span style="margin-right: 5px;" *ngIf="this.selectedAppsGroups.length == 1">
                                    {{ this.selectedAppsGroups[0].name }}
                                </span>
                                <span style="margin-right: 5px;" *ngIf="this.selectedAppsGroups.length == 2">
                                    {{ this.selectedAppsGroups[0].name }}
                                    <cds-icon shape="link" solid>
                                    </cds-icon>
                                    {{ this.selectedAppsGroups[1].name }}
                                </span>
                                |
                                <cds-icon shape="recycle" solid style="cursor: pointer; margin-left: 5px;"
                                          (click)="navigateSelection('dependencies-viewer');">
                                </cds-icon>
                                <cds-icon shape="chat-bubble" solid style="cursor: pointer; margin-left: 5px;"
                                          (click)="navigateSelection('protocol-analysis');">
                                </cds-icon>
                                <cds-icon shape="organization" solid
                                          style="cursor: pointer; margin-left: 5px; margin-right: 5px;"
                                          (click)="navigateSelection('clusters-viewer');">
                                </cds-icon>
                                |
                                <cds-icon shape="times-circle" solid style="cursor: pointer; margin-left: 5px;"
                                          (click)="removeSelection();">
                                </cds-icon>
                            </span>
                            </div>

                            <div class="clr-col-4" style="text-align: right;">
                                <div class="btn-group btn-outline-primary btn-icon btn-sm"
                                     style="margin-top: 10px; pointer-events: auto; background-color: white;" *ngIf="selectedView !== 'apps-groups'">
                                    <div class="radio btn">
                                        <input type="radio" name="btn-group-demo-radios" id="btn-demo-radio-1"
                                               [ngModel]="selectedView" value="dependencies-viewer"
                                               (click)="navigateSelection('dependencies-viewer');">
                                        <label for="btn-demo-radio-1">
                                            <cds-icon shape="recycle" title="Check"></cds-icon>
                                        </label>
                                    </div>
                                    <div class="radio btn">
                                        <input type="radio" name="btn-group-demo-radios" id="btn-demo-radio-2"
                                               [ngModel]="selectedView" value="protocol-analysis"
                                               (click)="navigateSelection('protocol-analysis');">
                                        <label for="btn-demo-radio-2">
                                            <cds-icon shape="chat-bubble" title="Check"></cds-icon>
                                        </label>
                                    </div>
                                    <div class="radio btn">
                                        <input type="radio" name="btn-group-demo-radios" id="btn-demo-radio-3"
                                               [ngModel]="selectedView" value="clusters-viewer"
                                               (click)="navigateSelection('clusters-viewer');">
                                        <label for="btn-demo-radio-3">
                                            <cds-icon shape="organization" title="Check"></cds-icon>
                                        </label>
                                    </div>
                                </div>
                                <span *ngIf="selectedView !== 'protocol-analysis'">
                                <span style=""
                                      *ngIf="groupsApps !== undefined && groupsApps.length > 0 && selectedView === 'apps-groups'">
                                    <span *ngFor="let group of groupsApps" class="p6"
                                          style="margin-left: 5px; margin-right: 5px; display: inline-block;">
                                        <span class="badge" style="background: {{group.color}}"> </span> {{group.name}}
                                    </span>
                                </span>
                                <span style="" *ngIf="selectedAppsGroups.length > 0 && selectedView !== 'apps-groups'">
                                    <span *ngFor="let group of selectedAppsGroups" class="p6"
                                          style="margin-left: 5px; margin-right: 5px; display: inline-block;">
                                        <span class="badge" style="background: {{group.color}}"> </span> {{group.name}}
                                    </span>
                                </span>
                            </span>
                                <span *ngIf="isLoading">
                                <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
                            </span>
                            </div>
                        </div>

                        <div id="divSvg" style="position: absolute; left: 0px; right: 0px; top: 0px; bottom: 0px; overflow: hidden;"></div>
                    </div>
                </clr-tab-content>
            </clr-tab>

            <clr-tab>
                <button id="flows_datatable_button" clrTabLink>{{ 'dcnetscope.flows_tab_datagrid' | transloco }}</button>
                <clr-tab-content *clrIfActive>
                    <div class="clr-row" style="padding: 10px;">
                        <div class="clr-col-12">
                            <app-netscope-flows-datagrid [temporalParameter]="temporalParameter"
                                                         [bidirectional]="selectedView === 'apps-groups'"
                                                         [flowDetails]="filteredLinks" [showHosts]="true"
                                                         [displayLinksToRessources]="false" [switchFocus]="switchFocus"
                                                         [isFocusedRessource]="isFocusedVm"
                                                         *ngIf="selectedView !== 'protocol-analysis'"
                                                         [exportCsvEnabled]="true"
                                                         [callExportCsv]="exportCSV"
                            ></app-netscope-flows-datagrid>
                            <app-netscope-flows-datagrid [temporalParameter]="temporalParameter"
                                                         [flowDetails]="perProtocolFlow" [ignoreFilters]="true"
                                                         [showHosts]="false" [hidePacketsExchanged]="true"
                                                         [displayLinksToRessources]="false" [perPortRow]="true"
                                                         [switchFocus]="switchFocus" [isFocusedRessource]="isFocusedVm"
                                                         *ngIf="selectedView === 'protocol-analysis'"
                                                         [exportCsvEnabled]="true"
                                                         [callExportCsv]="exportRowPerPortCSV"></app-netscope-flows-datagrid>
                        </div>
                    </div>
                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
</div>