import {
    Component,
    OnInit
} from '@angular/core';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);


@Component({
    selector: 'app-kpi-credits',
    templateUrl: './kpi-credits.component.html',
    styleUrls: ['./kpi-credits.component.css']
})
export class KpiCreditsComponent implements OnInit {

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};

    dataOrder = ClrDatagridSortOrder.ASC;

    kpiCredits: any = [];

    message: Message;

    isReady: boolean = false;

    creditSelected: string = '';

    instances: any = [];

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_KPI_CREDITS).subscribe(
            data => {
                this.kpiCredits = data;
                this.loadGraph();
            }
        );
    }

    private loadGraph(): void {

        let title: string = 'usage of credits from instances over the current month';
        let titleSize: string = '20px';

        let credits: string = 'normal';
        let time: number = 0;

        let cat: any = [];
        let normal: any = [];
        let over: any = [];
        let under: any = [];
        for (let i in this.kpiCredits) {
            cat.push(this.kpiCredits[i].timeago);
            let normal_pt: any = {
                name: 'normal',
                y: this.kpiCredits[i].nbNormal
            };
            normal.push(normal_pt);
            let over_pt: any = {
                name: 'overused',
                y: this.kpiCredits[i].nbOverused
            };
            over.push(over_pt);
            let under_pt: any = {
                name: 'underused',
                y: this.kpiCredits[i].nbUnderused
            };
            under.push(under_pt);
        }

        let series: any = [];
        let serie0: any = {
            type: 'column',
            name: 'normal',
            data: normal,
            color: '#FFCC00'
        };
        series.push(serie0);
        let serie1: any = {
            type: 'column',
            name: 'overused',
            data: over,
            color: '#CD7F32'
        };
        series.push(serie1);
        let serie2: any = {
            type: 'column',
            name: 'underused',
            data: under,
            color: '#C0C0C0'
        };
        series.push(serie2);

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            legend: {
                enabled: false
            },
            xAxis: {
                categories: cat,
                title: {
                    text: 'day of month'
                },
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat('%d', this.value);
                    }
                }
            },
            yAxis: [{
                title: {
                    text: 'instances',
                }
            }],
            chart: {
                plotBorderWidth: null,
                plotShadow: false
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                },
                series: {
                    cursor: 'pointer',
                    events: {
                        click: function(event) {
                            credits = event.point.name;
                            let time_str: string = event.point.category
                            time = +time_str;
                        }
                    },
                    point: {
                        events: {
                            click: function() {
                                setTimeout(() => this.loadKpiCredits(credits, time), 100);
                            }.bind(this)
                        }
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        text: "download",
                        symbolStroke: "#0072A3",
                        x: -10
                    }
                }
            },
            series: series
        };
        this.isReady = true;
    }

    private loadKpiCredits(credit: string, time: number): void {

        this.instances = [];
        this.creditSelected = credit;

        for (let i in this.kpiCredits) {
            if (this.kpiCredits[i].timeago == time) {
                for (let j in this.kpiCredits[i].ics) {
                    if (this.kpiCredits[i].ics[j].behavior == credit)
                        this.instances.push(this.kpiCredits[i].ics[j]);
                }
            }
        }
    }
}
