<clr-alerts>
	<clr-alert *ngIf="vdc_alert.nbvm_usage != 'info'" [clrAlertType]="vdc_alert.nbvm_usage" [clrAlertSizeSmall]=true [clrAlertAppLevel]=true>
		<clr-alert-item>
			<span class="alert-text">
				VM that can be created
			</span>
		</clr-alert-item>
	</clr-alert>
	<clr-alert *ngIf="vdc_alert.usagecpu_sat != 'info'" [clrAlertType]="vdc_alert.usagecpu_sat" [clrAlertSizeSmall]=true [clrAlertAppLevel]=true>
		<clr-alert-item>
			<span class="alert-text">
				CPU saturation date
			</span>
		</clr-alert-item>
	</clr-alert>
	<clr-alert *ngIf="vdc_alert.usageram_sat != 'info'" [clrAlertType]="vdc_alert.usageram_sat" [clrAlertSizeSmall]=true [clrAlertAppLevel]=true>
		<clr-alert-item>
			<span class="alert-text">
				RAM saturation date
			</span>
		</clr-alert-item>
	</clr-alert>
	<clr-alert *ngIf="vdc_alert.usagedisk_sat != 'info'" [clrAlertType]="vdc_alert.usagedisk_sat" [clrAlertSizeSmall]=true [clrAlertAppLevel]=true>
		<clr-alert-item>
			<span class="alert-text">
				Storage saturation date
			</span>
		</clr-alert-item>
	</clr-alert>
</clr-alerts>


<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;">
		</highcharts-chart>
	</div>
</div>

<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
	<div class="clr-row">
		<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
			<form clrForm clrLayout="vertical">
				<span class="badge" style="background-color:#5EA11A;font-size:12px;">{{vdc_prediction.NBVM_USAGE}}</span>
				<div class="clr-input">
					<label>VM that can be created</label>
					&nbsp;&nbsp;<cds-icon shape="warning-standard" [ngClass]="vdc_badge.nbvm_usage_class" size="18" *ngIf="vdc_badge.nbvm_usage_class != 'is-success'"></cds-icon>
				</div>
				<clr-control-helper>based on average utilization</clr-control-helper>
			</form>
		</div>
		<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
			<form clrForm clrLayout="vertical">
				<div class="clr-input">
					<label>CPU saturation</label>
				</div>
				<span class="badge" [ngClass]="vdc_badge.usagecpu_sat" style="font-size:12px;">{{vdc_prediction.USAGECPU_DATE_SAT}}</span>
				<clr-control-helper>the saturation date based on cpu utilization</clr-control-helper>
			</form>
		</div>
	</div>
</div>

<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">	
	<div class="clr-row">
		<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
			<form clrForm clrLayout="vertical">
				<div class="clr-input">
					<label>RAM saturation</label>
				</div>
				<span class="badge" [ngClass]="vdc_badge.usageram_sat" style="font-size:12px;">{{vdc_prediction.USAGERAM_DATE_SAT}}</span>
				<clr-control-helper>the saturation date based on ram utilization</clr-control-helper>
			</form>
		</div>
		<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
			<form clrForm clrLayout="vertical">
				<div class="clr-input">
					<label>Storage saturation</label>
				</div>
				<span class="badge" [ngClass]="vdc_badge.usagedisk_sat" style="font-size:12px;">{{vdc_prediction.USAGEDISK_DATE_SAT}}</span>
				<clr-control-helper>the saturation date based on storage utilization</clr-control-helper>
			</form>
		</div>
	</div>
</div>



