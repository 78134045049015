import {
    Component,
    OnInit
} from '@angular/core';
import {
    LicenseInfo,
    Message,
    User
} from '@app/model';
import {
    AccountService,
    LicenseService,
    ManagementService,
    MonitorService,
    ShareService
} from '@app/services';
import {
    VirtindexService
} from '@app/services/virtindex.service';

import {
    faAws,
    faGoogle,
    faMicrosoft
} from '@fortawesome/free-brands-svg-icons';
import {
    faEye
} from '@fortawesome/free-regular-svg-icons';
import {
    faHouseUser
} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-dcscopemenu',
    templateUrl: './dcscopemenu.component.html',
    styleUrls: ['./dcscopemenu.component.css']
})
export class DcscopemenuComponent implements OnInit {

    role: User;
    message: Message;

    // For trackdc instances
    vcloud_check: boolean;
    aws_check: boolean;
    azure_check: boolean;
    gcp_check: boolean;
    kubernetes_check: boolean;

    // For elements type
    aws_perimeter_check: boolean = false;
    azure_perimeter_check: boolean = false;
    gcp_perimeter_check: boolean = false;
    kubernetes_perimeter_check: boolean = false;

    // Cloud Pricing
    overviewIcone = faEye;
    awsIcon = faAws;
    azureIcon = faMicrosoft;
    gcpIcon = faGoogle;
    myproviderIcon = faHouseUser;

    // Green-Index
    greenindex_status: boolean = false;

    // Netscope
    netscopeShouldBeAvailable = false;

    // License
    license_info: LicenseInfo;


    /**
     * 
     */
    constructor(private authentication_svc: AccountService, private message_svc: ShareService,
        private virtindex_svc: VirtindexService, private monitor_svc: MonitorService,
        private license_svc: LicenseService, private management_svc: ManagementService) {}

    /**
     *
     */
    ngOnInit(): void {
        this.authentication_svc.user.subscribe(user => this.role = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.virtindex_svc.virtindexStatus.subscribe(status => this.greenindex_status = status);

        // Check trackdc instances
        this.monitor_svc.vcloudCheck.subscribe(details => this.vcloud_check = details);
        this.monitor_svc.awsCheck.subscribe(details => this.aws_check = details);
        this.monitor_svc.azureCheck.subscribe(details => this.azure_check = details);
        this.monitor_svc.gcpCheck.subscribe(details => this.gcp_check = details);
        this.monitor_svc.kubernetesCheck.subscribe(details => this.kubernetes_check = details);

        // Check elements type
        this.management_svc.awsCheck.subscribe((details) => (this.aws_perimeter_check = details));
        this.management_svc.azureCheck.subscribe((details) => (this.azure_perimeter_check = details));
        this.management_svc.gcpCheck.subscribe((details) => (this.gcp_perimeter_check = details));
        this.management_svc.kubernetesCheck.subscribe((details) => (this.kubernetes_perimeter_check = details));


        this.license_svc.licenseInfo.subscribe(infos => {
            this.license_info = infos
            // Netscope
            this.netscopeShouldBeAvailable = ((this.license_info.moduleslicense >> 1) & 1) === 1;
        });
    }

    checkExternalCloud(): boolean {
        return ((this.aws_check && this.aws_perimeter_check) || (this.azure_check && this.azure_perimeter_check) ||
            (this.gcp_check && this.gcp_perimeter_check)) && this.role.isMenuExternalCloud;
    }

    /**
     * 
     */
    checkAws(): boolean {
        return this.aws_check && this.aws_perimeter_check;
    }

    /**
     * 
     */
    checkAzure(): boolean {
        return this.azure_check && this.azure_perimeter_check;
    }

    /**
     * 
     */
    checkGcp(): boolean {
        return this.gcp_check && this.gcp_perimeter_check;
    }

    /**
     * 
     */
    checkKubernetes(): boolean {
        return this.kubernetes_check && this.kubernetes_perimeter_check && this.role.isMenuKubernetes;
    }

    /**
     * 
     */
    checkVCloud(): boolean {
        return this.vcloud_check && this.role.isMenuVcloud;
    }

}
