import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-cluster-distribution12]'
})
export class Clusterdistribution12Directive {

    constructor(public viewContainerRef12: ViewContainerRef) {}

}
