import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[cloud-kpi-evolution]'
})
export class CloudkpievolutionDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
