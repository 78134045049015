import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    Json,
    JSONTarget,
    Message,
    RecoVm,
    User
} from '@app/model';


@Component({
    selector: 'app-recommendationresizingram',
    templateUrl: './recommendationresizingram.component.html',
    styleUrls: ['./recommendationresizingram.component.css']
})
export class RecommendationresizingramComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: DataTables.Settings = {};

    dtTrigger: Subject < any > = new Subject();

    message: Message;

    math = Math;

    reco_vms_data: RecoVm[] = [];

    ram_model: RecoVm[] = [];

    private ram_table: any;

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (this.ram_table != undefined) {
            this.message.recoResizeSearch = this.ram_table.api().search();
        } else {
            this.ram_table = $('#reco-resizing-ram-list').dataTable();
            if (this.ram_table != undefined)
                this.message.recoResizeSearch = this.ram_table.api().search();
        }
    }

    ngOnInit(): void {

        $("div.dataTables_filter").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.initDtOptions();

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_VM).subscribe(
            data => {
                this.reco_vms_data = data;
                if (this.reco_vms_data.length > 0)
                    this.buildData();
            }
        );
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    private buildData(): void {

        for (var i = 0; i < this.reco_vms_data.length; i++) {
            if (this.reco_vms_data[i].ram_gain > 0)
                this.ram_model.push(this.reco_vms_data[i]);
        }
        this.ram_model.sort((a, b) => (a.ram_gain > b.ram_gain) ? 1 : -1);
        this.reloadTable();
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
            },
            language: {
                loadingRecords: "loading .. ",
                zeroRecords: "no data",
                search: '',
                searchPlaceholder: " search a vm..."
            },
            dom: '<"top">rt<"bottom"f>'
        }
    }
}
