<div class="clr-row" *ngIf="this.message.kubeDetail">
    <div class="card">
        <div class="card-header">
            <span style="color:grey;font-size:26px;">
                <cds-icon shape="container"></cds-icon>
                    CONTAINERS
            </span>
            <span style="float: right;" ngClass="addLink" (click)="message.kubeDetail = false">
                <cds-icon shape="times" size="24"></cds-icon>
            </span>
        </div>
        <div class="card-block">
            <div class="clr-row">
                <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                    <div class="clr-datagrid">
                        <clr-datagrid>
                            <clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'cluster'"><ng-container *clrDgHideableColumn="{hidden: false}">Cluster</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'node'"><ng-container *clrDgHideableColumn="{hidden: false}">Node</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'pod'"><ng-container *clrDgHideableColumn="{hidden: false}">Pod</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'powerAvg'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Power Avg (Watt)</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'whTotal'"><ng-container *clrDgHideableColumn="{hidden: false}">Wh total</ng-container></clr-dg-column>
                            <clr-dg-column [clrDgField]="'co2Total'"><ng-container *clrDgHideableColumn="{hidden: false}">Emission carbon (kgCO2eq)</ng-container></clr-dg-column>   
                            <clr-dg-row *clrDgItems="let data of greenit" [clrDgItem]="data">
                                <clr-dg-cell>{{data.name}}</clr-dg-cell>
                                <clr-dg-cell>{{data.cluster}}</clr-dg-cell>
                                <clr-dg-cell>{{data.node}}</clr-dg-cell>
                                <clr-dg-cell>{{data.pod}}</clr-dg-cell>
                                <clr-dg-cell>{{data.powerAvg.toFixed(2)}}</clr-dg-cell>
                                <clr-dg-cell>{{data.whTotal.toFixed(2)}}</clr-dg-cell>
                                <clr-dg-cell>{{data.co2Total.toFixed(2)}}</clr-dg-cell>
                            </clr-dg-row>
                            <clr-dg-footer>
                                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                                        entries per page
                                    </clr-dg-page-size>
                                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                </clr-dg-pagination>
                            </clr-dg-footer>
                        </clr-datagrid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>