<table id="reco-resizing-ram-list" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th>VM</th>
			<th>ACTION</th>
			<th>MODE</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let reco of ram_model">
			<td>{{reco.name}}</td>
			<td *ngIf="reco.reco_ram < 0; else addRam">remove {{math.abs(reco.reco_ram)}} MB</td>
			<ng-template #addRam>
				<td>add {{reco.reco_ram}} MB</td>
			</ng-template>
			<td>{{reco.reco_ram_mode}}</td>
		</tr>
	</tbody>
</table>
