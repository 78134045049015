import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {
    Subject
} from 'rxjs';
import {
    first
} from 'rxjs/operators';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    MeasurementService,
    ShareService
} from '@app/services';

import {
    Json,
    Message,
    ThresholdStats,
    TsSelected,
    User,
    VmSynthesis
} from '@app/model';

import {
    TroubleshootingcardlistComponent
} from '@app/troubleshooting/troubleshootingcardlist/troubleshootingcardlist.component';
import {
    TroubleshootingcardlistDirective
} from '@app/directives/troubleshootingcardlist.directive';

import {
    TroubleshootinggraphComponent
} from '@app/troubleshooting/troubleshootinggraph/troubleshootinggraph.component';
import {
    TroubleshootinggraphDirective
} from '@app/directives/troubleshootinggraph.directive';


@Component({
    selector: 'app-troubleshootinglist',
    templateUrl: './troubleshootinglist.component.html',
    styleUrls: ['./troubleshootinglist.component.css']
})
export class TroubleshootinglistComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    @ViewChild(TroubleshootingcardlistDirective) addCardList: TroubleshootingcardlistDirective;
    @ViewChild(TroubleshootinggraphDirective) addCardGraph: TroubleshootinggraphDirective;

    dtOptions: any = {};
    //dtOptions: DataTables.Settings = {};

    dtTrigger: Subject < any > = new Subject();

    jsonLoader: Json;

    message: Message;

    col_class: string = 'clr-col-lg-12 clr-col-md-12 clr-col-12';

    itemList: boolean = true;

    itemDetail: boolean = false;

    itemGraph: boolean = false;

    data_vms: VmSynthesis[] = [];

    vm_selected: VmSynthesis;

    ts_data: ThresholdStats[] = [];

    ts_selected: TsSelected = {
        uuid: '',
        father_id: '',
        name: '',
        type: '',
        cpu_nb: 0,
        pts_cpu_red: 0,
        pts_cpu_ora: 0,
        pts_crsr_red: 0,
        pts_crsr_ora: 0,
        pts_crdy_red: 0,
        pts_crdy_ora: 0,
        pts_cstp_red: 0,
        pts_cstp_ora: 0,
        pts_net_red: 0,
        pts_net_ora: 0,
        pts_ram_red: 0,
        pts_ram_ora: 0,
        pts_dlat_ora: 0,
        pts_dlat_red: 0,
        pts_dcmd_ora: 0,
        pts_dcmd_red: 0
    };

    private currentUser: User;
    isProcessing: boolean = true;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private measurement_svc: MeasurementService,
        private message_svc: ShareService) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();
        $("dt-buttons").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);

        this.initDtOptions();

        this.data_vms = this.jsonLoader.vmSynthesis;

        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    ngAfterViewInit(): void {

        this.dtTrigger.next();
        this.buildData();
    }

    ngAfterViewChecked(): void {

        if (this.message.isTroubleGraph) {
            this.itemList = false;
            this.itemDetail = false;
            this.itemGraph = true;

            setTimeout(() => this.loadCardGraph(), 100);
        }
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    getVmData(uuid: string): VmSynthesis {
        return this.data_vms.find(vm => vm.uuid === uuid);
    }

    setDetail(uuid: string): void {

        this.col_class = 'clr-col-lg-8 clr-col-md-8 clr-col-12';
        this.itemDetail = true;

        this.vm_selected = this.getVmData(uuid);

        let cur_stat: ThresholdStats = this.filterData(uuid);
        this.ts_selected.pts_crsr_ora = cur_stat.pts_cpu_crsr_orange;
        this.ts_selected.pts_crsr_red = cur_stat.pts_cpu_crsr_red;
        this.ts_selected.pts_crdy_ora = cur_stat.pts_cpu_rdy_orange;
        this.ts_selected.pts_crdy_red = cur_stat.pts_cpu_rdy_red;
        this.ts_selected.pts_cstp_ora = cur_stat.pts_cpu_cstp_orange;
        this.ts_selected.pts_cstp_red = cur_stat.pts_cpu_cstp_red;
        this.ts_selected.pts_net_red = cur_stat.pts_net_red;
        this.ts_selected.pts_net_ora = cur_stat.pts_net_orange;
        this.ts_selected.pts_ram_red = cur_stat.pts_ram_red;
        this.ts_selected.pts_ram_ora = cur_stat.pts_ram_orange;
        this.ts_selected.pts_dlat_ora = cur_stat.pts_disk_lat_orange;
        this.ts_selected.pts_dlat_red = cur_stat.pts_disk_lat_red;
        this.ts_selected.pts_dcmd_ora = cur_stat.pts_disk_cmd_orange;
        this.ts_selected.pts_dcmd_red = cur_stat.pts_disk_cmd_red;
        this.ts_selected.name = this.vm_selected.name;
        this.ts_selected.uuid = uuid;


        this.message.troubleSelect = this.ts_selected;
        setTimeout(() => this.loadCardList(), 100);
    }

    closeCard(): void {

        this.col_class = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
        this.itemList = true;
        this.itemDetail = false;
    }

    private buildData(): void {

        this.isProcessing = true;

        setTimeout(() => {
            this.measurement_svc.getThresholdStats(this.currentUser.login, this.message.elementView, this
                    .message.minTimeFilter / 1000, this.message.maxTimeFilter / 1000).pipe(first())
                .subscribe(
                    data => {
                        this.ts_data = data;
                        if (this.ts_data.length > 0)
                            this.reloadTable();
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
        }, 200);
    }

    private filterData(uuid: string): ThresholdStats {
        return this.ts_data.find(stat => stat.uuid === uuid);
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            deferRender: true,
            initComplete: () => {
                $('div.dataTables_filter').appendTo('span.search');
                $('div.dt-buttons').appendTo('span.export');
                $("[id*='DataTables_Table_']").css({
                    'padding-top': '5px'
                });
                $("[name*='DataTables_Table_']").css({
                    'padding': '0px'
                });
                this.isProcessing = false;
            },
            language: {
                loadingRecords: "loading ...",
                zeroRecords: "no data",
                search: '',
                searchPlaceholder: " search..."
            },
            dom: '<"top"B><"clear">frtlip',
            buttons: [

                {
                    extend: 'csv',
                    className: 'btn btn-link btn-sm'
                }, {
                    extend: 'copy',
                    className: 'btn btn-link btn-sm'
                }, {
                    extend: 'print',
                    className: 'btn btn-link btn-sm'
                }
            ]
        };
    }

    private loadCardList(): void {

        if (this.addCardList != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                TroubleshootingcardlistComponent);
            const viewContainerRef = this.addCardList.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCardGraph(): void {

        this.message.isTroubleGraph = false;
        if (this.addCardGraph != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                TroubleshootinggraphComponent);
            const viewContainerRef = this.addCardGraph.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }
}
