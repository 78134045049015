 <div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
        		<div class="card-header">
                		<span style="color:grey;font-size:16px;">
                        		Selected instances
                		</span>
			</div>
        		<div class="card-block">
				 <div class="clr-row">
					 <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
						<div class="clr-control-container" *ngIf="!isStack">
							<clr-combobox-container *ngIf="message.cloudProvider == 'aws'">
								<clr-combobox
									id="spec-counter-resource"
									class="full-width-input"
									[(ngModel)]="instance_selected"
									(ngModelChange)="reloadGraph()"
									(clrOpenChange)="initInstanceList()"
									placeholder="select an instance"
									required>
								  <clr-options>
									<clr-option *clrOptionItems="let instance of instances; field:'fullName'" [clrValue]="instance">
										{{instance.fullName}}
									</clr-option>
								  </clr-options>
								</clr-combobox>
								<clr-control-helper>enter an instance name or an instance id</clr-control-helper>
								<clr-control-error>instance not found</clr-control-error>
							</clr-combobox-container>
							<clr-combobox-container *ngIf="message.cloudProvider == 'azure' || message.cloudProvider == 'gcp'">
								<clr-combobox 
									[(ngModel)]="instance_selected"
									(ngModelChange)="reloadGraph()"
									(clrOpenChange)="initInstanceList()"
									placeholder="select an instance"
									required>
								  <clr-options>
									<clr-option *clrOptionItems="let instance of instances; field:'name'" [clrValue]="instance">
										{{instance.name}}
									</clr-option>
								  </clr-options>
								</clr-combobox>
								<clr-control-helper>enter an instance name</clr-control-helper>
								<clr-control-error>instance not found</clr-control-error>
							</clr-combobox-container>
						</div>
					</div>
					<div class="clr-col-lg-3 clr-col-md-3 clr-col-12" style="float: right;" *ngIf="message.cloudProvider != 'gcp'">
						<clr-toggle-container clrInline>
							<clr-toggle-wrapper>
								<input type="checkbox" clrToggle name="isStack" value="isStack" [(ngModel)]="isStack" (ngModelChange)="reloadGraphStack()" />
								<label>
									surplus credits charged analysis	
								</label>
							</clr-toggle-wrapper>
							<clr-control-helper>view instances that generate excess credits</clr-control-helper>
						</clr-toggle-container>
					</div>
				</div>
			</div>
		 </div>
	</div>
        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="!isStack">
 		<div class="card">
        		<div class="card-header">
				<clr-combobox-container>
					<label>CPU USAGE</label>
					<clr-combobox [(ngModel)]="cpuUsageMetrics"
							name="usage"
							(ngModelChange)="reloadGraph()"
							(click)="cpu_click()"
							clrMulti="true" required>
						<ng-container *clrOptionSelected="let selected">
							<cds-icon shape="line-chart" role="img" aria-label="welcome home"></cds-icon> {{selected}}
						</ng-container>
						<clr-options *ngIf="combobox1">
							<clr-option clrValue="avg">average cpu usage</clr-option>
							<clr-option clrValue="min">min cpu usage</clr-option>
							<clr-option clrValue="max">max cpu usage</clr-option>
						</clr-options>
					</clr-combobox>
				</clr-combobox-container>
			</div>
			<div class="card-block">
				<highcharts-chart
					id="spec-counter-cpu"
					[Highcharts] = "highcharts"
					[options] = "chartOptions"
					[constructorType]="'chart'"
					style = "width: 100%;display: block;" *ngIf="isReady">
				</highcharts-chart>
			</div>
		 </div>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="!isStack && message.cloudProvider != 'gcp'">
 		<div class="card">
        		<div class="card-header">
				<clr-combobox-container>
					<label>CPU CREDITS</label>
					<clr-combobox [(ngModel)]="creditsMetrics"
							name="net"
							(ngModelChange)="reloadGraph()"
							(click)="credit_click()"
							clrMulti="true" required>
						<ng-container *clrOptionSelected="let selected">
                                                        <cds-icon shape="line-chart" role="img" aria-label="welcome home"></cds-icon> {{selected}}
                                                </ng-container>
						<clr-options *ngIf="combobox2">
							<clr-option clrValue="usage">credits usage</clr-option>
							<clr-option clrValue="balance">credits balance</clr-option>
							<clr-option clrValue="surplusCharged" *ngIf="message.cloudProvider == 'aws'">credits surplus charged</clr-option>
						</clr-options>
					</clr-combobox>
				</clr-combobox-container>
			</div>
        		<div class="card-block">
				<highcharts-chart
					id="spec-counter-credits"
					[Highcharts] = "highcharts"
					[options] = "chartOptions2"
					[constructorType]="'chart'"
					style = "width: 100%;display: block;" *ngIf="isReady2">
				</highcharts-chart>
			</div>
		 </div>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="message.cloudProvider == 'azure' && !isStack">
 		<div class="card">
        		<div class="card-header">
				<clr-combobox-container>
					<label>RAM USAGE</label>
					<clr-combobox [(ngModel)]="ramUsageMetrics"
							name="usage"
							(ngModelChange)="reloadGraph()"
							(click)="ram_click()"
							clrMulti="true" required>
						<ng-container *clrOptionSelected="let selected">
                                                        <cds-icon shape="line-chart" role="img" aria-label="welcome home"></cds-icon> {{selected}}
                                                </ng-container>
						<clr-options *ngIf="combobox3">
							<clr-option clrValue="avg">average ram usage</clr-option>
							<clr-option clrValue="min">min ram usage</clr-option>
							<clr-option clrValue="max">max ram usage</clr-option>
						</clr-options>
					</clr-combobox>
				</clr-combobox-container>
			</div>
        		<div class="card-block">
				<highcharts-chart
					[Highcharts] = "highcharts"
					[options] = "chartOptions5"
					[constructorType]="'chart'"
					style = "width: 100%;display: block;" *ngIf="isReady">
				</highcharts-chart>
			</div>
		 </div>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="!isStack">
 		<div class="card">
        		<div class="card-header">
                		<clr-combobox-container>
					<label>NETWORK</label>
					<clr-combobox [(ngModel)]="netMetrics"
							name="net"
							(ngModelChange)="reloadGraph()"
							(click)="net_click()"
							clrMulti="true" required>
						<ng-container *clrOptionSelected="let selected">
                                                        <cds-icon shape="line-chart" role="img" aria-label="welcome home"></cds-icon> {{selected}}
                                                </ng-container>
						<clr-options *ngIf="combobox4">
							<clr-option clrValue="avgNetIn">average network in</clr-option>
							<clr-option clrValue="minNetIn">min network in</clr-option>
							<clr-option clrValue="maxNetIn">max network in</clr-option>
							<clr-option clrValue="avgNetOut">average network out</clr-option>
							<clr-option clrValue="minNetOut">min network out</clr-option>
							<clr-option clrValue="maxNetOut">max network out</clr-option>
						</clr-options>
					</clr-combobox>
				</clr-combobox-container>
			</div>
        		<div class="card-block">
				<highcharts-chart
					[Highcharts] = "highcharts"
					[options] = "chartOptions3"
					[constructorType]="'chart'"
					style = "width: 100%;display: block;" *ngIf="isReady3">
				</highcharts-chart>
			</div>
		 </div>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="!isStack">
 		<div class="card">
        		<div class="card-header">
                		<clr-combobox-container>
					<label>DISK</label>
					<clr-combobox [(ngModel)]="diskMetrics"
							name="disk"
							(ngModelChange)="reloadGraph()"
							(click)="disk_click()"
							clrMulti="true" required>
						<ng-container *clrOptionSelected="let selected">
                                                        <cds-icon shape="line-chart" role="img" aria-label="welcome home"></cds-icon> {{selected}}
                                                </ng-container>
						<clr-options *ngIf="combobox5">
							<clr-option clrValue="avgDiskRead">average disk read</clr-option>
							<clr-option clrValue="minDiskRead">min disk read</clr-option>
							<clr-option clrValue="maxDiskRead">max disk read</clr-option>
							<clr-option clrValue="avgDiskWrite">average disk write</clr-option>
							<clr-option clrValue="minDiskWrite">min disk write</clr-option>
							<clr-option clrValue="maxDiskWrite">max disk write</clr-option>
							<clr-option clrValue="avgDiskReadSec">average disk read seconde</clr-option>
							<clr-option clrValue="minDiskReadSec">min disk read seconde</clr-option>
							<clr-option clrValue="maxDiskReadSec">max disk read seconde</clr-option>
							<clr-option clrValue="avgDiskWriteSec">average disk write seconde</clr-option>
							<clr-option clrValue="minDiskWriteSec">min disk write seconde</clr-option>
							<clr-option clrValue="maxDiskWriteSec">max disk write seconde</clr-option>
						</clr-options>
					</clr-combobox>
				</clr-combobox-container>
			</div>
        		<div class="card-block">
				<highcharts-chart
					[Highcharts] = "highcharts"
					[options] = "chartOptions4"
					[constructorType]="'chart'"
					style = "width: 100%;display: block;" *ngIf="isReady4">
				</highcharts-chart>
			</div>
		 </div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isStack">
		<ng-template cloud-graph-counter-stack></ng-template>
	</div>
 </div>	
