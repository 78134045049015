<div class="clr-row" *ngIf="message.creditSelected == 'sp'">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card" *ngIf="selected_sp == null">
			<div class="card-header">
				<span style="color:grey;font-size:26px;">
					<cds-icon shape="book"></cds-icon>
					savings plans usage
				</span>
				<span style="margin-left:100px;" class="label label-info">
					Pick a savings plan to display the daily usage
				</span>
				<span style="float: right;" ngClass="addLink" (click)="message.creditSelected = ''">
					<cds-icon shape="times" size="24"></cds-icon>
				</span>
			</div>
			<div class="card-block">
				<clr-datagrid [(clrDgSingleSelected)]="selected_sp" (clrDgSingleSelectedChange)="spChanged($event)">
					<clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'uuid'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Identifier</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'description'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Description</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'region'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Region</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'commitment'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Commitment ($/hour)</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'type'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'family'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Family</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'end'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">End</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'avgUsageRate'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Average usage (%)</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let kpiSavingsplan of kpiSavingsplans" [clrDgItem]="kpiSavingsplan">
						<clr-dg-cell>{{kpiSavingsplan.id}}</clr-dg-cell>
						<clr-dg-cell>{{kpiSavingsplan.uuid}}</clr-dg-cell>
						<clr-dg-cell>{{kpiSavingsplan.info.description}}</clr-dg-cell>
						<clr-dg-cell>{{kpiSavingsplan.info.region}}</clr-dg-cell>
						<clr-dg-cell>{{kpiSavingsplan.info.commitment}}</clr-dg-cell>
						<clr-dg-cell>{{kpiSavingsplan.info.type}}</clr-dg-cell>
						<clr-dg-cell>{{kpiSavingsplan.info.family}}</clr-dg-cell>
						<clr-dg-cell>{{convertDate(kpiSavingsplan.info.end)}}</clr-dg-cell>
						<clr-dg-cell>{{kpiSavingsplan.info.state}}</clr-dg-cell>
						<clr-dg-cell>{{kpiSavingsplan.info.avgUsageRate}}</clr-dg-cell>
					</clr-dg-row>
					<clr-dg-footer>
						<clr-dg-pagination #pagination [clrDgPageSize]="10">
							<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
								entries per page
							</clr-dg-page-size>
							{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
						</clr-dg-pagination>
					</clr-dg-footer>
				</clr-datagrid>
			</div>
		</div>
	</div>
	<div class="card" *ngIf="selected_sp != null">
		<div class="card-header">
			<span style="color:grey;font-size:26px;">
				<cds-icon shape="book"></cds-icon>
				savings plan usage for savings plan {{selected_sp.id}}
			</span>
			<span style="float: right;" ngClass="addLink" (click)="selected_sp = null">
				<cds-icon shape="times"></cds-icon>
			</span>
		</div>
		<div class="card-block">
			<div class="clr-row">
				<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
					<clr-datagrid>
						<clr-dg-column>Description</clr-dg-column>
						<clr-dg-column>Average usage (%)</clr-dg-column>
						<clr-dg-row>
							<clr-dg-cell>{{selected_sp.info.description}}</clr-dg-cell>
							<clr-dg-cell>{{selected_sp.info.avgUsageRate}}</clr-dg-cell>
						</clr-dg-row>
					</clr-datagrid>
				</div>
				<div class="clr-col-lg-7 clr-col-md-7 clr-col-12">
					<div class="card">
						<div class="card-block">
							<highcharts-chart
								[Highcharts] = "highcharts"
								[options] = "chartOptions"
								[constructorType]="'chart'"
									style = "width: 100%;display: block;" *ngIf="isReady">
							</highcharts-chart>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
