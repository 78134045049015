import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-cluster-distribution6]'
})
export class Clusterdistribution6Directive {

    constructor(public viewContainerRef6: ViewContainerRef) {}

}
