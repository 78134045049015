<clr-alert *ngIf="message.isNotLoginPage" [clrAlertType]="message.genericInfoType" [clrAlertAppLevel]=true [(clrAlertClosed)]="message.isNoInfo">
	<clr-alert-item>
		<span class="alert-text">
			{{message.genericInfo}}
		</span>
	</clr-alert-item>
</clr-alert>
<clr-alerts *ngIf="message.isNotLoginPage">
    <!-- License-->
    <clr-alert *ngIf="license_info.alert >= 1" [clrAlertAppLevel]=true [clrAlertType]="license_info.alert == 2 ? 'danger' : 'warning'">
        <clr-alert-item>
            <div class="alert-text">
    			<b *ngIf="license_info.alertcode == 12 || license_info.alertcode == 15">No license key found&nbsp;!&nbsp;&nbsp;&nbsp;Please renew your license.</b>
				<b *ngIf="license_info.alertcode == 13">Your license is expiring&nbsp;!&nbsp;[Expiration date : {{formatDate(license_info.endlicense, false)}}]&nbsp;&nbsp;&nbsp;Please renew your license.</b>
				<b *ngIf="license_info.alertcode == 14">Your license is expiring&nbsp;!&nbsp;[VM number : {{license_info.vmclient}} / {{license_info.vmlicense == 'noli' ? 'No limit number' : license_info.vmlicense}}]&nbsp;&nbsp;&nbsp;Please renew your license.</b>
			</div>
            <div class="alert-actions">
                <button class="btn alert-action" (click)="callLicense()">Update license</button>
            </div>
        </clr-alert-item>
    </clr-alert>
    <!-- Monitoring instance status-->
	<clr-alert *ngFor="let data of instance_details" [clrAlertAppLevel]=true [clrAlertType]="instance_global_alert == 2 ? 'danger' : 'warning'">
		<clr-alert-item *ngIf="data.status > 0">
			<div class="alert-text">
				{{data.type}} connection problem since <b>{{formatDate(data.date, true)}}</b> for <b>{{data.name}}</b> ({{data.msg}})
			</div>
		</clr-alert-item>
		<clr-alert-item *ngIf="data.zombies">
			<div class="alert-text">
				Can not detect zombies for {{getInstanceFullname(data.type)}} <b>{{data.name}}</b> !
			</div>
		</clr-alert-item>
	</clr-alert>
    <!-- Database status-->
	<clr-alert *ngFor="let data of db_details" [clrAlertAppLevel]=true [clrAlertType]="db_global_alert == 2 ? 'danger' : 'warning'">
		<clr-alert-item>
			<div class="alert-text">
				No data recorded into database since <b>{{formatDate(data.date, true)}}</b> for <b>{{data.name}}</b>
			</div>
		</clr-alert-item>
	</clr-alert>
</clr-alerts>

<!-- LICENSE -->
<app-license #license [target]="modal"></app-license>
