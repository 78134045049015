import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-recommendation-consolidation]'
})
export class RecommendationconsolidationDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
