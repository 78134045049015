import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-cons-cal]'
})
export class ConscalendarDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
