import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    ClrWizard
} from "@clr/angular";
import {
    first
} from 'rxjs/operators';
import * as $ from 'jquery';

import {
    AccountService,
    ShareService
} from '@app/services';

import {
    Message,
    Role,
    UserRec
} from '@app/model';


@Component({
    selector: 'app-rolemgt',
    templateUrl: './rolemgt.component.html',
    styleUrls: ['./rolemgt.component.css']
})
export class RolemgtComponent implements OnInit {

    @ViewChild("wizardRole") wizardrole: ClrWizard;

    message: Message;

    model: any;

    roles: Role[] = [];

    usr_role: UserRec[] = [];

    selected_role: Role = {
        role: '',
        isMenuCloud: false,
        isMenuKpi: false,
        isMenuAlert: false,
        isMenuTrouble: false,
        isMenuCapa: false,
        isMenuGod: false,
        isMenuReco: false,
        isMenuReport: false,
        isMenuGreenit: false,
        isMenuDCNetScope: false,
        isMenuVcloud: false,
        isMenuVirtindex: false,
        isMenuExternalCloud: false,
        isMenuKubernetes: false,
        isDashboardT1: false,
        isDashboardT2: false,
        isDashboardT3: false,
        isDashboardT4: false,
        isDashboardT5: false,
        isDashboardT6: false
    };

    isUpdate: boolean = false;

    isDeleteRole: boolean = false;

    isDeleteError: boolean = false;


    constructor(private authentication_svc: AccountService, private message_svc: ShareService) {}

    ngOnInit(): void {

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.getRoles();

        this.model = {
            name: '',
            isMenuCloud: false,
            isMenuKpi: false,
            isMenuAlert: false,
            isMenuTrouble: false,
            isMenuCapa: false,
            isMenuGod: false,
            isMenuReco: false,
            isMenuReport: false,
            isMenuGreenit: false,
            isMenuVcloud: false,
            isMenuVirtindex: false,
            isMenuDCNetScope: false,
            isMenuExternalCloud: false,
            isMenuKubernetes: false,
            isDashboardT1: false,
            isDashboardT2: false,
            isDashboardT3: false,
            isDashboardT4: false,
            isDashboardT5: false,
            isDashboardT6: false,
            error: false,
            errorName: ''
        };
    }

    initWizard(): void {

        this.model = {
            name: '',
            isMenuCloud: false,
            isMenuKpi: false,
            isMenuAlert: false,
            isMenuTrouble: false,
            isMenuCapa: false,
            isMenuGod: false,
            isMenuReco: false,
            isMenuReport: false,
            isMenuGreenit: false,
            isMenuDCNetScope: false,
            isMenuVcloud: false,
            isMenuVirtindex: false,
            isMenuExternalCloud: false,
            isMenuKubernetes: false,
            isDashboardT1: false,
            isDashboardT2: false,
            isDashboardT3: false,
            isDashboardT4: false,
            isDashboardT5: false,
            isDashboardT6: false,
            error: false,
            errorName: ''
        };
    }

    goBack(): void {}

    doReset(): void {
        this.wizardrole.reset();
    }

    doFinish(): void {

        let role_rec: Role = {
            role: this.model.name.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:;?,<> ]/g, "_"),
            isMenuCloud: this.model.isMenuCloud,
            isMenuKpi: this.model.isMenuKpi,
            isMenuAlert: this.model.isMenuAlert,
            isMenuTrouble: this.model.isMenuTrouble,
            isMenuCapa: this.model.isMenuCapa,
            isMenuGod: this.model.isMenuGod,
            isMenuReco: this.model.isMenuReco,
            isMenuReport: this.model.isMenuReport,
            isMenuGreenit: this.model.isMenuGreenit,
            isMenuDCNetScope: this.model.isMenuDCNetScope,
            isMenuVcloud: this.model.isMenuVcloud,
            isMenuVirtindex: this.model.isMenuVirtindex,
            isMenuExternalCloud: this.model.isMenuExternalCloud,
            isMenuKubernetes: this.model.isMenuKubernetes,
            isDashboardT1: this.model.isDashboardT1,
            isDashboardT2: this.model.isDashboardT2,
            isDashboardT3: this.model.isDashboardT3,
            isDashboardT4: this.model.isDashboardT4,
            isDashboardT5: this.model.isDashboardT5,
            isDashboardT6: this.model.isDashboardT6
        };
        this.authentication_svc.addRole(role_rec).pipe(first()).subscribe(
            success => {
                this.getRoles();
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );

        this.doReset();
    }

    checkName(val: string): void {

        if (this.filterList(val)) {
            this.model.error = true;
            this.model.errorName = 'to ' + val + ' (role already exists)';
        } else if (val == "admin" || val == "ADMIN") {
            this.model.error = true;
            this.model.errorName = 'to ' + val;
        } else if (val.length == 0) {
            this.model.error = true;
            this.model.errorName = 'empty';
        } else if (val.length > 0) {
            this.model.error = false;
        }
    }

    filterList(val: string): boolean {

        let isInFilter: boolean = false;
        for (var i in this.roles) {
            if (this.roles[i].role == val) {
                isInFilter = true;
                break;
            }
        }

        return isInFilter;
    }

    switchRole(val: string): void {

        try {
            this.selected_role = this.findRole(val);
            setTimeout(() => $('#role-list').val(this.selected_role.role), 100);
        } catch (error) {
            //console.log('fail to load role');
        }
    }

    isDeletable(): void {

        if (this.selected_role != undefined && this.selected_role.role != "admin") {
            this.authentication_svc.checkUserRole(this.selected_role.role).pipe(first()).subscribe(
                data => {
                    this.usr_role = data;
                    if (this.usr_role.length > 0) {
                        this.isDeleteRole = false;
                        this.isDeleteError = true;
                    } else {
                        this.isDeleteRole = true;
                        this.isDeleteError = false;
                    }
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    removeRole(): void {

        if (this.selected_role.role != "admin") {
            this.authentication_svc.deleteRole(this.selected_role).pipe(first()).subscribe(
                success => {
                    this.message.genericInfoType = 'info';
                    this.message.genericInfo = 'role ' + this.selected_role.role + ' has been deleted';
                    this.message.isNoInfo = false;
                    this.getRoles();
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    updateRole(): void {

        let role_rec: Role = {
            role: this.selected_role.role.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:;?,<> ]/g, "_"),
            isMenuCloud: this.selected_role.isMenuCloud,
            isMenuKpi: this.selected_role.isMenuKpi,
            isMenuAlert: this.selected_role.isMenuAlert,
            isMenuTrouble: this.selected_role.isMenuTrouble,
            isMenuCapa: this.selected_role.isMenuCapa,
            isMenuGod: this.selected_role.isMenuGod,
            isMenuReco: this.selected_role.isMenuReco,
            isMenuReport: this.selected_role.isMenuReport,
            isMenuGreenit: this.selected_role.isMenuGreenit,
            isMenuDCNetScope: this.selected_role.isMenuDCNetScope,
            isMenuVcloud: this.selected_role.isMenuVcloud,
            isMenuVirtindex: this.selected_role.isMenuVirtindex,
            isMenuExternalCloud: this.selected_role.isMenuExternalCloud,
            isMenuKubernetes: this.selected_role.isMenuKubernetes,
            isDashboardT1: this.selected_role.isDashboardT1,
            isDashboardT2: this.selected_role.isDashboardT2,
            isDashboardT3: this.selected_role.isDashboardT3,
            isDashboardT4: this.selected_role.isDashboardT4,
            isDashboardT5: this.selected_role.isDashboardT5,
            isDashboardT6: this.selected_role.isDashboardT6
        };
        this.authentication_svc.updateRole(role_rec).pipe(first()).subscribe(
            success => {
                this.message.genericInfoType = 'info';
                this.message.genericInfo = 'role ' + this.selected_role.role + ' has been updated';
                this.message.isNoInfo = false;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );

        this.isUpdate = false;
    }

    private getRoles(): void {

        this.authentication_svc.getRoleList().pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.roles = [];
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].role != "admin")
                            this.roles.push(data[i]);
                    }
                    if (this.roles.length > 0)
                        this.loadActivations(this.roles[0]);
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private loadActivations(role: Role): void {

        this.selected_role = role;
        setTimeout(() => $('#role-list').val(role.role), 100);
    }

    private findRole(name: string): Role {
        return this.roles.find(role => role.role === name);
    }
}
