import {
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators
} from '@angular/forms';
import {
    Router
} from '@angular/router';
import {
    Json,
    Message,
    Searchbar
} from '@app/model';
import {
    JsonloaderService,
    ShareService
} from '@app/services';

@Component({
    selector: 'app-inframenu',
    templateUrl: './inframenu.component.html',
    styleUrls: ['./inframenu.component.css']
})
export class InframenuComponent implements OnInit {

    @ViewChild('searchbarInput') searchbarInput: ElementRef;

    jsonLoader: Json;

    message: Message;

    // Searchbar
    searchbar = new FormGroup({
        item: new FormControl('', [Validators.required, Validators.minLength(4)]),
    });
    selectedItem: Searchbar;

    items: any;

    // RP
    rp_synth_show: boolean = false;

    /**
     * 
     */
    constructor(private message_svc: ShareService, private json_svc: JsonloaderService, private router: Router) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.currentJson.subscribe(
            json => {
                this.jsonLoader = json
                this.items = this.jsonLoader.dcInfraMin;


                //Check if Res.Pool Synthesis is available
                if (this.jsonLoader.rpSynthesis != undefined) {
                    this.rp_synth_show = this.jsonLoader.rpSynthesis.length > 0;
                } else {
                    this.rp_synth_show = false;
                }
            }
        );
    }


    /**
     * For search bar
     */
    submit(value): void {

        if (this.searchbar.status == "VALID") {
            //this.selectedItem = this.getSelectedEntity(this.searchbar.value.item);
            this.selectedItem = this.getSelectedEntity(value);
            if (this.selectedItem != undefined) {
                this.message.currentUuid = this.selectedItem.uuid;
                this.message.currentName = this.selectedItem.name;
                this.message.currentType = this.selectedItem.type;
                this.message.powerUsageEnv = 'vmware';

                // Empty search bar
                let obj: HTMLInputElement = this.searchbarInput.nativeElement;
                obj.value = "";

                switch (this.selectedItem.type) {
                    case "CLUSTER":
                        if (this.router.url != "/clusterviews/synthesis")
                            this.router.navigate(['/clusterviews/synthesis']);
                        else {
                            this.router.navigate(['/dashboard']);
                            setTimeout(() => this.router.navigate(['/clusterviews/synthesis']), 1);
                        }
                        break;
                    case "SERVER":
                        if (this.router.url != "/hostviews/synthesis")
                            this.router.navigate(['/hostviews/synthesis']);
                        else {
                            this.router.navigate(['/dashboard']);
                            setTimeout(() => this.router.navigate(['/hostviews/synthesis']), 1);
                        }
                        break;
                    case "VM":
                        if (this.router.url != "/vmviews/synthesis")
                            this.router.navigate(['/vmviews/synthesis']);
                        else {
                            this.router.navigate(['/dashboard']);
                            setTimeout(() => this.router.navigate(['/vmviews/synthesis']), 1);
                        }
                        break;
                    case "DATASTORE":
                        if (this.router.url != "/storage-overview")
                            this.router.navigate(['/storage-overview']);
                        else {
                            this.router.navigate(['/dashboard']);
                            setTimeout(() => this.router.navigate(['/storage-overview']), 1);
                        }
                        break;
                    case "RESPOOL":
                        if (this.router.url != "/rpviews/synthesis")
                            this.router.navigate(['/rpviews/synthesis']);
                        else {
                            this.router.navigate(['/dashboard']);
                            setTimeout(() => this.router.navigate(['/rpviews/synthesis']), 1);
                        }
                        break;
                    default:
                        break;
                }

            }
        }
    }

    private getSelectedEntity(selectedName: string): Searchbar {
        return this.items.find(item => item.text === selectedName);
    }


}
