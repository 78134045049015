import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-cluster-distribution7]'
})
export class Clusterdistribution7Directive {

    constructor(public viewContainerRef7: ViewContainerRef) {}

}
