<table id="element-consover-ram" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th rowspan="2" style="display:none;"></th>
			<th rowspan="2">from</th>
			<th rowspan="2">to</th>
			<th rowspan="2">name</th >
			<th width="7%" rowspan="2">nb. of values</th>
			<th colspan="5">RAM consumption (%)</th>
		</tr>
		<tr>
			<th width="4%">avg</th>
			<th width="4%">min</th>
			<th width="4%">max</th>
			<th width="7%">RAM < 10</th>
			<th width="7%">RAM > 70</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let element of data_elements">
			<td style="display:none;">{{element.timeStart}}</td>
			<td>{{element.start}}</td>
			<td>{{element.end}}</td>
			<td>{{element.name}}
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callGraph(element)" *ngIf="currentUser.isMenuGod">
					<cds-icon shape="line-chart"></cds-icon>
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callZoom(element)" *ngIf="element.timeEnd-element.timeStart>3600000">
					<cds-icon shape="zoom-in"></cds-icon>
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callZoomOut(element)" *ngIf="zoom_out">
					<cds-icon shape="zoom-out"></cds-icon>
				</button>
			</td>
			<td>{{element.values}}</td>
			<td>{{element.avgram}}</td>
			<td>{{element.minram}}</td>
			<td>{{element.maxram}}</td>
			<td>{{element.ram10}}</td>
			<td>{{element.ram70}}</td>
		</tr>
  	</tbody>
</table>
