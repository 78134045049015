import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-t3]'
})
export class T3Directive {
    constructor(public viewContainerRef3: ViewContainerRef) {}
}
