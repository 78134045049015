import {
    Component,
    ChangeDetectorRef,
    OnInit,
    AfterViewInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    AuditService,
    ShareService
} from '@app/services';

import {
    Json,
    Message,
    User
} from '@app/model';

import {
    ClrDatagridSortOrder
} from '@clr/angular';
import * as $ from 'jquery';


@Component({
    selector: 'app-alertingtablerun',
    templateUrl: './alertingtablerun.component.html',
    styleUrls: ['./alertingtablerun.component.css']
})
export class AlertingtablerunComponent implements AfterViewInit, OnInit {

    message: Message;

    jsonLoader: Json;

    elements: string[] = [];

    dataOrderDesc = ClrDatagridSortOrder.DESC;

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private message_svc: ShareService,
        private audit_svc: AuditService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);
    }

    ngAfterViewInit(): void {
        this.getRunElement();
    }

    private getRunElement(): void {

        let granularity = 'day';
        if (this.message.isAlertRt)
            granularity = 'min';

        this.elements = [];
        this.audit_svc.getRunElement(this.currentUser.login, this.message.elementView, granularity).pipe(first())
            .subscribe(
                data => {
                    this.elements = data;
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
    }
}
