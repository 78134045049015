import {
    Component,
    ChangeDetectorRef,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    AuditService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    AlertTimeHistory,
    FilterElement,
    Json,
    Message,
    User
} from '@app/model';

import {
    alertConvApiToIhm
} from '../../../assets/js/tools.js';
import * as $ from 'jquery';


@Component({
    selector: 'app-alertingtable',
    templateUrl: './alertingtable.component.html',
    styleUrls: ['./alertingtable.component.css']
})
export class AlertingtableComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: DataTables.Settings = {};

    dtTrigger: Subject < any > = new Subject();

    message: Message;

    jsonLoader: Json;

    data_items: any;

    historical_alerts: any[] = [];

    filters: FilterElement[] = [];

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private message_svc: ShareService,
        private audit_svc: AuditService,
        private json_svc: JsonloaderService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
        this.data_items = this.jsonLoader.dcInfraMin;

        this.initDtOptions();
    }

    ngAfterViewInit(): void {

        this.dtTrigger.next();
        this.getHistoryDay();
        this.getFilters();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    private getInfraFromUuid(uuid: string): any {
        return this.data_items.find(item => item.uuid === uuid);
    }

    private getFilterFromUuid(uuid: string): any {
        return this.filters.find(item => item.name === uuid);
    }

    private getFilters(): void {

        for (var i = 0; i < this.message.filterList.length; i++) {
            let filter: FilterElement = {
                uuid: this.message.filterList[i].name,
                name: this.message.filterList[i].name,
                type: 'filter'
            };
            this.filters.push(filter);
        }
    }

    private reloadTable(): void {

        if (this.dtElement.dtInstance != undefined) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                $("div.dataTables_filter").remove();
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
            },
            language: {
                zeroRecords: "no data",
                search: '',
                searchPlaceholder: " search..."
            },
            dom: '<"top">frt<"bottom"ip>'
        };
    }

    private getHistoryDay(): void {

        if (this.message.alertHistoryTime > 0) {
            this.audit_svc.getAlertTimeHistory(this.currentUser.login, this.message.alertHistoryTime).pipe(first())
                .subscribe(
                    data => {
                        if (data.length > 0)
                            this.setHistoryTab(data);
                    },
                    error => {
                        if (error != null)
                            console.log(error)
                    }
                );
        }
    }

    private setHistoryTab(data: AlertTimeHistory[]): void {

        this.historical_alerts = [];
        for (var i = 0; i < data.length; i++) {
            let cur_alert: any = {
                uuid: '',
                threshold_1: data[i].threshold_1,
                threshold_2: data[i].threshold_2,
                frequency: data[i].frequency,
                counter: data[i].counter,
            };
            let cur_converted_alert = alertConvApiToIhm(cur_alert);

            let new_val: string = cur_converted_alert.th1 + ' ' + cur_converted_alert.unit;
            if (data[i].state == "critical")
                new_val = cur_converted_alert.th2 + ' ' + cur_converted_alert.unit;

            let definition: string = data[i].definition.replace('%STATE%', new_val);

            let type: string = 'FILTER';
            let name: string = '';
            if (data[i].counter == "cpuusage" || data[i].counter == "ramusage" || data[i].counter == "cpuready" ||
                data[i].counter == "cpucostop" ||
                data[i].counter == "ramactive" || data[i].counter == "ramshared" || data[i].counter == "ramconsumed"
            ) {
                const item: any = this.getInfraFromUuid(data[i].uuid);
                if (item != undefined) {
                    name = item.name;
                    type = item.type;
                }
            } else {
                const item: any = this.getFilterFromUuid(data[i].uuid);
                if (item != undefined)
                    name = item.name;
            }

            if (name != "") {
                let ath: any = {
                    alert: data[i].alert,
                    name: name,
                    type: type,
                    definition: definition,
                    base: cur_converted_alert.base,
                    state: data[i].state,
                    th1: cur_converted_alert.th1 + ' ' + cur_converted_alert.unit,
                    th2: cur_converted_alert.th2 + ' ' + cur_converted_alert.unit,
                    granularity: data[i].granularity
                };
                this.historical_alerts.push(ath);
            }
        }
        if (this.historical_alerts.length > 0)
            this.reloadTable();
    }
}
