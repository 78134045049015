import {
    Component,
    EventEmitter,
    OnInit,
    Output
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    ManagementService,
    ShareService
} from '@app/services';

import {
    Message
} from '@app/model';


@Component({
    selector: 'app-containers',
    templateUrl: './containers.component.html',
    styleUrls: ['./containers.component.css']
})
export class ContainersComponent implements OnInit {

    @Output("loadCluster") loadCluster: EventEmitter < any > = new EventEmitter();

    message: Message;

    isSettings: boolean = false;

    model: any = {
        clusterSelected: '',
    };

    clusters: any = [];


    constructor(
        private mgt_svc: ManagementService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.mgt_svc.getKubernetes().pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.model.clusterSelected = data[0].INSTANCE_NAME;
                    this.initLoadCluster();
                    for (let i = 0; i < data.length; i++) {
                        this.clusters.push(data[i]);
                    }
                }
            }
        );
    }

    initLoadCluster(): void {
        this.message.kubeCluster = this.model.clusterSelected;
    }

    reloadCluster(): void {
        this.loadCluster.emit();
    }
}
