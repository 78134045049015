import {
    Injectable
} from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {
    Observable
} from 'rxjs';

import {
    AccountService
} from './account.service';

import {
    User
} from '@app/model';
import {
    environment
} from "@environments/environment";


@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    private currentUser: User;


    constructor(private account_svc: AccountService) {
        this.account_svc.user.subscribe(user => this.currentUser = user);
    }

    intercept(req: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {

        let newreq: HttpRequest < any > ;

        if (
            req.url.search("/upload/logo") != -1 ||
            req.url.search("/upload/updates") != -1 ||
            req.url.search("/upload/device") != -1 ||
            req.url.search("/upload/view") != -1 ||
            req.url.search("/cloudpricing/fetchnewprices") != -1 ||
            req.url.search("/cloudcarbon/fetchnewemissions") != -1 ||
            req.url.search("/threats/blocklist/update") != -1
        ) {

            newreq = req.clone({
                setHeaders: {
                    'Authorization': 'Token ' + this.currentUser.token
                }
            });

        } else if (req.url.search("/v1/") != -1) {
            let headers = {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                //'Connection' : 'keep-alive',
            };
            if (req.url.search(environment.apiUrl) != -1) {
                headers['Authorization'] = 'Token ' + this.currentUser.token;
            }
            newreq = req.clone({
                setHeaders: headers
            });
        } else {
            newreq = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'Authorization': 'Token ' + this.currentUser.token
                }
            });

        }

        return next.handle(newreq);
    }
}
