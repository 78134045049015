import {
    Component,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    ManagementService,
    MonitorService,
    ShareService
} from '@app/services';

import {
    InstanceType,
    Message
} from '@app/model';

import {
    RecommendationComponent
} from '@app/recommendation/recommendation.component';
import {
    AwsComponent
} from '@app/provider/aws/aws.component';
import {
    AzureComponent
} from '@app/provider/azure/azure.component';
import {
    Subscription
} from 'rxjs';

@Component({
    selector: 'app-opportunities',
    templateUrl: './opportunities.component.html',
    styleUrls: ['./opportunities.component.css']
})
export class OpportunitiesComponent implements OnInit {

    message: Message;

    @ViewChild('vmwareopp') vmwareopp: RecommendationComponent;
    @ViewChild('awsopp') awsopp: AwsComponent;
    @ViewChild('azureopp') azureopp: AzureComponent;

    isAccountMgtReady: boolean = false;
    isReady: boolean = false;

    isDataReload: boolean = false;

    // For tabs
    tab: string;
    tab_subscription: Subscription;
    instancetype = InstanceType;

    constructor(private mgt_svc: ManagementService, private message_svc: ShareService,
        private monitor_svc: MonitorService) {}

    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.tab_subscription = this.monitor_svc.currentAppTab.subscribe((tab) => {
            this.tab = tab;

            this.isReady = false;
            this.isAccountMgtReady = false;
            this.message.cloudProvider = tab;

            switch (tab) {
                case InstanceType.AWS:
                    this.setAws();
                    break;
                case InstanceType.AZURE:
                    this.setAzure();
                    break;
                case InstanceType.GCP:
                    this.setGcp();
                    break;
                default:
                    break;
            }
        });
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.tab_subscription != undefined) {
            this.tab_subscription.unsubscribe();
        }
    }

    setAws(): void {
        this.mgt_svc.getRegions('aws').pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.message.isValidAws = true;
                    setTimeout(() => this.initCloud(), 100);
                }
            }
        );
    }

    setAzure(): void {
        this.mgt_svc.getRegions('azure').pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.message.isValidAzure = true;
                    setTimeout(() => this.initCloud(), 100);
                }
            }
        );
    }

    setGcp(): void {
        this.mgt_svc.getRegions('gcp').pipe(first()).subscribe(
            data => {
                if (data.length > 0) {
                    this.message.isValidGcp = true;
                    setTimeout(() => this.initCloud(), 100);
                }
            }
        );
    }

    loadRegion(): void {
        this.isDataReload = true;
        setTimeout(() => this.isDataReload = false, 100);
    }

    private initCloud(): void {
        this.isAccountMgtReady = true;
        setTimeout(() => this.isReady = true, 100);
    }
}
