import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    first
} from "rxjs/operators";

import {
    InstanceType,
    Message
} from "@app/model";

import {
    ManagementService,
    MonitorService,
    ShareService,
    JsonloaderService
} from "@app/services";

import {
    AllinclusiveComponent
} from "./allinclusive/allinclusive.component";
import {
    VmwareComponent
} from "./vmware/vmware.component";
import {
    DashboardCloudComponent
} from "@app/provider/dashboard-cloud/dashboard-cloud.component";
import {
    ContainersComponent
} from "@app/containers/containers.component";
import {
    DashboardContainerComponent
} from "@app/containers/dashboard-container/dashboard-container.component";
import {
    Subscription
} from "rxjs";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements AfterViewChecked, OnInit {
    @ViewChild("alldashboard") alldashboard: AllinclusiveComponent;
    @ViewChild("vmwaredashboard") vmwaredashboard: VmwareComponent;
    @ViewChild("awsdashboard") awsdashboard: DashboardCloudComponent;
    @ViewChild("azuredashboard") azuredashboard: DashboardCloudComponent;
    @ViewChild("gcpdashboard") gcpdashboard: DashboardCloudComponent;
    @ViewChild("containers") containers: ContainersComponent;
    @ViewChild("dashboardKube") kubernetesdashboard: DashboardContainerComponent;

    message: Message;

    isDcscope = true;
    isCo2scope = false;

    isAccountMgtReady: boolean = false;
    isReady: boolean = false;

    isDataReload: boolean = false;

    isContainerMgtReady: boolean = false;

    // For tabs
    tab: InstanceType;
    tab_subscription: Subscription;
    instanceType = InstanceType;

    // For Json reload
    json_subscription: Subscription;
    allowReload: boolean = false;

    /**
     * 
     */
    constructor(private cd: ChangeDetectorRef, private mgt_svc: ManagementService,
        private message_svc: ShareService, private monitor_svc: MonitorService,
        private json_svc: JsonloaderService
    ) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe((message) => (this.message = message));

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;

        this.tab_subscription = this.monitor_svc.currentAppTab.subscribe((tab) => {
            this.tab = tab;
            switch (tab) {
                case InstanceType.ALL:
                    this.setAllInclusive();
                    break;
                case InstanceType.VMWARE:
                    this.setVmware();
                    break;
                case InstanceType.AWS:
                    this.setAws();
                    break;
                case InstanceType.AZURE:
                    this.setAzure();
                    break;
                case InstanceType.GCP:
                    this.setGcp();
                    break;
                case InstanceType.KUBERNETES:
                    this.setKubernetes();
                    break;
                case InstanceType.XCLARITY:
                    this.setXClarity();
                    break;
                case InstanceType.SCAPHANDRE:
                    this.setScaphandre();
                    break;
                case InstanceType.OPENMANAGE:
                    this.setOpenManage();
                    break;
                case InstanceType.ONEVIEW:
                    this.setOneView();
                    break;
                case InstanceType.STORAGE:
                    this.setStorage();
                    break;
                case InstanceType.NETWORK:
                    this.setNetwork();
                    break;
                default:
                    break;
            }
        });

        this.json_subscription = this.json_svc.currentJson.subscribe(
            json => {
                if (this.awsdashboard !== undefined && this.allowReload) {
                    this.setAws();
                } else if (this.azuredashboard !== undefined && this.allowReload) {
                    this.setAzure();
                } else if (this.gcpdashboard !== undefined && this.allowReload) {
                    this.setGcp();
                }
                this.allowReload = true;
            }
        )
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.tab_subscription != undefined) {
            this.tab_subscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {}

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    setVmware(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
    }

    setAllInclusive(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
    }

    setAws(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
        this.isReady = false;
        this.message.cloudProvider = "aws";
        this.message.creditSelected = "";
        this.mgt_svc
            .getRegions("aws")
            .pipe(first())
            .subscribe((data) => {
                if (data.length > 0) {
                    this.message.isValidAws = true;
                    setTimeout(() => this.initCloud(), 100);
                }
            });
    }

    setAzure(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
        this.isReady = false;
        this.message.cloudProvider = "azure";
        this.message.creditSelected = "";
        this.mgt_svc
            .getRegions("azure")
            .pipe(first())
            .subscribe((data) => {
                if (data.length > 0) {
                    this.message.isValidAzure = true;
                    setTimeout(() => this.initCloud(), 100);
                }
            });
    }

    setGcp(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
        this.isReady = false;
        this.message.cloudProvider = "gcp";
        this.message.creditSelected = "";
        this.mgt_svc
            .getRegions("gcp")
            .pipe(first())
            .subscribe((data) => {
                if (data.length > 0) {
                    this.message.isValidGcp = true;
                    setTimeout(() => this.initCloud(), 100);
                }
            });
    }

    setScaphandre(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
    }

    setKubernetes(): void {
        this.isContainerMgtReady = true;
        this.isAccountMgtReady = false;
        this.isReady = false;
        this.message.creditSelected = "";
        this.message.kubeDetail = false;
        this.mgt_svc
            .getKubernetes()
            .pipe(first())
            .subscribe((data) => {
                if (data.length > 0) {
                    this.message.isValidKubernetes = true;
                    setTimeout(() => (this.isReady = true), 100);
                }
            });
    }

    setXClarity(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
    }

    setOpenManage(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
    }

    setOneView(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
    }

    setStorage(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
    }

    setNetwork(): void {
        this.isContainerMgtReady = false;
        this.isAccountMgtReady = false;
    }

    loadRegion(): void {
        this.isDataReload = true;
        setTimeout(() => (this.isDataReload = false), 100);
    }

    loadCluster(): void {
        this.isDataReload = true;
        setTimeout(() => (this.isDataReload = false), 100);
    }

    private initCloud(): void {
        this.isAccountMgtReady = true;
        setTimeout(() => (this.isReady = true), 100);
    }
}
