import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    JsonloaderService,
    ManagementService,
    ShareService
} from '@app/services';

import {
    COST,
    Json,
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    getUserCurrency
} from '../../../assets/js/tools.js';


@Component({
    selector: 'app-costvmgeneric',
    templateUrl: './costvmgeneric.component.html',
    styleUrls: ['./costvmgeneric.component.css']
})
export class CostvmgenericComponent implements AfterViewInit, OnInit {

    message: Message;

    globalCurrency: string = '';

    model: any;

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private management_svc: ManagementService,
        private data: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        this.data.currentMessage.subscribe(message => this.message = message);

        this.model = {
            vmCostGeneric: 200
        };
    }

    ngAfterViewInit(): void {
        this.getCost();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    enableVmUpdate(): void {

        this.message.isCostRecord = true;
        this.message.costVmGeneric = this.model.vmCostGeneric;
    }

    private getCost(): void {

        this.management_svc.getCost('root').pipe(first()).subscribe(
            data => {
                if (data.VM_COST != -1)
                    this.model.vmCostGeneric = data.VM_COST;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }
}
