import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-table]'
})
export class AlertingtablesDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
