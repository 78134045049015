import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    HostListener,
    OnInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
    Input
} from '@angular/core';

import {
    first
} from 'rxjs/operators';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);
import dumbbell from 'highcharts/modules/dumbbell';
dumbbell(Highcharts);

import * as moment from 'moment';

import {
    CloudTime,
    JSONTarget,
    Message,
    MetaCO2,
    User
} from '@app/model';

import {
    AccountService,
    ManagementService,
    MeasurementService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    CostsDetailComponent
} from '../aws/costs-detail/costs-detail.component';
import {
    CloudcostsDirective
} from '../../directives/cloudcosts.directive';

import {
    InstancesDetailComponent
} from '../aws/instances-detail/instances-detail.component';
import {
    CloudinstancesDirective
} from '../../directives/cloudinstances.directive';

import {
    InstancesCo2Component
} from '../aws/instances-co2/instances-co2.component';
import {
    Cloudco2Directive
} from '../../directives/cloudco2.directive';

import {
    KpiCreditsComponent
} from '../aws/kpi-credits/kpi-credits.component';

import {
    CloudkpicreditsDirective
} from '../../directives/cloudkpicredits.directive';

import {
    ConfiguratorComponent
} from '../configurator/configurator.component';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faLeaf
} from '@fortawesome/free-solid-svg-icons';
import { StatisticalComponent } from '../statistical/statistical.component';

@Component({
    selector: 'app-dashboard-cloud',
    templateUrl: './dashboard-cloud.component.html',
    styleUrls: ['./dashboard-cloud.component.css']
})
export class DashboardCloudComponent implements AfterViewChecked, OnInit, OnChanges {

    @Input() isreload: boolean = false;

    @ViewChild(CloudinstancesDirective) addCloudInstancesDetail: CloudinstancesDirective;
    @ViewChild(CloudcostsDirective) addCloudCostsDetail: CloudcostsDirective;
    @ViewChild(Cloudco2Directive) addCloudCo2: Cloudco2Directive;
    @ViewChild(CloudkpicreditsDirective) addCloudKpiCredits: CloudkpicreditsDirective;
    @ViewChild('addConfigurator') addConfigurator: ConfiguratorComponent;
    @ViewChild('addStatistical') addStatistical: StatisticalComponent;


    isDcscope = true;
    isCo2scope = false;

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;

    chartOptions1: Highcharts.Options = {};
    chartOptions2: Highcharts.Options = {};
    chartOptions3: Highcharts.Options = {};
    chartOptions4: Highcharts.Options = {};
    chartOptions5: Highcharts.Options = {};

    metadata: any = [];

    message: Message;

    isReady: boolean = false;
    isReady2: boolean = false;
    isReady3: boolean = false;

    evolution: any = [];

    med: any = null;

    months: any = [];

    model: any = {
        detail_str: '',
        profilMsg: 'over the current month',
        entityMsg: 'Total',
        isCostDashboard: true,
        isCo2Dashboard: true,
        isInfraDashboard: true,
        isConsoDashboard: true,
        isElasticityDashboard: false,
        isDynamicityDashboard: false,
        isResourceUsage: false,
        kpiAggUsagePercent: 0,
        more30Days: 0,
        carbonDashboardClass: 'clr-col-lg-4 clr-col-md-4 clr-col-12'
    };

    viewContainerRef: any;

    currentUser: User;

    ageStructure: any = [];


    @HostListener('document:click', ['$event.target'])
    DocumentClick(event: any) {
        if(event.id == "congigurator-co2-profil" || event.id == "congigurator-co2-tagvalue" || event.id == "congigurator-co2-tagname") {
            this.isReady = false;
            setTimeout(() => this.buildCO2Data(), 500);
        } else if(event.id == "cost-detail-close" || event.id == "instance-detail-close" || event.id == "credit-detail-close"|| event.id == "resource-detail-close") {
            this.initData();
        } else if (event.point != undefined) {
            if(event.point.id == "congigurator-co2-profil-time") {
                this.isReady = false;
                setTimeout(() => this.buildCO2Data(), 500);
            }
        }
    }

    constructor(
        private account_svc: AccountService,
        public componentFactoryResolver: ComponentFactoryResolver,
        private mgt_svc: ManagementService,
        private measurement_svc: MeasurementService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private library: FaIconLibrary,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            (            user: User) => {
                this.currentUser = user;
            }
        );

        this.library.addIcons(faLeaf);

        this.message_svc.currentMessage.subscribe((message: Message) => this.message = message);

        this.message.creditSelected = "";

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;

        this.message.cloudMessage.instanceIds = [];
        this.mgt_svc.getRegions(this.message.cloudProvider).pipe(first()).subscribe(
            (data: any[]) => {
                if (data.length > 0) {
                  for(let i in data) {
                    if(data[i].INSTANCE_TYPE == this.message.cloudProvider)
                      this.message.cloudMessage.instanceIds.push(data[i].INSTANCE_ID);
                  }

                    let cloudTime: CloudTime = {
                        currentMonth: moment().format('YYYY MMM'),
                        currentYear: moment().format('YYYY'),
                        startCurrentMonth: moment().startOf('month').unix(),
                        endCurrentMonth: moment().endOf('month').unix(),
                        startCurrentYear: moment().startOf('year').unix(),
                        endCurrentYear: moment().endOf('year').unix(),
                        isCurrentMonth: true,
                        isCurrentYear: true,
                        startProfile: 0,
                        endProfile: 0
                    };
                    this.message.cloudMessage.cloudTime = cloudTime;
        
                    setTimeout(() => this.loadRegionData(), 500);
                }
            }
        );

        // INIT MetaCO2
        this.message.cloudMessage.metaCo2 = {
            direct: 0,
            grey: 0,
            aggregate: 0,
            power: 0
        };

        this.buildShape();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['isreload'].currentValue)
            this.loadRegionData();

    }

    ngAfterViewChecked(): void {

        this.cd.detectChanges();
    }

    loadCostDetail(): void {

        this.model.isConsoDashboard = false;
        this.model.isCostDashboard = false;
        this.model.isCo2Dashboard = false;
        this.model.isInfraDashboard = false;
        this.model.isElasticityDashboard = false;
        this.model.isDynamicityDashboard = false;
        this.model.isResourceUsage = false;

        this.message.creditSelected = 'cost';
        this.model.detail_str = "cost";
        setTimeout(() => this.loadCloudCostsDetail(), 100);
    }

    loadCo2Detail(): void {

        this.model.carbonDashboardClass = 'clr-col-lg-3 clr-col-md-3 clr-col-12';

        this.isReady = false;
        this.model.isConsoDashboard = false;
        this.model.isCostDashboard = false;
        this.model.isInfraDashboard = false;
        this.model.isElasticityDashboard = false;
        this.model.isDynamicityDashboard = false;

        this.message.creditSelected = 'co2';
        this.model.detail_str = "co2";
        this.model.profilMsg = 'over the current month';
        this.model.entityMsg = 'Total';

        setTimeout(() => this.loadCloudCo2Detail(), 100);
    }

    loadInstancesDetail(): void {

        this.model.isConsoDashboard = false;
        this.model.isCostDashboard = false;
        this.model.isCo2Dashboard = false;
        this.model.isInfraDashboard = false;
        this.model.isElasticityDashboard = false;
        this.model.isDynamicityDashboard = false;
        this.model.isResourceUsage = false;

        this.message.creditSelected = 'instance';
        this.model.detail_str = "instance";
        setTimeout(() => this.loadCloudInstancesDetail(), 100);
    }

    initData(): void {

        this.model.detail_str = '';
        this.model.isInfraDashboard = true;
        this.model.isCostDashboard = true;
        this.model.isCo2Dashboard = true;
        this.model.isConsoDashboard = true;
        this.model.isElasticityDashboard = false;
        this.model.isDynamicityDashboard = false;
        this.model.isResourceUsage = false;
        this.model.profilMsg = 'over the current month',
        this.model.entityMsg = 'Total';
        this.model.carbonDashboardClass = 'clr-col-lg-4 clr-col-md-4 clr-col-12';
        this.message.cloudMessage.isWithTag = false;

        if(this.message.cloudMessage.tag != null)
            this.message.cloudMessage.tag.tagName == "";

        setTimeout(() => this.loadRegionData(), 100);
    }

    loadElasticity(): void {

        this.model.isDynamicityDashboard = false;
        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message.cloudProvider, JSONTarget.CLOUD_KPI_EVOLUTION).subscribe(
            (data: any) => {
                if (data.length > 0) {
                    this.loadEvolutionGraph(data);
                }
            }
        );
    }

    loadDynamicity(): void {

        this.model.isElasticityDashboard = false;
        this.loadAgeGraph();
    }

    loadCreditsDetail(): void {

        this.model.isConsoDashboard = false;
        this.model.isCostDashboard = false;
        this.model.isCo2Dashboard = false;
        this.model.isInfraDashboard = false;
        this.model.isElasticityDashboard = false;
        this.model.isDynamicityDashboard = false;
        this.model.isResourceUsage = false;

        this.message.creditSelected = 'credits';
        this.model.detail_str = "kpicredits";
        setTimeout(() => this.loadCloudKpiCredits(), 100);

    }

    loadResourceUsage(): void {

        this.model.isConsoDashboard = false;
        this.model.isCostDashboard = false;
        this.model.isCo2Dashboard = false;
        this.model.isInfraDashboard = false;
        this.model.isElasticityDashboard = false;
        this.model.isDynamicityDashboard = false;

        this.model.isResourceUsage = true;
        this.message.creditSelected = 'infra';
    }

    toFixedNumber(val: any, nb: number): number {

        let value: number = 0;
        if (val != null)
            value = val.toFixed(nb);

        return value;
    }

    private buildCO2Data(): void {

        this.model.entityMsg = 'Tag ' + this.message.cloudMessage.tag.tagName + ' [' + this.message.cloudMessage.tag.tagValue + ']';
        if(this.message.cloudMessage.tag.tagName == "none")
            this.model.entityMsg = 'Total';

        switch(this.message.cloudMessage.profile) {
            case "daily":
                let startDay = moment(this.message.cloudMessage.profileDate).subtract(1, 'days').unix()*1000;
                let day_str: string = moment.unix(startDay/1000).format('dddd, MMMM Do YYYY');
                this.model.profilMsg = 'over the ' + day_str;
                break;
            case "monthly":
                this.model.profilMsg = 'over ' + this.message.cloudMessage.profileDate;
                break;
            case "weekly":
                let startWeek = moment(this.message.cloudMessage.profileDate).subtract(1, 'weeks').weeks();
                this.model.profilMsg = 'over the week ' + startWeek;
                break;
            case "yearly":
                this.model.profilMsg = 'over ' + this.message.cloudMessage.profileDate;
                break;
            default:
                break;
        }
        setTimeout(() => this.loadCo2Graph(), 500);
    }

    private loadRegionData(): void {

        let identifiers: any = this.message.cloudMessage.instanceIds;

        this.measurement_svc.getProfileCarbonEmission('monthly', identifiers, true, this.message.cloudMessage.cloudTime,null, null).pipe(first()).subscribe(
            (data: any) => {
              if(data.length > 0) {
      
                let checkDataNotNull: any = [];
      
                for(let d of data) {
                  if(d.date != null) {
                    checkDataNotNull.push(d);
                  }
                }
      
                if(checkDataNotNull.length > 0) {
                  let metaCO2 : MetaCO2 = {
                    direct: Number(checkDataNotNull[checkDataNotNull.length-1].direct.toFixed(2)),
                    grey: Number(checkDataNotNull[checkDataNotNull.length-1].grey.toFixed(2)),
                    aggregate: Number((checkDataNotNull[checkDataNotNull.length-1].direct+checkDataNotNull[checkDataNotNull.length-1].grey).toFixed(2)),
                    power: checkDataNotNull[checkDataNotNull.length-1].power
                  };
                  this.message.cloudMessage.metaCo2 = metaCO2;
                  this.loadCo2Graph();
                }
              }
            },
            (error: any) => {
                if (error != null)
                    console.log(error)
            }
        );
        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message.cloudProvider,
            JSONTarget.CLOUD_METADATA).subscribe(
                (data: any) => {
                this.metadata = data;
                if (this.metadata.kpiCreditsAggMax > 0)
                    this.model.kpiAggUsagePercent = (this.metadata.kpiCreditAggUsage * 100 / this.metadata
                        .kpiCreditsAggMax).toFixed(0);

                this.loadCostsGraph();
            }
        );

        this.model.more30Days = 0;
        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message.cloudProvider, JSONTarget.CLOUD_AGE_STRUCTURE).subscribe(
            (data: any) => {
                if (data.length > 0) {
                    this.ageStructure = data;
                    for (let i in data) {
                        if (data[i].nbDay >= 30)
                            this.model.more30Days += data[i].nbVm;
                    }            
                }
            }
        );

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message.cloudProvider, 
            JSONTarget.CLOUD_MONTH).subscribe(
                (                    data2: any) => {
                        this.months = data2;
                        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter,
                                'cloud_' + this.message.cloudProvider, JSONTarget.CLOUD_OVERVIEW_EVOLUTION)
                            .subscribe(
                                (                                data3: any) => {
                                    this.evolution = data3;
                                    let iter: number = 0;
                                    iter = this.evolution.length;
                                    if (iter > 0)
                                        this.med = this.evolution[iter - 1].med;

                                    let val: string = '';
                                    val = this.months[0].name;
                                    this.model.selectedMonth = val;

                                    setTimeout(() => this.loadInfrastructureGraph(), 100);
                                }
                            );
                    }
        );
    }

    private loadCloudCostsDetail(): void {

        if (this.addCloudCostsDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CostsDetailComponent);
            const viewContainerRef = this.addCloudCostsDetail.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCloudInstancesDetail(): void {

        if (this.addCloudInstancesDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                InstancesDetailComponent);
            const viewContainerRef = this.addCloudInstancesDetail.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCloudCo2Detail(): void {

        this.isReady = true
        if (this.addCloudCo2 != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InstancesCo2Component);
            const viewContainerRef = this.addCloudCo2.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadCloudKpiCredits(): void {

        if (this.addCloudKpiCredits != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpiCreditsComponent);
            const viewContainerRef = this.addCloudKpiCredits.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }


    private loadCostsGraph(): void {

        let title: string = '';
        let titleSize: string = '20px';

        this.chartOptions2 = {
            credits: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            chart: {
                plotBorderWidth: null,
                plotShadow: false
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
            },
            legend: {
                enabled: true
            },
            exporting: {
                enabled: false
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                }
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                name: 'cost',
                data: [{
                    name: 'compute',
                    y: this.metadata.computeCost,
                    //color: '#C0C0C0'
                    color: '#f5b041'
                }, {
                    name: 'storage',
                    y: this.metadata.storageCost,
                    //color: '#FFCC00'
                    color: '#566573'
                }]
            }]
        };
        this.isReady2 = true;
    }

    private loadCo2Graph(): void {

        let title: string = '';
        let titleSize: string = '20px';

        this.chartOptions1 = {
            credits: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            chart: {
                plotBorderWidth: null,
                plotShadow: false
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
            },
            legend: {
                enabled: true
            },
            exporting: {
                enabled: false
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                }
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                name: 'carbon emission',
                data: [{
                    name: 'direct',
                    y: this.message.cloudMessage.metaCo2.direct,
                    color: '#10394c'
                }, {
                    name: 'embodied',
                    y: this.message.cloudMessage.metaCo2.grey,
                    color: '#94a651'
                }]
            }]
        };
        this.isReady = true;
    }

    private loadInfrastructureGraph(): void {

        let title: string = '';
        let titleSize: string = '20px';

        this.chartOptions3 = {
            credits: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            chart: {
                plotBorderWidth: null,
                plotShadow: false
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                name: 'Instances',
                data: [{
                    name: 'off',
                    y: this.med.nbOff,
                    color: '#616a6b'
                }, {
                    name: 'running',
                    y: this.med.nbRunning,
                    color: '#abd65c',
                    sliced: true,
                    selected: true
                }, {
                    name: 'deleted',
                    y: this.med.nbDestroy,
                    color: '#dc7633'
                }, {
                    name: 'created',
                    y: this.med.nbCreate,
                    color: '#546e7a'
                }]
            }]
        };
        this.isReady3 = true;
    }

    private loadEvolutionGraph(kpiEvolution: any): void {

        let title: string = '';
        let titleSize: string = '20px';
        let offset_y: number = 1.5;
        let offset_x: number = 1.5;

        let datas: any = [];
        let vm_datas: any = [];
        for (let i in kpiEvolution) {
            let x_all: number = kpiEvolution[i].all;
            let x_create: number = kpiEvolution[i].all + kpiEvolution[i].nbCreate;
            let x_del: number = x_all - kpiEvolution[i].ngDestroy;

            let j: number = +i;
            j = j + offset_y;

            let offset_2: number = 2 * x_all / 100;
            let offset_50: number = 50 * x_all / 100;
            if (x_all < 25) {
                offset_2 = offset_x;
                offset_50 = 50;
            }

            let del_color: string = "#dc7633";
            let del_offset: number = x_del;

            let add_color: string = "#546e7a";
            let add_offset: number = x_create + offset_2;

            if (x_create == x_all) {
                add_color = "#ffffff";
                add_offset = x_all + offset_50
            }
            if (x_del == x_all) {
                del_offset = x_all - offset_50;
                del_color = "#ffffff";
            }

            let data_del: any = {
                x: del_offset,
                x2: x_all,
                y: j + 1, // day
                color: del_color,
                text: ' ' + Highcharts.dateFormat('%Y/%m/%d', kpiEvolution[i].timeago) +
                    '<br>' + Math.abs(kpiEvolution[i].ngDestroy) + ' deleted',
            };
            datas.push(data_del);

            let data_all: any = {
                x: x_all,
                x2: x_all + offset_2,
                y: j + 1, // day
                color: '#abd65c',
                text: ' ' + Highcharts.dateFormat('%Y/%m/%d', kpiEvolution[i].timeago) +
                    '<br>' + ' total: ' + kpiEvolution[i].all
            };
            datas.push(data_all);

            let data_add: any = {
                x: x_all + offset_2,
                x2: add_offset,
                y: j + 1, // day
                color: add_color,
                text: ' ' + Highcharts.dateFormat('%Y/%m/%d', kpiEvolution[i].timeago) +
                    '<br>' + Math.abs(kpiEvolution[i].nbCreate) + ' added',
            };
            datas.push(data_add);
            vm_datas.push(kpiEvolution[i].all);
        }

        let serie0: any = {
            type: 'xrange',
            name: 'evolution',
            pointWidth: 5,
            data: datas
        };
        let series: any = [];
        series.push(serie0);

        this.chartOptions4 = {
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            chart: {
                inverted: false,
                plotBorderWidth: null,
                plotShadow: false,
            },
            xAxis: {
                visible: true,
                title: {
                    text: "instances",
                    align: 'high'
                },
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                visible: true,
                title: {
                    text: 'past days',
                    align: 'high'
                },
                labels: {
                    enabled: false
                }
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span><b>{point.text}</b><br/>'
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                },
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        symbolStroke: "#0072A3"
                    }
                }
            },
            series: series
        };

        this.model.isElasticityDashboard = true;
    }

    private loadAgeGraph(): void {

        let title: string = '';
        let datas: any = [];
        let cat: any = [];

        this.model.lastDay = this.ageStructure[0].nbDay;
        this.model.insLastDay = this.ageStructure[0].nbVm;

        this.model.more30Days = 0;
        for (let i in this.ageStructure) {
            cat.push(this.ageStructure[i].nbDay);
            datas.push(this.ageStructure[i].nbVm);
            if (this.ageStructure[i].nbDay >= 30)
                this.model.more30Days += this.ageStructure[i].nbVm;
        }

        let serie0: any = {
            type: 'bar',
            name: 'number of instances',
            color: '#abd65c',
            pointWidth: 5,
            data: datas
        };
        let series: any = [];
        series.push(serie0);

        this.chartOptions5 = {
            credits: {
                enabled: false
            },
            title: {
                text: title
            },
            xAxis: {
                categories: cat,
                title: {
                    text: 'past days',
                    align: 'high',
                    rotation: -90
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'instances',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' '
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            legend: {
                enabled: false
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        symbol: 'download',
                        symbolStroke: "#0072A3"
                    }
                }
            },
            series: series
        };

        this.model.isDynamicityDashboard = true;
    }


    private buildShape() {

        Highcharts.SVGRenderer.prototype.symbols.download = function(x: number, y: number, w: number, h: number) {
            var path = [
                // Arrow stem
                'M', x + w * 0.5, y,
                'L', x + w * 0.5, y + h * 0.7,
                // Arrow head
                'M', x + w * 0.3, y + h * 0.5,
                'L', x + w * 0.5, y + h * 0.7,
                'L', x + w * 0.7, y + h * 0.5,
                // Box
                'M', x, y + h * 0.9,
                'L', x, y + h,
                'L', x + w, y + h,
                'L', x + w, y + h * 0.9
            ];
            return path;
        };
    }
}
