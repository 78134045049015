<div style="width: 300px;">
    <button class="label label-blue" (click)="selectAll();triggerEvent()">Select all</button>
    <button class="label label-blue" (click)="unselectAll();triggerEvent()">Unselect all</button>

    <clr-checkbox-container clrInline>
        <label>{{ label }}</label>
        <clr-checkbox-wrapper *ngFor="let value of allValues">
            <input type="checkbox" clrCheckbox name="options" value="{{value}}" (change)="triggerEvent()" [(ngModel)]="options[value]" />
            <label>{{ value }}</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <hr/>
    <clr-checkbox-container clrInline>
        <label>Special cases</label>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options" value="turned-off" (change)="triggerEvent()" [(ngModel)]="displayTurnedOff" />
            <label>Turned off</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options" value="no-match" (change)="triggerEvent()" [(ngModel)]="displayNoMatch" />
            <label>No match</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
</div>
