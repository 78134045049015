import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {
    JSONTarget,
    Message,
    User
} from '@app/model';
import {
    AccountService,
    GreenitService,
    JsonloaderService,
    SettingsService,
    ShareService
} from '@app/services';
import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    getUserCurrency
} from 'src/assets/js/tools';
import {
    GREENIT_COLORS,
    GREENIT_GREY_COLORS,
    GreenitTarget
} from '../greenit.enums';

import * as Highcharts from 'highcharts';


@Component({
    selector: 'app-efficiencylist',
    templateUrl: './efficiencylist.component.html',
    styleUrls: ['./efficiencylist.component.css']
})
export class EfficiencylistComponent implements OnInit {

    @ViewChild("divalertlifespan") divalertlifespan: ElementRef;
    @ViewChild("divemptylifespan") divemptylifespan: ElementRef;

    @Input() target: GreenitTarget;
    @Input() efficiency_data: any;
    @Input() renewal_server_data: any;

    @Output('update') update = new EventEmitter();
    pushUpdate() {
        this.update.emit(true);
    }

    dataOrder = ClrDatagridSortOrder.ASC;
    dataOrderDesc = ClrDatagridSortOrder.DESC;

    math = Math;
    number = Number;

    message: Message;

    private currentUser: User;

    private shared_user: string;

    globalCurrency: string;

    // FOR EE MODAL
    efficiency_modal: boolean;

    // FOR CALCULATOR
    simulation_modal: boolean = false;
    simulation_data: any;
    show_best: boolean = true;

    // FOR HIGHCHARTS
    private greenit_color = GREENIT_COLORS;
    private greenit_grey_color = GREENIT_GREY_COLORS;

    Highcharts: typeof Highcharts = Highcharts;

    chart: Highcharts.Chart;
    chart2: Highcharts.Chart;
    chart3: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.chart.showLoading();
    };

    chartCallback2: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart2 = chart;
        this.chart2.showLoading();
    };

    chartCallback3: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart3 = chart;
        this.chart3.showLoading();
    };

    options: Highcharts.Options = {
        chart: {
            height: '250px',
            backgroundColor: 'rgba(255, 255, 255, 0.0)'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                //text: 'lifespan (years)'
                text: 'How long should I keep the renewal server (years)'
            },
        },
        yAxis: [{
            min: 0,
            labels: {
                align: 'right',
                x: -10
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: 'gray'
                },
                /* formatter: function() {
                    let obj: any = this;
                    let stack = obj.stack;
                    let values: any = this.axis.series[4];
                    if(stack == "new") {
                        values = this.axis.series[5];
                    }
                    let value = values.yData[this.x]
                    //return "kgCO2eq per VM = " + value;
                    return "" + value;
                }, */
            }
        }],
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        tooltip: {
            borderColor: 'black',
            formatter: function() {
                return `<span style="color: ${this.color}">•</span> ${this.series.name}: <b>${this.y}</b>`;
            }
        }
    }

    options2: Highcharts.Options = JSON.parse(JSON.stringify(this.options)); // create a copy
    options3: Highcharts.Options = JSON.parse(JSON.stringify(this.options)); // create a copy

    // FOR OPTIMIZATION MODAL
    optimization_json: any;
    optimization_modal: boolean;
    annual_kwh: number;
    annual_cost: number;
    annual_co2: number;
    optimization_kwh: number;
    optimization_cost: number;
    optimization_co2: number;
    optimization_current_servers: number;
    optimization_new_servers: number;


    /**
     * 
     */
    constructor(private authentication_svc: AccountService, private message_svc: ShareService,
        private json_svc: JsonloaderService, private renderer: Renderer2, private greenit_svc: GreenitService) {

        // FOR OPTIMIZATION
        this.annual_kwh = 0;
        this.annual_cost = 0;
        this.annual_co2 = 0;
        this.optimization_kwh = 0;
        this.optimization_cost = 0;
        this.optimization_co2 = 0;
        this.optimization_current_servers = 0;
        this.optimization_new_servers = 0;
    }


    /**
     * 
     */
    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        //XXX Check shared filter
        this.shared_user = this.currentUser.login;
        if (this.message.isShared) {
            this.shared_user = "root";
        }

        if (this.target == GreenitTarget.DC_HOST_CEE) {
            // OPTIMIZATION
            this.json_svc.getData(this.shared_user, this.message.currentFilter, JSONTarget.GREENIT_SERVERS)
                .subscribe(
                    data => {
                        this.optimization_json = data;

                        if (this.optimization_json != null) {
                            this.annual_kwh = Number(Number(this.optimization_json.OLDKWH).toFixed(0));
                            this.annual_co2 = Number(Number(this.optimization_json.OLDCO2).toFixed(0));
                            this.annual_cost = Number(Number(this.optimization_json.OLDCOST).toFixed(0));
                            this.optimization_kwh = Number(Number(this.optimization_json.KWH).toFixed(0));
                            this.optimization_co2 = Number(Number(this.optimization_json.CO2).toFixed(0));
                            this.optimization_cost = Number(Number(this.optimization_json.COST).toFixed(0));
                        }
                    }
                );
        }
    }

    /**
     * 
     */
    displayModalCalculator(data: any): void {
        this.show_best = true;
        this.simulation_data = data;
        this.simulation_modal = true;

        this.simulation_data.endyear = this.simulation_data.year + this.simulation_data.amortization;

        let current_year = new Date().getFullYear();
        this.simulation_data.remaining_year = this.simulation_data.endyear - current_year;

        // FIX remaining_year
        if (this.simulation_data.endyear < current_year)
            this.simulation_data.remaining_year = 0;
        else
            this.simulation_data.remaining_year += 1;

        this.simulation_data.remaining_embodied = this.simulation_data.calculator_co2g[0];

        // Build graph
        this.switchGraph();

        setTimeout(() => {
            if (this.divalertlifespan !== undefined && this.divemptylifespan !== undefined) {
                let height = `${this.divalertlifespan.nativeElement.offsetHeight}px`;
                this.renderer.setStyle(this.divemptylifespan.nativeElement, "height", height);
            }
        });
    }

    /**
     * 
     */
    showGraphBest(): void {

        setTimeout(() => {

            // Chart begins by best_index
            this.chart3.update({
                yAxis: [{
                    title: {
                        text: 'Annual global CO2 (kgCO2eq)'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'gray'
                        },
                    }
                }],
                xAxis: {
                    labels: {
                        formatter: function() {
                            return (this.value + 1) + "";
                        }
                    },
                },
                plotOptions: {
                    series: {
                        pointStart: this.simulation_data.calculator_bestindex,
                    },
                    column: {
                        groupPadding: 0.25,
                        pointWidth: 25,
                        borderWidth: 0
                    },
                },
                tooltip: {
                    borderColor: 'black',
                    formatter: function() {
                        return `<span style="color: ${this.color}">•</span> ${this.series.name}: <b>${this.y}</b>`;
                    }
                }
            });

            if (this.chart3.yAxis.length == 1) {
                this.chart3.addAxis({
                    id: 'yaxis-2',
                    opposite: true,
                    title: {
                        text: "Annual global CO2 per VM (kgCO2eq)",
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'gray'
                        }
                    }
                }, false);
            }

            // Remove old series
            for (var i = this.chart3.series.length - 1; i >= 0; i--) {
                this.chart3.series[i].remove(false);
            }

            this.chart3.addSeries({
                type: 'column',
                name: this.simulation_data.name + ' (direct)',
                cursor: 'pointer',
                color: this.greenit_color[0],
                data: [this.simulation_data.calculator_co2d[this.simulation_data
                    .calculator_bestindex]],
                stack: "current",
            }, false);

            this.chart3.addSeries({
                type: 'column',
                name: this.simulation_data.name + ' (embodied)',
                cursor: 'pointer',
                color: this.greenit_grey_color[0],
                data: [this.simulation_data.calculator_co2g[this.simulation_data
                    .calculator_bestindex]],
                stack: "current",
            }, false);

            this.chart3.addSeries({
                type: 'column',
                name: 'Renewal server (direct)',
                cursor: 'pointer',
                color: '#ea3946',
                data: [this.renewal_server_data.calculator_newco2d[this.simulation_data
                    .calculator_bestindex]],
                stack: "new",
            }, false);

            this.chart3.addSeries({
                type: 'column',
                name: 'Renewal server (embodied)',
                cursor: 'pointer',
                color: '#ab2630',
                data: [this.renewal_server_data.calculator_newco2g[this.simulation_data
                    .calculator_bestindex]],
                stack: "new",
            }, false);

            this.chart3.addSeries({
                type: 'column',
                name: this.simulation_data.name + ' (global per VM)',
                data: [this.simulation_data.calculator_pervmco2[this.simulation_data
                    .calculator_bestindex]],
                stack: "vmcurrent",
                color: this.greenit_color[0],
                pointWidth: 10,
                yAxis: 'yaxis-2',
            }, false);

            this.chart3.addSeries({
                type: 'column',
                name: 'Renewal server (global per VM)',
                data: [this.simulation_data.calculator_newpervmco2[this.simulation_data
                    .calculator_bestindex]],
                color: '#ea3946',
                stack: "vmnew",
                pointWidth: 10,
                yAxis: 'yaxis-2',
            }, false);

            // Hide chart loading
            this.chart3.hideLoading();

            // Redraw
            this.chart3.redraw();

            // Reflow
            this.chart3.reflow();

        }, 300);

    }

    /**
     * 
     */
    showGraphsDetails(): void {

        setTimeout(() => {

            // FIX : Remove old series
            for (var i = this.chart.series.length - 1; i >= 0; i--) {
                this.chart.series[i].remove(false);
            }
            for (var i = this.chart2.series.length - 1; i >= 0; i--) {
                this.chart2.series[i].remove(false);
            }

            let to_server = -1;
            if (this.simulation_data.remaining_year > 0)
                to_server = this.simulation_data.remaining_year - 0.5

            let plot_options_server: any = {
                color: '#fff4c7',
                from: -1,
                to: to_server,
            };

            let from_vm = 10;
            if (this.simulation_data.calculator_bestindex == 0) {
                from_vm = -1;
            } else {
                from_vm = this.simulation_data.calculator_bestindex - 0.5
            }

            let plot_options_vm: any = {
                color: '#E1F7E1',
                from: from_vm,
                to: 10,
            };

            this.chart.update({
                yAxis: [{
                    title: {
                        text: 'Annual global CO2 (kgCO2eq)'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'gray'
                        },
                    }
                }],
                xAxis: {
                    plotBands: plot_options_server,
                    labels: {
                        formatter: function() {
                            return (this.value + 1) + "";
                        }
                    },
                },
                plotOptions: {
                    series: {
                        pointStart: 0,
                    },
                    column: {
                        groupPadding: 0.05,
                        pointWidth: 25,
                        borderWidth: 0
                    },
                },

            });

            this.chart2.update({
                yAxis: [{
                    title: {
                        text: 'Annual global CO2 per VM (kgCO2eq)'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'gray'
                        },
                    }
                }],
                xAxis: {
                    plotBands: plot_options_vm,
                    labels: {
                        formatter: function() {
                            return (this.value + 1) + "";
                        }
                    },
                },
                plotOptions: {
                    series: {
                        pointStart: 0,
                    },
                    column: {
                        groupPadding: 0.20,
                        pointWidth: 25,
                        borderWidth: 0
                    },
                },
                tooltip: {
                    borderColor: 'black',
                    formatter: function() {
                        return `<span style="color: ${this.color}">•</span> ${this.series.name}: <b>${this.y}</b>`;
                    }
                }
            });

            this.chart.addSeries({
                type: 'column',
                name: this.simulation_data.name + ' (direct)',
                cursor: 'pointer',
                color: this.greenit_color[0],
                data: this.simulation_data.calculator_co2d,
                stack: "current",
            }, false);

            this.chart.addSeries({
                type: 'column',
                name: this.simulation_data.name + ' (embodied)',
                cursor: 'pointer',
                color: this.greenit_grey_color[0],
                data: this.simulation_data.calculator_co2g,
                stack: "current",
            }, false);

            this.chart.addSeries({
                type: 'column',
                name: 'Renewal server (direct)',
                cursor: 'pointer',
                color: '#ea3946',
                data: this.renewal_server_data.calculator_newco2d,
                stack: "new",
            }, false);

            this.chart.addSeries({
                type: 'column',
                name: 'Renewal server (embodied)',
                cursor: 'pointer',
                color: '#ab2630',
                data: this.renewal_server_data.calculator_newco2g,
                stack: "new",
            }, false);

            this.chart2.addSeries({
                type: 'column',
                name: this.simulation_data.name + ' (global per VM)',
                data: this.simulation_data.calculator_pervmco2,
                stack: "vmcurrent",
                color: this.greenit_color[0],
                pointWidth: 10,
                pointPadding: 0,
            }, false);

            this.chart2.addSeries({
                type: 'column',
                name: 'Renewal server (global per VM)',
                data: this.simulation_data.calculator_newpervmco2,
                color: '#ea3946',
                stack: "vmnew",
                pointWidth: 10,
                pointPadding: 0,
            }, false);

            // Hide chart loading
            this.chart.hideLoading();
            this.chart2.hideLoading();

            // Redraw
            this.chart.redraw();
            this.chart2.redraw();

            // Reflow
            this.chart.reflow();
            this.chart2.reflow();

        }, 300);
    }

    /**
     * 
     */
    switchGraph(): void {
        setTimeout(() => {
            if (!this.show_best) {
                this.showGraphsDetails();
            } else {
                this.showGraphBest();
            }
        });
    }

    /**
     * Display DC optimization modal
     */
    displayModalOptimization(): void {
        this.optimization_modal = true;

        setTimeout(() => {
            if (this.optimization_json != null) {

                let current_kwh = Number(this.optimization_json.OLDKWH).toFixed(0);
                let new_kwh = Number(this.optimization_json.KWH).toFixed(0);

                if (Number(current_kwh) > Number(new_kwh)) {
                    document.getElementById('greenit-prediction-current').innerHTML =
                        '<b style="color:red">' + Intl.NumberFormat().format(Number(current_kwh)) + '</b>';
                    document.getElementById('greenit-prediction-new').innerHTML =
                        '<b style="color:green">' + Intl.NumberFormat().format(Number(new_kwh)) + '</b>';
                } else {
                    document.getElementById('greenit-prediction-current').innerHTML =
                        '<b style="color:green">' + Intl.NumberFormat().format(Number(current_kwh)) +
                        '</b>';
                    document.getElementById('greenit-prediction-new').innerHTML = '<b style="color:red">' +
                        Intl.NumberFormat().format(Number(new_kwh)) + '</b>';
                }

                this.optimization_current_servers = this.optimization_json.CURRENT_SERVERS_NB;
                this.optimization_new_servers = this.optimization_json.OPTIMIZE_SERVERS_NB;

            }
        });
    }

    /**
     * 
     */
    displayModalEfficiency(): void {
        this.efficiency_modal = true;
    }

    /**
     * 
     */
    exportEfficiency() {
        let res = ["NAME", "CEE", "TEE", "ANNUAL kWh", "ANNUAL COST", "ANNUAL CO2 (kg CO2eq)",
            "LAST 24H POWER AVG (W), RENEWAL SERVER LIFESPAN (years)"
        ].join(
            ',') + '\n';
        res += Object.values < any > (this.efficiency_data).map(data => [data.name, data.cee, Number(data.tee)
            .toFixed(2), Number(data.wh / 1000).toFixed(2), Number(data.cost).toFixed(2),
            Number(data.co2).toFixed(2), Number(data.power).toFixed(2), Number(data.calculator_bestindex +
                1)
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'greenit_efficiency.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    /**
     * 
     */
    exportOptimization() {
        let res = ["NEW", "NEW SERVER OR ACTUAL", "CURRENT ANNUAL KWH", "NEW ANNUAL KWH (ESTIMATION)",
            "LIST OF REPLACED SERVERS"
        ].join(',') + '\n';
        res += Object.values < any > (this.optimization_json.SERVERS).map(data => [data.NEWFLAG, data.NAME, Number(
            data.OLDKWH).toFixed(0), Number(data.KWH).toFixed(0), data.OLDSERVERS.split(',').join(
            ' - ')].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'greenit_optimization.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }
}
