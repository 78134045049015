import {
    Component,
    EventEmitter,
    HostListener,
    OnInit
} from '@angular/core';
import * as d3 from 'd3';
import {
    JsonloaderService,
    ShareService
} from "@app/services";
import {
    Message
} from '@app/model';
import {
    Subscription
} from 'rxjs';
import {
    first
} from 'rxjs/operators';


@Component({
    selector: 'app-dcview',
    templateUrl: './dcview.component.html',
    styleUrls: ['./dcview.component.css']
})
export class DcviewComponent implements OnInit {

    message: Message;

    isDcscope = true;
    isCo2scope = false;

    data = {};

    svg;
    height = 1024;
    width = 768;
    margin = 20;

    loading = false;

    availableMetrics = [{
        "name": "cpu",
        "label": "cpu consumption",
        "json_property": "cpu"
    }, {
        "name": "ram",
        "label": "ram consumption",
        "json_property": "ram"
    }, {
        "name": "storage",
        "label": "storage consumption",
        "json_property": "sto"
    }, {
        "name": "power",
        "label": "power consumption",
        "json_property": "power"
    }]

    selectedMetric = "cpu"

    displayedPath = ["Infrastructure"];
    secondTableShouldChange = new EventEmitter();

    listedVms = [];

    jsonLoaderService;

    json_subscription: Subscription;

    constructor(jsonLoaderService: JsonloaderService, private message_svc: ShareService) {
        this.jsonLoaderService = jsonLoaderService;
    }

    ngOnInit(): void {

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;

        if (this.isCo2scope)
            this.selectedMetric = "power";

        setTimeout(() => {
            this.refreshData();

            window.onresize = () => {
                this.eraseTreeMap();
                this.createTreeMap();
            };
        }, 100);

        // Ensure that filter changes are taken into account
        this.json_subscription = this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
            this.refreshData();
            this.eraseTreeMap();
            this.createTreeMap();
        });
    }

    /**
     * 
     */
    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscriptions
        if (this.json_subscription != undefined) {
            this.json_subscription.unsubscribe();
        }
    }


    resetNavigation() {
        this.displayedPath = ["Infrastructure"];
        this.refreshData();
    }

    refreshData() {
        this.jsonLoaderService.currentJsonSubject.pipe(first()).subscribe(json => {
            let targetedJsonData;
            if (this.selectedMetric != "storage") {
                targetedJsonData = json.dcviewTreeData;
            } else {
                targetedJsonData = json.dcviewTreeStorageData;
            }

            if (targetedJsonData) {
                let dcviewTreeDataCopy = JSON.parse(JSON.stringify(targetedJsonData));
                this.data = this.processData(dcviewTreeDataCopy);
                this.createTreeMap();
            }
        })
    }

    selectMetric(metric) {
        let previousMetric = this.selectedMetric;
        this.selectedMetric = metric;

        if (previousMetric == "storage" || this.selectedMetric == "storage") {
            this.resetNavigation();
        }

        this.refreshData();
    }

    processData(data) {
        let selectedMetric = "cpu";

        function color(data) {
            if (data.type == "ROOT" || data.type == "CLUSTER") {
                return "white";
            }
            if (data.type == "VM") {
                return "grey";
            }
            return "black";
        }

        let addAggregatedData = (data, selectedMetric, recursionLevel = 0, parentPath = []) => {
            let currentPath = parentPath.concat(data.name);
            data.recursionLevel = recursionLevel
            data.textColor = color(data);
            data.path = currentPath;
            data.children.map((c) => c.parent = data);
            data.children = data.children.map((c) => addAggregatedData(c, selectedMetric, recursionLevel + 1,
                currentPath));
            data.sumChildrenValues = data.children.map((c) => c.value).reduce((a, b) => a + b, 0);
            data.labelled_name = data.name;

            if (data.type == "VM") {
                let propertyName = this.availableMetrics.filter((m) => m.name == this.selectedMetric)[0][
                    "json_property"
                ];
                data.value = data[propertyName];
            }

            if (data.type == "ROOT") {
                data.labelled_name = "Infrastructure";
            }

            let desiredServerMinSize = 15;
            if (data.type == "SERVER" && data.sumChildrenValues <= desiredServerMinSize) {
                if (data.sumChildrenValues > 0) {
                    data.value = desiredServerMinSize - data.sumChildrenValues;
                    if (data.value > 0.66 * desiredServerMinSize) {
                        let ratio = (desiredServerMinSize - data.value) / data.sumChildrenValues;
                        data.children.map((c) => c.value = ratio * c.value);
                        data.sumChildrenValues = data.children.map((c) => c.value).reduce((a, b) => a + b, 0);
                        data.value = desiredServerMinSize - data.sumChildrenValues;
                    }
                } else {
                    data.value = desiredServerMinSize;
                }
            }

            if (data.type == "VM") {
                data.labelled_name = "";
            }

            if (data.type == "VM") {
                let vmObject = {
                    name: data.name,
                    cpu: data.cpu,
                    ram: data.ram,
                    storage: data.sto,
                    power: data.power,
                    uuid: data.uuid
                };
                if (this.selectedMetric == "storage") {
                    vmObject["datastore"] = data.parent.name;
                    vmObject["datastore_path"] = data.parent.path;
                } else {
                    vmObject["server"] = data.parent.name;
                    vmObject["server_path"] = data.parent.path;
                    vmObject["cluster"] = data.parent.parent.name;
                    vmObject["cluster_path"] = data.parent.parent.path;
                    vmObject["datacenter"] = data.parent.parent.parent.name;
                    vmObject["datacenter_path"] = data.parent.parent.parent.path;
                }
                this.listedVms.push(vmObject);
            }
            return data;
        }

        function filterOnlyDisplayedData(data, path) {
            let firstPart = path[0];
            if (firstPart == data.name || (firstPart == "Infrastructure" && data.name == "root")) {
                let nextPath = path.slice(1)
                if (nextPath.length > 0) {
                    let newChildren = data.children
                        .map((c) => filterOnlyDisplayedData(c, nextPath))
                        .filter((p) => p != null);
                    data.children = newChildren;
                }
                return data
            } else {
                return null;
            }
        }

        this.listedVms = [];
        let selectedResult = filterOnlyDisplayedData(data, this.displayedPath);
        let completedResult = addAggregatedData(selectedResult, selectedMetric);
        // Send an event that forces the filters of the datatable to refresh.
        // The event is triggered with 1000ms of delay, to give some time to
        // the datatables to update correctly.
        setTimeout(() => {
            this.secondTableShouldChange.emit(this.listedVms);
        }, 2000);
        return completedResult;
    }

    changeSelection(newPath) {
        this.displayedPath = newPath;
        this.displayedPath[0] = "Infrastructure";
        this.refreshData();
    }

    changeSelectionFromTopMenu(lastElementPath) {
        let indexOfLastElementPath = this.displayedPath.indexOf(lastElementPath);
        this.changeSelection(this.displayedPath.slice(0, indexOfLastElementPath + 1));
    }

    createTreeMap(): void {
        d3.select('div#divSvg').select("svg").remove();
        this.svg = d3.select('div#divSvg')
            .append('svg')
            .attr('width', "100%")
            .attr('height', "100%")
            .append('g')
            .attr('id', 'graph_svg');
        this.refreshTreeMap();
    }

    eraseTreeMap(): void {
        d3.select('div#divSvg')
            .selectAll('*')
            .remove();
    }

    refreshTreeMap(): void {
        let svgHeight = document.getElementById("divSvg").clientHeight - 40;
        let svgWidth = document.getElementById("divSvg").clientWidth;

        let treemap = data => d3.treemap()
            .size([svgWidth, svgHeight])
            .paddingOuter(3)
            .paddingTop(19)
            .paddingInner(1)
            .round(true)
            (d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value));

        const root = treemap(this.data);

        const svg = d3.select('g#graph_svg');

        function roundedSize(node) {
            if (node.data.type == "VM") {
                return 0;
            }
            return 5;
        }

        let colors;
        if (this.selectedMetric == "storage") {
            colors = [
                "#0C67AD",
                "#BADFEA",
                "hsl(198, 83%, 94%)"
            ]
        } else {
            colors = [
                "#094789",
                "#3C9FD0",
                "#0C67AD",
                "#BADFEA",
                "hsl(198, 83%, 94%)"
            ]
        }
        let format = d3.format(",d");

        const node = svg.selectAll("g")
            .data(d3.group(root, d => d.height))
            .join("g")
            .selectAll("g")
            .data(d => d[1])
            .join("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`);

        node.append("title")
            .text(d => `${d.data["name"]}`);

        let nextId = 0;
        let generateUid = (str) => {
            return `id-${str}-${nextId++}`
        }

        // @ts-ignore
        node.append("rect")
            .attr("id", d => (d["nodeUid"] = generateUid("node")))
            // @ts-ignore
            .attr("fill", d => colors[d.data.recursionLevel])
            .attr("rx", d => roundedSize(d))
            .attr("ry", d => roundedSize(d))
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0);

        node.append("clipPath")
            .attr("id", d => (d["clipUid"] = generateUid("clip")))
            .append("use")
            .attr("xlink:href", d => `#${d["nodeUid"]}`);

        // @ts-ignore
        node.append("text")
            .style("max-width", (d, i, nodes) => d.x1 - d.x0 - 10)
            .style("max-height", (d, i, nodes) => d.y1 - d.y0 - 10)
            .style("overflow", "hidden")
            // @ts-ignore
            .attr("fill", (d, i, nodes) => d.data.textColor)
            .attr("clip-path", d => d["clipUid"])
            .selectAll("tspan")
            // @ts-ignore
            .data((d, i, nodes) => [d.data.labelled_name])
            .join("tspan")
            .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
            .attr("font-size", (d, i, nodes) => "8px")
            .text(d => d);

        // @ts-ignore
        node
            .on("click", (d, i) => {
                // @ts-ignore
                this.changeSelection(i.data.path);
            })
            .on("mouseover", function(d, i) {
                d3.select(this).select("rect").style('opacity', '0.66');
                d3.select(this).style("cursor", "pointer");
            })
            .on("mouseout", function(d, i) {
                d3.select(this).select("rect").style('opacity', '1.0');
                d3.select(this).style("cursor", "default");
            })

        // @ts-ignore
        node.filter(d => d.children).selectAll("tspan")
            .attr("dx", 3)
            .attr("y", 13);

        node.filter(d => !d.children).selectAll("tspan")
            .attr("x", 3)
            // @ts-ignore
            .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`);
    }
}

