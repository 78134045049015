export interface TimePercent {
    idle: number;
    lazy: number;
    normal: number;
    oversized: number;
    undersized: number;
    busy: number;
}

export interface RecoVm {
    name: string;
    uuid: string;
    type: string;
    server: string;
    date: string;
    behavior: string;
    threshold: number;
    noise: number;
    state: string;
    reco_tools: string;
    reco_cpu: number;
    reco_ram: number;
    reco_ram_type: string;
    reco_behavior: string;
    cpu_gain: number;
    comment: string;
    avg_cpu_cons: number;
    max_cpu_cons: number;
    avg_cpu_rdy: number;
    max_cpu_rdy: number;
    avg_cpu_cstp: number;
    max_cpu_cstp: number;
    cpu_saved: number;
    ram_gain: number;
    ram_capacity: string;
    ram_reserved: string;
    avg_ram_cons: number;
    max_ram_cons: number;
    avg_ram_act: number;
    max_ram_act: number;
    ram_saved: number;
    disk_saved: number;
    time_percent: TimePercent;
}

export interface RecoServer {
    name: string;
    uuid: string;
    type: string;
    tools_install: number;
    tools_update: number;
    tools_enable: number;
    vm_nb: number;
    avg_cpu_cons: number;
    avg_ram_cons: number;
    over_cpu: number;
    over_ram: number;
    isStop: boolean;
}

export enum PowershellResource {
    CPU = "CPU",
        RAM = "RAM"
}
