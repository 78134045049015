import {Component, EventEmitter, OnInit, Output} from '@angular/core';
// import Highcharts from "highcharts/highmaps";
import * as Highcharts from "highcharts/highmaps";
import * as worldMap from "@highcharts/map-collection/custom/world.geo.json";
import {
  FailureGetInfrastructureVersionsBetweenResponse,
  GetClustersWithFiltersResponse,
  GetInfrastructureVersionsBetweenResponse,
  InfrastructureVersion,
  IpDescription,
  TroubleshootingData,
  NetscopeService,
  RequestTroubleshootingDataResponse,
  SourceOrDestinationDescription,
  SuccessGetClustersWithFiltersResponse, SuccessGetInfrastructureVersionsBetweenResponse,
  SuccessRequestTroubleshootingDataResponse, TemporalParameter
} from "@app/services";
import {
  FlowDetail,
  FlowRessourceObject,
  SharedFilterOptions
} from "@app/netscope/netscope-flows-datagrid/netscope-flows-datagrid.component";
import {ClrDatagridSortOrder} from "@clr/angular";
import {NetscopeSupervisionComponent} from "@app/netscope/netscope-supervision/netscope-supervision.component";
import {TranslocoService} from "@ngneat/transloco";
import {NetscopeUtils} from "@app/netscope/netscope-utils";

let allCountriesCodes = [
  "fo",
  "um",
  "us",
  "jp",
  "sc",
  "in",
  "fr",
  "fm",
  "cn",
  "pt",
  "sw",
  "sh",
  "br",
  "ki",
  "ph",
  "mx",
  "es",
  "bu",
  "mv",
  "sp",
  "gb",
  "gr",
  "as",
  "dk",
  "gl",
  "gu",
  "mp",
  "pr",
  "vi",
  "ca",
  "st",
  "cv",
  "dm",
  "nl",
  "jm",
  "ws",
  "om",
  "vc",
  "tr",
  "bd",
  "lc",
  "nr",
  "no",
  "kn",
  "bh",
  "to",
  "fi",
  "id",
  "mu",
  "se",
  "tt",
  "my",
  "pa",
  "pw",
  "tv",
  "mh",
  "cl",
  "th",
  "gd",
  "ee",
  "ag",
  "tw",
  "bb",
  "it",
  "mt",
  "vu",
  "sg",
  "cy",
  "lk",
  "km",
  "fj",
  "ru",
  "va",
  "sm",
  "kz",
  "az",
  "tj",
  "ls",
  "uz",
  "ma",
  "co",
  "tl",
  "tz",
  "ar",
  "sa",
  "pk",
  "ye",
  "ae",
  "ke",
  "pe",
  "do",
  "ht",
  "pg",
  "ao",
  "kh",
  "vn",
  "mz",
  "cr",
  "bj",
  "ng",
  "ir",
  "sv",
  "sl",
  "gw",
  "hr",
  "bz",
  "za",
  "cf",
  "sd",
  "cd",
  "kw",
  "de",
  "be",
  "ie",
  "kp",
  "kr",
  "gy",
  "hn",
  "mm",
  "ga",
  "gq",
  "ni",
  "lv",
  "ug",
  "mw",
  "am",
  "sx",
  "tm",
  "zm",
  "nc",
  "mr",
  "dz",
  "lt",
  "et",
  "er",
  "gh",
  "si",
  "gt",
  "ba",
  "jo",
  "sy",
  "mc",
  "al",
  "uy",
  "cnm",
  "mn",
  "rw",
  "so",
  "bo",
  "cm",
  "cg",
  "eh",
  "rs",
  "me",
  "tg",
  "la",
  "af",
  "ua",
  "sk",
  "jk",
  "bg",
  "qa",
  "li",
  "at",
  "sz",
  "hu",
  "ro",
  "ne",
  "lu",
  "ad",
  "ci",
  "lr",
  "bn",
  "iq",
  "ge",
  "gm",
  "ch",
  "td",
  "kv",
  "lb",
  "dj",
  "bi",
  "sr",
  "il",
  "ml",
  "sn",
  "gn",
  "zw",
  "pl",
  "mk",
  "py",
  "by",
  "cz",
  "bf",
  "na",
  "ly",
  "tn",
  "bt",
  "md",
  "ss",
  "bw",
  "bs",
  "nz",
  "cu",
  "ec",
  "au",
  "ve",
  "sb",
  "mg",
  "is",
  "eg",
  "kg",
  "np",
];

class VmWithPortsAccessedFromOutsideInfo {
  name: string;
  uuid: string;
  ports_count: number;
  unique_ips: string[];

  public constructor(uuid: string, name: string, ports_count: number, unique_ips: string[]) {
    this.uuid = uuid;
    this.name = name;
    this.ports_count = ports_count;
    this.unique_ips = unique_ips;
  }
}

@Component({
  selector: 'app-netscope-outside-flows',
  templateUrl: './netscope-outside-flows.component.html',
  styleUrls: ['./netscope-outside-flows.component.css']
})
export class NetscopeOutsideFlowsComponent implements OnInit {

  @Output()
  loadingStateEmitter = new EventEmitter<boolean>();
  @Output()
  errorStateEmitter = new EventEmitter<boolean>();

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  chartData = [{ code3: "ABW", z: 105 }, { code3: "AFG", z: 35530 }];

  chartOptions: Highcharts.Options = {
    chart: {
      map: worldMap
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
          // 'Source map: <a href="http://code.highcharts.com/mapdata/custom/world.js">World, Miller projection, medium resolution</a>'
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox"
      }
    },
    legend: {
      enabled: true
    },
    colorAxis: {
      min: 0
    },
    series: [
      {
        type: "map",
        name: "Random data",
        states: {
          hover: {
            color: "#BADA55"
          }
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}"
        },
        allAreas: false,
        data: [],
        borderColor: 'black',
        borderWidth: 0.2,
      }
    ]
  };
  updateCountriesMapFlag = false;
  allIpsAndResourcesDescription: TroubleshootingData;
  showFlowModal = false;
  selectFlowDetailsModalTitle = "";
  allFlowsWithOutside = [];
  selectFlowDetails = [];
  vmsWithFlowsOutside = [];
  countriesWithFlowsOutside = [];
  networksWithFlowsOutside = [];
  vmsWithPortsAccessedFromOutside: VmWithPortsAccessedFromOutsideInfo[] = [];
  vmsCountWithManyOpenPorts = 0;
  vmsCountWithFewOpenPorts = 0;

  currentTimeIntervalDisplayed = {
    startDate: "",
    endDate: "",
  };
  temporalParameter: TemporalParameter;

  shouldUpdateFilters: EventEmitter < any > = new EventEmitter();

  infrastructureVersion: InfrastructureVersion;
  failureMode = false;
  isLoading = false;

  outsideFlowsTraffic = 0;
  vmsCountWithOutsideFlows = 0;
  outsideFlowsCount = 0;

  sharedOptions: SharedFilterOptions = {
    filterIsActive: false,
    selectedResources: []
  }

  overviewSelection = "vms";

  descSort = ClrDatagridSortOrder.DESC;

  constructor(public netscopeService: NetscopeService, public translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData = () => {
    this.isLoading = true;
    this.loadingStateEmitter.emit(true);

    this.netscopeService.requestTroubleshootingData().subscribe((response: RequestTroubleshootingDataResponse) => {
      if (response instanceof SuccessRequestTroubleshootingDataResponse) {
        this.allIpsAndResourcesDescription = response.troubleshootingData;
      }

      let startDate = this.allIpsAndResourcesDescription.params.start_time;
      let endDate = this.allIpsAndResourcesDescription.params.end_time;

      this.currentTimeIntervalDisplayed = {
        startDate: startDate,
        endDate: endDate
      }

      // Set temporal parameters
      this.temporalParameter = new TemporalParameter(startDate, endDate, "daily");

      this.netscopeService.getInfrastructureVersionsBetween(startDate, endDate, [], [], true).subscribe((infrastructureVersionResponse: GetInfrastructureVersionsBetweenResponse) => {
        if (infrastructureVersionResponse instanceof FailureGetInfrastructureVersionsBetweenResponse) {
          this.failureMode = true;
          this.errorStateEmitter.emit(true);
          this.isLoading = false;
          this.loadingStateEmitter.emit(false);
          return;
        }

        // let infrastructureVersion;
        if (infrastructureVersionResponse instanceof SuccessGetInfrastructureVersionsBetweenResponse) {
          this.infrastructureVersion = infrastructureVersionResponse.infrastructureVersionsBetween;
        }

        this.loadingStateEmitter.emit(false);

        this.reloadUi();
        this.isLoading = false;
      });
    });
  }

  reloadUi = () => {
    // Ranking countries
    let countriesRanking = new Map<string, number>();

    for (let countryCode of allCountriesCodes) {
      countriesRanking.set(countryCode, 0);
    }

    for (let tuple7 of this.allIpsAndResourcesDescription.all_ips) {
      let srcDstDescription: SourceOrDestinationDescription = tuple7[6];
      if (srcDstDescription != undefined && srcDstDescription.source !== null && srcDstDescription.source !== undefined) {
        let countryCode = srcDstDescription.source.country_code.toLowerCase();
        if (!countriesRanking.has(countryCode)) {
          countriesRanking.set(countryCode, 0);
        }
        countriesRanking.set(countryCode, countriesRanking.get(countryCode) + 1);
      }
      if (srcDstDescription != undefined && srcDstDescription.destination !== null && srcDstDescription.destination !== undefined) {
        let countryCode = srcDstDescription.destination.country_code.toLowerCase();
        if (!countriesRanking.has(countryCode)) {
          countriesRanking.set(countryCode, 0);
        }
        countriesRanking.set(countryCode, countriesRanking.get(countryCode) + 1);
      }
    }

    // Change the data for the worldmap and update the graph
    this.chartOptions.series = [
      {
        type: "map",
        name: "Random data",
        states: {
          hover: {
            color: "#BADA55"
          }
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}"
        },
        point: {
          events: {
            click: (e) => {
              e.preventDefault();
              this.filterFlowsForModal(`Flows with ${e.point.name}`, (o) => o?.country_code?.toLowerCase() == e.point.options["hc-key"]);
            }
          }
        },
        allAreas: false,
        data: [...countriesRanking.entries()]
      }
    ];
    this.updateCountriesMapFlag = true;

    // Create list of all flows
    this.allFlowsWithOutside = this.allIpsAndResourcesDescription.all_ips
        .filter((ipTuple) => ipTuple[6] != undefined)
        .map((ipTuple) => this._IpTupleToFlowDetail(ipTuple));

    // Create a list of VMs with flows outside
    let flattenAllRessources = this.allFlowsWithOutside
        .map((flow) => [
          [flow.source, flow.exchanged_bytes, flow.exchanged_packets],
          [flow.target, flow.exchanged_bytes, flow.exchanged_packets]
        ])
        .reduce((accumulator, tuple2) => accumulator.concat(tuple2), []);

    let outsideFlowsOfVms = flattenAllRessources
        .filter((t3) => t3[0]["type"] == "vm");

    // Create a list of VMs with their exchanged bytes
    let mapVmsFlows = new Map<string, {exchanged_bytes: number; exchanged_packets: number; uuid: string; name: string }>();

    for (let flow of outsideFlowsOfVms) {
      let flowUuid = flow[0].uuid;
      let flowName = flow[0].name;
      if (! mapVmsFlows.has(flowUuid)) {
        mapVmsFlows.set(flowUuid, {
          name: flowName,
          uuid: flowUuid,
          exchanged_bytes: 0,
          exchanged_packets: 0
        });
      }
      mapVmsFlows.get(flowUuid).exchanged_bytes += flow[1];
      mapVmsFlows.get(flowUuid).exchanged_packets += flow[2];
    }

    this.vmsWithFlowsOutside = [...mapVmsFlows.values()];

    // Create a list of countries with their exchanged bytes
    let mapCountriesFlows = new Map<string, {exchanged_bytes: number; exchanged_packets: number; country: string;}>();

    for (let flow of this.allFlowsWithOutside) {
      // First take both the src and dst countries
      let countries = [flow.source_country, flow.destination_country];
      // If src and dst are the same, keep one to prevent to double count the flow
      if (flow.source_country == flow.destination_country) {
        countries = [flow.source_country];
      } else {
        // If a country is known, remove any "Unknown" country to prevent to count a flow as unknown
        countries = countries.filter((c) => c != "Unknown");
      }
      for (let country of countries) {
        if (! mapCountriesFlows.has(country)) {
          mapCountriesFlows.set(country, {
            country: country,
            exchanged_bytes: 0,
            exchanged_packets: 0
          });
        }
        mapCountriesFlows.get(country).exchanged_bytes += flow.exchanged_bytes;
        mapCountriesFlows.get(country).exchanged_packets += flow.exchanged_packets;
      }
    }

    this.countriesWithFlowsOutside = [...mapCountriesFlows.values()];

    // Create a list of networks with their exchanged bytes
    let mapNetworkFlows = new Map<string, {exchanged_bytes: number; exchanged_packets: number; network: string; }>();

    for (let flow of this.allFlowsWithOutside) {
      // First take both the src and dst asns
      let asns = [flow.source_asn, flow.destination_asn];
      // If src and dst are the same, keep one to prevent to double count the flow
      if (flow.source_asn == flow.destination_asn) {
        asns = [flow.source_asn];
      } else {
        // If a asn is known, remove any "Unknown" asn to prevent to count a flow as unknown
        asns = asns.filter((c) => c != "Unknown");
      }
      for (let network of asns) {
        if (! mapNetworkFlows.has(network)) {
          mapNetworkFlows.set(network, {
            network: network,
            exchanged_bytes: 0,
            exchanged_packets: 0
          });
        }
        mapNetworkFlows.get(network).exchanged_bytes += flow.exchanged_bytes;
        mapNetworkFlows.get(network).exchanged_packets += flow.exchanged_packets;
      }
    }

    this.networksWithFlowsOutside = [...mapNetworkFlows.values()];

    // Update metrics that are displayed in cards under the world map
    this.outsideFlowsTraffic = this.vmsWithFlowsOutside.reduce((accumulator, vm) => accumulator + vm.exchanged_bytes, 0);
    this.vmsCountWithOutsideFlows = this.vmsWithFlowsOutside.length;
    this.outsideFlowsCount = outsideFlowsOfVms.length;

    // Update list of vms with ports accessed from outside
    this.vmsWithPortsAccessedFromOutside = this.allIpsAndResourcesDescription.vms_with_accessible_ports
        .map((o) => {
          let uniqueIps = [];
          let ipsList:  string[][] = Object.values(o.ports);
          for (let ips of ipsList) {
            for (let ip of ips) {
              if (uniqueIps.indexOf(ip) == -1) {
                uniqueIps.push(ip)
              }
            }
          }
          console.log("plop");
          return new VmWithPortsAccessedFromOutsideInfo(o.description.uuid, o.description.name, Object.values(o.ports).length, uniqueIps)
        });
    this.vmsCountWithManyOpenPorts = this.vmsWithPortsAccessedFromOutside.filter((o) => o["ports_count"] >= 10).length;
    this.vmsCountWithFewOpenPorts = this.vmsWithPortsAccessedFromOutside.filter((o) => o["ports_count"] < 10).length;
  }

  _IpTupleToFlowDetail = (ipTuple) => {
    let [srcIp, dstIp, srcUuid, dstUuid, exchangedBytes, exchangedPackets, detailedDescription] = ipTuple;
    let newFlowDetail = new FlowDetail();
    newFlowDetail.source = new FlowRessourceObject();
    newFlowDetail.target = new FlowRessourceObject();
    newFlowDetail.src_address = srcIp;
    newFlowDetail.source.address = srcIp;
    newFlowDetail.source.uuid = `vim.UnknownIpaddress:${srcIp}`;
    newFlowDetail.source.type = "unknown_ip";
    newFlowDetail.source.name = srcIp;
    newFlowDetail.source.is_in_filter = true;
    newFlowDetail.dst_address = dstIp;
    newFlowDetail.target.address = dstIp;
    newFlowDetail.target.uuid = `vim.UnknownIpaddress:${dstIp}`;
    newFlowDetail.target.type = "unknown_ip";
    newFlowDetail.target.name = dstIp;
    newFlowDetail.target.is_in_filter = true;
    newFlowDetail.exchanged_bytes = exchangedBytes;
    newFlowDetail.exchanged_packets = exchangedPackets;
    newFlowDetail.source_country = "Unknown";
    newFlowDetail.source_asn = "Unknown";
    newFlowDetail.destination_country = "Unknown";
    newFlowDetail.destination_asn = "Unknown";

    if (srcUuid !== "unknown") {
      if (this.infrastructureVersion.topology.node_mapping[srcIp] != undefined) {
        let sourceObj = this.infrastructureVersion.topology.node_mapping[srcIp];
        newFlowDetail.source.name = sourceObj.name;
        newFlowDetail.source.type = sourceObj.type;
        newFlowDetail.source.uuid = sourceObj.uuid;
      }
    }
    if (detailedDescription != undefined && detailedDescription.source != undefined) {
      newFlowDetail.source_country = detailedDescription.source.country;

      if (detailedDescription.source.asns != undefined && detailedDescription.source.asns.length > 0) {
        newFlowDetail.source_asn = detailedDescription.source.asns
            .map((asn) => asn.asn_description)
            .join(", ");
      }
    }

    if (dstUuid !== "unknown") {
      if (this.infrastructureVersion.topology.node_mapping[dstIp] != undefined) {
        let dstObj = this.infrastructureVersion.topology.node_mapping[dstIp];
        newFlowDetail.target.name = dstObj.name;
        newFlowDetail.target.type = dstObj.type;
        newFlowDetail.target.uuid = dstObj.uuid;
      }
    }

    if (detailedDescription != undefined && detailedDescription.destination != undefined) {
      newFlowDetail.destination_country = detailedDescription.destination.country;

      if (detailedDescription.destination.asns != undefined && detailedDescription.destination.asns.length > 0) {
        newFlowDetail.destination_asn = detailedDescription.destination.asns
            .map((asn) => asn.asn_description)
            .join(", ");
      }
    }

    return newFlowDetail;
  }

  filterFlowsForModal = (label: string, isValid: (any) => boolean, uniqueIps: string[] = undefined, allMustBeTrue=false) => {

    let matchingIps = this.allIpsAndResourcesDescription.all_ips
        .filter((row) => row[6] != undefined)
        .filter((row) => allMustBeTrue || (isValid({uuid: row[2]}) || isValid({uuid: row[3]}) || isValid(row[6]?.source) || isValid(row[6]?.destination)))
        .filter((row) => !allMustBeTrue || (isValid({uuid: row[2]}) && isValid({uuid: row[3]}) && isValid(row[6]?.source) && isValid(row[6]?.destination)));

    this.selectFlowDetails = [];
    for (let matchingIp of matchingIps) {
      let newFlowDetail = this._IpTupleToFlowDetail(matchingIp)
      this.selectFlowDetails.push(newFlowDetail);
    }

    if (uniqueIps != undefined) {
      this.selectFlowDetails = this.selectFlowDetails
          .filter((flow) => uniqueIps.indexOf(flow.src_address) != -1 || uniqueIps.indexOf(flow.dst_address) != -1);
    }

    this.selectFlowDetails.sort((t1, t2) => t2.exchanged_bytes - t1.exchanged_bytes);

    this.selectFlowDetailsModalTitle = label;
    this.showFlowModal = true;
  }

  filterVmsAndShowModal = (vmUuid: string) => {
    let label = this.translocoService.translate("dcnetscope.supervision.troubleshooting.flows_with_outside_ips_label");
    this.filterFlowsForModal(label, (o) => o?.uuid == vmUuid);
  }

  filterCountryAndShowModal = (country: string) => {
    let label = this.translocoService.translate("dcnetscope.supervision.troubleshooting.flows_with_label", { variable: country })
    if (country == "Unknown") {
      this.filterFlowsForModal(label, (o) => o?.country == undefined, undefined, true);
    } else {
      this.filterFlowsForModal(label, (o) => o?.country == country);
    }
  }

  filterNetworkProviderAndShowModal = (networkProvider: string) => {
    let label = this.translocoService.translate("dcnetscope.supervision.troubleshooting.flows_with_label", { variable: networkProvider })
    if (networkProvider == "Unknown") {
      this.filterFlowsForModal(label, (o) => o?.asns == undefined ||  o?.asns?.every((asn) => asn.asn_description == undefined), undefined, true);
    } else {
      this.filterFlowsForModal(label, (o) => o?.asns?.some((asn) => asn.asn_description == networkProvider));
    }
  }

  filterVmsWithOpenPortsAndShowModal = (vmUuid: string, uniqueIps: string[]) => {
    let label = this.translocoService.translate("dcnetscope.supervision.troubleshooting.flows_with_open_ports_label");
    this.filterFlowsForModal("Flows with ports open on Internet", (o) => o?.uuid == vmUuid, uniqueIps);
  }

  callExportVmsWithOusideFlowsCsv = () => {

  }

  exportCSV = () => {
    let csvHeader = "Source, Destination, SourceCountry, DestinationCountry, SourceNetwork, DestinationNetwork, ExchangedBytes, TotalPackets"
    let csvContent = csvHeader + "\n";
    for (let link of this.allFlowsWithOutside) {
      let sourceName = link.source.name;
      let targetName = link.target.name;
      let lineValue =
          `${sourceName}, ${targetName}, ${link.source_country}, ${link.destination_country}, ${link.source_asn}, ${link.destination_asn}, ${link.exchanged_bytes}, ${link.exchanged_packets}\n`;
      csvContent += lineValue;
    }

    let exportedFilename = 'netscope-external-flows.csv';
    let blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;'
    });
    // @ts-ignore
    if (navigator.msSaveBlob) { // IE 10+
      // @ts-ignore
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  protected readonly NetscopeUtils = NetscopeUtils;
}
