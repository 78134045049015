<nav class="subnav" style="padding-left:2rem" *ngIf="message.isNotLoginPage">
    <ul class="nav">
        <li class="nav-item">
            <clr-dropdown>
                <button class="btn btn-sm btn-link" clrDropdownTrigger>
                    <cds-icon shape="data-cluster"></cds-icon>&nbsp;
                    DC view
                    <cds-icon shape="angle" direction="down"></cds-icon>
                </button>
                <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                    <div aria-label="Dropdown header treemap" clrDropdownItem routerLink="./dcviews/treemap" routerLinkActive="active">
                        <cds-icon shape="grid-view"></cds-icon>
                        Tree Map
                    </div>
                    <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                    <div aria-label="Dropdown header flow" clrDropdownItem routerLink="./dcviews/flowdiag" routerLinkActive="active">
                        <cds-icon shape="shuffle"></cds-icon>
                        Flow Diagram
                    </div>
                </clr-dropdown-menu>
            </clr-dropdown>
        </li>
        <li class="nav-item">
            <clr-dropdown>
                <button class="btn btn-sm btn-link" clrDropdownTrigger>
                    <cds-icon shape="cluster"></cds-icon>&nbsp;
                    CLUSTER view
                    <cds-icon shape="angle" direction="down"></cds-icon>
                </button>
                <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                    <div aria-label="Dropdown header clrInformation" clrDropdownItem routerLink="./clusterviews/information" routerLinkActive="active">
                        <cds-icon shape="info-circle"></cds-icon>
                        Information
                    </div>
                    <div aria-label="Dropdown header clSynthesis" clrDropdownItem routerLink="./clusterviews/synthesis" routerLinkActive="active">
                        <cds-icon shape="details"></cds-icon>
                        Synthesis
                    </div>
                </clr-dropdown-menu>
            </clr-dropdown>
        </li>
        <li class="nav-item">
            <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <cds-icon shape="resource-pool"></cds-icon>&nbsp;
                        RES.POOL view
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header rpInformation" clrDropdownItem routerLink="./rpviews/information" routerLinkActive="active">
                            <cds-icon shape="info-circle"></cds-icon>
                            Information
                        </div>
                        <div aria-label="Dropdown header rpSynthesis" clrDropdownItem [disabled]="!rp_synth_show"
                             [routerLink]="rp_synth_show ? './rpviews/synthesis' : null" [routerLinkActive]="rp_synth_show ? 'active' : 'disable'">
                            <cds-icon shape="details"></cds-icon>
                            Synthesis
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <cds-icon shape="host"></cds-icon>&nbsp;
                        SERVER view
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header srvInfomartion" clrDropdownItem routerLink="./hostviews/information" routerLinkActive="active">
                            <cds-icon shape="info-circle"></cds-icon>
                            Information
                        </div>
                        <div aria-label="Dropdown header srvConsumption" clrDropdownItem routerLink="./hostviews/consumption" routerLinkActive="active">
                            <cds-icon shape="table"></cds-icon>
                            Consumption
                        </div>
                        <div aria-label="Dropdown header srvSynthesis" clrDropdownItem routerLink="./hostviews/synthesis" routerLinkActive="active">
                            <cds-icon shape="details"></cds-icon>
                            Synthesis
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <cds-icon shape="vm"></cds-icon>&nbsp;
                        VM view
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header vmInformation" clrDropdownItem routerLink="./vmviews/information" routerLinkActive="active">
                            <cds-icon shape="info-circle"></cds-icon>
                            Information
                        </div>
                        <div aria-label="Dropdown header vmConsumption" clrDropdownItem routerLink="./vmviews/consumption" routerLinkActive="active">
                            <cds-icon shape="table"></cds-icon>
                            Consumption
                        </div>
                        <div aria-label="Dropdown header vmSynthesis" clrDropdownItem routerLink="./vmviews/synthesis" routerLinkActive="active">
                            <cds-icon shape="details"></cds-icon>
                            Synthesis
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <cds-icon shape="storage"></cds-icon>&nbsp;
                        STORAGE view
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header dsStorage" clrDropdownItem routerLink="./storage-overview" routerLinkActive="active">
                            <cds-icon shape="storage"></cds-icon>
                            Datastore
                        </div>
                        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                        <div aria-label="Dropdown header vmStorage" clrDropdownItem routerLink="./storage-overview-vm" routerLinkActive="active">
                            <cds-icon shape="vm"></cds-icon>
                            Virtual machine
                        </div>
                        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
        					<div aria-label="Dropdown header vmStorage" clrDropdownItem routerLink="./storage-overview-snapshot" routerLinkActive="active">
                                <cds-icon shape="process-on-vm"></cds-icon>
							Snapshot
						</div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <cds-icon shape="search" size="20" class="is-info"></cds-icon>
            <form clrForm [formGroup]="searchbar" class="search-bar" style="margin-top:20px">
                <clr-datalist-container id="trm" style="flex-direction:column;margin-top:0">
                    <input clrDatalistInput #searchbarInput (ngModelChange)="submit($event)" placeholder="Search a cluster, a server, a vm or a datastore ..." name="Options" formControlName="item" size="50">
                    <datalist>
                        <option *ngFor="let item of items" [ngValue]="item.uuid">{{item.name}} - [{{item.type}}]</option>
                    </datalist>
                </clr-datalist-container>
            </form>
        </ul>
    </nav>
