import {
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    JsonloaderService,
    InfrastructureService,
    ShareService
} from '@app/services';

import {
    Evolution,
    HostBadge,
    HostPrediction,
    JSONTarget,
    Message,
    User
} from '@app/model';

import * as Highcharts from 'highcharts';

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';


@Component({
    selector: 'app-evolutionsynthesis',
    templateUrl: './evolutionsynthesis.component.html',
    styleUrls: ['./evolutionsynthesis.component.css']
})
export class EvolutionsynthesisComponent implements OnInit {

    message: Message;

    data_prediction: any;

    host_prediction: HostPrediction = {
        NBVM_CAP: 0,
        NBVM_CONS: 0,
        NBVM_CAPMAX: 0,
        VCPU_PERCENT: 0,
        VRAM_PERCENT: 0,
        CONSCPU_DATE: 0,
        CONSRAM_DATE: 0,
        CPU_DATE_SAT: '',
        RAM_DATE_SAT: '',
        RAM_RATIO: 0,
        CPU_RATIO: 0
    };

    host_badge: HostBadge = {
        cpu_commitment: 'badge-success',
        ram_commitment: 'badge-success',
        sto_commitment: 'badge-success',
        vm_off: 'badge-success addLink',
        vm_other: 'badge-success addLink',
        tools_te: 'badge-success addLink',
        tools_ti: 'badge-success addLink',
        tools_tu: 'badge-success addLink',
        nbvm_cap: false,
        nbvm_class: 'is-success',
        nbvm_capmax: false,
        nbvmmax_class: 'is-success',
        nbvm_cons: false,
        nbvmcons_class: 'is-success',
        cpu_sat: 'badge-success',
        ram_sat: 'badge-success'
    };

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    isReady: boolean = false;

    private entitySynth: any = [];

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private infra_svc: InfrastructureService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        let jt: JSONTarget = JSONTarget.HOSTS_PREDICTION;
        if (this.message.currentType == "SERVER") {
            this.entitySynth = this.message.hostSynth;
        } else if (this.message.currentType == "CLUSTER") {
            this.entitySynth = this.message.clusterSynth;
            jt = JSONTarget.CLUSTERS_PREDICTION;
        }
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, jt).subscribe(
            data => {
                this.data_prediction = data;
                this.buildEvolution();
            }
        );
    }

    private buildEvolution(): void {

        this.infra_svc.getHostEvolution(this.message.currentUuid, this.message.currentType).pipe(first()).subscribe(
            data => {
                this.loadEvolution(data);
            }, error => {
                console.log('no evolution data ' + this.message.currentUuid);
            }
        );
        this.getPrediction(this.message.currentUuid);
        if (this.host_prediction.NBVM_CAP <= (this.entitySynth.vms * 0.2)) {
            this.host_badge.nbvm_cap = true;
            this.host_badge.nbvm_class = 'is-warning';
        }
        if (this.host_prediction.NBVM_CAP <= (this.entitySynth.vms * 0.1)) {
            this.host_badge.nbvm_cap = true;
            this.host_badge.nbvm_class = 'is-error';
        }

        if (this.host_prediction.NBVM_CAPMAX <= (this.entitySynth.vms * 0.2)) {
            this.host_badge.nbvm_capmax = true;
            this.host_badge.nbvmmax_class = 'is-warning';
        }
        if (this.host_prediction.NBVM_CAPMAX <= (this.entitySynth.vms * 0.1)) {
            this.host_badge.nbvm_capmax = true;
            this.host_badge.nbvmmax_class = 'is-error';
        }

        if (this.host_prediction.NBVM_CONS <= (this.entitySynth.vms * 0.2)) {
            this.host_badge.nbvm_cons = true;
            this.host_badge.nbvmcons_class = 'is-warning';
        }
        if (this.host_prediction.NBVM_CONS <= (this.entitySynth.vms * 0.1)) {
            this.host_badge.nbvm_cons = true;
            this.host_badge.nbvmcons_class = 'is-error';
        }

        this.host_prediction.CPU_DATE_SAT = this.getSaturation(this.host_prediction.CONSCPU_DATE, 'cpu');
        this.host_prediction.RAM_DATE_SAT = this.getSaturation(this.host_prediction.CONSRAM_DATE, 'ram');
    }

    private getPrediction(uuid: string): void {
        for (var i in this.data_prediction) {
            if (this.data_prediction[i].UUID == uuid) {
                this.host_prediction.NBVM_CAP = this.data_prediction[i].NBVM_CAP;
                this.host_prediction.NBVM_CONS = this.data_prediction[i].NBVM_CONS;
                this.host_prediction.NBVM_CAPMAX = this.data_prediction[i].NBVM_CAPMAX;
                this.host_prediction.VCPU_PERCENT = this.data_prediction[i].VCPU_PERCENT;
                this.host_prediction.VRAM_PERCENT = this.data_prediction[i].VRAM_PERCENT;
                this.host_prediction.CONSCPU_DATE = this.data_prediction[i].CONSCPU_DATE;
                this.host_prediction.CONSRAM_DATE = this.data_prediction[i].CONSRAM_DATE;
                this.host_prediction.RAM_RATIO = this.data_prediction[i].RAM_RATIO;
                this.host_prediction.CPU_RATIO = this.data_prediction[i].CPU_RATIO;

            }
        }
    }

    private getSaturation(sat_date_unix: number, type: string): string {

        var date: string = '';
        let now = moment();
        const sat_date = moment.unix(sat_date_unix / 1000);
        if (sat_date.year() < now.year()) {
            date = 'the date has passed';
            if (type == "cpu")
                this.host_badge.cpu_sat = 'badge-danger';
            else
                this.host_badge.ram_sat = 'badge-danger';
        } else if (sat_date.year() == now.year()) {
            if ((sat_date.dayOfYear() - now.dayOfYear()) < 180) {
                date = sat_date.format('LL');
                if ((sat_date.dayOfYear() - now.dayOfYear()) < 30) {
                    if (type == "cpu")
                        this.host_badge.cpu_sat = 'badge-danger';
                    else
                        this.host_badge.ram_sat = 'badge-danger';
                } else {
                    if (type == "cpu")
                        this.host_badge.cpu_sat = 'badge-warning';
                    else
                        this.host_badge.ram_sat = 'badge-warning';
                }
            } else {
                date += 'over 6 months';
            }
        } else if (sat_date.year() > now.year()) {
            if ((sat_date.year() - now.year()) > 1) {
                date += 'over one year';
            } else {
                let days_remaining = 365 - now.dayOfYear();
                let days_past = sat_date.dayOfYear();
                if ((days_remaining + days_past) < 180)
                    date = sat_date.format('LL');
            }
        }

        return date;
    }

    private loadEvolution(data: any): void {

        var seriesOptions = [];
        var names = ['vms', 'vmrun'];
        var i = 0;
        names.forEach(name => {
            var counterData = [];
            for (var j = 0; j < data.length; j++) {
                counterData[j] = [];
                counterData[j][0] = parseInt(data[j].time);
                counterData[j][1] = parseInt(data[j][name]);
            }

            var legend_name = 'The total number of VM';
            if (name == "vmrun")
                legend_name = 'The running VM';

            seriesOptions[i] = {
                name: legend_name,
                cursor: 'pointer',
                data: counterData
            };

            i++;
        });

        this.callLineGraph(seriesOptions);
    }

    private callLineGraph(data: any): void {

        this.chartOptions = {
            chart: {
                type: 'line',
                height: '30%',
                backgroundColor: '#ffffff',
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        align: 'center',
                        y: -15
                    }
                },

            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            title: {
                text: 'Evolution of the number of VM',
                style: {
                    font: 'normal 12px arial',
                    color: 'grey'
                },
                align: 'center',
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                min: 0,
                labels: {
                    align: 'right',
                    x: -10
                },
                title: {
                    text: 'VM number'
                }
            },
            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                valueDecimals: 0
            },
            series: data
        };
        this.isReady = true;
    }
}
