import {
    ChangeDetectorRef,
    AfterContentChecked,
    Component,
    OnInit
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    ManagementService,
    ShareService
} from '@app/services';

import {
    Message
} from '@app/model';

import * as moment from 'moment';


@Component({
    selector: 'app-periodcalendar',
    templateUrl: './periodcalendar.component.html',
    styleUrls: ['./periodcalendar.component.css']
})
export class PeriodcalendarComponent implements OnInit {

    disabled: boolean = true;

    message: Message;

    start: string = '';
    end: string = '';

    range_time: any = {
        min: '',
        max: ''
    };

    constructor(private message_svc: ShareService, private management_svc: ManagementService, private cd:
        ChangeDetectorRef) {}

    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.getTimeViewAll();
    }

    ngAfterViewChecked() {
        if (this.start != this.message.elementPeriodStart)
            this.start = this.message.elementPeriodStart;
        if (this.end != this.message.elementPeriodEnd)
            this.end = this.message.elementPeriodEnd;

        this.cd.detectChanges();
    }

    updateStartDate(val): void {
        this.message.elementPeriodStart = val;
    }

    updateEndDate(val): void {
        this.message.elementPeriodEnd = val;
    }

    private getTimeViewAll(): void {
        this.management_svc.getTimeViewAll('root').pipe(first()).subscribe(
            data => {
                this.range_time.min = data.beginDate;
                this.range_time.max = data.endDate;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }
}
