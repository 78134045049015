import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    NetscopeService,
    GetLastInfrastructureVersionResponse,
    FailureGetLastInfrastructureVersionResponse,
    SuccessGetLastInfrastructureVersionResponse, DCNetscopeResourceWithName
} from '../../services/netscope.service';
import {
    Router
} from '@angular/router';
import {
    forkJoin, of
} from 'rxjs';
import * as d3 from 'd3';

import {
    LicenseService
} from "@app/services";
import {
    ClrCombobox,
    ClrDatagridComparatorInterface
} from "@clr/angular";
import {
    environment
} from "@environments/environment";
import {
    ClrDatagridSortOrder
} from '@clr/angular';
import {TranslocoService} from "@ngneat/transloco";
import {ComboboxSelectionItem} from "@app/netscope/netscope-common-flowview/netscope-common-flowview.component";
import {
    NetscopeCommonFlowviewComboboxComponent
} from "@app/netscope/netscope-common-flowview/netscope-common-flowview-combobox/netscope-common-flowview-combobox.component";


class PortKeyComparator implements ClrDatagridComparatorInterface < any > {
    compare(a: any, b: any) {
        if (isNaN(parseInt(a.key)) || isNaN(parseInt(b.key))) {
            return a.key.localeCompare(b.key, 'en', {
                numeric: true
            });
        }
        return parseInt(a.key) - parseInt(b.key);
    }
}

@Component({
    selector: 'app-netscope-topology',
    templateUrl: './netscope-topology.component.html',
    styleUrls: ['./netscope-topology.component.css']
})
export class NetscopeTopologyComponent implements OnInit, OnDestroy {

    isLoading = false;
    failureMode = false;
    svg;
    height = 1024;
    width = 768;
    chartRef;
    chartCheckInterval;
    resourceMouseMode = "showHide";
    snapCirclesToTheBorder = "DontSnapCircles";
    useColorBlindColors = "DontUseColorBlindColors";
    reloadButtonColorClass = "btn-primary";

    isNetscopeLicenceEnabled = true;

    portKeyComparator: any = new PortKeyComparator();
    ascSort = ClrDatagridSortOrder.ASC;

    lastSvgWidth = 0;
    lastSvgHeight = 0;

    root = [];
    nodesMapping = {};
    // timestamps = [];
    replayData;
    graphData = {
        links: []
    };
    currentTime = 0;
    minTime = 0;
    maxTime = 0;
    zoomedSwitches = [];
    hiddenHosts = [];

    @ViewChild('combobox')
    combobox: ClrCombobox < any > ;

    selectedResourcesWithSearchCombobox = [];
    resourcesForSearchCombobox = [];

    @ViewChild('dataSettingsPanel')
    dataSettingsPanel;

    allNetworks = [];
    allPortgroups = [];
    allDistribuedVSwitches = [];
    allHosts = [];
    allVms = [];
    allProtocols = [];
    targetedResources = {
        switches: [],
        portgroups: [],
        networks: [],
        hosts: [],
        virtualMachines: []
    };
    targetedResourcePattern = "";

    mapDataFilters = {
        switches: {},
        portgroups: {},
        networks: {},
        hosts: {},
        virtualMachines: {},
    };

    isPlaying = false;
    currentRangeValue = 0;
    minRangeValue = 0;
    maxRangeValue = 0;

    graphParameters = {
        userMovedMap: false,
        lastTransformation: undefined,
        firstTransformation: undefined
    }

    currentComboboxSelection: ComboboxSelectionItem[] = [];

    constructor(private netscopeService: NetscopeService, public licenceService: LicenseService, private route: Router, public translocoService: TranslocoService) {}

    ngOnInit(): void {
        this.reloadData();

        window.onresize = () => {
            const graphData = this.extractGraphData(this.root);
            this.fixUnconnectedHostsGraphData(graphData);
            this.eraseNetworkView();
            this.createNetworkView(graphData);
        };

        this.route.events.subscribe((event) => {
            this.ngOnDestroy();
        });

        this.licenceService.licenseInfo.subscribe(licenceInfo => {
            if (environment.production) {
                // Check second bit to be different from 0
                this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
            } else {
                this.isNetscopeLicenceEnabled = true;
            }
        });
    }

    ngOnDestroy = () => {
        if (this.chartCheckInterval) {
            clearInterval(this.chartCheckInterval);
        }
    }

    callbackChartRef = (ref) => {
        this.chartRef = ref;
    }

    exportGraph = () => {
        let svgBody = this.svg.html();
        let svgCode =
            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${svgBody}</svg>`;
        let exportedFilename = 'netscope-topology.svg';

        let blob = new Blob([svgCode], {
            type: 'text/csv;charset=utf-8;'
        });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    getChildren = folder => folder.children;

    reloadData = () => {
        this.isLoading = true;
        this.failureMode = false;

        const infrastructureVersionObservable = this.netscopeService.getLastInfrastructureVersion();
        forkJoin([
            infrastructureVersionObservable
        ]).subscribe((results: Array < GetLastInfrastructureVersionResponse > ) => {
            this.reloadButtonColorClass = "btn-primary";

            let resultsSingleElement: GetLastInfrastructureVersionResponse = results[0];

            if (resultsSingleElement instanceof FailureGetLastInfrastructureVersionResponse) {
                this.failureMode = true;
                this.isLoading = false;
                return;
            }

            let infrastructureVersion;
            if (resultsSingleElement instanceof SuccessGetLastInfrastructureVersionResponse) {
                infrastructureVersion = resultsSingleElement.lastInfrastructureVersion;
            }

            // @ts-ignore
            const nodesMapping = infrastructureVersion.topology.node_mapping;
            // @ts-ignore
            const topology = infrastructureVersion.topology.complete_topology;

            // Set vms for settings
            // @ts-ignore
            this.allVms = infrastructureVersion.topology.vm_only_topology.vms;
            // @ts-ignore
            this.allHosts = infrastructureVersion.topology.vm_only_topology.hosts;
            // @ts-ignore
            this.allDistribuedVSwitches = infrastructureVersion.topology.vm_only_topology.dswitches;
            // @ts-ignore
            this.allNetworks = infrastructureVersion.topology.vm_only_topology.networks;
            // @ts-ignore
            this.allPortgroups = infrastructureVersion.topology.vm_only_topology.portgroups;

            // Take into account filters

            // Fix: there is a bug in the code that generates infrastructure version. Portgroups also appear in the network property. Here I
            // filter elements of networks that are also in portgroups
            this.allNetworks = this.allNetworks
                .filter((e) => {
                    // @ts-ignore
                    return infrastructureVersion.topology.vm_only_topology.portgroups.map((p) => p.uuid).indexOf(e.uuid) === -1;
                });

            // Set the source and destination options
            this.allVms.forEach((vm) => {
                if (!this.mapDataFilters.virtualMachines.hasOwnProperty(vm.uuid)) {
                    this.mapDataFilters.virtualMachines[vm.uuid] = true;
                }
            });

            this.allHosts.forEach((host) => {
                if (!this.mapDataFilters.hosts.hasOwnProperty(host.uuid)) {
                    this.mapDataFilters.hosts[host.uuid] = true;
                }
            });

            this.allDistribuedVSwitches.forEach((distributedSwitch) => {
                if (!this.mapDataFilters.switches.hasOwnProperty(distributedSwitch.uuid)) {
                    this.mapDataFilters.switches[distributedSwitch.uuid] = true;
                }
            });

            this.allPortgroups.forEach((portgroup) => {
                if (!this.mapDataFilters.portgroups.hasOwnProperty(portgroup.uuid)) {
                    this.mapDataFilters.portgroups[portgroup.uuid] = true;
                }
            });

            this.allNetworks.forEach((network) => {
                if (!this.mapDataFilters.networks.hasOwnProperty(network.uuid)) {
                    this.mapDataFilters.networks[network.uuid] = true;
                }
            });

            this.resourcesForSearchCombobox = [
                ...this.allHosts,
                ...this.allVms
            ]

            this.nodesMapping = nodesMapping;
            this.root = topology;

            // Fix: create a list of switches on each datacenter
            this.fixRootForVnicAndVlansDatagrid(this.root)

            this.reloadVisualization();
            this.isLoading = false;
        });
    }

    fixRootForVnicAndVlansDatagrid = (root) => {
        let allResources = [...this.allVms, ...this.allHosts];
        for (let datacenter of root) {
            datacenter.switches = datacenter.children.filter((c) => c.type == 'switch');
            for (let dSwitch of datacenter.switches) {
                for (let portgroup of dSwitch.portGroups) {
                    let connectees = [];
                    if (portgroup.connectees !== undefined) {
                        connectees = portgroup.connectees;
                    }
                    // Add resource object to each connectee of a portgroup.
                    portgroup.hasNoConnectees = Object.keys(connectees).length == 0 ? true : false;
                    for (let item of Object.entries(connectees)) {
                        let resourceValue = item[1];
                        // @ts-ignore
                        let matchingResources = allResources.filter((r) => r.uuid == resourceValue.id);
                        for (let matchingResource of matchingResources) {
                            // @ts-ignore
                            resourceValue.resourceObj = {
                                id: matchingResource.uuid,
                                name: matchingResource.name,
                                // @ts-ignore
                                type: resourceValue.id.indexOf("VirtualMachine:") === -1 ? "host" : "vm",
                            };
                        }
                    }
                    // Pre-sort connectees by port key (string value of the port number)
                    portgroup.connecteesAsArray = Object.entries(connectees).map(tuple2 => Object({
                        key: tuple2[0],
                        value: tuple2[1]
                    }));
                    portgroup.connecteesAsArray = portgroup.connecteesAsArray
                        .sort((a, b) => this.portKeyComparator.compare(a, b))
                        .filter((c) => c.value.resourceObj !== undefined);
                }
            }
        }
    }

    fixUnconnectedHostsGraphData = (graphData) => {
        // Add hosts that may not be directly connected to a network or a distributed vSwitch
        // @ts-ignore
        let hostsUuidsInGraphDataNodes = graphData.hosts.map((h) => h.id);
        let vmUuidToNameIndex = {}
        this.allVms.map((vm) => {
            vmUuidToNameIndex[vm.uuid] = vm.name;
        });
        this.allHosts
            .filter((host) => hostsUuidsInGraphDataNodes.indexOf(host.uuid) == -1)
            .map((host) => {
                let newHost = {
                    "id": host.uuid,
                    "type": "host",
                    "name": host.name,
                    "children": host.children.map((vmId) => Object({
                        "name": vmUuidToNameIndex[vmId],
                        "uuid": vmId
                    }))
                };
                // @ts-ignore
                graphData.hosts.push(newHost);
                // @ts-ignore
                graphData.nodes.push(newHost);
            });
    }

    reloadVisualization = () => {
        this.graphData = this.extractGraphData(this.root);

        // Initialize replayData
        this.replayData = this.generateReplayData(this.graphData, this.root);
        // Reload UI
        this.reloadUi();
    }

    reloadUi = () => {
        // Set the right links
        this.graphData.links = this.replayData.links;
        // Add host uuid to vms
        let vmUuidToHostIndex = {};
        this.allHosts.map((router) => router.children.map((vmUuid) => {
            let short_uuid_parts = router.uuid.split(":");
            let short_uuid = short_uuid_parts[short_uuid_parts.length - 1];
            vmUuidToHostIndex[vmUuid] = {
                uuid: router.uuid,
                short_uuid: short_uuid,
                name: router.name
            };
        }))
        this.allVms.map((vm) => {
            vm.host = vmUuidToHostIndex[vm.uuid];
        });
        // Add switch uuid to portgroups
        let portgroupUuidToSwitchIndex = {};
        this.allDistribuedVSwitches.map((dswitch) => dswitch.children.map((portGroupUuid) => {
            let short_uuid_parts = dswitch.uuid.split(":");
            let short_uuid = short_uuid_parts[short_uuid_parts.length - 1];
            portgroupUuidToSwitchIndex[portGroupUuid] = {
                uuid: dswitch.uuid,
                short_uuid: short_uuid,
                name: dswitch.name
            };
        }))
        this.allPortgroups.map((portgroup) => {
            portgroup.switch = portgroupUuidToSwitchIndex[portgroup.uuid];
        });

        this.fixUnconnectedHostsGraphData(this.graphData);

        // Refresh network view
        this.eraseNetworkView();
        this.createNetworkView(this.graphData);
    }

    generateReplayData = (graphData, topology) => {
        // Create a GraphObject
        const graph = {};
        // 1st step: initialize nodes
        graphData.nodes.forEach((node) => {
            graph[node.id] = {
                id: node.id,
                node: node,
                neighbours: {}
            };
        });
        // 2nd step: iterate links and set neighbours property in nodes
        graphData.links.forEach((link) => {
            // Fetch relevant nodes
            const sourceNode = graph[link.source.id];
            const targetNode = graph[link.target.id];

            if (sourceNode !== undefined && targetNode !== undefined) {
                // Add to the neighbours of each relevant node, the other node
                sourceNode.neighbours[targetNode.id] = targetNode;
                targetNode.neighbours[sourceNode.id] = sourceNode;
            }
        });

        const result = {
            links: graphData.links.map((l) => Object({
                ...l
            }))
        };

        result.links.forEach((link) => {
            link.value = 0;
            link.metrics = [];
        });

        return result;
    }

    createNetworkView(graphData): void {
        d3.select('div#divSvg').select("svg").remove();
        this.refreshNetworkViewFlat(graphData);
    }

    eraseNetworkView(): void {
        d3.select('div#divSvg')
            .selectAll('*')
            .remove();
    }

    extractGraphData = (nodes, parent ? , result ? ) => {
        if (result === undefined) {
            result = {
                nodes: [],
                links: [],
                switches: [],
                ports: [],
                networks: [],
                hosts: [],
                vms: [],
            };
        }

        for (const node of nodes) {
            const graphNode = {
                id: node.uuid,
                type: node.type,
                name: node.name,
                children: []
            };
            if (['host', 'vm', 'switch', 'network', 'port'].indexOf(node.type) !== -1) {
                if (result.nodes.map((n) => n.id).indexOf(node.uuid) === -1) {
                    result.nodes.push(graphNode);
                }
                if (node.type === 'host' && result.hosts.map((n) => n.id).indexOf(node.uuid) === -1) {
                    // Process the host: sometimes vmware can be inconsistent when live migration happens: a VM is migrated on a
                    // new host, and in the API, the VM is associated to the new host, while on the portgroups, it is associated
                    // with the previous host. Here we redo the hosts <-> vms associations.
                    let nodeVms = [];
                    let nodeVmsUiidsCandidates = this.allHosts.filter((h) => h.uuid == node.uuid).map((h) => h
                        .children);
                    for (let nodeVmsUiids of nodeVmsUiidsCandidates) {
                        nodeVms = this.allVms.filter((vm) => nodeVmsUiids.indexOf(vm.uuid) !== -1);
                    }
                    node.vms = nodeVms;
                    graphNode.children = node.vms;
                    result.hosts.push(graphNode);
                }
                if (node.type === 'vm' && result.vms.map((n) => n.id).indexOf(node.uuid) === -1) {
                    result.vms.push(graphNode);
                }
                if (node.type === 'switch' && result.switches.map((n) => n.id).indexOf(node.uuid) === -1) {
                    graphNode.children = node.children;
                    result.switches.push(graphNode);
                }
                if (node.type === 'network' && result.networks.map((n) => n.id).indexOf(node.uuid) === -1) {
                    result.networks.push(graphNode);
                }
                if (node.type === 'port' && result.ports.map((n) => n.id).indexOf(node.uuid) === -1) {
                    result.ports.push(graphNode);
                }
            }
            // Add link if a parent is relevant
            if (parent !== undefined) {
                result.links.push({
                    source: parent,
                    target: graphNode
                });
            }
            this.extractGraphData(node.children, graphNode, result);
        }
        return result;
    }

    assignPositionsFlat = (graphData, viewCenterX, viewCenterY, svgHeight, filteredNodesUuids) => {
        const vmCircleSize = 40;
        let currentMaxX = 4 * vmCircleSize;
        let currentMaxY = vmCircleSize;

        const positioningConstraints = {
            hiddenVmsIds: [],
            box: {
                x: 0,
                y: 0
            }
        }
        // const hiddenVmsIds = [];

        for (const host of graphData.hosts) {
            let i = 0;
            let colIdx = 0;
            let rowIdx = 0;
            const singleLineOfVms = true;
            const vmsPerRow = singleLineOfVms ? host.children.length : 8;

            let howManyLinks = graphData.links
                .filter((l) => l.source == host.id || l.target == host.id)
                .length;

            let additionSpaceCausedByManyLinks = 0;
            if (howManyLinks > 9) {
                additionSpaceCausedByManyLinks = 2 * (howManyLinks - 9);
            }

            if (filteredNodesUuids.indexOf(host.id) === -1) {
                positioningConstraints.hiddenVmsIds.push(...host.children.map((vm) => vm.uuid));
                continue;
            }

            let displayedVmsCount = 0;
            for (const vm of host.children) {
                colIdx = i % vmsPerRow;
                rowIdx = Math.floor(i / vmsPerRow);

                if (filteredNodesUuids.indexOf(vm.uuid) === -1) {
                    continue;
                }

                graphData.nodes
                    .filter((n) => n.id === vm.uuid)
                    .map((n) => {
                        n.fx = currentMaxX + vmCircleSize + colIdx * vmCircleSize +
                            additionSpaceCausedByManyLinks;
                        n.fy = currentMaxY + vmCircleSize + rowIdx * vmCircleSize;
                        n.currentLinksCount = 0;
                        return n;
                    });

                i++;
                displayedVmsCount += 1;
            }

            // Compute position of host and the space it occupies
            const rowCount = Math.ceil(i / vmsPerRow);
            host.rowCount = rowCount;

            graphData.nodes
                .filter((n) => n.id === host.id)
                .map((n) => {
                    n.fx = currentMaxX;
                    n.fy = currentMaxY;
                    n.currentLinksCount = 0;
                    return n;
                });

            const usedRows = Math.min(vmsPerRow, displayedVmsCount);
            currentMaxX += 3 * vmCircleSize + usedRows * vmCircleSize + additionSpaceCausedByManyLinks;
        }
        positioningConstraints.box.x = Math.max(positioningConstraints.box.x, currentMaxX);
        positioningConstraints.box.y = Math.max(positioningConstraints.box.y, currentMaxY);

        let d3ColorScheme = d3.schemeSet2;

        if (this.useColorBlindColors === "useColorBlindColors") {
            // @ts-ignore
            d3ColorScheme = [
                '#000000',
                'rgb(230, 159, 0)',
                '#56B4E9',
                '#009E73',
                '#F0E442',
                '#0072B2',
                '#D55E00',
                '#CC79A7',
            ];
        }

        const networkMainObjects = [];
        networkMainObjects.push(...graphData.switches);
        networkMainObjects.push(...graphData.networks);

        const myColor = d3.scaleOrdinal().domain(networkMainObjects.map((o) => o.id))
            .range(d3ColorScheme);

        // Switches and port groups
        const switchHeight = 60;
        currentMaxX = 20;
        currentMaxY = 200;
        let idx = 0;
        for (const networkObject of networkMainObjects) {

            let currentSwitchHeight = switchHeight;

            if (filteredNodesUuids.indexOf(networkObject.id) === -1) {
                continue;
            }

            let correspondingNodes = graphData.nodes.filter((n) => n.id === networkObject.id);
            let graphDataNode = correspondingNodes.length > 0 ? correspondingNodes[0] : undefined;

            if (graphDataNode !== undefined) {
                graphDataNode.fx = currentMaxX;
                graphDataNode.fy = currentMaxY;
                graphDataNode.color = myColor(networkObject.id);

                if (this.zoomedSwitches.indexOf(graphDataNode.id) !== -1) {
                    let idxPortGroup = 0;
                    graphDataNode.portGroups = [];
                    for (let c of graphDataNode.children) {

                        let correspondingSubNodes = graphData.nodes.filter((n) => n.id === c.uuid);
                        let graphDataSubNode = correspondingSubNodes.length > 0 ? correspondingSubNodes[0] :
                            undefined;

                        if (filteredNodesUuids.indexOf(graphDataSubNode.id) === -1) {
                            continue;
                        }

                        if (graphDataSubNode !== undefined) {
                            graphDataSubNode.fx = currentMaxX + 50;
                            graphDataSubNode.fy = currentMaxY + currentSwitchHeight;
                            graphDataSubNode.color = myColor(networkObject.id);
                            graphDataSubNode.focus = true;
                            currentSwitchHeight += switchHeight;
                        }
                    }
                    graphDataNode.zoomed = true;
                } else {
                    graphDataNode.zoomed = false;

                    for (let c of graphDataNode.children) {
                        c.focus = false;
                    }
                }
            }
            currentMaxY += currentSwitchHeight;

            idx += 1;
        }

        // Ports of non zoomed switches will be superposed with their respective switches
        for (const networkSwitch of graphData.switches) {

            if (filteredNodesUuids.indexOf(networkSwitch.id) === -1) {
                continue;
            }

            if (networkSwitch.zoomed === true) {
                continue;
            }

            for (const networkPort of networkSwitch.children) {
                graphData.nodes
                    .filter((n) => n.id === networkPort.uuid)
                    .map((n) => {
                        n.fx = networkSwitch.fx + 20;
                        n.fy = networkSwitch.fy;
                        n.parent = networkSwitch;
                        n.color = n.parent.color;
                        return n;
                    });
            }
        }
        positioningConstraints.box.x = Math.max(positioningConstraints.box.x, currentMaxX);
        positioningConstraints.box.y = Math.max(positioningConstraints.box.y, currentMaxY);
        return positioningConstraints;
    }

    refreshNetworkViewFlat(graphData): void {
        // const graphSvg = d3.select('g#graph_svg');
        // graphSvg.selectAll('*').remove();

        let svgHeight = document.getElementById('divSvg').clientHeight;
        let svgWidth = document.getElementById('divSvg').clientWidth;

        if (svgHeight == 0) {
            svgHeight = this.lastSvgHeight;
        } else {
            this.lastSvgHeight = svgHeight;
        }

        if (svgWidth == 0) {
            svgWidth = this.lastSvgWidth;
        } else {
            this.lastSvgWidth = svgWidth;
        }

        const viewCenterX = svgWidth / 2;
        const viewCenterY = svgHeight / 2;
        const totalBytes = graphData.links.map((d) => d.value).reduce((a, b) => a + b);

        // Filter distributed vSwitch
        const filteredNodesUuids = [];
        filteredNodesUuids.push(...this.allDistribuedVSwitches.filter((n) => this.mapDataFilters.switches[n.uuid] ||
            this.mapDataFilters.switches[n.uuid] == 'indeterminate').map((n) => n.uuid));
        filteredNodesUuids.push(...this.allPortgroups.filter((n) => this.mapDataFilters.portgroups[n.uuid]).map((
            n) => n.uuid));
        filteredNodesUuids.push(...this.allNetworks.filter((n) => this.mapDataFilters.networks[n.uuid]).map((n) => n
            .uuid));
        filteredNodesUuids.push(...this.allHosts.filter((n) => this.mapDataFilters.hosts[n.uuid] || this
            .mapDataFilters.hosts[n.uuid] == 'indeterminate').map((n) => n.uuid));
        filteredNodesUuids.push(...this.allVms.filter((n) => this.mapDataFilters.virtualMachines[n.uuid]).map((n) =>
            n.uuid));
        filteredNodesUuids.push('network:outerRim');

        // Filter links with a datacenter
        graphData.links = graphData.links.filter((l) => {
            return l.source.type !== 'datacenter' && l.target.type !== 'datacenter:';
        });

        graphData.links = graphData.links.map((l) => {
            l.source = l.source.id;
            l.target = l.target.id;
            return l;
        });

        const forceParameter = -400;

        // @ts-ignore
        let ratioMinSizeOverMaxSize = 10;

        // @ts-ignore
        d3.select('div#divSvg').select("svg").remove();
        const svg = d3.select('div#divSvg')
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .on("click", (d, i) => {
                // Remove a previously open dropdown menus
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }
            });

        this.svg = svg;

        // Add zoom
        svg.call(d3.zoom()
            .extent([
                [0, 0],
                [svgWidth, svgHeight]
            ])
            .scaleExtent([0.1, ratioMinSizeOverMaxSize])
            .on("zoom", zoomed));

        const g = svg.append("g");

        const self = this;

        function zoomed({
            transform
        }) {
            self.graphParameters.userMovedMap = true;
            self.graphParameters.lastTransformation = transform;
            g.attr("transform", transform);
            if (self.snapCirclesToTheBorder === "SnapCircles") {
                snapCirclesToBorder();
            }
        }

        function snapCirclesToBorder() {
            // console.log(`transform: ${transform}`);
            const translateX = (-1) * self.graphParameters.lastTransformation.x / self.graphParameters
                .lastTransformation.k;
            const translateY = (-1) * self.graphParameters.lastTransformation.y / self.graphParameters
                .lastTransformation.k;

            console.log(`translate: ${translateX}, ${translateY}`);

            visuNode
                .filter((d) => {
                    // @ts-ignore
                    return d.type === 'vm' || d.type === 'host';
                })
                .select('.icon_with_text')
                .attr('transform', (d) => {
                    // @ts-ignore
                    const tolerance = d.type === 'host' ? 20 : 60;
                    // @ts-ignore
                    if (d.fy < translateY + tolerance) {
                        // @ts-ignore
                        return 'translate(' + 0 + ',' + (translateY - 15) + ')';
                    }
                });

            visuNode
                .filter((d) => {
                    // @ts-ignore
                    return  d.type === 'network' || (d.type === 'switch' && d.zoomed !== true) || d.type === 'port';
                })
                .select('.icon_with_text')
                .attr('transform', (d) => {
                    // @ts-ignore
                    const tolerance = d.port === 'host' ? 60 : 15;
                    // @ts-ignore
                    if (d.fx < translateX + tolerance) {
                        // @ts-ignore
                        return 'translate(' + translateX + ',' + 0 + ')';
                    }
                });
        }

        const simulation = d3.forceSimulation()
            // @ts-ignore
            .force('link', d3.forceLink().id((d) => d.id))
            .force('charge', d3.forceManyBody().strength(forceParameter))
            .force('center', d3.forceCenter(svgWidth / 2, svgHeight / 2))
            .force('x', d3.forceX())
            .force('y', d3.forceY());

        const positioningConstraints = this.assignPositionsFlat(graphData, viewCenterX, viewCenterY, svgHeight,
            filteredNodesUuids);

        const displayedNodes = graphData.nodes
            .filter((n) => !(n.type === 'port' && !n.focus))
            .filter((n) => filteredNodesUuids.indexOf(n.id) !== -1 || n.type === 'port')
            .filter((n) => positioningConstraints.hiddenVmsIds.indexOf(n.id) === -1);

        const networkNodes = graphData.nodes
            .filter((n) => n.type === 'network' || (n.type === 'switch' && n.zoomed === false) || (n.type ===
                'port' && n.focus));

        const visuNode = g.append('g')
            .attr('class', 'nodes')
            .selectAll('g')
            .data(displayedNodes)
            .enter()
            .append('g')
            // @ts-ignore
            .attr('transform', (d) => 'translate(' + d.fx + ',' + d.fy + ')');

        let currentInstanceObject = this;
        let focusedVmsUuids = currentInstanceObject.currentComboboxSelection
            .filter((s) => s.mode === "focus")
            .map((s) => s.resource.uuid);

        visuNode.filter((d) => {
                // @ts-ignore
                return d.type === 'vm' || d.type === 'host';
            })
            .on("mouseover", function(d, i) {
                d3.select(this).style("cursor", "pointer");
            })
            .on("mouseout", function(d, i) {
                d3.select(this).style("cursor", "default");
            })
            .on("click", function(d, i) {
                d.stopPropagation();

                let mouseEvent: number[] = d3.pointer(d);

                // @ts-ignore
                let mouseX = i.fx + mouseEvent[0];
                // @ts-ignore
                let mouseY = i.fy + mouseEvent[1] - 12;

                // @ts-ignore
                let node_id = i.id.replaceAll(":", "__").replaceAll(".", "__");
                let dropdownId = 'dropdownmenu_' + node_id;

                let existingDropdownForThisNode = document.getElementById(dropdownId) !== undefined && document
                    .getElementById(dropdownId) !== null;

                // Remove a previously open dropdown
                let existingDropdowns = document.getElementsByClassName("dropdown_actions");

                for (let dropdown of Array.from(existingDropdowns)) {
                    dropdown.remove();
                }

                if (!existingDropdownForThisNode) {

                    let textNode = d3.select(this).selectAll("text");
                    // @ts-ignore
                    let focusLabel = focusedVmsUuids.indexOf(i.id) === -1 ? "Focus" : "Unfocus";

                    let popover = g
                        .append("foreignObject")
                        .attr("class", "dropdown_actions")
                        .attr('id', dropdownId)
                        .attr('width', 0)
                        .attr('height', 0)
                        .attr('x', mouseX)
                        .attr('y', mouseY)
                        .html("<div class=\"dropdown open\" style='z-index: 999; margin-top: 0px;'>\n" +
                            "    <div id=\"node_action_popover\" class=\"dropdown-menu\">\n" +
                            "        <h4 class=\"dropdown-header\">Select an action</h4>\n" +
                            "        <div id=\"dropdown_focus_" + node_id + "\" class=\"dropdown-item\">" +
                            focusLabel + "</div>\n" +
                            "        <div id=\"dropdown_ignore_" + node_id +
                            "\" class=\"dropdown-item\">Ignore</div>\n" +
                            "        <div id=\"dropdown_resolve_" + node_id +
                            "\"class=\"dropdown-item\">Resolve DNS</div>\n" +
                            "        <div id=\"dropdown_resolve_dns_neighbours_" + node_id +
                            "\"class=\"dropdown-item\">Resolve DNS of neighbours</div>\n" +
                            "    </div>\n" +
                            "</div>");

                    // Ensure that the node and its dropdown menu are above all elements of the svg parent node.
                    // This moves the nodes at the beginning of the parent's list of children.
                    d3.select(this).raise();

                    // Adjust size of popover
                    setTimeout(() => {
                        let element = document.getElementById("node_action_popover");
                        popover
                            .transition()
                            .attr("height", element.offsetHeight + 24)
                            .attr("width", element.offsetWidth + 5);
                    }, 100);

                    d3.select("#dropdown_ignore_" + node_id).on("click", function(d2, i2) {
                        // @ts-ignore
                        currentInstanceObject.currentComboboxSelection.push(new ComboboxSelectionItem(new DCNetscopeResourceWithName(i.id, i.name), "ignore"));
                        currentInstanceObject.processNewSelection();
                        currentInstanceObject.reloadVisualization();
                    });

                    d3.select("#dropdown_focus_" + node_id).on("click", function(d2, i2) {
                        d2.stopPropagation();
                        document.getElementById(dropdownId).remove();
                        // @ts-ignore
                        currentInstanceObject.currentComboboxSelection.push(new ComboboxSelectionItem(new DCNetscopeResourceWithName(i.id, i.name), "focus"));
                        currentInstanceObject.processNewSelection();
                        currentInstanceObject.reloadVisualization();
                    });
                }
            });

        visuNode
            .filter((d) => {
                // @ts-ignore
                return d.type === 'switch';
            })
            .on("mouseover", function(d, i) {
                d3.select(this).style("cursor", "pointer");
            })
            .on("mouseout", function(d, i) {
                d3.select(this).style("cursor", "default");
            })
            .on("click", function(e, d) {
                // @ts-ignore
                let switchId = d.id;

                if (self.zoomedSwitches.indexOf(switchId) === -1) {
                    self.zoomedSwitches.push(switchId);
                } else {
                    self.zoomedSwitches = self.zoomedSwitches.filter((uuid) => uuid !== switchId);
                }
                // Refresh the visualization
                const graphData = self.extractGraphData(self.root);
                self.fixUnconnectedHostsGraphData(graphData);

                self.eraseNetworkView();
                self.createNetworkView(graphData);
            });

        const nodeRadius = 15;

        const linesNetworksAndSwitches = visuNode
            .filter((d) => {
                // @ts-ignore
                return d.type === 'network' || (d.type === 'switch' && d.zoomed === false) || (d.type === 'port' && d.focus);
            })
            .append('g')
            .append('path')
            .attr('d', (d) => {
                // @ts-ignore
                const endLineX = d.type === 'port' ? positioningConstraints.box.x - 50 : positioningConstraints.box.x;
                return `
             m 0 0
             l ${endLineX} ${0}`;
            })
            .style('fill', `black`)
            .style('stroke', (d) => {
                // @ts-ignore
                return d.color;
            })
            .style('stroke-width', '5px');

        // Add click handler to switches : when clicked, details of portgroups is shown
        linesNetworksAndSwitches
            .filter((d) => {
                // @ts-ignore
                return (d.type === 'switch' && d.zoomed === false) || (d.type === 'port' && d.focus);
            });

        const endOfLines = visuNode
            .filter((d) => {
                // @ts-ignore
                return d.type === 'network' || (d.type === 'switch' && d.zoomed === false) || (d.type === 'port' && d.focus);
            })
            .append('g')
            .append('circle')
            .attr('r', 5)
            .attr('cx', (d) => {
                // @ts-ignore
                const endLineX = d.type === 'port' ? positioningConstraints.box.x - 50 : positioningConstraints.box.x;
                return endLineX;
            })
            .attr('cy', (d) => 0)
            .style('fill', `black`)
            .style('stroke', (d) => {
                // @ts-ignore
                return d.color;
            })
            .style('stroke-width', '5px');

        const linkZoomedSwitchesAndPortGroups = visuNode
            .filter((d) => {
                // @ts-ignore
                return d.type === 'switch' && d.zoomed === true;
            })
            .append('g')
            .append('path')
            .attr('d', (d) => {
                // @ts-ignore
                const bottomY = d.children.filter((c) => this.mapDataFilters.portgroups[c.uuid] === true).length * 60;
                // @ts-ignore
                return `
             m 0 0
             l 50 ${0}
             l 0 ${bottomY}`;
            })
            .style('fill', `none`)
            .style('stroke', (d) => {
                // @ts-ignore
                return d.color;
            })
            .style('stroke-width', '5px');

        const attachPointRadius = 4.0;

        graphData.links.forEach((l) => {
            const sourceObject = graphData.nodes.filter((n) => n.id === l.source || n.uiid === l.source)[0];
            const destinationObject = graphData.nodes.filter((n) => n.id === l.target || n.uiid === l
                .target)[0];

            if (sourceObject.type === 'vm' || sourceObject.type === 'host' || destinationObject.type ===
                'vm' || destinationObject.type === 'host') {
                const hostOrVms = [sourceObject, destinationObject].filter((o) => o.type === 'host' || o
                    .type === 'vm')[0];
                let otherNode = [sourceObject, destinationObject].filter((o) => o !== hostOrVms)[0];
                otherNode = graphData.nodes.filter((n) => (n.id) === otherNode.id)[0];

                const lineResourceToNetwork = visuNode
                    .filter((d) => d === hostOrVms)
                    .append('g')
                    .append('path')
                    .attr('d', (d) => {
                        const spaceBetweenNetworkLinks = 4;
                        const xRelativePositionLink = hostOrVms.currentLinksCount % 2 === 0 ?
                            (hostOrVms.currentLinksCount / 2) * spaceBetweenNetworkLinks :
                            (0 - (hostOrVms.currentLinksCount + 1) / 2) * spaceBetweenNetworkLinks;
                        hostOrVms.currentLinksCount += 1;
                        return `
             m ${0} ${0}
             l ${xRelativePositionLink} ${20}
             l ${0} ${otherNode.fy - hostOrVms.fy - 20}             
             m -${attachPointRadius}, 0
             a ${attachPointRadius},${attachPointRadius} 0 1,0 ${2 * attachPointRadius},0
             a ${attachPointRadius},${attachPointRadius} 0 1,0 -${2 * attachPointRadius},0
             m ${attachPointRadius / 2}, 0
             a ${attachPointRadius / 2},${attachPointRadius / 2} 0 1,0 ${2 * attachPointRadius / 2},0
             a ${attachPointRadius / 2},${attachPointRadius / 2} 0 1,0 -${2 * attachPointRadius / 2},0
             m ${attachPointRadius / 4}, 0
             a ${attachPointRadius / 4},${attachPointRadius / 4} 0 1,0 ${2 * attachPointRadius / 4},0
             a ${attachPointRadius / 4},${attachPointRadius / 4} 0 1,0 -${2 * attachPointRadius / 4},0`
                    })
                    .style('fill', (d) => {
                        // @ts-ignore
                        return "none";
                    })
                    .style('stroke', (d) => {
                        // @ts-ignore
                        return otherNode.color;
                    })
                    .attr('stroke-width', (d) => {
                        return 2;
                    });
            }
        });

        const circles = visuNode.append('g')
            .attr('class', 'icon_with_text')
            .append('circle')
            .attr('r', nodeRadius)
            .style('stroke-width', 5) // set the stroke width
            .style('stroke', '#007FCB')
            .attr('fill', (d) => 'white');

        const iconsSvgCode = {
            "datacenter": "<path d=\"M26.5,4.08C22.77,4.08,19,5.4,19,7.91V9.5a18.75,18.75,0,0,1,2,.2V7.91c0-.65,2.09-1.84,5.5-1.84S32,7.27,32,7.91V18.24c0,.54-1.46,1.44-3.9,1.73v2c3.13-.32,5.9-1.6,5.9-3.75V7.91C34,5.4,30.23,4.08,26.5,4.08Z\"/><path d=\"M4,18.24V7.91c0-.65,2.09-1.84,5.5-1.84S15,7.27,15,7.91V9.7a18.75,18.75,0,0,1,2-.2V7.91c0-2.52-3.77-3.84-7.5-3.84S2,5.4,2,7.91V18.24C2,20.4,4.77,21.67,7.9,22V20C5.46,19.68,4,18.78,4,18.24Z\"/><path d=\"M18,10.85c-4.93,0-8.65,1.88-8.65,4.38V27.54c0,2.5,3.72,4.38,8.65,4.38s8.65-1.88,8.65-4.38V15.23C26.65,12.73,22.93,10.85,18,10.85Zm6.65,7.67c-.85,1-3.42,2-6.65,2A14.49,14.49,0,0,1,14,20v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v3.12c-.85,1-3.42,2-6.65,2a14.49,14.49,0,0,1-4-.53v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v2.29c0,.95-2.65,2.38-6.65,2.38s-6.65-1.43-6.65-2.38V15.23c0-.95,2.65-2.38,6.65-2.38s6.65,1.43,6.65,2.38Z\"/>",
            "network": "<path d=\"M26.58,32h-18a1,1,0,1,0,0,2h18a1,1,0,0,0,0-2Z\"/><path d=\"M17.75,2a14,14,0,0,0-14,14c0,.45,0,.89.07,1.33l0,0h0A14,14,0,1,0,17.75,2Zm0,2a12,12,0,0,1,8.44,3.48c0,.33,0,.66,0,1A18.51,18.51,0,0,0,14,8.53a2.33,2.33,0,0,0-1.14-.61l-.25,0c-.12-.42-.23-.84-.32-1.27s-.14-.81-.19-1.22A11.92,11.92,0,0,1,17.75,4Zm-3,5.87A17,17,0,0,1,25.92,10a16.9,16.9,0,0,1-3.11,7,2.28,2.28,0,0,0-2.58.57c-.35-.2-.7-.4-1-.63a16,16,0,0,1-4.93-5.23,2.25,2.25,0,0,0,.47-1.77Zm-4-3.6c0,.21.06.43.1.64.09.44.21.87.33,1.3a2.28,2.28,0,0,0-1.1,2.25A18.32,18.32,0,0,0,5.9,14.22,12,12,0,0,1,10.76,6.27Zm0,15.71A2.34,2.34,0,0,0,9.2,23.74l-.64,0A11.94,11.94,0,0,1,5.8,16.92l.11-.19a16.9,16.9,0,0,1,4.81-4.89,2.31,2.31,0,0,0,2.28.63,17.53,17.53,0,0,0,5.35,5.65c.41.27.83.52,1.25.76A2.32,2.32,0,0,0,19.78,20a16.94,16.94,0,0,1-6.2,3.11A2.34,2.34,0,0,0,10.76,22Zm7,6a11.92,11.92,0,0,1-5.81-1.51l.28-.06a2.34,2.34,0,0,0,1.57-1.79,18.43,18.43,0,0,0,7-3.5,2.29,2.29,0,0,0,3-.62,17.41,17.41,0,0,0,4.32.56l.53,0A12,12,0,0,1,17.75,28Zm6.51-8.9a2.33,2.33,0,0,0-.33-1.19,18.4,18.4,0,0,0,3.39-7.37q.75.35,1.48.78a12,12,0,0,1,.42,8.2A16,16,0,0,1,24.27,19.11Z\"/>",
            "switch": "<path d=\"M5.71,14H20.92V12H5.71L9.42,8.27A1,1,0,1,0,8,6.86L1.89,13,8,19.14a1,1,0,1,0,1.42-1.4Z\"/><rect x=\"23\" y=\"12\" width=\"3\" height=\"2\"/><rect x=\"28\" y=\"12\" width=\"2\" height=\"2\"/><path d=\"M27.92,17.86a1,1,0,0,0-1.42,1.41L30.21,23H15v2H30.21L26.5,28.74a1,1,0,1,0,1.42,1.4L34,24Z\"/><rect x=\"10\" y=\"23\" width=\"3\" height=\"2\"/><rect x=\"6\" y=\"23\" width=\"2\" height=\"2\"/>",
            "port": "<path d=\"M6.06,30a1,1,0,0,1-1-1V8h-2a1,1,0,0,1,0-2h4V29A1,1,0,0,1,6.06,30Z\"/><path d=\"M30.06,27h-25V9h25a3,3,0,0,1,3,3V24A3,3,0,0,1,30.06,27Zm-23-2h23a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1h-23Z\"/><rect x=\"22.06\" y=\"20\" width=\"6\" height=\"2\"/><rect x=\"22.06\" y=\"14\" width=\"6\" height=\"2\"/><path d=\"M19.06,22h-8V20h7V14h2v7A1,1,0,0,1,19.06,22Z\"/>",
            "host": "<path d=\"M18,24.3a2.48,2.48,0,1,0,2.48,2.47A2.48,2.48,0,0,0,18,24.3Zm0,3.6a1.13,1.13,0,1,1,1.13-1.12A1.13,1.13,0,0,1,18,27.9Z\"/><rect x=\"13.5\" y=\"20.7\" width=\"9\" height=\"1.44\"/><path d=\"M25.65,3.6H10.35A1.35,1.35,0,0,0,9,4.95V32.4H27V4.95A1.35,1.35,0,0,0,25.65,3.6Zm-.45,27H10.8V5.4H25.2Z\"/><rect x=\"12.6\" y=\"7.2\" width=\"10.8\" height=\"1.44\"/><rect x=\"12.6\" y=\"10.8\" width=\"10.8\" height=\"1.44\"/>",
            "vm": "<path d=\"M11,5H25V8h2V5a2,2,0,0,0-2-2H11A2,2,0,0,0,9,5v6.85h2Z\"/><path d=\"M30,10H17v2h8v6h2V12h3V26H22V17a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2V31a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V28h8a2,2,0,0,0,2-2V12A2,2,0,0,0,30,10ZM6,31V17H20v9H16V20H14v6a2,2,0,0,0,2,2h4v3Z\"/>"
        };
        const iconSvg = visuNode
            .select(".icon_with_text")
            .append('g')
            .attr('viewBox', `0 0 ${nodeRadius} ${nodeRadius}`)
            .append('g')
            .attr('transform', `translate(-11, -10) scale(0.60)`)
            // @ts-ignore
            .html((d) => iconsSvgCode[d.type]);

        const labels = visuNode
            .select(".icon_with_text")
            .append('text')
            // @ts-ignore
            .text((d) => d.name)
            // @ts-ignore
            .attr('x', (d) => {
                // @ts-ignore
                if (d.type === 'host') {
                    return nodeRadius + 6;
                }
                // @ts-ignore
                if (d.type === 'switch' || d.type === 'network') {
                    return -nodeRadius / 2;
                }
                // @ts-ignore
                if (d.type === 'vm') {
                    return nodeRadius - 15;
                }
                // @ts-ignore
                return 6 * d.name.length / 2 * (-1);
            })
            .attr('y', (d) => {
                // @ts-ignore
                if (d.type === 'host') {
                    return 3;
                }
                // @ts-ignore
                if (d.type === 'switch' || d.type === 'network') {
                    return nodeRadius + 16;
                }
                // @ts-ignore
                if (d.type === 'vm') {
                    return nodeRadius + 15;
                }
                return nodeRadius + 15;
            })
            .attr('style', 'text-shadow: 1px 1px 2px white, -1px -1px 2px white;')
            .attr('transform', (d) => {
                // @ts-ignore
                if (d.type === 'vm') {
                    return 'rotate(35)';
                }
                return '';
            });

        // step1: zoom out, and let d3 recompute positions and sizes
        const [x, y, k] = [0, 0, 1.0];
        g.attr('transform', 'translate(' + x + ',' + y + ') scale(' + k + ')');
        this.svg.call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(x, y).scale(k)
        );

        // step2: center the main group by translating in the middle of the size difference
        // between the group and the svg
        let [x2, y2, k2] = [
            (g.node().getBBox().width - this.svg.node().getBBox().width) / 2 + 20,
            (g.node().getBBox().height - this.svg.node().getBBox().height) / 2 + 20,
            1.0
        ];

        if (this.graphParameters.userMovedMap) {
            [x2, y2, k2] = [this.graphParameters.lastTransformation.x, this.graphParameters.lastTransformation.y,
                this.graphParameters.lastTransformation.k
            ];
        }

        g.attr('transform', 'translate(' + x2 + ',' + y2 + ') scale(' + k2 + ')');
        this.svg.call(
            d3.zoom().transform,
            d3.zoomIdentity.translate(x2, y2).scale(k2)
        );

        // Save initial visualisation's position parameters to enable "zoom-in", "zoom-out" and "reset" buttons to work.
        if (this.graphParameters.firstTransformation === undefined) {
            this.graphParameters.firstTransformation = {
                x: x2,
                y: y2,
                k: k2
            }
        }

        if (self.snapCirclesToTheBorder === "SnapCircles") {
            snapCirclesToBorder();
        }
    }

    onComboboxChange = (event) => {
        let processedSelection = this.currentComboboxSelection.filter((o) => o?.mode !== undefined);
        let newItemsInSelection = this.currentComboboxSelection.filter((o) => o?.mode === undefined);

        let processedNewItemsInSelection = newItemsInSelection.map((r) => new ComboboxSelectionItem(r.resource, "focus"));

        this.currentComboboxSelection = processedSelection.concat(processedNewItemsInSelection);

        event.model = this.currentComboboxSelection;

        this.processNewSelection();
        this.reloadVisualization();
    };

    processNewSelection = () => {
        let modeIsFocus = this.currentComboboxSelection.filter((item) => item.mode === "focus").length > 0;
        let computeResourceTypes = ["virtualMachines", "hosts"];

        //Reset everything as displayed
        for (let resourceType of computeResourceTypes) {
            for (let resource of Object.keys(this.mapDataFilters[resourceType])) {
                this.mapDataFilters[resourceType][resource] = modeIsFocus ? false : true;
            }
        }

        // Take into account the selection
        for (let item of this.currentComboboxSelection) {
            let resourceTypeKey = "unknown";
            if (item.resource.uuid.indexOf("vim.VirtualMachine") !== -1) {
                resourceTypeKey = "virtualMachines"
            }
            if (item.resource.uuid.indexOf("vim.HostSystem") !== -1) {
                resourceTypeKey = "hosts";
            }

            this.mapDataFilters[resourceTypeKey][item.resource.uuid] = item.mode === "ignore"? false : true;
            if (modeIsFocus) {
                if (resourceTypeKey === "hosts") {
                    this.allHosts
                        .filter((h) => h.uuid == item.resource.uuid)
                        .map((h) => h.children)
                        .flat()
                        .map((vmUuid) => {
                            this.mapDataFilters.virtualMachines[vmUuid] = true;
                        });
                }
                if (resourceTypeKey === "virtualMachines") {
                    this.allVms
                        .filter((vm) => vm.uuid == item.resource.uuid)
                        .map((vm) => vm.host)
                        .map((host) => {
                            this.mapDataFilters.hosts[host.uuid] = true;
                        });
                }
            } else {
                if (resourceTypeKey === "hosts") {
                    this.allHosts
                        .filter((h) => h.uuid == item.resource["uuid"])
                        .map((h) => h.children)
                        .flat()
                        .map((vmUuid) => {
                            this.mapDataFilters.virtualMachines[vmUuid] = false;
                        });
                }
            }

            console.log("item");
        }
    }

    fetchResourcesForCombobox = (filter = '', combobox: NetscopeCommonFlowviewComboboxComponent) => {
        combobox.loadingResources = true;

        let words = filter.split(" ");

        let resources = [...this.allHosts, ...this.allVms, ...this.allNetworks, ...this.allNetworks, ...this.allDistribuedVSwitches]

        let filteredResources = resources
            .filter((o) => o !== undefined)
            .filter((o) => o.uuid.indexOf(filter) !== 1 || o.name.indexOf(filter) !== -1);

        let ignoreAndFocusResults = [...filteredResources.map((o) => {
            return new ComboboxSelectionItem(o, "ignore");
        }), ...filteredResources.map((o) => {
            return new ComboboxSelectionItem(o, "focus");
        })]

        combobox.asyncResources$ = of(ignoreAndFocusResults);
        combobox.loadingResources = false;
    }

    setLanguage = (language: "french" | "english") => {
        let languageMap: Map<string, string> = new Map([
            ["french", "fr"],
            ["english", "en"]
        ]);
        let language_code = languageMap.get(language);
        this.translocoService.setDefaultLang(language_code);
        this.translocoService.setActiveLang(language_code);
        localStorage.setItem("language", language_code);
    }

    zoomInVisualisation = () => {
        console.log("zoom in visualisation");
        let transformation = {
            x: this.graphParameters.firstTransformation.x,
            y: this.graphParameters.firstTransformation.y,
            k: this.graphParameters.firstTransformation.k
        };

        if (this.graphParameters?.lastTransformation?.x !== undefined) {
            transformation.x = this.graphParameters.lastTransformation.x;
        }

        if (this.graphParameters?.lastTransformation?.y !== undefined) {
            transformation.y = this.graphParameters.lastTransformation.y;
        }

        if (this.graphParameters?.lastTransformation?.k !== undefined) {
            transformation.k = this.graphParameters.lastTransformation.k;
        }

        transformation.k *= 1.2;
        this.graphParameters.userMovedMap = true;

        this.graphParameters.lastTransformation = transformation;
        this.reloadVisualization();
    }

    zoomOutVisualisation = () => {
        console.log("zoom out visualisation");
        let transformation = {
            x: this.graphParameters.firstTransformation.x,
            y: this.graphParameters.firstTransformation.y,
            k: this.graphParameters.firstTransformation.k
        };

        if (this.graphParameters?.lastTransformation?.x !== undefined) {
            transformation.x = this.graphParameters.lastTransformation.x;
        }

        if (this.graphParameters?.lastTransformation?.y !== undefined) {
            transformation.y = this.graphParameters.lastTransformation.y;
        }

        if (this.graphParameters?.lastTransformation?.k !== undefined) {
            transformation.k = this.graphParameters.lastTransformation.k;
        }

        transformation.k *= 0.8;
        this.graphParameters.userMovedMap = true;

        this.graphParameters.lastTransformation = transformation;
        // this.reloadUi();
        this.reloadVisualization();
    }

    recenterVisualisation = () => {
        console.log("recenter visualisation");
        this.graphParameters.userMovedMap = false;
        this.graphParameters.lastTransformation.x = this.graphParameters.firstTransformation.x;
        this.graphParameters.lastTransformation.y = this.graphParameters.firstTransformation.y;
        this.graphParameters.lastTransformation.k = this.graphParameters.firstTransformation.k;
        this.reloadVisualization();
    }
}

