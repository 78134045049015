import {
    AfterViewChecked,
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ChangeDetectorRef,
    ViewContainerRef,
    ComponentFactory,
} from '@angular/core';
import {
    NavigationEnd,
    NavigationStart,
    Router
} from '@angular/router';
import {
    first
} from 'rxjs/operators';
import {
    Title
} from '@angular/platform-browser';

import {
    AccountService,
    JsonloaderService,
    ManagementService,
    ShareService,
    SettingsService,
    LicenseService,
    MonitorService,
    GreenitService
} from '@app/services';

import {
    FilterMgt,
    Json,
    Message,
    User,
    Status,
    StatusColor,
    IhmSettingsTarget,
    TimeFilter,
    InstanceType
} from '@app/model';

import {
    AlertingwatcherComponent
} from '@app/alerting/alertingwatcher/alertingwatcher.component';
import {
    AlertingwatcherDirective
} from '@app/directives/alertingwatcher.directive';

import {
    LicenseComponent
} from '@app/license/license.component';
import {
    LicenseType
} from '@app/license/license.enums';
import {
    LicenseInfo
} from '@app/model/license';

import {
    UploadComponent
} from '@app/upload/upload.component';
import {
    UploadType
} from '@app/upload/upload.enums';

import {
    MonitorComponent
} from '@app/monitor/monitor.component';

import {
    SettingsComponent
} from '@app/settings/settings.component';
import {
    Options
} from '@app/model/settings';

import {
    PluginComponent
} from '@app/plugin/plugin.component';
import {
    interval,
    Subscription
} from 'rxjs';
import {
    DashboardComponent
} from "@app/dashboard/dashboard.component";
import {
    AuthGuard
} from "@app/_helpers";
import {
    SelectionviewComponent
} from "@app/selectionview/selectionview.component";
import {
    PeriodviewComponent
} from "@app/periodview/periodview.component";
import {
    FiltermgtComponent
} from "@app/filtermgt/filtermgt.component";
import {
    ClustersynthesisComponent
} from "@app/clustersynthesis/clustersynthesis.component";
import {
    DcviewComponent
} from "@app/dcview/dcview.component";
import {
    DcviewFlowchartComponent
} from "@app/dcview/dcview-flowchart/dcview-flowchart.component";
import {
    HostsynthesisComponent
} from "@app/hostsynthesis/hostsynthesis.component";
import {
    HostinfoComponent
} from "@app/hostinfo/hostinfo.component";
import {
    HostconsoverComponent
} from "@app/hostconsover/hostconsover.component";
import {
    VmsynthesisComponent
} from "@app/vmsynthesis/vmsynthesis.component";
import {
    VminfoComponent
} from "@app/vminfo/vminfo.component";
import {
    VmconsoverComponent
} from "@app/vmconsover/vmconsover.component";
import {
    LoginComponent
} from "@app/login/login.component";
import {
    PowerComponent
} from "@app/greenit/power/power.component";
import {
    AnalysisComponent
} from "@app/greenit/analysis/analysis.component";
import {
    VirtIndexComponent
} from '@app/virtIndex/virtIndex.component';

import {
    CapaplanComponent
} from "@app/capaplan/capaplan.component";
import {
    ReportComponent
} from "@app/report/report.component";
import {
    GraphOnDemandComponent
} from "@app/graph-on-demand/graph-on-demand.component";
import {
    RecommendationComponent
} from "@app/recommendation/recommendation.component";
import {
    CostmgtComponent
} from "@app/costmgt/costmgt.component";
import {
    T6Component
} from "@app/t6/t6.component";
import {
    UsermgtComponent
} from "@app/usermgt/usermgt.component";
import {
    StorageOverviewComponent
} from "@app/storage-overview/storage-overview.component";
import {
    StorageOverviewVmComponent
} from "@app/storage-overview/storage-overview-vm/storage-overview-vm.component";
import {
    VmsnapshotComponent
} from '@app/vmsnapshot/vmsnapshot.component';
import {
    RpinfoComponent
} from "@app/rpinfo/rpinfo.component";
import {
    RpsynthesisComponent
} from "@app/rpsynthesis/rpsynthesis.component";
import {
    ClusterinfoComponent
} from "@app/clusterinfo/clusterinfo.component";
import {
    EvolutionComponent
} from '@app/greenit/evolution/evolution.component';
import {
    ElementsynthesisComponent
} from '@app/elementsynthesis/elementsynthesis.component';
import {
    VirtindexService
} from '@app/services/virtindex.service';
import {
    OpportunitiesComponent
} from '@app/opportunities/opportunities.component';
import {
    SimulationComponent
} from '@app/simulation/simulation.component';
import {
    CloudComponent
} from '@app/cloud/cloud.component';
import {
    CloudimpactComponent
} from '@app/cloudimpact/cloudimpact.component';
import {
    MycloudpricingComponent
} from "@app/mycloudpricing/mycloudpricing.component";
import {
    LdapComponent
} from '@app/settings/ldap/ldap.component';
import {
    SupervisionComponent
} from '@app/supervision/supervision.component';

export let browserRefresh = false;


@Component({
    selector: 'co2scope-app-root',
    templateUrl: './co2scope-app.component.html',
    styleUrls: ['./co2scope-app.component.css']
})
export class Co2scopeAppComponent implements AfterViewChecked, OnInit {

    title: string = 'CO2 Scope';

    @ViewChild(AlertingwatcherDirective) addWatcher: AlertingwatcherDirective;
    @ViewChild("upload", {
        static: true
    }) uploadChild: UploadComponent;
    @ViewChild("license", {
        static: true
    }) licenseChild: LicenseComponent;
    @ViewChild("monitor", {
        static: true
    }) monitorChild: MonitorComponent;
    @ViewChild("settings", {
        static: true
    }) settingsChild: SettingsComponent;
    @ViewChild("greenitsettings", {
        static: true
    }) greenitsettingsChild;
    @ViewChild("plugin", {
        static: true
    }) pluginChild: PluginComponent;
    @ViewChild("ldap", {
        static: true
    }) ldapChild: LdapComponent;
    @ViewChild("supervision", {
        read: ViewContainerRef
    }) supervisionChild;

    jsonLoader: Json;

    role: User;

    message: Message;

    //For tabs
    isShowTabs: boolean = true;

    // Filter
    filter_dsp: string = 'my filter';

    // License
    license_info: LicenseInfo;
    modal: LicenseType = LicenseType.MODAL;

    // Status
    instance_global_alert: number;
    instance_details: Array < Status > ;
    db_global_alert: number;
    db_details: Array < Status > ;

    // About
    isModalAbout: boolean = false;

    // Navigation
    start_url: string;
    end_url: string;
    from_router_subscription: Subscription;

    // Datalock modal
    isModalDatalock: boolean = false;

    // Alert
    alertWatcherComponent: any;

    // Session
    session_subscription: Subscription;

    /**
     * 
     */
    constructor(
        private titleService: Title,
        private componentFactoryResolver: ComponentFactoryResolver,
        private router: Router,
        private authentication_svc: AccountService,
        private settings_svc: SettingsService,
        private message_svc: ShareService,
        private management_svc: ManagementService,
        private json_svc: JsonloaderService,
        private license_svc: LicenseService,
        private monitor_svc: MonitorService,
        private cd: ChangeDetectorRef,
        private resolver: ComponentFactoryResolver,
        private virtindex_svc: VirtindexService,
        private greenit_svc: GreenitService) {

        router.resetConfig(this.getRoutes());

        this.titleService.setTitle(this.title);
    }

    /**
     * 
     */
    getRoutes = () => {
        return [{
            path: 'dashboard',
            component: DashboardComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'selectionview',
            component: SelectionviewComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'periodview',
            component: PeriodviewComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'filter',
            component: FiltermgtComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'clusterviews/synthesis',
            component: ClustersynthesisComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'dcviews/treemap',
            component: DcviewComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'dcviews/flowdiag',
            component: DcviewFlowchartComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'hostviews/synthesis',
            component: HostsynthesisComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'hostviews/information',
            component: HostinfoComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'hostviews/consumption',
            component: HostconsoverComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'vmviews/synthesis',
            component: VmsynthesisComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'vmviews/information',
            component: VminfoComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'vmviews/consumption',
            component: VmconsoverComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'login',
            component: LoginComponent
        }, {
            path: 'greenit/power',
            component: PowerComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'greenit/analysis',
            component: AnalysisComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'greenindex',
            component: VirtIndexComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'greenit/evolution',
            component: EvolutionComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'capacity',
            component: CapaplanComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'reporting',
            component: ReportComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'god',
            component: GraphOnDemandComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'god/resource/:resource_uuid',
            component: GraphOnDemandComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'license',
            component: LicenseComponent
        }, {
            path: 'recommendation',
            component: RecommendationComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'cost',
            component: CostmgtComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'dashboard-t6',
            component: T6Component,
            canActivate: [AuthGuard]
        }, {
            path: 'user',
            component: UsermgtComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'storage-overview',
            component: StorageOverviewComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'storage-overview-vm',
            component: StorageOverviewVmComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'storage-overview-snapshot',
            component: VmsnapshotComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'rpviews/information',
            component: RpinfoComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'rpviews/synthesis',
            component: RpsynthesisComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'clusterviews/information',
            component: ClusterinfoComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'elementsynthesis',
            component: ElementsynthesisComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'opportunities',
            component: OpportunitiesComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'simulation',
            component: SimulationComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'cloudcomparator',
            component: SimulationComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'cloudimpact',
            component: CloudimpactComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'cloud/:provider/:region',
            component: CloudComponent,
            canActivate: [AuthGuard]
        }, {
            path: 'my_cloud_pricing/edit',
            component: MycloudpricingComponent,
            canActivate: [AuthGuard]
        }, ];
    }

    /**
     * XXX Need async for checkSession
     */
    async ngOnInit(): Promise < void > {

        this.authentication_svc.user.subscribe(user => this.role = user);

        // Reload auth (for refresh)
        this.authentication_svc.reload();

        this.message_svc.currentMessage.subscribe(
            message => {
                this.message = message;
            }
        );

        this.json_svc.currentJson.subscribe(
            json => {
                this.jsonLoader = json
            }
        );

        this.router.events.subscribe(route => {

            // Sometimes url can contains a base and get parameters. For example, an URL such as
            // "http://foo.bar/a/b?a=1&b=2" can be divided in two parts:
            //    - an URL: http://foo.bar/a/b
            //    - one or several GET parameters: {a:1, b:2}
            let urlParts = this.router.url.toString().split("?");
            let url = urlParts[0];
            this.message.breadcrumpRoute = url;
            if (route instanceof NavigationStart) {
                this.start_url = this.router.url;
            }
            if (route instanceof NavigationEnd) {
                this.end_url = this.router.url;
            }

            // After login, get user filters, check current filter / check datalock
            if (this.start_url == "/login" && this.end_url == "/dashboard" && this.message.isNotLoginPage) {
                this.reload();
            }
        });

        //XXX check token : need to waiting for isNotLoginPage
        await this.checkSession();

        // After refresh, if session is valid
        if (this.message.isNotLoginPage) {
            this.reload();
        }

        this.license_svc.licenseInfo.subscribe(infos => {
            this.license_info = infos
        });

        this.monitor_svc.instanceGlobal.subscribe(global => this.instance_global_alert = global);
        this.monitor_svc.instanceDetails.subscribe(details => this.instance_details = details);
        this.monitor_svc.dbGlobal.subscribe(global => this.db_global_alert = global);
        this.monitor_svc.dbDetails.subscribe(details => this.db_details = details);

        this.virtindex_svc.virtindexStatus.subscribe(status => {
            let greenindex_status = status;

            // Send & receive data for green-index
            if (greenindex_status) {
                this.virtindex_svc.getVirtindexData();
            }
        });

    }

    ngAfterViewInit(): void {
        var element = document.getElementById("trm");
        $(element).children(".clr-col-md-2").remove();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }
    /**
     * 
     */
    private reload(): void {

        this.license_svc.getLicenseInfo();

        this.monitor_svc.getStatus();

        this.virtindex_svc.getVirtindexSettings();

        // Load alert watcher
        this.loadWatcher();

        // Load session ping
        this.loadSessionPing();

        // TODO : get myfilter & update this.message.currentFilter
        // XXX :  default this.message.currentFilter is 'all'

        this.from_router_subscription = this.management_svc.getFilterList(this.role.login).pipe(first()).subscribe(
            data => {
                // Update user filters
                this.message.filterList = [];
                this.message.filterList = data;
                this.addSharedFilter();

                // Load filter
                this.switchFilter('all');

                // Check datalock
                this.monitor_svc.checkDatalock().subscribe(
                    data => {
                        this.isModalDatalock = data.show;
                    }
                );
            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );
    }



    /**
     * For filters
     */
    switchFilter(val): void {
        let currentfilter: FilterMgt = this.getFilter(val);

        if (currentfilter.name != undefined) {

            this.message.currentFilter = currentfilter.name;

            // Init filter; check if jsons are valid, or create them !
            let options: Options = {
                reload: false,
                wd: false
            }
            // XXX Only reload 'cleared' filters
            let status = currentfilter.status;
            if (status == "cleared") {
                options.reload = true
            }

            // Load filter
            this.loadFilter(options);

            if (currentfilter.shared) {
                this.filter_dsp = 'shared filter';
                this.message.isShared = true;
            } else {
                this.filter_dsp = 'my filter';
                this.message.isShared = false;
            }

        } else {
            //XXX better UI : use info modal
            console.log('fail to find filter ' + val);
        }
    }


    private getFilter(name: string): FilterMgt {
        return this.message.filterList.find(filter => filter.name === name);
    }

    /**
     * Called by switchFilter
     * Called if settings changed
     */
    loadFilter(options: Options) {

        let reload_filter = false;
        let wd_change = false;

        if (options != null) {
            reload_filter = options.reload;
            wd_change = options.wd;
        }

        // Update filter settings
        let currentfilter = this.getFilter(this.message.currentFilter);

        this.message.elementView = currentfilter.element_view;
        this.message.periodView = currentfilter.time_view;

        this.management_svc.getTimeView(this.role.login).subscribe(
            views => {
                let timeview: TimeFilter = views.find(view => view.filter == currentfilter.time_view);
                if (timeview) {
                    this.message.minTimeFilter = timeview.begin;
                    this.message.maxTimeFilter = timeview.end;
                }
            },
            error => {
                //XXX better UI : use info modal
                console.log('fail to find timeview for filter ' + this.message.currentFilter);
            }
        );

        // Update elements type (for tabs)
        this.management_svc.initElementsType(this.role.login, currentfilter);

        // If WD have changed from settings
        if (wd_change) {
            // maybe current filter is WD filter
            // so need to switch to 'all'
            if (currentfilter != undefined && currentfilter.work_days == 1) {
                this.switchFilter('all');
                return; // DO NOT RELOAD FILTER IF WD FILTER
            }
        }

        // Reload filter
        if (reload_filter) {
            // Show progress
            this.message.waiting = true;

            // Check / create json files
            this.json_svc.checkJson(this.role.login, this.message.currentFilter).subscribe(
                complete => {
                    // Reload json
                    this.json_svc.changeJson(this.role.login, this.message.currentFilter);
                    // Update filter status
                    currentfilter.status = "ready";
                },
                error => {
                    // Reload json
                    this.json_svc.changeJson(this.role.login, this.message.currentFilter);
                    // Update filter status
                    currentfilter.status = "failed";
                }
            );

        } else {
            // Reload json
            this.json_svc.changeJson(this.role.login, this.message.currentFilter);

        }

        // Force update threshold & capacity & god settings & greenit settings from database
        this.settings_svc.reload(this.role.login, this.message.currentFilter, IhmSettingsTarget.RECOMMENDATION, true);
        this.settings_svc.reload(this.role.login, this.message.currentFilter, IhmSettingsTarget.CAPACITY, true);
        this.settings_svc.reload(this.role.login, this.message.currentFilter, IhmSettingsTarget.GOD, true);

        // Check data (extrapolation or not)
        this.greenit_svc.checkGreenitNetworkData(this.role.login, currentfilter);
        this.greenit_svc.checkGreenitStorageData(this.role.login, currentfilter);

        // FIX ExpressionChangedAfterItHasBeenCheckedError
        this.cd.detectChanges();
    }

    /**
     * 
     */
    private addSharedFilter(): void {

        if (this.role.login != "root") {
            this.management_svc.getViewsFromSharedFilter(this.role.login).pipe(first()).subscribe(
                data => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            const view = data[i].name;
                            const filter_id = data[i].id;
                            this.management_svc.testSharedFilter(this.role.login, view).pipe(first()).subscribe(
                                data => {
                                    if (data) {
                                        this.management_svc.getFilterFromId(filter_id).pipe(first())
                                            .subscribe(
                                                data => {
                                                    this.management_svc.addSymLink(this.role.login, data
                                                        .name).pipe(first()).subscribe();
                                                    this.message.filterList.push(data);
                                                }
                                            );
                                    }
                                }
                            );
                        }
                    }
                }
            );
        }
    }

    /**
     * Alerts
     */
    private loadWatcher(): void {
        if (this.addWatcher != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                AlertingwatcherComponent);
            const viewContainerRef = this.addWatcher.viewContainerRef;
            this.alertWatcherComponent = viewContainerRef.createComponent(componentFactory);
        }
    }

    private stopWatcher(): void {
        if (this.alertWatcherComponent != undefined)
            this.alertWatcherComponent.destroy();
    }

    /**
     * Session
     */
    private loadSessionPing(): void {
        let session_interval = interval(60000);
        this.session_subscription = session_interval.subscribe(val => this.checkSession());
    }

    private async checkSession(): Promise < void > {
        let isLogged = await this.authentication_svc.isLogged();
        if (isLogged)
            this.message.isNotLoginPage = true;
        else
            this.logout();
    }

    private stopSessionPing(): void {
        if (this.session_subscription != undefined)
            this.session_subscription.unsubscribe();
    }

    /**
     *
     */
    callAbout(): void {
        this.isModalAbout = true;
    }

    /**
     *
     */
    callDoc(lang: string): void {
        //update documentation href based on the version
        let docBaseUrl = "https://easyvirt.gitlab.io/dcscope/user-guide/v" + this.jsonLoader.version.release + "/" +
            lang;

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = docBaseUrl;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    /**
     *
     */
    callSettings(): void {
        this.settingsChild.callSettings();
    }

    /**
     *
     */
    callGreenitSettings(): void {
        this.greenitsettingsChild.displayModalSettings();
    }

    /**
     *
     */
    formatDate(time: number, showhour: boolean): string {
        if (!showhour)
            return new Date(time).toLocaleDateString('en-EN');
        else
            return new Date(time).toLocaleString('en-EN');
    }

    /**
     *
     */
    updateFlagColor(): string {
        let alert_max = Math.max(this.license_info.alert, Math.max(this.instance_global_alert, this
            .db_global_alert));
        return StatusColor[alert_max];
    }

    /**
     *
     */
    callLicense(): void {
        this.licenseChild.target = LicenseType.MODAL;
        this.licenseChild.displayModalLicense();
    }

    /**
     *
     */
    callUpload(): void {
        this.uploadChild.target = UploadType.UPDATES;
        this.uploadChild.displayModalUpload();
    }

    /**
     *
     */
    callArchive(): void {
        this.monitorChild.getArchive(this.role);
    }

    /**
     *
     */
    callMonitorList(): void {
        this.monitorChild.getMonitorList();
    }

    /**
     *
     */
    callPlugin(): void {
        //Update plugin properties
        this.pluginChild.update();
    }

    /**
     * 
     */
    callSupervision(): void {
        this.supervisionChild.clear();
        const factory: ComponentFactory < SupervisionComponent > = this.resolver.resolveComponentFactory(
            SupervisionComponent);
        const componentRef = this.supervisionChild.createComponent(factory);
        setTimeout(() => {
            componentRef.instance.displayModalSupervision();
        }, 100);
    }

    /**
     *
     */
    logout(): void {

        this.filter_dsp = 'my filter';

        /* RESET MESSAGE */
        this.message_svc.resetMessage();

        /* STOP ALERT WATCHER */
        this.stopWatcher();

        /* STOP SESSION PING */
        this.stopSessionPing();

        /* REMOVE SUBSCRIPTION */
        if (this.from_router_subscription != undefined)
            this.from_router_subscription.unsubscribe();

        /* RESET JSON */
        this.json_svc.resetJson();

        /* REMOVE INFO */
        this.license_svc.removeInfo();
        this.settings_svc.removeInfo();
        this.authentication_svc.logout();

        /* ROUTE TO LOGIN */
        this.router.navigate(['login']);

    }
}
