import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ComponentRef,
    HostListener
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    JsonloaderService,
    SettingsService,
    ShareService
} from '@app/services';

import {
    DashboardSettings,
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    T1Component
} from '@app/t1/t1.component';
import {
    T1Directive
} from '@app/directives/t1.directive';
import {
    T2Component
} from '@app/t2/t2.component';
import {
    T2Directive
} from '@app/directives/t2.directive';
import {
    T3Component
} from '@app/t3/t3.component';
import {
    T3Directive
} from '@app/directives/t3.directive';
import {
    T4Component
} from '@app/t4/t4.component';
import {
    T4Directive
} from '@app/directives/t4.directive';
import {
    T5Component
} from '@app/t5/t5.component';
import {
    T5Directive
} from '@app/directives/t5.directive';
import {
    T6Component
} from '@app/t6/t6.component';
import {
    T6Directive
} from '@app/directives/t6.directive';
import {
    T7Component
} from '@app/t7/t7.component';
import {
    T7Directive
} from '@app/directives/t7.directive';
import {
    T8Component
} from '@app/t8/t8.component';
import {
    T8Directive
} from '@app/directives/t8.directive';
import {
    T9Component
} from '@app/t9/t9.component';
import {
    T9Directive
} from '@app/directives/t9.directive';
import {
    T10Component
} from '@app/t10/t10.component';
import {
    T10Directive
} from '@app/directives/t10.directive';
import {
    T11Component
} from '@app/t11/t11.component';
import {
    T11Directive
} from '@app/directives/t11.directive';
import {
    Subscription
} from 'rxjs';
import {
    T14Component
} from '@app/t14/t14.component';
import {
    T14Directive
} from '@app/directives/t14.directive';

var currentUser: User;
var userSettings: DashboardSettings;
var componentRef: ComponentRef < any > ;
var t1Directive: T1Directive;
var componentRef2: ComponentRef < any > ;
var t2Directive: T2Directive;
var componentRef3: ComponentRef < any > ;
var t3Directive: T3Directive;
var componentRef4: ComponentRef < any > ;
var t4Directive: T4Directive;
var componentRef5: ComponentRef < any > ;
var t5Directive: T5Directive;
var componentRef6: ComponentRef < any > ;
var t6Directive: T6Directive;
var componentRef7: ComponentRef < any > ;
var t7Directive: T7Directive;
var componentRef8: ComponentRef < any > ;
var t8Directive: T8Directive;
var componentRef9: ComponentRef < any > ;
var t9Directive: T9Directive;
var componentRef10: ComponentRef < any > ;
var t10Directive: T10Directive;
var componentRef11: ComponentRef < any > ;
var t11Directive: T11Directive;
var componentRef14: ComponentRef < any > ;
var t14Directive: T14Directive;

import * as $ from 'jquery';

@Component({
    selector: 'app-vmware',
    templateUrl: './vmware.component.html',
    styleUrls: ['./vmware.component.css']
})
export class VmwareComponent implements AfterViewChecked, OnInit {

    t8: JSONTarget = JSONTarget.T8;

    message: Message;

    error: boolean = false;

    @ViewChild(T1Directive) addT1: T1Directive;
    @ViewChild(T2Directive) addT2: T2Directive;
    @ViewChild(T3Directive) addT3: T3Directive;
    @ViewChild(T4Directive) addT4: T4Directive;
    @ViewChild(T5Directive) addT5: T5Directive;
    @ViewChild(T6Directive) addT6: T6Directive;
    @ViewChild(T7Directive) addT7: T7Directive;
    @ViewChild(T8Directive) addT8: T8Directive;
    @ViewChild(T9Directive) addT9: T9Directive;
    @ViewChild(T10Directive) addT10: T10Directive;
    @ViewChild(T11Directive) addT11: T11Directive;
    @ViewChild(T14Directive) addT14: T14Directive;

    json_subscription: Array < Subscription > = [];
    settings_subscription: Array < Subscription > = [];

    isDcscope = true;
    isCo2scope = false;


    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
        private account_svc: AccountService,
        private settingsService: SettingsService,
        private message_svc: ShareService,
        private json_svc: JsonloaderService) {}

    ngOnInit(): void {
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.isCo2scope = this.message.isCO2Scope;
        this.isDcscope = this.message.isDCScope;

        this.account_svc.user.subscribe(
            user => {
                currentUser = user;

                if (currentUser.login == '') {
                    this.account_svc.reload();
                }

                // Load dashboard settings
                if (currentUser.login != '' && (userSettings == undefined || userSettings.user == ''))
                    this.settingsService.getUserSettings(currentUser.login).pipe(first()).subscribe();
            }
        );

    }

    ngAfterViewInit(): void {
        this.settings_subscription.push(this.settingsService.currentDS.subscribe(
            us => {
                userSettings = us;
                if (userSettings.user != "") {

                    this.setDirectives();

                    this.json_subscription.push(this.json_svc.currentJson.subscribe(
                        json => {
                            this.reloadCards();
                        }
                    ));

                }
            }
        ));
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.json_subscription != undefined) {
            this.json_subscription.forEach(element => {
                element.unsubscribe();
            });
        }
        if (this.settings_subscription != undefined) {
            this.settings_subscription.forEach(element => {
                element.unsubscribe();
            });
        }
        //console.log("destroyed dashboad");
    }

    reloadCards(): void {

        if (componentRef != undefined)
            componentRef.destroy();
        if (componentRef2 != undefined)
            componentRef2.destroy();
        if (componentRef3 != undefined)
            componentRef3.destroy();
        if (componentRef4 != undefined)
            componentRef4.destroy();
        if (componentRef5 != undefined)
            componentRef5.destroy();
        if (componentRef6 != undefined)
            componentRef6.destroy();
        if (componentRef7 != undefined)
            componentRef7.destroy();
        if (componentRef8 != undefined)
            componentRef8.destroy();
        if (componentRef9 != undefined)
            componentRef9.destroy();
        if (componentRef10 != undefined)
            componentRef10.destroy();
        if (componentRef11 != undefined)
            componentRef11.destroy();
        if (componentRef14 != undefined)
            componentRef14.destroy();

        setTimeout(() => this.loadCards(), 100);
    }

    reloadCard(card: string): void {

        let component_ref: ComponentRef < any > ;
        let card_permission: boolean = false;

        switch (card) {
            case "t1":
                component_ref = componentRef;
                card_permission = currentUser.isDashboardT1;
                break;
            case "t2":
                component_ref = componentRef2;
                card_permission = currentUser.isDashboardT2;
                break;
            case "t3":
                component_ref = componentRef3;
                card_permission = currentUser.isDashboardT3;
                break;
            case "t4":
                component_ref = componentRef4;
                card_permission = currentUser.isDashboardT4;
                break;
            case "t5":
                component_ref = componentRef5;
                card_permission = currentUser.isDashboardT5;
                break;
            case "t6":
                component_ref = componentRef6;
                card_permission = currentUser.isDashboardT6;
                break;
            case "t7":
                component_ref = componentRef7;
                card_permission = true;
                break;
            case "t8":
                component_ref = componentRef8;
                card_permission = true;
                break;
            case "t9":
                component_ref = componentRef9;
                card_permission = true;
                break;
            case "t10":
                component_ref = componentRef10;
                card_permission = true;
                break;
            case "t11":
                component_ref = componentRef11;
                card_permission = true;
                break;
            case "t14":
                component_ref = componentRef14;
                card_permission = true;
                break;
            default:
                break;
        }
        if (component_ref != undefined) {
            component_ref.destroy();
            if (card_permission)
                setTimeout(() => this.checkSettingCard(card), 100);
        }
    }

    unLoadCard(card: string): void {

        let component_ref: ComponentRef < any > ;
        let id: string = '';

        switch (card) {
            case "t1":
                component_ref = componentRef;
                id = '#dashboard-t1';
                break;
            case "t2":
                component_ref = componentRef2;
                id = '#dashboard-t2';
                break;
            case "t3":
                component_ref = componentRef3;
                id = '#dashboard-t3';
                break;
            case "t4":
                component_ref = componentRef4;
                id = '#dashboard-t4';
                break;
            case "t5":
                component_ref = componentRef5;
                id = '#dashboard-t5';
                break;
            case "t6":
                component_ref = componentRef6;
                id = '#dashboard-t6';
                break;
            case "t7":
                component_ref = componentRef7;
                id = '#dashboard-t7';
                break;
            case "t8":
                component_ref = componentRef8;
                id = '#dashboard-t8';
                break;
            case "t9":
                component_ref = componentRef9;
                id = '#dashboard-t9';
                break;
            case "t10":
                component_ref = componentRef10;
                id = '#dashboard-t10';
                break;
            case "t11":
                component_ref = componentRef11;
                id = '#dashboard-t11';
                break;
            case "t14":
                component_ref = componentRef14;
                id = '#dashboard-t14';
                break;
            default:
                break;
        }
        if (component_ref != undefined) {
            component_ref.destroy();
            $(id).hide();
        }
    }

    private setDirectives(): void {

        if (this.addT1 != undefined)
            t1Directive = this.addT1;

        if (this.addT2 != undefined)
            t2Directive = this.addT2;

        if (this.addT3 != undefined)
            t3Directive = this.addT3;

        if (this.addT4 != undefined)
            t4Directive = this.addT4;

        if (this.addT5 != undefined)
            t5Directive = this.addT5;

        if (this.addT6 != undefined)
            t6Directive = this.addT6;

        if (this.addT7 != undefined)
            t7Directive = this.addT7;

        if (this.addT8 != undefined)
            t8Directive = this.addT8;

        if (this.addT9 != undefined)
            t9Directive = this.addT9;

        if (this.addT10 != undefined)
            t10Directive = this.addT10;

        if (this.addT11 != undefined)
            t11Directive = this.addT11;

        if (this.addT14 != undefined)
            t14Directive = this.addT14;

    }

    private loadCards(): void {

        if (currentUser.isDashboardT1)
            this.checkT1();

        if (currentUser.isDashboardT2)
            this.checkT2();

        if (currentUser.isDashboardT3)
            this.checkT3();

        if (currentUser.isDashboardT4)
            this.checkT4();

        if (currentUser.isDashboardT5)
            this.checkT5();

        if (currentUser.isDashboardT6)
            this.checkT6();

        // TODO -> T7 - 11
        //if (currentUser.isDashboardT6)
        this.checkT7();

        //if (currentUser.isDashboardT6)
        this.checkT8();

        //if (currentUser.isDashboardT6)
        this.checkT9();

        //if (currentUser.isDashboardT6)
        this.checkT10();

        //if (currentUser.isDashboardT6)
        this.checkT11();

        //XXX
        this.checkT14();
    }

    private loadT1Card(): void {

        $('#dashboard-t1').show();
        if (t1Directive != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T1Component);
            const viewContainerRef = t1Directive.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadT2Card(): void {

        $('#dashboard-t2').show();
        if (t2Directive != undefined) {
            const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(T2Component);
            const viewContainerRef2 = t2Directive.viewContainerRef2;
            viewContainerRef2.clear();
            componentRef2 = viewContainerRef2.createComponent(componentFactory2);
        }
    }

    private loadT3Card(): void {

        $('#dashboard-t3').show();
        if (t3Directive != undefined) {
            const componentFactory3 = this.componentFactoryResolver.resolveComponentFactory(T3Component);
            const viewContainerRef3 = t3Directive.viewContainerRef3;
            viewContainerRef3.clear();
            componentRef3 = viewContainerRef3.createComponent(componentFactory3);
        }
    }

    private loadT4Card(): void {

        $('#dashboard-t4').show();
        if (t4Directive != undefined) {
            const componentFactory4 = this.componentFactoryResolver.resolveComponentFactory(T4Component);
            const viewContainerRef4 = t4Directive.viewContainerRef4;
            viewContainerRef4.clear();
            componentRef4 = viewContainerRef4.createComponent(componentFactory4);
        }
    }

    private loadT5Card(): void {

        $('#dashboard-t5').show();
        if (t5Directive != undefined) {
            const componentFactory5 = this.componentFactoryResolver.resolveComponentFactory(T5Component);
            const viewContainerRef5 = t5Directive.viewContainerRef5;
            viewContainerRef5.clear();
            componentRef5 = viewContainerRef5.createComponent(componentFactory5);
        }
    }

    private loadT6Card(): void {

        $('#dashboard-t6').show();
        if (t6Directive != undefined) {
            const componentFactory6 = this.componentFactoryResolver.resolveComponentFactory(T6Component);
            const viewContainerRef6 = t6Directive.viewContainerRef6;
            viewContainerRef6.clear();
            componentRef6 = viewContainerRef6.createComponent(componentFactory6);
        }
    }

    private loadT7Card(): void {

        $('#dashboard-t7').show();
        if (t7Directive != undefined) {
            const componentFactory7 = this.componentFactoryResolver.resolveComponentFactory(T7Component);
            const viewContainerRef7 = t7Directive.viewContainerRef7;
            viewContainerRef7.clear();
            componentRef7 = viewContainerRef7.createComponent(componentFactory7);
        }
    }

    private loadT8Card(): void {

        $('#dashboard-t8').show();
        if (t8Directive != undefined) {
            const componentFactory8 = this.componentFactoryResolver.resolveComponentFactory(T8Component);
            const viewContainerRef8 = t8Directive.viewContainerRef8;
            viewContainerRef8.clear();
            componentRef8 = viewContainerRef8.createComponent(componentFactory8);
        }
    }

    private loadT9Card(): void {

        $('#dashboard-t9').show();
        if (t9Directive != undefined) {
            const componentFactory9 = this.componentFactoryResolver.resolveComponentFactory(T9Component);
            const viewContainerRef9 = t9Directive.viewContainerRef9;
            viewContainerRef9.clear();
            componentRef9 = viewContainerRef9.createComponent(componentFactory9);
        }
    }

    private loadT10Card(): void {

        $('#dashboard-t10').show();
        if (t10Directive != undefined) {
            const componentFactory10 = this.componentFactoryResolver.resolveComponentFactory(T10Component);
            const viewContainerRef10 = t10Directive.viewContainerRef10;
            viewContainerRef10.clear();
            componentRef10 = viewContainerRef10.createComponent(componentFactory10);
        }
    }

    private loadT11Card(): void {

        $('#dashboard-t11').show();
        if (t11Directive != undefined) {
            const componentFactory11 = this.componentFactoryResolver.resolveComponentFactory(T11Component);
            const viewContainerRef11 = t11Directive.viewContainerRef11;
            viewContainerRef11.clear();
            componentRef11 = viewContainerRef11.createComponent(componentFactory11);
        }
    }

    private loadT14Card(): void {

        $('#dashboard-t14').show();
        if (t14Directive != undefined) {
            const componentFactory14 = this.componentFactoryResolver.resolveComponentFactory(T14Component);
            const viewContainerRef14 = t14Directive.viewContainerRef14;
            viewContainerRef14.clear();
            componentRef14 = viewContainerRef14.createComponent(componentFactory14);
        }
    }

    private checkSettingCard(card: string): void {

        switch (card) {
            case "t1":
                this.checkT1();
                break;
            case "t2":
                this.checkT2();
                break;
            case "t3":
                this.checkT3();
                break;
            case "t4":
                this.checkT4();
                break;
            case "t5":
                this.checkT5();
                break;
            case "t6":
                this.checkT6();
                break;
            case "t7":
                this.checkT7();
                break;
            case "t8":
                this.checkT8();
                break;
            case "t9":
                this.checkT9();
                break;
            case "t10":
                this.checkT10();
                break;
            case "t11":
                this.checkT11();
                break;
            case "t14":
                this.checkT14();
                break;
            default:
                break;
        }
    }

    private checkT1(): void {

        this.loadT1Card();
        if (!userSettings.isT1)
            $('#dashboard-t1').hide();
    }

    private checkT2(): void {

        this.loadT2Card();
        if (!userSettings.isT2)
            $('#dashboard-t2').hide();
    }

    private checkT3(): void {

        this.loadT3Card();
        if (!userSettings.isT3)
            $('#dashboard-t3').hide();
    }

    private checkT4(): void {

        this.loadT4Card();
        if (!userSettings.isT4)
            $('#dashboard-t4').hide();
    }

    private checkT5(): void {

        this.loadT5Card();
        if (!userSettings.isT5)
            $('#dashboard-t5').hide();
    }

    private checkT6(): void {

        this.loadT6Card();
        if (!userSettings.isT6)
            $('#dashboard-t6').hide();
    }

    private checkT7(): void {

        this.loadT7Card();
        //if (!userSettings.isT7)
        //$('#dashboard-t7').hide();
    }

    private checkT8(): void {

        this.loadT8Card();
        //if (!userSettings.isT8)
        //$('#dashboard-t8').hide();
    }
    private checkT9(): void {

        this.loadT9Card();
        //if (!userSettings.isT9)
        //$('#dashboard-t9').hide();
    }

    private checkT10(): void {

        this.loadT10Card();
        //if (!userSettings.isT10)
        //$('#dashboard-t10').hide();
    }

    private checkT11(): void {

        this.loadT11Card();
        //if (!userSettings.isT11)
        //$('#dashboard-t11').hide();
    }

    private checkT14(): void {

        this.loadT14Card();
        //if (!userSettings.isT14)
        //$('#dashboard-t14').hide();
    }
}
