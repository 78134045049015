import {
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import {
    interval,
    Subscription
} from 'rxjs';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    AuditService,
    ShareService
} from '@app/services';

import {
    Message,
    User,
    Watcher
} from '@app/model';


@Component({
    selector: 'app-alertingwatcher',
    templateUrl: './alertingwatcher.component.html',
    styleUrls: ['./alertingwatcher.component.css']
})
export class AlertingwatcherComponent implements OnDestroy, OnInit {

    message: Message;

    subscription: Subscription;

    private currentUser: User;


    constructor(
        private authentication_svc: AccountService,
        private message_svc: ShareService,
        private audit_svc: AuditService) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);
        this.message.alertWatcher = {
            rt_cri: 0,
            rt_warn: 0,
            rt_run: 0,
            dy_cri: 0,
            dy_warn: 0,
            dy_run: 0,
            all_cri: 0
        };

        this.getWatcher();

        const source = interval(60000);
        this.subscription = source.subscribe(val => this.getWatcher());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private getWatcher(): void {

        //console.log("call alert watcher for " + this.currentUser.login);

        this.audit_svc.getMetaWatcher(this.currentUser.login, this.message.elementView).pipe(first()).subscribe(
            data => {
                this.message.alertWatcher.all_cri = data.nb_cri;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
        this.audit_svc.getMetaAlert(this.currentUser.login, this.message.elementView, 'min').pipe(first())
            .subscribe(
                data => {
                    this.message.alertWatcher.rt_cri = data.nb_cri;
                    this.message.alertWatcher.rt_warn = data.nb_warn;
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        this.audit_svc.getMetaRunAlert(this.currentUser.login, this.message.currentFilter, 'min').pipe(first())
            .subscribe(
                data => {
                    this.message.alertWatcher.rt_run = data.nb;
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        this.audit_svc.getMetaAlert(this.currentUser.login, this.message.currentFilter, 'day').pipe(first())
            .subscribe(
                data => {
                    this.message.alertWatcher.dy_cri = data.nb_cri;
                    this.message.alertWatcher.dy_warn = data.nb_warn;
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
        this.audit_svc.getMetaRunAlert(this.currentUser.login, this.message.currentFilter, 'day').pipe(first())
            .subscribe(
                data => {
                    this.message.alertWatcher.dy_run = data.nb;
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
    }
}
