import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    GreenitTarget
} from './greenit.enums';

import {
    User,
    Message,
    JSONTarget
} from '@app/model';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';

import {
    faLeaf
} from '@fortawesome/free-solid-svg-icons';

import {
    getUserCurrency
} from '../../assets/js/tools';

import {
    EfficiencylistComponent
} from './efficiencylist/efficiencylist.component';


@Component({
    selector: 'app-greenit',
    templateUrl: './greenit.component.html',
    styleUrls: ['./greenit.component.css'],
})
export class GreenitComponent implements OnInit {

    @ViewChild("child") child: EfficiencylistComponent;

    target: GreenitTarget = GreenitTarget.DC_HOST_CEE;

    math = Math;
    number = Number;

    message: Message;

    currentUser: User;
    globalCurrency: string;

    shared_user: string;

    // FOR EE
    efficiency_data: any = [];
    renewal_data: any = [];

    /**
     * 
     */
    constructor(private authenticationService: AccountService, private json_svc: JsonloaderService,
        private message_svc: ShareService, library: FaIconLibrary) {

        library.addIcons(faLeaf);
    }


    /**
     * 
     */
    ngOnInit(): void {
        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        //XXX Check shared filter
        this.shared_user = this.currentUser.login;
        if (this.message.isShared) {
            this.shared_user = "root";
        }

        // Display DC
        this.displayDC();
    }

    /**
     * 
     */
    displayDC(): void {
        this.initCEE();
    }

    /**
     *
     */
    initCEE(): void {
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_DC_CEE)
            .subscribe(
                data => {
                    if (data != undefined && ( < any > data).length > 0) {
                        this.efficiency_data = data;

                        // Property name in lower case 
                        this.efficiency_data = this.efficiency_data.map(x => {
                            for (let prop in x) {
                                x[prop.toLowerCase()] = x[prop];
                                delete x[prop];
                            }
                            return x;
                        })

                        // Keep only RUNNING servers (only if DC_HOST_CEE)
                        // Keep NEW for calculator
                        if (this.target == GreenitTarget.DC_HOST_CEE) {
                            this.renewal_data = this.efficiency_data.find((e: {
                                name: string;
                            }) => (e.name == "RENEWAL_SERVER"));
                            this.efficiency_data = this.efficiency_data.filter((e: {
                                state: string;
                            }) => (e.state == "RUNNING"));
                        }
                    }
                }
            );
    }

    /**
     * 
     */
    displayModalEfficiency(): void {
        this.child.displayModalEfficiency();
    }

    /**
     * 
     */
    displayModalOptimization(): void {
        this.child.displayModalOptimization();
    }

    /**
     * 
     */
    formatNumber(value: number): string {
        return Intl.NumberFormat().format(value);
    }
}
