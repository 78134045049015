<ng-template #templateSelectedResources let-item="item" xmlns="http://www.w3.org/1999/html">
    <span [attr.class]="item?.resourceType == 'host' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ item?.resourceType }}</span>
    {{ item?.name }}
</ng-template>

<ng-template #templateDisplayResources let-item='item'>
    <span class="badge badge badge-light-blue">vm</span>
    {{ item.name }}
</ng-template>

<ng-template #templateDisplayResourcesWhenEmpty>
    <clr-control-helper slot="displayWhenEmpty">Type a resource name</clr-control-helper>
</ng-template>

<ng-template #templateDisplayResourcesWhenErrors>
    <clr-control-error slot="displayWhenError">There was an error</clr-control-error>
</ng-template>

<ng-template #listOfTagsManual>
    <div class="card" *ngFor="let appGroup of manualAppGroups">
        <div class="card-header">
            <span *ngIf="!appGroup.showEditingFeatures">
                {{appGroup.name}}

                <span style="margin-left: 5px;">
                    <a style="margin-right: 5px; cursor: pointer;" routerLink="/netscope/dependencies-viewer/apps_groups/{{appGroup.name}}" routerLinkActive="active"><cds-icon shape="recycle"></cds-icon></a>
                    <a style="margin-right: 5px; cursor: pointer;" routerLink="/netscope/protocol-analysis/apps_groups/{{appGroup.name}}" routerLinkActive="active"><cds-icon shape="chat-bubble"></cds-icon></a>
                    <a style="margin-right: 5px; cursor: pointer;" routerLink="/netscope/clusters-viewer/apps_groups/{{appGroup.name}}" routerLinkActive="active"><cds-icon shape="organization"></cds-icon></a>
                </span>
            </span>
            <span *ngIf="appGroup.showEditingFeatures">
                <input type="text" [(ngModel)]="appGroup.name"/>
            </span>

            <a role="tooltip" aria-haspopup="true" style="margin-left: 5px;" class="tooltip tooltip-lg tooltip-right" *ngIf="appGroup.nameIsValid !== undefined && !appGroup.nameIsValid">
                <cds-icon style="color: red;" shape="error-standard" ></cds-icon>
                <span class="tooltip-content">
                    The name is invalid. Please use the following chars:
                    <ul>
                        <li>Lowercase characters [a-z]</li>
                        <li>Uppercase characters [A-Z]</li>
                        <li>Numbers [0-9]</li>
                        <li>Underscode [_]</li>
                    </ul>
                </span>
            </a>

            <span style="margin-left: 5px; float: right; cursor: pointer;">
                <span class="label label-blue" style="cursor: pointer;" (click)="startEditing(appGroup)">edit</span>
                <span class="label label-blue" (click)="stopEditing(appGroup)" style="cursor: pointer;">save</span>
                <span class="label label-danger" (click)="deleteAppGroup(appGroup)">delete</span>
            </span>
        </div>
        <div class="card-block">
            <div class="card-text">
                <app-apps-groups-combobox
                    [(selectedItems)]="appGroup.selectedResources"
                    [itemsGenerator]="fetchVms"
                    (onChange)="vmSelectionChanged(appGroup, $event)"
                    [templateSelectedItems]="templateSelectedResources"
                    [templateDisplayItems]="templateDisplayResources"
                    [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
                    [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
                    [itemsField]="'name'"
                    *ngIf="appGroup.showEditingFeatures"
                >
                </app-apps-groups-combobox>

                <span *ngFor="let vm of appGroup.vms" class="label label-light-blue clickable">
                    <span class="badge" style="margin: 0 5px 0 0;">vm</span>
                    {{ vm.name }}
                    <cds-icon shape="times-circle" solid
                      style="margin-left: 5px; color: gray;"
                      (click)="deleteVmFromAppGroup(appGroup, vm)"
                      *ngIf="appGroup.showEditingFeatures">
                    </cds-icon>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #listOfTagsVMwareAutomatic>
    <div class="card" *ngFor="let tag of tagsVMware">
        <div class="card-header">
            <span>
                {{tag.name}}

                <span class="label label-warning">
                    automatic
                </span>

                <span style="margin-left: 5px;">
                    <a style="margin-right: 5px; cursor: pointer;" routerLink="/netscope/dependencies-viewer/apps_groups/{{tag.name}}" routerLinkActive="active"><cds-icon shape="recycle"></cds-icon></a>
                    <a style="margin-right: 5px; cursor: pointer;" routerLink="/netscope/protocol-analysis/apps_groups/{{tag.name}}" routerLinkActive="active"><cds-icon shape="chat-bubble"></cds-icon></a>
                    <a style="margin-right: 5px; cursor: pointer;" routerLink="/netscope/clusters-viewer/apps_groups/{{tag.name}}" routerLinkActive="active"><cds-icon shape="organization"></cds-icon></a>
                </span>
            </span>

            <span style="margin-left: 5px; float: right; cursor: pointer;">
                <span class="label label-danger" (click)="deleteVMwareTag(tag)">delete</span>
            </span>
        </div>
        <div class="card-block">
            <div class="card-text">
                <span *ngFor="let vm of tag.appGroup.vms" class="label label-light-blue clickable">
                    <span class="badge" style="margin: 0 5px 0 0;">vm</span>
                    {{ vm.name }}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #listOfCategoriesVMwareAutomatic>
    <div class="card" *ngFor="let categorie of categoriesVMware">
        <div class="card-header">
            <span *ngIf="!categorie.showEditingFeatures">
                {{categorie.name}}
                <span class="label label-warning">
                    automatic
                </span>
            </span>

            <span style="margin-left: 5px; float: right; cursor: pointer;">
                <span class="label label-danger" (click)="deleteCategorie(categorie)">delete</span>
            </span>
        </div>
        <div class="card-block">
            <div class="card-text">
                <span *ngFor="let tag of categorie.tags" class="label label-2 clickable">
                    <span class="badge" style="margin: 0 5px 0 0;">tag</span>
                    {{ tag }}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<app-apps-groups-wizard-tags-selection
    [vms]="this.vms"
    #wizard>
</app-apps-groups-wizard-tags-selection>

<div class="clr-row" style="width: 100%; margin-bottom: 10px;" *ngIf="failureMode && !isLoadingJson">
    <div class="clr-col-12">
        <div class="alert alert-danger" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                            Cannot load data from DC Netscope API. Please check the diagnostic page:
                            <a routerLink="/netscope/diagnostic" routerLinkActive="active">
                                <cds-icon shape="checkbox-list"></cds-icon>
                                Diagnostic
                            </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="clr-row" style="width: 100%">
    <div class="clr-col-8">
        <h3 style="margin: 5px;">
            Apps groups

            <a [routerLink]="['/netscope/flows/apps-groups']"><button class="btn btn-sm">Back to apps groups</button></a>

            <button (click)="openWizard()" class="btn btn-primary btn-sm btn-icon" style="z-index: 1; margin-left: 15px;">
                <clr-spinner clrInline [clrInverse]="true" *ngIf="wizardIsLoading">
                </clr-spinner>
                + new app group
            </button>

            <clr-dropdown>
                <button class="btn btn-primary btn-sm btn-icon" clrDropdownTrigger>
                    Export
                    <cds-icon shape="angle" direction="down"></cds-icon>
                </button>
                <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                    <label class="dropdown-header" aria-hidden="true">
                        Export as CSV file
                    </label>
                    <div aria-label="Dropdown Manual apps groups" clrDropdownItem (click)="exportManualAppsGroupsAsCSV()">Manual apps groups</div>
                    <div aria-label="Dropdown VMs not belonging to an app-group" clrDropdownItem (click)="exportVmsNotBelongingToAnAppGroupAsCSV()">VMs not belonging to an app-group</div>
                    <div class="dropdown-divider"></div>
                    <label class="dropdown-header" aria-hidden="true">
                        <clr-radio-container clrInline>
                            <label>CSV separator</label>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value="," [(ngModel)]="csvSperator" />
                                <label>comma</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value=";" [(ngModel)]="csvSperator" />
                                <label>semi-colon</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>
                    </label>
                </clr-dropdown-menu>
            </clr-dropdown>


            <span *ngIf="isLoadingJson" style="margin: 0px; margin-left: 5px;">
                <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
            </span>
        </h3>
    </div>
    <div class="clr-col-4">
    <span *ngIf="!isLoadingJson" style="float: right;">
        <button (click)="reloadAppsGroupsDataFromServer()" class="btn btn-primary btn-sm" style="z-index: 1; float: right;">
            <cds-icon shape="download"></cds-icon>
            pull from server
        </button>
        <button class="btn btn-outline btn-sm" style="z-index: 1; float: right;" *ngIf="isPushingAppsGroups">
            <clr-spinner clrInline>
            </clr-spinner>
            pushing
        </button>
        <button (click)="pushData()" class="btn btn-success btn-sm" style="z-index: 1; float: right;" *ngIf="!isPushingAppsGroups">
            <cds-icon shape="upload"></cds-icon>
            push to server
        </button>
    </span>
    </div>
</div>

<div class="clr-row" style="width: 100%;">
    <clr-tabs style="width: 100%;">
        <clr-tab>
            <button clrTabLink>Manual apps groups <span class="badge">{{ manualAppGroups !== undefined ? manualAppGroups.length : 0 }}</span></button>
            <ng-template [(clrIfActive)]="manualAppsGroupsIsActive">
                <clr-tab-content>
                    <ng-container *ngTemplateOutlet="listOfTagsManual"></ng-container>
                </clr-tab-content>
            </ng-template>
        </clr-tab>

        <clr-tab>
            <button clrTabLink>VMware tags <span class="badge">{{ tagsVMware !== undefined ? tagsVMware.length : 0 }}</span></button>
            <ng-template [(clrIfActive)]="vmwareTagsIsActive">
                <clr-tab-content>
                    <ng-container *ngTemplateOutlet="listOfTagsVMwareAutomatic"></ng-container>
                </clr-tab-content>
            </ng-template>
        </clr-tab>

        <clr-tab>
            <button clrTabLink>VMware categories <span class="badge">{{ categoriesVMware !== undefined ? categoriesVMware.length : 0  }}</span></button>
            <ng-template [(clrIfActive)]="vmwareCategoriesIsActive">
                <clr-tab-content>
                    <ng-container *ngTemplateOutlet="listOfCategoriesVMwareAutomatic"></ng-container>
                </clr-tab-content>
            </ng-template>
        </clr-tab>
    </clr-tabs>

</div>
