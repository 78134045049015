<div class="card">
	<div class="card-header card-adjust">
		<span class="export"></span>
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<span class="search"></span>
	</div>
	<div class="card-block">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<table id="host-vm-list" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  			<thead>
    				<tr>
      					<th>NAME</th>
      					<th>VCPU</th>
      					<th>VRAM (MB)</th>
      					<th>OPERATING SYSTEM</th>
      					<th>STATE</th>
      					<th>TOOLS</th>
      					<th *ngIf="currentUser.isDashboardT6">COST ({{globalCurrency}})</th>
    				</tr>
  			</thead>
  			<tbody>
    				<tr *ngFor="let vm of filterVm()" ngClass="addLink" (click)="callroute(vm)" class="addLink">
					<td>{{vm.name}}</td>
					<td>{{vm.vcpu}}</td>
					<td>{{vm.vram}}</td>
					<td>{{vm.os}}</td>
					<td>{{vm.state}}</td>
					<td>{{vm.tools}}</td>
					<td *ngIf="currentUser.isDashboardT6">{{formatCost(math.round(vm.cost))}}</td>
    				</tr>
  			</tbody>
  		</table>
	</div>
</div>
