<clr-alert *ngIf="show_example" [clrAlertType]="'info'" [clrAlertAppLevel]=true>
	<clr-alert-item>
		<span class="alert-text">
				Use the toggle button to participate and access to this feature.
			</span>
		</clr-alert-item>
</clr-alert>

<div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
        <clr-toggle-container style="flex-direction:row;margin-top:0.3rem">
            <label>
                <cds-icon shape="bell-curve" size="48"></cds-icon>
                <span style="font-size:28px;vertical-align:text-bottom;">&nbsp;Green-Index</span>
            </label>
            <clr-toggle-wrapper *ngIf="role.login == 'root'" style="margin-left:20px;">
                <input type="checkbox" clrToggle [(ngModel)]="settings_status" (click)="toggleAction()"/>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</div>

 <hr>

<!--TOGGLE OFF-->
<div *ngIf="show_example">
    <div class="clr-row">
        <div class="clr-col-lg-4">
            <div class="info-custom">
                <p><cds-icon size="24" shape="help-info"></cds-icon>
                    <b>Green-Index is a collaborative feature that aims to evaluate and compare some data around Green IT.</b>
                    <br><br>
                    This feature allows you to observe the data of other users and compare yourself to others.
                    <br><br>
                    <i>Examples : is my average energy consumption high ? Do I have a good VM per server ratio ? ...</i>
                </p>
            </div>
            <br>
            <div class="alert alert-warning" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                        </div>
                        <span class="alert-text" style="font-size:13px;text-align:justify;">
                            <b>To benefit from this feature it's necessary to collect some <u>anonymized</u> metrics :</b><br>
                            <ng-template [ngTemplateOutlet]="metrics"></ng-template>
                        </span>
                    </div>
                </div>
            </div>
            <br>
            <div style="text-align:center;">
                <cds-icon class="alert-icon" shape="check" style="color:#306b00" size="24"></cds-icon>&nbsp;<b>Thank you for your participation !</b>
            </div>
        </div>
        <div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
            <img src="../../assets/img/virtindex.png" width="100%" style="margin-left:20px;filter:grayscale(100%);display:block;" />
        </div>
    </div>
</div>

<!--TOGGLE ON-->
<div *ngIf="show_data">
    <clr-tabs>
        <clr-tab>
            <button clrTabLink>Overview</button>
            <ng-template [(clrIfActive)]="overview_active">
                <clr-tab-content>
                    <ng-template [ngTemplateOutlet]="defaultalert"></ng-template>
                    <div class="clr-row">
                        <div class="clr-col-lg-2 clr-col-md-8 clr-col-12"></div>
                        <div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
                            <div class="card">
                                <div class="card-header card-ajust">
                                   <h5><cds-icon shape="line-chart"></cds-icon>&nbsp;Carbon footprint per VM</h5>
                                </div>
                                <div class="card-block">
                                    <div class="clr-row">
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <div style="margin-top: 10%;margin-left: 10%;">
                                                <app-gaugechart [data]="carbon_radar[9]"></app-gaugechart>
                                            </div>
                                        </div>
                                        <div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
                                            <app-polarchart (tab)="changeTab($event)" [data]="carbon_radar"></app-polarchart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clr-col-lg-2 clr-col-md-8 clr-col-12"></div>
                    </div>
                </clr-tab-content>
            </ng-template>
        </clr-tab>
        <clr-tab>
            <button clrTabLink>Green IT</button>
             <ng-template [(clrIfActive)]="greenit_active">
                <clr-tab-content>
                    <ng-template [ngTemplateOutlet]="defaultalert"></ng-template>
                    <div class="card">
                        <div class="card-header card-ajust">
                            <h5>
                                <cds-icon shape="line-chart"></cds-icon>&nbsp;Green IT
                            </h5>
                        </div>
                        <div class="card-block">
                            <div class="clr-row" style="padding-top:40px;">
                                <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                    <div class="datagrid-limit-height" >
                                        <clr-datagrid>
                                            <clr-dg-action-bar style="margin-top:0;">
                                                <div class="btn-group" style="float:right">
                                                    <button type="button" class="btn btn-sm btn-link" (click)="exportGreenit()">
                                                        Export to CSV
                                                    </button>
                                                </div>
                                            </clr-dg-action-bar>

                                            <clr-dg-column [clrDgField]="'value.name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}"></ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.you'"><ng-container *clrDgHideableColumn="{hidden: false}">My infrastructure</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.min'"><ng-container *clrDgHideableColumn="{hidden: false}">Min</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.median'"><ng-container *clrDgHideableColumn="{hidden: false}">Median</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.max'"><ng-container *clrDgHideableColumn="{hidden: false}">Max</ng-container></clr-dg-column>
                                            
                                            <clr-dg-row *clrDgItems="let data of greenit_data | keyvalue" [clrDgItem]="data.value">
                                                <clr-dg-cell>{{data.value.name}}</clr-dg-cell>
                                                <clr-dg-cell><span [style.color]="data.value.color"><b>{{data.value.you}}</b></span></clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.min)}}</clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.median)}}</clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.max)}}</clr-dg-cell>
                                            </clr-dg-row>

                                            <clr-dg-footer>
                                                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                                                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                                </clr-dg-pagination>
                                            </clr-dg-footer>

                                        </clr-datagrid>
                                    </div>
                                </div>
                                <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
                                    <div class="clr-row">
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="greenit_data.co2tvm"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="greenit_data.co2gserver"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="greenit_data.co2gvm"></app-bullet>
                                        </div>
                                    </div>
                                
                                    <div class="clr-row">
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="greenit_data.kwhserver"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="greenit_data.ageserver"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="greenit_data.wattsvm"></app-bullet>
                                        </div>
                                    </div>
                                    <div class="clr-row">
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="greenit_data.coeffco2server"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="greenit_data.pueserver"></app-bullet>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </clr-tab-content>
             </ng-template>
        </clr-tab>
        <clr-tab>
            <button clrTabLink>Infrastructure</button>
            <ng-template [(clrIfActive)]="infrastructure_active">
                <clr-tab-content>
                    <div class="card">
                        <div class="card-header card-ajust">
                            <h5>
                                <cds-icon shape="line-chart"></cds-icon>&nbsp;Consolidation ratio
                            </h5>
                        </div>
                        <div class="card-block">
                            <div class="clr-row" style="padding-top:40px;">
                                <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                    <div class="datagrid-limit-height" >
                                        <clr-datagrid>
                                            <clr-dg-action-bar style="margin-top:0;">
                                                <div class="btn-group" style="float:right">
                                                    <button type="button" class="btn btn-sm btn-link" (click)="exportInfra()">
                                                        Export to CSV
                                                    </button>
                                                </div>
                                            </clr-dg-action-bar>

                                            <clr-dg-column [clrDgField]="'value.name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}"></ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.you'"><ng-container *clrDgHideableColumn="{hidden: false}">My infrastructure</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.min'"><ng-container *clrDgHideableColumn="{hidden: false}">Min</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.median'"><ng-container *clrDgHideableColumn="{hidden: false}">Median</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.max'"><ng-container *clrDgHideableColumn="{hidden: false}">Max</ng-container></clr-dg-column>
                                            
                                            <clr-dg-row *clrDgItems="let data of infrastructure_data | keyvalue" [clrDgItem]="data.value">
                                                <clr-dg-cell>{{data.value.name}}</clr-dg-cell>
                                                <clr-dg-cell><span [style.color]="data.value.color"><b>{{data.value.you}}</b></span></clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.min)}}</clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.median)}}</clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.max)}}</clr-dg-cell>
                                            </clr-dg-row>

                                            <clr-dg-footer>
                                                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                                                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                                </clr-dg-pagination>
                                            </clr-dg-footer>

                                        </clr-datagrid>
                                    </div>
                                </div>
                                <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
                                    <div class="clr-row">
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="infrastructure_data.vcpu"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="infrastructure_data.socket"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="infrastructure_data.server"></app-bullet>
                                        </div>
                                    </div>
                                    <div class="clr-row">
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="infrastructure_data.ramallocpercent"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="infrastructure_data.ramusepercent"></app-bullet>
                                        </div>
                                         <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="infrastructure_data.cpuusepercent"></app-bullet>
                                        </div>
                                    </div>
                                </div>
                            </div>             
                        </div>
                    </div>
                </clr-tab-content>
            </ng-template>
        </clr-tab>
        <clr-tab>
            <button clrTabLink>VM Behavior</button>
            <ng-template [(clrIfActive)]="behavior_active">
                <clr-tab-content>
                    <div class="card">
                        <div class="card-header card-ajust">
                            <h5><cds-icon shape="line-chart"></cds-icon>&nbsp;VM Behavior</h5>
                        </div>
                        <div class="card-block">
                            <div class="clr-row" style="padding-top:40px;">
                                <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                    <div class="datagrid-limit-height" >
                                        <clr-datagrid>
                                            <clr-dg-action-bar style="margin-top:0;">
                                                <div class="btn-group" style="float:right">
                                                    <button type="button" class="btn btn-sm btn-link" (click)="exportBehavior()">
                                                        Export to CSV
                                                    </button>
                                                </div>
                                            </clr-dg-action-bar>

                                            <clr-dg-column [clrDgField]="'value.name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}"></ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.you'"><ng-container *clrDgHideableColumn="{hidden: false}">My infrastructure</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.min'"><ng-container *clrDgHideableColumn="{hidden: false}">Min</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.median'"><ng-container *clrDgHideableColumn="{hidden: false}">Median</ng-container></clr-dg-column>
                                            <clr-dg-column [clrDgField]="'value.max'"><ng-container *clrDgHideableColumn="{hidden: false}">Max</ng-container></clr-dg-column>
                                            
                                            <clr-dg-row *clrDgItems="let data of behavior_data | keyvalue" [clrDgItem]="data.value">
                                                <clr-dg-cell>{{data.value.name}}</clr-dg-cell>
                                                <clr-dg-cell><span [style.color]="data.value.color"><b>{{data.value.you}}</b></span></clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.min)}}</clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.median)}}</clr-dg-cell>
                                                <clr-dg-cell>{{formatNumber(data.value.max)}}</clr-dg-cell>
                                            </clr-dg-row>

                                            <clr-dg-footer>
                                                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                                                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                                                </clr-dg-pagination>
                                            </clr-dg-footer>

                                        </clr-datagrid>
                                    </div>
                                </div>
                                <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
                                    <div class="clr-row">
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="behavior_data.idle"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="behavior_data.over"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="behavior_data.under"></app-bullet>
                                        </div>
                                    </div>
                                    <div class="clr-row">
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="behavior_data.idle_evol"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="behavior_data.over_evol"></app-bullet>
                                        </div>
                                        <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                                            <app-bullet [data]="behavior_data.under_evol"></app-bullet>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </clr-tab-content>
            </ng-template>
        </clr-tab>
    </clr-tabs>
</div>

<!-- MODAL ALERT -->
<clr-modal [(clrModalOpen)]="alert_modal" [clrModalClosable]=false [clrModalSize]="'sm'">
    <h3 class="modal-title">Sorry ...</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>{{alert_msg}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="alert_modal = false">Ok</button>
    </div>
</clr-modal>

<!-- MODAL TOGGLE -->
<clr-modal [(clrModalOpen)]="toggle_modal" [clrModalSize]="'xs'" [clrModalClosable]="false">
    <h3 class="modal-title">Green-Index</h3>
	<div class="modal-body">
        <div class="alert alert-warning" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        <b>By enabling this feature, you agree to share the following metrics anonymously :</b>
                    </span>
                </div>
            </div>
        </div>
        <hr>
        <div style="display:list-item;">
            <b>List of collected metrics :</b>
            <br><br>
            <div style="margin-left:10%">
                <ng-template [ngTemplateOutlet]="metrics"></ng-template>
            </div>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link"  (click)="confirmData(false)">Cancel</button>
        <button type="button" class="btn btn-success" (click)="confirmData(true)">Confirm</button>
    </div>
</clr-modal>

<!-- TEMPLATE DEFAULT VALUES ALERT -->
<ng-template #defaultalert >
    <div *ngIf="default_values_alert" style="margin-top:20px;" class="alert alert-warning" role="alert">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                </div>
                <span class="alert-text">
                    <span><b>This view includes pre-defined values from a benchmark accomplished by EasyVirt.<br>
                        For a comprehensive and accurate analysis, please fill out the specific characteristics concerning [{{ default_names }}] for your infrastructure in the Green IT settings.</b><br><br>
                    </span>
                    Pre-defined values :
                    <ul>
                        <li *ngFor="let metric of default_values">{{ metric.name }} = {{ metric.value }}</li>
                    </ul>
                </span>
            </div>
        </div> 
        <!-- <button type="button" class="close" aria-label="Close">
            <cds-icon aria-hidden="true" shape="close"></cds-icon>
        </button> -->  
    </div>
</ng-template>

<!-- TEMPLATE METRICS -->
<ng-template #metrics >
    <ul cds-list>
        <li>Infrastructure
            <ul cds-list>
                <li>vCPU per logical core</li>
                <li>VM per CPU socket</li>
                <li>VM per server</li>
                <li>RAM allocation & usage</li>
            </ul>
        </li>
        <li>GreenIt
            <ul cds-list>
                <li>Annual CO2 (kg CO2eq) per server</li>
                <li>Annual CO2 (kg CO2eq) per VM</li>
                <li>Annual kWh per server</li>
                <li>Average Watt per VM</li>
                <li>Average Server age</li>
                <li>PUE</li>
                <li>CO2 emission factor</li>
            </ul>
        </li>
        <li>Behavior
            <ul cds-list>
                <li>Idle VM percent</li>
                <li>Oversized VM percent</li>
                <li>Undersized VM percent</li>
            </ul>
        </li>
    </ul>   
</ng-template>