import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    ClrWizard
} from "@clr/angular";

import {
    AccountService,
    JsonloaderService,
    ManagementService,
    MeasurementService,
    ShareService
} from '@app/services';

import {
    ElementView,
    FilterMgt,
    FilterActivation,
    FilterDelete,
    Message,
    Settings,
    TimeFilter,
    User
} from '@app/model';

import * as moment from 'moment';


@Component({
    selector: 'app-filtermgt',
    templateUrl: './filtermgt.component.html',
    styleUrls: ['./filtermgt.component.css']
})
export class FiltermgtComponent implements OnInit {

    @ViewChild("wizardFilter") wizardfv: ClrWizard;

    message: Message;

    filters: FilterMgt[] = [];

    shared_filters: FilterMgt[] = [];

    users_filters: FilterMgt[] = [];

    isRdy: boolean = false;

    isEdit: boolean = false;

    isUpdate: boolean = false;

    edit_element_view: string = '';

    edit_time_view: string = '';

    edit_iswd: boolean = false;

    edit_isshared: boolean = false;

    selected_filter: FilterMgt;

    settings: Settings[] = [];

    work_days_str: string = '';

    work_days_tpl: string = '';

    now: any;

    element_views: ElementView[] = [];

    time_views: TimeFilter[] = [];

    update_state: string = 'error';

    update_result: string = 'changes have not been applied.';

    model: any;

    isDeleteFilter: boolean = false;

    currentUser: User;

    private currentFilter: string = '';


    constructor(
        private authentication_svc: AccountService,
        private message_svc: ShareService,
        private json_svc: JsonloaderService,
        private management_svc: ManagementService,
        private measurement_svc: MeasurementService
    ) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.getFilters();

        this.now = moment().format('MMMM Do YYYY');

        this.selected_filter = {
            id: 0,
            name: '',
            status: '',
            time: '',
            work_days: 0,
            time_view: '',
            element_view: '',
            shared: 0
        };

        this.model = {
            name: '',
            error: false,
            errorName: ''
        };
    }

    switchFilter(val: string): void {

        this.isEdit = false;
        this.isUpdate = false;
        this.edit_iswd = false;
        this.edit_isshared = false;
        this.update_state = 'error';
        this.update_result = 'changes have not been applied.';

        if (this.filters.length > 0 && val != "")
            this.loadFilter(this.getFilter(val));
    }

    editFilter(): void {

        this.isEdit = true;

        // GET THE CURRENT ELEMENT VIEW
        if (this.element_views.length == 0)
            this.loadElementViews();
        else
            this.edit_element_view = this.selected_filter.element_view;

        // GET THE CURRENT TIME VIEW
        if (this.time_views.length == 0)
            this.loadTimeViews();
        else
            this.edit_time_view = this.selected_filter.time_view;

        // GET THE CURRENT WD STATE
        if (this.selected_filter.work_days == 1) {
            this.edit_iswd = true;
            // GET THE WORK DAYS STRING
            if (this.settings.length == 0)
                this.callSettings();
        }

        // GET THE CURRENT SHARE STATE
        if (this.selected_filter.shared == 1)
            this.edit_isshared = true;
    }

    enableUpdate(val): void {

        if (val != this.selected_filter.work_days || val != this.selected_filter.time_view || val != this
            .selected_filter.element_view || val != this.selected_filter.shared) {
            this.isUpdate = true;
            this.update_result = 'changes have not been applied.';
            this.update_state = 'error';
        } else {
            this.isUpdate = false;
        }
    }

    removeFilter() {

        if (this.selected_filter.id > 0) {
            const filter_del: FilterDelete = {
                name: this.selected_filter.name,
                user: this.currentUser.login,
                id: this.selected_filter.id
            };
            this.removeLink(filter_del.name);
            this.management_svc.remFilter(filter_del).pipe(first()).subscribe(
                success => {
                    this.message.genericInfoType = 'info';
                    this.message.genericInfo = 'filter ' + this.selected_filter.name + ' has been deleted';
                    this.message.isNoInfo = false;
                    this.getFilters();
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );
            this.isEdit = false;
            this.isUpdate = false;
            this.edit_iswd = false;
            this.edit_isshared = false;
        }
    }

    updateFilter(): void {

        this.management_svc.updFilter(this.currentUser.login, this.selected_filter.name, this.selected_filter.id,
            this.edit_element_view, this.edit_time_view, this.edit_iswd, this.edit_isshared).subscribe(
            success => {
                this.update_state = 'success';
                this.update_result = 'changes have been applied.';

                let wd: number = 0;
                if (this.edit_iswd)
                    wd = 1;

                let isShare: number = 0;
                if (this.currentUser.login == "root") {
                    if (this.edit_isshared)
                        isShare = 1;
                    else
                        this.removeLink(this.selected_filter.name);
                }

                let filterUpd: FilterMgt = {
                    id: this.selected_filter.id,
                    name: this.selected_filter.name,
                    status: this.selected_filter.status,
                    time: this.selected_filter.time,
                    work_days: wd,
                    time_view: this.edit_time_view,
                    element_view: this.edit_element_view,
                    shared: isShare
                }
                this.updFilter(filterUpd);
                this.isUpdate = false;
                this.json_svc.cleanJson(this.currentUser.login, this.selected_filter.name).subscribe(
                    success => {
                        this.processingFilter(this.selected_filter.name);
                    },
                    error => {
                        console.log(error);
                    }
                );
            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );
    }

    formatEditDate(date: any): any {
        return moment(date).format('MMMM Do YYYY');
    }

    initWizard(): void {

        this.isEdit = false;
        this.isUpdate = false;
        this.edit_iswd = false;
        this.edit_isshared = false;
    }

    checkName(val): void {

        if (this.filterList(val)) {
            this.model.error = true;
            this.model.errorName = 'to ' + val + ' (filter already exists)';
        } else if (val == "all" || val == "ALL") {
            this.model.error = true;
            this.model.errorName = 'to ' + val;
        } else if (val.length == 0) {
            this.model.error = true;
            this.model.errorName = 'empty';
        } else if (val.length > 0) {
            this.model.error = false;

            //Remove accent
            var accent = [
                /[\300-\306]/g, /[\340-\346]/g, // A, a
                /[\310-\313]/g, /[\350-\353]/g, // E, e
                /[\314-\317]/g, /[\354-\357]/g, // I, i
                /[\322-\330]/g, /[\362-\370]/g, // O, o
                /[\331-\334]/g, /[\371-\374]/g, // U, u
                /[\321]/g, /[\361]/g, // N, n
                /[\307]/g, /[\347]/g, // C, c
            ];
            var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

            for (var i = 0; i < accent.length; i++) {
                this.model.name = this.model.name.replace(accent[i], noaccent[i]);
            }

            //Remove specs chars
            this.model.name = this.model.name.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:.;?,<> ]/g, "_");
        }
    }

    filterList(val: string): boolean {

        let isInFilter: boolean = false;
        for (var i in this.filters) {
            if (this.filters[i].name == val) {
                isInFilter = true;
                break;
            }
        }

        if (this.currentUser.login != "root") {
            for (var i in this.shared_filters) {
                if (this.shared_filters[i].name == val) {
                    isInFilter = true;
                    break;
                }
            }
        } else {
            if (this.edit_isshared) {
                // TODO get user's filter
                for (var i in this.users_filters) {
                    if (this.users_filters[i].name == val) {
                        isInFilter = true;
                        break;
                    }
                }
            }
        }

        return isInFilter;
    }

    loadElementViews(): void {

        this.management_svc.getElementView(this.currentUser.login).pipe(first()).subscribe(
            data => {
                this.element_views = data;
                if (this.isEdit)
                    this.edit_element_view = this.selected_filter.element_view;
                else
                    this.edit_element_view = this.element_views[0].FILTER;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    loadTimeViews(): void {

        this.management_svc.getTimeView(this.currentUser.login).pipe(first()).subscribe(
            data => {
                this.time_views = data;
                if (this.isEdit)
                    this.edit_time_view = this.selected_filter.time_view;
                else
                    this.edit_time_view = this.time_views[0].filter;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    goBack(): void {}

    doFinish(): void {

        if (this.model.name != "") {
            this.management_svc.addFilter(this.currentUser.login, this.model.name, this.edit_element_view, this
                .edit_time_view, this.edit_iswd, this.edit_isshared).subscribe(
                success => {
                    this.processingFilter(this.model.name);
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );
        }

    }

    doReset(): void {

        this.model.name = '';
        this.edit_iswd = false;
        this.edit_isshared = false;
        this.isRdy = false;
        this.settings = [];
        this.wizardfv.reset();
    }

    private removeLink(filter_name: string): void {

        this.authentication_svc.getUserList().pipe(first()).subscribe(
            data => {
                let users: User[] = [];
                users = data;
                if (users.length > 0) {
                    for (var i in users) {
                        this.management_svc.unLink(users[i].login, filter_name).pipe(first()).subscribe();
                    }
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private processingFilter(filter: string): void {

        this.message.genericInfoType = 'info';
        this.message.genericInfo = 'filter processing ...';
        this.message.isNoInfo = false;
        this.json_svc.checkJson(this.currentUser.login, filter).subscribe(
            complete => {
                this.isEdit = false;
                const filter_ready: FilterActivation = {
                    user_login: this.currentUser.login,
                    name: filter,
                    status: 'ready'
                };
                this.management_svc.enableFilter(filter_ready).subscribe(
                    success => {
                        this.getFilters();
                        this.message.genericInfoType = 'info';
                        this.message.genericInfo = 'the filter ' + filter + ' is ready to use';
                        this.message.isNoInfo = false;
                        this.doReset();
                    },
                    error => {
                        this.message.isNoInfo = true;
                        this.message.genericInfoType = 'danger';
                        this.message.genericInfo = 'fail to create the filter ' + filter;
                        this.message.isNoInfo = false;
                        if (error != null)
                            console.log(error);
                    }
                );
            },
            error => {
                this.message.isNoInfo = true;
                this.message.genericInfoType = 'danger';
                this.message.genericInfo = 'fail to create the filter ' + filter;
                this.message.isNoInfo = false;
                this.doReset();
                if (error != null)
                    console.log(error);
            }
        );
    }

    private getFilter(name: string): FilterMgt {
        return this.filters.find(filter => filter.name === name);
    }

    private updFilter(filter: FilterMgt): void {

        const index = this.filters.indexOf(this.filters.find(f => f.id === filter.id));
        if (index > -1) {
            this.filters.splice(index, 1);
            this.filters.push(filter);
            this.loadFilter(filter);
        }
    }

    private getFilters(): void {

        this.management_svc.getFullFilterList(this.currentUser.login).pipe(first()).subscribe(
            data => {
                this.filters = [];
                this.filters = data;
                // Only not created or processing filters
                this.message.filterList = this.filters.filter(x => (x.status != "created" && x.status !=
                    "processing"));
                // All filters
                if (this.filters.length > 0)
                    this.loadFilter(this.filters[0]);

                if (this.currentUser.login != "root") {
                    this.management_svc.getViewsFromSharedFilter(this.currentUser.login).pipe(first())
                        .subscribe(
                            data => {
                                if (data.length > 0) {
                                    for (var i = 0; i < data.length; i++) {
                                        const view = data[i].name;
                                        const filter_id = data[i].id;
                                        this.management_svc.testSharedFilter(this.currentUser.login, view).pipe(
                                            first()).subscribe(
                                            data => {
                                                if (data) {
                                                    this.management_svc.getFilterFromId(filter_id).pipe(
                                                        first()).subscribe(
                                                        data => {
                                                            // Only ready filters
                                                            if (data.status == "ready")
                                                                this.message.filterList.push(data);
                                                        }
                                                    );
                                                }
                                            }
                                        );
                                    }
                                }
                            }
                        );
                }
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );

        this.management_svc.getSharedFilterList().pipe(first()).subscribe(
            data => {
                this.shared_filters = [];
                this.shared_filters = data;
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );

        this.management_svc.getUsersFilterList().pipe(first()).subscribe(
            data => {
                this.users_filters = [];
                this.users_filters = data;
                //console.log(this.users_filters);
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private loadFilter(filter: FilterMgt): void {

        this.selected_filter = filter;

        if (filter.work_days == 0) {
            this.work_days_str = 'full';
        } else {
            if (this.settings.length == 0)
                this.callSettings();
            else
                this.work_days_str = this.work_days_tpl;
        }
        if (filter.shared == 1)
            this.edit_isshared = true;
        else
            this.edit_isshared = false;

        setTimeout(() => $('#filter-view').val(filter.name), 100);
        this.isRdy = true;
    }

    private callSettings(): void {

        this.measurement_svc.getSettings().pipe(first()).subscribe(
            data => {
                this.settings = data;
                this.work_days_str = this.setWorkDaysStr();
            },
            error => {
                if (error != null)
                    console.log(error)
            }
        );
    }

    private setWorkDaysStr(): string {

        this.work_days_tpl = '';
        for (var i = 0; i < this.settings.length; i++) {
            if (this.settings[i].name == "DAYS_OF_WEEK") {
                let days: string[] = this.settings[i].value.split(',');
                for (var j = 0; j < days.length; j++) {
                    this.work_days_tpl += this.buildStr(days[j]);
                }
                this.work_days_tpl = this.work_days_tpl.substring(0, this.work_days_tpl.length - 1);
                break;
            }
        }
        for (var i = 0; i < this.settings.length; i++) {
            if (this.settings[i].name == "TIME_RANGE_START") {
                this.work_days_tpl += ' [' + this.settings[i].value;

                break;
            }
        }
        for (var i = 0; i < this.settings.length; i++) {
            if (this.settings[i].name == "TIME_RANGE_END") {
                this.work_days_tpl += '-' + this.settings[i].value + ']';

                break;
            }
        }

        return this.work_days_tpl;
    }

    private buildStr(item): string {

        let tmp_str = '';
        switch (item) {
            case "1":
                tmp_str = 'Sat,';
                break;
            case "2":
                tmp_str = 'Sun,';
                break;
            case "3":
                tmp_str = 'Mon,';
                break;
            case "4":
                tmp_str = 'Tue,';
                break;
            case "5":
                tmp_str = 'Wed,';
                break;
            case "6":
                tmp_str = 'Thu,';
                break;
            case "7":
                tmp_str = 'Fri,';
                break;
            default:
                break;
        }

        return tmp_str;
    }
}
