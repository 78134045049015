import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import {
    ClrLoadingState,
    ClrDatagridSortOrder
} from '@clr/angular';
import {
    HttpEvent,
    HttpEventType
} from '@angular/common/http';
import {
    throwError
} from 'rxjs';

import {
    UploadService,
    AccountService
} from '@app/services';
import {
    UploadType
} from './upload.enums';
import {
    User
} from '@app/model';


@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

    @Input() target: UploadType;

    dataOrder = ClrDatagridSortOrder.DESC;

    url_logo: any = "";

    currentUser: User;

    report: UploadType;
    updates: UploadType;
    netdevice: UploadType;
    stodevice: UploadType;
    view: UploadType;

    upload_modal: boolean;
    upload_progress: number;
    upload_progress_class: string;
    upload_progress_message: string;
    upload_file: File;
    upload_button_state: ClrLoadingState;
    upload_button_ready: boolean;
    upload_close_button: boolean;

    //FOR REPORT
    custom_logo: boolean;
    delete_modal_logo: boolean;

    type_modal: boolean = false;


    /**
     * 
     */
    constructor(private authenticationService: AccountService, private upload_svc: UploadService) {
        this.upload_modal = false;
        this.report = UploadType.REPORT;
        this.updates = UploadType.UPDATES;
        this.netdevice = UploadType.NETDEVICE;
        this.stodevice = UploadType.STODEVICE;
        this.view = UploadType.VIEW;
    }

    /**
     * 
     */
    ngOnInit(): void {
        this.authenticationService.user.subscribe(user => this.currentUser = user);
    }

    /**
     * 
     */
    displayModalUpload(): void {
        this.upload_modal = true;
        this.initUpload();

        if (this.target == UploadType.REPORT) {
            this.getLogo();
        }
    }

    /**
     * 
     */
    init(event: any): void {
        this.upload_file = (event.target as HTMLInputElement).files[0];
        let valid_file: Boolean = false;

        switch (this.target) {
            case UploadType.UPDATES:
                if (this.upload_file.name.endsWith(".tar.gz") && (this.upload_file.type == "application/gzip" ||
                        this.upload_file.type == "application/x-gzip"))
                    valid_file = true;
                break;
            case UploadType.REPORT:
                if (this.upload_file.type == "image/jpg" || this.upload_file.type == "image/jpeg" || this
                    .upload_file.type == "image/png")
                    valid_file = true;
                break;
            case UploadType.NETDEVICE:
            case UploadType.STODEVICE:
                if (this.upload_file.type == ".csv" || this.upload_file.type == "text/csv" || this
                    .upload_file.type == "text/csv")
                    valid_file = true;
                break;
            case UploadType.VIEW:
                this.initUpload();
                if (this.upload_file.type == ".csv" || this.upload_file.type == "text/csv" || this
                    .upload_file.type == "text/csv")
                    valid_file = true;
                break;
            default:
                break;
        }

        if (valid_file) {
            this.upload_progress = 0;
            this.upload_progress_message = "File to upload : " + this.upload_file.name;
            this.upload_button_state = ClrLoadingState.DEFAULT;
            this.upload_button_ready = true;
        } else {
            this.upload_progress_message = "File " + this.upload_file.name + " not allowed !";
        }

    }

    /**
     * 
     */
    upload(): void {
        this.upload_close_button = false;
        this.upload_progress_message = "Upload in progress ...";
        this.upload_button_state = ClrLoadingState.LOADING;

        this.upload_svc.upload(this.upload_file, this.target).subscribe(
            (event: HttpEvent < any > ) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        //console.log('Request has been made!');
                        break;
                    case HttpEventType.ResponseHeader:
                        //console.log('Response header has been received!');
                        break;
                    case HttpEventType.UploadProgress:
                        this.upload_progress = Math.round(event.loaded / event.total * 100);
                        //console.log(`Uploaded! ${this.upload_progress}%`);
                        break;
                    case HttpEventType.Response:
                        this.upload_progress_class = "progress success";
                        this.upload_progress_message = "Upload completed !";
                        this.upload_button_state = ClrLoadingState.SUCCESS;

                        setTimeout(() => {
                            this.upload_button_ready = false; // Keep animation
                            this.upload_close_button = true;
                            if (this.target == UploadType.REPORT) {
                                this.custom_logo = true;
                                this.getLogo();
                            }
                        }, 500);
                        break;
                }
            },
            error => {
                if (error != null) {
                    let message = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        message = error.error.message;
                    } else {
                        // server-side error
                        message = `Error Code: ${error.status} with message: ${error.message}`;
                    }
                    this.upload_progress_class = "progress danger";
                    this.upload_progress_message = "Error : " + error.error;
                    this.upload_button_ready = false;
                    this.upload_close_button = true;
                    return throwError(message);
                }
            }
        );
    }

    private initUpload(): void {

        this.upload_close_button = true;
        this.upload_progress = 0;
        this.upload_progress_message = undefined;
        this.upload_progress_class = "progress init";
        this.upload_button_state = ClrLoadingState.DEFAULT;
        this.upload_button_ready = false;
        this.custom_logo = false;
    }

    /**
     * 
     */
    private getLogo() {
        //Check if custom logo is uploaded
        this.upload_svc.checkLogo().subscribe(
            data => {
                this.custom_logo = true;
                this.upload_svc.getLogo().subscribe(
                    res => {
                        let blob: Blob = new Blob([res], {
                            type: res.type
                        });
                        let reader = new FileReader();
                        reader.addEventListener("load", () => {
                            this.url_logo = reader.result;
                        }, false);

                        if (blob) {
                            reader.readAsDataURL(blob);
                        }
                    },
                    error => {
                        // already in console
                    }
                );
            },
            error => {
                // Already in console
                this.custom_logo = false;
            }
        );
    }

    /**
     * 
     */
    deleteLogo(): void {
        this.delete_modal_logo = false;

        //Delete custom logo
        this.upload_svc.deleteLogo().subscribe(
            data => {
                this.custom_logo = false;
            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );

    }

}
