import {
    Component,
    OnInit
} from '@angular/core';
import {
    FailureGetAppsGroupsResponse,
    FailureGetClustersWithFiltersResponse,
    FailureGetDailyTimestampResponse,
    FailureGetDashboardDataForFilterResponse,
    FailureGetDashboardGraphDataForFilter,
    FailureGetExternalIpsResponse,
    FailureGetLastInfrastructureVersionResponse,
    FailureGetOverallStatusResponse,
    FailureGetServicesStatusResponse,
    FailureGetVcentersStatusResponse,
    GetAppsGroupsResponse,
    GetClustersWithFiltersResponse,
    GetDailyTimestampResponse,
    GetDashboardDataForFilterResponse,
    GetDashboardGraphDataForFilter,
    GetExternalIpsResponse,
    GetLastInfrastructureVersionResponse,
    GetOverallStatusResponse,
    GetServicesStatusResponse,
    GetVcentersStatusResponse,
    NetscopeService,
    SuccessGetAppsGroupsResponse,
    SuccessGetClustersWithFiltersResponse,
    SuccessGetDailyTimestampResponse,
    SuccessGetDashboardDataForFilterResponse,
    SuccessGetDashboardGraphDataForFilter,
    SuccessGetExternalIpsResponse,
    SuccessGetLastInfrastructureVersionResponse,
    SuccessGetOverallStatusResponse,
    SuccessGetServicesStatusResponse,
    SuccessGetVcentersStatusResponse
} from "@app/services";
import {TranslocoService} from "@ngneat/transloco";

@Component({
    selector: 'app-netscope-diagnostic',
    templateUrl: './netscope-diagnostic.component.html',
    styleUrls: ['./netscope-diagnostic.component.css']
})
export class NetscopeDiagnosticComponent implements OnInit {

    statuses = {
        step1: "not-started",
        step2: "not-started",
        step3: "not-started",
        step4: "not-started",
        collect_status: "not-started",
        operation_status: "not-started",
    }
    endpointStatuses = {
        lastInfrastructure: "not-started",
        dashboard: "not-started",
        collectHistory: "not-started",
        topology: "not-started",
        flows: "not-started",
        heatmap: "not-started",
        appsGroups: "not-started",
        externalIps: "not-started",
    }
    urlDcNetscopeAgent: string;
    dcnetscopePasswordIsOk: boolean = false;
    vcentersAreOk: boolean = false;
    canConnectToDcnetscope: boolean = false;
    vcenters = [];
    services = [];

    constructor(private netscopeService: NetscopeService, public translocoService: TranslocoService) {}

    ngOnInit(): void {
        this.reloadData();
    }

    reloadData = () => {
        this.diagnoseInstallation();
    }

    initUiVariables = () => {
        this.statuses = {
            step1: "not-started",
            step2: "not-started",
            step3: "not-started",
            step4: "not-started",
            collect_status: "not-started",
            operation_status: "not-started",
        }
        this.urlDcNetscopeAgent = undefined;
        this.dcnetscopePasswordIsOk = false;
        this.vcentersAreOk = false;
        this.canConnectToDcnetscope = false;
        this.vcenters = [];
        this.services = [];
    }

    diagnoseInstallation = () => {

        this.initUiVariables();

        // Check the status of DC Netscope (configuration, services status)
        this.netscopeService.getNetscopeSettings().subscribe((settings) => {
            settings.filter((option) => option.option === "agent_url").map((option) => {
                this.urlDcNetscopeAgent = option.value;
                this.statuses.step1 = "success";

                // Reset next steps
                this.statuses.step2 = "current";
                this.statuses.step3 = "current";
                this.statuses.step4 = "current";
                this.statuses.collect_status = "current";
                this.statuses.operation_status = "not-started";

                this.netscopeService.getOverallStatus().subscribe((overallStatusReponse: GetOverallStatusResponse) => {

                    let overallStatus;
                    if (overallStatusReponse instanceof SuccessGetOverallStatusResponse) {
                        overallStatus = overallStatusReponse.overallStatus;
                    }

                    if (overallStatusReponse instanceof FailureGetOverallStatusResponse) {
                        this.statuses.step2 = "error";
                        this.statuses.step3 = "error";
                        this.canConnectToDcnetscope = false;
                        return;
                    }

                    this.statuses.step2 = "success";
                    this.statuses.step3 = "success";
                    this.canConnectToDcnetscope = true;

                    console.log(overallStatus);

                    // @ts-ignore
                    let lastCollectDateStr: string = overallStatus.status.last_collect;
                    if (lastCollectDateStr !== undefined && lastCollectDateStr.length > 0) {
                        let lastCollectDateAsEpoch = Date.parse(lastCollectDateStr);
                        let nowEpoch = Math.floor(new Date().getTime() / 1000.0);

                        let timeDifference = nowEpoch - lastCollectDateAsEpoch;
                        if (timeDifference < 3600) {
                            this.statuses.step4 = "success";
                            this.statuses.collect_status = "success";
                        }
                    }

                    this.netscopeService.getVcentersStatus().subscribe((
                        vcentersStatusResponse: GetVcentersStatusResponse) => {

                        let vcentersStatus;
                        if (
                            vcentersStatusResponse instanceof SuccessGetVcentersStatusResponse
                        ) {
                            vcentersStatus = vcentersStatusResponse.vcentersStatus;
                        }

                        if (
                            vcentersStatusResponse instanceof FailureGetVcentersStatusResponse
                        ) {
                            this.vcentersAreOk = false;
                            return;
                        }

                        let allVcenterAreOk = true;
                        let vcenters = [];
                        Object.entries(vcentersStatus).map(([vcenterKey,
                                                                vcenterStatus
                                                            ]) => {
                            if (vcenterStatus !== "ok") {
                                allVcenterAreOk = false;
                            }
                            vcenters.push({
                                name: vcenterKey,
                                status: vcenterStatus,
                                cssClass: vcenterStatus === "ok" ?
                                    "label-success" : "label-danger"
                            });
                        })

                        if (allVcenterAreOk) {
                            this.vcentersAreOk = true;
                        }

                        this.vcenters = vcenters;
                        console.log(vcentersStatus);
                    });

                    this.netscopeService.getServicesStatus().subscribe((
                        servicesStatusResponse: GetServicesStatusResponse) => {

                        let servicesStatus;
                        if (
                            servicesStatusResponse instanceof SuccessGetServicesStatusResponse
                        ) {
                            servicesStatus = servicesStatusResponse.servicesStatus;
                        }

                        if (
                            servicesStatusResponse instanceof FailureGetServicesStatusResponse
                        ) {
                            this.statuses.operation_status = "error";
                            return;
                        }

                        console.log(servicesStatus);

                        let allServicesAreOk = true;
                        let services = [];
                        Object.entries(servicesStatus).map(([serviceKey,
                                                                serviceStatus
                                                            ]) => {
                            if (serviceStatus !== "RUNNING") {
                                allServicesAreOk = false;
                            }
                            services.push({
                                name: serviceKey,
                                status: serviceStatus,
                                cssClass: serviceStatus ===
                                "RUNNING" ? "label-success" :
                                    "label-danger"
                            });
                        })

                        if (allServicesAreOk) {
                            this.statuses.operation_status = "success";
                        }

                        this.services = services;
                    });
                });
            });
        });

        this.netscopeService.getLastInfrastructureVersion().subscribe((infrastructureVersionResponse: GetLastInfrastructureVersionResponse) => {
            let infrastructureVersion;

            if (infrastructureVersionResponse instanceof SuccessGetLastInfrastructureVersionResponse) {
                this.endpointStatuses.lastInfrastructure = "success";
                infrastructureVersion = infrastructureVersionResponse.lastInfrastructureVersion;
            } else {
                this.endpointStatuses.lastInfrastructure = "error";
                this.canConnectToDcnetscope = false;
                return;
            }

            let vmsUuids = infrastructureVersion.topology.vm_only_topology.vms.map((vm) => vm.uuid);
            let hostsUuids = infrastructureVersion.topology.vm_only_topology.hosts.map((host) => host.uuid);

            // Check the status of endpoints
            this.netscopeService.getDailyTimestamps().subscribe((dailyTimeStampsDataResponse: GetDailyTimestampResponse) => {

                if (dailyTimeStampsDataResponse instanceof FailureGetDailyTimestampResponse) {
                    this.endpointStatuses.dashboard = "error";
                    this.statuses.step1 = "not-started";
                    this.statuses.step2 = "not-started";
                    this.statuses.step3 = "error";
                    this.statuses.step4 = "not-started";
                    this.statuses.collect_status = "not-started";
                    this.statuses.operation_status = "not-started";
                    return;
                }

                let dailyTimeStampsData;
                if (dailyTimeStampsDataResponse instanceof SuccessGetDailyTimestampResponse) {
                    dailyTimeStampsData = dailyTimeStampsDataResponse.dailyTimestamps;
                }

                let dailyTimestamps = dailyTimeStampsData.map((v) => v.start_time);
                let currentDayTimestamp = undefined;
                const minDay = Math.min(...dailyTimestamps);
                const maxDay = Math.max(...dailyTimestamps);
                if (currentDayTimestamp === undefined) {
                    let maxTimestamp = maxDay;
                    currentDayTimestamp = maxTimestamp;
                }

                const dashboardDataObservable = this.netscopeService.getDashboardDataForFilter(vmsUuids, hostsUuids, true, currentDayTimestamp);
                dashboardDataObservable.subscribe((dashboardDataResponse:
                    GetDashboardDataForFilterResponse) => {
                    if (dashboardDataResponse instanceof SuccessGetDashboardDataForFilterResponse) {
                        this.endpointStatuses.dashboard = "success";
                    }
                    if (dashboardDataResponse instanceof FailureGetDashboardDataForFilterResponse) {
                        this.endpointStatuses.dashboard = "error";
                    }
                });

                const dashboardGraphDataForFilterObservable = this.netscopeService
                    .getDashboardGraphDataForFilter(vmsUuids, hostsUuids, true);
                dashboardGraphDataForFilterObservable.subscribe((dashboardGraphDataForFilterResponse:
                    GetDashboardGraphDataForFilter) => {
                    if (
                        dashboardGraphDataForFilterResponse instanceof SuccessGetDashboardGraphDataForFilter
                    ) {
                        this.endpointStatuses.collectHistory = "success";
                    }
                    if (
                        dashboardGraphDataForFilterResponse instanceof FailureGetDashboardGraphDataForFilter
                    ) {
                        this.endpointStatuses.collectHistory = "error";
                    }
                });

                this.netscopeService.getClustersWithFilters(currentDayTimestamp, currentDayTimestamp + 1,
                    vmsUuids, hostsUuids, true, "daily").subscribe((clustersData:
                    GetClustersWithFiltersResponse) => {
                    if (clustersData instanceof SuccessGetClustersWithFiltersResponse) {
                        this.endpointStatuses.flows = "success";
                    }
                    if (clustersData instanceof FailureGetClustersWithFiltersResponse) {
                        this.endpointStatuses.flows = "error";
                    }
                });
            });
        });


        this.netscopeService.getLastInfrastructureVersion().subscribe((lastInfrastructureVersionResponse:
            GetLastInfrastructureVersionResponse) => {
            if (lastInfrastructureVersionResponse instanceof FailureGetLastInfrastructureVersionResponse) {
                this.endpointStatuses.topology = "error";
            }
            if (lastInfrastructureVersionResponse instanceof SuccessGetLastInfrastructureVersionResponse) {
                this.endpointStatuses.topology = "success";
            }
        });

        this.netscopeService.getAppsGroups().subscribe((appsGroupsResponse: GetAppsGroupsResponse) => {
            if (appsGroupsResponse instanceof SuccessGetAppsGroupsResponse) {
                this.endpointStatuses.appsGroups = "success";
            }
            if (appsGroupsResponse instanceof FailureGetAppsGroupsResponse) {
                this.endpointStatuses.appsGroups = "error";
            }
        });

        this.netscopeService.getExternalIps().subscribe((externalIpsDataReponse: GetExternalIpsResponse) => {
            if (externalIpsDataReponse instanceof SuccessGetExternalIpsResponse) {
                this.endpointStatuses.externalIps = "success";
            }
            if (externalIpsDataReponse instanceof FailureGetExternalIpsResponse) {
                this.endpointStatuses.externalIps = "error";
            }
        });
    }

    setLanguage = (language: "french" | "english") => {
        let languageMap: Map<string, string> = new Map([
            ["french", "fr"],
            ["english", "en"]
        ]);
        let language_code = languageMap.get(language);
        this.translocoService.setDefaultLang(language_code);
        this.translocoService.setActiveLang(language_code);
        localStorage.setItem("language", language_code);
    }
}

