export let GREENIT_COLORS = ['#7cb5ec', '#95959d', '#39ac73', '#a47d7c', '#ff6666', '#946ac8'];
export let GREENIT_GREY_COLORS = ['#476888', '#434348', '#26734d', '#694a49', '#b30000', '#613795'];

export let NETWORK_DIRECT_RATIO = 'network.direct.ratio';
export let STORAGE_DIRECT_RATIO = 'storage.direct.ratio';
export let HOST_DIRECT_RATIO = 'host.direct.ratio';

export let RENEWAL_DEFAULT_NAME = "renewal.server";

export enum Resource {
    CO2 = "co2",
        WH = "wh",
}

export enum GreenitTarget {

    ALL_CO2 = "all.co2.total",
        GLOBAL_CO2 = "global.co2.total",
        DC_HOST_CO2 = "dc.host.co2.total",
        DC_HOST_WH = "dc.host.wh.total",
        DESKTOP_CO2 = "desktop.all.co2.total",
        FILTER_DC_VM_POWER = "filter.dc.vm.power.avg",
        FILTER_DC_HOST_NB = "filter.dc.host.nb",
        FILTER_DC_VM_PERHOST = "filter.dc.vm.perhost",
        FILTER_DC_VM_PERSOCKET = "filter.dc.vm.persocket",
        FILTER_DC_HOST_ANALYZES = "filter.dc.host.analyzes",
        FILTER_DC_CO2_DATA = "filter.dc.co2.data",
        DC_HOST_CEE = "dc.host.cee",
        DC_HOST_MINMAX = "dc.host.minmax",
        KUBE_NODE_MINMAX = "kube.node.minmax",
        SERVERS_SETTINGS = "servers.settings",
        NETWORK_SETTINGS = "network.settings",
        STORAGE_SETTINGS = "storage.settings",
        SCAPHANDRE_CO2 = "scaphandre.co2.total",
        SCAPHANDRE_NODE_WH = "scaphandre.node.wh",
        SCAPHANDRE_NODE_CEE = "scaphandre.node.cee",
        XCLARITY_CO2 = "xclarity.co2.total",
        XCLARITY_NODE_CEE = "xclarity.node.cee",
        OPENMANAGE_CO2 = "openmanage.co2.total",
        OPENMANAGE_NODE_CEE = "openmanage.node.cee",
        ONEVIEW_CO2 = "oneview.co2.total",
        ONEVIEW_NODE_CEE = "oneview.node.cee",
        IPMI_CO2 = "ipmi.co2.total",
        IPMI_NODE_WH = "ipmi.node.wh",
        IPMI_NODE_CEE = "ipmi.node.cee",
        NETWORK_CO2 = "network.co2.total",
        NETWORK_WH = "network.wh",
        STORAGE_CO2 = "storage.co2.total",
        STORAGE_WH = "storage.wh",
        PIE_CO2 = "pie.co2.total",
        PIE_CO2G = "pie.grey.co2.total",
        PIE_STORAGE_CO2 = "pie.storage.co2.total",
        PIE_STORAGE_CO2G = "pie.storage.grey.co2.total",

        // Without API
        ALL_INCLUSIVE_CO2 = "allinclusive.co2.total",
        EXTRAPOLATION_STORAGE_CO2 = "extrapolation.storage.co2.total",
        EXTRAPOLATION_NETWORK_CO2 = "extrapolation.network.co2.total",
}

export enum TreeTarget {
    INDICATOR,
    NETWORK_INDICATOR,
    STORAGE_INDICATOR,
    MINMAX
}

export enum DefaultValues {
    HOST_AGE = 1,
        HOST_AMORTIZATION = 4,
        DC_PUE = 2,
        DC_DIRECT_CO2 = 0.093,
        DC_DIRECT_COST = 0.11,
        HOST_EXTRAPOLATION_RATIO = 65,
        NETWORK_EXTRAPOLATION_RATIO = 10,
        STORAGE_EXTRAPOLATION_RATIO = 25
}
