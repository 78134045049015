import {
    Component,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    GreenitTarget
} from '@app/greenit/greenit.enums';
import {
    JSONTarget
} from '@app/model';
import {
    TopkprocessComponent
} from '@app/scaphandre/topkprocess/topkprocess.component';
import {
    JsonloaderService
} from '@app/services';
import {
    T7Component
} from '@app/t7/t7.component';
import {
    T8Component
} from '@app/t8/t8.component';
import {
    T9Component
} from '@app/t9/t9.component';
import {
    Subscription
} from 'rxjs';

@Component({
    selector: 'app-scaphandre',
    templateUrl: './scaphandre.component.html',
    styleUrls: ['./scaphandre.component.css']
})
export class ScaphandreComponent implements OnInit {

    @ViewChild('t7') t7: T7Component;
    @ViewChild('t8') t8: T8Component;
    @ViewChild('t9') t9: T9Component;
    @ViewChild('topk') topk: TopkprocessComponent;

    t1_target: GreenitTarget = GreenitTarget.SCAPHANDRE_CO2;
    t2_target: JSONTarget = JSONTarget.T8_SCAPHANDRE;
    t3_target: GreenitTarget = GreenitTarget.SCAPHANDRE_NODE_WH;

    allowReload: boolean = false;

    json_subscription: Subscription;

    /**
     * 
     */
    constructor(private json_svc: JsonloaderService) {}

    /**
     * 
     */
    ngOnInit(): void {

        // Need subscription to JsonloaderService to detect filter reload
        this.json_subscription = this.json_svc.currentJson.subscribe(
            json => {
                if (this.t7 !== undefined && this.t8 !== undefined && this.t9 !== undefined && this.topk !==
                    undefined && this.allowReload) {
                    this.t7.getGreenitcf().load(true);
                    this.t8.load(true);
                    this.t9.load(true);
                    this.topk.load(true);
                }
                this.allowReload = true;
            }
        )
    }

    /**
     * 
     */
    @HostListener('unloaded')
    ngOnDestroy(): void {
        // Remove subscription
        if (this.json_subscription != undefined) {
            this.json_subscription.unsubscribe();
        }
        //console.log("destroyed storage");
    }

}
