import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';
import {
    Subject
} from 'rxjs';

import {
    AccountService,
    JsonloaderService,
    ManagementService,
    ShareService
} from '@app/services';

import {
    Json,
    Message,
    TagCost,
    TagCostMin,
    TagCostElement,
    User
} from '@app/model';

import {
    DataTableDirective
} from 'angular-datatables';

import * as uuid from 'uuid';

import * as moment from 'moment';

import {
    commaBigNumber,
    getUserCurrency
} from '../../../assets/js/tools.js';
import * as $ from 'jquery';

@Component({
    selector: 'app-costvmtag',
    templateUrl: './costvmtag.component.html',
    styleUrls: ['./costvmtag.component.css']
})
export class CostvmtagComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: any;

    dtTrigger: Subject < any > = new Subject();

    jsonLoader: Json;

    message: Message;

    data_vms: any = [];

    data_tbl: any = [];

    tags: TagCost[] = [];

    tags_vm: TagCostMin[] = [];

    isNewTag: boolean = false;

    isEditTag: boolean = false;

    isDeleteTag: boolean = false;

    isListTagVm: boolean = false;

    model: any = {
        name: '',
        cost: 0,
        tagSelected: '',
        tagSelectedUuid: '',
        tagSelectedCost: 0,
        VmSelected: '',
        VmSelectedUuid: ''
    };

    globalCurrency: string = '';

    private currentUser: User;

    private now: any;


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private management_svc: ManagementService) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.initDtOptions();

        this.listTag();

        this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
        this.data_vms = this.jsonLoader.vmInfo;
        for (var i = 0; i < this.data_vms.data.length; i++) {
            this.data_tbl[i] = [];
            this.data_tbl[i][0] = this.data_vms.data[i][0];
            this.data_tbl[i][1] = this.data_vms.data[i][1];
            this.data_tbl[i][2] = this.data_vms.data[i][2];
            this.data_tbl[i][3] = this.data_vms.data[i][3];
            this.data_tbl[i][4] = this.data_vms.data[i][4];
            this.data_tbl[i][5] = this.data_vms.data[i][10];
            this.data_tbl[i][6] = this.data_vms.data[i][9];
            this.data_tbl[i][7] = this.data_vms.data[i][8];
            this.data_tbl[i][8] = false;
        }
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    listTag(): void {

        this.management_svc.listTagCost().pipe(first()).subscribe(
            data => {
                this.tags = data;
                this.model.tagSelected = data[0].NAME;
                this.model.tagSelectedUuid = data[0].IDENTIFIER;
                this.model.tagSelectedCost = data[0].COST;
                if (this.model.tagSelectedUuid != "")
                    this.getElementsFromTag(this.model.tagSelectedUuid);
            },
            error => {
                this.model.tagSelected = "";
                this.model.tagSelectedUuid = "";
                this.model.tagSelectedCost = 0;
                if (error != null)
                    console.log(error);
            }
        );
    }

    createTag(): void {

        const uuid_tag = uuid.v4();
        this.now = moment().unix();
        const tag_rec: TagCost = {
            TIMEAGO: this.now * 1000,
            IDENTIFIER: uuid_tag,
            USER: 'root',
            NAME: this.model.name,
            COST: this.model.cost

        };
        this.management_svc.addTagCost(tag_rec).subscribe(
            complete => {
                this.deselectAllRows();
                this.listTag();
            },
            error => {
                if (error != null)
                    console.log(error);
            }
        );
    }

    switchTag(): void {

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search('').draw();
        });

        if (this.model.tagSelected != "") {
            let tag_sel: TagCost = this.getTagFromName(this.model.tagSelected);
            if (tag_sel != undefined) {
                if (tag_sel.IDENTIFIER != "") {
                    this.model.tagSelectedUuid = tag_sel.IDENTIFIER;
                    this.model.tagSelectedCost = tag_sel.COST;
                    this.deselectAllRows();
                    this.getElementsFromTag(tag_sel.IDENTIFIER);
                }
            }
        }
    }

    manageSelection(event: Event): void {

        let elementId: string = (event.target as Element).id;
        let uuid: string = elementId.replace("check-", "");

        for (var i = 0; i < this.data_tbl.length; i++) {
            if (this.data_tbl[i][1] == uuid) {
                if ($('#' + elementId).prop("checked"))
                    this.data_tbl[i][8] = true;
                else
                    this.data_tbl[i][8] = false;

                break;
            }
        }

        if (this.dtElement != undefined) {
            if (this.dtElement.dtInstance != undefined) {
                this.dtElement.dtInstance.then((dtInstance: any) => {
                    if ($('#' + elementId).prop("checked"))
                        dtInstance.row('#' + uuid).select();
                    else
                        dtInstance.row('#' + uuid).deselect();

                });
            }
        }
        this.manageBtn();
    }

    getVmTags(event: Event): void {

        this.tags_vm = [];
        let elementId: string = (event.target as Element).id.substring(5);

        if (elementId != "") {
            this.model.VmSelectedUuid = elementId;
            let vm: any = this.getVmFromUuid(elementId);
            if (vm != undefined)
                this.model.VmSelected = vm[0];

            this.management_svc.getTagsVm(elementId).pipe(first()).subscribe(
                data => {
                    this.tags_vm = data;
                },
                error => {
                    this.tags_vm = [];
                    if (error != null)
                        console.log(error);
                }
            );
        }
    }

    updateTag(): void {

        this.now = moment().unix();
        const old_tag: TagCost = this.getTagFromUuid(this.model.tagSelectedUuid);

        if (old_tag != undefined) {
            const tag_rec: TagCost = {
                TIMEAGO: this.now * 1000,
                IDENTIFIER: old_tag.IDENTIFIER,
                USER: 'root',
                NAME: this.model.tagSelected,
                COST: this.model.tagSelectedCost
            };

            this.management_svc.updateTagCost(tag_rec).subscribe(
                complete => {
                    this.message.isCostRecord = true;
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );
        }
    }

    removeTag(): void {

        if (this.model.tagSelectedUuid != "") {
            this.management_svc.delTagCost(this.model.tagSelectedUuid).subscribe(
                complete => {
                    this.deselectAllRows();
                    this.listTag();
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );
        }
    }

    removeTagForVm(tag): void {

        if (tag.IDENTIFIER != "" && this.model.VmSelectedUuid != "") {
            let tag_el: TagCostElement = {
                IDENTIFIER: tag.IDENTIFIER,
                ELEMENT: this.model.VmSelectedUuid
            };
            this.management_svc.delTagCostElement(tag_el).subscribe(
                complete => {
                    this.management_svc.getTagsVm(tag_el.ELEMENT).pipe(first()).subscribe(
                        data => {
                            this.tags_vm = data;
                        },
                        error => {
                            if (error != null)
                                console.log(error);
                        }
                    );
                },
                error => {
                    if (error != null)
                        console.log(error);
                }
            );
            let uuid: string = this.model.VmSelectedUuid;
            for (var i = 0; i < this.data_tbl.length; i++) {
                if (this.data_tbl[i][1] == uuid)
                    this.data_tbl[i][8] = false;

                break;
            }
            if (this.dtElement != undefined) {
                if (this.dtElement.dtInstance != undefined) {
                    this.dtElement.dtInstance.then((dtInstance: any) => {
                        dtInstance.row('#' + uuid).deselect();
                    });
                }
            }
        }
        this.manageBtn();
    }

    private getTagFromName(name: string): TagCost {
        return this.tags.find(tag => tag.NAME === name);
    }

    private getTagFromUuid(uuid: string): TagCost {
        return this.tags.find(tag => tag.IDENTIFIER === uuid);
    }

    private getVmFromUuid(uuid: string): any {
        return this.data_vms.data.find(vm => vm[1] === uuid);
    }

    private getElementsFromTag(uuid: string): void {

        this.message.waiting = true;
        let elements: TagCostElement[] = [];
        this.management_svc.getTagCostElement(uuid).pipe(first()).subscribe(
            data => {
                for (var i = 0; i < this.data_tbl.length; i++) {
                    this.data_tbl[i][8] = false;
                    for (var j = 0; j < data.length; j++) {
                        if (data[j].ELEMENT == this.data_tbl[i][1]) {
                            this.data_tbl[i][8] = true;
                            let uuid: string = data[j].ELEMENT;
                            if (this.dtElement != undefined) {
                                if (this.dtElement.dtInstance != undefined) {
                                    this.dtElement.dtInstance.then((dtInstance: any) => {
                                        dtInstance.row('#' + uuid).select();
                                    });
                                }
                            }
                        }
                    }
                }
                this.message.waiting = false;
            },
            error => {
                this.message.waiting = false;
                if (error != null)
                    console.log(error);
            }
        );
    }

    private deselectAllRows(): void {

        this.message.waiting = true;
        for (var i = 0; i < this.data_tbl.length; i++) {
            this.data_tbl[i][8] = false;
            let uuid: string = this.data_tbl[i][1];
            if (this.dtElement != undefined) {
                if (this.dtElement.dtInstance != undefined) {
                    this.dtElement.dtInstance.then((dtInstance: any) => {
                        dtInstance.row('#' + uuid).deselect();
                    });
                }
            }
        }
        this.message.waiting = false;
        this.manageBtn();
    }

    private filterRows(): void {

        this.message.waiting = true;

        if (this.dtElement != undefined) {
            if (this.dtElement.dtInstance != undefined) {
                this.dtElement.dtInstance.then((dtInstance: any) => {
                    var rows_fil = dtInstance.rows({
                        filter: 'applied'
                    }).data();
                    if (rows_fil.length > 0) {
                        for (var i = 0; i < rows_fil.length; i++) {
                            for (var j = 0; j < this.data_tbl.length; j++) {
                                let uuid: string = this.data_tbl[j][1];
                                if (rows_fil[i].DT_RowId == uuid) {
                                    this.data_tbl[j][8] = true;
                                    dtInstance.row('#' + uuid).select();
                                }
                            }
                        }
                    } else {
                        for (var i = 0; i < this.data_tbl.length; i++) {
                            this.data_tbl[i][8] = true;
                            let uuid: string = this.data_tbl[i][1];
                            dtInstance.row('#' + uuid).select();
                        }
                    }
                });
            }
        }

        this.message.waiting = false;
        this.manageBtn();
    }

    private assignToTag(): void {

        let tagcostels: TagCostElement[] = [];

        if (this.dtElement != undefined) {
            if (this.dtElement.dtInstance != undefined) {
                this.dtElement.dtInstance.then((dtInstance: any) => {
                    var data = dtInstance.rows({
                        selected: true
                    }).data();
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            if (this.model.tagSelectedUuid != "" && data[i].DT_RowId != "") {
                                let tagcostel: TagCostElement = {
                                    IDENTIFIER: this.model.tagSelectedUuid,
                                    ELEMENT: data[i].DT_RowId
                                };
                                tagcostels.push(tagcostel);
                            }
                        }

                        if (tagcostels.length > 0) {
                            this.management_svc.addTagCostElement(tagcostels).subscribe(
                                complete => {
                                    this.deselectAllRows();
                                    this.listTag();
                                    this.message.isCostRecord = true;
                                },
                                error => {
                                    if (error != null)
                                        console.log(error);
                                }
                            );
                        }
                    }
                });
            }
        }
    }

    private manageBtn(): void {

        if (this.dtElement != undefined) {
            if (this.dtElement.dtInstance != undefined) {
                this.dtElement.dtInstance.then((dtInstance: any) => {
                    var data = dtInstance.rows({
                        selected: true
                    }).data();
                    if (data.length > 0)
                        dtInstance.button(2).enable();
                    else
                        dtInstance.button(2).disable();
                });
            }
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10000,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            language: {
                search: '',
                searchPlaceholder: " filter..."
            },
            columnDefs: [{
                orderable: false,
                targets: [0],
            }],
            order: [
                [1, "asc"]
            ],
            dom: 'Bfrtip',
            buttons: [{
                text: 'Unselect all',
                className: 'btn',
                action: function() {
                    this.deselectAllRows();
                }.bind(this)
            }, {
                text: 'Select all',
                className: 'btn',
                action: function() {
                    this.filterRows();
                }.bind(this)
            }, {
                text: 'Assign selected VM to tag',
                className: 'btn btn-success',
                enabled: false,
                action: function() {
                    this.assignToTag();
                }.bind(this)
            }]
        };
    }
}
