import { Component, OnInit, ViewChild } from '@angular/core';

import {
  first
} from 'rxjs/operators';

import {
  CloudStat,
  CloudTime,
  CloudTag,
  Message,
  MetaCO2,
  User
} from '@app/model';

import {
  AccountService,
  ManagementService,
  MeasurementService,
  ShareService
} from '@app/services';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);
import HC_map from 'highcharts/modules/map';
HC_map(Highcharts);
import HC_treemap from 'highcharts/modules/treemap';
HC_treemap(Highcharts);
import HC_export from 'highcharts/modules/exporting';
HC_export(Highcharts);

import * as moment from 'moment';


@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.css']
})
export class ConfiguratorComponent implements OnInit {

  ROUND_MAX: number = 100;

  isDcscope = true;
  isCo2scope = false;

  highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart | null;
  chartOptions: Highcharts.Options = {};
  chartOptions2: Highcharts.Options = {};
  chartOptions3: Highcharts.Options = {};
  chartOptions4: Highcharts.Options = {};

  message: Message;

  co2s: any = [];

  profiles: any = [];

  tags: any = [];

  tagNames: any = [];

  tagValues: any = [];

  pieTagCo2: any = [];
  dataMapTagCo2: any = [];

  isReady: boolean = false;
  isReady2: boolean = false;
  isReady3: boolean = false;
  isReady4: boolean = false;

  currentUser: User;

  model: any = {
    configuratorClass: 'clr-col-lg-12 clr-col-md-12 clr-col-12',
    pieTagClass: 'clr-col-lg-12 clr-col-md-12 clr-col-12',
    profile: 'monthly',
    profileMsg: '',
    chartType: 'column',
    tagName: 'none',
    tagValue: 'none',
    isProfileCard: true,
    isWithTag: false,
    isValidPie: false,
    isPieClick: false,
    aggregateTotalPie: 0,
    isValidMap: false,
    mapTagClass: 'clr-col-lg-12 clr-col-md-12 clr-col-12',
    event: null,
    xrange_min: 0,
    xrange_max: 0,
    isResetZoom: false,
    isZoomStat: false
  };


  constructor(private account_svc: AccountService,
    private mgt_svc: ManagementService,
    private measurement_svc: MeasurementService,
    private message_svc: ShareService) { }

  ngOnInit(): void {

    this.account_svc.user.subscribe(
      (user: User) => {
          this.currentUser = user;
      }
    );

    this.message_svc.currentMessage.subscribe(message => this.message = message);
    this.isCo2scope = this.message.isCO2Scope;
    this.isDcscope = this.message.isDCScope;

    this.tagNames.push(this.model.tagName);
    this.mgt_svc.getCloudTags(this.message.cloudProvider).pipe(first()).subscribe(
      (data: any[]) => {
        if(data.length > 0) {
          for(let i in data) {
            if(!this.tagNames.includes(data[i].NAME))
              this.tagNames.push(data[i].NAME);
          }
        }
        this.tags = data;
      }
    );

    this.profiles.push('daily');
    this.profiles.push('monthly');
    this.profiles.push('weekly');
    this.profiles.push('yearly');

    // INIT tag
    this.message.cloudMessage.tag = {
      tagName: 'none',
      tagValue: 'none',
      vmsTag: [] 
    };

    // INIT cloudstat
    this.message.cloudMessage.cloudStat = {
      start: '',
      end: '',
      avgCo2: 0,
      maxCo2: 0,
      avgWh: 0,
      maxWh: 0,
      avgHour: 0,
      maxHour: 0,
      aggHour: 0,
      aggWh: 0,
      aggWhUnit: '',
      aggCo2: 0,
      it: '',
      name: '',
      uuid: '',
      state: '',
      subscription: '',
      resgroup: '',
      evolutionMin: 0,
      evolutionAvg: 0,
      evolutionMax: 0
    };

    setTimeout(() => this.loadProfile(), 500);
  }

  loadGenericProfile(): void {

    this.resetTagVar();

    this.model.event = null;
    this.model.xrange_min = 0;
    this.model.isValidPie = false;
    this.model.isPieClick = false;
    this.model.isValidMap = false;

    this.loadProfile();
  }

  loadProfileForTagName(): void {

    this.tagValues = [];
  
    if(this.model.tagName != "none") {
      this.message.cloudMessage.tag.tagName = this.model.tagName;
      this.tagValues.push('none');
      this.model.isValidPie = true;
      this.model.isWithTag = true;
      this.message.cloudMessage.isWithTag = true;

      for(let i in this.tags) {
        if(this.tags[i].NAME == this.model.tagName) {
          if(!this.tagValues.includes(this.tags[i].VALUE))
            this.tagValues.push(this.tags[i].VALUE);
        }
      }
      if(this.tagValues.length > 0)
        this.model.tagValue = this.tagValues[0];

      this.message.cloudMessage.tag.vmsTag = [];
      for(let i in this.tags) {
        if(this.tags[i].NAME == this.model.tagName) {
          let vTag: any = {
            identifier: this.tags[i].IDENTIFIER,
            tagName: this.tags[i].NAME,
            tagValue: this.tags[i].VALUE
          };
          this.message.cloudMessage.tag.vmsTag.push(vTag);
        }
      }

      this.loadProfile();
    } else {
      this.resetTagVar();
      this.resetTagPie();
    }
  }

  loadProfileForTagValue(): void {

    if(this.model.tagValue == "none") {
      this.message.cloudMessage.tag.tagName = this.model.tagName;
      this.message.cloudMessage.tag.tagValue = this.model.tagValue;
    } else {
      for(let i in this.tags) {
        if(this.tags[i].NAME == this.model.tagName && this.tags[i].VALUE == this.model.tagValue) {
          this.message.cloudMessage.tag.tagName = this.model.tagName;
          this.message.cloudMessage.tag.tagValue = this.model.tagValue;
        }
      }
    }

    this.loadProfile();
  }

  loadPieChart(): void {

    if(this.model.tagName != "none") {
      this.isReady = false;
      this.isReady2 = false;
      this.model.isPieClick = true;
  
      // BUILD THE TAG VALUES LIST
      let tag_values: string[] = [];
      for(let i in this.tags) {
        if(this.tags[i].NAME == this.model.tagName) {
          if(!tag_values.includes(this.tags[i].VALUE))
            tag_values.push(this.tags[i].VALUE);          
        }
      }

      // BUILD PIE TAG CO2 OBJECT
      this.pieTagCo2 = [];

      for(let i in tag_values) {
        let tag_uuids: string[] = [];
        let preTagCo2: any = {
          name: tag_values[i],
          uuids: tag_uuids,
          co2_d: 0,
          co2_e: 0,
          y: 0
        };

        for(let j in this.tags) {
          if(this.tags[j].NAME == this.model.tagName && this.tags[j].VALUE == tag_values[i]) {
            if(!tag_uuids.includes(this.tags[j].IDENTIFIER))
              tag_uuids.push(this.tags[j].IDENTIFIER);
          }
        }
        this.pieTagCo2.push(preTagCo2);
      }

      this.buildTagPieData();
    } else {
      this.resetTagPie();
    }
  }

  loadMapChart(): void {
    
    this.model.isProfileCard = false;

    let isGeneric: boolean = true;
    if(this.model.isWithTag)
      isGeneric = false;

    let tag: any = {
      tagName: this.message.cloudMessage.tag.tagName,
      tagValue: this.message.cloudMessage.tag.tagValue
    };

    this.measurement_svc.getMapCarbonEmission(this.model.profile, this.message.cloudMessage.instanceIds, isGeneric, 
      this.message.cloudMessage.cloudTime, tag).pipe(first()).subscribe(
      (data: any) => {
        if(data.length > 0) {
          let chartData: any;
          if(this.model.profile == "monthly")
            chartData = this.generateMapData(data);
          else if(this.model.profile == "yearly")
            chartData = this.generateMapYearData(data);

          this.loadMapGraph(chartData);
        }
      },
      (error: any) => {
          if (error != null)
              console.log(error)
      }
    );
  }

  loadMapFromPieChart(): void {

    this.isReady2 = false;
    this.isReady4 = false;

    this.chartOptions4 = {
      credits: {
          enabled: false
      },
      title: {
        text: ''
      },
      colorAxis: {
        minColor: '#94a651',
        maxColor: '#10394c'
      },
      tooltip: {
        shared: false,
        useHTML: true,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span>&nbsp;<b>{point.value} kgCO2 eq</b><br/>'
      },
      exporting: {
        enabled: false
      },
      series: [{
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        clip: false,
        data: this.dataMapTagCo2
      }],
    }

    this.isReady4 = true;
  }

  loadProfile(): void {

    this.isReady = false;
    this.isReady2 = false;
    this.model.configuratorClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
    this.message.cloudMessage.profile = this.model.profile;

    if(this.model.profile == "monthly" || this.model.profile == "yearly")
      this.model.isValidMap = true;
    else
      this.model.isValidMap = false;

    this.buildProfileCarbonEmission();
  }

  private buildProfileCarbonEmission(): void {

    let isGeneric: boolean = true;
    if(this.model.isWithTag)
      isGeneric = false;

    this.measurement_svc.getProfileCarbonEmission(this.model.profile, this.message.cloudMessage.instanceIds, isGeneric, 
      this.message.cloudMessage.cloudTime, this.model.tagName, this.model.tagValue).pipe(first()).subscribe(
      (data: any) => {
        if(data.length > 0) {

          let checkDataNotNull: any = [];
          this.co2s = [];

          for(let d of data) {
            if(d.date != null) {
              this.co2s.push(d);
              checkDataNotNull.push(d);
            }
          }
          this.loadGraph();

          if(checkDataNotNull.length > 0) {
            let metaCO2 : MetaCO2 = {
              direct: Number(checkDataNotNull[checkDataNotNull.length-1].direct.toFixed(2)),
              grey: Number(checkDataNotNull[checkDataNotNull.length-1].grey.toFixed(2)),
              aggregate: Number((checkDataNotNull[checkDataNotNull.length-1].direct+checkDataNotNull[checkDataNotNull.length-1].grey).toFixed(2)),
              power: checkDataNotNull[checkDataNotNull.length-1].power
            };
  
            // DAILY & WEEKLY NOT CURRENT PROFILE
            switch(this.model.profile) {
              case "daily":
                this.message.cloudMessage.cloudTime.startProfile = moment(checkDataNotNull[checkDataNotNull.length-1].date).subtract(1, 'days').unix();
                this.message.cloudMessage.cloudTime.endProfile =moment(checkDataNotNull[checkDataNotNull.length-1].date).unix();
                break;
              case "weekly":
                this.message.cloudMessage.cloudTime.startProfile = moment(checkDataNotNull[checkDataNotNull.length-1].date).subtract(1, 'weeks').unix();
                this.message.cloudMessage.cloudTime.endProfile = moment(checkDataNotNull[checkDataNotNull.length-1].date).unix();
                break;
              case "monthly":
                // CHECK FOR THE CURRENT MONTH
                let lastProfileTime = moment(checkDataNotNull[checkDataNotNull.length-1].date).subtract(1, 'months').format('YYYY MMM');
                if(this.message.cloudMessage.cloudTime.currentMonth != lastProfileTime) {
                  this.message.cloudMessage.cloudTime.isCurrentMonth = false;
                  this.message.cloudMessage.cloudTime.startProfile = moment(checkDataNotNull[checkDataNotNull.length-1].date).subtract(1, 'months').unix();
                  this.message.cloudMessage.cloudTime.endProfile = moment(checkDataNotNull[checkDataNotNull.length-1].date).unix();
                } else {
                  this.message.cloudMessage.cloudTime.isCurrentMonth = true;
                  this.message.cloudMessage.cloudTime.startProfile = this.message.cloudMessage.cloudTime.startCurrentMonth;
                  this.message.cloudMessage.cloudTime.endProfile = this.message.cloudMessage.cloudTime.endCurrentMonth;
                }
                break;
              case "yearly":
                this.message.cloudMessage.cloudTime.startProfile = this.message.cloudMessage.cloudTime.startCurrentYear;
                this.message.cloudMessage.cloudTime.endProfile = this.message.cloudMessage.cloudTime.endCurrentYear;
                break;
              default:
                break;
            }

            this.message.cloudMessage.metaCo2 = metaCO2;
          }
        }
      },
      (error: any) => {
          if (error != null)
              console.log(error)
      }
    );
  }

  reloadGraph(): void {

    this.isReady = false;
    if(this.model.chartType == "line") {
      this.model.chartType = 'column';
    } else {
      this.model.chartType = 'line';
    }
    
    this.loadGraph();
  }

  private loadGraph(): void {

    let title: string = '';
    let titleSize: string = '20px';
    let grid: boolean = false;
    let buttonName: boolean = false;

    let data: any;
    let cat: any = [];
    let mergedMeasurements: any = [];

    data = this.buildProfileData();
    cat = data.categorie;
    mergedMeasurements = data.measurements;

    //this.chart.pointer.zoomX = false;
    

    this.chartOptions = {
        credits: {
            enabled: false
        },
        title: {
            text: title,
            style: {
                color: 'grey',
                fontSize: titleSize
            }
        },
        chart: {
          type: this.model.chartType,
          zoomType: "x",
          events: {
            selection: function(event: any) {
              
              this.isReady = false;
              setTimeout(() => this.getSelection(event), 100);
            }.bind(this)
          }
        },
        xAxis: {
            categories: cat,
            min: this.model.xrange_min,
            max: this.model.xrange_max,
            events: {
              afterSetExtremes: (event: { min: number; max: number; }) => {

                let min: number = Math.round(event.min);
                let max: number = Math.round(event.max);

                let min_str : any;
                let start: any;
                let max_str : any;
                let end: any;

                if(this.model.profile == "daily") {
                  min_str = cat[min].split(' ');
                  // LOOKING FOR A DAY GONE
                  start = moment(min_str[0] + '-' + min_str[1] + '-' + min_str[2]).add(1, 'day').unix();
                  max_str = cat[max].split(' ');
                  end = moment(max_str[0] + '-' + max_str[1] + '-' + max_str[2]).add(1, 'day').unix();
                } else if(this.model.profile == "weekly") {
                  let weekMap: any = data.profileMap;
                  start = moment(weekMap[min].date).unix();
                  end = moment(weekMap[max].date).unix();
                } else if(this.model.profile == "monthly") {
                  min_str = cat[min].split(' ');
                  start = moment(min_str[0] + '-' + min_str[1] + '-01').unix();
                  max_str = cat[max].split(' ');
                  end = moment(max_str[0] + '-' + max_str[1] + '-01').add(1, 'M').endOf('month').unix();
                }

                if(!this.model.isZoomStat)
                  this.loadZoomStat(start, end, cat[max]);
              }
            }
        },
        yAxis: {
            visible: grid,
            min: 0,
            title: {
                text: 'Total kgCO2 eq emission'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        tooltip: {
            shared: false,
            headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} kgCO2eq</b><br/>'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    crop: false
                }
            }
        },
        lang: {
            contextButtonTitle: "Export graph"
        },
        exporting: {
            buttons: {
                contextButton: {
                    className: "addLink",
                    enabled: buttonName,
                    symbol: 'download',
                    text: "download",
                    symbolStroke: "#0072A3",
                    x: -10
                }
            }
        },
        series: mergedMeasurements
    };
    this.isReady = true;
  }

  resetZoom(): void {

    this.isReady = false;
    this.model.event = null;
    this.model.xrange_min = 0;
    this.message.creditSelected = 'co2';
    this.model.isZoomStat = false;
    if(this.model.profile == "monthly" || this.model.profile == "yearly")
      this.model.isValidMap = true;

    setTimeout(() => this.isReady = true, 100);

    switch(this.model.profile) {
      case "monthly":
          this.message.cloudMessage.cloudTime.startProfile = this.message.cloudMessage.cloudTime.startCurrentMonth;
          this.message.cloudMessage.cloudTime.endProfile = this.message.cloudMessage.cloudTime.endCurrentMonth;
        break;
      default:
        break;
    }
  }

  chartCallback: Highcharts.ChartCallbackFunction = (chart: any) => {

    if(this.model.event != null) {
      this.model.isResetZoom = true;
      this.message.creditSelected = 'co2stat';
      chart.xAxis[0].setExtremes(this.model.event.xAxis[0].min, this.model.event.xAxis[0].max);
      this.model.xrange_min = this.model.event.xAxis[0].min;
      this.model.xrange_max = this.model.event.xAxis[0].max;
    } else {
      this.model.isResetZoom = false;
    }
  };

  private getSelection(event: any): any {

    if(this.model.profile != "yearly") {
      this.model.event = event;
    } else {
      this.model.isResetZoom = false;
      this.resetZoom();
    }
    
    this.isReady = true;
  }

  private loadZoomStat(start: number, end : number, max_str: string): void {

    this.model.isZoomStat = true;
    this.model.isValidMap = false;

    let isGeneric: boolean = true;
    if(this.model.isWithTag)
      isGeneric = false;

    let tag: any = {
      tagName: this.message.cloudMessage.tag.tagName,
      tagValue: this.message.cloudMessage.tag.tagValue
    };

    this.message.cloudMessage.cloudTime.startProfile = start;
    this.message.cloudMessage.cloudTime.endProfile = end;
    if(max_str == moment().format('YYYY MMM'))
      this.message.cloudMessage.cloudTime.isCurrentMonth = true;
    else
      this.message.cloudMessage.cloudTime.isCurrentMonth = false;

    this.measurement_svc.getStatCarbonEmission(this.model.profile, this.message.cloudMessage.instanceIds, isGeneric, 
      this.message.cloudMessage.cloudTime, tag).pipe(first()).subscribe(
      (data: any) => {
        if(data.length > 0) {
          let start_str: string = moment(start*1000).format('YYYY MMMM');
          let end_str: string = moment(end*1000).subtract(1, 'months').format('YYYY MMMM');
          if(this.model.profile == "daily") {
            let startDay: any = moment(start*1000).subtract(1, 'day').unix();
            start_str = moment(startDay*1000).format('MMMM Do YYYY');
            let endDay: any = moment(end*1000).subtract(1, 'day').unix();
            end_str = moment(endDay*1000).format('MMMM Do YYYY');
          } else if(this.model.profile == "weekly") {
            let startDay: any = moment(start*1000).subtract(1, 'week').unix();
            start_str = moment(startDay*1000).format('MMMM Do YYYY');
            let endDay: any = moment(end*1000).subtract(1, 'day').unix();
            end_str = moment(endDay*1000).format('MMMM Do YYYY');
          }

          let aggWhUnit: string = 'wh';
          let aggPower: number = data[0].aggWh;
          if(data[0].aggWh > 1000) {
            aggWhUnit = 'kWh';
            aggPower = data[0].aggWh / 1000;
          }

          this.message.cloudMessage.cloudStat = {
            start: start_str,
            end: end_str,
            avgCo2: data[0].avgCo2,
            maxCo2: data[0].maxCo2,
            avgWh: data[0].avgWh,
            maxWh: data[0].maxWh,
            avgHour: data[0].avgHour,
            maxHour: data[0].maxHour,
            aggHour: data[0].aggHour,
            aggWh: aggPower,
            aggWhUnit: aggWhUnit,
            aggCo2: data[0].aggCo2,
            it: '',
            name: '',
            uuid: '',
            state: '',
            subscription: '',
            resgroup: '',
            evolutionMin: 0,
            evolutionAvg: 0,
            evolutionMax: 0
          };
          this.measurement_svc.getStatMaxUuidCarbonEmission(this.message.cloudProvider, this.model.profile, this.message.cloudMessage.instanceIds, 
            isGeneric, this.message.cloudMessage.cloudTime, tag).pipe(first()).subscribe(
            (data2: any) => {
              if(data2.length > 0) {
                if(this.message.cloudMessage.cloudStat.maxCo2 == data2[0].co2) {
                  this.message.cloudMessage.cloudStat.it = data2[0].it;
                  this.message.cloudMessage.cloudStat.name = data2[0].name;
                  this.message.cloudMessage.cloudStat.uuid = data2[0].uuid;
                  this.message.cloudMessage.cloudStat.state = data2[0].state;
                  this.message.cloudMessage.cloudStat.subscription = data2[0].subscription;
                  this.message.cloudMessage.cloudStat.resgroup = data2[0].resgroup;
                }
              }
            },
            (error: any) => {
              if (error != null)
                  console.log(error)
            }
          );
          this.measurement_svc.getStatEvolutionCarbonEmission(this.model.profile, this.message.cloudMessage.instanceIds, isGeneric, 
            this.message.cloudMessage.cloudTime, tag).pipe(first()).subscribe(
            (data3: any) => {
              let evol_p: any = [];
              if(data3.length > 0) {
                for(let i=0; i < data3.length; i++) {
                  let j: number = i+1;
                  if(j < data3.length) {
                    if(data3[j].co2 > 0) {
                      let x: number = 100-(data3[i].co2*100/data3[j].co2);
                      evol_p.push(x);
                    }
                  }
                }
                let evol_stats: any = this.maxMinAvg(evol_p);
                this.message.cloudMessage.cloudStat.evolutionMax = evol_stats[0];
                this.message.cloudMessage.cloudStat.evolutionMin = evol_stats[1];
                this.message.cloudMessage.cloudStat.evolutionAvg = evol_stats[2];
              }
            },
            (error: any) => {
              if (error != null)
                  console.log(error)
            }
          );
        }
      },
      (error: any) => {
          if (error != null)
              console.log(error)
      }
    );
  }

  private maxMinAvg(arr: any) {
    var max = arr[0];
    var min = arr[0];
    var sum = arr[0];
    for (var i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            max = arr[i];
        }
        if (arr[i] < min) {
            min = arr[i];
        }
        sum = sum + arr[i];
    }
    var avg = sum / arr.length;
    return [max, min, avg];
}

  private buildProfileData(): any {

    let dateRef: any;
    let cat: any = [];
    let mergedMeasurements: any = [];
    let profileMap: any = [];
    let profileData: any = {
      "categorie": cat,
      "measurements": mergedMeasurements,
      "profileMap":profileMap
    };
    let currentModel = this.model;

    let data: number[] = [];
    let data2: number[] = [];
    let dataDirect: any = {
        "name": "direct CO2 emission",
        "color": "#10394c",
        "data": data,
        cursor: 'pointer',
        events: {
          click: function(event: any) {
            switch(currentModel.profile) {
              case "monthly":
                for(let mm of profileData.profileMap) {
                  if(mm.month == event.point.category) {
                    dateRef = mm.month;

                    break;
                  }
                }
                break;
              case "daily":
                for(let dm of profileData.profileMap) {
                  if(dm.day == event.point.category) {
                    dateRef = dm.date;
                    break;
                  }
                }
                break;
              case "weekly":
                for(let wm of profileData.profileMap) {
                  if(wm.week == event.point.category) {
                    dateRef = wm.date;
                    break;
                  }
                }
                break;
              case "yearly":
                for(let ym of profileData.profileMap) {
                  if(ym.year == event.point.category) {
                    dateRef = ym.date;
                    break;
                  }
                }
                break;
              default:
                break;
            }
          }
        },
        point: {
            events: {
                click: function() {
                  this.message.cloudMessage.profileDate = dateRef;
                  setTimeout(() => this.updateMetaCo2(), 100);
                }.bind(this)
            }
        }
    };
    let dataGrey: any = {
        "name": "embodied CO2 emission",
        "color": "#94a651",
        "data": data2,
        cursor: 'pointer',
        events: {
          click: function(event: any) {
            switch(currentModel.profile) {
              case "monthly":
                for(let mm of profileData.profileMap) {
                  if(mm.month == event.point.category) {
                    dateRef = mm.month;

                    break;
                  }
                }
                break;
              case "daily":
                for(let dm of profileData.profileMap) {
                  if(dm.day == event.point.category) {
                    dateRef = dm.date;
                    break;
                  }
                }
                break;
              case "weekly":
                for(let wm of profileData.profileMap) {
                  if(wm.week == event.point.category) {
                    dateRef = wm.date;
                    break;
                  }
                }
                break;
              case "yearly":
                for(let ym of profileData.profileMap) {
                  if(ym.year == event.point.category) {
                    dateRef = ym.date;
                    break;
                  }
                }
                break;
              default:
                break;
            }
          }
        },
        point: {
            events: {
                click: function() {
                  this.message.cloudMessage.profileDate = dateRef;
                  setTimeout(() => this.updateMetaCo2(), 100);
                }.bind(this)
            }
        }
    };
    mergedMeasurements.push(dataDirect);
    mergedMeasurements.push(dataGrey);

    for (let i = 0; i < this.co2s.length; i++) {
      let lastProfileTime: any;
      // IN MEASUREMENT THE PROFILE TIME IS GONE 
      switch(this.model.profile) {
        case "monthly":
          lastProfileTime = moment(this.co2s[i].date).subtract(1, 'months').format('YYYY MMM');
          let mapMonthly: any = {
            month: lastProfileTime,
            date: this.co2s[i].date
          };
          profileMap[i] = mapMonthly;
          break;
        case "daily":
          lastProfileTime = moment(this.co2s[i].date).subtract(1, 'days').format('YYYY MM DD');
          let mapDaily: any = {
            day: lastProfileTime,
            date: this.co2s[i].date
          };
          profileMap[i] = mapDaily;
          break;
        case "weekly":
          lastProfileTime = moment(this.co2s[i].date).subtract(1, 'weeks').week();
          let mapweek: any = {
            week: lastProfileTime,
            date: this.co2s[i].date
          };
          profileMap[i] = mapweek;
          break;
        case "yearly":
          lastProfileTime = moment(this.co2s[i].date).format('YYYY');
          let mapYearly: any = {
            year: lastProfileTime,
            date: this.co2s[i].date
          };
          profileMap[i] = mapYearly;
          break;
        default:
          break;
      }

      // BUILD CATEGORIES
      cat[i] = lastProfileTime;

      // BUILD DIRECT CO2 DATA
      let datadObj: any = {
        id: 'congigurator-co2-profil-time',
        y: 0
      };
      if (this.co2s[i].direct > this.ROUND_MAX) {
        datadObj.y = Math.round(this.co2s[i].direct);
      } else {
        let val: number = +this.co2s[i].direct;
        datadObj.y = Number(val.toFixed(2));
      }
      data[i] = datadObj;

      // BUILD EMBODIED CO2 DATA
      let dataeObj: any = {
        id: 'congigurator-co2-profil-time',
        y: 0
      };
      if (this.co2s[i].grey > this.ROUND_MAX)
        dataeObj.y = Math.round(this.co2s[i].grey);
      else {
        let val: number = +this.co2s[i].grey;
        dataeObj.y = Number(val.toFixed(2));
      }
      data2[i] = dataeObj;
    }

    // INIT dateRef
    if(cat.length > 0) {
      this.model.xrange_max = cat.length-1;
      let lastProfile: number = cat[cat.length-1];
      switch(this.model.profile) {
        case "monthly":
          for(let mm of profileData.profileMap) {
            if(mm.month == lastProfile) {
              dateRef = mm.month;
              break;
            }
          }
          this.message.cloudMessage.profileDate = dateRef;
          break;
        case "weekly":
          for(let wm of profileData.profileMap) {
            if(wm.week == lastProfile) {
              dateRef = wm.date;
              break;
            }
          }
          this.message.cloudMessage.profileDate = dateRef;
          break;
        case "daily":
          for(let dm of profileData.profileMap) {
            if(dm.day == lastProfile) {
              dateRef = dm.date;
              break;
            }
          }
          this.message.cloudMessage.profileDate = dateRef;
          break;
        case "yearly":
          for(let ym of profileData.profileMap) {
            if(ym.year == lastProfile) {
              dateRef = ym.date;
              break;
            }
          }
          this.message.cloudMessage.profileDate = dateRef;
          break;
        default:
          break;
      }
    }

    return profileData;
  }

  private updateMetaCo2(): void {

    let metaCO2 : MetaCO2 = {
      direct: 0,
      grey: 0,
      aggregate: 0,
      power: 0
    };

    let profile_str: any = '';

    switch(this.model.profile) {
        case "daily":
          profile_str = 'days';
          break;
        case "monthly":
          profile_str = 'months';
          break;
        case "weekly":
          profile_str = 'weeks';
          break;
        case "yearly":
          profile_str = 'years';
          break;
        default:
          break;
      }
    for(let co2 of this.co2s) {
      if(this.model.profile == "monthly") {
        let lastProfileTime = moment(co2.date).subtract(1, profile_str).format('YYYY MMM');
        if(lastProfileTime === this.message.cloudMessage.profileDate) {
          metaCO2.direct = Number(co2.direct.toFixed(2));
          metaCO2.grey = Number(co2.grey.toFixed(2));
          metaCO2.power = Number(co2.power.toFixed(2));
          metaCO2.aggregate = Number((co2.direct+co2.grey).toFixed(2));
  
          if(this.message.cloudMessage.cloudTime.currentMonth != lastProfileTime) {
            this.message.cloudMessage.cloudTime.isCurrentMonth = false;
            this.message.cloudMessage.cloudTime.startProfile = moment(co2.date).subtract(1, profile_str).unix();
            this.message.cloudMessage.cloudTime.endProfile = moment(co2.date).unix();
          } else {
            this.message.cloudMessage.cloudTime.isCurrentMonth = true;
            this.message.cloudMessage.cloudTime.startProfile = this.message.cloudMessage.cloudTime.startCurrentMonth;
            this.message.cloudMessage.cloudTime.endProfile = this.message.cloudMessage.cloudTime.endCurrentMonth;
          }
          break;
        }
      } else if(this.model.profile == "yearly") {
        if(co2.date === this.message.cloudMessage.profileDate) {
          metaCO2.direct = Number(co2.direct.toFixed(2));
          metaCO2.grey = Number(co2.grey.toFixed(2));
          metaCO2.power = Number(co2.power.toFixed(2));
          metaCO2.aggregate = Number((co2.direct+co2.grey).toFixed(2));
  
          if(this.message.cloudMessage.cloudTime.currentYear != this.message.cloudMessage.profileDate) {
            this.message.cloudMessage.cloudTime.isCurrentYear = false;
            this.message.cloudMessage.cloudTime.startProfile = moment(co2.date).subtract(1, profile_str).unix();
            this.message.cloudMessage.cloudTime.endProfile = moment(co2.date).unix();
          } else {
            this.message.cloudMessage.cloudTime.isCurrentYear = true;
            this.message.cloudMessage.cloudTime.startProfile = this.message.cloudMessage.cloudTime.startCurrentYear;
            this.message.cloudMessage.cloudTime.endProfile = this.message.cloudMessage.cloudTime.endCurrentYear;
          }
          break;
        }
      } else {
        if(co2.date === this.message.cloudMessage.profileDate) {
          metaCO2.direct = Number(co2.direct.toFixed(2));
          metaCO2.grey = Number(co2.grey.toFixed(2));
          metaCO2.power = Number(co2.power.toFixed(2));
          metaCO2.aggregate = Number((co2.direct+co2.grey).toFixed(2));

          this.message.cloudMessage.cloudTime.startProfile = moment(co2.date).subtract(1, profile_str).unix();
          this.message.cloudMessage.cloudTime.endProfile = moment(co2.date).unix();
          break;
        }
      }
    }

    this.message.cloudMessage.metaCo2 = metaCO2;

    if(this.model.isValidPie && this.model.isPieClick)
      setTimeout(() => this.loadPieChart(), 100);
  }

  private resetTagVar(): void {

    this.tagValues = [];
    this.model.tagName = 'none';
    this.model.tagValue = 'none';
    this.message.cloudMessage.tag.tagName = 'none';
    this.message.cloudMessage.tag.tagValue = 'none';
    this.message.cloudMessage.tag.vmsTag = [];
    this.model.isWithTag = false;
    this.message.cloudMessage.isWithTag = false;
  }

  private resetTagPie(): void {

    this.model.isValidPie = false;
    this.model.isPieClick = false;
    this.model.configuratorClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
    this.model.isValidMap = false;

    this.loadProfile();
  }

  private buildTagPieData(): void {

    let start: number = this.message.cloudMessage.cloudTime.startProfile;
    let end: number = this.message.cloudMessage.cloudTime.endProfile;

    this.buildTagPieMessage();

    let i: number = 0;
    this.model.aggregateTotalPie = 0;

    let isCurrent: boolean = false;
    switch(this.model.profile) {
      case "monthly":
        if(this.message.cloudMessage.cloudTime.isCurrentMonth)
          isCurrent = true;

        break;
      case "yearly":
          if(this.message.cloudMessage.cloudTime.isCurrentYear)
            isCurrent = true;
  
          break;
      default:
        break;
    }

    this.dataMapTagCo2 = [];

    for(let tagCo2 of this.pieTagCo2) {
      this.measurement_svc.getTagCarbonEmission(this.model.profile, tagCo2.uuids, isCurrent, start, end).pipe(first()).subscribe(
        (data: any) => {
          if(data.length > 0) {
            if(data[0].date != null) {
              tagCo2.co2_d = Number(data[0].direct.toFixed(2));
              tagCo2.co2_e = Number(data[0].grey.toFixed(2));
              tagCo2.y = Number((data[0].direct+data[0].grey).toFixed(2));
              this.model.aggregateTotalPie += tagCo2.y;

              // LOAD PIE TO MAP DATA (IF NEED)
              let dataMap : any = {
                name: tagCo2.name,
                value: tagCo2.y,
                colorValue: tagCo2.y
              };
              this.dataMapTagCo2.push(dataMap);
            }
          }
        },
        (error: any) => {
            if (error != null)
                console.log(error)
        }
      );

      if(i == this.pieTagCo2.length-1) {
        setTimeout(() => this.loadTagGraph(this.pieTagCo2), 1000);
        setTimeout(() => this.isReady = true, 1000);
      }

      ++i;
    }
  }

  private buildTagPieMessage(): void {

    switch(this.model.profile) {
      case "daily":
        let startDay = moment(this.message.cloudMessage.profileDate).subtract(1, 'days').unix()*1000;
        let day_str: string = moment.unix(startDay/1000).format('dddd, MMMM Do YYYY');
        this.model.profileMsg = 'over the ' + day_str;
        break;
      case "monthly":
        this.model.profileMsg = 'over ' + this.message.cloudMessage.profileDate;
        break;
      case "weekly":
        let startWeek = moment(this.message.cloudMessage.profileDate).subtract(1, 'weeks').weeks();
        this.model.profileMsg = 'over the week ' + startWeek;
        break;
      case "yearly":
        this.model.profileMsg = 'over ' + this.message.cloudMessage.profileDate;
        break;
      default:
        break;
    }
  }

  private loadTagGraph(pieTagCo2: any): void {

    this.model.isProfileCard = false;

    let title: string = '';
    let titleSize: string = '20px';

    this.chartOptions2 = {
        credits: {
            enabled: false
        },
        title: {
            text: title,
            style: {
                color: 'grey',
                fontSize: titleSize
            }
        },
        chart: {
            plotBorderWidth: null,
            plotShadow: false
        },
        tooltip: {
            shared: false,
            headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
        },
        legend: {
            enabled: true
        },
        exporting: {
            enabled: false
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    crop: false
                }
            }
        },
        series: [{
          type: 'pie',
          innerSize: '50%',
          name: 'carbon emission',
          data: pieTagCo2
        }]
    };
    this.isReady2 = true;
  }

  private loadMapGraph(chartData: any): void {

    let cat = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let pointFormat: string = '{#unless point.custom.empty}{point.date:%A, %b %e, %Y}{/unless}<br>{point.value:.2f} kgCO2 eq';
    let rangeDescription: string = 'X Axis is showing all 7 days of the week, starting with Sunday.';

    if(this.model.profile == "yearly") {
      cat = ['week 1','2','3','4','5','6','7','8','9','week 10'];
      pointFormat = '{#unless point.custom.empty}week {point.custom.id}{/unless}<br>[{point.date:%A, %b %e, ' +
              '%Y }{/unless} - {point.date2:%A, %b %e, %Y }[{/unless}<br>{point.value:.2f} kgCO2 eq';
      rangeDescription = 'X Axis is showing week, starting with Sunday.';
    }
      
    this.chartOptions3 = {
      credits: {
          enabled: false
      },
      title: {
        text: ''
      },
      accessibility: {
        landmarkVerbosity: 'one'
      },
      tooltip: {
        enabled: true,
        outside: true,
            useHTML: true,
            headerFormat: '',
            pointFormat: pointFormat,
            nullFormat: 'No data'
        },
        xAxis: {
            categories: cat,
            opposite: true,
            lineWidth: 26,
            offset: 13,
            lineColor: 'rgba(27, 26, 37, 0.2)',
            labels: {
                rotation: 0,
                y: 2,
                style: {
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }
            },
            accessibility: {
                description: 'weeks',
                rangeDescription: rangeDescription
            },
            visible: true
        },
        yAxis: {
            min: 0,
            max: 5,
            accessibility: {
                description: 'weeks'
            },
            visible: false
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'middle'
        },
        colorAxis: {
            min: 0,
            stops: [
                [0.2, 'lightblue'],
                [0.4, '#CBDFC8'],
                [0.6, '#F3E99E'],
                [0.9, '#F9A05C']
            ],
            labels: {
                format: '{value}'
            }
        },
        series: [{
            keys: ['x', 'y', 'value', 'date', 'id'],
            type: 'heatmap',
            data: chartData,
            nullColor: 'rgba(196, 196, 196, 0.2)',
            borderWidth: 2,
            borderColor: 'rgba(196, 196, 196, 0.2)',
            dataLabels: [{
                enabled: true,
                format: '{#unless point.custom.empty}{point.value:.2f}{/unless}',
                style: {
                    textOutline: 'none',
                    fontWeight: 'normal',
                    fontSize: '0.9rem'
                },
                y: 4
              }, {
                enabled: true,
                align: 'left',
                verticalAlign: 'top',
                format: '{#unless ' +
                    'point.custom.empty}{point.custom.id}{/unless}',
                backgroundColor: 'whitesmoke',
                padding: 2,
                style: {
                    textOutline: 'none',
                    color: 'rgba(70, 70, 92, 1)',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    opacity: 0.5
                },
                x: 1,
                y: 1
            }]
        }]
      };
    
      this.isReady3 = true;
  }

  private generateMapData(data: any): any {

    const firstWeekday = new Date(data[0].date).getDay(),
      monthLength = data.length,
      emptyTilesFirst = firstWeekday,
      chartData = [];

    // Add the empty tiles before the first day of the month with null values to take up space in the chart
    for (let emptyDay = 0; emptyDay < emptyTilesFirst; emptyDay++) {
        chartData.push({
            x: emptyDay,
            y: 5,
            value: null,
            date: null,
            custom: {
                empty: true
            }
        });
    }

    // Loop through and populate with co2 and dates from the dataset
    for (let day = 1; day <= monthLength; day++) {
        // Get date from the given data array
        const date = data[day - 1].date;
        // Offset by thenumber of empty tiles
        const xCoordinate = (emptyTilesFirst + day - 1) % 7;
        const yCoordinate = Math.floor((firstWeekday + day - 1) / 7);
        const id = day;

        const co2 = data[day - 1].co2;

        chartData.push({
            x: xCoordinate,
            y: 5 - yCoordinate,
            value: co2,
            date: new Date(date).getTime(),
            custom: {
                id: id
            }
        });
    }

    return chartData;
  }

  private generateMapYearData(data: any): any {

    const firstWeek = moment(data[0].date).weeks(),
      yearLength = data.length,
      emptyTilesFirst = firstWeek,
      chartData = [];

    // Add the empty tiles before the first day of the month with null values to take up space in the chart
    for (let emptyWeek = 0; emptyWeek < emptyTilesFirst; emptyWeek++) {
        chartData.push({
            x: emptyWeek,
            y: 5,
            value: null,
            date: null,
            custom: {
                empty: true
            }
        });
    }

    // Loop through and populate with co2 and dates from the dataset
    for (let week = 1; week <= yearLength; week++) {
        // Get date from the given data array
        const date = data[week - 1].date;
        // Offset by thenumber of empty tiles
        const xCoordinate = (emptyTilesFirst + week - 2) % 10;
        const yCoordinate = Math.floor((firstWeek + week - 2) / 10);
        const id = week-1+firstWeek;

        const co2 = data[week - 1].co2;

        let date2: any = moment(date).add(1, 'week').format('YYYY-MM-DD');

        chartData.push({
            x: xCoordinate,
            y: 5 - yCoordinate,
            value: co2,
            date: new Date(date).getTime(),
            date2: new Date(date2).getTime(),
            custom: {
              id: id
            }
        });
    }

    return chartData;
  }
}
