import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[cloud-graph-counter-stack]'
})
export class CloudgraphcounterstatckDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
