import {
    Component,
    OnInit
} from '@angular/core';

@Component({
    selector: 'app-kubernetes',
    templateUrl: './kubernetes.component.html',
    styleUrls: ['./kubernetes.component.css']
})
export class KubernetesComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

}
