<div class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:20px;">CPU</span>
				<span class="label" style="float:right">
					vm
					<span class="badge">{{cpu_model.length}}</span>
				</span>
			</div>
			<div class="card-block">
				<ng-template load-recommendation-resizing-cpu></ng-template>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:20px;">RAM</span>
				<span class="label" style="float:right">
					vm
					<span class="badge">{{ram_model.length}}</span>
				</span>
			</div>
			<div class="card-block">
				<ng-template load-recommendation-resizing-ram></ng-template>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card">
			<div class="card-header">
				<span style="color:grey;font-size:20px;">DISK</span>
				<span class="label" style="float:right">
					item
					<span class="badge">{{disk_model.length}}</span>
				</span>
			</div>
			<div class="card-block">
				<ng-template load-recommendation-resizing-disk></ng-template>
			</div>
		</div>
	</div>
</div>
