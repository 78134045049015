<div class="card">
        <div class="card-header">
		<div class="clr-row">
			<div class="clr-col-lg-1 clr-col-md-1 clr-col-12">
				<div class="clr-control-container">
					<div class="clr-select-wrapper">
						<select class="clr-select" [(ngModel)]="model.profile" (ngModelChange)="loadProfile()">
							<option>monthly</option>
							<option>yearly</option>
						</select>
					</div>
					<span class="clr-subtext">select a profile</span>
				</div>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="model.profile == 'yearly'">
				<div class="clr-control-container">
                                        <div class="clr-select-wrapper">
                                                <select class="clr-select"
                                                        [(ngModel)]="model.selectedYear"
                                                        (ngModelChange)="loadYear($event)">
                                                        <option *ngFor="let year of years">{{year}}</option>
                                                </select>
                                        </div>
                                        <span class="clr-subtext">select year to display</span>
                                </div>
                        </div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="model.profile == 'monthly'">
				<div class="clr-control-container">
					<div class="clr-select-wrapper">
						<select class="clr-select"
							[(ngModel)]="model.selectedMonth"
							(ngModelChange)="loadMonth($event)">
							<option *ngFor="let month of months">{{month.name}}</option>
						</select>
					</div>
					<span class="clr-subtext">select month to display</span>
				</div>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<input type="text" placeholder="0" class="clr-input" [(ngModel)]="model.threshold" (ngModelChange)="updateThreshold()">
      					</div>
					<clr-control-helper>filter from a surplus credit value</clr-control-helper>
    				</div>
			</div>
			<div class="clr-col-lg-5 clr-col-md-5 clr-col-12" *ngIf="granularity == 'HOURLY'">
				<button class="btn btn-link btn-sm" style="float: right;size: 20px;" (click)="reset()">
					RESET ZOOM
        			</button>
			</div>
		</div>
	</div>
	<div class="card-block" style="text-align: center;">
		<highcharts-chart
			[Highcharts] = "highcharts"
			[options] = "chartOptions"
			[constructorType]="'chart'"
			style = "width: 100%;display: block;" *ngIf="isReady">
		</highcharts-chart>
		<label *ngIf="!isReady">{{msg_state}}</label>
	</div>
</div>