<clr-datagrid>
	<clr-dg-column [clrDgField]="'element'" [clrDgSortOrder]="dataOrderDesc"><ng-container *clrDgHideableColumn="{hidden: false}">Element</ng-container></clr-dg-column>
	<clr-dg-column [clrDgField]="'alert'"><ng-container *clrDgHideableColumn="{hidden: false}">Alert</ng-container></clr-dg-column>
	<clr-dg-column [clrDgField]="'base'"><ng-container *clrDgHideableColumn="{hidden: false}">Based on</ng-container></clr-dg-column>
	<clr-dg-column [clrDgField]="'about'"><ng-container *clrDgHideableColumn="{hidden: false}">About</ng-container></clr-dg-column>
	<clr-dg-column ><ng-container *clrDgHideableColumn="{hidden: false}">Action</ng-container></clr-dg-column>
	
	<clr-dg-row *clrDgItems="let alert of triggered_alerts" [clrDgItem]="alert">
		<clr-dg-cell>{{alert.element}}</clr-dg-cell>
		<clr-dg-cell>{{alert.alert}} [{{alert.name}}]</clr-dg-cell>
		<clr-dg-cell>{{alert.base}}</clr-dg-cell>
		<clr-dg-cell>{{formatDef(alert.uuid_alert)}}</clr-dg-cell>
		<clr-dg-cell>
			<a style="cursor: pointer;color:grey" (click)="gotoGraph(alert)">
					<span *ngIf="alert.action_text != 'none'">
						<cds-icon [attr.shape]="alert.action_icon"></cds-icon>
					</span>
			</a>
			<span style="size: 10px; color: #0072a3;">&nbsp;&nbsp;{{alert.action_text}}</span>
		</clr-dg-cell>
	</clr-dg-row>                  
	<clr-dg-footer>
		<clr-dg-pagination #alert [clrDgPageSize]="10">
			<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
			{{alert.firstItem + 1}} - {{alert.lastItem + 1}} of {{alert.totalItems}} entries
		</clr-dg-pagination>
	</clr-dg-footer>
</clr-datagrid>