import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-costgeneric-server]'
})
export class CostservergenericDirective {

    constructor(public viewContainerRef: ViewContainerRef) {}

}
