<div class="clr-row">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="line-chart"></cds-icon>&nbsp;Power Graph
                </h5>

                <app-power-combobox (onChange)="reset();" [allElements]="allElements" [(selectedElements)]="selectedElements" [targetInfrastructure]="targetInfrastructure" style="width:100%;display:inline-block;"></app-power-combobox>
            </div>

            <div class="card-block" style="width:100%;display:block;">
                <app-power-graph [selectedElements]="selectedElements" [targetInfrastructure]="targetInfrastructure" #powerGraph></app-power-graph>
            </div>
        </div>
    </div>
</div>