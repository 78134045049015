<div class="card">
	<div class="card-header card-adjust">
		<span class="export"></span>
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<span class="search"></span>
	</div>
	<div class="card-block">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  			<thead>
    				<tr>
      					<th>NAME</th>
      					<th>CPU</th>
      					<th>RAM (GB)</th>
      					<th>OPERATING SYSTEM</th>
    				</tr>
  			</thead>
  			<tbody>
    				<tr *ngFor="let host of filterHost()" ngClass="addLink" (click)="callroutehost(host)" class="addLink">
					<td>{{host.name}}</td>
					<td>{{host.cpucap}}</td>
					<td>{{math.round(host.ramcap/1024)}}</td>
					<td>{{host.os}}</td>
    				</tr>
  			</tbody>
		</table>
	</div>
</div>
