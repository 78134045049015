import {
    Directive,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[load-t12]'
})
export class T12Directive {

    constructor(public viewContainerRef12: ViewContainerRef) {}

}
