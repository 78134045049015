import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Subject
} from 'rxjs';
import {
    first
} from 'rxjs/operators';

import {
    DataTableDirective
} from 'angular-datatables';

import * as moment from 'moment';

import {
    AccountService,
    JsonloaderService,
    LcyService,
    ShareService
} from '@app/services';

import {
    JSONTarget,
    Lcy,
    Message,
    User,
    VmSynthesis
} from '@app/model';
import * as $ from 'jquery';


@Component({
    selector: 'app-lcy',
    templateUrl: './lcy.component.html',
    styleUrls: ['./lcy.component.css']
})
export class LcyComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: any = {};

    dtTrigger: Subject < any > = new Subject();

    message: Message;

    data_vms: VmSynthesis[] = [];

    data_events: Lcy[] = [];

    moment = moment;


    constructor(
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private lifecycle_svc: LcyService,
        private data: ShareService) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();
        $("dt-buttons").remove();

        this.data.currentMessage.subscribe(message => this.message = message);

        this.initDtOptions();

        this.data_vms = this.json_svc.json.vmSynthesis;
    }

    ngAfterViewInit(): void {

        this.message.waiting = true;
        this.buildData();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    private buildData(): void {

        if (this.message.currentUuid == "") {
            if (this.data_vms.length > 0)
                this.message.currentUuid = this.data_vms[0].uuid;
        }

        if (this.message.currentUuid != "") {
            this.lifecycle_svc.getLcy(this.message.currentUuid).pipe(first()).subscribe(
                data => {
                    this.data_events = data;
                    this.dtTrigger.next();
                    this.message.waiting = false;
                },
                error => {
                    this.message.waiting = false;
                    if (error != null)
                        console.log(error)
                }
            );
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
                $('div.dt-buttons').appendTo('span.export');
            },
            language: {
                search: '',
                searchPlaceholder: " search..."
            },
            dom: '<"top"B><"clear">frtip',
            buttons: [{
                extend: 'csv',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'copy',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'print',
                className: 'btn btn-link btn-sm'
            }],
            order: [
                [0, 'desc']
            ],
        };
    }
}
