<table id="reco-server-stop" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
	<thead>
		<tr>
			<th></th>
			<th>Server</th>
			<th>Number of VMs</th>
			<th>CPU Consumption</th>
			<th>RAM Consumption</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let reco of filter_srv">
			<td>
				<clr-checkbox-wrapper>
					<input type="checkbox" clrCheckbox name="serverstop" value="option1" [(ngModel)]="reco.isStop" (ngModelChange)="updateServerToStop(reco)" />
				</clr-checkbox-wrapper>
			</td>
			<td>{{reco.name}}</td>
			<td>{{reco.vm_nb}}</td>
			<td>{{reco.avg_cpu_cons}}%</td>
			<td>{{reco.avg_ram_cons}}%</td>
		</tr>
	</tbody>
</table>
