<div class="clr-row" *ngIf="message.creditSelected == 'co2'">
    <div class="card" *ngIf="!isSelected">
        <div class="card-block">
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!isCo2Year">
					<div class="clr-row">
						<div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
							<span style="left: right;color: grey; font-weight: bold;">
                                The instances list {{model.profileMsg}}
                            </span>
						</div>
						<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
							<button class="btn btn-link" style="position:relative;float:right;" (click)="exportInstances()">
								Export Instances
							</button>
						</div>
					</div>
					<div class="datagrid-limit-height" >
						<clr-datagrid >
							<clr-dg-column [clrDgField]="'instanceId'" [clrDgSortOrder]="dataOrder" *ngIf="provider == 'aws'"><ng-container *clrDgHideableColumn="{hidden: false}">Id</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Subscription</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'aws'"><ng-container *clrDgHideableColumn="{hidden: false}">Account</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'gcp'"><ng-container *clrDgHideableColumn="{hidden: false}">Project</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'region'"><ng-container *clrDgHideableColumn="{hidden: false}">Region</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'tagName'" *ngIf="message.cloudMessage.isWithTag"><ng-container *clrDgHideableColumn="{hidden: false}">Tag name</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'tagValue'" *ngIf="message.cloudMessage.isWithTag"><ng-container *clrDgHideableColumn="{hidden: false}">Tag value</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'resgroup'" *ngIf="provider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Resource</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'instanceType'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'state'"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'runH'"><ng-container *clrDgHideableColumn="{hidden: false}">Running hours</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'direct_power'" [clrDgSortOrder]="dataOrder2"><ng-container *clrDgHideableColumn="{hidden: false}">Direct power (Wh)</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'direct_co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Direct CO2 emission (kg CO2eq)</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'grey_co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Embodied emission (kg CO2eq)</ng-container></clr-dg-column>
							<clr-dg-row *clrDgItems="let instance of instances" [clrDgItem]="instance">
								<clr-dg-cell *ngIf="provider == 'aws'">{{instance.identifier}}</clr-dg-cell>
								<clr-dg-cell>{{instance.name}}</clr-dg-cell>
								<clr-dg-cell>{{instance.subscriptionDesc.subscription}}</clr-dg-cell>
								<clr-dg-cell>{{instance.subscriptionDesc.region}}</clr-dg-cell>
								<clr-dg-cell *ngIf="message.cloudMessage.isWithTag">{{instance.tagName}}</clr-dg-cell>
								<clr-dg-cell *ngIf="message.cloudMessage.isWithTag">{{instance.tagValue}}</clr-dg-cell>
								<clr-dg-cell *ngIf="provider == 'azure'">{{instance.subscriptionDesc.resgroup}}</clr-dg-cell>
								<clr-dg-cell>{{instance.instanceType}}</clr-dg-cell>
								<clr-dg-cell>{{instance.state}}</clr-dg-cell>
								<clr-dg-cell>{{instance.runH}}</clr-dg-cell>
								<clr-dg-cell>{{instance.direct_power.toFixed(2)}}</clr-dg-cell>
								<clr-dg-cell>{{instance.direct_co2.toFixed(2)}}</clr-dg-cell>
								<clr-dg-cell>{{instance.grey_co2.toFixed(2)}}</clr-dg-cell>
							</clr-dg-row>
							<clr-dg-footer>
								<clr-dg-pagination #pagination [clrDgPageSize]="10">
									<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
										entries per page
									</clr-dg-page-size>
									{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
								</clr-dg-pagination>
							</clr-dg-footer>
						</clr-datagrid>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
