<div style="width: 100%; height: 580px; display: block;">
	<highcharts-chart style="width: 100%; height: 100%; display: block;"
		[Highcharts] = "highcharts"
		[options] = "chartOptions"
		[constructorType]="'stockChart'"
		[callbackFunction]="chartCallback"
		[(update)]="updateFlag"
		[oneToOne]=true
		[hidden]="!isReady">
	</highcharts-chart>

	<div *ngIf="!isReady" class="alert alert-warning alert-sm" role="alert" style="margin: 20px;">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                </div>
                <div class="alert-text">
                    {{ noDataMessage }}
                </div>
            </div>
        </div>
    </div>
</div>