<clr-modal [(clrModalOpen)]="openGenerateReportModal">
    <h3 class="modal-title">{{ "dcnetscope.supervision.report_modal.title" | transloco }}</h3>
    <div class="modal-body">
        <clr-radio-container clrInline>
            <label>{{ "dcnetscope.supervision.report_modal.select_report_type" | transloco }}</label>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="duration_type" required value="single_day" [(ngModel)]="duration_type" />
                <label>{{ "dcnetscope.supervision.report_modal.single_day" | transloco }}</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="duration_type" required value="multiple_days" [(ngModel)]="duration_type" />
                <label>{{ "dcnetscope.supervision.report_modal.multiple_days" | transloco }}</label>
            </clr-radio-wrapper>
            <clr-control-error>This field is required!</clr-control-error>
        </clr-radio-container>

        <clr-radio-container clrInline>
            <label>{{ "dcnetscope.supervision.report_modal.select_email_sending" | transloco }}</label>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="email_send" required value="yes" [(ngModel)]="email_send" />
                <label>{{ "dcnetscope.supervision.report_modal.yes_label" | transloco }}</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="email_send" required value="no" [(ngModel)]="email_send" />
                <label>{{ "dcnetscope.supervision.report_modal.no_label" | transloco }}</label>
            </clr-radio-wrapper>
            <clr-control-error>This field is required!</clr-control-error>
        </clr-radio-container>

        <div *ngIf="duration_type == 'single_day'">
            <form clrForm>
                <clr-date-container>
                    <label>{{ "dcnetscope.supervision.report_modal.date" | transloco }}</label>
                    <input type="date" autocomplete="off" clrDate name="report_start_date" [(clrDate)]="start_date" />
                </clr-date-container>
            </form>
        </div>

        <div *ngIf="duration_type == 'multiple_days'">
            <form clrForm>
                <clr-date-container>
                    <label>{{ "dcnetscope.supervision.report_modal.start_date" | transloco }}</label>
                    <input type="date" autocomplete="off" clrDate name="report_start_date" [(clrDate)]="start_date" />
                </clr-date-container>
                <clr-date-container>
                    <label>{{ "dcnetscope.supervision.report_modal.end_date" | transloco }}</label>
                    <input type="date" autocomplete="off" clrDate name="report_end_date" [(clrDate)]="end_date" />
                </clr-date-container>
            </form>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="closeModal();">
            {{ "dcnetscope.supervision.report_modal.cancel" | transloco }}
        </button>
        <button type="button" class="btn btn-primary" disabled="{{ ((this.duration_type == 'single_day' && start_date === undefined) || (this.duration_type == 'multiple_days' && (start_date === undefined || end_date === undefined))) ? 'disabled' : '' }}" (click)="generateReport(); closeModal();">
            {{ "dcnetscope.supervision.report_modal.generate_report" | transloco }}
        </button>
    </div>
</clr-modal>

<div class="clr-row" style="padding: 15px;">
    <div class="clr-col-12">
        <button class="btn btn-primary" (click)="openGenerateReportModal = true;">{{ "dcnetscope.supervision.generate_report" | transloco }}</button>

        <clr-datagrid *ngIf="reportsData !== undefined">
            <clr-dg-column>{{ "dcnetscope.supervision.uuid_label" | transloco }}</clr-dg-column>
            <clr-dg-column>{{ "dcnetscope.supervision.state_label" | transloco }}</clr-dg-column>
            <clr-dg-column>{{ "dcnetscope.supervision.creation_label" | transloco }}</clr-dg-column>
            <clr-dg-column>{{ "dcnetscope.supervision.start_label" | transloco }}</clr-dg-column>
            <clr-dg-column>{{ "dcnetscope.supervision.end_label" | transloco }}</clr-dg-column>
            <clr-dg-column>{{ "dcnetscope.supervision.total_duration_label" | transloco }}</clr-dg-column>
            <clr-dg-column>{{ "dcnetscope.supervision.parameters_label" | transloco }}</clr-dg-column>
            <clr-dg-column>{{ "dcnetscope.supervision.actions_label" | transloco }}</clr-dg-column>

            <clr-dg-row *clrDgItems="let reportsRequest of reportsData.reports">
                <clr-dg-cell>{{ reportsRequest.uuid }}</clr-dg-cell>
                <clr-dg-cell>{{ reportsRequest.state }}</clr-dg-cell>
                <clr-dg-cell>{{ reportsRequest.created_at * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}</clr-dg-cell>
                <clr-dg-cell>{{ reportsRequest.launched_at * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}</clr-dg-cell>
                <clr-dg-cell>{{ reportsRequest.finished_at * 1000 | date: 'dd/MM/yyyy HH:mm:ss' }}</clr-dg-cell>
                <clr-dg-cell>{{ (reportsRequest.elapsed_time) }} {{ "dcnetscope.supervision.seconds" | transloco }}</clr-dg-cell>
                <clr-dg-cell>
                    <clr-signpost>
                        <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
                            <h3>Parameters</h3>
                            <p>
                                <code>
                                    {{ reportsRequest.params | json }}
                                </code>
                            </p>
                        </clr-signpost-content>
                    </clr-signpost>
                </clr-dg-cell>
                <clr-dg-cell>
                    <button (click)="downloadReport(reportsRequest.uuid)" class="btn btn-sm" *ngIf="reportsRequest.state == 'done'">{{ "dcnetscope.supervision.download_button_label" | transloco }}</button>
                    <button (click)="deleteReport(reportsRequest.uuid)" class="btn btn-sm btn-danger">{{ "dcnetscope.supervision.delete_button_label" | transloco }}</button>
                </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>{{ reportsData.reports.length }} items</clr-dg-footer>
        </clr-datagrid>
    </div>
</div>