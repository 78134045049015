<div class="clr-row" style="min-width: 1200px;">
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12" style="text-align:center">
        <label class="clr-control-label" style="font-size:inherit">
            Details of allocation & consumption on :<br>
            {{capaplan_lastdate_format}}
        </label>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
        <clr-radio-container clrInline style="margin-left:20%;margin-top:0px;">
            <label style="font-size:inherit">Simulation option :</label>
            <clr-radio-wrapper data-toggle="tooltip" data-placement="bottom" title="Disaster Recovery : only VM with the desired HA level">
                <input type="radio" clrRadio name="capaplan_simulation_option" required [value]=1 [(ngModel)]="capaplan_simulation_option"/>
                <label>DR</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper  data-toggle="tooltip" data-placement="bottom" title="Business Continuity Plan : all VM hosted on available servers & only VM with the desired HA level hosted on removed servers">
                <input type="radio" clrRadio name="capaplan_simulation_option" required [value]=0 [(ngModel)]="capaplan_simulation_option"/>
                <label>BCP</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper  data-toggle="tooltip" data-placement="bottom" title="Filter : only VM selected in the filter">
                <input type="radio" clrRadio name="capaplan_simulation_option" required [value]=2 [(ngModel)]="capaplan_simulation_option"/>
                <label>Filter</label>
            </clr-radio-wrapper>
        </clr-radio-container>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
        <clr-range-container [clrRangeHasProgress]="true" style="margin-left:20%;margin-top:0px;">
            <label style="font-size:inherit">HA Priority :</label>
            <input name="haslider" type="range" clrRange [(ngModel)]="capaplan_haslider_level" min="0" max="5" step="1" (change)="hasliderChange($event.target.value)"/>
            <clr-control-helper style="color:black;">
                <span style="float:left;">{{capaplan_haslider_label}}</span>
                <span style="float:right;">[{{capaplan_haslider_label_totalvm}} VM]</span>
            </clr-control-helper>
        </clr-range-container>
    </div>
</div>

<div class="clr-row" style="min-width: 1200px">
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <app-capares [target]="cap" [infra]="infraSubject.asObservable()" [simu]="simuSubject.asObservable()"></app-capares>
    </div>
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <app-capares [target]="cons" [infra]="infraSubject.asObservable()" [simu]="simuSubject.asObservable()"></app-capares>
    </div>
</div>

<div class="clr-row" style="min-width: 1200px">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card" >
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="slider"></cds-icon>&nbsp;Simulation : 
                    <div class="clr-select-wrapper" style="margin-left:10px;margin-right:20px;display:inline-flex;">
                        <select class="clr-select" style="min-width:150px" [(ngModel)]="simulation_selected" (ngModelChange)="switchSimulation()">
                            <option *ngFor="let simu of simulation_list" [ngValue]="simu">{{simu.NAME}}</option>
                        </select>
                    </div>
                    <button type="button" style="margin-right:10px;" class="btn btn-sm btn-icon btn-success-outline" (click)="simulation_name_modal=true"><cds-icon shape="plus"></cds-icon></button>
                    <button type="button" style="margin-right:10px;" class="btn btn-sm btn-icon btn-outline" [disabled]="simulation_save_disabled" (click)="saveSimulation()"><cds-icon shape="floppy"></cds-icon></button>
                    <button type="button" style="margin-right:10px;" class="btn btn-sm btn-icon btn-danger-outline" [disabled]="simulation_remove_disabled" (click)="removeSimulation()"><cds-icon shape="trash"></cds-icon></button>
                    
                    <button type="button" style="float:right;margin-top:0px" class="btn btn-default btn-sm" [disabled]="export_report_disabled || !simulation_save_disabled" (click)="generateReport()"><cds-icon shape="download"></cds-icon> Export Informations</button>
                    <button type="button" style="float:right;margin-top:0px" class="btn btn-primary btn-sm" [disabled]="!simulation_save_disabled" (click)="runSimulation()"><cds-icon shape="play"></cds-icon> Run simulation</button>
                    <!-- <clr-dropdown [clrCloseMenuOnItemClick]="true" style="float:right;">
                        <button class="nav-icon btn btn-primary btn-sm" clrDropdownTrigger aria-label="toggle"><cds-icon shape="cog"></cds-icon>&nbsp;Simulation<cds-icon shape="angle" direction="down"></cds-icon>
                        </button>
                        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                            <button clrDropdownItem type="button" (click)="runSimulation()"><cds-icon shape="play"></cds-icon> Run simulation</button>
                            <div class="dropdown-divider" role="separator"></div>
                            <button clrDropdownItem type="button" (click)="exportSimulation()"><cds-icon shape="download"></cds-icon> Export simulation</button>
                        </clr-dropdown-menu>
                    </clr-dropdown> -->
                    <button type="button" style="float:right;margin-top:0px" class="btn btn-success btn-sm" (click)="manageTemplate()"><cds-icon shape="vm"></cds-icon> Manage Templates</button>
                </h5>
            </div>
            <div class="card-block" style="padding-top: 0;">
                <div class="clr-row">
                    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                        <app-capaentity (save)="simulation_save_disabled=false" [target]="vm" [config]="configSubject.asObservable()" [templates]="templatesSubject.asObservable()"></app-capaentity>
                    </div>
                   <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                        <app-capaentity (save)="simulation_save_disabled=false" [target]="host" [config]="configSubject.asObservable()" [templates]="templatesSubject.asObservable()"></app-capaentity>
                    </div>
                    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                        <app-capaentity (save)="simulation_save_disabled=false" [target]="ds" [config]="configSubject.asObservable()" [templates]="templatesSubject.asObservable()"></app-capaentity>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL ERROR -->
<clr-modal [(clrModalOpen)]="error_modal" [clrModalClosable]=false [clrModalSize]="'sm'">
    <h3 class="modal-title">{{error_header}}</h3>
    <div class="modal-body" style="min-height:100px;">
        <div style="width:100%;overflow:auto;">
            {{error_body}}
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning-outline" (click)="error_modal = false">Ok</button>
    </div>
</clr-modal>

<!-- TEMPLATE MODAL -->
<clr-modal [(clrModalOpen)]="templates_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Manage Templates</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;min-height:200px;">
            <clr-tabs>
                <clr-tab>
                    <button clrTabLink>Virtual Machines</button>
                    <clr-tab-content *clrIfActive="true">
                        <ng-template [ngTemplateOutlet]="datagridtemplate" [ngTemplateOutletContext]="{type : vm}"></ng-template>
                    </clr-tab-content>
                </clr-tab>
                <clr-tab>
                    <button clrTabLink>Physical Machines</button>
                    <clr-tab-content *clrIfActive="false">
                        <ng-template [ngTemplateOutlet]="datagridtemplate" [ngTemplateOutletContext]="{type : host}"></ng-template>
                    </clr-tab-content>
                </clr-tab>
                <clr-tab>
                    <button clrTabLink>Datastores</button>
                    <clr-tab-content *clrIfActive="false">
                        <ng-template [ngTemplateOutlet]="datagridtemplate" [ngTemplateOutletContext]="{type : ds}"></ng-template>
                    </clr-tab-content>
                </clr-tab>
            </clr-tabs>
        </div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="saveTemplates()" *ngIf="save_templates_button">Save</button>
    </div>
</clr-modal>

<!-- DATAGRID TEMPLATE -->
<ng-template #datagridtemplate let-type="type">
    <clr-datagrid style="margin-top:20px" >
        <clr-dg-action-bar style="margin-top:0px">
            <div class="btn-group" style="float:right">
                <button *ngIf="type == vm" type="button" class="btn btn-success btn-sm" (click)="addTemplate(vm)">
                    <cds-icon shape="plus"></cds-icon>&nbsp;new virtual machine template
                </button>
                 <button *ngIf="type == host" type="button" class="btn btn-success btn-sm" (click)="addTemplate(host)">
                    <cds-icon shape="plus"></cds-icon>&nbsp;new physical machine template
                </button>
                <button *ngIf="type == ds" type="button" class="btn btn-success btn-sm" (click)="addTemplate(ds)">
                    <cds-icon shape="plus"></cds-icon>&nbsp;new datastore template
                </button>
            </div>
        </clr-dg-action-bar>
        <clr-dg-column style="min-width:30%;" [clrDgField]="'NAME'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
        <clr-dg-column *ngIf="type == vm || type == host" [clrDgField]="'CPUTHREADNB'"><ng-container *clrDgHideableColumn="{hidden: false}">{{type == vm ? "VCPU" : "Logical cores" }}</ng-container></clr-dg-column>
        <clr-dg-column *ngIf="type == vm || type == host" [clrDgField]="'RAMCAP'"><ng-container *clrDgHideableColumn="{hidden: false}">{{type == vm ? "VRAM (MB)" : "RAM capacity (MB)" }}</ng-container></clr-dg-column>
        <clr-dg-column *ngIf="type == vm || type == ds" [clrDgField]="'DISKCAP'"><ng-container *clrDgHideableColumn="{hidden: false}">{{ type == vm ? "VDISK (MB)" : " Storage capacity (MB)"}}</ng-container></clr-dg-column>
        <clr-dg-column style="max-width:15%;"><ng-container></ng-container></clr-dg-column>
        <clr-dg-row *clrDgItems="let template of getTemplatesFromType(type)" [clrDgItem]="template">
            <clr-dg-cell style="min-width:30%;">
                <div class="clr-form-control" style="margin:auto;display:unset;">
                    <div [ngClass]="(template.NAME == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="name" [(ngModel)]="template.NAME" (keyup)="checkTemplatesSettings($event.target, template);">
                            <cds-icon class="clr-validate-icon" *ngIf="template.NAME == ''" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="type == vm || type == host">
                <div class="clr-form-control" style="margin:auto;display:unset;">
                    <div [ngClass]="(template.CPUTHREADNB <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="vcpu" type="number" [(ngModel)]="template.CPUTHREADNB" style="width:4em;text-align:right" (change)="checkTemplatesSettings($event.target, template);">
                            <cds-icon class="clr-validate-icon" *ngIf="(template.CPUTHREADNB <= 0)" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="type == vm || type == host">
                <div class="clr-form-control" style="margin:auto;display:unset;">
                    <div [ngClass]="(template.RAMCAP <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="vram" type="number" [(ngModel)]="template.RAMCAP" style="width:5em;text-align:right" (change)="checkTemplatesSettings($event.target, template);">
                            <cds-icon class="clr-validate-icon" *ngIf="(template.RAMCAP <= 0)" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-dg-cell>
            <clr-dg-cell *ngIf="type == vm || type == ds">
                <div class="clr-form-control" style="margin:auto;display:unset;">
                    <div [ngClass]="(template.DISKCAP <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" id="vdisk" type="number" [(ngModel)]="template.DISKCAP" style="width:6em;text-align:right" (change)="checkTemplatesSettings($event.target, template);">
                            <cds-icon class="clr-validate-icon" *ngIf="(template.DISKCAP <= 0)" shape="exclamation-circle"></cds-icon>
                        </div>
                    </div>
                </div>
            </clr-dg-cell>
            <clr-dg-cell style="max-width:15%;">
                <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove' (click)="deleteTemplate(template)">
                    <cds-icon shape='trash'></cds-icon>
                </button>
            </clr-dg-cell>
        </clr-dg-row>                     
    <clr-dg-footer>
    <clr-dg-pagination #templates_page [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
            {{templates_page.firstItem + 1}} - {{templates_page.lastItem + 1}} of {{templates_page.totalItems}} entries
        </clr-dg-pagination>
    </clr-dg-footer>
 </clr-datagrid>

</ng-template>

<!-- SIMU NAME MODAL -->
<clr-modal [(clrModalOpen)]="simulation_name_modal" [clrModalClosable]=true>
    <h3 class="modal-title">Simulation name</h3>
    <div class="modal-body">
        <div class="clr-row">
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
				Please indicate the name of your simulation
				<br><br>
			</div>
        </div>
		<div class="clr-row">
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
				<div class="clr-control-container" style="width:100%">
					<div class="clr-input-wrapper">
						<input style="width:100%" type="text" class="clr-input" [(ngModel)]="simulation_name" (keyup)="checkSimulationName();">
					</div>
				</div>
				<br><br>
			</div>
		</div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-primary" (click)="newSimulation()" [disabled]="!simulation_name">OK</button>
    </div>
</clr-modal>
