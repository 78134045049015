<div class="clr-row" *ngIf="isData">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    <cds-icon shape="line-chart"></cds-icon>&nbsp;Power Graph
                </h5>

                <!--<clr-toggle-container style="flex-direction:row;float:right">
                    <clr-toggle-wrapper style="margin-bottom:20px;margin-right:20px;">
                        <input type="checkbox" clrToggle [(ngModel)]="isStacked" (ngModelChange)="stackOnNode()" [disabled]="isStackedDisable" />
                        <label>
                            <span class="btn btn-sm btn-link" style="margin:0px">stacked pods</span>
                        </label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>-->

                <clr-toggle-container style="flex-direction:row;float:right">
                    <clr-toggle-wrapper style="margin-bottom:20px;margin-right:20px;">
                        <input type="checkbox" clrToggle [(ngModel)]="all_nodes" (ngModelChange)="checkAll()" [disabled]="isAllDisable" />
                        <label>
                            <span class="btn btn-sm btn-link" style="margin:0px">all nodes</span>
                        </label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>

                <app-power-combobox (onChange)="all_nodes = false; isStacked = false; updateChart()" [(selectedElements)]="selectedElements" [targetInfrastructure]="targetInfrastructure" style="width:100%;display:inline-block;"></app-power-combobox>
            </div>

            <div class="card-block" style="width:100%;display:block;">
                <app-power-graph [selectedElements]="selectedElements" [targetInfrastructure]="targetInfrastructure" #powerGraph *ngIf="!isStacked"></app-power-graph>
                <app-power-vm-stacked [selectedElements]="selectedElements" [targetInfrastructure]="targetInfrastructure" #powerVmStacked *ngIf="isStacked"></app-power-vm-stacked>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isData">
    <div class="card">
            <div class="card-block">
                    <div class="card-text">
                            <div>
                                    <strong class="center">No data available !</strong>
                            </div>
                    </div>
            </div>
    </div>
</div>