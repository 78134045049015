<clr-vertical-nav style="height:100%;" [clrVerticalNavCollapsible]="true" class="nav-trigger--bottom" *ngIf="message.isNotLoginPage">
	<a clrVerticalNavLink routerLink="./dashboard" routerLinkActive="active">
		<cds-icon clrVerticalNavIcon shape="dashboard"></cds-icon>
		Dashboard
	</a>
    <a clrVerticalNavLink routerLink="./reporting" routerLinkActive="active" *ngIf="role.isMenuReport">
    	<cds-icon clrVerticalNavIcon shape="library"></cds-icon>
		Reporting
	</a>
    <a clrVerticalNavLink routerLink="./recommendation" routerLinkActive="active" *ngIf="role.isMenuReco">
		<cds-icon clrVerticalNavIcon shape="thumbs-up"></cds-icon>
		Recommendation
	</a>
	<a clrVerticalNavLink routerLink="./god" routerLinkActive="active" *ngIf="role.isMenuGod">
		<cds-icon clrVerticalNavIcon shape="line-chart"></cds-icon>
		Graph On Demand
	</a>
    <a clrVerticalNavLink routerLink="./capacity" routerLinkActive="active" *ngIf="role.isMenuCapa">
		<cds-icon clrVerticalNavIcon shape="slider"></cds-icon>
		Capacity Planning
	</a>
    <a clrVerticalNavLink routerLink="./troubleshooting" routerLinkActive="active" *ngIf="role.isMenuTrouble">
		<cds-icon clrVerticalNavIcon shape="exclamation-circle"></cds-icon>
		Troubleshooting
	</a>
    <a clrVerticalNavLink routerLink="./alerting" routerLinkActive="active" *ngIf="role.isMenuAlert">
		<cds-icon clrVerticalNavIcon shape="bell"></cds-icon>
		Alerting
	</a>
    <a clrVerticalNavLink routerLink="./kpi" routerLinkActive="active" *ngIf="role.isMenuKpi">
		<cds-icon clrVerticalNavIcon shape="bar-chart"></cds-icon>
		KPI
	</a>
	<clr-vertical-nav-group routerLinkActive="active" *ngIf="role.isMenuGreenit">
		<fa-icon clrVerticalNavIcon [icon]="['fas', 'leaf']"></fa-icon>
		Green IT&nbsp;<ng-template [ngTemplateOutlet]="greenindexstatus"></ng-template>
		<clr-vertical-nav-group-children>
			<a clrVerticalNavLink routerLink="./greenit/score" routerLinkActive="active">
				<cds-icon clrVerticalNavIcon shape="details"></cds-icon>
				Evolutions
			</a>
			<a clrVerticalNavLink routerLink="./greenit/analysis" routerLinkActive="active">
	    		<cds-icon clrVerticalNavIcon shape="table"></cds-icon>
				Analysis
			</a>
			<a clrVerticalNavLink routerLink="./greenit/power" routerLinkActive="active">
				<cds-icon clrVerticalNavIcon shape="bolt"></cds-icon>
				Power Graph
			</a>
			<a clrVerticalNavLink routerLink="./greenindex" routerLinkActive="active" *ngIf="role.isMenuVirtindex">
				<cds-icon clrVerticalNavIcon shape="bell-curve"></cds-icon>
				Green-Index&nbsp;<ng-template [ngTemplateOutlet]="greenindexstatus"></ng-template>
			</a>
			<a clrVerticalNavLink routerLink="./simulation" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="deploy" style="margin-right:10px"></cds-icon>
                Project Simulator
            </a>
		</clr-vertical-nav-group-children>
	</clr-vertical-nav-group>
			
	<div class="nav-divider" *ngIf="role.isMenuCloud"></div>
	<a clrVerticalNavLink routerLink="./cloudimpact" routerLinkActive="active" *ngIf="role.isMenuCloud">
		<cds-icon clrVerticalNavIcon shape="cloud"></cds-icon>
		Cloud Impact
	</a>
	<a clrVerticalNavLink routerLink="./cloud/myprovider/myregion" routerLinkActive="active" *ngIf="role.isMenuCloud">
		<fa-icon [icon]="myproviderIcon" ></fa-icon>
		My Cloud Impact
	</a>

	<div class="nav-divider" *ngIf="role.isMenuVcloud || role.isMenuExternalCloud || role.isMenuKubernetes"></div>
	<a clrVerticalNavLink routerLink="./vcloud" routerLinkActive="active" *ngIf="role.isMenuVcloud" [ngStyle]="checkVCloud() ? {} : {pointerEvents:'none',opacity:0.6}">
		<cds-icon clrVerticalNavIcon shape="resource-pool"></cds-icon>
		vCloud Director
	</a>
	<clr-vertical-nav-group routerLinkActive="active" id="spec-cloud-provider" *ngIf="role.isMenuExternalCloud" [ngStyle]="checkExternalCloud() ? {} : {pointerEvents:'none',opacity:0.6}">
		<cds-icon clrVerticalNavIcon shape="cloud"></cds-icon>
        External Cloud
        <clr-vertical-nav-group-children>
            <a id="spec-cloud-provider-aws" clrVerticalNavLink routerLink="./provider/aws" routerLinkActive="active" [ngStyle]="checkAws() ? {} : {pointerEvents:'none',opacity:0.6}">
                <fa-icon [icon]="awsIcon" ></fa-icon>
                AWS
            </a>
            <a clrVerticalNavLink routerLink="./provider/azure" routerLinkActive="active" [ngStyle]="checkAzure() ? {} : {pointerEvents:'none',opacity:0.6}">
                <fa-icon [icon]="azureIcon" ></fa-icon>
                Azure
            </a>
			<a clrVerticalNavLink routerLink="./provider/gcp" routerLinkActive="active" [ngStyle]="checkGcp() ? {} : {pointerEvents:'none',opacity:0.6}">
                <fa-icon [icon]="gcpIcon" ></fa-icon>
                GCP
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
	<a clrVerticalNavLink routerLink="./containers" routerLinkActive="active" *ngIf="role.isMenuKubernetes" [ngStyle]="checkKubernetes() ? {} : {pointerEvents:'none',opacity:0.6}">
		<cds-icon shape="container"></cds-icon>
		Kubernetes
	</a>

	<div class="nav-divider" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable"></div>
	<clr-vertical-nav-group routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
		<cds-icon shape="network-globe"></cds-icon>
		DC NetScope
		<clr-vertical-nav-group-children>
			<a clrVerticalNavLink routerLink="./netscope/dashboard" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="dashboard"></cds-icon>
				Dashboard
			</a>
			<a clrVerticalNavLink routerLink="./netscope/flows/clusters-viewer" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="organization"></cds-icon>
				Clusters viewer
			</a>
			<a clrVerticalNavLink routerLink="./netscope/flows/protocol-analysis" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="chat-bubble"></cds-icon>
				Protocol analysis
			</a>
			<a clrVerticalNavLink routerLink="./netscope/flows/dependencies-viewer" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="recycle"></cds-icon>
				Dependencies viewer
			</a>
			<a clrVerticalNavLink routerLink="./netscope/heatmap-viewer" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="heat-map"></cds-icon>
				Heatmap viewer
			</a>
			<a clrVerticalNavLink routerLink="./netscope/custom-protocols" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="tags"></cds-icon>
				Custom protocols
			</a>
			<a clrVerticalNavLink routerLink="./netscope/flows/apps-groups" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="node-group"></cds-icon>
				Apps groups
			</a>
			<a clrVerticalNavLink routerLink="./netscope/external_ips" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="note"></cds-icon>
				External IPs
			</a>
			<a clrVerticalNavLink routerLink="./netscope/supervision" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="radar"></cds-icon>
				Supervision
			</a>
			<a clrVerticalNavLink routerLink="./netscope/topology" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="tree-view"></cds-icon>
				Topology
			</a>
			<a clrVerticalNavLink routerLink="./netscope/diagnostic" routerLinkActive="active" *ngIf="role.isMenuDCNetScope && netscopeShouldBeAvailable">
				<cds-icon shape="checkbox-list"></cds-icon>
				Diagnostic
			</a>
		</clr-vertical-nav-group-children>
	</clr-vertical-nav-group>

 </clr-vertical-nav>

<!-- GREEN-INDEX-->
<ng-template #greenindexstatus >
	<cds-icon *ngIf="!greenindex_status" size="20" style="color:var(--clr-alert-warning-icon-color, #454545);" shape="exclamation-circle"></cds-icon>
</ng-template>
