import {
    Component,
    OnInit
} from '@angular/core';

@Component({
    selector: 'app-t13',
    templateUrl: './t13.component.html',
    styleUrls: ['./t13.component.css']
})
export class T13Component implements OnInit {

    isReady: boolean = false;


    constructor() {}

    ngOnInit(): void {

        this.isReady = true;
    }

}
