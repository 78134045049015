import {
    HttpClient
} from '@angular/common/http';
import {
    Injectable
} from '@angular/core';
import {
    map
} from 'rxjs/operators';

import {
    environment
} from '@environments/environment';


@Injectable({
    providedIn: 'root'
})
export class CloudProviderService {

    constructor(private http: HttpClient) {}

    getBestInstanceType(res: any) {
        return this.http.post < any > (`${environment.apiUrl}/v1/cloudprovider/bestinstancetype`, res).pipe(map(
            result => {
                return result;
            }));
    }

    isValidData(provider: string) {
        return this.http.get < any > (`${environment.apiUrl}/v1/cloudprovider/isvalid?provider=` + provider).pipe(
            map(
                data => {
                    return data;
                }));
    }

    getRegions(provider: string) {
        return this.http.get < any > (`${environment.apiUrl}/v1/cloudprovider/getregions?provider=` + provider)
            .pipe(map(
                data => {
                    return data;
                }));
    }

    getFamilies(provider: string) {
        return this.http.get < any > (`${environment.apiUrl}/v1/cloudprovider/getfamilies?provider=` + provider)
            .pipe(map(
                data => {
                    return data;
                }));
    }

    getSeries(provider: string, uc: string) {
        return this.http.get < any > (`${environment.apiUrl}/v1/cloudprovider/getseries?provider=` + provider +
                '&uc=' + uc)
            .pipe(map(data => {
                return data;
            }));
    }

    getIts(provider: string, serie: string) {
        return this.http.get < any > (`${environment.apiUrl}/v1/cloudprovider/getits?provider=` + provider +
                '&serie=' + serie)
            .pipe(map(data => {
                return data;
            }));
    }

    getItProperties(provider: string, it: string, region: string) {
        return this.http.get < any > (`${environment.apiUrl}/v1/cloudprovider/getitproperties?provider=` +
            provider +
            '&it=' + it + '&region=' + region).pipe(map(data => {
            return data;
        }));
    }

    getDataStorage(provider: string, type: string) {
        return this.http.get < any > (`${environment.apiUrl}/v1/cloudprovider/getdatastorage?provider=` + provider +
            '&type=' +
            type).pipe(map(data => {
            return data;
        }));
    }

    getItEstimation(res: any) {
        return this.http.post < any > (`${environment.apiUrl}/v1/cloudprovider/getitestimation`, res).pipe(map(
            result => {
                return result;
            }));
    }
}
