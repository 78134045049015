<div class="card addScroll">
    <div class="card-header card-ajust">
        <h5>
            <cds-icon shape="host-group"></cds-icon>
            &nbsp;List of {{target != network_target ? "servers" : "devices"}}
        </h5>
    </div>
    <div class="card-block">
        <div class="card-text">
            <div>
                <clr-datagrid style="min-height:230px;">
                    <clr-dg-action-bar>
                        <div class="btn-group" style="float:right">
                            <button type="button" class="btn btn-sm btn-link" (click)="exportList()">
                                Export to CSV
                            </button>
                        </div>
                    </clr-dg-action-bar>
                    <clr-dg-column [clrDgField]="'NAME'" [clrDgSortOrder]="ascOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                    <clr-dg-column *ngIf="target == ipmi_target" [clrDgField]="'SERVERMODEL'"><ng-container *clrDgHideableColumn="{hidden: false}">Server model</ng-container></clr-dg-column>
                    <clr-dg-column *ngIf="target == scaph_target" [clrDgField]="'OS'"><ng-container *clrDgHideableColumn="{hidden: false}">OS</ng-container></clr-dg-column>
                    <clr-dg-column *ngIf="target == network_target || target == storage_target" [clrDgField]="'TYPE'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
                    <clr-dg-column *ngIf="target != network_target && target != storage_target && target != ipmi_target" [clrDgField]="'SOCKETSNB'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU Sockets</ng-container></clr-dg-column>
                    <clr-dg-column *ngIf="target != network_target && target != storage_target && target != ipmi_target" [clrDgField]="'RAMCAP'"><ng-container *clrDgHideableColumn="{hidden: false}">RAM Capacity (MB)</ng-container></clr-dg-column>
                    <clr-dg-column *ngIf="target != network_target && target != storage_target && target != ipmi_target" [clrDgField]="'CEE'"><ng-container *clrDgHideableColumn="{hidden: false}">Energy Efficiency</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'WH'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual kWh</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'CO2'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual CO2 (kg CO2eq)</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'CO2G'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual embodied CO2 (kg CO2eq)</ng-container></clr-dg-column>
                    <clr-dg-column [clrDgField]="'POWER'"><ng-container *clrDgHideableColumn="{hidden: false}">Power (W)</ng-container></clr-dg-column>
                    <clr-dg-column *ngIf="target == scaph_target" [clrDgField]="'WASTEPOWER'">
                        <ng-container *clrDgHideableColumn="{hidden: false}">
                            <div role="tooltip" class="tooltip tooltip-bottom-left">
                                Power Waste (%)
                                <span class="tooltip-content dt-tooltip">Waste = (100 - (sum power of all processes / power of host)) * 100</span>
                            </div>
                        </ng-container>
                    </clr-dg-column>
                    <clr-dg-column *ngIf="target == scaph_target"><ng-container *clrDgHideableColumn="{hidden: false}"></ng-container></clr-dg-column>
                    <clr-dg-row *clrDgItems="let node of nodes_data" [clrDgItem]="node">
                        <clr-dg-cell>{{node.NAME}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="target == ipmi_target">{{node.SERVERMODEL}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="target == scaph_target">{{node.OS}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="target == network_target || target == storage_target">{{node.TYPE}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="target != network_target && target != storage_target && target != ipmi_target">{{node.SOCKETSNB}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="target != network_target && target != storage_target && target != ipmi_target">{{node.RAMCAP}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="target != network_target && target != storage_target && target != ipmi_target">{{node.CEE}}</clr-dg-cell>
                        <clr-dg-cell>{{node.KWH}}</clr-dg-cell>
                        <clr-dg-cell>{{node.CO2}}</clr-dg-cell>
                        <clr-dg-cell>{{node.CO2G}}</clr-dg-cell>
                        <clr-dg-cell>{{node.POWER}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="target == scaph_target">{{node.WASTEPOWER}}</clr-dg-cell>
                        <clr-dg-cell *ngIf="target == scaph_target"><button class="btn btn-link btn-sm" style="margin-top:-3%" (click)="displayModalProcess(node.NAME, node.PROCESSES)">Show processes</button></clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                        <clr-dg-pagination #node_page [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                            {{node_page.firstItem + 1}} - {{node_page.lastItem + 1}} of {{node_page.totalItems}} entries
                            </clr-dg-pagination>
                    </clr-dg-footer>
                </clr-datagrid>
            </div>
        </div>
    </div>
</div>

<!-- MODAL PROCESSES -->
<clr-modal [(clrModalOpen)]="show_process" [clrModalSize]="'xl'">
    <h3 class="modal-title">List of more energy-consuming processes over the last 24 hours</h3>
    <div class="modal-body" style="min-height:100px;">
        <clr-datagrid>
            <clr-dg-action-bar>
                <div class="btn-group" style="float:right">
                    <button type="button" class="btn btn-sm btn-link" (click)="exportProcesses()">
                        Export to CSV
                    </button>
                </div>
            </clr-dg-action-bar>
			<clr-dg-column [clrDgField]="'NAME'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
			<clr-dg-column [clrDgField]="'WH'" [clrDgSortOrder]="descOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Last 24h Wh</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'CO2'"><ng-container *clrDgHideableColumn="{hidden: false}">Last 24h CO2 (kg CO2eq)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'POWER'"><ng-container *clrDgHideableColumn="{hidden: false}">Power (W)</ng-container></clr-dg-column>
			<clr-dg-row *clrDgItems="let process of selected_processes" [clrDgItem]="process">
				<clr-dg-cell>{{process.NAME}}</clr-dg-cell>
				<clr-dg-cell>{{process.WH}}</clr-dg-cell>
                <clr-dg-cell>{{process.CO2}}</clr-dg-cell>
                <clr-dg-cell>{{process.POWER}}</clr-dg-cell>
			</clr-dg-row>
            <clr-dg-footer>
                <clr-dg-pagination #process_page [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{process_page.firstItem + 1}} - {{process_page.lastItem + 1}} of {{process_page.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>
		</clr-datagrid>
    </div>
</clr-modal>
