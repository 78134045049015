<clr-modal [(clrModalOpen)]="showFlowModal" [clrModalClosable]="true" [clrModalStaticBackdrop]="false" [clrModalSize]="'xl'">
    <div class="modal-body">
        <div class="clr-row">
            <div class="clr-col-12">
                <h3>{{ selectFlowDetailsModalTitle }}</h3>
                <app-netscope-flows-datagrid [temporalParameter]="temporalParameter" [flowDetails]="selectFlowDetails" [showCountry]="true" [showASN]="true" *ngIf="showFlowModal"></app-netscope-flows-datagrid>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="showFlowModal = false">
            Close
        </button>
    </div>
</clr-modal>

<div class="clr-row">
    <div class="clr-col-6">
        <div class="card">
            <h3 class="card-header">{{ "dcnetscope.supervision.outside_flows.map_label" | transloco }} <span style="margin-bottom: 5px;" class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span></h3>
            <div class="card-block">
                <div class="card-text">
                    <highcharts-chart
                            id="container"
                            [Highcharts]="Highcharts"
                            [constructorType]="chartConstructor"
                            [options]="chartOptions"
                            [(update)]="updateCountriesMapFlag"
                            style="display: block;">
                    </highcharts-chart>
                </div>
            </div>
        </div>

        <div class="clr-row">
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">{{ "dcnetscope.supervision.outside_flows.outside_traffic_label" | transloco }}</h3>
                        <p class="card-text">
                            {{ NetscopeUtils.formatBytes(outsideFlowsTraffic) }} {{ "dcnetscope.supervision.outside_flows.outside_traffic_text" | transloco }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">{{ "dcnetscope.supervision.outside_flows.vm_count_label" | transloco }}</h3>
                        <p class="card-text">
                            {{vmsCountWithOutsideFlows  | number: '1.0-0'}} {{ "dcnetscope.supervision.outside_flows.vm_count_text" | transloco }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="clr-col-4">
                <div class="card">
                    <div class="card-block">
                        <h3 class="card-title">{{ "dcnetscope.supervision.outside_flows.outside_flows_label" | transloco }}</h3>
                        <p class="card-text">
                            {{outsideFlowsCount  | number: '1.0-0'}} {{ "dcnetscope.supervision.outside_flows.outside_flows_text" | transloco }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <clr-alert [clrAlertType]="(vmsCountWithManyOpenPorts > 0) ? 'warning' : 'success'" style="margin-top: 12px;">
                <clr-alert-item>
                    <span class="alert-text" *ngIf="vmsCountWithManyOpenPorts > 0">{{vmsCountWithManyOpenPorts}} {{ "dcnetscope.supervision.outside_flows.warning_scan_vms" | transloco }}</span>
                    <span class="alert-text" *ngIf="vmsCountWithManyOpenPorts === 0"> {{ "dcnetscope.supervision.outside_flows.no_warning_scan_vms" | transloco }}</span>
                </clr-alert-item>
            </clr-alert>
        </div>
    </div>

    <div class="clr-col-6">
        <div class="card">
            <h3 class="card-header" style="position: relative;">{{ "dcnetscope.supervision.outside_flows.overview_label" | transloco }}
                <span style="margin-bottom: 5px;" class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span>
                <span style="position: absolute; right: 0px; float: right;">
                    <clr-radio-container clrInline style="margin-top: 0px;">
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="vms" [(ngModel)]="overviewSelection" />
                            <label>{{ "dcnetscope.supervision.outside_flows.overview.vms_label" | transloco }}</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="countries" [(ngModel)]="overviewSelection" />
                            <label>{{ "dcnetscope.supervision.outside_flows.overview.countries_label" | transloco }}</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="network_providers" [(ngModel)]="overviewSelection" />
                            <label>{{ "dcnetscope.supervision.outside_flows.overview.netproviders_label" | transloco }}</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="accessed_ports" [(ngModel)]="overviewSelection" />
                            <label>{{ "dcnetscope.supervision.outside_flows.overview.accessed_ports_label" | transloco }}</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                </span>
            </h3>
            <div class="card-block">
                <div class="card-text">

<!--                VMs -->
                    <clr-datagrid *ngIf="overviewSelection == 'vms'">
                        <clr-dg-column [clrDgField]="'name'">
                            {{ "dcnetscope.supervision.outside_flows.overview.vm_column" | transloco }}
                        </clr-dg-column>

                        <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">
                            {{ "dcnetscope.supervision.outside_flows.overview.total_bytes_column" | transloco }}
                            <clr-dg-filter [clrDgFilter]="totalBytesFilter">
                                <column-range-filter [fieldName]="'exchanged_bytes'" [items]="vmsWithFlowsOutside"
                                                     [formatType]="'bytes'"
                                                     [tableUpdate]="shouldUpdateFilters" #totalBytesFilter>
                                </column-range-filter>
                            </clr-dg-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'exchanged_packets'">
                            {{ "dcnetscope.supervision.outside_flows.overview.total_packets_column" | transloco }}
                            <clr-dg-filter [clrDgFilter]="totalPacketsFilter">
                                <column-range-filter [fieldName]="'exchanged_packets'" [items]="vmsWithFlowsOutside"
                                                     [formatType]="'numbers'"
                                                     [tableUpdate]="shouldUpdateFilters" #totalPacketsFilter>
                                </column-range-filter>
                            </clr-dg-filter>
                        </clr-dg-column>
                        <clr-dg-column>{{ "dcnetscope.supervision.outside_flows.overview.actions_column" | transloco }}</clr-dg-column>

                        <clr-dg-row *clrDgItems="let vmFlow of vmsWithFlowsOutside" [clrDgItem]="vmFlow">


                            <clr-dg-cell><span class="badge badge-blue">{{vmFlow.name}}</span></clr-dg-cell>


                            <clr-dg-cell>{{vmFlow.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
                            <clr-dg-cell>{{vmFlow.exchanged_packets | number}}</clr-dg-cell>

                            <clr-dg-cell><button class="btn btn-primary btn-sm" (click)="filterVmsAndShowModal(vmFlow.uuid);">{{ "dcnetscope.supervision.outside_flows.view_details_label" | transloco }}</button></clr-dg-cell>

                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                            </clr-dg-pagination>
                        </clr-dg-footer>

                    </clr-datagrid>

<!--                Countries -->
                    <clr-datagrid *ngIf="overviewSelection == 'countries'">
                        <clr-dg-column [clrDgField]="'country'">
                            {{ "dcnetscope.supervision.outside_flows.overview.country_column" | transloco }}
                        </clr-dg-column>

                        <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">
                            {{ "dcnetscope.supervision.outside_flows.overview.total_bytes_column" | transloco }}
                            <clr-dg-filter [clrDgFilter]="totalBytesFilter">
                                <column-range-filter [fieldName]="'exchanged_bytes'" [items]="countriesWithFlowsOutside"
                                                     [formatType]="'bytes'"
                                                     [tableUpdate]="shouldUpdateFilters" #totalBytesFilter>
                                </column-range-filter>
                            </clr-dg-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'exchanged_packets'">
                            {{ "dcnetscope.supervision.outside_flows.overview.total_packets_column" | transloco }}
                            <clr-dg-filter [clrDgFilter]="totalPacketsFilter">
                                <column-range-filter [fieldName]="'exchanged_packets'" [items]="countriesWithFlowsOutside"
                                                     [formatType]="'numbers'"
                                                     [tableUpdate]="shouldUpdateFilters" #totalPacketsFilter>
                                </column-range-filter>
                            </clr-dg-filter>
                        </clr-dg-column>
                        <clr-dg-column>{{ "dcnetscope.supervision.outside_flows.overview.actions_column" | transloco }}</clr-dg-column>

                        <clr-dg-row *clrDgItems="let countryFlow of countriesWithFlowsOutside" [clrDgItem]="countryFlow">


                            <clr-dg-cell><span class="badge badge-blue">{{countryFlow.country}}</span></clr-dg-cell>


                            <clr-dg-cell>{{countryFlow.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
                            <clr-dg-cell>{{countryFlow.exchanged_packets | number}}</clr-dg-cell>

                            <clr-dg-cell><button class="btn btn-primary btn-sm" (click)="filterCountryAndShowModal(countryFlow.country);">{{ "dcnetscope.supervision.outside_flows.view_details_label" | transloco }}</button></clr-dg-cell>

                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                            </clr-dg-pagination>
                        </clr-dg-footer>

                    </clr-datagrid>

<!--                Network providers -->
                    <clr-datagrid *ngIf="overviewSelection == 'network_providers'">
                        <clr-dg-column [clrDgField]="'network'">
                            {{ "dcnetscope.supervision.outside_flows.overview.network_provider_column" | transloco }}
                        </clr-dg-column>

                        <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">
                            {{ "dcnetscope.supervision.outside_flows.overview.total_bytes_column" | transloco }}
                            <clr-dg-filter [clrDgFilter]="totalBytesFilter">
                                <column-range-filter [fieldName]="'exchanged_bytes'" [items]="networksWithFlowsOutside"
                                                     [formatType]="'bytes'"
                                                     [tableUpdate]="shouldUpdateFilters" #totalBytesFilter>
                                </column-range-filter>
                            </clr-dg-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'exchanged_packets'">
                            {{ "dcnetscope.supervision.outside_flows.overview.total_packets_column" | transloco }}
                            <clr-dg-filter [clrDgFilter]="totalPacketsFilter">
                                <column-range-filter [fieldName]="'exchanged_packets'" [items]="networksWithFlowsOutside"
                                                     [formatType]="'numbers'"
                                                     [tableUpdate]="shouldUpdateFilters" #totalPacketsFilter>
                                </column-range-filter>
                            </clr-dg-filter>
                        </clr-dg-column>
                        <clr-dg-column>{{ "dcnetscope.supervision.outside_flows.overview.actions_column" | transloco }}</clr-dg-column>

                        <clr-dg-row *clrDgItems="let networkFlow of networksWithFlowsOutside" [clrDgItem]="networkFlow">


                            <clr-dg-cell><span class="badge badge-blue">{{networkFlow.network}}</span></clr-dg-cell>


                            <clr-dg-cell>{{networkFlow.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
                            <clr-dg-cell>{{networkFlow.exchanged_packets | number}}</clr-dg-cell>

                            <clr-dg-cell><button class="btn btn-primary btn-sm" (click)="filterNetworkProviderAndShowModal(networkFlow.network);">{{ "dcnetscope.supervision.outside_flows.view_details_label" | transloco }}</button></clr-dg-cell>

                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                            </clr-dg-pagination>
                        </clr-dg-footer>

                    </clr-datagrid>

                    <!--                Accessed Ports -->
                    <clr-datagrid *ngIf="overviewSelection == 'accessed_ports'">
                        <clr-dg-column [clrDgField]="'name'">
                            {{ "dcnetscope.supervision.outside_flows.overview.vm_column" | transloco }}
                        </clr-dg-column>

                        <clr-dg-column [clrDgField]="'ports_count'">
                            {{ "dcnetscope.supervision.outside_flows.overview.ports_count_column" | transloco }}
                            <clr-dg-filter [clrDgFilter]="totalPacketsFilter">
                                <column-range-filter [fieldName]="'ports_count'" [items]="vmsWithPortsAccessedFromOutside"
                                                     [formatType]="'numbers'"
                                                     [tableUpdate]="shouldUpdateFilters" #totalPacketsFilter>
                                </column-range-filter>
                            </clr-dg-filter>
                        </clr-dg-column>
                        <clr-dg-column>{{ "dcnetscope.supervision.outside_flows.overview.actions_column" | transloco }}</clr-dg-column>

                        <clr-dg-row *clrDgItems="let vmInfo of vmsWithPortsAccessedFromOutside" [clrDgItem]="vmInfo">


                            <clr-dg-cell><span class="badge badge-blue">{{vmInfo.name}}</span></clr-dg-cell>

                            <clr-dg-cell>{{vmInfo.ports_count | number}}</clr-dg-cell>

                            <clr-dg-cell><button class="btn btn-primary btn-sm" (click)="filterVmsWithOpenPortsAndShowModal(vmInfo.uuid, vmInfo.unique_ips);">{{ "dcnetscope.supervision.outside_flows.view_details_label" | transloco }}</button></clr-dg-cell>

                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                            </clr-dg-pagination>
                        </clr-dg-footer>

                    </clr-datagrid>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-12">
        <div class="card">
            <h3 class="card-header">{{ "dcnetscope.supervision.outside_flows.all_flows.all_flows_outside_label" | transloco }}</h3>
            <div class="card-block">
                <div class="card-text">
                    <app-netscope-flows-datagrid [temporalParameter]="temporalParameter" [flowDetails]="allFlowsWithOutside" [showCountry]="true" [showASN]="true" [exportCsvEnabled]="true" [callExportCsv]="exportCSV"></app-netscope-flows-datagrid>
                </div>
            </div>
        </div>
    </div>
</div>

