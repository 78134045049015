<div class="clr-row" *ngIf="message.creditSelected == 'instance'">
        <div class="card" *ngIf="!isSelected">
                <div class="card-header">
			<span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				INSTANCES
			</span>
			<span style="float: right;" ngClass="addLink" (click)="message.creditSelected = ''">
				<cds-icon id="instance-detail-close" shape="times" size="24"></cds-icon>
			</span>
        </div>
        <div class="card-block">
			<div class="clr-row">
				<div [ngClass]="graphClass" *ngIf="isGraph">
					<div class="card" style="margin-top: 0px;">
						<span style="float: right;" ngClass="addLink" (click)="closeGraph()" *ngIf="!isEvolutionYear">
							<cds-icon shape="times" size="20"></cds-icon>
						</span>
						<div class="card-block">
							<highcharts-chart
								[Highcharts] = "highcharts"
								[options] = "chartOptions"
								[constructorType]="'chart'"
								style = "width: 100%;display: block;" *ngIf="isReady">
							</highcharts-chart>
							<clr-toggle-container clrInline>
								<clr-toggle-wrapper>
										<input type="checkbox" clrToggle name="isEvolutionYear" value="isEvolutionYear" [(ngModel)]="isEvolutionYear" (ngModelChange)="reloadGraph()" />
										<label>
												the last months
										</label>
								</clr-toggle-wrapper>
								<clr-control-helper>display month by month</clr-control-helper>
							</clr-toggle-container>
						</div>
					</div>
				</div>
				<div [ngClass]="tabClass" *ngIf="!isEvolutionYear">
					<div class="clr-row">
                        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
							<div class="clr-control-container">
								<div class="clr-select-wrapper">
									<select class="clr-select" 
										[(ngModel)]="model.selectedMonth"
										(ngModelChange)="loadMonth($event)">
										<option *ngFor="let month of months">{{month.name}}</option>
									</select>
								</div>
								<span class="clr-subtext">select month to display</span>
							</div>
						</div>
						<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
							<clr-checkbox-container>
								<clr-checkbox-wrapper>
									<input id="overview-instance-detail-cb" type="checkbox" 
										clrCheckbox name="OverviewInstanceDetailCb" 
										[(ngModel)]="model.isModel"
										(change)="updateList()" />
									<label>current model</label>
								</clr-checkbox-wrapper>
							</clr-checkbox-container>
						</div>
						<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
							<span *ngIf="!selected_instance" style="margin-left:10px;" class="label label-info">
								Pick an instance to view its properties
							</span>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
                            <button class="btn btn-link" style="position:relative;float:right;" (click)="exportInstances()">
                                Export Instances
                            </button>
                        </div>
					</div>
					<div class="datagrid-limit-height" >
						<clr-datagrid [(clrDgSingleSelected)]="selected_ins" (clrDgSingleSelectedChange)="insChanged($event)">
							<clr-dg-column [clrDgField]="'instanceId'" [clrDgSortOrder]="dataOrder" *ngIf="provider == 'aws'"><ng-container *clrDgHideableColumn="{hidden: false}">Id</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Subscription</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'aws'"><ng-container *clrDgHideableColumn="{hidden: false}">Account</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'subscription'" *ngIf="provider == 'gcp'"><ng-container *clrDgHideableColumn="{hidden: false}">Project</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'region'"><ng-container *clrDgHideableColumn="{hidden: false}">Region</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'resgroup'" *ngIf="provider == 'azure'"><ng-container *clrDgHideableColumn="{hidden: false}">Resource</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'creation'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Creation date</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'instanceType'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'vcpu'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">vCPU</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'vram'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">vRAM (MB)</ng-container></clr-dg-column>
							<clr-dg-row *clrDgItems="let instance of instances" [clrDgItem]="instance">
								<clr-dg-cell *ngIf="provider == 'aws'">{{instance.instanceId}}</clr-dg-cell>
								<clr-dg-cell>{{instance.name}}</clr-dg-cell>
								<clr-dg-cell>{{instance.subscriptionDesc.subscription}}</clr-dg-cell>
								<clr-dg-cell>{{instance.subscriptionDesc.region}}</clr-dg-cell>
								<clr-dg-cell *ngIf="provider == 'azure'">{{instance.subscriptionDesc.resgroup}}</clr-dg-cell>
								<clr-dg-cell>{{instance.creation}}</clr-dg-cell>
								<clr-dg-cell>{{instance.instanceType}}</clr-dg-cell>
								<clr-dg-cell>{{instance.state}}</clr-dg-cell>
								<clr-dg-cell>{{instance.vcpu}}</clr-dg-cell>
								<clr-dg-cell>{{instance.vram}}</clr-dg-cell>
							</clr-dg-row>
							<clr-dg-footer>
								<clr-dg-pagination #pagination [clrDgPageSize]="10">
									<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
										entries per page
									</clr-dg-page-size>
									{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
								</clr-dg-pagination>
							</clr-dg-footer>
						</clr-datagrid>
					</div>
				</div>
			</div>
                </div>
        </div>
	<div class="card" *ngIf="isSelected">
        <div class="card-header">
            <span style="color:grey;font-size:26px;">
				<cds-icon shape="vm"></cds-icon>
				Instance properties for {{selected_instance.name}}
			</span>
			<span style="float: right;" ngClass="addLink" (click)="isSelected = false;isMultiDisk = false">
				<cds-icon shape="times"></cds-icon>
			</span>
        </div>
		<div class="card-block">
			<div class="datagrid-limit-height" >
				<clr-datagrid>
					<clr-dg-column>ID</clr-dg-column>
					<clr-dg-column>Name</clr-dg-column>
					<clr-dg-column>Creation date</clr-dg-column>
					<clr-dg-column>Type</clr-dg-column>
					<clr-dg-column>Operating system</clr-dg-column>
					<clr-dg-column>State</clr-dg-column>
					<clr-dg-column>vCPU</clr-dg-column>
					<clr-dg-column>vRAM (MB)</clr-dg-column>
					<clr-dg-column>Monitoring</clr-dg-column>
					<clr-dg-column>Billing</clr-dg-column>
					<clr-dg-column>Volume type</clr-dg-column>
					<clr-dg-column>Volume size (GB)</clr-dg-column>
					<clr-dg-row>
						<clr-dg-cell>{{selected_instance.instanceId}}</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.name}}</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.creation}}</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.instanceType}}</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.os}} {{selected_instance.os_desc}}</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.state}}</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.vcpu}}</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.vram}}</clr-dg-cell>	
						<clr-dg-cell>{{selected_instance.monitoring}}</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.billing}}</clr-dg-cell>
						<!--<clr-dg-cell *ngIf="selected_instance.volType != 'multi'; else wmulti">
							{{selected_instance.volType}}
						</clr-dg-cell>
						<ng-template #wmulti>
							<clr-dg-cell>
								{{selected_instance.volType}}
								<span style="float: right;color: #057ff5;font-size: 12px;" ngClass="addLink" (click)="loadDisksDetail()">
									more ...
								</span>
							</clr-dg-cell>
						</ng-template>-->
						<clr-dg-cell>
							{{selected_instance.volType}}
							<span style="float: right;color: #057ff5;font-size: 11px;" ngClass="addLink" (click)="loadDisksDetail()">
								<br>more ...
							</span>
						</clr-dg-cell>
						<clr-dg-cell>{{selected_instance.volSize}}</clr-dg-cell>
					</clr-dg-row>
				</clr-datagrid>
			</div>
			<div class="datagrid-limit-height" *ngIf="isMultiDisk">
				<clr-datagrid>
					<clr-dg-column>Volume ID</clr-dg-column>
					<clr-dg-column>Name</clr-dg-column>
					<clr-dg-column>Creation date</clr-dg-column>
					<clr-dg-column>Type</clr-dg-column>
					<clr-dg-column>Cache type</clr-dg-column>
					<clr-dg-column>Is encrypted</clr-dg-column>
					<clr-dg-column>State</clr-dg-column>
					<clr-dg-column>Size (GB)</clr-dg-column>
					<clr-dg-row *clrDgItems="let volume of volumes">
						<clr-dg-cell>{{convertVol(volume.IDENTIFIER)}}</clr-dg-cell>
						<clr-dg-cell>{{volume.NAME}}</clr-dg-cell>
						<clr-dg-cell>{{convertTime(volume.CLOUD_DISK_CREATION_TIME)}}</clr-dg-cell>
						<clr-dg-cell>{{volume.CLOUD_DISK_TYPE}}</clr-dg-cell>
						<clr-dg-cell>{{volume.CLOUD_DISK_CACHETYPE}}</clr-dg-cell>
						<clr-dg-cell>{{volume.CLOUD_DISK_ENCRYPTED}}</clr-dg-cell>
						<clr-dg-cell>{{volume.CLOUD_DISK_STATE}}</clr-dg-cell>
						<clr-dg-cell>{{volume.CLOUD_DISK_SIZE}}</clr-dg-cell>
					</clr-dg-row>
				</clr-datagrid>
			</div>
		</div>
	</div>
</div>