import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    Subject
} from 'rxjs';

import {
    DataTableDirective
} from 'angular-datatables';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    HostSynthesis,
    Message
} from '@app/model';

import {
    commaBigNumber,
    getUserCurrency
} from '../../assets/js/tools.js';
import * as $ from 'jquery';


@Component({
    selector: 'app-hostlist',
    templateUrl: './hostlist.component.html',
    styleUrls: ['./hostlist.component.css']
})
export class HostlistComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective, {
        static: false
    }) dtElement: DataTableDirective;

    dtOptions: any = {};

    dtTrigger: Subject < any > = new Subject();

    message: Message;

    data_hosts: HostSynthesis[] = [];

    math = Math;

    globalCurrency: string = '';

    private currentUser;


    constructor(
        private router: Router,
        private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService) {}

    ngOnInit(): void {

        $("div.dataTables_filter").remove();
        $("dt-buttons").remove();

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        this.data_hosts = this.json_svc.json.hostSynthesis;

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.initDtOptions();
    }

    ngAfterViewInit(): void {

        this.dtTrigger.next();
        if (this.data_hosts.length > 0)
            this.reloadTable();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    filterHost(): HostSynthesis[] {

        let hosts: HostSynthesis[] = [];
        for (var i in this.data_hosts) {
            if (this.data_hosts[i].father == this.message.currentUuid)
                hosts.push(this.data_hosts[i]);
        }

        return hosts;
    }

    formatCost(cost: number): number {
        return commaBigNumber(cost);
    }

    callroutehost(host): void {

        this.message.currentUuid = host.uuid;
        this.message.currentName = host.name;
        this.message.currentType = 'SERVER';
        this.router.navigate(['/hostviews/synthesis']);
    }

    private reloadTable(): void {

        if (this.dtElement != undefined) {
            if (this.dtElement.dtInstance != undefined) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    $("div.dataTables_filter").remove();
                    $("dt-buttons").remove();
                    dtInstance.destroy();
                    this.dtTrigger.next();
                });
            }
        }
    }

    private initDtOptions(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [
                [10, 15, 20, 25, 50, -1],
                [10, 15, 20, 25, 50, "All"]
            ],
            processing: true,
            destroy: true,
            initComplete: function() {
                $('div.dataTables_filter').appendTo('span.search');
                $('div.dt-buttons').appendTo('span.export');
                $("[id*='DataTables_Table_']").css({
                    'padding-top': '5px'
                });
                $("[name*='DataTables_Table_']").css({
                    'padding': '0px'
                });
            },
            language: {
                search: '',
                searchPlaceholder: " search..."
            },
            dom: '<"top"B><"clear">frtlip',
            buttons: [{
                extend: 'csv',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'copy',
                className: 'btn btn-link btn-sm'
            }, {
                extend: 'print',
                className: 'btn btn-link btn-sm'
            }]
        };
    }
}
