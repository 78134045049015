import {
    Component,
    OnInit
} from '@angular/core';

import {
    AccountService,
    JsonloaderService,
    ShareService,
    VsanService
} from '@app/services';

import {
    User,
    Message
} from '@app/model';

import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);

import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offline(Highcharts);

@Component({
    selector: 'app-vsan',
    templateUrl: './vsan.component.html',
    styleUrls: ['./vsan.component.css']
})
export class VsanComponent implements OnInit {

    message: Message;

    currentUser: User;

    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.chart.showLoading();
    };

    options: Highcharts.Options = {
        chart: {
            type: 'stockChart',
            zoomType: 'x',
            height: '400px'
        },
        credits: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        legend: {
            enabled: true
        },
        exporting: {
            enabled: true,
            fallbackToExportServer: false,
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        navigation: {
            buttonOptions: {
                align: 'right',
                verticalAlign: 'top',
                y: 0
            }
        },
        navigator: {
            adaptToUpdatedData: false
        },
        rangeSelector: {
            buttons: [{
                type: 'all',
                text: 'All'
            }, {
                type: 'year',
                count: 1,
                text: '1y'
            }, {
                type: 'month',
                count: 3,
                text: '3m'
            }, {
                type: 'month',
                count: 1,
                text: '1m'
            }, {
                type: 'day',
                count: 1,
                text: '1d'
            }],
            buttonTheme: {
                width: 40
            },
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: [{
            gridLineWidth: 0,
            min: 0,
            startOnTick: true,
            title: {
                style: {
                    color: '#3E576F'
                }
            },
            opposite: false
        }],
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueDecimals: 2
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                }
            }
        }
    }

    // FOR DISK GROUP
    dg_data: any;
    dg_dtOptions: any;

    /**
     * 
     */
    constructor(private authenticationService: AccountService, private message_svc: ShareService,
        private vsan_svc: VsanService, private json_svc: JsonloaderService) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.authenticationService.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        // Get vSAN data
        this.vsan_svc.getVsanData(this.message.currentUuid, this.message.minTimeFilter, this.message.maxTimeFilter)
            .subscribe(
                data => {
                    if (data != undefined)
                        this.updateChart(data);
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );

        let hosts_array = [];

        // Init hosts arrays for API (only hosts of this cluster) ...
        let hostSynthesis_json: JSON = this.json_svc.json.hostSynthesis;
        for (let i in hostSynthesis_json) {
            if (hostSynthesis_json[i].father == this.message.currentUuid) {
                hosts_array[i] = hostSynthesis_json[i].uuid;
            }
        }

        let identifiers = hosts_array.map(x => "'" + x + "'").join(',');

        // Get vSAN disk group
        this.vsan_svc.getVsanDiskGroup(identifiers, this.message.minTimeFilter, this.message.maxTimeFilter)
            .subscribe(
                data => {
                    this.dg_data = data;
                },
                error => {
                    if (error != null)
                        console.log(error)
                }
            );

        // WARN Need to init here ...
        this.dg_dtOptions = {
            bJQueryUI: true,
            language: {
                "emptyTable": "Processing, please wait ..."
            },
            columnDefs: [{
                className: 'dt-body-center',
                targets: "_all"
            }, ]
        };

    }

    /**
     * 
     */
    private updateChart(data: any): void {
        this.chart.hideLoading();

        let vsan_total_capacity = [];
        let vsan_used = [];
        let vsan_vm_swap = [];
        let vsan_vm_disk = [];

        for (let i in data) {
            let vsan_storage = data[i];
            vsan_total_capacity[i] = [];
            vsan_total_capacity[i][0] = vsan_storage.TIMEAGO;
            vsan_total_capacity[i][1] = vsan_storage.VSANTOTALCAPACITY / 1073741824; // in GB
            vsan_used[i] = [];
            vsan_used[i][0] = vsan_storage.TIMEAGO;
            vsan_used[i][1] = (vsan_storage.VSANTOTALCAPACITY - vsan_storage.VSANFREECAPACITY) /
                1073741824; // in GB
            vsan_vm_swap[i] = [];
            vsan_vm_swap[i][0] = vsan_storage.TIMEAGO;
            vsan_vm_swap[i][1] = vsan_storage.VSANVMSWAP / 1073741824; // in GB
            vsan_vm_disk[i] = [];
            vsan_vm_disk[i][0] = vsan_storage.TIMEAGO;
            vsan_vm_disk[i][1] = vsan_storage.VSANVDISK / 1073741824; // in GB
        }

        this.chart.addSeries({
            name: "Capacity",
            cursor: 'pointer',
            yAxis: 0,
            type: 'spline',
            data: vsan_total_capacity,
            tooltip: {
                valueSuffix: " GB"
            },
        });

        this.chart.addSeries({
            name: "Used",
            cursor: 'pointer',
            yAxis: 0,
            type: 'spline',
            data: vsan_used,
            tooltip: {
                valueSuffix: " GB"
            },
        });

        this.chart.addSeries({
            name: "VM swap",
            cursor: 'pointer',
            yAxis: 0,
            type: 'spline',
            data: vsan_vm_swap,
            tooltip: {
                valueSuffix: " GB"
            },
        });

        this.chart.addSeries({
            name: "VM vdisk",
            cursor: 'pointer',
            yAxis: 0,
            type: 'spline',
            data: vsan_vm_disk,
            tooltip: {
                valueSuffix: " GB"
            },
        });

    }

}
