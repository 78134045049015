import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    first
} from 'rxjs/operators';

import {
    AccountService,
    AuditService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    JSONTarget,
    Message,
    VmSynthesis,
    User
} from '@app/model';

import {
    getUserCurrency
} from '../../assets/js/tools.js';

import {
    RecommendationoperationComponent
} from '@app/recommendation/recommendationoperation/recommendationoperation.component';
import {
    RecommendationoperationDirective
} from '@app/directives/recommendationoperation.directive';

import {
    RecommendationresizingComponent
} from '@app/recommendation/recommendationresizing/recommendationresizing.component';
import {
    RecommendationresizingDirective
} from '@app/directives/recommendationresizing.directive';

import {
    RecommendationresizingdetailComponent
} from '@app/recommendation/recommendationresizingdetail/recommendationresizingdetail.component';
import {
    RecommendationresizingdetailDirective
} from '@app/directives/recommendationresizingdetail.directive';

import {
    RecommendationconsolidationComponent
} from '@app/recommendation/recommendationconsolidation/recommendationconsolidation.component';
import {
    RecommendationconsolidationDirective
} from '@app/directives/recommendationconsolidation.directive';

import {
    RecommendationconsolidationdetailComponent
} from '@app/recommendation/recommendationconsolidationdetail/recommendationconsolidationdetail.component';
import {
    RecommendationconsolidationdetailDirective
} from '@app/directives/recommendationconsolidationdetail.directive';

import {
    FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
    faLeaf
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-recommendation',
    templateUrl: './recommendation.component.html',
    styleUrls: ['./recommendation.component.css']
})
export class RecommendationComponent implements OnInit {

    @ViewChild(RecommendationoperationDirective) addOperation: RecommendationoperationDirective;
    @ViewChild(RecommendationresizingDirective) addResizing: RecommendationresizingDirective;
    @ViewChild(RecommendationresizingdetailDirective) addResizingDetail: RecommendationresizingdetailDirective;
    @ViewChild(RecommendationconsolidationDirective) addConsolidation: RecommendationconsolidationDirective;
    @ViewChild(RecommendationconsolidationdetailDirective) addConsolidationDetail:
        RecommendationconsolidationdetailDirective;

    message: Message;

    math = Math;

    reco_vms_data: any = [];

    reco_servers_data: any = [];

    reco_meta_data: any = [];

    cost_vcpus: number = 0;

    cost_vrams: number = 0;

    cost_vdisks: number = 0;

    resizing_cpu_class: string = 'progress top success';

    resizing_ram_class: string = 'progress top success';

    resizing_disk_class: string = 'progress top success';

    consolidation_cpu_class: string = 'progress top success';

    consolidation_ram_class: string = 'progress top success';

    isOperation: boolean = false;

    resize_title: string = 'Top 10 virtual machines resize';

    isResizing: boolean = false;

    isConsolidation: boolean = false;

    globalCurrency: string = '';

    currentUser: User;

    private data_cost: any = [];

    private data_vm: any = [];

    private greenit_reco_data: any = [];
    greenit_vcpu_co2: number = 0;
    greenit_ram_co2: number = 0;
    greenit_prediction_co2: number = 0;

    greenit_plane_ratio: number = 5.4;
    greeeit_train_ratio: number = 578;
    greenit_water_ratio: number = 7576;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private authentication_svc: AccountService,
        private audit_svc: AuditService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef,
        private library: FaIconLibrary) {

        library.addIcons(faLeaf);

    }

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.globalCurrency = getUserCurrency(this.currentUser.currency);

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.audit_svc.getValidTable('behavior_logger_' + this.currentUser.login + '_' + this.message.currentFilter)
            .pipe(first()).subscribe(
                data => {
                    if (data.length > 0)
                        this.message.currentBehTbl = true;
                    else
                        this.message.currentBehTbl = false;
                }, error => {
                    this.message.currentBehTbl = false;
                }
            );

        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_META).subscribe(
            data => {
                this.reco_meta_data = data;
                this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_VM)
                    .subscribe(
                        data => {
                            this.reco_vms_data = data;
                            this.data_vm = this.json_svc.json.vmSynthesis;
                            this.data_cost = this.json_svc.json.costAllocated;
                            this.buildData();
                        }
                    );
            }
        );

        // GET RECO GREENIT DATA
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_RECO)
            .subscribe(
                data => {
                    this.greenit_reco_data = data;
                    this.greenit_vcpu_co2 = this.math.round(this.greenit_reco_data.RECO_RESIZE_VCPU_CO2);
                    this.greenit_ram_co2 = this.math.round(this.greenit_reco_data.RECO_RESIZE_RAM_CO2);
                    this.greenit_prediction_co2 = this.math.round(this.greenit_reco_data.RECO_RESIZE_PREDICT_CO2);
                }
            );
    }

    ngAfterViewInit(): void {
        if (this.message.isFromAlert)
            this.loadOperation();

        // Update cds-icon color for signpost
        setTimeout(() => {
            // Update elements width
            const elements: Element[] = Array.from(document.getElementsByClassName('grey-signpost'));
            elements.forEach((element) => {
                (element.children[0].children[0] as HTMLElement).style.color = "#8c8c8c";
            })
        }, 100);
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    loadOperation(): void {

        this.isOperation = true;
        this.isResizing = false;
        this.isConsolidation = false;
        this.message.isFromAlert = false;

        setTimeout(() => this.loadOpe(), 100);
    }

    loadResizing(): void {

        this.isResizing = true;
        this.isOperation = false;
        this.isConsolidation = false;

        setTimeout(() => this.loadResize(), 100);
    }

    loadResizingDetails(is_detail: boolean): void {

        this.message.isRecoResize = is_detail;

        if (!is_detail) {
            this.message.isRecoPowershell = false;
            this.resize_title = 'Top 10 virtual machines resize';
            setTimeout(() => this.loadResize(), 100);
        } else {
            this.resize_title = 'Detail virtual machines resize';
            setTimeout(() => this.loadResizeDetail(), 100);
        }
    }

    loadConsolidationDetails(is_detail: boolean): void {

        this.message.isRecoConsolidation = is_detail;

        if (!is_detail) {
            setTimeout(() => this.loadConsolid(), 100);
        } else {
            setTimeout(() => this.loadConsolidDetail(), 100);
        }
    }

    loadConsolidation(): void {

        this.isConsolidation = true;
        this.isOperation = false;
        this.isResizing = false;

        setTimeout(() => this.loadConsolid(), 100);
    }

    loadPowerShell(): void {

        if (this.message.isPowershellCpu)
            this.message.isModalPowershellCpu = true;
        else if (this.message.isPowershellRam)
            this.message.isModalPowershellRam = true;
    }

    private buildData(): void {

        for (var i = 0; i < this.reco_vms_data.length; i++) {
            if (this.reco_vms_data[i].type == "VM") {
                if (this.data_vm != null) {
                    const vm: VmSynthesis = this.getVmData(this.reco_vms_data[i].uuid);
                    const vm_costs: any = this.getVmCost(this.reco_vms_data[i].uuid);
                    let cost_vcpu: number = 0;
                    let cost_vram: number = 0;
                    let cost_vdisk: number = 0;
                    if (vm.vcpu > 0)
                        cost_vcpu = vm_costs.cpu_cost / vm.vcpu;
                    if (vm.vram > 0)
                        cost_vram = vm_costs.ram_cost / vm.vram;
                    if (vm.ds > 0)
                        cost_vdisk = vm_costs.sto_cost / vm.ds;

                    if (this.reco_vms_data[i].reco_cpu < 0)
                        this.cost_vcpus += this.math.abs(cost_vcpu * this.reco_vms_data[i].reco_cpu);
                    else if (this.reco_vms_data[i].reco_cpu > 0)
                        this.cost_vcpus -= cost_vcpu * this.reco_vms_data[i].reco_cpu;

                    if (this.reco_vms_data[i].reco_ram < 0)
                        this.cost_vrams += this.math.abs(cost_vram * this.reco_vms_data[i].reco_ram);
                    else if (this.reco_vms_data[i].reco_ram > 0)
                        this.cost_vrams -= cost_vram * this.reco_vms_data[i].reco_ram;

                    if (this.reco_vms_data[i].disk_saved > 0)
                        this.cost_vdisks += cost_vdisk * this.reco_vms_data[i].disk_saved;
                }
            }
        }

        // MANAGE CSS CLASS
        if (this.reco_meta_data.vcpu_save_per > 50 && this.reco_meta_data.vcpu_save_per < 81)
            this.resizing_cpu_class = 'progress top warning';
        else if (this.reco_meta_data.vcpu_save_per > 80)
            this.resizing_cpu_class = 'progress top danger';

        if (this.reco_meta_data.ram_save_per > 50 && this.reco_meta_data.ram_save_per < 81)
            this.resizing_ram_class = 'progress top warning';
        else if (this.reco_meta_data.ram_save_per > 80)
            this.resizing_ram_class = 'progress top danger';

        if (this.reco_meta_data.disk_save_per > 50 && this.reco_meta_data.disk_save_per < 81)
            this.resizing_disk_class = 'progress top warning';
        else if (this.reco_meta_data.disk_save_per > 80)
            this.resizing_disk_class = 'progress top danger';

        if (this.reco_meta_data.over_ram_cons > 50 && this.reco_meta_data.over_ram_cons < 81)
            this.consolidation_ram_class = 'progress top warning';
        else if (this.reco_meta_data.over_ram_cons > 80)
            this.consolidation_ram_class = 'progress top danger';

        if (this.reco_meta_data.over_cpu_cons > 50 && this.reco_meta_data.over_cpu_cons < 81)
            this.consolidation_cpu_class = 'progress top warning';
        else if (this.reco_meta_data.over_cpu_cons > 80)
            this.consolidation_cpu_class = 'progress top danger';
    }

    private getVmData(uuid: string): VmSynthesis {
        return this.data_vm.find(vm => vm.uuid === uuid);
    }

    private getVmCost(uuid: string): any {
        return this.data_cost.find(vm => vm.uuid === uuid);
    }

    private loadOpe(): void {

        if (this.addOperation != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                RecommendationoperationComponent);
            const viewContainerRef = this.addOperation.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadResize(): void {

        if (this.addResizing != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                RecommendationresizingComponent);
            const viewContainerRef = this.addResizing.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadResizeDetail(): void {

        if (this.addResizingDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                RecommendationresizingdetailComponent);
            const viewContainerRef = this.addResizingDetail.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadConsolid(): void {

        if (this.addConsolidation != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                RecommendationconsolidationComponent);
            const viewContainerRef = this.addConsolidation.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadConsolidDetail(): void {

        if (this.addConsolidationDetail != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                RecommendationconsolidationdetailComponent);
            const viewContainerRef = this.addConsolidationDetail.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    /**
     * 
     */
    formatNumber(value: number): string {
        return Intl.NumberFormat().format(value);
    }

}
