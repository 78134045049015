import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    JSONTarget,
    Message,
    User
} from '@app/model';
import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';
import {
    ClrDatagridSortOrder
} from '@clr/angular';

@Component({
    selector: 'app-listnodes',
    templateUrl: './listnodes.component.html',
    styleUrls: ['./listnodes.component.css']
})
export class ListnodesComponent implements OnInit {

    @Input() target: JSONTarget;

    ascOrder = ClrDatagridSortOrder.ASC;
    descOrder = ClrDatagridSortOrder.DESC;

    message: Message;

    private currentUser: User;

    nodes_data: any = [];

    selected_node: string;
    selected_processes: any;
    show_process: boolean = false;


    scaph_target: JSONTarget = JSONTarget.GREENIT_SCAPH_CEE;
    ipmi_target: JSONTarget = JSONTarget.GREENIT_IPMI_CEE;
    network_target: JSONTarget = JSONTarget.GREENIT_NETWORK_CEE;
    storage_target: JSONTarget = JSONTarget.GREENIT_STORAGE_CEE;


    /**
     * 
     */
    constructor(private authentication_svc: AccountService, private message_svc: ShareService,
        private json_svc: JsonloaderService) {}

    /**
     * 
     */
    ngOnInit(): void {
        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);


        if (this.target !== undefined) {
            this.json_svc.getData(this.currentUser.login, this.message.currentFilter, this.target).subscribe(
                data => {
                    if (data != undefined && ( < any > data).length > 0) {
                        this.nodes_data = data;
                    }
                }
            );
        }
    }


    /**
     * 
     */
    displayModalProcess(node: string, processes: any) {
        this.selected_node = node;
        this.selected_processes = processes;
        this.show_process = true;
    }

    /**
     * 
     */
    exportList() {

        let res: any = [];

        switch (this.target) {
            case this.scaph_target:
                res = ["Name", "OS", "CPU Sockets", "RAM Capacity (MB)", "Annual kWh", "Annual CO2 (kg CO2eq)",
                    "Annual Embodied CO2 (kg CO2eq)", "Power (W)", "Power waste (%)"
                ].join(',') + '\n';
                res += Object.values < any > (this.nodes_data).map(data => [data.NAME, data.OS, data.SOCKETSNB, data
                    .RAMCAP,
                    data
                    .KWH, data.CO2, data.CO2G, data.POWER, data.WASTEPOWER
                ].join(",")).join('\n');
                break;
            case this.ipmi_target:
                res = ["Name", "Server model", "Annual kWh", "Annual CO2 (kg CO2eq)",
                    "Annual Embodied CO2 (kg CO2eq)", "Power (W)"
                ].join(',') + '\n';
                res += Object.values < any > (this.nodes_data).map(data => [data.NAME, data.SERVERMODEL, data
                    .KWH, data.CO2, data.CO2G, data.POWER
                ].join(",")).join('\n');
                break;
            case this.network_target:
            case this.storage_target:
                res = ["Name", "Type", "Annual kWh", "Annual CO2 (kg CO2eq)",
                    "Annual Embodied CO2 (kg CO2eq)", "Power (W)"
                ].join(',') + '\n';
                res += Object.values < any > (this.nodes_data).map(data => [data.NAME, data.TYPE, data
                    .KWH, data.CO2, data.CO2G, data.POWER
                ].join(",")).join('\n');
                break;
            default:
                res = ["Name", "CPU Sockets", "RAM Capacity (MB)", "Annual kWh", "Annual CO2 (kg CO2eq)",
                    "Annual Embodied CO2 (kg CO2eq)", "Power (W)"
                ].join(',') + '\n';
                res += Object.values < any > (this.nodes_data).map(data => [data.NAME, data.SOCKETSNB, data.RAMCAP,
                    data
                    .KWH, data.CO2, data.CO2G, data.POWER
                ].join(",")).join('\n');
                break;
        }

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = this.target.toLowerCase() + '.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    /**
     * 
     */
    exportProcesses() {
        let res = ["Name", "Last 24h Wh", "Last 24h CO2 (kg CO2eq), Power (W)"].join(',') + '\n';
        res += Object.values < any > (this.selected_processes).map(data => [data.NAME, data.WH, data.CO2, data
            .POWER
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'greenit_processes_' + this.selected_node.toLowerCase() + '.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

}
