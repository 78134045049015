import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NetscopeDashboardComponent} from "./netscope-dashboard/netscope-dashboard.component";
import { NetscopeTopologyComponent } from "./netscope-topology/netscope-topology.component";
import { NetscopeHeatmapViewerComponent } from "./netscope-heatmap-viewer/netscope-heatmap-viewer.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ClarityModule } from "@clr/angular";
import { AppRoutingModule } from "@app/app-routing.module";
import { HighchartsChartModule } from "highcharts-angular";
import { DataTablesModule } from "angular-datatables";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { ExpandableRowsComponent } from "./netscope-flows-datagrid/expandable-rows/expandable-rows.component";
import { RangeFilterComponent } from "./netscope-flows-datagrid/range-filter/range-filter.component";
import { ResourcesComboboxComponent } from "./netscope-flows-datagrid/resources-combobox/resources-combobox.component";
import { ResourceFilterComponent } from "./netscope-flows-datagrid/resource-filter/resource-filter.component";
import { HeatmapComboboxComponent } from "@app/netscope/netscope-heatmap-viewer/heatmap-combobox/heatmap-combobox.component";
import { HeatmapHighchartsHeatmapComponent } from "./netscope-heatmap-viewer/heatmap-highcharts-heatmap/heatmap-highcharts-heatmap.component";
import { NetscopeCustomProtocolsComponent } from "./netscope-custom-protocols/netscope-custom-protocols.component";
import { NetscopeAppsGroupsEditComponent } from "./netscope-apps-groups-edit/netscope-apps-groups-edit.component";
import { AppsGroupsComboboxComponent } from "@app/netscope/netscope-apps-groups-edit/apps-groups-combobox/apps-groups-combobox.component";
import { AppsGroupsWizardTagsSelectionComponent } from "./netscope-apps-groups-edit/apps-groups-wizard-tags-selection/apps-groups-wizard-tags-selection.component";
import { NetscopeCommonFlowviewComponent } from "./netscope-common-flowview/netscope-common-flowview.component";
import { NetscopeExternalIpsComponent } from "./netscope-external-ips/netscope-external-ips.component";
import { ExternalIpsWizardImportCsvComponent } from "./netscope-external-ips/external-ips-wizard-import-csv/external-ips-wizard-import-csv.component";
import { NetscopeDiagnosticComponent } from "./netscope-diagnostic/netscope-diagnostic.component";
import { NetscopeFlowsDatagridComponent } from "./netscope-flows-datagrid/netscope-flows-datagrid.component";
import { NetscopeThreatCenterComponent } from "./netscope-supervision/netscope-threat-center/netscope-threat-center.component";
import { NetscopeCommonFlowviewComboboxComponent } from "@app/netscope/netscope-common-flowview/netscope-common-flowview-combobox/netscope-common-flowview-combobox.component";
import { TranslocoModule } from "@ngneat/transloco";
import { NetscopeSupervisionComponent } from "./netscope-supervision/netscope-supervision.component";
import { NetscopeOutsideFlowsComponent } from './netscope-supervision/netscope-outside-flows/netscope-outside-flows.component';
import { NetscopeReportsComponent } from './netscope-supervision/netscope-reports/netscope-reports.component';


@NgModule({
  declarations: [
    NetscopeDashboardComponent,
    NetscopeHeatmapViewerComponent,
    NetscopeTopologyComponent,
    ExpandableRowsComponent,
    RangeFilterComponent,
    ResourceFilterComponent,
    HeatmapComboboxComponent,
    HeatmapHighchartsHeatmapComponent,
    NetscopeCustomProtocolsComponent,
    NetscopeAppsGroupsEditComponent,
    AppsGroupsComboboxComponent,
    AppsGroupsWizardTagsSelectionComponent,
    NetscopeCommonFlowviewComponent,
    NetscopeExternalIpsComponent,
    ExternalIpsWizardImportCsvComponent,
    NetscopeDiagnosticComponent,
    NetscopeFlowsDatagridComponent,
    NetscopeThreatCenterComponent,
    ResourcesComboboxComponent,
    NetscopeCommonFlowviewComboboxComponent,
    NetscopeSupervisionComponent,
    NetscopeOutsideFlowsComponent,
    NetscopeReportsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ClarityModule,
    AppRoutingModule,
    HighchartsChartModule,
    DataTablesModule,
    FontAwesomeModule,
    NgxSliderModule,
    TranslocoModule,
  ],
  exports: [NetscopeFlowsDatagridComponent],
})
export class NetscopeModule {}
