<div *ngIf="tab==instancetype.VMWARE" class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<ng-template load-t9></ng-template>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<ng-template load-t7></ng-template>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
		<ng-template load-t12></ng-template>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
		<ng-template load-t13></ng-template>
	</div>
</div>

<div *ngIf="tab==instancetype.AWS" class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-account-management [targetInfrastructure]="this.message.cloudProvider" (loadRegion)="loadRegion()" *ngIf="isAccountMgtReady"></app-account-management>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card addScroll" *ngIf="!isAwsReady">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="tree"></cds-icon>
					&nbsp;CO2 Emissions
				</h5>
			</div>
			<div class="card-block">
				<div class="card-text">
					<div>
						<strong class="center">No data available !</strong>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="isAwsReady">
			<app-instances-co2></app-instances-co2>
		</div>
	</div>
</div>

<div *ngIf="tab==instancetype.AZURE" class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-account-management [targetInfrastructure]="this.message.cloudProvider" (loadRegion)="loadRegion()" *ngIf="isAccountMgtReady"></app-account-management>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card addScroll" *ngIf="!isAzureReady">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="tree"></cds-icon>
					&nbsp;CO2 Emissions
				</h5>
			</div>
			<div class="card-block">
				<div class="card-text">
					<div>
						<strong class="center">No data available !</strong>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="isAzureReady">
			<app-instances-co2></app-instances-co2>
		</div>
	</div>
</div>

<div *ngIf="tab==instancetype.GCP" class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-account-management [targetInfrastructure]="this.message.cloudProvider" (loadRegion)="loadRegion()" *ngIf="isAccountMgtReady"></app-account-management>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card addScroll" *ngIf="!isGcpReady">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="tree"></cds-icon>
					&nbsp;CO2 Emissions
				</h5>
			</div>
			<div class="card-block">
				<div class="card-text">
					<div>
						<strong class="center">No data available !</strong>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="isGcpReady">
			<app-instances-co2></app-instances-co2>
		</div>
	</div>
</div>

<div *ngIf="tab==instancetype.KUBERNETES" class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card addScroll" *ngIf="!isKubernetesReady">
			<div class="card-header card-ajust">
				<h5>
					<cds-icon shape="tree"></cds-icon>
					&nbsp;CO2 Emissions
				</h5>
			</div>
			<div class="card-block">
				<div class="card-text">
					<div>
						<strong class="center">No data available !</strong>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="isKubernetesReady">
			<app-greenit-detail></app-greenit-detail>
		</div>
	</div>
</div>

<div *ngIf="tab==instancetype.SCAPHANDRE" class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<ng-template load-t9></ng-template>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<ng-template load-t7></ng-template>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-listnodes [target]="scaph_target"></app-listnodes>
	</div>
</div>

<div *ngIf="tab==instancetype.XCLARITY" class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<ng-template load-t9></ng-template>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<ng-template load-t7></ng-template>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-listnodes [target]="xclarity_target"></app-listnodes>
	</div>
</div>

<div *ngIf="tab==instancetype.OPENMANAGE" class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<ng-template load-t9></ng-template>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<ng-template load-t7></ng-template>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-listnodes [target]="openmanage_target"></app-listnodes>
	</div>
</div>

<div *ngIf="tab==instancetype.ONEVIEW" class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<ng-template load-t9></ng-template>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<ng-template load-t7></ng-template>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-listnodes [target]="oneview_target"></app-listnodes>
	</div>
</div>

<div *ngIf="tab==instancetype.IPMI" class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<ng-template load-t9></ng-template>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<ng-template load-t7></ng-template>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-listnodes [target]="ipmi_target"></app-listnodes>
	</div>
</div>

<div *ngIf="tab==instancetype.STORAGE" class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<ng-template load-t9></ng-template>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<ng-template load-t7></ng-template>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-listnodes [target]="storage_target"></app-listnodes>
	</div>
</div>

<div *ngIf="tab==instancetype.NETWORK" class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<ng-template load-t9></ng-template>
	</div>
	<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
		<ng-template load-t7></ng-template>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<app-listnodes [target]="network_target"></app-listnodes>
	</div>
</div>