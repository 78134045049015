import {
    Component,
    OnInit
} from '@angular/core';
import {
    JSONTarget,
    Message,
    User
} from '@app/model';
import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import * as Highcharts from 'highcharts/highcharts';
import HC_sunburst from 'highcharts/modules/sunburst';
HC_sunburst(Highcharts);

@Component({
    selector: 'app-topkprocess',
    templateUrl: './topkprocess.component.html',
    styleUrls: ['./topkprocess.component.css']
})
export class TopkprocessComponent implements OnInit {

    message: Message;

    private currentUser: User;

    data_process: any = {};

    isReady: boolean = false;

    best_process: any = {};
    worst_process: any = {};

    // SUNBURST CHART
    Highcharts: typeof Highcharts = Highcharts;

    chart: Highcharts.Chart;

    chartColors = Highcharts.getOptions().colors;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.chart.showLoading();
    };

    options: Highcharts.Options = {
        chart: {
            //backgroundColor: 'rgba(255, 255, 255, 0.0)',
            //plotBackgroundColor: null,
            //plotBorderWidth: null,
            //plotShadow: false,
            margin: [0, 0, 0, 0],
            //height: '200px',
            /* options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }, */
        },
        // Let the center circle be transparent
        //colors: ['transparent'].concat(Highcharts.getOptions().colors),
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: '',
        },
        legend: {
            enabled: false
        },
        tooltip: {
            formatter: function() {
                let tmp_point: any = this.point;
                if (tmp_point.parent == "root") {
                    return '<b>' + this.point.name + '</b>';
                } else {
                    return '<b>' + this.point.name + '</b>: ' + this.point.value.toFixed(2) + ' Wh';
                }
            },
            headerFormat: '',
        }
    }

    /**
     * 
     */
    constructor(private authentication_svc: AccountService, private message_svc: ShareService,
        private json_svc: JsonloaderService) {

        this.worst_process.name = "not available";
        this.worst_process.value = "n/a";
        this.best_process.name = this.worst_process.name;
        this.best_process.value = this.worst_process.value;
    }


    /**
     * 
     */
    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        // Load data
        this.load(false);
    }

    /**
     * 
     */
    load(reload: boolean): void {

        if (reload) {
            this.isReady = false;

            try {
                this.data_process = {};

                // Remove old series
                for (var i = this.chart.series.length - 1; i >= 0; i--) {
                    this.chart.series[i].remove(false);
                }

                // Show loading
                this.chart.showLoading();
            } catch {
                // Nothing
            }

        }

        // Get data
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_SCAPH_PROCESS)
            .subscribe(
                data => {

                    if (data != undefined && ( < any > data).length > 0) {
                        this.data_process = data;

                        // Sort by Wh
                        this.data_process = this.data_process.sort((a, b) => b.WH - a.WH);

                        this.worst_process = this.data_process[0];
                        this.best_process = this.data_process[this.data_process.length - 1];

                        // V1 : define custom pie colors (custom gradient)
                        let colors = [];
                        let highcharts_colors = Highcharts.getOptions().colors;

                        //let base = Highcharts.getOptions().colors[0];
                        //let base = "#476888";
                        //let base = "#0B610B";

                        //for (let i = 0; i < this.data_process.length; i += 1) {
                        //  colors.push(new Highcharts.Color(base).brighten((i) / this.data_process.length).get());
                        //}
                        //this.chartColors = colors;


                        // V2 : push white for root level & keep highcharts default colors
                        colors.push('#FFFFFF');
                        for (let i = 0; i < highcharts_colors.length - 1; i += 1) {
                            colors.push(highcharts_colors[i]);
                        }

                        this.chartColors = colors;

                        this.isReady = true;

                        setTimeout(() => {
                            this.displayPie();
                        }, 100);
                    }
                }
            );
    }

    /**
     * 
     */
    private displayPie(): void {

        if (this.chart === undefined)
            return;

        // Update chart
        //this.chart.update({ plotOptions: {pie: { colors: this.chartColors }} });

        this.chart.update({
            colors: this.chartColors
        });


        // Remove old series
        if (this.chart.series[0] != undefined)
            this.chart.series[0].remove(true);


        let processes: Map < string, Map < string, number >> = new Map < string,
            Map < string, number >> ();
        let fathers: Set < string > = new Set < string > ();

        this.data_process.forEach(element => {
            let values: Map < string, number > = processes.get(element.FATHER_NAME);
            if (values === undefined) {
                values = new Map < string, number > ();
                processes.set(element.FATHER_NAME, values);
                fathers.add(element.FATHER_NAME);
            }

            // FIX Sunburst
            let elementwh = element.WH;
            if (elementwh == 0) {
                elementwh = 0.01;
            }

            values.set(element.NAME, elementwh);

        });

        // Build data serie
        let datas = [];

        // Build root
        let data: any = {};
        data.id = "root";
        data.parent = "";
        data.name = "all scaphandre nodes : " + fathers.size;

        datas.push(data);


        processes.forEach(function(value, key) {
            let node_id = key;
            let node_processes = value;

            let ndata: any = {};
            // Build node data
            ndata.id = node_id;
            ndata.parent = "root";
            ndata.name = node_id;

            datas.push(ndata);

            // Build proceses data
            node_processes.forEach(function(value, key) {
                let pdata: any = {};

                // Build process data
                pdata.id = key;
                pdata.parent = node_id;
                pdata.name = key;
                pdata.value = value;

                datas.push(pdata);
            });

        });

        let mylevels = [{
            level: 1,
            levelIsConstant: false,
        }, {
            level: 2,
            colorByPoint: true,
        }, {
            level: 3,
            colorVariation: {
                key: 'brightness',
                to: 0.7
            }
        }];

        // Add serie
        this.chart.addSeries({
            type: 'sunburst',
            data: datas,
            allowTraversingTree: true,
            cursor: 'pointer',
            dataLabels: {
                format: '{point.name}',
                filter: {
                    property: 'innerArcLength',
                    operator: '>',
                    value: 16
                },
            },
            levels: mylevels
        });

        // Hide chart loading
        this.chart.hideLoading();
    }

}
