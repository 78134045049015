import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    AfterViewInit,
    OnInit,
    ViewChild
} from '@angular/core';

import {
    ConsovercpuComponent
} from '@app/consover/consovercpu/consovercpu.component';
import {
    ConsovercpuDirective
} from '@app/directives/consovercpu.directive';

import {
    ConsoverdiskComponent
} from '@app/consover/consoverdisk/consoverdisk.component';
import {
    ConsoverdiskDirective
} from '@app/directives/consoverdisk.directive';

import {
    ConsovernetComponent
} from '@app/consover/consovernet/consovernet.component';
import {
    ConsovernetDirective
} from '@app/directives/consovernet.directive';

import {
    ConsoverramComponent
} from '@app/consover/consoverram/consoverram.component';
import {
    ConsoverramDirective
} from '@app/directives/consoverram.directive';

import {
    AccountService,
    ShareService
} from '@app/services';

import {
    Message,
    User
} from '@app/model';


@Component({
    selector: 'app-consover',
    templateUrl: './consover.component.html',
    styleUrls: ['./consover.component.css']
})
export class ConsoverComponent implements AfterViewInit, OnInit {

    @ViewChild(ConsovercpuDirective) addConsovercpu: ConsovercpuDirective;
    @ViewChild(ConsoverdiskDirective) addConsoverdisk: ConsoverdiskDirective;
    @ViewChild(ConsovernetDirective) addConsovernet: ConsovernetDirective;
    @ViewChild(ConsoverramDirective) addConsoverram: ConsoverramDirective;

    message: Message;

    cpu_tab: boolean = true;

    ram_tab: boolean = false;

    dis_tab: boolean = false;

    net_tab: boolean = false;

    currentUser: User;

    private current_table: any;


    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private authentication_svc: AccountService,
        private message_svc: ShareService,
        private cd: ChangeDetectorRef) {}

    ngOnInit(): void {

        this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);
        switch (this.message.resourceConsover) {
            case "CPU":
                this.cpu_tab = true;
                this.dis_tab = false;
                this.net_tab = false;
                this.ram_tab = false;
                break;
            case "DIS":
                this.cpu_tab = false;
                this.dis_tab = true;
                this.net_tab = false;
                this.ram_tab = false;
                break;
            case "NET":
                this.cpu_tab = false;
                this.dis_tab = false;
                this.net_tab = true;
                this.ram_tab = false;
                break;
            case "RAM":
                this.cpu_tab = false;
                this.dis_tab = false;
                this.net_tab = false;
                this.ram_tab = true;
                break;
            default:
                break;
        }
    }

    ngAfterViewInit(): void {

        this.message.waiting = true;
        setTimeout(() => this.loadConsoverCpu(), 100);
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    switchResource(resource: string): void {

        this.message.waiting = true;
        switch (resource) {
            case "cpu":
                this.cpu_tab = true;
                this.dis_tab = false;
                this.net_tab = false;
                this.ram_tab = false;
                setTimeout(() => this.loadConsoverCpu(), 100);
                break;
            case "dis":
                this.cpu_tab = false;
                this.dis_tab = true;
                this.net_tab = false;
                this.ram_tab = false;
                setTimeout(() => this.loadConsoverDisk(), 100);
                break;
            case "net":
                this.cpu_tab = false;
                this.dis_tab = false;
                this.net_tab = true;
                this.ram_tab = false;
                setTimeout(() => this.loadConsoverNet(), 100);
                break;
            case "ram":
                this.cpu_tab = false;
                this.dis_tab = false;
                this.net_tab = false;
                this.ram_tab = true;
                setTimeout(() => this.loadConsoverRam(), 100);
                break;
            default:
                break;
        }
    }

    private loadConsoverCpu(): void {

        if (this.addConsovercpu != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsovercpuComponent);
            const viewContainerRef = this.addConsovercpu.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadConsoverDisk(): void {

        if (this.addConsoverdisk != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsoverdiskComponent);
            const viewContainerRef = this.addConsoverdisk.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadConsoverNet(): void {

        if (this.addConsovernet != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsovernetComponent);
            const viewContainerRef = this.addConsovernet.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }

    private loadConsoverRam(): void {

        if (this.addConsoverram != undefined) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsoverramComponent);
            const viewContainerRef = this.addConsoverram.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
        }
    }
}
