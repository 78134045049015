import {
    Component,
    OnInit,
    Input,
    ViewChild
} from '@angular/core';

import {
    AccountService,
    GreenitService,
    JsonloaderService,
    ShareService
} from '@app/services';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    GreenitTarget
} from '@app/greenit/greenit.enums';

import * as Highcharts from 'highcharts';
import {
    ClrDatagridSortOrder
} from '@clr/angular';
import {
    EfficiencylistComponent
} from '@app/greenit/efficiencylist/efficiencylist.component';


@Component({
    selector: 'app-t9',
    templateUrl: './t9.component.html',
    styleUrls: ['./t9.component.css']
})
export class T9Component implements OnInit {

    @Input() target: GreenitTarget;

    @ViewChild("child") child: EfficiencylistComponent;

    dataOrder = ClrDatagridSortOrder.ASC;
    dataOrderDesc = ClrDatagridSortOrder.DESC;

    cssClass: string = "card mymediumcard";

    number = Number;

    message: Message;

    private currentUser: User;

    private shared_user: string;

    // FOR DATE
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October",
        "November", "December"
    ];

    // FOR HEATMAP
    elements_data: Array < Array < string >> = [];
    element_tooltip: string;

    best_element: any = {};
    worst_element: any = {};

    month_date: string = "";

    isReady: boolean = false;
    isCee: boolean = false;
    isVmware: boolean = false;

    jsonTarget: JSONTarget;

    // FOR EE
    efficiency_data: any = [];
    renewal_data: any = [];

    // FOR HEATMAP
    unit: string = "Wh";
    div: number = 1;


    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.chart.showLoading();

        if (this.efficiency_data.length > 0) {
            this.setHeatmapData();
        }

        this.chart.hideLoading();
    };

    options: Highcharts.Options = {
        chart: {
            marginTop: 0,
            marginBottom: 0,
            height: '140px',
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            plotBorderWidth: 0,
            plotBorderColor: '#ffffff',
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
            visible: false,
        },
        yAxis: {
            title: null,
            reversed: true,
            categories: [],
            visible: false,
            gridLineColor: 'transparent',
        },
        colorAxis: {
            minColor: '#FFFFFF',
            //maxColor: '#39ac73'
            maxColor: '#476888'
        },
        tooltip: {
            formatter: function() {
                return this.element_tooltip;
            }.bind(this)
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 20,
            verticalAlign: 'top',
            symbolHeight: 90
        },
    }


    /**
     *  
     */
    constructor(private authentication_svc: AccountService, private message_svc: ShareService,
        private greenit_svc: GreenitService, private json_svc: JsonloaderService) {

        this.worst_element.name = "not available";
        this.worst_element.cee = "n/a";
        this.worst_element.value = "n/a";
        this.best_element.name = this.worst_element.name;
        this.best_element.cee = this.worst_element.cee;
        this.best_element.value = this.worst_element.value;

        if (this.target == undefined)
            this.target = GreenitTarget.DC_HOST_CEE;

    }

    /**
     * 
     */
    ngOnInit(): void {
        this.authentication_svc.user.subscribe(user => this.currentUser = user);
        this.message_svc.currentMessage.subscribe(message => this.message = message);

        //XXX Check shared filter
        this.shared_user = this.currentUser.login;
        if (this.message.isShared) {
            this.shared_user = "root";
        }

        // Load data
        this.load(false);

        // FIX css
        if (this.target == GreenitTarget.DC_HOST_CEE) {
            this.cssClass = "card myminicard";
        } else {
            this.cssClass = "card mymediumcard";
        }
    }

    /**
     * 
     */
    load(reload: boolean): void {

        if (reload) {
            this.isReady = false;

            try {
                // Remove old series
                for (var i = this.chart.series.length - 1; i >= 0; i--) {
                    this.chart.series[i].remove(false);
                }

                // Show loading
                this.chart.showLoading();
            } catch {
                // Nothing
            }

        }

        // Set data
        switch (this.target) {
            case GreenitTarget.DC_HOST_CEE:
                this.isVmware = true;
                this.jsonTarget = JSONTarget.GREENIT_DC_CEE;
                this.initCEE();
                break;
            case GreenitTarget.SCAPHANDRE_NODE_CEE:
                this.jsonTarget = JSONTarget.GREENIT_SCAPH_CEE;
                this.initCEE();
                break;
            case GreenitTarget.XCLARITY_NODE_CEE:
                this.jsonTarget = JSONTarget.GREENIT_XCLARITY_CEE;
                this.initCEE();
                break;
            case GreenitTarget.OPENMANAGE_NODE_CEE:
                this.jsonTarget = JSONTarget.GREENIT_OPENMANAGE_CEE;
                this.initCEE();
                break;
            case GreenitTarget.ONEVIEW_NODE_CEE:
                this.jsonTarget = JSONTarget.GREENIT_ONEVIEW_CEE;
                this.initCEE();
                break;
            default:
                this.initKWh();
                break;
        }
    }

    /**
     * 
     */
    private initKWh(): void {

        this.greenit_svc.getGreenitData(this.shared_user, this.message.currentFilter, this.target).subscribe(
            data => {
                if (data != undefined && ( < any > data).length > 0) {
                    this.efficiency_data = data;

                    if (this.efficiency_data != undefined && this.efficiency_data.length > 0) {

                        // Sort per values
                        this.efficiency_data.sort((a, b) => a.value - b.value);

                        // Sort per value (without 0)
                        let tmp_values = this.efficiency_data.filter((e: {
                            value: number
                        }) => e.value > 0).sort((a, b) => a.value - b.value);

                        if (tmp_values.length > 0) {
                            this.best_element = tmp_values[0];

                            // Define date
                            //XXX in order to show last month number (first day of month minus a day)
                            let lastTime = Number(this.best_element.time) - 24 * 3600 * 1000;
                            let lastDate = new Date(lastTime);
                            this.month_date = this.monthNames[lastDate.getMonth()] + ' ' + lastDate
                                .getFullYear();

                            // Compute unit
                            if (this.best_element.value > 2000) {
                                this.unit = "kWh";
                                this.div = 1000;
                            }

                            // Consider last as worst
                            this.worst_element = this.efficiency_data[this.efficiency_data.length - 1];

                            // Apply unit
                            this.efficiency_data.map(x => x.value = Number(Number(x.value / this.div).toFixed(
                                2)));
                        }
                    }

                    this.isReady = true;
                }
            }
        );
    }

    /**
     *
     */
    initCEE(): void {
        this.isCee = true;

        // FOR CEE
        this.json_svc.getData(this.currentUser.login, this.message.currentFilter, this.jsonTarget)
            .subscribe(
                //this.greenit_svc.getGreenitData(this.shared_user, this.message.currentFilter, this.target).subscribe(
                data => {
                    if (data != undefined && ( < any > data).length > 0) {
                        this.efficiency_data = data;

                        // Property name in lower case 
                        this.efficiency_data = this.efficiency_data.map(x => {
                            for (let prop in x) {
                                x[prop.toLowerCase()] = x[prop];
                                delete x[prop];
                            }
                            return x;
                        })

                        // Keep only RUNNING servers (only if DC_HOST_CEE)
                        // Keep NEW for calculator
                        if (this.target == GreenitTarget.DC_HOST_CEE) {
                            this.renewal_data = this.efficiency_data.find((e: {
                                name: string;
                            }) => (e.name == "RENEWAL_SERVER"));
                            this.efficiency_data = this.efficiency_data.filter((e: {
                                state: string;
                            }) => (e.state == "RUNNING"));
                        }

                        if (this.efficiency_data != undefined && this.efficiency_data.length > 0) {

                            // Sort per cee (not 0)
                            let tmp_cee = this.efficiency_data.filter((e: {
                                cee: number
                            }) => e.cee > 0).sort((a, b) => a.cee - b.cee);

                            if (tmp_cee.length > 0) {
                                this.best_element = tmp_cee[0];

                                let worst_cee = this.efficiency_data.sort((a, b) => b.cee - a.cee);
                                // Consider 0 as worst
                                this.worst_element = worst_cee[worst_cee.length - 1];

                                if (this.target == GreenitTarget.DC_HOST_CEE) {
                                    if (this.worst_element != undefined && this.worst_element.cee > 0) {
                                        this.worst_element = worst_cee[0];
                                    }
                                } else {
                                    if (this.worst_element != undefined)
                                        this.worst_element = worst_cee[0];
                                }
                            }
                        }

                        this.isReady = true;
                    }
                }
            );
    }

    /**
     * 
     */
    private setHeatmapData(): void {
        let datas: Array < Array < number >> = [];

        let size = this.efficiency_data.length;
        let columns = Math.round(Math.sqrt(size));

        // FIX width
        columns = columns > 10 ? 10 : columns;

        let i = 0,
            j = 0;

        this.efficiency_data.forEach(element => {
            if (j == columns) {
                i++;
                j = 0;
            }
            if (j == 0)
                this.elements_data[i] = [];

            let value;

            switch (this.target) {
                case GreenitTarget.DC_HOST_CEE:
                case GreenitTarget.SCAPHANDRE_NODE_CEE:
                case GreenitTarget.XCLARITY_NODE_CEE:
                case GreenitTarget.OPENMANAGE_NODE_CEE:
                case GreenitTarget.ONEVIEW_NODE_CEE:
                    value = element.cee;
                    break;
                default:
                    value = element.value;
                    break;
            }

            if (value != undefined) {
                this.elements_data[i][j] = element.name;
                let tmp_data = [i, j, value];
                datas.push(tmp_data);
                j++;
            }

        });

        this.chart.addSeries({
            type: "heatmap",
            data: datas,
            borderWidth: 1,
            borderColor: '#476888',
            point: {
                events: {
                    mouseOver: function(e: {
                        target: any;
                    }) {
                        let p = e.target;
                        switch (this.target) {
                            case GreenitTarget.DC_HOST_CEE:
                            case GreenitTarget.SCAPHANDRE_NODE_CEE:
                            case GreenitTarget.XCLARITY_NODE_CEE:
                            case GreenitTarget.OPENMANAGE_NODE_CEE:
                            case GreenitTarget.ONEVIEW_NODE_CEE:
                                this.element_tooltip = "<b>" + this.elements_data[p.x][p.y] +
                                    "</b><br>Current Energy Efficiency : " + p.value;
                                break;
                            default:
                                this.element_tooltip = "<b>" + this.elements_data[p.x][p.y] +
                                    "</b><br>Current month : " + p.value + " " + this.unit;
                                break;
                        }
                    }.bind(this)
                }
            }
        });
    }

    /**
     * 
     */
    displayModalEfficiency(): void {
        this.child.displayModalEfficiency();
    }

    displayModalOptimization(): void {
        this.child.displayModalOptimization();
    }

}
