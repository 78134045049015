<div [ngClass]="myClass">
	<div class="card-header card-ajust">
		<h5>
			<cds-icon shape="tree"></cds-icon>
			&nbsp;Evolution
		</h5>
	</div>
	<div class="card-block" id="dashboard-t8-core">
		<div class="card-text">
			<div *ngIf="isReady">
				<highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;" ></highcharts-chart>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> If you have just created your filter, data will be available tomorrow.</strong>
			</div>
		</div>
	</div>
</div>