<!-- MODAL PLUGIN -->
<clr-modal [(clrModalOpen)]="plugin_modal" [clrModalClosable]=true [clrModalSize]="'l'">
    <h3 class="modal-title">vSphere Client Plug-in Registration</h3>
    <div class="modal-body" style="overflow:auto">
        <br>
        <div class="alert alert-warning" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        <b>DC Scope must have a valid SSL certificate otherwise the vCenter will not deploy the plugin !</b>
                    </span>
                </div>
            </div>
        </div>
        <br>
        <div class="alert alert-info" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                        <b>Compatibility : vCenter 6.5 U2 or upper</b><br>
                        <b>After registration, you need logout/login vSphere client and refresh your browser.</b>
                    </span>
                </div>
            </div>
        </div>
        <hr>
        <div><p><b>Plugin registration :</b></p>
            1- <a (click)="getXML()" style="cursor:pointer;color:#0072a3;text-decoration:underline;">Download XML</a><br>
            2- Connect to the vCenter Managed Object Browser<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;https://&lt;vcenter_address&gt;/mob<br>
            3- Browse to 'ServiceContent' then 'ExtensionManager'<br>
            4- Choose 'RegisterExtension'<br>
            5- Paste the content of xml file<br>
            6- Invoke method (the return result must be 'void')
        </div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="plugin_modal = false">Close</button>
    </div>
</clr-modal>

<!-- MODAL INFO -->
<clr-modal [(clrModalOpen)]="info_modal" [clrModalClosable]=false [clrModalSize]="'sm'">
    <h3 class="modal-title">{{info_header}}</h3>
    <div class="modal-body" style="min-height:100px;">
        <div style="width:100%;">
            <p>{{info_body}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="info_modal = false">Ok</button>
    </div>
</clr-modal>
