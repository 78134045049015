<div class="card">
	<div class="card-header card-ajust">
		<h5><cds-icon shape="user"></cds-icon>&nbsp;&nbsp;Information about {{alias_selected.login}}</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<clr-datagrid>
				<clr-dg-column>NAME</clr-dg-column>
				<clr-dg-column>GROUP</clr-dg-column>
				<clr-dg-column>LOCAL</clr-dg-column>
				<clr-dg-column>LDAP</clr-dg-column>				
				<clr-dg-row>
					<clr-dg-cell>{{alias_selected.login}}</clr-dg-cell>
					<clr-dg-cell>{{currentUser.login}}</clr-dg-cell>
					<clr-dg-cell>{{alias_selected.is_local == 1 ? "true": "false"}}</clr-dg-cell>
					<clr-dg-cell>{{alias_selected.is_ldap == 1 ? "true": "false"}}</clr-dg-cell>
				</clr-dg-row>
			</clr-datagrid>
		</div>
	</div>
	<div class="card-footer">
		<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="editAlias()">
			Edit
		</button>
    </div>
</div>
<div class="card" *ngIf="isEditAlias">
	<button type="button" class="close" aria-label="Close" (click)="isEditAlias=false">
		<cds-icon shape="window-close" size="24"></cds-icon>
	</button>
	<div class="card-block">
		<div class="clr-row">
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<h5 class="card-title">edit parameters for {{alias_selected.login}}</h5>
			</div>
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<button style="float: right;" type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateAlias()" *ngIf="isUpdateAlias">
					Update
				</button>
			</div>
		</div>
		<div class="card-text">
			<clr-timeline>
				<clr-timeline-step clrState="success">
					<clr-timeline-step-header>{{formatEditDate(currentUser.last_co)}}</clr-timeline-step-header>
    					<clr-timeline-step-title>CONNECTION or UPDATE</clr-timeline-step-title>
					<clr-timeline-step-description>Last connection date</clr-timeline-step-description>
  				</clr-timeline-step>
				<clr-timeline-step clrState="success">
					<clr-timeline-step-header>STEP 1</clr-timeline-step-header>
					<clr-timeline-step-title>NAME</clr-timeline-step-title>
					<clr-timeline-step-description>
						<div class="clr-control-container">
							<div class="clr-input-container">
								<label>{{alias_selected.login}}</label>
							</div>
							<span class="clr-subtext">(cannot be changed)</span>
						</div>
					</clr-timeline-step-description>
				</clr-timeline-step>
				<clr-timeline-step clrState="success">
					<clr-timeline-step-header>STEP 2</clr-timeline-step-header>
					<clr-timeline-step-title>GROUP</clr-timeline-step-title>
					<clr-timeline-step-description>
						<div class="clr-control-container">
							<div class="clr-select-wrapper">
								<select class="clr-select" disabled=true>
									<option [ngValue]="currentUser">{{currentUser.login}}</option>
								</select>
							</div>
							<span class="clr-subtext">(cannot be changed)</span>
						</div>
					</clr-timeline-step-description>
				</clr-timeline-step>
				<clr-timeline-step [clrState]="edit_alias_model.is_ldap == 0 ? 'current' : 'success'">
					<clr-timeline-step-header>STEP 3</clr-timeline-step-header>
					<clr-timeline-step-title>PASSWORD</clr-timeline-step-title>
					<clr-timeline-step-description>
						<div class="clr-control-container">
							<div class="clr-input-container">
								<input type="password" class="clr-input" [(ngModel)]="edit_alias_model.password" disabled >
							</div>
						</div>
						<div>&nbsp;</div>
						<button class="btn btn-sm" (click)="isModalPassword = true;" [disabled]="edit_alias_model.is_ldap == 1">CHANGE</button>
						<span class="clr-subtext" *ngIf="edit_alias_model.is_ldap == 1">(cannot be changed)</span>
					</clr-timeline-step-description>
				</clr-timeline-step>
				<clr-timeline-step clrState="{{update_state}}">
					<clr-timeline-step-header>{{now}}</clr-timeline-step-header>
					<clr-timeline-step-title>UPDATE</clr-timeline-step-title>
					<clr-timeline-step-description>
						{{update_result}}
					</clr-timeline-step-description>
				</clr-timeline-step>
			</clr-timeline>
		</div>
	</div>
</div>
<div class="card">
	<div class="card-header card-ajust">
		<h5><cds-icon shape="users"></cds-icon>&nbsp;&nbsp;Information about {{currentUser.login}}</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<clr-datagrid>
				<clr-dg-column>NAME</clr-dg-column>
				<clr-dg-column>EMAIL</clr-dg-column>
				<clr-dg-column>CURRENCY</clr-dg-column>
				<clr-dg-column>USERS</clr-dg-column>			
				<clr-dg-row>
					<clr-dg-cell>{{currentUser.login}}</clr-dg-cell>
					<clr-dg-cell>{{currentUser.email}}</clr-dg-cell>
					<clr-dg-cell>{{getCurrency(currentUser.currency)}}</clr-dg-cell>
					<clr-dg-cell>
						<button class="btn btn-link" (click)="isAliasUser=true">
							<span style="position:relative;bottom:33%;">{{aliases.length}}</span>
						</button>
					</clr-dg-cell>
				</clr-dg-row>
			</clr-datagrid>
		</div>
	</div>
	<div class="card-footer">
		<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="editUser()">
			Edit
		</button>
    </div>
</div>
<div class="card" *ngIf="isEditUser">
	<button type="button" class="close" aria-label="Close" (click)="isEditUser=false">
		<cds-icon shape="window-close" size="24"></cds-icon>
	</button>
	<div class="card-block">
		<div class="clr-row">
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<h5 class="card-title">edit parameters for {{currentUser.login}}</h5>
			</div>
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<button style="float: right;" type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateUser()" *ngIf="isUpdateUser">
					Update
				</button>
			</div>
		</div>
		<div class="card-text">
			<clr-timeline>
				<clr-timeline-step clrState="success">
					<clr-timeline-step-header>{{formatEditDate(currentUser.last_co)}}</clr-timeline-step-header>
    					<clr-timeline-step-title>CONNECTION or UPDATE</clr-timeline-step-title>
					<clr-timeline-step-description>Last connection date.</clr-timeline-step-description>
  				</clr-timeline-step>
				<clr-timeline-step clrState="success">
					<clr-timeline-step-header>STEP 1</clr-timeline-step-header>
					<clr-timeline-step-title>NAME</clr-timeline-step-title>
					<clr-timeline-step-description>
						<div class="clr-control-container">
							<div class="clr-input-container">
									<label>{{currentUser.login}}</label>
							</div>
							<span class="clr-subtext">(cannot be changed)</span>
						</div>
					</clr-timeline-step-description>
				</clr-timeline-step>
				<clr-timeline-step clrState="current">
					<clr-timeline-step-header>STEP 2</clr-timeline-step-header>
					<clr-timeline-step-title>EMAIL</clr-timeline-step-title>
					<clr-timeline-step-description>
						<div class="clr-form-control" style="margin-top:0px">
							<div class="clr-input-container" [ngClass]="{'clr-error' :  edit_user_model.email_error}">
									<input id="edit-user-email" type="text" class="clr-input" [(ngModel)]="edit_user_model.email" (ngModelChange)="checkMail(edit_user_model);enableUserUpdate(!edit_user_model.email_error)">
									<cds-icon shape="exclamation-circle" class="is-error" size="18" *ngIf="edit_user_model.email_error"></cds-icon>
									<clr-control-error *ngIf="edit_user_model.email_error">you have entered an invalid email address !</clr-control-error>
								</div>
								<span class="clr-subtext" *ngIf="!edit_user_model.email_error">enter an email</span>
							</div>
						</clr-timeline-step-description>
				</clr-timeline-step>
				<clr-timeline-step clrState="success">
					<clr-timeline-step-header>STEP 3</clr-timeline-step-header>
					<clr-timeline-step-title>CURRENCY</clr-timeline-step-title>
					<clr-timeline-step-description>
						<div class="clr-control-container">
							<div class="clr-select-wrapper">
								<select class="clr-select" disabled=true>
									<option *ngFor="let currency of currencies">{{getCurrency(currency)}}</option>
								</select>
							</div>
							<span class="clr-subtext">(cannot be changed)</span>
						</div>
					</clr-timeline-step-description>
				</clr-timeline-step>
				<clr-timeline-step clrState="{{update_state}}">
					<clr-timeline-step-header>{{now}}</clr-timeline-step-header>
					<clr-timeline-step-title>UPDATE</clr-timeline-step-title>
					<clr-timeline-step-description>
						{{update_result}}
					</clr-timeline-step-description>
				</clr-timeline-step>
			</clr-timeline>
		</div>
	</div>
</div>

<clr-modal [(clrModalOpen)]="isModalPassword" [clrModalSize]="'xs'">
    <h3 class="modal-title">Enter new password for {{alias_selected.login}}</h3>
    <div class="modal-body">
		<div class="card">
			<div class="card-block">
				<div class="card-text">
					<ng-template [ngTemplateOutlet]="passwordtemplate"></ng-template>
				</div>
			</div>
		</div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" [disabled]="signUpForm.status != 'VALID'"
			(click)="updatePwd()">OK</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isAliasUser" [clrModalSize]="'l'">
    <h3 class="modal-title">
		<cds-icon shape="users" size="24"></cds-icon>
		Users of {{currentUser.login}}
	</h3>
    <div class="modal-body" style="overflow:auto">
		<clr-datagrid style="margin-top:20px;margin-bottom:20px;">
			<clr-dg-column [clrDgField]="'login'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">NAME</ng-container></clr-dg-column>
			<clr-dg-column><ng-container *clrDgHideableColumn="{hidden: false}">GROUP</ng-container></clr-dg-column>
			<clr-dg-column><ng-container *clrDgHideableColumn="{hidden: false}">LOCAL</ng-container></clr-dg-column>
			<clr-dg-column><ng-container *clrDgHideableColumn="{hidden: false}">LDAP</ng-container></clr-dg-column>
			
			<clr-dg-row *clrDgItems="let alias of aliases" [clrDgItem]="alias">
				<clr-dg-cell>{{alias.login}}</clr-dg-cell>
				<clr-dg-cell>{{currentUser.login}}</clr-dg-cell>
				<clr-dg-cell>{{alias.is_local == 1 ? "true": "false"}}</clr-dg-cell>
				<clr-dg-cell>{{alias.is_ldap == 1 ? "true": "false"}}</clr-dg-cell>
			</clr-dg-row>
			
			<clr-dg-footer>
                <clr-dg-pagination #user_aliases_page [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{user_aliases_page.firstItem + 1}} - {{user_aliases_page.lastItem + 1}} of {{user_aliases_page.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>
		</clr-datagrid>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-primary" (click)="isAliasUser = false">OK</button>
    </div>
</clr-modal>

<ng-template #passwordtemplate >
	<form clrForm [formGroup]="signUpForm">
		<clr-password-container>
			<label>Password</label>
			<input clrPassword formControlName="password" />
			<clr-control-error *clrIfError="'required'">This password is required!</clr-control-error>
			<clr-control-error *clrIfError="'minlength'">It must be at least 8 characters !</clr-control-error>
			<clr-control-error *clrIfError="'maxlength'">It must be less than 20 characters !</clr-control-error>
			<clr-control-error *clrIfError="'pattern'">It must contains lowercases, uppercases, digits and special characters !</clr-control-error>
		</clr-password-container>
		<clr-password-container>
			<label> Confirm Password</label>
			<input clrPassword formControlName="confirmPassword"/>
			<clr-control-error *clrIfError="'required'">Confirm password is required !</clr-control-error>
			<clr-control-error *clrIfError="'mismatch'">Passwords don't match !</clr-control-error>
			</clr-password-container>
	</form>
</ng-template>
