import {
    AfterViewInit,
    Component,
    OnInit
} from '@angular/core';

import {
    ClrDatagridSortOrder
} from '@clr/angular';

import {
    JSONTarget,
    Message,
    User
} from '@app/model';

import {
    AccountService,
    JsonloaderService,
    ShareService
} from '@app/services';

import * as Highcharts from "highcharts/highstock";
import {
    Options
} from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);


@Component({
    selector: 'app-costs-detail',
    templateUrl: './costs-detail.component.html',
    styleUrls: ['./costs-detail.component.css']
})
export class CostsDetailComponent implements OnInit {

    message: Message;

    dataOrder = ClrDatagridSortOrder.ASC;
    dataOrder2 = ClrDatagridSortOrder.DESC;

    highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart | null;
    chartOptions: Highcharts.Options = {};

    costs: any = [];
    costInstancesAll: any = [];
    costInstances: any = [];
    costInstancesYear: any = [];
    months: any = [];
    periods: any = ['month', 'year'];

    isCostYear: boolean = false;
    isVmYear: boolean = false;
    graphClass = 'clr-col-lg-2 clr-col-md-2 clr-col-12';
    tabClass: string = 'clr-col-lg-10 clr-col-md-10 clr-col-12';

    model: any = {
        selectedId: '',
        selectedName: '',
        selectedMonth: '',
        selectedPeriod: 'month',
        isModel: false
    };

    selected_instance: any;

    isReady: boolean = false;

    isGraph: boolean = true;

    account: string = '';

    provider: string = '';

    currentUser: User;


    constructor(
        private account_svc: AccountService,
        private json_svc: JsonloaderService,
        private message_svc: ShareService
    ) {}

    ngOnInit(): void {

        this.account_svc.user.subscribe(
            user => {
                this.currentUser = user;
            }
        );

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.provider = this.message.cloudProvider;

        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this.message
            .cloudProvider, JSONTarget.CLOUD_MONTH).subscribe(
            data => {
                this.months = data;
                this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter, 'cloud_' + this
                    .message.cloudProvider, JSONTarget.CLOUD_GLOBAL_COST).subscribe(
                    data2 => {
                        this.costs = data2;
                        this.json_svc.getCloudData(this.currentUser.login, this.message.currentFilter,
                            'cloud_' + this.message.cloudProvider, JSONTarget
                            .CLOUD_INSTANCE_CURRENT_COST).subscribe(
                            data3 => {
                                this.costInstancesAll = data3;
                                let val: string = '';
                                val = this.months[0].name;
                                this.model.selectedMonth = val;
                                for (let obj of this.costInstancesAll[val.replace(/ /g, "_")]) {
                                    this.costInstances.push(obj);
                                }
                                this.json_svc.getCloudData(this.currentUser.login, this.message
                                    .currentFilter, 'cloud_' + this.message.cloudProvider,
                                    JSONTarget.CLOUD_INSTANCE_MONTH_COST).subscribe(
                                    data4 => {
                                        this.costInstancesYear = data4;
                                        this.loadGraph(false, true);
                                    }
                                );
                            }
                        );
                    }
                );
            }
        );
    }

    ngAfterViewInit(): void {}

    updateList(): void {

        let val: any = this.model.selectedMonth.replace(/ /g, "_");
        this.costInstances = [];
        if (this.model.isModel) {
            for (let obj of this.costInstancesAll[val]) {
                if (obj.state != "destroy")
                    this.costInstances.push(obj);
            }
        } else {
            for (let obj of this.costInstancesAll[val]) {
                this.costInstances.push(obj);
            }
        }
    }

    reloadGraph(): void {

        this.isReady = false;
        if (this.isCostYear) {
            this.graphClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
            setTimeout(() => this.loadGraph(true, true), 100);
        } else {
            this.graphClass = 'clr-col-lg-2 clr-col-md-2 clr-col-12';
            setTimeout(() => this.loadGraph(false, true), 100);
        }
    }

    closeGraph(): void {

        this.tabClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
        this.isReady = false;
        this.isGraph = false;
    }

    private loadGraph(isYear: boolean, isAll: boolean): void {

        let title: string = '';
        let titleSize: string = '20px';
        let grid: boolean = false;
        let cat: any = [];
        let mergedMeasurements: any = [];
        let data: any = [];
        let data2: any = [];
        let dataCompute: any = {
            "name": "compute",
            "data": data,
            "color": "#f5b041"
        };
        let dataStorage: any = {
            "name": "storage",
            "data": data2,
            "color": "#566573"
        };
        mergedMeasurements.push(dataCompute);
        mergedMeasurements.push(dataStorage);

        let buttonName: boolean = false;

        if (isAll) {
            if (!isYear) {
                title = '';
                titleSize = '16px';
                cat[0] = 'previous month';
                cat[1] = 'current month';
                data[0] = this.costs[0].previous_compute_cost;
                data[1] = this.costs[0].current_compute_cost;
                data2[0] = this.costs[0].previous_storage_cost;
                data2[1] = this.costs[0].current_storage_cost;
            } else {
                title = '';
                titleSize = '20px';
                let m_check: string[] = [];
                let j = 0;
                for (let i = 0; i < this.costs.length; i++) {
                    if (i > 0) {
                        if (!m_check.includes(this.costs[i].month)) {
                            m_check.push(this.costs[i].month);
                            cat[j] = this.costs[i].month;
                            data[j] = this.costs[i].compute;
                            data2[j] = this.costs[i].storage;
                            ++j;
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < this.costInstancesYear.length; i++) {
                if (this.costInstancesYear[i].id == this.model.selectedId) {
                    title = this.model.selectedId + ' (' + this.model.selectedName + ')';
                    titleSize = '20px';
                    for (let j = 0; j < this.costInstancesYear[i].imc.length; j++) {
                        cat[j] = this.costInstancesYear[i].imc[j].name;
                        data[j] = this.costInstancesYear[i].imc[j].compute;
                        data2[j] = this.costInstancesYear[i].imc[j].storage;
                    }
                }
            }
        }

        if (isYear) {
            buttonName = true;
            grid = true;
        } else {
            buttonName = false;
            grid = false;
        }

        this.chartOptions = {
            credits: {
                enabled: false
            },
            title: {
                text: title,
                style: {
                    color: 'grey',
                    fontSize: titleSize
                }
            },
            chart: {
                type: 'column'
            },
            xAxis: {
                categories: cat
            },
            yAxis: {
                visible: grid,
                min: 0,
                title: {
                    text: 'Total cost'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            tooltip: {
                shared: false,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} $</b><br/>'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        crop: false
                    }
                }
            },
            lang: {
                contextButtonTitle: "Export graph"
            },
            exporting: {
                buttons: {
                    contextButton: {
                        className: "addLink",
                        enabled: buttonName,
                        symbol: 'download',
                        text: "download",
                        symbolStroke: "#0072A3",
                        x: -10
                    }
                }
            },
            series: mergedMeasurements
        };
        this.isReady = true;
    }

    loadVmGraph(event): void {

        this.model.selectedId = event.id;
        this.model.selectedName = event.name;
        this.isCostYear = true;
        this.isReady = false;
        this.graphClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
        setTimeout(() => this.loadGraph(true, false), 100);
    }

    loadMonth(event: any): void {

        this.model.isModel = false;
        this.costInstances = [];
        let val: any = event.replace(/ /g, "_");
        for (let obj of this.costInstancesAll[val]) {
            this.costInstances.push(obj);
        }
    }

    exportCosts() {

        let res = ["ID", "Name", "Account", "Region", "State", "Running Hours", "Compute cost ($/month)",
            "Storage cost ($/month)", "Total cost ($/month)"
        ].join(',') + '\n';
        res += Object.values < any > (this.costInstances).map(cost => [cost.id, cost.name, cost.subscription, cost
            .region, cost.state, cost.runH, cost.compute, cost.storage, cost.total
        ].join(",")).join('\n');

        let file_type = "text/csv;charset=utf-8;";

        // Create temp link
        let blob: Blob = new Blob([res], {
            type: file_type
        });
        let fileName = 'overview_cost.csv';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }
}
