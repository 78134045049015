import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import {
    Tab
} from '../virtIndex.enums';

import * as Highcharts from 'highcharts';
import Bullet from 'highcharts/modules/bullet';
Bullet(Highcharts);


@Component({
    selector: 'app-bullet',
    templateUrl: './bullet.component.html',
    styleUrls: ['./bullet.component.css']
})
export class BulletComponent implements OnInit {

    @Input() data: any;

    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    greenit_min_color = '#6aa84f';
    greenit_max_color = '#93c47d';
    infra_min_color = '#49aeda';
    infra_max_color = '#9bd8f3';


    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chart = chart;
        this.updateChart();
    };

    options: Highcharts.Options = {
        chart: {
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            type: 'bullet',
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        time: {
            useUTC: false
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        xAxis: {
            categories: ['']
        },
        yAxis: {},
        tooltip: {
            formatter: function() {
                return '<b>Default value detected : ' + this.y + '</b>';
            },
            borderColor: 'red'
        }

    }

    /**
     * 
     */
    constructor() {}

    /**
     * 
     */
    ngOnInit(): void {}

    /**
     * 
     */
    private updateChart(): void {
        if (this.data) {
            switch (this.data.tab) {
                case Tab.INFRA:
                    this.build(this.infra_min_color, this.infra_max_color);
                    break;
                case Tab.GREENIT:
                    this.build(this.greenit_min_color, this.greenit_max_color);
                    break;
                case Tab.BEHAVIOR:
                    this.buildBehavior();
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Vertical chart
     */
    private buildBehavior(): void {

        let customer_x = Number(this.data.you);
        let data_min = this.data.min;
        let data_avg = this.data.median;
        let data_max = this.data.max;
        let label_color = this.data.color;
        let min_color = this.data.min_color;
        let avg_color = this.data.avg_color;

        // FIX customer_x (default value) for chart
        if (customer_x < data_min)
            data_min = customer_x
        if (customer_x > data_max)
            data_max = customer_x;

        //XXX FIX height & marginTop
        let mheight = 150;
        let mtop = 80;
        if (this.data.desc) {
            mheight = 170;
            mtop = 100;
        }

        // Update chart & axis 
        this.chart.update({
            chart: {
                height: mheight,
                inverted: true,
                marginTop: mtop,
                marginLeft: 20
            },
            xAxis: {
                lineWidth: 0,
            },
            yAxis: {
                title: null,
                gridLineWidth: 0,
                startOnTick: false,
                endOnTick: false,
                plotBands: [{
                    from: data_min,
                    to: data_avg,
                    color: min_color
                }, {
                    from: data_avg,
                    to: data_max,
                    color: avg_color
                }],
                min: data_min,
                max: data_max
            }
        });

        // Push to chart
        this.chart.addSeries({
            type: 'scatter',
            data: [{
                y: customer_x,
                label: String(customer_x),
                dataLabels: {
                    enabled: true,
                    verticalAlign: 'top',
                    padding: 20,
                    style: {
                        fontSize: "16px",
                    },
                    color: label_color
                },
                marker: {
                    enabled: true,
                    fillColor: label_color,
                    radius: 5,
                    states: {
                        hover: {
                            fillColor: label_color
                        }
                    }
                }
            }],
            enableMouseTracking: false
        });

        // Update chart title
        this.addTitle(20);

    }


    /**
     * 
     */
    private build(color_min, color_max): void {

        let customer_x = Number(this.data.you);
        let data_min = this.data.min;
        let data_avg = this.data.median;
        let data_max = this.data.max;
        let label_color = this.data.color;
        let alert_default = this.data.alert_default;

        // FIX customer_x (default value) for chart
        if (customer_x < data_min)
            data_min = customer_x
        if (customer_x > data_max)
            data_max = customer_x;

        // Update chart & axis 
        this.chart.update({
            chart: {
                height: '150px',
                inverted: true,
                marginTop: 80,
                marginLeft: 20
            },
            xAxis: {
                lineWidth: 0,
            },
            yAxis: {
                title: null,
                startOnTick: false,
                min: data_min,
                max: data_max,
                gridLineWidth: 0,
                plotBands: [{
                    from: data_min,
                    to: data_avg,
                    color: color_min
                }, {
                    from: data_avg,
                    to: data_max,
                    color: color_max
                }]
            }
        });

        // Push to chart
        this.chart.addSeries({
            type: 'scatter',
            data: [{
                y: customer_x,
                label: String(customer_x),
                dataLabels: {
                    enabled: true,
                    verticalAlign: 'top',
                    padding: 20,
                    style: {
                        fontSize: "16px",
                    },
                    color: label_color
                },
                marker: {
                    enabled: true,
                    fillColor: label_color,
                    radius: 5,
                    states: {
                        hover: {
                            fillColor: label_color
                        }
                    }
                }
            }],
            enableMouseTracking: alert_default
        });

        // Update chart title
        this.addTitle(20);

    }

    /**
     * 
     */
    private addTitle(x: number): void {

        // Push name
        this.chart.renderer.text(this.data.name, x, 20)
            .attr({
                zIndex: 5
            })
            .css({
                color: 'black',
                fontSize: '12px',
                fontWeight: 'bold'
            })
            .add();

        let y = 40;

        // Push desc
        if (this.data.desc) {
            this.chart.renderer.text(this.data.desc, x, y)
                .attr({
                    zIndex: 5
                })
                .css({
                    color: 'black',
                    fontSize: '12px',
                    fontWeight: 'bold'
                })
                .add();

            y = 60;
        }

        // Push rank
        let top = 'rank : ' + this.data.rank_letter;
        this.chart.renderer.text(top, x, y)
            .attr({
                zIndex: 5
            })
            .css({
                color: 'black',
                fontSize: '12px',
            })
            .add();
    }

}
