import {
    Component, EventEmitter,
    OnInit, Output
} from '@angular/core';
import {
    ClearDetectedThreatsResponse,
    FailureClearDetectedThreatsResponse,
    FailureGetBlocklistVersionResponse,
    GetBlocklistVersionResponse,
    NetscopeService,
    SuccessClearDetectedThreatsResponse,
    SuccessGetBlocklistVersionResponse, TemporalParameter,
} from "@app/services";
import {
    GetDetectedThreatsResponse,
    SuccessGetDetectedThreatsResponse,
    FailureGetDetectedThreatsResponse
} from "@app/services";

@Component({
    selector: 'app-netscope-threat-center',
    templateUrl: './netscope-threat-center.component.html',
    styleUrls: ['./netscope-threat-center.component.css']
})
export class NetscopeThreatCenterComponent implements OnInit {

    @Output()
    loadingStateEmitter = new EventEmitter<boolean>();
    @Output()
    errorStateEmitter = new EventEmitter<boolean>();

    blocklistVersion = -1;
    detectedThreats = [];
    failureMode = false;
    isLoading = false;
    flowDetails = [];
    temporalParameter: TemporalParameter;

    constructor(private netscopeService: NetscopeService) {}

    ngOnInit(): void {
        this.reloadData();
    }

    clearData = () => {
        this.netscopeService.clearDetectedThreats().subscribe((response: ClearDetectedThreatsResponse) => {
            if (response instanceof FailureClearDetectedThreatsResponse) {
                this.failureMode = true;
                this.errorStateEmitter.emit(true);
                this.isLoading = false;
                this.loadingStateEmitter.emit(false);
                return;
            }

            if (response instanceof SuccessClearDetectedThreatsResponse) {
                this.reloadData();
            }
        });
    }

    updateBlocklist = () => {
        this.isLoading = true;
        this.netscopeService.askToFetchNewBlocklist().subscribe((result) => {
            this.isLoading = false;
            this.loadingStateEmitter.emit(false);
            this.reloadData();
        })
    }

    reloadData = () => {
        this.isLoading = true;
        this.loadingStateEmitter.emit(true);

        this.netscopeService.getBlocklistVersion().subscribe((response: GetBlocklistVersionResponse) => {
            if (response instanceof SuccessGetBlocklistVersionResponse) {
                this.blocklistVersion = response.blocklistVersion.msg;
            }

            if (response instanceof FailureGetBlocklistVersionResponse) {
                this.failureMode = true;
                this.errorStateEmitter.emit(true);
                this.isLoading = false;
                this.loadingStateEmitter.emit(false);
                return;
            }

            // Set a temporal parameter which will not be used
            this.temporalParameter = new TemporalParameter(0, 0, "daily");

            this.netscopeService.getDetectedThreats().subscribe((response:
                GetDetectedThreatsResponse) => {
                if (response instanceof FailureGetDetectedThreatsResponse) {
                    this.failureMode = true;
                    this.errorStateEmitter.emit(true);
                    this.isLoading = false;
                    this.loadingStateEmitter.emit(false);
                    return;
                }

                if (response instanceof SuccessGetDetectedThreatsResponse) {
                    this.detectedThreats = response.detectedThreats;
                }

                this.flowDetails = this.detectedThreats.map((threatObject) => {
                    threatObject.flow.threat = threatObject.threat;
                    threatObject.flow.threat.tags = threatObject.flow.threat
                        .tags.split(",");
                    threatObject.flow.date = threatObject.date;
                    threatObject.flow.threat.score = threatObject.score;
                    if (threatObject.flow.threat.score <= 0.15) {
                        threatObject.flow.threat.risk_level = "low";
                    } else if (threatObject.flow.threat.score <= 0.49) {
                        threatObject.flow.threat.risk_level = "medium";
                    } else {
                        threatObject.flow.threat.risk_level = "high";
                    }
                    threatObject.flow.threat.info_ip = threatObject.info_ip;
                    return threatObject.flow;
                });

                function detectType(uuid) {
                    if (uuid !== undefined && uuid !== null) {
                        if (uuid.indexOf("vim.VirtualMachine:") !== -1) {
                            return "vm";
                        }
                        if (uuid.indexOf("vim.HostSystem:") !== -1) {
                            return "host";
                        }
                        if (uuid.indexOf("vim.ExternalIpaddress:") !== -1) {
                            return "external_ip";
                        }
                    }
                    return "unknown_ip";
                }

                for (let flow of this.flowDetails) {
                    for (let [ip_address, flow_resource_key, resource_name] of [
                            [flow.src_ipaddr, "src", flow.src_resource_name],
                            [flow.dst_ipaddr, "dst", flow.dst_resource_name]
                        ]) {
                        let objectUuid = flow[`${flow_resource_key}_resource_uuid`];
                        let objectType = detectType(objectUuid);
                        let object = {
                            name: resource_name,
                            type: objectType,
                            uuid: objectType === "unknown_ip" ? `vim.UnknownIpaddress:${ip_address}` : objectUuid,
                            address: ip_address,
                            ipaddress: ip_address,
                            is_in_filter: true
                        }
                        flow[`${flow_resource_key}_object`] = object;
                    }
                    // Ensure compatibility with FlowDetail (common flow datagrid)
                    flow.source = flow.src_object;
                    flow.target = flow.dst_object;
                    flow.src_address = flow.src_ipaddr;
                    flow.dst_address = flow.dst_ipaddr;
                    // Set temporalParameter to help the datagrid to understand what are the temporal bound of the
                    // current flow
                    flow.temporalParameter = new TemporalParameter(flow.date, flow.date, "hourly");
                }

                setTimeout(() => {
                    this.isLoading = false;
                    this.loadingStateEmitter.emit(false);
                }, 300);
            });
        });
    }

}
