<ng-template #templateSelectedResources let-item="item" xmlns="http://www.w3.org/1999/html">
    <span class="label label-warning" *ngIf="item.mode == 'focus'" style="height: 0.75rem; vertical-align: baseline;">focus</span>
    <span class="label label-danger" *ngIf="item.mode == 'ignore'" style="height: 0.75rem; vertical-align: baseline;">ignore</span>
    <span>{{ item.resource.name }}</span>
</ng-template>

<ng-template #templateDisplayResources let-item='item'>
    <span class="label label-warning" *ngIf="item.mode == 'focus'" style="height: 0.75rem; vertical-align: baseline;">focus</span>
    <span class="label label-danger" *ngIf="item.mode == 'ignore'" style="height: 0.75rem; vertical-align: baseline;">ignore</span>
    <span *ngIf="item.resource.name === undefined && item.resource.ipaddress !== undefined">{{ item.resource.ipaddress }}</span>
    <span *ngIf="item.resource.name !== undefined">{{ item.resource.name }}</span>
</ng-template>

<ng-template #templateDisplayResourcesWhenEmpty>
</ng-template>

<ng-template #templateDisplayResourcesWhenErrors>
</ng-template>

<div style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-flow: column;">

    <clr-alert [clrAlertType]="'danger'" [clrAlertAppLevel]="true" *ngIf="failureMode && !isLoading">
        <clr-alert-item>
            <span class="alert-text">Cannot load data from DC Netscope API. Please check the diagnostic page.</span>
            <div class="alert-actions">
                <a routerLink="/netscope/diagnostic" routerLinkActive="active">
                    <button class="btn alert-action">
                        <cds-icon shape="checkbox-list"></cds-icon>
                        Diagnostic
                    </button>
                </a>
            </div>
        </clr-alert-item>
    </clr-alert>

    <div class="clr-row" style="margin: 0px; background-color: rgb(0, 55, 76); color : white; overflow: visible; min-height: 74px;">
        <div class="clr-col" id="flow_view_title" style="flex-grow: 0; color: white; text-align: center;">
            <table style="height: 100%; min-width: 90px; font-weight: 400; font-size: .8rem; letter-spacing: .01em; text-decoration: none;">
                <tr>
                    <td>
                        <cds-icon shape="tree-view" style="margin-right: 10px; margin-bottom: 3px;" size="md"></cds-icon>
                    </td>
                    <td style="text-align: left;">{{ "dcnetscope.topology.name" | transloco }}</td>
                </tr>
            </table>
        </div>

        <div id="column-flow-combobox" class="clr-col" style="padding-top: 10px; padding-bottom: 5px;">
            <netscope-common-flowview-combobox
                [(selectedItems)]="currentComboboxSelection"
                [itemsGenerator]="fetchResourcesForCombobox"
                (onChange)="onComboboxChange($event)"
                [templateSelectedItems]="templateSelectedResources"
                [templateDisplayItems]="templateDisplayResources"
                [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
                [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
                [itemsField]="'key'"
                style="white-space: normal;"
            ></netscope-common-flowview-combobox>
        </div>

        <div class="clr-col" style="width: 100px; flex-grow: 0; padding-left: 0px;">
            <table style="height: 100%;">
                <tr>
                    <td style="padding: 5px;">
                        <a href="javascript://" id="flow_view_reload_button" class="nav-link nav-icon" aria-label="reload" (click)="reloadData()">
                            <cds-icon shape="refresh" *ngIf="!isLoading" style="color: white;"></cds-icon>
                            <span class="spinner spinner spinner-inline spinner-inverse" *ngIf="isLoading">Loading...</span>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <a href="javascript://" class="nav-link nav-icon" aria-label="tour">
                            <cds-icon shape="help" style="color: white;"></cds-icon>
                        </a>
                    </td>
                    <td style="padding: 5px;">
                        <clr-dropdown [clrCloseMenuOnItemClick]="true">
                            <a clrDropdownTrigger href="javascript://" class="nav-link nav-icon" aria-label="settings">
                                <cds-icon shape="cog" style="color: white;"></cds-icon>
                            </a>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">Language</label>
                                <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="setLanguage('french')">French</div>
                                <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="setLanguage('english')">English</div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="clr-row" style="position: relative; height: 100%; bottom: 0px; width: 100%; margin: 0px;">
        <clr-tabs style="background-color: white; width: 100%; height: 100%;">
            <clr-tab>
                <button id="flows_visualisation_button" clrTabLink style="margin-left: 25px;">{{ 'dcnetscope.flows_tab_visualisation' | transloco }}</button>
                <clr-tab-content>
                    <div style="position: absolute; left: 0px; right: 0px; top: 35px; bottom: 0px;">
                        <div class="clr-row" style="margin: 0px; position: absolute; z-index: 999; width: 100%; top: 0px; right: 15px; float: right;  pointer-events: none;">
                            <div class="clr-col-6">
                            <span style="position: relative; z-index: 999; top: 5px; left: 15px; pointer-events: auto;">
                                <clr-signpost>
                                     <cds-icon shape="cog"
                                               solid
                                               clrSignpostTrigger>
                                     </cds-icon>
                                        <clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen>

                                            <clr-radio-container clrInline>
                                                <label>Click behaviour on hosts</label>
                                                <clr-radio-wrapper>
                                                    <input type="radio" clrRadio name="resourceMouseMode" required value="graph" [(ngModel)]="resourceMouseMode" />
                                                    <label>View graphs</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                    <input type="radio" clrRadio name="resourceMouseMode" required value="showHide" [(ngModel)]="resourceMouseMode" />
                                                    <label>Show/Hide VMs</label>
                                                </clr-radio-wrapper>
                                            </clr-radio-container>

                                            <clr-radio-container clrInline>
                                                <label>Snap circles to the border</label>
                                                <clr-radio-wrapper>
                                                    <input type="radio" clrRadio name="snapCircles" required value="SnapCircles" [(ngModel)]="snapCirclesToTheBorder" (click)="reloadData()" />
                                                    <label>Activated</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                    <input type="radio" clrRadio name="snapCircles" required value="DontSnapCircles" [(ngModel)]="snapCirclesToTheBorder" (click)="reloadData()" />
                                                    <label>Deactivated</label>
                                                </clr-radio-wrapper>
                                            </clr-radio-container>

                                            <clr-radio-container clrInline>
                                                <label>Color blind colors</label>
                                                <clr-radio-wrapper>
                                                    <input type="radio" clrRadio name="useColorBlind" required value="useColorBlindColors" [(ngModel)]="useColorBlindColors" (click)="reloadData()" />
                                                    <label>Activated</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                    <input type="radio" clrRadio name="useColorBlind" required value="DontUseColorBlindColors" [(ngModel)]="useColorBlindColors" (click)="reloadData()" />
                                                    <label>Deactivated</label>
                                                </clr-radio-wrapper>
                                            </clr-radio-container>

                                            <label class="clr-control-label">Export:</label>
                                            <div class="btn-group btn-primary btn-icon">
                                                <button class="btn btn-primary btn-sm" (click)="exportGraph()">Graph</button>
                                            </div>
                                        </clr-signpost-content>
                                </clr-signpost>

                                <ng-container>
                                    <span style="margin-left: 10px;">
                                        <span class="badge" style="height: 1.1rem; cursor: pointer;" (click)="zoomInVisualisation()">
                                          <cds-icon shape="zoom-in"></cds-icon>
                                          <span class="clr-sr-only">zoom in</span>
                                        </span>

                                        <span class="badge" style="height: 1.1rem; cursor: pointer;" (click)="zoomOutVisualisation()">
                                          <cds-icon shape="zoom-out"></cds-icon>
                                          <span class="clr-sr-only">zoom out</span>
                                        </span>

                                        <span class="badge" style="height: 1.1rem; cursor: pointer;" (click)="recenterVisualisation()">
                                          <cds-icon shape="crosshairs"></cds-icon>
                                          <span class="clr-sr-only">re-center</span>
                                        </span>
                                    </span>
                                </ng-container>

                            </span>
                            </div>

                            <div class="clr-col-2" style="text-align: center;">
                            </div>

                            <div class="clr-col-4" style="text-align: right;">
                            </div>
                        </div>

                        <div id="divSvg" style="position: absolute; left: 0px; right: 0px; top: 0px; bottom: 0px; overflow: hidden;"></div>
                    </div>
                </clr-tab-content>
            </clr-tab>

            <clr-tab *ngFor="let datacenter of this.root">
                <button clrTabLink>{{ datacenter.name }}</button>
                <clr-tab-content *clrIfActive>

                    <div class="clr-row">
                        <div class="card" *ngFor="let switch of datacenter.switches">
                            <div class="card-header">
                                <b>{{ switch.name }}</b> <span class="label label-info" style="float: right;">switch</span>
                            </div>
                            <div class="card-block" *ngFor="let portgroup of switch.portGroups">
                                <div class="card-title">
                                    <span style="font-size: 14px;">{{ portgroup.name }}</span> <span class="label label-info" style="float: right;">portgroup</span>
                                </div>
                                <div class="card-text">
                                    <clr-datagrid *ngIf="portgroup.hasNoConnectees === false">
                                        <clr-dg-column [clrDgField]="'key'" [clrDgSortBy]="portKeyComparator">Port key</clr-dg-column>
                                        <clr-dg-column>Resource</clr-dg-column>
                                        <clr-dg-column>Interface</clr-dg-column>
                                        <clr-dg-column>VLANs</clr-dg-column>

                                        <clr-dg-row *clrDgItems="let item of portgroup.connecteesAsArray">
                                            <clr-dg-cell>{{item.key}}</clr-dg-cell>
                                            <clr-dg-cell>
                                                <span class="badge badge-blue" *ngIf="item.value.resourceObj !== undefined && item.value.resourceObj.type === 'vm'">{{item.value.resourceObj.name}}</span>
                                                <span class="badge" *ngIf="item.value.resourceObj !== undefined && item.value.resourceObj.type === 'host'">{{item.value.resourceObj.name}}</span>
                                                <span class="label" *ngIf="item.value.resourceObj === undefined">not available <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><cds-icon shape="info-circle" size="24"></cds-icon><span class="tooltip-content">Resource {{item.value.resourceObj.id}} is not available in this filter</span></a></span>
                                            </clr-dg-cell>
                                            <clr-dg-cell>{{item.value.interface}}</clr-dg-cell>
                                            <clr-dg-cell>
                                                <span *ngFor="let vlan of item.value.vlans">
                                                    <span class="label label-info" *ngIf="vlan.start !== vlan.end">{{vlan.start}} - {{vlan.end}}</span>
                                                    <span class="label label-info" *ngIf="vlan.start === vlan.end && vlan.start !== 0">{{vlan.start}}</span>
                                                </span>
                                            </clr-dg-cell>
                                        </clr-dg-row>
                                    </clr-datagrid>
                                    <div class="alert alert-warning" role="alert"  *ngIf="portgroup.hasNoConnectees === true">
                                        <div class="alert-items">
                                            <div class="alert-item static">
                                                <div class="alert-icon-wrapper">
                                                    <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
                                                </div>
                                                <span class="alert-text">Nothing is connected to this portgroup.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-sm btn-link">Footer Action 1</button>
                                <button class="btn btn-sm btn-link">Footer Action 2</button>
                            </div>
                        </div>
                    </div>

                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
</div>